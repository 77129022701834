import { useTranslation } from './useTranslation';

export default () => {
  const { t } = useTranslation();
  const businessList = [
    { name: 'Strauss, Robert E', accounts: [] },
    { name: 'Clackamas County', accounts: [] },
  ];
  const selectedBusinesses = ['Strauss, Robert E'];
  const isRelease = !true;
  const isSamePersonAuthorizing = true;
  const content = {
    next: t('NEXT'),
    back: t('BACK'),
    cancel: t('CANCEL'),
    submit: t('SUBMIT'),
    done: t('DONE'),
    dateTimeFormat: 'hh:mm:ss a, DD MMMM YYYY',
    dateFormat: 'DD MMMM YYYY',
    yearFormat: 'YYYY',
    title: t('HISTORICAL_USAGE_TITLE'),
    formPage: {
      summary: t('HISTORICAL_USAGE_FORM_SUMMARY'),
      businesses: {
        title: t('HISTORICAL_USAGE_FORM_BUSINESSES_TITLE'),
        text: t('HISTORICAL_USAGE_FORM_BUSINESSES_TEXT'),
        note: t('HISTORICAL_USAGE_FORM_BUSINESSES_NOTE'),
        listTitle: t('HISTORICAL_USAGE_FORM_BUSINESSES_LIST_TITLE'),
        listText: t('HISTORICAL_USAGE_FORM_BUSINESSES_LIST_TEXT'),
        listNote: t('HISTORICAL_USAGE_FORM_BUSINESSES_LIST_NOTE'),
        noSelectionError: t(
          'HISTORICAL_USAGE_FORM_BUSINESSES_NO_SELECTION_ERROR',
        ),
      },
      authorization: {
        title: t('HISTORICAL_USAGE_FORM_AUTHORIZATION_TITLE'),
        releaseOption: t('HISTORICAL_USAGE_FORM_AUTHORIZATION_RELEASE_OPTION'),
        releaseOptionText: t(
          'HISTORICAL_USAGE_FORM_AUTHORIZATION_RELEASE_OPTION_TEXT',
        ),
        revokeOption: t('HISTORICAL_USAGE_FORM_AUTHORIZATION_REVOKE_OPTION'),
        revokeOptionText: t(
          'HISTORICAL_USAGE_FORM_AUTHORIZATION_REVOKE_OPTION_TEXT',
        ),
      },
      contactInformation: {
        title: t('HISTORICAL_USAGE_FORM_CONTACT_INFO_TITLE'),
        text: t('HISTORICAL_USAGE_FORM_CONTACT_INFO_TEXT'),
      },
      authContacInformation: {
        title: t('HISTORICAL_USAGE_FORM_CONTACT_AUTH_TITLE'),
        text: t('HISTORICAL_USAGE_FORM_CONTACT_AUTH_TEXT'),
        samePersonAuthorizing: t('HISTORICAL_USAGE_CONTACT_SAME_AS_ABOVE'),
      },
    },
    confirmPage: {
      summary: t('HISTORICAL_USAGE_CONFIRM_SUMMARY'),
      summaryText: t('HISTORICAL_USAGE_CONFIRM_TEXT'),
      selectServiceNote: t('HISTORICAL_USAGE_CONFIRM_SELECT_SERVICE_NOTE'),
      release: {
        title: t('HISTORICAL_USAGE_CONFIRM_RELEASE_TITLE'),
        groupsText: t('HISTORICAL_USAGE_CONFIRM_RELEASE_GROUPS_TEXT'),
        noReleaseNote: t('HISTORICAL_USAGE_CONFIRM_NO_RELEASE_NOTE'),
        releaseNote: t('HISTORICAL_USAGE_CONFIRM_RELEASE_NOTE'),
        prohibitionNote: t('HISTORICAL_USAGE_CONFIRM_RELEASE_PROHIBITION_NOTE'),
      },
      revoke: {
        title: t('HISTORICAL_USAGE_CONFIRM_REVOKE_TITLE'),
        groupsText: t('HISTORICAL_USAGE_CONFIRM_REVOKE_GROUPS_TEXT'),
        noRevokeNote: t('HISTORICAL_USAGE_CONFIRM_NO_REVOKE_NOTE'),
        revokeNote: t('HISTORICAL_USAGE_CONFIRM_REVOKE_NOTE'),
        prohibitionNote: t('HISTORICAL_USAGE_CONFIRM_REVOKE_PROHIBITION_NOTE'),
      },
    },
    donePage: {
      subTitle: t('HISTORICAL_USAGE_DONE_TRANSACTION_SUCCESSFUL'),
      release: {
        title: t('HISTORICAL_USAGE_DONE_RELEASE_TITLE'),
        text: t('HISTORICAL_USAGE_DONE_RELEASE_TEXT'),
      },
      revoke: {
        title: t('HISTORICAL_USAGE_DONE_REVOKE_TITLE'),
        text: t('HISTORICAL_USAGE_DONE_REVOKE_TEXT'),
      },
    },
  };

  return {
    businessList,
    selectedBusinesses,
    isRelease,
    isSamePersonAuthorizing,
    content,
  };
};
