import React, { useMemo } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import LeftNavBlock from '../utility/left-nav-block';
import ROUTES from '../../routes';
import LeftNavMenu from '../left-menu-navigation';

const ClosedAccountsLeftNav = () => {
  const { t } = useTranslation();
  const data = useMemo(
    () => [
      {
        labels: t('CLOSED_ACCOUNTS'),
        menuOpen: true,
        items: [
          {
            labels: t('VIEW_CLOSED_ACCOUNTS'),
            url: ROUTES.CLOSED_ACCOUNTS,
            slug: null,
            menuOpen: false,
          },
        ],
      },
    ],
    [],
  );
  const selectedLinkRoute = location.pathname;
  return (
    <LeftNavMenu leftNavMenuItems={data} selectedItem={selectedLinkRoute} />
  );
};

export default ClosedAccountsLeftNav;
