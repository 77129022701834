/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FC, Fragment, useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { styled, useTheme } from '@material-ui/core/styles';
import useContentMgmt from '../../../hooks/useContentMgmt';
import { useLocation } from '@reach/router';
import PGEButton from '../../buttons';
import colors from '../../../themes/main-colors';
import { navigate } from 'gatsby';
import routes from '../../../routes';
import { IneligibilityReasonCode } from '../../../__generated__/pgeplus-types';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { Question } from '../../pge-plus-common/Typography';
import useAccountListHeader from '../../account-summary/multi-accounts/useAccountListHeader';
import useAccountDetailList from '../../../hooks/useAccountDetailList';
import { AccountRelationship } from '../../../__generated__/pge-types';
import { getAccounts } from '../../pge-plus-common/queries/ChooseAccount';
import Backdrop from '../../backdrop/Backdrop';
import useAccountCustomer from '../../../hooks/useAccountCustomer';

const PREFIX = 'ineligible';
const classes = {
  submit: `${PREFIX}-submit`,
  back: `${PREFIX}-back`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled(Box)(({ theme }) => ({
  [`& .${classes.submit}`]: {
    backgroundColor: colors.orange,
    borderColor: colors.orange,
    borderRadius: '30px',
    fontSize: '18px',
    minWidth: '130px',
    '&:hover': {
      backgroundColor: colors.orange,
      borderColor: colors.orange,
      boxShadow: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  [`& .${classes.back}`]: {
    minWidth: '130px',
    border: '2px solid #27788C',
    backgroundColor: colors.white,
    borderRadius: '30px',
    fontSize: '18px',
    '&:hover': {
      border: `2px solid ${colors.buttonHoverPrimary}`,
      color: colors.buttonHoverPrimary,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));

export enum IneligibilityTypeNumber {
  NOT_RESIDENTIAL = 12501,
  NOT_MAIN_OR_CO_CUSTOMER = 12502,
  NOT_ACTIVE = 12503,
  PAST_DUE_30_DAYS = 12505,
  BANKRUPTCY = 12506,
  ENERGY_ASSISTANCE = 12507,
}

interface IneligibleProps {
  path: string;
}

type TextData = {
  title: string;
  text: string | React.ReactNode;
};

export const InEligible: FC<IneligibleProps> = props => {
  const { state } = useLocation();
  const theme = useTheme();
  const [hideBackButton, setHideBackButton] = useState<boolean>(false);
  const { accountListParams } = useAccountListHeader();
  const { customer, loading: loadingCustomer } = useAccountCustomer();
  const { accounts, totalCount, loading } = useAccountDetailList(
    getAccounts,
    'RES_ACCTS',
    {
      accountListParams: {
        ...accountListParams,
        // pageSize: showAccountByLimit,
        userAccountRelFilter: [
          AccountRelationship.Main,
          AccountRelationship.Coapp,
        ],
      },
    },
  );

  const ineligibleType = state as {
    errorType: IneligibilityReasonCode;
    IneligibilityCode: IneligibilityReasonCode;
    IneligibilityNumber: IneligibilityTypeNumber;
  };

  const { content, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_EV_PULSE_ENROLLMENT_INELIGIBLE,
  );

  const textData: TextData = {
    title: content.get('EV_CHARGER_INELIGIBLE_TITLE') || '',
    text: '',
  };
  const isInEligibleOwnerAndMultipleFamily =
    ineligibleType?.errorType ===
    IneligibilityReasonCode.IneligibleOwnerType +
      IneligibilityReasonCode.IneligibleHomeType;

  //@ts-ignore
  if (state?.errorType === 'REBATES_INELIGIBLE') {
    textData.text = richText(content.get('REBATES_INELIGIBLE'));
  }

  if (
    ineligibleType?.IneligibilityCode ===
    IneligibilityReasonCode.IneligibleApplicationLimit
  ) {
    textData.text = richText(
      content.get('REBATE_ONLY_INELIGIBLE_APPLICATION_IN_PROGRESS'),
    );
  } else if (
    ineligibleType?.IneligibilityCode === IneligibilityReasonCode.IneligibleC2M
  ) {
    if (
      ineligibleType?.IneligibilityNumber ===
      IneligibilityTypeNumber.NOT_RESIDENTIAL
    ) {
      textData.text = (
        <Fragment>
          {richText(`${content.get('EV_CHARGER_INELIGIBLE_NOT_RESIDENTIAL')}`)}
          {richText(
            `${content.get('EV_CHARGER_INELIGIBLE_CUSTOMER_ASSISTANCE')}`,
          )}
        </Fragment>
      );
    } else if (
      ineligibleType?.IneligibilityNumber ===
      IneligibilityTypeNumber.NOT_MAIN_OR_CO_CUSTOMER
    ) {
      textData.text = (
        <Fragment>
          {richText(
            `${content.get('EV_CHARGER_INELIGIBLE_NOT_MAIN_OR_CO_CUSTOMER')}`,
          )}
          {richText(
            `${content.get('EV_CHARGER_INELIGIBLE_CUSTOMER_ASSISTANCE')}`,
          )}
        </Fragment>
      );
    } else if (
      ineligibleType?.IneligibilityNumber === IneligibilityTypeNumber.NOT_ACTIVE
    ) {
      textData.text = (
        <Fragment>
          {richText(
            `${content.get('EV_CHARGER_INELIGIBLE_NOT_ACTIVE')} ${content.get(
              'EV_CHARGER_INELIGIBLE_NOT_ACTIVE_LINK_TO_START_SERVICE',
            )}`,
          )}
          {richText(`${content.get('REUSABLE_NEED_HELP')}`)}
        </Fragment>
      );
    } else if (
      ineligibleType?.IneligibilityNumber ===
      IneligibilityTypeNumber.PAST_DUE_30_DAYS
    ) {
      textData.text = (
        <Fragment>
          {richText(`${content.get('EV_CHARGER_INELIGIBLE_PAST_DUE_30_DAYS')}`)}
          {richText(`${content.get('REUSABLE_NEED_HELP')}`)}
        </Fragment>
      );
    } else if (
      ineligibleType?.IneligibilityNumber === IneligibilityTypeNumber.BANKRUPTCY
    ) {
      textData.text = (
        <Fragment>
          {richText(`${content.get('EV_CHARGER_INELIGIBLE_BANKRUPTCY')}`)}
          {richText(`${content.get('REUSABLE_NEED_HELP')}`)}
        </Fragment>
      );
    } else if (
      ineligibleType?.IneligibilityNumber ===
      IneligibilityTypeNumber.ENERGY_ASSISTANCE
    ) {
      textData.text = (
        <Fragment>
          {richText(
            `${content.get('EV_CHARGER_INELIGIBLE_ENERGY_ASSISTANCE')}`,
          )}
          {richText(`${content.get('REUSABLE_NEED_HELP')}`)}
        </Fragment>
      );
    } else {
      textData.text = (
        <Fragment>
          {richText(content.get('EV_CHARGER_INELIGIBLE_CUSTOMER_ASSISTANCE'))}
        </Fragment>
      );
    }
  } else if (
    ineligibleType?.errorType === IneligibilityReasonCode.IneligibleOwnerType
  ) {
    textData.text = (
      <Fragment>
        {richText(content.get('OWNER_INELIGIBLITY'))}
        {richText(content.get('REUSABLE_NEED_HELP'))}
      </Fragment>
    );
  } else if (
    ineligibleType?.errorType === IneligibilityReasonCode.IneligibleHomeType
  ) {
    textData.text = (
      <Fragment>
        {richText(content.get('MULTIFAMILY_INELIGIBLITY'))}
        {richText(content.get('REUSABLE_NEED_HELP'))}
      </Fragment>
    );
  } else if (isInEligibleOwnerAndMultipleFamily) {
    textData.text = (
      <Fragment>
        {richText(content.get('OWNER_INELIGIBLITY'))}
        {richText(content.get('MULTIFAMILY_INELIGIBLITY'))}
        {richText(content.get('REUSABLE_NEED_HELP'))}
      </Fragment>
    );
  } else if (
    ineligibleType?.errorType === IneligibilityReasonCode.IneligibleProductType
  ) {
    textData.text = (
      <Question
        detail={content.get('EXISTING_CHARGER_UNAVILABLE')}
        detailPopoverContent={content.get(
          'EXISTING_CHARGER_UNAVILABLE_TOOLTIP',
        )}
      />
    );
  } else if (
    ineligibleType?.IneligibilityCode ===
    IneligibilityReasonCode.IneligibleServiceProviderUnavailble
  ) {
    textData.text = (
      <Fragment>
        {richText(content.get('EV_CHARGER_INELIGIBLE_NOT_IN_ELIGIBLE_ZIPCODE'))}
        {richText(content.get('REUSABLE_NEED_HELP'))}
      </Fragment>
    );
  } else if (
    ineligibleType?.IneligibilityCode ===
    IneligibilityReasonCode.IneligibleApplicationInProgress
  ) {
    textData.text = (
      <Fragment>
        {richText(
          content.get('EV_CHARGER_INELIGIBLE_EXISTING_APPLICATION_IN_PROGRESS'),
        )}
        {richText(content.get('REUSABLE_NEED_HELP'))}
      </Fragment>
    );
  }

  useEffect(() => {
    if (
      totalCount === 0 ||
      (accountListParams.query === '' &&
        totalCount === 1 &&
        accounts[0].premiseInfo &&
        accounts[0].premiseInfo.length === 1 &&
        accounts[0].premiseInfo[0] &&
        customer?.personId &&
        //@ts-ignore
        state?.fromPage === routes.PGE_PLUS_EV_CHARGERS_CHOOSE_ACCOUNT)
    ) {
      setHideBackButton(true);
    } else {
      setHideBackButton(false);
    }
  }, [accounts, customer]);

  return (
    <Root mt={4}>
      {loading && <Backdrop forceOpen />}
      <Box textAlign={'center'}>
        <Typography variant={'h1'}>{textData.title}</Typography>
        <Typography
          component="div"
          css={{
            marginTop: 16,
            textAlign: 'left',
            '&>p:nth-child(2)': { marginTop: '28px' },
          }}
        >
          {textData.text}
        </Typography>
      </Box>

      <Box
        display={'flex'}
        justifyContent={'center'}
        flexDirection={'row'}
        mt={3}
        mb={3}
        css={{
          gap: hideBackButton ? 0 : 32,
          marginTop: 50,
          [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
            padding: '24px 14px',
            backgroundColor: colors.lightGray6,
            marginBottom: 0,
          },
        }}
      >
        <Box>
          {!hideBackButton && (
            <PGEButton
              fullWidth
              type={'button'}
              variant={'outlined'}
              color={'secondary'}
              className={classes.back}
              onClick={() => window.history.back()}
            >
              {content.get('REUSABLE_BUTTON_BACK')!}
            </PGEButton>
          )}
        </Box>
        <Box>
          <PGEButton
            fullWidth
            type={'button'}
            variant={'contained'}
            className={classes.submit}
            onClick={() => navigate(routes.ACCOUNT)}
          >
            {content.get('RETURN_TO_MY_ACCOUNT')!}
          </PGEButton>
        </Box>
      </Box>
    </Root>
  );
};
