import { useState, useEffect, useContext } from 'react';
import {
  getLeadData,
  updateLeadDetails,
} from '../components/client-program-promo-modal/ClientProgramPromoModal.query';
import useAuthQuery from './useAuthQuery';
import useWrapWithLoader from './useWrapWithLoading';

import {
  ContactInfoConfirmationOption,
  LeadData,
  LeadDetailsUpdateRequest,
  LeadDiscardReason,
  LeadDetailsInfo,
} from '../__generated__/pge-types';
import useSelectedAccountParams from './useSelectedAccountParams';
import apolloClient from '../lib/apolloClient';
import { navigate } from '@reach/router';
import ROUTES from '../routes';
import { NotificationsContext } from '../providers/NotificationsProvider';
import { useTranslation } from './useTranslation';

const useClientProgramPromo = (isClientProgramPromoModalEnabled: boolean) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const { accountNumber: acctId } = useSelectedAccountParams();
  const { loading, data } = useAuthQuery<{
    getLeadData: LeadData;
  }>(getLeadData, {
    variables: {
      params: {
        acctId: acctId,
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'no-cache',
    skip: !acctId || !isClientProgramPromoModalEnabled,
  });

  const leadData = data?.getLeadData?.results?.find(
    lead => lead?.boStatus?.toLowerCase() === 'active',
  );

  useEffect(() => {
    if (leadData) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [leadData]);

  if (isClientProgramPromoModalEnabled === false) {
    return {
      open: false,
      setOpen: undefined,
      leadData: undefined,
      handleReject: () => Promise.resolve(),
      handleApprove: () => Promise.resolve(),
      setNotificationMessage: () => void 0,
    };
  }

  const { wrapWithLoader } = useWrapWithLoader();

  async function submitLeadDetailsUpdate(payload: LeadDetailsUpdateRequest) {
    const { data: submitData, errors } = await apolloClient.mutate<{
      updateLeadDetails: LeadDetailsUpdateRequest;
    }>({
      mutation: updateLeadDetails,
      variables: {
        payload,
      },
      errorPolicy: 'all',
    });

    if (errors && errors.length > 0) {
      return { isSuccess: false, errors };
    }
    return { isSuccess: true };
  }

  const handleClick = wrapWithLoader(
    async ({
      outcome,
      rejectReason,
      notes,
    }: {
      outcome: string;
      rejectReason?: LeadDiscardReason;
      notes: string;
    }) => {
      try {
        if (leadData?.leadId && acctId) {
          const result = await submitLeadDetailsUpdate({
            leadId: leadData.leadId,
            outcome,
            discardReasonCd: rejectReason,
            notes,
            accountId: acctId,
          });
        }
      } catch (e) {
        console.log(e);
      } finally {
        setNotificationMessage(
          outcome === 'A'
            ? t('PAPERLESS_EMAIL_CONFIRMATION_MESSAGE_REJECT_FALSE')
            : t('PAPERLESS_EMAIL_CONFIRMATION_MESSAGE'),
        );
      }
    },
  );
  const handleClose = () => {
    setOpen(false);
  };

  const handleApprove = async (channel: string) => {
    await handleClick({ outcome: 'A', notes: channel });
    handleClose();
  };

  const handleReject = async (
    rejectReason: LeadDiscardReason,
    channel: string,
  ) => {
    await handleClick({ outcome: 'R', rejectReason, notes: channel });

    handleClose();
  };
  const notificationContext = useContext(NotificationsContext);
  const setNotificationMessage = (
    message: string | React.ReactNode = '',
    severity: 'success' | 'error' = 'success',
  ) => {
    notificationContext.setState({
      isOpen: true,
      message,
      severity,
    });
    window.scrollTo(0, 0);
  };
  return {
    open,
    setOpen,
    leadData,
    handleApprove,
    handleReject,
    handleClose,
    setNotificationMessage,
    acctId,
  };
};

export default useClientProgramPromo;
