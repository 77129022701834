import React, { FunctionComponent } from 'react';
import { Router } from '@reach/router';

import ROUTES from '../../routes';
import OpenEnrollmentElection from '../../components/open-enrollment/open-enrollment-election';
import AccountLayout from '../../components/account-layout';
import OpenEnrollmentContact from '../../components/open-enrollment/open-enrollment-contact';
import OpenEnrollmentPlan from '../../components/open-enrollment/open-enrollment-plan';
import OpenEnrollmentConfirm from '../../components/open-enrollment/open-enrollment-confirm';
import OpenEnrollmentDone from '../../components/open-enrollment/open-enrollment-done';
import { DirectAccessContextProvider } from '../../providers/DirectAccessProvider';

type Props = {
  path?: string;
};

const OpenEnrollmentPage: FunctionComponent<Props> = () => {
  return (
    <DirectAccessContextProvider>
      <AccountLayout>
        <Router basepath="/">
          <OpenEnrollmentElection path={ROUTES.OPEN_ENROLLMENT_ELECTION} />
          <OpenEnrollmentContact path={ROUTES.OPEN_ENROLLMENT_CONTACT} />
          <OpenEnrollmentPlan path={ROUTES.OPEN_ENROLLMENT_PLAN} />
          <OpenEnrollmentConfirm path={ROUTES.OPEN_ENROLLMENT_CONFIRM} />
          <OpenEnrollmentDone path={ROUTES.OPEN_ENROLLMENT_DONE} />
        </Router>
      </AccountLayout>
    </DirectAccessContextProvider>
  );
};

export default OpenEnrollmentPage;
