import gql from 'not-graphql-tag';
import { AlertReponse, GetAlertsRequest } from '../__generated__/pge-types';
import useAuthQuery from './useAuthQuery';

const GET__ALERTS = gql`
  query GetAlerts($params: GetAlertsRequest) {
    getAlerts(params: $params) {
      prefLanguage
      alerts {
        notificationType
        deliveryTypeDetails {
          deliveryType
          contactDetails {
            contactId
            encryptedContactId
            value
            encryptedPreferenceId
            isSelected
            isPrimary
          }
          deliveryTypeDescription
        }
        notificationTypeDescription
        notificationOptions {
          sequence
          value
        }
      }
    }
  }
`;

export type Alerts = {
  getAlerts: AlertReponse;
};

export const useGetAlertsService = (params: GetAlertsRequest) => {
  const { refetch, loading, error, data } = useAuthQuery<
    Alerts,
    { params: GetAlertsRequest }
  >(GET__ALERTS, {
    variables: {
      params,
    },
  });
  return {
    refetch,
    loading,
    error,
    data,
  };
};
export default useGetAlertsService;
