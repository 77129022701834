import React, { useState } from 'react';

import { useTranslation } from '../../../../../hooks/useTranslation';
import { OtherPeopleResponse } from '../../../../../__generated__/pge-types';
import AddUsersCard from '../../CoCustomer/AddUsersCard';
import AddUsersModal from '../AddUsersModal';
import AddCoCustomerForm from './AddCoCustomerForm';

type Props = {
  usersList: OtherPeopleResponse[] | undefined;
  refetchCustomerList: () => void;
};

export default ({ usersList: coCustomers, refetchCustomerList }: Props) => {
  const { t, richT } = useTranslation();
  const [
    isAddCoCustomerModalVisible,
    setIsAddCoCustomerModalVisible,
  ] = useState<boolean>(false);

  return (
    <>
      <AddUsersCard
        title={t('SSM_POST_ADD_CO_CUSTOMER_CARD_TITLE')}
        description={
          coCustomers && coCustomers?.length > 0
            ? richT('SSM_POST_CO_CUSTOMER_ALREADY_EXISTS_MESSAGE')
            : t('SSM_POST_ADD_CO_CUSTOMER_DESCRIPTION')
        }
        usersList={coCustomers}
        showAddUsersModal={() => setIsAddCoCustomerModalVisible(true)}
      />

      {isAddCoCustomerModalVisible && (
        <AddUsersModal
          onClose={() => setIsAddCoCustomerModalVisible(false)}
          open={isAddCoCustomerModalVisible}
        >
          <AddCoCustomerForm
            onClose={() => setIsAddCoCustomerModalVisible(false)}
            refetchCustomerList={refetchCustomerList}
          />
        </AddUsersModal>
      )}
    </>
  );
};
