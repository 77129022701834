import Typography from '@material-ui/core/Typography';
import { navigate, Router } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import EvRebates from '../../../components/ev-rebates';
import Backdrop from '../../../components/backdrop';
import EvRebateForm from '../../../components/ev-rebates/EvRebateForm';
import ProfileLayout from '../../../components/profile-layout';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import useAuthQuery from '../../../hooks/useAuthQuery';
import { getSalesforceEncryptedAccountNumberQuery } from '../../../components/ev-rebates/evRebate.queries';
import { AccountDetail } from '../../../__generated__/pge-types';
import '../../../templates/css/contentful-pages.css';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Box, Link } from '@material-ui/core';
import Button from '../../../components/buttons';
import PaperBox from '../../../components/utility/paper-box';
import config from '../../../config/config';
import gql from 'graphql-tag';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    copyBlock: {
      '& > p': {
        marginTop: '0.875rem',
      },
    },
    submitButton: {
      paddingLeft: 25,
      paddingRight: 25,
      marginLeft: 10,
      fontSize: 15,
      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
        width: '100%',
      },
    },
  }),
);
export const getAccountDetails = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountType
    }
  }
`;
export const EvRebatePage = (props: { path?: string }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { encryptedAccountNumber, accountParams } = useSelectedAccountParams();

  const sfUrl = config.salseforceEVFormURL;

  const { loading: hookLoading, data } = useAuthQuery(
    getSalesforceEncryptedAccountNumberQuery,
    {
      variables: {
        payload: {
          encryptedAccountNumber: encryptedAccountNumber,
        },
      },
      skip: !accountParams,
    },
  );

  const { data: onPremData } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetails, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
  });

  const accountType = onPremData?.getAccountDetails?.[0].accountType;

  const salesForceEncryptedAccountNumber = data?.getSalesforceEncryptedAccountNumber!;

  const loading = hookLoading || !data;

  if (loading || !accountType) {
    return <Backdrop forceOpen />;
  }

  const handleOnClickManageNext = async () => {
    await navigate(ROUTES.EV_REBATE_FORM_ENROLL, { replace: true });
  };

  const handleOnClickManageOk = async () => {
    await navigate(ROUTES.ACCOUNT, { replace: true });
  };

  {
    return (
      <>
        <Helmet>
          <title>{t('EV_REBATE_FORM')}</title>
        </Helmet>
        <ProfileLayout dense={false}>
          {accountType === 'RES' ? (
            <>
              <Typography variant={'h1'} data-testid={'plan-options-available'}>
                {t('ENROLL_IN_EV_REBATE_PROGRAM')}
              </Typography>

              <Box style={{ marginBottom: '1em' }}>
                <Router basepath="/">
                  <EvRebates
                    path={ROUTES.EV_REBATE_FORM}
                    handleOnClickManageNext={handleOnClickManageNext}
                  />
                  <EvRebateForm
                    path={ROUTES.EV_REBATE_FORM_ENROLL}
                    sfEncryptedAccountNumber={salesForceEncryptedAccountNumber}
                    hostUrl={sfUrl}
                  />
                </Router>
              </Box>
              <PaperBox className={classes.copyBlock}>
                <Typography variant="h2">
                  {t('EV_REBATE_PAGE_CARD_HEADER')}
                </Typography>

                <Typography variant={'body1'}>
                  {t('EV_REBATE_PAGE_CARD_PARAGRAPH_1')}
                </Typography>

                <Typography variant={'body1'}>
                  {t('EV_REBATE_PAGE_CARD_PARAGRAPH_2')}
                </Typography>

                <Typography variant={'body1'}>
                  {t('EV_REBATE_PAGE_CARD_PARAGRAPH_3')}{' '}
                  <Link href={ROUTES.EV_REBATE_PAGE} target="_blank">
                    {t('VIEW_FULL_PROGRAM_DETAILS')}
                  </Link>
                  {'.'}
                </Typography>
              </PaperBox>
            </>
          ) : (
            <>
              <PaperBox className={classes.copyBlock}>
                <Typography
                  variant={'h2'}
                  data-testid={'plan-options-not-available-h2'}
                >
                  {t('SINGLE_OPTION_NOT_AVAILABLE')}
                </Typography>
                <Typography variant={'body1'}>
                  {t('EV_REBATE_PROGRAM_PARAGRAPH')}{' '}
                  <Link href={ROUTES.EV_REBATE_PAGE} target="_blank">
                    {t('VIEW_FULL_PROGRAM_DETAILS')}
                  </Link>
                  {'.'}
                </Typography>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    marginTop: '1em',
                  }}
                >
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    className={classes.submitButton}
                    onClick={handleOnClickManageOk}
                    data-testid={'ok-cta'}
                  >
                    {t('OK')}
                  </Button>
                </Box>
              </PaperBox>
            </>
          )}
        </ProfileLayout>
      </>
    );
  }
};

export default EvRebatePage;
