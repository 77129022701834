import { Router } from '@reach/router';
import React from 'react';
import ROUTES from '../../routes';
import HistoryPage from './history';
import MultiPayPage from './multi-pay';
import PaymentPage from './pay';
import EqualPayPage from './equal-pay';
import PrivateRoute from '../../components/routing/PrivateRoute';
import NotFoundPage from '../../pages/404';
import PaymentConfirmationPage from './payment-confirmation';

export const PaymentRouter = () => {
  return (
    <Router basepath="/">
      <PaymentPage path={`${ROUTES.PAYMENT}/*`} />
      <PaymentConfirmationPage path={`${ROUTES.PAYMENT_CONFIRMATION}`} />
      <MultiPayPage path={`${ROUTES.MULTI_PAY}/*`} />
      <HistoryPage path={`${ROUTES.PAYMENT_HISTORY}`} />
      <EqualPayPage path={`${ROUTES.EQUAL_PAY}`} />
      <PrivateRoute path="/*" component={NotFoundPage} />
    </Router>
  );
};
