import { Box, Typography, createStyles, makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import {
  ActiveSeason,
  ActiveSeasonDeatils,
} from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import theme from '../../../../themes/theme';
import IconSnow from '../../../../static/snowIcon.svg';
import IconSun from '../../../../static/sunIcon.svg';
import { formatDateToMonth } from '../../../../util/date-utils';
import { LanguageContext } from '../../../../providers/LanguageProvider';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      background: 'white',
      padding: theme.spacing(2),
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      paddingBottom: theme.spacing(1),
    },
    seasonText: {
      '& p': {
        margin: 0,
      },
    },
    icon: {
      width: '32px',
    },
  }),
);

export function SeasonalDates({
  activeSeason,
}: {
  activeSeason?: ActiveSeasonDeatils;
}) {
  const { richT } = useTranslation();
  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box style={{ paddingRight: theme.spacing(1) }}>
          {activeSeason && activeSeason.name === ActiveSeason.Summer ? (
            <IconSun className={classes.icon} />
          ) : (
            <IconSnow className={classes.icon} />
          )}
        </Box>
        <Box>
          <Typography variant="body2" className={classes.seasonText}>
            {activeSeason && activeSeason.name === ActiveSeason.Summer
              ? richT('SUMMER_ACTIVE_SEASON_HEADING')
              : richT('WINTER_ACTIVE_SEASON_HEADING')}
          </Typography>
          <Typography variant="body2">
            {activeSeason &&
              richT('SMART_CHARGING_ACTIVE_SEASON_CTA', {
                SEASON_START: formatDateToMonth(
                  new Date(activeSeason.start),
                  language,
                ),
                SEASON_END: formatDateToMonth(
                  new Date(activeSeason.end),
                  language,
                ),
              })}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
