import React, { useMemo } from 'react';

import LeftNavBlock from '../utility/left-nav-block';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    LeftNavBlock: {
      '& .MuiButton-label': {
        display: 'flex',
        justifyContent: 'start',

        '& .MuiTypography-button': {
          whiteSpace: 'unset',
        },
      },
    },
  }),
);

const EssLeftPane = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const data = useMemo(
    () => ({
      label: t('ESS_PORTAL'),
      menuItems: [
        { label: t('BACK'), url: ROUTES.ACCOUNT },
        { label: t('ELECTION_WINDOW'), url: ROUTES.ESS_ELECTION_WINDOW },
        {
          label: t('DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE'),
          url: ROUTES.ESS_DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE,
        },
        {
          label: t('FACTS_ABOUT_ELECTRICITY_SUPPLY_CHOICES'),
          url:
            ROUTES.ESS_ENERGY_CHOICES_URL,
        },
      ],
    }),
    [],
  );
  return <LeftNavBlock {...data} buttonClass={classes.LeftNavBlock} />;
};

export default EssLeftPane;
