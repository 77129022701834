/** @jsx jsx */
import { jsx } from '@emotion/core';
import { navigate, useLocation } from '@reach/router';
import { Fragment, useEffect, useState } from 'react';
import { UpdateSectionEVRebatesMutationTypes } from '../../../hooks/pge-plus/useRebatesEligibility';
import useContentMgmt, {
  PageTextContentType,
} from '../../../hooks/useContentMgmt';
import { FormState } from '../../../hooks/useFormState.types';
import routes from '../../../routes';
import { GridItem } from '../../pge-plus-common/Grid';
import { SaveAndComeBackLink } from '../../pge-plus-common/SaveAndComeBackLink';
import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import { Question } from '../../pge-plus-common/Typography';
import { RebateEligibility } from '../evcharger.types';
import { ApplicationSectionStatus } from '../../../__generated__/pgeplus-types';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';

type RebatesIneligibleProps = {
  content: PageTextContentType;
  form: FormState<RebateEligibility>;
  path: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  handleSaveAndExit: () => void;
  updateSectionEVRebatesEligibility: UpdateSectionEVRebatesMutationTypes;
  updateFormValues: (values: RebateEligibility) => boolean;
};

export const RebatesIneligible = (props: RebatesIneligibleProps) => {
  const {
    content,
    setPageTitle,
    form,
    updateSectionEVRebatesEligibility,
    updateFormValues,
  } = props;
  const { richText } = useContentMgmt();
  const { wrapWithLoader } = useWrapWithLoader();
  const { state } = useLocation();
  // @ts-ignore
  const applicationIncomplete = state?.applicationIncomplete;

  const {
    formState: {
      selectCharger: { suppliedByCustomer },
    },
  } = useEVChargerFormState();

  useEffect(() => {
    setPageTitle(content.get('EV_CHARGER_INELIGIBLE_TITLE') || '');
  }, []);

  return (
    <form
      onSubmit={wrapWithLoader(
        form.submit(async () => {
          updateFormValues(form.values);
          if (applicationIncomplete) {
            const {
              validationError,
              amount,
            } = await updateSectionEVRebatesEligibility(
              form.values,
              false,
              ApplicationSectionStatus.Completed,
            );
            if (!validationError) {
              updateFormValues({ ...form.values, amount: amount?.toString() });
              void navigate(
                suppliedByCustomer
                  ? routes.PGE_PLUS_EV_CHARGERS_INSTALLATION
                  : routes.PGE_PLUS_EV_CHARGERS_SELECT_CHARGER,
              );
            }
          } else {
            void navigate(
              suppliedByCustomer
                ? routes.PGE_PLUS_EV_CHARGERS_INSTALLATION
                : routes.PGE_PLUS_EV_CHARGERS_SELECT_CHARGER,
            );
          }
        }),
      )}
      noValidate
    >
      <Fragment>
        <Question
          title
          detail={richText(content.get('REBATES_INELIGIBLE_MESSAGE'))}
        />
      </Fragment>
      <GridItem>
        <ProceedOrCancelButton back={false} />
      </GridItem>
    </form>
  );
};
