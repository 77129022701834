import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from '../../../hooks/useTranslation';
import Alerts from '../../alerts';

interface Props {
  message: string;
}

export default function IneligibleAlert(props: Props) {
  const { message } = props;
  const { t } = useTranslation();

  const assistance = t('AUTO_PAY_NOT_ENROLLED_ASSISTANCE');
  const assistanceHours = t('AUTO_PAY_NOT_ENROLLED_ASSISTANCE_HOURS');
  const assistancePhone = t('AUTO_PAY_NOT_ENROLLED_ASSISTANCE_NUMBER');

  return (
    <Alerts
      disableColorOverride={true}
      isOpen
      severity={'error'}
      variant={'outlined'}
      title={
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography>{message}</Typography>
          </Grid>
          <Grid item>
            <Typography>
              {assistance}{' '}
              {<a href={`tel:${assistancePhone}`}>{assistancePhone}</a>}{' '}
              {assistanceHours}
            </Typography>
          </Grid>
        </Grid>
      }
    />
  );
}
