import { useState } from 'react';
import { useTranslation } from './useTranslation';
import { PaginationProps } from '../components/pagination/Pagination';
import { PageSize } from '../components/pagination/types';

export default () => {
  const { t } = useTranslation();
  const isEnrollmentOpen = true;
  const isQuaterlyPeriod = false;
  const isAnnualPeriod = !isQuaterlyPeriod;
  const currentEnlectionWindowCloseAt = new Date('2021-01-01T00:00');
  const effectiveDate = new Date('2020-07-29T00:00');
  const isSamePersonAuthorizing = true;
  const accountGroups = [
    {
      labels: [{ primary: 'Clackamas County' }, { secondary: '20 Accounts' }],
      accounts: [
        {
          accountNumber: '0000000001',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: t('PLANS_ANNUAL_COST'),
        },
        {
          accountNumber: '0000000002',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: '',
        },
        {
          accountNumber: '0000000003',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: '',
        },
        {
          accountNumber: '0000000004',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: '',
        },
        {
          accountNumber: '0000000005',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: '',
        },
        {
          accountNumber: '0000000006',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: t('PLANS_ALTERNATE_PRICING'),
        },
        {
          accountNumber: '0000000007',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: '',
        },
        {
          accountNumber: '0000000008',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: t('PLANS_ALTERNATE_PRICING'),
        },
        {
          accountNumber: '0000000009',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: '',
        },
        {
          accountNumber: '0000000010',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: '',
        },
        {
          accountNumber: '0000000011',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: '',
        },
        {
          accountNumber: '0000000012',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: '',
        },
        {
          accountNumber: '0000000013',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: t('PLANS_ALTERNATE_PRICING'),
        },
        {
          accountNumber: '0000000014',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: t('PLANS_ALTERNATE_PRICING'),
        },
        {
          accountNumber: '0000000015',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: '',
        },
        {
          accountNumber: '0000000016',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: t('PLANS_ALTERNATE_PRICING'),
        },
        {
          accountNumber: '0000000017',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: t('PLANS_ALTERNATE_PRICING'),
        },
        {
          accountNumber: '0000000018',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: '',
        },
        {
          accountNumber: '0000000019',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: '',
        },
        {
          accountNumber: '0000000020',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: '',
        },
        {
          accountNumber: '0000000021',
          spid: 'abcda',
          serviceAddress: '12902 SW Hillside, Terrace Unit 209',
          plan: t('PLANS_ANNUAL_COST'),
          changedTo: '',
        },
      ] as any[],
    },
    {
      labels: [{ primary: 'Account Group 5' }, { secondary: '0 Accounts' }],
      accounts: [],
    },
  ];

  const availablePlans = [
    { id: 1, name: t('PLANS_ALTERNATE_PRICING') },
    { id: 2, name: t('PLANS_ANNUAL_COST') },
    { id: 3, name: t('CANCEL_PREVIOUS_REQUEST') },
  ];

  const selectedGroup = accountGroups[0];

  const pageSizes = [5, 10, 25];
  const page = 1;
  const pageSize = pageSizes[1];
  const start = (page - 1) * pageSize;
  const end = start + pageSize;

  const [records, setRecords] = useState(
    selectedGroup.accounts.slice(start, end),
  );

  const onPageChange = (newPage: number, newPageSize: PageSize) => {
    if (newPageSize === 'All') {
      setRecords(selectedGroup.accounts);
    } else {
      const newStart = (newPage - 1) * newPageSize;
      const newEnd = newStart + newPageSize;
      setRecords(selectedGroup.accounts.slice(newStart, newEnd));
    }
  };

  const paginationProps: PaginationProps = {
    count: selectedGroup.accounts.length,
    pageSize,
    page,
    pageSizes,
    onPageChange,
  };

  const changedAccounts = selectedGroup.accounts.filter(
    r => r.changedTo.length && r.changedTo !== r.plan,
  );

  const [summaryRecords, setSummaryRecords] = useState(
    changedAccounts.slice(start, end),
  );

  const onSummaryPageChange = (newPage: number, newPageSize: PageSize) => {
    if (newPageSize === 'All') {
      setRecords(changedAccounts);
    } else {
      const newStart = (newPage - 1) * newPageSize;
      const newEnd = newStart + newPageSize;
      setSummaryRecords(changedAccounts.slice(newStart, newEnd));
    }
  };

  const summaryPaginationProps: PaginationProps = {
    count: changedAccounts.length,
    pageSize,
    page,
    pageSizes,
    onPageChange: onSummaryPageChange,
  };

  const content = {
    next: t('NEXT'),
    back: t('BACK'),
    cancel: t('CANCEL'),
    done: t('DONE'),
    ok: t('OK'),
    dateTimeFormat: 'hh:mm:ss a, DD MMMM YYYY',
    dateFormat: 'DD MMMM YYYY',
    yearFormat: 'YYYY',
    electionPage: {
      // PENDING
      marketBasedPricinSiteUrl:
        'https://www.portlandgeneral.com/business/power-choices-pricing/market-based-pricing' ||
        t('MARKET_BASED_PRICING_SITE_URL'),
      marketBasedPricinSiteLabel: t('MARKET_BASED_PRICING_SITE_LABEL'),
      closed: {
        title: 'Open Enrollment' || t('OPEN_ENROLLMENT_ELECTION_CLOSED_TITLE'),
        subtitle:
          'The enrollment windows is currently closed' ||
          t('OPEN_ENROLLMENT_ELECTION_CLOSED_SUBTITLE'),
        text:
          'To learn about the next enrollment period, visit our <link>Market-based Pricing</link> page' ||
          t('OPEN_ELECTION_CLOSED_TEXT'),
      },
      open: {
        title: t('OPEN_ENROLLMENT_ELECTION_OPEN_TITLE'),
        note: t('OPEN_ENROLLMENT_ELECTION_OPEN_NOTE'),
        subtitle: t('OPEN_ENROLLMENT_ELECTION_OPEN_SUBTITLE'),
        text: t('OPEN_ENROLLMENT_ELECTION_OPEN_TEXT'),
        option1: t('OPEN_ENROLLMENT_ELECTION_OPEN_OPTION1'),
        option2: t('OPEN_ENROLLMENT_ELECTION_OPEN_OPTION2'),
        option3: t('OPEN_ENROLLMENT_ELECTION_OPEN_OPTION3'),
        ineligibleNote: t('OPEN_ENROLLMENT_ELECTION_OPEN_INELIGIBLE'),
        readFAQ: t('OPEN_ENROLLMENT_ELECTION_OPEN_READ_FAQ'),
        faqUrl:
          'https://www.portlandgeneral.com/business/power-choices-pricing/market-based-pricing/market-based-pricing-faq' ||
          t('OPEN_ENROLLMENT_FAQ_URL'),
        selectAccountGroupLabel: t(
          'OPEN_ENROLLMENT_ELECTION_OPEN_SELECT_ACCOUNT_TITLE',
        ),
        selectAccountGroupNote: t(
          'OPEN_ENROLLMENT_ELECTION_OPEN_SELECT_ACCOUNT_NOTE',
        ),
        createAccountGroupLabel: t(
          'OPEN_ENROLLMENT_ELECTION_OPEN_CREATE_ACCOUNT_TITLE',
        ),
        noServiceAddressesError: t(
          'OPEN_ENROLLMENT_ELECTION_OPEN_NO_SERVICE_ADDR_ERROR',
        ),
        operationsUrl:
          'https://www.portlandgeneral.com/business/power-choices-pricing/market-based-pricing/direct-access-operations' ||
          t('OPEN_ENROLLMENT_OPERATIONS_URL'),
        noSPIDError: t('OPEN_ENROLLMENT_NOSPID_ERROR'),
      },
    },
    contactPage: {
      title: t('OPEN_ENROLLMENT_FORM_TITLE'),
      contactInformation: {
        title: t('OPEN_ENROLLMENT_FORM_CONTACT_INFO_TITLE'),
        text: t('OPEN_ENROLLMENT_FORM_CONTACT_INFO_TEXT'),
      },
      contactAuthorization: {
        title: t('OPEN_ENROLLMENT_FORM_CONTACT_AUTH_TITLE'),
        text: t('OPEN_ENROLLMENT_FORM_CONTACT_AUTH_TEXT'),
        samePersonAuthorizing: t(
          'OPEN_ENROLLMENT_FORM_CONTACT_AUTH_SAME_AS_ABOVE',
        ),
      },
    },
    planPage: {
      title: t('OPEN_ENROLLMENT_PLAN_TITLE'),
      formTitle: t('OPEN_ENROLLMENT_PLAN_FORM_TITLE'),
      formText: t('OPEN_ENROLLMENT_PLAN_FORM_TEXT'),
    },
    confirmPage: {
      title: t('OPEN_ENROLLMENT_CONFIRM_TITLE'),
      summaryTitle: t('SUMMARY'),
      summaryText: t('GENERIC_CONFIRM_SUMMARY'),
      summaryNoChangeText: t('OPEN_ENROLLMENT_CONFIRM_SUMMARY_NO_CHANGE_TEXT'),
      summaryNoChangeNote: t('OPEN_ENROLLMENT_CONFIRM_SUMMARY_NO_CHANGE_NOTE'),
      confirm: t('OPEN_ENROLLMENT_CONFIRM_BUTTON'),
    },
    donePage: {
      title: t('OPEN_ENROLLMENT_DONE_TITLE'),
      doneTitle: t('OPEN_ENROLLMENT_DONE_TRANSACTION_SUCCESSFUL'),
      doneText: t('OPEN_ENROLLMENT_DONE_TEXT'),
      doneNote: t('OPEN_ENROLLMENT_DONE_NOTE'),
      doneDownload: t('OPEN_ENROLLMENT_DONE_DOWNLOAD'),
    },
  };

  return {
    isEnrollmentOpen,
    currentEnlectionWindowCloseAt,
    effectiveDate,
    isQuaterlyPeriod,
    isAnnualPeriod,
    isSamePersonAuthorizing,
    accountGroups,
    selectedGroup,
    availablePlans,
    paginationProps,
    records,
    summaryPaginationProps,
    summaryRecords,
    content,
  };
};
