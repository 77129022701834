import { useApolloClient } from '@apollo/react-hooks';
import gql from 'not-graphql-tag';
import {
  HistoricalUsageInput,
} from '../__generated__/pge-types';

const createDirectAccessHistoricalUsageMutation = gql`
  mutation createDirectAccessHistoricalUsage($payload: HistoricalUsageInput!) {
    createDirectAccessHistoricalUsage(payload: $payload) 
  }
`;

export function useDirectAccessUsageSubmit() {
  const apolloClient = useApolloClient();
  async function submit(payload: HistoricalUsageInput) {
    const result = await apolloClient.mutate<{
      createDirectAccessHistoricalUsage: boolean;
    }>({
      mutation: createDirectAccessHistoricalUsageMutation,
      variables: {
        payload,
      },
    });
    return result;
  }

  return { submit };
}
