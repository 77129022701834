import gql from 'not-graphql-tag';
import {
  GetApplicationInput,
  GetApplicationOutput,
  GetApplicationsInput,
  GetApplicationsOutput,
  QueryGetApplicationArgs,
  QueryGetApplicationsArgs,
} from '../../__generated__/pgeplus-types';
import {
  applicationEVFragment,
  applicationFragment,
  applicationSectionFragment,
} from '../../components/pge-plus-common/queries/fragments';

import { useApolloClient } from '@apollo/react-hooks';

export const quoteFragment = gql`
  fragment quoteFragment on Quote {
    ... on QuoteEV {
      id
      version
      jobId
      status
      total
      serviceTime {
        start
        end
      }
      createdAt
      updatedAt
      data {
        lineItems {
          ... on LineItemDetail {
            key
            name
            value
          }
          ... on LineItemCost {
            key
            name
            category
            amount
          }
          ... on LineItemProduct {
            key
            name
            value
            amount
          }
          ... on LineItemRebate {
            key
            name
            amount
            rebateItems {
              panelUpgrade
              evCharger
            }
          }
          ... on LineItemProgram {
            key
            name
            value
          }
        }
        confirmContractorRebatePayee
        confirmEVVehicleOwner
        acknowledgeTerms
      }
    }
  }
`;

export const jobFragment = gql`
  fragment jobFragment on Job {
    id
    serviceProviderId
    applicationId
    applicationNo
    jobType
    status
    createdAt
    updatedAt
    quote {
      ...quoteFragment
    }
    jobDetails {
      id
      jobId
      jobDate
      total
      comments
      createdAt
      updatedAt
      data {
        images {
          key
          name
          objectId
        }
      }
    }
    jobLocation {
      id
      jobId
      premiseId
      addressLine1
      city
      state
      postalCode
      firstName
      lastName
      middleName
      phone
      email
    }
    serviceProviderInfo {
      firstName
      lastName
      phone
    }
  }

  ${quoteFragment}
`;

export const getApplicationsByAccount = gql`
  query GetApplications($input: GetApplicationsInput!) {
    getApplications(input: $input) {
      applications {
        id
        accountId
        premiseId
        status
        statusReason
        applicationNo
        ...applicationFragment
        ... on ApplicationEV {
          job {
            ...jobFragment
          }
        }
        ... on ApplicationER {
          details {
            ...applicationSectionFragment
          }
        }
      }
    }
  }

  ${applicationFragment}
  ${jobFragment}
  ${applicationSectionFragment}
`;

export const getRichApplicationByApplicationNo = gql`
  query Query($input: GetApplicationInput!) {
    getApplication(input: $input) {
      application {
        ...applicationFragment
        ... on ApplicationEV {
          job {
            ...jobFragment
          }
        }
        ... on ApplicationER {
          details {
            ...applicationSectionFragment
          }
        }
      }
    }
  }

  ${applicationFragment}
  ${jobFragment}
  ${applicationSectionFragment}
`;

const useWelcomeBackApi = () => {
  const apolloClient = useApolloClient();

  const getApplicationsByAccountNo = async (
    input: GetApplicationsInput,
    query = getApplicationsByAccount,
  ) => {
    return apolloClient.query<
      { getApplications: GetApplicationsOutput },
      QueryGetApplicationsArgs
    >({
      query,
      variables: { input },
      context: { pgePlus: true },
      fetchPolicy: 'no-cache',
    });
  };

  //Must use 'applicationNo' as applicationId input
  const getApplicationByApplicationNo = async (input: GetApplicationInput) => {
    return await apolloClient.query<
      { getApplication: GetApplicationOutput },
      QueryGetApplicationArgs
    >({
      query: getRichApplicationByApplicationNo,
      variables: { input },
      context: { pgePlus: true },
      fetchPolicy: 'no-cache',
    });
  };

  return {
    getApplicationsByAccountNo, //Query
    getApplicationByApplicationNo, //Query
  };
};

export default useWelcomeBackApi;
