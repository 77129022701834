/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import {
  Box,
  Divider,
  FormHelperText,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';

import colors from '../../../../../themes/main-colors';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import CurrencyTextField from '../../../../currency-text-field';
import { ChargerInstallationCost } from '../rebateOnlyForm.types';
import useFormState from '../../../../../hooks/useFormState';
import { createChargerInstallationCostValidateFunction } from '../rebateOnlyForm.rules';
import { ProceedOrCancelButton } from '../../../../pge-plus-common/StepperForm';
import { SaveAndComeBackLink } from '../../../../pge-plus-common/SaveAndComeBackLink';
import routes from '../../../../../routes';
import useWrapWithLoader from '../../../../../hooks/useWrapWithLoading';
import { MultiFileUpload } from '../../../../pge-plus-common/multi-upload';
import { useRebateOnlyFormState } from '../../../../../providers/RebateOnlyFormStateProvider';
import {
  ApplicationErSectionType,
  SectionErRebateConfirm,
} from '../../../../../__generated__/pgeplus-types';
import { rebateConfirmERFormStateAdapter } from '../rebateOnlyFormState.adapter';
import useUpdateERSections from '../../../../../hooks/pge-plus/useUpdateERSections';
import useUtil from '../../../../../hooks/pge-plus/useUtil';

const ChargerAndInstallationCosts: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const theme = useTheme();

  const subText1 = {
    fontSize: 20,
    marginBottom: theme.spacing(2),
  };

  const boldText = {
    fontWeight: 700,
  };

  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_CHARGER_AND_INSTALLATION_COST,
  );

  const { wrapWithLoader } = useWrapWithLoader();
  const {
    formState: { application, rebateConfirm },
    getSectionData,
    updateFormState,
  } = useRebateOnlyFormState();

  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const chargerInstallation: ChargerInstallationCost = {
    installationCost: String(
      rebateConfirm.chargerInstallInfo?.installationCost || '',
    ),
    chargerCost: String(rebateConfirm.chargerInstallInfo?.chargerCost || ''),
    files: rebateConfirm.chargerInstallInfo?.receiptImages ?? undefined,
  };
  const { updateSectionRebateConfirm } = useUpdateERSections();
  const { setErrorNotification } = useUtil();

  const sectionDataRebateConfirm: SectionErRebateConfirm = getSectionData(
    ApplicationErSectionType.ErRebateConfirm,
  );

  const form = useFormState(chargerInstallation, {
    validate: createChargerInstallationCostValidateFunction(),
    validationContext: {
      content,
    },
  });

  const handleSubmit = wrapWithLoader(async (data: ChargerInstallationCost) => {
    try {
      const response = await updateSectionRebateConfirm(
        rebateConfirmERFormStateAdapter().fromFormState(
          {
            ...rebateConfirm,
            chargerInstallInfo: {
              ...rebateConfirm.chargerInstallInfo,
              installationCost: Number(data.installationCost) || 0,
              chargerCost: Number(data.chargerCost) || 0,
              receiptImages: data?.files,
            },
          },
          application?.id!,
          sectionDataRebateConfirm?.id,
        ),
      );

      if (!response.data?.updateSectionERRebateConfirm.section.errors?.length) {
        if (response.data?.updateSectionERRebateConfirm?.section.data) {
          const updatedValues = rebateConfirmERFormStateAdapter().toFormState(
            response.data?.updateSectionERRebateConfirm?.section.data,
          );
          updateFormState('rebateConfirm', {
            ...updatedValues,
          });
        }
        const rebateEligibiltiy2 =
          response.data?.updateSectionERRebateConfirm?.section?.data
            .preRebateEligibility2;
        void navigate(routes.PGE_PLUS_REBATE_ONLY_ELIGIBILITY_CONFIRMATION, {
          state: {
            message:
              rebateEligibiltiy2?.evRebate?.__typename ===
                'RebateSmartCharging' &&
              rebateEligibiltiy2?.evRebate.isHigherDiscount
                ? 'REBATE_ONLY_REBATE_RESULTS_AFTER_QPL_IQBD'
                : 'REBATE_ONLY_REBATE_RESULTS_AFTER_QPL_STANDARD',
            backButtonRoute:
              routes.PGE_PLUS_REBATE_ONLY_CHARGER_INSTALLATION_COSTS,
            nextButtonRoute: routes.PGE_PLUS_REBATE_ONLY_CONNECT_CHARGER,
          },
        });
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log('error', error);
      setErrorNotification(true);
    }
  });

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      const response = await updateSectionRebateConfirm({
        ...rebateConfirmERFormStateAdapter().fromFormState(
          {
            ...rebateConfirm,
            chargerInstallInfo: {
              ...rebateConfirm.chargerInstallInfo,
              installationCost: Number(form.values.installationCost) || 0,
              chargerCost: Number(form.values.chargerCost) || 0,
              receiptImages: form.values?.files,
            },
          },
          application?.id!,
          sectionDataRebateConfirm?.id,
        ),
        saveAndComeBack: true,
      });
      if (
        response?.errors ||
        response.data?.updateSectionERRebateConfirm?.validation?.errors ||
        response.data?.updateSectionERRebateConfirm?.section?.errors.length !==
          0
      ) {
        throw new Error();
      } else {
        void navigate(routes.ACCOUNT);
      }
    } catch {
      setErrorNotification(true);
    }
  });

  return (
    <Box>
      <form
        onSubmit={form.submit(values => handleSubmit(values), console.log)}
        noValidate
      >
        <Box
          css={{
            '& .MuiFormControl-root': {
              background: 'transparent',
            },
            '& .MuiInputBase-input': {
              background: '#FFF',
            },
          }}
        >
          <Typography css={{ ...subText1, ...boldText }}>
            {content.get('PLEASE_PROVIDE_CHARGER_INSTALLATION_COSTS_SUBHEADER')}
          </Typography>

          <Grid container spacing={2} css={{ justifyContent: 'space-between' }}>
            <Grid item xs={12} md={6}>
              <CurrencyTextField
                css={{
                  margin: 0,
                  width: '100%',
                  '& label': {
                    height: '20px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '90%',
                    '&.MuiInputLabel-shrink': {
                      overflow: 'unset',
                    },
                  },
                  marginTop: theme.spacing(3),
                }}
                label={content.get('CHARGER_COST') || ''}
                {...form.props('chargerCost')}
                error={Boolean(form.errors.chargerCost)}
                helperText={form.errors.chargerCost}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <CurrencyTextField
                css={{
                  margin: 0,
                  width: '100%',
                  '& label': {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '90%',
                    '&.MuiInputLabel-shrink': {
                      overflow: 'unset',
                    },
                  },
                  marginTop: theme.spacing(3),
                }}
                label={content.get('INSTALLATION_COST') || ''}
                {...form.props('installationCost')}
                error={Boolean(form.errors.installationCost)}
                helperText={form.errors.installationCost}
              />
            </Grid>
          </Grid>
          <Divider css={{ color: colors.gray93, margin: '20px 0 20px 0' }} />
          <Typography css={{ ...subText1, ...boldText }}>
            {content.get('PROVIDE_COPY_RECEIPTS_TITLE')}
          </Typography>
          <Typography css={{ ...subText1 }}>
            {content.get('UPLOAD_RECEIPTS_DETAILS')}
          </Typography>
          <MultiFileUpload
            form={form}
            formField={'files'}
            setIsFileUploading={setIsFileUploading}
          />
          {form.errors.files && (
            <FormHelperText error>{form.errors.files}</FormHelperText>
          )}
        </Box>

        <ProceedOrCancelButton
          back={routes.PGE_PLUS_REBATE_ONLY_CHARGER_INFORMATION}
          backLabel={content.get('BACK')}
          disable={
            isFileUploading ||
            !form.values.chargerCost ||
            !form.values.installationCost ||
            form.values.files === undefined ||
            form.values.files.length === 0
          }
        />
        <Box mt={6}>
          <SaveAndComeBackLink
            content={content}
            onSaveAndExit={() => handleSaveAndExit()}
          />
        </Box>
      </form>
    </Box>
  );
};

export default ChargerAndInstallationCosts;
