import React, { useEffect } from 'react';
import StopServiceForm from './StopServiceForm';
import ROUTES from '../../routes';
import Backdrop from '../../components/backdrop';
import { navigate } from '@reach/router';
import useAuthQuery from '../../hooks/useAuthQuery';
import gql from 'not-graphql-tag';
import { Model } from './types';

const STOP_SERVICE_ELIGIBILITY = gql`
  query stopServiceEligibility($encryptedAccountNumber: String!) {
    stopServiceEligibility(encryptedAccountNumber: $encryptedAccountNumber)
  }
`;

interface Props {
  path?: string;
  onSubmit: (data: Model) => void;
  data: Model | null;
  encryptedPersonId: string;
  encryptedAccountNumber: string;
}

export default function StopServiceEligibilityCheck({
  data,
  onSubmit,
  encryptedPersonId,
  encryptedAccountNumber,
}: Props) {
  const { data: responseData } = useAuthQuery<{
    stopServiceEligibility: boolean;
  }>(STOP_SERVICE_ELIGIBILITY, {
    variables: {
      encryptedAccountNumber,
    },
    skip: !encryptedAccountNumber,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (responseData && !responseData?.stopServiceEligibility) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.STOP_SERVICE_INELIGIBLE);
    }
  }, [responseData]);

  if (!responseData) {
    return <Backdrop forceOpen />;
  }

  return (
    <StopServiceForm
      data={data}
      onSubmit={onSubmit}
      encryptedPersonId={encryptedPersonId || ''}
      encryptedAccountNumber={encryptedAccountNumber || ''}
    />
  );
}
