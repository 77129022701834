import { convertValidationRules } from '../../../../hooks/useFormState';
import {
  validateEmail,
  validateExistsAndAlphabetCharacters,
  validatePhoneNumber,
  validateRequiredAndValidCharacters,
} from '../../../../util/form-validation';
import {
  ChargerInstallationCost,
  ConnectChargerToWifiType,
  ConnectTeslaToEVPulse,
  AccountInfo,
  ERVerifyRebate,
  EVPulseSmartChargingEnrollment,
  EligibleForEVPulse,
  QualifiedProductInfo,
  SmartCharging,
  RebateQualification,
  EvPulse,
  ChargerInstalledOnResidentialMeter,
  RebateConfirm,
  RebateConfirmIQBD,
  VerifyRebateEnrollmentConfirmation,
} from './rebateOnlyForm.types';

import { ErChargerInstallInfo } from '../../../../__generated__/pgeplus-types';

type AdditionalContext = {
  content: Map<string, string>;
  params?: any;
};

export enum EXISTING_REBATE_TYPE {
  SMART_CHARGING = 'SMART_CHARGING',
  EV_PULSE = 'EV_PULSE',
}

export const createAccountInfoValidateFunction = () =>
  convertValidationRules<AccountInfo, AdditionalContext>(context => {
    const { values, content } = context;

    return {
      isMainPerson: () =>
        values.isMainPerson === undefined
          ? `${content.get('ENTER_THE')} ` +
            content.get('MAIN_POINT_OF_CONTACT_ERR_MSG')
          : null,

      firstName:
        values.isMainPerson === false
          ? validateExistsAndAlphabetCharacters.bind(
              null,
              content.get('FIRST_NAME') || '',
            )
          : null,
      lastName:
        values.isMainPerson === false
          ? validateExistsAndAlphabetCharacters.bind(
              null,
              content.get('LAST_NAME') || '',
            )
          : null,
      phoneNumber: values.isMainPerson === false ? validatePhoneNumber : null,
      emailAddress: values.isMainPerson === false ? validateEmail : null,
      relationship:
        values.isMainPerson === false
          ? validateRequiredAndValidCharacters.bind(
              null,
              content.get('RELATIONSHIP_TO_USER') || '',
            )
          : null,
      homeType: validateRequiredAndValidCharacters.bind(
        null,
        content.get('HOME_TYPE_ERR_MESSAGE') || '',
      ),
    };
  });

export const evPulseEnrollmentValidateFunction = () =>
  convertValidationRules<EVPulseSmartChargingEnrollment, AdditionalContext>(
    context => {
      const { values, content } = context;

      return {
        doYouOwnTesla: () =>
          values.doYouOwnTesla === undefined
            ? content.get('FIELD_IS_REQUIRED')
            : null,
        doYouOwnL2Charger: () =>
          values.doYouOwnTesla && values.doYouOwnL2Charger === undefined
            ? content.get('FIELD_IS_REQUIRED')
            : null,
        vinImage: () =>
          values.doYouOwnL2Charger && !values.vinImage
            ? content.get('FIELD_IS_REQUIRED')
            : null,
        vin: () =>
          values.vinImage && !values.vin
            ? content.get('FIELD_IS_REQUIRED')
            : null,
      };
    },
  );

export const eligibleForEvPulseValidateFunction = () =>
  convertValidationRules<EvPulse, AdditionalContext>(context => {
    const { values, content } = context;

    return {
      vinImage: () =>
        !values.vinImage ? content.get('FIELD_IS_REQUIRED') : null,
      vin: () =>
        values.vinImage && !values.vin
          ? content.get('FIELD_IS_REQUIRED')
          : null,
    };
  });
export const uploadSerialChargerValidateFunction = () =>
  convertValidationRules<QualifiedProductInfo, AdditionalContext>(context => {
    const { values, content } = context;

    return {
      serialNumberImage: () =>
        !values.serialNumberImage ? content.get('FIELD_IS_REQUIRED') : null,
      serialNumber: () =>
        values.serialNumberImage && !values.serialNumber
          ? content.get('FIELD_IS_REQUIRED')
          : null,
    };
  });

export const uploadModelNumberValidateFunction = () =>
  convertValidationRules<SmartCharging, AdditionalContext>(context => {
    const { values, content } = context;

    return {
      modelNumberImage: () =>
        !values.modelNumberImage ? content.get('FIELD_IS_REQUIRED') : null,
      serialNumber: () =>
        values.modelNumberImage && !values.modelNumber
          ? content.get('FIELD_IS_REQUIRED')
          : null,
    };
  });

export const erVerifyRebateValidateFunction = () =>
  convertValidationRules<VerifyRebateEnrollmentConfirmation, AdditionalContext>(
    context => {
      const { values, content, params } = context;

      if (params?.rebateType === EXISTING_REBATE_TYPE.SMART_CHARGING) {
        return {
          willEnrollNewSmartChargingDevice: () =>
            values.willEnrollNewSmartChargingDevice === undefined
              ? content.get('FIELD_IS_REQUIRED')
              : null,
        };
      }

      if (params?.rebateType === EXISTING_REBATE_TYPE.EV_PULSE) {
        return {
          willEnrollNewSmartChargingDevice: () =>
            values.willEnrollNewSmartChargingDevice === undefined
              ? content.get('FIELD_IS_REQUIRED')
              : null,
          willEnrollNewVehicle: () =>
            values.willEnrollNewVehicle === false &&
            values.willEnrollNewVehicle === undefined
              ? content.get('FIELD_IS_REQUIRED')
              : null,
        };
      }

      return {};
    },
  );

export const connectTeslaToEVPulseValidateFunction = () =>
  convertValidationRules<ConnectTeslaToEVPulse, AdditionalContext>(context => {
    const { values, content } = context;

    return {
      isTeslaConnectedToEVPulse: () =>
        values.isTeslaConnectedToEVPulse === undefined
          ? content.get('FIELD_IS_REQUIRED')
          : null,
    };
  });

export const connectChargerToWifiValidateFunction = () =>
  convertValidationRules<RebateQualification, AdditionalContext>(context => {
    const { values, content } = context;

    return {
      wifiAvailable: () =>
        values.wifiAvailable === undefined || values.wifiAvailable === null
          ? content.get('FIELD_IS_REQUIRED')
          : null,
      doDriveTesla: () =>
        !values.wifiAvailable &&
        (values.doDriveTesla === undefined || values.doDriveTesla === null)
          ? content.get('FIELD_IS_REQUIRED')
          : null,
      haveChargerActivated: () =>
        values.wifiAvailable &&
        (values.haveChargerActivated === undefined ||
          values.haveChargerActivated === null)
          ? content.get('FIELD_IS_REQUIRED')
          : null,
    };
  });

export const chargerInstalledOnResidentialMeterValidateFunction = () =>
  convertValidationRules<ChargerInstalledOnResidentialMeter, AdditionalContext>(
    context => {
      const { values, content } = context;

      return {
        chargerInstalledOnResidentialMeter: () =>
          values.chargerInstalledOnResidentialMeter === undefined
            ? content.get('FIELD_IS_REQUIRED')
            : null,
      };
    },
  );

export const createChargerInstallationCostValidateFunction = () =>
  convertValidationRules<ChargerInstallationCost, AdditionalContext>(
    context => {
      const { values, content } = context;

      return {
        installationCost: () =>
          !values.installationCost ? content.get('FIELD_IS_REQUIRED') : null,
        chargerCost: () =>
          !values.chargerCost ? content.get('FIELD_IS_REQUIRED') : null,
        files: () =>
          !values.files || values.files.length === 0
            ? content.get('FIELD_IS_REQUIRED')
            : null,
      };
    },
  );

export const higherRabatesValidateFunction = () =>
  convertValidationRules<RebateConfirmIQBD, AdditionalContext>(context => {
    const { values, content } = context;

    return {
      checkEligibility: () =>
        values.checkEligibility === undefined ||
        values.checkEligibility === null
          ? content.get('FIELD_IS_REQUIRED')
          : null,
      houseHoldSize: () =>
        Boolean(values.checkEligibility)
          ? Boolean(values.houseHoldSize)
            ? null
            : content.get('FIELD_IS_REQUIRED')
          : null,
      grossIncome: () =>
        Boolean(values.checkEligibility)
          ? Boolean(values.grossIncome)
            ? null
            : content.get('FIELD_IS_REQUIRED')
          : null,
      grossIncomeConfirmed: () =>
        Boolean(values.checkEligibility)
          ? Boolean(values.grossIncomeConfirmed)
            ? null
            : content.get('FIELD_IS_REQUIRED')
          : null,
    };
  });

export const chargerInstallInfoValidateFunction = () =>
  convertValidationRules<ErChargerInstallInfo, AdditionalContext>(context => {
    const { values, content } = context;

    return {
      installationDate: () =>
        values.installationDate === undefined ||
        values.installationDate === null
          ? content.get('FIELD_IS_REQUIRED')
          : null,
      panelUpgraded: () =>
        values.panelUpgraded === undefined || values.panelUpgraded === null
          ? content.get('FIELD_IS_REQUIRED')
          : null,
    };
  });
