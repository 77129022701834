import { Router } from '@reach/router';
import React from 'react';
import ROUTES from '../../routes';
import CreateCustomGroup from './create-custom-group';
import HistoricalUsagePage from './historical-usage';
import ManageAccountGroupsPage from './manage-account-groups';
import ManageAccountsPage from './manage-accounts';
import ManageAlertsPage from './manage-alerts';
import OpenEnrollmentPage from './open-enrollment';
import RenewablePowerPage from './renewable-power';
import AccountSummaryPage from './summary';
import PrivateRoute from '../../components/routing/PrivateRoute';
import NotFoundPage from '../../pages/404';

export const AccountRouter = () => {
  return (
    <Router basepath="/">
      <OpenEnrollmentPage path={`${ROUTES.OPEN_ENROLLMENT}/*`} />
      <HistoricalUsagePage path={`${ROUTES.HISTORICAL_USAGE}/*`} />
      <ManageAccountsPage path={`${ROUTES.MANAGE_ACCOUNTS}/*`} />
      <ManageAccountGroupsPage path={`${ROUTES.MANAGE_ACCOUNT_GROUPS}/*`} />
      <RenewablePowerPage path={`${ROUTES.RENEWABLE_POWER}/*`} />
      <CreateCustomGroup path={ROUTES.CREATE_CUSTOM_GROUP} />
      <AccountSummaryPage path={ROUTES.ACCOUNT} />
      <ManageAlertsPage path={`${ROUTES.MANAGE_ALERTS}/*`} />
      <PrivateRoute path="/*" component={NotFoundPage} />
    </Router>
  );
};
