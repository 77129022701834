import React, { createContext, useReducer } from 'react';
import { ContactInfo } from '../components/contact-info-form/ContactInfoForm.types';

export interface DirectAccessContextState {
  isSamePersonAuthorizing: boolean;
  submittingContact?: ContactInfo;
  authorizingContact?: ContactInfo;
}

export enum DirectAccessContextActionType {
  SET_CONTACT_INFO = 'SET_CONTACT_INFO',
}

export type DirectAccessContextAction = {
  type: DirectAccessContextActionType.SET_CONTACT_INFO;
  submittingContact: ContactInfo;
  isSamePersonAuthorizing: boolean;
  authorizingContact?: ContactInfo;
};

interface DirectAccessContext {
  state: DirectAccessContextState;
  dispatch: (action: DirectAccessContextAction) => void;
}

const initialState: DirectAccessContext = {
  state: {
    isSamePersonAuthorizing: true,
    submittingContact: undefined,
    authorizingContact: undefined,
  },
  dispatch: () => {
    return;
  },
};

// eslint-disable-next-line no-redeclare
export const DirectAccessContext = createContext<DirectAccessContext>(
  initialState,
);

const { Provider } = DirectAccessContext;

const reducer = (
  state: DirectAccessContextState,
  action: DirectAccessContextAction,
): DirectAccessContextState => {
  switch (action.type) {
    case DirectAccessContextActionType.SET_CONTACT_INFO:
      return {
        ...state,
        isSamePersonAuthorizing: action.isSamePersonAuthorizing,
        submittingContact: action.submittingContact,
        authorizingContact: action.authorizingContact,
      };
    default:
      return state;
  }
};

export const DirectAccessContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState.state);
  return <Provider value={{ state, dispatch }}>{props.children}</Provider>;
};
