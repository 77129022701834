import React, { FunctionComponent, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useTranslation } from '../../hooks/useTranslation';

const ITEM_HEIGHT = 48;
const ITEM_NUMBER = 4;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * ITEM_NUMBER + ITEM_PADDING_TOP,
    },
  },
};
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      minWidth: 80,
    },
  }),
);

interface DaySelectorProps {
  value?: any;
  onChange?: (e: any) => void;
  className?: string;
  label?: string;
  disabled?: boolean;
}

const DaySelector: FunctionComponent<DaySelectorProps> = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = useState(props.value);
  const values = Array.from(Array(30).keys());

  if (props?.value && props?.value !== value) {
    setValue(props.value);
  }

  const onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newValue: number = event.target.value as number;
    setValue(newValue);
    props.onChange && props.onChange(newValue);
  };

  const customStyle = props.className ? props.className : '';
  const label = props.label ? props.label : t('DAY');
  return (
    <FormControl
      variant="outlined"
      className={`${classes.formControl} ${customStyle}`}
    >
      <InputLabel id="ignw-day-selector-label"> {label}</InputLabel>
      <Select
        labelId="ignw-day-selector"
        id="ignw-day-selector-select"
        value={value ? value : ''}
        onChange={onChange}
        label={label}
        MenuProps={MenuProps}
        disabled={!!props.disabled}
      >
        {values.map(v => (
          <MenuItem key={v} value={v + 1}>
            {v + 1}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DaySelector;
