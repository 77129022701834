import React, { FunctionComponent } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import {
  Grid,
  Typography,
  Paper,
  Card,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import PGEButton from '../../buttons';
import { BillMethodOptions } from '../PaperlessBillForm.types';
import ROUTES from '../../../routes';
import { navigate } from '@reach/router';
import Alerts from '../../alerts';

const useStyles = makeStyles(() =>
  createStyles({
    alertTitle: {
      fontWeight: 300,
      margin: '-10px 0 0',
      fontFamily: 'Forma-DJR-Display',
    },
    doneBtnHolder: {
      paddingTop: 10,
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  }),
);

export interface PaperlessDoneProps {
  path?: string;
  customerBillMethod: BillMethodOptions | null;
  customerEmail: string;
  customerAddress: string | null;
  onSubmit: () => void;
}

const PaperlessDone: FunctionComponent<PaperlessDoneProps> = props => {
  const { richT, t } = useTranslation();
  const classes = useStyles();

  // Handle user refreshing the page
  if (props.customerBillMethod === null) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(ROUTES.PAPERLESS_BILLING);
  }

  return (
    <Paper component={'main'}>
      <Card>
        <Alerts
          disableColorOverride={true}
          isOpen
          severity={'success'}
          variant={'outlined'}
          title={
            <h2 className={classes.alertTitle}>
              {props?.customerBillMethod === 'PAPERLESS'
                ? t('PAPERLESS_BILL_CONFIRMATION')
                : t('PAPER_BILL_CONFIRMATION')}
            </h2>
          }
          message={
            <>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography component="span">
                    {props?.customerBillMethod === 'PAPERLESS'
                      ? richT('CHANGE_BILL_PAPERLESS_CHANGED_INFO', {
                          CUSTOMER_EMAIL: props.customerEmail,
                          BILL_EMAIL: t('CUSTOMER_SERVICE_EMAIL'),
                        })
                      : richT('CHANGE_BILL_MAIL_CHANGED_INFO', {
                          CUSTOMER_ADDRESS_1: props.customerAddress
                            ? props.customerAddress.split(',')[0]
                            : '',
                          CUSTOMER_ADDRESS_2: props.customerAddress
                            ? props.customerAddress.split(',')[1]
                            : '',
                        })}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.doneBtnHolder}>
                <Grid>
                  <PGEButton
                    onClick={props.onSubmit}
                    type={'submit'}
                    variant="contained"
                    color="primary"
                    data-testid="change-password-button"
                  >
                    {t('DONE')}
                  </PGEButton>
                </Grid>
              </Grid>
            </>
          }
        />
      </Card>
    </Paper>
  );
};

export default PaperlessDone;
