import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import useFormState from '../../../../../hooks/useFormState';
import useSSMPostEnrollment from '../../../../../hooks/useSSMPostEnrollment';
import { useTranslation } from '../../../../../hooks/useTranslation';
import routes from '../../../../../routes';
import {
  OtherPeopleResponse,
  OtherPeopleRelType,
} from '../../../../../__generated__/pge-types';
import Backdrop from '../../../../backdrop';
import DatePicker from '../../../../date-picker';
import EmailTextField from '../../../../email-text-field';
import PhoneTextField from '../../../../phone-text-field';
import ProceedOrCancel from '../../../../proceed-or-cancel';
import TextField from '../../../../text-field';
import { SSMAuthUserInfoFormModel } from '../../../ssm.types';
import { createAuthUserInfoValidateFunction } from './AddAuthorizedPartyForm.rules';
import moment from 'moment-timezone';
import { displayPhoneNumberFormat } from '../../../../../util/format';

const CALENDAR_INITIAL_DATE_SELECTION = '01/01/1990';
const DATE_FORMAT = 'MM/dd/yyyy';

const initialDateSelection = moment(CALENDAR_INITIAL_DATE_SELECTION).toDate();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop0: {
      marginTop: theme.spacing(0),
    },
    marginTop: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    errorMessage: {
      margin: '0 14px',
    },
  }),
);

type Props = {
  usersList?: OtherPeopleResponse[];
  refetchCustomerList: () => void;
  onClose: () => void;
  showCoCustomerErrorModal: () => void;
};

const AddAuthorizedPartyForm = ({
  onClose,
  usersList,
  refetchCustomerList,
  showCoCustomerErrorModal,
}: Props) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    ssmPostEnrollmentState,
    submitAuthorizedUser,
    setNotificationMessage,
  } = useSSMPostEnrollment();

  const formState = useFormState(ssmPostEnrollmentState.authorizedUsersInfo!, {
    validate: createAuthUserInfoValidateFunction(),
  });

  const handleSubmit = async (contactInfo: SSMAuthUserInfoFormModel) => {
    try {
      const authUserFullName = `${contactInfo.firstName.toLocaleLowerCase()} ${contactInfo.middleName.toLocaleLowerCase()} ${contactInfo.lastName.toLocaleLowerCase()}`;
      //If an existing co-customer show error popup
      if (isAuthUserACoCustomer(authUserFullName)) {
        showCoCustomerErrorModal();
        onClose();
        return false;
      }

      //If an existing auth user, close the popup without adding this auth user
      if (isAuthUserExists(authUserFullName)) {
        onClose();
        return false;
      }

      setLoading(true);
      await submitAuthorizedUser({
        ...contactInfo,
        firstName: contactInfo?.firstName?.trim(),
        middleName: contactInfo?.middleName?.trim(),
        lastName: contactInfo?.lastName?.trim(),
        primaryPhone: displayPhoneNumberFormat(contactInfo.primaryPhone),
      });
      setLoading(false);
      setNotificationMessage(t('SSM_ADD_AUTH_USER_SUCCESS'));
      refetchCustomerList();
      onClose();
    } catch (e) {
      setLoading(false);
      setNotificationMessage(
        t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
        'error',
      );
      onClose();
    }
  };

  const isAuthUserACoCustomer = (authUserFullName: string) => {
    if (!usersList) {
      return false;
    }

    const coCustomersList = usersList
      ?.filter(
        (user: OtherPeopleResponse) =>
          user.acctRelType === OtherPeopleRelType.Coapp,
      )
      .map(
        user =>
          `${user.firstName?.toLocaleLowerCase()} ${user.middleName?.toLocaleLowerCase()} ${user?.lastName?.toLocaleLowerCase()}`,
      );

    return coCustomersList?.indexOf(authUserFullName) >= 0;
  };

  const isAuthUserExists = (authUserFullName: string) => {
    if (!usersList) {
      return false;
    }

    const existingAuthUsersList = usersList
      ?.filter(
        (user: OtherPeopleResponse) =>
          user.acctRelType === OtherPeopleRelType.Authusr,
      )
      .map(
        user =>
          `${user.firstName?.toLocaleLowerCase()} ${user.middleName?.toLocaleLowerCase()} ${user.lastName?.toLocaleLowerCase()}`,
      );

    return existingAuthUsersList?.indexOf(authUserFullName) >= 0;
  };

  return (
    <>
      {loading && <Backdrop forceOpen />}
      <Grid item container justify="center" spacing={4}>
        <Box mb={4}>
          <Typography variant={'h2'}>
            {t('SSM_POST_ADD_AUTH_USERS_CARD_TITLE')}
          </Typography>
        </Box>
      </Grid>

      <Grid item container>
        <Typography variant={'body1'} component={'div'} align="center">
          {t('SSM_POST_ADD_AUTH_USERS_DESC')}
        </Typography>
      </Grid>
      <Grid item container spacing={2} className={classes.marginTop}>
        <Grid item xs={12}>
          <TextField
            name={'firstName'}
            data-testid="firstName"
            label={t('FIRST_NAME')}
            inputProps={{
              minLength: 1,
              maxLength: 60,
              'data-testid': 'test-firstname',
            }}
            style={{ width: '100%' }}
            {...formState.props('firstName')}
            error={Boolean(formState.errors.firstName)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name={'middleName'}
            data-testid="middleName"
            label={t('MIDDLE_NAME_OR_INITIAL')}
            inputProps={{ minLength: 1, maxLength: 60 }}
            style={{ width: '100%' }}
            {...formState.props('middleName')}
            error={Boolean(formState.errors.middleName)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name={'lastName'}
            data-testid="lastName"
            label={t('LAST_NAME')}
            inputProps={{ minLength: 1, maxLength: 60 }}
            style={{ width: '100%' }}
            {...formState.props('lastName')}
            error={Boolean(formState.errors.lastName)}
          />
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            style={{ width: '100%' }}
            label={t('DATE_OF_BIRTH')}
            name="birthDate"
            format={DATE_FORMAT}
            className={classes.marginTop0}
            initialFocusedDate={initialDateSelection}
            {...formState.props('birthDate')}
            mask={'__/__/____'}
          />
        </Grid>

        <Grid item xs={12}>
          <EmailTextField
            name={'email'}
            data-testid="email"
            label={t('EMAIL')}
            inputProps={{ minLength: 1, maxLength: 60 }}
            style={{ width: '100%' }}
            className={classes.marginTop0}
            {...formState.props('email')}
            error={Boolean(formState.errors.email)}
          />
        </Grid>

        <Grid item xs={12}>
          <PhoneTextField
            name="primaryPhone"
            data-testid="primaryPhone"
            label={t('PRIMARY_PHONE')}
            {...formState.props('primaryPhone')}
            className={classes.marginTop0}
          />
        </Grid>

        <Grid item container className={classes.marginTop}>
          <ProceedOrCancel
            cancelRoute={routes.SSM_POST_ENROLLMENT_ADD_CO_CUSTOMER}
            cancelHandler={onClose}
            cancelVariant={'Button'}
            proceedHandler={formState.submit(
              async values => await handleSubmit(values),
              console.log,
            )}
            proceedLabel={t('ADD')}
            cancelLabel={t('CANCEL')}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddAuthorizedPartyForm;
