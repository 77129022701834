import { useStaticQuery, graphql } from 'gatsby';
import { Query } from '../../__generated__/gatsby-types';

export const accountDashboardInterceptModalQuery = graphql`
  {
    allContentfulSecureSiteModal(
      filter: { entryName: { regex: "/^((?!placeholder).)*$/i" } }
    ) {
      nodes {
        contentful_id
        customerClass
        node_locale
        pages {
          contentful_id
          headerImage {
            file {
              url
            }
          }
          headerText
          bodyCopy {
            raw
          }
          videoEmbedUrl
        }
      }
    }
  }
`;

export default () => {
  const { allContentfulSecureSiteModal } = useStaticQuery<Query>(
    accountDashboardInterceptModalQuery,
  );

  return {
    allContentfulSecureSiteModal,
  };
};
