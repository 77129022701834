import { useState, useContext } from 'react';
import {
  PostEnrollmentPaymentPreferences,
  PostEnrollmentNotificationSettings,
  PostEnrollmentEnrollInPrograms,
  PostEnrollmentCoCustomer,
  SSMCoCustomerInfoFormModel,
  SSMCoCustomerVerificationFormModel,
  SSMAuthUserInfoFormModel,
} from '../components/ssm/ssm.types';
import { getAccountDetailsQuery } from '../queries/autoPay.query';
import {
  AccountDetail,
  ApplicantResponse,
  ApplicantWithEligibilityResponse,
  DeliveryType,
  DeliveryTypeInput,
  NotificationOption,
  NotificationType,
  NotificationTypeInput,
  PersonPrimaryIdType,
  ValidatePersonResponse,
  UpdateAlrtDetailsRequest,
} from '../__generated__/pge-types';
import useAuthQuery from './useAuthQuery';
import useSelectedAccountParams from './useSelectedAccountParams';
import { NotificationsContext } from '../providers/NotificationsProvider';
import { createAuthUserPayload } from '../components/ssm/post-enrollment/CoCustomer/AddAuthorizedParty/AddAuthorizedPartyForm.rules';
import gql from 'not-graphql-tag';
import { useApolloClient } from '@apollo/react-hooks';
import {
  addAuthorizedUserMutation,
  addCoCustomerMutation,
} from '../components/ssm/post-enrollment/queries';
import { createCoCustomerPayload } from '../components/ssm/post-enrollment/CoCustomer/AddCoCustomer/AddCoCustomerForm.rules';
import useAccountCustomer from './useAccountCustomer';
import { allAlertTypes } from '../components/account-summary/manage-alerts/utils';
import { compact } from 'lodash';
import { useSSMNotificationSubmit } from './useSSMNotificationSubmit';
import { displayPhoneNumberFormat } from '../util/format';

export type SSMPostEnrollmentServiceState = {
  paymentPreferences: PostEnrollmentPaymentPreferences;
  notificationSettings: PostEnrollmentNotificationSettings;
  enrollInPrograms: PostEnrollmentEnrollInPrograms;
  coCustomers: PostEnrollmentCoCustomer;
  coCustomersContactInfo: SSMCoCustomerInfoFormModel;
  coCustomersVerifyInformation: SSMCoCustomerVerificationFormModel;
  authorizedUsersInfo?: SSMAuthUserInfoFormModel;
};

export const CoCustomerInfoFormValues: SSMCoCustomerInfoFormModel = {
  firstName: '',
  middleName: '',
  lastName: '',
  relationship: '',

  email: '',
  primaryPhone: '',
  useForMobile: false,
  mobilePhone: '',
  alternatePhone: '',
};

export const CoCustomerVerificationFormValues: SSMCoCustomerVerificationFormModel = {
  birthdate: '',
  idType: PersonPrimaryIdType.Ssn,
  idState: '',
  idValue: '',
};

export const AuthUsersInfoFormValues: SSMAuthUserInfoFormModel = {
  firstName: '',
  middleName: '',
  lastName: '',
  birthDate: '',
  email: '',
  primaryPhone: '',
};

export default () => {
  const { submit } = useSSMNotificationSubmit();

  const [ssmPostEnrollmentState, setSSMPostEnrollmentState] = useState<
    SSMPostEnrollmentServiceState
  >({
    paymentPreferences: {},
    notificationSettings: {},
    enrollInPrograms: {},
    coCustomers: {},
    coCustomersContactInfo: CoCustomerInfoFormValues,
    coCustomersVerifyInformation: CoCustomerVerificationFormValues,
    authorizedUsersInfo: AuthUsersInfoFormValues,
  });

  const { accountParams } = useSelectedAccountParams();
  const { customer } = useAccountCustomer();

  const notificationContext = useContext(NotificationsContext);
  const apolloClient = useApolloClient();

  const {
    loading: accountLoading,
    data: accountData,
    refetch: refetchAccount,
  } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetailsQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
  });

  const accountDetails = accountData?.getAccountDetails?.[0];

  const setNotificationMessage = (
    message: string | React.ReactNode = '',
    severity: 'success' | 'error' = 'success',
  ) => {
    notificationContext.setState({
      isOpen: true,
      message,
      severity,
    });
    window.scrollTo(0, 0);
  };

  const setNotificationSettings = async (
    notificationSettings: PostEnrollmentNotificationSettings,
    encryptedContactId? : string,
    originalPhoneNumber?: string
  ) => {
    setSSMPostEnrollmentState(state => ({
      ...state,
      notificationSettings,
    }));


    const updatedAlertArray = compact(
      allAlertTypes.map(typ => {
        const state =
          notificationSettings?.notificationTypes &&
          notificationSettings?.notificationTypes[typ];
        const originalAlert = notificationSettings?.originalAlerts?.find(
          alrt => alrt?.notificationType === typ,
        );

        if (!originalAlert) {
          return undefined;
        }

        if (
          notificationSettings?.oldAlertState &&
          notificationSettings?.oldAlertState[typ]?.email !== state?.email &&
          notificationSettings?.oldAlertState[typ]?.text !== state?.text
        ) {
          return {
            notificationType: originalAlert.notificationType,
            notificationOptions:
              originalAlert.notificationType === NotificationType.Webexc
                ? getUpdatedThreshold(
                    originalAlert?.notificationOptions!,
                    notificationSettings.exceedThreshold!,
                  )
                : originalAlert.notificationType === NotificationType.Webpyrem
                ? getUpdatedThreshold(
                    originalAlert?.notificationOptions!,
                    originalAlert?.notificationOptions?.value!,
                  )
                : null,
            deliveryTypes: originalAlert?.deliveryTypeDetails?.map(
              deliveryTypeDetail => {
                const personContacts = compact(
                  deliveryTypeDetail?.contactDetails?.map(contact => {
                    if (
                      contact?.value ===
                        displayPhoneNumberFormat(originalPhoneNumber) &&
                      deliveryTypeDetail.deliveryType === 'SMS'
                    ) {
                      return {
                        encryptedContactId: encryptedContactId ? encryptedContactId : contact?.encryptedContactId,
                        encryptedPreferenceId: contact?.encryptedPreferenceId
                          ? contact?.encryptedPreferenceId
                          : null,
                        value: encryptedContactId
                          ? notificationSettings?.phone
                          : contact?.value,
                        isSelected: state?.text,
                      };
                    } else if (
                      deliveryTypeDetail.deliveryType === DeliveryType.Email
                    ) {
                      return {
                        encryptedContactId: contact?.encryptedContactId,
                        encryptedPreferenceId: contact?.encryptedPreferenceId
                          ? contact?.encryptedPreferenceId
                          : null,
                        value: contact?.value,
                        isSelected: state?.email,
                      };
                    }
                  }),
                );

                return {
                  deliveryType: deliveryTypeDetail?.deliveryType,
                  contacts: personContacts,
                } as DeliveryTypeInput;
              },
            ),
          };
        } else if (
          notificationSettings?.oldAlertState &&
          notificationSettings?.oldAlertState[typ]?.email !== state?.email
        ) {
          return {
            notificationType: originalAlert.notificationType,
            notificationOptions:
              originalAlert.notificationType === NotificationType.Webexc
                ? getUpdatedThreshold(
                    originalAlert?.notificationOptions!,
                    notificationSettings.exceedThreshold!,
                  )
                : originalAlert.notificationType === NotificationType.Webpyrem
                ? getUpdatedThreshold(
                    originalAlert?.notificationOptions!,
                    originalAlert?.notificationOptions?.value!,
                  )
                : null,
            deliveryTypes: compact(
              originalAlert?.deliveryTypeDetails?.map(delType => {
                if (delType?.deliveryType === DeliveryType.Email) {
                  const personContacts = delType?.contactDetails?.map(
                    contact => {
                      return {
                        encryptedContactId: contact?.encryptedContactId,
                        encryptedPreferenceId: contact?.encryptedPreferenceId
                          ? contact?.encryptedPreferenceId
                          : null,
                        isSelected: state?.email,
                        value: contact?.value,
                      };
                    },
                  );
                  return {
                    deliveryType: delType?.deliveryType,
                    contacts: personContacts,
                  } as DeliveryTypeInput;
                }
              }),
            ),
          };
        } else if (
          notificationSettings?.oldAlertState &&
          notificationSettings?.oldAlertState[typ].text !== state?.text
        ) {
          return {
            notificationType: originalAlert.notificationType,
            notificationOptions:
              originalAlert.notificationType === NotificationType.Webexc
                ? getUpdatedThreshold(
                    originalAlert?.notificationOptions!,
                    notificationSettings.exceedThreshold!,
                  )
                : originalAlert.notificationType === NotificationType.Webpyrem
                ? getUpdatedThreshold(
                    originalAlert?.notificationOptions!,
                    originalAlert?.notificationOptions?.value!,
                  )
                : null,
            deliveryTypes: compact(
              originalAlert?.deliveryTypeDetails?.map(delType => {
                if (delType?.deliveryType === DeliveryType.Sms) {
                  const personContacts = compact(
                    delType?.contactDetails?.map(contact => {
                      if (
                        contact?.value ===
                          displayPhoneNumberFormat(
                            originalPhoneNumber
                          ) &&
                        delType.deliveryType === 'SMS'
                      ) {
                        return {
                          encryptedContactId: encryptedContactId ? encryptedContactId : contact?.encryptedContactId,
                          encryptedPreferenceId: contact?.encryptedPreferenceId
                            ? contact?.encryptedPreferenceId
                            : null,
                          value: encryptedContactId
                            ? notificationSettings?.phone
                            : contact?.value,
                          isSelected: state?.text,
                        };
                      }
                    }),
                  );
                  return {
                    deliveryType: delType.deliveryType,
                    contacts: personContacts,
                  } as DeliveryTypeInput;
                }
              }),
            ),
          };
        }
      }),
    ) as NotificationTypeInput[];
    const alertPayload: UpdateAlrtDetailsRequest = {
      notificationTypes: updatedAlertArray,
      accountNumber: accountParams?.accountNumber!,
      encryptedPersonId: accountParams?.encryptedPersonId!,
      prefLanguage: notificationSettings?.prefLanguage,
    };
    const result = await submit(alertPayload);
    return result;
  };

  const setCoCustomerContactInformation = (
    coCustomersContactInfo: SSMCoCustomerInfoFormModel,
  ) => {
    setSSMPostEnrollmentState(state => ({
      ...state,
      coCustomersContactInfo,
    }));
  };

  const setCoCustomerVerifyInformation = (
    coCustomersVerifyInformation: SSMCoCustomerVerificationFormModel,
  ) => {
    return setSSMPostEnrollmentState(state => ({
      ...state,
      coCustomersVerifyInformation,
    }));
  };

  const submitCoCustomer = async (
    customerInfo: SSMCoCustomerInfoFormModel &
      SSMCoCustomerVerificationFormModel,
    validatePersonResponse: ValidatePersonResponse | undefined,
  ) => {
    const payload = createCoCustomerPayload({
      customerContactInfo: customerInfo,
      accountNumber: accountDetails?.accountNumber!,
      mainPerId: customer?.personId,
      personId: validatePersonResponse?.perId,
    });

    const { data } = await apolloClient.mutate<{
      addCoApplicant: ApplicantWithEligibilityResponse;
    }>({
      mutation: addCoCustomerMutation,
      variables: {
        payload,
      },
    });
    return data;
  };

  const submitAuthorizedUser = async (
    customerInfo: SSMAuthUserInfoFormModel,
  ) => {
    const payload = createAuthUserPayload(
      customerInfo,
      accountDetails?.accountNumber!,
    );

    //Check if user is a co-customer throw error.
    const { data } = await apolloClient.mutate<{
      addCoCustomer: ApplicantResponse;
    }>({
      mutation: addAuthorizedUserMutation,
      variables: {
        payload,
      },
    });

    return data;
  };

  function getUpdatedThreshold(
    commPreferencesOption: NotificationOption | undefined,
    exceedThreshold: string,
  ) {
    if (exceedThreshold !== commPreferencesOption?.value) {
      return {
        sequence: commPreferencesOption?.sequence
          ? commPreferencesOption?.sequence
          : '1',
        value: exceedThreshold,
      };
    } else {
      return {
        sequence: commPreferencesOption?.sequence
          ? commPreferencesOption?.sequence
          : '1',
        value: commPreferencesOption?.value
          ? commPreferencesOption?.value
          : '0',
      };
    }
  }

  return {
    ssmPostEnrollmentState,
    setNotificationMessage,
    accountLoading,
    account: accountDetails,
    refetchAccount,
    submitAuthorizedUser,
    setCoCustomerContactInformation,
    setCoCustomerVerifyInformation,
    submitCoCustomer,
    setNotificationSettings,
  };
};
