import React, { FunctionComponent, useEffect } from 'react';
import { navigate } from '@reach/router';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '../../../hooks/useTranslation';
import { PeakTimeRebatesHistoryWidget } from '../../OpowerWidgets';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';
import { CardHeader, Button } from '@material-ui/core';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import useAuthQuery from '../../../hooks/useAuthQuery';
import { getPgeEnergyTrackerData } from '../../account-summary/single-account/queries';
import { AccountDetail } from '../../../__generated__/pge-types';
import SpinnerCard from '../../spinner/SpinnerCard';
import routes from '../../../routes';
import { useIsMobile } from '../../../util/style-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    compare: {
      color: colors.noirBlur,
      marginLeft: '.5em',
    },
    arrow: {
      color: theme.palette.success.main,
    },
  }),
);
const onDone = () => {
  return navigate(routes.ACCOUNT);
};
interface PeakTimeRebatesHistoryProps {
  path?: string;
}

const PeakTimeRebatesHistory: FunctionComponent<PeakTimeRebatesHistoryProps> = props => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  navigate(routes.ERROR);
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const {
    encryptedAccountNumber,
    encryptedPersonId,
    accountNumber,
  } = useSelectedAccountParams();

  const { loading: eTLoading, data: etData } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getPgeEnergyTrackerData, {
    variables: {
      params: {
        accountNumberList: [
          {
            accountNumber: accountNumber,
            encryptedPersonId: encryptedPersonId,
          },
        ],
      },
    },
    skip: !accountNumber || !encryptedPersonId,
  });
  const energyTrackerLoading = eTLoading || !etData;
  const pgeEnergyTrackerData = etData?.getAccountDetails[0]?.pgeEnergyTracker;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Typography
          className={classes.colorTextPrimary}
          component={'span'}
          variant={'h2'}
        >
          {richT('VIEW_PTR_HISTORY')}
        </Typography>
      </Grid>
      <Grid item>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={12}>
            <Card>
              <CardHeader
                title={t('PTR_USE_THIS_CHART')}
                titleTypographyProps={{
                  variant: 'h2',
                  className: classes.compare,
                }}
              />
              <CardContent>
                {energyTrackerLoading ? (
                  <Grid item xs={12}>
                    <SpinnerCard />
                  </Grid>
                ) : (
                  <PeakTimeRebatesHistoryWidget
                    entityId={
                      pgeEnergyTrackerData?.energyTrackerInfo?.identifiers?.[0]
                    }
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item justify={isMobile ? 'center' : 'flex-end'}>
        <Grid item xs={10} md="auto">
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={onDone}
          >
            {t('DONE')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PeakTimeRebatesHistory;
