/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { useState, Fragment } from 'react';
import { Box, Divider, Button, FormHelperText, Grid } from '@material-ui/core';
import { navigate } from 'gatsby';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import routes from '../../../routes';
import colors from '../../../themes/main-colors';
import { UploadPhotos as UploadPhotosFormTypes } from '../evcharger.types';

import useContentMgmt from '../../../hooks/useContentMgmt';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';
import useFormState from '../../../hooks/useFormState';
import { createSubmitPhotosValidateFunction } from '../../pge-plus-common/PGEPlusForm.rules';

import RadioButtonGroup from '../../pge-plus-common/RadioButtonGroup';
import { Question } from '../../pge-plus-common/Typography';
import { DropdownMenu } from '../../pge-plus-common/DropdownMenu';
import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import { photoUploadList } from './photoUploadList';
import { FileUploader } from '../../pge-plus-common/FileUploader';
import Modal from '../../pge-plus-common/Modal';
import {
  MainBreakerAmperage,
  DoubleBreakerAvailable,
  ApplicationEvSectionType,
  SectionEvPanelInfo,
} from '../../../__generated__/pgeplus-types';
import useUpdateEVSections from '../../../hooks/pge-plus/useUpdateEVSections';
import { uploadPhotosFormStateAdapter } from '../../pge-plus-common/utils/pgeplusFormstateAdapters';
import { SaveAndComeBackLink } from '../../pge-plus-common/SaveAndComeBackLink';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import useUtil from '../../../hooks/pge-plus/useUtil';
import { ContinueFromOtherDeviceButton } from '../../pge-plus-common/ContinueFromOtherDeviceButton';

interface UploadPhotosProps {
  children?: React.ReactNode;
  path: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageTitle: {
      fontSize: '16px !important',
      padding: '0px !important',
      color: `${colors.sparkBlue} !important`,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: 'unset !important',
      },
    },
  }),
);

export const UploadPhotos: React.FC<UploadPhotosProps> = props => {
  const {
    formState: { uploadPhotos, selectCharger, application },
    updateFormState,
    getSectionData,
  } = useEVChargerFormState();
  const sectionDataUploadPhotos: SectionEvPanelInfo = getSectionData(
    ApplicationEvSectionType.EvPanelInfo,
  );
  const classes = useStyles(props);
  const { content } = useContentMgmt(microcopyGroupIds.PGE_PLUS_UPLOAD_PHOTOS);

  const form = useFormState(uploadPhotos, {
    validate: createSubmitPhotosValidateFunction(),
    validationContext: {
      content,
    },
  });

  const { updateSectionEVPanelInfo } = useUpdateEVSections();
  const { setErrorNotification } = useUtil();

  const [openExamplePhotoModal, setOpenExamplePhotoModal] = useState<
    string | null
  >(null);
  const { wrapWithLoader } = useWrapWithLoader();

  const MainBreakerAmperageOptions = [
    { label: content.get('IM_NOT_SURE'), value: MainBreakerAmperage.Unknown },
    { label: content.get('200_AMPS'), value: MainBreakerAmperage.Amps_200 },
    { label: content.get('150_AMPS'), value: MainBreakerAmperage.Amps_150 },
    { label: content.get('125_AMPS'), value: MainBreakerAmperage.Amps_125 },
    { label: content.get('100_AMPS'), value: MainBreakerAmperage.Amps_100 },
    {
      label: content.get('NO_MAIN_BREAKER'),
      value: MainBreakerAmperage.NoMainBreaker,
    },
  ];

  const doubleBreakerOptions = [
    {
      label: content.get('IM_NOT_SURE'),
      value: DoubleBreakerAvailable.Unknown,
    },
    { label: content.get('REUSABLE_YES'), value: DoubleBreakerAvailable.Yes },
    { label: content.get('REUSABLE_NO'), value: DoubleBreakerAvailable.No },
  ];

  const onSubmit = wrapWithLoader(async (data: UploadPhotosFormTypes) => {
    try {
      const response = await updateSectionEVPanelInfo(
        uploadPhotosFormStateAdapter().fromFormState(
          data,
          application?.id!,
          sectionDataUploadPhotos?.id,
        ),
      );

      if (response.data?.updateSectionEVPanelInfo.validation?.success) {
        //Store the data in global store
        updateFormState('uploadPhotos', data);

        //Navigate to the next screen
        if (selectCharger.suppliedByCustomer) {
          void navigate(
            routes.PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS_CHARGER_RECIEPT,
          );
        } else {
          void navigate(routes.PGE_PLUS_EV_CHARGERS_SUMMARY);
        }
      } else {
        //TODO: Handle the validation error messages
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      await updateSectionEVPanelInfo(
        uploadPhotosFormStateAdapter().fromFormState(
          uploadPhotos,
          application?.id!,
          sectionDataUploadPhotos?.id,
          true,
        ),
      );
      void navigate(routes.ACCOUNT);
    } catch (e) {
      setErrorNotification(true);
    }
  });

  return (
    <Fragment>
      <form
        onSubmit={form.submit(values => onSubmit(values), console.log)}
        noValidate
      >
        <Box
          display="flex"
          flexDirection="column"
          css={{
            maxWidth: '790px',
            margin: 'auto',
            justifyContent: 'center',
          }}
        >
          <Question
            title={content.get('UPLOAD_PHOTOS_TITLE')}
            detail={content.get('UPLOAD_PHOTOS_INFO')}
          />

          {/* Upload Section */}
          <Box>
            {photoUploadList({ content }).map((photo, index) => (
              <Fragment>
                <Question title={photo.title} detail={photo.info} />

                <Box mt={3}>
                  <Button
                    className={classes.imageTitle}
                    onClick={() => setOpenExamplePhotoModal(`modal-${index}`)}
                  >
                    {photo.examplePhotoLink}
                  </Button>
                  <Modal
                    open={openExamplePhotoModal === `modal-${index}`}
                    onClose={() => setOpenExamplePhotoModal(null)}
                    title={`${photo.title}`}
                    showCloseIcon={true}
                  >
                    {' '}
                    <Box mt={3}>
                      <img
                        css={{
                          width: '100%',
                          maxHeight: '250px',
                        }}
                        src={photo.img}
                      />
                    </Box>
                  </Modal>
                </Box>
                {/* drag and drop box*/}
                <Box mt={2}>
                  <FileUploader
                    image={form.values[photo.formKey] as string}
                    placeholder={content.get('UPLOAD_A_PHOTO')}
                    reuploadLabel={content.get('REUPLOAD_PHOTO')!}
                    onUpload={key => form.setValue(photo.formKey, key)}
                  />
                  {form.errors[photo.formKey] && (
                    <FormHelperText error>
                      {form.errors[photo.formKey]}
                    </FormHelperText>
                  )}
                </Box>
                <Divider
                  css={{ color: colors.gray93, margin: '20px 0 0px 0' }}
                />
              </Fragment>
            ))}
          </Box>

          <Question
            title={content.get('YOUR_CHARGER_LOCATED_QUESTION')}
            detail={content.get('YOUR_CHARGER_LOCATED_QUESTION_INFO')}
          />

          {/* YES NO Radio Group */}
          <Box mt={3}>
            <Box display={'flex'} justifyContent={'center'}>
              <Grid xs={12} md={6}>
                <RadioButtonGroup
                  options={[
                    {
                      label: content.get('CHARGER_LOCATED_YES') || '',
                      value: false,
                    },
                    {
                      label: content.get('CHARGER_LOCATED_NO') || '',
                      value: true,
                    },
                  ]}
                  state={form.values.extendedInstallDistance}
                  setState={e => {
                    void form.setValue('extendedInstallDistance', e);
                  }}
                />
              </Grid>
            </Box>

            {form.errors.extendedInstallDistance && (
              <FormHelperText error>
                {form.errors.extendedInstallDistance}
              </FormHelperText>
            )}
          </Box>
          {/* drag and drop box*/}
          {form.values.extendedInstallDistance && (
            <Fragment>
              <Box mt={3}>
                <Button
                  className={classes.imageTitle}
                  onClick={() =>
                    setOpenExamplePhotoModal('modal-extendedDistanceImage')
                  }
                >
                  {content.get('EXTENDED_DISTANCE_EXAMPLE_PHOTO_LINK_TITLE')}
                </Button>
                <Modal
                  open={openExamplePhotoModal === 'modal-extendedDistanceImage'}
                  onClose={() => setOpenExamplePhotoModal(null)}
                  title={content.get('EXTENDED_DISTANCE_EXAMPLE_PHOTO_TITLE')}
                  showCloseIcon={true}
                >
                  {' '}
                  <Box mt={3}>
                    <img
                      css={{
                        width: '100%',
                        maxHeight: '340px',
                      }}
                      src={content.get('EXTENDED_DISTANCE_EXAMPLE_PHOTO')}
                    />
                  </Box>
                </Modal>
              </Box>
              <Box mt={2}>
                <FileUploader
                  image={form.values.extendedInstallDistanceImage}
                  placeholder={content.get('UPLOAD_A_PHOTO')}
                  reuploadLabel={content.get('REUPLOAD_PHOTO')!}
                  onUpload={key =>
                    form.setValue('extendedInstallDistanceImage', key)
                  }
                />

                {form.errors.extendedInstallDistanceImage && (
                  <FormHelperText error>
                    {form.errors.extendedInstallDistanceImage}
                  </FormHelperText>
                )}
              </Box>
            </Fragment>
          )}

          <Divider css={{ color: colors.gray93, margin: '30px 0 0 0' }} />

          {/* Amperage selection */}
          <Box>
            <Question
              title={content.get('AMPERAGE_OF_YOUR_MAIN_BREAKER')}
              detail={content.get('AMPERAGE_OF_YOUR_MAIN_BREAKER_INFO')}
              popoverContent={content.get(
                'AMPERAGE_OF_YOUR_MAIN_BREAKER_TOOLTIP',
              )}
            />
            <Box mt={2}>
              <DropdownMenu
                name={content.get('SELECT_ONE')}
                options={MainBreakerAmperageOptions}
                state={form.values.mainBreakerAmperage || ''}
                setState={e => {
                  void form.setValue('mainBreakerAmperage', e);
                }}
                errorMsg={(form.errors.mainBreakerAmperage as string) || ''}
              />
            </Box>

            <Divider css={{ color: colors.gray93, margin: '30px 0 0 0' }} />
          </Box>
          {/* Double breaker selection */}
          <Box>
            <Question
              title={content.get('OPEN_FOR_DOUBLE_BREAKER')}
              detail={content.get('OPEN_FOR_DOUBLE_BREAKER_INFO')}
              popoverContent={content.get('OPEN_FOR_DOUBLE_BREAKER_TOOLTIP')}
            />
            <Box mt={2}>
              <DropdownMenu
                name={content.get('SELECT_ONE')}
                options={doubleBreakerOptions}
                state={form.values.doubleBreakerAvailable || ''}
                setState={e => {
                  void form.setValue('doubleBreakerAvailable', e);
                }}
                errorMsg={(form.errors.doubleBreakerAvailable as string) || ''}
              />
            </Box>
          </Box>

          <Divider css={{ color: colors.gray93, margin: '30px 0 0 0' }} />

          <ProceedOrCancelButton
            back={routes.PGE_PLUS_EV_CHARGERS_INSTALLATION_PANEL_DETAILS}
          />

          <Box mt={3}>
            <SaveAndComeBackLink
              content={content}
              onSaveAndExit={handleSaveAndExit}
            />
          </Box>
        </Box>
      </form>
    </Fragment>
  );
};
