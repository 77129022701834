import {
  Box,
  Card,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from '../../hooks/useTranslation';
import React from 'react';
import colors from '../../themes/main-colors';
import PowerRestorationIcon from '../../static/Power_Restoration_Icon.svg';
import PowerRestorationIconMob from '../../static/Power_Restoration_Icon_Mob.svg';
import ReactMarkdown from 'react-markdown/with-html';
import ROUTES from '../../routes';
import { useIsMobile } from '../../util/style-utils';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: 'inset 0px 2px 0px #EB7100, 0px 3px 6px #00000029',
    },
    restorationBox: {
      background: colors.lightGray7,
      height: '175px',
      width: '95%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '5px',
      padding: '12px',
      margin: '12px 12px 0px 12px',
    },
    contentBox: {
      display: 'flex',
    },
    timeStampBox: {
      marginLeft: '1.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    statusUpdateStyles: {
      marginTop: '0.5rem',
      fontSize: '12px',
    },
    statusUpdateStylesMob: {
      marginTop: '1.5rem',
      fontSize: '12px',
    },
    warningMessage: {
      textAlign: 'center',
      paddingLeft: '6rem',
      paddingRight: '6rem',
      fontSize: '16px',
    },
    warningMessageMob: {
      textAlign: 'center',
      fontSize: '16px',
    },
    sizeOfFont16: {
      fontSize: '16px',
    },
    sizeOfFont22: {
      fontSize: '22px',
    },
    restorationTimeStamp: {
      fontSize: '16px',
      fontWeight: 'bolder',
    },
  }),
);

type pspsActiveCardWarningMessageData = {
  pspsActiveCardWarningMessage: React.ReactNode;
  lastUpdatedAt: string;
  restorationTimeStamp: string | null;
};

export function ActiveOutageCard(props: pspsActiveCardWarningMessageData) {
  const { inlineRichT, t } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const {
    pspsActiveCardWarningMessage,
    lastUpdatedAt,
    restorationTimeStamp,
  } = props;

  return (
    <Grid item>
      <Card className={classes.card}>
        <Typography className={classes.sizeOfFont22}>
          {inlineRichT('PSPS_HEADING')}
        </Typography>
        <Box className={classes.restorationBox}>
          <Box className={classes.contentBox}>
            {isMobile ? <PowerRestorationIconMob /> : <PowerRestorationIcon />}
            <div className={classes.timeStampBox}>
              <Typography className={classes.sizeOfFont16}>
                {t('ESTIMATED_RESTORATION_DASHBOARD')}
              </Typography>
              <Typography className={classes.restorationTimeStamp}>
                {restorationTimeStamp}
              </Typography>
            </div>
          </Box>
          <Typography
            className={
              isMobile
                ? classes.statusUpdateStylesMob
                : classes.statusUpdateStyles
            }
          >
            {inlineRichT('PSPS_STATUS_UPDATED_AT', {
              UPDATED_TIMESTAMP: lastUpdatedAt,
            })}
          </Typography>
          <Typography
            className={
              isMobile ? classes.warningMessageMob : classes.warningMessage
            }
          >
            {pspsActiveCardWarningMessage}
          </Typography>
        </Box>
        <Typography>
          <ReactMarkdown
            source={t('PSPS_LEARN_MORE', {
              PAGE_LINK: ROUTES.PUBLIC_SAFETY_POWER_SHUTOFF,
            })}
            escapeHtml={false}
          />
        </Typography>
      </Card>
    </Grid>
  );
}
