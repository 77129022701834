import { Router } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import AccountLayout from '../../components/account-layout';
import ROUTES from '../../routes';
import OCPAForm from '../../components/oregon-consumer-privacy-act/OCPAForm';

export const OCPAFormMain = () => {
  return (
    <>
      <Helmet>
        <title>Oregon Consumer Privacy Act</title>
      </Helmet>
      <AccountLayout>
        <Router basepath="/">
          <OCPAForm path={`${ROUTES.OREGON_CONSUMER_PRIVACY_ACT}/*`} />
        </Router>
      </AccountLayout>
    </>
  );
};
export default OCPAFormMain;
