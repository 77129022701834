/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { Fragment, useState } from 'react';
import { Grid, Typography, Link as MuiLink } from '@material-ui/core';
import { RouteComponentProps, navigate, useLocation } from '@reach/router';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import Backdrop from '../../../../backdrop/Backdrop';
import theme from '../../../../../themes/theme';
import { Link } from 'gatsby';
import Buttons from '../../../../buttons';
import { PgeEvModel } from '../../../../pge-plus-common/PgeEvModel';

type TEligibilityConfirmationState = {
  /***
   * Pass the slug as string
   * Entry has to be added and formatted in the PGE+ Eligibility confirmation microcopy group.
   ***/
  message: string;
  backButtonRoute: string;
  nextButtonRoute: string;
};

const EligibilityConfirmation: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const {
    content,
    richText,
    inlineRichText,
    isContentLoading,
  } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_ELIGIBILITY_CONFIRMATION,
  );
  const [isPGEEvModelVisible, setIsPGEEvModelVisible] = useState<boolean>(
    false,
  );
  const location = useLocation();
  const {
    message,
    backButtonRoute,
    nextButtonRoute,
  } = location.state as TEligibilityConfirmationState;

  if (isContentLoading) {
    return <Backdrop forceOpen />;
  }

  return (
    <Grid container>
      <Grid item>
        {message === 'REBATE_ONLY_REBATE_RESULTS_AFTER_QPL_STANDARD' ? (
          <Fragment>
            <CustomTypography>
              {richText(
                content.get('REBATE_ONLY_REBATE_RESULTS_AFTER_QPL_STANDARD_1'),
              )}
            </CustomTypography>
            <CustomTypography>
              {inlineRichText(
                content.get('REBATE_ONLY_REBATE_RESULTS_AFTER_QPL_STANDARD_2'),
              )}
              <MuiLink
                css={{ fontWeight: 'bold', cursor: 'pointer' }}
                onClick={() => setIsPGEEvModelVisible(true)}
              >
                {content.get('REBATE_ONLY_REBATE_RESULTS_AFTER_QPL_STANDARD_3')}
              </MuiLink>
            </CustomTypography>
          </Fragment>
        ) : message === 'EV_PULSE_REBATE_OFFER_DETAILS' ? (
          <Fragment>
            <CustomTypography>
              {inlineRichText(content.get('EV_PULSE_REBATE_OFFER_DETAILS_1'))}
              <MuiLink
                css={{ fontWeight: 'bold', cursor: 'pointer' }}
                onClick={() => setIsPGEEvModelVisible(true)}
              >
                {content.get('EV_PULSE_REBATE_OFFER_DETAILS_2')}
              </MuiLink>
              {inlineRichText(content.get('EV_PULSE_REBATE_OFFER_DETAILS_3'))}
            </CustomTypography>
            <CustomTypography>
              {richText(content.get('EV_PULSE_REBATE_OFFER_DETAILS_4'))}
            </CustomTypography>
          </Fragment>
        ) : (
          <CustomTypography>{richText(content.get(message))}</CustomTypography>
        )}
      </Grid>
      <Grid
        item
        container
        spacing={2}
        css={{
          marginTop: 24,
          marginBottom: 24,
          justifyContent: 'center',
        }}
      >
        <Grid item xs={6} md={3}>
          <Link to={backButtonRoute}>
            <Buttons
              type="button"
              fullWidth
              variant="outlined"
              color="secondary"
              css={{
                fontSize: '18px',
                borderRadius: '30px',
                '.MuiButton-label': {
                  fontWeight: 500,
                },
                '&:hover': {
                  borderWidth: '2px',
                },
              }}
            >
              {content.get('REUSABLE_BUTTON_BACK')}
            </Buttons>
          </Link>
        </Grid>
        <Grid item xs={6} md={3}>
          <Link to={nextButtonRoute}>
            <Buttons
              type="button"
              fullWidth
              variant="contained"
              css={{
                fontSize: '18px',
                borderRadius: '30px',
                '.MuiButton-label': {
                  fontWeight: 500,
                },
              }}
            >
              {content.get('REUSABLE_BUTTON_NEXT')}
            </Buttons>
          </Link>
        </Grid>
      </Grid>
      <PgeEvModel
        isVisible={isPGEEvModelVisible}
        setIsVisible={setIsPGEEvModelVisible}
      />
    </Grid>
  );
};

const CustomTypography = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      variant="body1"
      css={{
        fontSize: 20,
        [theme.breakpoints.down('xs')]: {
          fontSize: 16,
        },
      }}
    >
      {children}
    </Typography>
  );
};

export default EligibilityConfirmation;
