import gql from 'not-graphql-tag';
import {
  PersonalIdentificationType,
  PersonalIdentificationTypeParams,
} from '../__generated__/pge-types';
import useAuthQuery from './useAuthQuery';

const getPersonPrimaryIdentificationType = gql`
  query getPersonPrimaryIdentificationType(
    $params: PersonalIdentificationTypeParams!
  ) {
    getPersonPrimaryIdentificationType(params: $params)
  }
`;

export default (encryptedPersonId: string | undefined) => {
  const { data, loading } = useAuthQuery<
    {
      getPersonPrimaryIdentificationType: PersonalIdentificationType;
    },
    {
      params: PersonalIdentificationTypeParams;
    }
  >(getPersonPrimaryIdentificationType, {
    variables: {
      params: {
        encryptedPersonId: encryptedPersonId || '',
      },
    },
    skip: !encryptedPersonId,
  });

  const primaryIdType = data?.getPersonPrimaryIdentificationType;

  return {
    primaryIdType,
    getPrimaryIdTypeLoading: loading,
  };
};
