import { createStyles, makeStyles, Theme } from '@material-ui/core';

import colors from '../../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    colorTextDanger: {
      color: colors.roseRed,
    },
    listGrid: {
      flexDirection: 'row',
      display: 'flex',
      margin: '1em 0',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    confirmationNumber: {
      color: colors.noirBlur,
      [theme.breakpoints.down('xs')]: {},
      [theme.breakpoints.up('sm')]: {
        marginRight: 3,
      },
      [theme.breakpoints.up('md')]: {
        marginRight: 3,
      },
      [theme.breakpoints.up('lg')]: {
        marginRight: 3,
      },
    },
    paymentMethodContainer: {
      display: 'inline-block',
      alignItems: 'flex-start',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    paymentAmountCustom: {
      fontSize: '1.33rem',
      fontWeight: 300,
      lineHeight: 1.2,
      marginLeft: '2em',
    },
    paymentMethodTextContainer: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    paymentMethodIcon: {
      width: '1.5em',
      height: 'auto',
      verticalAlign: 'middle',
      marginRight: '0.2em',
      [theme.breakpoints.down('sm')]: {},
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
    },
    submit: {
      [theme.breakpoints.down('sm')]: {
        width: '70%',
        marginBottom: '1em',
      },
    },
    tryAgain: {
      marginRight: '1em',
      color: '#D63F2F',
      border: '1px solid #D63F2F',
      '&:hover': {
        border: '1px solid #D63F2F',
      },
      [theme.breakpoints.down('sm')]: {
        width: '70%',
        marginRight: '0em',
      },
    },
    marginTop1: {
      marginTop: '1em',
    },
    receiptContainer: {
      display: 'inline-block',
      flexDirection: 'row',
      alignItems: 'left',
      marginTop: '1em',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    paymentmethodLabel: {
      marginLeft: '0.5em',
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    confirmationValue: {
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    padding1: {
      padding: '1em',
    },
    marginLeftSpace: {
      marginLeft: '.5em',
      color: colors.noirBlur,
    },
    mainContainer: {
      justifyContent: 'flex-start',
      width: '100%',
      marginBottom: '1em',
    },
    paperContainer: {
      width: '100%',
      marginTop: '1em',
      boxShadow: 'none',
      marginBottom: '1em',
    },
    paymentPartialContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      display: 'flex',
      marginTop: '-0.5em',
      marginBottom: '1em',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: '-0.5em',
      },
    },
    paymentPartialSuccess: {
      display: 'flex',
      marginLeft: '1em',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        margin: 0,
        alignItems: 'flex-start',
      },
    },
    paymentSingleFailure: {
      display: 'flex',
      marginTop: '-0.2em',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        margin: 0,
        alignItems: 'flex-start',
      },
    },
    paymentIconPartial: {
      width: '1.5em',
      height: 'auto',
      verticalAlign: 'middle',
      marginLeft: '0.5em',
      marginRight: '0.2em',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    marginBot1: {
      marginBottom: '1em',
    },
    paymentAlertBox: {
      '& .MuiAlert-icon': {
        padding: theme.spacing(1.25, 0, 0, 0),
      },
    },
    noWrap: {
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'normal',
      },
    },
  }),
);
export default useStyles;
