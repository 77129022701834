import React, { FC, useEffect, Dispatch, SetStateAction } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import PaperBox from '../../utility/paper-box';
import ProceedOrCancel from '../../proceed-or-cancel';
import useStyles from '../styles';
import { PeakTimeRebatesTransactionType } from '../types';
import PeakTimeRebatesNotificationPreferences from '../notification-preferences';
import PeakTimeRebatesSuccess from '../success';
import { FormState } from '../../../hooks/useFormState.types';
import { Model } from '../rules';

interface Props {
  setTransactionType: Dispatch<SetStateAction<any>>;
  form: FormState<Model>;
  isSubmitSuccessful: boolean;
  showNotificationPreferenceRequiredError: boolean;
  handleOnFormSubmit: () => Promise<void>;
}

export const PeakTimeRebatesEnroll: FC<Props> = props => {
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    setTransactionType,
    isSubmitSuccessful,
    form,
    showNotificationPreferenceRequiredError,
    handleOnFormSubmit,
  } = props;

  useEffect(() => {
    setTransactionType(PeakTimeRebatesTransactionType.Enroll);
  }, []);

  return (
    <>
      {isSubmitSuccessful && (
        <PeakTimeRebatesSuccess
          transactionType={PeakTimeRebatesTransactionType.Enroll}
        />
      )}

      {!isSubmitSuccessful && (
        <Grid
          className={classes.root}
          container
          spacing={3}
          style={{ marginTop: '1em' }}
        >
          <Grid item>
            <Grid item xs={12} style={{ marginBottom: '1.5em' }}>
              <Typography className={classes.colorTextPrimary} variant={'h4'}>
                {t('CONFIRM_BEFORE_SIGNING_UP_PTR')}
              </Typography>
            </Grid>
            <PaperBox>
              <PeakTimeRebatesNotificationPreferences
                form={form}
                showNotificationPreferenceRequiredError={
                  showNotificationPreferenceRequiredError
                }
              />
            </PaperBox>

            <Grid
              item
              xs={12}
              style={{
                marginTop: '1em',
                marginRight: '.75em',
              }}
            >
              <ProceedOrCancel
                proceedLabel={t('SUBMIT')}
                proceedHandler={handleOnFormSubmit}
                cancelLabel={t('CANCEL')}
                cancelRoute={ROUTES.ACCOUNT}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default PeakTimeRebatesEnroll;
