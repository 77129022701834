import { useApolloClient } from '@apollo/react-hooks';
import { StopServiceSubmitRequest } from '../../__generated__/pge-types';
import gql from 'not-graphql-tag';

const stopServiceMutation = gql`
  mutation stopServiceMutation($payload: StopServiceSubmitRequest!) {
    stopServiceSubmit(payload: $payload)
  }
`;

export function useStopService() {
  const apolloClient = useApolloClient();

  async function submit(payload: StopServiceSubmitRequest) {
    await apolloClient.mutate<{ stopServiceSubmit: string }>({
      mutation: stopServiceMutation,
      variables: {
        payload,
      },
    });
  }

  return { submit };
}
