import React, { FC } from 'react';
import { Router } from '@reach/router';
import { Grid, Typography } from '@material-ui/core';
import Helmet from 'react-helmet';
import ROUTES from '../../../routes';
import PDDForm from '../../../components/preferred-due-day/';
import PDDSuccess from '../../../components/preferred-due-day/PDDSuccess';
import ProfileLayout from '../../../components/profile-layout';
import { useTranslation } from '../../../hooks/useTranslation';

type Props = {
  path?: string;
};

const PreferredDueDaySuccessPage: FC<Props> = () => {
  const { t } = useTranslation();
  const title = t('PDD_PAGE_TITLE');
  return (
    <ProfileLayout dense={false}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant={'h1'}>{title}</Typography>
        </Grid>
        <Grid item>
          <Router basepath="/">
            <PDDForm path={ROUTES.PREFERRED_DUE_DAY} />
            <PDDSuccess path={ROUTES.PREFERRED_DUE_DAY_SUCCESS} />
          </Router>
        </Grid>
      </Grid>
    </ProfileLayout>
  );
};
export default PreferredDueDaySuccessPage;
