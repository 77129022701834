import React, { FC } from 'react';
import {
  Typography,
  makeStyles,
  createStyles,
  useTheme,
  useMediaQuery,
  Card,
  CardContent,
  Box,
} from '@material-ui/core';
import colors from '../../../../themes/main-colors';
import { Theme } from '@material-ui/core/styles';
import { useTranslation } from '../../../../hooks/useTranslation';
import SingleInstallment from '../confirm-plan/SingleInstallment';
import PaymentConfirmationForm from '../../../payment-form/payment-confirmation-form';
import {
  OneTimePayConfirmationResponse,
  OneTimePayConfigData,
} from '../../../payment-form/oneTimePayment.types';
import { TpaMonthsMatrix } from '../useTPA';
import ROUTES from '../../../../routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    completed: {
      color:'#43936F !important',
    },
    completePlanInstallmentWrap: {
      textAlign: 'center',
      '& h5': {
        fontWeight: 300,
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 -16px', //-16 to nullify the padding
      },
    },
    trackerInfo: {
      border: `1px solid ${colors.lightGray1}`,
      borderWidth: '1px 0',
      padding: theme.spacing(2, 0, 3, 0),
      '& i': {
        maxWidth: 570,
        margin: '0 auto',
        color: colors.darkGray3,
        lineHeight: 1,
      },
    },
    spacing: {
      margin: theme.spacing(0, 0, 3, 0),
    },
    done: {
      width: 124,
      marginLeft: 'auto',
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        width: 243,
        marginRight: 'auto',
      },
    },
    tpaCompleteblock: {
      margin: theme.spacing(0, 0, 2, 0),
    },
  }),
);
interface Props {
  path?: string;
  paymentPlanConfirmationNumber: number;
  installmentPlan: TpaMonthsMatrix[];
  remainingAmount: number;
  oneTimePayConfirmationData: OneTimePayConfirmationResponse;
  oneTimePayConfig: OneTimePayConfigData;
  paymentMethodCapLimit: number;
  printContainerId: string;
}

const TPACompletePlan: FC<Props> = (props: Props) => {
  const {
    path,
    installmentPlan,
    remainingAmount,
    oneTimePayConfirmationData,
    oneTimePayConfig,
    paymentMethodCapLimit,
    printContainerId
  } = props;
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <Card className={classes.tpaCompleteblock}>
        <CardContent>
          <Box className={classes.completePlanInstallmentWrap} mx="auto">
            <Typography variant="h2" align="center" className={classes.spacing}>
              {t('YOUR_PAYMENT_PLAN_IS_ON_TRACK')}
            </Typography>
            {installmentPlan.map(
              (plan: TpaMonthsMatrix) =>
                plan.monthlyAmount &&
                plan.paymentDate && (
                  <SingleInstallment
                    installmentAmount={plan.monthlyAmount}
                    installmentDate={plan.paymentDate}
                    isFirstInstallment={false}
                    isPaymentCompleted={plan.isPaymentCompleted}
                    isTPACompletePaymentPage
                  />
                ),
            )}
            <Typography align="center" variant="h5" className={classes.spacing}>
              {richT('TPA_COMPLETE_PAYMENT_REMAINING_AMOUNT', {
                AMOUNT: remainingAmount?.toString(),
              })}
            </Typography>
          </Box>
          <Typography className={`${classes.trackerInfo}`}>
            <Typography
              data-testid={'tpa-complete-plan-helper-text'}
              variant="subtitle1"
              display="block"
              component={'i'}
            >
              {t('TPA_COMPLETE_PAYMENT_HELPER_TEXT')}
            </Typography>
          </Typography>
        </CardContent>
      </Card>
      <div style={{marginBottom:'1em'}}>
      {oneTimePayConfirmationData &&
        oneTimePayConfig &&
        paymentMethodCapLimit && (
          <PaymentConfirmationForm
            oneTimePayConfirmationData={oneTimePayConfirmationData}
            oneTimePayConfig={oneTimePayConfig}
            paymentMethodCapLimit={paymentMethodCapLimit}
            redirectionURL={ROUTES.TPA_MAKE_PAYMENT}
            printContainerId={printContainerId}
          />
        )}
      </div>
    </>
  );
};
export default TPACompletePlan;
