import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Hidden,
  MobileStepper,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { useRef, useState } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import theme from '../../themes/theme';
import { setInStorage } from '../../util/storage-utils';
import MediaWrapper from '../media-wrapper';
import { FullDivider } from '../pge-plus-common/FullDivider';
import RichText from '../rich-text';
import storage from './storage-utils';
import { useAccountDashboardIntercept } from './useAccountDashboardIntercept';

const useStyles = makeStyles(() =>
  createStyles({
    modal: {
      '& .MuiDialog-paperWidthSm': {
        padding: '16px',
        width: '90%',
        [theme.breakpoints.up('sm')]: {
          height: '60%',
        },
        [theme.breakpoints.down('sm')]: {
          height: '80vh',
        },
      },
    },
    container: {
      padding: '16px',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      textAlign: 'right',
      padding: '0',
    },
    close: {
      cursor: 'pointer',
    },
    headerImage: {
      maxHeight: '200px',
    },
    headerText: {
      fontSize: '25px',
      textTransform: 'capitalize',
      fontWeight: 'bold',
      color: colors.vibrantOrange,
      textAlign: 'center',
    },
    actions: {
      justifyContent: 'space-between',
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    actionButtons: {
      display: 'flex',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'end',
        flex: '0 0 200px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        flexDirection: 'column',
      },
    },
    remindMeLater: {
      '& .Mui-checked svg': {
        fill: colors.vibrantBlue,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        textAlign: 'center',
      },
    },
    previousActionButton: {
      width: '100%',
      color: colors.vibrantBlue,
      border: `1px solid ${colors.vibrantBlue}`,
    },
    nextActionButton: {
      marginLeft: '20px',
      color: colors.vibrantBlue,
      border: `1px solid ${colors.vibrantBlue}`,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        order: -1,
        marginLeft: 'unset',
        marginBottom: '10px',
      },
    },
    dotsContainer: {
      padding: '0',
      paddingTop: '2px',
      background: 'transparent',
      '& .MuiMobileStepper-dot': {
        height: '12px',
        width: '12px',
      },
      '& .MuiMobileStepper-dotActive': {
        background: colors.vibrantOrange,
      },
    },
    embeddedMedia: {
      width: '100%',
      height: '100%',
      '& iframe': {
        width: '100% !important',
      },
    },
    marginBottom: {
      marginBottom: '20px',
    },
  }),
);

export const AccountDashboardIntercept = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const contentRef = useRef<HTMLDivElement>(null);
  const [activeStep, setActiveStep] = useState(0);
  const { pages, open, setOpen } = useAccountDashboardIntercept(true);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    contentRef.current?.scrollTo(0, 0);
  };

  const handlePrevious = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
    contentRef.current?.scrollTo(0, 0);
  };

  const handleClose = (remindMeLaterOrBackdropClick: boolean) => {
    if (remindMeLaterOrBackdropClick) {
      setInStorage(
        storage.ACCOUNT_DASHBOARD_INTERCEPT_MODAL_STORAGE_KEY,
        storage.AccountDashboardInterceptLocalStorageValues.REMIND_ME_LATER,
      );
    } else {
      setInStorage(
        storage.ACCOUNT_DASHBOARD_INTERCEPT_MODAL_STORAGE_KEY,
        storage.AccountDashboardInterceptLocalStorageValues.NEVER_SHOW_AGAIN,
      );
    }
    setOpen?.(false);
  };

  return (
    <Dialog
      open={open}
      scroll={'paper'}
      className={classes.modal}
      onBackdropClick={() => handleClose(true)}
    >
      <DialogTitle className={classes.title} data-testid="title">
        <CancelIcon
          onClick={() => handleClose(false)}
          className={classes.close}
          data-testid="cancel"
        />
      </DialogTitle>
      <DialogContent ref={contentRef}>
        <DialogContentText>
          <Box className={classes.content}>
            {pages?.[activeStep]?.headerImage?.file?.url && (
              <img
                src={pages?.[activeStep]?.headerImage?.file?.url}
                className={`${classes.headerImage} ${classes.marginBottom}`}
                data-testid="headerImage"
              />
            )}
            {pages?.[activeStep]?.headerText && (
              <Typography
                className={`${classes.headerText} ${classes.marginBottom}`}
                data-testid="headerText"
              >
                {pages?.[activeStep]?.headerText}
              </Typography>
            )}
            {pages?.[activeStep]?.bodyCopy && (
              <RichText className={classes.marginBottom} data-testid="body">
                {pages?.[activeStep]?.bodyCopy}
              </RichText>
            )}
            {pages?.[activeStep]?.videoEmbedUrl && (
              <Box
                className={classes.embeddedMedia}
                data-testid="embeddedMedia"
              >
                <MediaWrapper url={pages?.[activeStep]?.videoEmbedUrl} />
              </Box>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
      {(pages?.length ?? 0) > 1 && (
        <MobileStepper
          className={classes.dotsContainer}
          variant="dots"
          steps={pages?.length || 1}
          position="static"
          activeStep={activeStep}
          nextButton={<Button />}
          backButton={<Button />}
          data-testid="dots"
        />
      )}
      <FullDivider />
      <DialogActions className={classes.actions}>
        <Hidden smDown>
          <Box className={classes.remindMeLater} data-testid="checkbox">
            <FormControlLabel
              control={
                <Checkbox
                  name={'remind-me-later'}
                  onChange={() => handleClose(true)}
                  color={'primary'}
                />
              }
              label={t('REMIND_ME_LATER')}
            />
          </Box>
        </Hidden>
        <Box className={classes.actionButtons}>
          {activeStep !== 0 && (
            <Button
              onClick={() => handlePrevious()}
              color="primary"
              variant="outlined"
              className={classes.previousActionButton}
              data-testid="previous"
              style={
                activeStep === (pages?.length || 1) - 1
                  ? { width: '50%' }
                  : undefined
              }
            >
              {t('PREVIOUS')}
            </Button>
          )}
          {activeStep !== (pages?.length || 1) - 1 && (
            <Button
              onClick={() => handleNext()}
              color="primary"
              variant="outlined"
              className={classes.nextActionButton}
              disabled={activeStep === (pages?.length || 1) - 1}
              data-testid="next"
              style={activeStep === 0 ? { width: '50%' } : undefined}
            >
              {t('NEXT')}
            </Button>
          )}
        </Box>
        <Hidden smUp>
          <Box className={classes.remindMeLater} data-testid="checkbox">
            <FormControlLabel
              control={
                <Checkbox
                  name={'remind-me-later'}
                  onChange={() => handleClose(true)}
                  color={'primary'}
                />
              }
              label={t('REMIND_ME_LATER')}
            />
          </Box>
        </Hidden>
      </DialogActions>
    </Dialog>
  );
};
