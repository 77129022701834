import useValidationForm from './useValidationForm';
import { useTranslation } from './useTranslation';
import {
  ContactInfoFormFields,
  ContactInfo,
} from '../components/contact-info-form/ContactInfoForm.types';

const { t } = useTranslation();

export default (validationRules: () => void, contact?: ContactInfo) => {
  const defaultFormValues: ContactInfoFormFields = {
    company: {
      label: t('CONTACT_INFO_COMPANY_NAME'),
      value: contact?.company || '',
      errorMessage: '',
    },
    name: {
      label: t('CONTACT_INFO_FULL_NAME'),
      value: contact?.name || '',
      errorMessage: '',
    },
    title: {
      label: t('CONTACT_INFO_TITLE'),
      value: contact?.title || '',
      errorMessage: '',
    },
    email: {
      label: t('CONTACT_INFO_EMAIL_ADDRESS'),
      value: contact?.email || '',
      errorMessage: '',
    },
    phone: {
      label: t('CONTACT_INFO_PHONE_NUMBER'),
      value: contact?.phone || '',
      errorMessage: '',
    },
  };

  const props = useValidationForm(defaultFormValues, validationRules);
  return {
    ...props,
  };
};

export const mapFormDataToContactInfo = (data: any): ContactInfo => {
  return {
    company: data.company.value,
    name: data.name.value,
    title: data.title.value,
    email: data.email.value,
    phone: data.phone.value,
  };
};
