import {
  Box,
  CircularProgress,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    loader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
);

export function Loader() {
  const classes = useStyles();
  return (
    <Box className={classes.loader}>
      <CircularProgress />
    </Box>
  );
}
