import React, { FC, useContext, useEffect } from 'react';
import { navigate, Router, useLocation } from '@reach/router';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import Backdrop from '../backdrop';
import useNeedMoreTimeToPayForm from './useNeedMoreTimeToPayForm';
import Landing from './landing';
import PlanOptions from './plan-options';
import Confirm from './confirm';
import TransactionSuccessful from './transaction-successful';
import { Typography, makeStyles, createStyles, Box, Grid } from '@material-ui/core';
import AccountDropdown from '../account-dropdown';
import TPACreatePlan from './tpa/create-plan/TPACreatePlan';
import ProgressTracker, { ProgressTrackerStep } from '../ProgressTracker';
import useTPA from './tpa/useTPA';
import theme from '../../themes/theme';
import TPAReviewPlan from './tpa/review-plan/TPAReviewPlan';
import TPACompletePlan from './tpa/complete/TPAComplete';
import TPAConfirmPlan from './tpa/confirm-plan/TPAConfirmPlan';
import TPAMakePayment from './tpa/make-payment/TPAMakePayment';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';
import useUtil from './useUtil';
import { add } from 'date-fns';

interface Props {
  isTPAFlagOverwrite?: boolean | null;
}

const NeedMoreTimeToPayForm: FC<Props> = (props: Props) => {
  //TODO: Once this is live, remove the feature flag and associated code incl. unit  tests.
  const { isTPAEnabled } = useContext(featureFlagsContext);
  const isTPAFeatureEnabled =
    props.isTPAFlagOverwrite !== (null || undefined)
      ? props.isTPAFlagOverwrite
      : isTPAEnabled;

  const { t } = useTranslation();
  const location = useLocation();

  const useStyles = makeStyles(() =>
    createStyles({
      divSpacing: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      successDivSpacing: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
      }
    }),
  );
  const classes = useStyles();

  const { setErrorNotification } = useUtil();

  const {
    progressTrackerSteps,
    getCurrentStepDetails,
    isLoadingTPADetails,
    tpaDetails,
    installmentAmount,
    setInstallmentAmount,
    isShowTPAStepper,
    handleOnReviewPlanClick,
    handleCreatePlanOnBackClick,
    isTPAPlanSelected,
    setIsTPAPlanSelected,
    dueDate: firstInstallmentDueDate,
    currentChargesDue,
    handleOnConfirmPlanClick,
    handleReviewPlanOnBackClick,
    handleOnPaymentConfirmation,
    numberOfMonths,
    tpaConfirmationNumber,
    tpaInstallmentPlan,
    mailingAddress,
    oneTimePayConfirmationDataDetails,
    oneTimePayConfigDataDetails,
    paymentMethodCapLimitData,
    tpaDownPaymentAmount,
    getAccountDetailsError,
  } = useTPA();

  const {
    loadingPEData,
    currentPEPlan,
    hasAvailablePEOptions,
    handleOnShowOptionsClick,
    availablePEOptions,
    isEligibleForPETpa,
    handleOnSelectionOptionChange,
    selectedPEPlan,
    handleOnSelectOptionSubmit,
    handleOnCancel,
    isPastDue,
    handleOnSubmit,
    isOnlyOnePEChoiceAvailable,
    isOnlyPEOptionOneAvailable,
    confirmationNumber,
    getPEInfoError,
  } = useNeedMoreTimeToPayForm({
    isTPAEligible: !!tpaDetails?.isEligible,
    isTPAPlanSelected: isTPAPlanSelected,
    setIsTPAPlanSelected: setIsTPAPlanSelected,
  });

  const { loading: loadingSelectedAcctParams } = useSelectedAccountParams();

  const loadingData =
    loadingSelectedAcctParams ||
    loadingPEData ||
    (isTPAFeatureEnabled && isLoadingTPADetails);

  useEffect(() => {
    if (getPEInfoError || getAccountDetailsError) {
      setErrorNotification(true);
    } else {
      setErrorNotification(false);
    }
  }, [getPEInfoError, getAccountDetailsError]);

  const isTPAEnrolled = Boolean(tpaDetails?.isEnrolled);
  const PRINT_CONTAINER_ID = 'tpa-enroll-print-receipt';
  return (
    <>
    <Grid container direction="column" spacing={2}  id={PRINT_CONTAINER_ID}>
    
      <Grid item>   
        <Typography variant={'h1'} data-testid={'nmttp-header'}>
          {getCurrentStepDetails().headline}
        </Typography>
      </Grid>
     
      {isShowTPAStepper && (
        <div className={classes.divSpacing}>
          <ProgressTracker steps={progressTrackerSteps} />
        </div>
      )}

      {isTPAFeatureEnabled && (
        <div className={confirmationNumber ? classes.successDivSpacing : classes.divSpacing}>
          <AccountDropdown />
        </div>
      )}
      {!isTPAFeatureEnabled && (
        <Box mt={2} mb={2}>
          <AccountDropdown showSelectedAccountOnly={true} />
        </Box>
      )}

      {loadingData && <Backdrop forceOpen />}

      {!loadingData && (
        <Router basepath="/">
          <Landing
            path={ROUTES.NEED_MORE_TIME_TO_PAY}
            currentPlan={currentPEPlan}
            hasAvailablePEOptions={hasAvailablePEOptions}
            onShowOptionClick={handleOnShowOptionsClick}
            availablePEOptions={availablePEOptions}
            tpaDetails={tpaDetails}
            isTPAEnrolled={isTPAEnrolled}
          />
          <PlanOptions
            path={ROUTES.NEED_MORE_TIME_TO_PAY_PLAN_OPTIONS}
            hasAvailablePEOptions={hasAvailablePEOptions}
            availablePEOptions={availablePEOptions}
            isEligibleForPETpa={isEligibleForPETpa}
            onSelectionOptionChange={handleOnSelectionOptionChange}
            selectedPEPlan={selectedPEPlan}
            onSelectOptionSubmit={handleOnSelectOptionSubmit}
            onCancel={handleOnCancel}
            isTpaEligible={!!tpaDetails?.isEligible}
            isTPAPlanSelected={isTPAPlanSelected}
          />
          <PlanOptions
            path={ROUTES.NEED_MORE_TIME_TO_PAY_PLAN_OPTIONS_NOTPA}
            hasAvailablePEOptions={hasAvailablePEOptions}
            availablePEOptions={availablePEOptions}
            isEligibleForPETpa={isEligibleForPETpa}
            onSelectionOptionChange={handleOnSelectionOptionChange}
            selectedPEPlan={selectedPEPlan}
            onSelectOptionSubmit={handleOnSelectOptionSubmit}
            onCancel={handleOnCancel}
            isTpaEligible={!!tpaDetails?.isEligible}
            isTPAPlanSelected={isTPAPlanSelected}
          />
          <Confirm
            path={ROUTES.NEED_MORE_TIME_TO_PAY_CONFIRM}
            currentPlan={currentPEPlan}
            selectedPlan={selectedPEPlan}
            isEligibleForPETpa={isEligibleForPETpa}
            isPastDue={isPastDue}
            onSubmit={handleOnSubmit}
            isOnlyOnePEChoiceAvailable={isOnlyOnePEChoiceAvailable}
          />
          <Confirm
            path={ROUTES.NEED_MORE_TIME_TO_PAY_CONFIRM_NOTPA}
            currentPlan={currentPEPlan}
            selectedPlan={selectedPEPlan}
            isEligibleForPETpa={isEligibleForPETpa}
            isPastDue={isPastDue}
            onSubmit={handleOnSubmit}
            isOnlyOnePEChoiceAvailable={isOnlyOnePEChoiceAvailable}
          />
          <TransactionSuccessful
            path={ROUTES.NEED_MORE_TIME_TO_PAY_SUCCESS}
            selectedPEPlan={selectedPEPlan}
            isOnlyPEOptionOneAvailable={isOnlyPEOptionOneAvailable}
            confirmationNumber={confirmationNumber}
            printContainerId={PRINT_CONTAINER_ID}
          />
          {tpaDetails && (
            <>
              <TPACreatePlan
                path={ROUTES.TPA_CREATE_PLAN}
                numberOfMonths={numberOfMonths}
                currentCharges={currentChargesDue!}
                accountBalance={tpaDetails?.accountBalance}
                dueDate={firstInstallmentDueDate!}
                setInstallmentAmount={setInstallmentAmount}
                handleOnReviewPlanClick={handleOnReviewPlanClick}
                handleOnBackClick={handleCreatePlanOnBackClick}
              />
              <TPAReviewPlan
                path={ROUTES.TPA_REVIEW_PLAN}
                accountBalance={tpaDetails?.accountBalance}
                monthlyInstallment={installmentAmount}
                totalNumberOfMonths={numberOfMonths}
                firstInstallmentDate={firstInstallmentDueDate!}
                lastInstallmentDate={add(firstInstallmentDueDate!, {
                  months: numberOfMonths - 1,
                })}
                handleOnConfirmClick={handleOnConfirmPlanClick}
                handleOnBackClick={handleReviewPlanOnBackClick}
              />
              <TPAConfirmPlan
                path={ROUTES.TPA_CONFIRM_PLAN}
                paymentPlanConfirmationNumber={tpaConfirmationNumber}
                installmentPlan={tpaInstallmentPlan}
                mailingAddress={mailingAddress!}
                handleOnMakePaymentClick={() =>
                  navigate(ROUTES.TPA_MAKE_PAYMENT)
                }
              />
              <TPAMakePayment
                path={ROUTES.TPA_MAKE_PAYMENT}
                handleOnPaymentConfirmation={handleOnPaymentConfirmation}
                firstInstallmentAmount={tpaDownPaymentAmount}
                firstInstallmentDueDate={firstInstallmentDueDate!}
              />

              <TPACompletePlan
                path={ROUTES.TPA_COMPLETE}
                paymentPlanConfirmationNumber={tpaConfirmationNumber}
                installmentPlan={tpaInstallmentPlan}
                oneTimePayConfirmationData={oneTimePayConfirmationDataDetails!}
                oneTimePayConfig={oneTimePayConfigDataDetails!}
                paymentMethodCapLimit={paymentMethodCapLimitData}
                remainingAmount={Number(
                  (tpaDetails?.accountBalance! - tpaDownPaymentAmount).toFixed(
                    2,
                  ),
                )} // acountbalance - downpayment amount
                printContainerId={PRINT_CONTAINER_ID}
              />
            </>
          )}
        </Router>
      )}
      </Grid>
    </>
  );
};

export default NeedMoreTimeToPayForm;
