import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AccountDetail, PremiseInfo } from '../../../__generated__/pge-types';
import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';

type Props = {
  account: AccountDetail;
  handleClick: (account: AccountDetail) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  cardStyle: {
    boxShadow: 'none',
    borderRadius: '0',
    backgroundColor: 'transparent',
    border: '1px solid rgb(127 127 127 / 50%)',
    marginBottom: '20px',
    cursor: 'pointer',
  },
  cardContent: {
    paddingTop: '24px',
    paddingBottom: '24px',
    paddingLeft: '34px',
    paddingRight: '34px',
  },
  cardTypoGraphy: {
    fontSize: '24px',
    lineHeight: '31px',
    color: colors.sparkBlue,
    marginBottom: '5px',
    position: 'relative',
  },
  arrowStyle: {
    position: 'absolute',
    top: '1px',
    right: '-8px',
    width: '29px',
    height: '28px',
  },
  accountDetail: {
    fontSize: '16px',
    lineHeight: '22px',
  },
}));

const SSMAccount = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { account, handleClick } = props;
  let serviceAdress = account?.premiseInfo as PremiseInfo[];
  if (Number(account?.premiseInfo?.length) > 1) {
    serviceAdress = account?.premiseInfo?.filter(
      premise => premise?.saDetails![0]?.saStatus !== 'STOPPED',
    ) as PremiseInfo[];
  }

  return (
    <>
      <Card
        data-testid="accountLabel"
        onClick={() => handleClick(account)}
        className={classes.cardStyle}
      >
        <Box>
          <CardContent className={classes.cardContent}>
            <Typography
              component="div"
              variant="h5"
              className={classes.cardTypoGraphy}
            >
              {serviceAdress ? serviceAdress[0]?.addressLine1 : null}
              <ArrowForwardIosIcon className={classes.arrowStyle} />
            </Typography>
            <Typography
              variant="subtitle1"
              component="div"
              className={classes.accountDetail}
            >
              {t('SSM_ACCOUNT')}: <b>{account.accountNumber}</b>
            </Typography>
          </CardContent>
        </Box>
      </Card>
    </>
  );
};

export default SSMAccount;
