import React from 'react';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import { UploadPhotos } from '../evcharger.types';

export interface PhotoUploadListProps {
  formKey: keyof UploadPhotos;
  title: string | React.ReactNode;
  info: string | React.ReactNode;
  img: string;
  examplePhotoLink: string | React.ReactNode;
}

type Props = {
  content: PageTextContentType;
};

export const photoUploadList = ({ content }: Props): PhotoUploadListProps[] => {
  return [
    {
      formKey: 'panelImage',
      title: content.get('YOUR_PANEL_OPEN_DOOR'),
      info: content.get('YOUR_PANEL_OPEN_DOOR_INFO'),
      img: content.get('YOUR_PANEL_OPEN_DOOR_EXAMPLE_PHOTO') || '',
      examplePhotoLink: content.get(
        'YOUR_PANEL_OPEN_DOOR_EXAMPLE_PHOTO_LINK_TITLE',
      ),
    },
    {
      formKey: 'amperageImage',
      title: content.get('AMPERAGE_MAIN_BREAKER'),
      info: content.get('AMPERAGE_MAIN_BREAKER_INFO'),
      img: content.get('AMPERAGE_MAIN_BREAKER_EXAMPLE_PHOTO') || '',
      examplePhotoLink: content.get(
        'AMPERAGE_MAIN_BREAKER_INFO_EXAMPLE_PHOTO_LINK_TITLE',
      ),
    },
    {
      formKey: 'panelLocationImage',
      title: content.get('YOUR_PANEL_LOCATION'),
      info: content.get('YOUR_PANEL_LOCATION_INFO'),
      img: content.get('YOUR_PANEL_LOCATION_EXAMPLE_PHOTO') || '',
      examplePhotoLink: content.get(
        'YOUR_PANEL_LOCATION_EXAMPLE_PHOTO_LINK_TITLE',
      ),
    },
  ];
};
