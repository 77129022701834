/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';
import { RouteComponentProps, navigate, useLocation } from '@reach/router';
import { Box, Divider, FormHelperText, Typography } from '@material-ui/core';

import { ProceedOrCancelButton } from '../../../../pge-plus-common/StepperForm';
import ROUTES from '../../../../../routes';
import colors from '../../../../../themes/main-colors';
import DatePicker from '../../../../date-picker/DatePicker';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import useFormState from '../../../../../hooks/useFormState';
import { useRebateOnlyFormState } from '../../../../../providers/RebateOnlyFormStateProvider';
import { chargerInstallInfoValidateFunction } from '../rebateOnlyForm.rules';
import {
  ApplicationErSectionType,
  ErChargerInstallInfo,
  RebateSmartCharging,
  SectionErRebateConfirm,
  SectionErRebateInfo,
} from '../../../../../__generated__/pgeplus-types';
import useUtil from '../../../../../hooks/pge-plus/useUtil';
import useWrapWithLoader from '../../../../../hooks/useWrapWithLoading';
import useUpdateERSections from '../../../../../hooks/pge-plus/useUpdateERSections';
import { rebateConfirmERFormStateAdapter } from '../rebateOnlyFormState.adapter';
import ContactInfoRow from '../../../../pge-plus-form/ContactInfo/ContactInfoRow';
import { SaveAndComeBackLink } from '../../../../pge-plus-common/SaveAndComeBackLink';
import Grid from '@material-ui/core/Grid';
import RadioButtonGroup from '../../../../pge-plus-common/RadioButtonGroup';

type ChargerInformationState = {
  backButtonRoute: string;
};

const ChargerInformation: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const location = useLocation();
  const { backButtonRoute } = (location.state || {}) as ChargerInformationState;

  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_REBATE_CONFIRM,
  );

  const {
    formState: { rebateConfirm, rebateInfo, application },
    getSectionData,
    updateFormState,
  } = useRebateOnlyFormState();

  const { updateSectionRebateConfirm } = useUpdateERSections();
  const { setErrorNotification } = useUtil();
  const { wrapWithLoader } = useWrapWithLoader();

  const sectionDataRebateConfirm: SectionErRebateConfirm = getSectionData(
    ApplicationErSectionType.ErRebateConfirm,
  );

  const sectionDataRebateInfo: SectionErRebateInfo = getSectionData(
    ApplicationErSectionType.ErRebateInfo,
  );

  const form = useFormState(rebateConfirm.chargerInstallInfo || {}, {
    validate: chargerInstallInfoValidateFunction(),
    validationContext: {
      content,
    },
  });

  const backRouteState = () => {
    const rebateSmartCharging = rebateConfirm.preRebateEligibility1
      ?.evRebate as RebateSmartCharging;
    const backState: any = {
      chargerAmount: rebateSmartCharging?.charger,
      panelUpgradeAmount: rebateSmartCharging?.panelUpgrade,
    };

    //Navigation logic
    if (
      sectionDataRebateInfo.data.rebateInfo.existingEnrollments.iqbd &&
      rebateSmartCharging?.isHigherDiscount
    ) {
      backState['rebateType'] = 'iqbd';
    } else if (
      !sectionDataRebateInfo.data.rebateInfo.existingEnrollments.iqbd &&
      !rebateSmartCharging?.isHigherDiscount
    ) {
      backState['rebateType'] = 'standard';
    } else if (
      !sectionDataRebateInfo.data.rebateInfo.existingEnrollments.iqbd &&
      rebateSmartCharging?.isHigherDiscount
    ) {
      backState['rebateType'] = 'higher';
    }
    if (backState.rebateType) {
      return backState;
    } else {
      return;
    }
  };

  const handleSubmit = wrapWithLoader(async (data: ErChargerInstallInfo) => {
    try {
      const response = await updateSectionRebateConfirm(
        rebateConfirmERFormStateAdapter().fromFormState(
          { ...rebateConfirm, chargerInstallInfo: data },
          application?.id!,
          sectionDataRebateConfirm?.id,
        ),
      );

      if (!response.data?.updateSectionERRebateConfirm.section.errors.length) {
        if (response.data?.updateSectionERRebateConfirm?.section.data) {
          const updatedValues = rebateConfirmERFormStateAdapter().toFormState(
            response.data?.updateSectionERRebateConfirm?.section.data,
          );
          updateFormState('rebateConfirm', {
            ...updatedValues,
          });
        }

        if (
          response.data?.updateSectionERRebateConfirm.section.data
            .preRebateEligibility2?.evRebate?.__typename === 'RebateBYOC'
        ) {
          void navigate(ROUTES.PGE_PLUS_REBATE_ONLY_ELIGIBILITY_CONFIRMATION, {
            state: {
              message: 'EV_PULSE_REBATE_BYOC',
              backButtonRoute: ROUTES.PGE_PLUS_REBATE_ONLY_CHARGER_INFORMATION,
              nextButtonRoute: ROUTES.PGE_PLUS_REBATE_ONLY_CONNECT_CHARGER,
            },
          });
        } else if (
          response.data?.updateSectionERRebateConfirm.section.data
            .preRebateEligibility2?.evRebate?.__typename ===
          'RebateSmartCharging'
        ) {
          void navigate(ROUTES.PGE_PLUS_REBATE_ONLY_CHARGER_INSTALLATION_COSTS);
        } else {
          throw new Error();
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log('error', error);
      setErrorNotification(true);
    }
  });

  const contactInfoBox = {
    border: `1px solid ${colors.sparkBlue}`,
    borderRadius: 5,
    margin: '20px 0 20px 0',
    padding: 20,
    fontSize: 16,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  };

  const divider = {
    color: colors.lightGray1,
  };

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      const response = await updateSectionRebateConfirm({
        ...rebateConfirmERFormStateAdapter().fromFormState(
          {
            ...rebateConfirm,
            chargerInstallInfo: form.values,
          },
          application?.id!,
          sectionDataRebateConfirm?.id,
        ),
        saveAndComeBack: true,
      });
      if (
        response?.errors ||
        response.data?.updateSectionERRebateConfirm?.validation?.errors ||
        response.data?.updateSectionERRebateConfirm?.section?.errors.length !==
          0
      ) {
        throw new Error();
      } else {
        void navigate(ROUTES.ACCOUNT);
      }
    } catch {
      setErrorNotification(true);
    }
  });

  return (
    <form onSubmit={form.submit(values => handleSubmit(values), console.log)}>
      <Box>
        <Box display={'flex'} justifyContent={'center'}>
          <Grid css={{ ...contactInfoBox }} xs={12} md={8}>
            <Box fontWeight="700" display="flex" justifyContent="space-between">
              <Typography
                variant={'body1'}
                css={{ fontWeight: 700, fontSize: 20, marginBottom: 15 }}
              >
                {content.get('YOUR_CHARGER_INFO')}
              </Typography>
            </Box>

            <Divider css={{ ...divider }} sx={{ my: 2 }} />

            <ContactInfoRow
              label={content.get('MODEL_NUMBER')}
              value={rebateInfo.chargerValidation?.product?.modelNo || ''}
            />
            <ContactInfoRow
              label={content.get('SERIAL_NUMBER')}
              value={rebateInfo.qualifiedProductInfo?.serialNumber || ''}
            />
          </Grid>
        </Box>
        <Box
          css={{
            width: '100%',
            marginBottom: '24px',
            '&>div': {
              width: '100%',
            },
          }}
        >
          <Typography
            css={{
              fontFamily: 'Untitled-Sans',
              fontSize: 20,
              fontWeight: 'bold',
              lineHeight: '26px',
            }}
          >
            {content.get('WHEN_WAS_CHARGER_INSTALLED')}
          </Typography>
          <DatePicker
            name={content.get('SELECTED_DATE')}
            label={content.get('SELECTED_DATE')}
            value={form.values.installationDate}
            onChange={({ target: { value } }: any) => {
              if (value) {
                void form.setValue('installationDate', value);
              }
            }}
          />
          {form.errors.installationDate && (
            <FormHelperText error>
              {form.errors.installationDate}
            </FormHelperText>
          )}
        </Box>
        <Box>
          <Typography
            css={{
              fontFamily: 'Untitled-Sans',
              fontSize: 20,
              fontWeight: 'bold',
              lineHeight: '26px',
            }}
          >
            {content.get('WAS_PANEL_REPLACED')}
          </Typography>
          <Box display={'flex'} justifyContent={'center'} mt={4}>
            <Grid xs={12} md={6}>
              <RadioButtonGroup
                options={[
                  {
                    label: content.get('REUSABLE_YES') || '',
                    value: content.get('REUSABLE_YES') || '',
                  },
                  {
                    label: content.get('REUSABLE_NO') || '',
                    value: content.get('REUSABLE_NO') || '',
                  },
                ]}
                state={
                  form.values.panelUpgraded === true
                    ? content.get('REUSABLE_YES') || ''
                    : form.values.panelUpgraded === false
                    ? content.get('REUSABLE_NO') || ''
                    : ''
                }
                setState={val => {
                  void form.setValue(
                    'panelUpgraded',
                    val === content.get('REUSABLE_YES'),
                  );
                }}
              />
            </Grid>
          </Box>
          {form.errors.panelUpgraded && (
            <FormHelperText error>{form.errors.panelUpgraded}</FormHelperText>
          )}
        </Box>
        <ProceedOrCancelButton
          back={
            backButtonRoute ||
            (backRouteState()?.rebateType === 'standard'
              ? ROUTES.PGE_PLUS_REBATE_ONLY_STANDARD_REBATES_ELIGIBILE
              : ROUTES.PGE_PLUS_REBATE_ONLY_HIGHER_REBATES_ELIGIBILE)
          }
          backRouteState={backRouteState()}
          backLabel={content.get('REUSABLE_BUTTON_BACK')}
        />
      </Box>
      <Box mt={6}>
        <SaveAndComeBackLink
          content={content}
          onSaveAndExit={handleSaveAndExit}
        />
      </Box>
    </form>
  );
};

export default ChargerInformation;
