import React, { FC } from 'react';
import {
  Typography,
  makeStyles,
  createStyles,
  useTheme,
  useMediaQuery,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Paper,
} from '@material-ui/core';

import {
  Address,
  Maybe,
  PremiseInfo,
  ServiceAddress,
} from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import colors from '../../../../themes/main-colors';
import { Theme } from '@material-ui/core/styles';

import {
  toDateStringFullMonthName,
  determineAddressParts,
} from '../../../../util/format';
import SingleInstallment from './SingleInstallment';
import ROUTES from '../../../../routes';

import ProceedOrCancel from '../../../proceed-or-cancel/ProceedOrCancel';
import { TpaMonthsMatrix } from '../useTPA';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    completed: {
      color: '#43936F !important',
    },
    accountCard: {
      margin: theme.spacing(1.5, 0, 1.5, 0),
    },
    accountCardContainer: {
      padding: theme.spacing(2.61, 2.8, 2.61, 2.8),
      textAlign: 'center',
    },
    headingLabel: {
      color: colors.noirBlur,
      marginBottom: '1.375rem',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '.812rem',
      },
    },
    ConfirmNumberSection: {
      borderRight: `1px solid ${colors.lightGray1}`,
      paddingRight: 10,
      [theme.breakpoints.down('sm')]: {
        borderRight: 0,
        paddingRight: 0,
        marginBottom: '1.562rem',
      },
    },
    addressSection: {
      paddingLeft: 10,
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
      },
    },
    dollar: {
      color: colors.noirBlur,
    },
    bottomLabel: {},
    vdivider: {
      margin: theme.spacing(0, 0, -1, 0),
    },
    paymentLabel: {
      fontWeight: 400,
      margin: theme.spacing(0, 0, 2, 0),
    },
    paymentContainer: {
      padding: theme.spacing(2.5, 3, 4, 3),
    },
    hintText: {
      color: colors.darkGray3,
      fontWeight: 'normal',
    },
    installmentContainer: {
      clear: 'both',
      borderBottom: `1px solid ${colors.lightGray1}`,
      marginBottom: '24px',
      minHeight: 200,
      marginTop: theme.spacing(4.2),
    },
    footerButton: {
      margin: theme.spacing(2.5),
    },
  }),
);

interface Props {
  path?: string;
  paymentPlanConfirmationNumber: number;
  installmentPlan: TpaMonthsMatrix[];
  mailingAddress: PremiseInfo | Address;
  handleOnMakePaymentClick: () => void;
}

const TPAConfirmPlan: FC<Props> = (props: Props) => {
  const {
    path,
    installmentPlan,
    mailingAddress,
    handleOnMakePaymentClick,
    paymentPlanConfirmationNumber,
  } = props;

  const { t, richT } = useTranslation();
  const classes = useStyles();
  const addressParts = determineAddressParts(mailingAddress);

  return (
    <>
      <Card className={classes.accountCard}>
        <CardContent className={classes.accountCardContainer}>
          <Grid container>
            <Grid item xs={12} md={6} className={classes.ConfirmNumberSection}>
              <Typography variant={'h6'} className={classes.headingLabel}>
                {t('PAYMENT_PLAN_CONFIRMATION_MESSAGE')}
              </Typography>

              <Typography
                variant={'h3'}
                style={{ fontWeight: 800 }}
                className={classes.bottomLabel}
              >
                {paymentPlanConfirmationNumber}
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} className={classes.addressSection}>
              <Typography variant={'h6'} className={classes.headingLabel}>
                {t('PAYMENT_PLAN_CONFIRMATION_ADDRESS')}
              </Typography>
              <Typography
                variant={'h3'}
                style={{ fontWeight: 800 }}
                className={classes.bottomLabel}
                data-testid={'account-date-element'}
              >
                {addressParts.addressPart} <br /> {addressParts.cityPart}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.accountCard}>
        <CardContent className={classes.paymentContainer}>
          <Grid container>
            <Box>
              <Typography variant={'h2'} className={classes.paymentLabel}>
                {t('PAYMENT_PLAN_PROGRESS')}
              </Typography>
            </Box>
            <Box>
              <Typography variant={'h6'}>
                {t('PAYMENT_PLAN_PROGRESS_TEXT')}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.installmentContainer}>
            {installmentPlan &&
              installmentPlan.map(
                (tpaMatrix: TpaMonthsMatrix, index: number) =>
                  tpaMatrix.monthlyAmount &&
                  tpaMatrix.paymentDate && (
                    <SingleInstallment
                      key={index}
                      installmentAmount={tpaMatrix.monthlyAmount}
                      installmentDate={tpaMatrix.paymentDate}
                      isFirstInstallment={!index}
                      isPaymentCompleted={tpaMatrix?.isPaymentCompleted}
                    />
                  ),
              )}
          </Grid>
          <Grid>
            <Typography
              variant={'h5'}
              component={'i'}
              className={classes.hintText}
            >
              {t('PAYMENT_PLAN_PROGRESS_TEXT_GRAY_FONT')}
            </Typography>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.accountCard}>
        <CardContent className={classes.paymentContainer}>
          <Grid container>
            <Box>
              {installmentPlan && (
                <Typography variant={'h6'}>
                  {richT('PAYMENT_CARD_DUE_TEXT', {
                    AMOUNT: `${installmentPlan[0]?.monthlyAmount}`,
                    DUE_DATE: `${toDateStringFullMonthName(
                      installmentPlan[0]?.paymentDate,
                    )}`,
                  })}
                </Typography>
              )}
            </Box>
          </Grid>
        </CardContent>
      </Card>
      <Grid className={classes.footerButton}>
        <ProceedOrCancel
          proceedDisabled={false}
          proceedLabel={t('TPA_STEPPER_LABEL_4')}
          proceedHandler={handleOnMakePaymentClick}
          cancelRoute={false} //TODO: update with actual routes
        />
      </Grid>
    </>
  );
};
export default TPAConfirmPlan;
