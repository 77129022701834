import { Router } from '@reach/router';
import React from 'react';
import ROUTES from '../../routes';
import EvRebatePage from './sgtb-ev-rebate';
import PeakTimesRebateGivingBackPage from './peak-time-rebates-giving-back';
import MedicalCertificatePage from './medical-certificate';

export const ProgramsRouter = () => {
  return (
    <Router basepath="/">
      <PeakTimesRebateGivingBackPage
        path={`${ROUTES.PEAKTIME_REBATES_GIVING_BACK}/*`}
      />
      <EvRebatePage path={`${ROUTES.EV_REBATE_FORM}/*`} />
      <MedicalCertificatePage path={`${ROUTES.MEDICAL_CERTIFICATE}/*`} />
    </Router>
  );
};
