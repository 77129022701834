import {
  validateAccountNumber,
  validateNameField,
  validatePhoneNumber,
} from '../../../util/form-validation';
import { convertValidationRules } from '../../../hooks/useFormState';
import { BaseValidationContext } from '../../../hooks/useFormState.types';

export type Model = {
  accountNumber: string;
  businessName: string;
  ein: string;
  phoneNumber: string;
  verificationType: 'phoneNumber' | 'ein';
};

export const defaultValues: Model = {
  accountNumber: '',
  businessName: '',
  ein: '',
  phoneNumber: '',
  verificationType: 'ein',
};

type AdditionalContext = {
  t: (translation: string) => string;
};

function validateEIN(ein: string, context: AdditionalContext) {
  if (ein.length < 10) {
    return context.t('ENTER_EIN_NUMBER');
  }
  return null;
}

export default convertValidationRules<Model, AdditionalContext>(
  ({ values, t }: BaseValidationContext<Model> & AdditionalContext) => ({
    accountNumber: validateAccountNumber,
    businessName: validateNameField.bind(null, t('BUSINESS_NAME')),
    ein: values.verificationType === 'ein' ? validateEIN : null,
    phoneNumber:
      values.verificationType === 'phoneNumber' ? validatePhoneNumber : null,
  }),
);
