import {
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { FormState, PropsFactory } from '../../../hooks/useFormState.types';
import { DateAndAdress } from '../../../hooks/useSSMStopService';
import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) => ({
  questionHeading: {
    fontSize: '20px',
    marginTop: '15px',
    lineHeight: '30px',
    marginBottom: '15px',
    color: colors.noirBlur,
  },
  helperText: {
    color: colors.roseRed,
  },
  root: {
    '& label': {
      color: colors.roseRed,
    },
  },
}));

const SSMStayBehind = (props: any) => {
  const classes = useStyles();

  const { formState } = props;
  const { t } = useTranslation();

  return (
    <FormControl className={formState.error && classes.root}>
      <Typography variant="h2" className={classes.questionHeading}>
        <b>{t('SOMEONE_STAYING_BEHIND')}</b>
      </Typography>
      <RadioGroup name="stayingBehind" {...formState} id={'staybehind'}>
        <FormControlLabel
          value={t('YES')}
          control={
            <Radio
              color={'primary'}
              id={'stayBehindY'}
              className={formState.error && classes.helperText}
            />
          }
          label={t('YES')}
        />
        <FormControlLabel
          value={t('NO')}
          control={
            <Radio
              color={'primary'}
              id={'stayBehindN'}
              className={formState.error && classes.helperText}
            />
          }
          label={t('NO')}
        />
      </RadioGroup>
      {formState.error && (
        <Typography variant="subtitle1" className={classes.helperText}>
          {formState.helperText}
        </Typography>
      )}
    </FormControl>
  );
};

export default SSMStayBehind;
