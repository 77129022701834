import { createStyles, makeStyles, Theme } from '@material-ui/core';
import colors from '../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionArea: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      justtifyContent: 'flex-end',
    },
    gridDivider: {
      margin: theme.spacing(2, 0),
    },
    inputField: {
      [theme.breakpoints.up('md')]: { width: '40% !important' },
    },
    backLinkDesktop: {
      display: 'inline-block',
      marginRight: 20,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    backLinkMobile: {
      display: 'none',
      margin: 20,
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    submitButton: {
      paddingLeft: 25,
      paddingRight: 25,
      marginLeft: 10,
      fontSize: 15,
      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
        width: '100%',
      },
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    colorTextError: {
      color: colors.roseRed,
    },
    submit: {
      display: 'flex',
      marginTop: '10px',
      margin: theme.spacing(3, 0, 2),
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: 'auto',
        justifyContent: 'center',
      },
    },
    backButton: {
      color: '#fff',
      // '&:hover': {
      // }
    },
    buttonWrapper: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    buttonContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column-reverse',
      },
      // [theme.breakpoints.down('xs')]: {
      //   flexDirection: 'column',
      // },
    },
    imageWrapper: {
      [theme.breakpoints.down('xs')]: {
        display: 'flex',
        justifyContent: 'left',
      },
    },
  }),
);

export default useStyles;
