import {
  Container,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { navigate, RouteComponentProps, Router } from '@reach/router';
import React, { FC, useEffect, useState } from 'react';
import ProgressTracker from '../../../components/ProgressTracker';
import Page2ColumnLayout from '../../../components/utility/page2-column-layout';
import { Wizard } from '../../../components/utility/wizard/Wizard';
import { WizardStep } from '../../../components/utility/wizard/WizardStep';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import { SSMChooseAccount } from '../../../components/ssm/common/SSMChooseAccount';
import {
  createAddressAndDateFormAdapter,
  SSMAddressAndDateForm,
  createAddressAndDateValidateFunction,
} from '../../../components/ssm/common/SSMAddressAndDateForm';
import {
  SSMHomeDetailsForm,
  createSSMMoveHomeDetailsFormAdapter,
  createHomeDetailsValidateFunction,
} from '../../../components/ssm/common/SSMHomeDetailsForm';
import {
  createSSMStayBehindValidation,
  createStayBehindFormAdapter,
  SSMTransferPeopleToServiceComponent,
} from '../../../components/ssm/move/SSMTransferPeopleToServiceComponent';
import {
  SSMStopCurrentService,
  createSSMMoveStopDateForm,
  createSSMMoveStopValidateFunction,
} from '../../../components/ssm/move/SSMStopCurrentService';
import { SSMServiceSummary } from '../../../components/ssm/common/SSMServiceSummary';
import { SSMMoveServiceSuccess } from '../../../components/ssm/move/SSMMoveServiceSuccess';
import useAccountCustomer from '../../../hooks/useAccountCustomer';

import {
  AccountInfo,
  SSMStopCurrentServiceDate,
  ServiceSummary,
  ServiceSummaryType,
  AddressAndDate,
  HomeDetails,
  Meter,
  SSMMoveStayBehind,
} from '../../../components/ssm/ssm.types';

import useValidatePremiseQuery from '../../../hooks/useValidatePremiseQuery';
import {
  EligibilityInfo,
  PremiseEligibility,
} from '../../../components/ssm/common/PremiseEligibility/PremiseEligibility';
import Backdrop from '../../../components/backdrop';
import useUtil from '../../../components/need-more-time-to-pay/useUtil';
import {
  ValidatePremiseResponse,
  PremiseDetail,
  Maybe,
  QuickAddressSearchParams,
  MoveSvcEligibilityResponse,
  OtherPeopleResponse,
  SearchServiceAddress,
  CustomerContactInput,
  StartMoveEligibility,
} from '../../../__generated__/pge-types';
import { QasResult } from '../../../hooks/useQas';
import { SSMConfirmDetails } from '../../../components/ssm/move/SSMConfirmDetails';
import useSSMMoveGetAccountEligibilityQuery from '../../../hooks/useSSMMoveGetAccountEligibilityQuery';
import { SSMMoveServiceIneligible } from '../../../components/ssm/move/SSMMoveServiceIneligible';
import useSSMMoveService from '../../../hooks/useSSMMoveService';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { usePremiseSearchQuery } from '../../../hooks/usePremiseSearchQuery';
import useSelectedGroupId from '../../../hooks/useSelectedGroupId';
import useAccountListHeader from '../../../components/account-summary/multi-accounts/useAccountListHeader';
import useAccountDetailList from '../../../hooks/useAccountDetailList';
import { getAccounts } from '../../../components/ssm/common/queries';
import useMainCustomerQuery from '../../../hooks/useMainCustomerQuery';
import { useCreateCustomerContact } from '../../../hooks/useCreateCustomerContact';
import {
  CustContactCharacteristicType,
  CustContactCharacteristicValue,
  CustContactClass,
  CustContactType,
} from '../../../hooks/useSSMStopService';
import { displayPhoneNumberFormat } from '../../../util/format';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 3, 16, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 0, 10, 0),
      },
    },
    mobileSummary: {
      padding: '2em',
      paddingTop: '0',
      marginTop: '-1px',
    },
    fullWidth: {
      width: '100%',
    },
    containerWidth: {
      maxWidth: '450px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      '& .MuiStepper-horizontal': {
        padding: 0,
        marginBottom: theme.spacing(2),
      },
    },
    stepTitle: {
      marginBottom: theme.spacing(3),
    },
    successContainerWidth: {
      maxWidth: '820px',
      margin: 'auto',
    },
  }),
);

type ValidatePremiseData = {
  validatePremise: ValidatePremiseResponse[];
};

const SSMMoveService: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setErrorNotification } = useUtil();
  const { wrapWithLoader } = useWrapWithLoader();
  const {
    ssmMoveServiceState,
    setAccountInfo,
    setAddressAndDate,
    doVerifyAddress,
    setHomeDetails,
    doVerifyQASAddress,
    setSsmMoveTransferPeopleToService,
    setSsmStopCurrentServiceDate,
    onConfirm,
    setSSMMoveStayBehind,
    concatenateAddressFields,
    setMoveToEncryptedPremiseIds,
    setMoveFromPremiseIds,
  } = useSSMMoveService();
  const { refetch: validatePremise } = useValidatePremiseQuery();
  const { refetch: checkMainCustomer } = useMainCustomerQuery();
  const { submit: createWebCustomerContact } = useCreateCustomerContact();
  const {
    setPremiseIds,
    loading: premiseSearchLoading,
    error,
    data: premiseInfoData,
  } = usePremiseSearchQuery();
  const {
    customer: customerInfoData,
    loading: customerInfoLoading,
    error: customerInfoError,
  } = useAccountCustomer();

  const {
    refetch: validateAccountAndPersonEligibility,
  } = useSSMMoveGetAccountEligibilityQuery();
  const [loading, setLoading] = useState<boolean>(false);
  const [notMainCustomer, setNotMainCustomer] = useState<boolean>(false);
  const [transferServiceUserList, setTransferServiceUserList] = useState<
    OtherPeopleResponse[]
  >([]);
  const [validatePremiseData, setValidatePremiseData] = useState<
    ValidatePremiseData
  >();

  const [accountAddressInfo, setAccountAddressInfo] = useState<AccountInfo>();
  const [isRenewablesEligible, setIsRenewablesEligible] = useState<boolean>(
    false,
  );

  const pathname = props.location?.pathname;

  const [serviceSummary, setServiceSummary] = useState<ServiceSummary>({
    type: ServiceSummaryType.MOVE,
  });
  const [
    personAndAccountInEligibleData,
    setPersonAndAccountInEligibleData,
  ] = useState<MoveSvcEligibilityResponse>({
    hasActiveDepositAndTPAAgreement: false,
    hasBadDebt: false,
    hasDisconnect: false,
    hasActiveServiceAgreement: false,
    hasEnrolledInEqualpay: false,
    hasFraud: false,
    hasPendingDisconnect: false,
  });

  const [currentPosition, setCurrentPosition] = useState<
    google.maps.LatLngLiteral | undefined
  >();

  const [accountCount, setAccountCount] = useState<Number>();
  const [multiplePremisesIneligible, setMultiplePremisesIneligible] = useState<
    boolean
  >(false);
  const [
    pendingDisconnectIneligible,
    setPendingDisconnectIneligible,
  ] = useState<boolean>(false);
  const [showSameAddressError, setShowSameAddressError] = useState<boolean>(
    false,
  );
  const [encrPersonId, setEncrPersonId] = useState<string>('');
  const [personName, setPersonName] = useState<string>('');

  const [isAddressVerified, setIsAddressVerified] = useState<boolean>(false);
  const [isMeterSelectionVerified, setIsMeterSelectionVerified] = useState<
    boolean
  >(false);

  const [
    isAddressSuggestionModalDisplayed,
    setIsAddressSuggestionModalDisplayed,
  ] = useState<boolean>(false);

  const [autoCompleteAddress, setAutoCompleteAddress] = useState<string>('');

  const [
    isMultipleMetersModalDisplayed,
    setIsMultipleMetersModalDisplayed,
  ] = useState<boolean>(false);

  const [
    isPremiseEligibilityModalDisplayed,
    setIsPremiseEligibilityModalDisplayed,
  ] = useState<boolean>(false);
  const [pnpNumber, setPnpNumber] = useState<string>('');

  const [metersInfo, setMetersInfo] = useState<Meter[]>([]);

  const [eligibilityInfo, setEligibilityInfo] = useState<EligibilityInfo>({
    isEligible: true,
    spTypeNotResidential: false,
    pendingDisconnect: false,
    meterStatusInactive: false,
  });

  const [qasData, setQasData] = useState<null | {
    params: QuickAddressSearchParams;
    results: QasResult;
  }>();

  useEffect(() => {
    if (premiseInfoData && premiseInfoData?.premiseSearch.length > 0) {
      const moveFromPremiseIds = premiseInfoData?.premiseSearch.map(
        premise => premise.premId,
      );
      if (moveFromPremiseIds.length > 0) {
        setMoveFromPremiseIds(moveFromPremiseIds as string[]);
      }
    }
  }, [premiseInfoData]);

  useEffect(() => {
    setServiceSummary(summary => ({
      ...summary,
      ...(ssmMoveServiceState?.accountInfo?.mainCustomerName
        ? { name: ssmMoveServiceState?.accountInfo?.mainCustomerName }
        : {}),
      ...(ssmMoveServiceState?.accountInfo?.accountNumber
        ? { account: ssmMoveServiceState?.accountInfo?.accountNumber }
        : {}),
      ...(ssmMoveServiceState?.accountInfo?.premiseInfo &&
      ssmMoveServiceState?.accountInfo?.premiseInfo[0]
        ? {
            fromAddress: `${ssmMoveServiceState?.accountInfo?.premiseInfo[0].addressLine1}, ${ssmMoveServiceState?.accountInfo?.premiseInfo[0].city}, ${ssmMoveServiceState?.accountInfo?.premiseInfo[0].state}, ${ssmMoveServiceState?.accountInfo?.premiseInfo[0]?.postal}`,
          }
        : {}),
      ...(ssmMoveServiceState?.addressAndDate?.address
        ? { toAddress: ssmMoveServiceState?.addressAndDate?.address }
        : {}),
      ...(ssmMoveServiceState?.addressAndDate?.date
        ? { date: ssmMoveServiceState?.addressAndDate?.date }
        : {}),
      ...(ssmMoveServiceState?.ssmStopCurrentServiceDate.date
        ? { stopDate: ssmMoveServiceState?.ssmStopCurrentServiceDate.date }
        : {}),
    }));
  }, [ssmMoveServiceState]);

  const handleSelectedAccount = wrapWithLoader(
    async (_accountInfo: AccountInfo) => {
      const accountInfo = _accountInfo;
      if (Number(accountInfo?.premiseInfo?.length) > 1) {
        accountInfo.premiseInfo = _accountInfo.premiseInfo?.filter(
          premise => premise?.saDetails![0]?.saStatus !== 'STOPPED',
        );
      }
      let premiseIdArr;
      if (accountInfo?.premiseInfo?.length) {
        premiseIdArr = accountInfo?.premiseInfo?.map(
          (premise: any) => premise?.encryptedPremiseId,
        );
        setPremiseIds(premiseIdArr);
      }

      setAccountAddressInfo(accountInfo);

      if (customerInfoData && Object.keys(customerInfoData).length > 0) {
        const mobNumber = customerInfoData.contactDetails?.filter(
          contact => contact?.contactType === 'MOB',
        );
        const _pnpNumber = customerInfoData.contactDetails?.filter(
          contact => contact?.contactType === 'PNP',
        );
        if (mobNumber && mobNumber?.length > 0) {
          setHomeDetails({
            registeredMobileNumber: mobNumber[0]?.contactValue?.replace(
              /[^0-9]/g,
              '',
            ),
          });
        }
        if (_pnpNumber && _pnpNumber?.length > 0) {
          setPnpNumber(
            displayPhoneNumberFormat(
              _pnpNumber[0]?.contactValue?.replace(/[^0-9]/g, '') || '',
            ),
          );
        }
        setEncrPersonId(customerInfoData.encryptedPersonId);
        const perId = customerInfoData.personId;
        const encryptedPersonId = customerInfoData.encryptedPersonId;
        const acctId = accountInfo.accountNumber || '';
        const encryptedPremId = premiseIdArr && premiseIdArr[0];
        const result = await checkMainCustomer({
          params: {
            accountNumberList: [
              {
                accountNumber: acctId,
                encryptedPersonId,
              },
            ],
            encryptedPersonId,
          },
        });

        if (
          !result.loading &&
          !result.errors &&
          Object.keys(result?.data?.getAccountDetails).length > 0
        ) {
          if (result.data.getAccountDetails[0].relationType !== 'MAIN') {
            const payload: CustomerContactInput = {
              accountNumber: '',
              longDescription:
                'Not eligible for move because not main customer',
              encryptedPersonId: customerInfoData.encryptedPersonId,
              contactClass: CustContactClass.WSS,
              contactType: CustContactType.WEBCK,
              characteristics: [
                {
                  type: CustContactCharacteristicType.CMCHNNL,
                  value: CustContactCharacteristicValue.CSWEB,
                  action: '',
                },
              ],
              shouldPrintLetter: false,
            };
            const ccResult = await createWebCustomerContact(payload);
            setContinueLoading(false);
            if (ccResult?.errors) {
              setErrorNotification(true);
            }
            setNotMainCustomer(true);
            void navigate(ROUTES.SSM_MOVE_INELIGIBLE, { replace: true });
            return false;
          }
        } else if (result.errors) {
          setErrorNotification(true);
        }

        const response = await validateAccountAndPersonEligibility({
          param: {
            perId,
            acctId,
            encryptedPremId,
          },
        });

        if (
          !response.loading &&
          !response.errors &&
          Object.keys(response.data).length > 0
        ) {
          const moveSvcEligibilityData = response.data.moveSvcEligibility;
          setPersonAndAccountInEligibleData(moveSvcEligibilityData);
          const { hasActiveServiceAgreement, ...rest } = moveSvcEligibilityData;
          const isInEligible = Object.values(rest).some(k => k === true);
          if (isInEligible || !hasActiveServiceAgreement) {
            setContinueLoading(false);
            await navigate(ROUTES.SSM_MOVE_INELIGIBLE, { replace: true });
          } else {
            setContinueLoading(false);
            await navigate(ROUTES.SSM_MOVE_ADDRESS, {
              replace: totalCount === 1 ? true : false,
            });
          }
        } else if (response.errors) {
          setErrorNotification(true);
        }
        setAccountInfo(accountInfo);
        return true;
      }
      return false;
    },
  );

  const handleAddressAndDate = async (addressAndDate: AddressAndDate) => {
    if (showSameAddressError) {
      return false;
    }
    if (multiplePremisesIneligible) {
      setLoading(true);
      const payload = {
        accountNumber: '',
        longDescription: `Multiple Premises, Address: ${autoCompleteAddress}`,
        encryptedPersonId: encrPersonId,
        contactClass: CustContactClass.WSS,
        contactType: CustContactType.WEBCK,
        characteristics: [
          {
            type: CustContactCharacteristicType.CMCHNNL,
            value: CustContactCharacteristicValue.CSWEB,
            action: '',
          },
        ],
        shouldPrintLetter: false,
      };
      const result = await createWebCustomerContact(payload);
      if (!result?.errors && result?.data?.createWebCustomerContact) {
        setLoading(false);
      }
      if (result?.errors) {
        setLoading(false);
        setErrorNotification(true);
      }
      setServiceSummary({
        type: ServiceSummaryType.MOVE,
        ...(ssmMoveServiceState?.accountInfo?.mainCustomerName
          ? { name: ssmMoveServiceState?.accountInfo?.mainCustomerName }
          : {}),
        ...(ssmMoveServiceState?.accountInfo?.accountNumber
          ? { account: ssmMoveServiceState?.accountInfo?.accountNumber }
          : {}),
        ...(ssmMoveServiceState?.accountInfo?.premiseInfo &&
        ssmMoveServiceState?.accountInfo?.premiseInfo[0]
          ? {
              fromAddress:
                ssmMoveServiceState?.accountInfo?.premiseInfo[0].addressLine1,
            }
          : {}),
      });
      void navigate(ROUTES.SSM_MOVE_INELIGIBLE, { replace: true });
      return false;
    }
    if (
      await doVerifyAddress(
        isAddressVerified,
        autoCompleteAddress,
        setIsAddressSuggestionModalDisplayed,
        setIsMultipleMetersModalDisplayed,
        metersInfo,
        isMeterSelectionVerified,
        addressAndDate.address,
      )
    ) {
      try {
        setLoading(true);
        let proceedNext: boolean = false;
        const payload: Array<Maybe<PremiseDetail>> = [];
        const isEveryMeterDisabled = metersInfo.every(
          (meter: Meter) => meter.disabled === true,
        );
        metersInfo.forEach((meter: Meter) => {
          let item: Maybe<PremiseDetail>;
          const index = payload.findIndex(
            payloadItem => payloadItem?.premId === meter.premiseId,
          );
          if (index === -1) {
            item = {
              premId: meter.premiseId,
              spList: [meter.spId],
            };
            if (!meter.disabled && meter.checked && !isEveryMeterDisabled) {
              payload.push(item);
            } else if (isEveryMeterDisabled) {
              payload.push(item);
            }
          } else {
            item = {
              premId: meter.premiseId,
              spList: [...payload[index]!.spList, meter.spId],
            };
            if (!meter.disabled && meter.checked && !isEveryMeterDisabled) {
              payload[index] = item;
            } else if (isEveryMeterDisabled) {
              payload[index] = item;
            }
          }
        });

        const result = await validatePremise({
          payload: {
            perId: customerInfoData?.personId!,
            premiseDetails: payload,
            serviceAddress: addressAndDate?.address?.addressLine1,
            validateDate: String(
              moment(addressAndDate.date).format('YYYY-MM-DD'),
            ),
          },
        });
        if (
          !result.loading &&
          !result.errors &&
          result.data.validatePremise.length > 0
        ) {
          if (
            result.data.validatePremise[0].eligibility ===
            StartMoveEligibility.InEligible
          ) {
            setPendingDisconnectIneligible(true);
            setLoading(false);
            void navigate(ROUTES.SSM_MOVE_INELIGIBLE);
            return false;
          } else {
            setPendingDisconnectIneligible(false);
          }
          setValidatePremiseData(result.data);
          const ltSpIds = metersInfo
            .filter(
              (meter: Meter) =>
                meter.spType === 'E-UB-LT' && meter.isAreaLight === true,
            )
            .map((meter: Meter) => meter.spId);
          setLoading(false);
          if (result.data.validatePremise.length > 0) {
            const _eligibilityInfo: EligibilityInfo = {
              isEligible: true,
              spTypeNotResidential: false,
              pendingDisconnect: false,
              meterStatusInactive: false,
            };
            result.data.validatePremise.forEach(premise => {
              premise.spList?.some(sp => {
                if (!ltSpIds.includes(sp?.spId || '')) {
                  if (sp?.meterStatusInactive) {
                    _eligibilityInfo.isEligible = false;
                    _eligibilityInfo.meterStatusInactive = true;
                  }
                  if (sp?.pendingDisconnect) {
                    _eligibilityInfo.isEligible = false;
                    _eligibilityInfo.pendingDisconnect = true;
                  }
                  if (sp?.spTypeNotResidential) {
                    _eligibilityInfo.isEligible = false;
                    _eligibilityInfo.spTypeNotResidential = true;
                  }
                }
              });
              setIsPremiseEligibilityModalDisplayed(
                !_eligibilityInfo.isEligible,
              );
              setEligibilityInfo(_eligibilityInfo);
              if (_eligibilityInfo.isEligible) {
                proceedNext = true;
              }
            });
          }
        }
        setAddressAndDate(addressAndDate);
        if (proceedNext) {
          return true;
        }
      } catch {
        setLoading(false);
        setErrorNotification(true);
      }
    }
    return false;
  };

  const handleMoveToAddress = (address: SearchServiceAddress) => {
    if (address?.premiseIds) {
      setMoveToEncryptedPremiseIds(address?.premiseIds);
    }
  };

  const handleHomeDetails = wrapWithLoader(async (homeDetails: HomeDetails) => {
    const { sameAsServiceAddress } = homeDetails;
    if (!sameAsServiceAddress) {
      try {
        const data = await doVerifyQASAddress(homeDetails);
        setQasData(state => ({
          ...state,
          results: data?.results,
          params: data?.params,
        }));
        if (data.results.type === 'AUTO') {
          setHomeDetails({ ...homeDetails, mailQasVerified: true });
          void navigate(ROUTES.SSM_MOVE_TRANSFER_PEOPLE_TO_SERVICE);
          return true;
        } else {
          return false;
        }
      } catch {
        setErrorNotification(true);
        return false;
      }
    } else {
      setHomeDetails(homeDetails);
      return true;
    }
  });

  const handleTransferPeopleToService = async (
    stayingBehind: SSMMoveStayBehind,
  ) => {
    await setSsmMoveTransferPeopleToService(transferServiceUserList);
    await setSSMMoveStayBehind(stayingBehind);
    return true;
  };

  const handleStopCurrentServiceDate = async (
    ssmStopCurrentServiceDate: SSMStopCurrentServiceDate,
  ) => {
    await setSsmStopCurrentServiceDate(ssmStopCurrentServiceDate);
    return true;
  };

  const handleConfirm = wrapWithLoader(async () => {
    const result = await onConfirm(metersInfo);
    if (result?.data?.moveSvcSubmit?.isSuccessfull) {
      return true;
    } else {
      setErrorNotification(true);
      return false;
    }
  });

  const { selectedGroupId } = useSelectedGroupId();
  const { accountListParams } = useAccountListHeader();

  const { accounts, totalCount } = useAccountDetailList(
    getAccounts,
    selectedGroupId,
    {
      accountListParams: {
        ...accountListParams,
      },
    },
  );

  const [continueLoading, setContinueLoading] = useState(true);

  useEffect(() => {
    const totalAccounts = customerInfoData?.accountMeta?.totalAccounts;
    if (totalAccounts === 0) {
      setLoading(false);
      setContinueLoading(false);
      void navigate(ROUTES.SSM_START_ADDRESS);
    } else {
      if (totalCount === undefined) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        void navigate(ROUTES.SSM_MOVE, { replace: true });
      } else if (
        pathname !== ROUTES.SSM_MOVE_SUCCESS &&
        totalCount &&
        totalCount !== 1 &&
        customerInfoLoading === false
      ) {
        void navigate(ROUTES.SSM_MOVE_CHOOSE_ACCOUNT, { replace: true });
      } else if (accounts.length && customerInfoLoading === false) {
        void handleSelectedAccount(accounts[0] as AccountInfo);
      }
    }
  }, [totalCount, customerInfoLoading]);

  const steps = [
    {
      path: ROUTES.SSM_MOVE_CHOOSE_ACCOUNT,
      title: t('WHICH_ACCOUNT_IS_MOVING'),
      wizardStep: (
        <WizardStep
          initial={ssmMoveServiceState.accountInfo}
          component={SSMChooseAccount}
          onNext={handleSelectedAccount}
          nextButton={false}
          componentProps={{
            handleSelectedAccount,
            setAccountCount,
            setContinueLoading,
          }}
        ></WizardStep>
      ),
      progressStep: {
        label: t('CHOOSE_ACCOUNT'),
        isComplete:
          !!ssmMoveServiceState?.accountInfo?.address &&
          !!ssmMoveServiceState?.accountInfo?.accountNumber,
      },
    },
    {
      path: ROUTES.SSM_MOVE_ADDRESS,
      title: t('WHERE_DO_YOU_NEED_SERVICE'),
      wizardStep: (
        <WizardStep
          initial={ssmMoveServiceState.addressAndDate}
          onNext={handleAddressAndDate}
          backRoute={
            accountCount && accountCount > 1
              ? ROUTES.SSM_MOVE_CHOOSE_ACCOUNT
              : ROUTES.SSM_HOME
          }
          component={SSMAddressAndDateForm}
          formAdapter={createAddressAndDateFormAdapter()}
          validate={createAddressAndDateValidateFunction()}
          componentProps={{
            isSSMMoveFlow: true,
            isAddressVerified,
            setIsAddressVerified,
            isMeterSelectionVerified,
            setIsMeterSelectionVerified,
            isAddressSuggestionModalDisplayed,
            setIsAddressSuggestionModalDisplayed,
            autoCompleteAddress,
            setAutoCompleteAddress,
            isMultipleMetersModalDisplayed,
            setIsMultipleMetersModalDisplayed,
            metersInfo,
            setMetersInfo,
            currentPosition,
            setCurrentPosition,
            handleMoveToAddress,
            setMultiplePremisesIneligible,
            setPersonName,
            showSameAddressError,
            setShowSameAddressError,
            fromAddr: accountAddressInfo?.premiseInfo
              ? accountAddressInfo?.premiseInfo[0]
              : {},
          }}
        ></WizardStep>
      ),
      progressStep: {
        label: `${t('ADDRESS')} ${t('DETAILS')}`,
      },
    },
    {
      path: ROUTES.SSM_MOVE_HOME,
      title: t('TELL_US_ABOUT_YOUR_HOME'),
      wizardStep: (
        <WizardStep
          initial={ssmMoveServiceState.homeDetails}
          onNext={handleHomeDetails}
          onBack={concatenateAddressFields}
          component={SSMHomeDetailsForm}
          componentProps={{
            isSSMMoveFlow: true,
            qasData,
            setQasData,
            moveServiceAddress: ssmMoveServiceState.addressAndDate.address,
            setHomeDetails,
            mobNumber:
              ssmMoveServiceState.homeDetails.registeredMobileNumber || '',
            setIsRenewablesEligible,
            isRenewablesEligible,
          }}
          formAdapter={createSSMMoveHomeDetailsFormAdapter()}
          validate={createHomeDetailsValidateFunction()}
        ></WizardStep>
      ),
      progressStep: {
        label: `${t('HOME')} ${t('DETAILS')}`,
      },
    },
    {
      path: ROUTES.SSM_MOVE_TRANSFER_PEOPLE_TO_SERVICE,
      title: t('TRANSFER_OTHERS_ON_YOUR_ACCOUNT'),
      wizardStep: (
        <WizardStep
          initial={ssmMoveServiceState.stayingBehindInfo}
          onNext={handleTransferPeopleToService}
          component={SSMTransferPeopleToServiceComponent}
          componentProps={{
            transferServiceUserList: transferServiceUserList,
            setTransferServiceUserList: setTransferServiceUserList,
          }}
          formAdapter={createStayBehindFormAdapter()}
          validate={createSSMStayBehindValidation()}
        ></WizardStep>
      ),
      progressStep: {
        label: t('ACCOUNT_USERS'),
      },
    },
    {
      path: ROUTES.SSM_MOVE_STOP_CURRENT_SERVICE,
      title: t('SSM_MOVE_STOP_YOUR_CURRENT_SERVICE'),
      wizardStep: (
        <WizardStep
          initial={ssmMoveServiceState.ssmStopCurrentServiceDate}
          onNext={handleStopCurrentServiceDate}
          component={SSMStopCurrentService}
          componentProps={{
            accountInfo: ssmMoveServiceState?.accountInfo,
          }}
          formAdapter={createSSMMoveStopDateForm()}
          validate={createSSMMoveStopValidateFunction()}
        ></WizardStep>
      ),
      progressStep: {
        label: t('STOP_CURRENT_SERVICE'),
        isComplete: !!ssmMoveServiceState.ssmStopCurrentServiceDate?.date,
      },
    },
    {
      path: ROUTES.SSM_MOVE_CONFIRM,
      wizardStep: (
        <WizardStep
          initial={ssmMoveServiceState}
          onNext={handleConfirm}
          nextText={t('SUBMIT')}
          nextRoute={ROUTES.SSM_MOVE_SUCCESS}
          component={SSMConfirmDetails}
          componentProps={{
            metersInfo,
            ssmMoveServiceState,
            isRenewablesEligible,
          }}
        ></WizardStep>
      ),
      progressStep: {
        label: `${t('CONFIRM')} ${t('DETAILS')}`,
        isComplete: false,
      },
    },
  ];

  return (
    <>
      {loading ? <Backdrop forceOpen message={t('LOADING')} /> : null}
      {continueLoading ? <Backdrop forceOpen message={t('LOADING')} /> : null}
      {pathname !== ROUTES.SSM_MOVE_SUCCESS && (
        <Hidden smUp>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className={classes.mobileSummary}
          >
            <Grid item container>
              <SSMServiceSummary
                summary={serviceSummary}
                isPulldown={true}
                service="MOVE"
              />
            </Grid>
          </Grid>
        </Hidden>
      )}
      {pathname &&
      ![ROUTES.SSM_MOVE_SUCCESS, ROUTES.SSM_MOVE_INELIGIBLE].includes(
        pathname,
      ) ? (
        <Container maxWidth="lg" className={classes.root}>
          <Page2ColumnLayout
            leftColumn={
              !window.location.pathname.includes(
                ROUTES.SSM_MOVE_CHOOSE_ACCOUNT,
              ) && window.location.pathname !== ROUTES.SSM_MOVE ? (
                <SSMServiceSummary summary={serviceSummary} />
              ) : (
                <></>
              )
            }
          >
            <Grid
              item
              direction="column"
              spacing={2}
              xs={12}
              justify={'center'}
              alignItems={'center'}
              className={classes.containerWidth}
            >
              <Grid
                item
                container
                direction="column"
                xs={12}
                justify={'center'}
                alignItems={'center'}
              >
                <Hidden smDown>
                  <Grid item className={classes.stepTitle}>
                    <Typography variant={'h1'} align={'center'}>
                      {steps.find(s => s.path === pathname)?.title ?? ''}
                    </Typography>
                  </Grid>
                </Hidden>

                <Grid item container justify="center" xs={12}>
                  {window.location.pathname !== ROUTES.SSM_MOVE ? (
                    <ProgressTracker
                      steps={steps.map(({ progressStep, path }, index) => ({
                        ...progressStep,
                        isActive: path === pathname,
                        isComplete:
                          progressStep.isComplete ||
                          steps.map(step => step.path).indexOf(pathname || '') >
                            index,
                      }))}
                    />
                  ) : null}
                </Grid>
                <Grid
                  item
                  justify="center"
                  xs={12}
                  className={classes.fullWidth}
                >
                  <Wizard basepath="/">
                    {steps.map(({ wizardStep, path, title }, index) => {
                      return (
                        <wizardStep.type
                          {...{
                            ...wizardStep.props,
                            path: path,
                            key: path,
                            title,
                            backRoute:
                              wizardStep.props.backRoute ??
                              (index > 0 ? steps[index - 1].path : false),
                            nextRoute:
                              wizardStep.props.nextRoute ??
                              (index < steps.length - 1
                                ? steps[index + 1].path
                                : undefined),
                          }}
                        />
                      );
                    })}
                  </Wizard>
                </Grid>
              </Grid>
            </Grid>
          </Page2ColumnLayout>
        </Container>
      ) : (
        <Container maxWidth="lg">
          <Grid
            item
            direction="column"
            xs={12}
            justify={'center'}
            alignItems={'center'}
          >
            <Grid
              item
              container
              direction="column"
              xs={12}
              justify={'center'}
              alignItems={'center'}
            >
              <Grid item justify="center" xs={12} className={classes.fullWidth}>
                <Router basepath="/">
                  <SSMMoveServiceSuccess
                    path={ROUTES.SSM_MOVE_SUCCESS}
                    ssmMoveServiceState={ssmMoveServiceState}
                    pnpNumber={pnpNumber}
                  />

                  <SSMMoveServiceIneligible
                    path={ROUTES.SSM_MOVE_INELIGIBLE}
                    personAndAccountInEligibleData={
                      personAndAccountInEligibleData
                    }
                    setPersonAndAccountInEligibleData={
                      setPersonAndAccountInEligibleData
                    }
                    notMainCustomer={notMainCustomer}
                    multiplePremisesIneligible={multiplePremisesIneligible}
                    serviceAddress={`${accountAddressInfo?.premiseInfo!?.[0]
                      ?.addressLine1 ||
                      ''}, ${accountAddressInfo?.premiseInfo!?.[0]?.city ||
                      ''}, ${accountAddressInfo?.premiseInfo!?.[0]?.postal ||
                      ''}`}
                    serviceSummary={serviceSummary}
                    pendingDisconnectIneligible={pendingDisconnectIneligible}
                  />
                </Router>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )}
      <PremiseEligibility
        isOpen={isPremiseEligibilityModalDisplayed}
        handleClose={() => setIsPremiseEligibilityModalDisplayed(false)}
        userAddress={autoCompleteAddress || ''}
        eligibilityInfo={eligibilityInfo}
      />
    </>
  );
};

export default SSMMoveService;
