import React from 'react';
import { Typography, CardActionArea } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { CheckCircle } from '@material-ui/icons';
import colors from '../../themes/main-colors';

interface ProductCardProps {
  image?: string;
  title: string;
  handleOnClick: (event: React.MouseEvent<HTMLElement>) => void;
  isActive: boolean;
}

export default function ProductCard({
  title,
  image,
  handleOnClick,
  isActive,
}: ProductCardProps) {
  const selectedBox = {
    border: '1px solid ' + colors.brightBlue,
    backgroundColor: colors.lightBlue,
  };

  return (
    <Card
      raised={false}
      onClick={handleOnClick}
      component="div"
      css={{
        cursor: 'pointer',
        boxShadow: 'none',
        padding: 10,
        position: 'relative',
        height: '100%',
        border: '1px solid ' + colors.gray300,
        borderRadius: '5px',
        ...(!image
          ? {
              display: 'flex',
              justifyContent: 'center',
              minHeight: '245px',
            }
          : { display: 'flex', flexDirection: 'column' }),
        ...(isActive ? selectedBox : {}),
      }}
    >
      {isActive && (
        <CheckCircle
          css={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            width: '18px',
            height: '18px',
          }}
          htmlColor={colors.shoreGreen}
        />
      )}
      {image && (
        <CardMedia
          component="img"
          height="165"
          image={image}
          alt={title}
          css={{ borderRadius: '5px', backgroundColor: colors.lightGray1 }}
        />
      )}
      <CardContent
        css={{
          ...(!image
            ? {
                display: 'flex',
                alignItems: 'center',
              }
            : {
                padding: 0,
                paddingBottom: '0 !important',
                marginTop: '5px',
                flexGrow: 1,
              }),
        }}
      >
        <Typography
          variant="h4"
          css={{
            textAlign: 'center',
            height: '100%',
            fontSize: '1rem',
            lineHeight: '20px !important',
            color: isActive ? colors.brightBlue : '#1c304a',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
            fontFamily: 'Untitled-Sans, regular',
          }}
        >
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
}
