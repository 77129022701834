/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';
import Buttons from '../../buttons';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';
import useContentMgmt from '../../../hooks/useContentMgmt';
import microcopyGroupIds from '../microcopyGroupIds';

export type ProceedOrCancelButtonProps = {
  back: string | false;
  backRouteState?: any;
  next?: boolean;
  isCancel?: boolean | false;
  nextLabel?: string;
  backLabel?: string;
  disable?: boolean | false;
};

const ProceedOrCancelButton = ({
  back,
  backRouteState,
  next = true,
  isCancel,
  nextLabel = '',
  backLabel = '',
  disable = false,
}: ProceedOrCancelButtonProps) => {
  const { content } = useContentMgmt(microcopyGroupIds.PGE_PLUS_REUSABLE_SLUGS);

  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <Grid
      container
      spacing={2}
      css={{
        marginTop: 24,
        marginBottom: 24,
        justifyContent: 'center',
        ...((!back && isCancel) || !next
          ? {
              display: 'flex',
              justifyContent: 'center',
            }
          : {}),
      }}
    >
      {back && (
        <Grid item xs={6} md={3}>
          <Link to={back} state={backRouteState}>
            <Buttons
              type="button"
              fullWidth
              variant="outlined"
              color="secondary"
              css={{
                fontSize: '18px',
                borderRadius: '30px',
                '.MuiButton-label': {
                  fontWeight: 500,
                },
                '&:hover': {
                  borderWidth: '2px',
                },
              }}
            >
              {Boolean(backLabel)
                ? backLabel
                : content.get('REUSABLE_BUTTON_BACK')}
            </Buttons>
          </Link>
        </Grid>
      )}
      {next && (
        <Grid
          item
          xs={back ? 6 : isCancel ? 6 : 12}
          md={back ? 3 : isCancel ? 3 : 6}
        >
          <Buttons
            type="submit"
            fullWidth
            variant="contained"
            disabled={disable}
            css={{
              fontSize: '18px',
              borderRadius: '30px',
              '.MuiButton-label': {
                fontWeight: 500,
              },
            }}
          >
            {Boolean(nextLabel)
              ? nextLabel
              : content.get('REUSABLE_BUTTON_NEXT')}
          </Buttons>
        </Grid>
      )}
    </Grid>
  );
};

export default ProceedOrCancelButton;
