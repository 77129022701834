import { DocumentNode } from 'graphql';
import useAuthQuery from './useAuthQuery';
import {
  ViewPaymentHistoryDetail,
  AccountParams,
  AccountDetail,
} from '../__generated__/pge-types';
import { PageSize } from '../components/pagination/types';
import { getAccountDetails } from '../components/closed-accounts/queries';
import { getAccountDetailsForPaymentHistoryDetails } from '../queries/paymentHistory.query';

type Arguments = {
  closedAccountsHistoryParams: {
    page: number;
    pageSize: PageSize | number;
    accountParams: AccountParams | null;
  };
};

export default function useClosedAccountsPaymentHistory({
  closedAccountsHistoryParams,
}: Arguments) {
  const {
    page = 1,
    pageSize = 15,
    accountParams,
  } = closedAccountsHistoryParams;

  const { data } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetails, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !Boolean(accountParams),
  });

  const selectedAccount = data?.getAccountDetails?.[0];
  const { loading, data: historyData, error } = useAuthQuery(
    getAccountDetailsForPaymentHistoryDetails,
    {
      variables: {
        params: {
          accountNumberList: [accountParams],
        },
        paymentHistoryParams: {
          pagingParams: {
            limit: pageSize,
            offset: (page - 1) * Number(pageSize),
          },
        },
      },
      skip: !Boolean(accountParams),
    },
  );
  const historyDetails = historyData?.getAccountDetails?.[0].paymentHistory;

  const totalDetailsRecords = historyDetails?.totalDetailsRecords;
  const paymentHistoryDetails = historyDetails?.paymentHistoryDetails as ViewPaymentHistoryDetail[];

  return {
    historyLoading: loading,
    historyData: paymentHistoryDetails,
    error,
    totalCount: totalDetailsRecords,
    selectedAccount,
  };
}
