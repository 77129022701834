import {
  validateAccountNumber,
  validateNameField,
  validateNicknameField,
  validatePhoneNumber,
} from '../../../util/form-validation';

export default () => ({
  // this must match the names of the form fields
  accountNumber: validateAccountNumber,
  businessName: validateNameField.bind(null, 'businessName'),
  nickname: validateNicknameField.bind(null, 'nickname'),
  ein: () => '',
  phoneNumber: validatePhoneNumber,
});
