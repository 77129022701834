import React, { FunctionComponent } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Theme,
  useTheme,
  Card,
  CardContent,
} from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import useOpenEnrollment from '../../../hooks/useOpenEnrollment';
import Link from '../../text-link';
import PGEButton from '../../buttons/PGE-Button';
import { isMobile } from '../../../util/style-utils';
import { navigate } from '@reach/router';
import ROUTES from '../../../routes';
import PlanSelector from '../../plan-selector';
import Pagination from '../../pagination';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme: Theme) => ({
  actionArea: {
    [theme.breakpoints.down('sm')]: {
      padingLeft: theme.spacing(4),
      padingRight: theme.spacing(4),
    },
  },
  formAction: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface OpenEnrollmentPlanProps {
  path?: string;
}

const OpenEnrollmentPlan: FunctionComponent<OpenEnrollmentPlanProps> = props => {
  const { t } = useTranslation();
  const {
    content,
    accountGroups,
    availablePlans,
    paginationProps,
    records,
    isQuaterlyPeriod,
    effectiveDate,
    selectedGroup,
  } = useOpenEnrollment();

  console.log(paginationProps);

  const accountGroup = accountGroups[0];
  const theme = useTheme();
  const classes = useStyles();

  const planOptions = [
    { id: undefined, name: <em>None</em> },
    ...availablePlans,
  ];

  if (isQuaterlyPeriod) {
    planOptions.splice(2, 1);
  }

  return (
    <Grid container direction={'column'} spacing={3}>
      <Grid item>
        <Typography variant={'h2'}>{content.planPage.title}</Typography>
      </Grid>
      <Grid item>
        <Card>
          <CardContent>
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <Typography variant={'h2'}>
                  {content.planPage.formTitle}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant={'body2'}>
                  {content.planPage.formText
                    .replace(
                      '<effective-date>',
                      dayjs(effectiveDate).format(content.dateFormat),
                    )
                    .replace(
                      '<group-name>',
                      selectedGroup.labels[0].primary || '',
                    )}
                  <a
                    target={'__blank'}
                    href={content.electionPage.open.operationsUrl}
                  >
                    ESS
                  </a>
                  .
                </Typography>
              </Grid>
              <Grid item>
                <Pagination {...paginationProps} />
                <PlanSelector
                  accounts={records}
                  editMode={true}
                  availablePlans={planOptions}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        container
        style={
          isMobile()
            ? { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }
            : {}
        }
        direction={isMobile() ? 'column-reverse' : 'row'}
        justify={'space-between'}
        alignItems={isMobile() ? 'stretch' : 'center'}
        spacing={1}
      >
        <Grid item>
          <Grid container justify={'center'}>
            <Link plain to={ROUTES.ACCOUNT}>
              <Typography className={classes.formAction}>
                {t('CANCEL')}
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction={isMobile() ? 'column-reverse' : 'row'}
            spacing={2}
          >
            <Grid item>
              <PGEButton
                variant={'outlined'}
                color={'secondary'}
                onClick={() => navigate(ROUTES.OPEN_ENROLLMENT_CONTACT)}
                className={classes.formAction}
              >
                {content.back}
              </PGEButton>
            </Grid>
            <Grid item>
              <PGEButton
                variant={'contained'}
                color={'primary'}
                onClick={() => navigate(ROUTES.OPEN_ENROLLMENT_CONFIRM)}
                className={classes.formAction}
              >
                {content.next}
              </PGEButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OpenEnrollmentPlan;
