import React from 'react';
import {
  FormControlLabel,
  Checkbox,
  useTheme,
  Paper,
  Typography,
} from '@material-ui/core';
import colors from '../../../themes/main-colors';
import { useTranslation } from '../../../hooks/useTranslation';
import { BaseFormModel } from '../../../hooks/useFormState.types';

type Props = {
  coCustomerCount: number;
  coCustomerData: any;
  form: BaseFormModel;
};

const MoveServiceCoCustomer = ({
  coCustomerCount,
  coCustomerData,
  form,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const toggleSelectCoCustomer = async (coCustomer: any) => {
    if (
      form.values.selectedCoCustomers.some(
        (c: any) => c.encryptedPersonId === coCustomer.encryptedPersonId,
      )
    ) {
      await form.setValue(
        'selectedCoCustomers',
        form.values.selectedCoCustomers.filter(
          (c: any) => c.encryptedPersonId !== coCustomer.encryptedPersonId,
        ),
      );
    } else {
      await form.setValue('selectedCoCustomers', [
        ...form.values.selectedCoCustomers,
        coCustomer,
      ]);
    }
  };

  return (
    <Paper
      style={{
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
        color: colors.noirBlur,
      }}
    >
      <Typography variant="h2" style={{ marginBottom: theme.spacing(2) }}>
        {t('CO_CUSTOMER_DETAILS')}
      </Typography>
      <Typography variant="body1" style={{ marginBottom: theme.spacing(2) }}>
        {coCustomerCount === 1
          ? t('MOVE_SERVICE_HAS_ONE_CO_CUSTOMER')
          : t('MOVE_SERVICE_HAS_MULTIPLE_CO_CUSTOMERS')}
      </Typography>
      {Object.values(coCustomerData).map((coCustomer: any, index: number) => (
        <FormControlLabel
          label={[
            coCustomer.firstName,
            coCustomer.middleName || '',
            coCustomer.lastName,
          ].join(' ')}
          key={`co-customer-${index}`}
          control={
            <Checkbox
              color="primary"
              id={`co-customer-${index}`}
              value={coCustomer.encryptedPersonId}
              checked={form.values.selectedCoCustomers.some(
                (c: any) =>
                  c.encryptedPersonId === coCustomer.encryptedPersonId,
              )}
              onClick={async (e: any) => {
                e.preventDefault();
                await toggleSelectCoCustomer(coCustomer);
              }}
            />
          }
        />
      ))}
    </Paper>
  );
};

export default MoveServiceCoCustomer;
