import React, { FC, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import {
  Typography,
  Paper,
  makeStyles,
  createStyles,
  Grid,
} from '@material-ui/core';
import ROUTES from '../../../routes';
import PGEButton from '../../buttons';
import { useTranslation } from '../../../hooks/useTranslation';
import { navigate } from 'gatsby';
import { PaymentExtensionOptionDetail } from '../useNeedMoreTimeToPayForm';
import TextLink from '../../text-link';
import Alerts from '../../alerts';
import { DownloadAndPrint } from '../../download-print/DownloadAndPrint';
import theme from '../../../themes/theme';

const useStyles = makeStyles(() =>
  createStyles({
    transactionSuccessful: {
      '& .MuiAlert-message': {
        width: '100%',
      },
    },
    alertTitle: {
      fontWeight: 300,
      margin: '-10px 0 0',
      fontFamily: 'Forma-DJR-Display',
    },
    doneBtnHolder: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '16px',
    },
    commonSpacing: {
      marginTop: '14px',
    },
  }),
);

interface Props {
  path?: string;
  selectedPEPlan: PaymentExtensionOptionDetail | null | undefined;
  isOnlyPEOptionOneAvailable: boolean;
  confirmationNumber: string | null | undefined;
  printContainerId: string;
}

const TransactionSuccessful: FC<Props> = (props: Props) => {
  const { t, inlineRichT } = useTranslation();
  const classes = useStyles();
  const {
    selectedPEPlan,
    isOnlyPEOptionOneAvailable,
    confirmationNumber,
    printContainerId,
  } = props;

  useEffect(() => {
    if (!Boolean(selectedPEPlan) || !Boolean(confirmationNumber)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.NEED_MORE_TIME_TO_PAY);
    }
  }, []);

  return (
    <>
      <Paper
        className={classes.transactionSuccessful}
        style={{ margin: theme.spacing(1) }}
      >
        <Alerts
          disableColorOverride={true}
          severity={'success'}
          variant={'outlined'}
          title={
            <Grid
              item
              container
              direction={'row'}
              spacing={2}
              justify="space-between"
            >
              <Grid item>
                <h2 className={classes.alertTitle}>
                  {t('TRANSACTION_SUCCESSFUL')}
                </h2>
              </Grid>
              <Grid item style={{ paddingTop: '0' }}>
                <DownloadAndPrint
                  containerId={printContainerId}
                  excludeSelectors={['[data-noprint]']}
                ></DownloadAndPrint>
              </Grid>
            </Grid>
          }
          message={
            <>
              <Typography className={classes.commonSpacing}>
                {selectedPEPlan?.descriptionNode}
              </Typography>
              <Typography className={classes.commonSpacing}>
                {inlineRichT('CONFIRMATION_NUMBER_NMTTP', {
                  CONFIRMATION_NUMBER: confirmationNumber!,
                })}
              </Typography>
              {isOnlyPEOptionOneAvailable && (
                <Typography className={classes.commonSpacing}>
                  <TextLink to={ROUTES.PREFERRED_DUE_DAY}>
                    {t('SELECT_A_NEW_DUE_DATE')}
                  </TextLink>
                </Typography>
              )}
              <Box className={classes.doneBtnHolder} data-noprint>
                <PGEButton
                  size={'large'}
                  onClick={async () => {
                    await navigate(ROUTES.ACCOUNT);
                  }}
                >
                  <Typography variant={'h4'} noWrap>
                    {t('DONE')}
                  </Typography>
                </PGEButton>
              </Box>
            </>
          }
          isOpen
        />
      </Paper>
    </>
  );
};

export default TransactionSuccessful;
