import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import AccountTextField from '../../account-text-field';
import Button from '../../buttons';
import MaskedNameTextField from '../../masked-name-text-field';
import SampleBill from '../../sample-bill';
import TextLink from '../../text-link';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRow: {
      marginTop: '2em',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
      },
    },
    buttonContainer: {
      display: 'flex',
      marginLeft: '4px',
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
        marginTop: '1em',
        width: '100%',
        justifyContent: 'center',
      },
    },
    button: {
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },
    },
  }),
);

interface AddResidentialAccountProps {
  path: string;
}

const AddResidentialAccount: FunctionComponent<
  AddResidentialAccountProps | any
> = props => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Paper>
        <Card>
          <CardContent>
            <Typography>{'Add Residential Account'}</Typography>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignItems: 'center',
              }}
            >
              <Grid item xs={6}>
                <AccountTextField
                  data-testid={'create-residential-acccount-number'}
                  name={'accountNumber'}
                  label={t('ACCOUNT_NUMBER')}
                  onChange={props.handleChange}
                  onBlur={props.onBlur}
                  autoComplete={t('ACCOUNT_NUMBER')}
                  type={'number'}
                  inputProps={{ minLength: 10, maxLength: 10, type: 'number' }}
                  error={!!props?.accountNumber?.errorMessage}
                  helperText={props?.accountNumber?.errorMessage}
                />
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <p style={{ marginLeft: 20 }}>
                  <SampleBill />
                </p>
              </Grid>
              <Grid item xs={6}>
                <MaskedNameTextField
                  name={'nickname'}
                  label={t('Account nickname (optional)')}
                  onChange={props.handleChange}
                  onBlur={props.onBlur}
                  style={{ width: '100%' }}
                  error={!!props?.nickname?.errorMessage}
                  helperText={props?.nickname?.errorMessage}
                />
              </Grid>
              <Grid item xs={12} className={classes.buttonRow}>
                <Grid item>
                  <TextLink plain to={ROUTES.MANAGE_ACCOUNTS}>
                    {t('Back')}
                  </TextLink>
                </Grid>
                <Grid item className={classes.buttonContainer}>
                  <Button
                    onClick={props.handleSubmit}
                    disabled={props.accountNumber.value.length < 9}
                    size={'large'}
                    className={classes.button}
                  >
                    {t('Add')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </>
  );
};

export default AddResidentialAccount;
