/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';

import routes from '../../../../routes';
import { Layout } from '../../../../components/pge-plus-common/Layout';
import {
  Router,
  useLocation,
  RouteComponentProps,
  navigate,
} from '@reach/router';
import PrivateRoute from '../../../../components/routing/PrivateRoute';
import NotFoundPage from '../../../../pages/404';

import ChooseAccount from '../../../../components/pge-plus/ev-charger/rebate-only-flow/choose-account';
import ConfirmYourCharger from '../../../../components/pge-plus/ev-charger/rebate-only-flow/confirm-your-charger';
import CustomerInformation from '../../../../components/pge-plus/ev-charger/rebate-only-flow/customer-information';
import RebateEligibility from '../../../../components/pge-plus/ev-charger/rebate-only-flow/rebate-eligibility';
import useContentMgmt from '../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../components/pge-plus-common/microcopyGroupIds';
import { Box, Typography } from '@material-ui/core';
import { ProgressStepper } from '../../../../components/pge-plus-common/StepperForm';
import RebateOnlyIneligible from './ineligible';
import Success from '../../../../components/pge-plus/ev-charger/rebate-only-flow/success';
import ConfirmYourInformation from '../../../../components/pge-plus/ev-charger/rebate-only-flow/confirm-your-information/ConfirmYourInformation';
import { useRebateOnlyFormState } from '../../../../providers/RebateOnlyFormStateProvider';
import { featureFlagsContext } from '../../../../providers/FeatureFlagsProvider';
import Backdrop from '../../../../components/backdrop/Backdrop';

const RebateOnly: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const theme = useTheme();
  const { isPGEPlusRebateOnlyEnabled, loading } = useContext(
    featureFlagsContext,
  );
  const { content: contentStepper } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_STEPPER,
  );
  const { content: contentPageTitle } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_PAGE_TITLES,
  );

  const {
    formState: { application },
  } = useRebateOnlyFormState();

  const { pathname: path } = useLocation();
  const pathname = path.replace(/\/$/, '');

  const [pageTitle, setPageTitle] = useState<string>('');

  useEffect(() => {
    //Redirect to choose account is appliction data not available
    if (
      !application &&
      pathname !== routes.PGE_PLUS_REBATE_ONLY_HOME &&
      pathname !== routes.PGE_PLUS_REBATE_ONLY_WELCOME_BACK
    ) {
      void navigate(routes.PGE_PLUS_REBATE_ONLY_HOME);
    }
  }, []);

  useEffect(() => {
    if (!loading && !isPGEPlusRebateOnlyEnabled) {
      void navigate(routes.ACCOUNT);
    }
  }, [isPGEPlusRebateOnlyEnabled, loading]);

  const pageTitles: Record<
    string,
    { displayOnMobile: boolean; content: React.ReactNode }
  > = {
    [routes.PGE_PLUS_REBATE_ONLY_CONFIRM_YOUR_CHARGER]: {
      displayOnMobile: true,
      content:
        contentPageTitle.get('PAGE_TITLE_UPLOAD_YOUR_MODEL_NUMBER') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_VERIFY_CHARGER_REBATE]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_VERIFY_REBATE') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_VERIFY_EVPULSE_REBATE]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_VERIFY_REBATE') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_UPLOAD_MODEL_NUMBER]: {
      displayOnMobile: true,
      content:
        contentPageTitle.get('PAGE_TITLE_UPLOAD_YOUR_MODEL_NUMBER') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_UPLOAD_JUICEBOXID_NUMBER]: {
      displayOnMobile: true,
      content:
        contentPageTitle.get('PAGE_TITLE_UPLOAD_YOUR_CHARGER_ID_NUMBER') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_UPLOAD_SERIAL_NUMBER]: {
      displayOnMobile: true,
      content:
        contentPageTitle.get('PAGE_TITLE_UPLOAD_YOUR_SERIAL_NUMBER') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_EVPULSE_SMART_CHARGING_ENROLLMENT]: {
      displayOnMobile: true,
      content: contentPageTitle.get('FIDDLESTICKS_ALTERNATIVE') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_CUSTOMER_INFORMATION]: {
      displayOnMobile: true,
      content:
        contentPageTitle.get('PAGE_TITLE_REBATE_ONLY_CUSTOMER_INFORMATION') ||
        '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_HIGHER_REBATES_CHECK]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_LETS_TALK_REBATES') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_HIGHER_REBATES_ELIGIBILE]: {
      displayOnMobile: true,
      content:
        contentPageTitle.get('PAGE_TITLE_ELIGIBLE_FOR_HIGHER_REBATES') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_STANDARD_REBATES_ELIGIBILE]: {
      displayOnMobile: true,
      content:
        contentPageTitle.get('PAGE_TITLE_ELIGIBLE_FOR_STANDARD_REBATES') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_ELIGIBILITY_CONFIRMATION]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_CONGRATULATIONS') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_CHARGER_INFORMATION]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_LETS_TALK_REBATES') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_CHARGER_INSTALLATION_COSTS]: {
      displayOnMobile: true,
      content:
        contentPageTitle.get('PAGE_TITLE_CHARGER_AND_INSTALLATION_COSTS') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_CONNECT_TESLA]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_CONNECT_TO_APP') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_CONNECT_CHARGER]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_ALMOST_DONE') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_EVPULSE_ELIGIBLE]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_ELIGIBLE_FOR_EVPULSE') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_CHARGER_INSTRUCTIONS]: {
      displayOnMobile: true,
      content:
        contentPageTitle.get('PAGE_TITLE_REBATE_ONLY_REBATE_ELIGIBILITY') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_REBATE_RESULTS]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_REBATE_RESULTS') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_EV_PULSE_REBATE_RESULTS]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_REBATE_RESULTS') || '',
    },
    [routes.PGE_PLUS_REBATE_ONLY_SUCCESS]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_REBATE_ONLY_SUCCESS') || '',
    },
  };

  const steps = useMemo(() => {
    return [
      {
        path: routes.PGE_PLUS_REBATE_ONLY_CONFIRM_YOUR_CHARGER,
        subPaths: [
          routes.PGE_PLUS_REBATE_ONLY_VERIFY_CHARGER_REBATE,
          routes.PGE_PLUS_REBATE_ONLY_VERIFY_EVPULSE_REBATE,
          routes.PGE_PLUS_REBATE_ONLY_UPLOAD_MODEL_NUMBER,
          routes.PGE_PLUS_REBATE_ONLY_UPLOAD_JUICEBOXID_NUMBER,
          routes.PGE_PLUS_REBATE_ONLY_UPLOAD_SERIAL_NUMBER,
          routes.PGE_PLUS_REBATE_ONLY_UPLOAD_SERIAL_NUMBER,
        ],
        title:
          contentStepper.get('PROGRESS_STEPPER_CONFIRM_YOUR_CHARGER') || '',
        component: (
          <ConfirmYourCharger
            path={`${routes.PGE_PLUS_REBATE_ONLY_CONFIRM_YOUR_CHARGER}/*`}
          />
        ),
      },
      {
        path: routes.PGE_PLUS_REBATE_ONLY_CUSTOMER_INFORMATION,
        title:
          contentStepper.get('PROGRESS_STEPPER_CUSTOMER_INFORMATION') || '',
        component: (
          <CustomerInformation
            path={routes.PGE_PLUS_REBATE_ONLY_CUSTOMER_INFORMATION}
          />
        ),
      },
      {
        path: routes.PGE_PLUS_REBATE_ONLY_REBATE_ELIGIBILITY,
        subPaths: [
          routes.PGE_PLUS_REBATE_ONLY_CONNECT_TESLA,
          routes.PGE_PLUS_REBATE_ONLY_ELIGIBILITY_CONFIRMATION,
          routes.PGE_PLUS_REBATE_ONLY_HIGHER_REBATES_CHECK,
          routes.PGE_PLUS_REBATE_ONLY_HIGHER_REBATES_ELIGIBILE,
          routes.PGE_PLUS_REBATE_ONLY_STANDARD_REBATES_ELIGIBILE,
          routes.PGE_PLUS_REBATE_ONLY_CHARGER_INFORMATION,
          routes.PGE_PLUS_REBATE_ONLY_CHARGER_INSTALLATION_COSTS,
          routes.PGE_PLUS_REBATE_ONLY_CONNECT_CHARGER,
          routes.PGE_PLUS_REBATE_ONLY_EVPULSE_ELIGIBLE,
          routes.PGE_PLUS_REBATE_ONLY_CHARGER_INSTRUCTIONS,
          routes.PGE_PLUS_REBATE_ONLY_REBATE_RESULTS,
          routes.PGE_PLUS_REBATE_ONLY_EV_PULSE_REBATE_RESULTS,
          routes.PGE_PLUS_REBATE_ONLY_SUCCESS,
        ],
        title: contentStepper.get('PROGRESS_STEPPER_REBATE_ELIGIBILITY') || '',
        component: (
          <RebateEligibility
            path={`${routes.PGE_PLUS_REBATE_ONLY_REBATE_ELIGIBILITY}/*`}
          />
        ),
      },
    ];
  }, [contentStepper]);

  if (loading) {
    return <Backdrop forceOpen />;
  }

  return (
    <Layout>
      <Grid
        container
        spacing={2}
        justify="center"
        alignItems="center"
        direction="column"
        css={{
          marginTop: 35,
          [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
            display: 'block',
          },
        }}
      >
        {pathname && pageTitles[pathname] && (
          <Box
            mt={2}
            mb={2}
            css={css`
              display: flex;
              align-items: center;
              flex-direction: column;
              @media (max-width: ${theme.breakpoints.values.sm}px) {
                display: none;
              }
              @media (min-width: ${theme.breakpoints.values.md}px) {
                display: block;
              }
            `}
          >
            <Typography
              variant={'h1'}
              css={{
                fontSize: 36,
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                '& p': {
                  margin: 0,
                  textAlign: 'center',
                },
              }}
            >
              {pageTitles[pathname].content}
            </Typography>
          </Box>
        )}

        {pathname &&
          !([
            routes.PGE_PLUS_REBATE_ONLY_HOME,
            routes.PGE_PLUS_REBATE_ONLY_CONFIRM_YOUR_INFORMATION,
            routes.PGE_PLUS_REBATE_ONLY_INELIGIBLE,
            routes.PGE_PLUS_REBATE_ONLY_EVPULSE_SMART_CHARGING_ENROLLMENT,
          ] as string[]).includes(pathname) && (
            <Grid item xs={12} md={9}>
              <ProgressStepper
                content={contentStepper}
                steps={steps.map(({ component, ...rest }) => ({ ...rest }))}
                isComplete={[
                  routes.PGE_PLUS_REBATE_ONLY_SUCCESS as string,
                ].includes(pathname)}
              />
            </Grid>
          )}

        {pathname &&
          pageTitles[pathname] &&
          pageTitles[pathname].displayOnMobile && (
            <Box
              mt={4}
              mx={1}
              mb={3}
              css={css`
                @media (max-width: ${theme.breakpoints.values.sm}px) {
                  display: block;
                }
                @media (min-width: ${theme.breakpoints.values.md}px) {
                  display: none;
                }
              `}
            >
              <Typography
                variant={'h1'}
                css={{
                  fontSize: 24,
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  '& p': {
                    margin: 0,
                  },
                  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                    textAlign: 'left',
                  },
                }}
              >
                {pageTitles[pathname].content}
              </Typography>
            </Box>
          )}
      </Grid>
      <Grid
        container
        spacing={2}
        justify="center"
        css={{
          marginBottom: 100,
          [theme.breakpoints.up('md')]: {
            '.MuiTypography-root': {
              lineHeight: '26px',
            },
          },
        }}
      >
        <Grid item xs={12} md={9} css={{ maxWidth: 790 }}>
          <Router basepath="/">
            {steps.map(({ component }, idx) => (
              <component.type {...component.props} key={idx} />
            ))}
            <ChooseAccount path={routes.PGE_PLUS_REBATE_ONLY_HOME} />
            <ConfirmYourInformation
              path={routes.PGE_PLUS_REBATE_ONLY_CONFIRM_YOUR_INFORMATION}
            />
            <RebateOnlyIneligible
              path={routes.PGE_PLUS_REBATE_ONLY_INELIGIBLE}
            />
            <Success path={routes.PGE_PLUS_REBATE_ONLY_SUCCESS} />
            <PrivateRoute path="/*" component={NotFoundPage} />
          </Router>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default RebateOnly;
