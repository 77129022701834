import React from 'react';
import Modal from '../../modal/Modal';
import Box from '@material-ui/core/Box';
import PGEButton from '../../buttons';
import { Typography } from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';
import { useTranslation } from '../../../hooks/useTranslation';
import TextLink from '../../text-link';

type Props = {
  open: boolean;
  onBack: () => void;
};
const useStyles = makeStyles((theme: Theme) => ({
  body: {
    maxWidth: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    '& > *': {
      // marginTop: theme.spacing(1),
    },
  },
  infoline: {
    marginBottom: '10px',
  },
  cancelButton: {
    backgroundColor: colors.white,
    marginRight: theme.spacing(1),
  },
  bold: {
    fontWeight: 'bold',
  },
}));
const SSMAddressErrorModal = (props: Props) => {
  const { open, onBack } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Modal
      open={open}
      showCloseIcon={false}
      title={
        <Typography variant="h2" data-testid="header">
          {t('CUSTOMER_ASSISTANCE_REQUIRED')}
        </Typography>
      }
      onBackdropClick={() => onBack()}
    >
      <Box className={classes.body} data-testid="kicker">
        <Typography className={classes.infoline}>
          {t('UNABLE_TO_FIND_MAILING_ADDRESS')}{' '}
          <TextLink
            external
            plain
            textColor={theme.palette.primary.main}
            to={`tel:${t('CALL_FOR_ASSISTANCE_NUMBER')}`}
          >
            {t('CALL_FOR_ASSISTANCE_NUMBER')}
          </TextLink>{' '}
        </Typography>
        <Typography className={classes.infoline}>
          {t('SSM_STOP_BUSINESS_CUSTOMERS')}{' '}
          <TextLink
            external
            plain
            textColor={theme.palette.primary.main}
            to={`tel:${t('CALL_FOR_ASSISTANCE_NUMBER')}`}
          >
            {t('BUSINESS_CUSTOMER_SERVICE_NUMBER')}
          </TextLink>{' '}
        </Typography>
      </Box>
      <Box justifyContent="flex-end" display="flex">
        <PGEButton
          color={'primary'}
          variant={'contained'}
          onClick={() => onBack()}
          data-testid="done"
        >
          {t('OK')}
        </PGEButton>
      </Box>
    </Modal>
  );
};
export default SSMAddressErrorModal;
