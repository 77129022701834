import React, { FC, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { Typography, Grid, makeStyles, createStyles } from '@material-ui/core';
import ROUTES from '../../../routes';
import { useTranslation } from '../../../hooks/useTranslation';
import PaperBox from '../../../components/utility/paper-box';
import { PaymentExtensionCurrentPlan } from '../../../__generated__/pge-types';
import { PaymentExtensionOptionDetail } from '../useNeedMoreTimeToPayForm';
import ProceedOrCancel from '../../proceed-or-cancel';
import formatCurrency from 'format-currency';
import { navigate } from 'gatsby';
import { toDateString } from '../../../util/format';
import ReactMarkdown from 'react-markdown/with-html';

const useStyles = makeStyles(() =>
  createStyles({
    fixCasing: {
      '& span': {
        display: 'inline-block',
      },
      '& span::first-letter': { textTransform: 'lowercase' },
    },
  }),
);

interface Props {
  path?: string;
  currentPlan: PaymentExtensionCurrentPlan | null | undefined;
  selectedPlan: PaymentExtensionOptionDetail | null | undefined;
  isEligibleForPETpa: boolean;
  isPastDue: boolean;
  onSubmit: () => Promise<void>;
  isOnlyOnePEChoiceAvailable: boolean;
}

const Confirm: FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { t, richT, inlineRichT } = useTranslation();
  const {
    currentPlan,
    selectedPlan,
    isEligibleForPETpa,
    isPastDue,
    onSubmit,
    isOnlyOnePEChoiceAvailable,
  } = props;

  useEffect(() => {
    if (!Boolean(selectedPlan)) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.NEED_MORE_TIME_TO_PAY);
    }
  }, []);

  const hasCurrentplan = Boolean(currentPlan);

  return (
    <>
      <PaperBox>
        <Typography variant={'h2'} style={{ marginBottom: '1em' }}>
          {t('CONFIRM_YOUR_PAYMENT_OPTION_NMTTP')}
        </Typography>

        {hasCurrentplan && (
          <>
            <Typography style={{ marginBottom: '1em' }}>
              {inlineRichT('CANCEL_CURRENT_PLAN_NMTTP', {
                AMOUNT: `$${formatCurrency(currentPlan?.amountDue)}`,
                DATE: toDateString(currentPlan?.dueDate),
              })}
            </Typography>
            <Typography style={{ marginBottom: '1.5em' }}>
              {t('CREATE_NEW_PLAN_NMTTP')}{' '}
              <span className={classes.fixCasing}>
                {selectedPlan?.descriptionNode}
              </span>
            </Typography>
          </>
        )}

        {!hasCurrentplan && (
          <Typography style={{ marginBottom: '1.5em' }}>
            {selectedPlan?.descriptionNode}
          </Typography>
        )}

        {isEligibleForPETpa && (
          <Typography component={'div'}>
            {richT('TPA_ELGIBLE_NMTTP', {
              CALL_FOR_ASSISTANCE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
            })}
          </Typography>
        )}

        <hr />

        <Box mt={2}>
          <Typography component={'span'}>
            <ReactMarkdown
              source={t('TERMS_AND_CONDITIONS_NMTTP', {
                PAYMENT_OPTIONS_ROUTE: ROUTES.PAYMENT_OPTIONS,
              })}
              escapeHtml={false}
            />
          </Typography>
        </Box>

        {isPastDue && (
          <Typography>{t('PAST_DUE_NOTIFICATION_NMTTP')}</Typography>
        )}
      </PaperBox>
      <Grid
        item
        xs={12}
        style={{
          marginTop: '1em',
        }}
      >
        <ProceedOrCancel
          proceedLabel={t('SUBMIT')}
          proceedHandler={onSubmit}
          cancelLabel={t('BACK')}
          cancelRoute={
            isOnlyOnePEChoiceAvailable
              ? ROUTES.NEED_MORE_TIME_TO_PAY
              : ROUTES.NEED_MORE_TIME_TO_PAY_PLAN_OPTIONS
          }
        />
      </Grid>
    </>
  );
};

export default Confirm;
