import { useApolloClient } from '@apollo/react-hooks';
import { getOnecheckPdf, submitOnecheckPayment } from './queries';
import { AccountAmounts } from './types';
import {
  OnecheckPaymentSubmitResponse,
  OnecheckPaymentSubmitRequest,
  OnecheckPaymentAccountRequest,
  OnecheckPaymentDownloadRemittanceFormResponse,
  GroupType,
  OnecheckDisplayOption,
  PaymentProfile,
  PaymentInputList,
  PaymentConfirmationList,
  GuestPaymentConfirmation,
} from '../../__generated__/pge-types';
import sumBy from 'lodash/sumBy';
import { format } from 'date-fns';
import { formatServiceAddress } from '../../util/format';
import useSelectedGroupId from '../../hooks/useSelectedGroupId';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import { makePayment } from '../../queries/payment.query';
import { submitGuestPayMutation } from '../../queries/guestpay.query';

function formatDate(date: Date) {
  return format(date, 'MM/dd/yyyy');
}

function toOnecheckAccount({
  amount,
  account,
}: AccountAmounts): OnecheckPaymentAccountRequest {
  const amountDue = account?.currentCharges?.amountDue || 0;
  return {
    payment: amount,
    paymentOriginal: Math.max(amountDue, 0),
    amountDue,
    accountNumber: account.accountNumber,
    // eslint-disable-next-line deprecation/deprecation
    addresses: account?.premiseInfo?.map(formatServiceAddress) || [],
  };
}

export function useMultiPayService() {
  const apolloClient = useApolloClient();
  const { customer, groups } = useAccountCustomer();
  const { selectedGroupId } = useSelectedGroupId();

  function toOnecheckRequest(
    accountAmounts: Array<AccountAmounts>,
  ): OnecheckPaymentSubmitRequest {
    const today = formatDate(new Date());
    const selectedGroup = groups?.find(grp => grp.groupId === selectedGroupId);
    const accountGroup = selectedGroup?.groupName || '';
    const isCustomGroup = selectedGroup?.type === GroupType.Custom;

    const totalAmountDue = sumBy(
      accountAmounts,
      'account.currentCharges.amountDue',
    );
    const oneCheckTotal = sumBy(accountAmounts, 'amount');
    return {
      totalAmountDue,
      oneCheckTotal,
      todaysDate: today,
      accounts: accountAmounts.map(toOnecheckAccount),
      numberOfAccounts: accountAmounts.length,
      accountGroup,
      isCustomGroup,
      displayOption: OnecheckDisplayOption.AmountDue,
      encryptedPersonId: customer?.encryptedPersonId!,
    };
  }

  async function submitOnecheck(accountAmounts: Array<AccountAmounts>) {
    const { data, errors } = await apolloClient.mutate<
      {
        submitOnecheckPayment: OnecheckPaymentSubmitResponse;
      },
      {
        payload: OnecheckPaymentSubmitRequest;
      }
    >({
      mutation: submitOnecheckPayment,
      errorPolicy: 'all',
      variables: {
        payload: toOnecheckRequest(accountAmounts),
      },
    });

    if (
      data?.submitOnecheckPayment?.success !== true ||
      !data ||
      (errors && errors.length > 0)
    ) {
      return Promise.reject('Could not submit onecheck payment');
    }

    return data?.submitOnecheckPayment;
  }

  async function fetchOnecheckPdf(
    remittanceId: string,
    accountAmounts: Array<AccountAmounts>,
  ) {
    const { data } = await apolloClient.query<{
      getDownloadOnecheckRemittanceFormPdf: OnecheckPaymentDownloadRemittanceFormResponse;
    }>({
      query: getOnecheckPdf,
      variables: {
        payload: {
          ...toOnecheckRequest(accountAmounts),
          remittanceId,
        },
      },
    });

    if (!data || data?.getDownloadOnecheckRemittanceFormPdf?.success !== true) {
      return Promise.resolve('Could not fetch PDF');
    }

    return data?.getDownloadOnecheckRemittanceFormPdf?.pdf!;
  }

  async function submitMultipay(
    accountAmounts: Array<AccountAmounts>,
    profile: PaymentProfile,
    paymentDate: string,
  ) {
    const payload: PaymentInputList = {
      payment: accountAmounts.map(aa => ({
        accountNumber: aa.account.accountNumber,
        encryptedPersonId: aa.account.encryptedPersonId,
        tokenId: profile.token,
        paymentAmount: aa.amount,
        paymentDate,
      })),
    };

    const { data, errors } = await apolloClient.mutate<{
      makePayment: PaymentConfirmationList;
    }>({
      mutation: makePayment,
      errorPolicy: 'all',
      variables: {
        payload,
      },
    });

    if (!data || (errors && errors.length > 0)) {
      return Promise.reject('Could not submit multi payment');
    }

    return data?.makePayment;
  }

  return {
    submitOnecheck,
    fetchOnecheckPdf,
    submitMultipay,
  };
}
