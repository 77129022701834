import gql from 'not-graphql-tag';

export const updateAccountGroupsMutation = gql`
  mutation updateGroup($payload: UpdateGroupInput!) {
    updateGroup(payload: $payload) {
      success
      message
    }
  }
`;

export const GroupDetailsFragment = gql`
  fragment GroupDetailInfo on Group {
    groupName
    groupId
    numberOfAccounts
    isDefault
    type
    isDefaultAccountExists
    isLoggedOnUserAutoGroup
    defaultAccount {
      accountNumber
      encryptedAccountNumber
      encryptedPersonId
    }
  }
`;

export const createAccountGroupMutation = gql`
  mutation createAccountGroup($payload: CreateAccountGroupInput!) {
    createAccountGroup(payload: $payload) {
      success
      message
      group {
        groupId
      }
    }
  }
`;

export const deleteGroupMutation = gql`
  mutation deleteGroup($payload: DeleteGroupInput!) {
    deleteGroup(payload: $payload) {
      success
      message
      errorReason
    }
  }
`;

export const addRemoveAccountsToGroupMutation = gql`
  mutation addRemoveAccountsToGroup($payload: AddRemoveAccountsInput!) {
    addRemoveAccountsToGroup(payload: $payload) {
      success
      message
      code
      errorReason
      groupStatus
    }
  }
`;

export const createCustomGroupMutation = gql`
  mutation createCustomGroup($payload: CreateGroupInput!) {
    createCustomGroup(payload: $payload) {
      success
      message
      errorReason
      group {
        ...GroupDetailInfo
      }
    }
  }
  ${GroupDetailsFragment}
`;

export const getAccountsList = gql`
  query getAccountDetailList($params: AccountDetailListParams!) {
    getAccountDetailList(params: $params) {
      totalCount
      timestamp
      accounts {
        accountNumber
        encryptedAccountNumber
        encryptedPersonId
        description
        isDefault
        isActive
        mainCustomerName
        pendingDisconnect {
          isPendingDisconnect
        }
        premiseInfo {
          encryptedPremiseId
          addressLine1
          addressLine2
          houseType
          city
          county
          state
          postal
          country
          saDetails {
            encryptedSAId
            saStatus
            startDate
            endDate
            nickName
          }
        }
        billInfo {
          amountDue
          dueDate
        }
      }
    }
  }
`;
