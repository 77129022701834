import gql from 'not-graphql-tag';
import useAuthQuery from './useAuthQuery';
import {
  AccountEligibilityParams,
  AccountEligibilityResponse,
  OtherPeopleResponse,
} from '../__generated__/pge-types';

const getCoCustomersListQuery = gql`
  query OtherPeople($param: OtherPeopleRequest!) {
    otherPeople(param: $param) {
      acctRelType
      perId
      firstName
      lastName
      middleName
    }
  }
`;

export const useGetCoCustomersAndAuthUsersListQuery = (
  accountNumber: string | undefined,
) => {
  const { loading, data, error, refetch } = useAuthQuery<{
    otherPeople: Array<OtherPeopleResponse>;
  }>(getCoCustomersListQuery, {
    variables: {
      param: {
        acctId: accountNumber,
      },
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    skip: !accountNumber,
  });

  return {
    refetch,
    loading,
    error,
    data,
  };
};

export default useGetCoCustomersAndAuthUsersListQuery;
