import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  EditLink,
  DataLabel,
  getIdTypeLabel,
} from '../../start-service/StartServiceReviewHelpers';
import { useTranslation } from '../../../hooks/useTranslation';
import { BaseFormModel } from '../../../hooks/useFormState.types';
import colors from '../../../themes/main-colors';
import { displayPhoneNumberFormat, toDateString } from '../../../util/format';
import ROUTES from '../../../routes';
import { PersonalIdentificationType } from '../../../__generated__/pge-types';

type CoCustomerInfoReviewProps = {
  form: BaseFormModel;
  goToStep: (step: number) => Promise<void>;
  coCustomerType: 'co' | 'main';
  coCustomerData: any;
};

const MoveServiceCoCustomerReview = ({
  form,
  goToStep,
  coCustomerType,
  coCustomerData,
}: CoCustomerInfoReviewProps) => {
  const { t } = useTranslation();

  const selectedCoCustomer: any = useMemo(() => {
    if (coCustomerData && form.values.encryptedPersonId) {
      return Object.values(coCustomerData).find(
        (c: any) => c.encryptedPersonId === form.values.encryptedPersonId,
      );
    }
    return null;
  }, [form, coCustomerData]);

  if (!selectedCoCustomer) {
    return null;
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        style={{ borderTop: `1px solid ${colors.lightGray1}`, marginTop: 30 }}
      >
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h2">{t('CO_CUSTOMER_INFORMATION')}</Typography>
            <EditLink
              step={coCustomerType === 'main' ? 2.2 : 2.4}
              to={
                coCustomerType === 'main'
                  ? ROUTES.MOVE_SERVICE_MAIN_CUSTOMER_INFO
                  : ROUTES.MOVE_SERVICE_CO_CUSTOMER_INFO
              }
              goToStep={goToStep}
            />
          </Box>
        </Grid>
        <DataLabel
          text={t('CUSTOMER_NAME')}
          value={
            <Typography variant="body2">
              {[
                selectedCoCustomer.firstName,
                selectedCoCustomer.middleName || '',
                selectedCoCustomer.lastName,
              ].join(' ')}
            </Typography>
          }
        />
        {(!form.values.primaryIdType ||
          form.values.primaryIdType === PersonalIdentificationType.None) && (
          <DataLabel
            text={t('BIRTHDATE')}
            value={toDateString(form.values.birthdate)}
          />
        )}
        <DataLabel
          text={t('PREFERRED_PHONE')}
          value={displayPhoneNumberFormat(form.values.preferredPhone)}
        />
        {(!form.values.primaryIdType ||
          form.values.primaryIdType === PersonalIdentificationType.None) && (
          <DataLabel
            text={getIdTypeLabel(form.values.idSubType || form.values.idType)}
            value={`*****${form.values.idValue.substr(
              form.values.idValue.length - 4,
              form.values.idValue.length,
            )}`}
          />
        )}
        <DataLabel
          text={t('EMAIL_ADDRESS')}
          value={form.values.email || t('NONE')}
        />
        <DataLabel
          text={t('ALTERNATE_PHONE')}
          value={
            <Typography variant="body2">
              {form.values.alternatePhone
                ? displayPhoneNumberFormat(form.values.alternatePhone)
                : t('NONE')}
              {form.values.alternatePhone
                ? ' - ' + form.values.altPhoneType.toLowerCase()
                : ''}
              {form.values?.altPhoneExt &&
              form.values.altPhoneType === t('WORK') ? (
                <>
                  <br />
                  {`${t('EXT')} ${form.values.altPhoneExt}`}
                </>
              ) : null}
            </Typography>
          }
        />
      </Grid>
    </>
  );
};

export default MoveServiceCoCustomerReview;
