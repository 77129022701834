/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { useState, Fragment } from 'react';
import {
  Box,
  Typography,
  Divider,
  useTheme,
  Link,
  Grid,
} from '@material-ui/core';

import { PageTextContentType } from '../../../hooks/useContentMgmt';
import colors from '../../../themes/main-colors';
import { Maybe } from '../../../__generated__/pgeplus-types';
import { parsePhoneNumber } from '../../../util/format';
import ContactInfoRow from './ContactInfoRow';
import UpdateContactPhone from './UpdateContactPhone';
import { GridItem } from '../../pge-plus-common/Grid';
import routes from '../../../routes';

type UserInfo = {
  firstName: Maybe<string> | undefined;
  middleName: Maybe<string> | undefined;
  lastName: Maybe<string> | undefined;
  address: Maybe<string> | undefined;
  phoneNumber: Maybe<string> | undefined;
  emailAddress: Maybe<string> | undefined;
};
type ContactInfoBoxProps = {
  content: PageTextContentType;
  userInfo: UserInfo;
  onPhoneNumberUpdate: (phoneNumber: string) => void;
};

export const ContactInfoBox: React.FC<ContactInfoBoxProps> = ({
  content,
  userInfo,
  onPhoneNumberUpdate,
}) => {
  const [updatePhoneModalVisibility, setUpdatePhoneModalVisibility] = useState<
    boolean
  >(false);

  const theme = useTheme();

  const contactInfoBox = {
    border: `1px solid ${colors.sparkBlue}`,
    borderRadius: 5,
    margin: '20px 0 20px 0',
    padding: 20,
    fontSize: 16,
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
  };

  const divider = {
    color: colors.lightGray1,
  };

  return (
    <Fragment>
      <Box display={'flex'} justifyContent={'center'}>
        <Grid css={{ ...contactInfoBox }} xs={12} md={9}>
          <Box fontWeight="700" display="flex" justifyContent="space-between">
            <Typography
              variant={'body1'}
              css={{ fontWeight: 700, fontSize: 20, marginBottom: 15 }}
            >
              {content.get('SERVICE_CONTACT_INFORMATION')}
            </Typography>
          </Box>

          <Divider css={{ ...divider }} sx={{ my: 2 }} />

          {/* Contact Info Details */}
          <ContactInfoRow
            label={content.get('REUSABLE_NAME') || ''}
            value={[
              userInfo?.firstName,
              userInfo?.middleName,
              userInfo?.lastName,
            ].join(' ')}
          />

          <ContactInfoRow
            label={content.get('ADDRESS') || ''}
            value={userInfo?.address || ''}
          />
          <ContactInfoRow
            label={content.get('REUSABLE_PHONE') || ''}
            value={userInfo?.phoneNumber! || 'N/A'}
            editable={true}
            editLabel={content.get('REUSABLE_EDIT')}
            onEdit={() => setUpdatePhoneModalVisibility(true)}
          />
          <ContactInfoRow
            label={content.get('REUSABLE_EMAIL') || ''}
            value={userInfo?.emailAddress || 'N/A'}
          />
        </Grid>
      </Box>

      <Box display={'flex'} justifyContent={'center'}>
        <Grid css={{ marginTop: '10px' }} xs={12} md={9}>
          <GridItem>
            <Link
              variant="body1"
              href={routes.PRIVACY_POLICY}
              target="_blank"
              css={{
                fontSize: '20px',
                fontWeight: 700,
                cursor: 'pointer',
                '&:hover': {
                  textDecoration: 'none',
                },
                [theme.breakpoints.down('xs')]: {
                  fontSize: 16,
                },
              }}
            >
              {content.get('HOW_WE_USE_YOUR_PERSONAL_INFORMATION')}
            </Link>
          </GridItem>
        </Grid>
      </Box>

      {updatePhoneModalVisibility && (
        <UpdateContactPhone
          phoneNumber={parsePhoneNumber(userInfo?.phoneNumber || '')}
          open={updatePhoneModalVisibility}
          content={content}
          onClose={() => setUpdatePhoneModalVisibility(false)}
          onUpdate={phone => onPhoneNumberUpdate(phone)}
        />
      )}
    </Fragment>
  );
};
