import React, { FunctionComponent, useMemo } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { navigate } from 'gatsby';

import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import Button from '../../buttons';
import { useTranslation } from '../../../hooks/useTranslation';

const useStyles = makeStyles(theme =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    button: {
      display: 'flex',
      flexDirection: 'row',
    },
  }),
);

const NoServiceAgreement: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const data = useMemo(
    () => [
      {
        title: t('START_NEW_SERVICE'),
        contents: [
          { value: t('MOVING_TO_NEW_ADDRESS') },
          { value: t('BUYING_OR_LEASING_PROPERTY') },
          { value: t('START_YOUR_NEW_SERVICE_HERE') },
        ],
        buttonLabel: t('START_SERVICE'),
        link: ROUTES.SSM_START,
      },
      {
        title: t('ADD_AN_ACCOUNT_GROUP'),
        contents: [
          { value: t('NEED_TO_GET_ORGANIZED') },
          {
            value: t('CONSOLIDATE_MULTIPLE_SERVICES'),
            style: { fontWeight: 'bold' },
          },
        ],
        buttonLabel: t('ADD_AN_ACCOUNT_GROUP'),
        link: ROUTES.MANAGE_GROUPS_ACCOUNT,
      },
    ],
    [],
  );

  return (
    <>
      <Grid container spacing={2} direction="column" wrap="nowrap">
        <Grid item xs={12}>
          <Typography variant={'body1'} className={classes.colorTextPrimary}>
            {t('NO_SERVICE_AGREEMENT')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} direction="row">
            {data.map(({ title, contents, buttonLabel, link }) => (
              <Grid key={title} item xs={12} lg={5} sm={6}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item style={{ height: '130px' }}>
                        <Grid item>
                          <Typography
                            variant={'h4'}
                            className={classes.colorTextPrimary}
                          >
                            {title}
                          </Typography>
                        </Grid>
                        {contents.map((content, idx) => (
                          <Grid
                            key={idx}
                            item
                            style={{ marginTop: idx > 0 ? '0' : '1em' }}
                          >
                            <Typography
                              variant={'body2'}
                              className={classes.colorTextPrimary}
                              style={content.style as React.CSSProperties}
                            >
                              {content.value}
                            </Typography>
                          </Grid>
                        ))}
                      </Grid>

                      <Grid
                        item
                        className={classes.button}
                        xs={12}
                        style={{ marginBottom: '0.75em' }}
                      >
                        <Button
                          onClick={() => {
                            if (link) {
                              return navigate(link);
                            }
                          }}
                          size={'large'}
                          fullWidth
                          style={{ width: '100%' }}
                        >
                          {buttonLabel}
                        </Button>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default NoServiceAgreement;
