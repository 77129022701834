import gql from 'not-graphql-tag';

export const getAccountDetailsQuery = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      encryptedAccountNumber
      mailingAddress {
        addressLine1
        city
        state
        postal
      }
      premiseInfo {
        addressLine1
        addressLine2
        city
        state
        postal
      }
      paperLessBillInfo {
        paperLessEmail
      }
      isPaperlessBillEnrolled {
        result
      }
      isLoggedInUserOnAccount
    }
  }
`;

export const updatePaperlessBillMutation = gql`
  mutation updatePaperlessBill($payload: UpdatePaperlessBillRequest) {
    updatePaperlessBill(payload: $payload) {
      success
    }
  }
`;
