import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import routes from '../../../routes';
import { HabitatSupport as THabitatSupport } from '../types';
import { Card } from './Card';

export function HabitatSupport(props: THabitatSupport) {
  const { t } = useTranslation();

  if (props.isEnrolled === true) {
    return (
      <Card
        type={'compact'}
        theme={'green'}
        title={t('HABITAT_SUPPORT_ENROLLED')}
        ctaText={t('PROJECT_UPDATES')}
        ctaLink={routes.HABITAT_SUPPORT}
      />
    );
  }

  return (
    <Card
      type={'compact'}
      theme={'green'}
      title={t('HABITAT_SUPPORT_NOT_ENROLLED')}
      ctaText={t('ENROLL_NOW')}
      ctaLink={routes.RENEWABLE_POWER}
    />
  );
}
