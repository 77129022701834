import gql from 'not-graphql-tag';
import useAuthQuery from './useAuthQuery';
import { AccountDetailParams, AccountDetail } from '../__generated__/pge-types';

const GET_CUSTOMER_RELTYPE = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      relationType
    }
  }
`;

export const useMainCustomerQuery = () => {
  const { refetch, loading, error, data } = useAuthQuery<
    { getAccountDetails: Array<AccountDetail> },
    { params: AccountDetailParams }
  >(GET_CUSTOMER_RELTYPE, {
    skip: true,
  });
  return {
    refetch,
    loading,
    error,
    data,
  };
};

export default useMainCustomerQuery;
