import gql from 'not-graphql-tag';
import { EnrollmentAccounts, OpenEnrollmentAccountsRequest } from '../__generated__/pge-types';
import useAuthQuery from './useAuthQuery';

const GET_OPEN_ENROLLMENT_ACCOUNTS = gql`
  query getOpenEnrollmentAccounts($payload: OpenEnrollmentAccountsRequest!) {
    getOpenEnrollmentAccounts(payload: $payload) {
      openEnrollmentAccounts {
        acctId
        address
        currentPlan
        effectiveRateSched
        spId
        perId
      }
      eligibleForEnrollment
      totalAccounts
    }
  }
`;

interface Request {
  payload: OpenEnrollmentAccountsRequest;
}

interface Response {
  getOpenEnrollmentAccounts: EnrollmentAccounts;
}

export default function useOpenEnrollmentValidate() {
  const { refetch, loading, error, data } = useAuthQuery<
    Response, 
    { payload: OpenEnrollmentAccountsRequest }
  >(
    GET_OPEN_ENROLLMENT_ACCOUNTS,
    {
      skip: true,
    },
  );
  return {
    refetch,
    loading,
    error,
    data,
  };
}
