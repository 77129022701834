import { Typography } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import truncate from 'lodash/truncate';
import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { displayCustomerName } from '../../util/format';
import { useComponentSize, useIsMobile } from '../../util/style-utils';
import { AccountDetail } from '../../__generated__/pge-types';

type Props = {
  selectedAccount: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  defaultCursor: {
    cursor: 'default',
  },
  button: {
    // border: 'none',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& > span': {
      minWidth: 0,
      display: 'flex',
    },
  },
  accountContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const StyledButton = ({
  isDisplay,
  ...rest
}: ButtonProps & { isDisplay: boolean }) => (
  <Button
    fullWidth
    aria-controls="customized-menu"
    aria-haspopup="true"
    variant="outlined"
    style={{
      background: '#fff',
      display: 'flex',
    }}
    disableRipple={isDisplay}
    {...rest}
  />
);

function firstServiceAddressLine(account: AccountDetail) {
  return account?.premiseInfo?.[0]?.addressLine1;
}

export default function SelectedClosedAccountsCard({ selectedAccount }: Props) {
  const { t } = useTranslation();
  const anchorEl = React.useRef<null | HTMLElement>(null);

  const isDisplay = true;
  const { width } = useComponentSize(anchorEl);
  const isMobile = useIsMobile();

  const theme = useTheme();
  const showMini = width <= theme.breakpoints.values.sm;
  const classes = useStyles({ showMini });

  return (
    <>
      <Grid
        container
        spacing={1}
        direction="row"
        className={classes.accountContainer}
      >
        {!selectedAccount && (
          <Grid item>
            <Typography variant="body1">{t('SELECT_ACCOUNT_LABEL')}</Typography>
          </Grid>
        )}
        <Grid item style={{ flexGrow: 1 }}>
          <StyledButton
            buttonRef={anchorEl}
            className={clsx(
              classes.defaultCursor,
              classes.button,
              classes.accountContainer,
            )}
            isDisplay={isDisplay}
          >
            <Grid
              container
              spacing={1}
              style={{ flexWrap: isMobile ? 'wrap' : 'nowrap' }}
            >
              <Grid item xs={showMini ? 12 : 4}>
                <Grid container spacing={1} direction="row">
                  <Grid item xs={12} zeroMinWidth>
                    <Typography align="left" variant="h3">
                      {selectedAccount.accountNumber}
                    </Typography>
                  </Grid>
                  {selectedAccount.description && (
                    <Grid item xs={12} zeroMinWidth>
                      <Typography align="left" variant="h3">
                        (
                        {showMini
                          ? truncate(selectedAccount.description, {
                              length: 13,
                            })
                          : selectedAccount.description}
                        )
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={showMini ? 12 : 4}>
                <Typography align="left" variant="body2">
                  {firstServiceAddressLine(selectedAccount)}
                </Typography>
              </Grid>
              {!showMini && (
                <Grid item xs={4}>
                  <Typography noWrap align="left" variant="body2">
                    {displayCustomerName(selectedAccount)}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </StyledButton>
        </Grid>
      </Grid>
    </>
  );
}
