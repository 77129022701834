import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AdjustSharpIcon from '@material-ui/icons/AdjustSharp';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import mainColors from '../../../themes/main-colors';
import { DraggedItemStatus } from './MultiFileUpload';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    wrapper: {
      position: 'relative',
    },
    progress: {
      color: mainColors.noirBlur,
      position: 'absolute',
      top: '2px',
      left: '2px',
      zIndex: 1,
    },
  }),
);

export function Progress(props: {
  loading: boolean;
  fileAlreadyUploaded: boolean;
  status: DraggedItemStatus;
}) {
  const classes = useStyles();
  const { loading, fileAlreadyUploaded, status } = props;

  return (
    <div className={classes.root}>
      {fileAlreadyUploaded && (
        <div className={classes.wrapper}>
          <CheckIcon
            style={{
              background: mainColors.greenCheckBg,
              borderRadius: '50%',
              color: 'white',
            }}
          />
        </div>
      )}
      {!fileAlreadyUploaded && status === DraggedItemStatus.ACCEPTED && (
        <div className={classes.wrapper}>
          {loading ? (
            <AdjustSharpIcon
              style={{
                color: mainColors.darkGray3,
              }}
            />
          ) : (
            <CheckIcon
              style={{
                background: mainColors.greenCheckBg,
                borderRadius: '50%',
                color: 'white',
              }}
            />
          )}
          {loading && (
            <CircularProgress size={20} className={classes.progress} />
          )}
        </div>
      )}
      {!fileAlreadyUploaded && status === DraggedItemStatus.REJECTED && (
        <div className={classes.wrapper}>
          <AdjustSharpIcon
            style={{
              color: mainColors.errorRed,
            }}
          />
        </div>
      )}
    </div>
  );
}
