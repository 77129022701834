import React, { FC } from 'react';
import { Typography, Grid, Paper, Card, CardContent } from '@material-ui/core';
import { navigate } from 'gatsby';
import ROUTES from '../../../routes';
import PGEButton from '../../buttons';
import { useTranslation } from '../../../hooks/useTranslation';
import Alerts from '../../alerts';

interface Props {
  path?: string;
}

const GiveBackConfirmation: FC<Props> = () => {
  const { t, richT } = useTranslation();

  return (
    <Paper component={'main'}>
      <Card>
        <CardContent>
          <Alerts
            disableColorOverride={true}
            isOpen
            severity={'success'}
            variant={'outlined'}
            title={t('UPDATE_SUCCESSFUL')}
            message={
              <>
                <Grid container spacing={2}>
                  <Grid item>
                    <Typography component="span">
                      {richT('PTR_GIVE_BACK_UPDATE_SUCCESS_MESSAGE', {
                        SMART_GRID_EMAIL: t('SMART_GRID_EMAIL'),
                      })}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    paddingTop: 10,
                    display: 'flex',
                    flexDirection: 'row-reverse',
                  }}
                >
                  <Grid>
                    <PGEButton
                      onClick={async () => {
                        await navigate(ROUTES.ACCOUNT);
                      }}
                      type={'submit'}
                      variant="contained"
                      color="primary"
                      data-testid="give-back-confirm-button"
                    >
                      {t('DONE')}
                    </PGEButton>
                  </Grid>
                </Grid>
              </>
            }
          />
        </CardContent>
      </Card>
    </Paper>
  );
};

export default GiveBackConfirmation;
