/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FC, ReactNode } from 'react';
// import { Unstable_Grid2 as Grid } from '@material-ui/core';
import { Grid, GridSize } from '@material-ui/core';

interface GridContainerProps {
  children: ReactNode;
}
export const GridContainer: FC<GridContainerProps> = ({ children }) => (
  <Grid
    container
    css={{
      rowGap: '24px',
      justifyContent: 'space-around',
      width: '100%',
    }}
  >
    {children}
  </Grid>
);

interface GridItemProps {
  children: ReactNode;
  width?: GridSize;
}
export const GridItem: FC<GridItemProps> = ({ children, width = 12 }) => {
  return (
    <Grid item xs={width ?? 12}>
      {children}
    </Grid>
  );
};
