import {
  Checkbox,
  createStyles,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from '../../../../../hooks/useTranslation';
import TextField from '../../../../text-field';
import Dropdown from '../../../../dropdown';
import EmailTextField from '../../../../email-text-field';
import PhoneTextField from '../../../../phone-text-field';
import { CocustomerRelationshipType } from '../../../../../__generated__/pge-types';
import { WizardFormProps } from '../../../../utility/wizard/wizard.types';
import { SSMCoCustomerInfoFormModel } from '../../../ssm.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop0: {
      marginTop: theme.spacing(0),
    },
    errorMessage: {
      margin: '0 14px',
    },
  }),
);

export const ContactInformation = ({
  formState,
  componentProps,
}: WizardFormProps<SSMCoCustomerInfoFormModel>) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { active } = componentProps;
  if (!active) {
    return null;
  }

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name={'firstName'}
            data-testid="firstName"
            label={t('FIRST_NAME')}
            inputProps={{
              minLength: 1,
              maxLength: 60,
              'data-testid': 'test-firstname',
            }}
            style={{ width: '100%' }}
            {...formState.props('firstName')}
            error={Boolean(formState.errors.firstName)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name={'middleName'}
            data-testid="middleName"
            label={t('MIDDLE_NAME_OR_INITIAL')}
            inputProps={{ minLength: 1, maxLength: 60 }}
            style={{ width: '100%' }}
            {...formState.props('middleName')}
            error={Boolean(formState.errors.middleName)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name={'lastName'}
            data-testid="lastName"
            label={t('LAST_NAME')}
            inputProps={{ minLength: 1, maxLength: 60 }}
            style={{ width: '100%' }}
            {...formState.props('lastName')}
            error={Boolean(formState.errors.lastName)}
          />
        </Grid>

        <Grid item xs={12}>
          <Dropdown
            label={t('RELATIONSHIP')}
            data-testid="relationship"
            selectionList={[
              {
                value: CocustomerRelationshipType.Spouse,
                label: t('SPOUSE'),
              },
              {
                value: CocustomerRelationshipType.DomesticPartner,
                label: t('DOMESTIC_PARTNER'),
              },
              {
                value: CocustomerRelationshipType.Roommate,
                label: CocustomerRelationshipType.Roommate,
              },
              {
                value: CocustomerRelationshipType.Other,
                label: CocustomerRelationshipType.Other,
              },
            ]}
            {...formState.props('relationship')}
            onChange={(e: any) => {
              void formState.setValue('relationship', e.target.value);
              void formState.onChange(e);
            }}
            error={Boolean(formState.errors.relationship)}
          />

          {formState.errors.relationship && (
            <FormHelperText className={classes.errorMessage} error={true}>
              {formState.errors.relationship}
            </FormHelperText>
          )}
        </Grid>

        <Grid item xs={12}>
          <EmailTextField
            name={'email'}
            data-testid="email"
            label={t('EMAIL')}
            inputProps={{ minLength: 1, maxLength: 60 }}
            style={{ width: '100%' }}
            className={classes.marginTop0}
            {...formState.props('email')}
            error={Boolean(formState.errors.email)}
          />
        </Grid>

        <Grid item xs={12}>
          <PhoneTextField
            name="primaryPhone"
            data-testid="primaryPhone"
            label={t('PRIMARY_PHONE')}
            {...formState.props('primaryPhone')}
            className={classes.marginTop0}
          />

          <FormControlLabel
            control={
              <Checkbox
                name={'useForMobile'}
                data-testid="useForMobile"
                color={'primary'}
                checked={formState.values.useForMobile}
                {...formState.props('useForMobile')}
                onChange={async e => {
                  await formState.setValue('useForMobile', e.target.checked);
                  await formState.setValue('mobilePhone', formState.values.primaryPhone);
                }}
              />
            }
            label={t('USE_FOR_MOBILE')}
          />
        </Grid>

        {!formState.values.useForMobile && (
          <Grid item xs={12}>
            <PhoneTextField
              name="mobilePhone"
              data-testid="mobilePhone"
              label={t('MOBILE_PHONE_OPTIONAL')}
              {...formState.props('mobilePhone')}
              className={classes.marginTop0}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <PhoneTextField
            name="Alternate Phone"
            data-testid="alternatePhone"
            label={t('ALT_PHONE_OPTIONAL')}
            {...formState.props('alternatePhone')}
            className={classes.marginTop0}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactInformation;
