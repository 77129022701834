import { convertValidationRules } from '../../hooks/useFormState';
import { useTranslation } from '../../hooks/useTranslation';
import {
  validatePhoneNumber,
  validateExistsAndAlphabetCharacters,
  validateEmail,
  validateRequiredAndValidCharacters,
} from '../../util/form-validation';
import {
  ContactInfo as ContactInfoFormType,
  RebateEligibility as RebateEligibilityType,
  SelectCharger as SelectChargerType,
  Installation as InstallationFormType,
  UploadPhotos as UploadPhotosFormType,
  UploadChargerReceipt as UploadChargerReceiptFormType,
} from '../pge-plus-form/evcharger.types';

import {
  ChargerInstallLocation,
  PanelLocation,
} from '../../__generated__/pgeplus-types';
import { CheckRebateEligibilityType } from '../../hooks/pge-plus/useRebatesEligibility';
import { CurrentRebatesView } from '../pge-plus-form/RebateEligibility';

type AdditionalContext = {
  content: Map<string, string>;
  checkRebateEligibility?: CheckRebateEligibilityType;
  currentView?: CurrentRebatesView;
};

export const validateAmount = (name: string, value: any): string | null => {
  const { t } = useTranslation();
  console.log(name + '--' + value);
  const unFormattedAmount = Boolean(value)
    ? value
        .toString()
        .replace('$', '')
        .replace(',', '')
    : '';
  if (!unFormattedAmount) {
    return name;
  }
  if (Number(unFormattedAmount) === 0) {
    return t('ERROR_INVALID_ANNUALHOUSEHOLDINCOME_AMOUNT');
  }
  return null;
};
export const createContactInfoValidateFunction = () =>
  convertValidationRules<ContactInfoFormType, AdditionalContext>(context => {
    const { values, content } = context;

    return {
      isMainPerson: () =>
        values.isMainPerson === undefined
          ? 'Enter the ' + content.get('MAIN_POINT_OF_CONTACT_ERR_MSG')
          : null,
      isOwnHome: () =>
        values.isOwnHome === undefined
          ? 'Enter the ' + content.get('HOME_OWNER_STATUS')
          : null,
      firstName:
        values.isMainPerson === false
          ? validateExistsAndAlphabetCharacters.bind(
              null,
              content.get('FIRST_NAME') || '',
            )
          : null,
      lastName:
        values.isMainPerson === false
          ? validateExistsAndAlphabetCharacters.bind(
              null,
              content.get('LAST_NAME') || '',
            )
          : null,
      phoneNumber: values.isMainPerson === false ? validatePhoneNumber : null,
      email: values.isMainPerson === false ? validateEmail : null,
      userRelationship:
        values.isMainPerson === false
          ? validateRequiredAndValidCharacters.bind(
              null,
              content.get('RELATIONSHIP_TO_USER') || '',
            )
          : null,
      homeType: values.isOwnHome
        ? validateRequiredAndValidCharacters.bind(
            null,
            content.get('HOME_TYPE_ERR_MESSAGE') || '',
          )
        : null,
    };
  });

export const createRebateEligibilityValidateFunction = () =>
  convertValidationRules<RebateEligibilityType, AdditionalContext>(context => {
    const { values, content, checkRebateEligibility, currentView } = context;
    if (currentView === CurrentRebatesView.NEW_CHARGER) {
      return {
        isNeedRebateInfo: () =>
          checkRebateEligibility?.isEligibleForIQDB && !values.isNeedRebateInfo
            ? content.get('FIELD_IS_REQUIRED')
            : null,
        ...(values.isNeedRebateInfo === content.get('REUSABLE_YES') && {
          houseHoldSize: () =>
            !values.houseHoldSize
              ? content.get('HOUSEHOLD_SIZE_ERR_MESSAGE') || ''
              : null,
          annualHouseholdIncome: validateAmount.bind(
            null,
            content.get('HOUSEHOLD_INCOME_ERR_MESSAGE')!,
          ),
          isAnnnualIncomeChecked: () =>
            !values.isAnnnualIncomeChecked
              ? content.get('ANNUAL_INCOME_CHECKBOX_ERR_MESSAGE') || ''
              : null,
        }),
        isHaveWifi: () =>
          (!checkRebateEligibility?.isEligibleForIQDB ||
            values.isNeedRebateInfo) &&
          !values.isHaveWifi
            ? content.get('FIELD_IS_REQUIRED')
            : null,
        isCommitToExtendWifi: () =>
          values.isHaveWifi === content.get('REUSABLE_YES') &&
          !values.isCommitToExtendWifi
            ? content.get('FIELD_IS_REQUIRED')
            : null,
        isOwnTesla: () =>
          (values.isCommitToExtendWifi === content.get('REUSABLE_NO') &&
            !values.isOwnTesla) ||
          (values.isHaveWifi === content.get('REUSABLE_NO') &&
            !values.isOwnTesla)
            ? content.get('FIELD_IS_REQUIRED')
            : null,
      };
    }
    if (currentView === CurrentRebatesView.EXISTING_CHARGER) {
      return {
        chargerForExistingVehicle: () =>
          !values.chargerForExistingVehicle
            ? content.get('FIELD_IS_REQUIRED')
            : null,
        continueWithoutRebate: () =>
          values.chargerForExistingVehicle === content.get('REUSABLE_NO') &&
          !values.continueWithoutRebate
            ? content.get('FIELD_IS_REQUIRED')
            : null,
      };
    }
    if (currentView === CurrentRebatesView.IMAGE_UPLOAD) {
      return {
        vinImage: () =>
          !values.vinImage ? content.get('FIELD_IS_REQUIRED') : null,
        vin: () =>
          values.vinImage && !values.vin
            ? content.get('FIELD_IS_REQUIRED')
            : null,
      };
    }
    return {};
  });

export const createSelectChargerValidateFunction = () =>
  convertValidationRules<SelectChargerType, AdditionalContext>(context => {
    const { values, content } = context;

    return {
      chargerId: () =>
        !values?.chargerId ? content.get('SELECT_A_CHARGER') : null,
    };
  });

export const createInstallationValidateFunction = () =>
  convertValidationRules<InstallationFormType, AdditionalContext>(context => {
    const { values, content } = context;
    console.log(values);

    return {
      urgency: validateRequiredAndValidCharacters.bind(
        null,
        content.get('URGENCY_ERR_MESSAGE') || '',
      ),
      dayOfWeek: () =>
        values.dayOfWeek?.length === 0
          ? content.get('INSTALLATION_DAYS_ERR_MESSAGE') || ''
          : null,
      timeFrame: () =>
        values.timeFrame?.length === 0
          ? content.get('INSTALLATION_TIMES_ERR_MESSAGE') || ''
          : null,
      preferredServiceTime: () =>
        !values.preferredServiceTime
          ? content.get('INSTALLATION_TIMES_ERR_MESSAGE') || ''
          : null,
    };
  });

export const createInstallationLocationValidateFunction = () =>
  convertValidationRules<InstallationFormType, AdditionalContext>(context => {
    const { values, content } = context;

    return {
      panelLocation: !values.panelLocation
        ? validateRequiredAndValidCharacters.bind(
            null,
            content.get('PANEL_LOCATION_TEXT') || '',
          )
        : null,
      comment: () =>
        values.panelLocation === PanelLocation.Other && values.comment === ''
          ? content.get('PANEL_LOCATION_COMMENT_ERR_MESSAGE') || ''
          : null,
    };
  });

export const createInstallationPanelValidateFunction = () =>
  convertValidationRules<InstallationFormType, AdditionalContext>(context => {
    const { values, content } = context;

    const isGarageInstallation =
      values.panelLocation === PanelLocation.GarageAttached ||
      values.installLocation === ChargerInstallLocation.Garage;

    return {
      distanceToPanel: () =>
        !values.installerRecommendPanelDistance && !values.distanceToPanel
          ? content.get('DISTANCE_FROM_PANEL_ERR_MESSAGE') || ''
          : null,
      panelSideLocation: () =>
        !values.installerRecommendPanelDistance &&
        isGarageInstallation &&
        !values.panelSideLocation
          ? content.get('PANEL_LOCATION_ERR_MESSAGE') || ''
          : null,
    };
  });

export const createSubmitPhotosValidateFunction = () =>
  convertValidationRules<UploadPhotosFormType, AdditionalContext>(context => {
    const { values, content } = context;

    return {
      panelImage: () =>
        !values.panelImage ? content.get('FIELD_IS_REQUIRED') : null,
      amperageImage: () =>
        !values.panelImage ? content.get('FIELD_IS_REQUIRED') : null,
      panelLocationImage: () =>
        !values.panelImage ? content.get('FIELD_IS_REQUIRED') : null,

      extendedInstallDistance: () =>
        values.extendedInstallDistance === undefined
          ? content.get('FIELD_IS_REQUIRED')
          : null,

      extendedInstallDistanceImage: () =>
        values.extendedInstallDistance && !values.extendedInstallDistanceImage
          ? content.get('FIELD_IS_REQUIRED')
          : null,

      mainBreakerAmperage: () =>
        !values.mainBreakerAmperage ? content.get('FIELD_IS_REQUIRED') : null,
      doubleBreakerAvailable: () =>
        !values.doubleBreakerAvailable
          ? content.get('FIELD_IS_REQUIRED')
          : null,
    };
  });

export const createSubmitChargerReceiptValidateFunction = () =>
  convertValidationRules<UploadChargerReceiptFormType, AdditionalContext>(
    context => {
      const { values, content } = context;

      return {
        chargerCost: () =>
          !values.chargerCost ? content.get('FIELD_IS_REQUIRED') : null,
        receiptImage: () =>
          !values.receiptImage ? content.get('FIELD_IS_REQUIRED') : null,
      };
    },
  );
