import React from 'react';
import {
  LastSeason,
  SmartThermostatCardType,
} from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import routes from '../../../../routes';
import { SmartThermostat as TSmartThermostat } from '../../types';
import { Card } from '../Card';
import { SeasonalDates } from './SeasonalDates';

export function SmartThermostat(props: TSmartThermostat) {
  const { cardType, lastSeason, lastSeasonEarnedCredit, seasonalDates } = props;
  const { t, richT } = useTranslation();

  function getLastSeasonText(lSeason?: LastSeason) {
    if (lastSeason === LastSeason.Last) {
      return t('LAST_SEASON');
    }
    if (lastSeason === LastSeason.Summer) {
      return t('SUMMER_SEASON');
    }
    if (lastSeason === LastSeason.Winter) {
      return t('WINTER_SEASON');
    }
  }

  if (cardType === SmartThermostatCardType.EarnedSeasonalRebateCard) {
    return (
      <Card
        type="full"
        theme="blue"
        title={t('SMART_THERMOSTAT_PROGRAM')}
        sectionA={richT('EARNED_SEASONAL_REBATE_CARD_PART1', {
          CREDIT: lastSeasonEarnedCredit,
          LAST_SEASON: getLastSeasonText(lastSeason),
        })}
        sectionB={<SeasonalDates season={seasonalDates} />}
        ctaLink={routes.PGE_MARKETPLACE_SMART_THERMOSTAT}
        ctaText={t('LEARN_MORE')}
      />
    );
  }

  if (
    cardType === SmartThermostatCardType.NewAndJoiningProgramWithoutIncentive
  ) {
    return (
      <Card
        type="full"
        theme="blue"
        title={t('SMART_THERMOSTAT_PROGRAM')}
        sectionA={richT('NEW_JOIN_WITHOUT_INTENSIVE_CARD', {
          CREDIT: lastSeasonEarnedCredit ?? '25',
        })}
        sectionB={<SeasonalDates season={seasonalDates} />}
        ctaLink={routes.PGE_MARKETPLACE_SMART_THERMOSTAT}
        ctaText={t('LEARN_MORE')}
      />
    );
  }

  if (cardType === SmartThermostatCardType.NewAndJoiningProgramWithIncentive) {
    return (
      <Card
        type="full"
        theme="blue"
        title={t('SMART_THERMOSTAT_PROGRAM')}
        sectionA={richT('NEW_JOIN_WITH_INTENSIVE_CARD', {
          CREDIT: lastSeasonEarnedCredit,
        })}
        sectionB={<SeasonalDates season={seasonalDates} />}
        ctaLink={routes.PGE_MARKETPLACE_SMART_THERMOSTAT}
        ctaText={t('LEARN_MORE')}
      />
    );
  }

  if (cardType === SmartThermostatCardType.PtrToByot) {
    return (
      <Card
        type="full"
        theme="blue"
        title={t('SMART_THERMOSTAT_PROGRAM')}
        sectionA={richT('PTR_TO_BYOT_CARD', {
          CREDIT: lastSeasonEarnedCredit,
        })}
        sectionB={<SeasonalDates season={seasonalDates} />}
        ctaLink={routes.PGE_MARKETPLACE_SMART_THERMOSTAT}
        ctaText={t('LEARN_MORE')}
      />
    );
  }

  if (cardType === SmartThermostatCardType.EnrolledInProgramDefaultCard) {
    return (
      <Card
        type="full"
        theme="blue"
        title={t('SMART_THERMOSTAT_PROGRAM')}
        sectionA={t('SMART_THERMOSTAT_DEFAULT_CARD')}
        sectionB={<SeasonalDates season={seasonalDates} />}
        ctaLink={routes.PGE_MARKETPLACE_SMART_THERMOSTAT}
        ctaText={t('LEARN_MORE')}
      />
    );
  }

  return <></>;
}
