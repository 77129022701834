import React, { useState } from 'react';
import { Box, Typography, Popover, IconButton } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';

import colors from '../../themes/main-colors';
import useContentMgmt from '../../hooks/useContentMgmt';
import microcopyGroupIds from './microcopyGroupIds';
import PopoverCard from './PopoverCard';

interface PopoverToolTipProps {
  triggerContent?: React.ReactNode;
  popoverContent: React.ReactNode;
  baseContent?: React.ReactNode;
}

export default function PopoverToolTip({
  triggerContent,
  popoverContent,
  baseContent,
}: PopoverToolTipProps) {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { content } = useContentMgmt(microcopyGroupIds.PGE_PLUS_REUSABLE_SLUGS);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {triggerContent ? (
        <Box onClick={handleClick}>{triggerContent}</Box>
      ) : (
        <Typography
          component="span"
          css={{
            cursor: 'pointer',
            color: colors.sparkBlue,
            borderBottom: `1px dotted ${
              Boolean(anchorEl) ? colors.orange : colors.sparkBlue
            }`,
            fontWeight: 'bold',
            fontSize: '20px',
            lineHeight: '26px',
            height: 'fit-content',
            marginLeft: '8px',
            [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
              fontSize: '16px',
              lineHeight: '23px',
            },
          }}
          onClick={handleClick}
        >
          {baseContent ? baseContent : content.get('REUSABLE_LEARN_MORE')}
        </Typography>
      )}

      <PopoverCard
        isOpen={Boolean(anchorEl)}
        anchorEl={anchorEl}
        closeTooltip={handleClose}
        content={popoverContent}
      />
    </>
  );
}
