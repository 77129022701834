import React, { useState, useMemo, useRef } from 'react';
import {
  Theme,
  makeStyles,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import TextField from '../text-field';
import PhoneTextField from '../phone-text-field';
import PhoneExtTextField from '../phone-ext-text-field';
import { BaseFormModel } from '../../hooks/useFormState.types';
import { HowWeUseYourPhoneNumberTooltip } from '../how-we-use-your-phone-number-tooltip';
import colors from '../../themes/main-colors';
import { useTranslation } from '../../hooks/useTranslation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    phoneSelector: {
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

type CustomerInfoProps = {
  form: BaseFormModel;
  headerText: string;
  nameError: string;
  useAltPhone: boolean;
  setUseAltPhone: any;
  showNameFields?: boolean;
  isLoggedinUser?: boolean;
};

const CustomerInfo = ({
  form,
  headerText,
  nameError = '',
  useAltPhone,
  setUseAltPhone,
  showNameFields = true,
  isLoggedinUser,
}: CustomerInfoProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const anchorRef = useRef<any>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [
    showHowWeUsePhoneAnchor,
    setShowHowWeUsePhoneAnchor,
  ] = useState<HTMLElement | null>(null);

  const openHowWeUsePhoneTooltip = () => {
    setShowHowWeUsePhoneAnchor(anchorRef.current);
  };

  const closeHowWeUsePhoneTooltip = () => {
    setShowHowWeUsePhoneAnchor(null);
  };

  const phoneTypes = useMemo(() => [t('MOBILE'), t('HOME'), t('WORK')], []);

  return (
    <>
      <Typography variant="h2" style={{ paddingBottom: 16 }}>
        {headerText}
      </Typography>
      {showNameFields && (
        <Grid container spacing={2}>
          {Boolean(nameError) && (
            <Grid item xs={12}>
              <Typography variant="body1" style={{ color: colors.roseRed }}>
                {nameError}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} md={6}>
            <TextField
              name={'firstName'}
              label={t('FIRST_NAME')}
              inputProps={{ minLength: 1, maxLength: 15 }}
              style={{ width: '100%' }}
              {...form.props('firstName')}
              error={Boolean(form.errors.firstName) || Boolean(nameError)}
              disabled={isLoggedinUser}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name={'middleName'}
              label={t('MIDDLE_NAME')}
              inputProps={{ minLength: 1, maxLength: 15 }}
              style={{ width: '100%' }}
              {...form.props('middleName')}
              error={Boolean(form.errors.middleName) || Boolean(nameError)}
              disabled={isLoggedinUser}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name={'lastName'}
              label={t('LAST_NAME')}
              inputProps={{ minLength: 1, maxLength: 60 }}
              style={{ width: '100%' }}
              {...form.props('lastName')}
              error={Boolean(form.errors.lastName) || Boolean(nameError)}
              disabled={isLoggedinUser}
            />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <PhoneTextField
            name={'preferredPhone'}
            label={t('PREFERRED_PHONE')}
            inputProps={{ minLength: 1, maxLength: 15 }}
            style={{ width: '100%' }}
            {...form.props('preferredPhone')}
            disabled={isLoggedinUser}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <a
            href="#"
            onClick={(e: any) => {
              e.preventDefault();
              setUseAltPhone(!useAltPhone);
            }}
            style={{
              cursor: 'pointer',
              textDecoration: 'none',
              display: 'inline',
            }}
          >
            <Box display="flex">
              <span
                style={{
                  color: colors.sparkBlue,
                  fontSize: '1rem',
                }}
              >
                {t('ADD_ALTERNATE_PHONE')}
              </span>
              {useAltPhone ? (
                <ExpandLess style={{ fontSize: 29 }} />
              ) : (
                <ExpandMore style={{ fontSize: 29 }} />
              )}
            </Box>
          </a>
        </Grid>
        <Grid item xs={12} md={6}>
          <a
            ref={anchorRef}
            href="#"
            onClick={(e: any) => {
              e.preventDefault();
              openHowWeUsePhoneTooltip();
            }}
            style={{
              cursor: 'pointer',
              display: 'inline',
              color: colors.sparkBlue,
              borderBottom: `2px dotted ${colors.sparkBlue}`,
              textDecoration: 'none',
              fontSize: '1.0rem',
            }}
          >
            {t('HOW_WE_USE_YOUR_PHONE_NUMBER')}
          </a>
          <HowWeUseYourPhoneNumberTooltip
            isOpen={Boolean(showHowWeUsePhoneAnchor)}
            closeTooltip={closeHowWeUsePhoneTooltip}
            anchorEl={showHowWeUsePhoneAnchor}
          />
        </Grid>
      </Grid>

      {useAltPhone && (
        <Grid container spacing={2} style={{ marginBottom: theme.spacing(2) }}>
          <Grid item xs={12} md={6}>
            <PhoneTextField
              name={'alternatePhone'}
              label={t('ALTERNATE_PHONE')}
              inputProps={{ minLength: 1, maxLength: 15 }}
              style={{ width: '100%' }}
              {...form.props('alternatePhone')}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl
              variant="outlined"
              error={!!form.errors.altPhoneType}
              style={{ width: isMobile ? '100%' : 'auto' }}
            >
              <Select
                inputProps={{
                  'data-testid': 'alt-phone-value',
                }}
                name="altPhoneType"
                variant="outlined"
                className={classes.phoneSelector}
                value={form.values.altPhoneType}
                error={!!form.errors.altPhoneType}
                onChange={form.onChange}
                style={{ width: isMobile ? '100%' : 'auto' }}
              >
                {phoneTypes.map(v => (
                  <MenuItem key={v} value={v}>
                    {v}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{form.errors.altPhoneType}</FormHelperText>
            </FormControl>
          </Grid>
          {form.values.altPhoneType === t('WORK') && (
            <Grid item xs={12} md={6}>
              <PhoneExtTextField
                inputProps={{
                  'data-testid': 'alt-phone-ext-value',
                  maxLength: 5,
                }}
                name="altPhoneExt"
                label={t('ALTERNATE_PHONE_EXT')}
                value={form.values.altPhoneExt}
                onChange={form.onChange}
                error={!!form.errors.altPhoneExt}
                helperText={form.errors.altPhoneExt}
                onBlur={(e: any) => {
                  e.persist();
                  if (!form.values.alternatePhone && e.target.value) {
                    form.onBlur({
                      target: {
                        name: 'alternatePhone',
                        value: e.target.value,
                      },
                    });
                  } else {
                    form.onBlur(e);
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default CustomerInfo;
