import React from 'react';
import { SmartChargingCardType } from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import routes from '../../../../routes';
import { SmartCharging as TSmartCharging } from '../../types';
import { Card } from '../Card';
import { SeasonalDates } from './SeasonalDates';

export function SmartCharging(props: TSmartCharging) {
  const { cardType, lastSeasonEarnedCredit, activeSeason } = props;

  const { t, richT } = useTranslation();
  console.log(cardType);
  if (cardType === SmartChargingCardType.StandardCard) {
    return (
      <Card
        type="full"
        theme="blue"
        title={t('SMART_CHARGING_HEADER')}
        sectionA={t('SMART_CHARGING_DEFAULT_CARD')}
        sectionB={<SeasonalDates activeSeason={activeSeason} />}
        ctaLink={routes.SMART_CHARGING_PROGRAM}
        ctaText={t('SMART_CHARGING_OVERVIEW')}
      />
    );
  }

  if (cardType === SmartChargingCardType.EarnedCreditCard) {
    return (
      <Card
        type="full"
        theme="blue"
        title={t('SMART_CHARGING_HEADER')}
        sectionA={richT('SMART_CHARGING_EARNED_CREDIT_CARD', {
          CREDIT: lastSeasonEarnedCredit,
        })}
        sectionB={<SeasonalDates activeSeason={activeSeason} />}
        ctaLink={routes.SMART_CHARGING_PROGRAM}
        ctaText={t('SMART_CHARGING_OVERVIEW')}
      />
    );
  }

  return <></>;
}
