import {
  validateNameField,
  validatePhoneNumber,
  validateBirthdate,
  validateZipCode,
  validateCanadianZipCode,
  validateRequiredAndValidCharacters,
  validateNameFieldStrictCasing,
  validateEmailCoCustomer,
  validatePTREnrollment,
  validateEmail,
  validateAddressLookup,
} from '../../util/form-validation';
import { useTranslation } from '../../hooks/useTranslation';
import moment from 'moment';
import { convertValidationRules } from '../../hooks/useFormState';
import {
  PersonalIdentificationType,
  StateIdType,
  FederalIdentificationType,
  PremiseType,
  CocustomerRelationshipType,
  EmploymentType,
} from '../../__generated__/pge-types';
import { getStateName } from '../../util/format';
import { getPersonalIdentificationType } from '../../util/personalIdentification';
import { BaseValidationContext } from '../../hooks/useFormState.types';
import { statesAndProvincesGrouping } from '../utility/state-dropdown';
import { validateIdValue } from '../../util/startStopMoveIdValidations';

const { t } = useTranslation();

const dateBetweenTodayAnd60DaysFromNow = (startDate: string): boolean => {
  const now = moment()
    .startOf('day')
    .subtract(1, 'second');
  const sixtyDaysFromNow = moment()
    .add(59, 'day')
    .endOf('day');
  const momentStateDate = moment(new Date(startDate));

  return momentStateDate.isBetween(now, sixtyDaysFromNow);
};

const dateBetweenTodayAnd90DaysFromNow = (stopDate: string): boolean => {
  const now = moment()
    .startOf('day')
    .subtract(1, 'second');
  const sixtyDaysFromNow = moment()
    .add(89, 'day')
    .endOf('day');
  const momentStateDate = moment(new Date(stopDate));

  return momentStateDate.isBetween(now, sixtyDaysFromNow);
};

const validateStartDate = (startDate: string): string | null => {
  if (!startDate || !startDate.length) {
    return t('START_SERVICE_DATE_REQUIRED');
  }
  if (startDate.length < 8) {
    return t('ERROR_START_SERVICE_DATE_FORMAT');
  }

  if (!dateBetweenTodayAnd60DaysFromNow(startDate)) {
    return t('ERROR_ENTER_START_SERVICE_DATE');
  }

  return null;
};

const validateStopDate = (stopDate: string): string | null => {
  if (!stopDate || !stopDate.length) {
    return t('MOVE_SERVICE_STOP_DATE_BLANK');
  }
  if (stopDate.length < 8) {
    return t('MOVE_SERVICE_STOP_DATE_INCOMPLETE');
  }

  if (!dateBetweenTodayAnd90DaysFromNow(stopDate)) {
    return t('MOVE_SERVICE_STOP_DATE_INCOMPLETE');
  }

  return null;
};

type AdditionalCustomerContext = {
  primaryIdType: string | undefined;
  useAltMailingAddress: boolean;
};

type Step3AdditionalContext = {
  isPTREligible: boolean;
};

export type MoveServiceDetailsFormModel = {
  stopDate: string;
  livingRemainAtProperty: string;
  startDate: string;
  address: string;
  city: string;
  zip: string;
  ownOrRent: string;
  liveAtAddress: string;
};

export interface IMoveServiceCustomerFormModel {
  birthdate: '';
  idType:
    | PersonalIdentificationType.Ssn
    | 'stateId'
    | 'federalId'
    | PersonalIdentificationType.Itin;
  idSubType:
    | PersonalIdentificationType.None
    | StateIdType.DriverLicense
    | StateIdType.StateIdCard
    | PersonalIdentificationType.Dl
    | PersonalIdentificationType.Ortrib
    | PersonalIdentificationType.Visa
    | PersonalIdentificationType.Military
    | PersonalIdentificationType.Pssprt
    | PersonalIdentificationType.Resalien
    | PersonalIdentificationType.Ein
    | PersonalIdentificationType.Pincode
    | PersonalIdentificationType.Dob
    | FederalIdentificationType.UsPassport
    | FederalIdentificationType.UsStudentVisa
    | FederalIdentificationType.UsMilitaryId
    | FederalIdentificationType.UsTemporaryVisa
    | FederalIdentificationType.UsImmigration
    | FederalIdentificationType.OregonTribalId
    | '';
  idSubTypeState: string;
  idValue: string;
  preferredPhone: string;
  alternatePhone: string;
  altPhoneType: string;
  altPhoneExt: string;
  mailAddress: string;
  mailCity: string;
  mailState: string;
  mailZip: string;
  mailCountry: string;
  mailQasVerified: boolean;

  primaryIdType:
    | PersonalIdentificationType.None
    | PersonalIdentificationType.Dl
    | PersonalIdentificationType.Ortrib
    | PersonalIdentificationType.Visa
    | PersonalIdentificationType.Military
    | PersonalIdentificationType.Pssprt
    | PersonalIdentificationType.Resalien
    | PersonalIdentificationType.Ein
    | PersonalIdentificationType.Pincode
    | PersonalIdentificationType.Dob
    | '';

  useAltPhone?: boolean;
  email?: string;
  encryptedPersonId?: string;
}

export interface MoveServiceCustomerFormModel
  extends IMoveServiceCustomerFormModel {
  selectedCoCustomers: string[];
}

export interface ServiceOptionsFormModel {
  paperless: boolean | undefined;
  billDueOn: string | number;
  greenFutureEnroll: boolean | undefined;
  peakTimeEnroll: boolean | undefined;
  peakTimePhone: string;
  peakTimeEmail: string;
  peakTimeContactError: string;
  hasElectricVehicle: boolean | undefined;
  hasSmartThermostat: boolean | undefined;
}

export type ServiceReviewFormModel = {
  startAgreed: boolean;
};

export const step1Validations = convertValidationRules<
  MoveServiceDetailsFormModel
>(() => {
  return {
    stopDate: validateStopDate,
    livingRemainAtProperty: validateNameField.bind(
      null,
      t('LIVING_REMAIN_AT_PROPERTY'),
    ),
    startDate: validateStartDate,
    address: validateAddressLookup.bind(
      null,
      t('STREET_ADDRESS').toLowerCase(),
    ),
    city: validateRequiredAndValidCharacters.bind(
      null,
      t('CITY').toLowerCase(),
    ),
    zip: validateZipCode,
    ownOrRent: validateNameField.bind(null, t('DO_YOU_OWN_OR_RENT')),
    liveAtAddress: validateNameField.bind(null, t('DO_YOU_LIVE_HERE')),
  };
});

export const step2Validations = convertValidationRules<
  MoveServiceCustomerFormModel,
  AdditionalCustomerContext
>(
  (
    context: BaseValidationContext<MoveServiceCustomerFormModel> &
      AdditionalCustomerContext,
  ) => {
    const { values, primaryIdType, useAltMailingAddress } = context;
    const isAuthenticated = true;

    return {
      birthdate:
        !primaryIdType || primaryIdType === PersonalIdentificationType.None
          ? validateBirthdate
          : null,
      idType: validateNameFieldStrictCasing.bind(null, t('ID_TYPE')),
      idSubType: ['stateId', 'federalId'].includes(values.idType)
        ? validateNameFieldStrictCasing.bind(
            null,
            values.idType === 'stateId'
              ? t('STATE_ID_TYPE')
              : t('FEDERAL_ID_TYPE'),
          )
        : null,
      idSubTypeState:
        (!isAuthenticated ||
          !primaryIdType ||
          primaryIdType === PersonalIdentificationType.None) &&
        values.idType === 'stateId' &&
        (values.idSubType === StateIdType.DriverLicense ||
          values.idSubType === StateIdType.StateIdCard)
          ? validateNameField.bind(null, t('STATE'))
          : null,
      idValue: validateIdValue(
        values.idType,
        values.idSubType,
        isAuthenticated,
        primaryIdType,
      ),
      email: null,
      preferredPhone: validatePhoneNumber,
      alternatePhone:
        values.alternatePhone || (!values.alternatePhone && values.altPhoneExt)
          ? validatePhoneNumber
          : null,
      altPhoneType: values.alternatePhone
        ? validateNameField.bind(null, t('PHONE_TYPE'))
        : null,
      altPhoneExt: null,
      mailAddress: useAltMailingAddress
        ? validateRequiredAndValidCharacters.bind(
            null,
            t('STREET_ADDRESS').toLowerCase(),
          )
        : null,
      mailCity: useAltMailingAddress
        ? validateRequiredAndValidCharacters.bind(null, t('CITY').toLowerCase())
        : null,
      mailState: useAltMailingAddress
        ? validateNameField.bind(null, t('STATE_PROVINCE_LABEL'))
        : null,
      mailZip: useAltMailingAddress
        ? statesAndProvincesGrouping.canada.includes(values.mailState)
          ? validateCanadianZipCode
          : validateZipCode
        : null,

      selectedCoCustomers: null,
    };
  },
);

export const step2BValidations = convertValidationRules<
  IMoveServiceCustomerFormModel
>((context: BaseValidationContext<IMoveServiceCustomerFormModel>) => {
  const { values } = context;
  const isAuthenticated = true;
  const isCoCustomer = true;
  return {
    birthdate:
      !values.primaryIdType ||
      values.primaryIdType === PersonalIdentificationType.None
        ? validateBirthdate
        : null,
    idType: validateNameFieldStrictCasing.bind(null, t('ID_TYPE')),
    idSubType: ['stateId', 'federalId'].includes(values.idType)
      ? validateNameFieldStrictCasing.bind(
          null,
          values.idType === 'stateId'
            ? t('STATE_ID_TYPE')
            : t('FEDERAL_ID_TYPE'),
        )
      : null,
    idSubTypeState:
      (!isAuthenticated ||
        !values.primaryIdType ||
        values.primaryIdType === PersonalIdentificationType.None) &&
      values.idType === 'stateId' &&
      (values.idSubType === StateIdType.DriverLicense ||
        values.idSubType === StateIdType.StateIdCard)
        ? validateNameField.bind(null, t('STATE'))
        : null,
    idValue: validateIdValue(
      values.idType,
      values.idSubType,
      isAuthenticated,
      values.primaryIdType,
      isCoCustomer,
    ),
    preferredPhone: validatePhoneNumber,
    alternatePhone:
      values.alternatePhone || (!values.alternatePhone && values.altPhoneExt)
        ? validatePhoneNumber
        : null,
    altPhoneType: values.alternatePhone
      ? validateNameField.bind(null, t('PHONE_TYPE'))
      : null,
    altPhoneExt: null,
    email: validateEmailCoCustomer,
  };
});

const validatePeakTimePhone = (values: ServiceOptionsFormModel) => {
  if (values.peakTimeEnroll) {
    // if enrolled and phone is not blank validate the phone
    if (values.peakTimePhone) {
      return validatePhoneNumber;
    }
  }

  return null;
};

const validatePeakTimeEmail = (values: ServiceOptionsFormModel) => {
  if (values.peakTimeEnroll) {
    // if enrolled and email is not blank, validate the email
    if (values.peakTimeEmail) {
      return validateEmail;
    }
  }
  return null;
};

export const step3Validations = convertValidationRules<
  ServiceOptionsFormModel,
  Step3AdditionalContext
>(
  (
    context: BaseValidationContext<ServiceOptionsFormModel> &
      Step3AdditionalContext,
  ) => {
    const { values, isPTREligible } = context;
    return {
      peakTimePhone: validatePeakTimePhone(values),
      peakTimeEmail: validatePeakTimeEmail(values),
      peakTimeContactError: isPTREligible
        ? validatePTREnrollment.bind(null, values)
        : null,
    };
  },
);

export const createCustomerPayload = (values: any) => {
  const payload = {
    encryptedPersonId: values.encryptedPersonId,
    personalIdentificationType: values.hasExistingPrimaryIdentification
      ? PersonalIdentificationType.None
      : getPersonalIdentificationType(values.idType, values.idSubType),
    additionalInformation: {
      dateOfBirth: values.dateOfBirth,
      primaryPhone: values.preferredPhone,
      emailAddress: values.email,
      password: '',
      socialSecurityNumber: values.hasExistingPrimaryIdentification
        ? null
        : values.idType === PersonalIdentificationType.Ssn
        ? values.idValue
        : null
        ? values.idValue
        : null,
      alternatePhoneInfo: values.alternatePhone
        ? {
            alternatePhoneNumber: values.alternatePHone,
            alternatePhoneType: values.altPhoneType,
            alternatePhoneExt: values.altPhoneExt,
          }
        : null,
      mailingAddress: values.mailingAndServiceAddressesSame
        ? null
        : {
            addressLine1: values.mailAddress,
            city: values.mailCity,
            state: values.mailState,
            postal: values.mailZip,
            country: values.mailCountry,
            qasVerified: values.mailQasVerified,
          },
      previousAddress: null,
      federalInformation:
        !values.hasExistingPrimaryIdentification &&
        values.idType === 'federalId'
          ? {
              federalIDType: values.idSubType,
              federalIDNumber: values.idValue,
            }
          : null,
      stateInformation: {
        stateIdentificationType:
          values.idType === 'stateId'
            ? values.idSubType
            : StateIdType.DriverLicense,
        stateIDState: !values.hasExistingPrimaryIdentification
          ? {
              name: getStateName(values.idSubTypeState),
              code: values.idSubTypeState,
              country: statesAndProvincesGrouping.canada.includes(
                values.idSubTypeState,
              )
                ? 'CAN'
                : 'USA',
            }
          : {},
        stateIDNumber:
          !values.hasExistingPrimaryIdentification &&
          values.idType === 'stateId'
            ? values.idValue
            : '',
      },
      employmentInformation: {
        employerName: 'N/A',
        employmentOption: EmploymentType.None,
      },
      hasSocialSecurityInfo: !values.hasExistingPrimaryIdentification
        ? values.idType === PersonalIdentificationType.Ssn
        : false,
      hasStateInfo: !values.hasExistingPrimaryIdentification
        ? values.idType === 'stateId'
        : false,
      hasFederalInfo: !values.hasExistingPrimaryIdentification
        ? values.idType === 'federalId'
        : false,
      mailingAndServiceAddressesSame: values.mailingAndServiceAddressesSame,
      registerForOnlineAccess: false, // this may need to be true...
      hasExistingPrimaryIdentification:
        values.hasExistingPrimaryIdentification ?? false,
    },
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    spouseOrRegisteredDomesticPartnerType: CocustomerRelationshipType.None,
  };

  return payload;
};

export const createCoCustomerPayload = (values: any) => {
  const payload = {
    encryptedPersonId: values.encryptedPersonId,
    personalIdentificationType: getPersonalIdentificationType(
      values.idType,
      values.idSubType,
    ),
    additionalInformation: {
      dateOfBirth: values.birthdate,
      primaryPhone: values.preferredPhone,
      emailAddress: values.email,
      password: '',
      socialSecurityNumber:
        values.idType === PersonalIdentificationType.Ssn
          ? values.idValue
          : null,
      alternatePhoneInfo: values.alternatePhone
        ? {
            alternatePhoneNumber: values.alternatePhone,
            alternatePhoneType: values.altPhoneType,
            alternatePhoneText: values.altPhoneExt,
          }
        : null,
      mailingAddress: null,
      previousAddress: null,
      federalInformation:
        values.idType === 'federalId'
          ? {
              federalIDType: values.idSubType,
              federalIDNumber: values.idValue,
            }
          : null,
      stateInformation: {
        stateIdentificationType:
          values.idType === 'stateId'
            ? values.idSubType
            : StateIdType.DriverLicense,
        stateIDState:
          values.idType === 'stateId'
            ? {
                name: getStateName(values.idSubTypeState),
                code: values.idSubTypeState,
                country: statesAndProvincesGrouping.canada.includes(
                  values.idSubTypeState,
                )
                  ? 'CAN'
                  : 'USA',
              }
            : {},
        stateIDNumber: values.idType === 'stateId' ? values.idValue : '',
      },
      employmentInformation: {
        employerName: 'N/A',
        employmentOption: EmploymentType.None,
      },
      hasSocialSecurityInfo: values.idType === PersonalIdentificationType.Ssn,
      hasStateInfo: values.idType === 'stateId',
      hasFederalInfo: values.idType === 'federalId',
      mailingAndServiceAddressesSame: values.mailingAndServiceAddressesSame,
      registerForOnlineAccess: false,
      hasExistingPrimaryIdentification: false,
    },
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    spouseOrRegisteredDomesticPartnerType: CocustomerRelationshipType.None,
  };
  return payload;
};

export const createPayload = (values: any) => {
  const payload = {
    encryptedAccountNumber: values.encryptedAccountNumber,
    encryptedPersonId: values.encryptedPersonId,
    anyoneRemainingAtProperty: values.livingRemainAtProperty === t('YES'),
    currentServiceAddress: {
      addressLine1: values?.currentServiceAddress?.addressLine1,
      city: values?.currentServiceAddress?.city,
      state: values?.currentServiceAddress?.state,
      postal: values?.currentServiceAddress?.postal,
      country: values?.currentServiceAddress?.country || 'USA',
      qasVerified: true,
    },
    customerInformation: createCustomerPayload(values.customer),
    eligibleForGreenResource: !values.isServiceDisconnected,
    eligibleForMoveService: values.eligibleForMoveService,
    heatSourceType: 'OTHERELECHEAT',
    isCurrentlyPaperlessBilling: values.isCurrentlyPaperlessBilling,
    isElectricVehicleSelected: values.hasElectricVehicle,
    isPaperlessBillingSelected: values.paperless,
    isRenewablesSelected: values.greenFutureEnroll,
    isSmartThermostatSelected: values.hasSmartThermostat,
    livesAtPremise: values.liveAtAddress === t('YES'),
    meterAccessInfo: null,
    moveToServiceAddress: {
      addressLine1: values.address,
      city: values.city,
      state: 'OR',
      postal: values.postal,
      country: 'USA',
      qasVerified: true,
    },
    selectedPremiseType:
      values.ownOrRent === t('OWN')
        ? PremiseType.Own
        : values.ownOrRent === t('RENT')
        ? PremiseType.Rent
        : PremiseType.None,
    startDate: values.startDate,
    stopDate: values.stopDate,
    waterHeaterType: 'CONVELECWATHEAT',
    coCustomersInformation: [
      values.mainCustomer ? createCoCustomerPayload(values.mainCustomer) : null,
      values.coCustomer ? createCoCustomerPayload(values.coCustomer) : null,
    ].filter(Boolean),
    preferredDueDate:
      (values.billDueOn && Number(values.billDueOn)) > 0
        ? values.billDueOn.toString()
        : null,
    isAddressExactMatch: values.isAddressExactMatch,
    premiseId: values.premiseId,
  };
  return payload;
};
