import { useState } from 'react';
import { navigate } from '@reach/router';
import ROUTES from '../../routes';
import {
  OneTimePayConfirmationResponse,
  OneTimePayConfigData,
} from '../payment-form/oneTimePayment.types';

export const DEFAULT_MONTHS = 12;
export const MAX_NUM_MONTHS_FOR_SHORT_TERM = 12;

export type PaymentObject = {
  oneTimePayConfirmationData: OneTimePayConfirmationResponse;
  oneTimePayConfig?: OneTimePayConfigData | null;
  paymentMethodCapLimit: number;
};

export default () => {
  const [
    oneTimePayConfirmationDataDetails,
    setOneTimePayConfirmationData,
  ] = useState<OneTimePayConfirmationResponse>();
  const [oneTimePayConfigDataDetails, setOneTimePayConfig] = useState<
    OneTimePayConfigData
  >();
  const [paymentMethodCapLimitData, setPaymentMethodCapLimit] = useState<
    number
  >(2);

  const handleOnPaymentConfirmation = async (data: PaymentObject) => {
    const {
      oneTimePayConfirmationData,
      oneTimePayConfig,
      paymentMethodCapLimit,
    } = data;
    if (
      oneTimePayConfirmationData &&
      oneTimePayConfig &&
      paymentMethodCapLimit
    ) {
      setOneTimePayConfirmationData(oneTimePayConfirmationData);
      setOneTimePayConfig(oneTimePayConfig);
      setPaymentMethodCapLimit(paymentMethodCapLimit);
    }
    await navigate(ROUTES.RECONNECT_SERVICE_PAYMENT_CONFIRMATION);
  };

  return {
    handleOnPaymentConfirmation,
    oneTimePayConfirmationDataDetails,
    oneTimePayConfigDataDetails,
    paymentMethodCapLimitData,
  };
};
