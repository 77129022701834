import React, { useState } from 'react';

import { useTranslation } from '../../../../../hooks/useTranslation';
import {
  OtherPeopleRelType,
  OtherPeopleResponse,
} from '../../../../../__generated__/pge-types';
import AddUsersCard from '../../CoCustomer/AddUsersCard';
import AddUsersModal from '../AddUsersModal';
import AddAuthorizedPartyForm from './AddAuthorizedPartyForm';
import { AuthUserIsCoCustomerError } from './AuthUserIsCoCustomerError';

type Props = {
  usersList: OtherPeopleResponse[] | undefined;
  refetchCustomerList: () => void;
};

export default ({ usersList, refetchCustomerList }: Props) => {
  const { t } = useTranslation();

  const [addAuthorizedUsers, setAddAuthorizedUsers] = useState<boolean>(false);
  const [alreadyCoCustomerError, setAlreadyCoCustomerError] = useState<boolean>(
    false,
  );

  return (
    <>
      <AddUsersCard
        title={t('SSM_POST_ADD_AUTH_USERS_CARD_TITLE')}
        description={t('SSM_POST_ADD_AUTH_USERS_DESC')}
        usersList={usersList?.filter(
          (user: OtherPeopleResponse) =>
            user.acctRelType === OtherPeopleRelType.Authusr,
        )}
        showAddUsersModal={() => setAddAuthorizedUsers(true)}
        authUsers={true}
      />

      {addAuthorizedUsers && (
        <AddUsersModal
          onClose={() => setAddAuthorizedUsers(false)}
          open={addAuthorizedUsers}
        >
          <AddAuthorizedPartyForm
            onClose={() => setAddAuthorizedUsers(false)}
            refetchCustomerList={refetchCustomerList}
            usersList={usersList}
            showCoCustomerErrorModal={() => setAlreadyCoCustomerError(true)}
          />
        </AddUsersModal>
      )}

      <AuthUserIsCoCustomerError
        isOpen={alreadyCoCustomerError}
        handleClose={() => setAlreadyCoCustomerError(false)}
      />
    </>
  );
};
