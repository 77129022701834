import {
  Card,
  CardContent,
  createStyles,
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { useLocation } from '@reach/router';
import sumBy from 'lodash/sumBy';
import React from 'react';
import { FormState } from '../../hooks/useFormState.types';
import { useTranslation } from '../../hooks/useTranslation';
import { toCurrencyString, toDateStringFullMonthName } from '../../util/format';
import { useIsMobile } from '../../util/style-utils';
import DatePicker from '../date-picker';
import useStyles from '../payment-form/PaymentForm.styles';
import PaymentProfileIcon from '../paymentus/payment-profile-icon';
import { AccountAmounts, PaymentDateState, PaymentProfileState } from './types';
import { add } from 'date-fns';
import { getAccountDisplayString } from '../paymentus/utils';
import { getPaymentMethodLabel } from '../../hooks/usePaymentus';

const useLocalStyles = makeStyles((theme: Theme) =>
  createStyles({
    big: {
      fontSize: '1.8em',
    },
  }),
);
type Props = {
  accountAmounts: Array<AccountAmounts>;
  profileState: PaymentProfileState;
  paymentDateState: PaymentDateState;
  paymentDateForm?: FormState<PaymentDateState>;
};

const PaymentSummary = ({
  accountAmounts,
  profileState,
  paymentDateForm,
  paymentDateState,
}: Props) => {
  const { t, richT } = useTranslation();
  const isMobile = useIsMobile();
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const location = useLocation();

  const total = sumBy(accountAmounts, 'amount');

  return (
    <Grid container spacing={3} style={{ marginTop: '1em' }}>
      <Grid item xs={12}>
        <Grid
          item
          xs={12}
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            margin: '1em 0',
          }}
        >
          <Grid item xs={4}>
            <Typography variant={'body1'} className={classes.colorTextPrimary}>
              {isMobile ? t('PAY') : t('PAYMENT_AMOUNT')}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={8}
            direction={'row'}
            alignItems={'center'}
            justify={'space-between'}
          >
            <Grid item>
              <Typography
                variant={'body1'}
                className={`${classes.colorTextPrimary} ${localClasses.big}`}
              >
                ${toCurrencyString(total, true)}
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item>
                <Typography
                  variant={'body1'}
                  className={classes.colorTextPrimary}
                >
                  {t('APPLIED_TO')} {accountAmounts.length}{' '}
                  {(accountAmounts.length !== 1
                    ? t('ACCOUNTS')
                    : t('ACCOUNT')
                  ).toLowerCase()}
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>

        <Divider />

        <Grid
          item
          xs={12}
          style={{
            flexDirection: 'row',
            display: 'flex',
            alignItems: 'center',
            margin: '1em 0',
          }}
        >
          <Grid item xs={4}>
            <Typography variant={'body1'} className={classes.colorTextPrimary}>
              {t('ON')}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            {paymentDateForm ? (
              <DatePicker
                minDate={new Date()}
                maxDate={add(new Date(), { years: 1 })}
                name={'paymentDate'}
                label={t('PAYMENT_DATE')}
                variant={'outlined'}
                {...paymentDateForm?.props('paymentDate')}
              />
            ) : (
              <Typography
                variant={'body1'}
                className={`${classes.colorTextPrimary} ${localClasses.big}`}
              >
                {toDateStringFullMonthName(paymentDateState.paymentDate)}
              </Typography>
            )}
          </Grid>
        </Grid>

        <Divider />

        <Grid
          item
          xs={12}
          style={{
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: isMobile ? 'flex-start' : 'center',
            display: 'flex',
            marginTop: '2em',
            marginBottom: '1em',
          }}
        >
          <Grid item xs={12} md={4} style={{ paddingRight: '1em' }}>
            <Typography variant={'body1'} className={classes.colorTextPrimary}>
              {t('USING').toLowerCase()}{' '}
              {getPaymentMethodLabel(
                profileState?.selectedProfileInfo?.profile?.type,
                true,
              )}
            </Typography>
          </Grid>
          <Grid
            container
            direction={'row'}
            item
            xs={12}
            md={8}
            spacing={2}
            alignItems={'center'}
          >
            <Grid item>
              <PaymentProfileIcon
                type={profileState?.selectedProfileInfo?.profile?.type}
              ></PaymentProfileIcon>
            </Grid>
            <Grid item>
              <Typography
                variant={'body1'}
                className={`${classes.colorTextPrimary} ${localClasses.big}`}
              >
                {getAccountDisplayString(
                  profileState?.selectedProfileInfo?.profile,
                ) || t('GUEST_PAY_AUTHORIZED')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentSummary;
