import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { navigate, RouteComponentProps } from '@reach/router';
import React, { FC, useEffect, useState } from 'react';
import Page2ColumnLayout from '../../components/utility/page2-column-layout';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import Helmet from 'react-helmet';
import EssLeftPane from '../../components/ess/EssLeftPane';
import { Wizard } from '../../components/utility/wizard/Wizard';
import { WizardStep } from '../../components/utility/wizard/WizardStep';
import useDirectAccessHistoricalUsage, {
  DirectAccessHistoricalUsageModel,
} from '../../hooks/useDirectAccessHistoricalUsage';
import { createStyles, Hidden, makeStyles, Theme } from '@material-ui/core';
import { createDirectAccessHistoricalUsageFormAdapter, createDirectAccessValidateFunction, DirectAccessHistoricalUsageForm } from '../../components/ess/direct-access-historical-usage-release/DirectAccessHistoricalUsageForm';
import { DirectAccessHistoricalUsageSummary } from '../../components/ess/direct-access-historical-usage-release/DirectAccessHistoricalUsageSummary';
import { DirectAccessHistoricalUsageConfirmation } from '../../components/ess/direct-access-historical-usage-release/DirectAccessHistoricalUsageConfirmation';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import { Group } from '../../__generated__/pge-types';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import useUtil from '../../components/need-more-time-to-pay/useUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wizardTitle: {
      fontSize: '2rem',
    },
  }),
);

const DirectAccessHistoricalUsage: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    directAccessHistoricalUsageState,
    setDirectAccessHistoricalUsageState,
    onConfirm,
  } = useDirectAccessHistoricalUsage();
  
  const pathname = props.location?.pathname;
  const [isFormInvalid, setIsFormInvalid] = useState(true);
  const { groups, customer, loading: accountGroupsLoading } = useAccountCustomer();
  const [businesses, setBusinesses] = useState<{ name: string; isSelected: boolean; groupId: string; }[]>([]);
  const { wrapWithLoader } = useWrapWithLoader();
  const { setErrorNotification } = useUtil();

  useEffect(() => {
    setBusinesses([]);
    if (groups && groups.length > 0) {
      groups.map((group: Group) => {
        setBusinesses(current => [...current, { name: group.groupName, isSelected: false, groupId: group.groupId }]);
      })
    }
  }, [groups])

  const handleHistoricalUsageForm = async (
    state: DirectAccessHistoricalUsageModel,
  ) => {
    setDirectAccessHistoricalUsageState(state);
    return true;
  };

  const handleSubmit = wrapWithLoader(async () => {
    const result = await onConfirm(customer?.personId);
    if (result?.errors) {
      setErrorNotification(true);
      return false;
    }
    return true;
  });

  const handleDone = async () => {
    await navigate(ROUTES.ACCOUNT);
    return true;
  };
  const handleFormValidation = async (value: boolean) => {
    setIsFormInvalid(value);
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(ROUTES.ESS_DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE);
  }, []);

  const steps = [
    {
      path: ROUTES.ESS_DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE,
      title: t('DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE'),
      wizardStep: (
        <WizardStep
          initial={directAccessHistoricalUsageState}
          onNext={handleHistoricalUsageForm}
          formAdapter={createDirectAccessHistoricalUsageFormAdapter()}
          validate={createDirectAccessValidateFunction()}
          component={DirectAccessHistoricalUsageForm}
          componentProps={{
            handleFormValidation,
            businesses,
            accountGroupsLoading
          }}
          backRoute={false}
          nextRoute={ROUTES.ESS_DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE_SUMMARY}
          proceedDisabled={isFormInvalid}
        ></WizardStep>
      ),
    },
    {
      path: ROUTES.ESS_DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE_SUMMARY,
      title: t('DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE'),
      wizardStep: (
        <WizardStep
          initial={directAccessHistoricalUsageState}
          nextText={t('SUBMIT')}
          onNext={handleSubmit}
          backRoute={ROUTES.ESS_DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE}
          component={DirectAccessHistoricalUsageSummary}
        ></WizardStep>
      ),
    },
    {
      path: ROUTES.ESS_DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE_CONFIRMATION,
      title: t('DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE'),
      wizardStep: (
        <WizardStep
          initial={directAccessHistoricalUsageState}
          component={DirectAccessHistoricalUsageConfirmation}
          backRoute={false}
          onNext={handleDone}
          nextText={t('DONE')}
        ></WizardStep>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>{t('DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE')}</title>
      </Helmet>
      <Page2ColumnLayout leftColumn={<EssLeftPane />}>
        <Grid container direction="column" spacing={2}>
          <Hidden smDown>
            <Grid item>
              <Typography variant={'h1'} className={classes.wizardTitle}>
                {steps.find(s => s.path === pathname)?.title ?? ''}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item>
            <Wizard basepath="/">
              {steps.map(({ wizardStep, path, title }, index) => {
                return (
                  <wizardStep.type
                    {...{
                      ...wizardStep.props,
                      path: path,
                      key: path,
                      title,
                      backRoute:
                        wizardStep.props.backRoute ??
                        (index > 0 ? steps[index - 1].path : false),
                      nextRoute:
                        wizardStep.props.nextRoute ??
                        (index < steps.length - 1
                          ? steps[index + 1].path
                          : undefined),
                    }}
                  />
                );
              })}
            </Wizard>
          </Grid>
        </Grid>
      </Page2ColumnLayout>
    </>
  );
};

export default DirectAccessHistoricalUsage;
