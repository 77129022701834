import React, { FC } from 'react';
import {
  Typography,
  makeStyles,
  createStyles,
  useTheme,
  useMediaQuery,
  Paper,
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

import { useTranslation } from '../../../../hooks/useTranslation';
import colors from '../../../../themes/main-colors';
import ROUTES from '../../../../routes';
import {
  toDateStringFullMonthName,
  toDateString,
} from '../../../../util/format';
import { calculateTpaAmounts } from '../tpaAmountCalculationUtil';
import ProceedOrCancel from '../../../proceed-or-cancel/ProceedOrCancel';
import useGTMUtilNmttp from '../../gtm/useGTMUtilNmttp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      borderBottom: `1px solid ${colors.lightGray1}`,
      padding: theme.spacing(3, 0, 3, 0),
      '&:first-child': {
        padding: theme.spacing(0, 0, 3, 0),
        '& p': {
          margin: 0,
        },
      },
      '&:last-child': {
        borderBottom: 0,
        padding: theme.spacing(3, 0, 0, 0),
      },
    },
    monthlyInstallment: {
      display: 'inline-block',
    },
    hintText: {
      color: colors.darkGray3,
      fontWeight: 'normal',
    },
    confirmButtonContainer: {
      marginTop: theme.spacing(3),
    },
    completed: {
      color: '#43936F !important',
    },
  }),
);

interface Props {
  path?: string;
  accountBalance?: number;
  monthlyInstallment: number;
  totalNumberOfMonths: number;
  firstInstallmentDate: Date;
  lastInstallmentDate: Date;
  handleOnBackClick: () => void;
  handleOnConfirmClick: () => void;
}
const TPAReviewPlan: FC<Props> = (props: Props) => {
  const {
    path,
    accountBalance,
    totalNumberOfMonths,
    firstInstallmentDate,
    lastInstallmentDate,
    handleOnBackClick,
    handleOnConfirmClick,
  } = props;

  const { gtm_TPAREviewPlan_handleOnBackButtonClick } = useGTMUtilNmttp();
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const confirmButtonClick = () => {
    handleOnConfirmClick();
  };
  const backButtonClick = () => {
    gtm_TPAREviewPlan_handleOnBackButtonClick();
    handleOnBackClick();
  };

  const {
    arrangementAmount: monthlyInstallment,
    downPaymentAmount,
  } = calculateTpaAmounts(accountBalance!, totalNumberOfMonths);

  return (
    <>
      <Paper>
        <Card>
          <CardContent>
            <Typography variant={'h6'} className={classes.contentWrapper}>
              {richT('REVIEW_PLAN_TITLE', {
                MONTHLY_INSTALLMENT: `$${downPaymentAmount}`,
                DUE_DATE: toDateStringFullMonthName(firstInstallmentDate),
              })}
            </Typography>
            <Grid container className={classes.contentWrapper}>
              <Grid item md={4} xs={6}>
                <Typography variant={'h6'}>{t('ACCOUNT_BALANCE')}</Typography>
              </Grid>

              <Grid item md={8} xs={6}>
                <Typography variant={'h2'}>{`$${accountBalance}`}</Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.contentWrapper}>
              <Grid item md={4} xs={6}>
                <Typography variant={'h6'}>
                  {t('MONTHLY_INSTALLMENTS')}
                </Typography>
              </Grid>

              <Grid item md={8} xs={6}>
                <Typography
                  variant={'h2'}
                  className={classes.monthlyInstallment}
                >
                  {`$${monthlyInstallment}/${totalNumberOfMonths}`}
                </Typography>
                <Typography
                  variant={'h5'}
                  component={'span'}
                  className={classes.hintText}
                >
                  {` (${totalNumberOfMonths} installments total)`}
                </Typography>
              </Grid>
            </Grid>
            <Grid container className={classes.contentWrapper}>
              <Grid item md={4} xs={6}>
                <Typography variant={'h6'}>
                  {t('FIRST_INSTALLMENT_DUE')}
                </Typography>
              </Grid>

              <Grid item md={8} xs={6}>
                <Typography variant={'h2'}>
                  {toDateStringFullMonthName(firstInstallmentDate)}
                </Typography>
              </Grid>

              <Typography
                variant={'h5'}
                component={'i'}
                className={classes.hintText}
              >
                {t('FIRST_INSTALLMENT_HELP_TEXT', {
                  MONTHLY_INSTALLMENT: `$${downPaymentAmount}`,
                  DUE_DATE: toDateStringFullMonthName(firstInstallmentDate),
                })}
              </Typography>
            </Grid>
            <Grid container className={classes.contentWrapper}>
              <Grid item md={4} xs={6}>
                <Typography variant={'h6'}>
                  {t('FINAL_INSTALLMENT_DUE')}
                </Typography>
              </Grid>

              <Grid item md={8} xs={6}>
                <Typography variant={'h2'}>
                  {toDateString(lastInstallmentDate, 'MMM. yyyy')}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
      <Grid className={classes.confirmButtonContainer}>
        <ProceedOrCancel
          proceedDisabled={false}
          proceedLabel={t('CONFIRM_PLAN')}
          proceedHandler={confirmButtonClick}
          cancelLabel={t('BACK')}
          cancelHandler={backButtonClick}
          cancelRoute={ROUTES.TPA_CREATE_PLAN}
        />
      </Grid>
    </>
  );
};
export default TPAReviewPlan;
