import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SearchIcon from '@material-ui/icons/Search';
import React, { FunctionComponent } from 'react';

import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';
import Dropdown from '../../dropdown';
import Menu from '../../menu';
import Pagination from '../../pagination';
import { AccountsProps } from '../../payment-form/PaymentForm.types';
import TextField from '../../text-field';
import Link from '../../text-link';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    root: {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
    menuButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    col3: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '0em',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: '-2.25em',
        marginLeft: '5em',
      },
    },
    values: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    checkboxContainer: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '-4.2em',
      },
      [theme.breakpoints.up('md')]: {
        marginRight: '2em',
      },
    },
    accountInfo: {},
    inactive: {
      [theme.breakpoints.up('md')]: {
        marginTop: '1em',
      },
    },
  }),
);

const ManageAccounts: FunctionComponent<AccountsProps | any> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    paginationProps,
    menuItems,
    sortByItems,
    onSearchChange,
    onSortByChange,
    manageAccounts,
    isSelectAll,
    onCheckboxChange,
    handleSelectAll,
  } = props;

  return (
    <>
      <Grid
        item
        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      >
        <Grid item xs={6}>
          <TextField
            name={'searchAccounts'}
            onChange={onSearchChange}
            label={t('Search accounts ')}
            InputProps={{
              endAdornment: (
                <InputAdornment position={'end'}>
                  {<SearchIcon />}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <Dropdown
            label={t('Sort by')}
            selectionList={sortByItems}
            onChange={onSortByChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <div style={{ margin: '1em' }}>
            <Pagination {...paginationProps} />
          </div>
        </Grid>
      </Grid>
      <FormGroup>
        <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
          <FormControlLabel
            control={
              <Checkbox
                name={'selectAll'}
                checked={!!isSelectAll}
                onChange={handleSelectAll}
                color={'primary'}
              />
            }
            label={t('Select all')}
          />
        </Grid>
        {props.state.accounts.map((account: any, idx: number) => (
          <Grid item key={`row-${idx.toString()}`} style={{ marginTop: '1em' }}>
            <Paper>
              <Card>
                <CardContent className={classes.root}>
                  <Hidden mdUp>
                    <CardHeader
                      action={<Menu items={menuItems} value={idx} />}
                    />
                    <Grid
                      item
                      xs={2}
                      md={1}
                      className={classes.checkboxContainer}
                    >
                      <Checkbox
                        color={'primary'}
                        checked={account.active && !!account.checked}
                        disabled={!account.active}
                        onChange={onCheckboxChange}
                        name={`account-checkbox-${idx}`}
                      />
                    </Grid>
                  </Hidden>
                  <Grid item className={classes.container}>
                    <Hidden smDown>
                      <Grid item xs={1} className={classes.checkboxContainer}>
                        <Checkbox
                          color={'primary'}
                          checked={account.active && !!account.checked}
                          disabled={!account.active}
                          onChange={onCheckboxChange}
                          name={`account-checkbox-${idx}`}
                        />
                      </Grid>
                    </Hidden>
                    <Grid item xs={12} className={classes.accountInfo}>
                      <Grid item>
                        <Link
                          plain
                          style={{
                            fontWeight: 'bold',
                            color: account.active
                              ? colors.sparkBlue
                              : colors.noirBlur,
                            cursor: account.active ? 'pointer' : 'arrow',
                          }}
                        >
                          {account.accountNumber}
                          {account.nickname && ` (${account.nickname})`}
                        </Link>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant={'body1'}
                          className={classes.colorTextPrimary}
                        >
                          {account.serviceAddress}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant={'body1'}
                          className={classes.colorTextPrimary}
                        >
                          {account.fullName}
                        </Typography>
                      </Grid>
                    </Grid>
                    {!manageAccounts && (
                      <Grid item xs={12} md={6} className={classes.col3}>
                        {account.active ? (
                          <>
                            <Grid item className={classes.values}>
                              <Typography
                                variant={'body1'}
                                className={classes.colorTextPrimary}
                              >
                                {t('Amount due')}:
                              </Typography>
                              <Typography
                                variant={'subtitle2'}
                                className={classes.colorTextPrimary}
                                style={{ marginLeft: '4px' }}
                              >
                                {account.amountDue}
                              </Typography>
                            </Grid>
                            <Grid item className={classes.values}>
                              <Typography
                                variant={'body1'}
                                className={classes.colorTextPrimary}
                              >
                                {t('Due date')}:
                              </Typography>
                              <Typography
                                variant={'subtitle2'}
                                className={classes.colorTextPrimary}
                                style={{ marginLeft: '4px' }}
                              >
                                {account.dueDate}
                              </Typography>
                            </Grid>
                          </>
                        ) : (
                          <Grid
                            item
                            className={[classes.values, classes.inactive].join(
                              ' ',
                            )}
                          >
                            <Typography
                              variant={'subtitle2'}
                              className={classes.colorTextPrimary}
                            >
                              {t('Inactive')}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )}
                    {manageAccounts && !account.active && (
                      <Grid item xs={12} md={5} className={classes.col3}>
                        <Grid
                          item
                          className={[classes.values, classes.inactive].join(
                            ' ',
                          )}
                        >
                          <Typography
                            variant={'subtitle2'}
                            className={classes.colorTextPrimary}
                          >
                            {t('Inactive')}
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                    {manageAccounts &&
                      props?.state?.primaryAccountNumber ===
                        account.accountNumber && (
                        <Grid
                          item
                          xs={12}
                          md={6}
                          className={classes.col3}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <CheckCircleIcon color={'primary'} />
                          <Typography variant={'body1'}>
                            {t('Primary')}
                          </Typography>
                        </Grid>
                      )}
                    <Grid item xs={2} className={classes.menuButton}>
                      <Hidden smDown>
                        <Menu items={menuItems} value={idx} />
                      </Hidden>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        ))}
      </FormGroup>
    </>
  );
};

export default ManageAccounts;
