import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '../../../components/buttons';
import { useIsMobile } from '../../../util/style-utils';

type Props = {
  path?: string;
  onSubmit: () => void;
};

export default function ManageAlertsIneligible({ onSubmit }: Props) {
  const { t, richT } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography component="span">
              {richT('MANAGE_ALERTS_INELIGIBLE', {
                SERVICE_NUMBER: t('RESIDENTIAL_CUSTOMER_SERVICE_NUMBER'),
                BUSINESS_SERVICE_NUMBER: t('BUSINESS_CUSTOMER_SERVICE_NUMBER'),
              })}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid container item justify={isMobile ? 'center' : 'flex-end'}>
        <Grid item xs={10} md="auto">
          <Button
            color="secondary"
            variant="contained"
            fullWidth
            onClick={onSubmit}
          >
            {t('DONE')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
