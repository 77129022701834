import { Button, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { differenceBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import {
  useDeleteProfile,
  useSavedProfileList,
} from '../../../hooks/usePaymentus';
import { useTranslation } from '../../../hooks/useTranslation';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import {
  PaymentCategory,
  PaymentProfile,
} from '../../../__generated__/pge-types';
import AddPaymentMethodDropdown from '../../payment-method-type/AddPaymentMethodDropdown';
import PaymentMethodTypeIconList from '../../payment-method-type/PaymentMethodTypeIconList';
import EditPaymentProfile from '../../paymentus/edit-payment-profile';
import {
  EditProps,
  PaymentProfileInfo,
  ProfileInfoChangeHandler,
} from '../../paymentus/types';
import {
  getAllowedPaymentCategories,
  getPmCategoryFromType,
} from '../../paymentus/utils';
import PaymentMethodDropdown from '../payment-method-dropdown';
import PaymentMethodList from '../payment-method-list';

const ToggleAddOrUpdate = ({
  toggleAddOrUpdate,
  onToggle,
}: {
  toggleAddOrUpdate: boolean;
  onToggle: () => void;
}) => {
  const { t } = useTranslation();
  return toggleAddOrUpdate ? (
    <Grid container item direction="row" spacing={2} alignItems="center">
      <Grid
        container
        alignItems="center"
        style={{ width: 'auto', marginBottom: '2em' }}
      >
        <PaymentMethodTypeIconList
          labelComponent={
            <Button
              data-testid="pm-close-toggle-button"
              startIcon={<RemoveIcon fontSize="small" />}
              color="primary"
              onClick={event => onToggle()}
            >
              {t('CLOSE')}
            </Button>
          }
        />
      </Grid>
    </Grid>
  ) : (
    <Grid container direction="row" spacing={2} alignItems="center">
      <Grid container alignItems="center" style={{ width: 'auto' }}>
        <PaymentMethodTypeIconList
          labelComponent={
            <Button
              data-testid="pm-add-edit-toggle-button"
              startIcon={<AddIcon fontSize="small" />}
              color="primary"
              onClick={event => onToggle()}
            >
              {`${t('ADD')} / ${t('EDIT')}`}
            </Button>
          }
        />
      </Grid>
    </Grid>
  );
};

type Props = {
  onChange: ProfileInfoChangeHandler;
  onNew: ProfileInfoChangeHandler;
  onDelete: ProfileInfoChangeHandler;
  allowedCategories?: PaymentCategory[];
  excludeCategories?: any[];
  currentProfileInfo?: PaymentProfileInfo;
  excludeProfilesInfo?: PaymentProfileInfo[];
  selectLabel?: string;
  resetSelected?: boolean;
  allowAddOrUpdate?: boolean;
  isAutopay?: boolean;
};

export const PaymentMethodSelector = (props: Props) => {
  const { t } = useTranslation();
  const { wrapWithLoader } = useWrapWithLoader();
  const { deleteProfile } = useDeleteProfile();

  const {
    currentProfileInfo,
    onChange,
    onNew,
    onDelete,
    allowedCategories,
    selectLabel,
    excludeProfilesInfo,
    resetSelected = false,
    allowAddOrUpdate = true,
    isAutopay = false,
  } = props;

  const [editProps, setEditProps] = useState<EditProps>();
  const [showEdit, setShowEdit] = useState(false);
  const [toggleAddOrUpdate, setToggleAddOrUpdate] = useState(false);

  const notificationContext = useContext(NotificationsContext);

  const { customer } = useAccountCustomer();
  const { loading, savedProfileList, refetch } = useSavedProfileList(customer);

  const [profileList, setProfileList] = useState<PaymentProfile[]>([]);
  const [selectedProfileInfo, setSelectedProfileInfo] = useState(
    currentProfileInfo,
  );

  const [deletedProfileList, setDeletedProfileList] = useState<
    PaymentProfile[]
  >([]);

  useEffect(() => {
    if (resetSelected || currentProfileInfo !== selectedProfileInfo) {
      setSelectedProfileInfo(currentProfileInfo);
    }
  }, [resetSelected, currentProfileInfo]);

  useEffect(() => {
    if (savedProfileList) {
      let filterSavedProfileList: PaymentProfile[] = [];
      if (props.excludeCategories && props.excludeCategories.length) {
        filterSavedProfileList = savedProfileList?.filter(
          profile =>
            !props.excludeCategories!.includes(
              getPmCategoryFromType(profile.type),
            ),
        );
      } else {
        filterSavedProfileList = savedProfileList;
      }
      setProfileList(
        differenceBy(
          filterSavedProfileList,
          [
            ...(excludeProfilesInfo || []).map(pi => pi.profile),
            ...deletedProfileList,
          ],
          'token',
        ),
      );
    }
  }, [savedProfileList, excludeProfilesInfo, props.excludeCategories]);

  useEffect(() => {
    if (savedProfileList) {
      setDeletedProfileList(delProfileList =>
        delProfileList.filter(p =>
          savedProfileList.some(sp => sp.token === p.token),
        ),
      );
    }
  }, [savedProfileList]);

  const updateSelectedOnDelete = (deletedProfile: PaymentProfile) => {
    if (deletedProfile.token === selectedProfileInfo?.profile?.token) {
      const pList = profileList.filter(p => p.token !== deletedProfile.token);
      const profile = pList.find(p => p.default) || pList[0];
      if (profile) {
        onChange({ profile });
      }
      setSelectedProfileInfo(profile ? { profile } : undefined);
    }
  };
  const handleDeleteProfile = wrapWithLoader(
    async (profile: PaymentProfile) => {
      setProfileList((pList = []) =>
        pList.filter(p => p.token !== profile?.token),
      );
      setDeletedProfileList(delProfileList => [...delProfileList, profile]);
      await onDelete({ profile });

      await deleteProfile({
        ownerId: customer!.personId,
        token: profile?.token,
      });
      await refetch();
      updateSelectedOnDelete(profile);
      notificationContext.setState({
        isOpen: true,
        severity: 'success',
        variant: 'filled',
        message: t('PAYMENT_PROFILE_DELETE_SUCCESS_MESSAGE'),
      });
    },
  );

  const handleEditProfile = (profile: PaymentProfile) => {
    const onClose = () => {
      setShowEdit(false);
      setEditProps(undefined);
    };

    setEditProps({
      iframeProps: {
        ownerId: customer?.personId,
        token: profile.token,
        pmCategory: getPmCategoryFromType(profile.type)!,
        postMessagePmDetailsOrigin: location.origin,
      },
      onChange: async (changedProfile: PaymentProfile) => {
        await handleEditProfileDone(changedProfile);
        onClose();
        notificationContext.setState({
          isOpen: true,
          severity: 'success',
          variant: 'filled',
          message: t('PAYMENT_PROFILE_EDIT_SUCCESS_MESSAGE'),
        });
      },
      onClose,
    });
    setShowEdit(true);
  };

  const handleEditProfileDone = wrapWithLoader(
    async (profile: PaymentProfile) => {
      setProfileList((pList = []) =>
        pList.map(p => (p.token === profile?.token ? profile : p)),
      );

      await refetch();
    },
  );

  useEffect(() => {
    if (!loading && !profileList.length) {
      setToggleAddOrUpdate(true);
    } else {
      setToggleAddOrUpdate(false);
    }
  }, [loading, profileList.length]);

  const addOrUpdateProps = {
    ownerId: customer?.personId,
    allowedCategories: isAutopay
      ? allowedCategories!
      : getAllowedPaymentCategories(savedProfileList, allowedCategories),

    onNew: async (profile: PaymentProfileInfo) => {
      setProfileList((pList = []) => [...pList, profile.profile]);
      setSelectedProfileInfo(profile);
      setToggleAddOrUpdate(false);
      onNew(profile);
      await refetch();
    },
  };

  const selectProps = {
    currentProfile: selectedProfileInfo?.profile,
    profileList,
    onChange: (profile: PaymentProfile) => {
      const profileInfo = { profile };
      setSelectedProfileInfo(profileInfo);
      onChange(profileInfo);
    },
    onEdit: handleEditProfile,
    onDelete: async (profile: PaymentProfile) => {
      await handleDeleteProfile(profile);
    },
    selectLabel: selectedProfileInfo?.profile ? undefined : selectLabel,
    excludeProfiles: excludeProfilesInfo?.map(pi => pi.profile),
  };

  return (
    <>
      <Grid
        container
        item
        direction="column"
        spacing={1}
        alignItems={'center'}
        justify={'center'}
      >
        <Grid
          container
          item
          direction="column"
          spacing={1}
          style={{ width: '100%' }}
        >
          {loading ? (
            <Grid item>
              <CircularProgress></CircularProgress>
            </Grid>
          ) : profileList?.length ? (
            <Grid container item spacing={1} direction="column">
              <Grid item container>
                <PaymentMethodDropdown {...selectProps} />
              </Grid>
              {allowAddOrUpdate && profileList?.length ? (
                <Grid item container>
                  <ToggleAddOrUpdate
                    toggleAddOrUpdate={toggleAddOrUpdate}
                    onToggle={() => setToggleAddOrUpdate(!toggleAddOrUpdate)}
                  />
                </Grid>
              ) : null}
            </Grid>
          ) : null}
        </Grid>
        {allowAddOrUpdate && toggleAddOrUpdate ? (
          <Grid
            container
            item
            direction="column"
            spacing={4}
            alignItems={'stretch'}
            style={{ width: '100%' }}
          >
            <Grid container item spacing={1} direction="row">
              <AddPaymentMethodDropdown {...addOrUpdateProps} />
              {(!profileList || profileList.length === 0) && (
                <PaymentMethodTypeIconList
                  labelComponent={<Typography> + {t('WE_ACCEPT')}</Typography>}
                />
              )}
            </Grid>
            {profileList?.length ? (
              <Grid
                container
                item
                spacing={1}
                direction="row"
                // style={{ width: '100%' }}
              >
                <Grid
                  container
                  item
                  xs={12}
                  // style={{ width: '100%' }}
                >
                  <PaymentMethodList {...selectProps}></PaymentMethodList>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        ) : null}
      </Grid>
      {editProps && (
        <EditPaymentProfile
          open={showEdit}
          editProps={editProps}
        ></EditPaymentProfile>
      )}
    </>
  );
};

export default PaymentMethodSelector;
