import { useApolloClient } from '@apollo/react-hooks';
import gql from 'not-graphql-tag';
import {
  MoveSvcRequest,
  MoveSvcSubmitResponse,
} from '../__generated__/pge-types';

const moveServiceMutation = gql`
  mutation MoveSvcSubmit($payload: MoveSvcRequest) {
    moveSvcSubmit(payload: $payload) {
      isSuccessfull
    }
  }
`;

export function useSSMMoveSubmit() {
  const apolloClient = useApolloClient();
  async function submit(payload: MoveSvcRequest) {
    const result = await apolloClient.mutate<{
      moveSvcSubmit: MoveSvcSubmitResponse;
    }>({
      mutation: moveServiceMutation,
      variables: {
        payload,
      },
      refetchQueries: ['getAccountInfo'],
    });
    return result;
  }
  return { submit };
}
