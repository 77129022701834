import { createStyles, makeStyles, Theme } from '@material-ui/core';
import colors from '../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justtifyContent: 'flex-end',
    },
    inputField: {
      [theme.breakpoints.up('md')]: { width: '40% !important' },
    },
    backLinkDesktop: {
      display: 'inline-block',
      marginRight: 20,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    backLinkMobile: {
      display: 'none',
      margin: 20,
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    submitButton: {
      paddingLeft: 25,
      paddingRight: 25,
      marginLeft: 10,
      fontSize: 15,
      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
        width: '100%',
      },
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    colorTextError: {
      color: colors.roseRed,
    },
    confirmationNumber: {
      color: colors.noirBlur,
      [theme.breakpoints.down('xs')]: {},
      [theme.breakpoints.up('sm')]: {
        marginLeft: 3,
        marginRight: 3,
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: 3,
        marginRight: 3,
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: 3,
        marginRight: 3,
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      zIndex: -1,
    },
    submit: {
      display: 'flex',
      marginTop: '10px',
      margin: theme.spacing(3, 0, 2),
      justifyContent: 'flex-end',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: 'auto',
        justifyContent: 'center',
      },
    },
    backButton: {
      color: '#fff',
      // '&:hover': {
      // }
    },
    buttonWrapper: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    buttonContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column-reverse',
      },
      // [theme.breakpoints.down('xs')]: {
      //   flexDirection: 'column',
      // },
    },
    peakTimeRebateSuccessAlert: {
      '& .MuiAlertTitle-root': {
        '& p': {
          margin: theme.spacing(0),
        },
      },
      '& .MuiAlert-message': {
        width: '100%',
      },
    },
    alertTitle: {
      fontWeight: 300,
      margin: '-10px 0 0',
      fontFamily: 'Forma-DJR-Display'
    },
    doneBtnHolder: {
      paddingTop: '10px',
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  }),
);

export default useStyles;
