import gql from 'not-graphql-tag';

export const getBillingAndPaymentHistoryDetails = gql`
  query getBillingAndPaymentHistoryDetails(
    $payload: BillingAndPaymentHistoryDetailsInput!
  ) {
    getBillingAndPaymentHistoryDetails(payload: $payload) {
      accountNumbers
      billingSummaries {
        isSummaryBillAccount
        accountNumber
        description
        encryptedAccountNumber
        encryptedBillingId
        totalKwh
        amountDue
        dueDate
        billDate
        details {
          serviceAddress
        }
      }
      totalAmount
      totalAccounts
    }
  }
`;
