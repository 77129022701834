import { differenceInCalendarDays, isSameDay, parse, isFuture } from 'date-fns';
import {
  AccountDetail,
  AutoPayInfo,
  CurrentCharges,
  PaymentCategory,
  PaymentEligibility,
  PaymentProfile,
  PreferredDueDateDetails,
} from '../../__generated__/pge-types';
import {
  PaymentProfileInfo,
  PaymentProfileMultiSelectWrapper,
} from '../paymentus/types';
import {
  AutoPayConfigData,
  PaymentFeature,
  PaymentMethodSelectorData,
} from './auto-pay.types';
import { Model as BankSectionModel } from './payment-method-selector/BankingInfoSection.rules';

function parseDate(dateString: string) {
  console.log(parse(dateString, 'MM/dd/yyyy', new Date()));
  return parse(dateString, 'MM/dd/yyyy', new Date());
}

function checkSameDay(dateString: string | undefined | null): boolean {
  if (!dateString) {
    return false;
  }
  return isSameDay(new Date(), parseDate(dateString));
}

export function isPDDEligible(
  preferredDueDate?: PreferredDueDateDetails | null,
) {
  return (
    !checkSameDay(preferredDueDate?.dueDate?.effectiveDate) &&
    preferredDueDate?.eligibility?.isEligible
  ); // TODO CVM NEED THIS FROM SCHEMA
}

export function isEligibleToChangePaymentMethod(response?: AutoPayInfo | null) {
  return !response?.isSameDayEnrolled;
}

export function isEligibleToCancel(response?: AutoPayInfo | null) {
  return isEligibleToChangePaymentMethod(response);
}

export function isDateInNextNDays(date: Date | string, n: number): boolean {
  const typedDate = typeof date === 'string' ? parseDate(date) : date;
  return differenceInCalendarDays(typedDate, new Date()) <= n;
}

export function billDueInfo(currentCharges?: CurrentCharges | null) {
  if (!currentCharges) {
    return undefined;
  }

  const { dueDate, amountDue } = currentCharges;

  const isDue = isDateInNextNDays(
    typeof dueDate === 'string' ? parseDate(dueDate) : dueDate,
    3,
  );
  const amount = amountDue;

  if (!isDue || !amount || amount <= 0) {
    return undefined;
  }
  return amount;
}

export function getPaymentMethodSelectorData(
  feature: PaymentFeature,
  eligible?: PaymentEligibility | null,
  isFutureDated?: boolean | null,
  savedProfileList?: PaymentProfile[],
  selectedProfileInfo?: PaymentProfileInfo,
  multiPaymentProfileList?: PaymentProfileMultiSelectWrapper[],
): PaymentMethodSelectorData {
  return {
    paymentType: eligible?.isCashOnly ? 'creditCard' : 'bankAccount',
    pmCategory: eligible?.isCashOnly ? PaymentCategory.Cc : undefined,
    savedProfileList,
    bankAccountOption: 'currentBankAccount',
    savedBankInfo: {
      encryptedBankAccountNumber: null,
      maskedBankAccountNumber: null,
      bankRoutingNumber: null,
    },
    newBankInfo: {
      bankAccountNumber: null,
      bankRoutingNumber: null,
    },
    newProfileList: [],
    paymentFeature: feature,
    selectedProfileInfo,
    disableRememberMe: isFutureDated || false,
    multiPaymentProfileList,
    selectedPaymentProfileList: [],
  };
}

export function defaultBankingData(
  data: PaymentMethodSelectorData,
): BankSectionModel {
  const bankInfo = data.newBankInfo;
  const defaultAccountNumber = bankInfo.bankAccountNumber || '';
  const routingNumber = bankInfo.bankRoutingNumber || '';

  return {
    bankAccountNumber: defaultAccountNumber,
    confirmBankAccountNumber: defaultAccountNumber,
    routingNumber,
    paymentFeature: data.paymentFeature!,
    rememberMe: true,
    disableRememberMe: false,
  };
}

export function getDefaultAutoPayConfigData(
  account: AccountDetail,
  savedProfileList?: PaymentProfile[],
) {
  return {
    selectedDay: account.preferredDueDate?.dueDate?.preferredDueDate || null,
    paymentSelector: getPaymentMethodSelectorData(
      'autoPay',
      account.paymentEligibility,
      account.paymentAlerts?.isFutureDated,
      savedProfileList,
    ),
  };
}

export function mergeAutoPayConfigChanges(
  config: AutoPayConfigData,
  {
    newProfileList,
    selectedProfileInfo,
  }: {
    newProfileList: PaymentProfile[];
    selectedProfileInfo?: PaymentProfileInfo;
  },
): AutoPayConfigData {
  return {
    ...config,
    paymentSelector: {
      ...config.paymentSelector,
      selectedProfileInfo,
    },
  };
}

export function getDayFromDueDate(dueDate?: Date | string) {
  return dueDate
    ? (typeof dueDate === 'string'
        ? new Date(Date.parse(dueDate))
        : dueDate
      ).getUTCDate()
    : null;
}

export function isDateInFuture(dueDate?: Date | string) {
  return dueDate
    ? isFuture(
        typeof dueDate === 'string' ? new Date(Date.parse(dueDate)) : dueDate,
      )
    : false;
}
