import { Router } from '@reach/router';
import React from 'react';
import ROUTES from '../../routes';

import PrivateRoute from '../../components/routing/PrivateRoute';
import NotFoundPage from '../../pages/404';

import SSMPostEnrollment from './post-enrollment';
import SSMStopService from './stop';
import SSMMoveService from './move';

export const SSMRouter = () => {
  return (
    <Router basepath="/">
      <SSMPostEnrollment path={`${ROUTES.SSM_POST_ENROLLMENT}/*`} />
      <SSMStopService path={`${ROUTES.SSM_STOP}/*`} />
      <SSMMoveService path={`${ROUTES.SSM_MOVE}/*`} />
      <PrivateRoute path="/*" component={NotFoundPage} />
    </Router>
  );
};
