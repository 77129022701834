import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import GroupSortFilterPageHeader from '../../account-summary/sort-group-filter-page-header/GroupSortFilterPageHeader';
import Button from '../../buttons';
import TextLink from '../../text-link';
import Modal from '../../modal';
import Box from '@material-ui/core/Box';
import CustomGroupsList from '../../account-summary/multi-accounts/CustomGroupsList';
import Backdrop from '../../backdrop';
import { useSetSelectedGroupId } from '../../../hooks/useSelectedGroupId';
import { useAccountGroupsLanding } from './useAccountGroupsLanding';
import { useIsMobile } from '../../../util/style-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    buttonRow: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
      },
    },
    buttonContainer: {
      display: 'flex',
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
        justifyContent: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
        justifyContent: 'center',
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: '0',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
        justifyContent: 'left',
      },
    },
    button: {
      margin: theme.spacing(1),
    },
    paper: {
      [theme.breakpoints.down('xs')]: {
        position: 'relative',
        width: 300,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #FFF',
        boxShadow: 'none',
        padding: theme.spacing(2),
        outline: 0,
      },
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #FFF',
        boxShadow: 'none',
        padding: theme.spacing(2, 4, 3),
        outline: 0,
      },
    },
  }),
);

type Props = {
  path?: string;
};

export default function AccountGroupsLanding(_: Props) {
  const { richT, t } = useTranslation();
  const classes = useStyles();
  const { setSelectedGroupId } = useSetSelectedGroupId();
  const {
    loading,
    state,
    multicheckState,
    isRemoveButtonDisabled,
    showRemoveGroupModal,
    toggleRemoveGroupModal,
    handleMultiGroupsDelete,
    groupToRemove,
    setGroupIdToRemove,
    setGroupAsDefault,
    sortFilterProps,
    showMakePrimary,
  } = useAccountGroupsLanding();
  const isMobile = useIsMobile();

  const modalCopy = () => {
    if (multicheckState.allSelected) {
      return richT('ONLINE_ACCESS_REMOVE_ALL');
    }
    if (multicheckState.getSelected().length > 1) {
      return richT('ONLINE_ACCESS_REMOVE_MULTIPLE');
    }
    return richT('ONLINE_ACCESS_REMOVE_SINGLE');
  };
  const modalHeader = () => {
    if (multicheckState.getSelected().length === 1) {
      return t('REMOVE_ACCOUNT_GROUP_MODAL_HEAD');
    }
    return t('REMOVE_ACCOUNT_GROUPS_MODAL_HEAD');
  };

  return (
    <>
      <Grid item xs={12} style={{ marginTop: '1em' }}>
        <Paper>
          <Card>
            <CardContent>
              <Grid item xs={12}>
                <Grid item style={{ marginBottom: '1em' }}>
                  <Typography
                    variant={'body2'}
                    style={{
                      display: 'inline-block',
                      marginRight: '1em',
                      fontSize: '18px',
                    }}
                  >
                    {t('ACCOUNT_GROUP_LANDING_PARA_ONE')}
                  </Typography>
                </Grid>
                <Grid item style={{ marginBottom: '1em' }}>
                  <Typography
                    variant={'body2'}
                    style={{
                      display: 'inline-block',
                      marginRight: '1em',
                      fontSize: '18px',
                    }}
                  >
                    {t('ACCOUNT_GROUP_LANDING_PARA_TWO')}
                  </Typography>
                </Grid>
              </Grid>
              <Button
                onClick={() => navigate(ROUTES.MANAGE_GROUPS_ACCOUNT)}
                size={'large'}
                className={classes.button}
                variant={'outlined'}
                color={'secondary'}
              >
                {t('ADD_ACCOUNT_GROUP_BUTTON')}
              </Button>
              <Button
                onClick={() => navigate(ROUTES.CREATE_CUSTOM_GROUP)}
                size={'large'}
                className={classes.button}
                variant={'outlined'}
                color={'secondary'}
              >
                {t('CREATE_ACCOUNT_GROUP_BUTTON')}
              </Button>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
      <Grid xs={12} style={{ marginTop: '1em' }}>
        <Paper>
          <Card>
            <CardContent>
              {!loading && (
                <Grid item xs={12}>
                  <Typography
                    style={{ color: colors.noirBlur }}
                    component={'span'}
                    variant={'h2'}
                  >
                    {t('ACCOUNT_GROUPS_LANDING_CARD2_HEAD')}
                  </Typography>

                  <GroupSortFilterPageHeader
                    dispatch={sortFilterProps.dispatch}
                    actions={sortFilterProps.GroupListReducer.actions}
                    groupListParams={sortFilterProps.groupListParams}
                    totalCount={sortFilterProps.totalCount}
                  />
                </Grid>
              )}

              {loading && <Backdrop forceOpen />}

              {!loading && (
                <Grid item xs={12}>
                  <CustomGroupsList
                    groups={state.groups || []}
                    multicheckState={multicheckState}
                    onRemoveGroup={group => {
                      setGroupIdToRemove(group?.groupId);
                    }}
                    onViewDetails={async group => {
                      await navigate(ROUTES.ACCOUNT);
                      setTimeout(() => {
                        setSelectedGroupId(group?.groupId);
                      });
                      window.scrollTo(0, 0);
                    }}
                    onSetGroupDefault={async group => {
                      await setGroupAsDefault(group);
                    }}
                    onEditGroup={async group => {
                      await navigate(ROUTES.MANAGE_ACCOUNT_GROUPS);
                      setSelectedGroupId(group?.groupId);
                      window.scrollTo(0, 0);
                    }}
                    showMakePrimary={showMakePrimary()}
                  />
                </Grid>
              )}

              <Grid
                item
                xs={12}
                style={{
                  margin: '1em',
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                }}
              >
                <Grid item xs={'auto'}>
                  <Typography variant={'body1'}>
                    {t('REMOVE_ACCOUNTS_NOTE')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  marginRight: '1em',
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justify={isMobile ? 'center' : 'flex-end'}
                  alignItems="center"
                  wrap="wrap-reverse"
                >
                  <Grid item xs={10} md="auto">
                    <Grid container justify="center">
                      <Grid item>
                        <TextLink plain to={ROUTES.ACCOUNT}>
                          {t('CANCEL')}
                        </TextLink>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10} md="auto">
                    <Button
                      onClick={() => toggleRemoveGroupModal(true)}
                      disabled={isRemoveButtonDisabled}
                      size={'large'}
                      className={classes.button}
                      fullWidth
                    >
                      {t('REMOVE_ACCOUNT_BUTTON')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
      {showRemoveGroupModal && (
        <Modal
          open={showRemoveGroupModal}
          showCloseIcon={true}
          onClose={() => toggleRemoveGroupModal(false)}
          title={<Typography variant="h2">{modalHeader()}</Typography>}
        >
          <Paper className={classes.paper}>
            <Typography variant={'body2'}>
              <Grid item xs={12}>
                <Typography>{modalCopy()}</Typography>
              </Grid>
            </Typography>
            <Grid
              container
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingTop: 10,
              }}
            >
              <Box justifyContent="flex-end" display="flex">
                <Button
                  style={{ marginRight: 10 }}
                  className={classes.button}
                  color={'secondary'}
                  variant={'outlined'}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleRemoveGroupModal(false);
                  }}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  className={classes.button}
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => {
                    return handleMultiGroupsDelete(
                      multicheckState.getSelected(),
                    );
                  }}
                >
                  {t('REMOVE_ACCOUNT_BUTTON')}
                </Button>
              </Box>
            </Grid>
          </Paper>
        </Modal>
      )}
      {groupToRemove && (
        <Modal
          open={true}
          showCloseIcon={true}
          onClose={() => setGroupIdToRemove('')}
          title={
            <Typography variant="h2">
              {t('REMOVE_ACCOUNT_GROUPS_MODAL_HEAD')}
            </Typography>
          }
        >
          <Paper className={classes.paper}>
            <Typography variant={'body2'}>
              <Grid item xs={12}>
                <Typography component="span">
                  {richT('ONLINE_ACCESS_REMOVE_SINGLE')}
                </Typography>
              </Grid>
            </Typography>
            <Grid
              container
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingTop: 10,
              }}
            >
              <Box justifyContent="flex-end" display="flex">
                <Button
                  style={{ marginRight: 10 }}
                  className={classes.button}
                  color={'secondary'}
                  variant={'outlined'}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setGroupIdToRemove('');
                  }}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  className={classes.button}
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => {
                    return handleMultiGroupsDelete([groupToRemove]);
                  }}
                >
                  {t('REMOVE_ACCOUNT_BUTTON')}
                </Button>
              </Box>
            </Grid>
          </Paper>
        </Modal>
      )}
    </>
  );
}
