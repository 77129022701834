import React, { useEffect } from 'react';
import withWidth from '@material-ui/core/withWidth';
import Grid from '@material-ui/core/Grid';
import ViewBill from '../../../components/view-bill';
import useViewBill from '../../../hooks/useViewBill';
import { useTranslation } from '../../../hooks/useTranslation';
import { getViewBillInfo } from '../../../queries/viewBill.query';
import useTPA from '../../../components/need-more-time-to-pay/tpa/useTPA';
import useAccountDetailList from '../../../hooks/useAccountDetailList';
import { getAccountsList } from '../../../components/account-dropdown/queries';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import { AccountDetail } from '../../../__generated__/pge-types';
import routes from '../../../routes';
import useAccountListHeader from '../../../components/account-summary/multi-accounts/useAccountListHeader';
import useAccountsStore from '../../../hooks/useAccountsStore';
import { VirtualAccountType } from '../../../constants/customEnum';

const ViewBillPage = () => {
  //Check if this is redirected from quick view bill page with query param view-bill?selectedAccount=12345
  const accountParam: string | null = new URLSearchParams(location.search).get(
    'selectedAccount',
  );

  return accountParam ? (
    <ViewBillPageWithSelectedAccount accountParam={accountParam} />
  ) : (
    <DefaultViewBillPage />
  );
};

/** If this is view bill page is redirected with the selectedAccount param in the URL (mostly from quick view bill unhappy scenarios),
 * we need to set that as a default selected account for the logged in user
 */

const ViewBillPageWithSelectedAccount = (props: { accountParam: string }) => {
  const { setSelectedAccountParams } = useSelectedAccountParams();

  const { accountListParams } = useAccountListHeader();

  const { loading, accounts } = useAccountDetailList(
    getAccountsList,
    VirtualAccountType.ALL_ACCTS,
    {
      accountListParams,
    },
  );

  useEffect(() => {
    if (!loading && props.accountParam) {
      const accountDetail: AccountDetail[] = accounts.filter(
        ({ accountNumber }) => props.accountParam === accountNumber,
      );

      if (accountDetail.length > 0) {
        setSelectedAccountParams(
          VirtualAccountType.ALL_ACCTS,
          accountDetail[0],
        );
        window.history.replaceState(
          {},
          document.title,
          routes.VIEW_BILL + location.hash,
        );
      } else {
        //Show primary account if the account is not linked to the user
        const primaryAccount = accounts.filter(({ isDefault }) => isDefault);

        if (primaryAccount.length > 0) {
          setSelectedAccountParams(
            VirtualAccountType.ALL_ACCTS,
            primaryAccount[0],
          );
          window.history.replaceState(
            {},
            document.title,
            routes.VIEW_BILL + location.hash,
          );
        } else {
          window.history.replaceState(
            {},
            document.title,
            routes.VIEW_BILL + location.hash,
          );
        }
      }
    }
  }, [accounts, loading]);

  return (
    <Grid container justify="center" alignItems="center">
      <ViewBill
        accountParams={null}
        loading={false}
        billDetails={null}
        selectedAccount={null}
        monthUsage={null}
        averageTemperature={null}
        billInfo={null}
        autopay={null}
        isPendingDisconnect={undefined}
        paymentPlanType={null}
        encryptedAccountNumber={null}
        encryptedPersonId={undefined}
        tpaDetails={undefined}
        pgeEnergyTracker={undefined}
        isSummaryBillAccount={undefined}
        isNewAccount={undefined}
      />
    </Grid>
  );
};

const DefaultViewBillPage = () => {
  const { t } = useTranslation();
  const { setAccountDisconnectedStatus } = useAccountsStore();
  const {
    loading,
    billDetails,
    averageTemperature,
    monthUsage,
    selectedAccount,
    accountParams,
    latestPaymentHistory,
    billInfo,
    autopay,
    isPendingDisconnect,
    paymentPlanType,
    pgeEnergyTrackerData,
    isSummaryBillAccount,
    isNewAccount,
  } = useViewBill(getViewBillInfo);

  const { tpaDetails } = useTPA({ shouldRedirect: false });

  const {
    encryptedAccountNumber,
    encryptedPersonId,
  } = useSelectedAccountParams();

  useEffect(() => {
    if (selectedAccount) {
      setAccountDisconnectedStatus(
        selectedAccount?.serviceConnectivity?.isReconnectInProgress!,
        selectedAccount?.serviceConnectivity?.isDisconnected!,
        selectedAccount?.serviceConnectivity?.isEligibleForReconnect!,
      );
    }
  }, [selectedAccount]);
  return (
    <Grid container justify="center" alignItems="center">
      <ViewBill
        accountParams={accountParams}
        loading={loading}
        billDetails={billDetails}
        selectedAccount={selectedAccount}
        monthUsage={monthUsage}
        averageTemperature={averageTemperature}
        latestPaymentHistory={latestPaymentHistory}
        billInfo={billInfo}
        autopay={autopay}
        isPendingDisconnect={isPendingDisconnect}
        paymentPlanType={paymentPlanType}
        encryptedAccountNumber={encryptedAccountNumber}
        encryptedPersonId={encryptedPersonId}
        tpaDetails={tpaDetails}
        pgeEnergyTracker={pgeEnergyTrackerData}
        isSummaryBillAccount={isSummaryBillAccount!}
        isNewAccount={isNewAccount}
      />
    </Grid>
  );
};

export default withWidth()(ViewBillPage);
