import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { Group } from '../../../__generated__/pge-types';
import { MulticheckState } from '../../../hooks/useMulticheckState';
import CustomGroupListItem from './CustomGroupListItem';

type Props = {
  groups: Array<Group>;
  onRemoveGroup: (group: Group) => void;
  onViewDetails: (group: Group) => void;
  onSetGroupDefault: (group: Group) => void;
  onEditGroup: (group: Group) => void;
  multicheckState: MulticheckState;
  showMakePrimary?: boolean;
};

export default function CustomGroupsList(props: Props) {
  const { t } = useTranslation();
  const {
    groups,
    onRemoveGroup,
    multicheckState,
    onViewDetails,
    onEditGroup,
    onSetGroupDefault,
    showMakePrimary,
  } = props;
  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: '0 1em',
        }}
      >
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                name={'selectAll'}
                checked={multicheckState.allSelected}
                onChange={multicheckState.handleSelectAll}
                color={'primary'}
              />
            }
            disabled={multicheckState.selectAllDisabled}
            label={
              multicheckState.getSelected().length
                ? `${t('SELECT_ALL')} (${multicheckState.getSelected().length})`
                : t('SELECT_ALL')
            }
          />
        </Grid>
      </Grid>
      {groups.map((group: Group) => (
        <CustomGroupListItem
          key={group.groupId}
          group={group}
          onCheckboxChange={multicheckState.handleCheckToggle(group.groupId)}
          checked={multicheckState.isChecked(group.groupId)}
          onRemoveGroup={onRemoveGroup}
          onViewDetails={onViewDetails}
          onEditGroup={onEditGroup}
          onSetGroupDefault={onSetGroupDefault}
          showMakePrimary={showMakePrimary}
        />
      ))}
    </>
  );
}
