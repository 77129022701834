import React, { useEffect } from 'react';
import { useTheme } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PhoneTextField from '../../phone-text-field';
import TextField from '../../text-field';
import TextLink from '../../text-link';
import colors from '../../../themes/main-colors';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';

type PeakTimeRebatesProps = {
  form: any;
  customerEmail: string;
  customerPhone: string | undefined;
};

const PeakTimeRebates = ({
  form,
  customerEmail,
  customerPhone,
}: PeakTimeRebatesProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    if (customerEmail && !form.values.peakTimeEmail) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async () => {
        await form.setValue('peakTimeEmail', customerEmail);
      })();
    }
  }, [customerEmail]);

  useEffect(() => {
    if (customerPhone && !form.values.peakTimePhone) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async () => {
        await form.setValue('peakTimePhone', customerPhone);
      })();
    } else if (!customerPhone && !form.values.peakTimePhone) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      (async () => {
        await form.setValue('peakTimePhone', '');
      })();
    }
  }, [customerPhone]);

  return (
    <Grid container spacing={2}>
      <Grid item style={{ width: 50 }}>
        <FormControlLabel
          style={{ height: 50, marginTop: 10 }}
          control={
            <Checkbox
              name={'peakTimeEnroll'}
              checked={Boolean(form.values.peakTimeEnroll)}
              value={Boolean(form.values.peakTimeEnroll)}
              className="peak-time-rebates-enroll"
              color={'primary'}
              onChange={async (e: any) => {
                form.onChange(e);
                form.setError('peakTimeContactError', null);
                if (!e.target.checked) {
                  await Promise.all([
                    form.setValue('peakTimePhone', ''),
                    form.setValue('peakTimeEmail', ''),
                  ]);
                  form.setError('peakTimePhone', null);
                  form.setError('peakTimeEmail', null);
                }
              }}
            />
          }
          label={''}
        />
      </Grid>
      <Grid item md={11}>
        <Grid container spacing={2}>
          <Typography
            variant="h3"
            style={{
              marginTop: theme.spacing(4),
              fontWeight: 'bold',
              marginBottom: theme.spacing(2),
            }}
          >
            {t('PTR_SENTENCE_CASE')}
          </Typography>
          <Grid item xs={12}>
            <Typography
              variant="h3"
              style={{ marginBottom: theme.spacing(2), fontWeight: 400 }}
            >
              {t('PTR_OPTIONS_SUBHEADER')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography component="div" variant="body2">
              <span>
                {`${t('PEAK_TIME_REBATES_OPTIONS_BODY_1')} `}
                <TextLink plain to={ROUTES.PTR_TERMS_AND_CONDITIONS} external>
                  {`${t('PTR_SENTENCE_CASE')}`}
                </TextLink>{' '}
                {t('GREEN_FUTURE_CHOICE_OPTIONS_BODY_2')}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={11}>
            <Typography variant="body2" style={{ marginTop: theme.spacing(2) }}>
              {t('PTR_OPTIONS_NOTIFICATION_TEXT')}
            </Typography>
          </Grid>
          {Boolean(form.errors.peakTimeContactError) && (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                style={{ color: colors.roseRed }}
                id={
                  form.values.peakTimeEnroll
                    ? 'ptr-enter-phone-or-email'
                    : 'ptr-remove-phone-and-email'
                }
              >
                {form.errors.peakTimeContactError}
              </Typography>
            </Grid>
          )}
          <Grid item md={7} xs={12}>
            <PhoneTextField
              name={'peakTimePhone'}
              label={t('MOBILE_PHONE')}
              inputProps={{ minLength: 1, maxLength: 15 }}
              style={{ width: '100%' }}
              onChange={(e: any) => {
                form.onChange(e);
                if (e.target.value && form.values.peakTimeEnroll) {
                  form.setError('peakTimeContactError', null);
                } else if (!e.target.value && !form.values.peakTimeEnroll) {
                  form.setError('peakTimeContactError', null);
                }
              }}
              value={form.values.peakTimePhone}
              error={!!form.errors.peakTimePhone}
              helperText={form.errors.peakTimePhone}
              onBlur={form.onBlur}
            />
          </Grid>
          <Grid item md={7} xs={12}>
            <TextField
              name={'peakTimeEmail'}
              label={t('EMAIL')}
              style={{ width: '100%' }}
              inputProps={{ minLength: 1, maxLength: 50 }}
              onChange={(e: any) => {
                form.onChange(e);
                if (e.target.value && form.values.peakTimeEnroll) {
                  form.setError('peakTimeContactError', null);
                } else if (!e.target.value && !form.values.peakTimeEnroll) {
                  form.setError('peakTimeContactError', null);
                }
              }}
              error={!!form.errors.peakTimeEmail}
              helperText={form.errors.peakTimeEmail}
              onBlur={form.onBlur}
              value={form.values.peakTimeEmail}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PeakTimeRebates;
