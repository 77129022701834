import { navigate } from 'gatsby';
import { Dispatch, SetStateAction, useContext, useState } from 'react';

import {
  addAccount,
  removeAccount,
  removeAccounts,
  updateNickname,
  updatePrimaryAccount,
  updateSelectedAccount,
} from '../actions/account';
import businessValidationRules from '../components/manage-accounts/add-business-account/AddBusinessAccount.rules';
import residentialValidationRules from '../components/manage-accounts/add-residential-account/AddResidentialAccount.rules';
import nickNameDialogValidationRules from '../components/manage-accounts/ManageAccounts.rules';
import { AccountType } from '../components/payment-form/PaymentForm.types';
import { NotificationsContext } from '../providers/NotificationsProvider';
import ROUTES from '../routes';
import useGlobalState from '../state/state';
import useAccountSummary, { AccountSummaryHook } from './useAccountSummary';
import { useTranslation } from './useTranslation';
import useValidationForm, { DefaultFormValuesProps } from './useValidationForm';

export const defaultResidentialFormValues: DefaultFormValuesProps = {
  accountNumber: { value: '', errorMessage: '' },
  nickname: { value: '', errorMessage: '' },
};

export const defaultBusinessFormValues: DefaultFormValuesProps = {
  accountNumber: { value: '', errorMessage: '' },
  nickname: { value: '', errorMessage: '' },
  ein: { value: '', errorMessage: '' },
  phoneNumber: { value: '', errorMessage: '' },
  businessName: { value: '', errorMessage: '' },
  verificationType: { value: '', errorMessage: '' },
};

export type DefaultFormValue = {
  value: string | number | boolean;
  errorMessage: string | null;
};

export interface NicknameDialogFormProps {
  handleSubmit: (event: React.MouseEvent<Element, MouseEvent>) => void;
  handleChange: React.ChangeEvent;
  onBlur: React.MouseEvent;
  nickname: DefaultFormValue;
  type: DefaultFormValue;
  nicknameCheckBox: DefaultFormValue;
}

export interface AddResidentialFormProps {
  accountNumber: DefaultFormValue;
  nickname: DefaultFormValue;
  handleSubmit: React.FormEvent | React.MouseEvent | Event | any;
  handleChange: React.ChangeEvent;
  onBlur: React.MouseEvent;
  isButtonActionDisabled: boolean;
  setFormState: Dispatch<SetStateAction<any>>;
  setErrorState: Dispatch<SetStateAction<any>>;
}

export interface AddBusinessFormProps {
  accountNumber: DefaultFormValue;
  nickname: DefaultFormValue;
  businessName: DefaultFormValue;
  ein: DefaultFormValue;
  phoneNumber: DefaultFormValue;
  verificationType: DefaultFormValue;
  handleSubmit: React.FormEvent | React.MouseEvent | Event | any;
  handleChange: React.ChangeEvent;
  onBlur: React.MouseEvent;
  isButtonActionDisabled: boolean;
  setFormState: Dispatch<SetStateAction<any>>;
  setErrorState: Dispatch<SetStateAction<any>>;
}

export interface ManageAccountHook extends AccountSummaryHook {
  manageAccounts: true;
  isRemoveButtonDisabled: boolean;
  residentialFormProps: AddResidentialFormProps;
  businessFormProps: AddBusinessFormProps;
  handleRemove: () => void;
  showModal: boolean;
  toggleModal: Dispatch<SetStateAction<boolean>>;
  nicknameDialogFormProps: NicknameDialogFormProps;
  showNicknameModal: boolean;
  toggleNicknameModal: Dispatch<SetStateAction<boolean>>;
}

export default (): ManageAccountHook => {
  const notificationContext = useContext(NotificationsContext);
  const accountSummaryProps = useAccountSummary();
  const residentialProps = useValidationForm(
    defaultResidentialFormValues,
    residentialValidationRules,
  );
  const businessProps = useValidationForm(
    defaultBusinessFormValues,
    businessValidationRules,
  );
  const nicknameProps = useValidationForm(
    {
      nickname: { value: '', errorMessage: '' },
      type: { value: 'add', errorMessage: 'remove' },
      nicknameCheckBox: { value: false, errorMessage: '' },
    },
    nickNameDialogValidationRules,
  );
  const [isResidentialButtonDisabled] = useState(true);
  const [showModal, toggleModal] = useState(false);
  const [showNicknameModal, toggleNicknameModal] = useState(false);
  const { t } = useTranslation();
  const [state, setAccountState] = useGlobalState('account');

  const nicknameDialogFormProps = {
    ...nicknameProps,
    handleSubmit: () => {
      if (!!nicknameProps.nicknameCheckBox.value) {
        updateNickname(setAccountState, {
          idx: state.selectedAccount!,
          nickname: '',
        });

        nicknameProps.setFormState((formState: any) => ({
          ...formState,
          nickname: { value: '', errorMessage: '' },
          nicknameCheckBox: { value: false, errorMessage: '' },
        }));
      } else {
        updateNickname(setAccountState, {
          idx: state.selectedAccount!,
          nickname: nicknameProps.nickname.value,
        });
      }
    },
  };

  const getAccount = (
    acct: AccountType | DefaultFormValuesProps | any,
  ): AccountType => ({
    ...acct,
    accountNumber: acct.accountNumber.value,
    serviceAddress: state.serviceAddress,
    fullName: state.fullName,
    amountDue: state.amountDue,
    dueDate: state.dueDate,
    pastDue: new Date(state.dueDate) < new Date(),
    nickname: acct.nickname.value,
    active: true,
    checked: false,
    businessName: acct?.businessName?.value,
    ein: acct?.ein?.value,
    phoneNumber: acct?.phoneNumber?.value,
    verificationType: acct?.verificationType?.value,
  });

  const onAddSuccess = () => {
    notificationContext.setState({
      isOpen: true,
      severity: 'success',
      variant: 'filled',
      message: t('1 account added'),
    });
  };

  const residentialFormProps: AddResidentialFormProps = {
    ...residentialProps,
    isButtonActionDisabled: isResidentialButtonDisabled,
    handleSubmit: () => {
      const { data, errors } = residentialProps.handleSubmit();
      if (!errors.length) {
        addAccount(setAccountState, getAccount(data));

        residentialProps.setFormState(defaultResidentialFormValues);
        onAddSuccess();
        return navigate(ROUTES.MANAGE_ACCOUNTS);
      }
    },
  };

  const businessFormProps: AddBusinessFormProps = {
    ...businessProps,
    isButtonActionDisabled: false,
    handleSubmit: () => {
      const { data, errors } = businessProps.handleSubmit();
      if (errors.length < 2) {
        addAccount(setAccountState, getAccount(data));

        businessProps.setFormState(defaultBusinessFormValues);
        onAddSuccess();
        return navigate(ROUTES.MANAGE_ACCOUNTS);
      }
    },
  };

  const onRemoveSuccess = (message: string): void => {
    notificationContext.setState({
      isOpen: true,
      severity: 'success',
      variant: 'filled',
      message: message,
    });
  };

  const handleRemove = (): void => {
    const accounts = state.accounts.filter(
      acct => !acct.checked && !acct.active,
    );
    removeAccounts(setAccountState);

    // close modal
    toggleModal(false);

    const message = (): string => {
      if (accounts.length > 1) {
        return `[${accounts.map(
          acct => acct.accountNumber,
        )}] accounts removed.`;
      }
      return 'Account removed.';
    };

    onRemoveSuccess(message());

    // scroll to top of the page so you can see the notifications feedback
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };

  const menuItems = [
    {
      text: t('View Details'),
    },
    {
      text: t('Nickname'),
      onClick: (_: any, idx: number) => {
        const { active } = state.accounts[idx];
        if (active) {
          updateSelectedAccount(setAccountState, { idx });
          toggleNicknameModal(true);
        }
      },
    },
    {
      text: t('Remove'),
      onClick: (_: any, idx: number) => {
        removeAccount(setAccountState, { idx });
      },
    },
    {
      text: t('Make Primary'),
      onClick: (_: any, idx: number) => {
        updatePrimaryAccount(setAccountState, { idx });
      },
    },
  ];

  const isRemoveButtonDisabled = !state.accounts.some(row => !!row.checked);

  return {
    ...accountSummaryProps,
    nicknameDialogFormProps,
    residentialFormProps,
    businessFormProps,
    state,
    menuItems,
    manageAccounts: true,
    isRemoveButtonDisabled,
    handleRemove,
    showModal,
    toggleModal,
    showNicknameModal,
    toggleNicknameModal,
  };
};
