import React, { useContext, useEffect } from 'react';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';

import PGEButton from '../../buttons/PGE-Button';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DownloadAndPrint } from '../../download-print/DownloadAndPrint';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
  actionArea: {
    '& Button': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: theme.spacing(3),
      },
    },
  },
}));

const useCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      border: `1px solid ${theme.palette.success.main}`,
    },
    color: {
      color: theme.palette.success.main,
    },
  }),
);

interface AutoPayCancelDoneProps {
  path?: string;
  onSubmit: () => void;
  printContainerId: string;
}

export default ({ onSubmit, printContainerId }: AutoPayCancelDoneProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const styles = useCardStyles();
  const title = t('AUTO_PAY_CANCEL_DONE_TITLE');
  const message = t('AUTO_PAY_CANCEL_DONE_MESSAGE');

  const notificationContext = useContext(NotificationsContext);
  useEffect(() => {
    notificationContext.setState({
      isOpen: true,
      severity: 'success',
      variant: 'filled',
      message: t('AUTO_PAY_CANCEL_DONE_NOTIFICATION'),
    });
  }, []);

  return (
    <Paper component={'main'} className={styles.border}>
      <Box style={{ padding: '1em' }}>
        <Grid container spacing={4}>
        <Grid item container direction={'row'} spacing={2} justify='space-between'>
          <Grid item>
          <Grid
            container
            spacing={2}
            direction={'row'}
            alignItems={'center'}
          >
            <Grid item>
              <Typography
                variant={'h2'}
                className={styles.color}
                style={{ paddingTop: '0.3em' }}
              >
                <CheckCircleIcon fontSize="inherit" />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'h2'}>{title}</Typography>
            </Grid>
          </Grid>
          </Grid>
            <Grid item>
              <DownloadAndPrint
                containerId={printContainerId}
                excludeSelectors={['[data-noprint]']}
              ></DownloadAndPrint>
          </Grid>
        </Grid>
          <Grid item xs={12} style={{ paddingLeft: '1.5em' }}>
            <Grid container item xs={12} spacing={4}>
              <Grid container item>
                <Typography>{message}</Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} data-noprint>
              <Grid
                container
                item
                xs={12}
                justify="flex-end"
                className={classes.actionArea}
              >
                <PGEButton onClick={onSubmit}>{t('DONE')}</PGEButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
