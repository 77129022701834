/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FC, ReactNode, useState, useEffect, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import Modal from '../../pge-plus-common/Modal';
import useContentMgmt from '../../../hooks/useContentMgmt';
import colors from '../../../themes/main-colors';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import routes from '../../../routes';

interface DeclineModalProps {
  children?: ReactNode;
  path: string;
  onCloseModal?: any;
  handleDecline?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalDecline: {
      width: '150px',
      border: '2px solid #27788C',
      marginRight: '20px',
      borderRadius: '30px',
    },
    close: {
      width: '150px',
      border: '2px solid #27788C',
      borderRadius: '30px',
    },
  }),
);

export const DeclineModal: React.FC<DeclineModalProps> = props => {
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_FINAL_QUOTE_ACCEPT_DECLINE,
  );
  const classes = useStyles();

  return (
    <Modal
      open={props?.children === 'declineModal'}
      onClose={() => {
        props?.onCloseModal(null);
      }}
      showCloseIcon={true}
    >
      <Grid container>
        <Typography
          variant="h2"
          css={{
            color: colors.noirBlur,
            fontFamily: 'Untitled-Sans',
            marginBottom: '20px',
          }}
        >
          {content.get('DECLINE_QUOTE_ARE_YOU_SURE_HEADLINE')}
        </Typography>
        <Typography
          css={{
            fontSize: '20px',
            fontFamily: 'Untitled-Sans',
            marginBottom: '50px',
            lineHeight: '26px',
          }}
        >
          {content.get('CUSTOMER_QUOTE_DECLINE_QUOTE_IF_YOU_QUESTIONS')}
        </Typography>

        <Box
          css={{
            alignItems: 'center',
            display: 'contents',
          }}
        >
          <Button
            variant={'outlined'}
            className={classes.modalDecline}
            onClick={props.handleDecline}
            data-testid="modal-back-button"
          >
            {content.get('REUSABLE_BUTTON_DECLINE')}
          </Button>
          <Button
            color={'primary'}
            variant={'contained'}
            className={classes.close}
            onClick={() => {
              props?.onCloseModal(null);
            }}
            data-testid="modal-okay-button"
          >
            {content.get('REUSABLE_BUTTON_CLOSE')}
          </Button>
        </Box>
      </Grid>
    </Modal>
  );
};
