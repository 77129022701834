import React, { Dispatch, useState } from 'react';
import { Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, useMediaQuery } from '@material-ui/core';

import Search from '../../search';
import Dropdown from '../../dropdown';
import Pagination from '../../pagination';
import { PageSize } from '../../pagination/types';
import { useTranslation } from '../../../hooks/useTranslation';
import GroupListReducer, {
  GroupListState,
  GroupSort,
} from '../multi-accounts/groupListReducer';

type StyleProps = {
  showMini: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  formArea: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    marginTop: theme.spacing(2),
    display: 'flex',
    flex: ({ showMini }: StyleProps) => (showMini ? 1 : 0),
    flexDirection: ({ showMini }: StyleProps) => (showMini ? 'column' : 'row'),
    marginBottom: ({ showMini }: StyleProps) =>
      showMini ? theme.spacing(0) : undefined,
  },
  formControl: {
    minWidth: 200,
    '& .MuiOutlinedInput-input': {
      padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
    '& .MuiOutlinedInput-inputAdornEnd': {
      paddingRight: 0,
    },
    flex: ({ showMini }: StyleProps) => (showMini ? 1 : 0),
    width: ({ showMini }: StyleProps) => (showMini ? '100%' : undefined),
    marginBottom: ({ showMini }: StyleProps) =>
      showMini ? theme.spacing(3) : undefined,
    marginRight: ({ showMini }: StyleProps) =>
      showMini ? theme.spacing(0) : theme.spacing(2),
  },
}));

const pageSizes: PageSize[] = [5, 10, 15 as const];
type selection = {
  value: string;
  label: string;
};
type Props = {
  groupListParams: GroupListState;
  totalCount: number;
  actions: typeof GroupListReducer.actions;
  dispatch: Dispatch<any>;
  compact?: boolean;
  sortSelectionList?: selection[];
};

export default function GroupSortFilterPageHeader({
  groupListParams,
  totalCount = 0,
  dispatch,
  actions,
  compact = false,
  sortSelectionList,
}: Props) {
  const theme = useTheme();
  const smallDown = useMediaQuery(theme.breakpoints.down('sm'));
  const showMini = compact || smallDown;

  const classes = useStyles({ showMini });
  const { t } = useTranslation();
  const [dropDownValue, setUpdateValue] = useState(t('SORT_BY'));

  const selectionList = sortSelectionList || [
    { value: t('SORT_BY'), label: t('SORT_BY') },
    { value: GroupSort.AToZ, label: t('SORT_A_TO_Z') },
    { value: GroupSort.ZToA, label: t('SORT_Z_TO_A') },
    { value: GroupSort.Grouptype, label: t('GROUP_TYPE_SORT') },
    { value: GroupSort.Numberofaccounts, label: t('NUMBER_OF_ACCOUNTS_SORT') },
  ];
  return (
    <div className={classes.formArea}>
      <div className={classes.formControl}>
        <Search
          onSearch={(search: string) => {
            dispatch(actions.setQuery(search));
          }}
          value={groupListParams.query}
          label={t('SEARCH_GROUPS')}
        />
      </div>
      <div className={classes.formControl}>
        <Dropdown
          value={
            dropDownValue !== t('SORT_BY')
              ? groupListParams.sortBy
              : t('SORT_BY')
          }
          selectionList={selectionList}
          onChange={(e: any) => {
            let value = e.target.value;
            if (e.target.value === t('SORT_BY')) {
              value = GroupSort.AToZ;
              setUpdateValue(t('SORT_BY'));
            } else {
              setUpdateValue(e?.target?.value);
            }

            dispatch(actions.setSortBy(value as GroupSort));
          }}
        />
      </div>
      {totalCount > pageSizes[0] && (
        <Box className={classes.formControl} flexGrow={1}>
          <Pagination
            showPageString={true}
            page={groupListParams.page}
            pageSize={groupListParams.pageSize}
            count={totalCount}
            pageSizes={pageSizes}
            onPageChange={(newPage: number, newPageSize: PageSize) => {
              if (newPage !== groupListParams.page) {
                dispatch(actions.setPage(newPage));
              }
              if (newPageSize !== groupListParams.pageSize) {
                dispatch(actions.setPageSize(newPageSize));
              }
            }}
          />
        </Box>
      )}
    </div>
  );
}
