/** @jsx jsx */
import { jsx } from '@emotion/core';

import React from 'react';
import { RouteComponentProps } from '@reach/router';
import RebateResults from './RebateResults';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import routes from '../../../../../routes';
import { useRebateOnlyFormState } from '../../../../../providers/RebateOnlyFormStateProvider';

const EVPulseRebateResults: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_REBATE_RESULTS,
  );
  const {
    formState: { rebateConfirm },
  } = useRebateOnlyFormState();

  return (
    <RebateResults
      title={content.get('CHARGER_INSTALLTION_TITLE')}
      eligibilityText={content.get('EVPULSE_REBATE_ELIGIBILITY_TEXT') || ''}
      rebateData={{
        totalRebates: rebateConfirm.evPulseRebateAmount,
      }}
      backRoute={routes.PGE_PLUS_REBATE_ONLY_CONNECT_TESLA}
    />
  );
};

export default EVPulseRebateResults;
