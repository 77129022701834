import React from 'react';
import { Link } from '@reach/router';
import { makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Button from '../buttons';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: 15,
    width: '100%',
  },
}));

const ButtonLink = ({ text, className, ...props }: any) => {
  const classes = useStyles();
  return (
    <Button
      color="primary"
      component={Link}
      {...props}
      className={clsx(classes.button, className)}
    >
      {text}
    </Button>
  );
};

export default ButtonLink;
