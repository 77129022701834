import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useReducer, useState } from 'react';
import { PageSize } from '../../components/pagination/types';
import PaymentHistoryPage from '../../components/payment-history/PaymentHistoryPage';
import PaymentHistoryReducer from '../../components/payment-history/paymentHistoryReducer';
import useDownloadBill from '../../hooks/useDownloadBill';
import { useTranslation } from '../../hooks/useTranslation';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import colors from '../../themes/main-colors';
import { toCurrencyDisplayFormat, toDateString } from '../../util/format';
import {
  ViewPaymentHistoryDetail,
  ViewPaymentHistoryBillingAndPaymentType,
  AccountParams,
  AccountDetail,
} from '../../__generated__/pge-types';
import ROUTES from '../../routes';
import TextLink from '../../components/text-link';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import useClosedAccountsPaymentHistory from '../../hooks/useClosedAccountsPaymentHistory';
import { useLocation } from '@reach/router';
import SelectedClosedAccountsCard from '../../components/closed-accounts/SelectedClosedAccountsCard';
import { isNil } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainArea: {
      minWidth: '20em',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    paperBox: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(1),
      [theme.breakpoints.up('xs')]: {
        padding: theme.spacing(2),
      },
    },
    accountSummary: {
      textAlign: 'center',
      padding: theme.spacing(3),
    },
  }),
);

type Props = {
  path?: string;
};

type LocationState = {
  currentAccount: AccountDetail;
};

export default (_: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [state, dispatch] = useReducer(
    PaymentHistoryReducer.reducer,
    PaymentHistoryReducer.initialState,
  );
  const [
    currentBill,
    setCurrentBill,
  ] = useState<ViewPaymentHistoryDetail | null>(null);

  const location = useLocation();
  const { currentAccount } = location.state as LocationState;

  const accountParams: AccountParams | undefined = {
    accountNumber: currentAccount.accountNumber,
    encryptedPersonId: currentAccount.encryptedPersonId,
  };

  const {
    historyLoading,
    historyData,
    totalCount,
    selectedAccount,
  } = useClosedAccountsPaymentHistory({
    closedAccountsHistoryParams: {
      page: state.page,
      pageSize: state.pageSize,
      accountParams, //For Testing Purpose
    },
  });

  const downloader = useDownloadBill();
  const { wrapWithLoader } = useWrapWithLoader();

  const onDownload = wrapWithLoader(
    async (
      encryptedBillId: string | null | undefined,
      startDate: string | null | undefined,
      endDate: string | null | undefined,
    ) => {
      if (encryptedBillId) {
        const billId = encryptedBillId;
        const start = startDate;
        const end = endDate;

        const linkSource = await downloader.downloadBill(billId);
        const downloadLink = document.createElement('a');

        downloadLink.href = linkSource;
        downloadLink.download = `${t('VIEW_BILL').replace(
          ' ',
          '_',
        )}-${toDateString(start)}_${toDateString(end)}.pdf`;

        downloadLink.click();
      }
    },
    () => t('NO_BILL_FOUND'),
  );

  useEffect(() => {
    if (
      selectedAccount?.encryptedAccountNumber !==
      state.currentAccount?.encryptedAccountNumber
    ) {
      dispatch(
        PaymentHistoryReducer.actions.setCurrentAccount(selectedAccount!),
      );
      setCurrentBill(null);
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (historyData && !currentBill) {
      const firstBill = historyData.find(
        (item: any) =>
          item.type === ViewPaymentHistoryBillingAndPaymentType.Bill,
      );
      if (firstBill) {
        setCurrentBill(firstBill);
      }
    }
  }, [historyData]);

  if (accountParams === undefined) {
    return null;
  }

  const selectedAccountIsCurrentAccount =
    (selectedAccount &&
      state.currentAccount &&
      selectedAccount?.encryptedAccountNumber ===
        state.currentAccount?.encryptedAccountNumber) ??
    false;

  const downloadBill = (
    encryptedBillId: string | null | undefined,
    startDate: string | null | undefined,
    endDate: string | null | undefined,
  ): Promise<any> => onDownload(encryptedBillId, startDate, endDate);
  return (
    <>
      <div className={classes.mainArea}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextLink
              to={ROUTES.CLOSED_ACCOUNTS}
              plain
              style={{
                color: colors.sparkBlue,
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <NavigateBeforeIcon fontSize="small" />
              {t('BACK_TO_CLOSED_ACCOUNT_LIST')}
            </TextLink>
          </Grid>
          <Grid item>
            <Typography
              className={classes.colorTextPrimary}
              component={'span'}
              variant={'h1'}
            >
              {t('BILLING_PAYMENT_HISTORY_SIDE_MENU')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {selectedAccount && (
              <SelectedClosedAccountsCard selectedAccount={selectedAccount} />
            )}
          </Grid>
          <Grid item xs={12}>
            {selectedAccountIsCurrentAccount && (
              <Card className={classes.accountSummary}>
                <Typography variant={'h4'}>
                  {`${t('ACCOUNT_BALANCE')} `}
                  <strong>
                    {`$${
                      isNil(selectedAccount?.billInfo?.amountDue)
                        ? t('NOT_AVAILABLE')
                        : toCurrencyDisplayFormat(
                            selectedAccount!.billInfo!.amountDue,
                            true,
                            'CR',
                          )
                    }`}
                  </strong>{' '}
                </Typography>
              </Card>
            )}
          </Grid>
          <Grid item xs={12}>
            <PaymentHistoryPage
              currentBill={currentBill}
              historyLoading={
                historyLoading || !selectedAccountIsCurrentAccount
              }
              history={historyData}
              page={state.page}
              pageSize={state.pageSize}
              totalCount={!selectedAccountIsCurrentAccount ? 0 : totalCount}
              onPageChange={(newPage: number, newPageSize: PageSize) => {
                if (state.page !== newPage) {
                  dispatch(PaymentHistoryReducer.actions.setPage(newPage));
                }

                if (state.pageSize !== newPageSize) {
                  dispatch(
                    PaymentHistoryReducer.actions.setPageSize(newPageSize),
                  );
                }
              }}
              isClosedAccount
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};
