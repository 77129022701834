import React, { FunctionComponent, useEffect, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import {
  makeStyles,
  Theme,
  Paper,
  Box,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import colors from '../../../themes/main-colors';
import TextField from '../../text-field';
import { useTranslation } from '../../../hooks/useTranslation';
import Backdrop from '../../backdrop';
import TextLink from '../../text-link';
import Button from '../../buttons';
import PhoneTextField from '../../phone-text-field';
import { parsePhoneNumber } from '../../../util/format';
import { UpdateInfoProps } from '../../../hooks/useUpdateInfoForm';
import {
  AccountRelationship,
  OnlineAccountType,
} from '../../../__generated__/pge-types';
import ROUTES from '../../../routes';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

const useStyles = makeStyles((theme: Theme) => ({
  contactPhones: {
    '& > div ': {
      display: 'flex',
      flexDirection: 'row',
      gap: '.625rem',
      margin: '0 0 1.25rem 0',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
  },
  phoneField: {
    margin: 0,
    width: '40% !important',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },
  checkBoxLabel: {
    fontSize: '0.875rem',
  },
  backLink: {
    '& a': {
      display: 'flex',
      alignItems: 'center',
      marginTop: 20,
      marginBottom: 10,
    },
    marginBottom: 30,
  },
  colorTextPrimary: {
    color: colors.noirBlur,
  },
  Card: {
    padding: 30,
    color: colors.noirBlur,
    marginBottom: 10,
  },
  textStyle: {
    marginBottom: 20,
  },
  widthStyle: {
    width: '100%',
  },
  smallText: {
    marginLeft: '1em',
    fontSize: '0.875rem',
  },
  grayText: {
    color: colors.darkGray2,
  },
  redText: {
    color: colors.roseRed,
  },
}));

interface UpdatePersonalInfoFormProps extends UpdateInfoProps {
  path?: string;
}

const UpdatePersonalInfoForm: FunctionComponent<UpdatePersonalInfoFormProps> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    loading,
    accountType,
    signedInUserRelationshipType,
    updateInfoFormProps,
    initialFormState,
    handleQasSubmit,
    setIsMobilePhoneSameAsPrimaryPhone,
    isMobilePhoneSameAsPrimaryPhone,
    mobNumber,
  } = props;
  const { customer } = useAccountCustomer();
  const handleUsePrimaryForMobile = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const usePnpForMobile = e.target.checked;
    setIsMobilePhoneSameAsPrimaryPhone(usePnpForMobile);
    if (usePnpForMobile) {
      updateInfoFormProps.mobilePhone.value = '';
    } else {
      updateInfoFormProps.mobilePhone.value = mobNumber || '';
    }
  };

  const isFormUpdated =
    JSON.stringify(initialFormState) !== JSON.stringify(updateInfoFormProps);

  return (
    <>
      {loading && <Backdrop forceOpen />}
      {/* remove back to commercial information update link if user is not maincustomer or co-customer */}
      {signedInUserRelationshipType &&
        (signedInUserRelationshipType === AccountRelationship.Coapp ||
          signedInUserRelationshipType === AccountRelationship.Main) &&
        accountType === OnlineAccountType.PgeCommercialAcct && (
          <Typography
            className={`${classes.backLink} ${classes.colorTextPrimary} ${classes.textStyle}`}
            variant={'h4'}
            data-testid="back-link"
          >
            <TextLink to={ROUTES.UPDATE_INFORMATION} plain>
              <ChevronLeft />
              {t('BACK_TO_COMMERCIAL_INFORMATION_UPDATE')}
            </TextLink>
          </Typography>
        )}

      {!loading && (
        <>
          <Grid item>
            <Typography variant={'h1'}>
              {t('UPDATE_PERSONAL_CONTACT_INFORMATION')}
            </Typography>
          </Grid>
          <CardContent>
            <Paper className={classes.Card}>
              <Typography variant="h2" className={classes.textStyle}>
                {t('CUSTOMER_NAME')}
              </Typography>

              <Typography variant="body2" className={classes.textStyle}>
                {t('TO_ADD_A_CO_CUSTOMER')}
                <TextLink plain external={true} to={`tel:${t('PGE_PHONE')}`}>
                  {t('PGE_PHONE')}.
                </TextLink>
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Select
                    value={updateInfoFormProps.reasonForChange.value}
                    id="reasonForChangeUpdateInfo"
                    name="reasonForChange"
                    onChange={updateInfoFormProps.handleChange}
                    error={!!updateInfoFormProps.reasonForChange.errorMessage}
                    onBlur={updateInfoFormProps.onBlur}
                    variant="outlined"
                    className={classes.widthStyle}
                    displayEmpty
                  >
                    <MenuItem value={''}>
                      <span className={classes.grayText}>
                        {t('REASON_FOR_CHANGE')}
                      </span>
                    </MenuItem>
                    {props.ChangeNameReasons.map((v, i) => (
                      <MenuItem key={i} value={v.value}>
                        {t(v.label)}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText className={classes.redText}>
                    {updateInfoFormProps.reasonForChange.errorMessage}
                  </FormHelperText>
                </Grid>
                {accountType === OnlineAccountType.PgeResidentialAcct && (
                  <Grid style={{ width: '100%', padding: '0px 12px 4px 12px' }}>
                    {updateInfoFormProps.reasonForChange.value && (
                      <Box>
                        <Alert severity="info">
                          {
                            props.ChangeNameAllowedFields[
                              updateInfoFormProps.reasonForChange
                                ?.value as string
                            ]
                          }
                        </Alert>
                      </Box>
                    )}
                  </Grid>
                )}
                {accountType === OnlineAccountType.PgeResidentialAcct && (
                  <>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name={'signedInUserFirstName'}
                        id="nameUpdateInfo"
                        label={t('FIRST_NAME')}
                        className={classes.widthStyle}
                        inputProps={{ maxLength: 90 }}
                        onChange={updateInfoFormProps.handleChange}
                        error={
                          !!updateInfoFormProps.signedInUserFirstName
                            .errorMessage
                        }
                        helperText={
                          updateInfoFormProps.signedInUserFirstName.errorMessage
                        }
                        value={
                          updateInfoFormProps.signedInUserFirstName.value || ''
                        }
                        onBlur={updateInfoFormProps.onBlur}
                        disabled={
                          !updateInfoFormProps.reasonForChange?.value ||
                          updateInfoFormProps.reasonForChange?.value ===
                            t('MARRIAGE') ||
                          updateInfoFormProps.reasonForChange?.value ===
                            t('DIVORCE')
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name={'signedInUserMiddleName'}
                        id="nameUpdateInfo"
                        label={t('MIDDLE_NAME')}
                        className={classes.widthStyle}
                        inputProps={{ maxLength: 90 }}
                        onChange={updateInfoFormProps.handleChange}
                        error={
                          !!updateInfoFormProps.signedInUserMiddleName
                            .errorMessage
                        }
                        helperText={
                          updateInfoFormProps.signedInUserMiddleName
                            .errorMessage
                        }
                        value={
                          updateInfoFormProps.signedInUserMiddleName.value || ''
                        }
                        onBlur={updateInfoFormProps.onBlur}
                        disabled={
                          !updateInfoFormProps.reasonForChange?.value ||
                          updateInfoFormProps.reasonForChange?.value ===
                            t('MARRIAGE') ||
                          updateInfoFormProps.reasonForChange?.value ===
                            t('DIVORCE')
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <TextField
                        name={'signedInUserLastName'}
                        id="nameUpdateInfo"
                        label={t('LAST_NAME')}
                        className={classes.widthStyle}
                        inputProps={{ maxLength: 90 }}
                        onChange={updateInfoFormProps.handleChange}
                        error={
                          !!updateInfoFormProps.signedInUserLastName
                            .errorMessage
                        }
                        helperText={
                          updateInfoFormProps.signedInUserLastName.errorMessage
                        }
                        value={
                          updateInfoFormProps.signedInUserLastName.value || ''
                        }
                        onBlur={updateInfoFormProps.onBlur}
                        disabled={!updateInfoFormProps.reasonForChange?.value}
                      />
                    </Grid>
                  </>
                )}
                {accountType === OnlineAccountType.PgeCommercialAcct && (
                  <Grid
                    container
                    spacing={3}
                    style={{ padding: '5px 12px 4px' }}
                  >
                    <Grid item xs={12} md={12}>
                      <TextField
                        name={'name'}
                        id="nameUpdateInfo"
                        label={t('NAME')}
                        className={classes.widthStyle}
                        inputProps={{ maxLength: 90 }}
                        onChange={updateInfoFormProps.handleChange}
                        error={!!updateInfoFormProps.name.errorMessage}
                        helperText={updateInfoFormProps.name.errorMessage}
                        value={updateInfoFormProps.name.value || ''}
                        onBlur={updateInfoFormProps.onBlur}
                        disabled={!updateInfoFormProps.reasonForChange?.value}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Paper>

            <Paper className={classes.Card}>
              <Typography variant="h2" className={classes.textStyle}>
                {t('PHONE')}
              </Typography>
              <Typography variant="subtitle1" className={classes.textStyle}>
                {t('LETS_KEEP_IN_TOUCH')}
              </Typography>
              <Box className={classes.contactPhones}>
                <Box>
                  <PhoneTextField
                    className={classes.phoneField}
                    name={'primaryPhone'}
                    id="primaryContactUpdateInfo"
                    data-testid="primary-contact-update-info"
                    label={t('PRIMARY_CONTACT')}
                    autoComplete={'telephone'}
                    onChange={updateInfoFormProps?.handleChange}
                    onBlur={(event: any) => {
                      event.target.value = parsePhoneNumber(event.target.value);
                      if (updateInfoFormProps && updateInfoFormProps.onBlur) {
                        updateInfoFormProps.onBlur(event);
                      }
                    }}
                    error={!!updateInfoFormProps.primaryPhone.errorMessage}
                    value={updateInfoFormProps.primaryPhone.value}
                    helperText={updateInfoFormProps.primaryPhone.errorMessage}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        name={'isMobileSameAsPrimary'}
                        value={isMobilePhoneSameAsPrimaryPhone}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleUsePrimaryForMobile(e);
                        }}
                        color={'primary'}
                        data-testid="check-box"
                      />
                    }
                    label={t('USE_FOR_MOBILE')}
                    classes={{
                      label: classes.checkBoxLabel,
                    }}
                  />
                </Box>
                {!isMobilePhoneSameAsPrimaryPhone && (
                  <Box>
                    <PhoneTextField
                      className={classes.phoneField}
                      name={'mobilePhone'}
                      id="mobilePhoneUpdateInfo"
                      label={t('MOBILE_PHONE')}
                      autoComplete={'telephone'}
                      onChange={updateInfoFormProps?.handleChange}
                      value={updateInfoFormProps.mobilePhone.value}
                      error={!!updateInfoFormProps.mobilePhone.errorMessage}
                      helperText={updateInfoFormProps.mobilePhone.errorMessage}
                    />
                  </Box>
                )}
                <Box>
                  <PhoneTextField
                    className={classes.phoneField}
                    name={'alternatePhone'}
                    id="alternatePhoneUpdateInfo"
                    label={t('ALTERNATE_PHONE')}
                    autoComplete={'telephone'}
                    margin={'none'}
                    onChange={updateInfoFormProps?.handleChange}
                    error={!!updateInfoFormProps.alternatePhone.errorMessage}
                    value={updateInfoFormProps.alternatePhone.value}
                    helperText={updateInfoFormProps.alternatePhone.errorMessage}
                  />
                </Box>
              </Box>
              <Typography variant="body2">
                {t('CONTACT_INFO_FOOTER')}
              </Typography>
            </Paper>

            <Paper className={classes.Card}>
              <Typography variant="h2" className={classes.textStyle}>
                {t('EMAIL')}
              </Typography>
              <Typography variant="subtitle1" className={classes.textStyle}>
                {t('CHANGE_EMAIL_COPY')}
              </Typography>
              <Typography variant="subtitle1">
                {t('PRIMARY')}: <strong>{customer?.email}</strong>
                <TextLink to={ROUTES.CHANGE_EMAIL} plain>
                  <span className={classes.smallText}>{t('EDIT')}</span>
                </TextLink>
              </Typography>
            </Paper>

            <Paper className={classes.Card}>
              <Typography variant="h2" className={classes.textStyle}>
                {t('PREFERRED_CONTACT_LANGUAGE')}
              </Typography>

              <Grid container spacing={3}>
                <Grid item xs={6} md={6}>
                  <Select
                    value={updateInfoFormProps.preferredLanguage.value}
                    id="preferredLanguage"
                    name="preferredLanguage"
                    onChange={updateInfoFormProps.handleChange}
                    error={!!updateInfoFormProps.errorMessage}
                    onBlur={updateInfoFormProps.onBlur}
                    variant="outlined"
                    style={{ width: '100%' }}
                  >
                    {props.preferredLanguages.map(v => (
                      <MenuItem key={v} value={v}>
                        {t(v)}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Paper>

            <Grid container>
              <Grid item xs={12} lg={12}>
                <Grid container justify="flex-end">
                  <Grid item>
                    <Button
                      data-testid="save-button"
                      onClick={() => {
                        handleQasSubmit(false);
                      }}
                      disabled={!isFormUpdated}
                      size={'large'}
                    >
                      {t('SAVE')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </>
      )}
    </>
  );
};

export default UpdatePersonalInfoForm;
