import { createGlobalState } from 'react-hooks-global-state';

import account, { AccountState } from './account';
import billing from './billing';
import core from './core';
import outages from './outages';
import service from './service';

export type Entity = {
  // temp default until all entities are in global state
  [key: string]: any;
};
export type InitialState = {
  account: AccountState;
  billing: Entity;
  core: Entity;
  outages: Entity;
  service: Entity;
};

export const initialState: InitialState = {
  account,
  billing,
  core,
  service,
  outages,
};

export const { useGlobalState } = createGlobalState(initialState);

export default useGlobalState;
