/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from '@reach/router';
import { Grid, Typography, useTheme } from '@material-ui/core';
import Buttons from '../../../../buttons';
import routes from '../../../../../routes';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import { useRebateOnlyFormState } from '../../../../../providers/RebateOnlyFormStateProvider';
import { getFormattedCurrencyString } from '../../../../../util/format';

const Success: React.FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { content, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_SUCCESS,
  );
  const theme = useTheme();
  const {
    formState: { rebateConfirm },
  } = useRebateOnlyFormState();
  const [rebateCheckAmount, setRebateCheckAmount] = useState(0);

  useEffect(() => {
    const installationCost =
      rebateConfirm?.chargerInstallInfo?.installationCost || 0;
    const chargerCost = rebateConfirm?.chargerInstallInfo?.chargerCost || 0;
    const maxRebateAmount = rebateConfirm?.evPulseRebateAmount || 0;
    if (rebateConfirm?.appliedRebate?.__typename === 'RebateSmartCharging') {
      setRebateCheckAmount(
        Math.min(maxRebateAmount, installationCost + chargerCost),
      );
    } else {
      setRebateCheckAmount(maxRebateAmount);
    }
  }, []);

  return (
    <Grid container>
      <Grid item>
        <Typography
          variant={'body1'}
          css={{
            fontSize: '20px !important',
            lineHeight: '26px !important',
            '& p': {
              margin: 0,
            },
          }}
        >
          {richText(content.get('REBATE_ONLY_CONFIRMATION_TEXT'), {
            rebateAmount: `$${getFormattedCurrencyString(rebateCheckAmount)}`,
          })}
        </Typography>
      </Grid>
      <Grid item container css={{ justifyContent: 'center', marginTop: 50 }}>
        <Grid item xs={5}>
          <Link to={routes.ACCOUNT}>
            <Buttons
              type="submit"
              fullWidth
              variant="contained"
              css={{
                fontSize: '18px',
                borderRadius: '30px',
              }}
            >
              {content.get('REUSABLE_BUTTON_CLOSE')}
            </Buttons>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Success;
