/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import React from 'react';
import { Typography, Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { CheckCircle } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';

interface WelcomeBackCollapsedStepProps {
  title?: string;
  isComplete: boolean;
  step: number;
  description?: string;
}

export default function WelcomeBackCollapsedStep({
  title,
  isComplete,
  step,
  description,
}: WelcomeBackCollapsedStepProps) {
  const theme = useTheme();
  return (
    <Card
      raised={false}
      component="div"
      css={{
        padding: '20px',
        backgroundColor: isComplete ? colors.white : colors.megaMenuBackground,
        borderRadius: 0,
        boxShadow: 'none',
      }}
    >
      <CardContent
        css={{
          display: 'flex',
          padding: 0,
          paddingBottom: '0 !important',
        }}
      >
        {isComplete ? (
          <CheckCircle
            css={{
              width: '28px',
              height: '28px',
            }}
            htmlColor={colors.shoreGreen}
          />
        ) : (
          <Box
            css={{
              borderRadius: '100%',
              backgroundColor: colors.lightGray1,
              minWidth: '28px',
              height: '28px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              align="center"
              css={{
                color: colors.noirBlur,
                fontSize: '20px',
                fontWeight: 'bold',
                fontFamily: 'Forma-DJR-Display',
              }}
            >
              {step}
            </Typography>
          </Box>
        )}
        <Box css={{ marginLeft: '20px', marginTop: '4px' }}>
          <Typography
            variant="h4"
            css={{
              fontSize: '20px',
              color: isComplete ? colors.noirBlur : colors.lightGray4,
              fontFamily: 'Forma-DJR-Display',
              fontWeight: 'bold',
              lineHeight: '20px',
              [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                fontSize: '1rem',
              },
            }}
          >
            {title}
          </Typography>
          {description && (
            <Typography
              variant="h4"
              css={{
                fontSize: '16px',
                fontFamily: 'Untitled-Sans',
                fontWeight: 'normal',
                color: colors.noirBlur,
                marginTop: '12px',
              }}
            >
              {description}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
