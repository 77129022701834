import {
  IFrameInfoRequest,
  PaymentCategory,
  PaymentProfile,
} from '../../__generated__/pge-types';

export type ProfileChangeHandler = (profile: PaymentProfile) => void;
export type ProfileInfoChangeHandler = (
  profileInfo: PaymentProfileInfo,
) => void;

export const NO_PROFILE = { token: 'NO_TOKEN' };
export const ADD_PROFILE = {
  token: '',
  type: 'ADD_PROFILE',
  accountNumber: '',
};

export const PAY_BY_CHECK = {
  token: 'PAY_BY_CHECK',
  type: 'PAY_BY_CHECK',
  accountNumber: '',
};

export type PaymentProfileInfo = {
  profile: PaymentProfile;
  pmCategory?: PaymentCategory;
  isAnonymous?: boolean;
};

export type EditProps = {
  iframeProps: IFrameInfoRequest;
  onChange?: ProfileChangeHandler;
  onClose?: () => void;
  onNew?: ProfileChangeHandler;
};
export type CustomPaymentProfile = {
  profile: PaymentProfile;
  children: React.ReactNode;
};

export type MultiPaymentProfileChangeHandler = (
  profiles: selectedProfileInfo[],
) => void;
export type MultiPaymentProfileInfoChangeHandler = (
  profiles: PaymentProfileMultiSelectWrapper,
) => void;
export type MultiPaymentProfileInfoHandler = (
  profiles: PaymentProfileMultiSelectWrapper[],
) => void;
export type selectedProfileChangeHandler = (
  name: string,
  checked: boolean,
) => void;
export type amountChangeHandler = (name: string, amount: string) => void;
export type PaymentProfileInfoChangeHandler = (
  profiles: PaymentProfileMultiSelectWrapper,
  checked: boolean,
) => void;
export type selectedProfileInfo = {
  profile: PaymentProfile;
  value: string;
  checked?: boolean;
  error: boolean;
  errorText: string;
  isPrimary?: boolean;
};
export type PaymentProfileMultiSelectWrapper = {
  profile: PaymentProfile;
  isEditButton?: boolean;
  isDeleteButton?: boolean;
  paymentAmount?: string;
  isDisabled?: boolean;
  isChecked?: boolean;
  isError?: boolean;
  errorText?: string;
};
export type SplitPaymentInfo = {
  value?: string;
  error?: boolean;
  errorText?: string;
};
