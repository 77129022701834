import { createStyles, makeStyles, Theme } from '@material-ui/core';

import colors from '../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    margin2: {
      marginBottom: '2em',
      marginTop: '2em',
    },
    confirmationNumber: {
      color: colors.noirBlur,
      [theme.breakpoints.down('xs')]: {},
      [theme.breakpoints.up('sm')]: {
        marginRight: 3,
      },
      [theme.breakpoints.up('md')]: {
        marginRight: 3,
      },
      [theme.breakpoints.up('lg')]: {
        marginRight: 3,
      },
    },
    billRow: {
      maxHeight: 40,
      margin: '10px 0 7px',
    },

    submit: {
      display: 'flex',
      marginTop: '10px',
      margin: theme.spacing(3, 0, 2),
      [theme.breakpoints.down('md')]: {
        width: '100%',
        margin: 'auto',
        justifyContent: 'center',
      },
    },
    cancelLink: {
      marginTop: '1em',
      textAlign: 'center',
      marginLeft: '1em',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
      '& a': {
        '&:active': {
          borderBottom: 'none',
        },
      },
    },
    backButton: {
      color: '#fff',
    },
    buttonWrapper: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    buttonContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column-reverse',
        width: '80%',
      },
    },
    cancelVerificationLink: {
      paddingLeft: '1em',
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        paddingLeft: '0em',
      },
    },
    actionArea: {
      paddingTop: 0,
      '& Button': {
        width: '100%',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
          width: '60%',
          justifyContent: 'center',
          margin: theme.spacing(4),
          marginBottom: theme.spacing(1.5),
          flexDirection: 'column-reverse',
          marginLeft: '5em',
        },
      },
      '& a': {
        margin: theme.spacing(1.8),
      },
    },
    closeIcon: {
      top: '386px',
      left: '1990px',
      width: '14px',
      height: '14px',
      background: '#84898F 0% 0% no-repeat padding-box;',
      opacity: 1,
    },
    greyedOut: {
      color: colors.lightGray1,
    },
    paymentMethod: {
      width: '50%',
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    paymentMethodInput: {
      width: '45%',
      padding: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingTop: 0,
        marginLeft: '1.8em',
      },
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    editDeleteContainer: {
      paddingLeft: '2em',
    },
    amountDueLabel: {
      color: colors.noirBlur,
      backgroundColor: '#f7f7f7',
      padding: '0.5em',
    },
    accountTypeLabel: {
      '& span:last-child': {
        fontSize: '1rem',
      },
    },

    paymentWallet: {
      '& .MuiTextField-root': {
        marginLeft: 0,
      },
    },
    oneTimeContainer: {
      marginTop: '1em',
      display: 'flex',
      flexDirection: 'row',
    },

    toolTipContent: {
      display: 'flex',
      textAlign: 'left',
      fontSize: '14px',
      letterSpacing: '0px',
      color: '#1C304A',
      opacity: 1,
    },
    currencyTextField: {
      width: '100%',
      marginLeft: 0,
    },
    radioOptionAmountText: {
      fontWeight: 'bold',
    },
    //need to remove
    formRadioCls: {
      '& span:last-child': {
        minWidth: '100%',
      },
    },
  }),
);

export default useStyles;
