import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { navigate } from '@reach/router';
import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import {
  AccountDetail,
  PaymentProfile,
} from '../../../__generated__/pge-types';
import PGEButton from '../../buttons';
import { AutoPayConfigData } from '../auto-pay.types';
import AutoPayEnrolled from './AutoPayEnrolled';
import { useStyles } from './AutoPayStart.styles';
import Ineligible from './Ineligible';

interface AutoPayProps {
  path?: string;
  onUpdatePaymentProfile: (config: AutoPayConfigData) => Promise<void>;
  savedProfileList?: PaymentProfile[];
  onUpdatePayDay: (config: AutoPayConfigData) => Promise<void>;
  onUpdateAutoPayConfig: (config: AutoPayConfigData) => Promise<void>;
  onCancel: () => Promise<void>;
  account: AccountDetail;
}

export default function AutoPay({
  onUpdatePaymentProfile,
  savedProfileList,
  onUpdatePayDay,
  onUpdateAutoPayConfig,
  onCancel,
  account,
}: AutoPayProps) {
  const { richT, t } = useTranslation();
  const isEnrolled = account?.autoPay?.isEnrolled;

  // if the user is enrolled, do not worry about inelibility
  if (isEnrolled) {
    return (
      <AutoPayEnrolled
        onUpdatePaymentProfile={onUpdatePaymentProfile}
        savedProfileList={savedProfileList}
        onUpdatePayDay={onUpdatePayDay}
        onUpdateAutoPayConfig={onUpdateAutoPayConfig}
        onCancel={onCancel}
        account={account}
      />
    );
  }

  return <AutoPayNotEnrolled />;
}

const AutoPayNotEnrolled = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleEnrollClick = () => {
    return navigate(ROUTES.AUTO_PAY_CONFIG);
  };

  const infoNotEnrolled = t('AUTO_PAY_NOT_ENROLLED_INFO');
  const enrollButtonLabel = t('AUTO_PAYNOT_ENROLLED_ENROLL');

  return (
    <Grid container spacing={3} direction="column">
      <Grid item>
        <Card className={classes.root}>
          <CardContent>
            <Typography>{infoNotEnrolled}</Typography>
          </CardContent>
          <CardActions className={classes.actionArea}>
            <PGEButton onClick={handleEnrollClick}>
              {enrollButtonLabel}
            </PGEButton>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};
