import { useTranslation } from '../../../hooks/useTranslation';
import { addDays, isWithinInterval, set } from 'date-fns';

const { t } = useTranslation();

export const validateStoptDate = (date?: Date): string | null => {
  if (!date) {
    return t('SSM_STOP_DATE_ERROR');
  }

  const start = set(new Date(), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  if (!isWithinInterval(date, { start, end: addDays(start, 90) })) {
    return t('SSM_STOP_DATE_EXCEED');
  }
  return null;
};

export const validateState = (state: string) => {
  if (!state) {
    return t('SELECT_STATE');
  }
  return null;
};

export const validateInCareOf = (inCareOf: string) => {
  if (inCareOf !== undefined) {
    const invalidCharacters = new RegExp(/^.*?(?=[(^<>_={}[\];|\)]).*$/gi);
    if (inCareOf.match(invalidCharacters)) {
      return t('IN_CARE_OF_INVALID_CHARACTERS');
    }
  }
};

export const stayingBehindValidation = (stayingBehind: string) => {
  if (stayingBehind === undefined) {
    return t('SELECT_OPTION_ERROR_MESSAGE');
  }
};
