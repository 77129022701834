import { Router } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import AccountLayout from '../../components/account-layout';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import MyWaysToSave from '../../components/my-ways-to-save';

export const MyWaysToSaveMain = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('MY_WAYS_TO_SAVE')}</title>
      </Helmet>
      <AccountLayout>
        <Router basepath="/">
          <MyWaysToSave path={`${ROUTES.MY_WAYS_TO_SAVE}/*`} />
        </Router>
      </AccountLayout>
    </>
  );
};
export default MyWaysToSaveMain;
