import React, { FC } from 'react';
import { Typography, Box, Select, MenuItem } from '@material-ui/core';
import Button from '../buttons';
import AccountDropdown from '../account-dropdown';
import { useTranslation } from '../../hooks/useTranslation';
import PaperBox from '../utility/paper-box';
import { AccountCustomer, LinkedPerson } from '../../__generated__/pge-types';
import AddUsersModal from '../ssm/post-enrollment/CoCustomer/AddUsersModal';
import AddPerson from '../account-summary/manage-alerts/AddPerson';
import useStyles from './styles';

interface Props {
  path?: string;
  customer: AccountCustomer;
  persons: LinkedPerson[];
  selectedPerson: LinkedPerson;
  mainPersonEmail: string;
  handleChangePerson: (person: any) => void;
  handleSubmit: () => Promise<void>;
  showAddPersonModal: boolean;
  setShowAddPersonModal: React.Dispatch<React.SetStateAction<boolean>>;
  refetchPersons: (variables?: any | undefined) => Promise<any>;
  setRecentlyAddedPerson: React.Dispatch<
    React.SetStateAction<LinkedPerson | undefined>
  >;
}

const MedicalCertificate: FC<Props> = ({
  customer,
  persons,
  selectedPerson,
  handleChangePerson,
  mainPersonEmail,
  handleSubmit,
  showAddPersonModal,
  setShowAddPersonModal,
  refetchPersons,
  setRecentlyAddedPerson,
}: Props) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Box mt={2} mb={1}>
        <AccountDropdown />
      </Box>
      <PaperBox className={classes.copyBlock}>
        <Typography style={{ marginBottom: '5px' }} variant="body1">
          {t('MEDICAL_CERTIFICATE_SELECT_USER')}
        </Typography>
        <Box>
          <Select
            labelId="person-select"
            id="person-select"
            value={selectedPerson}
            variant="outlined"
            onChange={e => handleChangePerson(e.target.value)}
            className={classes.select}
            placeholder={t('SELECT_PERSON')}
          >
            {persons &&
              persons.map((person: any) => (
                <MenuItem key={person.encryptedPersonId} value={person}>
                  {person.name}
                </MenuItem>
              ))}
          </Select>
        </Box>
        <Box className={classes.boxStyles}>
          <Button
            variant={'contained'}
            color={'primary'}
            className={classes.submitButton}
            onClick={handleSubmit}
          >
            {t('ENROLL')}
          </Button>
          <Button
            color={'primary'}
            variant={'contained'}
            className={`${classes.addPerson} ${classes.submitButton}`}
            onClick={() => setShowAddPersonModal(true)}
            data-testid="add-person"
          >
            {t('ADD_PERSON')}
          </Button>
        </Box>
      </PaperBox>
      <PaperBox className={classes.copyBlock} style={{ paddingTop: -5 }}>
        {richT('MEDICAL_CERTIFICATE_ENROLL_INTRO')}
        <Typography className={classes.textBold} variant="h4">
          {t('WHO_IS_ELIGIBLE')}
        </Typography>
        {richT('MEDICAL_CERTIFICATE_WHO_IS_ELIGIBLE')}
      </PaperBox>
      {showAddPersonModal && (
        <AddUsersModal
          onClose={() => setShowAddPersonModal(false)}
          open={showAddPersonModal}
          title={t('ADD_PERSON_MODEL_TITLE')}
          isDynamicClass={true}
        >
          <AddPerson
            setShowAddPersonModal={setShowAddPersonModal}
            refetchPerson={refetchPersons}
            setRecentlyAddedPerson={setRecentlyAddedPerson}
            mainPersonEmail={mainPersonEmail}
            loggedInPerson={customer.personId}
            loggedInPersonName={customer.personName}
            isMedicalCerificateForm={true}
          />
        </AddUsersModal>
      )}
    </>
  );
};

export default MedicalCertificate;
