/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, FormHelperText, Grid } from '@material-ui/core';
import { Fragment, useEffect } from 'react';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import { FormState } from '../../../hooks/useFormState.types';
import { FullDivider } from '../../pge-plus-common/FullDivider';
import { Question } from '../../pge-plus-common/Typography';
import { RebateEligibility } from '../evcharger.types';
import { GridItem } from '../../pge-plus-common/Grid';
import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import routes from '../../../routes';
import { SaveAndComeBackLink } from '../../pge-plus-common/SaveAndComeBackLink';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { CurrentRebatesView, ExistingChargerType } from '.';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';
import { IneligibilityReasonCode } from '../../../__generated__/pgeplus-types';
import RadioButtonGroup from '../../pge-plus-common/RadioButtonGroup';

type EVPulseOrExistingSmartChargerProps = {
  content: PageTextContentType;
  form: FormState<RebateEligibility>;
  path: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  handleSaveAndExit: () => void;
  setCurrentView: React.Dispatch<
    React.SetStateAction<CurrentRebatesView | undefined>
  >;
  type: ExistingChargerType;
  identifier: string;
  updateFormValues: (values: RebateEligibility) => boolean;
};

export const EVPulseOrExistingSmartCharger = (
  props: EVPulseOrExistingSmartChargerProps,
) => {
  const {
    content,
    form,
    setPageTitle,
    handleSaveAndExit,
    setCurrentView,
    type,
    identifier,
    updateFormValues,
  } = props;
  const { pathname: path, state } = useLocation();
  const pathname = path.replace(/\/$/, '');
  // @ts-ignore
  const backRoute = state?.pathname;
  const {
    formState: {
      selectCharger: { suppliedByCustomer },
    },
  } = useEVChargerFormState();

  useEffect(() => {
    setCurrentView(CurrentRebatesView.EXISTING_CHARGER);
    setPageTitle(content.get('SMART_CHARGE_PAGE_TITLE') || '');
  }, []);

  const textData =
    type === ExistingChargerType.EV_PULSE
      ? {
          info: content.get('ALREADY_HAVE_A_VEHICLE_EVPULSE'),
          previousIdentifierTitle: content.get('PREVIOUSLY_ENROLLED_VEHICLE'),
          previousIdentifier: `VIN ${identifier.slice(0, 3)}-${identifier.slice(
            3,
            6,
          )}-${identifier.slice(6, 10)}-${identifier.slice(10)}`,
          firstQuestion: content.get('IS_CHARGER_ALREADY_INSTALLED'),
          secondQuestion: content.get('CONTINUE_WITHOUT_REBATE'),
        }
      : {
          info: content.get('WE_SEE_YOU_RE_PARTICIPATING_IN_SMART_CHARGING'),
          previousIdentifierTitle: content.get('YOUR_ENROLLED_CHARGER'),
          previousIdentifier: identifier,
          firstQuestion: content.get('IS_CHARGER_ALREADY_INSTALLED'),
          secondQuestion: content.get('CONTINUE_WITHOUT_REBATE'),
        };

  return (
    <form
      onSubmit={form.submit(values => {
        updateFormValues(form.values);
        if (
          form.values.chargerForExistingVehicle === content.get('REUSABLE_YES')
        ) {
          return void navigate(
            routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_NEW_CHARGER,
            {
              state: { pathname },
            },
          );
        }
        if (
          form.values.chargerForExistingVehicle ===
            content.get('REUSABLE_NO') &&
          form.values.continueWithoutRebate === content.get('REUSABLE_NO')
        ) {
          return void navigate(routes.PGE_PLUS_EV_CHARGERS_INELIGIBLE, {
            state: { errorType: 'REBATES_INELIGIBLE' },
          });
        }
        if (
          form.values.chargerForExistingVehicle ===
            content.get('REUSABLE_NO') &&
          form.values.continueWithoutRebate === content.get('REUSABLE_YES')
        ) {
          return void navigate(
            suppliedByCustomer
              ? routes.PGE_PLUS_EV_CHARGERS_INSTALLATION
              : routes.PGE_PLUS_EV_CHARGERS_SELECT_CHARGER,
          );
        }
      })}
      noValidate
    >
      <Question title detail={textData.info} />
      <Question
        title={textData.previousIdentifierTitle}
        detail={textData.previousIdentifier}
      />
      <Question
        title={textData.firstQuestion}
        popoverLinkContent={content.get('REUSABLE_LEARN_MORE')}
        popoverContent={content.get('LEARN_MORE_CHARGER_REBATE_POPOVER')}
      />
      <Box display={'flex'} justifyContent={'center'} mt={3}>
        <Grid xs={12} md={6}>
          <RadioButtonGroup
            options={[
              {
                label: content.get('REUSABLE_YES') || '',
                value: content.get('REUSABLE_YES') || '',
              },
              {
                label: content.get('REUSABLE_NO') || '',
                value: content.get('REUSABLE_NO') || '',
              },
            ]}
            state={form.values.chargerForExistingVehicle || ''}
            setState={e => {
              void form.setValue('chargerForExistingVehicle', e);
              if (e === content.get('REUSABLE_YES')) {
                void form.setValue('continueWithoutRebate', undefined);
              }
            }}
          />
        </Grid>
      </Box>
      {form.errors.chargerForExistingVehicle && (
        <GridItem>
          <FormHelperText error>
            {form.errors.chargerForExistingVehicle}
          </FormHelperText>
        </GridItem>
      )}
      <FullDivider css={{ margin: 0 }} />
      {form.values.chargerForExistingVehicle === content.get('REUSABLE_NO') && (
        <Fragment>
          <Question title={textData.secondQuestion} />
          <Box display={'flex'} justifyContent={'center'} mt={3}>
            <Grid xs={12} md={6}>
              <RadioButtonGroup
                options={[
                  {
                    label: content.get('REUSABLE_YES') || '',
                    value: content.get('REUSABLE_YES') || '',
                  },
                  {
                    label: content.get('REUSABLE_NO') || '',
                    value: content.get('REUSABLE_NO') || '',
                  },
                ]}
                state={form.values.continueWithoutRebate || ''}
                setState={e => form.setValue('continueWithoutRebate', e)}
              />
            </Grid>
          </Box>
          {form.errors.continueWithoutRebate && (
            <GridItem>
              <FormHelperText error>
                {form.errors.continueWithoutRebate}
              </FormHelperText>
            </GridItem>
          )}
          <FullDivider css={{ margin: 0 }} />
        </Fragment>
      )}
      <GridItem>
        <ProceedOrCancelButton
          back={backRoute || routes.PGE_PLUS_EV_CHARGERS_CONTACT_INFO}
        />
      </GridItem>
      <GridItem>
        <SaveAndComeBackLink
          content={content}
          onSaveAndExit={handleSaveAndExit}
        />
      </GridItem>
    </form>
  );
};
