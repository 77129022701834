import React, { ReactNode } from 'react';
import { AlertProps } from '@material-ui/lab';
import { makeStyles, Theme, createStyles, Box } from '@material-ui/core';
import Alerts from '../../alerts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(2, 0),
        borderRadius: 0,
      },
    },
    action: {
      alignItems: 'start',
    },
    bodyContainer: {
      '& :first-child': {
        marginTop: 0,
      },
    },
  }),
);

const BroadcastMessageBanner = ({
  messageBody,
  severity = 'info',
  ...props
}: AlertProps & { messageBody?: ReactNode }) => {
  return (
    <Alerts
      isOpen
      severity={severity}
      message={<Box mt="-1.5em">{messageBody}</Box>}
    />
  );
};

export default BroadcastMessageBanner;
