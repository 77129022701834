import { AccountDetail } from '../../__generated__/pge-types';
import { PageSize } from '../../components/pagination/types';
import { makeActions, createReducer } from '../../util/reducer-utils';

export type PaymentHistoryState = {
  page: number;
  pageSize: PageSize;
  currentAccount: AccountDetail | null;
};

const initialState: PaymentHistoryState = {
  page: 1,
  pageSize: 15,
  currentAccount: null,
};

const actions = makeActions({
  setPage: (page: number) => ({ page }),
  setPageSize: (pageSize: PageSize) => ({ pageSize }),
  setCurrentAccount: (currentAccount: AccountDetail) => ({
    currentAccount,
    page: 1,
  }),
});

const reducer = createReducer<PaymentHistoryState, typeof actions>({
  setPage: (state, { payload: { page } }) => ({ ...state, page }),
  setPageSize: (state, { payload: { pageSize } }) => ({ ...state, pageSize }),
  setCurrentAccount: (state, { payload: { currentAccount } }) => ({
    ...state,
    currentAccount,
    page: 1,
  }),
});

export default {
  initialState,
  reducer,
  actions,
};
