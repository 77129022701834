import { useState, useEffect } from 'react';
import {
  MoveServiceDetailsFormModel,
  IMoveServiceCustomerFormModel,
  MoveServiceCustomerFormModel,
  ServiceOptionsFormModel,
  ServiceReviewFormModel,
  step1Validations,
  step2Validations,
  step2BValidations,
  step3Validations,
  createPayload,
} from './MoveServiceForm.rules';
import { navigate } from '@reach/router';
import ROUTES from '../../routes';
import {
  PersonalIdentificationType,
  StateIdType,
} from '../../__generated__/pge-types';
import { useTranslation } from '../../hooks/useTranslation';
import useFormState from '../../hooks/useFormState';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import useGetMainCustomerPersonDetails from '../../hooks/useGetMainCustomerPersonDetails';
import useGetPersonPrimaryIdentificationType from '../../hooks/useGetPersonPrimaryIdentificationType';
import useIsCustomerServiceDisconnected from '../../hooks/useIsCustomerServiceDisconnected';
import useGetPreferredDueDateInfo from '../../hooks/useGetPreferredDueDateInfo';
import useMoveServiceSubmit from '../../hooks/useMoveServiceSubmit';

interface RouteMap<TValue> {
  [id: string]: TValue;
}
const phoneNumberFormatting = /[()\s\-]/gi;

const routeMap: RouteMap<string> = {
  1: ROUTES.MOVE_SERVICE,
  2: ROUTES.MOVE_SERVICE_CUSTOMER_INFO,
  '2.2': ROUTES.MOVE_SERVICE_MAIN_CUSTOMER_INFO,
  '2.4': ROUTES.MOVE_SERVICE_CO_CUSTOMER_INFO,
  3: ROUTES.MOVE_SERVICE_OPTIONS,
  4: ROUTES.MOVE_SERVICE_REVIEW,
  5: ROUTES.MOVE_SERVICE_CONFIRM,
};

const step1DefaultFormValues: MoveServiceDetailsFormModel = {
  stopDate: '',
  livingRemainAtProperty: '',

  address: '',
  city: '',
  zip: '',
  startDate: '',

  ownOrRent: '',
  liveAtAddress: '',
};

const step2DefaultFormValues: MoveServiceCustomerFormModel = {
  idType: PersonalIdentificationType.Ssn,
  idSubType: '',
  idSubTypeState: '',
  idValue: '',
  preferredPhone: '',
  alternatePhone: '',
  altPhoneType: '',
  altPhoneExt: '',

  mailAddress: '',
  mailCity: '',
  mailState: '',
  mailZip: '',
  mailCountry: '',
  mailQasVerified: false,

  selectedCoCustomers: [],

  primaryIdType: '',
  birthdate: '',
};

const step2BDefaultFormValues: IMoveServiceCustomerFormModel = {
  idType: PersonalIdentificationType.Ssn,
  idSubType: '',
  idSubTypeState: '',
  idValue: '',
  preferredPhone: '',
  alternatePhone: '',
  altPhoneType: '',
  altPhoneExt: '',

  mailAddress: '',
  mailCity: '',
  mailState: '',
  mailZip: '',
  mailCountry: '',
  mailQasVerified: false,

  primaryIdType: '',

  useAltPhone: false,
  email: '',

  birthdate: '',
};

const step3DefaultFormValues: ServiceOptionsFormModel = {
  paperless: true,
  billDueOn: 0,
  greenFutureEnroll: false,
  peakTimeEnroll: false,
  peakTimePhone: '',
  peakTimeEmail: '',
  hasElectricVehicle: false,
  hasSmartThermostat: false,
  peakTimeContactError: '',
};

const step4DefaultValues: ServiceReviewFormModel = {
  startAgreed: false,
};

export default () => {
  const { t } = useTranslation();
  const moveServiceSubmit = useMoveServiceSubmit();
  const [useAltMailingAddress, setUseAltMailingAddress] = useState(false);
  const [customerUseAltPhone, setCustomerUseAltPhone] = useState(false);
  const [isPTREligible, setIsPTREligible] = useState<boolean>(true);
  const [isRenewablesEligible, setIsRenewablesEligible] = useState<boolean>(
    false,
  );
  const [
    isCurrentlyPaperlessBilling,
    setIsCurrentlyPaperlessBilling,
  ] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [finalPayload, setFinalPayload] = useState<any>({});
  const { customer } = useAccountCustomer();
  const [rehydrateTimestamp, setRehydrateTimestamp] = useState<
    number | undefined
  >();
  const {
    accountDetails,
    accountNumber,
    coCustomerData,
    customerInfoData,
    encryptedAccountNumber,
    encryptedPersonId,
    isAuthenticated,
    loading: getMainCustomerPersonDetailsLoading,
    personsForAccountData,
  } = useGetMainCustomerPersonDetails();

  const { primaryIdType } = useGetPersonPrimaryIdentificationType(
    encryptedPersonId,
  );

  const { preferredDueDate } = useGetPreferredDueDateInfo(
    encryptedAccountNumber,
  );

  const serviceDetailsForm = useFormState(
    {
      ...step1DefaultFormValues,
    },
    { validate: step1Validations },
  );

  const customerInfoForm = useFormState(
    { ...step2DefaultFormValues },
    {
      validate: step2Validations,
      validationContext: {
        primaryIdType,
        useAltMailingAddress,
      },
    },
  );

  // TODO: Refactor? Co-customer 1. Not related to CC&B 'main customer/person'
  const mainCustomerInfoForm = useFormState(
    { ...step2BDefaultFormValues },
    {
      validate: step2BValidations,
    },
  );

  // TODO: Refactor? Co-customer 2.
  const coCustomerInfoForm = useFormState(
    {
      ...step2BDefaultFormValues,
    },
    {
      validate: step2BValidations,
    },
  );

  const serviceOptionsForm = useFormState(
    {
      ...step3DefaultFormValues,
      peakTimeEmail: customer?.email || customerInfoData?.emailAddress || '',
      peakTimePhone:
        customerInfoForm.values.altPhoneType === t('MOBILE')
          ? customerInfoForm.values.alternatePhone
          : '',
    },
    {
      validate: step3Validations,
      validationContext: {
        isPTREligible,
      },
    },
  );

  const { isServiceDisconnected } = useIsCustomerServiceDisconnected(
    serviceDetailsForm.values.address,
    serviceDetailsForm.values.city,
    'OR',
    serviceDetailsForm.values.zip,
  );

  const serviceReviewForm = useFormState({
    ...step4DefaultValues,
  });

  const mapPersonIdToStateOrFederalId = (id: any) => {
    if ([PersonalIdentificationType.Dl].includes(id)) {
      return StateIdType.DriverLicense;
    }

    return id;
  };

  useEffect(() => {
    let serviceOptionsFormValues = {
      ...serviceOptionsForm.values,
    };

    serviceOptionsFormValues.paperless = isCurrentlyPaperlessBilling;

    if (customerInfoData && customer) {
      serviceOptionsFormValues = {
        ...serviceOptionsFormValues,
        peakTimeEmail: customer?.email || customerInfoData.emailAddress || '',
        peakTimePhone:
          customerInfoData?.alternatePhoneInfo?.alternatePhoneType ===
          t('MOBILE')
            ? customerInfoData?.alternatePhoneInfo?.alternatePhoneNumber || ''
            : '',
      };
    }
    serviceOptionsForm.reset(serviceOptionsFormValues);
  }, [
    customerInfoData,
    customer,
    isCurrentlyPaperlessBilling,
    rehydrateTimestamp,
  ]);

  useEffect(() => {
    const serviceOptionsFormValues = {
      ...serviceOptionsForm.values,
    };

    if (preferredDueDate) {
      serviceOptionsFormValues.billDueOn = preferredDueDate ?? 0;
      serviceOptionsForm.reset(serviceOptionsFormValues);
    }
  }, [preferredDueDate, rehydrateTimestamp]);

  useEffect(() => {
    let customerInfoFormValues = {
      ...customerInfoForm.values,
    };
    if (customerInfoData && customer) {
      customerInfoFormValues = {
        ...customerInfoFormValues,
        preferredPhone:
          customerInfoData?.primaryPhone?.replace(phoneNumberFormatting, '') ||
          '',
      };
    }
    if (
      primaryIdType === PersonalIdentificationType.Ssn ||
      primaryIdType === PersonalIdentificationType.Itin
    ) {
      customerInfoFormValues = {
        ...customerInfoFormValues,
        idType: primaryIdType,
      };
    } else if (primaryIdType !== PersonalIdentificationType.None) {
      customerInfoFormValues = {
        ...customerInfoFormValues,
        idType: [PersonalIdentificationType.Dl].includes(primaryIdType as any)
          ? 'stateId'
          : 'federalId',
        idSubType: mapPersonIdToStateOrFederalId(primaryIdType || ''),
      };
    }
    customerInfoForm.reset(customerInfoFormValues);
  }, [customerInfoData, customer, primaryIdType, rehydrateTimestamp]);

  const handleServiceDetailSubmit = (eligibilityData: any) => async (
    values: MoveServiceDetailsFormModel,
  ) => {
    setIsPTREligible(eligibilityData?.isPeakTimeRebateEligible);
    setFinalPayload((state: any) => ({
      ...state,
      ...values,
      encryptedPersonId,
      encryptedAccountNumber,
      isServiceDisconnected,
      isAddressExactMatch: eligibilityData?.isAddressExactMatch,
      premiseId: eligibilityData.premiseId,
      eligibleForMoveServicce: eligibilityData?.eligibleForMoveService,
    }));
    await goToStep(currentStep + 1);
  };

  const handleCustomerInfoSubmit = async (
    values: MoveServiceCustomerFormModel,
  ) => {
    setFinalPayload((state: any) => ({
      ...state,
      customer: {
        ...values,
        mailingAndServiceAddressesSame: !useAltMailingAddress,
        encryptedPersonId,
        hasExistingPrimaryIdentification:
          primaryIdType && primaryIdType !== PersonalIdentificationType.None,
      },
    }));

    let stepIncrement = 1;
    if (Object.values(coCustomerData || {}).length > 0) {
      stepIncrement = 0.2;
      await mainCustomerInfoForm.setValue(
        'encryptedPersonId',
        (Object.values(coCustomerData) as any)?.[0]?.encryptedPersonId || '',
      );
      await mainCustomerInfoForm.setValue(
        'preferredPhone',
        (Object.values(coCustomerData) as any)?.[0]?.primaryPhone?.replace(
          phoneNumberFormatting,
          '',
        ) || '',
      );
    }
    const step = currentStep + stepIncrement;
    await goToStep(step);
  };

  // TODO: Refactor? Co-customer 1. Not related to CC&B 'main customer/person'
  const handleMainCustomerInfoSubmit = async (
    values: IMoveServiceCustomerFormModel,
  ) => {
    const custData: any =
      Object.values(coCustomerData).find(
        (c: any) => c.encryptedPersonId === values.encryptedPersonId,
      ) || {};
    setFinalPayload((state: any) => ({
      ...state,
      mainCustomer: {
        ...values,
        ...custData,
      },
    }));
    let step = 3;
    if (
      (Object.values(coCustomerData) as any)?.length > 1 &&
      !coCustomerInfoForm.values.encryptedPersonId
    ) {
      await coCustomerInfoForm.setValue(
        'encryptedPersonId',
        (Object.values(coCustomerData) as any)?.[1]?.encryptedPersonId || '',
      );
      await coCustomerInfoForm.setValue(
        'preferredPhone',
        (Object.values(coCustomerData) as any)?.[1]?.primaryPhone?.replace(
          phoneNumberFormatting,
          '',
        ) || '',
      );
      step = 2.4;
    }

    await goToStep(step);
  };

  // TODO: Refactor? Co-customer 2.
  const handleCoCustomerInfoSubmit = async (
    values: IMoveServiceCustomerFormModel,
  ) => {
    const custData: any =
      Object.values(coCustomerData).find(
        (c: any) => c.encryptedPersonId === values.encryptedPersonId,
      ) || {};
    setFinalPayload((state: any) => ({
      ...state,
      coCustomer: {
        ...values,
        ...custData,
      },
    }));
    await goToStep(3);
  };

  const handleServiceOptionsSubmit = async (
    values: ServiceOptionsFormModel,
  ) => {
    setFinalPayload((state: any) => ({
      ...state,
      ...values,
      isCurrentlyPaperlessBilling: isCurrentlyPaperlessBilling ?? false,
    }));
    await goToStep(currentStep + 1);
  };

  const handleReviewSubmit = async (values: ServiceReviewFormModel) => {
    try {
      setIsSubmitting(true);
      const currentServiceAddress = accountDetails?.premiseInfo?.[0];
      const roughPayload = {
        ...finalPayload,
        ...values,
        currentServiceAddress,
      };

      const payload = createPayload(roughPayload);

      await moveServiceSubmit({
        variables: {
          payload,
        },
      });

      setIsSubmitting(false);

      return goToStep(currentStep + 1);
    } catch (e) {
      setIsSubmitting(false);
      return navigate(ROUTES.MOVE_SERVICE_ASSISTANCE);
    }
  };

  const goToStep = async (step: number) => {
    setCurrentStep(step);
    await navigate(routeMap[step]);
  };

  const resetState = () => {
    setCurrentStep(1);
    setFinalPayload({});
    setIsPTREligible(true);
    setCustomerUseAltPhone(false);
    setUseAltMailingAddress(false);
    serviceDetailsForm.reset();
    customerInfoForm.reset();
    mainCustomerInfoForm.reset();
    coCustomerInfoForm.reset();
    serviceOptionsForm.reset();
    serviceReviewForm.reset();
    setRehydrateTimestamp(Date.now());
  };

  const checkStepToUrl = (location: any) => {
    if (
      !location?.state?.currentStep &&
      location?.pathname !== ROUTES.MOVE_SERVICE
    ) {
      resetState();
      return goToStep(1);
    }
    if (routeMap[location?.state?.currentStep || 0] !== location?.pathname) {
      if (location?.state?.currentStep) {
        delete location.state.currentStep;
      }
      resetState();
      return goToStep(1);
    }
    return null;
  };

  return {
    accountDetails,
    accountNumber,
    checkStepToUrl,
    coCustomerData,
    coCustomerInfoForm,
    currentStep,
    customerInfoData,
    customerInfoForm,
    customerUseAltPhone,
    encryptedAccountNumber,
    encryptedPersonId,
    getMainCustomerPersonDetailsLoading,
    goToStep,
    handleCoCustomerInfoSubmit,
    handleCustomerInfoSubmit,
    handleMainCustomerInfoSubmit,
    handleReviewSubmit,
    handleServiceDetailSubmit,
    handleServiceOptionsSubmit,
    isAuthenticated,
    isPTREligible,
    isRenewablesEligible,
    isSubmitting,
    mainCustomerInfoForm,
    personsForAccountData,
    primaryIdType,
    resetState,
    serviceDetailsForm,
    serviceOptionsForm,
    serviceReviewForm,
    setCustomerUseAltPhone,
    setIsRenewablesEligible,
    setUseAltMailingAddress,
    setIsCurrentlyPaperlessBilling,
    step2DefaultFormValues,
    useAltMailingAddress,
  };
};
