import { jsx, css } from '@emotion/core';

import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { AlertProps } from '@material-ui/lab';
import {
  makeStyles,
  Theme,
  createStyles,
  Collapse,
  Box,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import colors from '../../../themes/main-colors';
import useContentMgmt from '../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import Typography from '@material-ui/core/Typography';
import ROUTES from '../../../routes';
import {
  Application,
  ApplicationStatus,
  ApplicationType,
  JobStatus,
  QuoteStatus,
} from '../../../__generated__/pgeplus-types';
import { useIsMobile } from '../../../util/style-utils';

import IconChargerLevel2 from '../../../components/pge-plus-common/static/evChargerIcon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bodyContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: '0px 1px 1px #00000066',
      border: `10px solid ${colors.riverBlue}`,
      borderRadius: '4px',
      backgroundColor: colors.white,
      marginBottom: '2em',
      padding: '20px 22px 16px 18px',
      cursor: 'pointer',
    },
    textContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  }),
);

const PendingApplicationBanner = ({
  severity = 'success',
  applicationType,
  accountNo,
  applicationsData,
  ...props
}: AlertProps & {
  applicationType?: ApplicationType[];
  accountNo?: string;
  applicationsData?: Application[];
}) => {
  const classes = useStyles();
  const { bodyContainer, textContent } = classes;
  const { content } = useContentMgmt(microcopyGroupIds.PGE_PLUS_WELCOME_BACK);
  const isMobile = useIsMobile();

  const [open, setOpen] = useState(true);

  const getApplicationType = () =>
    applicationType?.find(type => type === ApplicationType.Er)
      ? content.get('EV_REBATE')
      : content.get('EV_CHARGING');

  const getApplicationStageText = () => {
    const applicationInView = accountNo
      ? applicationsData?.find(
          (application: Application) => application.accountId === accountNo,
        )
      : applicationsData && applicationsData[0];
    if (
      applicationType?.find(type => type === ApplicationType.Er) ||
      applicationInView?.status === ApplicationStatus.Created ||
      applicationInView?.status === ApplicationStatus.InProgress
    ) {
      return content.get('COMPLETE_YOUR_APPLICATION');
    } else if (applicationInView?.job?.quote?.status === QuoteStatus.Created) {
      return content.get('ACCEPT_YOUR_QUOTE');
    } else if (applicationInView?.job?.quote?.status === QuoteStatus.Accepted) {
      return content.get('INSTALLATION_COMING_SOON');
    } else if (
      applicationInView?.status === ApplicationStatus.Submitted ||
      applicationInView?.job?.status === JobStatus.Created ||
      applicationInView?.job?.status === JobStatus.Accepted ||
      applicationInView?.job?.status === JobStatus.Rejected
    ) {
      return content.get('QUOTE_REQUESTED');
    }
  };

  return (
    <Collapse in={open}>
      <Box
        className={bodyContainer}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          const accountNumber = accountNo
            ? applicationsData?.find(
                (application: Application) =>
                  application.accountId === accountNo,
              )?.accountId
            : applicationsData && applicationsData[0]?.accountId;
          void navigate(ROUTES.PGE_PLUS_EV_CHARGERS_WELCOME_BACK, {
            state: { accountNumber: accountNumber },
          });
        }}
      >
        <Box className={textContent}>
          <IconChargerLevel2 height={45} width={45} />
          <Typography
            css={{
              color: colors.sparkBlue,
              fontWeight: 500,
              marginLeft: '10px',
            }}
          >
            PGE+ {getApplicationType()}:{isMobile && <br />}{' '}
            {getApplicationStageText()}
          </Typography>
        </Box>
        <ChevronRight />
      </Box>
    </Collapse>
  );
};

export default PendingApplicationBanner;
