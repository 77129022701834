import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';

type Props = {
  isOpen: boolean;
  closeTooltip: () => void;
  anchorEl: HTMLElement | null;
};

const IsThisABusinessAddressToolTip = ({
  isOpen,
  anchorEl,
  closeTooltip,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={closeTooltip}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          maxWidth: 500,
        },
      }}
    >
      <Grid container spacing={0} direction="row-reverse">
        <Grid item xs={2} style={{ textAlign: 'right' }}>
          <IconButton onClick={closeTooltip}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Box padding={`${theme.spacing(2)}px`}>
            <Typography
              variant="body1"
              component="div"
              style={{ display: 'inline' }}
            >
              {t('BUSINESS_ADDRESS_TOOLTIP_BODY_1')}{' '}
              <a
                href={ROUTES.BUSINESS_SERVICE_CHECKLIST}
                target="_blank"
                rel="noopener"
                style={{ display: 'inline' }}
              >
                {t('BUSINESS_SERVICE_CHECKLIST')}
              </a>{' '}
              {t('AND_CALL_US_AT')}{' '}
              <a href={`tel:${t('BUSINESS_CUSTOMER_SERVICE_NUMBER')}`}>
                {t('BUSINESS_CUSTOMER_SERVICE_NUMBER')}
              </a>{' '}
              {t('BETWEEN_THE_HOURS_BUSINESS')}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default IsThisABusinessAddressToolTip;
