import gql from 'not-graphql-tag';

export const getAccountDetailsForPaymentHistoryDetails = gql`
  query getAccountDetails(
    $params: AccountDetailParams!
    $paymentHistoryParams: PaymentHistoryParams!
  ) {
    getAccountDetails(params: $params) {
      accountNumber
      encryptedAccountNumber
      encryptedPersonId
      description
      isDefault
      isActive
      accountType
      relationType
      mainCustomerName
      coCustomerNames
      isLoggedInUserOnAccount
      serviceAddresses {
        addressLine1
        addressLine2
        city
        state
        postal
      }
      isSummaryBillAccount
      billInfo {
        amountDue
        dueDate
        lastPaymentDate
        lastPaymentAmount
        isAccountPayable
        isNewAccount
        oneTimeFuturePaymentScheduled
        multipleFuturePaymentsScheduled
        enrolledInTPA
        billDetails {
          encryptedBillId
          downloadBillUrl
          billStatus
          billDate
          dueDate
          amountDue
          previousBalance
          totalAdjustments
          totalCurrentCharges
          totalBalanceAfterBill
          hasBills
          kwh
          billingPeriodStartDate
          billingPeriodEndDate
        }
      }
      autoPay {
        isEnrolled
      }
      paymentEligibility {
        isCashOnly
        isNonBillableNoBalance
      }
      preferredDueDate {
        dueDate {
          preferredDueDate
          status
          effectiveDate
        }
      }
      isPaperlessBillEnrolled {
        result
      }
      equalpay {
        paymentPlanType
      }
      pendingDisconnect {
        isPendingDisconnect
      }
      serviceConnectivity {
        isEligibleForReconnect
        isDisconnected
        isReconnectInProgress
      }
      paymentHistory(paymentHistoryParams: $paymentHistoryParams) {
        paymentHistoryDetails {
          date
          amountDue
          amountPaid
          encryptedBillId
          billingPeriodStartDate
          billingPeriodEndDate
          kwh
          type
        }
        totalDetailsRecords
      }
    }
  }
`;
