import { validateEmail, validatePhoneNumber } from '../../util/form-validation';

export type Model = {
  // this must match the names of the form field
  notificationEmail: string;
  notificationTextNumber: string;
};

const validationRules = (values: Model) => {
  return {
    notificationEmail:
      values.notificationEmail.length > 0
        ? validateEmail(values.notificationEmail)
        : null,
    notificationTextNumber:
      values.notificationTextNumber.length > 0
        ? validatePhoneNumber(values.notificationTextNumber)
        : null,
  };
};

export default validationRules;
