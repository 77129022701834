import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PaperBox from '../../utility/paper-box';
import useStyles from '../styles';

interface Props {
  notEnoughDataHeader?: String;
  notEnoughDataSubHeader?: String;
  firstDescription?: String;
  secondDescription?: String;
  csaInfo?: React.ReactNode;
  isEnrolled?: Boolean;
}

export const TimeOfDayNoEnoughData = (props: Props) => {
  const classes = useStyles();
  const {
    notEnoughDataHeader,
    notEnoughDataSubHeader,
    firstDescription,
    secondDescription,
    csaInfo,
    isEnrolled,
  } = props;

  return (
    <Grid container>
      <PaperBox className={classes.sideBySideBox}>
        {notEnoughDataHeader && (
          <Grid item>
            <Typography
              className={
                !isEnrolled
                  ? classes.notEnoughDataHeader
                  : classes.notEnoughDataEnrolled
              }
            >
              {notEnoughDataHeader}
            </Typography>
          </Grid>
        )}
        <Grid item className={classes.notEnoughDataGrid}>
          {notEnoughDataSubHeader && (
            <Typography className={classes.notEnoughDataSubHeader}>
              {notEnoughDataSubHeader}
            </Typography>
          )}
          {firstDescription && (
            <Typography className={classes.notEnoughDataContent} variant={'h6'}>
              {firstDescription}
            </Typography>
          )}
          {secondDescription && (
            <Typography className={classes.notEnoughDataContent} variant={'h6'}>
              {secondDescription}
            </Typography>
          )}
          {csaInfo && <Typography variant={'h6'}>{csaInfo}</Typography>}
        </Grid>
      </PaperBox>
    </Grid>
  );
};

export default TimeOfDayNoEnoughData;
