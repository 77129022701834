import React from 'react';
import { navigate } from '@reach/router';
import ROUTES from '../../routes';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { useTranslation } from '../../hooks/useTranslation';
import Alerts from '../alerts';
import PGEButton from '../buttons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiAlert-outlinedError': {
        flexDirection: 'column',
      },
      '& .MuiAlert-message': {
        marginTop: theme.spacing(-4.5),
        marginLeft: theme.spacing(5),
      },
    },
  }),
);

export default function Ineligible({
  children,
  title,
}: {
  children: React.ReactNode;
  title: React.ReactNode;
}) {
  const { t } = useTranslation();

  return (
    <div className={useStyles().root}>
      <Alerts
        disableColorOverride={true}
        isOpen
        severity={'error'}
        variant={'outlined'}
        title={title}
        message={children}
        action={
          <Box>
            <PGEButton size="large" onClick={() => navigate(ROUTES.ACCOUNT)}>
              {t('DONE')}
            </PGEButton>
          </Box>
        }
      />
    </div>
  );
}
