import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { Grid, Typography, Checkbox, makeStyles, createStyles, Theme, Paper, Card, CardContent, FormHelperText, Divider, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import { DirectAccessHistoricalUsageFormState, DirectAccessHistoricalUsageModel, HistoricalUsageAuthType } from '../../../hooks/useDirectAccessHistoricalUsage';
import PhoneTextField from '../../phone-text-field';
import EmailTextField from '../../email-text-field';
import { convertValidationRules } from '../../../hooks/useFormState';
import { validateEmail, validateExistsAndAlphabetCharacters, validateNameField, validatePhoneNumber } from '../../../util/form-validation';
import { BaseValidationContext } from '../../../hooks/useFormState.types';
import clsx from 'clsx';
import TextField from '../../text-field';
import Backdrop from '../../backdrop';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop0: {
      marginTop: theme.spacing(0),
    },
    marginTop1: {
      marginTop: '1em',
    },
    marginBottom1: {
      marginBottom: '1em',
    },
    errorMessage: {
      margin: '0 14px',
    },
    optionText: {
      paddingLeft: theme.spacing(4),
    },
    formField: {
      width: '100%',
      background: 'none',
      margin: 0,
    },
    cardContent: {
      padding: 16, 
      marginBottom: 30
    },
    divider: {
      marginTop: 16, 
      marginBottom: 16
    }
  }),
);

export const DirectAccessHistoricalUsageForm = ({
  formState,
  componentProps
}: WizardFormProps<DirectAccessHistoricalUsageModel, DirectAccessHistoricalUsageFormState>) => {
  const { t } = useTranslation();
  const { handleFormValidation, businesses, accountGroupsLoading } = componentProps;
  const classes = useStyles();
  
  const [businessNameNotSelected, setBusinessNameNotSelected] = useState(false);

  useEffect(() => {

    if (Object.keys(formState.errors).length === 0
      && businesses.some((b: { name: string; isSelected: boolean; groupId: string; }) => b.isSelected)
      && checkFieldValidations()) {
      handleFormValidation(false);
    } else {
      handleFormValidation(true);
    }

  }, [businesses, formState.errors, formState.values])

  useEffect(() => {
    if(formState.values.isSamePersonAuthorizing) {
      void formState.setValue('authFullName', '');
      void formState.setValue('authTitle', '');
      void formState.setValue('authEmail', '');
      void formState.setValue('authPhoneNumber', '');
    }
  }, [formState.values.isSamePersonAuthorizing])

  const checkFieldValidations = () => {
    if (formState.values.isSamePersonAuthorizing
      && (formState.values.companyName === ''
        || formState.values.contactFullName === ''
        || formState.values.contactTitle === ''
        || formState.values.contactEmail === ''
        || formState.values.contactPhoneNumber === '')) {
      return false;
    } else if (!formState.values.isSamePersonAuthorizing
      && Object.values(formState.values).some(o => o === '')) {
      return false;
    } else {
      return true;
    }
  }
  const handleBusinessSelection = (groupId: string, isSelected: boolean) => {
    const business = businesses.find((b: { name: string; isSelected: boolean; groupId: string; }) => b.groupId === groupId);
    if (business) {
      business.isSelected = isSelected;
    }
    void formState.setValue('businesses', businesses);
    if (!businesses.some((b: { name: string; isSelected: boolean; groupId: string; }) => b.isSelected)) {
      setBusinessNameNotSelected(true);
    } else {
      setBusinessNameNotSelected(false);
    }

  }

  const handleAuthTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    void formState.setValue('authType', event.target.value as HistoricalUsageAuthType);
  }

  return (
    <>
    {accountGroupsLoading ? (
        <Backdrop forceOpen message={t('LOADING')} />
      ) : null}
      <Grid item xs={12} className={classes.marginTop1}>
        <Paper>
          <Card>
            <CardContent className={classes.cardContent}>
              <Grid container direction="column">
                <Grid item container>
                  <Grid item xs={12}>
                    <Grid item className={classes.marginBottom1}>
                      <Typography variant={'body1'}>
                        {t('HISTORICAL_USAGE_FORM_SUMMARY')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h2"
                      className={classes.marginBottom1}>
                      {t('HISTORICAL_USAGE_FORM_BUSINESSES_TITLE')}
                    </Typography>
                    <Typography variant={'body1'}
                      className={classes.marginBottom1}>
                      {t('HISTORICAL_USAGE_FORM_BUSINESSES_TEXT')}
                    </Typography>
                    <Typography variant={'body1'}
                      className={classes.marginBottom1}>
                      {t('HISTORICAL_USAGE_FORM_BUSINESSES_NOTE')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h2"
                      className={classes.marginBottom1}>
                      {t('HISTORICAL_USAGE_FORM_BUSINESSES_LIST_TITLE')}
                    </Typography>
                    {businesses.map((business: { name: string, isSelected: boolean, groupId: string }, index: number) => (
                      <Grid
                        key={index}
                        container
                        direction={'row'}
                        alignItems={'center'}
                      >
                        <Grid item>
                          <Checkbox
                            name={`business_${index}`}
                            id={`business_${index}`}
                            checked={business.isSelected}
                            onChange={e => handleBusinessSelection(business.groupId, e.target.checked)}
                            color="primary"
                          />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant={'h4'}
                          >
                            {business.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    ))}
                    {businessNameNotSelected && (
                      <FormHelperText
                        className={clsx(
                          'MuiFormHelperText-root MuiFormHelperText-contained Mui-error',
                        )}
                      >
                        {t('SELECT_BUSINESS_NAME_ERROR')}
                      </FormHelperText>
                    )}
                    <Typography variant={'body1'} className={classes.marginTop1}>
                      {t('HISTORICAL_USAGE_FORM_BUSINESSES_LIST_NOTE')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Typography variant="h2">
                    {t('HISTORICAL_USAGE_FORM_AUTHORIZATION_TITLE')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <RadioGroup
                    aria-label={'direct-access-authorization'}
                    data-testid={'authType'}
                    name={'authType'}
                    value={formState.values.authType}
                    onChange={handleAuthTypeChange}
                  >
                    <FormControlLabel
                      value={t('RELEASE')}
                      control={<Radio color={'primary'} />}
                      label={t('HISTORICAL_USAGE_FORM_AUTHORIZATION_RELEASE_OPTION')}
                    />
                    <Typography variant={'body1'} className={`${classes.marginBottom1} ${classes.optionText}`}>
                      {t('HISTORICAL_USAGE_FORM_AUTHORIZATION_RELEASE_OPTION_TEXT')}
                    </Typography>

                    <FormControlLabel
                      value={t('REVOKE')}
                      control={<Radio color={'primary'} />}
                      label={t('HISTORICAL_USAGE_FORM_AUTHORIZATION_REVOKE_OPTION')}
                    />
                    <Typography variant={'body1'} className={classes.optionText}>
                      {t('HISTORICAL_USAGE_FORM_AUTHORIZATION_REVOKE_OPTION_TEXT')}
                    </Typography>
                  </RadioGroup>
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Typography variant="h2">
                    {t('CONTACT_INFORMATION')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name={'companyName'}
                    data-testid="companyName"
                    label={t('CONTACT_INFO_COMPANY_NAME')}
                    inputProps={{
                      minLength: 1,
                      maxLength: 80,
                      'data-testid': 'test-companyName',
                    }}
                    className={classes.formField}
                    {...formState.props('companyName')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name={'contactFullName'}
                    data-testid="contactFullName"
                    label={t('CONTACT_INFO_FULL_NAME')}
                    inputProps={{
                      minLength: 1,
                      maxLength: 80,
                      'data-testid': 'test-contactFullName',
                    }}
                    className={classes.formField}
                    {...formState.props('contactFullName')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    name={'contactTitle'}
                    data-testid="contactTitle"
                    label={t('CONTACT_INFO_TITLE')}
                    inputProps={{
                      minLength: 1,
                      maxLength: 50,
                      'data-testid': 'test-contactTitle',
                    }}
                    className={classes.formField}
                    {...formState.props('contactTitle')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <EmailTextField
                    margin="none"
                    name={'contactEmail'}
                    label={t('CONTACT_INFO_EMAIL_ADDRESS')}
                    data-testid={'contactEmail'}
                    {...formState.props('contactEmail')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PhoneTextField
                    name={'contactPhoneNumber'}
                    label={t('CONTACT_INFO_PHONE_NUMBER')}
                    className={classes.formField}
                    inputProps={{ minLength: 1, maxLength: 15 }}
                    data-testid="contactPhoneNumber"
                    {...formState.props('contactPhoneNumber')}
                  />
                </Grid>
              </Grid>
              <Divider className={classes.divider} />
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Typography variant="h2">
                    {t('AUTHORIZATION')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={'isSamePersonAuthorizing'}
                        id="sameAsAbove"
                        color={'primary'}
                        data-testid="isSamePersonAuthorizing"
                        checked={formState.values.isSamePersonAuthorizing}
                        {...formState.props('isSamePersonAuthorizing')}
                      />
                    }
                    label={t('HISTORICAL_USAGE_CONTACT_SAME_AS_ABOVE')}
                  />
                </Grid>
                {!formState.values.isSamePersonAuthorizing && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        name={'authFullName'}
                        data-testid="authFullName"
                        label={t('CONTACT_INFO_FULL_NAME')}
                        inputProps={{
                          minLength: 1,
                          maxLength: 80,
                          'data-testid': 'test-authFullName',
                        }}
                        className={classes.formField}
                        {...formState.props('authFullName')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={'authTitle'}
                        data-testid="authTitle"
                        label={t('CONTACT_INFO_TITLE')}
                        inputProps={{
                          minLength: 1,
                          maxLength: 50,
                          'data-testid': 'test-authTitle',
                        }}
                        className={classes.formField}
                        {...formState.props('authTitle')}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <EmailTextField
                        margin="none"
                        name={'authEmail'}
                        label={t('CONTACT_INFO_EMAIL_ADDRESS')}
                        data-testid={'authEmail'}
                        {...formState.props('authEmail')}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <PhoneTextField
                        name={'authPhoneNumber'}
                        label={t('CONTACT_INFO_PHONE_NUMBER')}
                        className={classes.formField}
                        inputProps={{ minLength: 1, maxLength: 15 }}
                        data-testid="authPhoneNumber"
                        {...formState.props('authPhoneNumber')}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
    </>
  );
};

export const createDirectAccessHistoricalUsageFormAdapter = () => {
  return {
    toFormState: (
      initial: DirectAccessHistoricalUsageModel,
    ): DirectAccessHistoricalUsageFormState => ({
      businesses: initial.businesses,
      authType: initial.authType,
      isSamePersonAuthorizing: initial.isSamePersonAuthorizing ?? true,
      companyName: initial.contactInfo?.companyName ?? '',
      contactFullName: initial.contactInfo?.fullName ?? '',
      contactTitle: initial.contactInfo?.title ?? '',
      contactEmail: initial.contactInfo?.emailAddress ?? '',
      contactPhoneNumber: initial.contactInfo?.phoneNumber ?? '',
      authFullName: initial.authInfo?.fullName ?? '',
      authTitle: initial.authInfo?.title ?? '',
      authEmail: initial.authInfo?.emailAddress ?? '',
      authPhoneNumber: initial.authInfo?.phoneNumber ?? '',
    }),
    fromFormState: (
      state: DirectAccessHistoricalUsageFormState,
    ): DirectAccessHistoricalUsageModel => {
      return {
        businesses: state.businesses,
        authType: state.authType,
        isSamePersonAuthorizing: state.isSamePersonAuthorizing,
        contactInfo: {
          companyName: state.companyName,
          fullName: state.contactFullName,
          title: state.contactTitle,
          emailAddress: state.contactEmail,
          phoneNumber: state.contactPhoneNumber
        },
        authInfo: {
          fullName: state.isSamePersonAuthorizing ? state.contactFullName : state.authFullName,
          title: state.isSamePersonAuthorizing ? state.contactTitle : state.authTitle,
          emailAddress: state.isSamePersonAuthorizing ? state.contactEmail : state.authEmail,
          phoneNumber: state.isSamePersonAuthorizing ? state.contactPhoneNumber : state.authPhoneNumber
        }
      };
    },
  };
};

export const createDirectAccessValidateFunction = () => {
  const { t } = useTranslation();
  return convertValidationRules<DirectAccessHistoricalUsageFormState>(
    (context: BaseValidationContext<DirectAccessHistoricalUsageFormState>) => {
      const {
        values: { isSamePersonAuthorizing },
      } = context;
      return {
        companyName: validateNameField.bind(
          null,
          'Company Name',
        ),
        contactFullName: validateExistsAndAlphabetCharacters.bind(
          null,
          'Full Name',
        ),
        contactTitle: validateExistsAndAlphabetCharacters.bind(
          null,
          'Title',
        ),
        contactEmail: validateEmail,
        contactPhoneNumber: validatePhoneNumber,
        isSamePersonAuthorizing: () => null,
        authFullName: isSamePersonAuthorizing
          ? () => null
          : validateExistsAndAlphabetCharacters.bind(
            null,
            'Full Name',
          ),
        authTitle: isSamePersonAuthorizing
          ? () => null
          : validateExistsAndAlphabetCharacters.bind(
            null,
            'Title',
          ),
        authEmail: isSamePersonAuthorizing
          ? () => null
          : validateEmail,
        authPhoneNumber: isSamePersonAuthorizing
          ? () => null
          : validatePhoneNumber
      };
    },
  );;
};
