import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import {
  EditLink,
  DataLabel,
} from '../../start-service/StartServiceReviewHelpers';
import { BaseFormModel } from '../../../hooks/useFormState.types';
import { useTranslation } from '../../../hooks/useTranslation';
import { toDateString } from '../../../util/format';
import ROUTES from '../../../routes';

type MoveServiceDetailsReviewProps = {
  serviceDetailsForm: BaseFormModel;
  goToStep: (step: number) => Promise<void>;
  accountDetails: any;
};

const MoveServiceDetailsReview = ({
  serviceDetailsForm,
  goToStep,
  accountDetails,
}: MoveServiceDetailsReviewProps) => {
  const { t } = useTranslation();

  const previousAddress = [
    accountDetails?.premiseInfo?.[0]?.addressLine1 || '',
    (accountDetails?.premiseInfo?.[0]?.city || '') + ',',
    accountDetails?.premiseInfo?.[0]?.state || '',
    accountDetails?.premiseInfo?.[0]?.postal || '',
  ].join(' ');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{t('SERVICE_DETAILS')}</Typography>
          <EditLink step={1} to={ROUTES.MOVE_SERVICE} goToStep={goToStep} />
        </Box>
      </Grid>
      <DataLabel
        text={t('STOP_SERVICE_AT')}
        value={<Typography variant="body2">{previousAddress}</Typography>}
      />
      <DataLabel
        text={t('STOP_SERVICE_ON')}
        value={
          <Typography variant="body2">
            {toDateString(serviceDetailsForm.values.stopDate)}
          </Typography>
        }
      />
      <DataLabel
        text={t('START_SERVICE_AT')}
        value={
          <Typography variant="body2">
            {[
              serviceDetailsForm?.values?.address,
              serviceDetailsForm?.values?.city + ',',
              serviceDetailsForm?.values?.state,
              serviceDetailsForm?.values?.zip,
            ].join(' ')}
          </Typography>
        }
      />
      <DataLabel
        text={t('START_SERVICE_ON')}
        value={
          <Typography variant="body2">
            {toDateString(serviceDetailsForm.values.startDate)}
          </Typography>
        }
      />
      <DataLabel
        text={t('OWN_OR_RENT')}
        value={serviceDetailsForm.values.ownOrRent}
      />
      <DataLabel
        text={t('DO_YOU_LIVE_AT_THIS_ADDRESS')}
        value={serviceDetailsForm.values.liveAtAddress}
      />
    </Grid>
  );
};

export default MoveServiceDetailsReview;
