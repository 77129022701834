import React from 'react';
import { CommunitySolarCardType } from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import { CommunitySolar as TCommunitySolar } from '../../types';
import { Card } from '../Card';
import { CreditAndChargeDetails } from './CreditAndChargeDetails';

export function CommunitySolar(props: TCommunitySolar) {
  const { cardType, amountDetails, chargesAndCredits } = props;
  const { t, inlineRichT } = useTranslation();

  if (cardType === CommunitySolarCardType.FirstMonthBill) {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('WELCOME_TO_COMMUNITY_SOLAR')}
        sectionA={inlineRichT('COMMUNITY_SOLAR_FIRST_MONTH')}
        sectionB={
          <CreditAndChargeDetails
            amountDetails={amountDetails}
            chargesAndCredits={chargesAndCredits}
            showAmountBreakdown={true}
          />
        }
      />
    );
  }

  if (cardType === CommunitySolarCardType.MonthlyBill) {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('COMMUNITY_SOLAR_PERFORMANCE')}
        sectionA={inlineRichT('COMMUNITY_SOLAR_MONTHLY_ANNUAL_BILL')}
        sectionB={
          <CreditAndChargeDetails
            amountDetails={amountDetails}
            chargesAndCredits={chargesAndCredits}
            showAmountBreakdown={true}
          />
        }
      />
    );
  }

  if (cardType === CommunitySolarCardType.TwoMonthsOnSameBill) {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('COMMUNITY_SOLAR_PERFORMANCE')}
        sectionA={inlineRichT('COMMUNITY_SOLAR_MONTHLY_ANNUAL_BILL')}
        sectionB={
          <CreditAndChargeDetails
            amountDetails={amountDetails}
            chargesAndCredits={chargesAndCredits}
            showAmountBreakdown={true}
          />
        }
      />
    );
  }

  if (cardType === CommunitySolarCardType.AnnualRecap) {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('COMMUNITY_SOLAR_PERFORMANCE')}
        sectionA={inlineRichT('COMMUNITY_SOLAR_MONTHLY_ANNUAL_BILL')}
        sectionB={
          <CreditAndChargeDetails
            amountDetails={amountDetails}
            chargesAndCredits={chargesAndCredits}
            showCommunitySolarMessage={true}
          />
        }
      />
    );
  }

  if (cardType === CommunitySolarCardType.AnnualRecapWithFee) {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('COMMUNITY_SOLAR_PERFORMANCE')}
        sectionA={inlineRichT('COMMUNITY_SOLAR_ANNUAL_WITH_FEE_BILL', {
          reconciliationFee: chargesAndCredits?.reconciliationFee ?? '',
        })}
        sectionB={
          <CreditAndChargeDetails
            amountDetails={amountDetails}
            chargesAndCredits={chargesAndCredits}
          />
        }
      />
    );
  }

  return <></>;
}
