import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from 'infinite-react-carousel';
import React, { FunctionComponent } from 'react';

import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';
import Link from '../../text-link';

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PGEButton from '../../buttons';

function NextArrow(props: any) {
  const { className, style, onClick } = props;
  const classes = useStyles();

  return (
    <div
      className={className}
      style={{ ...style, display: 'block', paddingTop: 130 }}
      onClick={onClick}
    >
      <PGEButton
        className={classes.arrow}
        style={{ paddingRight: 10 }}
        variant={'outlined'}
        size={'large'}
        startIcon={<ArrowForwardIosIcon fontSize={'large'} />}
      >
        {' '}
      </PGEButton>
    </div>
  );
}

function PrevArrow(props: any) {
  const { className, style, onClick } = props;
  const classes = useStyles();

  return (
    <div
      className={className}
      style={{ ...style, display: 'block', paddingTop: 130 }}
      onClick={onClick}
    >
      <PGEButton
        className={classes.arrow}
        style={{ paddingLeft: 0 }}
        variant={'outlined'}
        size={'large'}
        startIcon={
          <ArrowBackIosIcon style={{ paddingLeft: 0 }} fontSize={'large'} />
        }
      >
        {' '}
      </PGEButton>
    </div>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    paper: {
      padding: '1.5em',
    },
    card: {
      height: '267px',
    },
    arrow: {
      borderColor: 'transparent',
      color: '#A9A9A9',
      '&:hover': {
        color: '#A9A9A9',
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      },
    },
    tempCardBackground: {
      color: '#fff',
      background: 'rgb(71,186,190)',
      backgroundImage:
        'url(https://i.pinimg.com/originals/97/2a/cc/972acc8642e564e2040e7d272c583042.jpg)',
      // background: 'linear-gradient(90deg, rgba(71,186,190,1) 19%, npm rgba(112,249,233,1) 59%, rgba(2,0,36,1) 96%, rgba(2,0,36,1) 99%, rgba(0,212,255,1) 100%)'
    },
    greenSource: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    carouselLink: {
      // display: 'flex',
      // justifyContent: 'center',
      // alignItems: 'baseline',
      position: 'absolute',
      bottom: '0',
      marginLeft: '3em',
      marginBottom: '1em',
      // margin: 'auto',
    },
  }),
);

interface EnergyImpactCarouselProps {
  isMobile?: boolean;
}

const EnergyImpactCarousel: FunctionComponent<EnergyImpactCarouselProps> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const settings = {
    dots: true,
    slidesToShow: props?.isMobile ? 1 : 3,
    initialSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div>
      <Grid item xs={12}>
        <Typography variant={'h2'} className={classes.colorTextPrimary}>
          {t('Increase your impact')}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ margin: '1em' }}>
        <Typography variant={'subtitle1'} className={classes.colorTextPrimary}>
          {t('As a PGE customer, you can save energy, money, and go green.')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Slider {...settings}>
          <Grid item className={classes.paper}>
            <Paper>
              <Card className={classes.card}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography
                        variant={'h2'}
                        className={classes.colorTextPrimary}
                      >
                        {'Peak Time Rebates'}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant={'subtitle2'}
                        className={classes.colorTextPrimary}
                      >
                        {'Earn rebates or shifting energy on certain days.'}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        variant={'body1'}
                        className={classes.colorTextPrimary}
                      >
                        {`Peak Time Rebates rewards you for shifting and reducing
                        your energy demand and prices are at their highest.`}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.carouselLink}>
                      <Link plain to={''}>
                        {'Enroll in Peak Time Rebates'}
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item className={classes.paper}>
            <Paper>
              <Card
                className={classes.card}
                style={{
                  backgroundImage:
                    'url(https://images.unsplash.com/photo-1530891671629-3a053324e4f7?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=882&q=80)',
                }}
              >
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography
                        variant={'h2'}
                        className={classes.colorTextPrimary}
                      >
                        Renewable Power
                      </Typography>
                    </Grid>
                    <Grid item className={classes.greenSource}>
                      <Typography
                        variant={'h3'}
                        className={classes.colorTextPrimary}
                        style={{ marginRight: '4px' }}
                      >
                        {'Green Future Choice'}
                      </Typography>
                      <Typography
                        variant={'body1'}
                        className={classes.colorTextPrimary}
                      >
                        {
                          ' is giving you all of you electricity from renewable energy.'
                        }
                      </Typography>
                    </Grid>
                    <Grid item className={classes.carouselLink}>
                      <Link plain to={''}>
                        {'Renewal power settings'}
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item className={classes.paper}>
            <Paper className={classes.tempCardBackground}>
              <Card
                className={[classes.card, classes.tempCardBackground].join(' ')}
              >
                <CardContent>
                  <Typography style={{ color: '#fff' }} variant={'h3'}>
                    NIGHT OWL? CHARGE AN ELECTRIC VEHICLE OVERNIGHT?
                  </Typography>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item className={classes.paper}>
            <Paper>
              <Card className={classes.card}>
                <CardContent>
                  <img
                    src={
                      'https://images.unsplash.com/photo-1548613053-22087dd8edb8?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=250&q=250'
                    }
                  />
                </CardContent>
              </Card>
            </Paper>
          </Grid>
          <Grid item className={classes.paper}>
            <Paper>
              <Card className={classes.card}>
                <CardContent>
                  <img
                    src={
                      'https://images.unsplash.com/photo-1529310399831-ed472b81d589?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=250&q=250'
                    }
                  />
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Slider>
      </Grid>
    </div>
  );
};

export default EnergyImpactCarousel;
