import gql from 'not-graphql-tag';
import { AlertReponse, GetAlertsRequest } from '../__generated__/pge-types';
import useAuthQuery from './useAuthQuery';

const GET__ALERTS = gql`
  query GetAlerts($params: GetAlertsRequest) {
    getAlerts(params: $params) {
      prefLanguage
      alerts {
        notificationType
        deliveryTypeDetails {
          deliveryType
          contactDetails {
            contactId
            encryptedContactId
            value
            encryptedPreferenceId
            isSelected
            isPrimary
          }
          deliveryTypeDescription
        }
        notificationTypeDescription
        notificationOptions {
          sequence
          value
        }
        notificationGroup
        notificationGroupDescription
        classification
        classificationDescrption
        isEmailRequired
        notificationTypeSequence
        notificationGroupSequence
        classificationSequence
        isSMSAllowed
      }
    }
  }
`;

export type Alerts = {
  getAlerts: AlertReponse;
};

type AlertRequest = {
  requestParams: {
    params: GetAlertsRequest;
    skipParam: boolean;
  };
};

export const useGetManageAlertsService = ({ requestParams }: AlertRequest) => {
  const { params, skipParam } = requestParams;
  const { refetch, loading, error, data } = useAuthQuery<
    Alerts,
    { params: GetAlertsRequest }
  >(GET__ALERTS, {
    variables: {
      params,
    },
    skip: skipParam,
    fetchPolicy: 'no-cache',
  });
  return {
    refetch,
    loading,
    error,
    data,
  };
};
export default useGetManageAlertsService;
