import { Router } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import AccountLayout from '../../components/account-layout';
import TimeOfDay from '../../components/time-of-day';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
export const TimeOfDayMain = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('TOD_TITLE_CASE')}</title>
      </Helmet>
      <AccountLayout>
        <Router basepath="/">
          <TimeOfDay path={`${ROUTES.TIMEOFDAY}/*`} />
        </Router>
      </AccountLayout>
    </>
  );
};
export default TimeOfDayMain;
