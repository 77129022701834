/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FC, ReactNode, useState, useEffect, Fragment } from 'react';
import { Box, Divider, Paper, Typography } from '@material-ui/core';
import { navigate } from 'gatsby';
import { useTheme } from '@material-ui/core/styles';

import routes from '../../../routes';
import colors from '../../../themes/main-colors';

import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import useContentMgmt, {
  PageTextContentType,
  ProductData,
} from '../../../hooks/useContentMgmt';
import { useTranslation } from '../../../hooks/useTranslation';
import SummarySection, {
  SummarySectionProps,
} from '../../pge-plus-common/SummarySection';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import {
  ApplicationEvSectionType,
  SectionEvAccountInfo,
  HomeType,
  TimeFrame,
  PanelLocation,
  PanelDistance,
  PanelSideLocation,
  DoubleBreakerAvailable,
  MainBreakerAmperage,
  SectionEvSummary,
  ApplicationSectionStatus,
  ChargerInstallLocation,
} from '../../../__generated__/pgeplus-types';
import { titleCase } from '../../../util/string-utils';
import useUpdateEVSections from '../../../hooks/pge-plus/useUpdateEVSections';
import { SaveAndComeBackLink } from '../../pge-plus-common/SaveAndComeBackLink';
import useUtil from '../../../hooks/pge-plus/useUtil';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import {
  displayPhoneNumberFormat,
  toCurrencyString,
} from '../../../util/format';

interface SummaryProps {
  children?: ReactNode;
  path: string;
}

const getSummaryData = (
  content: PageTextContentType,
): SummarySectionProps[] => {
  const {
    formState: {
      contactInfo,
      rebateEligibility,
      selectCharger,
      installation,
      uploadPhotos,
      application,
    },
    getSectionData,
  } = useEVChargerFormState();

  const [evChargerProductList, setEvChargerProductList] = useState<
    ProductData[]
  >([]);

  const { content: contentContactInfo, getProductList } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_CONTACT_INFO,
  );
  const { content: contentInstallation } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLATION_LOCATION,
  );
  const { content: contentUploadPhotos } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_UPLOAD_PHOTOS,
  );
  const { content: contentRebateEligibility } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ELIGIBILITY,
  );

  useEffect(() => {
    const fetchProductList = async (productListEntryId: string) => {
      const productList = await getProductList({
        productListEntryId,
      });
      setEvChargerProductList(productList);
      //console.log('productList', productList);
    };
    fetchProductList(
      selectCharger.suppliedByCustomer
        ? microcopyGroupIds.PGE_PLUS_CONFIRM_YOUR_CHARGER_PRODUCT_LIST
        : microcopyGroupIds.PGE_PLUS_SELECT_CHARGER_PRODUCT_LIST,
    ).catch(err => {
      console.log('fetchProductList error: ', err);
    });
  }, []);

  const sectionDataAccountInfo: SectionEvAccountInfo = getSectionData(
    ApplicationEvSectionType.EvAccountInfo,
  );

  const HOME_TYPES = {
    [HomeType.Detached]: contentContactInfo.get('DETACHED'),
    [HomeType.Townhouse]: contentContactInfo.get('TOWNHOUSE'),
    [HomeType.Duplex]: contentContactInfo.get('DUPLEX'),
    [HomeType.Triplex]: contentContactInfo.get('TRIPLEX'),
    [HomeType.Apartment]: contentContactInfo.get('APARTMENT'),
    [HomeType.Condo]: contentContactInfo.get('CONDO'),
  };

  const TIMEFRAME = {
    [TimeFrame.Morning]: contentInstallation.get('MORNING_8AM_TO_12PM'),
    [TimeFrame.Afternoon]: contentInstallation.get('AFTERNOON_12PM_TO_5PM'),
    [TimeFrame.Any]: contentInstallation.get('NO_PREFERENCE'),
  };

  const PANEL_LOCATION = {
    [PanelLocation.GarageAttached]: contentInstallation.get('ATTACHED_GARAGE'),
    [PanelLocation.Basement]: contentInstallation.get('BASEMENT'),
    [PanelLocation.Inside]: contentInstallation.get('INSIDE_THE_HOME'),
    [PanelLocation.Outside]: contentInstallation.get('OUTSIDE_THE_HOME'),
    [PanelLocation.NotSure]: contentInstallation.get('NOT_SURE'),
    [PanelLocation.Other]: contentInstallation.get('OTHER'),
  };

  const CHARGER_INSTALL_LOCATION = {
    [ChargerInstallLocation.Garage]: contentInstallation.get('GARAGE'),
    [ChargerInstallLocation.Outside]: contentInstallation.get(
      'OUTSIDE_THE_HOME_PLAIN',
    ),
  };

  const DISTANCE_TO_PANEL = {
    [PanelDistance.Lt_5]: contentInstallation.get('5_FEET_OR_LESS'),
    [PanelDistance.Btw_6_30]: contentInstallation.get('6_TO_30_FEET'),
    [PanelDistance.Gt_30]: contentInstallation.get('MORE_THAN_30_FEET'),
  };

  const PANEL_SIDE_LOCATION = {
    [PanelSideLocation.Left]: contentInstallation.get('LEFT_SIDE'),
    [PanelSideLocation.BackWall]: contentInstallation.get('BACK_SIDE'),
    [PanelSideLocation.Right]: contentInstallation.get('RIGHT_SIDE'),
  };

  const MAIN_BREAKER_AMPS = {
    [MainBreakerAmperage.Unknown]: contentUploadPhotos.get('IM_NOT_SURE'),
    [MainBreakerAmperage.Amps_200]: contentUploadPhotos.get('200_AMPS'),
    [MainBreakerAmperage.Amps_150]: contentUploadPhotos.get('150_AMPS'),
    [MainBreakerAmperage.Amps_125]: contentUploadPhotos.get('125_AMPS'),
    [MainBreakerAmperage.Amps_100]: contentUploadPhotos.get('100_AMPS'),
    [MainBreakerAmperage.NoMainBreaker]: contentUploadPhotos.get(
      'NO_MAIN_BREAKER',
    ),
  };

  const DOUBLE_BREAKER_OPTIONS = {
    [DoubleBreakerAvailable.Unknown]: contentUploadPhotos.get('IM_NOT_SURE'),
    [DoubleBreakerAvailable.Yes]: contentUploadPhotos.get('REUSABLE_YES'),
    [DoubleBreakerAvailable.No]: contentUploadPhotos.get('REUSABLE_NO'),
  };

  return [
    {
      sectionTitle: content.get('CONFIRM_CONTACT_INFO')!,
      editLink: routes.PGE_PLUS_EV_CHARGERS_CONTACT_INFO,
      items: [
        {
          label: content.get('CONTACT_INFO')!,
          values: [
            `${(contactInfo.isMainPerson
              ? sectionDataAccountInfo?.data?.accountContact?.firstName
              : contactInfo.firstName)!}
            ${(contactInfo.isMainPerson
              ? sectionDataAccountInfo?.data?.accountContact?.middleName
              : contactInfo.middleName)!}
            ${(contactInfo.isMainPerson
              ? sectionDataAccountInfo?.data?.accountContact?.lastName
              : contactInfo.lastName)!}`,
            (contactInfo.isMainPerson
              ? sectionDataAccountInfo?.data?.accountContact?.phoneNumber
              : displayPhoneNumberFormat(contactInfo.phoneNumber))!,
            (contactInfo.isMainPerson
              ? sectionDataAccountInfo?.data?.accountContact?.emailAddress
              : contactInfo.email)!,
          ],
        },
        {
          label: content.get('ADDRESS')!,
          values: [sectionDataAccountInfo?.data?.address?.addressLine1],
        },
        {
          label: content.get('ARE_YOU_HOMEOWNER')!,
          values: [contactInfo.isOwnHome ? 'Yes' : 'No'],
        },
        {
          label: content.get('TYPE_OF_HOME')!,
          values: [HOME_TYPES[contactInfo.homeType!] || ''],
        },
      ],
    },
    {
      sectionTitle: content.get('REBATE_ELIGIBILITY_TITLE')!,
      editLink: routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY,
      items: [
        {
          label: content.get('INCOME_ELIGIBILITY')!,
          values: [
            rebateEligibility.houseHoldSize ?? 'NA',
            rebateEligibility.annualHouseholdIncome
              ? `$${toCurrencyString(
                  Number(rebateEligibility.annualHouseholdIncome),
                  true,
                  true,
                )}`
              : 'NA',
          ],
        },
        {
          label: content.get('WIFI')!,
          values: [rebateEligibility.isHaveWifi ?? 'NA'],
        },
        {
          label: content.get('ESTIMATED_REBATES')!,
          values: [
            rebateEligibility.amount
              ? `$${toCurrencyString(
                  Number(rebateEligibility.amount),
                  true,
                  true,
                )}`
              : 'NA',
          ],
        },
      ],
    },
    {
      sectionTitle: content.get('CONFIRM_YOUR_CHARGER')!,
      editLink: selectCharger.suppliedByCustomer
        ? routes.PGE_PLUS_EV_CHARGERS_CONFIRM_YOUR_CHARGER
        : routes.PGE_PLUS_EV_CHARGERS_SELECT_CHARGER,
      items: [
        {
          label: content.get('CHARGER_SELECTION')!,
          values: [
            selectCharger.chargerId
              ? evChargerProductList.filter(
                  charger => charger.id === selectCharger.chargerId,
                )?.[0]?.entryName
              : '',
          ],
        },
      ],
    },
    {
      sectionTitle: content.get('REUSABLE_INSTALLATION')!,
      editLink: routes.PGE_PLUS_EV_CHARGERS_INSTALLATION,
      items: [
        {
          label: content.get('HOW_SOON_NEED_INSTALLATION')!,
          values: [installation.urgency],
        },
        {
          label: content.get('WHEN_YOU_NEED_YOUR_CHARGER_INSTALLED')!,
          values: [
            installation.dayOfWeek?.map(day => titleCase(day)).join(', '),
            installation.timeFrame?.map(time => TIMEFRAME[time]).join(', '),
          ],
        },
        {
          label: content.get('ELECTRICAL_PANEL_LOCATION_TITLE')!,
          values: [
            installation.panelLocation
              ? PANEL_LOCATION[installation.panelLocation]
              : '',
          ],
        },
        ...(installation.panelLocation === PanelLocation.Other
          ? [
              {
                label: contentInstallation.get('OTHER_COMMENTS_FIELD_LABEL')!,
                values: [installation.comment ? installation.comment : ''],
              },
            ]
          : []),
        ...(installation.panelLocation === PanelLocation.Basement ||
        installation.panelLocation === PanelLocation.Inside ||
        installation.panelLocation === PanelLocation.NotSure ||
        installation.panelLocation === PanelLocation.Other
          ? [
              {
                label: contentInstallation.get('CHARGER_IN_GARAGE_OR_OUTSIDE')!,
                values: [
                  installation.installLocation
                    ? CHARGER_INSTALL_LOCATION[installation.installLocation]
                    : '',
                ],
              },
            ]
          : []),
        ...(!installation.installerRecommendPanelDistance
          ? [
              {
                label: content.get('DISTANCE_FROM_GARAGE_TO_PANEL')!,
                values: [
                  installation.distanceToPanel
                    ? DISTANCE_TO_PANEL[installation.distanceToPanel]
                    : '',
                ],
              },
            ]
          : [
              {
                label: content.get('DISTANCE_FROM_GARAGE_TO_PANEL')!,
                values: [
                  contentInstallation.get('PREFER_INSTALLER_RECOMMENDATION'),
                ],
              },
            ]),
        ...((!installation.installerRecommendPanelDistance &&
          (installation.panelLocation === PanelLocation.GarageAttached ||
            installation.panelLocation === PanelLocation.Outside)) ||
        (installation.installLocation === ChargerInstallLocation.Garage &&
          !installation.installerRecommendPanelDistance &&
          (installation.panelLocation === PanelLocation.Basement ||
            installation.panelLocation === PanelLocation.Inside ||
            installation.panelLocation === PanelLocation.NotSure ||
            installation.panelLocation === PanelLocation.Other))
          ? [
              {
                label: content.get('PANEL_LOCATION')!,
                values: [
                  installation.panelSideLocation
                    ? PANEL_SIDE_LOCATION[installation.panelSideLocation]
                    : '',
                ],
              },
            ]
          : []),
      ],
    },
    {
      sectionTitle: content.get('UPLOAD_YOUR_PHOTOS')!,
      editLink: routes.PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS,
      items: [
        {
          label: content.get('PHOTOS_PROVIDED')!,
          values: [
            uploadPhotos.panelImage,
            uploadPhotos.amperageImage,
            uploadPhotos.panelLocationImage,
          ],
        },
        {
          label: contentUploadPhotos
            .get('YOUR_CHARGER_LOCATED_QUESTION')
            ?.slice(3)!,
          values: [
            uploadPhotos.extendedInstallDistance
              ? uploadPhotos.extendedInstallDistanceImage
              : contentUploadPhotos.get('REUSABLE_YES'),
          ],
        },
        {
          label: content.get('AMPS_ON_MAIN_BREAKER')!,
          values: [
            uploadPhotos.mainBreakerAmperage
              ? MAIN_BREAKER_AMPS[uploadPhotos.mainBreakerAmperage]
              : '',
          ],
        },
        {
          label: content.get('TWO_SLOTS_OPEN')!,
          values: [
            uploadPhotos.doubleBreakerAvailable
              ? DOUBLE_BREAKER_OPTIONS[uploadPhotos.doubleBreakerAvailable]
              : '',
          ],
        },
      ],
    },
  ];
};

export const Summary: FC<SummaryProps> = props => {
  const {
    formState,
    formState: { application, selectCharger, uploadPhotos },
    getSectionData,
  } = useEVChargerFormState();
  const { t } = useTranslation();
  const theme = useTheme();

  const sectionDataSummary: SectionEvSummary = getSectionData(
    ApplicationEvSectionType.EvSummary,
  );

  const { updateSectionEVSummary } = useUpdateEVSections();
  const { setErrorNotification } = useUtil();

  const [acceptTerms, setAcceptTerms] = useState<string[]>();
  const { content } = useContentMgmt(microcopyGroupIds.PGE_PLUS_SUMMARY);
  const { wrapWithLoader } = useWrapWithLoader();

  const onSubmit = wrapWithLoader(async () => {
    try {
      const response = await updateSectionEVSummary({
        applicationId: application?.id!,
        id: sectionDataSummary?.id,
        status: ApplicationSectionStatus.Completed,
        confirmed: true,
      });

      if (response.data?.updateSectionEVSummary.validation?.success) {
        //Navigate to the next screen
        void navigate(routes.PGE_PLUS_EV_CHARGERS_COST_ESTIMATION);
      } else {
        //TODO: Handle the validation error messages
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      await updateSectionEVSummary({
        applicationId: application?.id!,
        id: sectionDataSummary?.id,
        status: ApplicationSectionStatus.Completed,
        confirmed: true,
        saveAndComeBack: true,
      });

      void navigate(routes.ACCOUNT);
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const SUMMARY_DATA = getSummaryData(content);
  if (selectCharger.suppliedByCustomer) {
    SUMMARY_DATA.forEach(summary => {
      if (summary.sectionTitle === content.get('UPLOAD_YOUR_PHOTOS')) {
        summary.items.push({
          label: content.get('CHARGER_RECEIPT')!,
          values: [
            uploadPhotos.chargerCost?.toString() || '',
            uploadPhotos.receiptImage || '',
          ],
        });
      }
    });
  }

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        void onSubmit();
      }}
    >
      <Box>
        <Box>
          <Typography
            variant={'h2'}
            css={{
              fontSize: '24px',
              [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
                display: 'none',
              },
            }}
          >
            {content.get('EVERYTHING_LOOKS_CORRECT')}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={'body1'}
            css={{
              fontSize: 20,
              [theme.breakpoints.down('xs')]: {
                fontSize: 16,
              },
            }}
          >
            {content.get('REVIEW_INFO_TEXT')}
          </Typography>
        </Box>

        <Box
          mt={2.5}
          css={{
            marginBottom: 42,
          }}
        >
          <Paper css={{ padding: '30px 15px', backgroundColor: colors.white }}>
            {SUMMARY_DATA.map((summaryItem, idx) => (
              <Fragment>
                <SummarySection {...summaryItem} />

                {idx !== SUMMARY_DATA.length - 1 && (
                  <Divider
                    css={{
                      color: colors.gray93,
                      margin: theme.spacing(2, 0, 2, 0),
                    }}
                  />
                )}
              </Fragment>
            ))}
          </Paper>
        </Box>

        <ProceedOrCancelButton
          back={
            selectCharger.suppliedByCustomer
              ? routes.PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS_CHARGER_RECIEPT
              : routes.PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS
          }
        />

        <Box mt={6}>
          <SaveAndComeBackLink
            content={content}
            onSaveAndExit={handleSaveAndExit}
          />
        </Box>
      </Box>
    </form>
  );
};
