import gql from 'graphql-tag';

export const getAccountDetailsForReconnect = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      reconnectQuoteDetails {
        quoteStatus
        minimumDueOption {
          balance
          deposit
          reconnectFee
          totalDue
        }
        fullAmountOption {
          balance
          deposit
          reconnectFee
          totalDue
        }
        optionsValidThrough
      }
      serviceConnectivity {
        isEligibleForReconnect
        isDisconnected
      }
    }
  }
`;
