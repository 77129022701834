import React, { FunctionComponent, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import TextField from '../text-field';
import EmailTextField from '../email-text-field';
import PhoneTextField from '../phone-text-field';

import {
  ContactInfoFormProps,
  ContactInfoFormFields,
} from './ContactInfoForm.types';
import { nameOfFactory } from '../../util/form-validation';
import { parsePhoneNumber } from '../../util/format';

const nameOf = nameOfFactory<ContactInfoFormFields>();

const ContactInfoForm: FunctionComponent<ContactInfoFormProps> = props => {
  return (
    <form noValidate>
      <Grid container direction={'column'} spacing={2}>
        {props.company && (
          <Grid item>
            <TextField
              style={{ width: '100%' }}
              name={nameOf('company')}
              label={props.company?.label}
              inputProps={{ minLength: 1, maxLength: 80 }}
              value={props.company?.value}
              onChange={props.handleChange}
              error={!!props.company?.errorMessage}
              helperText={props.company?.errorMessage}
              onBlur={props.onBlur}
            />
          </Grid>
        )}
        <Grid item>
          <TextField
            style={{ width: '100%' }}
            name={nameOf('name')}
            label={props.name.label}
            inputProps={{ minLength: 1, maxLength: 80 }}
            value={props.name.value}
            onChange={props.handleChange}
            error={!!props.name.errorMessage}
            helperText={props.name.errorMessage}
            onBlur={props.onBlur}
          />
        </Grid>
        {props.title && (
          <Grid item>
            <TextField
              style={{ width: '100%' }}
              name={nameOf('title')}
              label={props.title.label}
              inputProps={{ minLength: 1, maxLength: 50 }}
              value={props.title.value}
              onChange={props.handleChange}
              error={!!props.title.errorMessage}
              helperText={props.title.errorMessage}
              onBlur={props.onBlur}
            />
          </Grid>
        )}
        <Grid item>
          <EmailTextField
            name={nameOf('email')}
            label={props.email.label}
            inputProps={{ minLength: 1, maxLength: 50 }}
            value={props.email.value}
            error={!!props.email.errorMessage}
            helperText={props.email.errorMessage}
            onChange={props.handleChange}
            onBlur={props.onBlur}
            margin={'none'}
          />
        </Grid>
        <Grid item>
          <PhoneTextField
            name={nameOf('phone')}
            label={props.phone.label}
            inputProps={{ minLength: 1, maxLength: 15 }}
            value={props.phone.value}
            onChange={props.handleChange}
            error={!!props.phone.errorMessage}
            helperText={props.phone.errorMessage}
            onBlur={(event: any) => {
              event.target.value = parsePhoneNumber(event.target.value);
              if (props.onBlur) {
                props.onBlur(event);
              }
            }}
            margin={'none'}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default ContactInfoForm;
