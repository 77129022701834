import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useLocation } from '@reach/router';

import ROUTES from '../../../routes';
import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';
import { featureFlagsContext } from '../../../providers/FeatureFlagsProvider';

import useAccountsStore from '../../../hooks/useAccountsStore';
import LeftNavMenu from '../../left-menu-navigation';
import { useAccountCustomerDashboard } from '../../../hooks/useAccountCustomerDashboard';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import useAuthQuery from '../../../hooks/useAuthQuery';
import { getRooftopSolarQuery } from '../../rooftop-solar/rooftopSolar.query';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    customTooltip: {
      backgroundColor: colors.sparkBlue,
    },
    customArrow: {
      color: colors.sparkBlue,
    },
    tooltipContent: {
      fontSize: '16px',
      fontWeight: 600,
      maxWidth: '220px',
      lineHeight: '20px',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
    },
    tooltipCloseButton: {
      fontSize: '30px',
      cursor: 'pointer',
      padding: '3px',
      minWidth: 'unset',
      color: colors.white,
      marginRight: '-12px',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        borderRadius: '50%',
      },
    },
  }),
);

export const useProductsAndProgramsMenu = (isRooftopSolar: boolean, t: any) => {
  return {
    labels: t('PRODUCTS_AND_PROGRAMS'),
    slug: null,
    menuOpen: true,
    items: [
      {
        labels: t('PTR_SENTENCE_CASE'),
        url: ROUTES.PEAKTIME_REBATES,
        menuOpen: false,
      },
      {
        labels: t('RENEWABLE_POWER'),
        url: ROUTES.RENEWABLE_POWER,
        menuOpen: false,
      },
      {
        labels: t('TOD_TITLE_CASE'),
        url: ROUTES.TIMEOFDAY,
        menuOpen: false,
      },
      {
        labels: t('SMART_THERMOSTATS'),
        url: ROUTES.PGE_MARKETPLACE_SMART_THERMOSTAT,
        menuOpen: false,
      },
      {
        labels: t('MEDICAL_CERTIFICATE_PROGRAM'),
        url: ROUTES.MEDICAL_CERTIFICATE,
        menuOpen: false,
      },
      ...(isRooftopSolar
        ? [
            {
              labels: t('ROOFTOP_NAV_TITLE'),
              url: ROUTES.ROOFTOP_SOLAR,
              menuOpen: false,
            },
          ]
        : []),
      {
        labels: t('INCOME_QUALIFIED_BILL_DISCOUNT'),
        url: ROUTES.IQBD_FORM,
        menuOpen: false,
      },
    ],
  };
};

interface MenuItem {
  label: string;
  url: string;
  onClick?: any;
}

type AccountLeftNavBlockType = {
  showSingle?: boolean;
};

const AccountLeftNavBlock = (props: AccountLeftNavBlockType) => {
  const { showSingle } = props;
  const { accountParams } = useSelectedAccountParams();
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const { showLegacyUI } = useAccountCustomerDashboard();
  const { isClosedAccountsEnabled, isPGEPlusEVEnabled } = useContext(
    featureFlagsContext,
  );
  const { customerAccountState } = useAccountsStore();

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const handleTooltipCloseButtonClick = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    typeof window !== 'undefined' &&
      window.localStorage.setItem('isInactiveAccountsTooltipClosed', '1');
    setShowTooltip(false);
  };
  const { data: solarData } = useAuthQuery(getRooftopSolarQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function,no-empty-function
    onError: () => {},
    skip: !accountParams,
  });

  const isRooftopSolar = Boolean(
    solarData?.getAccountDetails?.[0]?.premiseInfo?.[0]?.rooftopSolar
      ?.applicationDetails?.length,
  );

  useEffect(() => {
    typeof window !== 'undefined' &&
    window.localStorage.getItem('isInactiveAccountsTooltipClosed')
      ? setShowTooltip(false)
      : setShowTooltip(true);
  }, []);

  const accountLeftNavOptions = useMemo(() => {
    const menuItems = [
      {
        labels: t('MY_ACCOUNT'),
        menuOpen: true,
        items: [
          {
            labels: t('OUTAGE_MAP_AND_REPORTING'),
            url: ROUTES.OUTAGES,
            slug: null,
            menuOpen: false,
          },
          {
            labels: t('MY_ACCOUNT_DASHBOARD'),
            url: ROUTES.ACCOUNT,
            slug: null,
            menuOpen: false,
          },
          ...(!showLegacyUI
            ? [
                {
                  labels: t('MY_PROGRAMS'),
                  url: ROUTES.MY_PROGRAMS,
                  slug: null,
                  menuOpen: false,
                },
              ]
            : []),
          ...(!showLegacyUI
            ? [
                {
                  labels: t('ADDITIONAL_PROGRAMS_AND_OFFERING'),
                  url: ROUTES.ADDITIONAL_PROGRAMS_AND_OFFERING,
                  slug: null,
                  menuOpen: false,
                },
              ]
            : []),
          {
            labels: t('BILLING_AND_PAYMENT'),
            slug: null,
            menuOpen: true,
            items: [
              {
                labels: t('PAY_BILL'),
                url: ROUTES.PAYMENT,
                menuOpen: false,
              },
              {
                labels: t('VIEW_BILL_DETAILS'),
                url: ROUTES.VIEW_BILL,
                menuOpen: false,
              },
              {
                labels: t('BILLING_PAYMENT_HISTORY_SIDE_MENU'),
                url: ROUTES.PAYMENT_HISTORY,
                menuOpen: false,
              },
              {
                labels: t('NMTTP'),
                url: ROUTES.NEED_MORE_TIME_TO_PAY,
                menuOpen: false,
              },
              {
                labels: t('MULTI_PAY'),
                url: ROUTES.MULTI_PAY,
                menuOpen: false,
              },
              {
                labels: t('VIEW_AND_DOWNLOAD_BILLS'),
                url: ROUTES.DOWNLOAD_MULTIPLE_BILLS,
                menuOpen: false,
              },
              {
                labels: t('BILLING_OPTIONS_SIDE_MENU'),
                url: ROUTES.PAYMENT_OPTIONS,
                menuOpen: false,
              },
            ],
          },
          {
            labels: t('MANAGE_YOUR_SERVICE'),
            slug: null,
            menuOpen: true,
            items: [
              {
                labels: t('MY_ENERGY_USE'),
                url: ROUTES.MY_ENERGY_USE,
                slug: null,
                menuOpen: false,
              },
              {
                labels: t('MY_WAYS_TO_SAVE'),
                url: ROUTES.MY_WAYS_TO_SAVE,
                menuOpen: false,
              },
              {
                labels: t('START_STOP_SERVICE_SIDE_MENU'),
                url: ROUTES.START_STOP_MOVE,
                menuOpen: false,
              },
              {
                labels: t('GET_OUTAGE_UPDATES'),
                url: ROUTES.OUTAGES,
                menuOpen: false,
              },
              ...(isPGEPlusEVEnabled
                ? [
                    {
                      labels: t('PGE_PLUS'),
                      url: ROUTES.PGE_PLUS_GET_STARTED,
                      menuOpen: false,
                    },
                  ]
                : []),
              ...(!showLegacyUI
                ? [
                    {
                      labels: t('MY_PRICING_PLAN_OPTIONS'),
                      url: ROUTES.MY_PRICING_PLAN,
                      menuOpen: false,
                    },
                  ]
                : []),
            ],
          },
          useProductsAndProgramsMenu(isRooftopSolar, t),
          {
            labels: t('PROFILE_AND_PREFERENCES_SIDE_MENU'),
            slug: null,
            menuOpen: true,
            items: [
              {
                labels: t('MANAGE_USERS_AND_ALERTS_TITLE'),
                url: ROUTES.MANAGE_ALERTS,
                menuOpen: false,
              },
              {
                labels: t('MANAGE_ACCOUNTS_GROUPS'),
                url: ROUTES.MANAGE_ACCOUNTS_LANDING_SCREEN,
                menuOpen: false,
              },
              {
                labels: t('UPDATE_INFORMATION'),
                url: ROUTES.UPDATE_INFORMATION,
                menuOpen: false,
              },
              {
                labels: t('TWO_STEP_AUTHENTICATION'),
                url: ROUTES.TWO_STEP_AUTHENTICATION,
                menuOpen: false,
              },
              {
                labels: t('CHANGE_PASSWORD_BUTTON'),
                url: ROUTES.CHANGE_PASSWORD,
                menuOpen: false,
              },
              {
                labels: t('CHANGE_PIN'),
                url: ROUTES.CHANGE_PIN,
                menuOpen: false,
              },
              {
                labels: t('CHANGE_EMAIL'),
                url: ROUTES.CHANGE_EMAIL,
                menuOpen: false,
              },
              {
                labels: t('CANCEL_ONLINE_SERVICE'),
                url: ROUTES.CANCEL_ONLINE_SERVICE,
                menuOpen: false,
              },
            ],
          },
          ...(isClosedAccountsEnabled &&
          customerAccountState?.hasInactiveAccounts
            ? [
                {
                  labels: t('CLOSED_ACCOUNTS'),
                  slug: null,
                  menuOpen: true,
                  items: [
                    {
                      labels: t('VIEW_CLOSED_ACCOUNTS'),
                      url: ROUTES.CLOSED_ACCOUNTS,
                      menuOpen: false,
                    },
                  ],
                },
              ]
            : []),
        ],
      },
    ];
    return menuItems;
  }, [isClosedAccountsEnabled, showSingle, isRooftopSolar, showLegacyUI]);

  const selectedLinkRoute =
    location.pathname === ROUTES.ACCOUNT ? ROUTES.ACCOUNT : location.pathname;

  return (
    <LeftNavMenu
      leftNavMenuItems={accountLeftNavOptions}
      selectedItem={selectedLinkRoute}
    />
  );
};

export default AccountLeftNavBlock;
