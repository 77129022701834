import React, { useEffect } from 'react';
import {
  Paper,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { navigate } from '@reach/router';
import { useTranslation } from '../../hooks/useTranslation';
import PGEButton from '../buttons';
import ROUTES from '../../routes';

type Props = {
  path: string;
  setShowProgressBar: (arg0: boolean) => void;
  isMobile: boolean;
  isAuthenticated: boolean;
  accountNumber: string | undefined;
  encryptedPersonId: string | undefined;
};

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    '& h2': {
      fontFamily: theme.typography.h2.fontFamily,
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
    },
  },
}));

export const MoveServiceCustomerAssistance = ({
  setShowProgressBar,
  isMobile,
  isAuthenticated,
  accountNumber,
  encryptedPersonId,
}: Props) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    setShowProgressBar(false);

    return function cleanup() {
      setShowProgressBar(true);
    };
  }, []);

  return (
    <Paper>
      <Card>
        <CardContent>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <span className={classes.mainContent}>
                {richT('MOVE_SERVICE_ADDRESS_INELIGIBLE_ERROR')}
              </span>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justify={isMobile ? 'center' : 'flex-end'}
              spacing={1}
            >
              <Grid item xs={10} md="auto">
                <PGEButton
                  fullWidth
                  onClick={async () => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    navigate(isAuthenticated ? ROUTES.ACCOUNT : ROUTES.HOME);
                  }}
                >
                  {t('OK')}
                </PGEButton>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};
