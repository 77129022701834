import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import AccountTextField from '../../account-text-field';
import Button from '../../buttons';
import PhoneTextField from '../../phone-text-field';
import SampleBill from '../../sample-bill';
import TextField from '../../text-field';
import TextLink from '../../text-link';

const useStyles = makeStyles((theme: Theme) => ({
  submitButton: {
    paddingLeft: 25,
    paddingRight: 25,
    fontSize: 15,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      width: '100%',
    },
  },
  colorTextPrimary: {
    color: colors.noirBlur,
  },
  buttonRow: {
    marginTop: '2em',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
    },
  },
  buttonContainer: {
    display: 'flex',
    marginLeft: '4px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
      marginTop: '1em',
      width: '100%',
      justifyContent: 'center',
    },
  },
  button: {
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
  input: {
    width: '100%',
  },
}));

interface AddBusinessAccountProps {
  path: string;
}

const Divider = (props: { classes: { or: string }; label: string }) => (
  <Hidden mdDown>
    <Grid className={props.classes.or} item xs={'auto'}>
      <Typography variant={'subtitle2'}>{props.label}</Typography>
    </Grid>
  </Hidden>
);

export const AddBusinessAccount: FunctionComponent<
  AddBusinessAccountProps | any
> = props => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <Paper>
        <Card>
          <CardContent>
            <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
              <Grid item xs={12}>
                <Typography variant={'h2'}>
                  {t('Add business account')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <AccountTextField
                  data-testid={'create-rbusiness-acccount-number'}
                  name={'accountNumber'}
                  label={t('ACCOUNT_NUMBER')}
                  onChange={props?.handleChange}
                  onBlur={props?.onBlur}
                  autoComplete={t('ACCOUNT_NUMBER')}
                  type={'number'}
                  inputProps={{ minLength: 10, maxLength: 10, type: 'number' }}
                  error={!!props?.accountNumber?.errorMessage}
                  helperText={props?.accountNumber?.errorMessage}
                />
              </Grid>
              <Grid
                item
                xs={3}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <p style={{ marginLeft: 20 }}>
                  <SampleBill />
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name={'businessName'}
                  label={t('BUSINESS_NAME')}
                  style={{ width: '100%', marginTop: 20 }}
                  inputProps={{ minLength: 1, maxLength: 50 }}
                  onChange={props?.handleChange}
                  error={!!props?.businessName?.errorMessage}
                  helperText={props?.businessName?.errorMessage}
                  onBlur={props?.onBlur}
                />
              </Grid>
              <Typography
                className={classes.colorTextPrimary}
                component={'span'}
                color={'textPrimary'}
                variant={'body1'}
                style={{ padding: 5, marginTop: 15, marginBottom: 15 }}
              >
                {t('IDENTIFY_YOUR_BUSINESS_WITH_PHONE_OR_EIN')}
              </Typography>
              <RadioGroup
                aria-label={'create-residential-phone-number-account-number'}
                name={'verificationType'}
                value={props?.verificationType?.value}
                onChange={props?.handleChange}
              >
                <Grid
                  container
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    margin: '0',
                  }}
                >
                  <Grid item xs={1} md={1}>
                    <FormControlLabel
                      value={'ein'}
                      control={<Radio color={'primary'} />}
                      label={null}
                    />
                  </Grid>
                  <Grid item xs={11} md={4}>
                    <TextField
                      data-testid={'validate-ein-number'}
                      name={'ein'}
                      label={t('EIN_NUMBER')}
                      onChange={props?.handleChange}
                      onBlur={props?.onBlur}
                      className={classes.input}
                      inputProps={{ minLength: 10, maxLength: 10 }}
                      disabled={props?.verificationType?.value !== 'ein'}
                      error={
                        !!props?.ein?.errorMessage &&
                        props?.verificationType?.value === 'ein'
                      }
                      helperText={
                        props?.verificationType?.value === 'ein' &&
                        props?.ein?.errorMessage
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={1} style={{ textAlign: 'center' }}>
                    <Typography variant={'h3'}>{t('OR')}</Typography>
                  </Grid>

                  <Grid item xs={1} md={1}>
                    <FormControlLabel
                      value={'phoneNumber'}
                      control={<Radio color={'primary'} />}
                      label={null}
                    />
                  </Grid>
                  <Grid item xs={11} md={4}>
                    <PhoneTextField
                      data-testid={'validate-phone-number'}
                      name={'phoneNumber'}
                      label={t('PHONE_NUMBER')}
                      onChange={props?.handleChange}
                      autoComplete={'telephone'}
                      className={classes.input}
                      onBlur={props.onBlur}
                      inputProps={{
                        minLength: 10,
                        maxLength: 10,
                        type: 'telephone',
                      }}
                      disabled={props.verificationType?.value !== 'phoneNumber'}
                      error={
                        !!props.phoneNumber?.errorMessage &&
                        props?.verificationType?.value === 'phoneNumber'
                      }
                      helperText={
                        props.verificationType?.value === 'phoneNumber' &&
                        props?.phoneNumber?.errorMessage
                      }
                    />
                  </Grid>
                  <Grid item xs={12} style={{ margin: '1em 0' }}>
                    <Typography variant={'body1'}>
                      {t(
                        'Your account will be named based on the business name in our system. A nickname can help you differentiate this account from others you have access to. You can add this later.',
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      data-testid={'validate-nickname'}
                      name={'ein'}
                      label={t('Nickname (optional)')}
                      onChange={props?.handleChange}
                      onBlur={props?.onBlur}
                      className={classes.input}
                      inputProps={{ minLength: 10, maxLength: 10 }}
                      disabled={props.verificationType.value !== 'ein'}
                      error={!!props.nickname.errorMessage}
                      helperText={props.nickname.errorMessage}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.buttonRow}>
                    <Grid item>
                      <TextLink plain to={ROUTES.MANAGE_ACCOUNTS}>
                        {t('Back')}
                      </TextLink>
                    </Grid>
                    <Grid item className={classes.buttonContainer}>
                      <Button
                        onClick={props.handleSubmit}
                        disabled={props.isButtonActionDisabled}
                        size={'large'}
                        className={classes.button}
                      >
                        {t('Add')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </RadioGroup>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </>
  );
};

export default AddBusinessAccount;
