import { useState, useEffect } from 'react';
import gql from 'not-graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  MoveToServiceAddressEligibilityRequest,
  MoveToServiceAddressEligibilityResponse,
} from '../../../__generated__/pge-types';

const USE_MOVE_TO_SERVICE_ADDRESS_ELIGIBILITY = gql`
  query moveToServiceAddressEligibility(
    $payload: MoveToServiceAddressEligibilityRequest
  ) {
    moveToServiceAddressEligibility(payload: $payload) {
      isEligible
      isAddressExactMatch
      isPeakTimeRebateEligible
      premiseId
      serviceAddressEligibilityType
    }
  }
`;

interface Response {
  moveToServiceAddressEligibility: MoveToServiceAddressEligibilityResponse;
}

interface Request {
  payload: MoveToServiceAddressEligibilityRequest;
}

export const useMoveToServiceAddressEligibility = () => {
  const [
    params,
    setParams,
  ] = useState<MoveToServiceAddressEligibilityRequest | null>(null);
  const [
    getMoveToServiceAddressEligibility,
    { data, loading, error },
  ] = useLazyQuery<Response, Request>(USE_MOVE_TO_SERVICE_ADDRESS_ELIGIBILITY);

  useEffect(() => {
    if (params) {
      getMoveToServiceAddressEligibility({
        variables: { payload: params },
      });
    }
  }, [params]);

  return { data, loading, params, setParams, error };
};
