import { useContext } from 'react';
import { ContentfulSmartCard } from '../../__generated__/gatsby-types';
import { LanguageContext } from '../../providers/LanguageProvider';
import NextBestOfferDetails from './nextBestOffer.query';

export default () => {
  const { language } = useContext(LanguageContext);
  const { allContentfulSmartCardSection } = NextBestOfferDetails();

  const {
    cardGrouping,
    cardGroupingDisplayType,
  } = allContentfulSmartCardSection.nodes.filter(
    node => node.node_locale === language,
  )[0];

  return {
    contentfulSectionCard: allContentfulSmartCardSection.nodes[0],
    cardGroupingDisplayType,
    cardGrouping: cardGrouping as ContentfulSmartCard[],
  };
};
