import React, { FC, useState } from 'react';
import Page2ColumnLayout from '../../components/utility/page2-column-layout';
import MainArea from '../../components/utility/main-area';
import { useTranslation } from '../../hooks/useTranslation';
import ViewBillLeftPane from '../../components/view-bill/view-bill-left-pane';
import Helmet from 'react-helmet';
import NeedMoreTimeToPayForm from '../../components/need-more-time-to-pay';

const NeedMoreTimeToPayPage: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('NMTTP')}</title>
      </Helmet>
      <Page2ColumnLayout leftColumn={<ViewBillLeftPane />}>
        <MainArea>
          <NeedMoreTimeToPayForm />
        </MainArea>
      </Page2ColumnLayout>
    </>
  );
};

export default NeedMoreTimeToPayPage;
