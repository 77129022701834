/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Divider, FormHelperText, useTheme } from '@material-ui/core';
import { RouteComponentProps, useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import useFormState from '../../../../../hooks/useFormState';
import useWrapWithLoader from '../../../../../hooks/useWrapWithLoading';
import routes from '../../../../../routes';
import RadioButtonGroup from '../../../../pge-plus-common/RadioButtonGroup';
import { ProceedOrCancelButton } from '../../../../pge-plus-common/StepperForm';
import { Question } from '../../../../pge-plus-common/Typography';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import {
  EXISTING_REBATE_TYPE,
  erVerifyRebateValidateFunction,
} from '../rebateOnlyForm.rules';
import {
  ERVerifyRebate,
  UpdateSectionErrorCode,
  VerifyRebateEnrollmentConfirmation,
} from '../rebateOnlyForm.types';
import WelcomeToPGEPlusModal from '../../../../pge-plus-common/WelcomeToPGEPlusModal';
import { useRebateOnlyFormState } from '../../../../../providers/RebateOnlyFormStateProvider';
import {
  ApplicationErSectionType,
  SectionErRebateInfo,
} from '../../../../../__generated__/pgeplus-types';
import useUtil from '../../../../../hooks/pge-plus/useUtil';
import useUpdateERSections from '../../../../../hooks/pge-plus/useUpdateERSections';
import { rebateInfoERFormStateAdapter } from '../rebateOnlyFormState.adapter';
import { SaveAndComeBackLink } from '../../../../pge-plus-common/SaveAndComeBackLink';

const erVerifyInfo: ERVerifyRebate = {};

const VerifyChargerRebate: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const location = useLocation();
  const theme = useTheme();
  const { wrapWithLoader } = useWrapWithLoader();

  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_VERIFY_REBATE,
  );
  const { identifier } = (location.state as { identifier: string }) || {};
  const {
    formState: { application, rebateInfo },
    getSectionData,
    updateFormState,
  } = useRebateOnlyFormState();

  const form = useFormState(
    rebateInfo.smartChargingEnrollmentConfirmation || {},
    {
      validate: erVerifyRebateValidateFunction(),
      validationContext: {
        content,
        params: {
          rebateType: EXISTING_REBATE_TYPE.SMART_CHARGING,
        },
      },
    },
  );

  const sectionDataRebateInfo: SectionErRebateInfo = getSectionData(
    ApplicationErSectionType.ErRebateInfo,
  );
  const { setErrorNotification } = useUtil();
  const { updateSectionRebateInfo } = useUpdateERSections();

  const onSubmit = wrapWithLoader(
    async (data: VerifyRebateEnrollmentConfirmation) => {
      try {
        const response = await updateSectionRebateInfo(
          rebateInfoERFormStateAdapter().fromFormState(
            {
              ...rebateInfo,
              smartChargingEnrollmentConfirmation: data,
            },
            application?.id!,
            sectionDataRebateInfo?.id,
          ),
        );

        if (
          response?.errors ||
          response.data?.updateSectionERRebateInfo?.validation?.errors
        ) {
          throw new Error();
        }

        if (response.data?.updateSectionERRebateInfo?.section.errors.length) {
          const notANewCharger =
            response.data?.updateSectionERRebateInfo?.section.errors.filter(
              error => error.code === UpdateSectionErrorCode.NotANewCharger,
            ).length > 0;
          if (notANewCharger) {
            void navigate(routes.PGE_PLUS_REBATE_ONLY_INELIGIBLE, {
              state: {
                message: 'REBATE_ONLY_SMART_CHARGING_INELIGIBLE',
              },
            });
          }
        }

        if (
          response.data?.updateSectionERRebateInfo.section.errors.length === 0
        ) {
          //Store the data in global store
          const rebateInfoSection =
            response.data?.updateSectionERRebateInfo?.section;
          if (rebateInfoSection?.data) {
            const updatedValues = rebateInfoERFormStateAdapter().toFormState(
              rebateInfoSection.data,
            );
            updateFormState('rebateInfo', {
              ...updatedValues,
            });

            await navigate(routes.PGE_PLUS_REBATE_ONLY_UPLOAD_MODEL_NUMBER);
            return;
          }
        }
      } catch (e) {
        setErrorNotification(true);
      }
    },
  );

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      const response = await updateSectionRebateInfo({
        ...rebateInfoERFormStateAdapter().fromFormState(
          {
            ...rebateInfo,
            smartChargingEnrollmentConfirmation: form.values,
          },
          application?.id!,
          sectionDataRebateInfo?.id,
        ),
        saveAndComeBack: true,
      });
      if (
        response?.errors ||
        response.data?.updateSectionERRebateInfo?.validation?.errors ||
        response.data?.updateSectionERRebateInfo?.section?.errors.length !== 0
      ) {
        throw new Error();
      } else {
        void navigate(routes.ACCOUNT);
      }
    } catch (e) {
      console.log('err', e);
      setErrorNotification(true);
    }
  });

  return (
    <form onSubmit={form.submit(values => onSubmit(values))} noValidate>
      <Question detail={content.get('REBATE_ONLY_VERIFY_REBATES_DETAILS')} />
      <Question
        title={content.get('YOUR_ENROLLED_CHARGER_1')}
        detail={
          sectionDataRebateInfo?.data.rebateInfo.existingEnrollments
            .smartCharging &&
          sectionDataRebateInfo?.data.rebateInfo.existingEnrollments
            .smartCharging.length > 0
            ? sectionDataRebateInfo?.data.rebateInfo.existingEnrollments
                .smartCharging[0].serialNo
            : ''
        }
      />
      <Box
        css={{
          marginBottom: '16px',
        }}
      >
        <Question
          title={content.get(
            'REBATE_ONLY_IS_CHARGER_DIFFERENT_HEADER_CHARGER_REBATE',
          )}
          popoverLinkContent={content.get('REUSABLE_LEARN_MORE')}
          popoverContent={content.get(
            'REBATE_ONLY_SMART_CHARGING_REBATE_DETAILS',
          )}
        />
      </Box>
      <Box
        css={{ width: 'max-content', marginLeft: 'auto', marginRight: 'auto' }}
      >
        <RadioButtonGroup
          options={[
            {
              label: content.get('REUSABLE_YES') || '',
              value: true,
            },
            {
              label: content.get('REUSABLE_NO') || '',
              value: false,
            },
          ]}
          state={form.values.willEnrollNewSmartChargingDevice}
          setState={e => form.setValue('willEnrollNewSmartChargingDevice', e)}
        />
        {form.errors.willEnrollNewSmartChargingDevice && (
          <FormHelperText error>
            {form.errors.willEnrollNewSmartChargingDevice}
          </FormHelperText>
        )}
      </Box>
      <Divider css={{ margin: theme.spacing(3, 0) }} />
      <ProceedOrCancelButton
        back={
          sectionDataRebateInfo?.data.mustVerifyResidentialMeter
            ? routes.PGE_PLUS_REBATE_ONLY_CONFIRM_YOUR_INFORMATION
            : routes.PGE_PLUS_REBATE_ONLY_HOME
        }
        backLabel={content.get('BACK')}
        disable={form.values.willEnrollNewSmartChargingDevice === undefined}
      />
      <Box mt={6}>
        <SaveAndComeBackLink
          content={content}
          onSaveAndExit={handleSaveAndExit}
        />
      </Box>
      <Box>
        {!rebateInfo.smartChargingEnrollmentConfirmation
          ?.willEnrollNewSmartChargingDevice && <WelcomeToPGEPlusModal />}
      </Box>
    </form>
  );
};

export default VerifyChargerRebate;
