import { useMutation } from '@apollo/react-hooks';
import gql from 'not-graphql-tag';
import {
  applicationEVFragment,
  applicationFragment,
} from '../../components/pge-plus-common/queries/fragments';
import { SubmitApplicationEvOutput } from '../../__generated__/pgeplus-types';
import useUtil from './useUtil';

export const submitApplicationMutation = gql`
  mutation SubmitApplicationEV($input: SubmitApplicationEVInput!) {
    submitApplicationEV(input: $input) {
      ineligibilityReasons {
        message
        reference {
          number
          category
        }
        code
        details
      }
      application {
        ...applicationFragment
        ...applicationEVFragment
      }
    }
  }

  ${applicationFragment}
  ${applicationEVFragment}
`;

export const useSubmitApplicationMutation = () => {
  const { setErrorNotification } = useUtil();
  const [submitApplicationEV, { data, loading, error }] = useMutation<{
    submitApplicationEV: SubmitApplicationEvOutput;
  }>(submitApplicationMutation, {
    context: {
      pgePlus: true,
    },
    onError: () => setErrorNotification(true),
  });

  return {
    submitApplicationEV,
    data,
    loading,
    error,
  };
};
