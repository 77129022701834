import { useTranslation } from '../../../hooks/useTranslation';
import { BaseValidationContext } from '../../../hooks/useFormState.types';
const { t } = useTranslation();

export type Model = {
  isGreenSource: boolean;
  isCleanWind: boolean;
  blocks: number;
  isSolarSource: boolean;
  isHabitatSupport: boolean;
  includeInPromotion: boolean;
  promoCode: string;
  selectedAnswer: string;
  companyName: string;
};

const validateCompanyName = (values: Model) => {
  const { companyName, includeInPromotion } = values;
  if (!includeInPromotion) {
    return null;
  }
  const COMPANY_NAME_REGEX = /[<>,^]/g;
  return !COMPANY_NAME_REGEX.test(companyName) &&
    companyName &&
    companyName.length > 0
    ? null
    : t('RENEWABLE_CONFIG_COMPANY_INVALID_COMPANY_ERROR');
};

export const validatePromoCode = (values: Model) => {
  const { promoCode } = values;
  if (!promoCode) {
    return null;
  }
  const PROMO_CODE_REGEX = /[<>,^]/g;
  return !PROMO_CODE_REGEX.test(promoCode) && promoCode.length > 0
    ? null
    : t('RENEWABLE_POWER_PROGRAM_PROMO_CODE_ERROR');
};

export const validateBlocks = (values: Model) => {
  const { blocks, isCleanWind } = values;
  if (!isCleanWind) {
    return null;
  }
  return blocks <= 0 ? t('RENEWABLE_POWER_BLOCKS_ERROR') : null;
};

export const validationRules = (
  values: Model,
  context?: BaseValidationContext<Model>,
) => {
  return {
    promoCode: validatePromoCode(values),
    blocks: validateBlocks(values),
    companyName: validateCompanyName(values),
  };
};
