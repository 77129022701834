import React, { useEffect, useContext } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '../../hooks/useTranslation';
import AccountDropdown from '../account-dropdown';
import { navigate, Router } from '@reach/router';
import ROUTES from '../../routes';
import ReconnectServiceStart from './reconnect-service-start/ReconnectServiceStart';
import ReconnectServicePayment from './reconnect-service-payment';
import ReconnectServicePaymentConfirmation from './reconnect-service-payment-confirmation';
import useOneTimePayment from '../../hooks/useOneTimePayment';
import Backdrop from '../../components/backdrop';
import useReconnectService from './useReconnectService';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import { getAccountDetailsForReconnect } from './queries';
import useAuthQuery from '../../hooks/useAuthQuery';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import { Grid } from '@material-ui/core';
import { DownloadAndPrint } from '../download-print/DownloadAndPrint';

const ReconnectService = (props: { path?: string }) => {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationsContext);

  const {
    accountLoading,
    accountDetails,
    customer,
    savedProfileList,
    profileListLoading,
  } = useOneTimePayment();

  const {
    handleOnPaymentConfirmation,
    oneTimePayConfirmationDataDetails,
    oneTimePayConfigDataDetails,
    paymentMethodCapLimitData,
  } = useReconnectService();

  const { encryptedAccountNumber, accountParams } = useSelectedAccountParams();

  const setErrorNotification = (isError: boolean) => {
    notificationContext.setState({
      isOpen: isError,
      message: isError
        ? t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY')
        : undefined,
      severity: isError ? 'error' : undefined,
    });
    isError && typeof window !== 'undefined' && window.scrollTo(0, 0);
  };

  const {
    loading: reconnectQuoteLoading,
    data: quotedata,
    error: reconnectQuoteError,
  } = useAuthQuery(getAccountDetailsForReconnect, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    errorPolicy: 'all',
    skip: !encryptedAccountNumber,
  });

  const reconnectPaymentOption =
    quotedata?.getAccountDetails?.[0].reconnectQuoteDetails;

  useEffect(() => {
    const isDisconnected =
      quotedata?.getAccountDetails?.[0].serviceConnectivity?.isDisconnected;
    const isEligibleForReconnect =
      quotedata?.getAccountDetails?.[0].serviceConnectivity
        ?.isEligibleForReconnect;

    if (!isDisconnected && !isEligibleForReconnect) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.ACCOUNT, { replace: true });
      return;
    } else {
      if (!quotedata?.getAccountDetails?.[0].reconnectQuoteDetails) {
        setErrorNotification(true);
      } else {
        setErrorNotification(false);
      }
    }
  }, [quotedata?.getAccountDetails?.[0]]);

  useEffect(() => {
    (() => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.RECONNECT_SERVICE_START);
    })();
  }, [accountParams?.accountNumber]);

  useEffect(() => {
    if (reconnectQuoteError) {
      setErrorNotification(true);
    } else {
      setErrorNotification(false);
    }
  }, [reconnectQuoteError]);
  if (
    accountDetails === undefined ||
    accountLoading ||
    customer === undefined ||
    savedProfileList === undefined ||
    profileListLoading
  ) {
    return <Backdrop forceOpen />;
  }
  const PRINT_CONTAINER_ID = 'reconnect-payment-print-receipt';
  return (
    <>
    <Grid container id={PRINT_CONTAINER_ID}>
      {reconnectQuoteLoading && <Backdrop forceOpen />}
        <Grid item>
        <Typography variant={'h1'} noWrap>
          {t('RECONNECT_SERVICE')}
        </Typography>
        </Grid>
      <Grid item xs={12}>
      <Box mt={2} mb={1}>
        <AccountDropdown showSelectedAccountOnly={true} />
      </Box>
      </Grid>
      {!reconnectQuoteLoading && reconnectPaymentOption && (
        <Router basepath="/">
          <ReconnectServiceStart
            reconnectPaymentOption={reconnectPaymentOption}
            path={ROUTES.RECONNECT_SERVICE_START}
          />
          <ReconnectServicePayment
            path={ROUTES.RECONNECT_SERVICE_PAYMENT}
            handleOnPaymentConfirmation={handleOnPaymentConfirmation}
            minimumAmount={String(
              Number(
                reconnectPaymentOption?.minimumDueOption?.totalDue,
              ).toFixed(2),
            )}
            fullAmount={String(
              Number(
                reconnectPaymentOption?.fullAmountOption?.totalDue,
              ).toFixed(2),
            )}
          />
          <ReconnectServicePaymentConfirmation
            path={ROUTES.RECONNECT_SERVICE_PAYMENT_CONFIRMATION}
            oneTimePayConfirmationData={oneTimePayConfirmationDataDetails!}
            oneTimePayConfig={oneTimePayConfigDataDetails!}
            paymentMethodCapLimit={paymentMethodCapLimitData}
            isForReconnect={true}
            minimumAmount={String(
              Number(
                reconnectPaymentOption?.minimumDueOption?.totalDue,
              ).toFixed(2),
            )}
            fullAmount={String(
              Number(
                reconnectPaymentOption?.fullAmountOption?.totalDue,
              ).toFixed(2),
            )}
            selectedAccountNumber={accountParams?.accountNumber!}
            printContainerId={PRINT_CONTAINER_ID}
          />
        </Router>
      )}
      </Grid>
    </>
  );
};

export default ReconnectService;
