import { useApolloClient } from '@apollo/react-hooks';
import { updatePaperlessBillMutation } from '../../queries/paperlessBilling.query';
import {
  UpdatePaperlessBillRequest,
  UpdatePaperlessBillResponse,
} from '../../__generated__/pge-types';

export function usePaperlessBillForm() {
  const apolloClient = useApolloClient();

  async function submitPaperless(
    encryptedPersonId: string,
    encryptedAccountNumber: string,
    isPaperless: boolean,
    email: string,
  ) {
    const payload: UpdatePaperlessBillRequest = {
      encryptedAccountNumber: encryptedAccountNumber,
      isPaperless: isPaperless,
      email: email,
      encryptedPersonId: encryptedPersonId,
    };

    const { data, errors } = await apolloClient.mutate<
      {
        updatePaperlessBill: UpdatePaperlessBillResponse;
      },
      {
        payload: UpdatePaperlessBillRequest;
      }
    >({
      mutation: updatePaperlessBillMutation,
      variables: { payload },
      errorPolicy: 'all',
    });

    if (
      data?.updatePaperlessBill.success !== true ||
      !data ||
      (errors && errors.length > 0)
    ) {
      return Promise.reject('Could not submit paperless enrollment');
    }

    return data?.updatePaperlessBill;
  }

  return {
    submitPaperless,
  };
}
