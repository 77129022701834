import React from 'react';
import { TimeOfDaySummaryInfo } from '../../../__generated__/pge-types';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { months } from '../TODConstants';
import { useTranslation } from '../../../hooks/useTranslation';
import useStyles from '../styles';
interface Props {
  timeOfDaySummaryInfo?: TimeOfDaySummaryInfo;
}

export const TimeOfDaySavingsChart = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { timeOfDaySummaryInfo } = props;
  const area1Props =
    timeOfDaySummaryInfo?.deltaBasicToTodAmount &&
    timeOfDaySummaryInfo.deltaBasicToTodAmount >= 0
      ? {
          name: t('TOD_TITLE_CASE'),
          dataKey: 'todBillAmount',
          stroke: '#00AED0',
        }
      : {
          name: t('BASIC_SERVICE'),
          dataKey: 'basicBillAmount',
          stroke: '#E86A5E',
        };

  const area2Props =
    timeOfDaySummaryInfo?.deltaBasicToTodAmount &&
    timeOfDaySummaryInfo.deltaBasicToTodAmount >= 0
      ? {
          name: t('BASIC_SERVICE'),
          dataKey: 'basicBillAmount',
          stroke: '#E86A5E',
        }
      : {
          name: t('TOD_TITLE_CASE'),
          dataKey: 'todBillAmount',
          stroke: '#00AED0',
        };

  const getISODate = (date = '') => {
    return new Date(date);
  };

  const summaryDetails =
    (timeOfDaySummaryInfo?.timeofDaySummaryDetails &&
      timeOfDaySummaryInfo?.timeofDaySummaryDetails
        .map(summaryDetail => {
          return {
            unixTimestamp: getISODate(summaryDetail?.billDate || '').getTime(),
            dateMonth: `${
              months[getISODate(summaryDetail?.billDate || '').getMonth()]
            }-${getISODate(summaryDetail?.billDate || '').getDate()}`,
            ...summaryDetail,
          };
        })
        .sort((a, b) => a.unixTimestamp - b.unixTimestamp)) ||
    [];

  summaryDetails.splice(0, summaryDetails.length - 12);

  return (
    <ResponsiveContainer width="100%" height={200}>
      <AreaChart
        data={summaryDetails}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="dateMonth" />
        <YAxis
          tickFormatter={tick => `$${tick}`}
          domain={[
            dataMin => Math.floor(dataMin / 20) * 20,
            dataMax => Math.ceil(dataMax / 20) * 20,
          ]}
        />
        <Tooltip
          content={({ active, payload, label }: any) => {
            if (active && payload) {
              return (
                <div className={classes.tooltip}>
                  <div>{label}</div>
                  <div
                    style={{
                      color:
                        payload[0]?.dataKey === 'basicBillAmount'
                          ? '#E86A5E'
                          : '#00AED0',
                    }}
                  >
                    {payload[0]?.name}: ${Number(payload[0]?.value).toFixed(2)}
                  </div>
                  <div
                    style={{
                      color:
                        payload[1]?.dataKey === 'todBillAmount'
                          ? '#00AED0'
                          : '#E86A5E',
                    }}
                  >
                    {payload[1]?.name}: ${Number(payload[1]?.value).toFixed(2)}
                  </div>
                </div>
              );
            }
            return <></>;
          }}
        />
        <Legend
          align="left"
          verticalAlign="top"
          height={45}
          wrapperStyle={{ marginLeft: '5%' }}
        />
        <Area
          legendType="square"
          type="monotone"
          fillOpacity="0"
          strokeWidth={3}
          {...area1Props}
        />
        <Area
          legendType="square"
          type="monotone"
          fillOpacity="0"
          strokeWidth={3}
          {...area2Props}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default TimeOfDaySavingsChart;
