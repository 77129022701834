import React from 'react';
import {
  Paper,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useTranslation } from '../../hooks/useTranslation';
import PGEButton from '../buttons';
import { navigate } from '@reach/router';
import ROUTES from '../../routes';
import { useIsMobile } from '../../util/style-utils';

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    '& h2': {
      fontFamily: theme.typography.h2.fontFamily,
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
    },
  },
}));

export default function StopServiceIneligible(_: { path?: string }) {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <Paper>
      <Card>
        <CardContent>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <span className={classes.mainContent}>
                {richT('STOP_SERVICE_INELIGIBLE_ERROR')}
              </span>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justify={isMobile ? 'center' : 'flex-end'}
              spacing={1}
            >
              <Grid item xs={10} md="auto">
                <PGEButton fullWidth onClick={() => navigate(ROUTES.ACCOUNT)}>
                  {t('OK')}
                </PGEButton>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
}
