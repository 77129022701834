import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '../buttons';
import PhoneTextField from '../phone-text-field';
import { auth } from '../../firebase';
import { verifyPhoneNumber } from './firebase.helper';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import { useRecaptcha } from '../../hooks/useRecaptcha';
import { useTranslation } from '../../hooks/useTranslation';
import { FirebaseError } from 'firebase/app';
import { AuthErrorCodes } from 'firebase/auth';
import { useLocation } from '@reach/router';
import useAuth from '../../hooks/useAuth';
import routes from '../../routes';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  mobilePhone: string;
  onNext: (id: string, phone: string) => void;
};

type LocationState = {
  forceLogin: boolean;
};

const PhoneDetails = ({ mobilePhone, onNext }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const [phone, setPhone] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const { signOut } = useAuth();

  const recaptcha = useRecaptcha('two-step-enrollment');
  const notificationContext = useContext(NotificationsContext);

  const locationState = (location.state || {}) as LocationState;

  useEffect(() => {
    if (mobilePhone) {
      setPhone(mobilePhone);
    }
  }, [mobilePhone]);

  useEffect(() => {
    //We will force users to login if the come directly from registration page due to UNSUPPORTED_FIRST_FACTOR error from firebase.
    if (locationState.forceLogin === true) {
      void signOut(routes.TWO_STEP_AUTHENTICATION);
    }
  }, [locationState]);

  const sendVerificationCode = async () => {
    if (phone) {
      if (!auth.currentUser || !recaptcha) {
        showNotification(t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'), 'error');
        return false;
      }
      setIsDisabled(true);
      try {
        const verificationId = await verifyPhoneNumber(
          auth.currentUser,
          '+1' + phone,
          recaptcha,
        );

        if (!verificationId) {
          setIsDisabled(false);
          showNotification(
            t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
            'error',
          );
        } else {
          //showNotification(t('TWO_STEP_AUTH_OTP_SENT_TO_PHONE'));
          onNext(verificationId, '+1' + phone);
        }
      } catch (e) {
        setIsDisabled(false);
        if (
          (e as FirebaseError)?.code ===
          AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN
        ) {
          showNotification(
            t('TWO_STEP_AUTH_CREDENTIAL_TOO_OLD_LOGIN_AGAIN_ERROR'),
            'error',
          );
        } else if (
          (e as FirebaseError)?.code === AuthErrorCodes.UNSUPPORTED_FIRST_FACTOR
        ) {
          showNotification(
            t('TWO_STEP_AUTH_CREDENTIAL_TOO_OLD_LOGIN_AGAIN_ERROR'),
            'error',
          );
        } else {
          showNotification(
            t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
            'error',
          );
        }
      }
    }
  };

  const showNotification = (
    message: string,
    type: 'error' | 'success' = 'success',
  ) => {
    notificationContext.setState({
      isOpen: true,
      message: message,
      severity: type,
    });
    window.scrollTo(0, 0);
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Typography variant={'body1'}>
          {t('TWO_STEP_AUTH_ENTER_PHONE_NUMBER_INFO')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <PhoneTextField
          name="primaryPhone"
          data-testid="primaryPhone"
          label={t('PHONE_NUMBER')}
          value={phone}
          style={{ width: 250 }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPhone(e.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Button
          className={classes.button}
          variant={'outlined'}
          color={'secondary'}
          onClick={sendVerificationCode}
          disabled={phone.length !== 10 || isDisabled}
        >
          {t('TWO_STEP_AUTH_SEND_VERIFICATION_CODE')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default PhoneDetails;
