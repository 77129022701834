import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { AlertOptions, AlertState } from './types';
import { useStyles } from './styles';
import { useTranslation } from '../../../hooks/useTranslation';
import {
  ClickAwayListener,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import colors from '../../../themes/main-colors';
import ROUTES from '../../../routes';
import { useIsMobile } from '../../../util/style-utils';
import { NotificationType } from '../../../__generated__/pge-types';
import { Link } from '@reach/router';
import ExternalLinkIcon from '../../../static/icon_external_link.svg';

interface ToggleButtonProps {
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
}

export const ToggleButton = ({
  disabled = false,
  value,
  onChange,
}: ToggleButtonProps) => {
  const classes = useStyles();
  const toggle = disabled
    ? undefined
    : () => {
        onChange(!value);
      };

  return (
    <>
      {!value ? (
        <IconButton
          className={classes.iconText}
          color={disabled ? undefined : 'primary'}
          onClick={toggle}
          key={String(value)}
          disabled={disabled}
        >
          <AddCircleIcon
            data-testid="testing-add-circle-icon"
            fontSize="large"
          />
        </IconButton>
      ) : (
        <IconButton
          className={classes.iconText}
          style={disabled ? undefined : { color: '#43936F' }}
          onClick={toggle}
          key={String(value)}
          disabled={disabled}
        >
          <CheckCircleIcon
            data-testid="testing-check-circle-icon"
            fontSize="large"
          />
        </IconButton>
      )}
    </>
  );
};

type Props = {
  children: React.ReactNode;
  value: AlertState;
  onChange: (value: AlertState, kind: 'email' | 'text') => void;
  tooltipValue: string | React.ReactNode;
  hideTextSection: boolean;
  hideEmailSection: boolean;
  isMultiSelect?: boolean;
  name?: string;
  handleNavigate: (route: string) => void;
} & AlertOptions & { label: React.ReactNode };

export function EmailTextToggleControl({
  emailDisabled = false,
  textDisabled = false,
  value,
  onChange,
  children,
  tooltipValue,
  hideTextSection,
  hideEmailSection,
  isMultiSelect,
  handleNavigate,
  name,
}: Props) {
  const classes = useStyles();
  const useTooltipStyles = makeStyles((theme: Theme) =>
    createStyles({
      tooltip: {
        backgroundColor: colors.lightYellow,
        color: colors.black,
        maxWidth: 'auto',
        height: 'auto',
        fontSize: theme.typography.pxToRem(14),
        border: '1px solid #dadde9',
        padding: '1rem',
        fontFamily: 'Untitled-Sans',
      },
    }),
  );
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const [isOpen, toggleOpen] = useState(false);
  const tooltipClasses = useTooltipStyles();
  const createChangeHandler = (kind: 'email' | 'text') => () => {
    if (name === NotificationType.Ptrevent.toString()) {
      return handleNavigate(ROUTES.PEAKTIME_REBATES);
    } else {
      const newValue = {
        ...value,
        [kind]: !value[kind],
      };
      onChange(newValue, kind);
    }
  };

  const renderTooltipLink = (toolTipCode: string) => {
    switch (toolTipCode) {
      case NotificationType.Homeevcharge:
        return ROUTES.SMART_CHARGING_PROGRAM;
      case NotificationType.Netmtr:
        return ROUTES.ROOFTOP_SOLAR;
      case NotificationType.Ptrevent:
        return ROUTES.PEAKTIME_REBATES;
      case NotificationType.Timeofday:
        return ROUTES.TIMEOFDAY;
      default:
        return '';
    }
  };

  const labelPortion = (
    <ClickAwayListener onClickAway={() => toggleOpen(false)}>
      <Tooltip
        open={isOpen}
        onClose={() => toggleOpen(false)}
        classes={tooltipClasses}
        interactive
        placement="bottom-start"
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <>
            {t(`NOTIFICATION_${tooltipValue}_TYPE_TOOLTIP`)}
            {(tooltipValue === NotificationType.Homeevcharge ||
              tooltipValue === NotificationType.Netmtr ||
              tooltipValue === NotificationType.Ptrevent ||
              tooltipValue === NotificationType.Timeofday) && (
              <Link
                to={renderTooltipLink(tooltipValue as string)}
                onClick={e => {
                  void handleNavigate(
                    renderTooltipLink(tooltipValue as string),
                  );
                  e.preventDefault();
                }}
              >
                {t(`NOTIFICATION_${tooltipValue}_TYPE_TOOLTIP_LINK`)}
              </Link>
            )}
          </>
        }
      >
        <Typography variant={'body1'}>
          <a
            href={'/'}
            onClick={e => {
              e.preventDefault();
              toggleOpen(!isOpen);
            }}
            className={classes.notificationTypeDecoration}
          >
            {t(`NOTIFICATION_${tooltipValue}_TYPE_DESCRIPTION`)}
          </a>
          {tooltipValue === NotificationType.Ptrevent && (
            <ExternalLinkIcon className={classes.externalLinkIcon} />
          )}
        </Typography>
      </Tooltip>
    </ClickAwayListener>
  );
  const rowStart = Boolean(children) ? (
    <Grid
      container
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <Grid item style={{ paddingRight: 10 }}>
        {labelPortion}
      </Grid>
      {children}
    </Grid>
  ) : isMultiSelect ? (
    <Typography>{tooltipValue}</Typography>
  ) : (
    labelPortion
  );
  const displayContent = () => {
    const content = [];
    if (!hideEmailSection) {
      content.push(
        <ToggleButton
          disabled={emailDisabled}
          value={value.email}
          onChange={createChangeHandler('email')}
        />,
      );
    }
    if (!hideTextSection) {
      content.push(
        <ToggleButton
          value={value.text}
          onChange={createChangeHandler('text')}
          disabled={textDisabled}
        />,
      );
    }
    if (content.length === 1) {
      content.unshift(<Box></Box>);
    }
    return content;
  };
  return (
    <div className={classes.row}>
      <Box className={isMobile ? classes.notificationTypeData : ''}>
        {rowStart}
      </Box>
      {displayContent()}
    </div>
  );
}
