import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Grid, Link } from '@material-ui/core';
import { Query } from '../../../__generated__/gatsby-types';
import { documentToMUIComponents } from '../../../util/contentful';
import { INLINES } from '@contentful/rich-text-types';
import { LanguageContext } from '../../../providers/LanguageProvider';
import BroadcastMessageBanner from './BroadcastMessageBanner';

// The ne filter in this query is part of a (hopefully temporary) hack to get around:
// https://www.npmjs.com/package/gatsby-source-contentful#notes-on-contentful-content-models
// In order to have a type included in the __generated__/gatsby-types schema, there need to
// be at least one instance of a content entry for the type. Entry 2mGmGOew643UpSrw1rU9nS
// is the place holder for BroadcastMessage and it should never be displayed.
const AccountSummaryBroadcastMessageQuery = graphql`
  {
    allContentfulAccountSummaryBroadcastMessage(
      filter: { contentful_id: { ne: "2mGmGOew643UpSrw1rU9nS" } }
    ) {
      nodes {
        id
        node_locale
        messageType
        messageBody {
          raw
        }
      }
    }
  }
`;

const BroadcastMessage = () => {
  const { allContentfulAccountSummaryBroadcastMessage } = useStaticQuery<Query>(
    AccountSummaryBroadcastMessageQuery,
  );
  const { language } = useContext(LanguageContext);

  return allContentfulAccountSummaryBroadcastMessage.nodes.length ? (
    <Grid item>
      {allContentfulAccountSummaryBroadcastMessage.nodes
        .filter(node => node.node_locale === language) //only show messages for current language
        .map(message => (
          <BroadcastMessageBanner
            data-testid="broadcast-message"
            key={message.id}
            severity={message.messageType?.toLowerCase() as any}
            messageBody={
              message.messageBody &&
              documentToMUIComponents(message.messageBody, {
                renderNode: {
                  [INLINES.HYPERLINK]: (node, children) => {
                    return <Link href={node.data.uri}>{children}</Link>;
                  },
                },
              })
            }
          />
        ))}
    </Grid>
  ) : null;
};

export default BroadcastMessage;
