import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { MulticheckState } from '../../../hooks/useMulticheckState';
import { AccountDetail } from '../../../__generated__/pge-types';
import { MenuItem } from '../../menu/Menu';
import ListItem from '../../list-item';
import TextLink from '../../text-link';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import { displayAccountIdentifier } from '../../../util/format';
import Circle from '@material-ui/icons/FiberManualRecord';
import { useTranslation } from '../../../hooks/useTranslation';

type Props = {
  account: AccountDetail;
  multicheckState: MulticheckState;
  menuItems: Array<MenuItem<AccountDetail>>;
  onViewDetails: (account: AccountDetail) => void;
};

export default function SimpleAccountListItem({
  account,
  multicheckState,
  menuItems,
  onViewDetails,
}: Props) {
  const { t } = useTranslation();

  return (
    <ListItem
      item={account}
      checked={multicheckState.isChecked(account.accountNumber)}
      checkDisabled={!multicheckState.isAnOption(account.accountNumber)}
      onCheckChange={multicheckState.handleCheckToggle(account.accountNumber)}
      menuItems={menuItems}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Grid container spacing={0} alignItems="center">
            <Grid item>
              {account.isActive ? (
                <TextLink
                  onClick={e => {
                    e.preventDefault();
                    onViewDetails(account);
                  }}
                  to={ROUTES.ACCOUNT}
                  plain
                  style={{
                    fontWeight: 'bold',
                    color: colors.sparkBlue,
                    cursor: 'pointer',
                  }}
                >
                  {displayAccountIdentifier(account)}
                </TextLink>
              ) : (
                <Typography variant="body2">
                  <strong>{displayAccountIdentifier(account)}</strong>
                </Typography>
              )}
            </Grid>
            {account.isDefault && account.accountNumber && (
              <Circle
                titleAccess={t('PRIMARY_ACCOUNT')}
                style={{ color: colors.shoreGreen }}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={0} direction="column">
            {Number(account?.premiseInfo?.length) > 0 && (
              <Grid item>
                <Typography variant="body1">
                  {account?.premiseInfo?.[0]?.addressLine1}
                </Typography>
              </Grid>
            )}
            <Grid item>
              <Typography variant="body1">
                {account.mainCustomerName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
}
