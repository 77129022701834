import React, { FunctionComponent, ReactNode } from 'react';

import AccountLeftNavBlock from '../utility/account-left-nav-block';
import Page2ColumnLayout from '../utility/page2-column-layout/Page2ColumnLayout';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import NoSaNavBlock from '../utility/no-sa-nav-block';

export interface AccountLayoutProps {
  dense?: boolean;
  showSingle?: boolean;
  children: ReactNode;
}

const AccountLayout: FunctionComponent<AccountLayoutProps> = props => {
  const { children, dense, showSingle } = props;
  const { customer } = useAccountCustomer();

  return (
    <Page2ColumnLayout
      dense={dense}
      leftColumn={
        customer?.accountMeta?.totalAccounts === 0 ? (
          <NoSaNavBlock />
        ) : (
          <AccountLeftNavBlock showSingle={showSingle} />
        )
      }
    >
      {children}
    </Page2ColumnLayout>
  );
};

export default AccountLayout;
