import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PaperBox from '../../utility/paper-box';
import useStyles from '../styles';
import { ArrowUpward } from '@material-ui/icons';
import { Avatar, Box, List, ListItem, ListItemAvatar } from '@material-ui/core';
import { TimeOfDayInfo } from '../../../__generated__/pge-types';
import TimeOfDaySavingsChart from './TimeOfDaySavingsChart';

interface Props {
  timeOfDayInfo?: TimeOfDayInfo | any;
  savingHeader: String;
  footer: String;
}

export const TimeOfDaySavings = (props: Props) => {
  const classes = useStyles();
  const { timeOfDayInfo, savingHeader, footer } = props;

  return (
    <Grid container>
      <PaperBox className={classes.savingsBox}>
        {savingHeader && (
          <Grid item>
            <Typography className={classes.sideBySideHeader}>
              {savingHeader}
            </Typography>
          </Grid>
        )}

        <Grid item>
          <List>
            <ListItem style={{ justifyContent: 'center' }}>
              <ListItemAvatar>
                <Avatar
                  className={
                    timeOfDayInfo?.timeOfDaySummaryInfo?.deltaBasicToTodAmount >
                    0
                      ? classes.blueIcon
                      : classes.redIcon
                  }
                >
                  <ArrowUpward />
                </Avatar>
              </ListItemAvatar>

              <Box display="flex" flexDirection="column">
                <Typography variant="h1">
                  $
                  {Math.abs(
                    timeOfDayInfo?.timeOfDaySummaryInfo?.deltaBasicToTodAmount,
                  )}
                </Typography>
              </Box>
            </ListItem>
          </List>
        </Grid>
        {timeOfDayInfo?.timeOfDaySummaryInfo && (
          <Grid item style={{ margin: '0px -55px 0px -10px' }}>
            <TimeOfDaySavingsChart
              timeOfDaySummaryInfo={timeOfDayInfo?.timeOfDaySummaryInfo}
            />
          </Grid>
        )}
        {footer && (
          <Grid item alignContent="center">
            <Typography>* {footer}</Typography>
          </Grid>
        )}
      </PaperBox>
    </Grid>
  );
};

export default TimeOfDaySavings;
