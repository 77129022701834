import {
  makeStyles,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
} from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React, { useEffect, useState } from 'react';
import colors from '../../../themes/main-colors';
import { PaymentProfile } from '../../../__generated__/pge-types';
import { ProfileChangeHandler } from '../../paymentus/types';
import PaymentMethodItem from '../payment-method-item';
import useStyles from '../styles';

const StyledButton = ({ ...rest }: ButtonProps) => (
  <Button
    fullWidth
    aria-controls="customized-menu"
    aria-haspopup="true"
    variant="outlined"
    endIcon={<KeyboardArrowDownIcon />}
    style={{
      background: '#fff',
    }}
    {...rest}
  />
);

interface StyledMenuProps extends MenuProps {
  width: number;
}

const useStyledMenuStyles = makeStyles(() => ({
  paper: {
    width: (props: { width: number }) => props.width,
  },
}));

const StyledMenu = (props: StyledMenuProps) => {
  const classes = useStyledMenuStyles(props);
  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={classes}
      {...props}
    />
  );
};

type Props = {
  onChange: ProfileChangeHandler;
  currentProfile?: PaymentProfile;
  excludeProfiles?: PaymentProfile[];
  profileList: PaymentProfile[];
  selectLabel?: string;
};

export const PaymentMethodDropdown = (props: Props) => {
  const classes = useStyles();
  const {
    currentProfile,
    profileList,
    selectLabel,
    excludeProfiles = [],
    onChange,
  } = props;

  const defaultProfile = profileList.find(p => p.default) || profileList[0];

  const [selectedProfile, setSelectedProfile] = useState(
    currentProfile || defaultProfile,
  );
  const anchorEl = React.useRef<null | HTMLElement>(null);

  const [width, setWidth] = React.useState(0);

  const [open, setOpen] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setWidth(event.currentTarget.clientWidth);
    anchorEl.current = event.currentTarget;
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!selectLabel) {
      onChange(selectedProfile);
    }
  }, []);

  useEffect(() => {
    setSelectedProfile(currentProfile || defaultProfile);
  }, [currentProfile]);

  const onProfileChange = (profile: PaymentProfile) => {
    setSelectedProfile(profile);
    onChange(profile);
    onClose();
  };

  return (
    <Grid
      container
      item
      direction="column"
      spacing={4}
      alignItems={'flex-start'}
      justify={'flex-start'}
    >
      <Grid container item spacing={1} direction="row">
        <Grid item container xs={12} className={classes.groupSelector}>
          <StyledButton
            onClick={handleClick}
            data-testid="payment-method-dropdown-button"
          >
            <div className={classes.paymentItemContainer}>
              {selectLabel && (
                <Typography
                  variant={'body1'}
                  style={{ fontSize: '1rem', padding: '0.5em' }}
                >
                  {selectLabel}
                </Typography>
              )}
              {!selectLabel && <PaymentMethodItem profile={selectedProfile} />}
            </div>
          </StyledButton>
        </Grid>
      </Grid>

      <StyledMenu
        data-testid="payment-method-dropdown-menu"
        width={width}
        anchorEl={anchorEl.current}
        keepMounted
        open={open}
        onClose={onClose}
      >
        {profileList
          .filter(p => !excludeProfiles.some(ep => ep.token === p.token))
          .map(profile => (
            <MenuItem
              data-testid={'test-' + profile.token}
              className={classes.paymentItemContainer}
              key={profile.token}
              selected={!selectLabel && profile.token === selectedProfile.token}
              onClick={() => onProfileChange(profile)}
              style={{ borderBottom: `1px solid ${colors.lightGray1}` }}
            >
              <PaymentMethodItem profile={profile} />
            </MenuItem>
          ))}
      </StyledMenu>
    </Grid>
  );
};

export default PaymentMethodDropdown;
