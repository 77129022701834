import { convertValidationRules } from '../../../hooks/useFormState';

import { validateAmount } from '../../../util/form-validation';
import { useTranslation } from '../../../hooks/useTranslation';
import { parse, isWithinInterval, addDays } from 'date-fns';
import moment from 'moment-timezone';
import { toDateString } from '../../../util/format';
import { isDateInFuture } from '../../auto-pay/auto-pay.utils';

export type Model = {
  paymentAmount: string;
  paymentDate: string;
  charityAmount?: string;
  charitySelection?: string;
  charityConfirm?: boolean;
};

const { t } = useTranslation();

const paymentDatePattern = /\d{2}\/\d{2}\/\d{4}/;

type AdditionalContext = {
  maxPayDate?: Date;
  minimumAmount?: string;
  fullAmount?: string;
  selectedReconnectOption?: string;
  charitySelection?: string;
};

export const validatePaymentDate = (
  payDate: string,
  maxPayDate?: Date,
): string | null => {
  if (!payDate || payDate === '') {
    return t('ERROR_PAYMENT_DATE_REQUIRED');
  }
  if (!paymentDatePattern.test(payDate)) {
    return t('ERROR_INVALID_PAYMENT_DATE');
  }

  const PstTime = moment()
    .tz('Canada/Pacific')
    .format('MM/DD/yyyy');

  const userSelectedDate = parse(payDate, 'MM/dd/yyyy', new Date(0));
  const today = new Date(PstTime).setHours(0, 0, 0, 0);
  const maxDate = maxPayDate ? maxPayDate : addDays(today, 365);
  const errorMsg = maxPayDate
    ? t('ERROR_PAYMENT_DATE_NOT_IN_RANGE', {
        minDate: toDateString(today),
        maxDate: toDateString(maxDate),
      })
    : t('ERROR_INVALID_PAYMENT_DATE');

  const isInRange = isWithinInterval(userSelectedDate, {
    start: today,
    end: maxDate,
  });

  if (!isInRange) {
    return errorMsg;
  }

  return null;
};
export const validateReconnectAmount = (
  amount: string,
  fullAmount: string,
  selectedReconnectOption: string,
  minimumAmount: string,
): string | null => {
  const unFormattedAmount = Boolean(amount)
    ? amount
        .toString()
        .replace('$', '')
        .replace(',', '')
    : '';
  if (!unFormattedAmount) {
    return t('ERROR_PAYMENT_AMOUNT_REQUIRED');
  }
  if (selectedReconnectOption === 'RECONNECT_PAY_OTHER_AMOUNT') {
    if (Number(unFormattedAmount) === 0) {
      return t('PAYMENT_AMOUNT_CANNOT_ZERO');
    }
    if (
      Number(unFormattedAmount) < Number(minimumAmount) ||
      Number(unFormattedAmount) > Number(fullAmount)
    ) {
      return t('PAYMENT_AMT_MIN_MAX_ERROR');
    }
  }
  return null;
};
export default convertValidationRules<Model, AdditionalContext>(context => ({
  paymentAmount: amount => {
    if (context.selectedReconnectOption) {
      return validateReconnectAmount(
        amount,
        context.fullAmount!,
        context.selectedReconnectOption,
        context.minimumAmount!,
      );
    } else {
      const validation = validateAmount(amount);
      if (validation) {
        return validation;
      } else if (context.minimumAmount) {
        const unFormattedAmount = Boolean(amount)
          ? amount
              .toString()
              .replace('$', '')
              .replace(',', '')
          : '';

        if (Number(unFormattedAmount) < Number(context.minimumAmount)) {
          return t('PAYMENT_AMT_MIN_ERROR');
        }
      }

      return null;
    }
  },
  paymentDate: payDate => {
    return validatePaymentDate(payDate, context.maxPayDate);
  },
  charityConfirm: value => {
    if (
      context.charitySelection &&
      context.charitySelection !== 'none' &&
      !value
    ) {
      return t('CONFIRM_CHARITY');
    }
    return null;
  },
}));
