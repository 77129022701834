import {
  validatePhoneNumber,
  validateNameField,
  validatePostalCode,
  validateAlphabetCharacters,
  validPhoneExt,
  validateAlternatePhone,
  validateReasonForChange,
  validateExistsAndAlphabetCharacters,
  validateAlphabetCharactersWithAmp,
  validateAddressField,
  validatePhoneNumberLength,
} from '../../util/form-validation';
import { useTranslation } from '../../hooks/useTranslation';
import { isCanadianState } from '../../components/utility/state-dropdown';
import { AccountPerson, RelationshipType } from '../../__generated__/pge-types';
import { FormattedFullName } from '../../util/string-utils';

const zipRegex = /(^[0-9]{5})$/;
export const validateZipCode = (zipCode: string): string | null => {
  if (!zipCode || !zipCode.length) {
    return t('ENTER_ZIP_OR_POSTAL');
  }
  if (zipCode.length < 5 || (zipCode && !zipRegex.test(zipCode))) {
    return t('ERROR_ZIP_CODE_INVALID');
  }
  return null;
};

const { t } = useTranslation();

export const isNullOrEmpty = (state: any, keys: string[]): boolean =>
  keys.every(k => !state[k]?.value?.length);

const allowNullOrEmpty = (
  validationFn: (value: any) => string | null,
  isAllowNullOrEmpty: boolean,
) => (value: any): string | null => {
  if (isAllowNullOrEmpty && (!value || !value.length)) {
    return null;
  }

  return validationFn(value);
};

export const ResidentialFormValidationRules = (
  event: string,
  state: any,
): any => {
  const persons: AccountPerson[] = state.oldState.persons;
  const mainUser = persons.find(
    p => p.personRelationshipType === RelationshipType.MainCustomer,
  );
  const rules: any = {
    primaryPhone: validatePhoneNumber,
    mobilePhone: validateAlternatePhone.bind(null, state.mobilePhone.value),
    alternatePhone: validateAlternatePhone.bind(
      null,
      state.alternatePhone.value,
    ),
    address: allowNullOrEmpty(
      validateAddressField,
      isNullOrEmpty(state, ['zip', 'city', 'state']),
    ),
    zip: allowNullOrEmpty(
      isCanadianState(state.state.value) ? validatePostalCode : validateZipCode,
      isNullOrEmpty(state, ['address', 'city', 'state']),
    ),
    city: allowNullOrEmpty(
      validateExistsAndAlphabetCharacters.bind(null, t('CITY')),
      isNullOrEmpty(state, ['address', 'zip', 'state']),
    ),
    state: allowNullOrEmpty(
      validateNameField.bind(null, t('STATE')),
      isNullOrEmpty(state, ['address', 'zip', 'city']),
    ),
    firstName: validateExistsAndAlphabetCharacters.bind(null, t('FIRST_NAME')),
    middleName: validateAlphabetCharacters.bind(null, t('MIDDLE_NAME')),
    lastName: validateExistsAndAlphabetCharacters.bind(null, t('LAST_NAME')),
    coCustomerFirstName: validateExistsAndAlphabetCharacters.bind(
      null,
      t('FIRST_NAME'),
    ),
    coCustomerMiddleName: validateAlphabetCharacters.bind(
      null,
      t('MIDDLE_NAME'),
    ),
    coCustomerLastName: validateExistsAndAlphabetCharacters.bind(
      null,
      t('LAST_NAME'),
    ),
    signedInUserFirstName: validateExistsAndAlphabetCharacters.bind(
      null,
      t('FIRST_NAME'),
    ),
    signedInUserMiddleName: validateAlphabetCharacters.bind(
      null,
      t('MIDDLE_NAME'),
    ),
    signedInUserLastName: validateExistsAndAlphabetCharacters.bind(
      null,
      t('LAST_NAME'),
    ),
    reasonForChange: validateReasonForChange.bind(
      null,
      FormattedFullName(
        state.firstName.value,
        state.middleName.value,
        state.lastName.value,
      ),
      mainUser?.fullName || persons[0]?.fullName || '',
    ),
  };
  const coAppUser = persons.find(
    p => p.personRelationshipType === RelationshipType.CoApplicant,
  );
  if (coAppUser) {
    rules['coCustomerReasonForChange'] = validateReasonForChange.bind(
      null,
      FormattedFullName(
        state.coCustomerFirstName.value,
        state.coCustomerMiddleName.value,
        state.coCustomerLastName.value,
      ),
      coAppUser?.fullName || '',
    );
  }
  return rules;
};

function validatePhoneExt(phone: string, ext: string) {
  const err = validatePhoneNumberLength(phone);
  if (err) {
    return err;
  }
  if (ext && !phone) {
    return t('ERROR_PHONE_REQUIRED');
  }
  return null;
}

export const CommercialFormValidationRules = (
  event: string,
  state: any,
): any => {
  return {
    name: validateExistsAndAlphabetCharacters.bind(null, t('NAME')),
    primaryPhone: validatePhoneNumber,
    address: allowNullOrEmpty(
      validateAddressField,
      isNullOrEmpty(state, ['zip', 'city', 'state']),
    ),
    zip: allowNullOrEmpty(
      isCanadianState(state.state.value) ? validatePostalCode : validateZipCode,
      isNullOrEmpty(state, ['address', 'city', 'state']),
    ),
    city: allowNullOrEmpty(
      validateExistsAndAlphabetCharacters.bind(null, t('CITY')),
      isNullOrEmpty(state, ['address', 'zip', 'state']),
    ),
    state: allowNullOrEmpty(
      validateNameField.bind(null, t('STATE')),
      isNullOrEmpty(state, ['address', 'zip', 'city']),
    ),
    contactName: validateAlphabetCharacters.bind(null, t('CONTACT_NAME')),
    bookKeeperName: validateAlphabetCharactersWithAmp.bind(
      null,
      t('BOOKKEEPER_NAME'),
    ),
    bookKeeperPhone: (phone: string) =>
      validatePhoneExt(phone, state.bookKeeperPhoneExt.value),
    bookKeeperPhoneExt: validPhoneExt,
    contactPrimaryPhone: (phone: string) =>
      validatePhoneExt(phone, state.primaryPhoneExt.value),
    primaryPhoneExt: validPhoneExt,
  };
};
