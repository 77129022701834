import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { navigate } from 'gatsby';
import React, { FunctionComponent } from 'react';

import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import Button from '../buttons';
import Link from '../text-link';

interface CancelServiceProps {
  path?: string;
  classes: any;
}

const CancelService: FunctionComponent<CancelServiceProps> = props => {
  const { t } = useTranslation();

  return (
    <>
      <Paper>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={'body1'}>
                  {t('CANCEL_ONLINE_INFO_1')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'body1'}>
                  {t('CANCEL_ONLINE_INFO_2')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography variant={'body1'}>
                  {t('CANCEL_ONLINE_INFO_3')}{' '}
                  <Link plain to={ROUTES.MANAGE_ALERTS}>
                    {t('MANAGE_ALERTS')}
                  </Link>{' '}
                  {t('CANCEL_ONLINE_INFO_3_CONTINUED')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'body1'}>
                  {t('CANCEL_ONLINE_INFO_4')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'subtitle2'}>
                  {t('CANCEL_ONLINE_UNREGISTER_INFO')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <Button
                  size={'large'}
                  onClick={() =>
                    navigate(ROUTES.CANCEL_ONLINE_SERVICE_VERIFICATION)
                  }
                  className={props.classes.submitButton}
                >
                  {t('CANCEL_ONLINE_SERVICE')}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </>
  );
};

export default CancelService;
