import { DocumentNode } from 'graphql';
import useAuthQuery from './useAuthQuery';
import { ViewPaymentHistoryDetail } from '../__generated__/pge-types';
import { PageSize } from '../components/pagination/types';
import useSelectedAccountParams from './useSelectedAccountParams';
import { getAccountDetailsForPaymentHistoryDetails } from '../queries/paymentHistory.query';
import { getAccountDetails } from '../components/account-summary/single-account/queries';

type Arguments = {
  paymentHistoryParams: {
    page: number;
    pageSize: PageSize | number;
  };
};

export default function usePaymentHistory({ paymentHistoryParams }: Arguments) {
  const { page = 1, pageSize = 15 } = paymentHistoryParams;

  const { accountParams } = useSelectedAccountParams();
  const { data } = useAuthQuery(
    getAccountDetails,
    {
      variables: {
        params: {
          accountNumberList: [accountParams],
        }
      },
      skip: !Boolean(accountParams),
    },
  );
  const selectedAccount = data?.getAccountDetails?.[0];
  const { loading, data: historyData, error } = useAuthQuery(getAccountDetailsForPaymentHistoryDetails, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
      paymentHistoryParams: {
        pagingParams: {
          limit: pageSize,
          offset: (page - 1) * Number(pageSize),
        },
      },
    },
    skip: !Boolean(accountParams),
  });
  const historyDetails = historyData?.getAccountDetails?.[0].paymentHistory;

  const totalDetailsRecords = historyDetails?.totalDetailsRecords;
  const paymentHistoryDetails = historyDetails?.paymentHistoryDetails as ViewPaymentHistoryDetail[];
  const isSummaryBillAccount = historyData?.getAccountDetails?.[0].isSummaryBillAccount;
  
  return {
    historyLoading: loading,
    historyData: paymentHistoryDetails,
    error,
    totalCount: totalDetailsRecords,
    selectedAccount,
    accountParams,
    isSummaryBillAccount,
  };
}
