import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subField: {
      '& input': {
        padding: theme.spacing(1, 2),
      },
    },
    icon: {
      color: theme.palette.success.main,
    },
    colorTextPrimary: {
      color: colors.noirBlur,
      margin: theme.spacing(2, 0),
    },
    primaryEmail: {
      color: colors.noirBlur,
      display: 'flex',
    },
    boldText: {
      fontWeight: 'bold',
    },
    primaryEmailStyles: {
      fontWeight: 'bold',
      marginLeft: '5px',
    },
    alerts: {
      display: 'flex',
      flexDirection: 'column',
    },
    alertsButton: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1em',
    },
    header: {
      margin: theme.spacing(1, 2, 2, 6),
      color: colors.noirBlur,
      fontWeight: 800,
    },
    accountCard: {
      padding: theme.spacing(2),
      width: '100%',
    },
    subCard: {
      paddingTop: theme.spacing(2),
    },
    text: {
      margin: theme.spacing(1, 2, 2, 2),
      color: colors.noirBlur,
    },
    textLeft: {
      margin: theme.spacing(1, 6, 2, 2),
      color: colors.noirBlur,
    },
    gridContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    noLastBorder: {
      '& > *:last-child': {
        borderBottom: 'none',
      },
    },
    callModal: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: theme.spacing(4, 6, 4, 4),
      boxShadow: theme.shadows[5],
      backgroundColor: theme.palette.background.paper,
      [theme.breakpoints.down('sm')]: {
        top: '50%',
        left: 10,
        right: 10,
        transform: 'translate(0, -50%)',
      },
    },
    row: {
      minHeight: theme.spacing(6.5),
      flex: 0,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > *': {
        flex: 2,
      },
      '& > *:first-child': {
        flex: 10,
      },
    },
    rightColumns: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    footerText: {
      '& p': {
        marginBottom: 0,
      },
      '& p:not(:first-child)': {
        marginTop: 0,
      },
    },
    modalBodytext: {
      marginRight: '.75rem',
    },
    manageAlertsSuccess: {
      '& p': {
        margin: '5px 0',
      },
      '& .MuiAlert-message': {
        width: '100%',
      },
    },
    buttonBox: {
      width: '100%',
    },
    addPerson: {
      top: '15px',
      float: 'right',
      borderRadius: '20px',
    },
    deletePerson: {
      top: '15px',
      borderRadius: '20px',
    },
    alertTitle: {
      fontWeight: 300,
      margin: '-10px 0 0',
      fontFamily: 'Forma-DJR-Display',
    },
    select: {
      background: '#fff',
      width: '100%',
    },
    '.MuiSelect-select': {
      '&:focus': {
        background: '#fff',
      },
    },
    removePersonDataModalWrapper: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '700px',
        width: '80%',
      },
    },
    removePersonDataModal: {
      padding: theme.spacing(3),
      borderRadius: '5px',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(4, 3, 3, 3),
      },
    },
    updatePhoneNumberDialog: {
      padding: '2rem',
    },
    textConfirmBox: {
      background: colors.aliceBlue,
      textAlign: 'center',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    textConfirmContent: {
      color: colors.noirBlur,
    },
    closeBtn: {
      position: 'absolute',
      top: 10,
      right: 10,
      padding: 0,
    },
    modalBody: {
      marginTop: theme.spacing(1),
      '& > *': {
        marginTop: theme.spacing(1),
      },
    },
    dialogBtn: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
    confirmBtn: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    userContact: {
      padding: '1rem',
      backgroundColor: colors.white,
      borderRadius: '5px',
    },
    accordionDetails: {
      display: 'block',
    },
    userContactDetailsAccordion: {
      backgroundColor: colors.inputFieldBackground,
      marginTop: '2rem !important',
      boxShadow: 'none',
    },
    emailAndPhoneLabel: {
      fontWeight: 'bold',
      marginTop: '1rem',
      marginBottom: '0.5rem',
    },
    updateButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1rem',
    },
    updateButton: {
      borderRadius: '20px',
    },
    activateButton: {
      background: colors.orange,
      '&:hover': {
        backgroundColor: colors.orange,
        borderColor: colors.orange,
      },
    },
    buttonRow: {
      '& button': {
        borderRadius: '20px',
      },
    },
    deleteIcon: {
      marginRight: '5px',
    },
    alertAccordionHeading: {
      fontSize: '19px',
      fontWeight: 'bold',
    },
    alertsInfo: {
      display: 'block',
    },
    renderCardData: {
      width: '100%',
    },
    renderAlertsCard: {
      background: colors.lightGray7,
      marginBottom: '20px',
      padding: '1rem',
    },
    alertGroupHeading: {
      fontSize: '1rem',
      fontWeight: 600,
      marginBottom: '0.3rem',
    },
    notificationTypeDecoration: {
      fontWeight: 'bold',
      color: colors.sparkBlue,
      borderBottom: 'dashed',
      borderWidth: '1px !important',
      textDecoration: 'none',
    },
    alertsCard: {
      padding: '0',
    },
    privacyPolicyContent: {
      margin: '0rem 1rem',
    },
    notificationTypeData: {
      flex: '7 !important',
    },
    savePreferencesButton: {
      borderRadius: '20px',
      background: colors.orange,
      '&:hover': {
        backgroundColor: colors.orange,
        borderColor: colors.orange,
      },
    },
    alertsWarning: {
      width: '30rem',
      height: 'auto',
      padding: '2rem',
    },
    alertsWarningMob: {
      width: 'auto',
      height: 'auto',
      padding: '2rem',
    },
    unsavedContent: {
      fontSize: '16px',
      marginTop: '0.5rem',
    },
    alertButtonContainer: {
      marginTop: '1rem',
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column-reverse',
        alignItems: 'center',
      },
    },
    save: {
      borderRadius: theme.spacing(3.75),
      width: '25%',
      background: colors.orange,
      '& span': {
        fontSize: '18px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: '1em',
      },
      '&:hover': {
        background: colors.orange,
      },
    },
    close: {
      color: colors.sparkBlue,
      marginRight: '1em',
      background: 'transparent',
      borderColor: colors.sparkBlue,
      borderRadius: theme.spacing(3.75),
      minWidth: '100px',
      borderWidth: '2px',
      padding: '5px',
      '& span': {
        fontSize: '18px',
      },
      '&:hover': {
        color: colors.sparkBlue,
        background: 'transparent',
        borderColor: colors.sparkBlue,
        borderWidth: '2px',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginRight: '0em',
      },
    },
    externalLinkIcon: {
      marginLeft: '0.5rem',
      width: '3%',
      height: 'auto',
    },
    iconText: {
      padding: '9px 10px',
      '&:hover': {
        background: 'none',
      },
    },
  }),
);
