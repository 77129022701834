import gql from 'graphql-tag';

export const getLeadData = gql`
  query GetLeadData($params: LeadInfoParams!) {
    getLeadData(params: $params) {
      results {
        bo
        boStatus
        initiativeCd
        leadId
        detailedDescr
        descr
        campaign
      }
    }
  }
`;

export const updateLeadDetails = gql`
  mutation updateLeadDetails($payload: LeadDetailsUpdateRequest!) {
    updateLeadDetails(payload: $payload) {
      leadId
      outcome
      discardReasonCd
      notes
    }
  }
`;
