import {
  AccountDetail,
  Maybe,
  PremiseInfo,
  ProgramsEnrollmentStatusDetails,
} from '../../__generated__/pge-types';
import { useAccountCustomerDashboard } from '../../hooks/useAccountCustomerDashboard';
import useAuthQuery from '../../hooks/useAuthQuery';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import { GET_ACCOUNT_DETAILS } from '../my-programs/queries';
import {
  getActiveEncryptedPremiseId,
  getActiveEncryptedSaId,
} from '../my-programs/utils';
import { getProgramsEnrollmentStatusDetailsQuery } from './queries';

export function useAdditionalProgramsSmartCards() {
  const {
    showLegacyUI,
    loading: accountTypeLoading,
  } = useAccountCustomerDashboard();
  const { accountParams, encryptedAccountNumber } = useSelectedAccountParams();

  const {
    data: account,
    loading: accountsLoading,
    error: getAccountsError,
  } = useAuthQuery<{
    getAccountDetails: [AccountDetail];
  }>(GET_ACCOUNT_DETAILS, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams || showLegacyUI,
    errorPolicy: 'all',
  });

  const encryptedServiceAgreementId = getActiveEncryptedSaId(
    account?.getAccountDetails?.[0]?.premiseInfo as PremiseInfo[] | undefined,
  );

  const encryptedPremiseId = getActiveEncryptedPremiseId(
    account?.getAccountDetails?.[0]?.premiseInfo as PremiseInfo[] | undefined,
  );

  const {
    data: programsEnrollmentStausResponse,
    loading: programsEnrollmentStausLoading,
    error: programsEnrollmentStausResponseError,
  } = useAuthQuery<{
    getProgramsEnrollmentStatusDetails: Maybe<ProgramsEnrollmentStatusDetails>;
  }>(getProgramsEnrollmentStatusDetailsQuery, {
    variables: {
      params: {
        encryptedAccountNumber,
        encryptedPremiseId,
        encryptedSaId: encryptedServiceAgreementId,
      },
    },
    fetchPolicy: 'cache-and-network',
    skip:
      !encryptedAccountNumber ||
      !encryptedPremiseId ||
      !encryptedServiceAgreementId ||
      showLegacyUI,
    errorPolicy: 'all',
  });

  const programEnrollmentDetails =
    programsEnrollmentStausResponse?.getProgramsEnrollmentStatusDetails;

  const error = getAccountsError || programsEnrollmentStausResponseError;
  const loading =
    accountsLoading || programsEnrollmentStausLoading || accountTypeLoading;

  const enrolledPrograms: string[] = [];

  if (programEnrollmentDetails?.energyShifting) {
    enrolledPrograms.push(
      ...programEnrollmentDetails?.energyShifting
        ?.filter(program => program?.isEnrolled || !program?.isEligible)
        .map(unEnrollProgram => unEnrollProgram?.programName as string),
    );
  }
  if (programEnrollmentDetails?.renewables) {
    enrolledPrograms.push(
      ...programEnrollmentDetails?.renewables
        ?.filter(program => program?.isEnrolled || !program?.isEligible)
        .map(unEnrollProgram => unEnrollProgram?.programName as string),
    );
  }

  return {
    enrolledPrograms: transformToContentfulType(enrolledPrograms),
    error,
    loading,
  };
}

export enum SmartCardType {
  EV_SMART_CHARGING = 'EV-CHARGERS',
  PEAK_TIME_REBATES = 'PEAK-TIME-REBATES',
  SMART_THERMOSTAT = 'SMART-THERMOSTAT',
  TIME_OF_DAY = 'TIME-OF-DAY',
  SMART_BATTERY_PILOT = 'SOLAR-PLUS-BATTERY',
  GREEN_FUTURE_CHOICE = 'GREEN-FUTURE',
}

function transformToContentfulType(values: string[]): string[] {
  const isGreenFutureEnrolledBoth =
    values.filter(
      value =>
        value === 'GREEN_FUTURE_CHOICE' || value === 'GREEN_FUTURE_BLOCK',
    )?.length === 2;
  return values.map(value => {
    const enumKey = value as keyof typeof SmartCardType;
    if (enumKey in SmartCardType) {
      if (enumKey === 'GREEN_FUTURE_CHOICE') {
        return isGreenFutureEnrolledBoth ? SmartCardType[enumKey] : value;
      }
      return SmartCardType[enumKey];
    } else {
      return value;
    }
  });
}
