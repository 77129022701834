/** @jsx jsx */
import { jsx } from '@emotion/core';

import React from 'react';
import { Router } from '@reach/router';
import ROUTES from '../../routes';
import Form from '../../components/iqbd/Form';

const IQBDHome: React.FC = () => {
  return (
    <Router basepath="/">
      <Form path={ROUTES.IQBD_FORM} />
    </Router>
  );
};

export default IQBDHome;
