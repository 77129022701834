import React, { FC, ReactNode, useContext } from 'react';
import Box from '@material-ui/core/Box';
import {
  Typography,
  Grid,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  Divider,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import ROUTES from '../../../routes';
import { useTranslation } from '../../../hooks/useTranslation';
import PaperBox from '../../../components/utility/paper-box';
import {
  PaymentExtensionOption,
  PaymentExtensionOptionType,
} from '../../../__generated__/pge-types';
import { navigate } from '@reach/router';
import ProceedOrCancel from '../../proceed-or-cancel';
import formatCurrency from 'format-currency';
import { toDateString } from '../../../util/format';
import ReactMarkdown from 'react-markdown/with-html';
import { TPA_PLAN_OPTION } from '../useNeedMoreTimeToPayForm';
import { featureFlagsContext } from '../../../providers/FeatureFlagsProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hdivider: {
      margin: theme.spacing(2),
    },
  }),
);
interface Props {
  path?: string;
  hasAvailablePEOptions: boolean;
  availablePEOptions: PaymentExtensionOption[];
  isEligibleForPETpa: boolean;
  onSelectionOptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selectedPEPlan: PaymentExtensionOption | null | undefined;
  onSelectOptionSubmit: () => Promise<void>;
  onCancel: () => Promise<void>;
  isTpaEligible: boolean;
  isTPAPlanSelected: boolean;
  isTPAFlagOverwrite?: boolean | null;
}

const PlanOptions: FC<Props> = (props: Props) => {
  //TODO: Once this is live, remove the feature flag and associated code incl. unit  tests.
  const { isTPAEnabled } = useContext(featureFlagsContext);
  const isTPAFeatureEnabled =
    props.isTPAFlagOverwrite !== (null || undefined)
      ? props.isTPAFlagOverwrite
      : isTPAEnabled;

  const { t, richT, inlineRichT } = useTranslation();
  const {
    hasAvailablePEOptions,
    availablePEOptions,
    isEligibleForPETpa,
    onSelectionOptionChange,
    selectedPEPlan,
    onSelectOptionSubmit,
    onCancel,
    isTpaEligible,
    isTPAPlanSelected,
  } = props;

  const classes = useStyles();

  const getPlanDescription = (plan: PaymentExtensionOption): ReactNode => {
    let transalationSlug: string = '';
    switch (plan?.paymentExtensionOptionType) {
      case PaymentExtensionOptionType.Option1: {
        transalationSlug = 'OPTION_ONE_NMTTP';
        break;
      }
      case PaymentExtensionOptionType.Option2: {
        transalationSlug = 'OPTION_TWO_NMTTP';
        break;
      }
      case PaymentExtensionOptionType.Option3: {
        transalationSlug = 'OPTION_THREE_NMTTP';
        break;
      }
    }

    return inlineRichT(transalationSlug, {
      AMOUNT: `$${formatCurrency(plan?.amount)}`,
      DATE: toDateString(plan?.planDate),
    });
  };

  return (
    <>
      {((!isTPAFeatureEnabled && !hasAvailablePEOptions) ||
        (isTPAFeatureEnabled && !hasAvailablePEOptions && !isTpaEligible)) && (
        <>
          <PaperBox>
            <Typography
              variant={'h2'}
              data-testid={'plan-options-not-available-h2'}
            >
              {t('OPTION_NOT_AVAILABLE')}
            </Typography>
            <Typography component={'div'}>
              <ReactMarkdown
                source={t('FOR_ASSISTANCE_CALL_AND_ENERGY_ASSISTANCE', {
                  CALL_FOR_ASSISTANCE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
                  BILL_PAY_ASSISTANCE_ROUTE: ROUTES.BILL_PAYMENT_ASSISTANCE,
                })}
                escapeHtml={false}
              />
            </Typography>
          </PaperBox>
          <Grid
            item
            xs={12}
            style={{
              marginTop: '1em',
            }}
          >
            <ProceedOrCancel
              proceedHandler={async () => await navigate(ROUTES.ACCOUNT)}
              proceedLabel={t('OK')}
              cancelLabel={t('BACK')}
              cancelRoute={ROUTES.NEED_MORE_TIME_TO_PAY}
            />
          </Grid>
        </>
      )}

      {(hasAvailablePEOptions || (isTPAFeatureEnabled && isTpaEligible)) && (
        <>
          <PaperBox>
            <Typography
              variant={'h2'}
              data-testid={'plan-options-available-h2'}
            >
              {t('PAYMENT_OPTIONS_NMTTP')}
            </Typography>
            {hasAvailablePEOptions && (
              <Typography>{t('YOU_ARE_ELIGIBLE_FOR_NMTTP')}</Typography>
            )}
            <Box mt={1} mb={1}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label={'payment-extension-options'}
                  name={'paymentExtensionOptions'}
                  onChange={onSelectionOptionChange}
                >
                  {hasAvailablePEOptions && (
                    <Grid
                      container
                      style={{ display: 'flex', justifyContent: 'center' }}
                      direction={'column'}
                    >
                      {availablePEOptions?.map((plan, index: number) => {
                        return (
                          <FormControlLabel
                            value={plan.paymentExtensionOptionType!}
                            control={<Radio color={'primary'} />}
                            label={getPlanDescription(plan)}
                            data-testid={`plan-option-item-${index}`}
                            key={`plan--option-item-${index}`}
                            checked={
                              selectedPEPlan?.paymentExtensionOptionType ===
                              plan.paymentExtensionOptionType
                            }
                          />
                        );
                      })}
                    </Grid>
                  )}
                  {isTPAFeatureEnabled && isTpaEligible && (
                    <Grid
                      container
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                      direction={'column'}
                    >
                      {hasAvailablePEOptions && (
                        // Only show the divider if PE Options are displayed above...
                        <Divider className={classes.hdivider} />
                      )}
                      <Typography variant={'h3'}>
                        {t('PAYMENT_PLAN_NMTTP')}
                      </Typography>

                      <FormControlLabel
                        value={TPA_PLAN_OPTION}
                        control={<Radio color={'primary'} />}
                        label={t('OPTION_TPA_NMTTP')}
                        data-testid={'plan-option-item-tpa'}
                        key={'plan--option-item-tpa'}
                        checked={isTPAPlanSelected}
                      />
                    </Grid>
                  )}
                </RadioGroup>
              </FormControl>
            </Box>
            {hasAvailablePEOptions && isTPAFeatureEnabled && !isTpaEligible && (
              <div>
                <Divider className={classes.hdivider} />
                <Typography variant={'h3'}>
                  {t('PAYMENT_PLAN_NMTTP')}
                </Typography>
                <Typography>
                  {richT('TPA_INELIGIBLE_NMTTP', {
                    PGE_PHONE: t('PGE_PHONE'),
                  })}
                </Typography>
              </div>
            )}

            {!isTPAFeatureEnabled && isEligibleForPETpa && (
              <Typography component={'div'}>
                {richT('TPA_ELGIBLE_NMTTP', {
                  CALL_FOR_ASSISTANCE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
                })}
              </Typography>
            )}
            <Typography component={'div'}>
              <ReactMarkdown
                source={t('IF_YOU_NEED_HELP_NMTTP', {
                  BILL_PAY_ASSISTANCE_ROUTE: ROUTES.BILL_PAYMENT_ASSISTANCE,
                })}
                escapeHtml={false}
              />
            </Typography>
          </PaperBox>
          <Grid
            item
            xs={12}
            style={{
              marginTop: '1em',
            }}
          >
            <ProceedOrCancel
              proceedDisabled={!Boolean(selectedPEPlan) && !isTPAPlanSelected}
              proceedLabel={t('NEXT')}
              proceedHandler={onSelectOptionSubmit}
              cancelLabel={t('BACK')}
              cancelHandler={onCancel}
              cancelRoute={ROUTES.NEED_MORE_TIME_TO_PAY}
            />
          </Grid>
        </>
      )}
    </>
  );
};

export default PlanOptions;
