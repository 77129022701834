import React from 'react';
import Modal from '@material-ui/core/Modal';
import Close from '@material-ui/icons/Close';
import { useStyles } from './styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import { useTranslation } from '../../../hooks/useTranslation';
import PGEButton from '../../buttons';

type Props = {
  open: boolean;
  onClose: () => void;
  title?: string;
  bodyText?: Array<string>;
};

export default function AlertsModal({ open, onClose, title, bodyText }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={onClose}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <div className={classes.callModal}>
        <div className={'closeButton'}>
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={onClose}
            data-testid="close-icon"
          >
            <Close />
          </IconButton>
        </div>
        {title ? (
          <Typography variant={'h2'} gutterBottom data-testid="modal-title">
            {title}
          </Typography>
        ) : null}
        {bodyText?.map((item, index) => {
          return (
            <Box
              mt={1}
              display={'flex'}
              flexDirection={'row'}
              alignItems={'baseline'}
              key={index}
            >
              <Typography
                variant={'h4'}
                className={classes.modalBodytext}
                data-testid={`modal-body-text-${index}`}
              >
                {item}
              </Typography>
            </Box>
          );
        })}
        <Box
          mt={1}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-end'}
          alignItems={'baseline'}   
        >
          <PGEButton onClick={onClose}>{t('OK')}</PGEButton>
        </Box>
      </div>
    </Modal>
  );
}
