import React, {
  FunctionComponent,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from '@material-ui/core';

import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import PaperBox from '../../utility/paper-box';
import Button from '../../buttons';
import useStyles from '../styles';
import PeakTimeRebatesNotificationPreferences from '../notification-preferences';
import { PeakTimeRebatesTransactionType } from '../types';
import PeakTimeRebatesConfirm from './confirm';
import PeakTimeRebatesSuccess from '../success';
import { FormState } from '../../../hooks/useFormState.types';
import { Model } from '../rules';
import colors from '../../../themes/main-colors';

interface Props {
  setTransactionType: Dispatch<SetStateAction<any>>;
  transactionType: PeakTimeRebatesTransactionType;
  handleOnChangeEnrollOrCancel: (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => Promise<void>;
  form: FormState<Model>;
  showNotificationPreferenceRequiredError: boolean;
  handleOnClickManageNext: () => Promise<void>;
  showConfirm: boolean;
  setShowConfirm: Dispatch<SetStateAction<any>>;
  handleOnFormSubmit: () => Promise<void>;
  isSubmitSuccessful: boolean;
  isPeakTimeGivingBackEligible: boolean;
}

const PeakTimeRebatesManageEnrollment: FunctionComponent<Props> = props => {
  const { t, richT } = useTranslation();
  const classes = useStyles();

  const {
    setTransactionType,
    transactionType,
    handleOnChangeEnrollOrCancel,
    form,
    showNotificationPreferenceRequiredError,
    handleOnClickManageNext,
    showConfirm,
    setShowConfirm,
    handleOnFormSubmit,
    isSubmitSuccessful,
    isPeakTimeGivingBackEligible,
  } = props;

  useEffect(() => {
    if (transactionType === PeakTimeRebatesTransactionType.Unknown) {
      setTransactionType(PeakTimeRebatesTransactionType.Update);
    }
  }, []);

  return (
    <Grid className={classes.root} container>
      {!showConfirm && !isSubmitSuccessful && (
        <>
          <Grid item xs={12} style={{ marginBottom: '-1em' }}>
            <Typography
              className={classes.colorTextPrimary}
              variant={'h4'}
              component="div"
            >
              {richT('MANAGE_YOUR_ENROLLMENT_NEW_PTR')}
            </Typography>
          </Grid>
          <PaperBox>
            <Grid item xs={12} style={{ marginBottom: '1em' }}>
              <Typography className={classes.colorTextPrimary} variant={'h2'}>
                {'Enrollment options'}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '1em' }}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="enrollment"
                  name="enrollment"
                  value={transactionType}
                  onChange={handleOnChangeEnrollOrCancel}
                >
                  <FormControlLabel
                    value={PeakTimeRebatesTransactionType.Update}
                    control={<Radio color={'primary'} />}
                    label="Enrolled"
                  />
                  <FormControlLabel
                    value={PeakTimeRebatesTransactionType.Cancel}
                    control={<Radio color={'primary'} />}
                    label="Unenroll from Peak Time Rebates"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Divider />
            {isPeakTimeGivingBackEligible && (
              <>
                <Grid
                  item
                  xs={12}
                  style={{ marginBottom: '1em', marginTop: '1em' }}
                >
                  <Typography
                    style={{ color: colors.sparkBlue }}
                    variant={'h2'}
                    component="div"
                  >
                    {t('PTR_MANAGE_SMART_GRID_HEADER')}
                  </Typography>
                  <Typography
                    className={classes.colorTextPrimary}
                    variant={'body2'}
                    component="div"
                  >
                    {richT('PTR_MANAGE_SMART_GRID_GIVING_BACK_MESSAGE', {
                      PEAKTIME_REBATES_GIVING_BACK_ROUTE:
                        ROUTES.PEAKTIME_REBATES_GIVING_BACK,
                    })}
                  </Typography>
                </Grid>
                <Divider />
              </>
            )}
            <Grid item xs={12} style={{ marginBottom: '1em' }}>
              <PeakTimeRebatesNotificationPreferences
                form={form}
                showNotificationPreferenceRequiredError={
                  showNotificationPreferenceRequiredError
                }
              />
            </Grid>
          </PaperBox>
          <Grid container justify="flex-end" style={{ marginTop: '.5em' }}>
            <Grid item>
              <Button
                variant={'contained'}
                color={'primary'}
                className={classes.submitButton}
                onClick={handleOnClickManageNext}
              >
                {t('NEXT')}
              </Button>
            </Grid>
          </Grid>
        </>
      )}

      {showConfirm && !isSubmitSuccessful && (
        <Grid item xs={12}>
          <Typography
            className={classes.colorTextPrimary}
            variant={'h4'}
            component="div"
          >
            {richT('MANAGE_YOUR_ENROLLMENT_NEW_PTR')}
          </Typography>
          <PeakTimeRebatesConfirm
            transactionType={transactionType}
            emailAddress={form.values.notificationEmail}
            phoneNumber={form.values.notificationTextNumber}
            setShowConfirm={setShowConfirm}
            handleOnClickManageSubmit={handleOnFormSubmit}
          />
        </Grid>
      )}

      {isSubmitSuccessful && (
        <PeakTimeRebatesSuccess transactionType={transactionType} />
      )}
    </Grid>
  );
};

export default PeakTimeRebatesManageEnrollment;
