import React, { FunctionComponent as FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { navigate } from 'gatsby';
import { useTranslation } from '../../hooks/useTranslation';
import { Paper, makeStyles, createStyles } from '@material-ui/core';
import colors from '../../themes/main-colors';
import ProceedOrCancel from '../proceed-or-cancel';
import ROUTES from '../../routes';
import Alerts from '../alerts';

const useStyles = makeStyles(() =>
  createStyles({
    updateInfoContainer: {
      color: colors.noirBlur,
      marginBottom: '15px',
      '& .MuiAlert-message': {
        width: '100%',
      },
    },
    alertTitle: {
      fontWeight: 300,
      margin: '-10px 0 0',
      fontFamily: 'Forma-DJR-Display',
    },
    doneBtnHolder: {
      padding: '8px',
    },
  }),
);

const UpdateInfoConfirmation = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Paper className={classes.updateInfoContainer}>
        <Alerts
          disableColorOverride={true}
          severity={'success'}
          variant={'outlined'}
          title={<h2 className={classes.alertTitle}>{t('SUCCESS')}</h2>}
          message={
            <>
              <Typography variant="body1">
                {t('WE_HAVE_RECEIVED_YOUR_REQUEST')}
              </Typography>
              <div className={classes.doneBtnHolder}>
                <ProceedOrCancel
                  proceedLabel={t('DONE')}
                  proceedHandler={async () => {
                    await navigate(ROUTES.ACCOUNT);
                  }}
                  cancelRoute={false}
                />
              </div>
            </>
          }
          isOpen
        />
      </Paper>
    </>
  );
};

export default UpdateInfoConfirmation;
