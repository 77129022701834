/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FC, ReactNode, useState, useEffect } from 'react';
import { Box, FormHelperText, Grid } from '@material-ui/core';
import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import routes from '../../../routes';
import { navigate } from 'gatsby';
import useContentMgmt from '../../../hooks/useContentMgmt';

import { Question } from '../../pge-plus-common/Typography';
import { GridContainer, GridItem } from '../../pge-plus-common/Grid';
import { DropdownMenu } from '../../pge-plus-common/DropdownMenu';
import { FullDivider } from '../../pge-plus-common/FullDivider';
import { CheckBoxes } from '../../pge-plus-common/CheckBoxes';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';
import { Installation as InstallationFormType } from '../evcharger.types';
import useFormState from '../../../hooks/useFormState';
import { createInstallationValidateFunction } from '../../pge-plus-common/PGEPlusForm.rules';
import {
  DayOfWeek,
  TimeFrame,
  SectionEvInstall,
  ApplicationEvSectionType,
} from '../../../__generated__/pgeplus-types';
import { titleCase } from '../../../util/string-utils';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { SaveAndComeBackLink } from '../../pge-plus-common/SaveAndComeBackLink';

import useUpdateEVSections from '../../../hooks/pge-plus/useUpdateEVSections';
import { installationFormStateAdapter } from '../../pge-plus-common/utils/pgeplusFormstateAdapters';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import useUtil from '../../../hooks/pge-plus/useUtil';

interface InstallationProps {
  children?: ReactNode;
  path: string;
}

export const Installation: FC<InstallationProps> = props => {
  const { wrapWithLoader } = useWrapWithLoader();

  const {
    formState: { installation, selectCharger, application },
    updateFormState,
    getSectionData,
  } = useEVChargerFormState();

  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLATION_LOCATION,
  );

  const form = useFormState(installation, {
    validate: createInstallationValidateFunction(),
    validationContext: {
      content: content,
    },
  });

  const { updateSectionEVInstall } = useUpdateEVSections();
  const sectionDataInstallationInfo: SectionEvInstall = getSectionData(
    ApplicationEvSectionType.EvInstall,
  );
  const { setErrorNotification } = useUtil();

  const submitSection = async (
    data: InstallationFormType,
    saveAndComeBack: boolean = false,
  ) => {
    return updateSectionEVInstall(
      installationFormStateAdapter().fromFormState(
        data,
        application?.id!,
        sectionDataInstallationInfo?.id,
        saveAndComeBack,
      ),
    );
  };

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      await submitSection(form.values, true);
      void navigate(routes.ACCOUNT);
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const onSubmit = (data: InstallationFormType) => {
    //Store the data in global store
    updateFormState('installation', data);

    //Navigate to the next screen
    void navigate(routes.PGE_PLUS_EV_CHARGERS_INSTALLATION_LOCATION);
  };

  const howSoonOptions = [
    {
      label: content.get('URGENCY_DROPDOWN_OPTION_1')!,
      value: content.get('URGENCY_DROPDOWN_OPTION_1')!,
    },
    {
      label: content.get('URGENCY_DROPDOWN_OPTION_2')!,
      value: content.get('URGENCY_DROPDOWN_OPTION_2')!,
    },
    {
      label: content.get('URGENCY_DROPDOWN_OPTION_3')!,
      value: content.get('URGENCY_DROPDOWN_OPTION_3')!,
    },
    {
      label: content.get('URGENCY_DROPDOWN_OPTION_4')!,
      value: content.get('URGENCY_DROPDOWN_OPTION_4')!,
    },
  ];

  const dayOfTheWeekOptions = [
    { label: titleCase(DayOfWeek.Monday), value: DayOfWeek.Monday },
    { label: titleCase(DayOfWeek.Tuesday), value: DayOfWeek.Tuesday },
    { label: titleCase(DayOfWeek.Wednesday), value: DayOfWeek.Wednesday },
    { label: titleCase(DayOfWeek.Thursday), value: DayOfWeek.Thursday },
    { label: titleCase(DayOfWeek.Friday), value: DayOfWeek.Friday },
  ];

  const timeOfTheWeekOptions = [
    {
      label: content.get('TIME_SLOT_1')! || '',
      value: content.get('TIME_SLOT_1') || '',
    },
    {
      label: content.get('TIME_SLOT_2')! || '',
      value: content.get('TIME_SLOT_2') || '',
    },
    {
      label: content.get('TIME_SLOT_3')! || '',
      value: content.get('TIME_SLOT_3') || '',
    },
  ];

  return (
    <Box
      css={css`
        max-width: 790px;
        margin: auto;
      `}
    >
      <form
        onSubmit={form.submit(values => onSubmit(values), console.log)}
        noValidate
      >
        <Grid
          container
          css={{
            rowGap: '24px',
            '.MuiTypography-body1': {
              marginTop: 0,
            },
          }}
        >
          <Question
            title={content.get('HOW_SOON_NEED_INSTALLATION')}
            detail={content.get('HOW_SOON_NEED_INSTALLATION_DESCRIPTION')}
            popoverContent={content.get(
              'HOW_SOON_LIKE_YOUR_INSTALLATION_TOOLTIP',
            )}
          />

          <GridItem>
            <DropdownMenu
              name={content.get('SELECT_URGENCY') || ''}
              options={howSoonOptions}
              state={form.values.urgency || ''}
              setState={e => {
                void form.setValue('urgency', e);
              }}
              errorMsg={(form.errors.urgency as string) || ''}
            />
          </GridItem>

          <FullDivider
            style={{
              margin: '0',
            }}
          />

          <Question
            title={content.get('WHEN_YOU_NEED_YOUR_CHARGER_INSTALLED')}
            detail={content.get(
              'WHEN_YOU_NEED_YOUR_CHARGER_INSTALLED_DESCRIPTION',
            )}
          />

          <Question
            title={content.get('DAY_OF_THE_WEEK')}
            popoverContent={content.get('DAY_OF_THE_WEEK_TOOLTIP')}
          />
          <GridItem>
            <CheckBoxes
              options={dayOfTheWeekOptions}
              state={form.values.dayOfWeek || []}
              setState={e => {
                void form.setValue('dayOfWeek', e);
              }}
            />
            {form.errors.dayOfWeek && (
              <FormHelperText error>{form.errors.dayOfWeek}</FormHelperText>
            )}
          </GridItem>
          <Question
            title={content.get('TIME_OF_THE_WEEK')}
            popoverContent={content.get('TIME_OF_THE_WEEK_TOOLTIP')}
          />
          <GridItem>
            <CheckBoxes
              options={timeOfTheWeekOptions}
              state={form.values.preferredServiceTime || []}
              setState={e => {
                void form.setValue('preferredServiceTime', e);
              }}
            />
            {form.errors.preferredServiceTime && (
              <FormHelperText error>
                {form.errors.preferredServiceTime}
              </FormHelperText>
            )}
          </GridItem>
          <FullDivider
            style={{
              margin: '0',
            }}
          />
          <ProceedOrCancelButton
            back={
              selectCharger.suppliedByCustomer
                ? routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY
                : routes.PGE_PLUS_EV_CHARGERS_SELECT_CHARGER
            }
            disable={
              !form.values.urgency ||
              !form.values.dayOfWeek ||
              form.values.dayOfWeek?.length === 0 ||
              !form.values.preferredServiceTime ||
              form.values.preferredServiceTime?.length === 0
                ? true
                : false
            }
          />

          <GridItem css={{ marginTop: 24 }}>
            <SaveAndComeBackLink
              content={content}
              onSaveAndExit={handleSaveAndExit}
            />
          </GridItem>
        </Grid>
      </form>
    </Box>
  );
};
