/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { Fragment, useState } from 'react';
import { RouteComponentProps, useLocation, navigate } from '@reach/router';
import { Box, Link, Typography } from '@material-ui/core';

import { ProceedOrCancelButton } from '../../../../pge-plus-common/StepperForm';
import ROUTES from '../../../../../routes';
import colors from '../../../../../themes/main-colors';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import { SaveAndComeBackLink } from '../../../../pge-plus-common/SaveAndComeBackLink';
import { getFormattedCurrencyString } from '../../../../../util/format';
import { PgeEvModel } from '../../../../pge-plus-common/PgeEvModel';

const EligibleForRebates: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const [isPGEEvModelVisible, setIsPGEEvModelVisible] = useState<boolean>(
    false,
  );
  const { content, isContentLoading, inlineRichText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_REBATE_CONFIRM,
  );

  const location = useLocation();
  const locationState: any = location.state;

  const mainContent =
    locationState?.rebateType === 'standard' ? (
      <Fragment>
        {inlineRichText(
          content.get('REBATE_ONLY_ELIGIBILITY_TEXT_STANDARD_1'),
          {
            CHARGER_AMOUNT: getFormattedCurrencyString(
              locationState?.chargerAmount || '',
            ),
            PANEL_UPGRADE: getFormattedCurrencyString(
              locationState?.panelUpgradeAmount || '',
            ),
          },
        )}
        <Link
          css={{ fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => setIsPGEEvModelVisible(true)}
        >
          {content.get('REBATE_ONLY_ELIGIBILITY_TEXT_STANDARD_2')}
        </Link>
      </Fragment>
    ) : locationState?.rebateType === 'higher' ? (
      <Fragment>
        {content.get('REBATE_ONLY_ELIGIBILITY_TEXT_HIGHER_1')}
        <Link
          css={{ fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => setIsPGEEvModelVisible(true)}
        >
          {content.get('REBATE_ONLY_ELIGIBILITY_TEXT_HIGHER_2')}
        </Link>
        {inlineRichText(content.get('REBATE_ONLY_ELIGIBILITY_TEXT_HIGHER_3'), {
          CHARGER_AMOUNT: getFormattedCurrencyString(
            locationState?.chargerAmount || '',
          ),
          PANEL_UPGRADE: getFormattedCurrencyString(
            locationState?.panelUpgradeAmount || '',
          ),
        })}
      </Fragment>
    ) : (
      <Fragment>
        {inlineRichText(content.get('REBATE_ONLY_ELIGIBILITY_TEXT_IQBD_1'), {
          CHARGER_AMOUNT: getFormattedCurrencyString(
            locationState?.chargerAmount || '',
          ),
          PANEL_UPGRADE: getFormattedCurrencyString(
            locationState?.panelUpgradeAmount || '',
          ),
        })}
        <Link
          css={{ fontWeight: 'bold', cursor: 'pointer' }}
          onClick={() => setIsPGEEvModelVisible(true)}
        >
          {content.get('REBATE_ONLY_ELIGIBILITY_TEXT_IQBD_2')}
        </Link>
      </Fragment>
    );

  const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    void navigate(ROUTES.PGE_PLUS_REBATE_ONLY_CHARGER_INFORMATION, {
      state: {
        backButtonRoute:
          locationState?.rebateType === 'standard'
            ? ROUTES.PGE_PLUS_REBATE_ONLY_STANDARD_REBATES_ELIGIBILE
            : ROUTES.PGE_PLUS_REBATE_ONLY_HIGHER_REBATES_ELIGIBILE,
      },
    });
  };

  const handleSaveAndExit = () => {
    void navigate(ROUTES.ACCOUNT);
  };

  if (isContentLoading) {
    return null;
  }

  return (
    <form onSubmit={handleSubmit}>
      <Box>
        <Typography
          css={{
            fontFamily: 'Untitled-Sans',
            fontSize: 20,
            color: colors.noirBlur,
            marginBottom: '26px',
            marginTop: '20px',
            display: 'inline-block',
          }}
        >
          {mainContent}
        </Typography>
        <ProceedOrCancelButton
          back={
            locationState?.isAbleToRequestIQBD
              ? ROUTES.PGE_PLUS_REBATE_ONLY_HIGHER_REBATES_CHECK
              : ROUTES.PGE_PLUS_REBATE_ONLY_CUSTOMER_INFORMATION
          }
          backLabel={content.get('REUSABLE_BUTTON_BACK')}
        />
      </Box>
      <Box mt={6}>
        <SaveAndComeBackLink
          content={content}
          onSaveAndExit={handleSaveAndExit}
        />
      </Box>
      <PgeEvModel
        isVisible={isPGEEvModelVisible}
        setIsVisible={setIsPGEEvModelVisible}
      />
    </form>
  );
};

export default EligibleForRebates;
