import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { Box, Typography } from '@material-ui/core';
import NumericTextField from '../../numeric-text-field';
import { EmailTextToggleControl } from './EmailTextToggle';
import { Model, AlertState, AlertOptions } from './types';
import { useStyles } from './styles';
import { useTranslation } from '../../../hooks/useTranslation';
import { FormState } from '../../../hooks/useFormState.types';
import { useIsMobile } from '../../../util/style-utils';
import { NotificationType } from '../../../__generated__/pge-types';

function sanitizeNumber(num: string) {
  if (num.startsWith('$')) {
    return num.slice(1).replace(/,/g, '');
  }
  return num;
}

type Props = {
  alertOptions: AlertOptions[];
  form: FormState<Model>;
  headingName: string;
  subHeading: string;
  categoryName: string;
  handleNavigate: (route: string) => void;
};

export default function({
  alertOptions,
  form,
  headingName,
  subHeading,
  categoryName,
  handleNavigate,
}: Props) {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <>
      {!isMobile ? (
        <Accordion
          defaultExpanded
          className={classes.userContactDetailsAccordion}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.gridContainer}>
              <div className={classes.alertAccordionHeading}>{headingName}</div>
              <div>{subHeading}</div>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.alertsInfo}>
            {renderDetails(
              alertOptions,
              form,
              null,
              null,
              categoryName,
              handleNavigate,
            )}
          </AccordionDetails>
        </Accordion>
      ) : (
        <div className={classes.renderAlertsCard}>
          {renderDetails(
            alertOptions,
            form,
            headingName,
            subHeading,
            categoryName,
            handleNavigate,
          )}
        </div>
      )}
    </>
  );
}
const renderDetails = (
  alertOptions: AlertOptions[],
  form: FormState<Model>,
  headingName: string | null,
  subHeading: string | null,
  categoryName: string,
  handleNavigate: (route: string) => void,
) => {
  const classes = useStyles();
  const { t, inlineRichT } = useTranslation();
  const isMobile = useIsMobile();
  const getEmailFilteredData = () => {
    return alertOptions.filter(
      data =>
        !data.hideEmail &&
        !data.emailDisabled &&
        data.key !== NotificationType.Ptrevent,
    );
  };
  const isHideText = alertOptions?.every(option => !option.isSMSAllowed);
  const getSMSFilteredData = () => {
    return alertOptions.filter(data => !data.hideText && !data.textDisabled);
  };
  const getEmailData = () => {
    return getEmailFilteredData()
      .map((item: AlertOptions) => {
        return (form.values[item.key as keyof Model] as AlertState) || {};
      })
      ?.reduce(
        (currentAlertState, result) => {
          return {
            email: currentAlertState.email && result.email,
          };
        },
        { email: true },
      );
  };
  const getTextData = () => {
    return getSMSFilteredData()
      .map((item: AlertOptions) => {
        return (form.values[item.key as keyof Model] as AlertState) || {};
      })
      ?.reduce(
        (currentAlertState, result) => {
          return {
            text: currentAlertState.text && result.text,
          };
        },
        { text: true },
      );
  };
  const selectAllData = { ...getEmailData(), ...getTextData() };
  const displayHeading = () => {
    const heading = [];
    if (categoryName !== 'OUNO') {
      heading.push(
        <Box>
          <Typography variant={'body1'} style={{ paddingLeft: 5 }}>
            {t('EMAIL')}
          </Typography>
        </Box>,
      );
    }
    if (!isHideText) {
      heading.push(
        <Box>
          <Typography variant={'body1'} style={{ paddingLeft: 5 }}>
            {t('TEXT')}*
          </Typography>
        </Box>,
      );
    }
    if (heading.length === 1) {
      heading.unshift(<Box></Box>);
    }
    return heading;
  };
  return (
    <>
      {isMobile && (
        <div>
          <Typography className={classes.alertGroupHeading}>
            {headingName}
          </Typography>
          <Typography>{subHeading}</Typography>
        </div>
      )}
      <div className={classes.gridContainer}>
        <div className={classes.row}>
          <Box className={isMobile ? classes.notificationTypeData : ''}>
            <Typography variant={'body1'}></Typography>
          </Box>
          {displayHeading()}
        </div>
        <EmailTextToggleControl
          emailDisabled={getEmailFilteredData().length === 0}
          textDisabled={getSMSFilteredData().length === 0}
          isMultiSelect={true}
          value={selectAllData}
          handleNavigate={handleNavigate}
          hideTextSection={isHideText}
          hideEmailSection={categoryName === 'OUNO'}
          onChange={(newValue, kind) => {
            if (kind === 'email') {
              alertOptions.forEach(data => {
                const oldValue = form.values[
                  data.key as keyof Model
                ] as AlertState;
                if (
                  !data.emailDisabled &&
                  !data.hideEmail &&
                  data.key !== NotificationType.Ptrevent
                ) {
                  const updatedValue = {
                    email: newValue.email,
                    text: oldValue.text,
                  };
                  return form.setValue(data.key as keyof Model, updatedValue);
                }
              });
            } else {
              alertOptions.forEach(data => {
                const oldValue = form.values[
                  data.key as keyof Model
                ] as AlertState;
                if (
                  !data.textDisabled &&
                  !data.hideText &&
                  data.key !== NotificationType.Ptrevent
                ) {
                  const updatedValue = {
                    email: oldValue.email,
                    text: newValue.text,
                  };
                  return form.setValue(data.key as keyof Model, updatedValue);
                }
              });
            }
          }}
          key="select"
          label={'Select All'}
          children={undefined}
          tooltipValue={inlineRichT('SELECT_ALL_BOLD')}
          classification={''}
          notificationTypeSequence={0}
          notificationGroupSequence={0}
          classificationSequence={0}
        />
        {alertOptions.map((item: AlertOptions) => {
          const value =
            (form.values[item.key as keyof Model] as AlertState) || {};
          const anyChosen = value.text || value.email;

          return (
            <EmailTextToggleControl
              value={value}
              name={item.key}
              handleNavigate={handleNavigate}
              hideTextSection={isHideText}
              hideEmailSection={categoryName === 'OUNO'}
              onChange={newValue => {
                return form.setValue(item.key as keyof Model, newValue);
              }}
              tooltipValue={item.key || ''}
              {...item}
            >
              {item.textFieldKey && anyChosen ? (
                <NumericTextField
                  className={classes.subField}
                  style={{ width: 140 }}
                  maxLength={5}
                  prefix="$"
                  allowDecimal
                  inputProps={{ type: 'number' }}
                  variant={'outlined'}
                  {...form.props(item.textFieldKey as keyof Model)}
                  error={
                    anyChosen &&
                    Boolean(form.errors[item.textFieldKey as keyof Model])
                  }
                  helperText={
                    anyChosen && form.errors[item.textFieldKey as keyof Model]
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    form.setValue(
                      item.textFieldKey as keyof Model,
                      sanitizeNumber(e.target.value),
                    )
                  }
                />
              ) : null}
            </EmailTextToggleControl>
          );
        })}
      </div>
    </>
  );
};
