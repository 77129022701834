import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect } from 'react';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { useTranslation } from '../../../hooks/useTranslation';
import TextField from '../../text-field';
import { TextFieldProps } from '../../text-field/TextField';
import MaskedInput from 'react-text-mask';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

interface NumberFormatCustomProps {
  [x: string]: any;
  inputRef: (instance: HTMLElement | null) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  max?: number;
}

const numberMask = createNumberMask({
  prefix: '$',
  integerLimit: 8,
  includeThousandsSeparator: true,
  allowLeadingZeroes: false,
  allowDecimal: true,
});

const mask = (val: string, ...args: any) => {
  return numberMask(val, ...args);
};

function sanitizeNumber(num: string) {
  if (num.startsWith('$')) {
    return num.slice(1).replace(/,/g, '');
  }
  return num;
}
function addDecimalsAsReqd(value: string) {
  if (value) {
    const idx = value.indexOf('.');

    return idx < 0
      ? `${value}.00`
      : idx === value.length - 1
      ? `${value}00`
      : idx === value.length - 2
      ? `${value}0`
      : value;
  }

  return value;
}

function sanitizeWithDecimals(value: string) {
  return value && sanitizeNumber(addDecimalsAsReqd(value));
}

const changeHandler = (
  onChange: (event: { target: { name: string; value: string } }) => void,
) => (name: string, value: string) => {
  if (onChange) {
    onChange({
      target: {
        name,

        value,
      },
    });
  }
};
function MaskedNumberFormat({
  inputRef,
  onChange,
  ...props
}: NumberFormatCustomProps) {
  const setRef = useCallback((ref: MaskedInput) => {
    inputRef(ref ? ref.inputElement : ref);
  }, []);
  const handleChange = changeHandler(onChange);

  useEffect(() => {
    handleChange(props.name, sanitizeWithDecimals(props.value));
  }, []);

  return (
    <MaskedInput
      data-testid={'numeric-text-field'}
      {...props}
      onBlur={e => {
        if (props.onBlur) {
          props.onBlur(e);
        }

        handleChange(props.name, sanitizeWithDecimals(e.target.value));
      }}
      onChange={e => {
        if (onChange) {
          onChange({
            target: {
              name: props.name,
              value: sanitizeNumber(e.target.value),
            },
          });
        }
      }}
      ref={setRef}
      mask={mask}
      placeholder="$"
      guide={false}
      showMask
    />
  );
}

export default (props: TextFieldProps | any) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        name={props.name || 'amount'}
        label={props.label || t('PAYMENT_AMOUNT')}
        onChange={props.onChange}
        onBlur={props.onBlur}
        InputProps={{ inputComponent: MaskedNumberFormat as any }}
        error={props.error}
        helperText={props.helperText}
        disabled={props.disabled}
        value={
          Boolean(props?.defaultValue)
            ? props.defaultValue.toString().replace('$', '')
            : null
        }
        {...props}
        style={props.style}
      />
    </div>
  );
};
