/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';
import {
  Grid,
  Box,
  CircularProgress,
  Typography,
  useTheme,
} from '@material-ui/core';

import { ProgressStep } from './ProgressStepper';

import colors from '../../../themes/main-colors';

type MobileProgressBarProps = {
  totalSteps: number;
  currentStep: number;
  currentTitle: string;
  nextTitle: string;
  steps: Array<ProgressStep>;
};

const MobileProgressBar = ({
  totalSteps,
  currentStep,
  currentTitle,
  nextTitle,
  steps,
}: MobileProgressBarProps) => {
  const isComplete = currentStep === totalSteps;
  const theme = useTheme();

  const progressPercent = (currentStep / totalSteps) * 100;

  return (
    <Grid
      container
      spacing={2}
      css={{
        display: 'inline-flex',
        justify: 'center',
        flexWrap: 'nowrap',
        alignItems: 'center',
      }}
    >
      <Grid item sm={7}>
        <Box position="relative" display="flex" justifyContent="center">
          <CircularProgress
            variant="determinate"
            value={100}
            size={75}
            css={{
              color: theme.palette.grey[200],
            }}
          />
          <CircularProgress
            variant="determinate"
            value={progressPercent}
            size={75}
            css={{
              position: 'absolute',
              top: 0,
              left: 0,
              strokeLinecap: 'round',
              display: 'flex',
              justifyContent: 'center',
              color: colors.shoreGreen,
              width: '100% !important',
              height: '100% !important',
            }}
          />
          <Typography
            component="div"
            css={{
              position: 'absolute',
              fontSize: '1.2rem',
              top: 0,
              left: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
            }}
          >
            <b>{currentStep}</b>
            <span>&nbsp;of&nbsp;</span>
            <b> {totalSteps}</b>
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        sm={5}
        css={{
          display: isComplete ? 'flex' : 'block',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Grid
          container
          css={{
            display: 'flex',
            flexDirection: 'column',
            paddingTop: isComplete ? 0 : theme.spacing(1),
          }}
        >
          <Grid item>
            <Typography
              variant="h2"
              css={{
                fontSize: '1.5rem',
                fontWeight: 'bold',
                fontFamily: 'Untitled-Sans',
                color: colors.noirBlur,
              }}
            >
              {/* Last step title is shown when all steps are completed */}
              {currentTitle || (isComplete && steps[steps.length - 1].title)}
            </Typography>
          </Grid>
          {!isComplete && (
            <Grid
              item
              css={{
                color: colors.darkGray2,
                fontFamily: 'Untitled-Sans',
              }}
            >
              {nextTitle}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MobileProgressBar;
