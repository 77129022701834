import React, {
  useEffect,
  useState,
  useMemo,
  ChangeEvent,
  useReducer,
  useRef,
} from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Autocomplete from '@material-ui/lab/Autocomplete';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import DatePicker from '../date-picker';
import TextField from '../text-field';
import { useTranslation } from '../../hooks/useTranslation';
import { Paper } from '@material-ui/core';
import colors from '../../themes/main-colors';
import ROUTES from '../../routes';
import ZipInputField from '../zip-input-field/ZipInputField';
import { IsThisABusinessAddressToolTip } from '../is-this-a-business-address-tooltip';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import moment from 'moment';
import { useSearchServiceAddressQuery } from '../start-service/useSearchServiceAddressQuery';
import { useMoveToServiceAddressEligibility } from './move-service-eligibility/useMoveToServiceAddressEligibility';
import {
  SearchServiceAddress,
  Maybe,
  PremiseType,
  ServiceAddressEligibilityType,
} from '../../__generated__/pge-types';
import { navigate } from '@reach/router';
import debounce from 'lodash/debounce';
import { NextBackOrCancel } from '../next-back-or-cancel';
import useAuthQuery from '../../hooks/useAuthQuery';
import {
  getAccountsList,
  getSelectedAccount,
} from '../account-dropdown/queries';
import useAccountDetailList from '../../hooks/useAccountDetailList';
import Backdrop from '../backdrop';
import useAccountListHeader from '../account-summary/multi-accounts/useAccountListHeader';
import { scrollToErrors } from '../../util/form-validation';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';

const getDateXDaysFromNow = (daysFromNow: number): Date => {
  return moment()
    .add(daysFromNow - 1, 'days')
    .toDate();
};

interface Props {
  form: any;
  handleServiceDetailSubmit: any;
  isMobile: boolean;
  isAuthenticated: boolean;
  path: string;
  accountNumber: string | undefined;
  encryptedPersonId: string | undefined;
}

export const MoveServiceForm = ({
  form,
  handleServiceDetailSubmit,
  accountNumber,
  encryptedPersonId,
}: Props) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const {
    selectedGroupId,
    accountParams,
    loading: useSelectedAccountParamsLoading,
    encryptedAccountNumber,
  } = useSelectedAccountParams();

  const { accountListParams } = useAccountListHeader();
  const { wrapWithLoader } = useWrapWithLoader();

  const anchorRef = useRef<any>();

  const { detailList, loading: loadingAccounts } = useAccountDetailList(
    getAccountsList,
    selectedGroupId,
    {
      accountListParams,
    },
  );

  const { data: accountDetailsListData } = useAuthQuery(getSelectedAccount, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams || !detailList || useSelectedAccountParamsLoading,
  });

  const selectedAccount = accountDetailsListData?.getAccountDetails?.[0];

  const {
    setParams,
    data: moveServiceEligibilityData,
    error,
  } = useMoveToServiceAddressEligibility();
  const [isNextEnabled, setNextEnabled] = useState<boolean>(false);
  const [noOptionsText, setNoOptionsText] = useState<string>(
    t('START_TYPING_TO_SEARCH_FOR_ADDRESS_SELECT'),
  );

  const [
    isThisABusinessAddressTooltipAnchor,
    setIsThisABusinessAddressTooltipAnchor,
  ] = useState<HTMLElement | null>(null);

  const openBusinessAddressTooltip = () => {
    setIsThisABusinessAddressTooltipAnchor(anchorRef.current);
  };

  const closeBusinessAddressTooltip = () => {
    setIsThisABusinessAddressTooltipAnchor(null);
  };

  const [autoCompleteAddress, setAutoCompleteAddress] = useState<
    SearchServiceAddress
  >();
  const {
    loading,
    data,
    setSearchString,
    searchString,
  } = useSearchServiceAddressQuery();
  const setNewSearchString = (newSearchString: string) =>
    newSearchString &&
    newSearchString.trim().length >= 3 &&
    setSearchString(newSearchString);

  const debounceSetSearchString = useMemo(
    //ref: https://css-tricks.com/debouncing-throttling-explained-examples/
    () => debounce(setNewSearchString, 250),
    [],
  );

  const formComplete = (): boolean => {
    return (
      Object.keys(form.errors).length === 0 &&
      Object.keys(form.values).every((key: string) => !!form.values[key])
    );
  };

  useEffect(() => {
    if (searchString) {
      setNoOptionsText(t('NO_MATCHING_ADDRESSES_FOUND'));
    }
  }, [searchString]);

  useEffect(() => {
    if (error) {
      setIsSubmitting(false);

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.MOVE_SERVICE_ASSISTANCE);
    }
  }, [error]);

  useEffect(() => {
    setNextEnabled(formComplete());
  }, [form.values, form.errors]);

  useEffect(() => {
    // an autocomplete address was selected, then we want to populate the other fields
    if (autoCompleteAddress) {
      form.setValue('address', autoCompleteAddress?.addressLine1);
      form.setValue('city', autoCompleteAddress?.city);
      form.setValue('zip', autoCompleteAddress?.postal);
    }
  }, [autoCompleteAddress]);

  useEffect(() => {
    setIsSubmitting(false);

    const isEligible =
      moveServiceEligibilityData?.moveToServiceAddressEligibility?.isEligible;

    // if the address is eligible then submit step 1 of form and move to step 2
    if (moveServiceEligibilityData !== undefined && isEligible) {
      const startDateMoreThan19DaysAfterStopDate = moment(
        form.values.startDate,
        'MM/DD/YYYY',
      )
        .startOf('day')
        .isAfter(
          moment(form.values.stopDate, 'MM/DD/YYYY')
            .startOf('day')
            .add(19, 'day'),
        );

      if (
        startDateMoreThan19DaysAfterStopDate ||
        form.values.livingRemainAtProperty === t('YES')
      ) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate(ROUTES.MOVE_SERVICE_ASSISTANCE);
      } else {
        const submitWithEligibility = handleServiceDetailSubmit(
          moveServiceEligibilityData?.moveToServiceAddressEligibility,
        );
        form.submit(submitWithEligibility(form.values));
      }
    } else if (moveServiceEligibilityData !== undefined && !isEligible) {
      // check if we should navigate to default error page or more specific error page
      if (
        moveServiceEligibilityData?.moveToServiceAddressEligibility
          ?.serviceAddressEligibilityType ===
        ServiceAddressEligibilityType.CommercialBuilding
      ) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate(ROUTES.MOVE_SERVICE_ADDRESS_COMMERICAL_INELIGIBLE);
      } else if (
        moveServiceEligibilityData?.moveToServiceAddressEligibility
          ?.serviceAddressEligibilityType ===
        ServiceAddressEligibilityType.AddressNotFound
      ) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate(ROUTES.MOVE_SERVICE_ADDRESS_INELIGIBLE);
      } else {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate(ROUTES.MOVE_SERVICE_ASSISTANCE);
      }
    }
  }, [moveServiceEligibilityData]);

  const validateMoveServiceEligibility = (): void => {
    setIsSubmitting(true);

    setParams({
      serviceAddress: {
        addressLine1: form.values.address,
        city: form.values.city,
        state: 'OR',
        postal: form.values.zip,
      },
      encryptedAccountNumber: encryptedAccountNumber || '',
      selectedPremiseType:
        form.values.ownOrRent === t('RENT')
          ? PremiseType.Rent
          : PremiseType.Own,
    });
  };

  if (loadingAccounts) {
    return <Backdrop message={t('LOADING_ACCOUNT_INFORMATION')} forceOpen />;
  }

  return (
    <>
      {isSubmitting && (
        <Backdrop message="Checking service address eligibility" forceOpen />
      )}
      <Paper style={{ padding: 30, color: colors.noirBlur, marginBottom: 30 }}>
        <Typography variant="h2">{t('WE_SHOW_YOU_MOVING_FROM')}</Typography>
        <Typography
          variant="body1"
          style={{
            textAlign: 'center',
            background: colors.lightGray2,
            padding: 15,
            marginBottom: 25,
            marginTop: 25,
          }}
        >
          {selectedAccount?.premiseInfo?.[0]?.addressLine1}
        </Typography>
        <Typography variant="h2">
          {t('WHEN_DO_YOU_WANT_TO_STOP_SERVCE')}
        </Typography>

        <Grid item xs={12} md={7}>
          <DatePicker
            style={{ width: '100%' }}
            label={t('STOP_SERVICE_DATE')}
            name="stopDate"
            minDate={new Date()}
            maxDate={getDateXDaysFromNow(90)}
            {...form.props('stopDate')}
            data-cy="stop-field"
          />
        </Grid>

        <Typography
          variant="h2"
          className={
            !!form.errors.livingRemainAtProperty
              ? 'MuiFormHelperText-contained Mui-error'
              : ''
          }
          style={{
            color: !!form.errors.livingRemainAtProperty ? 'red' : 'inherit',
            paddingTop: 20,
            paddingBottom: 12,
          }}
        >
          {t('WILL_ANYONE_LIVING_REMAIN')}
        </Typography>
        <Grid container spacing={1} style={{ margin: '12px 10px' }}>
          <RadioGroup
            aria-labelledby={t('WILL_ANYONE_LIVING_REMAIN')}
            name={'livingRemainAtProperty'}
            value={form.props('livingRemainAtProperty').value}
            onChange={form.props('livingRemainAtProperty').onChange}
            onBlur={form.props('livingRemainAtProperty').onBlur}
            data-cy="will-anyone-living-remain-field"
          >
            <Grid container spacing={1} direction="column">
              <Grid item>
                <FormControlLabel
                  value={t('YES')}
                  control={<Radio color={'primary'} />}
                  label={t('YES')}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  value={t('NO')}
                  control={<Radio color={'primary'} />}
                  label={t('NO')}
                />
              </Grid>
              <Grid item>
                <Typography
                  variant="body1"
                  style={{
                    color: 'red',
                  }}
                >
                  {!!form.errors.livingRemainAtProperty}
                </Typography>
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid>
      </Paper>

      <Paper style={{ padding: 30, color: colors.noirBlur, marginBottom: 30 }}>
        <Typography variant="h2">{t('WHERE_ARE_YOU_MOVING_TO')}</Typography>
        <Grid container spacing={2} alignItems="flex-start">
          <Grid item xs={12} md={12}>
            <Autocomplete
              options={
                (data?.searchServiceAddress
                  ?.addresses as SearchServiceAddress[]) || []
              }
              filterOptions={options => options}
              freeSolo={false}
              inputValue={form.props('address').value}
              getOptionLabel={option => `${option?.addressLine1}`}
              style={{ width: '100%' }}
              onChange={(
                event: ChangeEvent<{}>,
                newValue: Maybe<SearchServiceAddress>,
              ) => {
                event.preventDefault();
                if (newValue?.addressLine1) {
                  // as we are showing 5 digits on UI we are trimming it to 5 digits
                  setAutoCompleteAddress({
                    ...newValue,
                    postal: newValue.postal.slice(0, 5),
                  });
                } else {
                  setAutoCompleteAddress({
                    addressLine1: '',
                    city: '',
                    postal: '',
                  });
                }
              }}
              onInputChange={async (_, _2, reason: any) => {
                if (reason === 'clear') {
                  await Promise.all([
                    form.setValue('address', ''),
                    form.setValue('city', ''),
                    form.setValue('zip', ''),
                  ]);
                  setAutoCompleteAddress({
                    addressLine1: '',
                    city: '',
                    postal: '',
                  });
                }
              }}
              noOptionsText={noOptionsText}
              loading={loading}
              renderInput={params => (
                <TextField
                  {...params}
                  label={t('STREET_ADDRESS')}
                  variant="outlined"
                  style={{ width: '100%', marginTop: 20 }}
                  {...form.props('address')}
                  data-cy="address-field"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    form.props('address').onChange(e);
                    debounceSetSearchString(e.target.value);
                  }}
                  inputProps={{
                    ...params.inputProps,
                    maxLength: 60,
                    // adding this to disable the browser auto complete so that i doesn't clash with autocomplete component
                    //ref: https://stackoverflow.com/questions/48304062/material-ui-textfield-disable-browser-autocomplete
                    autoComplete: 'new-password',
                  }}
                />
              )}
              renderOption={option => {
                return (
                  <Grid container alignItems="center">
                    <Grid item>
                      {`${option?.addressLine1}, ${option?.city}, ${option?.postal}`}
                    </Grid>
                  </Grid>
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              name={'city'}
              label={t('CITY')}
              style={{ width: '100%' }}
              inputProps={{ minLength: 1, maxLength: 20 }}
              {...form.props('city')}
              data-cy="city-field"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ZipInputField
              name={'zip'}
              label={t('ZIP_CODE')}
              style={{ width: '100%' }}
              {...form.props('zip')}
              data-cy="zip-field"
            />
          </Grid>
          <Grid item xs={12}>
            <a
              ref={anchorRef}
              href="#"
              onClick={(e: any) => {
                e.preventDefault();
                openBusinessAddressTooltip();
              }}
            >
              {t('IS_THIS_A_BUSINESS_ADDRESS')}
            </a>
            <IsThisABusinessAddressToolTip
              isOpen={Boolean(isThisABusinessAddressTooltipAnchor)}
              anchorEl={isThisABusinessAddressTooltipAnchor}
              closeTooltip={closeBusinessAddressTooltip}
            />
          </Grid>
        </Grid>
        <Typography
          variant="h2"
          style={{
            borderTop: `1px solid ${colors.lightGray1}`,
            paddingTop: 25,
            marginTop: 25,
          }}
        >
          {t('WHEN_DO_YOU_WANT_TO_START_SERVCE')}
        </Typography>

        <Grid item xs={12} md={7}>
          <DatePicker
            style={{ width: '100%' }}
            label={t('START_SERVICE_DATE')}
            name="startDate"
            minDate={new Date()}
            maxDate={getDateXDaysFromNow(60)}
            {...form.props('startDate')}
            data-cy="start-field"
          />
        </Grid>
      </Paper>
      <Paper style={{ padding: 30, color: colors.noirBlur }}>
        <Typography variant="h2" style={{ marginBottom: 25 }}>
          {t('TELL_US_ABOUT_YOUR_NEW_HOME')}
        </Typography>

        <RadioGroup
          aria-labelledby={t('DO_YOU_OWN_OR_RENT')}
          name={'ownOrRent'}
          value={form.props('ownOrRent').value}
          onChange={form.props('ownOrRent').onChange}
          onBlur={form.props('ownOrRent').onBlur}
          data-cy="own-field"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <Typography
                variant="body1"
                className={
                  !!form.errors.ownOrRent
                    ? 'MuiFormHelperText-contained Mui-error'
                    : ''
                }
                style={{
                  color: !!form.errors.ownOrRent ? 'red' : 'inherit',
                }}
              >
                {t('DO_YOU_OWN_OR_RENT')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormControlLabel
                value={t('OWN')}
                control={<Radio color={'primary'} />}
                label={t('OWN')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControlLabel
                value={t('RENT')}
                control={<Radio color={'primary'} />}
                label={t('RENT')}
              />
            </Grid>
          </Grid>
        </RadioGroup>
        <RadioGroup
          aria-labelledby={t('DO_YOU_LIVE_HERE')}
          name={'liveAtAddress'}
          value={form.props('liveAtAddress').value}
          onChange={form.props('liveAtAddress').onChange}
          onBlur={form.props('liveAtAddress').onBlur}
          data-cy="liveAt-field"
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <Typography
                variant="body1"
                className={
                  !!form.errors.liveAtAddress
                    ? 'MuiFormHelperText-contained Mui-error'
                    : ''
                }
                style={{
                  color: !!form.errors.liveAtAddress ? 'red' : 'inherit',
                }}
              >
                {t('DO_YOU_LIVE_HERE')}
              </Typography>
            </Grid>
            <Grid item xs={6} md={2}>
              <FormControlLabel
                value={t('YES')}
                control={<Radio color={'primary'} />}
                label={t('YES')}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <FormControlLabel
                value={t('NO')}
                control={<Radio color={'primary'} />}
                label={t('NO')}
              />
            </Grid>
          </Grid>
        </RadioGroup>
      </Paper>
      <div style={{ margin: '25px 0' }}>
        <NextBackOrCancel
          cancelRoute={false}
          nextHandler={wrapWithLoader(
            form.submit(async () => {
              if (isNextEnabled || !isSubmitting) {
                validateMoveServiceEligibility();
              }
              scrollToErrors();
            }),
          )}
        />
      </div>
    </>
  );
};
