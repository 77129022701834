import Typography from '@material-ui/core/Typography';
import { Router, navigate, useLocation } from '@reach/router';
import React, { useContext, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import Backdrop from '../../../components/backdrop';
import { Box } from '@material-ui/core';
import MedicalCertificate from '../../../components/medical-certificate/MedicalCertificate';
import MedicalCertificateConfirm from '../../../components/medical-certificate/MedicalCertificateConfirm';
import MedicalCertificateEnrolled from '../../../components/medical-certificate/MedicalCertificateEnrolled';
import MedicalCertificateIneligible from '../../../components/medical-certificate/MedicalCertificateIneligible';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import { useManageMedicalCertificate } from '../../../components/medical-certificate/useManageMedicalCertificate';
import {
  LinkedPerson,
  MedicalCertEnrollmentStatus,
} from '../../../__generated__/pge-types';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import AccountLayout from '../../../components/account-layout';

export const MedicalCertificatePage = (_: { path?: string }) => {
  const { t } = useTranslation();

  const notificationContext = useContext(NotificationsContext);
  const location = useLocation();

  const {
    selectedAccountNumber,
    customer,
    isLoading,
    persons,
    refetchPersons,
    mainPersonEmail,
    handleSubmitEnrollment,
    currentEnrollmentDetails,
  } = useManageMedicalCertificate();

  const [selectedPerson, setSelectedPerson] = useState<LinkedPerson | null>(
    null,
  );
  const [showAddPersonModal, setShowAddPersonModal] = useState(false);
  const [recentlyAddedPerson, setRecentlyAddedPerson] = useState<
    LinkedPerson | undefined
  >();
  const { wrapWithLoader } = useWrapWithLoader();
  const handleChangePerson = (person: any) => {
    setSelectedPerson(person);
  };

  const handleSubmit = wrapWithLoader(async () => {
    const submitResult = await handleSubmitEnrollment(
      selectedPerson?.encryptedPersonId!,
    );

    if (submitResult?.caseId) {
      void navigate(ROUTES.MEDICAL_CERTIFICATE_CONFIRM, {
        replace: true,
        state: {
          isEnrolled: true,
          expirationDate: submitResult?.expirationDate,
        },
      });
    } else {
      notificationContext.setState({
        isOpen: true,
        severity: 'error',
        variant: 'filled',
        message: t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
      });
    }
  });

  useEffect(() => {
    if (customer && persons !== undefined) {
      let person: any = persons?.find(
        (p: any) => p.personId === customer?.personId,
      );
      if (!person) {
        person = persons[0];
      }
      setSelectedPerson(person);
    }
  }, [persons, customer]);

  useEffect(() => {
    if (recentlyAddedPerson) {
      const { personId } = recentlyAddedPerson as any;
      const person: any = persons?.find((p: any) => p.personId === personId);
      if (person) {
        setSelectedPerson(person);
        setRecentlyAddedPerson(undefined);
      }
    }
  }, [recentlyAddedPerson, persons]);

  useEffect(() => {
    if (!currentEnrollmentDetails) {
      return;
    }

    const isEnrolled = currentEnrollmentDetails?.isEnrolled;
    const isEnrollmentOpen =
      isEnrolled &&
      currentEnrollmentDetails?.enrollmentStatus ===
        MedicalCertEnrollmentStatus.Open;
    const isEligible = currentEnrollmentDetails?.isEligible;
    const medCertExpiryDate = currentEnrollmentDetails?.expirationDate;
    if (!isEnrolled) {
      void navigate(ROUTES.MEDICAL_CERTIFICATE, { replace: true });
    } else if (isEnrolled) {
      if (isEnrollmentOpen) {
        void navigate(ROUTES.MEDICAL_CERTIFICATE_CONFIRM, {
          state: {
            isEnrolled: true,
            expirationDate: medCertExpiryDate,
          },
          replace: true,
        });
        return;
      } else {
        void navigate(ROUTES.MEDICAL_CERTIFICATE_ENROLLED, {
          state: {
            medCertExpiryDate: medCertExpiryDate,
            isEnrolled: true,
          },
          replace: true,
        });
        return;
      }
    } else if (!isEligible) {
      void navigate(ROUTES.MEDICAL_CERTIFICATE_INELIGIBLE, { replace: true });
    }
  }, [currentEnrollmentDetails, selectedAccountNumber]);

  if (
    isLoading ||
    !customer ||
    !persons ||
    !selectedPerson ||
    !currentEnrollmentDetails
  ) {
    return <Backdrop forceOpen />;
  }

  return (
    <>
      <Helmet>
        <title>{t('MEDICAL_CERTIFICATE_PROGRAM')}</title>
      </Helmet>
      <AccountLayout>
        <Typography variant={'h1'} data-testid={'program-title'}>
          {t('MEDICAL_CERTIFICATE_PROGRAM')}
        </Typography>
        <Box style={{ marginBottom: '1em' }}>
          <Router basepath="/">
            <MedicalCertificate
              path={ROUTES.MEDICAL_CERTIFICATE}
              customer={customer}
              persons={persons}
              selectedPerson={selectedPerson}
              handleChangePerson={handleChangePerson}
              mainPersonEmail={mainPersonEmail}
              handleSubmit={handleSubmit}
              showAddPersonModal={showAddPersonModal}
              setShowAddPersonModal={setShowAddPersonModal}
              refetchPersons={refetchPersons}
              setRecentlyAddedPerson={setRecentlyAddedPerson}
            />
            <MedicalCertificateConfirm
              path={ROUTES.MEDICAL_CERTIFICATE_CONFIRM}
            />
            <MedicalCertificateEnrolled
              path={ROUTES.MEDICAL_CERTIFICATE_ENROLLED}
            />
            <MedicalCertificateIneligible
              path={ROUTES.MEDICAL_CERTIFICATE_INELIGIBLE}
            />
          </Router>
        </Box>
        </AccountLayout>
    </>
  );
};

export default MedicalCertificatePage;
