import React from 'react';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link } from '@reach/router';
import ButtonLink from '../button-link';
import Button from '../buttons';
import colors from '../../themes/main-colors';
import { useTranslation } from '../../hooks/useTranslation';
import { useIsMobile } from '../../util/style-utils';
import { scrollToErrors } from '../../util/form-validation';

type CancelVariant = 'Link' | 'Button';

interface NextBackOrCancelProps {
  cancelRoute: string | false;
  cancelHandler?: () => void | any;
  cancelLabel?: string;
  cancelVariant?: CancelVariant;
  nextHandler: () => void | any;
  nextLabel?: string;
  nextDisabled?: boolean;
  backLabel?: string;
  backHandler?: () => Promise<any> | any | void;
  isFinalStep?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    fontSize: 15,
    width: '100%',
    minWidth: 101,
    boxSizing: 'border-box',
  },
  backButton: {
    background: 'white',
    color: colors.sparkBlue,
    border: `1px solid ${colors.sparkBlue}`,
    '&:hover': {
      color: 'white',
    },
    width: '100%',
  },
  backLinkDesktop: {
    display: 'inline',
    textDecoration: 'none',
    fontSize: '1.1em',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
    },
  },
  backLinkMobile: {
    display: 'none',
    textAlign: 'center',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  finalStep: {
    backgroundColor: colors.orange,
    borderColor: colors.orange,
    '&:hover': {
      backgroundColor: colors.orange,
      borderColor: colors.orange,
    },
  },
}));

const NextBackOrCancel = ({
  cancelRoute,
  cancelHandler,
  cancelLabel = '',
  cancelVariant = 'Link',
  nextHandler,
  nextLabel = '',
  nextDisabled,
  backLabel = '',
  backHandler,
  isFinalStep = false,
}: NextBackOrCancelProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const CancelComponent = cancelVariant === 'Link' ? Link : ButtonLink;
  const isMobile = useIsMobile();

  return (
    <Grid
      container
      spacing={2}
      alignItems={isMobile ? 'stretch' : 'center'}
      direction={isMobile ? 'column-reverse' : 'row'}
      justify={isMobile ? 'center' : 'flex-end'}
    >
      {cancelRoute && (
        <Grid
          item
          xs={12}
          md={6}
          style={isMobile ? { width: '100%', textAlign: 'center' } : {}}
        >
          <CancelComponent
            className={classes.backLinkDesktop}
            to={cancelRoute}
            onClick={cancelHandler}
          >
            {cancelLabel === '' ? t('CANCEL') : cancelLabel}
          </CancelComponent>
        </Grid>
      )}
      <Grid item xs={12} md={6} className={classes.actionContainer}>
        <Grid
          container
          spacing={2}
          justify={isMobile ? 'center' : 'flex-end'}
          direction={isMobile ? 'column-reverse' : 'row'}
          alignItems={isMobile ? 'center' : 'center'}
        >
          {backHandler && (
            <Grid item xs={10} md={4} style={isMobile ? { width: '100%' } : {}}>
              <Button
                variant={'outlined'}
                color={'primary'}
                className={classes.backButton}
                onClick={backHandler}
                size="large"
                data-cy="back-btn"
              >
                {backLabel === '' ? t('BACK') : backLabel}
              </Button>
            </Grid>
          )}
          <Grid
            item
            xs={10}
            md={'auto'}
            style={isMobile ? { width: '100%' } : {}}
          >
            <Button
              variant={'contained'}
              color={'primary'}
              className={clsx(classes.button, isFinalStep && classes.finalStep)}
              onClick={async () => {
                await nextHandler();
                scrollToErrors();
              }}
              disabled={nextDisabled}
              size="large"
              data-cy="next-btn"
            >
              {nextLabel === '' ? t('NEXT') : nextLabel}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default NextBackOrCancel;
