import { useState } from 'react';
import { ElectionWindowStatus, OpenEnrollmentAccountInput } from '../__generated__/pge-types';
import { useCreateOpenEnrollment } from './useCreateOpenEnrollment';

export type ContactInfoModel = {
  fullName?: string;
  email?: string;
  phoneNumber?: string;
  authFullName?: string;
  authTitle?: string;
  authPhoneNumber?: string;
};

export type ElectionWindowModel = {
  contactInfo: ContactInfoModel;
};

export default () => {
  const [electionWindowState, setElectionWindowState] = useState<
    ElectionWindowModel
  >({
    contactInfo: {},
  });

  const { submit } = useCreateOpenEnrollment();

  const setContactInfo = (contactInfo: ContactInfoModel) => {
    setElectionWindowState(state => ({
      ...state,
      contactInfo,
    }));
  };

  const onSubmit = async (enrolledAccounts: OpenEnrollmentAccountInput[], electionWindowStatus: ElectionWindowStatus) => {
    
    const openEnrollmentReq = {
      authorization: {
        fullName: electionWindowState?.contactInfo?.authFullName,
        phoneNumber: electionWindowState?.contactInfo?.authPhoneNumber,
        title: electionWindowState?.contactInfo?.authTitle,
      },
      contactInformation: {
        emailAddress: electionWindowState?.contactInfo?.email,
        fullName: electionWindowState?.contactInfo?.fullName,
        phoneNumber: electionWindowState?.contactInfo?.phoneNumber,
      },
      electionWindow: electionWindowStatus.electionWindow!,
      openEnrollmentAccounts: enrolledAccounts,
      periodEffectiveDate: electionWindowStatus.periodEffectiveDate!,
    };

    const result = await submit(openEnrollmentReq);
    return result;
  };

  return {
    electionWindowState,
    setContactInfo,
    onSubmit,
  };
};
