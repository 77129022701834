/** @jsx jsx */
import { jsx } from '@emotion/core';
import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { ApplicationSectionStatus } from '../../../__generated__/pgeplus-types';
import { UpdateSectionEVRebatesMutationTypes } from '../../../hooks/pge-plus/useRebatesEligibility';
import useContentMgmt, {
  PageTextContentType,
} from '../../../hooks/useContentMgmt';
import { FormState } from '../../../hooks/useFormState.types';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';
import routes from '../../../routes';
import { GridItem } from '../../pge-plus-common/Grid';
import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import { Question } from '../../pge-plus-common/Typography';
import { RebateEligibility } from '../evcharger.types';

type AllRebatesClaimedProps = {
  content: PageTextContentType;
  form: FormState<RebateEligibility>;
  path: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  updateSectionEVRebatesEligibility: UpdateSectionEVRebatesMutationTypes;
  updateFormValues: (values: RebateEligibility) => boolean;
};

export const AllRebatesClaimed = (props: AllRebatesClaimedProps) => {
  const {
    content,
    setPageTitle,
    form,
    updateFormValues,
    updateSectionEVRebatesEligibility,
  } = props;
  const { richText } = useContentMgmt();
  const { wrapWithLoader } = useWrapWithLoader();

  const {
    formState: {
      selectCharger: { suppliedByCustomer },
    },
  } = useEVChargerFormState();

  useEffect(() => {
    setPageTitle('All Rebates Claimed');
  }, []);

  return (
    <form
      onSubmit={wrapWithLoader(
        form.submit(async () => {
          updateFormValues(form.values);
          const { validationError } = await updateSectionEVRebatesEligibility(
            form.values,
            false,
            ApplicationSectionStatus.Completed,
          );
          if (!validationError) {
            void navigate(
              suppliedByCustomer
                ? routes.PGE_PLUS_EV_CHARGERS_INSTALLATION
                : routes.PGE_PLUS_EV_CHARGERS_SELECT_CHARGER,
            );
          }
        }),
      )}
      noValidate
    >
      <Question title detail={richText(content.get('EVSE_REBATES'))} />
      <GridItem>
        <ProceedOrCancelButton back={false} />
      </GridItem>
    </form>
  );
};
