import { useStaticQuery, graphql } from 'gatsby';
import { Query } from '../../__generated__/gatsby-types';

export const nextBestOfferDetailsQuery = graphql`
  {
    allContentfulSmartCardSection(
      filter: { contentful_id: { eq: "5wbWZdtGZddzGdudua6hUo" } }
    ) {
      nodes {
        node_locale
        cardGroupingDisplayType
        cardGrouping {
          ... on ContentfulSmartCard {
            header
            subHeader
            image {
              file {
                url
              }
            }
            mobileImage {
              file {
                url
              }
            }
            copy {
              raw
            }
            button {
              __typename
              buttonLink {
                ... on ContentfulApplicationPage {
                  slug: url
                }
                ... on ContentfulPageArticle {
                  slug
                }
                ... on ContentfulWrapperVanityUrl {
                  slug: vanityUrl
                }
                ... on ContentfulWrapperMediaAsset {
                  media {
                    file {
                      url
                    }
                  }
                }
                ... on ContentfulWrapperExternalLink {
                  websiteUrl
                }
                ... on ContentfulPagePromotional {
                  slug
                }
                ... on ContentfulPageOverlay {
                  slug
                }
                ... on ContentfulPageNewsRelease {
                  slug
                }
                ... on ContentfulPageList {
                  slug
                }
                ... on ContentfulPageHome {
                  slug
                }
                ... on ContentfulPageBasic {
                  slug
                }
              }
              button {
                buttonText
              }
            }
          }
        }
      }
    }
  }
`;

export default () => {
  const { allContentfulSmartCardSection } = useStaticQuery<Query>(
    nextBestOfferDetailsQuery,
  );

  return {
    allContentfulSmartCardSection,
  };
};
