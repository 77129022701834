import { createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import PaymentConfirmationForm from '../../payment-form/payment-confirmation-form/PaymentConfirmationForm';
import ReconnectServiceConfirmationContent from '../reconnect-service-confirmation-content';
import {
  OneTimePayConfigData,
  OneTimePayConfirmationResponse,
} from '../../payment-form/oneTimePayment.types';

import {
  PaymentConfirmation,
  ServiceConnectivity,
} from '../../../__generated__/pge-types';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import useAccountsStore from '../../../hooks/useAccountsStore';
interface Props {
  path?: string;
  oneTimePayConfirmationData: OneTimePayConfirmationResponse;
  oneTimePayConfig: OneTimePayConfigData;
  paymentMethodCapLimit: number;
  isForReconnect: boolean;
  minimumAmount: string;
  fullAmount: string;
  selectedAccountNumber: string;
  printContainerId: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    successMessageText: {
      marginBottom: '1em',
      '& a': {
        textDecoration: 'none',
      },
    },
  }),
);

const ReconnectServicePaymentConfirmation: FunctionComponent<Props> = (
  props: Props,
) => {
  const {
    oneTimePayConfirmationData,
    oneTimePayConfig,
    paymentMethodCapLimit,
    isForReconnect,
    minimumAmount,
    fullAmount,
    selectedAccountNumber,
    printContainerId,
  } = props;
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const apolloClient = useApolloClient();
  const {
    customerAccountState,
    setAccountDisconnectedStatus,
  } = useAccountsStore();
  const [successProfiles, setSuccessProfiles] = useState<PaymentConfirmation[]>(
    [],
  );
  const [successPaymentAmount, SetSuccessPaymentAmount] = useState<
    number | null
  >(null);
  const [isCompleteSuccess, setIsCompleteSuccess] = useState<Boolean>(false);
  const notificationContext = useContext(NotificationsContext);
  useEffect(() => {
    if (oneTimePayConfirmationData.paymentConfirmation.length) {
      setSuccessProfiles(
        oneTimePayConfirmationData.paymentConfirmation.filter(
          p => p.isSuccess === true,
        ),
      );
    }
  }, [oneTimePayConfirmationData]);

  useEffect(() => {
    if (successProfiles.length) {
      let successAmount = 0;
      successProfiles.map(profiles => {
        successAmount = successAmount + profiles.paymentAmount;
      });
      SetSuccessPaymentAmount(successAmount);
    }
  }, [successProfiles]);

  useEffect(() => {
    const completeStatus =
      successPaymentAmount !== null &&
      successPaymentAmount <= Number(fullAmount) &&
      successPaymentAmount >= Number(minimumAmount)
        ? true
        : false;
    setIsCompleteSuccess(completeStatus);
  }, [successPaymentAmount]);

  useEffect(() => {
    if (isCompleteSuccess) {
      setAccountDisconnectedStatus(
        true,
        customerAccountState?.isAccountDisonnected,
        customerAccountState?.isAccountEligibleForReconnect,
      );
      apolloClient.cache.writeFragment({
        id: `$AccountDetail:${selectedAccountNumber}.serviceConnectivity`,
        fragment: gql`
          fragment GetAccountDetails on ServiceConnectivity {
            isEligibleForReconnect
            isDisconnected
            isReconnectInProgress
          }
        `,
        data: {
          __typename: 'ServiceConnectivity',
          isReconnectInProgress: true,
        },
      });

      notificationContext.setState({
        isOpen: true,
        severity: 'success',
        variant: 'filled',
        message: t('RECONNECT_SUCCESS_BANER'),
      });
    }
  }, [isCompleteSuccess]);
  return (
    <>
      {successProfiles.length > 0 && isCompleteSuccess && (
        <Grid container>
          <Grid item xs={12}>
            <Typography
              variant={'body2'}
              className={classes.successMessageText}
            >
              {richT('RECONNECT_SUCCESS_SUB_BANER', {
                BELOW_LINK: '#confirmationDiv',
              })}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ReconnectServiceConfirmationContent/>
          </Grid>
        </Grid>
      )}

      <div id="confirmationDiv"  style={{marginBottom:'1em'}}>
        <PaymentConfirmationForm
          oneTimePayConfirmationData={oneTimePayConfirmationData}
          oneTimePayConfig={oneTimePayConfig}
          paymentMethodCapLimit={paymentMethodCapLimit}
          isForReconnect={isForReconnect}
          printContainerId={printContainerId}
        />
      </div>
    </>
  );
};
export default ReconnectServicePaymentConfirmation;
