import { Box, Grid, Paper, Typography } from '@material-ui/core';
import { useLocation, Link } from '@reach/router';
import React, { useEffect } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { useIsMobile } from '../../util/style-utils';
import ProceedOrCancel from '../proceed-or-cancel';
import ReviewTable from './ReviewTable';
import { AccountAmounts, PaymentDateState, PaymentProfileState } from './types';
import ROUTES from '../../routes';
import PaymentSummary from './PaymentSummary';
import {
  formatDate,
  replaceAsteriskForMarkdown,
  toCurrencyString,
} from '../../util/format';
import sumBy from 'lodash/sumBy';
import useFormState from '../../hooks/useFormState';
import validate from './PaymentReviewStep.rules';
import { getMultipayEligibility } from './utils';
import colors from '../../themes/main-colors';
import { getAccountDisplayString } from '../paymentus/utils';
import { getPaymentCategoryLabel } from '../../hooks/usePaymentus';

type Props = {
  path?: string;
  onSubmit: (
    accountAmounts: Array<AccountAmounts>,
    profileState: PaymentProfileState,
    paymentDateState: PaymentDateState,
    timestamp: string,
  ) => void;
};

type LocationState = {
  accountAmounts: Array<AccountAmounts>;
  profileState: PaymentProfileState;
  paymentDateState: PaymentDateState;
  timestamp: string;
};

const PaymentReviewStep = ({ onSubmit }: Props) => {
  const { t, richT } = useTranslation();
  const isMobile = useIsMobile();
  const location = useLocation();
  const {
    accountAmounts,
    timestamp,
    profileState,
    paymentDateState,
  }: LocationState = location?.state as LocationState;

  const eligibleAccounts = accountAmounts.filter(
    ({ account }) =>
      getMultipayEligibility(
        account,
        profileState?.selectedProfileInfo?.profile,
      ).isEligible,
  );

  const paymentDateForm = useFormState(
    {
      paymentDate: formatDate(new Date()),
    },
    {
      validate,
    },
  );

  const summaryProps = {
    accountAmounts: eligibleAccounts,
    profileState,
    paymentDateState,
    paymentDateForm,
  };

  const total = sumBy(eligibleAccounts, 'amount');

  // getAccountDisplayString returns a 10 char string (6 asterisks + 4 digits)
  // react-markdown 4.3.1 has a bug or limitation where if the number of contiguous
  // asterisks N > 4, then it seems to be able to render only (N-1)%4 + 1 asterisks.
  // Replacing asterisk with its html entity helps fix this issue for 4.3.1
  // TODO - remove replaceAsteriskForMarkdown when we update react-markdown to 5.x
  const maskedAccountNumber =
    replaceAsteriskForMarkdown(
      getAccountDisplayString(profileState.selectedProfileInfo?.profile),
    ) || undefined;

  return (
    <Box marginTop={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2">{t('REVIEW_YOUR_PAYMENT')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper component={'main'} style={{ justifyContent: 'flex-start' }}>
            <Box style={{ padding: '1em' }}>
              <PaymentSummary {...summaryProps} />
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <ReviewTable
            accountAmounts={accountAmounts}
            timestamp={timestamp}
            profileState={profileState}
            disclaimer={
              <Grid item xs={12} style={{ marginTop: '2em' }}>
                <Grid item>
                  <Typography component="div">
                    {richT(
                      maskedAccountNumber
                        ? 'MULTIPAY_I_AUTHORIZE_PGE_TO_CHARGE_NEW'
                        : 'MULTIPAY_I_AUTHORIZE_PGE_TO_CHARGE_GUEST',
                      {
                        PAYMENT_AMOUNT: `$${toCurrencyString(total, true)}`,
                        PAYMENT_METHOD_TYPE: getPaymentCategoryLabel(
                          profileState.selectedProfileInfo?.profile?.type,
                          true,
                        ),
                        MASKED_ACCOUNT_NUMBER: maskedAccountNumber,
                      },
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography component={'div'}>
                    {richT('MULTIPAY_SUBMIT_CANNOT_CANCEL')}
                  </Typography>
                </Grid>
              </Grid>
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            justify="space-between"
            direction={isMobile ? 'column-reverse' : 'row'}
            spacing={1}
          >
            <Grid item xs={12} md="auto">
              <Box textAlign="center">
                <Link to={ROUTES.ACCOUNT}>{t('CANCEL')}</Link>
              </Box>
            </Grid>
            <Grid item xs={12} md="auto">
              <Box paddingRight={1}>
                <ProceedOrCancel
                  proceedLabel={t('SUBMIT_PAYMENT')}
                  proceedDisabled={
                    !!paymentDateForm.errors.paymentDate || total <= 0
                  }
                  proceedHandler={() =>
                    onSubmit(
                      eligibleAccounts,
                      profileState,
                      paymentDateForm.values,
                      timestamp,
                    )
                  }
                  cancelRoute={ROUTES.MULTI_PAY_PAYMENT_METHOD}
                  cancelLabel={t('BACK')}
                  cancelVariant="Button"
                  proceedStyle={
                    !paymentDateForm.errors.paymentDate && total > 0
                      ? colors.orange
                      : ''
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PaymentReviewStep;
