import {
  Box,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { Fragment } from 'react';
import { AccountDetail, AccountType } from '../../../__generated__/pge-types';
import useAuthQuery from '../../../hooks/useAuthQuery';
import useGetAccountType from '../../../hooks/useGetAccountType';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';
import { getPgeEnergyTrackerData } from '../../account-summary/single-account/queries';
import Backdrop from '../../backdrop';
import EnergyAnalysisWidget from '../../OpowerWidgets/components/EnergyAnalysisWidget';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorTextPrimary: {
      backgroundColor: colors.navBarBackground,
    },
    widget: {
      backgroundColor: colors.lightGray2,
      margin: '20px',
      [theme.breakpoints.down('sm')]: {
        margin: '0px',
        padding: '0px',
      },
    },

    nonRes: {
      display: 'flex',
    },
    nonResText: {
      padding: '20px',
      fontSize: '20px',
      lineHeight: '25px',
      color: colors.lightCyan,
    },
  }),
);

type Props = {
  path?: string;
};

export default function FullSurvey(_: Props) {
  const { accountParams } = useSelectedAccountParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const { accountTypeData } = useGetAccountType({});

  const { loading: eTLoading, data: etData } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getPgeEnergyTrackerData, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams,
  });

  const pgeEnergyTrackerData = etData?.getAccountDetails[0]?.pgeEnergyTracker;
  const isResAccount =
    accountTypeData?.getAccountDetails[0]?.accountType === AccountType?.Res;
  const isLoading = etData === undefined || eTLoading;
  if (isLoading || accountTypeData === undefined) {
    return (
      <Backdrop forceOpen message={t('STILL_LOADING')} data-testid="loading" />
    );
  }
  return (
    <Fragment>
      <Box>
        <Card className={classes.colorTextPrimary}>
          {isResAccount ? (
            <CardContent className={classes.widget} data-testid="widget-res">
              <EnergyAnalysisWidget
                entityId={
                  pgeEnergyTrackerData?.energyTrackerInfo?.identifiers?.[0]
                }
                props={[
                  {
                    'opower-instance': 'widget-survey-full',
                  },
                ]}
              />
            </CardContent>
          ) : (
            !isResAccount && (
              <CardContent
                className={classes.widget}
                data-testid="widget-non-res"
              >
                <Grid item className={classes.nonRes}>
                  <Typography className={classes.nonResText}>
                    {t(
                      'MY_PRICING_PLAN_OPTIONS_WIDGET_NON_RESIDENTIAL_MESSAGE',
                    )}
                  </Typography>
                </Grid>
              </CardContent>
            )
          )}
        </Card>
      </Box>
    </Fragment>
  );
}
