import React, { FC } from 'react';
import { Typography, Grid, Card, Paper } from '@material-ui/core';
import { navigate } from 'gatsby';
import ROUTES from '../../../routes';
import PGEButton from '../../buttons';
import { useTranslation } from '../../../hooks/useTranslation';
import useStyles from '../styles';
import { PeakTimeRebatesTransactionType } from '../types';
import Alerts from '../../alerts';

interface Props {
  path?: string;
  transactionType: PeakTimeRebatesTransactionType;
}

const PeakTimeRebatesSuccess: FC<Props> = (props: Props) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const { transactionType } = props;

  const getPeakTimeRebateTitle = () => {
    let title;
    switch (transactionType) {
      case PeakTimeRebatesTransactionType.Enroll:
        title = t('ENROLLMENT_SUCCESSFUL');
        break;
      case PeakTimeRebatesTransactionType.Cancel:
        title = richT('UNENROLLMENT_SUCCESSFUL');
        break;
      case PeakTimeRebatesTransactionType.Update:
        title = t('UPDATE_SUCCESSFUL');
        break;
    }
    return title;
  };

  const getPeakTimeRebateMsg = () => {
    let message;
    switch (transactionType) {
      case PeakTimeRebatesTransactionType.Enroll:
        message = t('YOU_ARE_NOW_ENROLLED_PTR');
        break;
      case PeakTimeRebatesTransactionType.Cancel:
        message = t('YOU_ARE_NO_LONER_ENROLLED_PTR');
        break;
      case PeakTimeRebatesTransactionType.Update:
        message = t('NOTIFICATION_UPDATE_SUCCESS_PTR');
        break;
    }
    return message;
  };

  return (
    <Paper className={classes.peakTimeRebateSuccessAlert} component={'main'}>
      <Card>
        <Alerts
          disableColorOverride={true}
          isOpen
          severity={'success'}
          variant={'outlined'}
          title={
            <h2 className={classes.alertTitle}>{getPeakTimeRebateTitle()}</h2>
          }
          message={
            <>
              <Grid container spacing={2}>
                <Grid item>
                  <Typography component="span">
                    {getPeakTimeRebateMsg()}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container className={classes.doneBtnHolder}>
                <Grid>
                  <PGEButton
                    size={'large'}
                    onClick={async () => {
                      await navigate(ROUTES.ACCOUNT);
                    }}
                  >
                    <Typography variant={'h4'} noWrap>
                      {t('DONE')}
                    </Typography>
                  </PGEButton>
                </Grid>
              </Grid>
            </>
          }
        />
      </Card>
    </Paper>
  );
};

export default PeakTimeRebatesSuccess;
