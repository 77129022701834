import gql from 'not-graphql-tag';

export const getAccountDetailsQuery = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      encryptedPersonId
      accountType
      paymentEligibility {
        isCashOnly
        isNonBillableNoBalance
      }
      paymentAlerts {
        isFutureDated
        isMultipleSameDayPayment
        isSingleSameDayPayment
        lastPaidAmount
        totalFutureDatedPaymentAmount
      }
      currentCharges {
        amountDue
        dueDate
      }
      autoPay {
        isEnrolled
        isSameDayEnrolled
        startDate
      }
      pastDueAmount
    }
  }
`;

export const getOnetimePaymentInfoQuery = gql`
  query getOnetimePaymentInfo($payload: PaymentEligibilityRequest) {
    getOnetimePaymentInfo(payload: $payload) {
      eligibility {
        billMatrixUrl
        amountDue
        isPendingDisconnect
        isFutureDated
        futureDatedPaymentAmount
        isCashOnly
        isNonBillableNoBalance
        isSingleSameDayPayment
        isMultipleSameDayPayment
        lastPaidAmount
        autoPay {
          autoPayId
          autoPaySource
          paymentType
          saveBankinfo
          useSavedBankInfo
          savedBankInfo {
            encryptedBankAccountNumber
            maskedBankAccountNumber
            bankRoutingNumber
          }
        }
        oneTimePayment {
          paymentDate
          paymentAmount
          saveBankInfo
          emailAddress
          useSavedBankInfo
          savedBankInfo {
            encryptedBankAccountNumber
            maskedBankAccountNumber
            bankRoutingNumber
          }
        }
      }
      paymentPlanType {
        paymentPlanType
      }
    }
  }
`;

export const submitOnetimePaymentMutation = gql`
  mutation makePayment($payload: PaymentInputList!) {
    makePayment(payload: $payload) {
      isPartialSucceed
      paymentConfirmation {
        accountNumber
        confirmationId
        isSuccess
        errorReason
        errorMessage
        paymentAmount
        cardType
        lastFourDigitsCardNumber
        paymentMethod
        digitalWalletEmail
      }
    }
  }
`;
