import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { Card, CardContent, createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DirectAccessHistoricalUsageModel } from '../../../hooks/useDirectAccessHistoricalUsage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      border: `1px solid ${theme.palette.success.main}`,
    },
    circleIcon: {
      color: theme.palette.success.main,
      paddingTop: '0.3em'
    },
    marginTop1: {
      marginTop: '1em',
    },
    marginBottom1: {
      marginBottom: '1em',
    },
    cardContent: {
      padding: 16, 
      marginBottom: 30
    },
  }),
);

export const DirectAccessHistoricalUsageConfirmation = ({
  formState,
}: WizardFormProps<DirectAccessHistoricalUsageModel>) => {
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <>
      <Grid item xs={12} className={styles.marginTop1}>
        <Paper className={styles.border}>
          <Card>
            <CardContent className={styles.cardContent}>
              <Grid container direction="column">
                <Grid item container>
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={2}
                    direction={'row'}
                    alignItems={'center'}
                  >
                    <Grid item>
                      <Typography
                        variant={'h2'}
                        className={styles.circleIcon}
                      >
                        <CheckCircleIcon fontSize="inherit" data-testid={'check-circle-icon'}/>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={'h2'}>
                        {t('HISTORICAL_USAGE_DONE_TRANSACTION_SUCCESSFUL')}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'body1'}
                      className={styles.marginBottom1}
                      data-testid={'historical-done-release-txt'}>
                      {t('HISTORICAL_USAGE_DONE_RELEASE_TEXT')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
    </>
  );
};
