import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { navigate } from 'gatsby';

import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import Button from '../../buttons';
import TextLink from '../../text-link';
import AccountGroupDropdownComponent from '../../account-group-dropdown/AccountGroupDropdown';
import SortFilterPageHeader from '../../account-summary/sort-filter-page-header/SortFilterPageHeader';
import Backdrop from '../../backdrop';
import { useAddAccounts } from './useAddAccounts';
import SimpleAccountListItem from '../../account-summary/multi-accounts/SimpleAccountListItem';
import { AccountDetail } from '../../../__generated__/pge-types';
import useAccountListItemMenuItems from '../../account-summary/multi-accounts/useAccountListItemMenuItems';
import useAccountListHeader from '../../account-summary/multi-accounts/useAccountListHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRow: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
      },
    },
    buttonContainer: {
      display: 'flex',
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
        marginTop: theme.spacing(2),
        width: '100%',
        justifyContent: 'center',
      },
    },
    button: {
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },
    },
  }),
);

type Props = {
  path: string;
};

export default function AddGroupAccounts(_: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    selectedGroupId,
    onGroupChange,
    editingGroup,
    loading,
    accounts,
    groups,
    sortFilterProps,
    multicheckState,
    isAddButtonDisabled,
    handleAdd,
    onAccountSearch,
    onAccountSort,
    onAccountPageChange,
  } = useAddAccounts();
  const { onViewDetails, makeMenuItems } = useAccountListItemMenuItems();

  return (
    <>
      <Grid item xs={12} style={{ marginTop: '1em' }}>
        <Paper>
          <Card>
            <CardContent style={{ margin: '1em' }}>
              <Grid item>
                <Typography variant={'h2'}>{t('ADD_ACCOUNTS')}</Typography>
              </Grid>
              <Grid item style={{ marginTop: '1em' }}>
                <Grid item style={{ marginBottom: '1em' }}>
                  <Typography
                    variant={'body2'}
                    style={{ display: 'inline-block', marginRight: '1em' }}
                  >
                    {t('CUSTOM_GROUP')}
                  </Typography>
                  <Typography
                    variant={'body2'}
                    style={{ display: 'inline-block', marginRight: '1em' }}
                  >
                    {editingGroup?.groupName}
                  </Typography>
                </Grid>
                <Grid item style={{ marginBottom: '1em' }}>
                  <Typography
                    variant={'body2'}
                    style={{ display: 'inline-block', marginRight: '1em' }}
                  >
                    {t('NUMBER_OF_ACCOUNTS_LABEL')}
                  </Typography>
                  <Typography
                    variant={'body2'}
                    style={{ display: 'inline-block', marginRight: '1em' }}
                  >
                    {editingGroup?.numberOfAccounts}
                  </Typography>
                </Grid>
                <Grid item style={{ marginBottom: '1em' }}>
                  {t('MANAGE_ACCOUNT_GROUPS_LABEL')}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {selectedGroupId !== undefined && groups.length > 0 && (
                  <AccountGroupDropdownComponent
                    onSelect={onGroupChange}
                    selectedGroupId={selectedGroupId}
                    groups={groups}
                  />
                )}

                {!loading && (
                  <SortFilterPageHeader
                    onAccountSearch={onAccountSearch}
                    onAccountSort={onAccountSort}
                    onAccountPageChange={onAccountPageChange}
                    accountListParams={sortFilterProps.accountListParams}
                    totalCount={sortFilterProps.totalCount}
                  />
                )}
              </Grid>

              {!loading && (
                <Grid item xs={12}>
                  {(accounts || []).map((account: AccountDetail) => (
                    <SimpleAccountListItem
                      account={account}
                      multicheckState={multicheckState}
                      menuItems={makeMenuItems(account)}
                      onViewDetails={onViewDetails}
                    />
                  ))}
                </Grid>
              )}

              {loading && <Backdrop forceOpen />}

              <Grid item xs={12} className={classes.buttonRow}>
                <Grid item>
                  <TextLink plain to={ROUTES.MANAGE_ACCOUNT_GROUPS}>
                    {t('BACK')}
                  </TextLink>
                </Grid>
                <Grid item className={classes.buttonContainer}>
                  <Button
                    onClick={async () => {
                      await handleAdd();
                      await navigate(ROUTES.MANAGE_ACCOUNT_GROUPS);
                    }}
                    disabled={isAddButtonDisabled}
                    size={'large'}
                    className={classes.button}
                  >
                    {t('ADD')}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
        <Grid item style={{ marginTop: '1em' }}>
          <Typography
            variant={'body2'}
            style={{ display: 'inline-block', marginRight: '0.2em' }}
          >
            {t('CALL_FOR_ASSISTANCE')}
          </Typography>
          <TextLink
            plain
            external={true}
            to={`tel:${t('CALL_FOR_ASSISTANCE_BIZ_NUMBER')}`}
          >
            {t('CALL_FOR_ASSISTANCE_BIZ_NUMBER')}
          </TextLink>
        </Grid>
      </Grid>
    </>
  );
}
