import { useState } from 'react';
import gql from 'not-graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
  SearchServiceAddressRequest,
  SearchServiceAddressResponse,
} from '../../__generated__/pge-types';

const SEARCH_SERVICE_ADDRESS_QUERY = gql`
  query searchServiceAddress($payload: SearchServiceAddressRequest) {
    searchServiceAddress(payload: $payload) {
      addresses {
        addressLine1
        city
        postal
      }
    }
  }
`;

interface Request {
  payload: SearchServiceAddressRequest;
}
interface Response {
  searchServiceAddress: SearchServiceAddressResponse;
}

export const useSearchServiceAddressQuery = () => {
  const [searchString, setSearchString] = useState<string>();
  const { loading, error, data } = useQuery<Response, Request>(
    SEARCH_SERVICE_ADDRESS_QUERY,
    {
      variables: { payload: { match: searchString } },
      fetchPolicy: 'network-only',
    },
  );

  return {
    searchString,
    setSearchString,
    loading,
    error,
    data,
  };
};
