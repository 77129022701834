import {
  Paper,
  Divider,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import ListSubheader from '@material-ui/core/ListSubheader';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { MilitaryAddressTooltip } from '../military-address-tooltip';
import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import DatePicker from '../date-picker';
import TextField from '../text-field';
import ZipInputField, { CanadianZipInputField } from '../zip-input-field';
import { statesAndProvincesGrouping } from '../utility/state-dropdown';
import useFormState from '../../hooks/useFormState';
import { Model } from './types';
import validate from './StopServiceForm.rules';
import useQas, { QasResult } from '../../hooks/useQas';
import useCreatePersonLeavingBehindContactLog from '../../hooks/useCreatePersonLeavingBehindContactLog';
import {
  QuickAddressSearchParams,
  SuggestedAddress,
} from '../../__generated__/pge-types';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import { QasModal } from '../qas';
import PhoneTextField from '../phone-text-field';
import PhoneExtTextField from '../phone-ext-text-field';
import PGEButton from '../buttons';
import { useIsMobile } from '../../util/style-utils';
import { getPostalCountry } from '../../util/format';
import { scrollToErrors } from '../../util/form-validation';

const getDate90DaysFromNow = (): Date => {
  return moment()
    .add(89, 'days')
    .toDate();
};

const defaultValues: Model = {
  stopDate: '',
  address: '',
  city: '',
  state: '',
  zip: '',
  country: 'USA',
  inCareOf: '',
  phone: '',
  phoneExt: '',
  livingRemainAtProperty: false,
  qasVerified: false,
};

type Props = {
  onSubmit: (data: Model) => void;
  data: Model | null;
  encryptedPersonId: string;
  encryptedAccountNumber: string;
};

export default function StopServiceForm({
  onSubmit,
  data,
  encryptedPersonId,
  encryptedAccountNumber,
}: Props) {
  const { richT, t } = useTranslation();
  const { search } = useQas();
  const createLog = useCreatePersonLeavingBehindContactLog();
  const [qasData, setQasData] = useState<null | {
    params: QuickAddressSearchParams;
    results: QasResult;
  }>(null);
  const { wrapWithLoader } = useWrapWithLoader();
  const mailingAddressSectionId = 'mailing-address-form-section';
  const scrollToMailingAddressRef = useRef(false);
  const isMobile = useIsMobile();
  const anchorRef = useRef<any>();

  const [
    showAddressOutsideUSTooltipAnchor,
    setShowAddressOutsideUSTooltipAnchor,
  ] = useState<HTMLElement | null>(null);

  const openAddressOutsideUSTooltip = () => {
    setShowAddressOutsideUSTooltipAnchor(anchorRef.current || null);
  };

  const closeShowAddressOutsideUSTooltip = () => {
    setShowAddressOutsideUSTooltipAnchor(null);
  };

  useEffect(() => {
    if (scrollToMailingAddressRef.current) {
      scrollToMailingAddressRef.current = false;
      document.getElementById(mailingAddressSectionId)?.scrollIntoView();
    }
  }, [scrollToMailingAddressRef.current]);

  const form = useFormState(data || defaultValues, {
    validate,
    validationContext: {
      t,
    },
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper style={{ padding: 30, color: colors.noirBlur }}>
            <Grid container direction="column" spacing={2}>
              <Grid item container direction="column" spacing={0}>
                <Grid item xs={12}>
                  <Typography variant="h2">
                    {t('WHEN_DO_YOU_WANT_TO_STOP_SERVCE')}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={6}>
                  <DatePicker
                    style={{ width: '100%' }}
                    label={t('STOP_SERVICE_DATE')}
                    name="stopDate"
                    {...form.props('stopDate')}
                    onChange={(e: any) => {
                      return form.setValue('stopDate', e.target.value || '');
                    }}
                    minDate={new Date()}
                    maxDate={getDate90DaysFromNow()}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item container direction="column" spacing={2}>
                <Grid item id={mailingAddressSectionId}>
                  <Typography variant="h2">
                    {t('WHERE_SHOULD_WE_SEND_FINAL_BILL')}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography variant="h4">
                    <a
                      ref={anchorRef}
                      style={{
                        cursor: 'pointer',
                        display: 'inline',
                        color: colors.sparkBlue,
                        borderBottom: `2px dotted ${colors.sparkBlue}`,
                        textDecoration: 'none',
                        fontSize: '1.0rem',
                      }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        openAddressOutsideUSTooltip();
                      }}
                      href="#"
                    >
                      {t('MAILING_ADDRESS_OUTSIDE_US')}
                    </a>
                    <MilitaryAddressTooltip
                      isOpen={Boolean(showAddressOutsideUSTooltipAnchor)}
                      closeTooltip={closeShowAddressOutsideUSTooltip}
                      anchorEl={showAddressOutsideUSTooltipAnchor}
                    />
                  </Typography>
                </Grid>

                <Grid item container spacing={3} direction="column">
                  <Grid item xs={12} md={6}>
                    <TextField
                      name={'address'}
                      label={t('STREET_ADDRESS')}
                      inputProps={{ minLength: 1, maxLength: 60 }}
                      style={{ width: '100%', marginTop: 20 }}
                      {...form.props('address')}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      name={'city'}
                      label={t('CITY')}
                      style={{ width: '100%' }}
                      inputProps={{ minLength: 1, maxLength: 20 }}
                      {...form.props('city')}
                    />
                  </Grid>

                  <Grid item container direction="row" spacing={3}>
                    <Grid item xs={12} md={6}>
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={Boolean(form.errors.state)}
                      >
                        <InputLabel id="select-state-label">
                          {t('STATE_PROVINCE_LABEL')}
                        </InputLabel>
                        <Select
                          fullWidth
                          labelId="select-state-label"
                          label={t('STATE_PROVINCE_LABEL')}
                          placeholder={t('STATE_PROVINCE_LABEL')}
                          aria-labelledby="account-select-label"
                          id="account-select-select"
                          name="state"
                          onChange={async (e: any) => {
                            e.persist();
                            await form.setValue('state', e.target.value);
                            if (form.values.zip) {
                              if (
                                !statesAndProvincesGrouping.unitedStates.includes(
                                  e.target.value,
                                ) &&
                                form.values.zip.length === 5
                              ) {
                                await form.setValue('zip', '');
                              }
                              if (
                                !statesAndProvincesGrouping.canada.includes(
                                  e.target.value,
                                ) &&
                                form.values.zip.length === 7
                              ) {
                                await form.setValue('zip', '');
                              }
                            }
                          }}
                          onBlur={form.onBlur}
                          value={form.values.state}
                          native
                        >
                          <option aria-label="None" value="" />
                          <optgroup
                            style={{ backgroundColor: '#fff' }}
                            label={t('UNITED_STATES')}
                          >
                            {statesAndProvincesGrouping.unitedStates.map(
                              stateValue => (
                                <option key={stateValue} value={stateValue}>
                                  {stateValue}
                                </option>
                              ),
                            )}
                          </optgroup>
                          <optgroup
                            style={{ backgroundColor: '#fff' }}
                            label={t('CANADA')}
                          >
                            {statesAndProvincesGrouping.canada.map(
                              stateValue => (
                                <option key={stateValue} value={stateValue}>
                                  {stateValue}
                                </option>
                              ),
                            )}
                          </optgroup>
                        </Select>
                        <FormHelperText error={Boolean(form.errors.state)}>
                          {form.errors.state}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      {statesAndProvincesGrouping.canada.includes(
                        form.values.state,
                      ) ? (
                        <CanadianZipInputField
                          name={'zip'}
                          label={t('ZIP_POSTAL_CODE')}
                          style={{ width: '100%' }}
                          inputProps={{ minLength: 1, maxLength: 7 }}
                          {...form.props('zip')}
                        />
                      ) : (
                        <ZipInputField
                          name={'zip'}
                          label={t('ZIP_POSTAL_CODE')}
                          style={{ width: '100%' }}
                          inputProps={{ minLength: 1, maxLength: 5 }}
                          {...form.props('zip')}
                        />
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      name={'inCareOf'}
                      label={t('IN_CARE_OF')}
                      style={{ width: '100%' }}
                      inputProps={{ minLength: 1, maxLength: 30 }}
                      {...form.props('inCareOf')}
                      helperText={
                        form.errors.inCareOf ||
                        t('NAME_OF_PERSON_TO_MAIL_BILL_TO')
                      }
                    />
                  </Grid>

                  <Grid item container direction="row" spacing={3}>
                    <Grid item xs={12} md={6}>
                      <PhoneTextField
                        autoComplete="telephone"
                        name={'phone'}
                        label={t('PHONE_NUMBER')}
                        style={{ width: '100%' }}
                        inputProps={{ minLength: 1, maxLength: 15 }}
                        {...form.props('phone')}
                        margin="none"
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <PhoneExtTextField
                        name={'phoneExt'}
                        label={t('EXT')}
                        style={{ width: '100%' }}
                        inputProps={{ minLength: 1, maxLength: 15 }}
                        {...form.props('phoneExt')}
                        margin="none"
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h2">
                      {t('WILL_ANYONE_LIVING_REMAIN')}
                    </Typography>
                  </Grid>

                  <Grid item container spacing={3} style={{ margin: 10 }}>
                    <RadioGroup
                      aria-labelledby={t('WILL_ANYONE_LIVING_REMAIN')}
                      name={'livingRemainAtProperty'}
                      value={String(form.values.livingRemainAtProperty)}
                      onChange={async e => {
                        const livingRemainAtProperty =
                          e.target.value === 'true';
                        await form.setValue(
                          'livingRemainAtProperty',
                          livingRemainAtProperty,
                        );
                        if (livingRemainAtProperty) {
                          await createLog({
                            variables: {
                              payload: {
                                encryptedAccountNumber,
                                encryptedPersonId,
                              },
                            },
                          });
                        }
                      }}
                    >
                      <Grid item xl={12}>
                        <FormControlLabel
                          value={'true'}
                          control={<Radio color={'primary'} />}
                          label={t('YES')}
                        />
                      </Grid>
                      <Grid item xl={12}>
                        <FormControlLabel
                          value={'false'}
                          control={<Radio color={'primary'} />}
                          label={t('NO')}
                        />
                      </Grid>
                      {form.values.livingRemainAtProperty && (
                        <FormHelperText error>
                          {richT('STOP_SERVICE_INELIGIBLE_USER_STAYING')}
                        </FormHelperText>
                      )}
                    </RadioGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid
            item
            container
            direction="row"
            justify={isMobile ? 'center' : 'flex-end'}
            spacing={1}
            wrap="wrap-reverse"
          >
            <Grid item xs={10} md="auto">
              <PGEButton
                fullWidth
                disabled={form.values.livingRemainAtProperty}
                onClick={wrapWithLoader(
                  form.submit(async (formData: Model) => {
                    const country = getPostalCountry(formData.zip);
                    await form.setValue('country', country);

                    const params: QuickAddressSearchParams = {
                      city: formData.city,
                      state: formData.state,
                      addressLine1: formData.address,
                      postal: formData.zip,
                      country: country,
                      isMailingAddress: true,
                    };

                    const results = await search(params);
                    return setQasData({ results, params });
                  }, scrollToErrors),
                )}
              >
                {t('NEXT')}
              </PGEButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {qasData && (
        <QasModal
          results={qasData.results}
          onChange={wrapWithLoader(async (address: SuggestedAddress) => {
            setQasData(null);
            await Promise.all([
              form.setValue('city', address.city),
              form.setValue('state', address.state),
              form.setValue('address', address.addressLine1),
              form.setValue('zip', address.postal),
              form.setValue('country', address.country),
              form.setValue('qasVerified', true),
            ]);
            return wrapWithLoader(form.submit(onSubmit))();
          })}
          address={qasData.params}
          onSearch={(params, results) => setQasData({ params, results })}
          onEdit={() => {
            scrollToMailingAddressRef.current = true;
            setQasData(null);
          }}
          onUseEntered={async () => {
            await form.setValue('qasVerified', false);

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            wrapWithLoader(form.submit(onSubmit))();
          }}
        />
      )}
    </>
  );
}
