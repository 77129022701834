import Grid from '@material-ui/core/Grid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { Router } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';

import AccountLayout from '../../../components/account-layout/AccountLayout';
import Backdrop from '../../../components/backdrop';
import ManageAccounts from '../../../components/manage-accounts';
import AddBusinessAccount from '../../../components/manage-accounts/add-business-account';
import AddResidentialAccount from '../../../components/manage-accounts/add-residential-account';
import useManageAccounts from '../../../hooks/useManageAccounts';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainArea: {
      minWidth: '50em',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        padding: '0 5em',
        minWidth: '60em',
      },
      [theme.breakpoints.down('md')]: {
        minWidth: '15em',
      },
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
  }),
);

type Props = {
  path?: string;
};

const ManageAccountsPage = (props: Props & WithWidth) => {
  const { t } = useTranslation();
  const manageAccountsProps = useManageAccounts();
  const { loading } = manageAccountsProps;
  const isMobile = ['xs', 'sm', 'md'].includes(props.width);
  const childProps = { ...manageAccountsProps, isMobile, ...props };
  const classes = useStyles();

  return (
    <AccountLayout>
      <Helmet>
        <title>{t('MANAGE_ACCOUNTS')}</title>
      </Helmet>
      <div className={classes.mainArea}>
        {loading && <Backdrop forceOpen />}

        {!loading && (
          <Grid
            container
            style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
          >
            <Grid item style={{ marginBottom: '1em' }} xs={12} md={8}>
              <Typography
                style={{ color: colors.noirBlur }}
                component={'span'}
                variant={'h1'}
              >
                {t('MANAGE_ACCOUNTS')}
              </Typography>
            </Grid>
            <Router basepath="/">
              <ManageAccounts {...childProps} path={ROUTES.MANAGE_ACCOUNTS} />
              <AddResidentialAccount
                {...childProps}
                {...childProps.residentialFormProps}
                path={ROUTES.MANAGE_RESIDENTIAL_ACCOUNT}
              />
              <AddBusinessAccount
                {...childProps}
                {...childProps.businessFormProps}
                path={ROUTES.MANAGE_BUSINESS_ACCOUNT}
              />
            </Router>
          </Grid>
        )}
      </div>
    </AccountLayout>
  );
};

export default withWidth()(ManageAccountsPage);
