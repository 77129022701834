import { Router } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import AccountLayout from '../../components/account-layout';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import MyPrograms from '../../components/my-programs';

export const MyProgramsMain = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('MY_PROGRAMS')}</title>
      </Helmet>
      <AccountLayout>
        <Router basepath="/">
          <MyPrograms path={`${ROUTES.MY_PROGRAMS}/*`} />
        </Router>
      </AccountLayout>
    </>
  );
};
export default MyProgramsMain;
