import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useTranslation } from '../../../hooks/useTranslation';
import useOpenEnrollment from '../../../hooks/useOpenEnrollment';
import Link from '../../text-link';
import AccountGroupSelect from '../../account-group-select';
import PGEButton from '../../buttons/PGE-Button';
import { isMobile } from '../../../util/style-utils';
import { navigate } from '@reach/router';
import ROUTES from '../../../routes';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import dayjs from 'dayjs';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  actionArea: {
    [theme.breakpoints.down('sm')]: {
      padingLeft: theme.spacing(4),
      padingRight: theme.spacing(4),
    },
  },
  formAction: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface OpenEnrollmentElectionProps {
  path?: string;
}

const OpenEnrollmentElection: FunctionComponent<OpenEnrollmentElectionProps> = props => {
  const { t } = useTranslation();
  const { isEnrollmentOpen, content, effectiveDate } = useOpenEnrollment();

  useEffect(() => {
    window.location.replace(ROUTES.OPEN_ENROLLMENT_ELECTION_LEGACY_URL);
  });

  return (
    <Grid container direction={'column'} spacing={4}>
      <Grid item>
        <Typography variant={'h2'}>
          {isEnrollmentOpen
            ? content.electionPage.open.title
            : content.electionPage.closed.title}
        </Typography>
        <Typography variant={'body2'}>
          {content.electionPage.open.note.replace(
            '<effective-date>',
            dayjs(effectiveDate).format(content.dateFormat),
          )}
        </Typography>
      </Grid>
      <Grid item>{isEnrollmentOpen ? <DoElection /> : <DeniedElection />}</Grid>
    </Grid>
  );
};

const DoElection: FunctionComponent = () => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const {
    accountGroups,
    isQuaterlyPeriod,
    isAnnualPeriod,
    currentEnlectionWindowCloseAt,
    effectiveDate,
    content,
  } = useOpenEnrollment();
  const notificationContext = useContext(NotificationsContext);

  const [accountGroup, setAccountGroup] = useState(accountGroups[0]);
  const [noSPIDError, setNoSPIDError] = useState(false);

  const onChangeGroup = (selectedGroup: any) => {
    setAccountGroup(selectedGroup);
    setNoSPIDError(false);
  };

  const onNext = () => {
    // TODO: CC&B checks that one or more eligible service points exist for the accounts in the currently selected group
    // And if no eligible service points are found
    if (!accountGroup.accounts.length) {
      setNoSPIDError(true);
      return;
    }

    return navigate(ROUTES.OPEN_ENROLLMENT_CONTACT);
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Card>
          <CardContent>
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <Typography variant={'h2'}>
                  {content.electionPage.open.subtitle}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction={'column'}>
                  <Grid item>
                    <Typography variant="body2">
                      {richT('OPEN_ENROLLMENT_ELECTION_OPEN_TEXT', {
                        DATE_TO_COMPLETE: dayjs(
                          currentEnlectionWindowCloseAt,
                        ).format(content.dateTimeFormat),
                      })}
                      {/* {content.electionPage.open.text.replace('<date-to-complete>', dayjs(currentEnlectionWindowCloseAt).format(content.dateTimeFormat))} */}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ul>
                      <li>
                        <Typography variant="body2">
                          {content.electionPage.open.option1.replace(
                            '<effective-date>',
                            dayjs(effectiveDate).format(content.dateFormat),
                          )}
                          {/* TODO: Change */}
                          <a
                            target={'__blank'}
                            href={content.electionPage.open.operationsUrl}
                          >
                            ESS
                          </a>
                        </Typography>
                      </li>
                      {isQuaterlyPeriod && (
                        <li>
                          <Typography variant="body2">
                            {content.electionPage.open.option2}
                          </Typography>
                        </li>
                      )}
                      {isAnnualPeriod && (
                        <li>
                          <Typography variant="body2">
                            {content.electionPage.open.option3.replace(
                              '<change-year>',
                              dayjs(effectiveDate).format(content.yearFormat),
                            )}
                          </Typography>
                        </li>
                      )}
                    </ul>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {content.electionPage.open.ineligibleNote}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2">
                      {content.electionPage.open.readFAQ}
                      <a href={content.electionPage.open.faqUrl}>FAQ</a>.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Divider />
              </Grid>
              <Grid item>
                <Typography variant={'h4'}>
                  {content.electionPage.open.selectAccountGroupLabel}
                </Typography>
              </Grid>
              <Grid item>
                {noSPIDError && (
                  <FormHelperText
                    className={clsx(
                      'MuiFormHelperText-root MuiFormHelperText-contained Mui-error',
                    )}
                  >
                    {content.electionPage.open.noServiceAddressesError}
                  </FormHelperText>
                )}
                <AccountGroupSelect
                  elements={accountGroups}
                  onSelect={onChangeGroup}
                />
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {content.electionPage.open.selectAccountGroupNote}
                </Typography>
              </Grid>
              <Grid item>
                <Link plain to={ROUTES.MANAGE_ACCOUNT_GROUPS}>
                  <Typography>
                    {content.electionPage.open.createAccountGroupLabel}
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        container
        //className={classes.actionArea}
        style={
          isMobile()
            ? { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }
            : {}
        }
        direction={isMobile() ? 'column-reverse' : 'row'}
        justify={'space-between'}
        alignItems={isMobile() ? 'stretch' : 'center'}
        spacing={1}
      >
        <Grid item>
          <Grid container justify={'center'}>
            <Link plain to={ROUTES.ACCOUNT}>
              <Typography className={classes.formAction}>
                {t('CANCEL')}
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction={isMobile() ? 'column-reverse' : 'row'}
            spacing={2}
          >
            <Grid item>
              <PGEButton
                variant={'contained'}
                color={'primary'}
                onClick={onNext}
                className={classes.formAction}
              >
                {t('NEXT')}
              </PGEButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const DeniedElection: FunctionComponent = () => {
  const { content } = useOpenEnrollment();
  const theme = useTheme();
  const classes = useStyles();
  const textParts = content.electionPage.closed.text.split(
    '<link>Market-based Pricing</link>',
  );

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Card>
          <CardContent>
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <Typography variant={'h2'}>
                  {content.electionPage.closed.subtitle}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {textParts[0]}
                  <a
                    href={content.electionPage.marketBasedPricinSiteUrl}
                    target="_blank"
                  >
                    {content.electionPage.marketBasedPricinSiteLabel}
                  </a>
                  {textParts[1]}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        container
        style={
          isMobile()
            ? { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }
            : {}
        }
        direction={'column'}
        alignItems={isMobile() ? 'stretch' : 'flex-end'}
      >
        <Grid item>
          <PGEButton
            variant={'contained'}
            color={'primary'}
            onClick={() => navigate(ROUTES.ACCOUNT)}
            className={classes.formAction}
          >
            {content.ok}
          </PGEButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OpenEnrollmentElection;
