import {
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { navigate, RouteComponentProps, Router } from '@reach/router';
import React, { FC, useEffect, useState } from 'react';
import { SSMStopServiceSuccess } from '../../../components/ssm/stop/SSMStopServiceSuccess';
import {
  SSMStopServiceAddress,
  createAddressAndDateFormAdapter,
  createAddressAndDateValidateFunction,
} from '../../../components/ssm/stop/SSMStopServiceAddress';
import { SSMStopServiceReview } from '../../../components/ssm/stop/SSMStopServiceReview';
import Page2ColumnLayout from '../../../components/utility/page2-column-layout';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import useSSMStopService, {
  CustContactCharacteristicType,
  CustContactCharacteristicValue,
  CustContactClass,
  CustContactType,
  DateAndAdress,
  eligibilityKey,
  StopEligibility,
} from '../../../hooks/useSSMStopService';
import { Wizard } from '../../../components/utility/wizard/Wizard';
import { WizardStep } from '../../../components/utility/wizard/WizardStep';
import { SSMServiceSummary } from '../../../components/ssm/common/SSMServiceSummary';
import {
  ServiceSummary,
  ServiceSummaryType,
  AccountInfo,
} from '../../../components/ssm/ssm.types';
import { SSMChooseAccount } from '../../../components/ssm/common/SSMChooseAccount';
import {
  QuickAddressSearchParams,
  ServiceAddress,
  StopServiceEligibilityResponse,
  PremiseInfo,
} from '../../../__generated__/pge-types';
import { usePremiseSearchQuery } from '../../../hooks/usePremiseSearchQuery';
import { QasResult } from '../../../hooks/useQas';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import useUtil from '../../../components/need-more-time-to-pay/useUtil';
import { SSMStopServiceIneligible } from '../../../components/ssm/stop/SSMStopServiceIneligible';
import useSSMStopGetAccountEligibilityQuery from '../../../hooks/useSSMStopGetAccountEligibilityQuery';
import SSMAddressErrorModal from '../../../components/ssm/stop/SSMAddressErrorModal';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import useIsPaperlessBillEnrolled from '../../../hooks/useIsPaperlessBillEnrolled';
import useSelectedGroupId from '../../../hooks/useSelectedGroupId';
import useAccountListHeader from '../../../components/account-summary/multi-accounts/useAccountListHeader';
import useAccountDetailList from '../../../hooks/useAccountDetailList';
import { getAccounts } from '../../../components/ssm/common/queries';
import Backdrop from '../../../components/backdrop/Backdrop';
import useGetAccountEligibilityQuery from '../../../hooks/useEqualPayCheck';
import { useCreateCustomerContact } from '../../../hooks/useCreateCustomerContact';
import useAccountCustomer from '../../../hooks/useAccountCustomer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 3, 16, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 2, 10, 2),
      },
    },
    mobileSummary: {
      padding: '2em',
      paddingTop: '0',
      marginTop: '-1px',
    },
    fullWidth: {
      width: '100%',
    },
    containerWidth: {
      maxWidth: '480px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      '& .MuiStepper-horizontal': {
        padding: 0,
        marginBottom: theme.spacing(2),
      },
    },
    containerWidthReviewPage: {
      maxWidth: '385px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
      '& .MuiStepper-horizontal': {
        padding: 0,
        marginBottom: theme.spacing(2),
      },
    },
    stepTitle: {
      marginBottom: theme.spacing(3),
    },
    mobileSummaryWrapper: {
      padding: '0 16px',
    },
  }),
);

const SSMStopService: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    ssmStopServiceState,
    setAddressAndDate,
    doVerifyAddress,
    onConfirm,
    setAccountDetails,
    setSSMStopServiceState,
  } = useSSMStopService();

  const { wrapWithLoader } = useWrapWithLoader();

  const [accountCount, setAccountCount] = useState<Number>();

  const pathname = props.location?.pathname;

  const { setPremiseIds, data } = usePremiseSearchQuery();
  const { setErrorNotification } = useUtil();

  const [serviceSummary, setServiceSummary] = useState<ServiceSummary>({
    type: ServiceSummaryType.STOP,
  });

  const [showAdressError, setShowAddressError] = useState<boolean>(false);

  const onBack = () => {
    setShowAddressError(false);
  };

  const {
    refetch: validateAccountAndPersonEligibility,
  } = useSSMStopGetAccountEligibilityQuery();

  const [
    personAndAccountInEligibleData,
    setPersonAndAccountInEligibleData,
  ] = useState<StopEligibility>({
    isPendingDisconnect: false,
    isDisconnected: false,
    hasMultiplePremises: false,
    hasNoActiveElectricSA: false,
    hasProtectedAddress: false,
    hasPendingStop: false,
    isEqualPay: false,
  });

  const [qasData, setQasData] = useState<null | {
    params: QuickAddressSearchParams;
    results: QasResult;
  }>();

  const [accountInfo, setAccountInfo] = useState<AccountInfo>();
  const [hasNoActiveElectricSAs, setHasNoActiveElectricSAs] = useState<boolean>(
    false,
  );

  const {
    encryptedAccountNumber,
    encryptedPersonId,
  } = useSelectedAccountParams();
  const {
    customer: customerInfoData,
    loading: customerInfoLoading,
    error: customerInfoError,
  } = useAccountCustomer();

  const { isEqualPay } = useGetAccountEligibilityQuery();

  const { isPaperlessBillEnrolled } = useIsPaperlessBillEnrolled(
    encryptedPersonId || '',
    encryptedAccountNumber || '',
  );

  const { selectedGroupId } = useSelectedGroupId();
  const { accountListParams } = useAccountListHeader();

  const { submit } = useCreateCustomerContact();

  const { accounts, totalCount } = useAccountDetailList(
    getAccounts,
    selectedGroupId,
    {
      accountListParams: {
        ...accountListParams,
      },
    },
  );

  const [continueLoading, setContinueLoading] = useState(true);

  useEffect(() => {
    const totalAccounts = customerInfoData?.accountMeta?.totalAccounts;
    if (totalAccounts === 0) {
      setContinueLoading(false);
      setHasNoActiveElectricSAs(true);
      void navigate(ROUTES.SSM_STOP_INELIGIBLE);
    } else {
      if (totalCount !== undefined) {
        if (
          pathname !== ROUTES.SSM_STOP_SUCCESS &&
          totalCount &&
          totalCount !== 1
        ) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          void navigate(ROUTES.SSM_STOP_ACCOUNT, { replace: true });
        } else {
          void handleSelectedAccount(accounts[0] as AccountInfo);
        }
      } else {
        void navigate(ROUTES.SSM_STOP, { replace: true });
      }
    }
  }, [totalCount, customerInfoLoading]);

  useEffect(() => {
    let address: ServiceAddress;
    if (ssmStopServiceState?.addressAndDate?.addressLine1) {
      const premiseInfo = ssmStopServiceState?.account
        ?.premiseInfo as PremiseInfo[];
      address = {
        addressLine1: `${premiseInfo[0]?.addressLine1}, ${premiseInfo[0]?.postal} `,
      };
    }

    setServiceSummary(summary => ({
      ...summary,
      ...(ssmStopServiceState?.account?.accountNumber
        ? { account: ssmStopServiceState?.account?.accountNumber }
        : {}),
      ...(ssmStopServiceState?.account?.mainCustomerName
        ? { name: ssmStopServiceState?.account?.mainCustomerName }
        : {}),
      ...(address ? { address: address } : {}),
      ...(ssmStopServiceState?.addressAndDate?.date
        ? { stopDate: ssmStopServiceState?.addressAndDate?.date }
        : {}),
    }));
  }, [ssmStopServiceState]);

  const handleAddressAndDate = wrapWithLoader(
    async (addressAndDate: DateAndAdress) => {
      const verifiedAddress = await doVerifyAddress(addressAndDate);
      setAddressAndDate({
        ...addressAndDate,
        country: verifiedAddress?.params?.country!,
        qasVerified: false,
      });
      setQasData(state => ({
        ...state,
        results: verifiedAddress?.results,
        params: verifiedAddress?.params,
      }));
      if (verifiedAddress.results.type === 'AUTO') {
        setAddressAndDate({
          ...addressAndDate,
          country: verifiedAddress?.params?.country!,
          qasVerified: true,
        });
        return true;
      }
      return false;
    },
  );

  const handleSelectedAccount = wrapWithLoader(
    async (_account: AccountInfo) => {
      const account = _account;
      if (Number(account?.premiseInfo?.length) > 1) {
        account.premiseInfo = _account.premiseInfo?.filter(
          premise => premise?.saDetails![0]?.saStatus !== 'STOPPED',
        );
      }
      if (account && Object.keys(account).length) {
        const premiseIdArr = account?.premiseInfo?.map(
          (premise: any) => premise?.encryptedPremiseId,
        );
        setAccountInfo(account);
        const equalPay = await isEqualPay(
          account?.accountNumber!,
          encryptedPersonId!,
        );

        if (equalPay?.equalpay?.paymentPlanType === 'EqualPay') {
          setPersonAndAccountInEligibleData(prev => {
            return {
              ...prev,
              isEqualPay: true,
            };
          });
          const payload = {
            accountNumber: account?.accountNumber!,
            longDescription: `Stop Service:Account - ${account?.accountNumber} has/have Enrolled in Equal Pay`,
            encryptedPersonId: encryptedPersonId,
            contactClass: CustContactClass.WSS,
            contactType: CustContactType.WEBCK,
            characteristics: [
              {
                type: CustContactCharacteristicType.CMCHNNL,
                value: CustContactCharacteristicValue.CSWEB,
                action: '',
              },
            ],
            shouldPrintLetter: false,
          };
          const result = await submit(payload);
          if (result?.data?.createWebCustomerContact) {
            setContinueLoading(false);
            await navigate(ROUTES.SSM_STOP_INELIGIBLE, { replace: true });
          }
        } else {
          const result = await validateAccountAndPersonEligibility({
            params: {
              acctId: account?.accountNumber || '',
              encryptedpremId:
                account?.premiseInfo?.[0]?.encryptedPremiseId || '',
            },
          });

          const stopSvcEligibilityData = result?.data
            ?.stopSrvceEligibility as StopServiceEligibilityResponse[];
          if (
            !result?.loading &&
            !result?.errors &&
            stopSvcEligibilityData?.length
          ) {
            const isInEligible = stopSvcEligibilityData
              ?.map(elig =>
                Object.fromEntries(
                  Object.entries(elig).filter(([key, value]) => value === true),
                ),
              )
              ?.filter(elg => Object.keys(elg).length);

            const updated: StopEligibility = isInEligible[0];
            if (updated) {
              Object.keys(updated)?.map((key: string) => {
                const notification = key as eligibilityKey;
                setPersonAndAccountInEligibleData(prev => {
                  return {
                    ...prev,
                    isEqualPay: false,
                    [key]: updated[notification],
                  };
                });
              });
            }
            if (isInEligible?.length) {
              setContinueLoading(false);
              await navigate(ROUTES.SSM_STOP_INELIGIBLE, { replace: true });
            } else {
              setContinueLoading(false);
              await navigate(ROUTES.SSM_STOP_ADDRESS, {
                replace: totalCount === 1 ? true : false,
              });
            }
          }
          setSSMStopServiceState({
            addressAndDate: {},
            account: {},
          });
          setPremiseIds(premiseIdArr);
          setAccountDetails(account);
          return true;
        }
      }
      return false;
    },
  );

  const handleConfirm = wrapWithLoader(async () => {
    const result = await onConfirm(data?.premiseSearch!);
    if (result?.errors) {
      setErrorNotification(true);
      return false;
    }
    if (result?.data?.stopServiceSubmitRequest?.isSucessfull) {
      return true;
    } else {
      setErrorNotification(true);
      return false;
    }
  });

  const steps = [
    {
      path: ROUTES.SSM_STOP_ACCOUNT,
      title: t('WHICH_ACCOUNT_DO_YOU_WANT_TO_STOP_SERVICE_FOR'),
      wizardStep: (
        <WizardStep
          initial={ssmStopServiceState.account}
          path={ROUTES.SSM_STOP_ACCOUNT}
          onNext={handleSelectedAccount}
          component={SSMChooseAccount}
          nextButton={false}
          backRoute={false}
          componentProps={{
            handleSelectedAccount,
            setAccountCount,
            setContinueLoading,
          }}
        ></WizardStep>
      ),
    },
    {
      path: ROUTES.SSM_STOP_ADDRESS,
      title: t('SSM_STOP_ADDRESS_AND_DATE_FORM'),
      wizardStep: (
        <WizardStep
          initial={ssmStopServiceState.addressAndDate}
          path={ROUTES.SSM_STOP_ADDRESS}
          onNext={handleAddressAndDate}
          backRoute={
            accountCount && accountCount > 1
              ? ROUTES.SSM_STOP_ACCOUNT
              : ROUTES.START_STOP_MOVE
          }
          nextRoute={ROUTES.SSM_STOP_REVIEW}
          component={SSMStopServiceAddress}
          formAdapter={createAddressAndDateFormAdapter()}
          validate={createAddressAndDateValidateFunction()}
          componentProps={{
            qasData,
            setQasData,
            setShowAddressError,
            ssmStopServiceState,
            isPaperlessBillEnrolled,
          }}
        ></WizardStep>
      ),
    },
    {
      path: ROUTES.SSM_STOP_REVIEW,
      title: t('EVERYTHING_LOOK_CORRECT'),
      wizardStep: (
        <WizardStep
          initial={ssmStopServiceState.addressAndDate}
          path={ROUTES.SSM_STOP_REVIEW}
          onNext={handleConfirm}
          nextText={t('SUBMIT')}
          backRoute={ROUTES.SSM_STOP_ADDRESS}
          nextRoute={ROUTES.SSM_STOP_SUCCESS}
          component={SSMStopServiceReview}
          componentProps={{ ssmStopServiceState }}
        ></WizardStep>
      ),
    },
  ];

  return (
    <>
      {continueLoading ? (
        <Backdrop forceOpen={true} message={t('LOADING')} />
      ) : null}
      <Hidden smUp>
        <Grid container direction="column" justify="center" alignItems="center">
          <Grid item container className={classes.mobileSummaryWrapper}>
            <SSMServiceSummary
              summary={serviceSummary}
              isPulldown={true}
              service="STOP"
            />
          </Grid>
        </Grid>
      </Hidden>
      <>
        {pathname &&
        ![ROUTES.SSM_STOP_SUCCESS, ROUTES.SSM_STOP_INELIGIBLE].includes(
          pathname,
        ) ? (
          <Page2ColumnLayout
            leftColumn={
              !window.location.pathname.includes(ROUTES.SSM_STOP_ACCOUNT) &&
              window.location.pathname !== ROUTES.SSM_STOP ? (
                <SSMServiceSummary summary={serviceSummary} />
              ) : (
                <></>
              )
            }
          >
            <Grid
              item
              direction="column"
              spacing={2}
              xs={12}
              justify={'center'}
              alignItems={'center'}
              className={
                pathname === ROUTES.SSM_STOP_REVIEW
                  ? classes.containerWidthReviewPage
                  : classes.containerWidth
              }
            >
              <Grid
                item
                container
                direction="column"
                xs={12}
                justify={'center'}
                alignItems={'center'}
              >
                <Hidden smDown>
                  <Grid
                    item
                    style={{ width: '100%' }}
                    className={
                      pathname === ROUTES.SSM_STOP_ACCOUNT
                        ? classes.stepTitle
                        : ''
                    }
                  >
                    <Typography variant={'h1'}>
                      {steps.find(s => s.path === pathname)?.title ?? ''}
                    </Typography>
                  </Grid>
                </Hidden>
                <Grid
                  item
                  justify="center"
                  xs={12}
                  className={classes.fullWidth}
                >
                  <Wizard basepath="/">
                    {steps.map(({ wizardStep, path, title }, index) => {
                      return (
                        <wizardStep.type
                          {...{
                            ...wizardStep.props,
                            path: path,
                            key: path,
                            title,
                            backRoute:
                              wizardStep.props.backRoute ??
                              (index > 0 ? steps[index - 1].path : false),
                            nextRoute:
                              wizardStep.props.nextRoute ??
                              (index < steps.length - 1
                                ? steps[index + 1].path
                                : undefined),
                          }}
                        />
                      );
                    })}
                  </Wizard>
                </Grid>
              </Grid>
            </Grid>
          </Page2ColumnLayout>
        ) : (
          <Grid container direction="row" justify="center">
            <Router basepath="/">
              <SSMStopServiceSuccess
                path={ROUTES.SSM_STOP_SUCCESS}
                ssmStopServiceState={ssmStopServiceState}
              />
              <SSMStopServiceIneligible
                path={ROUTES.SSM_STOP_INELIGIBLE}
                personAndAccountInEligibleData={personAndAccountInEligibleData}
                accountCount={accountCount!}
                serviceAddress={`${accountInfo?.premiseInfo!?.[0]
                  ?.addressLine1 || ''}, ${accountInfo?.premiseInfo!?.[0]
                  ?.city || ''}, ${accountInfo?.premiseInfo!?.[0]?.postal ||
                  ''}`}
                setPersonAndAccountInEligibleData={
                  setPersonAndAccountInEligibleData
                }
                hasNoActiveElectricSAs={hasNoActiveElectricSAs}
              />
            </Router>
          </Grid>
        )}
      </>
      <SSMAddressErrorModal open={showAdressError} onBack={onBack} />
    </>
  );
};

export default SSMStopService;
