import {
  TableRow,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { PeakTimeEvent } from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import { LanguageContext } from '../../../../providers/LanguageProvider';
import { formatFullMonthWithDay } from '../../../../util/date-utils';
import colors from '../../../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      maxHeight: '192px',
      overflow: 'scroll',
      overflowX: 'hidden',
      marginTop: '0.5rem',
      width: 'inherit',
    },

    evenRow: {
      background: colors.lightGray7,
    },

    tableDateCellStyle: {
      padding: '6px',
      borderBottom: 0,
      align: 'left',
      fontWeight: 'bold',
    },

    tableCreditCellStyle: {
      padding: '6px',
      borderBottom: 0,
      align: 'left',
    },
  }),
);

type SeasonalEventsProps = {
  ptrInfo?: PeakTimeEvent[];
};
export default function SeasonalEvents(props: SeasonalEventsProps) {
  const { ptrInfo } = props;
  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();

  return (
    <div className={classes.table}>
      <TableContainer>
        <Table>
          <TableBody>
            {ptrInfo &&
              ptrInfo?.map((data, index) => (
                <TableRow
                  key={data.eventDate || index}
                  className={index % 2 == 0 ? classes.evenRow : ''}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    className={classes.tableDateCellStyle}
                  >
                    {formatFullMonthWithDay(
                      new Date(data.eventDate || new Date()),
                      language,
                      t,
                    )}
                    :
                  </TableCell>
                  <TableCell className={classes.tableCreditCellStyle}>
                    ${data.eventEarnedCredit?.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
