import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { navigate } from 'gatsby';

import ROUTES from '../../../routes';
import { toDateString, toCurrencyDisplayFormat } from '../../../util/format';
import { getTpaPaymentsOverDue, getUpcomingTpaPayment } from './utils';
import useViewBill from '../../../hooks/useViewBill';
import useDownloadBill from '../../../hooks/useDownloadBill';
import { getViewBillInfo } from '../../../queries/viewBill.query';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { useTranslation } from '../../../hooks/useTranslation';

import PGEButton from '../../buttons';
import colors from '../../../themes/main-colors';
import TPAPlanView from '../../need-more-time-to-pay/landing/tpa-view/TPAPlanView';
import { TpaAccountDetails } from '../../need-more-time-to-pay/tpa/useTPA';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centerColumn: {
      width: '100%',
    },
    paymentPlanCard: {
      margin: theme.spacing(2, 0, 2, 0),
      padding: theme.spacing(2),
      width: '100%',
    },
    statusCardSpacing: {
      margin: theme.spacing(0, 0, 2, 0),
    },
    billTextMoney: {
      margin: theme.spacing(0, 0, 2, 0),
      fontWeight: 'bold',
    },
    missedPayment: {
      fontSize: '16px',
      color: `${colors.errorRed} !important`,
      margin: theme.spacing(0, 0, 2, 0),
      fontWeight: 600,
      maxWidth: '300px',
    },
    buttonsSection: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    alertContainer: {
      width: '100%',
      '& .MuiAlert-root': {
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: `${colors.errorRed}`,
        '& .MuiAlert-message': {
          textAlign: 'center',
          fontSize: '15px',
          fontWeight: 'bold',
        },
      },
    },
    boldText: {
      fontWeight: 'bold',
    },
  }),
);

type Props = {
  tpaDetails: TpaAccountDetails | undefined;
};

const TPAStatus: React.FC<Props> = props => {
  const { t } = useTranslation();

  const classes = useStyles();
  const { tpaDetails } = props;

  // FIXME: TPA Status Card Simplification - Below commented out lines represent the temp removal of the TPA Status card. Activate this once we bring it back.

  // const tpaMatrix = tpaDetails?.enrolledInstallmentDetails?.monthsMatrix;
  // const planInstallmentDueDate =
  //   tpaMatrix && getUpcomingTpaPayment(tpaMatrix)?.paymentDate;
  // const isTPAPaymentOverDue = React.useMemo(() => {
  //   if (tpaMatrix) {
  //     return getTpaPaymentsOverDue(tpaMatrix).length ? true : false;
  //   }
  // }, [tpaMatrix]);

  // const { billDetails } = useViewBill(getViewBillInfo);

  // const downloader = useDownloadBill();
  // const { wrapWithLoader } = useWrapWithLoader();

  // const downloadBill = wrapWithLoader(
  //   async (e?: any) => {
  //     if (e) {
  //       e.preventDefault();
  //     }
  //     if (billDetails?.encryptedBillId) {
  //       const billId = billDetails?.encryptedBillId;
  //       const start = billDetails?.billingPeriodStartDate;
  //       const end = billDetails?.billingPeriodEndDate;

  //       const linkSource = await downloader.downloadBill(billId);
  //       const downloadLink = document.createElement('a');

  //       downloadLink.href = linkSource;
  //       downloadLink.download = `${t('VIEW_BILL').replace(
  //         ' ',
  //         '_',
  //       )}-${toDateString(start)}_${toDateString(end)}.pdf`;
  //       downloadLink.click();
  //     }
  //   },
  //   () => t('NO_BILL_FOUND'),
  // );

  return (
    <Grid className={classes.centerColumn}>
      <Paper className={classes.paymentPlanCard}>
        <Box justifyContent={'left'} display={'flex'}>
          <Typography
            variant="h2"
            color="textSecondary"
            className={classes.statusCardSpacing}
          >
            {t('YOUR_PAYMENT_PLAN_PROGRESS')}
          </Typography>
        </Box>
        {/* {isTPAPaymentOverDue && (
          <Box
            justifyContent={'center'}
            display={'flex'}
            className={classes.statusCardSpacing}
            data-testid="payment-due-alert"
          >
            <div className={classes.alertContainer}>
              <Alert icon={false} variant="filled" severity="error">
                {t('WE_DID_NOT_RECEIVE_YOUR_PLAN_INSTALLMENT_DUE') + ' '}
                {toDateString(planInstallmentDueDate, 'MM/dd/yyyy')}.
              </Alert>
            </div>
          </Box>
        )} */}
        {/* FIXME: TPA Status Card Simplification - below text will get replaced with the commented out version right below it once we bring the TPA Status card back. */}
        <Box justifyContent={'left'} display={'flex'}>
          <Typography
            variant={'body1'}
            className={classes.statusCardSpacing}
            display="inline"
          >
            {t('YOUR_REMAINING_PAYMENT_PLAN_BALANCE')}{' '}
            <Typography
              variant={'body1'}
              className={classes.boldText}
              display="inline"
            >
              {'$' +
                toCurrencyDisplayFormat(
                  tpaDetails?.enrolledInstallmentDetails?.remainingBalance || 0,
                  true,
                  'CR',
                )}
            </Typography>
          </Typography>
        </Box>

        {/* <Box justifyContent={'center'} display={'flex'}>
          <Typography variant={'body1'} className={classes.statusCardSpacing}>
            {t('YOUR_REMAINING_PAYMENT_PLAN_BALANCE')}
          </Typography>
        </Box>
        <Box justifyContent={'center'} display={'flex'}>
          <Typography variant={'h1'} className={classes.billTextMoney}>
            $
            {toCurrencyDisplayFormat(
              tpaDetails?.enrolledInstallmentDetails?.remainingBalance || 0,
              true,
              'CR',
            )}
          </Typography>
        </Box>
        {isTPAPaymentOverDue && (
          <Box
            justifyContent={'center'}
            display={'flex'}
            data-testid="late-charge-alert"
          >
            <Typography
              variant={'body2'}
              align="center"
              className={classes.missedPayment}
            >
              {t('LATE_CHARGE_MISSED_PAYMENT')}
            </Typography>
          </Box>
        )}
        <Divider />
        {tpaMatrix && <TPAPlanView tpaMatrix={tpaMatrix} />}
        <Divider />
        <Box
          justifyContent={'center'}
          display={'flex'}
          className={classes.buttonsSection}
        >
          <PGEButton
            size="large"
            style={{
              background: colors.orange,
              marginRight: '1em',
            }}
            onMouseDown={() => navigate(ROUTES.PAYMENT)}
          >
            {t('PAY_BILL')}
          </PGEButton>
          <PGEButton onMouseDown={downloadBill} size="large">
            {t('DOWNLOAD_PDF')}
          </PGEButton>
        </Box> */}
      </Paper>
    </Grid>
  );
};

export default TPAStatus;
