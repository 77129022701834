import React, { FC } from 'react';
import { Typography, Box } from '@material-ui/core';
import Button from '../buttons';
import useStyles from './styles';
import AccountDropdown from '../account-dropdown';
import { useTranslation } from '../../hooks/useTranslation';
import PaperBox from '../utility/paper-box';
import { navigate } from 'gatsby';
import ROUTES from '../../routes';
import { useLocation } from '@reach/router';
import { toDateString } from '../../util/format';

interface Props {
  path?: string;
}

const MedicalCertificateConfirmation: FC<Props> = _ => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const locationState = location.state as {
    isEnrolled: boolean;
    expirationDate: string;
  };

  if (!locationState?.isEnrolled) {
    void navigate(ROUTES.MEDICAL_CERTIFICATE, { replace: true });
  }

  const expirationDate = locationState?.expirationDate
    ? toDateString(new Date(locationState?.expirationDate))
    : '';

  return (
    <>
      <Box mt={2} mb={1}>
        <AccountDropdown />
      </Box>
      <PaperBox className={classes.copyBlock}>
        <Typography className={`${classes.textBold}`} variant={'h4'}>
          {richT('MEDICAL_CERTIFICATE_SUCCESS_WITH_EXPIRY_DATE', {
            expirationDate,
          })}
        </Typography>
        <Typography className={`${classes.textBold}`} variant={'h4'}>
          {t('NEXT_STEPS')}
        </Typography>
        <Typography variant={'body1'}>
          {t('DOWNLOAD')} {t('THE').toLowerCase()}{' '}
          <a
            href={t('MEDICAL_CERTIFICATE_APPLICATION_URL')}
            download
            target="_blank"
          >
            {t('MEDICAL_CERTIFICATE_APPLICATION')}{' '}
            <img
              style={{ width: '14px' }}
              src={'/static/icon_PDF.svg'}
              alt=""
            />
          </a>
          .
        </Typography>
        {richT('MEDICAL_CERTIFICATE_NEXT_STEPS')}
      </PaperBox>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          marginTop: '.5em',
        }}
      >
        <Button
          variant={'contained'}
          color={'primary'}
          className={classes.submitButton}
          onClick={() => void navigate(ROUTES.ACCOUNT)}
        >
          {t('DONE')}
        </Button>
      </Box>
    </>
  );
};

export default MedicalCertificateConfirmation;
