import { makeStyles, Typography, Theme, Box } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import React from 'react';
import colors from '../../../../themes/main-colors';
import {
  PeakTimeEvent,
  PeakTimeEventSeason,
} from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import SunIcon from '../../../../static/sunIcon.svg';
import SnowIcon from '../../../../static/snowIcon.svg';
import SeasonalEvents from './SeasonalEvents';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: colors.white,
      display: 'flex',
      flexDirection: 'column',
      padding: '1rem',
      width: 'inherit',
    },
    heading: {
      display: 'flex',
    },

    paddingLeft: {
      paddingLeft: '2.5rem',
    },

    marginLeft: {
      marginLeft: '0.5rem',
    },

    dates: {
      '& p': {
        marginTop: '0px !important',
      },
    },
  }),
);

type PTRSectionBProps = {
  season: PeakTimeEventSeason;
  currentSeasonInfo?: React.ReactNode;
  currentSeasonHeaderText: React.ReactNode;
  peakTimeEvents?: PeakTimeEvent[];
};

export default function SeasonalInfo(props: PTRSectionBProps) {
  const {
    season,
    currentSeasonHeaderText,
    currentSeasonInfo,
    peakTimeEvents,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <Box className={classes.heading}>
        {PeakTimeEventSeason[season]?.toLowerCase() === 'summer' ? (
          <SunIcon />
        ) : (
          <SnowIcon />
        )}
        <div className={classes.marginLeft}>
          <Typography variant="subtitle1" className={classes.dates}>
            {currentSeasonHeaderText}
          </Typography>
        </div>
      </Box>
      <Box>
        {currentSeasonInfo ? (
          <Typography variant="subtitle1" className={classes.paddingLeft}>
            {currentSeasonInfo}
          </Typography>
        ) : (
          <SeasonalEvents ptrInfo={peakTimeEvents} />
        )}
      </Box>
    </div>
  );
}
