import { Dispatch, SetStateAction } from 'react';

import { AccountType } from '../components/payment-form/PaymentForm.types';
import { AccountState } from '../state/account';

const persistState = (account: AccountState): void => {
  sessionStorage.setItem('account', JSON.stringify(account));
};

export const addAccount = (
  setState: Dispatch<SetStateAction<any>>,
  payload: AccountType,
): void => {
  setState((state: AccountState) => {
    const accountState = {
      ...state,
      accounts: [...state.accounts, { ...payload }],
    };
    persistState(accountState);
    return accountState;
  });
};

export const removeAccounts = (
  setState: Dispatch<SetStateAction<any>>,
): void => {
  setState((state: AccountState) => {
    const accounts = state.accounts.filter(
      (acct: AccountType) => !acct.checked || !acct.active,
    );
    const accountState = {
      ...state,
      accounts,
    };
    persistState(accountState);
    return accountState;
  });
};

export const removeAccount = (
  setState: Dispatch<SetStateAction<any>>,
  payload: { idx: number },
): void => {
  setState((state: AccountState) => {
    const { idx } = payload;
    const accounts = [...state.accounts].filter((_, index) => index !== idx);
    const accountState = {
      ...state,
      accounts,
      selectedAccount: null,
    };
    persistState(accountState);
    return accountState;
  });
};

export const selectAllAccounts = (
  setState: Dispatch<SetStateAction<any>>,
  payload: boolean,
): void => {
  setState((state: AccountState) => {
    const accountState = {
      ...state,
      accounts: state.accounts.map((acct: AccountType) => ({
        ...acct,
        checked: payload,
      })),
    };
    persistState(accountState);
    return accountState;
  });
};

export const toggleCheckBox = (
  setState: Dispatch<SetStateAction<any>>,
  payload: { idx: number; value: boolean },
): void => {
  setState((state: AccountState) => {
    const { value, idx } = payload;
    const accounts = [...state.accounts];
    accounts[idx] = {
      ...accounts[idx],
      checked: value,
    };
    const accountState = {
      ...state,
      accounts,
    };
    persistState(accountState);
    return accountState;
  });
};

export const updateNickname = (
  setState: Dispatch<SetStateAction<any>>,
  payload: { idx: number; nickname: string },
): void => {
  setState((state: AccountState) => {
    const { idx, nickname } = payload;
    const accounts = [...state.accounts];
    accounts[idx] = {
      ...accounts[idx],
      nickname,
    };
    const accountState = {
      ...state,
      accounts,
      selectedAccount: null,
    };
    persistState(accountState);
    return accountState;
  });
};

export const updateSelectedAccount = (
  setState: Dispatch<SetStateAction<any>>,
  payload: { idx: number },
): void => {
  setState((state: AccountState) => {
    const { idx } = payload;
    const accountState = {
      ...state,
      selectedAccount: idx,
    };
    persistState(accountState);
    return accountState;
  });
};

export const updatePrimaryAccount = (
  setState: Dispatch<SetStateAction<any>>,
  payload: { idx: number },
): void => {
  setState((state: AccountState) => {
    const { idx } = payload;
    const { accountNumber, active } = state.accounts[idx];
    if (!active) {
      return { ...state, selectedAccount: null };
    }
    const accountState = {
      ...state,
      primaryAccountNumber: accountNumber,
      selectedAccount: null,
    };
    persistState(accountState);
    return accountState;
  });
};
