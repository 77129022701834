import React from 'react';
import { Box, Button, Typography, withWidth } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import colors from '../../../themes/main-colors';
import { IqbdEnrollIneligibilityReason } from '../../../__generated__/pge-types';

import IQBDFormSubmitSuccess from '../IQBDFormSubmitSuccess';
import { FORM_TYPE_ACTION_BUTTON_TYPES } from '../Form';
import IQBDFormUnenrolled from '../IQBDFormUnenrolled';
import useContentMgmt, {
  PageTextContentType,
} from '../../../hooks/useContentMgmt';

interface HeaderCardProps {
  content: PageTextContentType;
  type: string;
  reason?: string;
  discount?: string;
  expirationDate?: string;
  formTypeActionButtonTypes?: FORM_TYPE_ACTION_BUTTON_TYPES[];
  handleUpdate: () => void;
  handleUnEnroll: () => void;
  onClickDone: () => void;
}

export enum IQBD_HEADER_CARD_TYPES {
  Success = 'success',
  Enrolled = 'enrolled',
  UnEnrolled = 'unenrolled',
  IneligibleFromPrevApplication = 'ineligibleFromPrevApplication',
  Ineligible = 'ineligible',
  Expired = 'expired',
  PendingReview = 'pendingReview',
  EnrolledFromAnotherAccount = 'enrolledFromAnotherAccount',
}

const useStyles = makeStyles(() => ({
  container: {
    boxShadow: '0px 1px 1px #00000066',
    backgroundColor: colors.white,
    marginBottom: '30px',
    padding: '30px',
  },
  heading: {
    textAlign: 'center',
    fontSize: '32px',
    lineHeight: '40px',
    fontFamil: 'Untitled Sans',
    fontWeight: 'bold',
    color: colors.darkBlack,
    marginBottom: '30px',
  },
  messagesBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  message: {
    fontSize: '18px',
    lineHeight: 1.5,
    color: colors.darkBlack,
  },
  button: {
    borderRadius: '5px',
    padding: '8px 37px',
    fontWeight: 'bold',
    fontSize: '18px',
    fontFamily: 'Untitled-Sans',
    display: 'block',
    backgroundColor: colors.buttonHoverPrimary,
    border: `1px solid ${colors.buttonHoverPrimary}`,
    color: colors.white,
    '&:hover': {
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: colors.buttonHoverPrimary,
        borderColor: colors.buttonHoverPrimary,
        color: colors.white,
      },
    },
  },
}));

const IQBDHeaderCard = ({
  content,
  type,
  reason,
  discount,
  expirationDate,
  formTypeActionButtonTypes,
  handleUpdate,
  handleUnEnroll,
  onClickDone,
}: HeaderCardProps) => {
  const classes = useStyles();
  const { richText } = useContentMgmt();

  const getParaText = () => {
    switch (type) {
      case IQBD_HEADER_CARD_TYPES.IneligibleFromPrevApplication:
        return content.get(
          'HEADER_CARD_PARA_TEXT_INELIGIBLE_FROM_PREV_APPLICATION',
        );
      case IQBD_HEADER_CARD_TYPES.Enrolled:
        return richText(content.get('HEADER_CARD_PARA_TEXT_ENROLLED'), {
          discount,
          expirationDate,
        });
      case IQBD_HEADER_CARD_TYPES.Expired:
        return content.get('HEADER_CARD_PARA_TEXT_EXPIRED');
      case IQBD_HEADER_CARD_TYPES.PendingReview:
        return content.get('HEADER_CARD_PARA_TEXT_PENDING_REVIEW');
      case IQBD_HEADER_CARD_TYPES.EnrolledFromAnotherAccount:
        return content.get('HEADER_CARD_PARA_TEXT_ALREADY_ENROLLED');
    }
  };

  const getIneligibilityReason = () => {
    switch (reason) {
      // Tier 1 ineligibilities
      case IqbdEnrollIneligibilityReason.CustNotRes:
        return content.get('CUS_NOT_RES_INELIGIBILITY_MSG');
      case IqbdEnrollIneligibilityReason.NotMainPerson:
        return content.get('NOT_MAIN_PERSON_INELIGIBILITY_MSG');
      case IqbdEnrollIneligibilityReason.NotEligibleSa:
        return content.get('NOT_ELIGIBLE_SA_AND_HOUSESIZE_INELIGIBILITY_MSG');

      // Tier 2 ineligibilities
      case IqbdEnrollIneligibilityReason.Multicase:
      case IqbdEnrollIneligibilityReason.Multisa:
      case IqbdEnrollIneligibilityReason.Other:
      case IqbdEnrollIneligibilityReason.Pgedisc:
        return content.get('HEADER_CARD_PARA_TEXT_PENDING_REVIEW');
      case IqbdEnrollIneligibilityReason.Housesize:
        return content.get('NOT_ELIGIBLE_SA_AND_HOUSESIZE_INELIGIBILITY_MSG');
      case IqbdEnrollIneligibilityReason.AlreadyEnrolled:
        return content.get('HEADER_CARD_PARA_TEXT_ALREADY_ENROLLED');
      case IqbdEnrollIneligibilityReason.Overincome:
        return content.get('OVERINCOME_INELIGIBILITY_MSG');
    }
  };

  const isPendingReviewCase =
    type === IQBD_HEADER_CARD_TYPES.PendingReview ||
    reason === IqbdEnrollIneligibilityReason.Multicase ||
    reason === IqbdEnrollIneligibilityReason.Multisa ||
    reason === IqbdEnrollIneligibilityReason.Other ||
    reason === IqbdEnrollIneligibilityReason.Pgedisc;

  const shouldShowContactVerbiage =
    isPendingReviewCase ||
    type === IQBD_HEADER_CARD_TYPES.Expired ||
    type === IQBD_HEADER_CARD_TYPES.Enrolled ||
    reason === IqbdEnrollIneligibilityReason.CustNotRes ||
    reason === IqbdEnrollIneligibilityReason.Overincome;

  const heading =
    type === IQBD_HEADER_CARD_TYPES.IneligibleFromPrevApplication
      ? content.get('HEADER_CARD_NOT_ENROLLED_HEADING')
      : type === IQBD_HEADER_CARD_TYPES.Enrolled
      ? content.get('HEADER_CARD_ENROLLED_HEADING')
      : type === IQBD_HEADER_CARD_TYPES.EnrolledFromAnotherAccount
      ? content.get('HEADER_CARD_ALREADY_ENROLLED_HEADING')
      : type === IQBD_HEADER_CARD_TYPES.PendingReview
      ? content.get('HEADER_CARD_PENDING_REVIEW_ENROLLED_HEADING')
      : type === IQBD_HEADER_CARD_TYPES.Expired
      ? content.get('HEADER_CARD_ENROLLMENT_EXPIRED_HEADING')
      : '';

  return type === IQBD_HEADER_CARD_TYPES.Success ? (
    <IQBDFormSubmitSuccess content={content} discount={discount} />
  ) : type === IQBD_HEADER_CARD_TYPES.UnEnrolled ? (
    <IQBDFormUnenrolled content={content} onClickDone={onClickDone} />
  ) : (
    <Box className={classes.container}>
      {heading && (
        <Typography
          className={classes.heading}
          css={{
            color:
              type === IQBD_HEADER_CARD_TYPES.Enrolled
                ? colors.shoreGreen
                : colors.darkBlack,
          }}
        >
          {heading}
        </Typography>
      )}
      <Box className={classes.messagesBox}>
        <Typography className={classes.message}>{getParaText()}</Typography>
        {type === IQBD_HEADER_CARD_TYPES.Ineligible && reason && (
          <Typography className={classes.message}>
            {getIneligibilityReason()}
          </Typography>
        )}
        {shouldShowContactVerbiage && (
          <Typography className={classes.message}>
            {isPendingReviewCase ||
            type === IQBD_HEADER_CARD_TYPES.Expired ||
            type === IQBD_HEADER_CARD_TYPES.Enrolled
              ? content.get('HAVE_ANY_QUESTIONS')
              : content.get('IF_RECEIVED_MSG_IN_ERROR')}{' '}
            <Typography
              component={'span'}
              css={{
                color: colors.accentRed,
                fontWeight: 'bold',
                cursor: 'pointer',
              }}
            >
              {content.get('ASSISTANCE_PHONE_NUMBER')}.
            </Typography>
          </Typography>
        )}
      </Box>
      <Box
        css={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '32px',
          marginTop: '32px',
        }}
      >
        {formTypeActionButtonTypes?.map(buttonType => {
          return (
            <Button
              className={classes.button}
              onClick={
                buttonType === FORM_TYPE_ACTION_BUTTON_TYPES.Update
                  ? handleUpdate
                  : handleUnEnroll
              }
            >
              {buttonType === FORM_TYPE_ACTION_BUTTON_TYPES.Update
                ? content.get('UPDATE')
                : content.get('UNENROLL')}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
};

export default withWidth()(IQBDHeaderCard);
