import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import sumBy from 'lodash/sumBy';
import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import {
  displayAccountIdentifier,
  toCurrencyString,
  toCurrencyDisplayFormat,
} from '../../util/format';
import { useIsMobile } from '../../util/style-utils';
import { AccountAmounts, PaymentProfileState } from './types';
import { getMultipayEligibility } from './utils';
import ErrorIcon from '@material-ui/icons/Error';
import { AccountDetail } from '../../__generated__/pge-types';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    totalArea: {
      background: colors.lightGray2,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    },
    sum: {
      fontSize: '1.8em',
    },
    total: {
      fontSize: '1.3em',
    },
    ineligible: {
      color: `${theme.palette.error.main} !important`,
    },
  }),
);

type Props = {
  accountAmounts: Array<AccountAmounts>;
  timestamp?: string;
  disclaimer?: React.ReactNode;
  profileState?: PaymentProfileState;
  skipEligibilityCheck?: boolean;
};

type RowProps = {
  account: AccountDetail;
  amount: number;
  rowKey: string;
  className?: string;
  isEligible: boolean;
};
const Row = ({ account, amount, rowKey, className, isEligible }: RowProps) => {
  return (
    <Grid item key={rowKey} xs={12} className={className || ''}>
      <Grid container>
        <Grid
          container
          item
          xs={8}
          md={6}
          direction={'row'}
          spacing={1}
          alignItems={'center'}
        >
          {!isEligible && (
            <Grid item>
              <ErrorIcon></ErrorIcon>
            </Grid>
          )}
          <Grid item>
            <Typography variant="body1">
              <strong>{displayAccountIdentifier(account)}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item xs={3}>
            <Typography variant="body1" align="center">
              $
              {toCurrencyDisplayFormat(
                Number(account?.currentCharges?.amountDue),
                true,
                'CR',
              )}
            </Typography>
          </Grid>
        </Hidden>
        <Grid item xs={4} md={3}>
          <Typography variant="body1" align="right">
            ${toCurrencyString(amount, true)}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function ReviewTable({
  accountAmounts,
  timestamp,
  disclaimer,
  profileState,
  skipEligibilityCheck,
}: Props) {
  const { t, richT } = useTranslation();
  const isMobile = useIsMobile();
  const classes = useStyles();

  const eligibleAccounts = accountAmounts.filter(
    ({ account }) =>
      skipEligibilityCheck ||
      getMultipayEligibility(
        account,
        profileState?.selectedProfileInfo?.profile,
      ).isEligible,
  );

  const ineligibleAccounts = accountAmounts.reduce(
    (ineligibleGrouping, { account, amount }) => {
      const { isEligible, type } = getMultipayEligibility(
        account,
        profileState?.selectedProfileInfo?.profile,
      );
      if (!skipEligibilityCheck && !isEligible && type) {
        ineligibleGrouping.set(type, [
          ...(ineligibleGrouping.get(type) || []),
          { account, amount },
        ]);
      }
      return ineligibleGrouping;
    },
    new Map<string, Array<AccountAmounts>>(),
  );

  const total = sumBy(eligibleAccounts, 'amount');

  return (
    <Card>
      <CardContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  borderBottom="1px solid #ccc"
                  paddingBottom={2}
                  marginTop={1}
                >
                  <Grid container>
                    <Grid item xs={8} md={6}>
                      <Typography variant="body1">{t('ACCOUNT')}</Typography>
                    </Grid>
                    <Hidden smDown>
                      <Grid item xs={3}>
                        <Typography variant="body1" align="center">
                          {t('AMOUNT_DUE')}*
                        </Typography>
                      </Grid>
                    </Hidden>
                    <Grid item xs={4} md={3}>
                      <Typography variant="body1" align="right">
                        {isMobile ? t('PAYMENT') : t('PAYMENT_AMOUNT')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {Array.from(ineligibleAccounts.entries()).map(([key, values]) => {
                return (
                  <Grid container item key={`ineligible-table-${key}`}>
                    {values.map(({ account, amount }) => (
                      <Row
                        key={account.accountNumber}
                        account={account}
                        amount={amount}
                        rowKey={account.accountNumber}
                        className={classes.ineligible}
                        isEligible={false}
                      ></Row>
                    ))}
                    {
                      <Grid item xs={12} key={`ineligible-${key}`}>
                        <Grid container>
                          <Typography
                            variant={'body1'}
                            component={'div'}
                            className={classes.ineligible}
                          >
                            {richT(key, {
                              ASSISTANCE_NUMBER: t(
                                'CALL_FOR_ASSISTANCE_NUMBER',
                              ),
                            })}
                          </Typography>
                        </Grid>
                      </Grid>
                    }
                    {
                      <Grid item xs={12} key={`divier-ineligible-${key}`}>
                        <Divider></Divider>
                      </Grid>
                    }
                  </Grid>
                );
              })}
              {eligibleAccounts.map(({ account, amount }) => (
                <Row
                  key={account.accountNumber}
                  account={account}
                  amount={amount}
                  rowKey={account.accountNumber}
                  isEligible={true}
                ></Row>
              ))}
              {eligibleAccounts?.length > 0 && (
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              justify="space-between"
              alignItems={isMobile ? 'stretch' : 'center'}
              direction={isMobile ? 'column-reverse' : 'row'}
              spacing={2}
            >
              <Grid item xs={12} md="auto">
                {timestamp && (
                  <Typography variant="body1" align="center">
                    {t('AMOUNT_DUE_INCLUDES')} {timestamp}.
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} md="auto">
                <Grid
                  container
                  alignItems="flex-end"
                  spacing={1}
                  className={classes.totalArea}
                  justify="center"
                >
                  <Grid item>
                    <Typography variant="body1" className={classes.total}>
                      {t('TOTAL')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h3" className={classes.sum}>
                      ${toCurrencyString(total, true)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {disclaimer && eligibleAccounts?.length > 0 && (
            <Grid item xs={12}>
              {disclaimer}
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}
