import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import useSSMPostEnrollment from '../../../../../hooks/useSSMPostEnrollment';
import { useTranslation } from '../../../../../hooks/useTranslation';
import routes from '../../../../../routes';
import ProgressTracker from '../../../../ProgressTracker';
import { WizardStep } from '../../../../utility/wizard/WizardStep';
import ContactInformation from './ContactInformation';
import VerifyInformation from './VerifyInformation';
import {
  createCoCustomerInfoValidateFunction,
  createCoCustomerVerifyLastFourDigitsValidateFunction,
  createCoCustomerVerifyValidateFunction,
} from './AddCoCustomerForm.rules';
import Backdrop from '../../../../backdrop';
import {
  SSMCoCustomerInfoFormModel,
  SSMCoCustomerVerificationFormModel,
} from '../../../ssm.types';
import {
  PersonVerificationType,
  QueryValidatePersonArgs,
  ValidatePersonContactValueInput,
  Maybe,
  ValidatePersonResponse,
  PersonPrimaryIdType,
  ApplicantVerificationEligibility,
  ApplicantWithEligibilityResponse,
  PersonalIdentificationType,
  CustomerContactInput,
} from '../../../../../__generated__/pge-types';
import useValidatePersonQuery from '../../../../../hooks/useValidatePersonQuery';
import Ineligible, { CoCustomerIneligibleMessageTypes } from './Ineligible';
import { navigate } from '@reach/router';
import {
  CustContactCharacteristicType,
  CustContactCharacteristicValue,
  CustContactClass,
  CustContactType,
} from '../../../../../hooks/useSSMStopService';
import useUtil from '../../../../need-more-time-to-pay/useUtil';
import { useCreateCustomerContact } from '../../../../../hooks/useCreateCustomerContact';

enum WIZARD_STEPS {
  CONTACT_INFORMATION = 'contact_information',
  VERIFY_INFORMATION = 'verify_information',
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textCenter: {
      textAlign: 'center',
    },
  }),
);

interface Props {
  refetchCustomerList: () => void;
  onClose: () => void;
}
interface ExperianCreditCheckFailure {
  [key: string]: number;
}

interface ExperianFailedCacheData {
  firstName: string;
  middleName: string;
  lastName: string;
  ssn: string;
}

const AddCoCustomerForm = ({ onClose, refetchCustomerList }: Props) => {
  const classes = useStyles();
  const { refetch: validatePerson } = useValidatePersonQuery();
  const { submit: createWebCustomerContact } = useCreateCustomerContact();

  const { setErrorNotification } = useUtil();

  const idErrorCount = useRef(0);
  const idExperianCreditCheckFailure = useRef<ExperianCreditCheckFailure>({});
  const [
    experianFailedData,
    setExperianFailedData,
  ] = useState<ExperianFailedCacheData | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false);

  const [validatePersonData, setValidatePersonData] = useState<
    ValidatePersonResponse
  >();

  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState<WIZARD_STEPS>(
    WIZARD_STEPS.CONTACT_INFORMATION,
  );

  const [
    inEligible,
    setInEligible,
  ] = useState<CoCustomerIneligibleMessageTypes | null>(null);
  const [idValueError, setIdValueError] = useState<string>('');

  const {
    ssmPostEnrollmentState,
    setNotificationMessage,
    setCoCustomerContactInformation,
    setCoCustomerVerifyInformation,
    submitCoCustomer,
    account,
  } = useSSMPostEnrollment();

  const handleContactInfo = async (contactInfo: SSMCoCustomerInfoFormModel) => {
    try {
      setLoading(true);
      const contactValues: Array<Maybe<ValidatePersonContactValueInput>> = [];
      if (Number(contactInfo.primaryPhone?.length) > 0) {
        contactValues.push({
          value: String(contactInfo.primaryPhone),
          type: PersonVerificationType.Pnp,
        });
      }

      if (Number(contactInfo.mobilePhone?.length) > 0) {
        contactValues.push({
          value: String(contactInfo.mobilePhone),
          type: PersonVerificationType.Mob,
        });
      }

      if (Number(contactInfo.alternatePhone?.length) > 0) {
        contactValues.push({
          value: String(contactInfo.alternatePhone),
          type: PersonVerificationType.Alt,
        });
      }

      const params: QueryValidatePersonArgs = {
        payload: {
          firstName: String(contactInfo.firstName)?.trim(),
          middleName: String(contactInfo.middleName)?.trim(),
          lastName: String(contactInfo.lastName)?.trim(),
          contactValues,
        },
      };

      const result = await validatePerson(params);

      if (!result.loading && !result.errors) {
        setCoCustomerContactInformation(contactInfo);

        const response = result.data?.validatePerson;
        if (response) {
          if (response.isFraud || response.isBadDebt) {
            if (response.encryptedPersonId) {
              const payload: CustomerContactInput = {
                accountNumber: account?.accountNumber!,
                longDescription: response.isBadDebt
                  ? 'Co-Customer noted for bad debt'
                  : 'Co-customer noted for Fraud',
                encryptedPersonId: response.encryptedPersonId,
                contactClass: CustContactClass.WSS,
                contactType: CustContactType.WEBCK,
                characteristics: [
                  {
                    type: CustContactCharacteristicType.CMCHNNL,
                    value: CustContactCharacteristicValue.CSWEB,
                    action: '',
                  },
                ],
                shouldPrintLetter: false,
              };
              const ccResult = await createWebCustomerContact(payload);
              if (
                !ccResult?.errors &&
                ccResult?.data?.createWebCustomerContact
              ) {
                setLoading(false);
                setInEligible(CoCustomerIneligibleMessageTypes.C2M_CHECK);
              }
              if (ccResult?.errors) {
                setLoading(false);
                setErrorNotification(true);
              }

              return false;
            }
          }

          setValidatePersonData(response);
        } else {
          setValidatePersonData(undefined);
        }

        setLoading(false);

        //Check if the next button is disabled due to experian error
        if (isNextDisabled && experianFailedData) {
          const customerInfo = {
            firstName: contactInfo.firstName,
            middleName: contactInfo.middleName,
            lastName: contactInfo.lastName,
            ssn: ssmPostEnrollmentState.coCustomersVerifyInformation.idValue,
          };
          checkInfoHasBeenUpdated(customerInfo);
        }

        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      setNotificationMessage(
        t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
        'error',
      );
      onClose();
      return false;
    }

    setActiveStep(WIZARD_STEPS.VERIFY_INFORMATION);
    return true;
  };

  const generateErrorTrackerKey = (
    contactInfo: SSMCoCustomerInfoFormModel,
    verificationInfo: SSMCoCustomerVerificationFormModel,
  ) => {
    const key = [
      contactInfo.firstName,
      contactInfo.middleName,
      contactInfo.lastName,
      verificationInfo.idValue,
    ]
      .join()
      .replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '');

    if (!(key in idExperianCreditCheckFailure.current)) {
      idExperianCreditCheckFailure.current = {
        ...idExperianCreditCheckFailure.current,
        [key]: 0,
      };
    }

    return key;
  };

  const handleSubmitCoCustomer = async (
    verificationInfo: SSMCoCustomerVerificationFormModel,
  ) => {
    setLoading(true);
    setCoCustomerVerifyInformation(verificationInfo);
    setIdValueError('');

    try {
      const _verificationInfo = {
        ...verificationInfo,
        idValue: verificationInfo?.idValue?.trim() || '',
      };
      const response = await submitCoCustomer(
        {
          ..._verificationInfo,
          ...ssmPostEnrollmentState.coCustomersContactInfo,
        },
        validatePersonData,
      );

      setLoading(false);

      if (response?.addCoApplicant.verificationFlag) {
        const verificationFlag = response?.addCoApplicant?.verificationFlag;

        if (
          verificationFlag ===
          ApplicantVerificationEligibility.IdVerificationFailed
        ) {
          idErrorCount.current += 1;

          if (idErrorCount.current < 3) {
            setIdValueError(t('THEIR_ID_IS_INVALID'));
            return false;
          }

          void navigate(routes.SSM_POST_ENROLLMENT_ADD_CO_CUSTOMER_ASSISTANCE);
          return false;
        }

        //Track experian errors and limit API calls made for same data again
        if (
          verificationFlag ===
            ApplicantVerificationEligibility.ExperianCreditCheckError ||
          verificationFlag ===
            ApplicantVerificationEligibility.ExperianCreditCheckFailed ||
          verificationFlag ===
            ApplicantVerificationEligibility.ExperianCreditCheckPersonNotFound
        ) {
          const experianErrorCheckKey = generateErrorTrackerKey(
            ssmPostEnrollmentState.coCustomersContactInfo,
            verificationInfo,
          );
          const errorCount =
            idExperianCreditCheckFailure.current[experianErrorCheckKey];
          idExperianCreditCheckFailure.current[experianErrorCheckKey] =
            errorCount + 1;

          if (
            idExperianCreditCheckFailure.current[experianErrorCheckKey] >= 3
          ) {
            void navigate(
              routes.SSM_POST_ENROLLMENT_ADD_CO_CUSTOMER_ASSISTANCE,
            );
            return false;
          }

          setIsNextDisabled(true);
          const {
            firstName,
            middleName,
            lastName,
          } = ssmPostEnrollmentState.coCustomersContactInfo;
          setExperianFailedData({
            firstName,
            middleName,
            lastName,
            ssn: verificationInfo.idValue,
          });
          const message =
            verificationFlag ===
            ApplicantVerificationEligibility.ExperianCreditCheckPersonNotFound
              ? CoCustomerIneligibleMessageTypes.NOT_FOUND
              : CoCustomerIneligibleMessageTypes.EXPERIAN_CREDIT_CHECK;

          setInEligible(message);
          return true;
        }
      }

      refetchCustomerList();
      setNotificationMessage(t('SSM_ADD_CO_CUSTOMER_SUCCESS'));
      onClose();
    } catch (e) {
      setNotificationMessage(
        t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
        'error',
      );
      setLoading(false);
      onClose();
    }
    return false;
  };

  const checkInfoHasBeenUpdated = (customerInfo: ExperianFailedCacheData) => {
    if (experianFailedData) {
      const customerStr =
        customerInfo.firstName +
        customerInfo.middleName +
        customerInfo.lastName +
        customerInfo.ssn;
      const experianFailedStr =
        experianFailedData.firstName +
        experianFailedData.middleName +
        experianFailedData.lastName +
        experianFailedData.ssn;

      if (
        experianFailedStr.toLocaleLowerCase() !==
        customerStr.toLocaleLowerCase()
      ) {
        setIsNextDisabled(false);
      } else {
        setIsNextDisabled(true);
      }
    }
  };

  const onSSNChange = (idValue: string) => {
    if (isNextDisabled && idValue) {
      const customerInfo = {
        firstName: ssmPostEnrollmentState.coCustomersContactInfo.firstName,
        middleName: ssmPostEnrollmentState.coCustomersContactInfo.middleName,
        lastName: ssmPostEnrollmentState.coCustomersContactInfo.lastName,
        ssn: idValue,
      };
      checkInfoHasBeenUpdated(customerInfo);
    }
  };

  const steps = [
    {
      path: routes.SSM_POST_ENROLLMENT_ADD_CO_CUSTOMER,
      title: t('SSM_POST_ADD_CO_CUSTOMER_STEP_1_TITLE'),
      name: WIZARD_STEPS.CONTACT_INFORMATION,
      wizardStep: (
        <WizardStep
          initial={ssmPostEnrollmentState.coCustomersContactInfo}
          onNext={handleContactInfo}
          nextRoute={routes.SSM_POST_ENROLLMENT_ADD_CO_CUSTOMER}
          backRoute={routes.SSM_POST_ENROLLMENT_ADD_CO_CUSTOMER}
          component={ContactInformation}
          componentProps={{
            active: activeStep === WIZARD_STEPS.CONTACT_INFORMATION,
          }}
          backText={t('CANCEL')}
          onBack={onClose}
          nextButton={activeStep === WIZARD_STEPS.CONTACT_INFORMATION}
          validate={createCoCustomerInfoValidateFunction()}
        ></WizardStep>
      ),
      progressStep: {
        label: t('SSM_POST_ADD_CO_CUSTOMER_STEP_1_TITLE'),
      },
    },
    {
      path: routes.SSM_POST_ENROLLMENT_ADD_CO_CUSTOMER,
      title: t('SSM_POST_ADD_CO_CUSTOMER_STEP_2_TITLE'),
      name: WIZARD_STEPS.VERIFY_INFORMATION,
      wizardStep: (
        <WizardStep
          initial={ssmPostEnrollmentState.coCustomersVerifyInformation}
          onNext={handleSubmitCoCustomer}
          onBack={() => {
            setActiveStep(WIZARD_STEPS.CONTACT_INFORMATION);
            return true;
          }}
          nextText={t('ADD')}
          nextRoute={routes.SSM_POST_ENROLLMENT_ADD_CO_CUSTOMER}
          backRoute={routes.SSM_POST_ENROLLMENT_ADD_CO_CUSTOMER}
          component={VerifyInformation}
          componentProps={{
            active: activeStep === WIZARD_STEPS.VERIFY_INFORMATION,
            primaryIdType: validatePersonData?.primaryIdType,
            idValueError,
            onSSNChange,
          }}
          nextButton={activeStep === WIZARD_STEPS.VERIFY_INFORMATION}
          validate={
            validatePersonData?.primaryIdType &&
            validatePersonData?.primaryIdType !== PersonPrimaryIdType.None
              ? createCoCustomerVerifyLastFourDigitsValidateFunction()
              : createCoCustomerVerifyValidateFunction()
          }
          proceedDisabled={isNextDisabled}
        ></WizardStep>
      ),
      progressStep: {
        label: t('SSM_POST_ADD_CO_CUSTOMER_STEP_2_TITLE'),
      },
    },
  ];

  if (inEligible) {
    return (
      <Ineligible
        onBack={() => {
          setInEligible(null);
        }}
        onDone={onClose}
        ineligibilityType={inEligible}
      />
    );
  }

  return (
    <>
      <Grid item container justify="center" spacing={4}>
        <Box mb={4}>
          <Typography variant={'h2'}>
            {t('SSM_POST_ADD_CO_CUSTOMER_CARD_TITLE')}
          </Typography>
        </Box>
      </Grid>

      {loading && <Backdrop forceOpen />}

      <Grid item container>
        <Typography
          variant={'body1'}
          component={'div'}
          className={classes.textCenter}
        >
          {t('SSM_POST_ADD_CO_CUSTOMER_DESCRIPTION')}
        </Typography>
      </Grid>

      <Grid container justify="center" spacing={0} item>
        <ProgressTracker
          steps={steps.map(({ progressStep, name }, index) => ({
            ...progressStep,
            isActive: name === activeStep,
            isComplete:
              steps.map(step => step.name).indexOf(activeStep || '') > index,
          }))}
        />
      </Grid>

      <Grid item>
        {steps.map(({ wizardStep, path, title }, index) => {
          return (
            <wizardStep.type
              {...{
                ...wizardStep.props,
                path: path,
                key: path + index,
                title: '',
              }}
            />
          );
        })}
      </Grid>
    </>
  );
};

export default AddCoCustomerForm;
