import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import React, { ReactNode } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import PaperBox from '../../utility/paper-box';
import useStyles from '../styles';
import { Hidden } from '@material-ui/core';
import { TimeOfDayInfo } from '../../../__generated__/pge-types';
import { toCurrencyDisplayFormat } from '../../../util/format';

interface Props {
  timeOfDayInfo?: TimeOfDayInfo | any;
  sideBySideHeader: String | ReactNode;
  footer: String;
}

export const TimeOfDaySideBySideComparision = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { timeOfDayInfo, sideBySideHeader, footer } = props;

  return (
    <Grid container>
      <PaperBox className={classes.sideBySideBox}>
        {sideBySideHeader && (
          <Grid item>
            <Typography className={classes.sideBySideHeader}>
              {sideBySideHeader}
            </Typography>
          </Grid>
        )}

        <Grid container xs={12} style={{ margin: '15px 0px' }}>
          <Grid item xs={12} md={6}>
            <Typography className={classes.todHeader} variant={'h4'}>
              {t('TIME_OF_DAY_AMOUNT')}
            </Typography>

            <Typography variant={'h4'} className={classes.sideBySideAmount}>
              ${timeOfDayInfo?.timeOfDaySummaryInfo?.todBillAmount}*
            </Typography>

            <Divider className={classes.sideBySideDivider} />

            <Typography className={classes.sideBySideSubHeader}>
              {t('PAY_LESS_WEEKENDS')}
            </Typography>

            <Typography className={classes.sideBySideContent}>
              {t('IF_YOU_CAN_SHIFT_USING_HOT_WATER')}
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Hidden xsDown>
              <Grid item className={classes.verticalDivider}>
                <Divider orientation="vertical" />
              </Grid>
            </Hidden>

            <Typography className={classes.todHeader} variant={'h4'}>
              {t('BASIC_SERVICE')}
            </Typography>

            <Typography variant={'h4'} className={classes.sideBySideAmount}>
              ${timeOfDayInfo?.timeOfDaySummaryInfo?.basicBillAmount}*
            </Typography>

            <Divider className={classes.sideBySideDivider} />

            <Typography className={classes.sideBySideSubHeader}>
              {t('SAME_PRICE_ALL_HOURS')}
            </Typography>

            <Typography className={classes.sideBySideContent}>
              {t('IF_YOU_USE_MORE_ELECTRICITY')}
            </Typography>
          </Grid>
        </Grid>
        {footer && <Typography align="center">* {footer}</Typography>}
      </PaperBox>
    </Grid>
  );
};

export default TimeOfDaySideBySideComparision;
