import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import {
  Grid,
  Typography,
  makeStyles,
  createStyles,
  Theme,
  Paper,
  Card,
  CardContent,
  Divider,
} from '@material-ui/core';
import { WizardFormProps } from '../utility/wizard/wizard.types';
import { ContactInfoModel } from '../../hooks/useElectionWindow';
import TextField from '../text-field';
import PhoneTextField from '../phone-text-field/PhoneTextField';
import EmailTextField from '../email-text-field/EmailTextfield';
import { convertValidationRules } from '../../hooks/useFormState';
import { BaseValidationContext } from '../../hooks/useFormState.types';
import { validateEmail, validateExistsAndAlphabetCharacters, validatePhoneNumber } from '../../util/form-validation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop0: {
      marginTop: theme.spacing(0),
    },
    errorMessage: {
      margin: '0 14px',
    },
    optionText: {
      paddingLeft: theme.spacing(4),
    },
    formField: {
      width: '100%',
      background: 'none',
    },
    margin0: {
      '& p': {
        margin: theme.spacing(0),
      }
    },
  }),
);

export const ElectionWindowContactInfo = ({
  formState,
  componentProps
}: WizardFormProps<ContactInfoModel>) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const { selectedGroup } = componentProps;

  return (
    <Grid item xs={12} style={{ marginTop: '1em' }}>
      <Paper>
        <Card>
          <CardContent style={{ padding: 16, marginBottom: 30 }}>
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Typography variant="h2">
                  {t('CONTACT_INFORMATION')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'body1'} className={classes.margin0}>
                  {richT('OPEN_ENROLLMENT_FORM_CONTACT_INFO_TEXT', {
                    ACCOUNT_GROUP_NAME: selectedGroup?.groupName
                  })}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name={'contactFullName'}
                  data-testid={'contactFullName'}
                  label={t('CONTACT_INFO_FULL_NAME')}
                  inputProps={{
                    minLength: 1,
                    maxLength: 80,
                    'data-testid': 'test-contactFullName',
                  }}
                  className={classes.formField}
                  {...formState.props('fullName')}
                />
              </Grid>
              <Grid item xs={12}>
                <EmailTextField
                  margin="none"
                  name={'contactEmail'}
                  label={t('CONTACT_INFO_EMAIL_ADDRESS')}
                  data-testid={'contactEmail'}
                  {...formState.props('email')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PhoneTextField
                  name={'contactPhoneNumber'}
                  label={t('CONTACT_INFO_PHONE_NUMBER')}
                  className={classes.formField}
                  style={{ margin: 0 }}
                  inputProps={{ minLength: 1, maxLength: 15 }}
                  data-testid={'contactPhoneNumber'}
                  {...formState.props('phoneNumber')}
                />
              </Grid>
            </Grid>
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            <Grid container direction="column" spacing={3}>
              <Grid item>
                <Typography variant="h2">
                  {t('AUTHORIZATION')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'body1'}>
                  {t('OPEN_ENROLLMENT_CONTACT_AUTH_FORM_TEXT')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name={'authFullName'}
                  data-testid={'authFullName'}
                  label={t('CONTACT_INFO_FULL_NAME')}
                  inputProps={{
                    minLength: 1,
                    maxLength: 80,
                    'data-testid': 'test-authFullName',
                  }}
                  className={classes.formField}
                  {...formState.props('authFullName')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name={'authTitle'}
                  data-testid={'authTitle'}
                  label={t('CONTACT_INFO_TITLE')}
                  inputProps={{
                    minLength: 1,
                    maxLength: 50,
                    'data-testid': 'test-authTitle',
                  }}
                  className={classes.formField}
                  {...formState.props('authTitle')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <PhoneTextField
                  name={'authPhoneNumber'}
                  label={t('CONTACT_INFO_PHONE_NUMBER')}
                  className={classes.formField}
                  style={{ margin: 0 }}
                  inputProps={{ minLength: 1, maxLength: 15 }}
                  data-testid={'authPhoneNumber'}
                  {...formState.props('authPhoneNumber')}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </Grid>
  );
};

export const createElectionWindowContactInfoFormAdapter = () => {
  return {
    toFormState: (
      initial: ContactInfoModel,
    ): ContactInfoModel => ({
      fullName: initial.fullName ?? '',
      email: initial.email ?? '',
      phoneNumber: initial.phoneNumber ?? '',
      authFullName: initial.authFullName ?? '',
      authTitle: initial.authTitle ?? '',
      authPhoneNumber: initial.authPhoneNumber ?? '',
    }),
    fromFormState: (
      state: ContactInfoModel,
    ): ContactInfoModel => {
      return {
        fullName: state.fullName,
        email: state.email,
        phoneNumber: state.phoneNumber,
        authFullName: state.authFullName,
        authTitle: state.authTitle,
        authPhoneNumber: state.authPhoneNumber
      };
    },
  };
};

export const createElectionWindowFormValidateFunc = () => {
  const { t } = useTranslation();
  return convertValidationRules<ContactInfoModel>(
    (context: BaseValidationContext<ContactInfoModel>) => {
      return {
        fullName: validateExistsAndAlphabetCharacters.bind(
          null,
          t('CONTACT_INFO_FULL_NAME'),
        ),
        email: validateEmail,
        phoneNumber: validatePhoneNumber,
        authFullName: validateExistsAndAlphabetCharacters.bind(
          null,
          t('CONTACT_INFO_FULL_NAME'),
        ),
        authTitle: validateExistsAndAlphabetCharacters.bind(
          null,
          t('CONTACT_INFO_TITLE'),
        ),
        authPhoneNumber: validatePhoneNumber
      };
    },
  );
};
