import { ProgressTrackerStep } from '../ProgressTracker';
import { useTranslation } from '../../hooks/useTranslation';
import { useLocation } from '@reach/router';
import ROUTES from '../../routes';
import { useState } from 'react';

const oneCheckRoutes = [
  ROUTES.MULTI_PAY,
  ROUTES.MULTI_PAY_PAYMENT_AMOUNTS,
  ROUTES.MULTI_PAY_PAYMENT_METHOD,
  ROUTES.MULTI_PAY_REVIEW,
  ROUTES.MULTI_PAY_COMPLETE,
];

const multiPayRoutes = [
  ROUTES.MULTI_PAY,
  ROUTES.MULTI_PAY_PAYMENT_AMOUNTS,
  ROUTES.MULTI_PAY_PAYMENT_METHOD,
  ROUTES.MULTI_PAY_PAYMENT_REVIEW,
  ROUTES.MULTI_PAY_PAYMENT_COMPLETE,
];

function getStepIndex(pathname: string, isOneCheck: boolean) {
  // Strip trailing slash from the pathname
  const index = getStepRoutes(isOneCheck).indexOf(pathname.replace(/\/$/, ''));
  if (index === -1 && pathname.startsWith(ROUTES.MULTI_PAY)) {
    throw new Error('INVALID MUTLI-PAY PATH NAME');
  }
  return index;
}

function getStepRoutes(isOneCheck: boolean) {
  return isOneCheck ? oneCheckRoutes : multiPayRoutes;
}

export default function useMultiPaySteps() {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const [isOneCheck, setIsOneCheck] = useState(false);
  const currentStep = getStepIndex(pathname, isOneCheck);
  const currentRoute = getStepRoutes(isOneCheck)[currentStep];

  const steps: Array<ProgressTrackerStep> = [
    t('MULTI_PAY_SELECT_ACCOUNTS_STEP'),
    t('MULTI_PAY_PAYMENT_AMOUNTS_STEP'),
    t(
      isOneCheck
        ? 'MULTI_PAY_PAYMENT_METHOD_STEP'
        : 'MULTI_PAY_PAYMENT_PROFILE_STEP',
    ),
    t(isOneCheck ? 'MULTI_PAY_REVIEW_STEP' : 'MULTI_PAY_PAYMENT_REVIEW_STEP'),
    t('MULTI_PAY_COMPLETE_STEP'),
  ].map((label, i) => ({
    label,
    isActive: i === currentStep,
    isComplete: i < currentStep,
  }));

  return {
    steps,
    stepIndex: currentStep,
    stepRoute: currentRoute,
    setIsOneCheck,
  };
}
