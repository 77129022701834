import gql from 'graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import { useEffect } from 'react';
import { sectionFragment } from '../../components/pge-plus-common/queries/fragments/section-ev';
import useUtil from './useUtil';

export const getApplicationSectionQuery = gql`
  query($input: GetApplicationSectionInput!) {
    getApplicationSection(input: $input) {
      ...sectionFragment
    }
  }
  ${sectionFragment}
`;
export const useGetApplicationSectionQuery = (
  applicationId: String,
  sectionId: String,
) => {
  const { setErrorNotification } = useUtil();

  const [
    getApplicationSection,
    { loading, error, data: getApplicationSectionData },
  ] = useLazyQuery(getApplicationSectionQuery, {
    context: {
      pgePlus: true,
    },
    fetchPolicy: 'no-cache',
    onError: () => setErrorNotification(true),
  });

  useEffect(() => {
    if (true) {
      getApplicationSection({
        variables: {
          input: {
            applicationId: applicationId,
            sectionId: sectionId,
          },
        },
      });
    }
  }, []);

  return {
    getApplicationSection,
    getApplicationSectionData,
    loading,
    error,
  };
};
