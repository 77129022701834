import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent, useEffect } from 'react';
import { parse } from 'date-fns';

import { useTranslation } from '../../../hooks/useTranslation';
import useStyles from '../payment-verification-form/PaymentVerificationForm.styles';
import { UsePaymentFormResponse } from '../PaymentForm.types';
import { OneTimePayConfigData } from '../oneTimePayment.types';
import { isWithInThreeDays } from '../oneTimePay.utils';
import TextLink from '../../text-link';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import {
  toCurrencyString,
  toDateStringFullMonthName,
} from '../../../util/format';
import Box from '@material-ui/core/Box';
import ProceedOrCancel from '../../proceed-or-cancel/ProceedOrCancel';
import PaymentProfileIcon from '../../paymentus/payment-profile-icon/PaymentProfileIcon';
import { getAccountDisplayString } from '../../paymentus/utils';
import {
  getPaymentCategoryLabel,
  getPaymentMethodLabel,
} from '../../../hooks/usePaymentus';
import { createStyles, makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';

interface PaymentVerificationFormProps extends UsePaymentFormResponse {
  path: string;
  isMobile: boolean;
  oneTimePayConfigData: OneTimePayConfigData;
  formattedPaymentAmount: string;
  onSubmit: () => void;
  paymentMethodCapLimit: number;
  isForTPA?: boolean;
  cancelHandler?: () => void;
  charityAmount?: string;
  cancelButtonText?: string;
  cancelButtonRoute?: string;
  backButtonText?: string;
  backButtonRoute?: string;
  nextButtonText?: string;
}

const getFormattedPaymentAmount = (paymentAmount: string) => {
  return paymentAmount
    ? `$${toCurrencyString(parseFloat(paymentAmount), false)}`
    : '';
};
const PaymentVerificationForm: FunctionComponent<PaymentVerificationFormProps> = props => {
  const { richT, t } = useTranslation();
  const classes = useStyles();

  const {
    cancelButtonText = t('CANCEL'),
    cancelButtonRoute = ROUTES.ACCOUNT,
    backButtonText = t('BACK'),
    backButtonRoute = ROUTES.PAYMENT,
    nextButtonText = t('PAY_BILL'),
  } = props;

  const paymentDateIsWithInThreeDays = isWithInThreeDays(
    props.oneTimePayConfigData?.paymentDate,
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const selectedProfileList =
    props.oneTimePayConfigData?.paymentSelector?.selectedPaymentProfileList;

  return (
    <Grid container spacing={3} className={classes.marginTop1}>
      <Grid item xs={12}>
        {!props.isMobile ? (
          <Grid item xs={12} className={classes.marginBotSpace}>
            <Typography
              className={classes.colorTextPrimary}
              component={'span'}
              variant={'h2'}
            >
              {t('VERIFY_PAYMENT')}
            </Typography>
          </Grid>
        ) : null}
        <Paper component={'main'} className={classes.main}>
          <Box className={classes.whiteBox}>
            {props.oneTimePayConfigData?.charitySelection &&
            props.oneTimePayConfigData?.charitySelection !== 'none' ? (
              <Grid item xs={12} className={classes.marginBotSpace}>
                <Typography
                  className={classes.colorTextPrimary}
                  component={'span'}
                  variant={'body1'}
                >
                  {richT('VERIFICATION_TOTAL_PAYMENT_MESSAGE_WITH_CHARITY', {
                    TOTAL_PAYMENT: getFormattedPaymentAmount(
                      (
                        Number(props.oneTimePayConfigData?.paymentAmount) +
                        Number(props.oneTimePayConfigData?.charityAmount)
                      ).toFixed(2),
                    ),
                    ACCOUNT_PAYMENT: getFormattedPaymentAmount(
                      props.oneTimePayConfigData?.paymentAmount,
                    ),
                    CHARITY_AMOUNT: getFormattedPaymentAmount(
                      props.oneTimePayConfigData?.charityAmount!,
                    ),
                    PAYMNET_DATE: props.oneTimePayConfigData?.paymentDate
                      ? toDateStringFullMonthName(
                          parse(
                            props.oneTimePayConfigData?.paymentDate,
                            'MM/dd/yyyy',
                            new Date(),
                          ),
                        )
                      : '',
                  })}
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} className={classes.marginBotSpace}>
                <Typography
                  className={classes.colorTextPrimary}
                  component={'span'}
                  variant={'body1'}
                >
                  {richT('VERIFICATION_TOTAL_PAYMENT_MESSAGE', {
                    ACCOUNT_PAYMENT: getFormattedPaymentAmount(
                      props.oneTimePayConfigData?.paymentAmount,
                    ),
                    PAYMENT_DATE: props.oneTimePayConfigData?.paymentDate
                      ? toDateStringFullMonthName(
                          parse(
                            props.oneTimePayConfigData?.paymentDate,
                            'MM/dd/yyyy',
                            new Date(),
                          ),
                        )
                      : '',
                  })}
                </Typography>
              </Grid>
            )}

            {selectedProfileList &&
              selectedProfileList.map(paymentProfile => {
                return (
                  <>
                    <Grid
                      item
                      key={paymentProfile.profile.token}
                      xs={12}
                      className={classes.paymentMethodContainer}
                    >
                      <Grid
                        item
                        xs={12}
                        style={
                          selectedProfileList.length ===
                          props.paymentMethodCapLimit
                            ? {}
                            : {
                                display: 'flex',
                                justifyContent: 'center',
                              }
                        }
                      >
                        <Typography
                          className={`${classes.colorTextPrimary} ${classes.noWrap}`}
                          css={{
                            '& p': {
                              margin: 0,
                            },
                          }}
                        >
                          {selectedProfileList.length ===
                          props.paymentMethodCapLimit
                            ? richT('VERIFY_PAYMENT_AMOUNT_MESSAGE', {
                                PAYMENT_AMOUNT: getFormattedPaymentAmount(
                                  Number(paymentProfile.value).toFixed(2),
                                ),
                                PAYMENT_METHOD: getPaymentMethodLabel(
                                  paymentProfile?.profile.type,
                                  true,
                                ),
                              })
                            : null}
                        </Typography>

                        <div>
                          {selectedProfileList.length !==
                          props.paymentMethodCapLimit ? (
                            <Typography
                              className={classes.marginLeftSpace}
                              component="span"
                            >
                              {t('ONETIMEPAY_USING_LABEL') +
                                ' ' +
                                getPaymentMethodLabel(
                                  paymentProfile?.profile.type,
                                  true,
                                )}
                            </Typography>
                          ) : null}
                          <Typography
                            className={classes.paymentMethodIcon}
                            component="span"
                            style={
                              selectedProfileList.length ===
                              props.paymentMethodCapLimit
                                ? {
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }
                                : { width: '100%', display: 'inline' }
                            }
                            variant={'body2'}
                          >
                            <PaymentProfileIcon
                              type={paymentProfile.profile?.type}
                            ></PaymentProfileIcon>
                            <Typography
                              component="span"
                              style={{
                                verticalAlign: 'middle',
                                marginLeft: '0.2em',
                                fontWeight: 'bold',
                              }}
                            >
                              {getAccountDisplayString(
                                paymentProfile?.profile,
                              ) || t('GUEST_PAY_AUTHORIZED')}
                            </Typography>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                );
              })}
            {props.isMobile &&
            selectedProfileList.length !== props.paymentMethodCapLimit ? (
              <div className={classes.paddingSpace} />
            ) : (
              <Divider />
            )}
            <Grid item xs={12} className={classes.marginTop1}>
              <Typography
                className={classes.colorTextPrimary}
                component={'span'}
                style={{ lineHeight: 2 }}
              >
                {richT(
                  props.isForTPA
                    ? 'I_AUTHORIZE_PGE_TO_CHARGE_TPA_PAYMENT'
                    : paymentDateIsWithInThreeDays
                    ? 'I_AUTHORIZE_PGE_TO_CHARGE_ONETIME_PAY'
                    : 'I_AUTHORIZE_TO_PGE_TEXT_ABOVE_THREE_DAYS',
                  {
                    amount:
                      props?.oneTimePayConfigData?.charitySelection &&
                      props?.oneTimePayConfigData?.charitySelection !== 'none'
                        ? getFormattedPaymentAmount(
                            (
                              Number(
                                props.oneTimePayConfigData?.paymentAmount,
                              ) +
                              Number(props.oneTimePayConfigData?.charityAmount)
                            ).toFixed(2),
                          )
                        : props.formattedPaymentAmount,
                    paymentType:
                      selectedProfileList.length === props.paymentMethodCapLimit
                        ? 'account(s)'
                        : getPaymentCategoryLabel(
                            selectedProfileList[0].profile?.type,
                          ),
                  },
                )}
              </Typography>
            </Grid>
          </Box>
        </Paper>
      </Grid>
      {props?.isForTPA ? (
        <Grid container item xs={12} justify={'flex-end'}>
          <Grid item xs={12} md="auto">
            <Box>
              <ProceedOrCancel
                proceedHandler={props.onSubmit}
                cancelRoute={ROUTES.TPA_MAKE_PAYMENT}
                cancelLabel={t('BACK')}
                proceedLabel={t('SUBMIT_PAYMENT')}
                cancelHandler={
                  props?.cancelHandler
                    ? props?.cancelHandler
                    : () => {
                        return false;
                      }
                }
                proceedStyle={colors.orange}
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          item
          xs={12}
          className={classes.buttonContainer}
          spacing={1}
        >
          <Grid item md="auto">
            <Box className={classes.cancelVerificationLink}>
              <TextLink to={cancelButtonRoute} className={classes.cancel}>
                {cancelButtonText}
              </TextLink>
            </Box>
          </Grid>
          <Grid item md="auto">
            <Box>
              <ProceedOrCancel
                proceedHandler={props.onSubmit}
                cancelRoute={backButtonRoute}
                cancelHandler={
                  props?.cancelHandler
                    ? props?.cancelHandler
                    : () => {
                        return false;
                      }
                }
                cancelLabel={backButtonText}
                cancelVariant="Button"
                proceedLabel={nextButtonText}
                proceedStyle={colors.orange}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default PaymentVerificationForm;
