import {
  validateAccountNumber,
  validateNicknameField,
} from '../../../util/form-validation';

export default () => ({
  // this must match the names of the form fields
  accountNumber: validateAccountNumber,
  nickname: validateNicknameField.bind(null, 'nickname'),
});
