/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Layout } from '../../components/pge-plus-common/Layout';
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from '@material-ui/core';
import { ProgressStepper } from '../../components/pge-plus-common/StepperForm';
import { Router, useLocation } from '@reach/router';
import { useTheme } from '@material-ui/core/styles';
import routes from '../../routes';
import { Landing } from '../../components/pge-plus-form/Landing';
import { ContactInfo } from '../../components/pge-plus-form/ContactInfo';
import { RebateEligibility } from '../../components/pge-plus-form/RebateEligibility';
import { SelectCharger } from '../../components/pge-plus-form/SelectCharger';
import { Installation } from '../../components/pge-plus-form/Installation';
import { UploadPhotos } from '../../components/pge-plus-form/UploadPhotos';
import { Summary } from '../../components/pge-plus-form/Summary';
import { CostEstimation } from '../../components/pge-plus-form/CostEstimation';
import { InstallationLocation } from '../../components/pge-plus-form/InstallationLocation';
import { InstallationPanelDetails } from '../../components/pge-plus-form/InstallationLocation/PanelDetails';
import { Success } from '../../components/pge-plus-form/Success';
import { ConfirmYourCharger } from '../../components/pge-plus-form/ConfirmYourCharger';
import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';
import { InEligible } from '../../components/pge-plus-form/Ineligible';
import { ChooseAccount } from '../../components/pge-plus-form/ChooseAccount';
import { WelcomeBack } from '../../components/pge-plus-form/WelcomeBack';
import { FinalQuote } from '../../components/pge-plus-form/FinalQuote';
import { DeclineQuote } from '../../components/pge-plus-form/FinalQuote/DeclineQuote';
import { FinalSuccess } from '../../components/pge-plus-form/FinalQuote/FinalSuccess';

import useContentMgmt from '../../hooks/useContentMgmt';
import microcopyGroupIds from '../../components/pge-plus-common/microcopyGroupIds';
import Backdrop from '../../components/backdrop/Backdrop';
import { UploadChargerReceipt } from '../../components/pge-plus-form/UploadPhotos/UploadChargerReceipt';
import { useEVChargerFormState } from '../../providers/EVChargerFormStateProvider';
import { navigate } from 'gatsby';

const PGEPlusFormPage: React.FC = () => {
  const theme = useTheme();
  const { isTPAEnabled, isPGEPlusEVEnabled, loading } = useContext(
    featureFlagsContext,
  );
  const {
    formState: { application, selectCharger },
  } = useEVChargerFormState();

  const { pathname: path } = useLocation();
  let pathname = path.replace(/\/$/, '');
  if (pathname.includes(routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY)) {
    pathname = routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY;
  }

  const { content: contentStepper } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_STEPPER,
  );

  const { content: contentPageTitle, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_PAGE_TITLES,
  );

  const [pageTitle, setPageTitle] = useState<string>('');

  useEffect(() => {
    //Redirect to choose account is appliction data not available
    if (
      !application &&
      pathname !== routes.PGE_PLUS_EV_CHARGERS_CHOOSE_ACCOUNT &&
      pathname !== routes.PGE_PLUS_EV_CHARGERS_WELCOME_BACK
    ) {
      void navigate(routes.PGE_PLUS_EV_CHARGERS_CHOOSE_ACCOUNT);
    }
  }, []);

  useEffect(() => {
    if (!loading && !isPGEPlusEVEnabled) {
      void navigate(routes.ACCOUNT);
    }
  }, [isPGEPlusEVEnabled, loading]);

  const pageTitles: Record<
    string,
    { displayOnMobile: boolean; content: React.ReactNode }
  > = {
    [routes.PGE_PLUS_EV_CHARGERS_CONFIRM_YOUR_CHARGER]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_SELECT_YOUR_CHARGER') || '',
    },
    [routes.PGE_PLUS_EV_CHARGERS_CONTACT_INFO]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_CUSTOMER_INFORMATION') || '',
    },
    [routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY]: {
      displayOnMobile: true,
      content: pageTitle,
    },
    [routes.PGE_PLUS_EV_CHARGERS_SELECT_CHARGER]: {
      displayOnMobile: false,
      content: contentPageTitle.get('PAGE_TITLE_SELECT_A_CHARGER') || '',
    },
    [routes.PGE_PLUS_EV_CHARGERS_INSTALLATION]: {
      displayOnMobile: false,
      content: contentPageTitle.get('PAGE_TITLE_INSTALLATION') || '',
    },
    [routes.PGE_PLUS_EV_CHARGERS_INSTALLATION_LOCATION]: {
      displayOnMobile: false,
      content: contentPageTitle.get('PAGE_TITLE_INSTALLATION') || '',
    },
    [routes.PGE_PLUS_EV_CHARGERS_INSTALLATION_PANEL_DETAILS]: {
      displayOnMobile: false,
      content: contentPageTitle.get('PAGE_TITLE_INSTALLATION') || '',
    },
    [routes.PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS]: {
      displayOnMobile: false,
      content: contentPageTitle.get('PAGE_TITLE_UPLOAD_PHOTOS') || '',
    },
    [routes.PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS_CHARGER_RECIEPT]: {
      displayOnMobile: false,
      content: contentPageTitle.get('PAGE_TITLE_UPLOAD_PHOTOS') || '',
    },
    [routes.PGE_PLUS_EV_CHARGERS_SUMMARY]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_SUMMARY') || '',
    },
    [routes.PGE_PLUS_EV_CHARGERS_COST_ESTIMATION]: {
      displayOnMobile: true,
      content: contentPageTitle.get('PAGE_TITLE_COST_ESTIMATION') || '',
    },
    [routes.PGE_PLUS_EV_CHARGERS_SUCCESS]: {
      displayOnMobile: true,
      content: richText(contentPageTitle.get('PAGE_TITLE_SUCCESS') || ''),
    },
  };

  const steps = useMemo(() => {
    const confirmYourChargerSection = {
      path: routes.PGE_PLUS_EV_CHARGERS_CONFIRM_YOUR_CHARGER,
      title: contentStepper.get('PROGRESS_STEPPER_SELECT_YOUR_CHARGER') || '',
      component: (
        <ConfirmYourCharger
          path={routes.PGE_PLUS_EV_CHARGERS_CONFIRM_YOUR_CHARGER}
        />
      ),
    };

    const selectChargerSection = {
      path: routes.PGE_PLUS_EV_CHARGERS_SELECT_CHARGER,
      title: contentStepper.get('PROGRESS_STEPPER_SELECT_A_CHARGER') || '',
      component: (
        <SelectCharger path={routes.PGE_PLUS_EV_CHARGERS_SELECT_CHARGER} />
      ),
    };

    const sections = [
      {
        path: routes.PGE_PLUS_EV_CHARGERS_CONTACT_INFO,
        title:
          contentStepper.get('PROGRESS_STEPPER_CUSTOMER_INFORMATION') || '',
        component: (
          <ContactInfo path={routes.PGE_PLUS_EV_CHARGERS_CONTACT_INFO} />
        ),
      },
      {
        path: routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY,
        title: contentStepper.get('PROGRESS_STEPPER_REBATE_ELIGIBILITY') || '',
        component: (
          <RebateEligibility
            path={`${routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY}/*`}
            setPageTitle={setPageTitle}
          />
        ),
      },
      {
        path: routes.PGE_PLUS_EV_CHARGERS_INSTALLATION,
        subPaths: [
          routes.PGE_PLUS_EV_CHARGERS_INSTALLATION_LOCATION,
          routes.PGE_PLUS_EV_CHARGERS_INSTALLATION_PANEL_DETAILS,
        ],
        title: contentStepper.get('PROGRESS_STEPPER_INSTALLATION') || '',
        component: (
          <Installation path={routes.PGE_PLUS_EV_CHARGERS_INSTALLATION} />
        ),
      },
      {
        path: routes.PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS,
        subPaths: [routes.PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS_CHARGER_RECIEPT],
        title: contentStepper.get('PROGRESS_STEPPER_UPLOAD_PHOTOS') || '',
        component: (
          <UploadPhotos path={routes.PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS} />
        ),
      },
      {
        path: routes.PGE_PLUS_EV_CHARGERS_SUMMARY,
        title: contentStepper.get('PROGRESS_STEPPER_SUMMARY') || '',
        component: <Summary path={routes.PGE_PLUS_EV_CHARGERS_SUMMARY} />,
      },
      {
        path: routes.PGE_PLUS_EV_CHARGERS_COST_ESTIMATION,
        title: contentStepper.get('PROGRESS_STEPPER_COST_ESTIMATION') || '',
        component: (
          <CostEstimation path={routes.PGE_PLUS_EV_CHARGERS_COST_ESTIMATION} />
        ),
      },
    ];

    //Push the select charger on the right place for Buy/BYOC flow
    sections.splice(
      selectCharger.suppliedByCustomer ? 0 : 2,
      0,
      //@ts-ignore
      selectCharger.suppliedByCustomer
        ? confirmYourChargerSection
        : selectChargerSection,
    );

    return sections;
  }, [selectCharger.suppliedByCustomer, contentStepper]);

  if (loading) {
    return <Backdrop forceOpen />;
  }

  if (!isTPAEnabled) {
    return <div>Sorry, something went wrong.</div>;
  } else {
    return (
      <Layout>
        <Grid
          container
          spacing={2}
          justify="center"
          alignItems="center"
          direction="column"
          css={{
            marginTop: 35,
            [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
              display: 'block',
            },
          }}
        >
          {pathname && pageTitles[pathname] && (
            <Box
              mt={2}
              mb={2}
              css={css`
                display: flex;
                align-items: center;
                flex-direction: column;
                @media (max-width: ${theme.breakpoints.values.sm}px) {
                  display: none;
                }
                @media (min-width: ${theme.breakpoints.values.md}px) {
                  display: block;
                }
              `}
            >
              <Typography
                variant={'h1'}
                css={{
                  fontSize: 36,
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  '& p': {
                    margin: 0,
                    textAlign: 'center',
                  },
                }}
              >
                {pageTitles[pathname].content}
              </Typography>
            </Box>
          )}

          {pathname &&
            ![
              routes.PGE_PLUS_EV_CHARGERS_HOME as string,
              routes.PGE_PLUS_EV_CHARGERS_CHOOSE_ACCOUNT as string,
              routes.PGE_PLUS_EV_CHARGERS_WELCOME_BACK as string,
              routes.PGE_PLUS_EV_CHARGERS_INELIGIBLE as string,
              routes.PGE_PLUS_EV_CHARGERS_FINAL_QUOTE as string,
              routes.PGE_PLUS_EV_CHARGERS_DECLINE as string,
              routes.PGE_PLUS_EV_CHARGERS_FINAL_SUCCESS as string,
            ].includes(pathname) && (
              <Grid item xs={12} md={9}>
                <ProgressStepper
                  content={contentStepper}
                  steps={steps.map(({ component, ...rest }) => ({ ...rest }))}
                  isComplete={[
                    routes.PGE_PLUS_EV_CHARGERS_SUCCESS as string,
                  ].includes(pathname)}
                />
              </Grid>
            )}

          {pathname &&
            pageTitles[pathname] &&
            pageTitles[pathname].displayOnMobile && (
              <Box
                mt={4}
                mx={1}
                mb={2}
                css={css`
                  @media (max-width: ${theme.breakpoints.values.sm}px) {
                    display: block;
                  }
                  @media (min-width: ${theme.breakpoints.values.md}px) {
                    display: none;
                  }
                `}
              >
                <Typography
                  variant={'h1'}
                  css={{
                    fontSize: 24,
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                    '& p': {
                      margin: 0,
                    },
                    [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                      textAlign: 'left',
                    },
                  }}
                >
                  {pageTitles[pathname].content}
                </Typography>
              </Box>
            )}
        </Grid>

        <Grid
          container
          spacing={2}
          justify="center"
          css={{
            marginBottom: 100,
            [theme.breakpoints.up('md')]: {
              '.MuiTypography-root': {
                lineHeight: '26px',
              },
            },
          }}
        >
          <Grid item xs={12} md={9} css={{ maxWidth: 790 }}>
            <Router basepath="/">
              <Landing path={routes.PGE_PLUS_EV_CHARGERS_HOME} />
              <ChooseAccount
                path={routes.PGE_PLUS_EV_CHARGERS_CHOOSE_ACCOUNT}
              />
              <Success path={routes.PGE_PLUS_EV_CHARGERS_SUCCESS} />
              <InstallationLocation
                path={routes.PGE_PLUS_EV_CHARGERS_INSTALLATION_LOCATION}
              />
              <InstallationPanelDetails
                path={routes.PGE_PLUS_EV_CHARGERS_INSTALLATION_PANEL_DETAILS}
              />
              <UploadChargerReceipt
                path={routes.PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS_CHARGER_RECIEPT}
              />
              <InEligible path={routes.PGE_PLUS_EV_CHARGERS_INELIGIBLE} />
              <WelcomeBack path={routes.PGE_PLUS_EV_CHARGERS_WELCOME_BACK} />
              <FinalQuote path={routes.PGE_PLUS_EV_CHARGERS_FINAL_QUOTE} />
              <DeclineQuote path={routes.PGE_PLUS_EV_CHARGERS_DECLINE} />
              <FinalSuccess path={routes.PGE_PLUS_EV_CHARGERS_FINAL_SUCCESS} />
            </Router>
            <Router basepath="/">
              {steps.map(({ component }, idx) => (
                <component.type {...component.props} key={idx} />
              ))}
            </Router>
          </Grid>
        </Grid>
      </Layout>
    );
  }
};

export default PGEPlusFormPage;
