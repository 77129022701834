import React from 'react';
import { Box, Button, Typography, withWidth } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';

import colors from '../../../themes/main-colors';

import { PageTextContentType } from '../../../hooks/useContentMgmt';

const useStyles = makeStyles(() => ({
  container: {
    boxShadow: '0px 1px 1px #00000066',
    backgroundColor: colors.white,
    marginBottom: '30px',
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    textAlign: 'center',
    fontSize: '32px',
    lineHeight: '40px',
    fontFamil: 'Untitled Sans',
    fontWeight: 'bold',
    color: colors.darkBlack,
  },
  messagesBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  message: {
    fontSize: '18px',
    lineHeight: '22px',
    color: colors.darkBlack,
  },
}));

interface FormUnenrolledProps {
  content: PageTextContentType;
  onClickDone: () => void;
}

const IQBDFormUnenrolled = ({ content, onClickDone }: FormUnenrolledProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box
        css={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: '30px',
          gap: '8px',
        }}
      >
        <CheckCircle
          css={{
            width: '32px',
            height: '32px',
          }}
          htmlColor={colors.shoreGreen}
        />
        <Typography className={classes.heading}>
          {content.get('UNENROLLMENT_SUCCESSFUL')}
        </Typography>
      </Box>
      <Box className={classes.messagesBox}>
        <Typography className={classes.message}>
          {content.get('UNENROLLMENT_MSG')}
        </Typography>
      </Box>
      <Button
        data-testid={'iqbd-form-unenrolled'}
        variant={'outlined'}
        color={'primary'}
        css={{
          backgroundColor: colors.buttonHoverPrimary,
          borderColor: colors.buttonHoverPrimary,
          color: colors.white,
          borderRadius: '30px',
          fontSize: '18px',
          minWidth: '130px',
          paddingLeft: '64px',
          paddingRight: '64px',
          margin: '30px auto',
          alignSelf: 'center',
          '&:hover': {
            backgroundColor: colors.buttonHoverPrimary,
            borderColor: colors.buttonHoverPrimary,
            color: colors.white,
            boxShadow: 'none',
          },
        }}
        onClick={onClickDone}
      >
        {content.get('DONE')}
      </Button>
    </Box>
  );
};

export default withWidth()(IQBDFormUnenrolled);
