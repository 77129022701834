import { useState, useContext, useReducer, useEffect } from 'react';

import { useApolloClient } from '@apollo/react-hooks';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import { useTranslation } from '../../../hooks/useTranslation';
import { addRemoveAccountsToGroupMutation } from '../../../queries/manageAccountGroups.query';
import { getAccountsList } from '../../account-summary/multi-accounts/queries';
import useAccountDetailList from '../../../hooks/useAccountDetailList';
import AccountListReducer from '../../account-summary/multi-accounts/accountListReducer';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import useAccountMulticheckState from '../../../hooks/useAccountMulticheckState';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import useSelectedGroupId from '../../../hooks/useSelectedGroupId';
import { AccountDetail } from '../../../__generated__/pge-types';
import useAccountListHeader from '../../account-summary/multi-accounts/useAccountListHeader';

export const useAddAccounts = () => {
  const { selectedGroupId: editingGroupId } = useSelectedGroupId();

  const { groups } = useAccountCustomer();
  const notificationContext = useContext(NotificationsContext);
  const [selectedGroupId, setSelectedGroupId] = useState(editingGroupId);
  const apolloClient = useApolloClient();
  const { wrapWithLoader } = useWrapWithLoader();

  useEffect(() => {
    setSelectedGroupId(editingGroupId);
  }, [editingGroupId]);

  const {
    accountListParams,
    onResetParams,
    onAccountSearch,
    onAccountSort,
    onAccountPageChange,
  } = useAccountListHeader();

  const onGroupChange = (groupId: string) => {
    onResetParams();
    setSelectedGroupId(groupId);
  };

  const { loading, accounts, totalCount = 0 } = useAccountDetailList(
    getAccountsList,
    selectedGroupId,
    {
      accountListParams,
    },
  );

  const multicheckState = useAccountMulticheckState({
    query: accountListParams.query,
    groupId: selectedGroupId,
    allowMultiGroup: true,
    allowInactive: true,
  });
  const { t } = useTranslation();

  const isAddButtonDisabled = multicheckState.getSelected().length === 0;

  const handleAdd = wrapWithLoader(async () => {
    const payloadAccounts = multicheckState
      .getSelectedAccounts()
      .map((account: AccountDetail) => account.accountNumber);

    const result = await apolloClient.mutate({
      mutation: addRemoveAccountsToGroupMutation,
      variables: {
        payload: {
          groupId: editingGroupId,
          accounts: payloadAccounts,
          action: 'ADD',
          groupName: editingGroup?.groupName,
        },
      },
    });

    const success = result?.data?.addRemoveAccountsToGroup?.success || false;

    if (success) {
      await apolloClient.resetStore();
      const message = (): string => {
        if (payloadAccounts.length > 1) {
          return `${payloadAccounts.length} accounts added`;
        }
        return t('ACCOUNT_ADDED_MESSAGE_SUCCESS');
      };

      onAddSuccess(message());

      // scroll to top of the page so you can see the notifications feedback
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    } else {
      handleErrors('ADD_GROUP_ACCOUNT_ERROR');
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    }
  });

  const onAddSuccess = (message: string): void => {
    notificationContext.setState({
      isOpen: true,
      severity: 'success',
      variant: 'filled',
      message: message,
    });
  };

  const handleErrors = (message: string) => {
    notificationContext.setState({
      severity: 'error',
      isOpen: true,
      variant: 'filled',
      title: t(message),
    });
  };

  const editingGroup = groups?.find(grp => grp.groupId === editingGroupId);

  return {
    loading: loading || !selectedGroupId,
    editingGroup,
    groups,
    accounts,
    sortFilterProps: {
      totalCount,
      accountListParams,
      AccountListReducer,
    },
    onAccountSearch,
    onAccountSort,
    onAccountPageChange,
    multicheckState,
    isAddButtonDisabled,
    handleAdd,
    onGroupChange,
    selectedGroupId,
  };
};
