import React, { FunctionComponent } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useTranslation } from '../../../hooks/useTranslation';
import EmailTextField from '../../email-text-field/EmailTextfield';
import PhoneTextField from '../../phone-text-field/PhoneTextField';
import { FormState } from '../../../hooks/useFormState.types';
import { Model } from '../rules';
import useStyles from '../styles';

interface Props {
  path?: string;
  form: FormState<Model>;
  showNotificationPreferenceRequiredError: boolean;
}

const PeakTimeRebatesNotificationPreferences: FunctionComponent<Props> = props => {
  const { t, richT } = useTranslation();
  const classes = useStyles();

  const { form, showNotificationPreferenceRequiredError } = props;

  return (
    <Grid
      className={classes.root}
      container
      spacing={3}
      style={{ marginTop: '1em' }}
    >
      <Grid item>
        <Grid item xs={12} style={{ marginBottom: '1em' }}>
          <Typography className={classes.colorTextPrimary} variant={'h2'}>
            {t('NOTIFICATION_PREFERENCES')}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '1em' }}>
          <Typography className={classes.colorTextPrimary} variant={'body2'}>
            {t('TELL_US_NOTIFICATION_PREFERENCE_PTR')}
          </Typography>
        </Grid>

        {showNotificationPreferenceRequiredError && (
          <Grid item xs={12}>
            <Typography
              className={classes.colorTextError}
              variant={'subtitle1'}
            >
              {t('ENTER_AN_EMAIL_OR_MOBILE_PHONE')}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} style={{ marginBottom: '1em' }}>
          <EmailTextField
            className={classes.inputField}
            name="notificationEmail"
            {...form.props('notificationEmail')}
          />
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '1em' }}>
          <PhoneTextField
            className={classes.inputField}
            name="notificationTextNumber"
            {...form.props('notificationTextNumber')}
          />
        </Grid>

        <Grid item xs={12} style={{ marginBottom: '1em' }}>
          <Typography className={classes.colorTextPrimary} variant={'body2'}>
            {t('IMPORTANT_TO_NOTE_PTR')}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: '1em' }}>
          <Typography className={classes.colorTextPrimary} variant={'body2'}>
            {richT('PHONE_NOTIFICATION_CONSENT')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PeakTimeRebatesNotificationPreferences;
