import React, { Fragment, FunctionComponent, useContext } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import ProgramOptionsList, {
  Option,
} from '../program-options-list/ProgramOptionsList';
import ColorBlendSpinner from '../../spinner/ColorBlendSpinner';
import Drawer from '../../expansion-panel';
export interface BillingOptionsProps {
  isMobile: boolean;
  optionList: Option[];
  defaultExpand?: boolean;
}

const BillingOptions: FunctionComponent<BillingOptionsProps> = ({
  isMobile,
  optionList,
  defaultExpand,
}) => {
  const { richT } = useTranslation();

  const truncateLength = 30;
  const activeOptionsList = optionList
    .filter(option => option.active)
    .map(opt => opt.name)
    .join(', ')
    .replace(/:/g, '');

  const activeOptionsDisplay =
    activeOptionsList && activeOptionsList.length > truncateLength
      ? activeOptionsList
          .substring(0, truncateLength)
          .replace(/,$|\s$/, '')
          .concat('...')
      : activeOptionsList;

  const contents = (
    <Fragment>
      <Drawer
        title={
          optionList?.some(item => item.active && !item.disabled)
            ? richT('SELECTED_OPTION_LIST', {
                OPTION_LIST: activeOptionsDisplay,
              })
            : richT('SELECT_YOUR_BILLING_OPTIONS')
        }
        isAlternatePanelStyle={true}
        style={{ margin: '0px 16px 16px' }}
        defaultExpand={defaultExpand}
      >
        {!optionList ? (
          <ColorBlendSpinner />
        ) : (
          <ProgramOptionsList
            options={optionList}
            renderInCard={false}
            isMobile={isMobile}
          />
        )}
      </Drawer>
    </Fragment>
  );

  return contents;
};
export default BillingOptions;
