import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from '@material-ui/core';
import colors from '../../../themes/main-colors';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import PaperBox from '../../utility/paper-box';
import useStyles from '../styles';

import { FormState } from '../../../hooks/useFormState.types';
import { GiveBackInfo } from '../giveBack.types';
import OrganizationDetails from './OrganizationDetails';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { navigate } from 'gatsby';
import { NextBackOrCancel } from '../../next-back-or-cancel';

interface Props {
  path: string;
  form: FormState<GiveBackInfo>;
  handleOnFormSubmit: () => Promise<boolean | undefined>;
}

export const PeakTimeRebatesGiveBackEnrollment: FC<Props> = props => {
  const { t, richT } = useTranslation();
  const classes = useStyles();

  const { handleOnFormSubmit, form } = props;

  const { wrapWithLoader } = useWrapWithLoader();

  const onSubmit = wrapWithLoader(
    form.submit(async () => {
      await handleOnFormSubmit();

      const routeToNavigate =
        form.values.enrollment === 'NotToDonate'
          ? ROUTES.PEAKTIME_REBATES_GIVING_BACK_NOT_TO_DONATE_CONFIRM
          : ROUTES.PEAKTIME_REBATES_GIVING_BACK_CONFIRMATION;
      return navigate(routeToNavigate);
    }),
  );

  return (
    <Grid container direction={'column'} spacing={2}>
      <>
        <PaperBox>
          <Grid item>
            <Typography variant={'h2'}>
              {t('PTR_GIVE_BACK_SUBTITLE_SMARTGRID')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              className={classes.colorTextPrimary}
              variant={'body2'}
              component="span"
            >
              {t('PTR_GIVE_BACK_CHOOSE_DONATE_DESCRIPTION')}
            </Typography>
          </Grid>
          <Divider className={classes.gridDivider} />
          <Grid item xs={12}>
            <Typography className={classes.colorTextPrimary} variant={'h4'}>
              {t('PTR_GIVE_BACK_CHOOSE_YOUR_NON_PROFIT')}
            </Typography>
          </Grid>
          <Grid item>
            {form.errors.organization &&
              form.values.enrollment !== 'NotToDonate' && (
                <Typography variant={'body1'} style={{ color: colors.roseRed }}>
                  {form.errors.organization}
                </Typography>
              )}
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="organization"
                name="organization"
                value={form.values.organization}
                onChange={form.onChange}
              >
                <FormControlLabel
                  value={'CentralCityConcern'}
                  control={<Radio color={'primary'} />}
                  label={t('PTR_GIVE_BACK_ORG_CENTRAL_CITY_CONCERN')}
                />
                <FormControlLabel
                  value={'CommunityEnergyProject'}
                  control={<Radio color={'primary'} />}
                  label={t('PTR_GIVE_BACK_ORG_COMMUNITY_ENERGY_PROJECT')}
                />
                <FormControlLabel
                  value={'WorkingTheoryFarm'}
                  control={<Radio color={'primary'} />}
                  label={t('PTR_GIVE_BACK_ORG_WORKING_THEORY_FARM')}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Divider className={classes.gridDivider} />
          <Grid item xs={12}>
            <Typography className={classes.colorTextPrimary} variant={'h4'}>
              {t('PTR_GIVE_BACK_SELECT_EVENTS')}
            </Typography>
          </Grid>
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="enrollment"
                name="enrollment"
                value={form.values.enrollment}
                onChange={async (e: any) => {
                  e.persist();
                  await form.onChange(e);
                  if (e.target.value === 'NotToDonate') {
                    await form.setValue('organization', 'None');
                  }
                }}
              >
                <FormControlLabel
                  value={'Winter'}
                  control={<Radio color={'primary'} />}
                  label={t('PTR_GIVE_BACK_DONATE_WINTER_SEASONS_LABEL')}
                />
                <FormControlLabel
                  value={'NextPeakEvent'}
                  control={<Radio color={'primary'} />}
                  label={t('PTR_GIVE_BACK_DONATE_NEXT_PEAK_TIME_EVENT_LABEL')}
                />
                <FormControlLabel
                  value={'NotToDonate'}
                  control={<Radio color={'primary'} />}
                  label={t('PTR_GIVE_BACK_NOT_TO_DONATE')}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Divider className={classes.gridDivider} />
          <OrganizationDetails />
          <Divider className={classes.gridDivider} />
          <Grid item>
            <Typography
              className={classes.gridDivider}
              variant={'body2'}
              component="span"
            >
              {richT('PTR_GIVE_BACK_LEGAL_DESCRIPTION')}
            </Typography>
          </Grid>
        </PaperBox>
        <Grid item xs={12}>
          <NextBackOrCancel
            isFinalStep
            backHandler={() => navigate(ROUTES.PEAKTIME_REBATES)}
            cancelRoute={ROUTES.ACCOUNT}
            nextHandler={onSubmit}
            nextLabel={t('SUBMIT')}
          />
        </Grid>
      </>
    </Grid>
  );
};

export default PeakTimeRebatesGiveBackEnrollment;
