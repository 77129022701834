import React from 'react';
import ManageAlertsConfirmPhone from './ManageAlertsConfirmPhone';
import { Dialog } from '@material-ui/core';

type Props = {
  onClose: () => void;
  onSubmit: (code: string, onInvalid: () => void) => void;
  phoneNumber: string;
  hasActivationCode: boolean;
  onTooManyFailures: () => void;
};
export default function ManageAlertsConfirmPhoneDialog({
  onClose,
  phoneNumber,
  onTooManyFailures,
  hasActivationCode,
  onSubmit,
}: Props) {
  return (
    <Dialog open={true} onClose={() => onClose()}>
      <ManageAlertsConfirmPhone
        onSubmit={onSubmit}
        phoneNumber={phoneNumber}
        hasActivationCode={hasActivationCode}
        onTooManyFailures={onTooManyFailures}
        onPrevious={() => onClose()}
      />
    </Dialog>
  );
}
