import mapValues from 'lodash/mapValues';
import { FieldErrors } from '../../hooks/useFormState.types';
import { validateAmount } from '../../util/form-validation';

// account number to amount
export type Model = Record<string, string>;

type AdditionalContext = {
  t: (translation: string) => string;
  isChecked: (acctNumber: string) => boolean;
};

export default function validate(
  values: Model,
  context: AdditionalContext,
): FieldErrors<Model> {
  return mapValues(values, (amt, key) => {
    if (!context.isChecked(key)) {
      return null;
    }
    return validateAmount(amt);
  });
}
