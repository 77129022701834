/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { GridContainer } from '../../../../pge-plus-common/Grid';
import VerifyChargerRebate from './VerifyChargerRebate';
import routes from '../../../../../routes';
import UploadModelNumber from './UploadModelNumber';
import UploadJuiceboxIdNumber from './UploadJuiceboxIdNumber';
import UploadSerialNumber from './UploadSerialNumber';
import SmartChargingEnrollForTesla from './SmartChargingEnrollForTesla';
import VerifyEvPulseRebate from './VerifyEvPulseRebate';

const ConfirmYourCharger: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  return (
    <GridContainer>
      <Router
        basepath="/"
        css={{
          width: '100%',
        }}
      >
        <VerifyChargerRebate
          path={routes.PGE_PLUS_REBATE_ONLY_VERIFY_CHARGER_REBATE}
        />
        <VerifyEvPulseRebate
          path={routes.PGE_PLUS_REBATE_ONLY_VERIFY_EVPULSE_REBATE}
        />
        <UploadModelNumber
          path={routes.PGE_PLUS_REBATE_ONLY_UPLOAD_MODEL_NUMBER}
        />
        <UploadJuiceboxIdNumber
          path={routes.PGE_PLUS_REBATE_ONLY_UPLOAD_JUICEBOXID_NUMBER}
        />
        <UploadSerialNumber
          path={routes.PGE_PLUS_REBATE_ONLY_UPLOAD_SERIAL_NUMBER}
        />
        <SmartChargingEnrollForTesla
          path={routes.PGE_PLUS_REBATE_ONLY_EVPULSE_SMART_CHARGING_ENROLLMENT}
        />
      </Router>
    </GridContainer>
  );
};

export default ConfirmYourCharger;
