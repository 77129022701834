import React, { useState } from 'react';

import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Close from '@material-ui/icons/Close';
import { useTranslation } from '../../hooks/useTranslation';
import PGEButton from '../buttons/PGE-Button';
import { ClientProgramPromoModalProps } from './ClientProgramPromo.types';
import useClientProgramPromo from '../../hooks/useClientProgramPromo';
import { LeadDiscardReason } from '../../__generated__/pge-types';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contactDataModalWrapper: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '600px',
        width: '80%',
      },
    },
    contactDataModal: {
      padding: theme.spacing(3),
      borderRadius: '5px',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(4, 3, 3, 3),
      },
    },
    closeBtn: {
      position: 'absolute',
      top: 24,
      right: 24,
      padding: 0,
    },
    modalBody: {
      marginTop: theme.spacing(1),
      '& > *': {
        marginTop: theme.spacing(1),
      },
    },
    contactData: {
      fontWeight: 'bold',
      wordBreak: 'break-word',
    },
    contactEmail: {
      fontWeight: 'bold',
      wordBreak: 'break-all',
    },
    updateBtn: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: 'flex-end',
      },
    },
    okBtn: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
  }),
);

export default function ClientProgramPromoModal({
  open,
  onClose,
}: ClientProgramPromoModalProps) {
  const { t, inlineRichT } = useTranslation();
  const classes = useStyles();
  const {
    leadData,
    handleReject,
    handleApprove,
    setNotificationMessage,
    acctId,
  } = useClientProgramPromo(true);
  const onRejectClick = () => {
    void handleReject(LeadDiscardReason.LeadDiscardNo, 'Web Channel');
    onClose();
  };

  const onApproveClick = () => {
    void handleApprove('Web Channel');
    onClose();
  };
  if (!leadData) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={'body'}
      aria-labelledby="client-program-promo-modal"
      aria-describedby="client-program-promo-modal"
      className={classes.contactDataModalWrapper}
      disableScrollLock
    >
      <Paper className={classes.contactDataModal}>
        <Box>
          <Grid container justify="space-between">
            <Typography
              variant={'body1'}
              gutterBottom
              style={{ paddingRight: '24px' }}
            >
              {''}
            </Typography>

            <IconButton
              aria-label="close"
              color="inherit"
              onClick={onClose}
              className={classes.closeBtn}
            >
              <Close />
            </IconButton>
          </Grid>

          <Box className={classes.modalBody}>
            <Typography variant={'h3'}>
              {inlineRichT(`${leadData?.campaign}`, { ACCOUNT_ID: acctId })}
            </Typography>

            <Grid style={{ marginTop: 20 }} container spacing={2}>
              <Grid className={classes.updateBtn} item xs={12} md={6}>
                <PGEButton
                  size="large"
                  color="secondary"
                  variant="outlined"
                  onClick={onRejectClick}
                >
                  {t('REJECT')}
                </PGEButton>
              </Grid>
              <Grid item xs={12} md={6} className={classes.okBtn}>
                <PGEButton size="large" onClick={onApproveClick}>
                  {t('APPROVE')}
                </PGEButton>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Dialog>
  );
}
