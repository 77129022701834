/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { Fragment, useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  Button,
  createStyles,
  Divider,
  FormHelperText,
  Theme,
  useTheme,
} from '@material-ui/core';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import colors from '../../../../../themes/main-colors';
import Modal from '../../../../pge-plus-common/Modal';
import PopoverToolTip from '../../../../pge-plus-common/PopoverToolTip';
import { ProceedOrCancelButton } from '../../../../pge-plus-common/StepperForm';
import routes from '../../../../../routes';
import { SaveAndComeBackLink } from '../../../../pge-plus-common/SaveAndComeBackLink';
import useWrapWithLoader from '../../../../../hooks/useWrapWithLoading';
import useFormState from '../../../../../hooks/useFormState';
import FileUploaderWithOCR from '../../../../pge-plus-common/FileUploaderWithOCR';
import TextField from '../../../../text-field';
import { useRebateOnlyFormState } from '../../../../../providers/RebateOnlyFormStateProvider';
import { uploadSerialChargerValidateFunction } from '../rebateOnlyForm.rules';
import {
  QualifiedProductInfo,
  UpdateSectionErrorCode,
} from '../rebateOnlyForm.types';
import {
  ApplicationErSectionType,
  ApplicationSectionStatus,
  OcrFieldNames,
  SectionErRebateInfo,
} from '../../../../../__generated__/pgeplus-types';
import { rebateInfoERFormStateAdapter } from '../rebateOnlyFormState.adapter';
import useUpdateERSections from '../../../../../hooks/pge-plus/useUpdateERSections';
import useUtil from '../../../../need-more-time-to-pay/useUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageTitle: {
      fontSize: '16px !important',
      padding: '0px !important',
      color: `${colors.sparkBlue} !important`,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: 'unset !important',
      },
    },
    errorMessage: {
      fontFamily: 'Untitled-Sans',
      fontSize: '1rem !important',
      letterSpacing: '1px',
    },
  }),
);

const UploadSerialNumber: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const theme = useTheme();
  const classes = useStyles(props);
  const { content, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_CONFIRM_YOUR_CHARGER,
  );
  const { content: fileUploaderText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_UPLOAD_PHOTOS,
  );
  const {
    formState: { rebateInfo, application },
    getSectionData,
    updateFormState,
  } = useRebateOnlyFormState();
  const { wrapWithLoader } = useWrapWithLoader();
  const form = useFormState(rebateInfo.qualifiedProductInfo!, {
    validate: uploadSerialChargerValidateFunction(),
    validationContext: {
      content,
    },
  });

  const [openExamplePhotoModal, setOpenExamplePhotoModal] = useState<boolean>(
    false,
  );
  const [ocrExtractionStatus, setOcrExtractionStatus] = useState<
    string | undefined
  >();

  const subText1 = {
    fontSize: 20,
    marginBottom: theme.spacing(2),
  };

  const sectionDataRebateInfo: SectionErRebateInfo = getSectionData(
    ApplicationErSectionType.ErRebateInfo,
  );

  const { updateSectionRebateInfo } = useUpdateERSections();

  const { setErrorNotification } = useUtil();
  const onSubmit = wrapWithLoader(async (data: QualifiedProductInfo) => {
    try {
      const response = await updateSectionRebateInfo(
        rebateInfoERFormStateAdapter().fromFormState(
          {
            ...rebateInfo,
            qualifiedProductInfo: data,
            evPulse: undefined,
          },
          application?.id!,
          sectionDataRebateInfo?.id,
          ApplicationSectionStatus.Completed,
        ),
      );
      // Save the response in a local var
      const result = response?.data?.updateSectionERRebateInfo;

      // Check for errors to show the red banner and block navigation
      if (response?.errors || result?.validation?.errors) {
        throw new Error();
      }

      //Update the app state with the user selected values
      if (result?.section.data) {
        const updatedValues = rebateInfoERFormStateAdapter().toFormState(
          result?.section.data,
        );
        updateFormState('rebateInfo', {
          ...updatedValues,
        });
      }

      if (result?.section?.errors.length === 0) {
        void navigate(routes.PGE_PLUS_REBATE_ONLY_CUSTOMER_INFORMATION);
      } else {
        const serialNoAlreadyEnrolled = result?.section.errors.filter(
          reason =>
            reason.code === UpdateSectionErrorCode.SerialNoAlreadyEnrolled,
        );
        if (serialNoAlreadyEnrolled) {
          await navigate(routes.PGE_PLUS_REBATE_ONLY_INELIGIBLE, {
            state: {
              message: 'REBATE_ONLY_SMART_CHARGING_INELIGIBLE',
            },
          });
        } else {
          throw new Error();
        }
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const boldText = {
    fontWeight: 700,
  };

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      const response = await updateSectionRebateInfo({
        ...rebateInfoERFormStateAdapter().fromFormState(
          {
            ...rebateInfo,
            qualifiedProductInfo: form.values,
            evPulse: undefined,
          },
          application?.id!,
          sectionDataRebateInfo?.id,
        ),
        saveAndComeBack: true,
      });
      if (
        response?.errors ||
        response.data?.updateSectionERRebateInfo?.validation?.errors ||
        response.data?.updateSectionERRebateInfo?.section?.errors.length !== 0
      ) {
        throw new Error();
      } else {
        void navigate(routes.ACCOUNT);
      }
    } catch {
      setErrorNotification(true);
    }
  });

  return (
    <form
      onSubmit={form.submit(values => onSubmit(values), console.log)}
      noValidate
    >
      <Box>
        <Box>
          <Typography css={{ ...subText1, ...boldText }}>
            {content.get('UPLOAD_PHOTO_CHARGER_SERIAL_NUMBER')}
          </Typography>
        </Box>
        <Box>
          <PopoverToolTip
            baseContent={content.get('HOW_FIND_SERIAL_NUMBER')}
            popoverContent={richText(
              content.get('HOW_FIND_SERIAL_NUMBER_TOOLTIP'),
            )}
          />
        </Box>
        <Box>
          <Fragment>
            {!form.values?.serialNumberImage && (
              <Box mt={3}>
                <Button
                  className={classes.imageTitle}
                  onClick={() => setOpenExamplePhotoModal(true)}
                >
                  {content.get('REUSABLE_VIEW_EXAMPLE_PHOTO')}
                </Button>
                <Modal
                  open={openExamplePhotoModal}
                  onClose={() => setOpenExamplePhotoModal(false)}
                  title={content.get('MODEL_SAMPLE_PHOTO_TITLE')}
                  showCloseIcon={true}
                  modalStyles={{ width: 600 }}
                >
                  {' '}
                  <Box mt={3}>
                    <img
                      css={{
                        width: '100%',
                        maxHeight: '350px',
                        objectFit: 'cover',
                      }}
                      src={content.get('MODEL_SAMPLE_PHOTO_URL')}
                    />
                  </Box>
                </Modal>
              </Box>
            )}
            <FileUploaderWithOCR
              placeholder={fileUploaderText.get('UPLOAD_A_PHOTO')}
              reuploadLabel={fileUploaderText.get('REUPLOAD_PHOTO')!}
              ocrType={OcrFieldNames.SerialNumber}
              onComplete={(key, extractedText, message, status) => {
                void form.setValue('serialNumberImage', { id: key });
                void form.setValue('serialNumber', extractedText);
                setOcrExtractionStatus(status);
              }}
              image={form.values?.serialNumberImage}
            />
            {form.errors?.serialNumberImage && (
              <FormHelperText className={classes.errorMessage} error>
                {form.errors.serialNumberImage}
              </FormHelperText>
            )}

            {form.values?.serialNumberImage && (
              <Box>
                {ocrExtractionStatus && ocrExtractionStatus === 'fail' && (
                  <FormHelperText
                    className={classes.errorMessage}
                    error
                    component="div"
                  >
                    {fileUploaderText.get('OCR_FAIL_MESSAGE')}
                  </FormHelperText>
                )}
                <Typography
                  css={{
                    ...subText1,
                    ...boldText,
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(0),
                  }}
                >
                  {ocrExtractionStatus && ocrExtractionStatus === 'success'
                    ? content.get('SERIAL_NUMBER_VERIFICATION')
                    : content.get('SERIAL_NUMBER')}
                </Typography>
                <TextField
                  css={{
                    background: 'transparent',
                    '.MuiInputBase-formControl': {
                      background: colors.white,
                    },
                    width: '100%',
                    margin: '8px 0 8px 0',
                  }}
                  {...form.props('serialNumber')}
                />
                {ocrExtractionStatus && ocrExtractionStatus === 'success' && (
                  <Typography css={{ marginTop: 8 }}>
                    {content.get('SERIAL_OCR_SUCCESS_MESSAGE')}
                  </Typography>
                )}
              </Box>
            )}
            <Divider css={{ color: colors.gray93, margin: '20px 0 0px 0' }} />
          </Fragment>
        </Box>
        <ProceedOrCancelButton
          back={routes.PGE_PLUS_REBATE_ONLY_UPLOAD_MODEL_NUMBER}
          backLabel={content.get('REUSABLE_BACK')}
          disable={
            !form.values?.serialNumber || !form.values?.serialNumberImage
          }
        />
        <Box mt={3}>
          <SaveAndComeBackLink
            content={content}
            onSaveAndExit={handleSaveAndExit}
          />
        </Box>
      </Box>
    </form>
  );
};

export default UploadSerialNumber;
