import { Box, Grid, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { navigate } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import { useIsMobile } from '../../../util/style-utils';
import PGEButton from '../../buttons';
import PaperBox from '../../utility/paper-box';
import useStyles from '../styles';

interface Props {
  path?: string;
}

const TimeOfDayMeterException: FC<Props> = (props: Props) => {
  const isMobile = useIsMobile();
  const { t, richT } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <PaperBox className={classes.successBorder}>
        <Grid container spacing={4}>
          <Grid
            item
            container
            xs={12}
            spacing={2}
            direction={'row'}
            alignItems={'center'}
          >
            <Grid item>
              <Typography
                variant={'h2'}
                className={classes.successColor}
                style={{ paddingTop: '0.3em' }}
              >
                <CheckCircleIcon fontSize="inherit" />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'h2'}>
                {t('METER_EXCEPTION_TOD_TITLE')}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant={'body2'} component={'div'}>
              {richT('METER_EXCEPTION_TOD_BODY')}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify={isMobile ? 'center' : 'flex-end'}
          spacing={1}
          wrap="wrap-reverse"
        >
          <Grid item xs={10} md="auto">
            <PGEButton
              fullWidth
              onClick={async () => {
                await navigate(ROUTES.ACCOUNT);
              }}
            >
              <Typography variant={'h4'} noWrap>
                {t('DONE')}
              </Typography>
            </PGEButton>
          </Grid>
        </Grid>
      </PaperBox>
    </>
  );
};

export default TimeOfDayMeterException;
