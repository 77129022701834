import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import PhoneTextField from '../../phone-text-field/PhoneTextField';
import { useTranslation } from '../../../hooks/useTranslation';
import TextField from '../../text-field';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Button from '../../../components/buttons';
import useFormState, {
  convertValidationRules,
} from '../../../hooks/useFormState';
import {
  validateAlphabetCharacters,
  validateEmail,
  validateExistsAndAlphabetCharacters,
  validateNameField,
  validatePersonDOBGretherThanMinAge,
  validatePhoneNumberLength,
} from '../../../util/form-validation';
import EmailTextField from '../../email-text-field/EmailTextfield';
import {
  AccountDetail,
  LinkedApplicantResponse,
  LinkedPerson,
  PersonPrimaryIdType,
} from '../../../__generated__/pge-types';
import apolloClient from '../../../lib/apolloClient';
import { getAccountDetailsQuery } from '../../../queries/autoPay.query';
import useAuthQuery from '../../../hooks/useAuthQuery';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import {
  displayPhoneNumberFormat,
  formatSocialSecurity,
} from '../../../util/format';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import {
  linkedPersonMutation,
  sendVerificationForAddPersonMutation,
} from './queries';
import DatePicker from '../../date-picker';
import { BaseValidationContext } from '../../../hooks/useFormState.types';
import moment from 'moment-timezone';
import { parse, format } from 'date-fns';
import Dropdown from '../../dropdown';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from '@material-ui/core';
import { statesAndProvincesGrouping } from '../../utility/state-dropdown';
import { idTypeValueValidators } from '../../ssm/post-enrollment/CoCustomer/AddCoCustomer/AddCoCustomerForm.rules';
import colors from '../../../themes/main-colors';
import SSNTextField from '../../ssn-text-field';
import { ApolloQueryResult } from 'apollo-client';

export type AddPersonDetail = {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  primaryPhone?: string;
  birthDate: string;
  idType?:
    | PersonPrimaryIdType.Ssn
    | PersonPrimaryIdType.Dl
    | PersonPrimaryIdType.Matr
    | PersonPrimaryIdType.Pssprt
    | PersonPrimaryIdType.Resalien
    | PersonPrimaryIdType.Visa
    | PersonPrimaryIdType.Military
    | PersonPrimaryIdType.Ortrib
    | '';

  idState?: string;
  idValue?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    formField: {
      marginTop: '16px',
      marginBottom: '0px',
      '& .MuiFormHelperText-contained': {
        '& p': {
          marginTop: '0px',
          marginBottom: '0px',
        },
      },
    },
    buttonRow: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '50%',
      marginLeft: '50%',
      marginTop: '16px',
      '& button': {
        width: 'calc(50% - 8px)',
        borderRadius: '20px',
      },
    },
    marginTop: {
      marginTop: theme.spacing(3),
    },
    optionText: {
      paddingLeft: theme.spacing(4),
    },
    colorTextError: {
      color: colors.errorRed,
      margin: theme.spacing(2, 0),
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    contentBox: {
      background: colors.aliceBlue,
      padding: '2rem',
    },
    addUserButton: {
      background: colors.orange,
    },
  }),
);

export type Props = {
  setShowAddPersonModal: (value: boolean) => void;
  refetchPerson: () => Promise<
    ApolloQueryResult<{ getLinkedPersons: LinkedPerson[] }>
  >;
  setRecentlyAddedPerson: React.Dispatch<React.SetStateAction<any>>;
  mainPersonEmail: string | undefined;
  loggedInPerson: string | undefined | null;
  loggedInPersonName: string | undefined | null;
  isMedicalCerificateForm?: boolean;
};

const AddPerson = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { accountParams } = useSelectedAccountParams();
  const { setShowAddPersonModal, setRecentlyAddedPerson } = props;
  const { wrapWithLoader } = useWrapWithLoader();
  const DOB_CALENDAR_INITIAL_DATE_SELECTION = '01/01/1990';
  const DATE_FORMAT = 'MM/dd/yyyy';

  const initialDateSelection = moment(
    DOB_CALENDAR_INITIAL_DATE_SELECTION,
  ).toDate();
  const { data: accountData } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetailsQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
  });

  const accountDetails = accountData?.getAccountDetails?.[0];
  const [addPerson, setAddPerson] = useState<AddPersonDetail>({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    primaryPhone: '',
    birthDate: '',
    idType: '',
    idState: '',
    idValue: '',
  });

  const AllowedIDTypes: Record<string, string> = {
    ['']: `${t('PLEASE_SELECT_OPTIONAL')}`,
    [PersonPrimaryIdType.Ssn]: `${t('SOCIAL_SECURITY_NUMBER')}`,
    [PersonPrimaryIdType.Dl]: `${t('DRIVERS_LICENSE_STATE_ID')}`,
    [PersonPrimaryIdType.Matr]: t('MATRICULA_ID'),
    [PersonPrimaryIdType.Pssprt]: t('PASSPORT'),
    [PersonPrimaryIdType.Resalien]: t('RESIDENT_ALIEN'),
    [PersonPrimaryIdType.Visa]: t('VISA_ID'),
    [PersonPrimaryIdType.Military]: t('MILITARY_ID'),
    [PersonPrimaryIdType.Ortrib]: t('OR_TRIBAL_ID'),
  };

  const formState = useFormState(addPerson, {
    validate: createAddPersonValidateFunction(),
  });
  const isStateIdFieldEnabled =
    formState.values.idType === PersonPrimaryIdType.Dl;
  const idValueFieldLabel: string =
    AllowedIDTypes[formState.values.idType as string];

  const handleSubmit = wrapWithLoader(async () => {
    const commonPayload = {
      lastName: formState.values?.lastName,
      firstName: formState.values?.firstName,
      middleName: formState.values?.middleName,
      acctId: accountDetails?.accountNumber!,
      mainPersonEmailId: props.mainPersonEmail,
      updatedBy: props.loggedInPerson,
      loggedInUserName: props.loggedInPersonName,
    };
    const additionalInfo = {
      dateOfBirth: formState.values?.birthDate
        ? format(
            parse(formState.values?.birthDate, 'dd/mm/yyyy', new Date()),
            'yyyy-dd-mm',
          )
        : null,
      primaryPhone: formState.values?.primaryPhone
        ? displayPhoneNumberFormat(
            formState.values?.primaryPhone?.replace(/[^0-9]/g, ''),
          )
        : null,
      primaryPhoneIsMobile: formState.values?.primaryPhone ? true : false,
      emailAddress: formState.values?.email ? formState.values?.email : null,
    };
    const payload =
      formState.values?.idType === ''
        ? {
            ...commonPayload,
            additionalInfo: {
              ...additionalInfo,
            },
          }
        : {
            ...commonPayload,
            additionalInfo: {
              ...additionalInfo,
              idInfo: {
                idType: formState.values?.idType,
                idValue:
                  formState.values?.idType === PersonPrimaryIdType.Ssn &&
                  formState.values?.idValue?.length !== 4
                    ? formatSocialSecurity(formState.values?.idValue!)
                    : formState.values?.idValue,
                state: formState.values?.idState,
              },
            },
          };
    if (!props.isMedicalCerificateForm) {
      const { data, errors } = await apolloClient.mutate<{
        sendVerificationForAddPerson: LinkedApplicantResponse;
      }>({
        mutation: sendVerificationForAddPersonMutation,
        variables: {
          payload,
        },
      });

      if (data?.sendVerificationForAddPerson.success) {
        await props.refetchPerson();
        setRecentlyAddedPerson({
          ...payload,
          personId: data?.sendVerificationForAddPerson?.perId,
        });
        setShowAddPersonModal(false);
      } else {
        if (errors && errors.length > 0) {
          return Promise.reject('Mutation failed');
        }
      }
      return data;
    } else {
      const { data, errors } = await apolloClient.mutate<{
        linkedAccountApplicant: LinkedApplicantResponse;
      }>({
        mutation: linkedPersonMutation,
        variables: {
          payload,
        },
      });

      if (data?.linkedAccountApplicant.success) {
        await props.refetchPerson();
        setRecentlyAddedPerson({
          ...payload,
          personId: data?.linkedAccountApplicant?.perId,
        });
        setShowAddPersonModal(false);
      } else {
        if (errors && errors.length > 0) {
          return Promise.reject('Mutation failed');
        }
      }
      return data;
    }
  });

  return (
    <>
      <div className={classes.contentBox}>
        <Grid item xs={12} md={12} style={{ marginTop: '10px' }}>
          <TextField
            name={'firstName'}
            label={t('FIRST_NAME_1')}
            data-testid="firstName"
            {...formState.props('firstName')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            name={'middleName'}
            label={t('MIDDLE_NAME_OPTIONAL')}
            className={classes.formField}
            data-testid="middleName"
            {...formState?.props('middleName')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            name={'lastName'}
            label={t('LAST_NAME_1')}
            className={classes.formField}
            data-testid="lastName"
            {...formState?.props('lastName')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <EmailTextField
            name={'email'}
            data-testid="email"
            label={t('EMAIL')}
            inputProps={{ minLength: 1, maxLength: 60 }}
            style={{ width: '100%' }}
            className={classes.formField}
            {...formState.props('email')}
            error={Boolean(formState.errors.email)}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <PhoneTextField
            name={'primaryPhone'}
            label={t('PRIMARY_PHONE_OPTIONAL')}
            className={classes.formField}
            inputProps={{ minLength: 1, maxLength: 15 }}
            data-testid="primaryPhone"
            {...formState?.props('primaryPhone')}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <DatePicker
            data-testid="dob"
            style={{ width: '100%' }}
            label={t('DATE_OF_BIRTH')}
            name="birthDate"
            format={DATE_FORMAT}
            className={classes.formField}
            initialFocusedDate={initialDateSelection}
            {...formState.props('birthDate')}
            mask={'__/__/____'}
          />
        </Grid>
        <Grid item xs={12} md={12} style={{ marginTop: '16px' }}>
          <Dropdown
            selectionList={Object.keys(AllowedIDTypes).map((key: string) => ({
              value: key,
              label: AllowedIDTypes[key],
            }))}
            {...formState.props('idType')}
            error={Boolean(formState.errors.idType)}
            onChange={async (e: any) => {
              await Promise.all([
                formState.setValue('idType', e.target.value),
                formState.setValue(
                  'idState',
                  e.target.value === 'DL' ? 'OR' : '',
                ),
                formState.setValue('idValue', ''),
              ]);
              formState.setError('idState', null);
              formState.setError('idValue', null);
              // eslint-disable-next-line @typescript-eslint/no-floating-promises
              formState.onChange(e);
            }}
            displayEmpty
          />
        </Grid>

        {isStateIdFieldEnabled && (
          <Grid item xs={12} md={12} className={classes.marginTop}>
            <FormControl
              error={Boolean(formState.errors.idState)}
              style={{ width: '100%', background: 'transparent' }}
              variant="outlined"
            >
              <InputLabel id="id-sub-type-state-label">{t('STATE')}</InputLabel>
              <Select
                labelId="id-sub-type-state-label"
                name="idState"
                inputProps={{
                  'data-testid': 'state-field',
                  style: { background: '#FFF' },
                }}
                value={formState.values.idState}
                error={Boolean(formState.errors.idState)}
                autoWidth
                onBlur={formState.onBlur}
                style={{ width: '100%' }}
                label={t('STATE')}
                variant="outlined"
                native
              >
                <option key={'OR'} value={'OR'}>
                  {'OR'}
                </option>
                {statesAndProvincesGrouping.unitedStates
                  .filter((v: string) => v !== 'OR')
                  .map((v: string) => (
                    <option key={v} value={v}>
                      {v}
                    </option>
                  ))}
              </Select>
              <FormHelperText>{formState.errors.idState}</FormHelperText>
            </FormControl>
          </Grid>
        )}
        {formState.values.idType !== '' ? (
          formState.values.idType !== PersonPrimaryIdType.Ssn ? (
            <Grid item xs={12} md={12} style={{ marginTop: '16px' }}>
              <TextField
                inputProps={{
                  maxLength: 15,
                  style: { background: '#FFF' },
                }}
                style={{
                  width: '100%',
                  background: 'transparent',
                }}
                name="idValue"
                {...formState.props('idValue')}
                error={Boolean(formState.errors.idValue)}
                label={idValueFieldLabel}
                delayedTextMask={true}
              />
            </Grid>
          ) : (
            <Grid item xs={12} md={12} style={{ marginTop: '16px' }}>
              <SSNTextField
                name="idValue"
                label={t('SOCIAL_SECURITY_NUMBER')}
                {...formState.props('idValue')}
                error={Boolean(formState.errors.idValue)}
              />
            </Grid>
          )
        ) : null}

        {/* <Grid className={classes.buttonRow}>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => setShowAddPersonModal(false)}
      >
        {t('CANCEL')}
      </Button>

      <Button
        color={'primary'}
        variant={'contained'}
        onClick={formState.submit(
          async values => await handleSubmit(),
          console.log,
        )}
        data-testid="done"
      >
        {t('DONE')}
      </Button>
    </Grid> */}
      </div>
      <Grid className={classes.buttonRow}>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => setShowAddPersonModal(false)}
        >
          {t('CANCEL')}
        </Button>

        <Button
          className={classes.addUserButton}
          color={'primary'}
          variant={'contained'}
          onClick={formState.submit(
            async values => await handleSubmit(),
            console.log,
          )}
          data-testid="done"
        >
          {t('ADD_USER')}
        </Button>
      </Grid>
    </>
  );
};

export default AddPerson;

export const validateSelectedContact = (selectedContact: string) => {
  const { t } = useTranslation();

  if (!selectedContact || selectedContact === '') {
    return t('SELECTED_CONTACT_REQUIRED');
  }
  return null;
};

export const createAddPersonValidateFunction = () =>
  convertValidationRules<AddPersonDetail>(
    (context: BaseValidationContext<AddPersonDetail>) => {
      const { t } = useTranslation();
      return {
        firstName: validateExistsAndAlphabetCharacters.bind(
          null,
          t('FIRST_NAME'),
        ),

        middleName: validateAlphabetCharacters.bind(
          null,
          t('MIDDLE_NAME_OR_INITIAL'),
        ),

        lastName: validateExistsAndAlphabetCharacters.bind(
          null,
          t('LAST_NAME'),
        ),
        email: validateEmail,
        primaryPhone: context?.values?.primaryPhone
          ? validatePhoneNumberLength
          : null,
        birthDate: validatePersonDOBGretherThanMinAge.bind(
          null,
          context?.values?.birthDate,
          13,
        ),
        idType: null,
        idState:
          context?.values?.idType === PersonPrimaryIdType.Dl
            ? validateNameField.bind(null, t('STATE'))
            : null,
        idValue: idTypeValueValidators[context?.values?.idType!]?.(),
      };
    },
  );
