import React, { FunctionComponent } from 'react';
import useDirectAccess from '../../../hooks/useHistoricalUsage';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import { isMobile } from '../../../util/style-utils';
import PGEButton from '../../buttons';
import { navigate } from 'gatsby';
import ROUTES from '../../../routes';
import Alerts from '../../alerts';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  actionArea: {
    [theme.breakpoints.down('sm')]: {
      padingLeft: theme.spacing(4),
      padingRight: theme.spacing(4),
    },
  },
  formAction: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface HistoricalUsageDoneProps {
  path?: string;
}

const HistoricalUsageDone: FunctionComponent<HistoricalUsageDoneProps> = props => {
  const classes = useStyles();
  const { content, isRelease } = useDirectAccess();
  const theme = useTheme();
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant={'h2'}>
          {isRelease
            ? content.donePage.release.title
            : content.donePage.revoke.title}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <Grid container spacing={2}>
          <Alerts
            disableColorOverride={true}
            severity={'success'}
            variant={'outlined'}
            title={content.donePage.subTitle}
            message={
              <Typography>
                {isRelease
                  ? content.donePage.release.text
                  : content.donePage.revoke.text}
              </Typography>
            }
            isOpen
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        style={
          isMobile()
            ? { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }
            : {}
        }
        direction={'column'}
        alignItems={isMobile() ? 'stretch' : 'flex-end'}
      >
        <Grid item>
          <PGEButton
            variant={'contained'}
            color={'primary'}
            onClick={() => navigate(ROUTES.ACCOUNT)}
            className={classes.formAction}
          >
            {content.done}
          </PGEButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HistoricalUsageDone;
