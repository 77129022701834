import React, { FC, useContext } from 'react';
import Page2ColumnLayout from '../../components/utility/page2-column-layout';
import MainArea from '../../components/utility/main-area';
import { useTranslation } from '../../hooks/useTranslation';
import Helmet from 'react-helmet';
import ClosedAccountsLeftNav from '../../components/closed-accounts/ClosedAccountLeftPane';
import ClosedAccounts from '../../components/closed-accounts/ClosedAccounts';
import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';
import { navigate } from 'gatsby';
import ROUTES from '../../routes';
import { Router } from '@reach/router';
import ClosedAccountsPaymentHistoryPage from './closed-accounts-payment-history';

const ClosedAccountsPage: FC = () => {
  const { t } = useTranslation();
  const { isClosedAccountsEnabled } = useContext(featureFlagsContext);

  if (!isClosedAccountsEnabled) {
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(ROUTES.ACCOUNT);
  }

  return (
    <>
      <Helmet>
        <title>{t('CLOSED_ACCOUNTS')}</title>
      </Helmet>

      <Page2ColumnLayout leftColumn={<ClosedAccountsLeftNav />}>
        <MainArea>
          <Router basepath="/">
            <ClosedAccounts path={`${ROUTES.CLOSED_ACCOUNTS}/*`} />
            <ClosedAccountsPaymentHistoryPage
              path={`${ROUTES.CLOSED_ACCOUNTS_HISTORY}`}
            />
          </Router>
        </MainArea>
      </Page2ColumnLayout>
    </>
  );
};

export default ClosedAccountsPage;
