import gql from 'not-graphql-tag';

/* mutation stopServiceMutation($payload: StopServiceSubmitRequest!) {
    stopServiceSubmit(payload: $payload)
  } */

export const addCoCustomerMutation = gql`
  mutation AddCoApplicant($payload: CoApplicant!) {
    addCoApplicant(payload: $payload) {
      perId
      verificationFlag
    }
  }
`;

export const addAuthorizedUserMutation = gql`
  mutation addAuthUserApplicant($payload: AuthUserApplicant!) {
    addAuthUserApplicant(payload: $payload) {
      perId
    }
  }
`;

export const validateLastFourDigitPersonalIDMutation = gql`
  mutation validateID {
    addCoApplicant(payload: $payload) {
      perId
    }
  }
`;
