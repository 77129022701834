import { useState, useMemo } from 'react';

export type MulticheckState = {
  allSelected: boolean;
  selectAllDisabled: boolean;
  isChecked: (item: string) => boolean;
  isAnOption: (item: string) => boolean;
  handleCheckToggle: (item: string) => () => void;
  handleSelectAll: () => void;
  getSelected: () => Array<string>;
  options: Array<string>;
  checkedOptions: () => Array<string>;
};

export type MulticheckOptions = {
  initialChecked?: Record<string, boolean>;
};

function allChecked(options: Array<string>) {
  return options.reduce((acc, option) => {
    return { ...acc, [option]: true };
  }, {});
}

export default function useMulticheckState(
  options: Array<string>,
  { initialChecked = {} }: MulticheckOptions = {},
) {
  const [checked, setChecked] = useState<Record<string, boolean>>(
    initialChecked,
  );

  const noOptions = useMemo(() => options.length === 0, [options]);
  const allSelected = useMemo(
    () =>
      noOptions ? false : options.every(option => Boolean(checked[option])),
    [noOptions, options, checked],
  );

  function handleSelectAll() {
    if (allSelected) {
      setChecked({});
    } else {
      setChecked(allChecked(options));
    }
  }

  function isChecked(item: string) {
    return Boolean(checked[item]);
  }

  function handleCheckToggle(item: string) {
    return () => {
      setChecked(current => {
        const newValue = {
          ...current,
          [item]: !current[item],
        };
        return newValue;
      });
    };
  }

  function checkedOptions() {
    return options.filter(opt => checked[opt]);
  }

  function isAnOption(option: string) {
    return options.includes(option);
  }

  return {
    selectAllDisabled: noOptions,
    allSelected,
    isChecked,
    handleCheckToggle,
    handleSelectAll,
    getSelected: checkedOptions,
    options,
    checked,
    checkedOptions,
    isAnOption,
    setChecked,
  };
}
