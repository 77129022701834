import React, { useState, useContext, ReactNode, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { navigate, useLocation } from '@reach/router';
import formatCurrency from 'format-currency';

import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import {
  createPaymentExtensionMutation,
  getAccountDetailsForPaymentExtension,
} from '../../queries/paymentExtension.query';
import {
  PaymentExtensionEligibility,
  PaymentExtensionCurrentPlan,
  PaymentExtensionOption,
  Maybe,
  PaymentExtensionOptionType,
  PaymentExtensionResponse,
} from '../../__generated__/pge-types';
import useAuthQuery from '../../hooks/useAuthQuery';
import { toDateString } from '../../util/format';
import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';
import useUtil from './useUtil';
import useGTMUtilNmttp from './gtm/useGTMUtilNmttp';

export type PaymentExtensionOptionDetail = PaymentExtensionOption & {
  descriptionNode?: ReactNode;
};

export const TPA_PLAN_OPTION = 'TPA';

type Props = {
  isTPAEligible: boolean | null;
  isTPAFlagOverwrite?: boolean | null;
  isTPAPlanSelected: boolean;
  setIsTPAPlanSelected: (value: boolean) => void;
};

export default ({
  isTPAEligible,
  isTPAFlagOverwrite = null,
  isTPAPlanSelected,
  setIsTPAPlanSelected,
}: Props) => {
  //TODO: Once this is live, remove the feature flag and associated code incl. unit  tests.
  const { isTPAEnabled } = useContext(featureFlagsContext);
  const isTPAFeatureEnabled =
    isTPAFlagOverwrite !== null ? isTPAFlagOverwrite : isTPAEnabled;

  const {
    gtm_Nmttp_HandleOnShowOptionsClick,
    gtm_PlanOptions_HandleOnSelectionOptionChange,
    gtm_PlanOptions_HandleOnSelectOptionSubmit,
    gtm_Confirm_HandleOnSubmit,
    gtm_Confirm_HandleOnSubmitError,
  } = useGTMUtilNmttp();
  const { t, inlineRichT } = useTranslation();
  const { wrapWithLoader } = useWrapWithLoader();
  const location = useLocation();

  const [currentPlan, setCurrentPlan] = useState<
    PaymentExtensionCurrentPlan | null | undefined
  >();

  const [availablePEOptions, setAvailablePEOptions] = useState<
    PaymentExtensionOptionDetail[]
  >([]);

  const [isEligibleForPETpa, setIsEligibleForPETpa] = useState<boolean>(false);

  const [isPastDue, setIsPastDue] = useState<boolean>(false);

  const [
    selectedPEPlan,
    setSelectedPEPlan,
  ] = useState<PaymentExtensionOptionDetail | null>(null);

  const { setErrorNotification } = useUtil();

  const [confirmationNumber, setConfirmationNumber] = useState<string>();

  const {
    encryptedPersonId,
    encryptedAccountNumber,
    accountParams,
    loading: loadingAccountParams,
  } = useSelectedAccountParams();

  const getPlanDescription = (plan: PaymentExtensionOption): ReactNode => {
    let descriptionTemplate: string = '';

    switch (plan?.paymentExtensionOptionType) {
      case PaymentExtensionOptionType.Option1: {
        descriptionTemplate = 'OPTION_ONE_NMTTP';
        break;
      }
      case PaymentExtensionOptionType.Option2: {
        descriptionTemplate = 'OPTION_TWO_NMTTP';
        break;
      }
      case PaymentExtensionOptionType.Option3: {
        descriptionTemplate = 'OPTION_THREE_NMTTP';
        break;
      }
    }
    const children = inlineRichT(descriptionTemplate, {
      AMOUNT: `$${formatCurrency(plan?.amount)}`,
      DATE: toDateString(plan?.planDate),
    });
    return <span>{children}</span>;
  };

  const parseAvailableOptions = (
    availableOptionsParam:
      | Maybe<Array<Maybe<PaymentExtensionOption>>>
      | null
      | undefined,
  ): PaymentExtensionOptionDetail[] => {
    const optionArray: PaymentExtensionOptionDetail[] = [];
    availableOptionsParam?.map(
      option =>
        Boolean(option) &&
        optionArray.push({
          descriptionNode: getPlanDescription(option!),
          ...option,
        }),
    );
    return optionArray;
  };

  const determineIsPastDue = (
    currentPlanParam: PaymentExtensionCurrentPlan | null | undefined,
    eligiblityParam: PaymentExtensionEligibility,
    availableOptionsParam:
      | Maybe<Array<Maybe<PaymentExtensionOption>>>
      | null
      | undefined,
  ): boolean => {
    const result: boolean =
      (Boolean(currentPlanParam) &&
        currentPlanParam?.enrolledInOption3 !== null &&
        currentPlanParam?.enrolledInOption3 !== undefined &&
        currentPlanParam.enrolledInOption3) ||
      Boolean(
        eligiblityParam ===
          PaymentExtensionEligibility.EligibleWithCurrentPlan &&
          availableOptionsParam &&
          availableOptionsParam?.filter(
            plan =>
              plan?.paymentExtensionOptionType ===
              PaymentExtensionOptionType.Option3,
          )?.length > 0,
      );

    return result;
  };

  const {
    loading: loadingPEInfoQuery,
    data: getPEInfoData,
    error: getPEInfoError,
    refetch,
  } = useAuthQuery(getAccountDetailsForPaymentExtension, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip:
      accountParams === null ||
      accountParams === undefined ||
      !accountParams,
    fetchPolicy: 'network-only',
    onError: () => setErrorNotification(true),
  });

  const refreshPEData = (
    paymentExtensionInfo: PaymentExtensionResponse | undefined,
  ) => {
    const eligibilityReponse = paymentExtensionInfo?.eligibility;
    if (eligibilityReponse) {
      if (
        eligibilityReponse !== PaymentExtensionEligibility.Unknown &&
        eligibilityReponse !== PaymentExtensionEligibility.Ineligible
      ) {
        const currentPlanResponse = paymentExtensionInfo?.currentPlan;
        const availableOptionsReponse =
          paymentExtensionInfo?.paymentExtensionOptions;
        setCurrentPlan(currentPlanResponse);
        setAvailablePEOptions(parseAvailableOptions(availableOptionsReponse));
        setIsPastDue(
          determineIsPastDue(
            currentPlanResponse,
            eligibilityReponse,
            availableOptionsReponse,
          ),
        );
        setIsEligibleForPETpa(Boolean(paymentExtensionInfo?.isEnrolled));
      }
    }
  };

  useEffect(() => {
    if (!loadingPEInfoQuery) {
      const eligibilityReponse =
        getPEInfoData?.getAccountDetails?.[0].paymentExtensionInfo?.eligibility;
      if (eligibilityReponse) {
        if (
          eligibilityReponse !== PaymentExtensionEligibility.Unknown &&
          eligibilityReponse !== PaymentExtensionEligibility.Ineligible
        ) {
          const currentPlanResponse =
            getPEInfoData?.getAccountDetails?.[0].paymentExtensionInfo?.currentPlan;
          const availableOptionsReponse =
            getPEInfoData?.getAccountDetails?.[0].paymentExtensionInfo
              ?.paymentExtensionOptions;
          setCurrentPlan(currentPlanResponse);
          const options = parseAvailableOptions(availableOptionsReponse);
          setAvailablePEOptions(options);
          setIsPastDue(
            determineIsPastDue(
              currentPlanResponse,
              eligibilityReponse,
              availableOptionsReponse,
            ),
          );
          setIsEligibleForPETpa(
            Boolean(getPEInfoData?.getAccountDetails?.[0].paymentExtensionInfo?.isEnrolled),
          );
        }
      }
    }
    refreshPEData(getPEInfoData?.getAccountDetails?.[0].paymentExtensionInfo);
  }, [getPEInfoData]);

   useEffect(() => {
    setCurrentPlan(undefined);
    setAvailablePEOptions([]);
    setIsPastDue(false);
    setIsEligibleForPETpa(false);

    refreshPEData(getPEInfoData?.getAccountDetails?.[0].paymentExtensionInfo);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      encryptedAccountNumber && refetch();
    })();
  }, [accountParams?.accountNumber]);

  const [submitPaymentExtension] = useMutation(createPaymentExtensionMutation, {
    variables: {
      payload: {
        hasPastDueAmount: isPastDue,
        currentPlandId: currentPlan?.planId,
        enrolledInOption3: currentPlan?.enrolledInOption3,
        selectedPaymentExtensionOption: {
          paymentExtensionOptionType:
            selectedPEPlan?.paymentExtensionOptionType,
          amount: selectedPEPlan?.amount,
          planDate: selectedPEPlan?.planDate,
        },
        encryptedAccountNumber: encryptedAccountNumber,
        encryptedPersonId: encryptedPersonId,
      },
    },
  });

  const isOnlyOnePEChoiceAvailable = availablePEOptions.length === 1;

  const handleOnShowOptionsClick = async (
    isEnrolled: Boolean = false,
  ): Promise<void> => {
    gtm_Nmttp_HandleOnShowOptionsClick();
    if (!isTPAFeatureEnabled) {
      if (isOnlyOnePEChoiceAvailable) {
        setSelectedPEPlan(availablePEOptions[0]);
        await navigate(ROUTES.NEED_MORE_TIME_TO_PAY_CONFIRM);
      } else {
        await navigate(ROUTES.NEED_MORE_TIME_TO_PAY_PLAN_OPTIONS);
      }
    } else {
      if (isOnlyOnePEChoiceAvailable && !isTPAEligible) {
        setSelectedPEPlan(availablePEOptions[0]);
        await navigate(ROUTES.NEED_MORE_TIME_TO_PAY_CONFIRM_NOTPA);
      } else if (!isTPAEligible) {
        await navigate(ROUTES.NEED_MORE_TIME_TO_PAY_PLAN_OPTIONS_NOTPA);
      } else if (isEnrolled && isOnlyOnePEChoiceAvailable) {
        setSelectedPEPlan(availablePEOptions[0]);
        await navigate(ROUTES.NEED_MORE_TIME_TO_PAY_CONFIRM);
      } else {
        await navigate(ROUTES.NEED_MORE_TIME_TO_PAY_PLAN_OPTIONS);
      }
    }
  };

  const handleOnSelectionOptionChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    gtm_PlanOptions_HandleOnSelectionOptionChange(e.target.value);
    const selectedPEOption = availablePEOptions.find(
      plan => plan.paymentExtensionOptionType === e.target.value,
    );
    if (selectedPEOption) {
      setSelectedPEPlan(selectedPEOption);
      setIsTPAPlanSelected(false);
    } else if (TPA_PLAN_OPTION === e.target.value) {
      setIsTPAPlanSelected(true);
      setSelectedPEPlan(null);
    }
  };

  const handleOnSelectOptionSubmit = async (): Promise<void> => {
    gtm_PlanOptions_HandleOnSelectOptionSubmit();
    if (selectedPEPlan !== null && selectedPEPlan !== undefined) {
      await navigate(ROUTES.NEED_MORE_TIME_TO_PAY_CONFIRM);
    } else if (isTPAPlanSelected) {
      await navigate(ROUTES.TPA_CREATE_PLAN);
    } else {
      setErrorNotification(true);
    }
  };

  const handleOnCancel = async (): Promise<void> => {
    setSelectedPEPlan(null);
    setIsTPAPlanSelected(false);
  };

  const handleOnSubmit = wrapWithLoader(
    async (): Promise<void> => {
      if (selectedPEPlan === null || selectedPEPlan === undefined) {
        gtm_Confirm_HandleOnSubmitError();
        setErrorNotification(true);
      }
      const response = await submitPaymentExtension();
      if (response?.data) {
        const result =
          response?.data?.enrollPaymentExtension?.confirmationNumber;
        if (result) {
          gtm_Confirm_HandleOnSubmit();
          setConfirmationNumber(result);
          await navigate(ROUTES.NEED_MORE_TIME_TO_PAY_SUCCESS);
        } else {
          gtm_Confirm_HandleOnSubmitError();
          setErrorNotification(true);
        }
      }
    },
  );

  return {
    loadingPEData: loadingAccountParams || loadingPEInfoQuery,
    handleOnShowOptionsClick,
    handleOnSelectionOptionChange,
    handleOnSelectOptionSubmit,
    handleOnCancel,
    handleOnSubmit,
    currentPEPlan: currentPlan,
    hasAvailablePEOptions: Boolean(availablePEOptions?.length),
    availablePEOptions,
    isEligibleForPETpa,
    isPastDue,
    isOnlyPEOptionOneAvailable:
      availablePEOptions?.length === 1 &&
      availablePEOptions[0].paymentExtensionOptionType ===
        PaymentExtensionOptionType.Option1,
    selectedPEPlan,
    confirmationNumber,
    isOnlyOnePEChoiceAvailable,
    getPEInfoError,
  };
};
