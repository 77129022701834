/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FC } from 'react';
import {
  Paper,
  Checkbox,
  FormControlLabel,
  Typography,
  FormHelperText,
} from '@material-ui/core';

import colors from '../../themes/main-colors';
import { CustomCSS } from '../../util/emotion';
import { isMobile } from 'react-device-detect';

interface CheckBoxWithPaperProps {
  option: { label: string; value: string };
  state: string;
  setState: (value: string) => void;
  errorMsg?: string | null;
}

export const CheckBoxWithPaper: FC<CheckBoxWithPaperProps> = ({
  option,
  state,
  setState,
  errorMsg = '',
}) => {
  const handleChange = (value: string) => () => {
    isChecked(value) ? setState('') : setState(value);
  };
  const isChecked = (value: string) => value === state;

  return (
    <Paper
      css={{
        padding: 20,
        backgroundColor: colors.riverBlue,
        color: colors.noirBlur,
        '& .MuiFormControlLabel-root': {
          alignItems: 'flex-start',
        },
        '& .MuiTypography-root': {
          fontSize: isMobile ? '16px' : '20px',
          lineHeight: isMobile ? '20px' : '26px',
          color: colors.noirBlur,
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
          color: colors.sparkBlue,
        },
      }}
    >
      <FormControlLabel
        control={
          <Checkbox
            color={'primary'}
            css={{
              padding: '3px 20px 20px 10px',
              '& .MuiIconButton-label': {
                position: 'relative',
                zIndex: 0,
              },
              '&:not(.Mui-checked) .MuiIconButton-label:after': {
                content: '""',
                left: 4,
                top: 4,
                height: 13,
                width: 13,
                position: 'absolute',
                backgroundColor: colors.white,
                zIndex: -1,
              },
            }}
            size={'small'}
          />
        }
        checked={isChecked(option.value)}
        onChange={handleChange(option.value)}
        label={
          <Typography css={{ fontSize: isMobile ? 16 : 20 }}>
            {option.label}
          </Typography>
        }
        css={{ display: 'flex', alignItems: 'flex-start' }}
      />
      {errorMsg && <FormHelperText error>{errorMsg}</FormHelperText>}
    </Paper>
  );
};
