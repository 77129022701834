import Grid from '@material-ui/core/Grid';
import React from 'react';
import { getPaymentMethodLabelForType } from '../../../hooks/usePaymentus';
import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';
import { substrNFromEnd } from '../../../util/format';
import { PaymentProfile } from '../../../__generated__/pge-types';
import PaymentProfileIcon from '../../paymentus/payment-profile-icon';
import {
  getAccountDisplayString,
  addEditExcludeList,
} from '../../paymentus/utils';
import { isExpired } from '../../../util/date-utils';

const PaymentMethodItem = ({ profile }: { profile: PaymentProfile }) => {
  const { t } = useTranslation();

  const typeLabel =
    profile.type && !addEditExcludeList.includes(profile.type)
      ? getPaymentMethodLabelForType(profile.type)
      : '';

  const numberLabel =
    profile.type && addEditExcludeList.includes(profile.type)
      ? getAccountDisplayString(profile)
      : substrNFromEnd(4, getAccountDisplayString(profile));

  return (
    <Grid
      container
      item
      direction={'row'}
      alignItems={'center'}
      justify={'flex-start'}
      spacing={2}
      style={{
        fontSize: '1rem',
        color: colors.noirBlur,
      }}
    >
      <Grid item xs={12} sm={3} style={{ maxWidth: '6em' }}>
        <PaymentProfileIcon type={profile.type} />
      </Grid>
      <Grid
        item
        xs={profile.creditCardExpiryDate ? 9 : 12}
        sm={6}
        style={{ whiteSpace: 'normal' }}
      >
        {t(`${typeLabel} ${numberLabel}`)}
      </Grid>
      {profile.creditCardExpiryDate &&
        (isExpired(profile.creditCardExpiryDate) ? (
          <Grid
            item
            xs={12}
            sm={3}
            style={{
              color: colors.roseRed,
            }}
          >
            {t('EXPIRED')}
          </Grid>
        ) : (
          <Grid item xs={3} style={{ justifySelf: 'end' }}>
            {profile.creditCardExpiryDate.month}
            {'/'}
            {substrNFromEnd(2, profile.creditCardExpiryDate.year)}
          </Grid>
        ))}
    </Grid>
  );
};

export default PaymentMethodItem;
