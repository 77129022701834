import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography, { TypographyProps } from '@material-ui/core/Typography';
import React, { ReactNode, forwardRef } from 'react';

import { useTranslation } from '../../hooks/useTranslation';
import PGEButton from '../buttons';
import { PGEButtonProps } from '../buttons/PGE-Button';
import TextLink from '../text-link';
import { TextLinkProps } from '../text-link/TextLink';

interface DialogProps {
  title?: string;
  message?: string | HTMLElement;
  isOpen: boolean;
  onCancel?: (event: any) => void;
  onSubmit?: (event: React.MouseEvent) => void;
  children?: ReactNode;
  titleProps?: TypographyProps;
  cancelButtonProps?: TextLinkProps;
  submitButtonProps?: PGEButtonProps;
  submitLabel?: string | JSX.Element;
  cancelLabel?: string | JSX.Element;
  onClose?: () => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'relative',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '0px solid #FFF',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      outline: 0,
    },
    buttons: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      paddingTop: 10,
    },
  }),
);

const Dialog = forwardRef<HTMLElement, DialogProps>((props, ref) => {
  const [open, setOpen] = React.useState(props.isOpen);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(!open);
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div>
      <Modal open={open} className={classes.modal} onClose={handleClose}>
        <Paper className={classes.paper}>
          <Typography
            variant={'h5'}
            gutterBottom
            align={'left'}
            {...props?.titleProps}
          >
            {props.title}
          </Typography>
          <Typography variant={'body2'}>
            {props.message || props.children}
          </Typography>
          {(props.onCancel || props.onSubmit) && (
            <Grid
              container
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingTop: 10,
              }}
            >
              <Grid item style={{ paddingRight: 10 }}>
                <TextLink
                  plain
                  {...props?.cancelButtonProps}
                  onClick={props.onCancel}
                >
                  {props?.cancelLabel || t('Cancel')}
                </TextLink>
              </Grid>
              <PGEButton
                color={'secondary'}
                variant={'contained'}
                {...props?.submitButtonProps}
                onClick={props.onSubmit}
              >
                {props?.submitLabel || t('Remove')}
              </PGEButton>
            </Grid>
          )}
        </Paper>
      </Modal>
    </div>
  );
});

export default Dialog;
