import gql from 'not-graphql-tag';

export const getPreferredDueDateInfoQuery = gql`
  query getPreferredDueDateInfo($payload: PreferredDueDateRequest!) {
    getPreferredDueDateInfo(payload: $payload) {
      dueDateInfo {
        preferredDueDate
        status
        effectiveDate
      }
      eligibility {
        reasonCode
        isEligible
      }
    }
  }
`;

export const updatePreferredDueDate = gql`
  mutation addPreferredDueDate($payload: PreferredDueDateAddRequest) {
    addPreferredDueDate(payload: $payload) {
      preferredDueDate
      status
      effectiveDate
    }
  }
`;
export const getAccountDetailsForPreferredDueDate = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      preferredDueDate {
        eligibility {
          reasonCode
          isEligible
        }
        dueDate {
          preferredDueDate
          status
          effectiveDate
        }
      }
    }
  }
`;
