import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import ROUTES from '../../../routes';
import { Link } from 'gatsby';

import { useTranslation } from '../../../hooks/useTranslation';
import Alerts from '../../alerts';
import Button from '../../buttons';

interface CancelServiceProps {
  path?: string;
  handleSubmit?: (arg: any) => void | any;
  classes: any;
}

const CancelServiceVerification: FunctionComponent<CancelServiceProps> = props => {
  const { t } = useTranslation();

  return (
    <>
      <Paper>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant={'body1'}>
                  {t('CANCEL_ONLINE_VERIFICATION_1')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Alerts
                  disableColorOverride={true}
                  isOpen
                  severity={'warning'}
                  variant={'outlined'}
                  title={t('CANCEL_ONLINE_VERIFICATION_2')}
                  message={
                    <Grid item xs={12}>
                      <Typography variant={'body1'}>
                        {t('CANCEL_ONLINE_VERIFICATION_3')}
                      </Typography>
                    </Grid>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'h3'}>
                  {t('CANCEL_ONLINE_UNREGISTER_INFO')}
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justify="flex-end"
                alignContent="center"
                alignItems="center"
                spacing={2}
              >
                <Link
                  className={props.classes.backLinkDesktop}
                  to={ROUTES.CANCEL_ONLINE_SERVICE}
                >
                  {t('PREVIOUS')}
                </Link>
                <Button
                  size={'large'}
                  onClick={props?.handleSubmit}
                  className={props.classes.submitButton}
                >
                  {t('PROCEED')}
                </Button>
                <Link
                  to={ROUTES.CANCEL_ONLINE_SERVICE}
                  className={props.classes.backLinkMobile}
                >
                  {t('PREVIOUS')}
                </Link>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </>
  );
};

export default CancelServiceVerification;
