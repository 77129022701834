import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { AlertOptions } from '../../../components/account-summary/manage-alerts/types';
import { useStyles } from '../../../components/account-summary/manage-alerts/styles';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';
import {
  NotificationPreferenceObj,
  PostEnrollmentNotificationSettings,
} from '../ssm.types';
import {
  CommPreference,
  NotificationType,
} from '../../../__generated__/pge-types';
import { FormState } from '../../../hooks/useFormState.types';
import NumericTextField from '../../numeric-text-field';

type Props = {
  alert: CommPreference;
  emailText: NotificationPreferenceObj;
  setEmailText: (emailTest: NotificationPreferenceObj) => void;
  form: FormState<PostEnrollmentNotificationSettings>;
  textFieldKey?: string;
};

const useNotificationStyles = makeStyles((theme: Theme) => ({
  subField: {
    '& input': {
      padding: theme.spacing(1, 2),
    },
  },
  labelPortion: {
    fontWeight: 600,
  },
  emailBox: {
    marginRight: '50px',
    minWidth: '40px',
  },
  rowStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  labelText: {
    paddingRight: 10,
  },
  textFldText: {
    paddingLeft: 7,
  },
  rowBorder: {
    border: '0px',
  },
  inputTitle: {
    color: colors.charcoal,
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'bold',
    '& .Mui-error': {
      margin: '0 !important',
      background: `${colors.siteBackground} !important`,
    },
  },
  displayNone: {
    display: 'none',
  },
}));

function sanitizeNumber(num: string) {
  if (num.startsWith('$')) {
    return num.slice(1).replace(/,/g, '');
  }
  return num;
}

export function EmailTextToggleControlSSM({
  alert,
  emailText,
  setEmailText,
  form,
  textFieldKey,
}: Props) {
  const classes = useStyles();
  const notifyClass = useNotificationStyles();

  const labelPortion = (
    <Typography variant={'body1'} className={notifyClass.labelPortion}>
      {alert.notificationTypeDescription}
    </Typography>
  );

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    labelKey: string,
  ) => {
    const { name } = event.target;
    if (labelKey !== undefined) {
      if (
        Object.keys(emailText).length &&
        emailText[labelKey] &&
        emailText[labelKey][name] === true
      ) {
        setEmailText({
          ...emailText,
          [labelKey]: {
            ...emailText[labelKey],
            [name]: false,
          },
        });
      } else {
        setEmailText({
          ...emailText,
          [labelKey]: {
            ...emailText[labelKey],
            [name]: true,
          },
        });
      }
    }
  };

  const anyChosen =
    emailText[alert?.notificationType]?.email ||
    emailText[alert?.notificationType]?.text;

  return (
    <>
      {Object.keys(emailText).length ? (
        <div className={`${classes.row} ${notifyClass.rowBorder}`}>
          <Box className={notifyClass.inputTitle}>
            <Grid
              container
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              <Grid item style={{ paddingRight: 10 }}>
                {labelPortion}
              </Grid>
              {anyChosen && textFieldKey && (
                <NumericTextField
                  className={classes.subField}
                  style={{ width: 140 }}
                  maxLength={5}
                  prefix="$"
                  allowDecimal
                  inputProps={{ type: 'number' }}
                  variant={'outlined'}
                  {...form.props(
                    textFieldKey as keyof PostEnrollmentNotificationSettings,
                  )}
                  error={
                    anyChosen &&
                    Boolean(
                      form.errors[
                        textFieldKey as keyof PostEnrollmentNotificationSettings
                      ],
                    )
                  }
                  helperText={
                    anyChosen &&
                    form.errors[
                      textFieldKey as keyof PostEnrollmentNotificationSettings
                    ]
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    form.setValue(
                      textFieldKey as keyof PostEnrollmentNotificationSettings,
                      sanitizeNumber(e.target.value),
                    )
                  }
                />
              )}
            </Grid>
          </Box>

          <Box className={notifyClass.emailBox}>
            <Checkbox
              color={'primary'}
              name={'email'}
              className={
                alert?.notificationType === NotificationType.Out
                  ? notifyClass.displayNone
                  : ''
              }
              checked={
                alert.notificationTypeDescription === 'Disconnect Notice'
                  ? true
                  : emailText[alert?.notificationType]?.email
              }
              onChange={e => handleChange(e, alert.notificationType)}
              disabled={
                alert.notificationTypeDescription === 'Disconnect Notice'
                  ? true
                  : false
              }
            />
          </Box>
          <Box>
            <Checkbox
              data-testid="testing-check-circle-icon"
              color={'primary'}
              name={'text'}
              checked={emailText[alert?.notificationType]?.text}
              onChange={e => handleChange(e, alert.notificationType)}
            />
          </Box>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
