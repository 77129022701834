import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import routes from '../../../routes';
import { Card } from './Card';
import GoodEnergyQuizImage from '../../../images/goodenergyquiz.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      width: 'inherit',
      height: '100%',
      objectFit: 'cover',
      [theme.breakpoints.down('sm')]: {
        height: '170px',
      },
    },
  }),
);

export function GoodEnergyQuiz() {
  const { t, richT, inlineRichT } = useTranslation();
  const classes = useStyles();
  return (
    <Card
      type="full"
      theme="blue"
      title={inlineRichT('GOOD_ENERGY_QUIZ_HEADER')}
      sectionA={richT('GOOD_ENERGY_QUIZ_DEFAULT_CARD')}
      sectionB={
        <img alt={''} src={GoodEnergyQuizImage} className={classes.img} />
      }
      ctaLink={routes.GOOD_ENERGY_QUIZ_LINK}
      ctaText={t('GOOD_ENERGY_QUIZ_LINK_TEXT')}
    />
  );
}
