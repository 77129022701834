import React from 'react';
import { navigate } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {
  PersonalIdentificationType,
  StateIdType,
  FederalIdentificationType,
} from '../../__generated__/pge-types';
import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';

type EditLinkProps = {
  step: number;
  to: string;
  goToStep: (step: number) => Promise<void>;
};

export const EditLink = ({ step, to, goToStep }: EditLinkProps) => {
  const { t } = useTranslation();
  return (
    <Typography
      variant="body2"
      onClick={async () => {
        await goToStep(step);
        await navigate(to);
      }}
      style={{
        color: colors.sparkBlue,
        fontSize: '16px',
        cursor: 'pointer',
      }}
    >
      {t('EDIT')}
    </Typography>
  );
};

type DataLabelProps = {
  text: string;
  value: any;
};

export const DataLabel = ({ text, value }: DataLabelProps) => (
  <Grid item xs={12} md={6}>
    <strong>{text}</strong>
    <br />
    {value}
  </Grid>
);

interface IdTypeLabel<TValue> {
  [id: string]: TValue;
}

const idTypeLabelMap: IdTypeLabel<string> = {
  [PersonalIdentificationType.Ssn]: 'SSN',
  [PersonalIdentificationType.Itin]: 'ITIN',
  [PersonalIdentificationType.Dl]: 'DRIVERS_LICENSE',
  [StateIdType.DriverLicense]: 'DRIVERS_LICENSE',
  [StateIdType.StateIdCard]: 'STATE_ID_CARD',
  [PersonalIdentificationType.Ortrib]: 'OREGON_TRIBAL_ID',
  [FederalIdentificationType.OregonTribalId]: 'OREGON_TRIBAL_ID',
  [PersonalIdentificationType.Visa]: 'VISA_ID',
  [FederalIdentificationType.UsStudentVisa]: 'US_STUDENT_VISA_ID',
  [FederalIdentificationType.UsTemporaryVisa]: 'US_TEMPORARY_VISA_ID',
  [PersonalIdentificationType.Military]: 'MILITARY_ID',
  [FederalIdentificationType.UsMilitaryId]: 'MILITARY_ID',
  [PersonalIdentificationType.Pssprt]: 'PASSPORT',
  [FederalIdentificationType.UsPassport]: 'PASSPORT',
  [PersonalIdentificationType.Resalien]: 'RESIDENT_ALIEN_ID',
  [FederalIdentificationType.UsImmigration]: 'RESIDENT_ALIEN_ID',
};

export const getIdTypeLabel = (idType: any): string => {
  const { t } = useTranslation();
  return t(idTypeLabelMap[idType] || '');
};
