import React, { useContext } from 'react';
import { Box, Typography, withWidth } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';

import colors from '../../../themes/main-colors';

import useContentMgmt, {
  PageTextContentType,
} from '../../../hooks/useContentMgmt';
import { formatFullDateWithYear } from '../../../util/date-utils';
import { LanguageContext } from '../../../providers/LanguageProvider';

const useStyles = makeStyles(() => ({
  container: {
    boxShadow: '0px 1px 1px #00000066',
    backgroundColor: colors.white,
    marginBottom: '30px',
    padding: '30px',
  },
  heading: {
    textAlign: 'center',
    fontSize: '32px',
    lineHeight: '40px',
    fontFamil: 'Untitled Sans',
    fontWeight: 'bold',
    color: colors.darkBlack,
    marginBottom: '30px',
  },
  messagesBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  message: {
    fontSize: '18px',
    lineHeight: '22px',
    color: colors.darkBlack,
    '& p': {
      marginBlockStart: '10px',
      marginBlockEnd: '10px',
      display: 'inline-block',
    },
  },
}));

interface FormSuccessProps {
  content: PageTextContentType;
  discount?: string;
}

const IQBDFormSubmitSuccess = ({ content, discount }: FormSuccessProps) => {
  const classes = useStyles();
  const { richText } = useContentMgmt();
  const { language } = useContext(LanguageContext);

  const twoYearsFromNowDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 2),
  );

  return (
    <Box className={classes.container}>
      <Box
        css={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <CheckCircle
          css={{
            width: '64px',
            height: '64px',
          }}
          htmlColor={colors.shoreGreen}
        />
      </Box>
      <Typography className={classes.heading}>
        {content.get('FORM_SUCCESS_TITLE')}
      </Typography>
      <Box className={classes.messagesBox}>
        <Typography className={classes.message}>
          {richText(content.get('ENROLL_SUCCESS_MESSAGE'), {
            discount: discount,
            two_years_from_now_date: formatFullDateWithYear(
              twoYearsFromNowDate,
              language,
              true,
            ),
          })}
          <Typography
            component={'span'}
            css={{
              color: colors.sparkBlue,
              fontWeight: 'bold',
              textDecoration: 'underline',
              cursor: 'pointer',
              marginLeft: '4px',
            }}
          >
            {content.get('ASSISTANCE_PHONE_NUMBER')}
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
};

export default withWidth()(IQBDFormSubmitSuccess);
