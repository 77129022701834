import React, { createContext, useReducer } from 'react';
import { cloneDeep } from 'lodash';
import { RenewablePower } from '../__generated__/pge-types';

export enum RenewablePowerOptionType {
  GREEN_SOURCE = 'GREEN_SOURCE',
  CLEAN_WIND = 'CLEAN_WIND',
  SOLAR_SOURCE = 'SOLAR_SOURCE',
  HABITAT_SUPPORT = 'HABITAT_SUPPORT',
}
export interface RenewablePowerOption {
  label: string;
  optionType: RenewablePowerOptionType;
  isEnrolled: boolean;
  isSelected: boolean;
  isEnable: boolean;
  disableReason?: string;
  enrolledBlocks?: number;
  blocks?: number;
  price: number;
  unit?: 'block' | 'kWh';
}

export interface RenewablePowerContextState {
  greenSource: RenewablePowerOption;
  cleanWind: RenewablePowerOption;
  solarSource: RenewablePowerOption;
  habitatSupport: RenewablePowerOption;
  promoCode?: string;
  selectedProgramAnswer?: string;
  includeInPromotion: boolean;
  companyName?: string;
  isSubmitted: boolean;
}

export enum RenewablePowerContextActionType {
  SAVE_INFO = 'SAVE_INFO',
  SUBMIT = 'SUBMIT',
  SAVE = 'SAVE',
}

export type RenewablePowerContextAction =
  | {
      type: RenewablePowerContextActionType.SAVE_INFO;
      data: object;
    }
  | {
      type: RenewablePowerContextActionType.SUBMIT;
      data: object;
    }
  | {
      type: RenewablePowerContextActionType.SAVE;
      data: any;
    };

interface RenewablePowerContext {
  state: RenewablePowerContextState;
  dispatch: (action: RenewablePowerContextAction) => void;
}

const initialState: RenewablePowerContext = {
  state: {
    greenSource: {
      label: 'Green Future Choice',
      optionType: RenewablePowerOptionType.GREEN_SOURCE,
      isEnable: true,
      isEnrolled: false,
      isSelected: false,
      price: 0.094,
      unit: 'kWh',
    },
    cleanWind: {
      label: 'Green Future Block',
      optionType: RenewablePowerOptionType.CLEAN_WIND,
      isEnable: true,
      isEnrolled: false,
      isSelected: false,
      enrolledBlocks: 0,
      blocks: 0,
      price: 1.88,
      unit: 'block',
    },
    solarSource: {
      label: 'Green Future Solar',
      optionType: RenewablePowerOptionType.SOLAR_SOURCE,
      isEnable: false,
      disableReason: 'sold out',
      isEnrolled: false,
      isSelected: false,
      price: 5,
      unit: 'block',
    },
    habitatSupport: {
      label: 'Habitat Support',
      optionType: RenewablePowerOptionType.HABITAT_SUPPORT,
      isEnable: true,
      isEnrolled: false,
      isSelected: false,
      price: 2.5,
    },
    promoCode: '',
    selectedProgramAnswer: 'Select',
    includeInPromotion: true,
    companyName: '',
    isSubmitted: false,
  },
  dispatch: (action: RenewablePowerContextAction) => {
    return;
  },
};

// eslint-disable-next-line no-redeclare
export const RenewablePowerContext = createContext<RenewablePowerContext>(
  initialState,
);

const { Provider } = RenewablePowerContext;

const reducer = (
  state: RenewablePowerContextState,
  action: RenewablePowerContextAction,
): RenewablePowerContextState => {
  switch (action.type) {
    case RenewablePowerContextActionType.SAVE:
      const {
        data: {
          blocks,
          companyName,
          includeInPromotion,
          isCleanWind,
          isGreenSource,
          isHabitatSupport,
          isSolarSource,
          promoCode,
          selectedAnswer,
        },
      } = action;

      state.cleanWind.blocks = blocks;
      state.cleanWind.blocks = parseInt(blocks);
      state.companyName = companyName;
      state.greenSource.isSelected = isGreenSource;
      state.habitatSupport.isSelected = isHabitatSupport;
      state.solarSource.isSelected = isSolarSource;
      state.promoCode = promoCode;
      state.includeInPromotion = includeInPromotion;
      state.selectedProgramAnswer = selectedAnswer;
      state.cleanWind.isSelected = isCleanWind;

      return { ...state };
    case RenewablePowerContextActionType.SUBMIT:
      state.isSubmitted = true;
      return { ...state };
    default:
      return state;
  }
};

interface RenewableInitialStateData {
  data: RenewablePower;
  isSmallBusiness: boolean;
}

function configureRenewableInitialState(props: RenewableInitialStateData) {
  const renewableCurrentState = cloneDeep(initialState.state);

  renewableCurrentState.greenSource.isEnrolled = renewableCurrentState.greenSource.isSelected =
    props.data?.isGreenSourceEnrolled || false;
  renewableCurrentState.cleanWind.isEnrolled = renewableCurrentState.cleanWind.isSelected =
    props.data?.isCleanWindEnrolled || false;
  renewableCurrentState.habitatSupport.isEnrolled = renewableCurrentState.habitatSupport.isSelected =
    props.data?.isHabitatSupportEnrolled || false;
  renewableCurrentState.solarSource.isEnrolled = renewableCurrentState.solarSource.isSelected =
    props.data?.isGreenFutureSolarEnrolled || false;

  renewableCurrentState.cleanWind.enrolledBlocks = renewableCurrentState.cleanWind.blocks =
    props.data?.cleanWindBlocks || 0;
  renewableCurrentState.solarSource.enrolledBlocks = renewableCurrentState.solarSource.blocks =
    props.data?.greenFutureSolarBlocks || 0;

  renewableCurrentState.includeInPromotion = props.isSmallBusiness;

  return renewableCurrentState;
}

export const RenewablePowerContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(
    reducer,
    props,
    configureRenewableInitialState,
  );

  return <Provider value={{ state, dispatch }}>{props.children}</Provider>;
};
