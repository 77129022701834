import React, { FC, ReactNode } from 'react';
import { Grid, Typography, Box, makeStyles, Theme } from '@material-ui/core';

import { ArrowForwardIos } from '@material-ui/icons';
import colors from '../../themes/main-colors';
import { useTheme } from '@material-ui/core/styles';
interface ProgramCardProps {
  title: ReactNode;
  icon?: ReactNode;
  description?: ReactNode;
  onClick?: () => void;
  className?: string;
}

export const ProgramCard: FC<ProgramCardProps> = ({
  title,
  icon = null,
  description = '',
  onClick = () => true,
  className = '',
}) => {
  const theme = useTheme();
  const paperCard = {
    cursor: 'pointer',
    padding: theme.spacing(3, 3),
    border: `1px solid ${colors.lightGray4}80`,
  };
  const discriptionPaperCard = {
    marginTop: theme.spacing(1),
    backgroundColor: colors.lightGray6,
    cursor: 'pointer',
    padding: theme.spacing(1, 2),
  };
  return (
    <Grid
      container
      onClick={onClick}
      className={className}
      spacing={0}
      css={className ? { ...paperCard } : { ...discriptionPaperCard }}
    >
      {icon && (
        <Grid
          item
          container
          xs={2}
          md={2}
          alignItems="center"
          justify="flex-start"
        >
          {icon}
        </Grid>
      )}

      <Grid item container xs={icon ? 10 : 12} md={icon ? 10 : 12}>
        <Grid
          item
          container
          justify="space-between"
          css={{ flexWrap: 'nowrap' }}
        >
          <Box>
            <Typography
              css={{
                color: colors.sparkBlue,
                fontWeight: 700,
                fontSize: '20px',
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              component="div"
              css={{ fontSize: '16px' }}
            >
              {description}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" marginTop={'4px'}>
            <ArrowForwardIos
              css={{ fontSize: 20 }}
              fontWeight={700}
              htmlColor={colors.sparkBlue}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};
