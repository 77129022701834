import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '../buttons';
import OtpInputBox from '../otp-input-box';
import { auth } from '../../firebase';
import { enrollUser } from './firebase.helper';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import { useTranslation } from '../../hooks/useTranslation';
import { FirebaseError } from 'firebase/app';
import { AuthErrorCodes } from 'firebase/auth';
import Backdrop from '../backdrop';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

type Props = {
  phoneNumber: string;
  verificationCodeId: string;
  onVerify: () => void;
};

const Enrollment = ({ phoneNumber, verificationCodeId, onVerify }: Props) => {
  const classes = useStyles();
  const [otp, setOtp] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const notificationContext = useContext(NotificationsContext);

  const { t, inlineRichT } = useTranslation();

  const verifyCode = async () => {
    if (otp) {
      if (!auth.currentUser || !verificationCodeId) {
        showNotification(t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'), 'error');
        return false;
      }

      setIsLoading(true);
      try {
        const response = await enrollUser(
          auth.currentUser,
          verificationCodeId,
          otp,
        );
        setIsLoading(false);
        if (response) {
          showNotification(t('TWO_STEP_AUTH_ENROLLMENT_SUCCESS'));
          onVerify();
        } else {
          showNotification(
            t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
            'error',
          );
        }
      } catch (e) {
        if ((e as FirebaseError)?.code === AuthErrorCodes.INVALID_CODE) {
          showNotification(t('TWO_STEP_AUTH_INVALID_ONE_TIME_CODE'), 'error');
        }
        setIsLoading(false);
      }
    } else {
      showNotification(t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'), 'error');
    }
  };

  const showNotification = (
    message: string,
    type: 'error' | 'success' = 'success',
  ) => {
    notificationContext.setState({
      isOpen: true,
      message: message,
      severity: type,
    });
    window.scrollTo(0, 0);
  };

  return (
    <Grid container>
      {isLoading ? <Backdrop forceOpen message={t('LOADING')} /> : null}
      <Grid item xs={12} md={12}>
        <Typography variant={'body1'}>
          {inlineRichT('TWO_STEP_AUTH_ENROLL_INFO', {
            PHONE_NUMBER: phoneNumber,
          })}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <OtpInputBox
          value={otp}
          valueLength={6}
          onChange={e => setOtp(e)}
          error=""
          isCompactMode={false}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Button
          className={classes.button}
          variant={'outlined'}
          color={'secondary'}
          onClick={verifyCode}
          disabled={otp.length !== 6 || isLoading}
          aria-label="enroll"
        >
          {t('ENROLL')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Enrollment;
