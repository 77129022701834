import {
  Box,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import PaperBox from '../../utility/paper-box';
import { Close } from '@material-ui/icons';
import colors from '../../../themes/main-colors';
import { useIsMobile } from '../../../util/style-utils';
import { navigate } from '@reach/router';
import ROUTES from '../../../routes';
import { displayPhoneNumberFormat } from '../../../util/format';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import Link from '../../text-link';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperCard: {
      margin: theme.spacing(2, 0, 0, 2),
      width: '100%',
      boxShadow: 'inset 0px 2px 0px #27788C, 0px 3px 6px #00000029',
      borderRadius: '4px',
    },
    paperbox: {
      margin: theme.spacing(1, 1, 1, 1),
    },
    paperBoxOuterContainer: {
      padding: theme.spacing(2),
    },
    paperBoxContainer: {
      padding: theme.spacing(2, 2, 2, 2),
      width: '100%',
      backgroundColor: colors.gray93,
      marginTop: theme.spacing(2),
    },
    centerContainer: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    callModal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: theme.spacing(4, 4, 3, 3),
      boxShadow: theme.shadows[5],
      backgroundColor: theme.palette.background.paper,
      borderRadius: '5px',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '95%',
        minWidth: '80%',
        padding: theme.spacing(4, 3, 3, 3),
        overflow: 'auto',
      },
      overflow: 'auto',
      maxHeight: '95%',
    },
    callModalTitle: {
      fontSize: '20px',
    },
    modal: {
      position: 'absolute',
      width: '100%',
      maxWidth: '450px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50% , -50%)',
      outline: 0,
      [theme.breakpoints.down('xs')]: {
        maxWidth: '95%',
      },
    },
    button: {
      background: colors.orange,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
    },
    textMoney: {
      fontWeight: 'bold',
    },
    boxSize: {
      width: '49%',
    },
    mobileBoxSize: {
      width: '99%',
    },
    diaplyTopMargin: {
      marginTop: theme.spacing(1),
    },
    vdivider: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    numberTextOnMobile: {
      fontWeight: 'bold',
      fontSize: '27px',
      paddingRight: '16px',
      textAlign: 'center',
    },
    numberText: {
      fontWeight: 'bold',
      fontSize: '70px',
      paddingRight: '16px',
      textAlign: 'center',
    },
  }),
);

interface Props {
  path?: string;
}
const ReconnectServiceConfirmationContent: FunctionComponent<Props> = ({}) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const { customer } = useAccountCustomer();
  const isMobile = useIsMobile();
  const [showHowTOBreakerModal, setShowHowTOBreakerModal] = useState(false);
  const onShowHowTOBreakerModal = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowHowTOBreakerModal(true);
  };

  const primaryContact = customer?.contactDetails?.find(contact => {
    return contact?.contactType === 'PNP';
  });
  const pnpPhone = primaryContact?.contactValue?.replace(/\D/g, '') || '';
  const mobileContact = customer?.contactDetails?.find(contact => {
    return contact?.contactType === 'MOB';
  });
  const mobilePhone = mobileContact?.contactValue?.replace(/\D/g, '') || '';
  return (
    <>
      <PaperBox>
        <Grid container>
          <Grid item justify='flex-start' style={{ marginLeft: '1em', marginBottom: '1em' }}>
            <Typography variant={'h2'}>{t('WHAT_HAPPENS_NOW')}</Typography>
          </Grid>          
          <Grid item xs={12} style={{ marginLeft: '1em' }}>
            <Typography variant={'body2'}>
              {t('RECONNECT_SERVICE_CONFIRMATION_SUB_TEXT')}
            </Typography>
          </Grid>
        </Grid>
        <PaperBox style={{ marginLeft: '1em' }}>
          <Grid
            container
            direction="row"
            alignItems={!isMobile ? 'center' : 'flex-start'}
          >
            <Grid item xs={2}>
              <div
                className={
                  isMobile ? classes.numberTextOnMobile : classes.numberText
                }
              >
                {t('NUMBER_1')}
              </div>
            </Grid>
            <Grid item xs={10}>
              <Typography variant={'h3'} style={{ marginBottom: '1em' }}>
                {t('CONFIRM_CONTACT_INFO')}
              </Typography>
              <Typography variant={'body2'}>
                {t('CORRECT_PHONE_TEXT')}
              </Typography>
              <Box
                className={classes.paperBoxContainer}
                style={{ marginBottom: '1.5em' }}
              >
                <Grid container direction="row" spacing={1}>
                  <Grid item xs={isMobile ? 12 : 3}>
                    {t('PRIMARY_PHONE_TEXT')}
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 12 : 9}
                    className={classes.textMoney}
                  >
                    {pnpPhone
                      ? displayPhoneNumberFormat(pnpPhone)
                      : t('RECONNECT_CONTENT_PRIMARY_PHONE')}
                  </Grid>
                  <Grid item xs={isMobile ? 12 : 3}>
                    {t('MOBILE_PHONE_TEXT')}
                  </Grid>
                  <Grid
                    item
                    xs={isMobile ? 12 : 9}
                    className={classes.textMoney}
                  >
                    {mobilePhone
                      ? displayPhoneNumberFormat(mobilePhone)
                      : t('NOT_SPECIFIED')}
                  </Grid>
                </Grid>
              </Box>
              <Grid item xs={12}>
                <Link to={ROUTES.UPDATE_INFORMATION}>
                  {t('UPDATE_CONTACT_TEXT')}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </PaperBox>

        <PaperBox style={{ marginLeft: '1em' }}>
          <Grid
            container
            direction="row"
            alignItems={!isMobile ? 'center' : 'flex-start'}
          >
            <Grid item xs={2}>
              <div
                className={
                  isMobile ? classes.numberTextOnMobile : classes.numberText
                }
              >
                {t('NUMBER_2')}
              </div>
            </Grid>
            <Grid item xs={10}>
              <Typography variant={'h3'} style={{ marginBottom: '1em' }}>
                {t('TO_AVOID_TEXT')}
              </Typography>
              <Typography variant={'body2'} style={{ marginBottom: '1em' }}>
                {t('TURN_OFF_TEXT')}
              </Typography>
              <Grid item xs={12}>
                <Link onClick={onShowHowTOBreakerModal}>
                  {t('HOW_TO_FIND_BREAKER')}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </PaperBox>

        <PaperBox style={{ marginLeft: '1em' }}>
          <Grid
            container
            direction="row"
            alignItems={!isMobile ? 'center' : 'flex-start'}
          >
            <Grid item xs={2}>
              <div
                className={
                  isMobile ? classes.numberTextOnMobile : classes.numberText
                }
              >
                {t('NUMBER_3')}
              </div>
            </Grid>
            <Grid item xs={10}>
              <Typography variant={'h3'} style={{ marginBottom: '1em' }}>
                {t('OPTION_3_TEXT')}
              </Typography>
              <Typography variant={'body2'} style={{ marginBottom: '1em' }}>
                {t('OPTION_3_BODY')}
              </Typography>
            </Grid>
          </Grid>
        </PaperBox>

        <PaperBox style={{ marginLeft: '1em' }}>
          <Grid
            container
            direction="row"
            alignItems={!isMobile ? 'center' : 'flex-start'}
          >
            <Grid item xs={2}>
              <div
                className={
                  isMobile ? classes.numberTextOnMobile : classes.numberText
                }
              >
                {t('NUMBER_4')}
              </div>
            </Grid>
            <Grid item xs={10}>
              <Typography variant={'h3'} style={{ marginBottom: '1em' }}>
                {t('OPTION_4_TEXT')}
              </Typography>
              <Typography variant={'body2'} style={{ marginBottom: '1em' }}>
                {t('OPTION_4_BODY')}
              </Typography>
              <Grid item xs={12}>
                <Link to={ROUTES.NEED_MORE_TIME_TO_PAY_PLAN_OPTIONS}>
                  {t('SEE_YOUR_PLAN_LINK')}
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </PaperBox>
      </PaperBox>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={showHowTOBreakerModal}
        onClose={() => setShowHowTOBreakerModal(false)}
      >
        <div className={classes.callModal}>
          <div className={'closeButton'}>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setShowHowTOBreakerModal(false)}
            >
              <Close />
            </IconButton>
          </div>
          <Box
            mt={1}
            display={'flex'}
            flexDirection={'row'}
            alignItems={'baseline'}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant={'h4'} style={{ marginRight: '.75rem' }}>
                  {richT('HOW_TO_FIND_BREAKER')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant={'body2'} style={{ marginRight: '.75rem' }}>
                  {richT('HOW_TO_FIND_BREAKER_MODAL_TEXT')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </div>
      </Modal>
    </>
  );
};
export default ReconnectServiceConfirmationContent;
