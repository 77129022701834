/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import {
  Box,
  Button,
  FormHelperText,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import FileUploaderWithOCR from '../../../../pge-plus-common/FileUploaderWithOCR';
import { EvPulse, UpdateSectionErrorCode } from '../rebateOnlyForm.types';
import useFormState from '../../../../../hooks/useFormState';
import { eligibleForEvPulseValidateFunction } from '../rebateOnlyForm.rules';
import TextField from '../../../../text-field';
import colors from '../../../../../themes/main-colors';
import Modal from '../../../../pge-plus-common/Modal';
import { ProceedOrCancelButton } from '../../../../pge-plus-common/StepperForm';
import routes from '../../../../../routes';
import { SaveAndComeBackLink } from '../../../../pge-plus-common/SaveAndComeBackLink';
import { useRebateOnlyFormState } from '../../../../../providers/RebateOnlyFormStateProvider';
import useWrapWithLoader from '../../../../../hooks/useWrapWithLoading';
import { rebateConfirmERFormStateAdapter } from '../rebateOnlyFormState.adapter';
import {
  ApplicationErSectionType,
  OcrFieldNames,
  SectionErRebateConfirm,
} from '../../../../../__generated__/pgeplus-types';
import useUpdateERSections from '../../../../../hooks/pge-plus/useUpdateERSections';
import useUtil from '../../../../need-more-time-to-pay/useUtil';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageTitle: {
      fontSize: '16px !important',
      padding: '0px !important',
      color: `${colors.sparkBlue} !important`,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: 'unset !important',
      },
    },
    errorMessage: {
      fontSize: '1rem !important',
      letterSpacing: '1px',
    },
  }),
);
const EligibleForEvPulse: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const theme = useTheme();
  const subText1 = {
    fontSize: 20,
    marginBottom: theme.spacing(2),
  };
  const boldText = {
    fontWeight: 700,
  };
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_ELIGIBLE_FOR_EVPLUS,
  );
  const { content: fileUploaderText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_UPLOAD_PHOTOS,
  );
  const [ocrExtractionStatus, setOcrExtractionStatus] = useState<
    string | undefined
  >();
  const classes = useStyles(props);
  const [openExamplePhotoModal, setOpenExamplePhotoModal] = useState<boolean>(
    false,
  );
  const {
    formState: { rebateConfirm, application },
    getSectionData,
    updateFormState,
  } = useRebateOnlyFormState();
  const form = useFormState(
    rebateConfirm.evPulse!, //TODO: This should be taken from the global app state
    {
      validate: eligibleForEvPulseValidateFunction(),
      validationContext: {
        content,
      },
    },
  );
  const { wrapWithLoader } = useWrapWithLoader();
  const sectionDataRebateConfirm: SectionErRebateConfirm = getSectionData(
    ApplicationErSectionType.ErRebateConfirm,
  );
  const { updateSectionRebateConfirm } = useUpdateERSections();
  const { setErrorNotification } = useUtil();
  const onSubmit = wrapWithLoader(async (data: EvPulse) => {
    try {
      const response = await updateSectionRebateConfirm(
        rebateConfirmERFormStateAdapter().fromFormState(
          {
            ...rebateConfirm,
            evPulse: data,
          },
          application?.id!,
          sectionDataRebateConfirm?.id,
        ),
      );

      //Store the data in global store
      if (response.data?.updateSectionERRebateConfirm?.section.data) {
        const updatedValues = rebateConfirmERFormStateAdapter().toFormState(
          response.data?.updateSectionERRebateConfirm?.section.data,
        );
        updateFormState('rebateConfirm', updatedValues);
      }

      if (
        !response.data?.updateSectionERRebateConfirm.section?.errors?.length
      ) {
        void navigate(routes.PGE_PLUS_REBATE_ONLY_CONNECT_TESLA, {
          state: {
            backRoute: true,
          },
        });
      } else {
        const ineligibleErrors = response.data?.updateSectionERRebateConfirm.section.errors.filter(
          reason => reason.code === UpdateSectionErrorCode.VINAlreadyEnrolled,
        );
        if (ineligibleErrors.length > 0) {
          void navigate(routes.PGE_PLUS_REBATE_ONLY_INELIGIBLE, {
            state: {
              message: 'REBATE_ONLY_SMART_CHARGING_INELIGIBLE',
            },
          });
          return;
        } else {
          throw new Error();
        }
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      const response = await updateSectionRebateConfirm({
        ...rebateConfirmERFormStateAdapter().fromFormState(
          {
            ...rebateConfirm,
            evPulse: form.values,
          },
          application?.id!,
          sectionDataRebateConfirm?.id,
        ),
        saveAndComeBack: true,
      });
      if (
        response?.errors ||
        response.data?.updateSectionERRebateConfirm?.validation?.errors ||
        response.data?.updateSectionERRebateConfirm?.section?.errors.length !==
          0
      ) {
        throw new Error();
      } else {
        void navigate(routes.ACCOUNT);
      }
    } catch {
      setErrorNotification(true);
    }
  });

  return (
    <Box>
      <form
        onSubmit={form.submit(values => onSubmit(values), console.log)}
        noValidate
      >
        <Box>
          <Typography css={{ ...subText1 }}>
            {content.get('PGE_PLUS_REBATE_ONLY_ELIGIBLE_FOR_EVPLUS_SUBTEXT1')}
          </Typography>
          <Typography css={{ ...subText1, ...boldText }}>
            {content.get('PGE_PLUS_REBATE_ONLY_ELIGIBLE_FOR_EVPLUS_SUBTEXT2')}
          </Typography>
        </Box>
        <Box mt={2}>
          {!form.values?.vinImage && (
            <Box mt={3}>
              <Button
                className={classes.imageTitle}
                onClick={() => setOpenExamplePhotoModal(true)}
              >
                {content.get('EVPULSE_ENROLLMENT_VIN_SAMPLE_LINK_TEXT')}
              </Button>
              <Modal
                open={openExamplePhotoModal}
                onClose={() => setOpenExamplePhotoModal(false)}
                title={content.get('MODEL_SAMPLE_PHOTO_TITLE')}
                showCloseIcon={true}
                modalStyles={{ width: 600 }}
              >
                {' '}
                <Box mt={3}>
                  <img
                    css={{
                      width: '100%',
                      maxHeight: '350px',
                      objectFit: 'cover',
                    }}
                    src={content.get('SAMPLE_IMAGE_URL')}
                  />
                </Box>
              </Modal>
            </Box>
          )}
          <FileUploaderWithOCR
            placeholder={fileUploaderText.get('UPLOAD_A_PHOTO')}
            reuploadLabel={fileUploaderText.get('REUPLOAD_PHOTO')!}
            ocrType={OcrFieldNames.Vin}
            onComplete={(key, extractedText, message, status) => {
              void form.setValue('vinImage', { id: key });
              void form.setValue('vin', extractedText);
              setOcrExtractionStatus(status);
            }}
            image={form.values?.vinImage}
          />
          {form.errors?.vinImage && (
            <FormHelperText className={classes.errorMessage} error>
              {form.errors?.vinImage}
            </FormHelperText>
          )}

          {form.values?.vinImage && (
            <Box
              css={{
                marginBottom: theme.spacing(1),
                '& .MuiFormControl-root': {
                  background: 'transparent',
                },
                '& .MuiInputBase-input': {
                  background: '#FFF',
                },
              }}
            >
              {ocrExtractionStatus && ocrExtractionStatus === 'fail' && (
                <FormHelperText
                  className={classes.errorMessage}
                  error
                  component="div"
                >
                  {fileUploaderText.get('OCR_FAIL_MESSAGE')}
                </FormHelperText>
              )}
              <Typography css={{ ...subText1, ...boldText }}>
                {ocrExtractionStatus && ocrExtractionStatus === 'success'
                  ? content.get('REBATE_ONLY_EDIT_VIN_HEADER')
                  : content.get('VIN')}
              </Typography>
              <TextField
                css={{
                  background: 'transparent',
                  '.MuiInputBase-formControl': {
                    background: colors.white,
                  },
                  width: '100%',
                }}
                name={'vin'}
                {...form.props('vin')}
              />

              {ocrExtractionStatus && ocrExtractionStatus === 'success' && (
                <Typography css={{ marginTop: 8 }}>
                  {fileUploaderText.get('VIN_SUCCESS_MESSAGE')}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box>
          <ProceedOrCancelButton
            back={routes.PGE_PLUS_REBATE_ONLY_CONNECT_CHARGER}
            disable={
              (form.values?.vinImage === undefined ||
                form.values?.vinImage === null) &&
              (form.values?.vin === undefined || form.values?.vin === null)
            }
          />
        </Box>
        <SaveAndComeBackLink
          content={content}
          onSaveAndExit={handleSaveAndExit}
        />
      </form>
    </Box>
  );
};

export default EligibleForEvPulse;
