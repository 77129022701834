import { Divider, Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import PaperBox from '../../utility/paper-box';
import useStyles from '../styles';
import ROUTES from '../../../routes';
import PGEButton from '../../buttons';
import { navigate } from 'gatsby';
import TextLink from '../../text-link';
import { useIsMobile } from '../../../util/style-utils';
import { TimeOfDayInfo } from '../../../__generated__/pge-types';
import TimeOfDaySideBySideComparision from '../enroll/TimeOfDaySideBySideComparision';
import TimeOfDayNoEnoughData from '../enroll/TimeOfDayNoEnoughData';
import TimeOfDaySavings from '../enroll/TimeOfDaySavings';

interface Props {
  path?: String;
  timeOfDayInfo?: TimeOfDayInfo | null;
}

const TimeOfDayManage: FunctionComponent<Props> = props => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { timeOfDayInfo } = props;

  const [savingsHeader, setSavingsHeader] = useState<string>(
    t('YOUR_SAVINGS_ON_TIME_OF_DAY'),
  );

  useEffect(() => {
    if ((timeOfDayInfo?.timeOfDaySummaryInfo?.deltaBasicToTodAmount || 0) < 0) {
      setSavingsHeader(t('WHAT_YOU_WOULD_HAVE_SAVED'));
    } else {
      setSavingsHeader(t('YOUR_SAVINGS_ON_TIME_OF_DAY'));
    }
  }, [timeOfDayInfo]);

  return (
    <Grid
      container
      className={classes.root}
      spacing={3}
      style={{ marginTop: '1em' }}
    >
      <PaperBox>
        {timeOfDayInfo?.timeOfDaySummaryInfo &&
        timeOfDayInfo?.timeOfDaySummaryInfo.timeofDaySummaryDetails?.length ? (
          <>
            <Grid item xs={12}>
              <Typography className={classes.todHeader} variant={'h4'}>
                {t('YOUR_PERSONALIZED_SAVING_COMPARISON')}
              </Typography>
            </Grid>
            <Grid item>
              <TimeOfDaySideBySideComparision
                timeOfDayInfo={timeOfDayInfo}
                sideBySideHeader={t('AMOUNTS_SHOWN_REFLECT_YOUR_ENERGY_USAGE')}
                footer={t('AMOUNTS_REFLECT_WHAT_YOU_ACTUALLY_SPENT')}
              />
              <TimeOfDaySavings
                timeOfDayInfo={timeOfDayInfo}
                savingHeader={savingsHeader}
                footer={t('AMOUNTS_REFLECT_YOUR_ACTUAL_BILL')}
              />
            </Grid>
          </>
        ) : (
          <TimeOfDayNoEnoughData
            isEnrolled={true}
            notEnoughDataHeader={t('YOUR_PERSONALIZED_SAVING_COMPARISON')}
            notEnoughDataSubHeader={t(
              'UNFORTUNATELY_WE_ARE_NOT_ABLE_TO_DISPLAY_YOU_SAVINGS',
            )}
            firstDescription={t(
              'ONCE_YOU_HAVE_BEEN_ON_TIME_OF_DAY_FOR_FULL_MONTH',
            )}
          />
        )}
      </PaperBox>
      <PaperBox>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={'h2'} style={{ marginBottom: '1em' }}>
              {t('ENROLLED_TIME_OF_DAY_TITLE')}
            </Typography>

            <Typography variant={'body2'} style={{ marginBottom: '1em' }}>
              {t('ENROLLED_TIME_OF_DAY_BODY')}
            </Typography>
            <Divider />
            <Typography
              variant={'h4'}
              style={{ marginTop: '1em', marginBottom: '1em' }}
            >
              {t('ENROLLED_TOD_MARK_CHANGE_TITLE')}
            </Typography>

            <Typography variant={'body2'} component="span">
              {t('ENROLLED_TOD_MARK_CHANGE_BODY')}
              <TextLink
                to={ROUTES.TIMEOFDAY_UNENROLL}
                data-testid={'click-here-link'}
                style={{
                  fontWeight: 'bold',
                }}
              >
                {t('CLICK_HERE')}
              </TextLink>
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify={isMobile ? 'center' : 'flex-end'}
          spacing={1}
          wrap="wrap-reverse"
        >
          <Grid item xs={10} md="auto">
            <PGEButton
              size={'large'}
              onClick={async () => {
                await navigate(ROUTES.ACCOUNT);
              }}
            >
              <Typography variant={'h4'} noWrap>
                {t('DONE')}
              </Typography>
            </PGEButton>
          </Grid>
        </Grid>
      </PaperBox>
    </Grid>
  );
};
export default TimeOfDayManage;
