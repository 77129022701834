/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Grid, Typography, useTheme, Link } from '@material-ui/core';

type Props = {
  label: string | undefined;
  value: string | undefined;
  editable?: boolean;
  editLabel?: string;
  onEdit?: () => void;
};

const ContactInfoRow = ({
  label,
  value,
  editable = false,
  editLabel = '',
  onEdit,
}: Props) => {
  const theme = useTheme();

  return (
    <Grid container css={{ margin: '20px 0' }}>
      <Grid item xs={3}>
        <Typography css={{ fontSize: '16px' }}>{label}:</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography
          css={{ fontWeight: 700, wordBreak: 'break-all', fontSize: '16px' }}
        >
          {value}
        </Typography>

        {editable && (
          <Typography
            css={{
              fontWeight: 700,
              wordBreak: 'break-all',
              marginTop: 5,
              fontSize: '16px',
            }}
          >
            <Link
              href="#"
              onClick={e => {
                e.preventDefault();
                onEdit && onEdit();
              }}
            >
              {editLabel}
            </Link>
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default ContactInfoRow;
