import { Direction } from '../../../__generated__/pge-types';
import { PageSize } from '../../pagination/types';
import { makeActions, createReducer } from '../../../util/reducer-utils';

export type GroupListState = {
  page: number;
  query: string;
  pageSize: PageSize;
  sortBy: GroupSort;
  sortOrder: Direction;
};

export enum GroupSort {
  AToZ = 'A_TO_Z',
  ZToA = 'Z_TO_A',
  Grouptype = 'GROUPTYPE',
  Numberofaccounts = 'NUMBEROFACCOUNTS',
}

const initialState: GroupListState = {
  page: 1,
  pageSize: 5,
  query: '',
  sortBy: GroupSort.AToZ,
  sortOrder: Direction.Asc,
};

const actions = makeActions({
  setPage: (page: number) => ({ page }),
  setPageSize: (pageSize: PageSize) => ({ pageSize }),
  setQuery: (query: string) => ({ query, page: 1 }),
  setSortBy: (sortBy: GroupSort) => ({ sortBy }),
  setSortOrder: (sortOrder: Direction) => ({ sortOrder }),
  resetParams: ({
    sortOrder = initialState.sortOrder,
    sortBy = initialState.sortBy,
    page = initialState.page,
    query = initialState.query,
    pageSize = initialState.pageSize,
  } = {}) => ({ sortOrder, sortBy, page, query, pageSize }),
});

const reducer = createReducer<GroupListState, typeof actions>({
  setPage: (state, { payload: { page } }) => ({ ...state, page }),
  setPageSize: (state, { payload: { pageSize } }) => ({ ...state, pageSize }),
  setQuery: (state, { payload: { query, page } }) => ({
    ...state,
    query,
    page,
  }),
  setSortBy: (state, { payload: { sortBy } }) => ({ ...state, sortBy }),
  setSortOrder: (state, { payload: { sortOrder } }) => ({
    ...state,
    sortOrder,
  }),
  resetParams: (_, { payload }) => payload,
});

export default {
  initialState,
  reducer,
  actions,
};
