import {
  Box,
  Card,
  CardContent,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import routes from '../../routes';
import colors from '../../themes/main-colors';
import theme from '../../themes/theme';
import ErrorOverlay from '../error/error-overlay';
import { Loader } from '../my-programs/components/Loader';
import { MorePrograms } from './components/MorePrograms';
import { NoEnrollments } from './components/NoEnrollments';
import { PanelSection } from './components/PanelSection';
import { ProgramCategory } from './components/ProgramCategory';
import { SavingsPanelSection } from './components/SavingsPanelSection';
import { useProgramsPanel } from './useProgramsPanel';

const ENERGY_SHIFT_PANEL_SECTIONS_LIMIT = 2;
const RENEWABLES_PANEL_SECTIONS_LIMIT = 3;

const useStyles = (error?: boolean, loading?: boolean) =>
  makeStyles(() => {
    return createStyles({
      container: {
        padding: theme.spacing(2),
        minHeight: theme.spacing(50),
        ...(error
          ? {}
          : {
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }),
        ...(loading
          ? {
              justifyContent: 'center',
            }
          : {}),
        '&:last-child': {
          paddingBottom: theme.spacing(2),
        },
      },
      loaderTitle: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
      },
      warningIcon: {
        color: colors.errorRed,
      },
      title: {
        fontSize: theme.spacing(2.5),
        lineHeight: theme.typography.pxToRem(28),
        letterSpacing: '0.720px',
        fontFamily: 'Forma-DJR-Display',
        color: colors.lightCyan,
        marginBottom: theme.spacing(1.5),
      },
      footer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      footerLink: {
        textDecoration: 'none',
        color: colors.noirBlur,
      },
      footerEndIcon: {
        display: 'block',
      },
    });
  });

export function ProgramsPanel() {
  const { data, loading, error } = useProgramsPanel();
  const classes = useStyles(Boolean(error), loading)();
  const { t } = useTranslation();

  if (loading) {
    return (
      <Card>
        <Typography variant="h2" className={classes.loaderTitle}>
          {t('PROGRAM_ENROLLMENTS')}
        </Typography>
        <CardContent className={classes.container}>
          <Loader />
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card>
        <CardContent className={classes.container}>
          <ErrorOverlay title={t('PROGRAM_ENROLLMENTS')} />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardContent className={classes.container}>
        {Boolean(data?.energyShifting?.length) && (
          <ProgramCategory>
            <Typography className={classes.title}>
              {t('MY_ENERGY_SHIFTING_PROGRAMS')}
            </Typography>
            <SavingsPanelSection
              savingsOnBill={data?.onBillFlexLoadEarnings ?? ''}
              ytdSavings={data?.ytdFlexLoadEarnings ?? ''}
            />
            {data?.energyShifting
              ?.slice(0, ENERGY_SHIFT_PANEL_SECTIONS_LIMIT)
              ?.map(program => (
                <PanelSection
                  key={program?.programName}
                  program={program}
                  sectionBgColor={colors.riverBlue}
                />
              ))}
            {(data?.energyShifting?.length ?? 0) >
              ENERGY_SHIFT_PANEL_SECTIONS_LIMIT && (
              <MorePrograms totalEnrolled={data?.energyShifting?.length ?? 0} />
            )}
          </ProgramCategory>
        )}
        {Boolean(data?.renewables?.length) && (
          <ProgramCategory>
            <Typography className={classes.title}>
              {t('MY_RENEWABLE_PROGRAMS')}
            </Typography>
            {data?.renewables
              ?.slice(0, RENEWABLES_PANEL_SECTIONS_LIMIT)
              ?.map(program => (
                <PanelSection
                  key={program?.programName}
                  program={program}
                  sectionBgColor={colors.chromeWhite}
                />
              ))}
            {(data?.renewables?.length ?? 0) >
              RENEWABLES_PANEL_SECTIONS_LIMIT && (
              <MorePrograms totalEnrolled={data?.renewables?.length ?? 0} />
            )}
          </ProgramCategory>
        )}
        {!Boolean(data?.energyShifting?.length) && (
          <ProgramCategory>
            <NoEnrollments programCategory="energy-shifting" />
          </ProgramCategory>
        )}
        {!Boolean(data?.renewables?.length) && (
          <ProgramCategory>
            <NoEnrollments programCategory="renewables" />
          </ProgramCategory>
        )}
        <Box className={classes.footer}>
          <Link
            to={routes.ADDITIONAL_PROGRAMS_AND_OFFERING}
            className={classes.footerLink}
          >
            {t('VIEW_MY_PROGRAM_REC')}
          </Link>
          <KeyboardArrowRight className={classes.footerEndIcon} />
        </Box>
      </CardContent>
    </Card>
  );
}
