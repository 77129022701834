import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import AccountDropdown from '../account-dropdown';
import colors from '../../themes/main-colors';
import { graphql, useStaticQuery } from 'gatsby';
import { LanguageContext } from '../../providers/LanguageProvider';
import { useAdditionalProgramsSmartCards } from './useAdditionalProgramsSmartCards';
import Backdrop from '../backdrop';
import SmartCardSection, {
  SmartCardColors,
} from '../smart-card-section/SmartCardSection';
import RichText from '../rich-text';
import { useAccountCustomerDashboard } from '../../hooks/useAccountCustomerDashboard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2, 0),
    },
    heading: {
      fontSize: theme.spacing(4.5),
      lineHeight: theme.typography.pxToRem(28),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
      color: colors.lightCyan,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        marginBottom: '16px',
      },
    },

    accountBox: {
      margin: '16px 0px 32px',
      [theme.breakpoints.down('sm')]: {
        margin: '16px 0px 20px',
      },
    },
  }),
);

type Props = {
  path?: string;
};

export default function AdditionalPrograms(_: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  const { showLegacyUI } = useAccountCustomerDashboard();

  const { page } = useStaticQuery(graphql`
    query AdditionalProgramsPage {
      page: allContentfulApplicationPage(
        filter: { contentful_id: { eq: "1qJi94VR1ANQZyOVeME0EP" } }
      ) {
        nodes {
          node_locale
          contentEntries {
            ... on ContentfulSecureSiteRichText {
              __typename
              contentful_id
              richText {
                raw
                references {
                  __typename
                  ...FileLink
                  ...ExternalLink
                  ...BasicLink
                  ...ArticleLink
                  ...ListLink
                  ...NewsLandingLink
                  ...NewsLink
                  ...PromotionalLink
                  ...HomeLink
                  ...MediaLink
                  ...OverlayLink
                  ...Col2Link
                  ...ApplicationPageLink
                }
              }
            }
            ... on ContentfulSmartCardSection {
              __typename
              contentful_id
              sectionHeader
              sectionBody {
                __typename
                raw
                references {
                  __typename
                  ...FileLink
                  ...ExternalLink
                  ...BasicLink
                  ...ArticleLink
                  ...ListLink
                  ...NewsLandingLink
                  ...NewsLink
                  ...PromotionalLink
                  ...HomeLink
                  ...MediaLink
                  ...OverlayLink
                  ...Col2Link
                  ...ApplicationPageLink
                }
              }
              cardGrouping {
                __typename
                ... on ContentfulSmartCard {
                  __typename
                  contentful_id
                  header
                  subHeader
                  copy {
                    __typename
                    raw
                    references {
                      __typename
                      ...FileLink
                      ...ExternalLink
                      ...BasicLink
                      ...ArticleLink
                      ...ListLink
                      ...NewsLandingLink
                      ...NewsLink
                      ...PromotionalLink
                      ...HomeLink
                      ...MediaLink
                      ...OverlayLink
                      ...Col2Link
                      ...ApplicationPageLink
                    }
                  }
                  image {
                    file {
                      url
                    }
                  }
                  mobileImage {
                    file {
                      url
                    }
                  }
                  button {
                    __typename
                    contentful_id
                    button {
                      __typename
                      buttonType
                      buttonText
                      buttonIcon {
                        file {
                          contentType
                          url
                          fileName
                        }
                      }
                    }
                    buttonLink {
                      __typename
                      ...pageArticleLink
                      ...pageBasicLink
                      ...pageHomeLink
                      ...pageListLink
                      ...pageNewsReleaseLink
                      ...ModuleOverlay
                      ...pagePromotionalLink
                      ...wrapperExternalLink
                      ...MediaAsset
                      ...ApplicationPageLink
                    }
                  }
                  backgroundColor
                  finePrint
                  smartCardType
                }
                ... on ContentfulCampaignCard {
                  __typename
                  contentful_id
                  header
                  subHeader
                  copy {
                    __typename
                    raw
                    references {
                      __typename
                      ...FileLink
                      ...ExternalLink
                      ...BasicLink
                      ...ArticleLink
                      ...ListLink
                      ...NewsLandingLink
                      ...NewsLink
                      ...PromotionalLink
                      ...HomeLink
                      ...MediaLink
                      ...OverlayLink
                      ...Col2Link
                      ...ApplicationPageLink
                    }
                  }
                  image {
                    file {
                      url
                    }
                  }
                  mobileImage {
                    file {
                      url
                    }
                  }
                  optInText
                  optOutText
                  backgroundColor
                  finePrint
                  campaignType
                }
              }
              cardGroupingDisplayType
              standaloneCards {
                ... on ContentfulSmartCard {
                  __typename
                  contentful_id
                  header
                  subHeader
                  copy {
                    __typename
                    raw
                    references {
                      __typename
                      ...FileLink
                      ...ExternalLink
                      ...BasicLink
                      ...ArticleLink
                      ...ListLink
                      ...NewsLandingLink
                      ...NewsLink
                      ...PromotionalLink
                      ...HomeLink
                      ...MediaLink
                      ...OverlayLink
                      ...Col2Link
                      ...ApplicationPageLink
                    }
                  }
                  image {
                    file {
                      url
                    }
                  }
                  mobileImage {
                    file {
                      url
                    }
                  }
                  button {
                    __typename
                    contentful_id
                    button {
                      __typename
                      buttonType
                      buttonText
                      buttonIcon {
                        file {
                          contentType
                          url
                          fileName
                        }
                      }
                    }
                    buttonLink {
                      __typename
                      ...pageArticleLink
                      ...pageBasicLink
                      ...pageHomeLink
                      ...pageListLink
                      ...pageNewsReleaseLink
                      ...ModuleOverlay
                      ...pagePromotionalLink
                      ...wrapperExternalLink
                      ...MediaAsset
                      ...ApplicationPageLink
                    }
                  }
                  backgroundColor
                  finePrint
                  smartCardType
                }
                ... on ContentfulCampaignCard {
                  __typename
                  contentful_id
                  header
                  subHeader
                  copy {
                    __typename
                    raw
                    references {
                      __typename
                      ...FileLink
                      ...ExternalLink
                      ...BasicLink
                      ...ArticleLink
                      ...ListLink
                      ...NewsLandingLink
                      ...NewsLink
                      ...PromotionalLink
                      ...HomeLink
                      ...MediaLink
                      ...OverlayLink
                      ...Col2Link
                      ...ApplicationPageLink
                    }
                  }
                  image {
                    file {
                      url
                    }
                  }
                  mobileImage {
                    file {
                      url
                    }
                  }
                  optInText
                  optOutText
                  backgroundColor
                  finePrint
                  campaignType
                }
              }
            }
          }
        }
      }
    }
  `);
  const [content] = page.nodes.filter(
    (pg: any) => pg.node_locale === (language || 'en'),
  );

  const { enrolledPrograms, loading } = useAdditionalProgramsSmartCards();

  if (loading) {
    return <Backdrop forceOpen message={t('STILL_LOADING')} />;
  }

  return (
    <>
      <Typography className={classes.heading}>
        {t('ADDITIONAL_PROGRAMS_AND_OFFERING')}
      </Typography>
      <Box className={classes.accountBox}>
        <AccountDropdown />
      </Box>
      {showLegacyUI && (
        <Typography variant="h3">
          {t('ADDITIONAL_PROGRAMS_MESSAGE_FOR_NON_RES')}
        </Typography>
      )}
      {!showLegacyUI &&
        content &&
        content?.contentEntries.map((contentEntry: any) => {
          switch (contentEntry.__typename) {
            case 'ContentfulSecureSiteRichText': {
              return (
                <div
                  className={classes.container}
                  key={contentEntry.contentful_id}
                >
                  <RichText>{contentEntry.richText}</RichText>
                </div>
              );
            }
            case 'ContentfulSmartCardSection': {
              return (
                <div
                  className={classes.container}
                  key={contentEntry.contentful_id}
                >
                  <SmartCardSection
                    cardGrouping={
                      enrolledPrograms
                        ? contentEntry.cardGrouping
                            ?.filter(
                              (card: any) =>
                                !enrolledPrograms.includes(card.smartCardType),
                            )
                            .map((card: any) => card)
                        : contentEntry.cardGrouping
                    }
                    standaloneCards={
                      enrolledPrograms
                        ? contentEntry.standaloneCards
                            ?.filter(
                              (card: any) =>
                                !enrolledPrograms.includes(card.smartCardType),
                            )
                            .map((card: any) => {
                              return {
                                ...card,
                                backgroundColor:
                                  SmartCardColors[
                                    (
                                      card.backgroundColor ?? ('' as string)
                                    ).toUpperCase()
                                  ],
                              };
                            })
                        : contentEntry.standaloneCards
                    }
                    contentfulSectionCard={contentEntry}
                  />
                </div>
              );
            }
            default:
              return null;
          }
        })}
    </>
  );
}
