import React, { Dispatch, SetStateAction } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import DatePicker from '../date-picker';
import TextField from '../text-field';
import SSNTextField from '../ssn-text-field';
import { useTranslation } from '../../hooks/useTranslation';
import { statesAndProvincesGrouping } from '../utility/state-dropdown';
import colors from '../../themes/main-colors';
import { BaseFormModel } from '../../hooks/useFormState.types';
import {
  PersonalIdentificationType,
  StateIdType,
  FederalIdentificationType,
} from '../../__generated__/pge-types';

type CustomerIdentityProps = {
  form: BaseFormModel;
  identityError?: string;
  setIdentityError?: Dispatch<SetStateAction<string>>;
};

const CustomerIdentity = ({
  form,
  identityError,
  setIdentityError,
}: CustomerIdentityProps) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} direction="column">
      <Grid item xs={12} md={6}>
        <DatePicker
          style={{ width: '100%' }}
          label={t('DATE_OF_BIRTH')}
          name="birthdate"
          value={form.values.birthdate}
          onBlur={form.onBlur}
          onChange={form.onChange}
          error={Boolean(form.errors.birthdate)}
          helperText={form.errors.birthdate}
        />
      </Grid>
      {Boolean(identityError) && (
        <Grid item xs={12}>
          <Typography variant="body1" style={{ color: colors.roseRed }}>
            {identityError}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container spacing={2} direction="row">
          <Grid item xs={12} md={6}>
            <FormControl
              variant="outlined"
              style={{ width: '100%' }}
              error={Boolean(form.errors.idType)}
            >
              <InputLabel id="identity-type-label">{t('ID_TYPE')}</InputLabel>
              <Select
                inputProps={{
                  'data-testid': 'id-type-select-control',
                }}
                labelId="identity-type-label"
                name="idType"
                value={form.values.idType}
                onChange={async (e: any) => {
                  await Promise.all([
                    form.setValue('idSubType', ''),
                    form.setValue('idValue', ''),
                    form.setValue('idSubTypeState', ''),
                  ]);
                  setIdentityError?.('');
                  form.setError('idSubType', null);
                  form.setError('idValue', null);
                  form.setError('idSubTypeState', null);
                  form.onChange(e);
                }}
                error={Boolean(form.errors.idType)}
                autoWidth
                onBlur={form.onBlur}
                style={{ width: '100%' }}
                label={t('ID_TYPE')}
                variant="outlined"
              >
                <MenuItem
                  key="ssn"
                  value={PersonalIdentificationType.Ssn as string}
                >
                  {t('SOCIAL_SECURITY_NUMBER')}
                </MenuItem>
                <MenuItem key="stateId" value="stateId">
                  {t('STATE_ID')}
                </MenuItem>
                <MenuItem key="federalId" value="federalId">
                  {t('FEDERAL_ID')}
                </MenuItem>
              </Select>
              <FormHelperText>{form.errors.idType}</FormHelperText>
            </FormControl>
          </Grid>
          {form.values.idType === PersonalIdentificationType.Ssn && (
            <Grid item xs={12} md={6}>
              <SSNTextField
                name="idValue"
                label={t('SOCIAL_SECURITY_NUMBER')}
                {...form.props('idValue')}
                error={Boolean(form.errors.idValue) || Boolean(identityError)}
              />
            </Grid>
          )}
          {form.values.idType === 'stateId' && (
            <>
              <Grid item xs={12} md={6}>
                <FormControl
                  variant="outlined"
                  error={Boolean(form.errors.idSubType)}
                  style={{ width: '100%' }}
                >
                  <InputLabel id="state-id-type-label">
                    {t('STATE_ID_TYPE')}
                  </InputLabel>
                  <Select
                    inputProps={{
                      'data-testid': 'state-id-type-control',
                    }}
                    labelId="state-id-type-label"
                    name="idSubType"
                    value={form.values.idSubType}
                    onChange={async (e: any) => {
                      await form.setValue('idValue', '');
                      await form.setValue('idSubTypeState', '');
                      setIdentityError?.('');
                      form.setError('idValue', null);
                      form.setError('idSubTypeError', null);
                      form.onChange(e);
                    }}
                    onBlur={form.onBlur}
                    label={t('STATE_ID_TYPE')}
                    autoWidth
                    variant="outlined"
                    style={{ width: '100%' }}
                  >
                    <MenuItem
                      value={StateIdType.DriverLicense as string}
                      key={StateIdType.DriverLicense as string}
                    >
                      {t('DRIVERS_LICENSE')}
                    </MenuItem>
                    <MenuItem
                      value={StateIdType.StateIdCard as string}
                      key={StateIdType.StateIdCard as string}
                    >
                      {t('STATE_ID_CARD')}
                    </MenuItem>
                  </Select>
                  <FormHelperText>{form.errors.idSubType}</FormHelperText>
                </FormControl>
              </Grid>
              {(form.values.idSubType === StateIdType.DriverLicense ||
                form.values.idSubType === PersonalIdentificationType.Dl ||
                form.values.idSubType === StateIdType.StateIdCard) && (
                <Grid item xs={12} md={6}>
                  <FormControl
                    error={Boolean(form.errors.idSubTypeState)}
                    style={{ width: '100%' }}
                    variant="outlined"
                  >
                    <InputLabel id="id-sub-type-state-label">
                      {t('STATE')}
                    </InputLabel>
                    <Select
                      labelId="id-sub-type-state-label"
                      name="idSubTypeState"
                      value={form.values.idSubTypeState}
                      onChange={form.onChange}
                      error={Boolean(form.errors.idSubTypeState)}
                      autoWidth
                      onBlur={form.onBlur}
                      style={{ width: '100%' }}
                      label={t('STATE')}
                      variant="outlined"
                      native
                    >
                      <option aria-label="None" value="" />
                      {statesAndProvincesGrouping.unitedStates.map(
                        (v: string) => (
                          <option key={v} value={v}>
                            {v}
                          </option>
                        ),
                      )}
                    </Select>
                    <FormHelperText>
                      {form.errors.idSubTypeState}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                {(form.values.idSubType === StateIdType.DriverLicense ||
                  form.values.idSubType === PersonalIdentificationType.Dl) && (
                  <TextField
                    inputProps={{
                      'data-testid': 'state-id-type-dl',
                      maxLength: 19,
                    }}
                    name="idValue"
                    error={
                      Boolean(form.errors.idValue) || Boolean(identityError)
                    }
                    {...form.props('idValue')}
                    label={t('DRIVERS_LICENSE')}
                    delayedTextMask={true}
                  />
                )}
                {form.values.idSubType === StateIdType.StateIdCard && (
                  <TextField
                    inputProps={{
                      'data-testid': 'state-id-type-state-id-card',
                      maxLength: 19,
                    }}
                    name="idValue"
                    {...form.props('idValue')}
                    label={t('STATE_ID_CARD')}
                    error={
                      Boolean(form.errors.idValue) || Boolean(identityError)
                    }
                    delayedTextMask={true}
                  />
                )}
              </Grid>
            </>
          )}
          {form.values.idType === 'federalId' && (
            <>
              <Grid item xs={12} md={6}>
                <FormControl
                  variant="outlined"
                  error={Boolean(form.errors.idSubType)}
                  style={{ width: '100%' }}
                >
                  <InputLabel id="federal-id-type-label">
                    {t('FEDERAL_ID_TYPE')}
                  </InputLabel>
                  <Select
                    inputProps={{
                      'data-testid': 'federal-id-type-control',
                    }}
                    labelId="federal-id-type-label"
                    name="idSubType"
                    value={form.values.idSubType}
                    onChange={async (e: any) => {
                      await form.setValue('idValue', '');
                      setIdentityError?.('');
                      form.setError('idValue', null);
                      form.onChange(e);
                    }}
                    onBlur={form.onBlur}
                    label={t('FEDERAL_ID_TYPE')}
                    autoWidth
                    style={{ width: '100%' }}
                    variant="outlined"
                  >
                    <MenuItem
                      key={FederalIdentificationType.UsStudentVisa as string}
                      value={FederalIdentificationType.UsStudentVisa as string}
                    >
                      {t('US_STUDENT_VISA_ID')}
                    </MenuItem>
                    <MenuItem
                      key={FederalIdentificationType.UsTemporaryVisa as string}
                      value={
                        FederalIdentificationType.UsTemporaryVisa as string
                      }
                    >
                      {t('US_TEMPORARY_VISA_ID')}
                    </MenuItem>
                    <MenuItem
                      key={FederalIdentificationType.UsMilitaryId as string}
                      value={FederalIdentificationType.UsMilitaryId as string}
                    >
                      {t('MILITARY_ID')}
                    </MenuItem>
                    <MenuItem
                      key={FederalIdentificationType.UsPassport as string}
                      value={FederalIdentificationType.UsPassport as string}
                    >
                      {t('PASSPORT')}
                    </MenuItem>
                    <MenuItem
                      key={FederalIdentificationType.UsImmigration as string}
                      value={FederalIdentificationType.UsImmigration as string}
                    >
                      {t('RESIDENT_ALIEN_ID')}
                    </MenuItem>
                    <MenuItem
                      value={FederalIdentificationType.OregonTribalId as string}
                      key={FederalIdentificationType.OregonTribalId as string}
                    >
                      {t('OREGON_TRIBAL_ID')}
                    </MenuItem>
                  </Select>
                  <FormHelperText>{form.errors.idSubType}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                {form.values.idSubType ===
                  FederalIdentificationType.UsStudentVisa && (
                  <TextField
                    inputProps={{
                      'data-testid': 'federal-id-type-us-student-visa',
                      maxLength: 15,
                    }}
                    name="idValue"
                    error={form.errors.idValue}
                    {...form.props('idValue')}
                    label={t('US_STUDENT_VISA_ID')}
                    delayedTextMask={true}
                  />
                )}
                {form.values.idSubType ===
                  FederalIdentificationType.UsTemporaryVisa && (
                  <TextField
                    inputProps={{
                      'data-testid': 'federal-id-type-us-temporary-visa',
                      maxLength: 15,
                    }}
                    name="idValue"
                    {...form.props('idValue')}
                    label={t('US_TEMPORARY_VISA_ID')}
                    error={
                      Boolean(form.errors.idValue) || Boolean(identityError)
                    }
                    delayedTextMask={true}
                  />
                )}
                {form.values.idSubType ===
                  FederalIdentificationType.UsMilitaryId && (
                  <TextField
                    inputProps={{
                      'data-testid': 'federal-id-type-military',
                      maxLength: 15,
                    }}
                    name="idValue"
                    {...form.props('idValue')}
                    error={
                      Boolean(form.errors.idValue) || Boolean(identityError)
                    }
                    label={t('MILITARY_ID')}
                    delayedTextMask={true}
                  />
                )}
                {form.values.idSubType ===
                  FederalIdentificationType.UsPassport && (
                  <TextField
                    inputProps={{
                      'data-testid': 'federal-id-type-pssprt',
                      maxLength: 15,
                    }}
                    name="idValue"
                    {...form.props('idValue')}
                    error={
                      Boolean(form.errors.idValue) || Boolean(identityError)
                    }
                    label={t('PASSPORT')}
                    delayedTextMask={true}
                  />
                )}
                {form.values.idSubType ===
                  FederalIdentificationType.UsImmigration && (
                  <TextField
                    inputProps={{
                      'data-testid': 'federal-id-type-resalien',
                      maxLength: 15,
                    }}
                    name="idValue"
                    {...form.props('idValue')}
                    error={
                      Boolean(form.errors.idValue) || Boolean(identityError)
                    }
                    label={t('RESIDENT_ALIEN_ID')}
                    delayedTextMask={true}
                  />
                )}
                {form.values.idSubType ===
                  FederalIdentificationType.OregonTribalId && (
                  <TextField
                    inputProps={{
                      'data-testid': 'federal-id-type-ortrib',
                      maxLength: 15,
                    }}
                    name="idValue"
                    error={form.errors.idValue}
                    {...form.props('idValue')}
                    label={t('OREGON_TRIBAL_ID')}
                    delayedTextMask={true}
                  />
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerIdentity;
