import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import TextLink from '../../text-link';
import ROUTES from '../../../routes';

import { useTranslation } from '../../../hooks/useTranslation';
import { AccountDetail } from '../../../__generated__/pge-types';
import AccountListItem from './AccountListItem';
import { MulticheckState } from '../../../hooks/useMulticheckState';
import useUpdateDefaultAccount from '../../../hooks/useUpdateDefaultAccount';
import Backdrop from '../../backdrop';
import { BillMenuType } from './useAccountListItemMenuItems';
import { NickNameAddressPayload } from '../../nickname-change-modal/types';

type Props = {
  groupId?: string;
  accounts: Array<AccountDetail>;
  manageAccounts?: boolean;
  hideMenu?: boolean;
  onViewDetails?: (account: AccountDetail) => void;
  multicheckState?: MulticheckState;
  inactiveText?: string;
  onPayAll?: () => void;
  onRenderSelectAll?: any;
  billMenuType?: BillMenuType;
  accountsListCacheUpdate?: (payload: NickNameAddressPayload) => void;
};

export default function AccountList(props: Props) {
  const { t } = useTranslation();
  const {
    groupId,
    accounts,
    hideMenu,
    manageAccounts,
    multicheckState,
    inactiveText,
    onPayAll,
    onRenderSelectAll,
    onViewDetails,
    billMenuType = 'PAY',
    accountsListCacheUpdate,
  } = props;

  const checkedOptions = multicheckState
    ? multicheckState.checkedOptions()
    : [];

  const { savingDefaultAccount } = useUpdateDefaultAccount();

  return (
    <>
      {savingDefaultAccount && <Backdrop forceOpen />}
      <FormGroup>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            {onRenderSelectAll
              ? onRenderSelectAll()
              : multicheckState && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={'selectAll'}
                        checked={multicheckState.allSelected}
                        onChange={multicheckState.handleSelectAll}
                        color={'primary'}
                      />
                    }
                    disabled={multicheckState.selectAllDisabled}
                    label={`${t('SELECT_ALL')}${
                      checkedOptions.length ? ` (${checkedOptions.length})` : ''
                    }`}
                  />
                )}
          </Grid>
          {onPayAll && (
            <Grid item>
              <TextLink
                onClick={e => {
                  e.preventDefault();
                  onPayAll();
                }}
                to={ROUTES.MULTI_PAY}
                plain
                style={{
                  fontWeight: 'bold',
                }}
              >
                {t('MULTIPAY_PAY_ALL')}
              </TextLink>
            </Grid>
          )}
        </Grid>
        {accounts.map((account: AccountDetail) => {
          return (
            <AccountListItem
              groupId={groupId}
              inactiveText={inactiveText}
              key={account.accountNumber}
              account={account}
              manageAccounts={Boolean(manageAccounts)}
              onCheckboxChange={
                multicheckState
                  ? multicheckState.handleCheckToggle(account.accountNumber)
                  : () => true
              }
              checked={
                multicheckState
                  ? multicheckState.isChecked(account.accountNumber)
                  : false
              }
              checkDisabled={
                multicheckState
                  ? !multicheckState.isAnOption(account.accountNumber)
                  : true
              }
              hideMenu={hideMenu}
              onViewDetails={onViewDetails}
              billMenuType={billMenuType}
              accountsListCacheUpdate={accountsListCacheUpdate}
            />
          );
        })}
      </FormGroup>
    </>
  );
}
