import gql from 'not-graphql-tag';

export const getPersonsForAccountQuery = gql`
  query getPersonsForAccount($encryptedAccountNumber: String) {
    getPersonsForAccount(encryptedAccountNumber: $encryptedAccountNumber) {
      encryptedPersonId
      fullName
      personRelationshipType
    }
  }
`;

export const getMailingAddressForAccountQuery = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      mailingAddress {
        addressLine1
        state
        postal
        city
        country
      }
    }
  }
`;

export const updateAccountInformationMutation = gql`
  mutation updateAccountInfoInput($payload: SubmitUpdateInformationInput) {
    updateAccountInformation(payload: $payload)
  }
`;
