import {
  AccountDetail,
  Maybe,
  PremiseInfo,
  ProgramName,
  ProgramsEnrollmentStatus,
  ProgramsEnrollmentStatusDetails,
} from '../../__generated__/pge-types';
import useAuthQuery from '../../hooks/useAuthQuery';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import { GET_ACCOUNT_DETAILS } from '../my-programs/queries';
import {
  getActiveEncryptedPremiseId,
  getActiveEncryptedSaId,
} from '../my-programs/utils';
import { ProgramWithExtra } from './components/PanelSection';
import { GET_PROGRAMS_ENROLLMENT_DETAILS } from './queries';

function getEnrolledProgramsFor(programs: Maybe<ProgramsEnrollmentStatus>[]) {
  const isBothGFBAndGFC =
    programs.filter(
      program =>
        program?.isEnrolled &&
        (program.programName === ProgramName.GreenFutureBlock ||
          program.programName === ProgramName.GreenFutureChoice),
    )?.length === 2;

  if (isBothGFBAndGFC) {
    const enrolledPrograms: ProgramWithExtra[] = programs?.filter(
      program =>
        program?.isEnrolled === true &&
        program.programName !== ProgramName.GreenFutureBlock &&
        program.programName !== ProgramName.GreenFutureChoice,
    );
    enrolledPrograms.push({
      isEnrolled: true,
      programName: 'BothGFBAndGFC',
    });
    return enrolledPrograms;
  } else {
    return programs?.filter(program => program?.isEnrolled === true);
  }
}

export function useProgramsPanel() {
  const { accountParams, encryptedAccountNumber } = useSelectedAccountParams();
  const {
    data: account,
    loading: accountsLoading,
    error: getAccountsError,
  } = useAuthQuery<{
    getAccountDetails: [AccountDetail];
  }>(GET_ACCOUNT_DETAILS, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams,
    errorPolicy: 'all',
  });

  const encryptedServiceAgreementId = getActiveEncryptedSaId(
    account?.getAccountDetails?.[0]?.premiseInfo as PremiseInfo[] | undefined,
  );

  const encryptedPremiseId = getActiveEncryptedPremiseId(
    account?.getAccountDetails?.[0]?.premiseInfo as PremiseInfo[] | undefined,
  );

  const {
    data: allProgramsEnrollmentResponse,
    loading: allProgramsEnrollmentResponseLoading,
    error: allProgramsEnrollmentResponseError,
  } = useAuthQuery<{
    getProgramsEnrollmentStatusDetails: Maybe<ProgramsEnrollmentStatusDetails>;
  }>(GET_PROGRAMS_ENROLLMENT_DETAILS, {
    variables: {
      params: {
        encryptedAccountNumber,
        encryptedPremiseId,
        encryptedSaId: encryptedServiceAgreementId,
      },
    },
    skip:
      !encryptedAccountNumber ||
      !encryptedPremiseId ||
      !encryptedServiceAgreementId,
    errorPolicy: 'all',
  });

  const programEnrollmentDetails =
    allProgramsEnrollmentResponse?.getProgramsEnrollmentStatusDetails;

  const error = getAccountsError || allProgramsEnrollmentResponseError;
  const loading = accountsLoading || allProgramsEnrollmentResponseLoading;
  const data = {
    ...programEnrollmentDetails,
    energyShifting: getEnrolledProgramsFor(
      programEnrollmentDetails?.energyShifting ?? [],
    ),
    renewables: getEnrolledProgramsFor(
      programEnrollmentDetails?.renewables ?? [],
    ),
  };

  return {
    data,
    error,
    loading,
  };
}
