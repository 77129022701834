import {
  CardContent,
  CircularProgress,
  Paper,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { navigate } from 'gatsby';
import React from 'react';
import {
  AccountParams,
  EnergyTrackerData,
} from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import routes from '../../../../routes';
import { useIsMobile } from '../../../../util/style-utils';
import ErrorOverlay from '../../../error/error-overlay';
import { FullDivider } from '../../../pge-plus-common/FullDivider';
import { useCurrentUseCard } from './useCurrentUseCard';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      cursor: 'pointer',
    },
    centerChildren: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cardContent: {
      paddingBottom: '5px !important',
    },
    title: {
      fontFamily: 'Forma-DJR-Display',
      marginTop: '-5px',
      marginBottom: '5px',
      fontWeight: 100,
      fontSize: '24px',
    },
    cardDetails: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    subText: {
      textAlign: 'center',
      margin: 0,
      marginBottom: '15px',
      fontSize: '16px',
    },
    dialContainer: {
      display: 'flex',
    },
    dial: {
      '&>svg': {
        height: '130px',
        width: '220px',
      },
    },
    nextBill: {
      alignContent: 'flex-end',
      marginLeft: '-15px',
      '&>svg': {
        height: '36px',
        width: '130px',
      },
    },
    cardFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '95%',
      cursor: 'pointer',
    },
    cardFooterText: {
      margin: 0,
    },
    arrow: {
      marginLeft: '30px',
      marginTop: '-2px',
      alignContent: 'center',
    },
    footNote: {
      fontSize: '10px',
      marginTop: '8px',
    },
  }),
);

type CurrentUseCardProps = {
  data:
    | {
        getEnergyTrackerData: EnergyTrackerData;
      }
    | undefined;
  loading: boolean;
  error: boolean;
  accountParams: AccountParams;
};

export const CurrentUseCard = (props: CurrentUseCardProps) => {
  const {
    data: energyTrackerData,
    loading: energyTrackerLoading,
    error: energyTrackerError,
    accountParams,
  } = props;
  const classes = useStyles();
  const { inlineRichT, t } = useTranslation();
  const isMobile = useIsMobile();
  const { currentUseCardInfo, loading, error } = useCurrentUseCard(
    energyTrackerData,
    energyTrackerLoading,
    energyTrackerError,
    accountParams,
  );
  const handleClick = () => {
    void navigate(routes.VIEW_BILL);
  };

  if (loading) {
    return (
      <Paper>
        <CardContent className={classes.centerChildren}>
          <CircularProgress data-testid="loader" />
        </CardContent>
      </Paper>
    );
  }

  if (error) {
    return (
      <Paper>
        <CardContent className={classes.centerChildren} data-testid="error">
          <ErrorOverlay title={t('CURRENT_USE')} hide />
        </CardContent>
      </Paper>
    );
  }

  const getSubtext = () => {
    if (currentUseCardInfo?.currentUseIndicator === 'same') {
      return t('USAGE_ENERGY_SAME_AS_THIS_MONTH_MESSAGE');
    }

    if (currentUseCardInfo?.currentUseIndicator === 'less') {
      return inlineRichT(
        'YOU_ARE_CURRENTLY_USING_LESS_ENERGY_THAN_LAST_MONTH',
        {
          ENERYGY_USAGE_PERCENTAGE: currentUseCardInfo?.currentUsePercentage?.toString(),
        },
      );
    }

    if (currentUseCardInfo?.currentUseIndicator === 'more') {
      return inlineRichT(
        'YOU_ARE_CURRENTLY_USING_MORE_ENERGY_THAN_LAST_MONTH',
        {
          ENERYGY_USAGE_PERCENTAGE: currentUseCardInfo?.currentUsePercentage?.toString(),
        },
      );
    }
  };

  return (
    <Paper className={classes.paper}>
      <CardContent className={classes.cardContent}>
        <div className={classes.cardDetails} onClick={() => handleClick()}>
          <h2 className={classes.title} data-testid="title">
            {t('CURRENT_USE')}
          </h2>
          <p className={classes.subText} data-testid="subtext">
            {getSubtext()}
          </p>
          <div
            className={classes.dialContainer}
            style={{
              marginBottom: currentUseCardInfo?.dialInfo.containerBottomMargin,
            }}
          >
            <div className={classes.dial} data-testid="dial">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={`${currentUseCardInfo.dialInfo.p1} 0  ${currentUseCardInfo.dialInfo.x} ${currentUseCardInfo.dialInfo.y}`}
                style={{
                  height: currentUseCardInfo.dialInfo.height,
                }}
              >
                <circle
                  id="c1"
                  cx="50"
                  cy="50"
                  r="43"
                  fill="none"
                  stroke="#D7D7D7"
                  stroke-width="6"
                  stroke-dasharray="180 360"
                  pathLength="360"
                  stroke-linecap="round"
                  transform="rotate(180 50 50)"
                />
                <circle
                  id="c2"
                  cx="50"
                  cy="50"
                  r="43"
                  fill="none"
                  stroke="#35A4BF"
                  stroke-width="6"
                  stroke-dasharray={`${currentUseCardInfo.dialInfo.angle} 360`}
                  pathLength="360"
                  stroke-linecap="round"
                  transform="rotate(180 50 50)"
                />
                <defs>
                  <linearGradient id="gradient" gradientTransform="rotate(90)">
                    <stop offset="5%" stop-color="#E4E4E4" />
                    <stop offset="45%" stop-color="#FFFFFF" />
                    <stop offset="100%" stop-color="white" />
                  </linearGradient>
                </defs>
                <ellipse
                  cx="50"
                  cy="43"
                  rx="34"
                  ry="30"
                  fill="url(#gradient)"
                />
                {/* Hide till the C2M issue is fixed */}
                {false && currentUseCardInfo?.displayThreshold && (
                  <g
                    transform={`translate(50 50) rotate(${currentUseCardInfo.dialInfo.thresholdAngle})`}
                  >
                    <line
                      id="l1"
                      x1="-40"
                      y1="0"
                      x2="-50"
                      y2="0"
                      stroke="#84898F"
                      stroke-width=".3"
                    />
                    <text
                      id="t1"
                      x="63"
                      y="-1"
                      text-anchor="middle"
                      font-size="8"
                      font-weight="bold"
                      fill="#84898F"
                      font-family="Untitled-Sans"
                      writing-mode="vertical-lr"
                      transform="scale(-1, -1)"
                    >
                      {`$${currentUseCardInfo?.threshold}`}
                    </text>
                    <text
                      id="t2"
                      x="55"
                      y="-1"
                      text-anchor="middle"
                      font-size="7"
                      fill="#84898F"
                      font-family="Untitled-Sans"
                      writing-mode="vertical-lr"
                      transform="scale(-1, -1)"
                    >
                      {t('THRESHOLD')}
                    </text>
                  </g>
                )}
                <text
                  id="t3"
                  x="50"
                  y="40"
                  text-anchor="middle"
                  font-weight="bold"
                  fill="#20415B"
                  font-family="Untitled-Sans"
                >
                  {`$${currentUseCardInfo?.estimatedCurrentCharges}`}
                </text>
                <text
                  id="t4"
                  x="50"
                  y="55"
                  text-anchor="middle"
                  font-size="8"
                  fill="#20415B"
                  font-family="Untitled-Sans"
                >
                  {t('EST_CURRENT')}
                </text>
                <text
                  id="t5"
                  x="50"
                  y="65"
                  text-anchor="middle"
                  font-size="8"
                  fill="#20415B"
                  font-family="Untitled-Sans"
                >
                  {t('CHARGES')}
                </text>
              </svg>
            </div>
            <div
              className={classes.nextBill}
              style={{
                marginLeft: currentUseCardInfo?.dialInfo.marginLeft,
                marginBottom: currentUseCardInfo?.dialInfo.marginBottom,
              }}
              data-testid="nextbill"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 21">
                <text
                  id="t1"
                  x="0"
                  y="7"
                  font-weight="bold"
                  font-size="7"
                  fill="#20415B"
                  font-family="Untitled-Sans"
                >
                  {`$${currentUseCardInfo?.nextBillLowerBound} - $${currentUseCardInfo?.nextBillHigherBound}`}
                </text>
                <text
                  id="t2"
                  x="0"
                  y="16"
                  font-size="8"
                  fill="#84898F"
                  font-family="Untitled-Sans"
                >
                  {t('EST_NEXT_BILL')}
                </text>
              </svg>
            </div>
          </div>
          <div>
            {inlineRichT('BILLING_CYCLE_CURRENT_DAY', {
              BILLING_CYCLE_CURRENT_DAY: currentUseCardInfo?.billingCycleCurrentDay?.toString(),
              BILLING_CYCLE_TOTAL_DAYS: currentUseCardInfo?.billingCycleTotalDays?.toString(),
            })}
          </div>
          <p className={classes.footNote}>{t('CURRENT_USE_CARD_FOOT_NOTE')}</p>
          <FullDivider />
          <div className={classes.cardFooter} onClick={handleClick}>
            <p className={classes.cardFooterText} data-testid="footer">
              {t('VIEW_BILL_DETAILS')}
            </p>
            <div className={classes.arrow}>
              <ChevronRightIcon />
            </div>
          </div>
        </div>
      </CardContent>
    </Paper>
  );
};
