import { useApolloClient } from '@apollo/react-hooks';
import gql from 'not-graphql-tag';
import {
  OpenEnrollmentInput, OpenEnrollmentResponse,
} from '../__generated__/pge-types';

const CREATE_OPEN_ENROLLMENT = gql`
		mutation createOpenEnrollment($payload: OpenEnrollmentInput!) {
			createOpenEnrollment(payload: $payload) {
				isEnrollmentSuccessful
			}
		}
	`;

export function useCreateOpenEnrollment() {
  const apolloClient = useApolloClient();
  async function submit(payload: OpenEnrollmentInput) {
    const result = await apolloClient.mutate<{
        createOpenEnrollment: OpenEnrollmentResponse;
    }>({
      mutation: CREATE_OPEN_ENROLLMENT,
      variables: {
        payload,
      },
    });
    return result;
  }

  return { submit };
}
