import { navigate } from 'gatsby';
import { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';

import ROUTES from '../routes';
import { NotificationsContext } from '../providers/NotificationsProvider';
import useValidationForm, { DefaultFormValuesProps } from './useValidationForm';
import { useTranslation } from './useTranslation';
import createCustomGroupValidationRules from '../components/create-custom-group/CreateCustomGroup.rules';
import { createCustomGroupMutation } from '../queries/manageAccountGroups.query';
import useAccountCustomer, {
  getAccountInfoWithParams,
} from './useAccountCustomer';
import useWrapWithLoader from './useWrapWithLoading';
import {
  ErrorReason,
  AccountDetail,
  AccountCustomer,
} from '../__generated__/pge-types';

export type DefaultFormValue = {
  value: string | number | boolean;
  errorMessage: string | null;
};

export const defaultCreateCustomGroupForm: DefaultFormValuesProps = {
  accountNumber: { value: '', errorMessage: '' },
  nickname: { value: '', errorMessage: '' },
};

export interface GroupPropertiesFormProps {
  handleChange: React.ChangeEvent;
  onBlur: React.MouseEvent;
  groupName: DefaultFormValue;
}

export interface CreateCustomGroupHook {
  loading: boolean;
  groupPropertiesProps: GroupPropertiesFormProps;
  isCustomGroupAddButtonDisable: boolean;
  addCustomGroup: (accounts: AccountDetail[]) => void;
}

export default (): CreateCustomGroupHook => {
  const notificationContext = useContext(NotificationsContext);
  const { t } = useTranslation();
  const { loading, groups, writeCustomerCache } = useAccountCustomer();
  const { wrapWithLoader } = useWrapWithLoader();
  const groupPropertiesProps = useValidationForm(
    {
      groupName: { value: '', errorMessage: '' },
    },
    createCustomGroupValidationRules,
  );
  const [createCustomGrp] = useMutation(createCustomGroupMutation, {
    update(cache, { data: { createCustomGroup } }) {
      const createCustomGroupSuccess = createCustomGroup?.success || false;
      if (createCustomGroupSuccess) {
        const { getAccountInfo } = cache.readQuery({
          query: getAccountInfoWithParams,
          variables: {
            params: {
              removeInactiveAccounts: false,
            },
          },
        }) as { getAccountInfo: AccountCustomer };

        cache.writeQuery({
          query: getAccountInfoWithParams,
          variables: {
            params: {
              removeInactiveAccounts: false,
            },
          },
          data: {
            getAccountInfo: {
              ...getAccountInfo,
              groups: getAccountInfo.groups?.concat([createCustomGroup.group]),
            },
          },
        });
      }
    },
  });

  const isFormFieldsAreValid = () => {
    if (
      groupPropertiesProps.groupName.value &&
      !groupPropertiesProps.groupName.errorMessage
    ) {
      return false;
    } else {
      return true;
    }
  };

  const isCustomGroupAddButtonDisable = isFormFieldsAreValid();

  const showMessage = (message: string, isError: boolean) => {
    notificationContext.setState({
      severity: isError ? 'error' : 'success',
      isOpen: true,
      variant: 'filled',
      title: message,
    });

    // scroll to top of the page so you can see the notifications feedback
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  };

  const addCustomGroup = wrapWithLoader(async (accounts: AccountDetail[]) => {
    const accountsToSave = accounts.map((account: AccountDetail) => {
      return {
        accountNumber: account.accountNumber,
      };
    });

    const groupToSave = {
      groupName: groupPropertiesProps.groupName.value,
      accounts: accountsToSave,
    };

    const result = await createCustomGrp({
      variables: {
        payload: groupToSave,
      },
    });
    const success = result?.data?.createCustomGroup?.success || false;
    if (success) {
      const group = result?.data?.createCustomGroup?.group;
      await navigate(ROUTES.MANAGE_ACCOUNTS_LANDING_SCREEN, {
        state: {
          firstGroup: group.groupId,
        },
      });
      showMessage(
        t('GROUP_CREATED_SUCCESSFULLY', {
          NAME: group.groupName,
          NUMBER: group.numberOfAccounts,
        }),
        false,
      );
      writeCustomerCache({
        groups: [group, ...groups],
      });
    } else {
      const errorReason: ErrorReason =
        result?.data?.createCustomGroup?.errorReason;
      if (errorReason === ErrorReason.GroupNameAlreadyExists) {
        groupPropertiesProps.setErrorMessage(
          'groupName',
          t('GROUP_NAME_IN_USE'),
        );
      } else {
        showMessage(t('CREATE_GROUP_ERROR'), true);
      }
    }
  });

  return {
    loading,
    groupPropertiesProps,
    isCustomGroupAddButtonDisable,
    addCustomGroup,
  };
};
