import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useEffect, SetStateAction, Dispatch } from 'react';
import Button from '../../../../../buttons';
import colors from '../../../../../../themes/main-colors';
import { useTranslation } from '../../../../../../hooks/useTranslation';
import Ineligible from '../../../../../peak-time-rebates/ineligibile';
import EmailTextField from '../../../../../email-text-field';
import PhoneTextField from '../../../../../phone-text-field';
import { PeakTimeRebatesTransactionType } from '../../../../../peak-time-rebates/types';
import PTRIcon from '../../../../../../static/icon_get_rebate.svg';
import { useStaticQuery, graphql } from 'gatsby';
import { getComponent } from '../../../../../../util/contentful-render-utils';
import { FormState } from '../../../../../../hooks/useFormState.types';
import { Model } from '../../../../../peak-time-rebates/rules';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: `${theme.spacing(0, 20)} !important`,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing(0, 1)} !important`,
      },
    },
    header: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '26px',
    },
    footer: {
      padding: theme.spacing(5, 0, 0, 0),
    },
    colorTextError: {
      color: colors.roseRed,
    },
    grayInfo: {
      background: colors.lightGray3,
      padding: theme.spacing(2, 2),
      margin: theme.spacing(1, 0),
    },
    alignCenter: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: '15px',
      },
    },
    marginTop: {
      marginTop: theme.spacing(2),
    },
    mediaWrapper: {
      width: '100%',
      height: '275px',
      marginBottom: theme.spacing(4),
      overflow: 'hidden',
      '& .ContentfulWrapperembedMedia': {
        width: '100%',
        height: '275px',
      },
      '& .ContentfulWrapperembedMedia figure': {
        width: '100%',
        height: '275px',
      },
    },
    root: {
      backgroundColor: colors.lightGray2,
      width: '100%',
      '& .ContentfulElementColumns2 .spacingComponent': {
        padding: 0,
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1.25, 0),
        },
      },

      '& .pge-contentful-body': {
        fontSize: '11px',
        lineHeight: '16px',
        [theme.breakpoints.down('xs')]: {
          padding: theme.spacing(1.25, 0),
        },
      },
      '& .MuiTypography-h5': {
        fontSize: '16px',
        lineHeight: '0.5rem',
      },
      '& .MuiCardMedia-root': {
        width: '42px',
        height: '42px',
        margin: '0px 10px 8px 10px',
      },
    },
  }),
);

export interface PeakTimeRebatesProps {
  onClose: () => void;
  onSubmit: () => void;
  isEnrolled: boolean;
  isEligible: boolean;
  loading: boolean;
  form: FormState<Model>;
  showNotificationPreferenceRequiredError: boolean;
  setTransactionType: Dispatch<SetStateAction<any>>;
  transactionType: PeakTimeRebatesTransactionType;
}

const peakTimeRebatePromoQuery = graphql`
  query peakTimeRebatePromoEntry {
    contentfulSecureSiteProgramPromo(
      contentful_id: { eq: "9HHlN3FMyz2RRm0s1ti3d" }
    ) {
      __typename
      contentful_id
      hero {
        ...ModuleEmbedMediaWrapper
      }
      body {
        ...ModuleColumn3
      }
    }
  }
`;

const ProgramDetails = (props: PeakTimeRebatesProps) => {
  const {
    onClose,
    onSubmit,
    loading,
    isEligible,
    isEnrolled,
    form,
    showNotificationPreferenceRequiredError,
    transactionType,
    setTransactionType,
  } = props;

  const classes = useStyles();
  const { t, richT } = useTranslation();

  useEffect(() => {
    if (!loading && !isEnrolled && isEligible) {
      setTransactionType(PeakTimeRebatesTransactionType.Enroll);
    } else if (isEnrolled && isEligible) {
      setTransactionType(PeakTimeRebatesTransactionType.Update);
    }
  }, [loading, isEnrolled, isEligible]);

  const {
    contentfulSecureSiteProgramPromo: { hero, body },
  } = useStaticQuery(peakTimeRebatePromoQuery);

  const cancel = () => {
    onClose();
  };

  return (
    <>
      <Grid item container className={classes.container}>
        <Typography variant={'h2'} className={classes.alignCenter}>
          <PTRIcon style={{ width: '56px', height: '56px' }} />
          <span>{t('PTR_TITLE_CASE')}</span>
        </Typography>
      </Grid>

      <Grid item container className={classes.container}>
        <Typography variant={'body1'} component={'div'}>
          {t('SSM_POST_PTR_DETAILS_INTRO')}
        </Typography>
      </Grid>

      <Grid
        item
        container
        className={clsx(classes.mediaWrapper, classes.container)}
      >
        {getComponent(hero, 0)}
      </Grid>

      <Grid item container className={classes.container}>
        <Typography variant={'body1'} component={'div'}>
          {t('SSM_POST_PTR_DETAILS_INFO')}
        </Typography>
      </Grid>

      <Grid item container className={classes.root}>
        <Grid item container className={classes.container}>
          {getComponent(body, 0)}
        </Grid>
      </Grid>

      <Grid
        item
        container
        spacing={2}
        className={clsx(classes.marginTop, classes.container)}
      >
        {!loading && !isEligible && !isEnrolled && (
          <>
            <Typography variant={'h1'} className={classes.header}>
              {t('SSM_POST_PTR_ENROLLMENT_TITLE')}
            </Typography>
            <Grid item xs={12} style={{ marginBottom: '1em' }}>
              <Typography variant={'h6'} component={'div'}>
                {richT('INELIGIBLE_PTR')}
              </Typography>
            </Grid>
          </>
        )}
        {!loading && isEligible && (
          <>
            <Typography variant={'h1'} className={classes.header}>
              {t('SSM_POST_PTR_ENROLLMENT_TITLE')}
            </Typography>
            <Grid item xs={12} style={{ marginBottom: '1em' }}>
              <EmailTextField
                name="notificationEmail"
                {...form.props('notificationEmail')}
              />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '1em' }}>
              <PhoneTextField
                name="notificationTextNumber"
                {...form.props('notificationTextNumber')}
              />
            </Grid>
            {showNotificationPreferenceRequiredError && (
              <Grid item xs={12}>
                <Typography
                  className={classes.colorTextError}
                  variant={'subtitle1'}
                  data-testid="notification-preference-error-message"
                >
                  {t('ENTER_AN_EMAIL_OR_MOBILE_PHONE')}
                </Typography>
              </Grid>
            )}

            <Grid className={classes.grayInfo}>
              <Box>
                <Typography variant={'subtitle1'}>
                  {t('SSM_POST_PTR_SAVINGS_ESTIMATE')}
                </Typography>
              </Box>
              <Box mt={2}>
                <Typography variant={'subtitle1'}>
                  {t('SSM_POST_PTR_SAVINGS_DISCLAIMER')}
                </Typography>
              </Box>
            </Grid>
          </>
        )}
      </Grid>

      <Grid container justify="center">
        <Box className={classes.footer} mb={3}>
          <Button
            style={{ marginRight: 10 }}
            color={'secondary'}
            variant={'outlined'}
            onClick={() => cancel()}
          >
            {t('CANCEL')}
          </Button>
          {isEligible && (
            <Button
              color={'secondary'}
              variant={'contained'}
              style={{ background: colors.orange }}
              onClick={onSubmit}
              data-testid="submit-button"
            >
              {transactionType === PeakTimeRebatesTransactionType.Enroll
                ? t('ENROLL')
                : t('UPDATE')}
            </Button>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default ProgramDetails;
