import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link, navigate, useLocation } from '@reach/router';
import sumBy from 'lodash/sumBy';
import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';
import { toCurrencyString } from '../../util/format';
import { useIsMobile } from '../../util/style-utils';
import Ineligible from '../auto-pay/auto-pay-start/Ineligible';
import PGEButton from '../buttons';
import AddPaymentMethodDropdown from '../payment-method-type/AddPaymentMethodDropdown';
import PaymentMethodTypeIconList from '../payment-method-type/PaymentMethodTypeIconList';
import PaymentMethodSelector from '../payment-wallet/payment-method-selector';
import PaymentLimitsFees from '../paymentus/payment-limits-fees';
import {
  ADD_PROFILE,
  PaymentProfileInfo,
  PAY_BY_CHECK,
  ProfileInfoChangeHandler,
} from '../paymentus/types';
import { getAllowedPaymentCategories } from '../paymentus/utils';
import ProceedOrCancel from '../proceed-or-cancel';
import ReviewTable from './ReviewTable';
import {
  AccountAmounts,
  PaymentMethodState,
  PaymentProfileState,
} from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerCollapsed: { color: colors.sparkBlue },
    headerExpanded: { color: colors.noirBlur },
    accordionParent: {
      padding: theme.spacing(4),
      justifyContent: 'center',
    },
    textColor: {
      color: colors.noirBlur,
    },
  }),
);

type LocationState = PaymentMethodState | undefined;

type Props = {
  path?: string;
  onSubmit: (
    accountAmounts: Array<AccountAmounts>,
    timestamp: string,
    profileState: PaymentProfileState,
  ) => void;
  onOneCheck: (flag: boolean) => void;
  onChangeProfile: ProfileInfoChangeHandler;
  initialState?: PaymentMethodState;
  profileState?: PaymentProfileState;
};

type AccordionProps = {
  resetSelected?: boolean;
  profileState: PaymentProfileState;
  updateProfileState: (
    selectedProfileInfo?: PaymentProfileInfo,
    isSetAutoNext?: boolean,
  ) => void;
  total: number;
};

const AccordionHeader = ({
  title,
  subtitle,
  expanded,
}: {
  title: string;
  subtitle: string;
  expanded: boolean;
}) => {
  const classes = useStyles();
  return (
    <Grid container direction="column" item xs={12}>
      <Grid container item xs={12} style={{ flexBasis: 'unset' }}>
        <Typography
          variant={'h3'}
          className={
            expanded ? classes.headerExpanded : classes.headerCollapsed
          }
        >
          {title}
        </Typography>
      </Grid>

      <Grid container item xs={12} style={{ flexBasis: 'unset' }}>
        <Typography variant={'body1'}>{subtitle}</Typography>
      </Grid>
    </Grid>
  );
};

const PaymentMethodAccordion = ({
  resetSelected,
  profileState,
  updateProfileState,
  total,
}: AccordionProps) => {
  const { t, richT } = useTranslation();
  const [expanded, setExpanded] = useState(
    profileState?.selectedProfileInfo?.profile === PAY_BY_CHECK
      ? 'PAY_BY_CHECK'
      : profileState?.selectedProfileInfo?.profile === ADD_PROFILE
      ? 'PAY_AS_GUEST'
      : 'SELECT_METHOD',
  );
  const classes = useStyles();
  const [curSelectedProfile, setCurSelectedProfile] = useState(
    profileState.selectedProfileInfo,
  );
  const [selectLabel, setSelectLabel] = useState<string | undefined>(undefined);

  const noop = (_: object, __: boolean) => undefined;

  const handleSelectMethod = (_: object, isExpanded: boolean) => {
    setExpanded(isExpanded ? 'SELECT_METHOD' : '');
    if (isExpanded) {
      setSelectLabel(undefined);
      updateProfileState(curSelectedProfile);
    } else {
      setSelectLabel(t('PAY_USING'));
      updateProfileState(undefined);
    }
  };

  const handlePayByCheck = (_: object, isExpanded: boolean) => {
    setExpanded(isExpanded ? 'PAY_BY_CHECK' : 'SELECT_METHOD');
    updateProfileState(
      isExpanded
        ? { profile: PAY_BY_CHECK }
        : curSelectedProfile?.profile === PAY_BY_CHECK
        ? undefined
        : curSelectedProfile,
    );
  };

  const handlePayAsGuest = (_: object, isExpanded: boolean) => {
    setExpanded(isExpanded ? 'PAY_AS_GUEST' : 'SELECT_METHOD');
    updateProfileState(
      isExpanded ? { profile: ADD_PROFILE } : curSelectedProfile,
    );
  };

  const onNew = (selectedProfileInfo?: PaymentProfileInfo) => {
    updateProfileState(selectedProfileInfo, true);
  };

  const selectProps = {
    resetSelected,
    onChange: (profileInfo: PaymentProfileInfo) => {
      setCurSelectedProfile(profileInfo);
      if (expanded === 'SELECT_METHOD') {
        updateProfileState(profileInfo);
      }
    },
    onNew,
    onDelete: (profileInfo: PaymentProfileInfo) => {
      if (
        profileInfo?.profile?.token ===
        profileState.selectedProfileInfo?.profile.token
      ) {
        setCurSelectedProfile(undefined);
        updateProfileState(undefined);
      }
    },
    currentProfileInfo:
      [PAY_BY_CHECK, ADD_PROFILE].some(
        p => p.token === profileState.selectedProfileInfo?.profile?.token,
      ) || profileState.selectedProfileInfo?.isAnonymous
        ? undefined
        : profileState.selectedProfileInfo,
    selectLabel,
  };

  const addProps = {
    onNew,
    allowedCategories: getAllowedPaymentCategories(),
    isPrimaryPM: false,
    customLabel: t('ENTER_A_PAYMENT_METHOD'),
  };

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container>
        <Accordion
          expanded={expanded === 'SELECT_METHOD'}
          onChange={expanded === 'SELECT_METHOD' ? noop : handleSelectMethod}
          style={{ width: '100%' }}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'SELECT_METHOD' ? undefined : <ExpandMoreIcon />
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <AccordionHeader
              title={t('PAYMENT_METHOD_SELECTOR_TITLE')}
              subtitle={t('PAYMENT_METHOD_SELECTOR_SUBTITLE')}
              expanded={expanded === 'SELECT_METHOD'}
            ></AccordionHeader>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item xs={12}>
              <PaymentMethodSelector {...selectProps}></PaymentMethodSelector>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item>
        <Accordion
          expanded={expanded === 'PAY_BY_CHECK'}
          onChange={expanded === 'PAY_BY_CHECK' ? noop : handlePayByCheck}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'PAY_BY_CHECK' ? undefined : <ExpandMoreIcon />
            }
            aria-controls="panel2bh-content"
            id="panel2bh-header"
          >
            <AccordionHeader
              title={t('PAYMENTUS_PM_CATEGORY_PAY_BY_CHECK')}
              subtitle={t('PAY_BY_CHECK_SUBHEADER')}
              expanded={expanded === 'PAY_BY_CHECK'}
            ></AccordionHeader>
          </AccordionSummary>
          <AccordionDetails>
            <Box className={`${classes.textColor}`} style={{ padding: '1em' }}>
              {richT('PAY_BY_CHECK_INFO_V2', {
                ONE_CHECK_AMOUNT: `$${toCurrencyString(total, true)}`,
              })}
            </Box>
          </AccordionDetails>
        </Accordion>
      </Grid>
      <Grid item>
        <Accordion
          expanded={expanded === 'PAY_AS_GUEST'}
          onChange={expanded === 'PAY_AS_GUEST' ? noop : handlePayAsGuest}
        >
          <AccordionSummary
            expandIcon={
              expanded === 'PAY_AS_GUEST' ? undefined : <ExpandMoreIcon />
            }
            aria-controls="panel3bh-content"
            id="panel3bh-header"
          >
            <AccordionHeader
              title={t('PAYMENT_WITHOUT_SAVING_TITLE')}
              subtitle={t('PAYMENT_WITHOUT_SAVING_SUBTITLE')}
              expanded={expanded === 'PAY_AS_GUEST'}
            ></AccordionHeader>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container item xs={12}>
              <AddPaymentMethodDropdown
                {...addProps}
              ></AddPaymentMethodDropdown>
              <PaymentMethodTypeIconList
                labelComponent={<Typography> + {t('WE_ACCEPT')}</Typography>}
              />
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  );
};

export default function PaymentMethodStep({
  onSubmit,
  onOneCheck,
  onChangeProfile,
  initialState,
  profileState,
}: Props) {
  const { richT, t } = useTranslation();
  const isMobile = useIsMobile();
  const classes = useStyles();

  const location = useLocation();
  const locationState: LocationState =
    initialState || (location?.state as LocationState);
  const accountAmounts: Array<AccountAmounts> =
    locationState?.accountAmounts || [];
  const timestamp = locationState?.timestamp || '';

  const [resetSelected, setResetSelected] = useState(false);
  const [selectedProfileState, setSelectedProfileState] = useState<
    PaymentProfileState
  >(profileState || { savedProfileList: [], newProfileList: [] });

  const [ineligible, setIneligible] = useState(false);
  const [autoNext, setAutoNext] = useState(false);

  useEffect(() => {
    if (autoNext) {
      onSubmit(accountAmounts, timestamp, selectedProfileState);
    }
  }, [autoNext]);

  useEffect(() => {
    if (accountAmounts.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.MULTI_PAY);
    }
  }, []);

  useEffect(() => {
    if (selectedProfileState?.selectedProfileInfo?.profile?.token) {
      setIneligible(false);
      setResetSelected(false);
    }
  }, [selectedProfileState?.selectedProfileInfo?.profile?.token]);

  const total = sumBy(accountAmounts, 'amount');

  const updateProfileState = (
    selectedProfileInfo?: PaymentProfileInfo,
    isAutoNext: boolean = false,
  ) => {
    onOneCheck(selectedProfileInfo?.profile === PAY_BY_CHECK);
    setSelectedProfileState(profState => ({
      ...profState,
      selectedProfileInfo,
    }));
    if (selectedProfileInfo) {
      onChangeProfile(selectedProfileInfo);
    }
    setAutoNext(isAutoNext);
  };

  const paymentAccordionProps = {
    resetSelected,
    profileState: selectedProfileState,
    updateProfileState,
    total,
  };

  if (ineligible) {
    return (
      <Ineligible
        title={t('ONLINE_PAYMENT_UNAVAILABLE')}
        action={
          <Box>
            <PGEButton
              size="large"
              onClick={() => {
                setResetSelected(true);
                setIneligible(false);
              }}
            >
              {t('OK')}
            </PGEButton>
          </Box>
        }
      >
        {richT('MULTIPAY_INELIGIBLE_NON_BILLABLE')}
      </Ineligible>
    );
  }

  return (
    <Box marginTop={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2">
            {t('REVIEW_SELECTED_ACCOUNTS')} ({accountAmounts.length})
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ReviewTable
            accountAmounts={accountAmounts}
            timestamp={timestamp}
            skipEligibilityCheck={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Paper component={'main'} className={classes.accordionParent}>
            <Grid
              container
              direction="row"
              justify={'space-between'}
              style={{ marginBottom: '2em' }}
            >
              <Grid item>
                <Typography variant="h2">
                  {t('HOW_DO_YOU_WANT_TO_PAY')}
                </Typography>
              </Grid>
              <Grid
                container
                item
                justify={'flex-end'}
                style={{ width: 'auto' }}
              >
                <PaymentLimitsFees tabIndex={-1} />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <PaymentMethodAccordion {...paymentAccordionProps} />
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            justify="space-between"
            direction={isMobile ? 'column-reverse' : 'row'}
            spacing={1}
          >
            <Grid item xs={12} md="auto">
              <Box textAlign="center">
                <Link to={ROUTES.ACCOUNT}>{t('CANCEL')}</Link>
              </Box>
            </Grid>
            <Grid item xs={12} md="auto">
              <Box paddingRight={1}>
                <ProceedOrCancel
                  proceedHandler={() =>
                    onSubmit(accountAmounts, timestamp, selectedProfileState)
                  }
                  cancelRoute={ROUTES.MULTI_PAY_PAYMENT_AMOUNTS}
                  cancelLabel={t('BACK')}
                  proceedLabel={t('NEXT')}
                  cancelVariant="Button"
                  proceedDisabled={
                    !selectedProfileState?.selectedProfileInfo?.profile ||
                    selectedProfileState?.selectedProfileInfo?.profile ===
                      ADD_PROFILE
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
