/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FC, ReactNode, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography } from '@material-ui/core';

interface LandingProps {
  children?: ReactNode;
  path: string;
}

export const Landing: FC<LandingProps> = props => {
  const { register, handleSubmit, watch } = useForm();

  const onSubmit = (data: any) => console.log(data);

  const subText1 = {
    fontSize: 20,
    marginTop: 30,
  };
  const boldText = {
    fontWeight: 700,
  };
  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Title */}
        <Box mt={4}>
          <Typography variant={'h1'}>{'Let\'s go!'}</Typography>
        </Box>
        {/* Generic Description */}
        <Box>
          <Typography css={{ ...subText1 }}>
            {
              'Welcome to PGE+, where we’ll help you get the equipment and services to meet your energy needs.'
            }
          </Typography>
          <Typography css={{ ...subText1 }}>
            {
              ' We’ll ask you some questions to help determine what kind of charger and installation services you need and provide information about available rebates and financing options along the way.'
            }
          </Typography>
          <Typography css={{ ...subText1, ...boldText }}>
            {
              'Please confirm your contact information is correct, and this is the address where you would like the charger installed.'
            }
          </Typography>
        </Box>
        {/* Contact Info Box */}
        <Box></Box>
      </form>
    </Fragment>
  );
};
