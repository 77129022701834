import {
  ApplicationVerifier,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  sendEmailVerification,
  User,
} from 'firebase/auth';

import { auth } from '../../firebase';

export function verifyIfUserIsEnrolled(user: User) {
  const enrolledFactors = multiFactor(user).enrolledFactors;
  return enrolledFactors.length > 0;
}

export function getEnrolledFactors(user: User) {
  return multiFactor(user).enrolledFactors;
}

//Send email verification link to the user if not verified already
//@depricated. We will be using PGE email service to send verification link
export async function sendEmailVerificationLink(user: User): Promise<boolean> {
  try {
    await sendEmailVerification(user);
    return true;
  } catch (e) {
    return false;
  }
}

export async function verifyPhoneNumber(
  user: User,
  phoneNumber: string,
  recaptchaVerifier: ApplicationVerifier,
): Promise<false | string> {
  const session = await multiFactor(user).getSession();
  const phoneInfoOptions = {
    phoneNumber,
    session,
  };

  const phoneAuthProvider = new PhoneAuthProvider(auth);
  try {
    return await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier,
    );
  } catch (e) {
    throw e;
  }
}

export async function enrollUser(
  user: User,
  verificationCodeId: string,
  verificationCode: string,
) {
  const phoneAuthCredential = PhoneAuthProvider.credential(
    verificationCodeId,
    verificationCode,
  );
  const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(
    phoneAuthCredential,
  );

  try {
    await multiFactor(user).enroll(
      multiFactorAssertion,
      'Personal Phone Number',
    );
    return true;
  } catch (e) {
    throw e;
  }
}

export async function unEnrollUser(user: User, uid: string) {
  return multiFactor(user).unenroll(uid);
}
