import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { navigate } from 'gatsby';
import { BaseFormModel } from '../../../hooks/useFormState.types';
import { useTranslation } from '../../../hooks/useTranslation';
import { MoveServiceForm } from '../MoveServiceForm';
import { useMoveServiceEligibility } from './useMoveServiceEligibility';
import Backdrop from '../../backdrop';
import AccountDropdown from '../../account-dropdown';
import ROUTES from '../../../routes';

interface Props {
  accountDetails: any;
  form: BaseFormModel;
  handleServiceDetailSubmit: any;
  isMobile: boolean;
  path: string;
  isAuthenticated: boolean;
  encryptedAccountNumber: string | undefined;
  encryptedPersonId: string | undefined;
  accountNumber: string | undefined;
  setIsRenewablesEligible: Dispatch<SetStateAction<boolean>>;
  setIsCurrentlyPaperlessBilling: Dispatch<SetStateAction<boolean>>;
}

export const MoveServiceFormPreverificationCheck = ({
  accountDetails,
  form,
  isMobile,
  handleServiceDetailSubmit,
  isAuthenticated,
  encryptedAccountNumber,
  accountNumber,
  encryptedPersonId,
  setIsRenewablesEligible,
  setIsCurrentlyPaperlessBilling,
}: Props) => {
  const { data, loading, setServiceAddress } = useMoveServiceEligibility();
  const { t } = useTranslation();

  useEffect(() => {
    if (
      accountDetails &&
      encryptedAccountNumber &&
      accountDetails?.serviceAddresses?.[0]
    ) {
      const {
        addressLine1,
        city,
        state,
        postal,
        qasVerified,
      } = accountDetails.serviceAddresses[0];
      setServiceAddress({
        serviceAddress: {
          addressLine1,
          city,
          state,
          postal,
          qasVerified,
        },
        encryptedAccountNumber,
      });
    }
  }, [accountDetails, encryptedAccountNumber]);

  useEffect(() => {
    if (data && !data?.moveServiceEligibility.eligibleForMoveService) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.MOVE_SERVICE_INELIGIBLE);
    }
    if (data) {
      setIsRenewablesEligible(
        Boolean(data.moveServiceEligibility?.eligibleForGreenResource),
      );
      setIsCurrentlyPaperlessBilling(
        Boolean(data.moveServiceEligibility?.isCurrentlyPaperlessBilling),
      );
    }
  }, [data]);

  if (!data || loading) {
    return <Backdrop forceOpen message={t('CHECKING_ELIGIBILITY')} />;
  }

  return (
    <>
      <div style={{ marginBottom: 24 }}>
        <AccountDropdown />
      </div>
      <MoveServiceForm
        form={form}
        handleServiceDetailSubmit={handleServiceDetailSubmit}
        isMobile={isMobile}
        isAuthenticated={isAuthenticated}
        path={ROUTES.MOVE_SERVICE}
        accountNumber={accountNumber}
        encryptedPersonId={encryptedPersonId}
      />
    </>
  );
};
