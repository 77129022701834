import {
  Grid,
  makeStyles,
  createStyles,
  Typography,
  Box,
} from '@material-ui/core';
import React, { ReactNode } from 'react';
import moment from 'moment';
import { useTranslation } from '../../../hooks/useTranslation';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import colors from '../../../themes/main-colors';
import { Link } from 'gatsby';
import ROUTES from '../../../routes';
import { Meter, PropertyUsageType } from '../ssm.types';
import { displayPhoneNumberFormat } from '../../../util/format';
import { SSMMoveServiceState } from '../../../hooks/useSSMMoveService';
import {
  OtherPeopleResponse,
  OtherPeopleRelType,
} from '../../../__generated__/pge-types';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: '16px',
    },
    summaryCardContainer: {
      background: colors.lightGray2,
      boxShadow: `0px 3px 6px ${colors.boxShadowColor}`,
      padding: '25px',
      borderRadius: '5px',
      width: '100%',
      marginTop: '16px',
    },
    summaryCardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '16px',
    },
    summaryCardHeading: {
      textTransform: 'capitalize',
      fontSize: '16px',
      color: colors.lightGray4,
      fontWeight: 'bold',
    },
    summaryCardEdit: {
      fontSize: '16px',
      textDecoration: 'none',
    },
    divider: {
      color: colors.lightGray1,
      marginTop: '16px',
      marginBottom: '16px',
    },
    summaryCardBodyLabel: {
      fontWeight: 'bold',
      fontSize: '14px',
    },
    summaryCardBodyValue: {
      fontSize: '14px',
    },
    summaryCardBodySection: {
      marginLeft: '25px',
      marginBottom: '5px',
    },
    marginTop: {
      marginTop: '5px',
    },
    meter: {
      width: '100%',
      marginBottom: '5px',
      '& > p': {
        fontSize: '14px',
      },
    },
    textColor: {
      color: colors.noirBlur,
    },
  }),
);

type SsmStartSummaryType = {
  title: string;
  link: string;
  info: {
    label: string | ReactNode;
    value: string | ReactNode;
    meters?: Meter[];
  }[];
}[];

const renderTransferServiceUsers = (userList: OtherPeopleResponse[]) => {
  return userList.map(user => {
    const { firstName, middleName, lastName } = user;
    return <div>{`${firstName} ${middleName} ${lastName}`}</div>;
  });
};

export const SSMConfirmDetails = ({
  componentProps,
}: WizardFormProps<SSMMoveServiceState>) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const {
    metersInfo: meters,
    ssmMoveServiceState,
    isRenewablesEligible,
  } = componentProps;
  const propertyUsageType: Record<string, any> = {
    [PropertyUsageType.Own]: t('OWNER'),
    [PropertyUsageType.TNT]: t('TENANT'),
    [PropertyUsageType.LLD]: t('LANDLORD'),
    [PropertyUsageType.FNF]: t('FRIEND_FAMILY'),
  };

  let fullName: string = '';
  const coCustomers = ssmMoveServiceState.ssmMoveTransferPeopleToService.filter(
    (user: OtherPeopleResponse) =>
      user.acctRelType === OtherPeopleRelType.Coapp,
  );

  const authUsers = ssmMoveServiceState.ssmMoveTransferPeopleToService.filter(
    (user: OtherPeopleResponse) =>
      user.acctRelType === OtherPeopleRelType.Authusr,
  );

  if (ssmMoveServiceState?.contactInfo) {
    fullName = `${ssmMoveServiceState?.contactInfo?.firstName ??
      ''} ${ssmMoveServiceState?.contactInfo?.middleName ??
      ''} ${ssmMoveServiceState?.contactInfo?.lastName ?? ''}`.trim();
  }
  let fullAddress: string = '';
  fullAddress = `${
    ssmMoveServiceState?.addressAndDate?.address?.addressLine1
      ? ssmMoveServiceState?.addressAndDate?.address?.addressLine1
      : ''
  } ${
    ssmMoveServiceState?.addressAndDate?.address?.city
      ? ',' + ssmMoveServiceState?.addressAndDate?.address?.city
      : ''
  } ${
    ssmMoveServiceState?.addressAndDate?.address?.postal
      ? ',' + ssmMoveServiceState?.addressAndDate?.address?.postal
      : ''
  }`.trim();

  const ssmStartSummary: SsmStartSummaryType = [
    {
      title: t('ADDRESS_DETAILS'),
      link: ROUTES.SSM_MOVE_ADDRESS,
      info: [
        {
          label: t('WHERE_DO_YOU_WANT_TO_START'),
          value: fullAddress,
          meters,
        },
        {
          label: t('WHEN_START_SERVICE'),
          value: moment(ssmMoveServiceState?.addressAndDate.date).format(
            'MM/DD/YYYY',
          ),
        },
      ],
    },
    {
      title: t('HOME_DETAILS'),
      link: ROUTES.SSM_MOVE_HOME,
      info: [
        {
          label: t('HOW_WILL_YOU_USE_THE_PROPERTY'),
          value:
            propertyUsageType[ssmMoveServiceState.homeDetails.propertyUsage],
        },

        {
          label: t('BILLING_PREF'),
          value: ssmMoveServiceState.homeDetails.goPaperLess
            ? t('GO_PAPERLESS')
            : t('MAIL_BILL'),
        },
        {
          label: t('MAILING_ADDRESS_LABEL'),
          value: ssmMoveServiceState.homeDetails.sameAsServiceAddress
            ? fullAddress
            : [
                ssmMoveServiceState.homeDetails.mailAddress || '',
                ', ',
                ssmMoveServiceState.homeDetails.mailCity || '',
                ', ',
                ssmMoveServiceState.homeDetails.mailZip || '',
              ].join(''),
        },
        ...(isRenewablesEligible
          ? [
              {
                label: t('ENROLL_IN_GREEN_FUTURE'),
                value: ssmMoveServiceState.homeDetails.enrollInGreenFuture
                  ? t('YES')
                  : t('NO'),
              },
            ]
          : []),
        {
          label: t('RECEIVE_NOTIFICATIONS'),
          value: ssmMoveServiceState.homeDetails.shouldReceiveNotification ? (
            <span>
              {t('YES')}
              <br />
              {displayPhoneNumberFormat(
                ssmMoveServiceState.homeDetails.registeredMobileNumber?.replace(
                  /[^0-9]/g,
                  '',
                ),
              )}
            </span>
          ) : (
            t('NO')
          ),
        },
      ],
    },
    {
      title: t('ACCOUNT_USERS_LABEL'),
      link: ROUTES.SSM_MOVE_TRANSFER_PEOPLE_TO_SERVICE,
      info: [
        {
          label: t('CO_CUSTOMERS_LABEL'),
          value:
            coCustomers?.length > 0 ? (
              <>{renderTransferServiceUsers(coCustomers)}</>
            ) : (
              t('NA_LABEL')
            ),
        },
        {
          label: t('AUTH_PARTY_LABEL'),
          value:
            authUsers?.length > 0 ? (
              <>{renderTransferServiceUsers(authUsers)}</>
            ) : (
              t('NA_LABEL')
            ),
        },
        {
          label: t('SOMEONE_REMAINING_BEHIND'),
          value: ssmMoveServiceState.stayingBehindInfo.stayingBehind,
        },
      ],
    },
    {
      title: t('STOP_CURRENT_SERVICE'),
      link: ROUTES.SSM_MOVE_STOP_CURRENT_SERVICE,
      info: [
        {
          label: richT('WHEN_TO_STOP_SERVCE_AT_CURRENT_ADDRESS_MOVE'),
          value: moment(
            ssmMoveServiceState?.ssmStopCurrentServiceDate.date,
          ).format('MM/DD/YYYY'),
        },
      ],
    },
  ];

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      className={classes.container}
    >
      <Grid item container spacing={2}>
        <Typography data-testid="confirm-subtext">
          {t('SSM_MOVE_CONFIRM_SUBTEXT')}
        </Typography>
      </Grid>
      <Grid item container spacing={2}>
        <Box className={classes.summaryCardContainer}>
          {ssmStartSummary.map((cardContent, index) => (
            <Box key={index}>
              <Box className={classes.summaryCardHeader}>
                <Typography
                  className={classes.summaryCardHeading}
                  data-testid={`card-heading-${index + 1}`}
                >
                  {cardContent.title}
                </Typography>
                <Link
                  to={cardContent.link}
                  className={classes.summaryCardEdit}
                  data-testid={`header-link-${index + 1}`}
                >
                  {t('EDIT')}
                </Link>
              </Box>
              <Box>
                {cardContent.info.map((body, bodyIndex) => (
                  <Box
                    className={classes.summaryCardBodySection}
                    key={bodyIndex}
                  >
                    <Typography
                      className={classes.summaryCardBodyLabel}
                      data-testid={`body-label-${index + 1}${bodyIndex + 1}`}
                    >
                      {body.label}
                    </Typography>
                    <Typography
                      className={classes.summaryCardBodyValue}
                      data-testid={`body-value-${index + 1}${bodyIndex + 1}`}
                    >
                      {body.value}
                    </Typography>
                    {body?.meters?.length ? (
                      <Box className={classes.marginTop}>
                        {body?.meters
                          .filter((meter: Meter) => meter.checked)
                          .map((meter: Meter, meterIndex: number) => (
                            <Box className={classes.meter} key={meterIndex}>
                              <Typography data-testid="meter-index">{`${t(
                                'METER',
                              ).toUpperCase()} ${meterIndex + 1}`}</Typography>
                              <Typography data-testid="meter-type">
                                {t('TYPE')}
                                {': '}
                                <b className={classes.textColor}>
                                  {meter.spDesc}
                                </b>
                              </Typography>
                              {meter.locationDesc ? (
                                <Typography data-testid="meter-location">
                                  {t('LOCATION')}
                                  {': '}
                                  <b className={classes.textColor}>
                                    {meter.locationDesc}
                                  </b>
                                </Typography>
                              ) : meter.meterNumber ? (
                                <Typography data-testid="meter-number">
                                  {t('METER_NO')}
                                  {': '}
                                  <b className={classes.textColor}>
                                    {meter.meterNumber}
                                  </b>
                                </Typography>
                              ) : null}
                            </Box>
                          ))}
                      </Box>
                    ) : null}
                  </Box>
                ))}
              </Box>
              {index !== ssmStartSummary.length - 1 ? (
                <hr className={classes.divider} />
              ) : null}
            </Box>
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
