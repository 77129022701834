import React, { FunctionComponent, useContext, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import PGEButton from '../../buttons';
import { navigate } from '@reach/router';
import ROUTES from '../../../routes';
import TextField from '@material-ui/core/TextField';
import PhoneTextField from '../../phone-text-field';
import EmailTextField from '../../email-text-field';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import { useIsMobile } from '../../../util/style-utils';
import useRenewableIndustrialForm from './useRenewableIndustrialForm';
import { RenewablesAccountEligibleResponse } from '../../../__generated__/pge-types';
import { useTranslation } from '../../../hooks/useTranslation';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';

const { t } = useTranslation();

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  formAction: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export interface RenewablePowerIndustrialFormProps {
  path?: string;
  email?: string;
  phoneNumber?: number;
  eligibility: RenewablesAccountEligibleResponse;
  encryptedAccountNumber: string;
  pnpPhone?: string;
  content: any;
}

const RenewablePowerIndustrialForm: FunctionComponent<RenewablePowerIndustrialFormProps> = props => {
  const theme = useTheme();
  const classes = useStyles();
  const notificationContext = useContext(NotificationsContext);
  const {
    content,
    email,
    pnpPhone,
    encryptedAccountNumber,
    eligibility,
  } = props;

  const { form, onSubmit } = useRenewableIndustrialForm(
    email,
    pnpPhone,
  );

  const { wrapWithLoader } = useWrapWithLoader();
  const handleSubmit = wrapWithLoader(
    form.submit(async () => {
      try {
        const result = await onSubmit(
          encryptedAccountNumber,
          eligibility.encryptedServiceAgreementId!,
        );
        if (result.data?.createRenewablesEnrollmentTodo) {
          notificationContext.setState({
            isOpen: true,
            severity: 'success',
            message: content.industrialForm.notification,
          });
          return navigate(ROUTES.ACCOUNT);
        } else {
          showErrorNotification();
        }
      } catch (error) {
        console.log('handleSubmit error:', error);
        showErrorNotification();
      }
    }),
  );

  const showErrorNotification = () => {
    notificationContext.setState({
      isOpen: true,
      severity: 'error',
      message: t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
    });
  };

  return (
    <form noValidate onSubmit={handleSubmit}>
      <Grid container direction={'column'} spacing={2}>
        <Grid item>
          <Card>
            <CardContent>
              <Grid container direction={'column'} spacing={2}>
                <Grid item>
                  <Typography variant={'body1'}>
                    {content.industrialForm.industrial_renewable}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={'h2'}>
                    {content.industrialForm.contact_info}
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    name={'contactName'}
                    label={content.industrialForm.contact_name}
                    style={{ width: '100%' }}
                    variant={'outlined'}
                    inputProps={{ maxLength: 50 }}
                    {...form.props('contactName')}
                  />
                </Grid>
                <Grid item>
                  <EmailTextField
                    name={'emailAddress'}
                    label={content.industrialForm.email}
                    {...form.props('emailAddress')}
                  />
                </Grid>

                <Grid item>
                  <PhoneTextField
                    name={'phoneNumber'}
                    label={content.industrialForm.phone}
                    {...form.props('phoneNumber')}
                  />
                </Grid>
                <Grid item>
                  <Typography variant={'body1'}>
                    {content.industrialForm.industrial_renewable_2}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                container
                style={
                  useIsMobile()
                    ? {
                        paddingTop: theme.spacing(2),
                        paddingLeft: theme.spacing(4),
                        paddingRight: theme.spacing(4),
                      }
                    : { paddingTop: theme.spacing(2) }
                }
                direction={'column'}
                alignItems={useIsMobile() ? 'stretch' : 'flex-end'}
              >
                <Grid item>
                  <PGEButton
                    variant={'contained'}
                    color={'primary'}
                    type={'submit'}
                    className={classes.formAction}
                  >
                    {content.submit}
                  </PGEButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default RenewablePowerIndustrialForm;
