import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { navigate, Router, useLocation } from '@reach/router';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import React, { useContext, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import AccountGroupDropdown from '../../components/account-group-dropdown';
import useFeatureFlagProtectedValues from '../../components/account-summary/multi-accounts/useFeatureFlagProtectedValues';
import {
  CompleteStep,
  PaymentAmountsStep,
  PaymentMethodStep,
  ReviewStep,
  SelectAccountsStep,
  useMultiPayService,
  useMultiPaySteps,
} from '../../components/multi-pay';
import PaymentCompleteStep from '../../components/multi-pay/PaymentCompleteStep';
import PaymentReviewStep from '../../components/multi-pay/PaymentReviewStep';
import { getAccountsList } from '../../components/multi-pay/queries';
import {
  AccountAmounts,
  PaymentAmountsState,
  PaymentDateState,
  PaymentMethodState,
  PaymentProfileState,
  SelectAccountsState,
} from '../../components/multi-pay/types';
import PaymentLayout from '../../components/payment-layout';
import {
  ADD_PROFILE,
  PaymentProfileInfo,
  PAY_BY_CHECK,
} from '../../components/paymentus/types';
import ProgressTracker from '../../components/ProgressTracker';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import useAccountDetailList from '../../hooks/useAccountDetailList';
import { useSavedProfileList } from '../../hooks/usePaymentus';
import useSelectedGroupId from '../../hooks/useSelectedGroupId';
import { useTranslation } from '../../hooks/useTranslation';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import ROUTES from '../../routes';
import { AccountDetail, AccountParams } from '../../__generated__/pge-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainArea: {
      minWidth: '50em',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.up('lg')]: {
        padding: '0 5em',
        minWidth: '75em',
      },
      [theme.breakpoints.down('md')]: {
        minWidth: '15em',
      },
    },
  }),
);

type StepState = {
  selectAccounts?: SelectAccountsState;
  paymentAmounts?: PaymentAmountsState;
  paymentProfile?: PaymentProfileState;
  paymentMethod?: PaymentMethodState;
};

type Props = {
  path?: string;
};

export default function MultiPayPage(_: Props) {
  const classes = useStyles();
  const { richT, t } = useTranslation();
  const { steps, stepIndex, setIsOneCheck } = useMultiPaySteps();
  const [stepState, setStepState] = useState<StepState>({});
  const [selectedAmount, setSelectedAmount] = useState<number>(0);
  const location = useLocation();
  const multiPayService = useMultiPayService();
  const { wrapWithLoader } = useWrapWithLoader();

  const { customer } = useAccountCustomer();
  const { savedProfileList } = useSavedProfileList(customer);
  const { selectedGroupId } = useSelectedGroupId();
  const notificationContext = useContext(NotificationsContext);
  const {
    getAccountListStateByClosedAcctFlag,
  } = useFeatureFlagProtectedValues();

  useEffect(() => {
    const params = ((location.state || {}) as {
      accountParams?: Array<AccountParams>;
    }).accountParams;

    if (params && params.length !== selectedAmount) {
      setSelectedAmount(params.length);
    }

    // You can land on stepIndex 1 without 0, this backfills
    // step 0's state to handle a back button click
    if (params && stepIndex === 1 && !stepState.selectAccounts) {
      setStepState(state => ({
        ...state,
        selectAccounts: {
          checkedState: mapValues(keyBy(params, 'accountNumber'), () => true),
          accountListState: getAccountListStateByClosedAcctFlag(),
        },
      }));
    }
  }, [location.state]);

  useEffect(() => {
    setStepState(state => ({
      ...state,
      paymentProfile: {
        savedProfileList: savedProfileList || [],
        newProfileList: state?.paymentProfile?.newProfileList || [],
        selectedProfileInfo: state?.paymentProfile?.selectedProfileInfo,
      },
    }));
  }, [savedProfileList?.length]);

  const { refetch } = useAccountDetailList(getAccountsList, selectedGroupId, {
    accountListParams:
      stepState?.selectAccounts?.accountListState ||
      getAccountListStateByClosedAcctFlag(),
  });

  function onChangeProfile(selectedProfileInfo: PaymentProfileInfo) {
    setStepState(state => ({
      ...state,
      paymentProfile: {
        savedProfileList: state.paymentProfile?.savedProfileList || [],
        newProfileList: state.paymentProfile?.newProfileList || [],
        selectedProfileInfo,
      },
    }));
  }

  function handleSelectAccountSubmit(
    accounts: Array<AccountDetail>,
    selectAccounts: SelectAccountsState,
  ) {
    setStepState(state => ({
      ...state,
      selectAccounts,
      paymentAmounts: undefined,
    }));
    return navigate(ROUTES.MULTI_PAY_PAYMENT_AMOUNTS, {
      state: {
        accountParams: accounts,
      },
    });
  }

  function handlePaymentAmountsSubmit(
    accountAmounts: Array<AccountAmounts>,
    timestamp: string,
    paymentAmounts: PaymentAmountsState,
  ) {
    setStepState(state => ({
      ...state,
      paymentAmounts,
      paymentMethod: undefined,
    }));
    return navigate(ROUTES.MULTI_PAY_PAYMENT_METHOD, {
      state: {
        accountAmounts,
        timestamp,
      },
    });
  }

  const handlePaymentMethodSubmit = wrapWithLoader(
    async (
      accountAmounts: Array<AccountAmounts>,
      timestamp: string,
      profileState: PaymentProfileState,
    ) => {
      setStepState(state => ({
        ...state,
        paymentMethod: { accountAmounts, timestamp },
        paymentProfile: {
          ...profileState,
        },
      }));

      if (profileState?.selectedProfileInfo?.profile === PAY_BY_CHECK) {
        const result = await multiPayService.submitOnecheck(accountAmounts);

        return navigate(ROUTES.MULTI_PAY_REVIEW, {
          state: {
            accountAmounts,
            paymentId: result?.remittanceId,
            profileState,
            timestamp,
          },
        });
      }

      return navigate(ROUTES.MULTI_PAY_PAYMENT_REVIEW, {
        state: {
          accountAmounts,
          profileState,
          timestamp,
        },
      });
    },
  );

  const handleReviewSubmit = wrapWithLoader(
    async (
      accountAmounts: Array<AccountAmounts>,
      timestamp: string,
      paymentId: string,
    ) => {
      return navigate(ROUTES.MULTI_PAY_COMPLETE, {
        state: {
          accountAmounts,
          timestamp,
          paymentId,
        },
      });
    },
  );

  const handlePaymentReviewSubmit = wrapWithLoader(
    async (
      accountAmounts: Array<AccountAmounts>,
      profileState: PaymentProfileState,
      paymentDateState: PaymentDateState,
      timestamp: string,
    ) => {
      if (profileState.selectedProfileInfo?.profile) {
        const {
          isPartialSucceed,
          paymentConfirmation,
        } = await multiPayService.submitMultipay(
          accountAmounts,
          profileState.selectedProfileInfo.profile,
          paymentDateState.paymentDate,
        );

        if (refetch) {
          await refetch();
        }

        return navigate(ROUTES.MULTI_PAY_PAYMENT_COMPLETE, {
          state: {
            accountAmounts,
            profileState,
            paymentDateState,
            isPartialSucceed,
            paymentConfirmation,
            timestamp,
          },
        });
      }
    },
  );

  function handleCompleteSubmit() {
    return navigate(ROUTES.ACCOUNT);
  }

  const downloadPdf = wrapWithLoader(
    async (remittanceId: string, accountAmounts: Array<AccountAmounts>) => {
      const pdf = await multiPayService.fetchOnecheckPdf(
        remittanceId,
        accountAmounts,
      );
      const linkSource = `data:application/pdf;base64,${pdf}`;
      const downloadLink = document.createElement('a');

      downloadLink.href = linkSource;
      downloadLink.download = t('ONE_CHECK_SUMMARY_FILENAME');
      downloadLink.click();
    },
  );

  const viewPdf = wrapWithLoader(
    async (remittanceId: string, accountAmounts: Array<AccountAmounts>) => {
      const pdf = await multiPayService.fetchOnecheckPdf(
        remittanceId,
        accountAmounts,
      );
      const pdfWindow = window.open('');
      if (pdfWindow) {
        pdfWindow.document.write(
          `<iframe width='100%' height='100%' src='data:application/pdf;base64,${pdf}'></iframe>`,
        );
        pdfWindow.document.title = t('ONE_CHECK_SUMMARY_FILENAME');
      }
    },
  );

  return (
    <>
      <Helmet>
        <title>{t('MULTI_PAY')}</title>
      </Helmet>
      <PaymentLayout>
        <div className={classes.mainArea}>
          <Grid container direction="column" spacing={0}>
            <Grid item>
              <Box marginBottom={2}>
                <Typography variant="h1">{t('MULTI_PAY')}</Typography>
              </Box>
            </Grid>
            <Hidden smDown>
              <Grid item>
                <Typography variant="body1" component={'div'}>
                  {richT('MULTIPAY_SUBTITLE_NEW', {
                    PAY_BILL_ROUTE: ROUTES.PAYMENT,
                    AUTO_PAY_ROUTE: ROUTES.AUTO_PAY,
                  })}
                </Typography>
              </Grid>
            </Hidden>
          </Grid>
          <Grid container direction="column" spacing={0}>
            <Grid item>
              <ProgressTracker steps={steps} />
            </Grid>
            {stepIndex < 2 && (
              <Grid item>
                <AccountGroupDropdown
                  isDisplay={stepIndex > 0 ? selectedAmount : false}
                  onSelect={() => setStepState({})}
                />
              </Grid>
            )}
          </Grid>
          <Router basepath="/">
            <SelectAccountsStep
              path={ROUTES.MULTI_PAY}
              onSubmit={handleSelectAccountSubmit}
              initialState={stepState.selectAccounts}
            />
            <PaymentAmountsStep
              path={ROUTES.MULTI_PAY_PAYMENT_AMOUNTS}
              onSubmit={handlePaymentAmountsSubmit}
              initialState={stepState.paymentAmounts}
            />
            <PaymentMethodStep
              path={ROUTES.MULTI_PAY_PAYMENT_METHOD}
              onSubmit={handlePaymentMethodSubmit}
              onOneCheck={setIsOneCheck}
              onChangeProfile={onChangeProfile}
              initialState={stepState.paymentMethod}
              profileState={stepState.paymentProfile}
            />
            <ReviewStep
              path={ROUTES.MULTI_PAY_REVIEW}
              onSubmit={handleReviewSubmit}
              onDownloadPdf={downloadPdf}
              onPrintPdf={viewPdf}
            />
            <PaymentReviewStep
              path={ROUTES.MULTI_PAY_PAYMENT_REVIEW}
              onSubmit={handlePaymentReviewSubmit}
            />
            <PaymentCompleteStep
              path={ROUTES.MULTI_PAY_PAYMENT_COMPLETE}
              onSubmit={handleCompleteSubmit}
            />
            <CompleteStep
              path={ROUTES.MULTI_PAY_COMPLETE}
              onSubmit={handleCompleteSubmit}
              onDownloadPdf={downloadPdf}
              onPrintPdf={viewPdf}
            />
          </Router>
        </div>
      </PaymentLayout>
    </>
  );
}
