/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';
import { Button, Box, Typography, useTheme } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import colors from '../../themes/main-colors';
import { CustomCSS } from '../../util/emotion';

/*
  This is the common button with checkbox on top right corner
  Can render centered svg icon as it's passed by props
*/
interface CommonButtonProps {
  buttonLabel: React.ReactNode;
  handleOnClick: (event: React.MouseEvent<HTMLElement>) => void;
  isActive: boolean;
  img?: React.ReactNode;
}

export default function CommonButton({
  buttonLabel,
  handleOnClick,
  isActive,
  img,
}: CommonButtonProps) {
  const theme = useTheme();

  return (
    <Button
      css={{
        width: '100%',
        border: `1px solid ${colors.fordGray}`,
        color: colors.noirBlur,
        fontSize: '20px',
        textAlign: 'center',
        borderRadius: 0,
        padding: theme.spacing(2, 0),
        position: 'relative',
        background: colors.white,
        height: '150px',
        '&:hover': {
          backgroundColor: colors.white,
        },
        ...(isActive
          ? {
              backgroundColor: colors.navBarHoverBackground,
              color: colors.sparkBlue,
              boxShadow: `0px 5px 5px ${colors.boxShadowBlack}`,
              borderColor: colors.sparkBlue,
              borderRadius: 0,
              '&:hover': {
                backgroundColor: colors.navBarHoverBackground,
                color: colors.sparkBlue,
              },
            }
          : {}),
      }}
      onClick={handleOnClick}
    >
      <Box
        css={{
          display: 'flex',
          flexDirection: 'column' as const,
          justifyContent: 'center',
          alignItems: 'center',
          '& svg': {
            color: '#27788C',
            position: 'static',
            width: 100,
            height: 50,
            marginBottom: theme.spacing(1),
          },
        }}
      >
        {img && img}
        <Typography>{buttonLabel}</Typography>
      </Box>
      {isActive && (
        <CheckCircle
          css={{
            position: 'absolute',
            width: '18px',
            height: '18px',
            top: '5px',
            right: '5px',
          }}
          htmlColor={colors.shoreGreen}
        />
      )}
    </Button>
  );
}
