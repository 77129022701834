import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import { Link } from 'gatsby';
import React from 'react';

import colors from '../../../themes/main-colors';
import PendingProcessingIcon from '../../time-of-day/pending-processing-icon/PendingProcessingIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: '3em',
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
      },
    },
    blueIcons: {
      background: theme.palette.primary.main,
    },
    greenIcons: {
      background: theme.palette.success.main,
    },
    grayIcons: {
      background: colors.lightGray1,
    },
    whiteIcons: {
      background: colors.white,
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    listItem: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingLeft: 0,
      paddingRight: '1em',
    },
    link: {
      textDecoration: 'none',
      color: 'inherit',
    },
    optionLink: {
      textDecoration: 'underline',
      color: colors.sparkBlue,
    },
  }),
);

export interface Option {
  name: string;
  active: boolean | undefined;
  disabled?: boolean;
  link?: string | undefined;
  data?: {
    value: string;
  };
  subLink?: {
    url: string;
    text: string;
  };
  pending?: boolean | undefined;
  subText?: string | undefined;
}

interface ToggleableOptionsListContentProps {
  options: Option[];
  isMobile?: boolean;
  isReconnect?: boolean;
}

interface ToggleableOptionsListProps {
  listTitle?: string | undefined;
  options: Option[];
  renderInCard?: boolean;
  isMobile?: boolean;
  isReconnect?: boolean;
}

export const ListOptionRow = ({ option }: { option: Option }) => {
  const classes = useStyles();
  let content = (
    <>
      <div>
        <ListItem className={classes.listItem}>
          <Grid
            item
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <ListItemAvatar>
              <Avatar
                className={
                  option.disabled === true
                    ? classes.grayIcons
                    : option.active
                    ? option.pending
                      ? classes.whiteIcons
                      : classes.greenIcons
                    : classes.blueIcons
                }
              >
                {option.active && !option.disabled ? (
                  option.pending ? (
                    <PendingProcessingIcon />
                  ) : (
                    <CheckIcon />
                  )
                ) : (
                  <AddCircleIcon />
                )}
              </Avatar>
            </ListItemAvatar>
            <Box display="flex" flexDirection="column">
              <Typography variant="h6" className={classes.optionLink}>{`${
                option.name
              } ${option?.data?.value ?? ''}`}</Typography>
              {option.subLink && (
                <Link to={option.subLink.url} className={classes.link}>
                  {option.subLink.text}
                </Link>
              )}
            </Box>
          </Grid>
        </ListItem>
      </div>
    </>
  );

  if (option.link) {
    content = (
      <Link to={option.link} className={classes.link}>
        {content}
      </Link>
    );
  }

  return content;
};

export const ToggleableOptionsListContent = ({
  options,
  isMobile,
  isReconnect,
}: ToggleableOptionsListContentProps) => (
  <List
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: isReconnect ? 'column' : isMobile ? 'column' : 'row',
      padding: 0,
    }}
  >
    {options.map((option: Option) => (
      <ListOptionRow key={`list-option-${option.name}`} option={option} />
    ))}
  </List>
);

const ToggleableOptionsList = ({
  options,
  renderInCard = true,
  listTitle,
  isMobile,
  isReconnect,
}: ToggleableOptionsListProps) => {
  const classes = useStyles();

  let contents = (
    <ToggleableOptionsListContent
      options={options}
      isMobile={isMobile}
      isReconnect={isReconnect}
    />
  );
  if (renderInCard) {
    contents = (
      <Card>
        <CardContent>{contents}</CardContent>
      </Card>
    );
  }

  return (
    <Grid container direction="column">
      {listTitle && (
        <Grid item>
          <Typography className={classes.colorTextPrimary} variant="h2">
            {listTitle}
          </Typography>
        </Grid>
      )}
      <Grid item>{contents}</Grid>
    </Grid>
  );
};

export default ToggleableOptionsList;
