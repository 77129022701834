import gql from 'not-graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const CREATE_PERSON_LEAVING_BEHIND_CONTACT_LOG_MUTATION = gql`
  mutation createPersonLeavingBehindContactLog(
    $payload: ContactlogPersonLeavingBehindRequest
  ) {
    createPersonLeavingBehindContactLog(payload: $payload)
  }
`;

export default function useCreatePersonLeavingBehindContactLog() {
  const [createLog] = useMutation(
    CREATE_PERSON_LEAVING_BEHIND_CONTACT_LOG_MUTATION,
  );

  return createLog;
}
