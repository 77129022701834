import gql from 'not-graphql-tag';
import {
  CreateApplicationEvOutput,
  CancelApplicationInput,
  CancelApplicationOutput,
  MutationCancelApplicationArgs,
  GetApplicationsInput,
  GetApplicationsOutput,
  QueryGetApplicationsArgs,
  GetApplicationOutput,
  GetApplicationInput,
  QueryGetApplicationArgs,
  MutationCreateApplicationEvArgs,
  CreateApplicationEvInput,
  AcceptQuoteEvInput,
  MutationAcceptQuoteEvArgs,
  AcceptQuoteEvOutput,
  RejectQuoteInput,
  MutationRejectQuoteArgs,
  RejectQuoteOutput,
} from '../../__generated__/pgeplus-types';
import {
  applicationEVFragment,
  applicationFragment,
} from '../../components/pge-plus-common/queries/fragments';

import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';

export const createApplicationMutation = gql`
  mutation CreateApplicationEV($input: CreateApplicationEVInput!) {
    createApplicationEV(input: $input) {
      ineligibilityReasons {
        message
        reference {
          number
          category
        }
        code
        details
      }
      application {
        ...applicationFragment
        ...applicationEVFragment
      }
    }
  }

  ${applicationFragment}
  ${applicationEVFragment}
`;

export const getApplicationDetailsQuery = gql`
  query GetApplicationData($input: GetApplicationInput!) {
    getApplication(input: $input) {
      application {
        ...applicationFragment
        ...applicationEVFragment
      }
    }
  }

  ${applicationFragment}
  ${applicationEVFragment}
`;

export const quoteFragment = gql`
  fragment quoteFragment on Quote {
    ... on QuoteEV {
      id
      version
      jobId
      status
      total
      serviceTime {
        start
        end
      }
      createdAt
      updatedAt
      data {
        lineItems {
          ... on LineItemDetail {
            key
            name
            value
          }
          ... on LineItemCost {
            key
            name
            category
            amount
          }
          ... on LineItemProduct {
            key
            name
            value
            amount
          }
          ... on LineItemRebate {
            key
            name
            amount
            rebateItems {
              panelUpgrade
              evCharger
            }
          }
          ... on LineItemProgram {
            key
            name
            value
          }
        }
        confirmContractorRebatePayee
        confirmEVVehicleOwner
        acknowledgeTerms
      }
    }
  }
`;

export const jobFragment = gql`
  fragment jobFragment on Job {
    id
    serviceProviderId
    applicationId
    applicationNo
    jobType
    status
    createdAt
    updatedAt
    quote {
      ...quoteFragment
    }
    jobDetails {
      id
      jobId
      jobDate
      total
      comments
      createdAt
      updatedAt
      data {
        images {
          key
          name
          objectId
        }
      }
    }
    jobLocation {
      id
      jobId
      premiseId
      addressLine1
      city
      state
      postalCode
      firstName
      lastName
      middleName
      phone
      email
    }
    serviceProviderInfo {
      firstName
      lastName
      phone
    }
  }

  ${quoteFragment}
`;

export const getApplicationsByAccountQuery = gql`
  query GetApplications($input: GetApplicationsInput!) {
    getApplications(input: $input) {
      applications {
        id
        accountId
        premiseId
        status
        statusReason
        applicationNo
        applicationType
        job {
          ...jobFragment
        }
      }
    }
  }

  ${jobFragment}
`;

export const getApplicationsByAccountQueryWithDetails = gql`
  query GetApplications($input: GetApplicationsInput!) {
    getApplications(input: $input) {
      applications {
        id
        accountId
        premiseId
        status
        statusReason
        applicationNo
        ...applicationFragment
        ...applicationEVFragment
        job {
          ...jobFragment
        }
      }
    }
  }

  ${applicationFragment}
  ${applicationEVFragment}
  ${jobFragment}
`;

export const getRichApplicationDetailsQuery = gql`
  query Query($input: GetApplicationInput!) {
    getApplication(input: $input) {
      application {
        ...applicationFragment
        ...applicationEVFragment
        job {
          ...jobFragment
        }
      }
    }
  }

  ${applicationFragment}
  ${applicationEVFragment}
  ${jobFragment}
`;

export const cancelApplicationMutation = gql`
  mutation cancelApplication($input: CancelApplicationInput!) {
    cancelApplication(input: $input) {
      canceled
    }
  }
`;

export const acceptQuoteMutation = gql`
  mutation acceptQuote($input: AcceptQuoteEVInput!) {
    acceptQuoteEV(input: $input) {
      quote {
        ... on QuoteEV {
          version
          data {
            confirmEVVehicleOwner
            acknowledgeTerms
            confirmContractorRebatePayee
          }
        }
      }
    }
  }
`;

export const rejectQuoteMutation = gql`
  mutation RejectQuote($input: RejectQuoteInput!) {
    rejectQuote(input: $input) {
      quote {
        ... on QuoteEV {
          version
        }
      }
    }
  }
`;

const useEVApplicationApi = () => {
  const apolloClient = useApolloClient();

  const createApplication = async (input: CreateApplicationEvInput) => {
    return apolloClient.mutate<
      { createApplicationEV: CreateApplicationEvOutput },
      MutationCreateApplicationEvArgs
    >({
      mutation: createApplicationMutation,
      variables: {
        input,
      },
      context: { pgePlus: true },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
    });
  };

  const getApplicationsByAccount = async (input: GetApplicationsInput) => {
    return apolloClient.query<
      { getApplications: GetApplicationsOutput },
      QueryGetApplicationsArgs
    >({
      query: getApplicationsByAccountQuery,
      variables: {
        input,
      },
      context: { pgePlus: true },
      fetchPolicy: 'no-cache',
    });
  };

  const getApplicationsByAccountWithDetails = async (
    input: GetApplicationsInput,
    query = getApplicationsByAccountQueryWithDetails,
  ) => {
    return apolloClient.query<
      { getApplications: GetApplicationsOutput },
      QueryGetApplicationsArgs
    >({
      query,
      variables: {
        input,
      },
      context: { pgePlus: true },
      fetchPolicy: 'no-cache',
    });
  };

  //Must use 'applicationNo' as applicationId input
  const getApplicationDetails = async (input: GetApplicationInput) => {
    return await apolloClient.query<
      { getApplication: GetApplicationOutput },
      QueryGetApplicationArgs
    >({
      query: getApplicationDetailsQuery,
      variables: { input },
      context: { pgePlus: true },
      fetchPolicy: 'no-cache',
    });
  };

  const cancelApplication = async (input: CancelApplicationInput) => {
    return apolloClient.mutate<
      { cancelApplication: CancelApplicationOutput },
      MutationCancelApplicationArgs
    >({
      mutation: cancelApplicationMutation,
      variables: { input },
      context: { pgePlus: true },
      errorPolicy: 'all',
    });
  };

  const acceptQuote = async (input: AcceptQuoteEvInput) => {
    return apolloClient.mutate<
      { acceptQuote: AcceptQuoteEvOutput },
      MutationAcceptQuoteEvArgs
    >({
      mutation: acceptQuoteMutation,
      variables: { input },
      context: { pgePlus: true },
      errorPolicy: 'all',
    });
  };

  const declineQuote = async (input: RejectQuoteInput) => {
    return apolloClient.mutate<
      { acceptQuote: RejectQuoteOutput },
      MutationRejectQuoteArgs
    >({
      mutation: rejectQuoteMutation,
      variables: { input },
      context: { pgePlus: true },
      errorPolicy: 'all',
    });
  };

  return {
    createApplication, //Mutation
    getApplicationsByAccount, //Query
    getApplicationsByAccountWithDetails, //Query
    getApplicationDetails, //Query
    cancelApplication, //Mutation
    acceptQuote, //Mutation
    declineQuote, //Mutation
  };
};

export default useEVApplicationApi;
