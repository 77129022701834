import gql from 'not-graphql-tag';

export const GET_ACCOUNT_DETAILS = gql`
  query GetAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      premiseInfo {
        encryptedPremiseId
        saDetails {
          encryptedSAId
          saStatus
        }
      }
    }
  }
`;

export const GET_RENEWABLES_ENROLLMENT_DETAILS = gql`
  query GetRenewablesEnrollmentDetails(
    $params: RenewablesEnrollmentDetailsParams!
  ) {
    getRenewablesEnrollmentDetails(params: $params) {
      greenFutureProgramDetails {
        isEnrolled
        cardType
        totalConsumption
        consumptionPercentage
      }
      habitatSupport {
        isEnrolled
      }
    }
  }
`;

export const GET_NET_METERING_DETAILS = gql`
  query GetNetMeteringDetails($params: NetMeteringDetailsParams!) {
    getNetMeteringDetails(params: $params) {
      application {
        status
        submitDate
      }
      isFirstBillGenerated
      monthlyBill {
        billedEnergyThisMonth
        consumption
        energySavingAccountBalance
        energySavingBalance
        excessGeneration
      }
    }
  }
`;

export const getTimeOfDayEnrollmentDetailsQuery = gql`
  query getTimeOfDayEnrollmentDetails(
    $params: TimeOfDayEnrollmentDetailsParams!
  ) {
    getTimeOfDayEnrollmentDetails(params: $params) {
      annualLookBackEarnedCredit
      cardType
      isEnrolled
      midPeakCharges
      offPeakCharges
      onPeakCharges
      planSavings
    }
  }
`;

export const getPeakTimeRebateEnrollmentDetails = gql`
  query getPeakTimeRebateEnrollmentDetails(
    $params: PeakTimeRebateEnrollmentDetailsParams!
  ) {
    getPeakTimeRebateEnrollmentDetails(params: $params) {
      enrollmentStatus
      cardType
      seasonalDates {
        summer {
          start
          end
        }
        winter {
          start
          end
        }
      }
      lastPTRSeason
      nextPTRSeason
      activePTRSeason
      totalEarnedCredit
      peakTimeEvents {
        eventDate
        eventEarnedCredit
      }
    }
  }
`;

export const getSmartThermostatEnrollmentDetailsQuery = gql`
  query GetSmartThermostatEnrollmentDetails(
    $params: SmartThermostatEnrollmentDetailsParams!
  ) {
    getSmartThermostatEnrollmentDetails(params: $params) {
      isEnrolled
      cardType
      lastSeason
      lastSeasonEarnedCredit
      seasonalDates {
        summer {
          end
          start
        }
        winter {
          end
          start
        }
      }
    }
  }
`;

export const getProgramsEnrollmentStatusDetailsQuery = gql`
  query GetProgramsEnrollmentStatusDetails(
    $params: ProgramsEnrollmentStatusDetailsParams!
  ) {
    getProgramsEnrollmentStatusDetails(params: $params) {
      onBillFlexLoadEarnings
      ytdFlexLoadEarnings
      energyShifting {
        isEnrolled
        programName
      }
      renewables {
        isEnrolled
        programName
      }
    }
  }
`;

export const getSmartChargingEnrollmentDetailsQuery = gql`
  query getSmartChargingEnrollmentDetails(
    $params: SmartChargingEnrollmentDetailsParams!
  ) {
    getSmartChargingEnrollmentDetails(params: $params) {
      enrollmentStatus
      cardType
      activeSeason {
        start
        end
        name
      }
      lastSeasonEarnedCredit
    }
  }
`;

export const GET_COMMUNITY_SOLAR_DETAILS = gql`
  query GetCommunitySolarDetails($params: CommunitySolarDetailsParams!) {
    getCommunitySolarDetails(params: $params) {
      isEnrolled
      cardType
      amountDetails {
        currentMonths
        amountType
        amount
      }
      chargesAndCredits {
        generationCredit
        subscriptionFee
        adminFee
        reconciliationFee
        total
      }
    }
  }
`;

export const GET_SMART_BATTERY_DETAILS = gql`
  query GetSmartBatteryDetails($params: SmartBatteryDetailsParams!) {
    getSmartBatteryDetails(params: $params) {
      isEnrolled
      cardType
      currentBillCreditAmount
      currentBillKwh
      ytdCreditAmount
      ytdKwh
      peakTimeSeason {
        seasonCategory
        season
        startDate
        endDate
      }
    }
  }
`;
