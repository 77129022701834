import React, { FC } from 'react';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import formatCurrency from 'format-currency';
import ROUTES from '../../../../routes';
import { useTranslation } from '../../../../hooks/useTranslation';
import PaperBox from '../../../components/../utility/paper-box';
import { PaymentExtensionCurrentPlan } from '../../../../__generated__/pge-types';
import { toDateString } from '../../../../util/format';
import ReactMarkdown from 'react-markdown/with-html';
import colors from '../../../../themes/main-colors';

interface Props {
  path?: string;
  currentPlan: PaymentExtensionCurrentPlan;
  hasAvailableOptions: boolean;
  onRequestMoretimeToPayClick: () => Promise<void>;
}

const useStyles = makeStyles((theme: Theme) => ({
  headingLabel: {
    color: colors.noirBlur,
  },
}));

const CurrentPlan: FC<Props> = (props: Props) => {
  const { t, richT } = useTranslation();
  const { currentPlan, hasAvailableOptions } = props;

  const classes = useStyles();

  return (
    <>
      <PaperBox>
        <Typography variant={'h2'} className={classes.headingLabel}>
          {t('CURRENT_PAYMENT_EXTENSION_NMTTP')}
        </Typography>
        <Typography component={'div'}>
          {richT('YOU_HAVE_AGREED_TO_PAY_NMTTP', {
            AMOUNT: `$${formatCurrency(currentPlan?.amountDue)}`,
            DATE: toDateString(currentPlan?.dueDate),
          })}
        </Typography>

        {hasAvailableOptions && (
          <>
            <Typography>
              <span
                className={'onClickLink'}
                onClick={async () => {
                  await props.onRequestMoretimeToPayClick();
                }}
              >
                {t('REQUEST_MORE_TIME_NMTTP')}
              </span>
            </Typography>
            <Typography component={'div'}>
              <ReactMarkdown
                source={t('IF_YOU_NEED_HELP_NMTTP', {
                  BILL_PAY_ASSISTANCE_ROUTE: ROUTES.BILL_PAYMENT_ASSISTANCE,
                })}
                escapeHtml={false}
              />
            </Typography>
          </>
        )}

        {!hasAvailableOptions && (
          <>
            <Typography>{t('NO_OTHER_OPTIONS_NMTTP')}</Typography>
            <Typography component={'div'}>
              {richT('FOR_ASSISTANCE_CALL', {
                CALL_FOR_ASSISTANCE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
              })}
            </Typography>
          </>
        )}
      </PaperBox>
    </>
  );
};

export default CurrentPlan;
