import gql from 'not-graphql-tag';

export const getAccounDetailsForCacheUpdate = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      preferredDueDate {
        dueDate {
          preferredDueDate
          status
          effectiveDate
        }
      }
    }
  }
`;
