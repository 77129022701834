import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import {
  Button,
  Card,
  CardContent,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { WizardFormProps } from '../utility/wizard/wizard.types';
import { ElectionWindowModel } from '../../hooks/useElectionWindow';
import dayjs from 'dayjs';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {
  DownloadOpts,
  useOpenPDFNewTab,
} from '../../hooks/useOpenPDFNewTab';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import PricingPlanSelector from './PricingPlanSelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      border: `1px solid ${theme.palette.success.main}`,
    },
    color: {
      color: theme.palette.success.main,
    },
    gridDivider: {
      margin: theme.spacing(1, 0),
      height: '2px',
    },
    margin0: {
      '& p': {
        margin: theme.spacing(0),
      }
    },
  }),
);

const PRINT_CONTAINER_ID = 'one-time-print-receipt';

const isDST = (d: Date) => {
  const jan = new Date(d.getFullYear(), 0, 1);
  const jul = new Date(d.getFullYear(), 6, 1);
  return d.getTimezoneOffset() < Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

export const ElectionWindowConfirmation = ({
  formState,
  componentProps,
}: WizardFormProps<ElectionWindowModel>) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const { changedAccounts, availablePlans, electionWindowStatus } = componentProps;
  const opts: DownloadOpts = {
    containerId: PRINT_CONTAINER_ID,
    excludeSelectors: ['[data-noprint]'],
    minWidth: theme.breakpoints.values['lg'],
  };
  const { openPDFInNewTab } = useOpenPDFNewTab(opts);
  const submittedDate = new Date();
  return (
    <>
      <Grid container direction="column" style={{ marginTop: '1em' }}>
        <Paper className={classes.border}>
          <Card>
            <CardContent style={{ padding: 16, marginBottom: 30 }}>
              <Grid container direction="column" spacing={2}>
                <Grid item container>
                  <Grid
                    item
                    container
                    xs={12}
                    spacing={2}
                    direction={'row'}
                    alignItems={'center'}
                  >
                    <Grid item>
                      <Typography
                        variant={'h2'}
                        className={classes.color}
                        style={{ paddingTop: '0.3em' }}
                      >
                        <CheckCircleIcon fontSize="inherit" data-testid={'check-circle-icon'}/>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant={'h2'} data-testid={'transaction-successful'}>
                        {t('HISTORICAL_USAGE_DONE_TRANSACTION_SUCCESSFUL')}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant={'body1'}>
                    {t('OPEN_ENROLLMENT_DONE_TEXT')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={'body1'}>
                    {t('OPEN_ENROLLMENT_DONE_NOTE').replace(
                      '<effective-date>',
                      dayjs(electionWindowStatus?.periodEffectiveDate).format('MMMM D'),
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    data-print-exclude
                    color="primary"
                    onClick={openPDFInNewTab}
                    endIcon={<SystemUpdateAltIcon />}
                    data-testid={'download-summary'}
                  >
                    <Typography>
                      {t('OPEN_ENROLLMENT_DONE_DOWNLOAD')}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Grid>

      {/* <Hidden > */}
        <Grid container direction={'column'} spacing={4} id={PRINT_CONTAINER_ID} style={{ display: 'none'}}>
          <Grid item>
            <Card>
              <CardContent>
                <Grid container direction={'column'} spacing={2}>
                  <Grid item>
                    <Typography variant={'h2'}>{t('OPEN_ENROLLMENT_ELECTION_WINDOW_SUMMARY_TITLE')}</Typography>
                  </Grid>
                  <Divider className={classes.gridDivider} />
                  <Grid item>
                    <Typography variant={'body1'} className={classes.margin0}>
                      { richT('OPEN_ENROLLMENT_REQ_SUBMITTED_TIME_TEXT', {
                        SUBMITTED_DATE: dayjs(submittedDate).format('M/D/YYYY'),
                        SUBMITTED_TIME: dayjs(submittedDate).format('HH:mm:ss'),
                        TIME_ZONE: isDST(submittedDate) ? 'Pacific Daylight Savings Time' : 'Pacific Standard Time'
                      })}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'body1'}>
                      {t('OPEN_ENROLLMENT_PRINT_SUMMARY_TEXT')}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'body1'}>
                      {t('OPEN_ENROLLMENT_DONE_NOTE').replace(
                        '<effective-date>',
                        dayjs(electionWindowStatus?.periodEffectiveDate).format('MMM. D, YYYY'),
                      )}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <PricingPlanSelector
                      accounts={changedAccounts}
                      editMode={false}
                      availablePlans={availablePlans}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      {/* </Hidden> */}
    </>
  );
};
