import { useApolloClient } from '@apollo/react-hooks';
import useAuthQuery from '../../hooks/useAuthQuery';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import {
  getTimeOfDayEnrollmnentStatus,
  getTimeOfDayInfo,
  submitTimeOfDayEnrollment,
  submitTimeOfDayUnenrollment,
} from '../../queries/timeOfDay.query';
import {
  AccountDetail,
  TimeOfDayEnrollmentConfirmation,
  TimeOfDayUnenrollmentConfirmation,
} from '../../__generated__/pge-types';

export default () => {
  const apolloClient = useApolloClient();
  const {
    loading: accountParamsLoading,
    accountParams,
  } = useSelectedAccountParams();

  const { data, loading: timeOfDayLoading } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getTimeOfDayInfo, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
  });

  const timeOfDayInfo = data?.getAccountDetails?.[0].timeOfDayInfo;

  async function submitEnrollment() {
    const { data: submitData, errors } = await apolloClient.mutate<{
      submitTimeOfDayEnrollment: TimeOfDayEnrollmentConfirmation;
    }>({
      mutation: submitTimeOfDayEnrollment,
      variables: { payload: accountParams },
      errorPolicy: 'all',
      refetchQueries: [
        {
          query: getTimeOfDayEnrollmnentStatus,
          variables: {
            params: {
              accountNumberList: [accountParams],
            },
          },
        },
      ],
      awaitRefetchQueries: true,
    });

    if (errors && errors.length > 0) {
      return Promise.reject('Mutation failed');
    }

    return submitData?.submitTimeOfDayEnrollment;
  }

  async function submitUnenrollment(unEnrollmentReasonCode: string) {
    const payload = {
      ...accountParams,
      unEnrollmentReasonCd: unEnrollmentReasonCode,
    };

    const { data: submitData, errors } = await apolloClient.mutate<{
      submitTimeOfDayUnenrollment: TimeOfDayUnenrollmentConfirmation;
    }>({
      mutation: submitTimeOfDayUnenrollment,
      variables: { payload },
      errorPolicy: 'all',
      refetchQueries: [
        {
          query: getTimeOfDayEnrollmnentStatus,
          variables: {
            params: {
              accountNumberList: [accountParams],
            },
          },
        },
      ],
      awaitRefetchQueries: true,
    });

    if (errors && errors.length > 0) {
      return Promise.reject('Mutation failed');
    }

    return submitData?.submitTimeOfDayUnenrollment;
  }

  return {
    loading: accountParamsLoading || timeOfDayLoading,
    timeOfDayInfo,
    submitEnrollment,
    submitUnenrollment,
  };
};
