import React, { FunctionComponent } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Theme,
  useTheme,
  Card,
  CardContent,
} from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import useOpenEnrollment from '../../../hooks/useOpenEnrollment';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import PGEButton from '../../buttons/PGE-Button';
import { isMobile } from '../../../util/style-utils';
import { navigate } from '@reach/router';
import ROUTES from '../../../routes';
import TextLink from '../../text-link';
import Alerts from '../../alerts';

import dayjs from 'dayjs';

const useStyles = makeStyles((theme: Theme) => ({
  actionArea: {
    [theme.breakpoints.down('sm')]: {
      padingLeft: theme.spacing(4),
      padingRight: theme.spacing(4),
    },
  },
  formAction: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const AlertMessage = () => {
  const { content, effectiveDate } = useOpenEnrollment();

  return (
    <>
      <Grid item>
        <Typography variant={'body1'}>{content.donePage.doneText}</Typography>
      </Grid>
      <Grid item>
        <Typography variant={'body2'}>
          {content.donePage.doneNote.replace(
            '<effective-date>',
            dayjs(effectiveDate).format(content.dateFormat),
          )}
        </Typography>
      </Grid>
      <Grid item>
        <TextLink external plain to={''} download={'change_enrollment.pdf'}>
          <Grid item container direction={'row'} spacing={1}>
            <Grid item>
              <Typography color={'primary'} variant={'body2'}>
                {content.donePage.doneDownload}
              </Typography>
            </Grid>
            <Grid item>
              <PictureAsPdfIcon color={'primary'} />
            </Grid>
          </Grid>
        </TextLink>
      </Grid>
    </>
  );
};

interface OpenEnrollmentDoneProps {
  path?: string;
}

const OpenEnrollmentDone: FunctionComponent<OpenEnrollmentDoneProps> = props => {
  const { t } = useTranslation();
  const { content } = useOpenEnrollment();
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Grid item>
        <Typography variant={'h2'}>{content.donePage.title}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
      >
        <Grid container spacing={2} direction={'column'}>
          <Alerts
            disableColorOverride={true}
            severity={'success'}
            variant={'outlined'}
            title={content.donePage.doneTitle}
            message={<AlertMessage />}
            isOpen
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        style={
          isMobile()
            ? { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }
            : {}
        }
        direction={'column'}
        alignItems={isMobile() ? 'stretch' : 'flex-end'}
      >
        <Grid item>
          <PGEButton
            variant={'contained'}
            color={'primary'}
            onClick={() => navigate(ROUTES.ACCOUNT)}
            className={classes.formAction}
          >
            {content.done}
          </PGEButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default OpenEnrollmentDone;
