import React, { FunctionComponent, useState, useContext } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Theme,
  useTheme,
  Card,
  CardContent,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import useOpenEnrollment from '../../../hooks/useOpenEnrollment';
import Link from '../../text-link';
import PGEButton from '../../buttons/PGE-Button';
import { isMobile } from '../../../util/style-utils';
import { navigate } from '@reach/router';
import ROUTES from '../../../routes';
import ContactInfoForm from '../../contact-info-form';
import useContactInfoForm, {
  mapFormDataToContactInfo,
} from '../../../hooks/useContactInfoForm';
import {
  DirectAccessContext,
  DirectAccessContextActionType,
} from '../../../providers/DirectAccessProvider';
import { ContactInfo } from '../../contact-info-form/ContactInfoForm.types';
import ContactInfoFormRules from './ContactInfoForm.rules';

const useStyles = makeStyles((theme: Theme) => ({
  actionArea: {
    [theme.breakpoints.down('sm')]: {
      padingLeft: theme.spacing(4),
      padingRight: theme.spacing(4),
    },
  },
  formAction: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface OpenEnrollmentContactProps {
  path?: string;
}

const OpenEnrollmentContact: FunctionComponent<OpenEnrollmentContactProps> = props => {
  const { t } = useTranslation();
  const { content } = useOpenEnrollment();
  const theme = useTheme();
  const classes = useStyles();
  const { state, dispatch } = useContext(DirectAccessContext);
  const [isSamePersonAuthorizing, setIsSamePersonAuthorizing] = useState(
    state.isSamePersonAuthorizing,
  );
  const submittingContactInfoFormProps = useContactInfoForm(
    ContactInfoFormRules,
    state.submittingContact,
  );
  const authorizingContactInfoFormProps = {
    isSamePersonAuthorizing,
    setIsSamePersonAuthorizing,
    ...useContactInfoForm(ContactInfoFormRules, state.authorizingContact),
  };

  const handleNext = () => {
    const {
      data: submittingContactData,
      errors: submitContactErrors,
    } = submittingContactInfoFormProps.handleSubmit();
    if (submitContactErrors?.length) {
      return;
    }

    const submittingContact: ContactInfo = mapFormDataToContactInfo(
      submittingContactData,
    );

    let authorizingContact: ContactInfo | undefined = undefined;

    if (!isSamePersonAuthorizing) {
      const {
        data: authorizingContactData,
        errors: authorizingContactErrors,
      } = authorizingContactInfoFormProps.handleSubmit();
      if (authorizingContactErrors?.length) {
        return;
      }
      authorizingContact = mapFormDataToContactInfo(authorizingContactData);
    }

    dispatch({
      type: DirectAccessContextActionType.SET_CONTACT_INFO,
      submittingContact,
      isSamePersonAuthorizing,
      authorizingContact,
    });
    return navigate(ROUTES.OPEN_ENROLLMENT_PLAN);
  };

  return (
    <Grid container direction={'column'} spacing={4}>
      <Grid item>
        <Typography variant={'h2'}>{content.contactPage.title}</Typography>
      </Grid>
      <Grid item>
        <SubmittingContactInformation {...submittingContactInfoFormProps} />
      </Grid>
      <Grid item>
        <AuthorizingContactInformation {...authorizingContactInfoFormProps} />
      </Grid>
      <Grid
        item
        container
        style={
          isMobile()
            ? { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }
            : {}
        }
        direction={isMobile() ? 'column-reverse' : 'row'}
        justify={'space-between'}
        alignItems={isMobile() ? 'stretch' : 'center'}
        spacing={1}
      >
        <Grid item>
          <Grid container justify={'center'}>
            <Link plain to={ROUTES.ACCOUNT}>
              <Typography className={classes.formAction}>
                {t('CANCEL')}
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction={isMobile() ? 'column-reverse' : 'row'}
            spacing={2}
          >
            <Grid item>
              <PGEButton
                variant={'outlined'}
                color={'secondary'}
                onClick={() => navigate(ROUTES.OPEN_ENROLLMENT_ELECTION)}
                className={classes.formAction}
              >
                {content.back}
              </PGEButton>
            </Grid>
            <Grid item>
              <PGEButton
                variant={'contained'}
                color={'primary'}
                onClick={handleNext}
                className={classes.formAction}
              >
                {content.next}
              </PGEButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SubmittingContactInformation: FunctionComponent = (props: any) => {
  const { content, selectedGroup } = useOpenEnrollment();

  return (
    <Card>
      <CardContent>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <Typography variant={'h2'}>
              {content.contactPage.contactInformation.title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body2'}>
              {content.contactPage.contactInformation.text.replace(
                '<account-group-name>',
                selectedGroup.labels[0].primary || '',
              )}
            </Typography>
          </Grid>
          <Grid item>
            <ContactInfoForm {...props} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const AuthorizingContactInformation: FunctionComponent = (props: any) => {
  const { content } = useOpenEnrollment();
  const { isSamePersonAuthorizing, setIsSamePersonAuthorizing } = props;

  return (
    <Card>
      <CardContent>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <Typography variant={'h2'}>
              {content.contactPage.contactAuthorization.title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body2'}>
              {content.contactPage.contactAuthorization.text}
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSamePersonAuthorizing}
                  onChange={e => setIsSamePersonAuthorizing(e.target.checked)}
                  name="samePersonAuthorizing"
                  color="primary"
                />
              }
              label={
                content.contactPage.contactAuthorization.samePersonAuthorizing
              }
            />
          </Grid>
          {!isSamePersonAuthorizing && (
            <Grid item>
              <ContactInfoForm {...props} />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OpenEnrollmentContact;
