import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import { useTranslation } from '../../hooks/useTranslation';
import { Grid, Card, CardContent } from '@material-ui/core';
import { Model } from './types';
import { toDateStringFullMonthName } from '../../util/format';
import { parse } from 'date-fns';
import colors from '../../themes/main-colors';
import TextLink from '../text-link';
import PGEButton from '../buttons';
import { useIsMobile } from '../../util/style-utils';
import ROUTES from '../../routes';
import { AccountDetail } from '../../__generated__/pge-types';

type Props = {
  data: Model;
  path?: string;
  accountDetails: AccountDetail | undefined;
};

export default function StopServiceConfirmation({
  data,
  accountDetails,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const theme = useTheme();

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h2">
                {t('WE_HAVE_RECIEVED_YOUR_STOP_REQUEST')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {t('WE_WILL_STOP_YOUR_SERVICE_ON')}{' '}
                <strong>
                  {toDateStringFullMonthName(
                    parse(data.stopDate, 'MM/dd/yyyy', new Date(0)),
                  )}
                </strong>
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(3),
            }}
          >
            {accountDetails?.pendingDisconnect?.isPendingDisconnect && (
              <Typography
                variant="body1"
                style={{
                  color: colors.roseRed,
                  marginBottom: theme.spacing(2),
                }}
              >
                {t('STOP_SERVICE_PENDING_DISCONNECT_CONDITIONAL')}
              </Typography>
            )}
            {accountDetails?.autoPay?.isEnrolled && (
              <Typography variant="body1">
                {t('AUTO_PAY_STOP_SERVICE_CONDITIONAL')}
              </Typography>
            )}
            {accountDetails?.equalpay?.paymentPlanType !== 'RegularPay' && (
              <Typography variant="body1">
                {t('EQUAL_PAY_STOP_SERVICE_CONDITIONAL')}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} style={{ marginTop: theme.spacing(2) }}>
            <Typography variant="body1" component="div">
              {t('CALL_FOR_ASSISTANCE') + ' '}
              <TextLink external to={`tel:${t('CALL_FOR_ASSISTANCE_NUMBER')}`}>
                {t('CALL_FOR_ASSISTANCE_NUMBER')}
              </TextLink>
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: theme.spacing(2) }}>
            <Typography variant="body1" component="div">
              {t('CALL_FOR_ASSISTANCE_BIZ') + ' '}
              <TextLink
                external
                to={`tel:${t('CALL_FOR_ASSISTANCE_BIZ_NUMBER')}`}
              >
                {t('CALL_FOR_ASSISTANCE_BIZ_NUMBER')}
              </TextLink>
            </Typography>
          </Grid>
        </CardContent>
      </Card>
      <Grid
        container
        direction="row"
        justify={isMobile ? 'center' : 'flex-end'}
        spacing={1}
        wrap="wrap-reverse"
        style={{ marginTop: theme.spacing(3) }}
      >
        <Grid item xs={10} md="auto">
          <PGEButton fullWidth onClick={() => navigate(ROUTES.ACCOUNT)}>
            {t('OK')}
          </PGEButton>
        </Grid>
      </Grid>
    </>
  );
}
