import { DefaultFormValuesProps } from '../../hooks/useValidationForm';
import {
  validateAmount,
  validateBankAccount,
  validateConfirmBankAccount,
  validatePhoneNumber,
  validateRoutingNumber,
} from '../../util/form-validation';

export default (_: any, formState: DefaultFormValuesProps | any) => ({
  // this must match the names of the form fields
  paymentAmount: validateAmount,
  phoneNumber: validatePhoneNumber,
  routingNumber: validateRoutingNumber,
  bankAccountNumber: validateBankAccount.bind(null, formState),
  confirmBankAccountNumber: validateConfirmBankAccount.bind(null, formState),
});
