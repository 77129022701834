import {
  validateAccountNumber,
  validateNicknameField,
} from '../../util/form-validation';

export const validateNickNameCheckBox = (event: any) => {
  return null;
};

export default () => ({
  // this must match the names of the form fields
  accountNumber: validateAccountNumber,
  nickname: validateNicknameField.bind(null, 'nickname'),
  nickNameCheckBox: validateNickNameCheckBox,
  type: () => null,
});
