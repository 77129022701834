/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { Fragment } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { ProceedOrCancelButton } from '../../../../pge-plus-common/StepperForm';
import { Box, FormHelperText, Grid } from '@material-ui/core';
import { GridItem } from '../../../../pge-plus-common/Grid';
import { Question } from '../../../../pge-plus-common/Typography';
import useFormState from '../../../../../hooks/useFormState';
import { connectChargerToWifiValidateFunction } from '../rebateOnlyForm.rules';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import {
  ConnectChargerToWifiType,
  RebateQualification,
  UpdateSectionErrorCode,
} from '../rebateOnlyForm.types';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import RadioButtonGroup from '../../../../pge-plus-common/RadioButtonGroup';
import { SaveAndComeBackLink } from '../../../../pge-plus-common/SaveAndComeBackLink';
import routes from '../../../../../routes';
import { useRebateOnlyFormState } from '../../../../../providers/RebateOnlyFormStateProvider';
import {
  ApplicationErSectionType,
  ErRebateQualification,
  SectionErRebateConfirm,
} from '../../../../../__generated__/pgeplus-types';
import useUpdateERSections from '../../../../../hooks/pge-plus/useUpdateERSections';
import useWrapWithLoader from '../../../../../hooks/useWrapWithLoading';
import { rebateConfirmERFormStateAdapter } from '../rebateOnlyFormState.adapter';
import useUtil from '../../../../need-more-time-to-pay/useUtil';

const ConnectChargerToWifi: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_CHARGER_CONNECT_TO_WIFI,
  );
  const {
    formState: { rebateConfirm, application },
    getSectionData,
    updateFormState,
  } = useRebateOnlyFormState();
  const form = useFormState(
    rebateConfirm.rebateQualification!, //TODO: This should be taken from the global app state
    {
      validate: connectChargerToWifiValidateFunction(),
      validationContext: {
        content,
      },
    },
  );

  const { wrapWithLoader } = useWrapWithLoader();

  const sectionDataRebateConfirm: SectionErRebateConfirm = getSectionData(
    ApplicationErSectionType.ErRebateConfirm,
  );
  const { updateSectionRebateConfirm } = useUpdateERSections();
  const { setErrorNotification } = useUtil();
  const onSubmit = wrapWithLoader(async (data: RebateQualification) => {
    try {
      const response = await updateSectionRebateConfirm(
        rebateConfirmERFormStateAdapter().fromFormState(
          {
            ...rebateConfirm,
            rebateQualification: {
              ...data,
              doDriveTesla: !data?.wifiAvailable
                ? data?.doDriveTesla
                : undefined,
              haveChargerActivated: data?.wifiAvailable
                ? data?.haveChargerActivated
                : undefined,
            },
            connectTeslaToEVPulse: undefined,
            evPulse: data?.wifiAvailable ? undefined : rebateConfirm.evPulse,
            chargerInstallInfo: {
              ...rebateConfirm.chargerInstallInfo,
              installationCost:
                rebateConfirm.preRebateEligibility2?.evRebate?.__typename ===
                'RebateBYOC'
                  ? undefined
                  : rebateConfirm.chargerInstallInfo?.installationCost,
              chargerCost:
                rebateConfirm.preRebateEligibility2?.evRebate?.__typename ===
                'RebateBYOC'
                  ? undefined
                  : rebateConfirm.chargerInstallInfo?.chargerCost,
              receiptImages:
                rebateConfirm.preRebateEligibility2?.evRebate?.__typename ===
                'RebateBYOC'
                  ? undefined
                  : rebateConfirm.chargerInstallInfo?.receiptImages,
            },
          },
          application?.id!,
          sectionDataRebateConfirm?.id,
        ),
      );

      // Save the response in a local var
      const result = response?.data?.updateSectionERRebateConfirm;

      // Check for errors to show the red banner and block navigation
      if (response?.errors || result?.validation?.errors) {
        throw new Error();
      }

      //Update the app state with the user selected values
      if (result?.section?.data) {
        const responseData = result?.section?.data;
        const updatedValues = rebateConfirmERFormStateAdapter().toFormState(
          responseData,
        );

        updateFormState('rebateConfirm', updatedValues);
      }

      // Check for section errors to navigate to a specific fiddlesticks page based on the error code
      if (result?.section.errors.length) {
        const unableToConnectApp =
          result?.section.errors.filter(
            error => error.code === UpdateSectionErrorCode.UnableToConnectApp,
          ).length > 0;
        if (unableToConnectApp) {
          void navigate(routes.PGE_PLUS_REBATE_ONLY_INELIGIBLE, {
            state: {
              title: 'REBATE_ELIGIBILITY_WIFI_FLOW_DRIVE_NO_TESLA_TITLE',
              message: 'REBATE_ELIGIBILITY_WIFI_FLOW_DRIVE_NO_TESLA_TEXT',
              showBackButton: true,
            },
          });
          return;
        }
      }

      if (!response.data?.updateSectionERRebateConfirm.section.errors.length) {
        if (data?.wifiAvailable === false) {
          void navigate(routes.PGE_PLUS_REBATE_ONLY_EVPULSE_ELIGIBLE);
        } else {
          if (!data?.haveChargerActivated) {
            void navigate(routes.PGE_PLUS_REBATE_ONLY_CHARGER_INSTRUCTIONS);
          } else {
            void navigate(routes.PGE_PLUS_REBATE_ONLY_REBATE_RESULTS);
          }
        }
      } else {
        throw new Error();
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      const response = await updateSectionRebateConfirm({
        ...rebateConfirmERFormStateAdapter().fromFormState(
          {
            ...rebateConfirm,
            rebateQualification: {
              ...form.values,
              doDriveTesla: !form.values?.wifiAvailable
                ? form.values?.doDriveTesla
                : undefined,
              haveChargerActivated: form.values?.wifiAvailable
                ? form.values?.haveChargerActivated
                : undefined,
            },
            connectTeslaToEVPulse: undefined,
            evPulse: form.values?.wifiAvailable
              ? undefined
              : rebateConfirm.evPulse,
          },
          application?.id!,
          sectionDataRebateConfirm?.id,
        ),
        saveAndComeBack: true,
      });
      if (
        response?.errors ||
        response.data?.updateSectionERRebateConfirm?.validation?.errors ||
        response.data?.updateSectionERRebateConfirm?.section?.errors.length !==
          0
      ) {
        throw new Error();
      } else {
        void navigate(routes.ACCOUNT);
      }
    } catch {
      setErrorNotification(true);
    }
  });

  return (
    <Fragment>
      <form
        onSubmit={form.submit(values => onSubmit(values), console.log)}
        noValidate
      >
        <Grid
          container
          css={{
            rowGap: 24,
          }}
        >
          <GridItem>
            <Question
              title={content.get('HAVE_WIFI_TEXT')}
              popoverContent={content.get('WIFI_LEARN_MORE_TOOLTIP')}
              popoverLinkContent={content.get('REUSABLE_LEARN_MORE')}
            />
          </GridItem>
          <GridItem>
            <Box
              css={{
                width: 'max-content',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <RadioButtonGroup
                options={[
                  {
                    label: content.get('REUSABLE_YES') || '',
                    value: true,
                  },
                  {
                    label: content.get('REUSABLE_NO') || '',
                    value: false,
                  },
                ]}
                state={form.values?.wifiAvailable}
                setState={e => form.setValue('wifiAvailable', e)}
              />
              {form.errors?.wifiAvailable && (
                <FormHelperText error>
                  {form.errors?.wifiAvailable}
                </FormHelperText>
              )}
            </Box>
          </GridItem>
          {form.values?.wifiAvailable !== undefined &&
            form.values?.wifiAvailable === true && (
              <Grid
                container
                css={{
                  rowGap: 24,
                }}
              >
                <GridItem>
                  <Question
                    title={content.get('ACTIVATED_CHARGER_YES_TEXT')}
                    popoverContent={content.get('WIFI_LEARN_MORE_TOOLTIP')}
                    popoverLinkContent={content.get('REUSABLE_LEARN_MORE')}
                  />
                </GridItem>
                <GridItem>
                  <Box
                    css={{
                      width: 'max-content',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <RadioButtonGroup
                      options={[
                        {
                          label: content.get('REUSABLE_YES') || '',
                          value: true,
                        },
                        {
                          label: content.get('REUSABLE_NO') || '',
                          value: false,
                        },
                      ]}
                      state={form.values.haveChargerActivated}
                      setState={e => form.setValue('haveChargerActivated', e)}
                    />
                    {form.errors.haveChargerActivated && (
                      <FormHelperText error>
                        {form.errors.haveChargerActivated}
                      </FormHelperText>
                    )}
                  </Box>
                </GridItem>
              </Grid>
            )}
          {form.values?.wifiAvailable !== undefined &&
            form.values?.wifiAvailable === false && (
              <Grid
                container
                css={{
                  rowGap: 24,
                }}
              >
                <GridItem>
                  <Question
                    title={content.get('ACTIVATED_CHARGER_NO_TEXT')}
                    popoverContent={content.get('WIFI_LEARN_MORE_TOOLTIP')}
                    popoverLinkContent={content.get('REUSABLE_LEARN_MORE')}
                  />
                </GridItem>
                <GridItem>
                  <Box
                    css={{
                      width: 'max-content',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                    }}
                  >
                    <RadioButtonGroup
                      options={[
                        {
                          label: content.get('REUSABLE_YES') || '',
                          value: true,
                        },
                        {
                          label: content.get('REUSABLE_NO') || '',
                          value: false,
                        },
                      ]}
                      state={form.values?.doDriveTesla}
                      setState={e => form.setValue('doDriveTesla', e)}
                    />
                    {form.errors?.doDriveTesla && (
                      <FormHelperText error>
                        {form.errors?.doDriveTesla}
                      </FormHelperText>
                    )}
                  </Box>
                </GridItem>
              </Grid>
            )}
          <GridItem>
            <ProceedOrCancelButton
              back={routes.PGE_PLUS_REBATE_ONLY_ELIGIBILITY_CONFIRMATION}
              backRouteState={{
                message:
                  rebateConfirm.preRebateEligibility2?.evRebate?.__typename ===
                  'RebateBYOC'
                    ? 'EV_PULSE_REBATE_BYOC'
                    : rebateConfirm.preRebateEligibility2?.evRebate
                        ?.__typename === 'RebateSmartCharging' &&
                      rebateConfirm.preRebateEligibility2?.evRebate
                        ?.isHigherDiscount
                    ? 'REBATE_ONLY_REBATE_RESULTS_AFTER_QPL_IQBD'
                    : 'REBATE_ONLY_REBATE_RESULTS_AFTER_QPL_STANDARD',
                backButtonRoute:
                  rebateConfirm.preRebateEligibility2?.evRebate?.__typename ===
                  'RebateBYOC'
                    ? routes.PGE_PLUS_REBATE_ONLY_CHARGER_INFORMATION
                    : routes.PGE_PLUS_REBATE_ONLY_CHARGER_INSTALLATION_COSTS,
                nextButtonRoute: routes.PGE_PLUS_REBATE_ONLY_CONNECT_CHARGER,
              }}
              disable={
                form.values?.wifiAvailable === undefined ||
                form.values?.wifiAvailable === null ||
                (form.values?.wifiAvailable &&
                  form.values?.haveChargerActivated === undefined) ||
                form.values?.haveChargerActivated === null ||
                (!form.values?.wifiAvailable &&
                  (form.values?.doDriveTesla === undefined ||
                    form.values?.doDriveTesla === null))
              }
            />
          </GridItem>
        </Grid>
        <SaveAndComeBackLink
          content={content}
          onSaveAndExit={handleSaveAndExit}
        />
      </form>
    </Fragment>
  );
};

export default ConnectChargerToWifi;
