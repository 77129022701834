import gql from 'not-graphql-tag';
import { AdditionalInfo } from '../__generated__/pge-types';
import useAuthQuery from './useAuthQuery';

const getCoCustomerDetails = gql`
  query getCoCustomerDetails($encryptedPersonId: String) {
    getCoCustomerPersonDetails(encryptedPersonId: $encryptedPersonId) {
      dateOfBirth
      primaryPhone
      emailAddress
      password
      socialSecurityNumber
      alternatePhoneInfo {
        alternatePhoneNumber
        alternatePhoneType
        alternatePhoneText
      }
      mailingAddress {
        addressLine1
        city
        state
        postal
      }
      previousAddress {
        addressLine1
        city
        state
        postal
      }
      federalInformation {
        federalIDType
        federalIDNumber
      }
      stateInformation {
        stateIdentificationType
        stateIDNumber
        stateIDState {
          code
          name
          country
        }
      }
      hasSocialSecurityInfo
      hasStateInfo
      hasFederalInfo
      mailingAndServiceAddressesSame
      hasExistingPrimaryIdentification
    }
  }
`;

export default function useGetCoCustomerPersonDetails(
  encryptedPersonId: string | undefined,
) {
  const { data, loading } = useAuthQuery<
    {
      getCoCustomerPersonDetails: AdditionalInfo;
    },
    {
      encryptedPersonId: string;
    }
  >(getCoCustomerDetails, {
    variables: {
      encryptedPersonId: encryptedPersonId || '',
    },
    skip: !encryptedPersonId,
  });

  const coCustomerDetails = data?.getCoCustomerPersonDetails;

  return {
    coCustomerDetails,
    loading,
  };
}
