/**
 * For the Contentful Editors Team:
 * --------------------------------
 * There are two possible workflows:
 * 1. The workflow ONE would be:
 *      a. Unpublish the old modal parent.
 *      b. Create a new modal parent.
 *      c. Add new pages to the new modal parent.
 *      d. Publish the new modal and its pages.
 *
 * 2. The workflow TWO would be:
 *      a. Unpublish the old modal parent __pages__.
 *      b. Add new pages to the old modal parent.
 *      c. Publish the old modal parent and its new pages.
 *
 * <---------------------------------------------------------------->
 *
 * For the Technical Team:
 * -----------------------
 * 1. The logic below concats the parent contentful_id
 *    with all the page contentful_ids for the English locale.
 * 2. If the stored value matches what is currently published,
 *     then it does nothing.
 * 3. If the stored value does not match what is currently published,
 *    then it stores the new concatenated published keys value
 *    and deletes the local storage key that was set
 *    when the modal was dismissed.
 *
 *<---------------------------------------------------------------->

 * Note:
 * -----
 * The key also gets removed when the order of the pages is changed.
 * This is intentional.
 */

import { DEFAULT_LANGUAGE } from '../../../providers/LanguageProvider';
import { removeFromStorage, setInStorage } from '../../../util/storage-utils';
import DashboardInterceptQuery from '../dashboardIntercept.query';
import storage from './storageUtils';

export const resetDashboardIntercept = () => {
  const { allContentfulSecureSiteModal } = DashboardInterceptQuery();
  const contentfulSecureSiteModal = allContentfulSecureSiteModal.nodes.filter(
    node => node.node_locale === DEFAULT_LANGUAGE,
  )[0];

  const publishedItemsKeys = contentfulSecureSiteModal?.pages?.reduce(
    (allIds: string, page) => {
      return allIds + page?.contentful_id;
    },
    contentfulSecureSiteModal.contentful_id,
  );

  if (
    storage.resetDashboardInterceptStorageKeyExists() &&
    storage.resetDashboardInterceptStorageKeyExists() === publishedItemsKeys
  ) {
    return;
  } else {
    setInStorage(
      storage.RESET_ACCOUNT_DASHBOARD_INTERCEPT_MODAL_STORAGE_KEY,
      publishedItemsKeys,
    );
    removeFromStorage(storage.ACCOUNT_DASHBOARD_INTERCEPT_MODAL_STORAGE_KEY);
  }
};
