import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Paper } from '@material-ui/core';
import { PersonalIdentificationType } from '../../__generated__/pge-types';
import { useTranslation } from '../../hooks/useTranslation';
import { BaseFormModel } from '../../hooks/useFormState.types';
import Backdrop from '../backdrop';
import MoveServiceDetailsReview from './review/MoveServiceDetailsReview';
import MoveServiceCustomerReview from './review/MoveServiceCustomerReview';
import MoveServiceCoCustomerReview from './review/MoveServiceCoCustomerReview';
import colors from '../../themes/main-colors';
import { NextBackOrCancel } from '../next-back-or-cancel';
import ROUTES from '../../routes';

type MoveServiceReviewFormProps = {
  accountDetails: any;
  coCustomerData: any;
  coCustomerInfoForm: BaseFormModel;
  customerInfoForm: BaseFormModel;
  form: BaseFormModel;
  goToStep: (step: number) => Promise<void>;
  handleReviewSubmit: (values: any) => Promise<void>;
  isRenewablesEligible: boolean;
  isSubmitting: boolean;
  mainCustomerInfoForm: BaseFormModel;
  path: string;
  primaryIdType: PersonalIdentificationType | undefined;
  serviceDetailsForm: BaseFormModel;
  serviceOptionsForm: BaseFormModel;
  useAltMailingAddress: boolean;
};

export const MoveServiceReview = ({
  accountDetails,
  coCustomerData,
  coCustomerInfoForm,
  customerInfoForm,
  form,
  goToStep,
  handleReviewSubmit,
  isRenewablesEligible,
  isSubmitting,
  mainCustomerInfoForm,
  primaryIdType,
  serviceDetailsForm,
  serviceOptionsForm,
  useAltMailingAddress,
}: MoveServiceReviewFormProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    form.setValue('startAgreed', false);
  }, []);

  return (
    <>
      <Backdrop
        forceOpen={isSubmitting}
        message={t('THIS_WILL_TAKE_A_BIT_DO_NOT_REFRESH_YOUR_SCREEN')}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ marginBottom: theme.spacing(2) }}>
          <Typography variant="h2">
            {t('PLEASE_MAKE_SURE_EVERYTHING_IS_CORRECT')}
          </Typography>
        </Grid>
        <Paper style={{ padding: 30, color: colors.noirBlur }}>
          <MoveServiceDetailsReview
            serviceDetailsForm={serviceDetailsForm}
            goToStep={goToStep}
            accountDetails={accountDetails}
          />

          <MoveServiceCustomerReview
            serviceDetailsForm={serviceDetailsForm}
            customerInfoForm={customerInfoForm}
            primaryIdType={primaryIdType}
            useAltMailingAddress={useAltMailingAddress}
            goToStep={goToStep}
            accountDetails={accountDetails}
          />

          {mainCustomerInfoForm.values.encryptedPersonId && (
            <MoveServiceCoCustomerReview
              form={mainCustomerInfoForm}
              goToStep={goToStep}
              coCustomerType="main"
              coCustomerData={coCustomerData}
            />
          )}

          {coCustomerInfoForm.values.encryptedPersonId && (
            <MoveServiceCoCustomerReview
              goToStep={goToStep}
              form={coCustomerInfoForm}
              coCustomerType="co"
              coCustomerData={coCustomerData}
            />
          )}

          <Grid
            container
            spacing={3}
            style={{
              borderTop: `1px solid ${colors.lightGray1}`,
              marginTop: 30,
            }}
          >
            <Grid item xs={12}>
              <Typography variant="h2">{t('AUTHORIZE_SERVICE')}</Typography>
            </Grid>
            <Grid item style={{ width: 50 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={'startAgreed'}
                    value={form.values.startAgreed}
                    color={'primary'}
                    onChange={form.onChange}
                  />
                }
                label={''}
              />
            </Grid>
            <Grid item md={11} style={{ paddingTop: 20 }}>
              {!mainCustomerInfoForm.values.encryptedPersonId &&
                !coCustomerInfoForm.values.encryptedPersonId && (
                  <>
                    <Typography variant="body2">
                      {t('START_SERVICE_AGREEMENT_NO_CO_CUSTOMER')}
                    </Typography>
                    {serviceOptionsForm.values.peakTimeEnroll && (
                      <Typography variant="body2" style={{ marginTop: 16 }}>
                        <strong>
                          {t('I_AGREE_TO_THE')}{' '}
                          <a
                            href={ROUTES.PTR_TERMS_AND_CONDITIONS}
                            target="_blank"
                            rel="noopener"
                          >
                            {t('PTR_TERMS_AND_CONDITIONS')}
                          </a>
                        </strong>
                      </Typography>
                    )}
                    <Typography variant="body2" style={{ marginTop: 16 }}>
                      {t('FCRA_AGREEMENT_FOR_SSM_NO_CO_CUSTOMER', {
                        type: 'Move',
                      })}
                    </Typography>
                  </>
                )}
              {(mainCustomerInfoForm.values.encryptedPersonId ||
                coCustomerInfoForm.values.encryptedPersonId) && (
                <>
                  <Typography variant="body2">
                    {t('START_SERVICE_AGREEMENT_WITH_CO_CUSTOMER')}
                  </Typography>
                  {serviceOptionsForm.values.peakTimeEnroll && (
                    <Typography variant="body2" style={{ marginTop: 16 }}>
                      <strong>
                        {t('WE_AGREE_TO_THE')}{' '}
                        <a
                          href={ROUTES.PTR_TERMS_AND_CONDITIONS}
                          target="_blank"
                          rel="noopener"
                        >
                          {t('PTR_TERMS_AND_CONDITIONS')}
                        </a>
                      </strong>
                    </Typography>
                  )}
                  <Typography variant="body2" style={{ marginTop: 16 }}>
                    {t('FCRA_AGREEMENT_FOR_SSM_WITH_CO_CUSTOMER', {
                      type: 'Move',
                    })}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <div style={{ margin: 25 }}>
        <NextBackOrCancel
          isFinalStep
          backHandler={() => goToStep(3)}
          cancelRoute={ROUTES.ACCOUNT}
          nextDisabled={!form.values.startAgreed}
          nextHandler={form.submit(handleReviewSubmit)}
          nextLabel={t('MOVE_SERVICE')}
        />
      </div>
    </>
  );
};
