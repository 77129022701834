import { Router } from '@reach/router';
import React from 'react';
import ROUTES from '../../routes';
import AutoPay from './auto-pay/index';
import CancelOnlineServicePage from './cancel-online-service/index';
import ChangeEmailPage from './change-email';
import ChangePasswordPage from './change-password';
import ChangePinPage from './change-pin';
import PaperlessPage from './paperless';
import PreferredDueDayPage from './preferred-due-day/index';
import UpdateInfoPage from './update-info';
import UpdatePersonalInfoPage from './update-personal-information';
import PrivateRoute from '../../components/routing/PrivateRoute';
import NotFoundPage from '../../pages/404';
import MultiFactorEnrollmentPage from './two-step-authentication';

export const ProfileRouter = () => {
  return (
    <Router basepath="/">
      <AutoPay path={`${ROUTES.AUTO_PAY}/*`} />
      <CancelOnlineServicePage path={`${ROUTES.CANCEL_ONLINE_SERVICE}/*`} />
      <ChangeEmailPage path={ROUTES.CHANGE_EMAIL} />
      <ChangePasswordPage path={ROUTES.CHANGE_PASSWORD} />
      <ChangePinPage path={ROUTES.CHANGE_PIN} />
      <PaperlessPage path={`${ROUTES.PAPERLESS_BILLING}/*`} />
      <PreferredDueDayPage path={`${ROUTES.PREFERRED_DUE_DAY}/*`} />
      <UpdateInfoPage path={ROUTES.UPDATE_INFORMATION} />
      <UpdatePersonalInfoPage path={ROUTES.UPDATE_PERSONAL_INFORMATION} />
      <MultiFactorEnrollmentPage path={ROUTES.TWO_STEP_AUTHENTICATION} />
      <PrivateRoute path="/*" component={NotFoundPage} />
    </Router>
  );
};
