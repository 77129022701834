import { Router } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import FullSurvey from '../../components/my-energy-use/full-survey/FullSurvey';

export const HomeEnergyAnalysisSurvey = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('MY_ENERGY_USE')}</title>
      </Helmet>
      <Router basepath="/">
        <FullSurvey path={`${ROUTES.HOME_ENERGY_ANALYSIS}`} />
      </Router>
    </>
  );
};
export default HomeEnergyAnalysisSurvey;
