import React, { FunctionComponent, useState, useEffect } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

import { useTranslation } from '../../hooks/useTranslation';
import TextField from '../text-field';
import usePrevious from '../../hooks/usePrevious';

interface SearchProps {
  onSearch: (searchValue: string) => void;
  value?: string;
  label?: string;
}

const Search: FunctionComponent<SearchProps> = ({ value, label, onSearch }) => {
  const { t } = useTranslation();
  const seachLabel = label || 'Search group';

  const [searchValue, setSearchValue] = useState<string>(value || '');
  const previousProp = usePrevious(value);
  useEffect(() => {
    if (value !== undefined && previousProp !== null && searchValue !== value) {
      setSearchValue(value);
    }
  }, [value]);

  const onUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSearch = () => {
    if (value !== searchValue) {
      onSearch(searchValue);
    }
  };

  return (
    <TextField
      value={searchValue}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end" onClick={handleSearch}>
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      onKeyUp={(e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
      onChange={onUpdate}
      label={seachLabel}
      type="search"
      fullWidth
    />
  );
};

export default Search;
