import React, { useState } from 'react';
import { Grid, Theme, Typography, Link, Box } from '@material-ui/core';
import { Check } from '@material-ui/icons';
import Buttons from '../../buttons/PGE-Button';
import useContentMgmt, {
  PageTextContentType,
} from '../../../hooks/useContentMgmt';
import { styled } from '@material-ui/styles';
import colors from '../../../themes/main-colors';
import { RebateEligibilityData } from './IQDBOrNewSmartCharger';
import { toCurrencyString } from '../../../util/format';
import { PgeEvModel } from '../../pge-plus-common/PgeEvModel';
import { ConfettiModal } from '../../modal/ConfettiModal';

const PREFIX = 'eligibleForRebates';
const classes = {
  rebateItem: `${PREFIX}-rebateItem`,
  rebateDetails: `${PREFIX}-rebateDetails`,
  continueButton: `${PREFIX}-continueButton`,

  container: `${PREFIX}-root`,
  typoGraphy: `${PREFIX}-typoGraphy`,
};

const StyledBox = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`& .${classes.container}`]: {
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },

  [`& .${classes.continueButton}`]: {
    fontSize: '18px',
    borderRadius: '30px',
    width: '130px',
    marginTop: '24px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  [`& .${classes.typoGraphy}`]: {
    fontSize: '20px',
    marginTop: '20px',
  },
  [`& .${classes.rebateItem}`]: {
    fontSize: 20,
    '& span': {
      fontSize: 48,
      fontWeight: 'bold',
      [theme.breakpoints.down('sm')]: {
        fontSize: 42,
      },
    },
  },
  [`& .${classes.rebateDetails}`]: {
    fontSize: '20px',
    marginTop: '20px',
    display: 'flex',
  },
}));

export type PgeEvModelProps = {
  open: boolean | false;
  handleClose: () => void;
  rebateEligibilityData: RebateEligibilityData;
  content: PageTextContentType;
};

export const EligibleForRebatesModal = ({
  open,
  handleClose,
  rebateEligibilityData: { chargerAmount, higherRebate, panelAmount },
  content,
}: PgeEvModelProps) => {
  const { inlineRichText } = useContentMgmt();
  const [
    isSmartChargingProgramModalVisible,
    setIsSmartChargingProgramModalVisible,
  ] = useState<boolean>(false);

  return (
    <ConfettiModal
      title={content.get('ELIGIBLE_FOR_SMART_CHARGING_REBATES')}
      open={open}
      handleClose={handleClose}
    >
      <StyledBox>
        <Grid
          container
          css={{ display: 'flex', justifyContent: 'center', padding: '32px 0' }}
        >
          <Grid item>
            <Typography variant={'body1'} className={classes.rebateItem}>
              {inlineRichText(content.get('ELIGIBLE_CHARGER_REBATE'), {
                CHARGER_AMOUNT: toCurrencyString(
                  chargerAmount || 0,
                  true,
                  true,
                ),
              })}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant={'body1'}
              style={{
                fontSize: 32,
                fontWeight: 'bold',
                padding: '26px 26px 0 26px',
              }}
            >
              +
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body1'} className={classes.rebateItem}>
              {inlineRichText(content.get('ELIGIBLE_PANEL_REBATE'), {
                PANEL_AMOUNT: toCurrencyString(panelAmount || 0, true, true),
              })}
            </Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.container}>
          <Grid item xs={12} md={7}>
            <Typography variant={'body1'} className={classes.rebateDetails}>
              <Check
                htmlColor={colors.shoreGreen}
                css={{ marginRight: '8px' }}
              />
              <span>
                {inlineRichText(
                  content.get('SMART_CHARGING_PROGRAM_ELIGIBILITY_DETAILS_1'),
                )}
                <Link
                  css={{ fontWeight: 'bold', cursor: 'pointer' }}
                  onClick={() => setIsSmartChargingProgramModalVisible(true)}
                >
                  {content.get('SMARTCHARGING_PROGRAM')}
                </Link>
                *
              </span>
            </Typography>
            <Typography variant={'body1'} className={classes.rebateDetails}>
              <Check
                htmlColor={colors.shoreGreen}
                css={{ marginRight: '8px' }}
              />
              {inlineRichText(
                content.get('SMART_CHARGING_PROGRAM_ELIGIBILITY_DETAILS_2'),
              )}
            </Typography>
            <Typography
              variant={'body1'}
              css={{ fontSize: '16px', marginTop: '16px' }}
            >
              {inlineRichText(
                content.get('SMART_CHARGING_PROGRAM_ELIGIBILITY_DISCLAIMER'),
              )}
            </Typography>
            <Buttons
              type="submit"
              fullWidth
              variant="contained"
              className={classes.continueButton}
              onClick={handleClose}
            >
              {content.get('CONTINUE')}
            </Buttons>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            css={{ display: 'flex', alignItems: 'start' }}
          >
            {open && (
              <img
                style={{
                  width: '350px',
                  height: 'auto',
                }}
                src={content.get('ELIGIBLE_FOR_REBATES_BILL_ANIMATION_IMAGE')}
              />
            )}
          </Grid>
        </Grid>

        <PgeEvModel
          isVisible={isSmartChargingProgramModalVisible}
          setIsVisible={setIsSmartChargingProgramModalVisible}
        />
      </StyledBox>
    </ConfettiModal>
  );
};
