import { Router } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import AccountLayout from '../../components/account-layout';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import MyEnergyUse from '../../components/my-energy-use';

export const MyEnergyUseMain = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('MY_ENERGY_USE')}</title>
      </Helmet>
      <AccountLayout>
        <Router basepath="/">
          <MyEnergyUse path={`${ROUTES.MY_ENERGY_USE}/*`} />
        </Router>
      </AccountLayout>
    </>
  );
};
export default MyEnergyUseMain;
