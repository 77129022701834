import {
  validateEmail,
  validateNameField,
  validatePhone,
} from '../../../util/form-validation';
import { Model } from './useRenewableIndustrialForm';
import { BaseValidationContext } from '../../../hooks/useFormState.types';
import { useTranslation } from '../../../hooks/useTranslation';

const { t } = useTranslation();

function validateContactName(value: string): string | null {
  const nameRegex = /^\w+[[a-zA-Z-.,`'\s]+$/;
  return !nameRegex.test(value) ? t('CONTACT_NAME_INVALID') : null;
}

const validationRules = (
  values: Model,
  context: BaseValidationContext<Model>,
) => {
  return {
    contactName:
      validateNameField(t('CONTACT_NAME'), values.contactName) ||
      validateContactName(values.contactName),
    emailAddress: validateEmail(values.emailAddress),
    phoneNumber: validatePhone(values.phoneNumber),
  };
};

export default validationRules;
