import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SSMAccount from './SSMAccount';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useAccountListHeader from '../../account-summary/multi-accounts/useAccountListHeader';
import useAccountDetailList from '../../../hooks/useAccountDetailList';
import useSelectedGroupId from '../../../hooks/useSelectedGroupId';
import AccountSearch from '../../account-search/AccountSearch';
import { AccountDetail } from '../../../__generated__/pge-types';
import colors from '../../../themes/main-colors';
import { useTranslation } from '../../../hooks/useTranslation';
import { AccountInfo } from '../ssm.types';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import { getAccounts } from './queries';

const useStyles = makeStyles((theme: Theme) => ({
  searchWrapper: {
    '& label': {
      fontSize: '16px',
      textAlign: 'center',
      lineHeight: '22px',
      marginBottom: '10px',
      display: 'block',
    },
  },
  cardSearch: {
    marginBottom: '30px',
  },
  showMoreAccounts: {
    fontSize: '20px',
    lineHeight: '30px',
    color: colors.sparkBlue,
    textAlign: 'center',
    cursor: 'pointer',
  },
}));

export const SSMChooseAccount = ({
  componentProps,
}: WizardFormProps<AccountInfo>) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    handleSelectedAccount,
    setAccountCount,
    setContinueLoading,
  } = componentProps;
  const { selectedGroupId } = useSelectedGroupId();
  const { accountListParams, onAccountSearch } = useAccountListHeader();
  const [showAccountByLimit, setShowAccountByLimit] = useState(5);
  const { accounts, totalCount, loading } = useAccountDetailList(
    getAccounts,
    selectedGroupId,
    {
      accountListParams: {
        ...accountListParams,
        pageSize: showAccountByLimit,
      },
    },
  );

  const singleAccountInegibilityCheck = async (): Promise<void> => {
    if (totalCount !== 1) {handleSelectedAccount(accounts[0]);}
  };

  useEffect(() => {
    if (accountListParams.query === '' && totalCount === 1) {
      void singleAccountInegibilityCheck();
    }
    setAccountCount(totalCount);
    if (totalCount !== 1 && totalCount !== undefined) {setContinueLoading(false);}
  }, [accounts.length]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid className={classes.searchWrapper}>
        <AccountSearch
          onSearch={(search: string) => {
            onAccountSearch(search);
          }}
          value={accountListParams.query}
          styleClass={classes.cardSearch}
          hideSearchIcon={true}
        />
      </Grid>
      {accounts?.slice(0, showAccountByLimit)?.map((account: AccountDetail) => (
        <SSMAccount account={account} handleClick={handleSelectedAccount} />
      ))}
      {accounts?.length > 4 && accounts?.length !== totalCount ? (
        <Typography
          variant="subtitle1"
          component="div"
          className={classes.showMoreAccounts}
          onClick={() => setShowAccountByLimit(showAccountByLimit + 5)}
        >
          {t('SHOW_MORE_ACCOUNTS')}
        </Typography>
      ) : null}
    </Grid>
  );
};
