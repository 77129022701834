import React, { FunctionComponent } from 'react';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import PinTextField from '../pin-text-field';
import { useTranslation } from '../../hooks/useTranslation';
import TextLink from '../text-link';
import PGEButton from '../buttons';
import useChangePinForm from './UseChangePinForm';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import ROUTES from '../../routes';
import { useIsMobile } from '../../util/style-utils';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';

const ChangePinForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { encryptedPersonId } = useSelectedAccountParams();
  const { form, onSubmit } = useChangePinForm();
  const { wrapWithLoader } = useWrapWithLoader();
  const handleSubmit = wrapWithLoader(
    form.submit(async () => {
      await onSubmit(form.values.newPin, encryptedPersonId || '');
    }),
  );
  const hasFourDigits = form.values.newPin.length >= 4;

  return (
    <Grid container direction="column">
      <Grid item>
        <form id="changePinForm" noValidate>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Card>
                <CardContent>
                  <Grid container spacing={1} direction="column">
                    <Grid item>
                      <Typography variant={'h2'}>
                        {t('CHANGE_PIN_TITLE')}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{t('CHANGE_PIN_USE')}</Typography>
                    </Grid>
                    <Grid item>
                      <PinTextField
                        name={'pinCode'}
                        label={t('CHANGE_PIN_CODE')}
                        data-testid={'newPinTextField'}
                        {...form.props('newPin')}
                      />
                    </Grid>
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justify={isMobile ? 'center' : 'flex-end'}
                        alignItems="center"
                        wrap="wrap-reverse"
                        spacing={2}
                      >
                        <Grid item xs={10} md="auto">
                          <PGEButton
                            fullWidth
                            data-testid="submitChangePinButton"
                            onClick={handleSubmit}
                            disabled={!hasFourDigits}
                          >
                            {t('CHANGE')}
                          </PGEButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justify={isMobile ? 'center' : 'flex-start'}
                alignItems="center"
                wrap="wrap-reverse"
                spacing={2}
              >
                <Grid item xs={10} md="auto">
                  <Grid container justify="center">
                    <Grid item>
                      <TextLink to={ROUTES.ACCOUNT}>{t('CANCEL')}</TextLink>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
export default ChangePinForm;
