import React, { FC, useEffect } from 'react';
import {
  Paper,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import PGEButton from '../../buttons';
import { navigate } from '@reach/router';
import ROUTES from '../../../routes';
import { useIsMobile } from '../../../util/style-utils';
import { BaseFormModel } from '../../../hooks/useFormState.types';

interface Props {
  path: string;
  setShowProgressBar: (arg0: boolean) => void;
  serviceDetailsForm: BaseFormModel;
}

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    '& h2': {
      fontFamily: theme.typography.h2.fontFamily,
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
    },
  },
}));

export const MoveServiceIneligibleCommercialAlert: FC<Props> = ({
  setShowProgressBar,
  serviceDetailsForm,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();

  useEffect(() => {
    setShowProgressBar(false);

    return function cleanup() {
      setShowProgressBar(true);
    };
  }, []);

  return (
    <Paper>
      <Card>
        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h2"
                component="div"
                className={classes.mainContent}
              >
                {t('COMMERCIAL_BUILDING')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="div">
                {t('UNABLE_TO_START_BUSINESS_SERVICE_ONLINE')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="div">
                {`${t('ADDRESS_ENTERED')}:`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {`${serviceDetailsForm.values.address}, ${serviceDetailsForm.values.city} ${serviceDetailsForm.values.zip}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="div"
                style={{ display: 'inline' }}
              >
                {t('TO_GET_STARTED')}{' '}
                <a
                  href={ROUTES.BUSINESS_SERVICE_CHECKLIST}
                  target="_blank"
                  rel="noopener"
                  style={{ display: 'inline' }}
                >
                  {t('BUSINESS_SERVICE_CHECKLIST')}
                </a>{' '}
                {t('AND_CALL_US_AT')}{' '}
                <a href={`tel:${t('BUSINESS_CUSTOMER_SERVICE_NUMBER')}`}>
                  {t('BUSINESS_CUSTOMER_SERVICE_NUMBER')}
                </a>{' '}
                {t('BETWEEN_THE_HOURS_BUSINESS')}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justify={isMobile ? 'center' : 'flex-end'}
            spacing={1}
            wrap="wrap-reverse"
          >
            <Grid item xs={10} md="auto">
              <PGEButton
                fullWidth
                onClick={() => navigate(ROUTES.MOVE_SERVICE)}
                color="secondary"
                variant="outlined"
              >
                {t('BACK')}
              </PGEButton>
            </Grid>
            <Grid item xs={10} md="auto">
              <PGEButton
                fullWidth
                onClick={async () => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  navigate(ROUTES.ACCOUNT);
                }}
              >
                {t('OK')}
              </PGEButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};
