import React from 'react';
import { Theme, makeStyles, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '../../hooks/useTranslation';

type HowWeUseYourPhoneNumberTooltipProps = {
  isOpen: boolean;
  closeTooltip: () => void;
  anchorEl: HTMLElement | null;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    phoneSelector: {
      marginTop: theme.spacing(2),
    },
    howWeUsePhoneTooltip: {
      padding: theme.spacing(2),
    },
    howWeUsePhoneTooltipText: {
      paddingTop: theme.spacing(2),
    },
    howWeUsePhoneTooltipTextContent: {
      paddingTop: theme.spacing(4),
    },
  }),
);

const HowWeUseYourPhoneNumberTooltip = ({
  isOpen,
  closeTooltip,
  anchorEl,
}: HowWeUseYourPhoneNumberTooltipProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={closeTooltip}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          maxWidth: 400,
        },
      }}
    >
      <Grid container spacing={0} direction="row-reverse">
        <Grid item xs={2} style={{ textAlign: 'right' }}>
          <IconButton aria-label="close" onClick={closeTooltip}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={10}>
          <Grid className={classes.howWeUsePhoneTooltip}>
            <Typography variant="body1">
              {t('HOW_WE_USE_YOUR_PHONE_NUMBER_TOOLTIP')}
            </Typography>
            <Typography
              variant="body1"
              className={classes.howWeUsePhoneTooltipText}
            >
              <b>{t('HOW_WE_USE_YOUR_PHONE_NUMBER_TOOLTIP_QUESTION')}</b>
            </Typography>
            <Typography variant="body1">
              <b>{t('YES_OF_COURSE')}</b>{' '}
              {t(
                'HOW_WE_USE_YOUR_PHONE_NUMBER_TOOLTIP_QUESTION_YES_ANSWER_DESCRIPTION',
              )}
            </Typography>
            <Typography
              variant="body1"
              className={classes.howWeUsePhoneTooltipTextContent}
            >
              <b>{t('NO_THANKS')}</b>{' '}
              {t(
                'HOW_WE_USE_YOUR_PHONE_NUMBER_TOOLTIP_QUESTION_NO_ANSWER_DESCRIPTION',
              )}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default HowWeUseYourPhoneNumberTooltip;
