import React, { FC, Dispatch, SetStateAction } from 'react';
import { Typography, Grid } from '@material-ui/core';
import ROUTES from '../../../../routes';
import { useTranslation } from '../../../../hooks/useTranslation';
import PaperBox from '../../../../components/utility/paper-box';
import useStyles from '../../styles';
import { PeakTimeRebatesTransactionType } from '../../types';
import ProceedOrCancel from '../../../proceed-or-cancel';
import { displayPhoneNumberFormat } from '../../../../util/format';
import PGEButton from '../../../buttons';
import { navigate } from '@reach/router';

interface Props {
  path?: string;
  transactionType: PeakTimeRebatesTransactionType;
  emailAddress: string;
  phoneNumber: string;
  setShowConfirm: Dispatch<SetStateAction<any>>;
  handleOnClickManageSubmit: () => Promise<void>;
}

const TransactionSuccessful: FC<Props> = (props: Props) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const {
    transactionType,
    emailAddress,
    phoneNumber,
    setShowConfirm,
    handleOnClickManageSubmit,
  } = props;

  return (
    <>
      <PaperBox>
        <Grid container>
          {transactionType === PeakTimeRebatesTransactionType.Cancel && (
            <>
              <Grid item xs={12} style={{ marginBottom: '1em' }}>
                <Typography className={classes.colorTextPrimary} variant={'h2'}>
                  {t('CANCEL_PEAK_TIME_ENROLLMENT')}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '1em' }}>
                <Typography
                  className={classes.colorTextPrimary}
                  variant={'body2'}
                  component={'div'}
                >
                  {richT('THANK_YOU_FOR_PARTICIPATING_PTR')}
                </Typography>
              </Grid>
            </>
          )}

          {transactionType === PeakTimeRebatesTransactionType.Update && (
            <>
              <Grid item xs={12}>
                <Typography variant={'h2'}>{t('SUMMARY')}</Typography>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '1em' }}>
                <Typography variant={'body2'}>
                  {t('CONFIRM_THE_FOLLOWING_INFORMATION')}
                </Typography>
                <Grid item xs={12} style={{ marginTop: '1.5em' }}>
                  <Typography variant={'h2'}>
                    {t('NOTIFICATION_PREFERENCES')}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '1.5em' }}>
                  <Typography variant={'body2'}>
                    {t('NOTIFICATION_OF_UPCOMING_PTR')}
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '1.5em' }}>
                  <Typography variant={'body2'} component={'div'}>
                    {richT('CONFIRM_EMAIL_ADDRESS', {
                      EMAIL_ADDRESS: Boolean(emailAddress)
                        ? emailAddress
                        : '&nbsp;',
                    })}
                  </Typography>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '1.5em' }}>
                  <Typography variant={'body2'} component={'div'}>
                    {richT('CONFIRM_PHONE_NUMBER', {
                      PHONE_NUMBER: Boolean(phoneNumber)
                        ? displayPhoneNumberFormat(phoneNumber)
                        : '&nbsp;',
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </PaperBox>

      <Grid
        item
        xs={12}
        style={{
          marginTop: '1em',
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
        <PGEButton
          size={'large'}
          style={{ flexShrink: 0, marginLeft: 8 }}
          onClick={handleOnClickManageSubmit}
        >
          {transactionType === PeakTimeRebatesTransactionType.Cancel
            ? t('YES')
            : t('SUBMIT')}
        </PGEButton>

        <PGEButton
          size={'large'}
          color="secondary"
          variant="outlined"
          onClick={() => {
            if (transactionType === PeakTimeRebatesTransactionType.Cancel) {
              return navigate(ROUTES.ACCOUNT);
            } else {
              setShowConfirm(false);
            }
          }}
        >
          {transactionType === PeakTimeRebatesTransactionType.Cancel
            ? t('NO')
            : t('PREVIOUS')}
        </PGEButton>
      </Grid>
    </>
  );
};

export default TransactionSuccessful;
