/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { useState, useEffect, Fragment } from 'react';
import { Grid, Typography, Box, FormHelperText } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import routes from '../../../routes';
import { navigate } from 'gatsby';
import useContentMgmt, {
  ProductData,
  ProductStatus,
} from '../../../hooks/useContentMgmt';
import ProductCard from '../../pge-plus-common/ProductCard';
import PopoverToolTip from '../../pge-plus-common/PopoverToolTip';
import WelcomeToPGEPlusModal from '../../pge-plus-common/WelcomeToPGEPlusModal';
import colors from '../../../themes/main-colors';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';
import useFormState from '../../../hooks/useFormState';
import { createSelectChargerValidateFunction } from '../../pge-plus-common/PGEPlusForm.rules';
import { SelectCharger, UpdateEVSectionErrorCode } from '../evcharger.types';
import { selectChargerFormStateAdapter } from '../../pge-plus-common/utils/pgeplusFormstateAdapters';
import useUpdateEVSections from '../../../hooks/pge-plus/useUpdateEVSections';
import {
  ApplicationEvSectionType,
  IneligibilityReasonCode,
  SectionEvChargerSelection,
} from '../../../__generated__/pgeplus-types';
import useUtil from '../../../hooks/pge-plus/useUtil';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';

const NONE_OF_THESE = 'NONE';

interface ConfirmYourChargerProps {
  children?: React.ReactNode;
  path: string;
}

export const ConfirmYourCharger: React.FC<ConfirmYourChargerProps> = props => {
  const theme = useTheme();
  const [evChargerProductList, setEvChargerProductList] = useState<
    ProductData[]
  >([]);

  const { content, getProductList } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_CONFIRM_YOUR_CHARGER,
  );

  const {
    formState: { selectCharger, application },
    updateFormState,
    getSectionData,
  } = useEVChargerFormState();

  const sectionDataSelectCharger: SectionEvChargerSelection = getSectionData(
    ApplicationEvSectionType.EvChargerSelection,
  );

  const form = useFormState(selectCharger, {
    validate: createSelectChargerValidateFunction(),
    validationContext: {
      content,
    },
  });
  const { updateSectionChargerSelection } = useUpdateEVSections();
  const { setErrorNotification } = useUtil();
  const { wrapWithLoader } = useWrapWithLoader();

  useEffect(() => {
    const fetchProductList = async () => {
      const productList = await getProductList({
        productListEntryId:
          microcopyGroupIds.PGE_PLUS_CONFIRM_YOUR_CHARGER_PRODUCT_LIST,
        status: ProductStatus.Active,
      });
      setEvChargerProductList(productList);
    };
    fetchProductList().catch(err => {
      //TODO: complete error handling
      console.log('fetchProductList error: ', err);
    });
  }, []);

  const onSubmit = wrapWithLoader(async (data: SelectCharger) => {
    try {
      const response = await updateSectionChargerSelection(
        selectChargerFormStateAdapter().fromFormState(
          { chargerId: data.chargerId, suppliedByCustomer: true },
          application?.id!,
          sectionDataSelectCharger?.id,
        ),
      );

      if (response.data?.updateSectionEVChargerSelection.validation?.success) {
        //Store the data in global store
        updateFormState('selectCharger', {
          chargerId: data.chargerId,
          suppliedByCustomer: true,
        });

        if (data.chargerId === NONE_OF_THESE) {
          void navigate(routes.PGE_PLUS_EV_CHARGERS_INELIGIBLE, {
            state: { errorType: IneligibilityReasonCode.IneligibleProductType },
          });
          return false;
        }

        //Navigate to the next screen
        void navigate(routes.PGE_PLUS_EV_CHARGERS_CONTACT_INFO);
      } else if (response.errors) {
        const ineligibleErrors = response.errors?.filter(
          reason =>
            reason?.extensions?.code ===
              UpdateEVSectionErrorCode.ProductInactive ||
            reason?.extensions?.code ===
              UpdateEVSectionErrorCode.ProductOutOfStock ||
            reason?.extensions?.code ===
              UpdateEVSectionErrorCode.ProductNotAvailable,
        );
        if (ineligibleErrors.length > 0) {
          setErrorNotification(
            true,
            content.get('PRODUCT_NOT_AVAILABLE_ERROR'),
          );
          return;
        } else {
          throw new Error();
        }
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });

  return (
    <Fragment>
      <form onSubmit={form.submit(values => onSubmit(values))}>
        <Box css={{ marginBottom: 3 }}>
          <Box>
            <Typography
              component="h1"
              variant="h2"
              css={{
                fontFamily: 'Forma-DJR-Display',
                color: colors.noirBlur,
                marginBottom: '24px',
                letterSpacing: '0.48px',
                [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
                  display: 'none',
                },
              }}
            >
              {content.get('CONFIRM_YOUR_QUALIFIED_CHARGER')}
            </Typography>
          </Box>
          <Box display={'flex'}>
            <Typography
              component="h1"
              variant="h3"
              css={{
                fontFamily: 'Untitled-Sans',
                color: colors.noirBlur,
                letterSpacing: 0,
                fontSize: '20px',
              }}
            >
              {content.get('WHICH_QUALIFIED_CHARGER_YOU_HAVE')}
              <PopoverToolTip
                popoverContent={content.get(
                  'CONFIRM_YOUR_CHARGER_TOOLTIP_INFO',
                )}
              />
            </Typography>
          </Box>
        </Box>

        <Grid
          container
          spacing={3}
          css={{
            justifyContent: 'space-between',
            [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
              marginTop: '1.25rem',
              marginBottom: '90px',
            },
            [`@media (min-width: ${theme.breakpoints.values.sm}px)`]: {
              marginTop: '1.5rem',
            },
          }}
        >
          {evChargerProductList &&
            evChargerProductList?.map(product => (
              <Grid item xs={6} sm={4} md={4} lg={4} key={product?.id}>
                <ProductCard
                  title={`${product.brand} ${product.modelNo}`}
                  image={product?.images?.[0].fields?.file?.url || ''}
                  handleOnClick={(e: React.MouseEvent<HTMLElement>) =>
                    form.setValue('chargerId', product?.id)
                  }
                  isActive={form.values.chargerId === product?.id}
                />
              </Grid>
            ))}
          <Grid item xs={6} sm={4} md={4} lg={4}>
            <ProductCard
              title={content.get('NONE_OF_THESE') || ''}
              handleOnClick={(e: React.MouseEvent<HTMLElement>) =>
                form.setValue('chargerId', NONE_OF_THESE)
              }
              isActive={form.values.chargerId === NONE_OF_THESE}
            />
          </Grid>
        </Grid>

        {form.errors.chargerId && (
          <FormHelperText error>{form.errors.chargerId}</FormHelperText>
        )}

        <ProceedOrCancelButton back={false} isCancel={true} />
      </form>

      {selectCharger.suppliedByCustomer && <WelcomeToPGEPlusModal />}
    </Fragment>
  );
};
