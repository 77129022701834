import {
  ErChargerValidation,
  EvRebate,
  HomeType,
  ErChargerInstallInfo,
  EvRebateInfo,
  StoreFileInput,
} from '../../../../__generated__/pgeplus-types';
import exp from 'constants';

export type AccountInfo = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phoneNumber?: string;
  emailAddress?: string;
  relationship?: string;
  isMainPerson?: boolean;
  homeType?: HomeType;
};

export type RebateInfo = {
  evPulse?: EVPulseSmartChargingEnrollment;
  smartCharging?: SmartCharging;
  chargerValidation?: ErChargerValidation;
  qualifiedProductInfo?: QualifiedProductInfo;
  chargerInstalledOnResidentialMeter?: boolean;
  smartChargingEnrollmentConfirmation?: VerifyRebateEnrollmentConfirmation;
  evPulseEnrollmentConfirmation?: VerifyRebateEnrollmentConfirmation;
};

export type RebateConfirm = {
  iqbd?: RebateConfirmIQBD;
  rebateQualification?: RebateQualification;
  connectTeslaToEVPulse?: ConnectTeslaToEVPulse;
  confirmChargerConnectedToResidentialMeter?: boolean;
  confirmDriveElectricVehicle?: boolean;
  agreeToTermsAndConditions?: boolean;
  evPulse?: EvPulse;
  evPulseRebateAmount?: number;
  appliedRebate?: EvRebate;
  rebateEligibility?: RebateEligibility;
  chargerInstallInfo?: ErChargerInstallInfo;
  chargerActivatedInAppConfirmed?: boolean;
  preRebateEligibility1?: RebateEligibility;
  preRebateEligibility2?: RebateEligibility;
  rebateInfo?: EvRebateInfo;
};

export type RebateEligibility = {
  evRebate?: EvRebate;
};

export type EVPulseSmartChargingEnrollment = {
  vin?: string;
  vinImage?: StoreFileInput;
  doYouOwnTesla?: boolean;
  doYouOwnL2Charger?: boolean;
};

export type RebateQualification = {
  doDriveTesla?: boolean;
  wifiAvailable?: boolean;
  haveChargerActivated?: boolean;
};

export type EvPulse = {
  vin?: string;
  vinImage?: StoreFileInput;
};

export type SmartCharging = {
  modelNumber?: string;
  modelNumberImage?: StoreFileInput;
};

export type RebateConfirmIQBD = {
  checkEligibility?: boolean;
  houseHoldSize?: number;
  grossIncome?: number;
  grossIncomeConfirmed?: boolean;
};

export type ERVerifyRebate = {
  isChargerDifferent?: boolean;
  enrollAnotherVehicle?: boolean;
};

export type ConnectTeslaToEVPulse = {
  isTeslaConnectedToEVPulse?: boolean;
};

export type ChargerInstalledOnResidentialMeter = {
  chargerInstalledOnResidentialMeter?: boolean;
};

export type ChargerInstallationCost = {
  installationCost?: string;
  chargerCost?: string;
  files?: StoreFileInput[];
};

export type ConnectChargerToWifiType = {
  isChargerConnectedToWifi?: string;
  isTeslaOrActivateCharger?: boolean;
};

export type EligibleForEVPulse = {
  vin?: string;
  vinImage?: StoreFileInput;
};

export type QualifiedProductInfo = {
  serialNumber?: string;
  serialNumberImage?: StoreFileInput;
};

export type VerifyRebateEnrollmentConfirmation = {
  willEnrollNewSmartChargingDevice?: boolean;
  willEnrollNewVehicle?: boolean;
};

export enum UpdateSectionErrorCode {
  NonResidentialMeter = 'NON_RESIDENTIAL_METER',
  NotANewVehicle = 'NOT_A_NEW_VEHICLE',
  DoesNotHaveL2Charger = 'DOES_NOT_HAVE_L2_CHARGER',
  VINAlreadyEnrolled = 'VIN_ALREADY_ENROLLED',
  SerialNoAlreadyEnrolled = 'SERIAL_NO_ALREADY_ENROLLED',
  DoesNotDriveTesla = 'DOES_NOT_DRIVE_TESLA',
  NotANewCharger = 'NOT_A_NEW_CHARGER',
  IneligibleHomeType = 'INELIGIBLE_HOME_TYPE',
  UnableToConnectApp = 'UNABLE_TO_CONNECT_APP',
  NotEligibleForRebates = 'NOT_ELIGIBLE_FOR_REBATES',
}

export enum ChargerType {
  CHARGE_POINT = 'ChargePoint',
  FLO = 'FLO',
  JUICE_BOX = 'JuiceBox',
  EV_PULSE = 'EvPulse',
}
