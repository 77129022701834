import { useApolloClient } from '@apollo/react-hooks';
import gql from 'not-graphql-tag';
import {
  MoveSvcRequest,
  UpdateAlrtDetailsRequest,
} from '../__generated__/pge-types';

const submitNotifcation = gql`
  mutation UpdateAlertDetails($payload: UpdateAlrtDetailsRequest) {
    updateAlertDetails(payload: $payload) {
      success
    }
  }
`;

interface notifySuccess {
  success: boolean;
}

export function useSSMNotificationSubmit() {
  const apolloClient = useApolloClient();
  async function submit(payload: UpdateAlrtDetailsRequest) {
    const result = await apolloClient.mutate<{
      updateAlertDetails: notifySuccess;
    }>({
      mutation: submitNotifcation,
      variables: {
        payload,
      },
    });
    return result.data?.updateAlertDetails;
  }
  return { submit };
}
