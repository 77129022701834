/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import MobileProgressBar from './MobileProgressBar';
import { useLocation } from '@reach/router';
import { useIsMobile } from '../../../util/style-utils';
import colors from '../../../themes/main-colors';
import routes from '../../../routes';
import { PageTextContentType } from '../../../hooks/useContentMgmt';

export type ProgressStep = {
  title: string;
  path: string;
  subPaths?: string[];
};
export type ProgressStepperProps = {
  steps: Array<ProgressStep>;
  isComplete: Boolean;
  content: PageTextContentType;
};

const ProgressStepper = ({
  steps,
  isComplete,
  content,
}: ProgressStepperProps) => {
  const isMobile = useIsMobile();

  const location = useLocation();
  let pathname = location.pathname;
  if (pathname.includes(routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY)) {
    pathname = routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY;
  }

  const activeIndex = steps.findIndex(step => {
    const cleanedPath = step.path.replace(/\/+$/, '');
    const cleanedPathname = pathname.replace(/\/+$/, '');

    return (
      cleanedPath === cleanedPathname ||
      (step.subPaths && step.subPaths.includes(cleanedPathname))
    );
  });

  const currentStepTitle = steps[activeIndex]?.title;
  const nextStepTitle =
    steps.length - 1 > activeIndex
      ? `${content.get('REUSABLE_BUTTON_NEXT')}: ${
          steps[activeIndex + 1]?.title
        }`
      : '';

  if (isMobile) {
    return (
      <MobileProgressBar
        currentStep={isComplete ? steps.length : activeIndex + 1}
        currentTitle={currentStepTitle}
        nextTitle={nextStepTitle}
        totalSteps={steps.length}
        steps={steps}
      />
    );
  }

  return (
    <Stepper
      activeStep={isComplete ? steps.length : activeIndex}
      alternativeLabel
      css={{
        backgroundColor: 'transparent',
        '& .MuiStepIcon-root.Mui-completed': {
          color: 'green !important',
        },
        '& .MuiStepIcon-root.MuiStepIcon-active': {
          color: `${colors.noirBlurLight} !important`,
        },
        '& .MuiStepConnector-lineHorizontal': {
          border: `1px dashed ${colors.lightGray1}`,
        },
        margin: '30px 0',
        padding: 0,
      }}
    >
      {steps.map(({ title }, idx) => (
        <Step key={idx}>
          <StepLabel
            css={{
              'svg:not(:root)': {
                overflow: 'visible',
              },
              '.MuiStepLabel-active': {
                fontWeight: 600,
              },
              '.MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
                marginTop: 7,
              },
              '.MuiStepIcon-completed': {
                color: `${colors.shoreGreen} !important`,
              },
              '.MuiStepIcon-root': {
                color: colors.lightGray1,
                width: '2.6em',
              },
              '.MuiStepIcon-root .MuiStepIcon-text': {
                fill: colors.noirBlur,
                fontSize: 18,
                transform: 'translateY(2px)',
              },
              '.MuiStepIcon-active .MuiStepIcon-text': {
                fill: colors.white,
                fontSize: 18,
                transform: 'translateY(2px)',
              },
            }}
          >
            {title}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default ProgressStepper;
