import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { useLocation } from '@reach/router';
import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { useIsMobile } from '../../util/style-utils';
import { PaymentConfirmation } from '../../__generated__/pge-types';
import ProceedOrCancel from '../proceed-or-cancel';
import PaymentSummary from './PaymentSummary';
import ResultTable from './ResultTable';
import { AccountAmounts, PaymentDateState, PaymentProfileState } from './types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      border: `1px solid ${theme.palette.success.main}`,
    },
    color: {
      color: theme.palette.success.main,
    },
  }),
);
type Props = {
  path?: string;
  onSubmit: () => void;
};

type LocationState = {
  accountAmounts: Array<AccountAmounts>;
  profileState: PaymentProfileState;
  paymentDateState: PaymentDateState;
  isPartialSucceed: boolean;
  paymentConfirmation: Array<PaymentConfirmation>;
  timestamp?: string;
};

const PaymentCompleteStep = ({ onSubmit }: Props) => {
  const { t, richT } = useTranslation();
  const styles = useStyles();
  const isMobile = useIsMobile();
  const location = useLocation();
  const {
    accountAmounts,
    profileState,
    paymentDateState,
    isPartialSucceed,
    paymentConfirmation,
    timestamp = '',
  }: LocationState = location?.state as LocationState;

  const confirmationMap = paymentConfirmation.reduce(
    (data: Map<string, PaymentConfirmation>, pc: PaymentConfirmation) => {
      return data.set(pc.accountNumber, pc);
    },
    new Map<string, PaymentConfirmation>(),
  );

  const results = accountAmounts.map(({ account, amount }) => ({
    account,
    amount,
    result: confirmationMap.get(account.accountNumber),
  }));

  const summaryProps = {
    accountAmounts: results.filter(a => a.result?.isSuccess),
    profileState,
    paymentDateState,
  };

  const completeProps = {
    results,
    timestamp,
    isSuccess: true,
    showConfirmationMsg: true,
  };

  return (
    <Paper component={'main'} className={styles.border}>
      <Box style={{ padding: '1em' }}>
        <Grid container spacing={4}>
          <Grid
            item
            container
            xs={12}
            spacing={2}
            direction={'row'}
            alignItems={'center'}
          >
            <Grid item>
              <Typography variant={'h2'} className={styles.color}>
                <CheckCircleIcon fontSize="inherit" />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'h2'}>
                {t('PAYMENT_CONFIRMATION')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container item xs={12} spacing={4}>
              <Grid container item>
                <PaymentSummary {...summaryProps}></PaymentSummary>
              </Grid>
              {completeProps.results?.length ? (
                <Grid container item>
                  <ResultTable {...completeProps}></ResultTable>
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justify="space-between"
                direction={isMobile ? 'column-reverse' : 'row-reverse'}
                spacing={1}
              >
                <Grid item xs={12} md="auto">
                  <Box paddingRight={1}>
                    <ProceedOrCancel
                      proceedLabel={t('DONE')}
                      proceedHandler={() => onSubmit()}
                      cancelRoute={false}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default PaymentCompleteStep;
