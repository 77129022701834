import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Divider, Grid } from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import useStyles from '../styles';

const OrganizationDetails: FunctionComponent = () => {
  const { t, richT } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid md={6} item className={classes.imageWrapper}>
        <img
          src={'../../../static/centralCityConcernlogo.jpg'}
          width={'200px'}
          height={'180px'}
        />
      </Grid>
      <Grid item>
        <Typography variant={'h3'}>
          {t('PTR_GIVE_BACK_ORG_NAME_CENTRAL_CITY_CONCERN')}
        </Typography>
        <Typography
          className={classes.colorTextPrimary}
          variant={'body2'}
          component="span"
        >
          {t('PTR_GIVE_BACK_ORG_CENTRAL_CITY_CONCERN_DESCRIPTION')}
        </Typography>
      </Grid>
      <Divider className={classes.gridDivider} />
      <Grid md={6} xs={12} item className={classes.imageWrapper}>
        <img
          src={'../../../static/communityEnergyProjectlogo.jpg'}
          width={'200px'}
          height={'120px'}
        />
      </Grid>
      <Grid item>
        <Typography variant={'h3'}>
          {t('PTR_GIVE_BACK_ORG_NAME_COMMUNITY_ENERGY_PROJECT')}
        </Typography>
        <Typography className={classes.colorTextPrimary} variant={'body2'}>
          {t('PTR_GIVE_BACK_ORG_COMMUNITY_ENERGY_PROJECT_DESCRIPTION')}
        </Typography>
      </Grid>
      <Divider className={classes.gridDivider} />
      <Grid md={6} xs={12} item className={classes.imageWrapper}>
        <img
          src={'../../../static/workingTheoryFarm.jpg'}
          width={'200px'}
          height={'180px'}
        />
      </Grid>
      <Grid item>
        <Typography variant={'h3'}>
          {t('PTR_GIVE_BACK_ORG_NAME_WORKING_THEORY_FARM')}
        </Typography>
        <Typography className={classes.colorTextPrimary} variant={'body2'}>
          {t('PTR_GIVE_BACK_ORG_WORKING_THEORY_FARM_DESCRIPTION')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OrganizationDetails;
