import React from 'react';
import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import colors from '../../../themes/main-colors';

interface RadioOptionProps {
  title: string;
  selectedValue?: string;
  yesOptionTitle?: string;
  noOptionTitle?: string;
  onChange: (value: string) => void;
}

export const RadioOption: React.FC<RadioOptionProps> = props => {
  const { title, selectedValue, yesOptionTitle, noOptionTitle } = props;

  return (
    <Box>
      <Typography
        css={{
          fontSize: '16px',
          lineHeight: '20px',
          color: colors.lightCyan,
          display: 'block',
          margin: '0 0 20px 0',
        }}
      >
        {title}
      </Typography>
      <RadioGroup
        row
        value={selectedValue}
        onChange={(_, value) => props.onChange(value)}
        css={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <FormControlLabel
          value={'Yes'}
          control={
            <Radio
              size="small"
              color={'primary'}
              css={{ paddingBottom: '3px', paddingTop: '3px' }}
            />
          }
          label={
            <Typography
              css={{
                fontSize: '16px',
                lineHeight: '20px',
              }}
            >
              {yesOptionTitle}
            </Typography>
          }
        />
        <FormControlLabel
          value={'No'}
          control={
            <Radio
              size="small"
              color={'primary'}
              css={{ paddingBottom: '3px', paddingTop: '3px' }}
            />
          }
          label={
            <Typography
              css={{
                fontSize: '16px',
                lineHeight: '20px',
              }}
            >
              {noOptionTitle}
            </Typography>
          }
        />
      </RadioGroup>
    </Box>
  );
};
