import { useTranslation } from '../../hooks/useTranslation';
import { isValidPassword } from '../../util/form-validation';
import { sameAsOldPasswordQuery } from '../../queries/changePassword.query';
import { SameAsOldPasswordResponse } from '../../__generated__/pge-types';
import { ApolloClient } from 'apollo-client';
import { convertValidationRules } from '../../hooks/useFormState';
import { BaseValidationContext } from '../../hooks/useFormState.types';

const { t } = useTranslation();

export type Model = {
  password: string;
};

type AdditionalContext = {
  apolloClient: ApolloClient<any>;
};

const validatePassword = async (
  password: string,
  context: BaseValidationContext<Model> & AdditionalContext,
): Promise<string | null> => {
  if (!password.length) {
    return t('ERROR_AUTH_PASSWORD_REQUIRED');
  }
  if (!isValidPassword(password)) {
    return t('ERROR_AUTH_INVALID_PASSWORD_RULES');
  }
  if (await validateSameAsOldPassword(password, context)) {
    return t('CHANGE_PASSWORD_SAME_PASSWORD_ERROR');
  }
  return null;
};

async function validateSameAsOldPassword(
  password: string,
  context: BaseValidationContext<Model> & AdditionalContext,
): Promise<boolean> {
  console.log(context.phase);
  if (context.phase === 'submit') {
    const { data } = await context.apolloClient.query<{
      sameAsOldPassword: SameAsOldPasswordResponse;
    }>({
      query: sameAsOldPasswordQuery,
      variables: {
        payload: {
          password: password,
        },
      },
      fetchPolicy: 'network-only',
    });

    const result = data?.sameAsOldPassword.sameAsOldPassword;

    return result || false;
  }

  return false;
}

export default convertValidationRules<Model, AdditionalContext>(() => {
  return {
    // this must match the names of the form fields
    password: validatePassword,
  };
});
