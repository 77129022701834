import { Box, Grid, Typography } from '@material-ui/core';
import { navigate } from 'gatsby';
import React, { FunctionComponent } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import PGEButton from '../../buttons';
import PaperBox from '../../utility/paper-box';
import ROUTES from '../../../routes';
import { useIsMobile } from '../../../util/style-utils';
interface Props {
  path?: string;
}

const TimeOfDayPending: FunctionComponent<Props> = props => {
  const isMobile = useIsMobile();
  const { t, richT } = useTranslation();
  return (
    <>
      <PaperBox>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={'h2'}>
              {t('TOD_ENROLLMENT_PENDING_TITLE')}
            </Typography>

            <Typography variant={'body2'} style={{ marginTop: '1em' }}>
              {t('TOD_ENROLLMENT_PENDING_BODY')}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          item
          container
          direction="row"
          justify={isMobile ? 'center' : 'flex-end'}
          spacing={1}
          wrap="wrap-reverse"
        >
          <Grid item xs={10} md="auto">
            <PGEButton
              fullWidth
              onClick={async () => {
                await navigate(ROUTES.ACCOUNT);
              }}
            >
              <Typography variant={'h4'} noWrap>
                {t('DONE')}
              </Typography>
            </PGEButton>
          </Grid>
        </Grid>
      </PaperBox>
    </>
  );
};

export default TimeOfDayPending;
