import React, { FunctionComponent, useContext } from 'react';
import { Router } from '@reach/router';
import { navigate } from 'gatsby';
import ROUTES from '../../routes';
import AccountLayout from '../../components/account-layout';
import Backdrop from '../../components/backdrop';
import { Grid, Typography } from '@material-ui/core';
import useRenewablePower from '../../hooks/useRenewablePower';
import RenewablePowerIneligibility from '../../components/renewable-power/renewable-power-ineligibility';
import RenewablePowerForm from '../../components/renewable-power/renewable-power-form';
import RenewablePowerConfirm from '../../components/renewable-power/renewable-power-confirm';
import RenewablePowerIndustrialForm from '../../components/renewable-power/renewable-power-industrial-form';
import {
  RenewablePowerContextProvider,
  RenewablePowerContextState,
} from '../../providers/RenewablePowerProvider';
import AccountDropdown from '../../components/account-dropdown';
import { RenewableUpdateAdditionalData } from '../../components/renewable-power/renewable-power.types';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import { isNil } from 'lodash';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import { Helmet } from 'react-helmet';
import { useApolloClient } from '@apollo/react-hooks';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import {
  RenewableEnrollment,
  RenewableEnrollmentStatus,
} from '../../__generated__/pge-types';
import { ApolloClient } from 'apollo-client';

type Props = {
  path?: string;
};

const RenewablePowerPage: FunctionComponent<Props> = () => {
  const { wrapWithLoader } = useWrapWithLoader();
  const notificationContext = useContext(NotificationsContext);
  const apolloClient = useApolloClient();
  const { accountParams } = useSelectedAccountParams();

  const {
    loading,
    content,
    programAnswers,
    settings,
    eligibilityData,
    accountDetails,
    renewableData,
    updateRenewableEnrollment,
    customer,
    encryptedPersonId,
  } = useRenewablePower({ ignoreNoAccounts: false });

  const handleConfirmSubmit = wrapWithLoader(async function(
    renewableStateData: RenewablePowerContextState,
  ) {
    const additionalRenewableInfo: RenewableUpdateAdditionalData = {
      encryptedAccountNumber: accountDetails!.encryptedAccountNumber,
      mainCustomerName: accountDetails!.mainCustomerName,
      encryptedPersonId: accountDetails!.encryptedPersonId,
    };

    const updateRenewableConfirmation = await updateRenewableEnrollment(
      renewableStateData,
      eligibilityData?.renewablesAccountEligibility || null,
    );

    if (updateRenewableConfirmation.data?.updateRenewables) {
      notificationContext.setState({
        isOpen: true,
        severity: 'success',
        message: content.donePage.text,
      });
      try {
        accountParams?.accountNumber
          ? updateApolloCache(
              renewableStateData,
              apolloClient,
              accountParams?.accountNumber,
            )
          : '';
      } catch (error) {
        // Swallowing because updating cache isn't core to this function.
      }
      return navigate(ROUTES.ACCOUNT);
    } else {
      const errorMsg =
        updateRenewableConfirmation.errors &&
        updateRenewableConfirmation.errors[0]
          ? updateRenewableConfirmation.errors[0].message
          : 'Unknown';

      throw new Error(
        'Mutation updateRenewableEnrollment returned an error. ' + errorMsg,
      );
    }
  });

  function updateApolloCache(
    renewableStateData: RenewablePowerContextState,
    client: ApolloClient<object>,
    accountNumber: string,
  ) {
    const renewableEnrollmentStatus =
      renewableStateData.greenSource.isSelected ||
      renewableStateData.cleanWind.isSelected ||
      renewableStateData.solarSource.isSelected
        ? RenewableEnrollmentStatus.Enrolled
        : RenewableEnrollmentStatus.NotEnrolled;

    const renewableEnrollment: RenewableEnrollment = {
      __typename: 'RenewableEnrollment',
      renewableEnrollmentStatus: renewableEnrollmentStatus,
    };
    client.writeData({
      id: `AccountDetail:${accountNumber}`,
      data: {
        renewableEnrollment: renewableEnrollment,
      },
    });
  }

  const isPageDataReady: boolean =
    !isNil(accountDetails) && !isNil(eligibilityData) && !loading;

  const isIndustrialFlag: boolean =
    accountDetails?.accountType === 'COM' &&
    eligibilityData?.renewablesAccountEligibility.isIndustrial!;

  const isEligibleFlag: boolean =
    eligibilityData?.renewablesAccountEligibility?.eligibility === 'Eligible';

  return (
    <AccountLayout>
      <Helmet>
        <title>{content.title}</title>
      </Helmet>
      <Grid container direction={'column'} spacing={4}>
        <Grid item>
          <Typography variant={'h1'}>{content.title}</Typography>
        </Grid>
        <Grid item>
          <AccountDropdown />
        </Grid>
        {!isPageDataReady && <Backdrop forceOpen />}

        {isPageDataReady && (
          <RenewablePowerContextProvider
            data={renewableData?.getRenewables}
            isSmallBusiness={
              eligibilityData?.renewablesAccountEligibility.isSmallBusiness
            }
          >
            <Grid item>
              <Router basepath="/">
                {isPageDataReady && !isEligibleFlag && (
                  <RenewablePowerIneligibility
                    path={ROUTES.RENEWABLE_POWER}
                    eligibility={
                      eligibilityData?.renewablesAccountEligibility
                        ?.eligibility || ''
                    }
                    content={content}
                  />
                )}
                {isPageDataReady && isEligibleFlag && !isIndustrialFlag && (
                  <RenewablePowerForm
                    path={ROUTES.RENEWABLE_POWER}
                    content={content}
                    programAnswers={programAnswers}
                    settings={settings}
                    eligibility={eligibilityData!.renewablesAccountEligibility}
                  />
                )}
                {isPageDataReady && isEligibleFlag && isIndustrialFlag && (
                  <RenewablePowerIndustrialForm
                    path={ROUTES.RENEWABLE_POWER}
                    content={content}
                    email={customer?.email}
                    eligibility={eligibilityData!.renewablesAccountEligibility}
                    encryptedAccountNumber={
                      accountDetails!.encryptedAccountNumber
                    }
                    pnpPhone={
                      customer?.contactDetails?.find(
                        phone => phone?.contactType === 'PNP',
                      )?.contactValue
                    }
                  />
                )}
                <RenewablePowerConfirm
                  path={ROUTES.RENEWABLE_POWER_CONFIRM}
                  content={content}
                  onSubmit={handleConfirmSubmit}
                />
              </Router>
            </Grid>
          </RenewablePowerContextProvider>
        )}
      </Grid>
    </AccountLayout>
  );
};

export default RenewablePowerPage;
