import React, { useState } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { WizardFormProps } from '../utility/wizard/wizard.types';
import { ElectionWindowModel } from '../../hooks/useElectionWindow';
import dayjs from 'dayjs';
import Pagination, { PaginationProps } from '../pagination/Pagination';
import PricingPlanSelector from './PricingPlanSelector';
import { PageSize } from '../pagination/types';
import { EnrollmentAccount } from '../../protected-pages/ess/electionWindow.types';
import ROUTES from '../../routes';

const useStyles = makeStyles((theme: Theme) => ({
  actionArea: {
    [theme.breakpoints.down('sm')]: {
      padingLeft: theme.spacing(4),
      padingRight: theme.spacing(4),
    },
  },
  formAction: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  margin0: {
    '& p': {
      margin: theme.spacing(0),
    }
  },
}));

export const ElectionWindowPricingPlan = ({
  formState,
  componentProps,
}: WizardFormProps<ElectionWindowModel>) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const {
    enrollmentAccounts,
    setEnrollmentAccounts,
    availablePlans,
    selectedGroup,
    electionWindowStatus,
  } = componentProps;
  const pageSizes = [5, 10, 25];
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<PageSize>(10);
  const start = (page - 1) * Number(pageSize);
  const end = start + Number(pageSize);

  const [records, setRecords] = useState(enrollmentAccounts.slice(start, end));

  const onPageChange = (newPage: number, newPageSize: PageSize) => {
    if (newPage !== page) {
      setPage(newPage);
    }
    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
    }
    const newStart = (newPage - 1) * Number(newPageSize);
    const newEnd = newStart + Number(newPageSize);
    setRecords(enrollmentAccounts.slice(newStart, newEnd));
  };
  const planOptions = [{ id: '', name: 'None' }, ...availablePlans];
  const paginationProps: PaginationProps = {
    count: enrollmentAccounts.length,
    pageSize,
    page,
    pageSizes,
    onPageChange,
  };

  const onPlanChange = (value: string, index: number) => {
    enrollmentAccounts.find(
      (acc: EnrollmentAccount) => acc.accountIndex === index,
    ).planChangedTo = value;
    setEnrollmentAccounts([...enrollmentAccounts]);
  };

  return (
    <>
      <Grid container direction={'column'} spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <Card>
              <CardContent>
                <Grid container direction={'column'} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant={'h2'}>
                      {t('OPEN_ENROLLMENT_PLAN_FORM_TITLE')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'body1'} className={classes.margin0} data-testid={'open-enrollment-plan-text'}>
                      {richT('OPEN_ENROLLMENT_PLAN_FORM_TEXT', {
                        EFFECTIVE_DATE: dayjs(electionWindowStatus?.periodEffectiveDate).format('MMMM D'),
                        GROUP_NAME: selectedGroup?.groupName
                      })}
                      <a
                        target={'__blank'}
                        href={ROUTES.ESS_DIRECT_ACCESS_OPERATIONS}
                        data-testid={'ess-direct-access-operations-link'}
                      >
                        ESS
                      </a>
                      .
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                  {enrollmentAccounts?.length > 5 && ( <Pagination {...paginationProps}/>)}
                    <PricingPlanSelector
                      accounts={records}
                      editMode={true}
                      availablePlans={planOptions}
                      onPlanChange={onPlanChange}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};
