import {
  CardContent,
  Paper,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import {
  AccountDetail,
  AccountDetailParams,
  AccountParams,
  CommPreferencesParams,
  DeliveryType,
  Maybe,
  NotificationFor,
  PersonContact,
  ServicePointDetail,
} from '../../../__generated__/pge-types';
import { useTranslation } from '../../../hooks/useTranslation';
import CauseDetermined from '../../../static/outageCauseIcons/dashboard/cause-determined.svg';
import CrewDispatched from '../../../static/outageCauseIcons/dashboard/crew-dispatched.svg';
import CrewOnsite from '../../../static/outageCauseIcons/dashboard/crew-onsite.svg';
import PowerOut from '../../../static/outageCauseIcons/dashboard/power-out.svg';
import mainColors from '../../../themes/main-colors';
import theme from '../../../themes/theme';
import Buttons from '../../buttons';
import RestorationStatus, {
  ACTIVE_RESTORATION_STEP,
} from '../../outage/outage-center/outage-map-panel/restoration-status/RestorationStatus';
import { formatEstimatedTimeOn } from '../../outage/outageUtils';
import { Model } from '../../outage/report-outage/serviceLocation.rules';
import UpdateOutageAlerts from '../../outage/outage-center/outage-notifications-popup/UpdateOutageAlerts';
import { getAccountNotificationsDetails } from '../../../queries/outage.query';
import { useApolloClient } from '@apollo/react-hooks';
import useUtils from '../../outage/outage-center/utils';
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      boxShadow: 'inset 0px 2px 0px #F06D04, 0px 3px 6px #00000029',
    },
    title: {
      fontWeight: 500,
      fontSize: '30px',
      fontFamily: 'Forma-DJR-Display',
      margin: 0,
    },
    accessTimeIcon: {
      fontSize: '17px',
      verticalAlign: 'text-bottom',
    },
    estimatedRestoration: {
      display: 'flex',
    },
    restorationStatusStepper: {
      borderRadius: '5px',
      backgroundColor: '#F2F2F2',
      padding: '10px 20px 5px 20px',
      width: '40%',
      [theme.breakpoints.down('sm')]: {
        marginTop: '20px',
        width: 'unset',
      },
    },
    currentStatus: {
      display: 'flex',
      marginTop: '25px',
      marginBottom: '25px',
    },
    svg: {
      marginRight: '25px',
    },
    outageStatus: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    noMargin: {
      margin: 0,
    },
    currentStatusDetails: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    actions: {
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    actionButton: {
      margin: '20px 20px 0 0',
      border: `2px solid ${mainColors.sparkBlue}`,
      width: '30%',
      '&:hover': {
        border: `2px solid ${mainColors.sparkBlue}`,
      },
      [theme.breakpoints.down('sm')]: {
        margin: 'unset',
        marginTop: '20px',
        width: '100%',
      },
      '&:nth-child(2)': {
        marginRight: 0,
      },
    },
    outageStatusDetails: {
      width: '60%',
      [theme.breakpoints.down('sm')]: {
        marginTop: '20px',
        width: 'unset',
      },
    },
  }),
);
type OutageCardProps = {
  outageDetails?: ServicePointDetail;
  accountParams: AccountParams | undefined;
  customerContact: Maybe<PersonContact>[] | undefined | null;
};
export const OutageCard = (props: OutageCardProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { outageDetails, accountParams, customerContact } = props;
  const [activeStep, setActiveStep] = React.useState<ACTIVE_RESTORATION_STEP>(
    ACTIVE_RESTORATION_STEP.POWER_OUT,
  );
  const [notificationFormValues, setNotificationFormValues] = useState<Model>({
    receiveEmail: false,
    email: '',
    receiveText: false,
    textNumber: '',
    callbackPhoneNumber: '',
    receiveCallBack: false,
    encryptedEmailContactId: '',
    encryptedTextNumberContactId: '',
  });
  const [
    isChangeNotificationSettingsPopup,
    setIsChangeNotificationSettingsPopup,
  ] = useState<boolean>(false);

  const [activeStepDetails, setActiveStepDetails] = React.useState<{
    icon: React.ReactNode;
    title: string;
  }>({
    icon: <PowerOut />,
    title: t('POWER_OUT'),
  });
  useEffect(() => {
    if (activeStep === ACTIVE_RESTORATION_STEP.CREW_DISPATCHED) {
      setActiveStepDetails({
        icon: <CrewDispatched />,
        title: t('CREW_DISPATCHED'),
      });
    }
    if (activeStep === ACTIVE_RESTORATION_STEP.CAUSE_DETERMINED) {
      setActiveStepDetails({
        icon: <CauseDetermined />,
        title: t('CAUSE_DETERMINED'),
      });
    }
    if (activeStep === ACTIVE_RESTORATION_STEP.CREW_ONSITE) {
      setActiveStepDetails({
        icon: <CrewOnsite />,
        title: t('CREW_ONSITE'),
      });
    }
  }, [activeStep]);

  const { setErrorNotification } = useUtils();
  const apolloClient = useApolloClient();

  const defaultFormValues: Model = {
    receiveEmail: false,
    email: '',
    receiveText: false,
    textNumber: '',
    callbackPhoneNumber: '',
    receiveCallBack: false,
    encryptedEmailContactId: '',
    encryptedEmailPreferenceId: '',
    encryptedTextNumberContactId: '',
    encryptedTextNumberPreferenceId: '',
  };
  const setupForm = (accountDetails: AccountDetail | undefined) => {
    if (Boolean(accountDetails?.commPreferences)) {
      accountDetails?.commPreferences &&
        accountDetails?.commPreferences[0]?.deliveryTypeDetails?.map(
          contactItem => {
            if (contactItem?.contactDetails) {
              if (contactItem?.deliveryType === DeliveryType.Email) {
                defaultFormValues.receiveEmail = Boolean(
                  contactItem.contactDetails[0]?.isSelected,
                );
                defaultFormValues.email =
                  contactItem.contactDetails[0]?.value || '';
                defaultFormValues.encryptedEmailContactId =
                  contactItem.contactDetails[0]?.encryptedContactId || '';
                defaultFormValues.encryptedEmailPreferenceId =
                  contactItem.contactDetails[0]?.encryptedPreferenceId || '';
              } else if (contactItem?.deliveryType === DeliveryType.Sms) {
                defaultFormValues.receiveText = Boolean(
                  contactItem.contactDetails[0]?.isSelected,
                );
                defaultFormValues.textNumber =
                  contactItem.contactDetails[0]?.value?.replace(/\D/g, '') ||
                  '';
                defaultFormValues.encryptedTextNumberContactId =
                  contactItem.contactDetails[0]?.encryptedContactId || '';
                defaultFormValues.encryptedTextNumberPreferenceId =
                  contactItem.contactDetails[0]?.encryptedPreferenceId || '';
              }
            }
          },
        );
    }

    if (!Boolean(defaultFormValues.email)) {
      const emailContact = customerContact?.find(contact => {
        return (
          (contact?.contactType === 'EMAIL' ||
            contact?.contactType === 'BEMAIL') &&
          contact?.isPrimary === true
        );
      });
      defaultFormValues.email = emailContact?.contactValue || '';
      defaultFormValues.receiveEmail = false;
      defaultFormValues.encryptedEmailContactId =
        (!Boolean(defaultFormValues.encryptedEmailContactId) &&
          emailContact?.encryptedContactId) ||
        '';
      defaultFormValues.encryptedEmailPreferenceId = null;
    }
    if (!Boolean(defaultFormValues.textNumber)) {
      const textContact = customerContact?.find(contact => {
        return contact?.contactType === 'MOB';
      });
      defaultFormValues.textNumber =
        textContact?.contactValue?.replace(/\D/g, '') || '';
      defaultFormValues.receiveText = false;
      defaultFormValues.encryptedTextNumberContactId =
        (!Boolean(defaultFormValues.encryptedTextNumberContactId) &&
          textContact?.encryptedContactId) ||
        '';
      defaultFormValues.encryptedTextNumberPreferenceId = null;
    }

    if (!Boolean(defaultFormValues.callbackPhoneNumber)) {
      const pnpPhone = customerContact?.find(contact => {
        return contact?.contactType === 'PNP';
      });
      if (pnpPhone) {
        defaultFormValues.callbackPhoneNumber =
          pnpPhone?.contactValue?.replace(/\D/g, '') || '';
      } else {
        const mobPhone = customerContact?.find(contact => {
          return contact?.contactType === 'MOB';
        });
        defaultFormValues.callbackPhoneNumber =
          mobPhone?.contactValue?.replace(/\D/g, '') || '';
      }
    }

    setNotificationFormValues(defaultFormValues);
    if (
      defaultFormValues.receiveCallBack ||
      defaultFormValues.receiveEmail ||
      defaultFormValues.receiveText
    ) {
      //setShowNotifSuccessfullySubscribed(true);
    }
  };

  const getNotificationPreferences = async () => {
    try {
      const result = await apolloClient.query<
        { getAccountDetails: Array<AccountDetail> },
        {
          params: AccountDetailParams;
          commPreferencesParams: CommPreferencesParams;
        }
      >({
        query: getAccountNotificationsDetails,
        variables: {
          params: {
            accountNumberList: [accountParams!],
          },
          commPreferencesParams: {
            notificationFor: NotificationFor.Outage,
          },
        },
        fetchPolicy: 'network-only',
      });
      setupForm(result?.data?.getAccountDetails[0]);
    } catch {
      setErrorNotification(true);
    }
  };

  useEffect(() => {
    void getNotificationPreferences();
  }, []);
  return (
    <>
      <Paper className={classes.container}>
        <CardContent>
          <div className={classes.outageStatus}>
            <div className={classes.outageStatusDetails}>
              <p className={classes.title} data-testid="card-title">
                {t('OUTAGE_REPORTED')}
              </p>
              <div
                className={classes.estimatedRestoration}
                data-testid="estimated-restoration"
              >
                <p>
                  <AccessTimeIcon className={classes.accessTimeIcon} />{' '}
                  {activeStep === ACTIVE_RESTORATION_STEP.CREW_ONSITE ||
                  activeStep === ACTIVE_RESTORATION_STEP.POWER_RESTORED
                    ? t('ESTIMATED_RESTORATION_DASHBOARD')
                    : t('INITIAL_ESTIMATED_RESTORATION_DASHBOARD')}{' '}
                  <b>{`${formatEstimatedTimeOn(
                    outageDetails?.outageInfo?.estimatedTimeOn,
                  )}`}</b>
                </p>
              </div>
              <div className={classes.currentStatus}>
                <div className={classes.svg}>{activeStepDetails.icon}</div>
                <div className={classes.currentStatusDetails}>
                  <p className={classes.noMargin}>
                    {t('OUTAGE_RESTORATION_STATUS')}
                  </p>
                  <p className={classes.noMargin}>
                    <b>{activeStepDetails.title}</b>
                  </p>
                </div>
              </div>
              <div>
                <p className={classes.noMargin}>
                  <b>{`${t('STATUS_UPDATED_AT')} ${format(
                    Date.now(),
                    'MM/dd/yyyy',
                  )}`}</b>
                </p>
                {t('OUTAGES_DYNAMIC_STATUS_UPDATE_MESSAGING') !==
                  'OUTAGES_DYNAMIC_STATUS_UPDATE_MESSAGING' && (
                  <p className={classes.noMargin}>
                    {t('OUTAGES_DYNAMIC_STATUS_UPDATE_MESSAGING')}
                  </p>
                )}
              </div>
            </div>
            <div
              className={classes.restorationStatusStepper}
              data-testid="restoration-status-stepper"
            >
              <RestorationStatus
                isDashboard={true}
                setActiveStep={setActiveStep}
                isCrewOnsite={false}
                crewDispatchedStatus={
                  outageDetails?.outageInfo?.crewDispatchStatus
                }
                cause={outageDetails?.outageInfo?.cause}
                eventStatus={outageDetails?.outageInfo?.eventStatus}
              />
            </div>
          </div>
          <div className={classes.actions}>
            <Buttons
              type="button"
              variant="contained"
              className={classes.actionButton}
              onClick={() => setIsChangeNotificationSettingsPopup(true)}
              data-testid="get-status-alerts"
            >
              {t('GET_STATUS_ALERTS')}
            </Buttons>
          </div>
        </CardContent>
      </Paper>
      {isChangeNotificationSettingsPopup && (
        <UpdateOutageAlerts
          setNotifFormSuccessfullySubmitted={
            setIsChangeNotificationSettingsPopup
          }
          setNotificationFormValues={setNotificationFormValues}
          open={isChangeNotificationSettingsPopup}
          setIsChangeNotificationSettingsPopup={
            setIsChangeNotificationSettingsPopup
          }
          loggedInUserNotificationRequestAccountParams={{
            accountNumber: accountParams?.accountNumber!,
            encryptedPersonId: accountParams?.encryptedPersonId!,
          }}
          notificationFormValues={notificationFormValues}
        />
      )}
    </>
  );
};
