import Grid from '@material-ui/core/Grid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withWidth, { WithWidth } from '@material-ui/core/withWidth';
import { Router } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';

import AccountLayout from '../../../components/account-layout/AccountLayout';
import Backdrop from '../../../components/backdrop';
import TextLink from '../../../components/text-link';
import CreateCustomGroup from '../../../components/create-custom-group';
import useCreateCustomGroupHook from '../../../hooks/useCreateCustomGroup';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainArea: {
      minWidth: '50em',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        minWidth: '15em',
      },
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
  }),
);

type Props = {
  path?: string;
};

const ManageAccountsPage = (props: Props & WithWidth) => {
  const { t } = useTranslation();
  const manageAccountsProps = useCreateCustomGroupHook();
  const { loading } = manageAccountsProps;
  const isMobile = ['xs', 'sm', 'md'].includes(props.width);
  const childProps = { ...manageAccountsProps, isMobile, ...props };
  const classes = useStyles();

  return (
    <AccountLayout>
      <Helmet>
        <title>{t('Manage Account Groups')}</title>
      </Helmet>
      <div className={classes.mainArea}>
        {loading && <Backdrop forceOpen />}

        {!loading && (
          <Grid
            container
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <Grid item style={{ marginBottom: '1em' }} xs={12} md={8}>
              <Typography
                style={{ color: colors.noirBlur }}
                component={'span'}
                variant={'h1'}
              >
                {t('Create a custom group')}
              </Typography>
            </Grid>
            <Router basepath="/">
              <CreateCustomGroup
                {...childProps}
                path={ROUTES.CREATE_CUSTOM_GROUP}
              />
            </Router>
            <Grid item style={{ marginTop: '1em' }}>
              <Typography
                variant={'body2'}
                style={{ display: 'inline-block', marginRight: '0.2em' }}
              >
                {t('CALL_FOR_ASSISTANCE')}
              </Typography>
              <TextLink
                plain
                external={true}
                to={'tel:' + t('CALL_FOR_ASSISTANCE_BIZ_NUMBER')}
              >
                {t('CALL_FOR_ASSISTANCE_BIZ_NUMBER')}
              </TextLink>
            </Grid>
          </Grid>
        )}
      </div>
    </AccountLayout>
  );
};

export default withWidth()(ManageAccountsPage);
