import React, { FC } from 'react';
import { Theme } from '@material-ui/core/styles';
import { isPast, isToday } from 'date-fns';

import { useTranslation } from '../../../../hooks/useTranslation';
import { Grid, makeStyles, createStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import colors from '../../../../themes/main-colors';
import { formatDate } from '../../../../util/format';
import PaymentDueIcon from '../../../../static/paymentDue.svg';

import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    firstInstallmentCircleIcon: {
      background: colors.errorRed,
      width: 112,
      height: 112,
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    circleIcon: {
      background: colors.gray97,
      width: 54,
      height: 54,
      borderRadius: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `2px solid ${colors.gray93}`,
    },
    fristInstallmentCircleText: (props: { largerAmount: Boolean }) => ({
      color: colors.white,
      fontWeight: 700,
      fontSize: props.largerAmount ? '1.75rem' : '2.125rem',
    }),
    circleText: (props: {
      largerAmount: Boolean;
      isTPAPaymentOverDue: Boolean | undefined;
    }) => ({
      color: props.isTPAPaymentOverDue ? colors.darkerRed : colors.darkGray2,
      fontSize: props.largerAmount ? '0.875rem' : '1.31rem',
    }),
    firstInstallmentCircleFooter: {
      color: colors.noirBlur,
      fontWeight: 'bold',
    },
    circleFooter: {
      color: colors.noirBlur,
    },
    delayedPayment: {
      color: colors.darkerRed,
    },
    circleUpcomingPayment: {
      border: `2px solid ${colors.darkerRed}`,
      backgroundColor: colors.white,
    },
    container: {
      display: 'inline-block',
      textAlign: 'center',
      margin: theme.spacing(0, 1.5, 3, 1.5),
      '&:first-child': {
        float: 'left',
        [theme.breakpoints.down('sm')]: {
          borderRight: 0,
          padding: theme.spacing(0, 2.25, 0, 0),
        },
      },
    },
    completePageContainer: {
      margin: theme.spacing(0, 0, 3, 1.5),
      width: '90px',
      '&:first-child': {
        float: 'none',
        padding: theme.spacing(0),
      },
      '& .completePageCircleIcon': {
        margin: '0 auto 10px',
      },
      '& .paymentDoneCheckmark': {
        backgroundColor: colors.white,
      },
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 0, 3, 0.75),
      },
    },
  }),
);
type Props = {
  installmentAmount: number;
  installmentDate: Date;
  isFirstInstallment: Boolean;
  isPaymentCompleted: Boolean;
  isTPACompletePaymentPage?: Boolean;
  isUpcomingPayment?: Boolean;
  isTPAPaymentOverDue?: Boolean;
};
const SingleInstallment: FC<Props> = (props: Props) => {
  const {
    installmentAmount,
    installmentDate,
    isFirstInstallment,
    isTPACompletePaymentPage,
    isPaymentCompleted,
    isUpcomingPayment,
    isTPAPaymentOverDue,
  } = props;
  const largerAmount = installmentAmount.toString().length > 2;
  const { t } = useTranslation();
  const classes = useStyles({
    largerAmount,
    isTPAPaymentOverDue: isTPAPaymentOverDue,
  });

  return (
    <>
      <Grid
        className={`${classes.container} ${
          isTPACompletePaymentPage ? classes.completePageContainer : ''
        }`}
      >
        {isTPAPaymentOverDue && !isUpcomingPayment ? (
          <PaymentDueIcon />
        ) : (
          <Typography
            component="div"
            className={clsx(
              isFirstInstallment
                ? classes.firstInstallmentCircleIcon
                : classes.circleIcon,
              isTPACompletePaymentPage && 'completePageCircleIcon',
              isPaymentCompleted && 'paymentDoneCheckmark',
              isTPAPaymentOverDue && classes.circleUpcomingPayment,
            )}
          >
            {isPaymentCompleted ? (
              <CheckIcon
                fontSize="large"
                style={{ color: colors.shoreGreen }}
              />
            ) : (
              <Typography
                component="span"
                variant="h3"
                className={clsx(
                  isFirstInstallment
                    ? classes.fristInstallmentCircleText
                    : classes.circleText,
                )}
              >
                {`$${installmentAmount}`}
                {isFirstInstallment && (
                  <sup data-testid={'sup-data-element'}>*</sup>
                )}
              </Typography>
            )}
          </Typography>
        )}

        {isFirstInstallment && (
          <Typography
            data-testid={'due-by-text'}
            component="p"
            variant={'h6'}
            className={classes.firstInstallmentCircleFooter}
          >
            {t('DUE_BY')}
          </Typography>
        )}
        <Typography
          data-testid={'display-date'}
          component="p"
          variant={'h4'}
          className={clsx(
            isFirstInstallment
              ? classes.firstInstallmentCircleFooter
              : classes.circleFooter,
            isTPAPaymentOverDue && classes.delayedPayment,
          )}
        >
          {installmentDate &&
          (isFirstInstallment ||
            (isTPACompletePaymentPage && isPaymentCompleted) ||
            isTPAPaymentOverDue)
            ? formatDate(installmentDate)
            : formatDate(installmentDate, 'MMM')}
        </Typography>
      </Grid>
    </>
  );
};
export default SingleInstallment;
