import { useApolloClient } from '@apollo/react-hooks';
import {
  MutationUpdateSectionErAccountInfoArgs,
  MutationUpdateSectionErRebateConfirmArgs,
  MutationUpdateSectionErRebateInfoArgs,
  UpdateSectionErAccountInfoInput,
  UpdateSectionErAccountInfoOutput,
  UpdateSectionErRebateConfirmInput,
  UpdateSectionErRebateConfirmOutput,
  UpdateSectionErRebateInfoInput,
  UpdateSectionErRebateInfoOutput,
} from '../../__generated__/pgeplus-types';
import gql from 'not-graphql-tag';
import {
  sectionERAccountInfo,
  sectionERRebateConfirm,
  sectionERRebateInfo,
  validationFragment,
} from '../../components/pge-plus-common/queries/fragments';

export const updateSectionAccountInfoMutation = gql`
  mutation updateSectionERAccountInfo(
    $input: UpdateSectionERAccountInfoInput!
  ) {
    updateSectionERAccountInfo(input: $input) {
      validation {
        ...validationFragment
      }
      section {
        ...sectionERAccountInfo
        errors {
          code
          details
          message
        }
      }
    }
  }
  ${sectionERAccountInfo}
  ${validationFragment}
`;

export const updateSectionRebateInfoMutation = gql`
  mutation updateSectionERRebateInfo($input: UpdateSectionERRebateInfoInput!) {
    updateSectionERRebateInfo(input: $input) {
      validation {
        ...validationFragment
      }
      section {
        ...sectionERRebateInfo
        errors {
          code
          details
          message
        }
      }
    }
  }
  ${sectionERRebateInfo}
  ${validationFragment}
`;

export const updateSectionRebateConfirmMutation = gql`
  mutation updateSectionERRebateConfirm(
    $input: UpdateSectionERRebateConfirmInput!
  ) {
    updateSectionERRebateConfirm(input: $input) {
      validation {
        ...validationFragment
      }
      section {
        ...sectionERRebateConfirm
        errors {
          code
          details
          message
        }
      }
    }
  }
  ${sectionERRebateConfirm}
  ${validationFragment}
`;

const useUpdateERSections = () => {
  const apolloClient = useApolloClient();

  const updateSectionAccountInfo = (input: UpdateSectionErAccountInfoInput) => {
    return apolloClient.mutate<
      { updateSectionERAccountInfo: UpdateSectionErAccountInfoOutput },
      MutationUpdateSectionErAccountInfoArgs
    >({
      mutation: updateSectionAccountInfoMutation,
      variables: { input },
      context: { pgePlus: true },
      errorPolicy: 'all',
    });
  };

  const updateSectionRebateInfo = (input: UpdateSectionErRebateInfoInput) => {
    return apolloClient.mutate<
      { updateSectionERRebateInfo: UpdateSectionErRebateInfoOutput },
      MutationUpdateSectionErRebateInfoArgs
    >({
      mutation: updateSectionRebateInfoMutation,
      variables: { input },
      context: { pgePlus: true },
      errorPolicy: 'all',
    });
  };

  const updateSectionRebateConfirm = (
    input: UpdateSectionErRebateConfirmInput,
  ) => {
    return apolloClient.mutate<
      { updateSectionERRebateConfirm: UpdateSectionErRebateConfirmOutput },
      MutationUpdateSectionErRebateConfirmArgs
    >({
      mutation: updateSectionRebateConfirmMutation,
      variables: { input },
      context: { pgePlus: true },
      errorPolicy: 'all',
    });
  };

  return {
    updateSectionAccountInfo,
    updateSectionRebateInfo,
    updateSectionRebateConfirm,
  };
};

export default useUpdateERSections;
