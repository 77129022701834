import gql from 'not-graphql-tag';
import useAuthQuery from './useAuthQuery';
import {
  MoveSvcEligibilityRequest,
  MoveSvcEligibilityResponse,
} from '../__generated__/pge-types';

const GET_MOVE_SVC_ELIGIBILITY_QUERY = gql`
  query moveSvcEligibility($param: MoveSvcEligibilityRequest) {
    moveSvcEligibility(param: $param) {
      hasDisconnect
      hasActiveDepositAndTPAAgreement
      hasActiveServiceAgreement
      hasBadDebt
      hasFraud
      hasEnrolledInEqualpay
      hasPendingDisconnect
    }
  }
`;

export const useSSMMoveGetAccountEligibilityQuery = () => {
  const { refetch, loading, error, data } = useAuthQuery<
    { moveSvcEligibility: MoveSvcEligibilityResponse },
    { param: MoveSvcEligibilityRequest }
  >(GET_MOVE_SVC_ELIGIBILITY_QUERY, {
    skip: true,
  });
  return {
    refetch,
    loading,
    error,
    data,
  };
};

export default useSSMMoveGetAccountEligibilityQuery;
