import { useApolloClient } from '@apollo/react-hooks';
import {
  AccountDetail,
  EnrollMedicalCertProgramResponse,
  LinkedPerson,
  MedicalCertStatusResponse,
} from '../../__generated__/pge-types';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import useAuthQuery from '../../hooks/useAuthQuery';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import {
  getLinkedPersons,
  GetMedicalCertStatus,
  enrollMedicalCertificateMutation,
} from './queries';
import gql from 'graphql-tag';

const getAccountDetailsQuery = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      premiseInfo {
        encryptedPremiseId
        addressLine1
        addressLine2
        city
        state
        postal
      }
    }
  }
`;

export function useManageMedicalCertificate() {
  const { accountParams } = useSelectedAccountParams();
  const { customer } = useAccountCustomer();

  const apolloClient = useApolloClient();

  const {
    loading: personsLoading,
    data: personData,
    refetch: refetchPersons,
  } = useAuthQuery<{
    getLinkedPersons: Array<LinkedPerson>;
  }>(getLinkedPersons, {
    variables: {
      params: {
        accountId: accountParams?.accountNumber,
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
    fetchPolicy: 'no-cache',
  });

  const {
    loading: medicalCertStatusLoading,
    data: medicalCertStatusData,
  } = useAuthQuery<{
    getMedicalCertStatus: MedicalCertStatusResponse;
  }>(GetMedicalCertStatus, {
    variables: {
      params: {
        accountNumber: accountParams?.accountNumber,
        encryptedPersonId: accountParams?.encryptedPersonId,
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
    fetchPolicy: 'no-cache',
  });

  const { loading: hookLoading, data } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetailsQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams,
  });

  const handleSubmitEnrollment = async (
    encryptedPersonId: string,
  ): Promise<EnrollMedicalCertProgramResponse | undefined> => {
    try {
      const result = await apolloClient.mutate<{
        enrollMedicalCertificateProgram: EnrollMedicalCertProgramResponse;
      }>({
        mutation: enrollMedicalCertificateMutation,
        variables: {
          payload: {
            accountNumber: accountParams?.accountNumber,
            encryptedPersonId: encryptedPersonId,
            loggedInUserName: customer?.personName,
            premiseId:
              data?.getAccountDetails?.[0].premiseInfo?.[0]
                ?.encryptedPremiseId || '',
          },
        },
      });
      return result?.data?.enrollMedicalCertificateProgram;
    } catch (error) {
      return undefined;
    }
  };

  const mainPersonEmail =
    (personData &&
      personData?.getLinkedPersons.find(
        person => person.accountRelTypeCd === 'MAIN',
      )?.email) ||
    '';

  return {
    selectedAccountNumber: accountParams?.accountNumber,
    customer,
    isLoading: personsLoading && medicalCertStatusLoading && hookLoading,
    persons: personData?.getLinkedPersons,
    refetchPersons,
    mainPersonEmail,
    handleSubmitEnrollment,
    currentEnrollmentDetails: medicalCertStatusData?.getMedicalCertStatus,
  };
}
