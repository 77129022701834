import gql from 'not-graphql-tag';
import useAuthQuery from './useAuthQuery';
import {
  StopServiceEligibilityResponse,
  StopServiceEligibilityRequest,
} from '../__generated__/pge-types';

const GET_STOP_SVC_ELIGIBILITY_QUERY = gql`
  query StopSrvceEligibility($params: StopServiceEligibilityRequest) {
    stopSrvceEligibility(params: $params) {
      acctId
      isPendingDisconnect
      isDisconnected
      hasMultiplePremises
      hasNoActiveElectricSA
      hasProtectedAddress
      hasPendingStop
    }
  }
`;

export const useSSMStopGetAccountEligibilityQuery = () => {
  const { refetch, loading, error, data } = useAuthQuery<
    { stopSrvceEligibility: StopServiceEligibilityResponse },
    { params: StopServiceEligibilityRequest }
  >(GET_STOP_SVC_ELIGIBILITY_QUERY, {
    skip: true,
  });

  return {
    refetch,
    loading,
    error,
    data,
  };
};

export default useSSMStopGetAccountEligibilityQuery;
