import React, { useContext, useMemo } from 'react';
import { Divider, Typography, Button, Paper } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { navigate } from 'gatsby';

import ROUTES from '../../routes';
import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import { featureFlagsContext } from '../../providers/FeatureFlagsProvider';
import { AccountLeftNavInactiveAccountsItem } from './account-left-nav-block/AccountLeftNavInactiveAccountsItem';
import useAccountsStore from '../../hooks/useAccountsStore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: colors.noirBlur,
      fontSize: '16px',
      fontWeight: 600,
      margin: theme.spacing(1, 3),
    },
    labelLg: {
      fontSize: '20px',
      fontWeight: 400,
    },
    subLabel: {
      color: theme.palette.primary.main,
      fontSize: '16px',
      fontWeight: 400,
      margin: theme.spacing(0, 4),
    },
    divider: {
      backgroundColor: `${theme.palette.primary.main}44`,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    button: {
      display: 'block',
      color: theme.palette.primary.main,
      textAlign: 'left',
      width: '100%',
      '&:hover': {
        backgroundColor: theme.palette.primary.main + '33',
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main + '33',
      },
    },
    navSection: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
    },
  }),
);

interface MenuItem {
  label: string;
  url: string;
  onClick?: any;
}

export default function NoSaNavBlock() {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isClosedAccountsEnabled } = useContext(featureFlagsContext);
  const { customerAccountState } = useAccountsStore();

  const manageYourServiceOptions: MenuItem[] = useMemo(
    () => [
      {
        label: t('START_STOP_SERVICE_SIDE_MENU'),
        url: ROUTES.START_STOP_MOVE,
      },
    ],
    [],
  );

  const profilePreferencesOptions: MenuItem[] = useMemo(
    () => [
      {
        label: t('MANAGE_ACCOUNTS_GROUPS'),
        url: ROUTES.MANAGE_ACCOUNTS_LANDING_SCREEN,
      },
      { label: t('CHANGE_PASSWORD_BUTTON'), url: ROUTES.CHANGE_PASSWORD },
      { label: t('CHANGE_PIN'), url: ROUTES.CHANGE_PIN },
      { label: t('CHANGE_EMAIL'), url: ROUTES.CHANGE_EMAIL },
      {
        label: t('CANCEL_ONLINE_SERVICE'),
        url: ROUTES.CANCEL_ONLINE_SERVICE,
      },
    ],
    [],
  );

  return (
    <>
      <Paper className={classes.navSection}>
        <Button
          className={classes.button}
          onMouseDown={() => navigate(ROUTES.ACCOUNT)}
        >
          <Typography
            variant="button"
            noWrap
            className={clsx(classes.label, classes.labelLg)}
          >
            {t('MY_ACCOUNT')}
          </Typography>
        </Button>
        <Divider classes={{ root: classes.divider }} />
        <Button
          className={classes.button}
          onMouseDown={() => navigate(ROUTES.OUTAGES)}
        >
          <Typography variant="button" noWrap className={classes.subLabel}>
            {t('REPORT_AN_OUTAGE')}
          </Typography>
        </Button>
        <Divider classes={{ root: classes.divider }} />
        <Typography variant={'button'} noWrap className={classes.label}>
          {t('MANAGE_YOUR_SERVICE')}
        </Typography>
        {manageYourServiceOptions.map((item, index) => (
          <Button
            key={`megaButton-${index}`}
            className={classes.button}
            onMouseDown={item.onClick ? item.onClick : () => navigate(item.url)}
          >
            <Typography variant="button" noWrap className={classes.subLabel}>
              {item.label}
            </Typography>
          </Button>
        ))}
        <Divider classes={{ root: classes.divider }} />
        <Typography variant={'button'} noWrap className={classes.label}>
          {t('PROFILE_AND_PREFERENCES_SIDE_MENU')}
        </Typography>
        {profilePreferencesOptions.map((item, index) => (
          <Button
            key={`megaButton-${index}`}
            className={classes.button}
            onMouseDown={item.onClick ? item.onClick : () => navigate(item.url)}
          >
            <Typography variant="button" noWrap className={classes.subLabel}>
              {item.label}
            </Typography>
          </Button>
        ))}
      </Paper>
    </>
  );
}
