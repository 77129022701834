import { Paper } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useMemo, useState } from 'react';
import { getPaymentMethodLabel } from '../../../hooks/usePaymentus';
import { useTranslation } from '../../../hooks/useTranslation';
import { dateSuffixMap, toDateString } from '../../../util/format';
import {
  AccountDetail,
  AccountType,
  PaymentCategory,
  PaymentProfile,
} from '../../../__generated__/pge-types';
import PGEButton from '../../buttons';
import DaySelector from '../../day-selector';
import PaymentMethodSelector from '../../payment-wallet/payment-method-selector';
import PaymentLimitsFees from '../../paymentus/payment-limits-fees';
import { ADD_PROFILE } from '../../paymentus/types';
import { getAccountDisplayString } from '../../paymentus/utils';
import { AutoPayConfigData } from '../auto-pay.types';
import {
  getDayFromDueDate,
  getPaymentMethodSelectorData,
  isDateInFuture,
  isDateInNextNDays,
  isEligibleToCancel,
  isEligibleToChangePaymentMethod,
  isPDDEligible,
  mergeAutoPayConfigChanges,
} from '../auto-pay.utils';
import { useStyles } from './AutoPayStart.styles';
import IneligibleAlert from './IneligibleAlert';

type Props = {
  onUpdatePaymentProfile: (config: AutoPayConfigData) => Promise<void>;
  savedProfileList?: PaymentProfile[];
  onUpdatePayDay: (config: AutoPayConfigData) => Promise<void>;
  onUpdateAutoPayConfig: (config: AutoPayConfigData) => Promise<void>;
  onCancel: () => Promise<void>;
  account: AccountDetail;
};

type AUTO_PAY_OPTIONS =
  | 'AUTO_PAY_UPDATE_PAYMENT_PROFILE'
  | 'AUTO_PAY_UPDATE_DAY'
  | 'AUTO_PAY_CANCEL';

export default function AutoPayEnrolled({
  onUpdatePayDay,
  onUpdatePaymentProfile,
  savedProfileList,
  onUpdateAutoPayConfig,
  onCancel,
  account,
}: Props) {
  const classes = useStyles();
  const { t, richT } = useTranslation();
  const [selectedOption, setSelectedOption] = useState<
    AUTO_PAY_OPTIONS | undefined
  >();

  const [autoNext, setAutoNext] = useState(false);

  const infoEnrolled = t('AUTO_PAY_ENROLLED_INFO_PAYMENT_PROFILE');
  const infoEnrolledOther = t('AUTO_PAY_ENROLLED_INFO_OTHER');
  const infoDayEnrolled = t('AUTO_PAY_ENLROLLED_INFO_DAY');
  const dueDayEnrolled = t('AUTO_PAY_ENROLLED_DUE_DAY');
  const updatePaymentProfileOption = t(
    'AUTO_PAY_ENROLLED_UPDATE_PAYMENT_PROFILE',
  );
  const updatePaymentProfileSubmit = t('UPDATE_PAYMENT_PROFILE');
  const updateDayOption = t('AUTO_PAY_ENROLLED_UPDATE_DAY_OPTION');
  const updateDayOptionText = t('AUTO_PAY_ENROLLED_UPDATE_DAY_OPTION_TEXT');
  const updateDayOptionSubmit = t('CHANGE');
  const updateDayOptionNote = t('AUTO_PAY_ENROLLED_UPDATE_DAY_OPTION_NOTE');
  const cancelOptionText = t('AUTO_PAY_ENROLLED_CANCEL_TEXT');
  const cancelOptionSubmit = t('AUTO_PAY_ENROLLED_CANCEL_SUBMIT');
  const cancelOption = t('AUTO_PAY_ENROLLED_CANCEL_OPTION');
  const ineligibleChangeDayMessage1 = t('AUTO_PAY_ENROLLED_INELIGIBLE_CHANGE');
  const ineligibleChangePaymentMethodMessage = t(
    'AUTO_PAY_ENRROLED_INELIGIBLE_CHANGE_PAYMENT_METHOD_MESSAGE',
  );
  const ineligibleCancelMessage = t(
    'AUTO_PAY_ENROLLED_INELIGIBLE_CANCEL_MESSAGE',
  );

  const currentPaymentProfile = savedProfileList?.find(
    p => p.token === account?.autoPay?.enrollDetails?.tokenId,
  );

  const preferredDueDate =
    account?.preferredDueDate?.dueDate?.preferredDueDate ||
    (isDateInFuture(account?.currentCharges?.dueDate)
      ? getDayFromDueDate(account?.currentCharges?.dueDate)
      : null);

  const defaultAutoPayConfig = useMemo(
    () => ({
      selectedDay: preferredDueDate,
      paymentSelector: getPaymentMethodSelectorData(
        'autoPay',
        account?.paymentEligibility,
        account?.paymentAlerts?.isFutureDated,
        savedProfileList,
        currentPaymentProfile ? { profile: currentPaymentProfile } : undefined,
      ),
    }),
    [account],
  );

  const [autoPayConfig, setAutoPayConfig] = useState<AutoPayConfigData>(
    defaultAutoPayConfig,
  );
  // const bankSectionForm = useBankSectionForm(autoPayConfig.paymentSelector);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoPayConfig(defaultAutoPayConfig);
    setSelectedOption(
      (event.target as HTMLInputElement).value as AUTO_PAY_OPTIONS,
    );
  };

  async function handlePaymentProfileChange() {
    try {
      return onUpdatePaymentProfile(autoPayConfig);
    } catch (_) {
      // Do nothing, this means, there was a form error
    }
  }

  useEffect(() => {
    if (autoNext) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handlePaymentProfileChange();
    }
  }, [autoNext]);

  const typeLabel = getPaymentMethodLabel(currentPaymentProfile?.type, true);

  const enrolledLabel = currentPaymentProfile?.type
    ? `${infoEnrolled} with ${typeLabel} `
    : infoEnrolledOther;
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Card className={classes.root}>
          <CardContent className={classes.cardContent}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant={'h4'}>
                  {enrolledLabel}
                  {!!currentPaymentProfile?.type && (
                    <b>{getAccountDisplayString(currentPaymentProfile)}</b>
                  )}
                  .
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {preferredDueDate ? (
                  <Typography variant={'body1'}>
                    {infoDayEnrolled}
                    <strong>
                      {preferredDueDate}
                      {dateSuffixMap(t, preferredDueDate)}
                    </strong>
                    .
                  </Typography>
                ) : (
                  <Typography variant={'body1'}>{dueDayEnrolled}</Typography>
                )}
              </Grid>
              <Grid item container direction={'column'} xs={12}>
                <RadioGroup
                  aria-label="change-auto-pay"
                  value={selectedOption || ''}
                  onChange={handleOptionChange}
                >
                  <FormControlLabel
                    value={'AUTO_PAY_UPDATE_PAYMENT_PROFILE'}
                    control={<Radio color={'primary'} />}
                    label={updatePaymentProfileOption}
                  />
                  {selectedOption === 'AUTO_PAY_UPDATE_PAYMENT_PROFILE' &&
                    !isEligibleToChangePaymentMethod(account.autoPay) && (
                      <IneligibleAlert
                        message={ineligibleChangePaymentMethodMessage}
                      />
                    )}
                  <FormControlLabel
                    value={'AUTO_PAY_UPDATE_DAY'}
                    control={<Radio color={'primary'} />}
                    label={updateDayOption}
                  />
                  {selectedOption === 'AUTO_PAY_UPDATE_DAY' ? (
                    isPDDEligible(account.preferredDueDate) ? (
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          container
                          spacing={2}
                          className={classes.daySelectionArea}
                        >
                          <Grid item>
                            <Typography variant={'body1'}>
                              {updateDayOptionText}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <DaySelector
                              value={autoPayConfig.selectedDay}
                              onChange={day =>
                                setAutoPayConfig(cfg => ({
                                  ...cfg,
                                  selectedDay: day,
                                }))
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant={'body1'}>
                            {updateDayOptionNote}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.actionArea}>
                          <PGEButton
                            disabled={
                              autoPayConfig.selectedDay === null ||
                              autoPayConfig.selectedDay ===
                                account?.preferredDueDate?.dueDate
                                  ?.preferredDueDate
                            }
                            onClick={() => onUpdatePayDay(autoPayConfig)}
                          >
                            {updateDayOptionSubmit}
                          </PGEButton>
                        </Grid>
                      </Grid>
                    ) : (
                      <IneligibleAlert message={ineligibleChangeDayMessage1} />
                    )
                  ) : null}
                  <FormControlLabel
                    value={'AUTO_PAY_CANCEL'}
                    control={<Radio color={'primary'} />}
                    label={cancelOption}
                  />
                  {selectedOption === 'AUTO_PAY_CANCEL' &&
                    isEligibleToCancel(account?.autoPay) && (
                      <Grid container spacing={2}>
                        <Grid item>
                          <Typography variant={'body1'}>
                            {cancelOptionText}
                          </Typography>
                          {Boolean(
                            isDateInNextNDays(
                              account.currentCharges?.dueDate,
                              1,
                            ),
                          ) &&
                            Number(account?.currentCharges?.amountDue) > 0 && (
                              <Typography
                                variant={'body1'}
                                className={classes.errorText}
                              >
                                <strong>
                                  {t('AUTO_PAY_CANCEL_PAYMENT_IN_PROCESS', {
                                    AMOUNT: String(
                                      account?.currentCharges?.amountDue,
                                    ),
                                    DUE_DATE: toDateString(
                                      account?.currentCharges?.dueDate,
                                    ),
                                  })}
                                </strong>
                              </Typography>
                            )}
                        </Grid>
                        <Grid
                          item
                          container
                          direction={'row'}
                          justify={'flex-end'}
                          spacing={1}
                          alignItems={'center'}
                          xs={12}
                        >
                          <Grid item xs={12} className={classes.actionArea}>
                            <PGEButton onClick={onCancel}>
                              {cancelOptionSubmit}
                            </PGEButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  {selectedOption === 'AUTO_PAY_CANCEL' &&
                    !isEligibleToCancel(account?.autoPay) && (
                      <IneligibleAlert message={ineligibleCancelMessage} />
                    )}
                </RadioGroup>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        {selectedOption === 'AUTO_PAY_UPDATE_PAYMENT_PROFILE' &&
          isEligibleToChangePaymentMethod(account.autoPay) && (
            <Grid container>
              <Grid item xs={12}>
                <Paper
                  style={{
                    justifyContent: 'flex-start',
                    padding: '0px 15px 20px 15px',
                  }}
                >
                  <Grid style={{ float: 'right', margin: '10px' }}>
                    <PaymentLimitsFees tabIndex={-1} />
                  </Grid>

                  <PaymentMethodSelector
                    selectLabel={t('SELECT_YOUR_PAYMENT_METHOD')}
                    isAutopay={true}
                    allowedCategories={
                      account?.autoPay?.availablePaymentMethods!
                    }
                    excludeCategories={
                      account?.accountType !== AccountType.Res
                        ? [
                            PaymentCategory.Cc,
                            PaymentCategory.Dc,
                            PaymentCategory.AmazonPay,
                            PaymentCategory.PaypalAccount,
                          ]
                        : account?.accountType === AccountType.Res &&
                          account?.paymentEligibility?.isCashOnly
                        ? [PaymentCategory.Dd]
                        : undefined
                    }
                    currentProfileInfo={
                      autoPayConfig.paymentSelector.selectedProfileInfo
                    }
                    onChange={selectedProfileInfo => {
                      setAutoPayConfig(config =>
                        mergeAutoPayConfigChanges(config, {
                          newProfileList: config.paymentSelector.newProfileList,
                          selectedProfileInfo,
                        }),
                      );
                      onUpdateAutoPayConfig &&
                        onUpdateAutoPayConfig(autoPayConfig);
                    }}
                    onNew={newProfileInfo => {
                      setAutoPayConfig(config =>
                        mergeAutoPayConfigChanges(config, {
                          newProfileList: [
                            ...config.paymentSelector.newProfileList,
                            newProfileInfo.profile,
                          ],
                          selectedProfileInfo: newProfileInfo,
                        }),
                      );
                      setAutoNext(true);
                    }}
                    onDelete={deleteProfile => {
                      setAutoPayConfig(config =>
                        mergeAutoPayConfigChanges(config, {
                          newProfileList: config.paymentSelector.newProfileList.filter(
                            p => p?.token !== deleteProfile?.profile?.token,
                          ),
                          selectedProfileInfo:
                            defaultAutoPayConfig.paymentSelector
                              .selectedProfileInfo,
                        }),
                      );
                      onUpdateAutoPayConfig &&
                        onUpdateAutoPayConfig(autoPayConfig);
                    }}
                  />
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                className={classes.actionArea}
                style={{ marginTop: '30px' }}
              >
                <Grid item xs={12} className={classes.actionArea}>
                  <PGEButton
                    disabled={
                      !autoPayConfig?.paymentSelector?.selectedProfileInfo ||
                      autoPayConfig?.paymentSelector?.selectedProfileInfo
                        ?.profile?.token === currentPaymentProfile?.token ||
                      autoPayConfig?.paymentSelector?.selectedProfileInfo
                        ?.profile === ADD_PROFILE
                    }
                    onClick={handlePaymentProfileChange}
                  >
                    {updatePaymentProfileSubmit}
                  </PGEButton>
                </Grid>
              </Grid>
            </Grid>
          )}
      </Grid>
    </Grid>
  );
}
