import gql from 'not-graphql-tag';

export const getPeakTimeRebatesProgramInfoQuery = gql`
  query getPeakTimeRebateProgramInfo($payload: PeakTimeRebateParams!) {
    getPeakTimeRebateProgramInfo(payload: $payload) {
      isEnrolled
      isEligible
      peakTimeRebateContact {
        email
        mobilePhoneNumber
      }
    }
  }
`;

export const peakTimeRebatesEnrollMutation = gql`
  mutation enrollPeakTimeRebate($payload: PeaktimeRebateUpsertInput!) {
    enrollPeakTimeRebate(payload: $payload) {
      success
    }
  }
`;

export const peakTimeRebatesUpdateMutation = gql`
  mutation updatePeakTimeRebate($payload: PeaktimeRebateUpsertInput!) {
    updatePeakTimeRebate(payload: $payload) {
      success
    }
  }
`;

export const peakTimeRebatesCancelMutation = gql`
  mutation cancelPeakTimeRebate($payload: PeakTimeRebateParams!) {
    cancelPeakTimeRebate(payload: $payload) {
      success
    }
  }
`;

export const getAccountCacheQuery = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      peaktimeRebate {
        hasActiveSA
        peakTimeRebateEnrollmentStatus
        hasRebates
      }
    }
  }
`;
