import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import useHistoricalUsage from '../../../hooks/useHistoricalUsage';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  useTheme,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { isMobile } from '../../../util/style-utils';
import Link from '../../text-link';
import PGEButton from '../../buttons';
import ROUTES from '../../../routes';
import { navigate } from 'gatsby';
import {
  HistoricalUsageContext,
  HistoricalUsageAuthType,
  HistoricalUsageContextActionType,
} from '../../../providers/HistoricalUsageProvider';

const useStyles = makeStyles((theme: Theme) => ({
  actionArea: {
    [theme.breakpoints.down('sm')]: {
      padingLeft: theme.spacing(4),
      padingRight: theme.spacing(4),
    },
  },
  formAction: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface HistoricalUsageConfirmProps {
  path?: string;
}

const HistoricalUsageConfirm: FunctionComponent<HistoricalUsageConfirmProps> = props => {
  const { content } = useHistoricalUsage();
  const { state, dispatch } = useContext(HistoricalUsageContext);
  const theme = useTheme();
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = () => {
    setIsSubmitting(true);
    dispatch({
      type: HistoricalUsageContextActionType.SUBMIT,
    });
    return navigate(ROUTES.HISTORICAL_USAGE_DONE);
  };

  useEffect(() => {
    isSubmitting && state.isSubmitted && navigate(ROUTES.ACCOUNT);
  }, [state]);

  return (
    <Grid container direction={'column'} spacing={4}>
      <Grid item>
        <Typography variant={'h2'}>
          {state.authType === HistoricalUsageAuthType.RELEASE
            ? content.confirmPage.release.title
            : content.confirmPage.revoke.title}
        </Typography>
      </Grid>
      <Grid item>
        <HistoricalUsageConfirmInfo />
      </Grid>
      <Grid
        item
        container
        style={
          isMobile()
            ? { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }
            : {}
        }
        direction={isMobile() ? 'column-reverse' : 'row'}
        justify={'space-between'}
        alignItems={isMobile() ? 'stretch' : 'center'}
        spacing={1}
      >
        <Grid item>
          <Grid container justify={'center'}>
            <Link plain to={ROUTES.ACCOUNT}>
              <Typography className={classes.formAction}>
                {content.cancel}
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction={isMobile() ? 'column-reverse' : 'row'}
            spacing={2}
          >
            <Grid item>
              <PGEButton
                variant={'outlined'}
                color={'secondary'}
                onClick={() => navigate(ROUTES.HISTORICAL_USAGE_CONFIG)}
                className={classes.formAction}
              >
                {content.back}
              </PGEButton>
            </Grid>
            <Grid item>
              <PGEButton
                variant={'contained'}
                color={'primary'}
                onClick={handleConfirm}
                className={classes.formAction}
              >
                {content.submit}
              </PGEButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const HistoricalUsageConfirmInfo: FunctionComponent = () => {
  const { t } = useTranslation();
  const { content } = useHistoricalUsage();
  const { state } = useContext(HistoricalUsageContext);
  const selectedBusinesses = state.businesses.filter(b => b.isSelected);
  return (
    <Grid container direction={'column'} spacing={2}>
      <Grid item>
        <Card>
          <CardContent>
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <Typography variant={'h4'}>
                  {content.confirmPage.summary}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant={'body1'}>
                  {content.confirmPage.summaryText}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant={'body1'}>
                  {state.authType === HistoricalUsageAuthType.RELEASE
                    ? content.confirmPage.release.groupsText
                    : content.confirmPage.revoke.groupsText}
                </Typography>
              </Grid>
              <Grid item>
                <Grid container direction={'column'} spacing={2}>
                  {selectedBusinesses.map((business, index) => {
                    return (
                      <Grid key={index} item>
                        <Typography variant={'h3'}>{business.name}</Typography>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant={'body1'}>
                  {state.authType === HistoricalUsageAuthType.RELEASE
                    ? content.confirmPage.release.noReleaseNote
                    : content.confirmPage.revoke.noRevokeNote}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant={'body1'}>
                  {content.confirmPage.selectServiceNote}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant={'body1'}>
                  {state.authType === HistoricalUsageAuthType.RELEASE
                    ? content.confirmPage.release.releaseNote
                    : content.confirmPage.revoke.revokeNote}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant={'body1'}>
                  {state.authType === HistoricalUsageAuthType.RELEASE
                    ? content.confirmPage.release.prohibitionNote
                    : content.confirmPage.revoke.prohibitionNote}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default HistoricalUsageConfirm;
