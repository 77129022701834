import gql from 'not-graphql-tag';
import { useMutation } from '@apollo/react-hooks';

const MOVE_SERVICE_SUBMIT_MUTATION = gql`
  mutation moveServiceSubmit($payload: MoveServiceRequest) {
    moveServiceSubmit(payload: $payload) {
      isSuccessful
    }
  }
`;

export default function useMoveServiceSubmit() {
  const [moveServiceSubmit] = useMutation(MOVE_SERVICE_SUBMIT_MUTATION);
  return moveServiceSubmit;
}
