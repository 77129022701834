import { Router } from '@reach/router';
import React from 'react';
import ROUTES from '../../routes';
import ServiceMovePage from './move';
import ServiceStopPage from './stop';
import PrivateRoute from '../../components/routing/PrivateRoute';
import NotFoundPage from '../../pages/404';

export const ServiceRouter = () => {
  return (
    <Router basepath="/">
      <ServiceStopPage path={`${ROUTES.STOP_SERVICE}/*`} />
      <ServiceMovePage path={`${ROUTES.MOVE_SERVICE}/*`} />
      <PrivateRoute path="/*" component={NotFoundPage} />
      {/* // TODO where is this route? */}
      {/* '/secure/service/account-groups', */}
    </Router>
  );
};
