import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

import colors from '../../themes/main-colors';

const useStyles = makeStyles(() =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
  }),
);

export type Headers = {
  title: string;
  value?: string;
};
interface FormHeaderSectionProps {
  headers: Headers[];
  style?: { [k: string]: string };
}

export default ({ headers, style = {} }: FormHeaderSectionProps) => {
  const classes = useStyles();

  return (
    <Paper style={{ boxShadow: 'none' }}>
      <CardContent>
        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...style,
          }}
        >
          {headers.map(({ title, value }, idx) => (
            <Grid item xs={12} md={4} key={idx.toString()}>
              <Grid item xs={12}>
                <Typography
                  className={classes.colorTextPrimary}
                  variant={idx < 1 ? 'subtitle2' : 'body2'}
                >
                  {title}
                </Typography>
              </Grid>
              {value && (
                <Grid item xs={12}>
                  <Typography
                    variant={'subtitle2'}
                    className={classes.colorTextPrimary}
                  >
                    {value}
                  </Typography>
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Paper>
  );
};
