import { Router } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import ReconnectService from '../../components/reconnect-service';
import ReconnectServiceLeftNav from '../../components/reconnect-service/reconnect-service-left-nav';
import Page2ColumnLayout from '../../components/utility/page2-column-layout';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
export const ReconnectServiceMain = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('RECONNECT_SERVICE')}</title>
      </Helmet>
      <Page2ColumnLayout leftColumn={<ReconnectServiceLeftNav />}>
        <Router basepath="/">
          <ReconnectService path={`${ROUTES.RECONNECT_SERVICE}/*`} />
        </Router>
      </Page2ColumnLayout>
    </>
  );
};
export default ReconnectServiceMain;
