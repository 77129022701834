import { Box, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { FullDivider } from '../../pge-plus-common/FullDivider';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
    },
  }),
);

export function ProgramCategory({ children }: { children?: React.ReactNode }) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {children}
      <FullDivider />
    </Box>
  );
}
