import React, { useContext, useEffect } from 'react';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
  Hidden,
} from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import PGEButton from '../../buttons/PGE-Button';
import { useIsMobile } from '../../../util/style-utils';
import colors from '../../../themes/main-colors';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import { AutoPayConfigData } from '../auto-pay.types';
import Alerts from '../../alerts';
import ROUTES from '../../../routes';
import { getAccountDisplayString } from '../../paymentus/utils';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getPaymentMethodLabel } from '../../../hooks/usePaymentus';
import { DownloadAndPrint } from '../../download-print/DownloadAndPrint';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  warnMessage: {
    color: `${theme.palette.error.main} !important`,
  },
  actionArea: {
    '& Button': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: theme.spacing(3),
      },
    },
  },
  greyedOut: {
    color: colors.lightGray1,
  },
  values: {
    '& h1': {
      [theme.breakpoints.up('md')]: {
        fontSize: '2rem',
        letterSpacing: '0.640px',
      },
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: '1.3rem',
        letterSpacing: '0.400px',
      },
    },
  },
}));

const useCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    border: {
      border: `1px solid ${theme.palette.success.main}`,
    },
    color: {
      color: theme.palette.success.main,
    },
  }),
);

type Props = {
  path?: string;
  confirmationNumber: string;
  config: AutoPayConfigData;
  email: string;
  isPendingDisconnect: boolean;
  onSubmit: () => void;
  hasPreferredDueDateError: boolean;
  preferredDueDate: number | null;
  billDueInfo: number | undefined;
  printContainerId: string;
};

const AlertMessage = (props: Props) => {
  const {
    confirmationNumber,
    config,
    hasPreferredDueDateError,
    preferredDueDate,
    email,
    isPendingDisconnect,
    billDueInfo,
  } = props;
  const { t, richT } = useTranslation();
  const classes = useStyles();

  const note = t('AUTO_PAY_DONE_NOTE');
  const warning = t('AUTO_PAY_DONE_WARNING');

  const paymentSelectorData = config.paymentSelector;
  const isMobile = useIsMobile();

  const paymentProfileNumber = getAccountDisplayString(
    paymentSelectorData.selectedProfileInfo?.profile,
    7,
  );

  const paymentProfileType =
    paymentSelectorData.selectedProfileInfo?.profile.type;
  const paymentMethod = getPaymentMethodLabel(paymentProfileType);
  const bankValue = (
    <Typography variant={'h1'}>{paymentProfileNumber}</Typography>
  );

  const pddValue = <Typography variant={'h1'}>{preferredDueDate}</Typography>;
  const confirmationValue = (
    <Typography variant={'h1'}>{confirmationNumber}</Typography>
  );

  return (
    <>
      <Grid item container spacing={2} justify={'space-around'}>
        <Grid item container spacing={2} justify={'space-around'}>
          <Grid item xs={12} md={4}>
            <Grid container direction={'column'}>
              <Grid item>
                <Typography variant={'h4'}>{paymentMethod}</Typography>
              </Grid>

              <Hidden mdUp>
                <Grid item>
                  <strong>{bankValue}</strong>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4}>
            <Grid
              container
              direction={'column'}
              className={
                hasPreferredDueDateError ? classes.greyedOut : undefined
              }
            >
              <Grid item>
                <Typography variant={'h4'}>
                  {isMobile
                    ? t('AUTO_PAY_DONE_AUTO_PAY_DAY')
                    : t('AUTO_PAY_DONE_DAY_NUMBER')}
                </Typography>
              </Grid>

              <Hidden mdUp>
                <Grid item>
                  <strong>{pddValue}</strong>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container direction={'column'}>
              <Grid item>
                <Typography variant={'h4'}>
                  {t('AUTO_PAY_DONE_CONFIRMATION_NUMBER')}
                </Typography>
              </Grid>

              <Hidden mdUp>
                <Grid item>
                  <strong>{confirmationValue}</strong>
                </Grid>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>

        <Hidden smDown>
          <Grid
            item
            container
            spacing={2}
            justify={'space-around'}
            className={classes.values}
          >
            <Grid item xs={12} md={4}>
              <strong>{bankValue}</strong>
            </Grid>

            <Grid item xs={12} md={4}>
              <strong>{pddValue}</strong>
            </Grid>

            <Grid item xs={12} md={4}>
              <strong>{confirmationValue}</strong>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
      <br />
      <Grid item>
        <Typography variant={'body1'}>
          {richT('AUTO_PAY_DONE_MESSAGE_V2', {
            PAYMENT_METHOD: paymentMethod,
          })}
        </Typography>
      </Grid>
      <br data-noprint />
      <Grid item>
        <Typography variant={'body1'}>
          {note}
          <strong> {email}</strong>.
        </Typography>
      </Grid>
      {isPendingDisconnect && (
        <Grid item>
          <Typography variant={'body1'} className={classes.warnMessage}>
            {warning}
          </Typography>
        </Grid>
      )}
      {billDueInfo !== undefined && (
        <Grid item>
          <Typography variant={'body1'} className={classes.warnMessage}>
            <strong>{t('AUTO_DEDUCTIONS_NEXT_BILL')}</strong>
          </Typography>
          <Typography variant={'body1'} className={classes.warnMessage}>
            {richT('TO_PAY_AMOUNT', {
              AMOUNT: String(billDueInfo),
              ONE_TIME_PAY_URL: ROUTES.PAYMENT,
            })}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default (props: Props) => {
  const { onSubmit, hasPreferredDueDateError, printContainerId } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const styles = useCardStyles();
  const title = t('AUTO_PAY_DONE_TITLE');

  const notificationContext = useContext(NotificationsContext);
  useEffect(() => {
    notificationContext.setState({
      isOpen: true,
      severity: 'success',
      variant: 'filled',
      message: t('AUTO_PAY_DONE_NOTIFICATION'),
    });
  }, []);

  return (
    <Paper component={'main'} className={styles.border}>
      <Box style={{ padding: '1em' }}>
        <Grid container spacing={4}>
          <Grid
            item
            container
            direction={'row'}
            spacing={2}
            justify="space-between"
          >
            <Grid item>
              <Grid
                container
                spacing={2}
                direction={'row'}
                alignItems={'center'}
              >
                <Grid item>
                  <Typography
                    variant={'h2'}
                    className={styles.color}
                    style={{ paddingTop: '0.3em' }}
                  >
                    <CheckCircleIcon fontSize="inherit" />
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant={'h2'}>{title}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <DownloadAndPrint
                containerId={printContainerId}
                excludeSelectors={['[data-noprint]']}
                minHeight={
                  (props.billDueInfo !== undefined &&
                    props.isPendingDisconnect) ||
                  (props.billDueInfo !== undefined &&
                    props.hasPreferredDueDateError)
                    ? 100
                    : undefined
                }
              ></DownloadAndPrint>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: '1.5em' }}>
            <Grid container item xs={12} spacing={4}>
              <Grid container item>
                {hasPreferredDueDateError && (
                  <Box marginBottom={2}>
                    <Alerts
                      disableColorOverride={true}
                      isOpen
                      message={t('AUTO_PAY_DATE_NOT_CHANGED', {
                        PHONE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
                      })}
                      severity="error"
                    />
                  </Box>
                )}
                <AlertMessage {...props} />
              </Grid>
            </Grid>
            <Grid item xs={12} data-noprint>
              <Grid
                container
                item
                xs={12}
                justify="flex-end"
                className={classes.actionArea}
              >
                <PGEButton onClick={onSubmit}>{t('DONE')}</PGEButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
