/** @jsx jsx */
import { jsx } from '@emotion/core';
import { navigate, useLocation } from '@reach/router';
import { Fragment, useEffect } from 'react';
import { ApplicationSectionStatus } from '../../../__generated__/pgeplus-types';
import { UpdateSectionEVRebatesMutationTypes } from '../../../hooks/pge-plus/useRebatesEligibility';
import useContentMgmt, {
  PageTextContentType,
} from '../../../hooks/useContentMgmt';
import { FormState } from '../../../hooks/useFormState.types';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import routes from '../../../routes';
import { GridItem } from '../../pge-plus-common/Grid';
import { SaveAndComeBackLink } from '../../pge-plus-common/SaveAndComeBackLink';
import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import { Question } from '../../pge-plus-common/Typography';
import { RebateEligibility } from '../evcharger.types';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';

type NoRebatesProps = {
  content: PageTextContentType;
  form: FormState<RebateEligibility>;
  path: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  handleSaveAndExit: () => void;
  updateSectionEVRebatesEligibility: UpdateSectionEVRebatesMutationTypes;
  updateFormValues: (values: RebateEligibility) => boolean;
};

export const NoRebates = (props: NoRebatesProps) => {
  const {
    content,
    handleSaveAndExit,
    setPageTitle,
    form,
    updateSectionEVRebatesEligibility,
    updateFormValues,
  } = props;
  const { richText } = useContentMgmt();
  const { wrapWithLoader } = useWrapWithLoader();
  const { state } = useLocation();
  // @ts-ignore
  const backRoute = state?.pathname;

  const {
    formState: {
      selectCharger: { suppliedByCustomer },
    },
  } = useEVChargerFormState();

  useEffect(() => {
    setPageTitle(content.get('REBATES_INELIGIBLE_SCREEN_TITLE') || '');
  }, []);

  return (
    <form
      onSubmit={wrapWithLoader(
        form.submit(async () => {
          updateFormValues(form.values);
          const { validationError } = await updateSectionEVRebatesEligibility(
            form.values,
            false,
            ApplicationSectionStatus.Completed,
          );
          if (!validationError) {
            void navigate(
              suppliedByCustomer
                ? routes.PGE_PLUS_EV_CHARGERS_INSTALLATION
                : routes.PGE_PLUS_EV_CHARGERS_SELECT_CHARGER,
            );
          }
        }),
      )}
      noValidate
    >
      <Fragment>
        <Question detail={richText(content.get('REBATES_INELIGIBLE_1'))} />
        <Question detail={richText(content.get('REBATES_INELIGIBLE_2'))} />
        <Question detail={richText(content.get('REBATES_INELIGIBLE_3'))} />
      </Fragment>
      <GridItem>
        <ProceedOrCancelButton
          back={
            backRoute ||
            routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_NEW_CHARGER
          }
        />
      </GridItem>
      <GridItem>
        <SaveAndComeBackLink
          content={content}
          onSaveAndExit={handleSaveAndExit}
        />
      </GridItem>
    </form>
  );
};
