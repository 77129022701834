import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';

import { ManageAccountHook } from '../../hooks/useManageAccounts';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';
import AccountGroupSelect from '../account-group-select';
import MultiAccounts from '../account-summary/multi-accounts/MultiAccounts';
import Button from '../buttons';
import FormHeaderSection from '../form-header-section';
import MaskedNameTextField from '../masked-name-text-field';
import TextLink from '../text-link';
import AddAccountHeader from './add-account-header';
import Modal from './Modal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    buttonRow: {
      marginTop: '2em',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
      },
    },
    buttonContainer: {
      display: 'flex',
      marginLeft: '4px',
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
        marginTop: '1em',
        marginBottom: '1em',
        width: '100%',
        justifyContent: 'center',
      },
    },
    button: {
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },
    },
  }),
);

interface ManageAccountsProps extends ManageAccountHook {
  path: string;
}

const ManageAccounts: FunctionComponent<ManageAccountsProps> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const childProps = props;
  const {
    state,
    formHeaders,
    multipleManageAccountHeaders,
    nicknameDialogFormProps,
  } = childProps;

  const modalCopy = () => {
    if (state?.accounts?.length > 1) {
      const selectedAccounts = childProps.state.accounts
        .filter(row => row.checked)
        .map(row => row.accountNumber)
        .toString();
      return `Are you sure you want to remove these [${selectedAccounts}] accounts from online access?`;
    }
    if (childProps.isSelectAll) {
      return 'You have selected all accounts. This will remove all accounts and group from your account list.';
    }
    return 'Are you sure you want to remove this account from online access?';
  };

  return (
    <>
      <Grid item xs={12} style={{ marginBottom: '1em' }}>
        <AddAccountHeader {...props} />
      </Grid>

      {state?.accounts?.length > 1 ? (
        <AccountGroupSelect
          elements={multipleManageAccountHeaders}
          onSelect={console.log}
        />
      ) : (
        <FormHeaderSection
          headers={formHeaders}
          style={{ marginBottom: '-1em' }}
        />
      )}

      <Grid item xs={12} style={{ marginTop: '1em' }}>
        <Paper>
          <Card>
            <CardContent style={{ margin: '1em' }}>
              <Grid item>
                <Typography variant={'h2'}>{t('Your accounts')}</Typography>
              </Grid>
              <Grid item style={{ marginTop: '1em' }}>
                <Typography variant={'body2'}>
                  {t('You have online access to the following PGE accounts:')}
                </Typography>
              </Grid>
              <MultiAccounts {...childProps} />
              <Grid
                item
                xs={12}
                style={{
                  margin: '1em',
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                }}
              >
                <Grid item xs={'auto'}>
                  <Typography variant={'body1'}>
                    {t(
                      'Selecting Remove only removes the account from your online access. ',
                    )}
                    <span style={{ color: colors.roseRed }}>
                      {t(
                        'Removing online access will not stop electric service.',
                      )}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12} className={classes.buttonRow}>
                <Grid item>
                  <TextLink plain to={ROUTES.ACCOUNT}>
                    {t('Back')}
                  </TextLink>
                </Grid>
                <Grid item className={classes.buttonContainer}>
                  <Button
                    onClick={() => childProps.toggleModal(true)}
                    disabled={childProps?.isPayButtonDisabled}
                    size={'large'}
                    className={classes.button}
                  >
                    {t('Remove')}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
      {props.showNicknameModal && (
        <Modal
          isOpen
          submitLabel={'Update'}
          submitButtonProps={{
            disabled:
              !nicknameDialogFormProps?.nickname?.value &&
              !nicknameDialogFormProps?.nicknameCheckBox?.value,
          }}
          onCancel={e => {
            e.preventDefault();
            e.stopPropagation();
            props.toggleNicknameModal(false);
          }}
          onClose={() => props.toggleNicknameModal(false)}
          onSubmit={event => {
            nicknameDialogFormProps.handleSubmit(event);
            props.toggleNicknameModal(false);
          }}
          title={'Nickname account'}
          titleProps={{
            variant: 'h2',
          }}
        >
          <Grid item xs={12}>
            <Grid item xs={12}>
              <MaskedNameTextField
                name={'nickname'}
                label={t('Nickname')}
                onChange={nicknameDialogFormProps.handleChange}
                onBlur={nicknameDialogFormProps.onBlur}
                style={{ width: '80%' }}
                error={!!nicknameDialogFormProps?.nickname?.errorMessage}
                helperText={nicknameDialogFormProps?.nickname?.errorMessage}
                defaultValue={nicknameDialogFormProps?.nickname?.value}
              />
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', flexDirection: 'row' }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name={'nicknameCheckBox'}
                    onChange={nicknameDialogFormProps.handleChange as any}
                    color={'primary'}
                  />
                }
                label={t('Remove nickname')}
              />
            </Grid>
          </Grid>
        </Modal>
      )}
      {childProps.showModal && (
        <Modal
          isOpen={true}
          onClose={() => props.toggleModal(false)}
          onCancel={e => {
            e.preventDefault();
            e.stopPropagation();
            childProps.toggleModal(false);
          }}
          onSubmit={childProps.handleRemove}
          title={'Remove account(s)'}
          message={''}
        >
          <Grid item xs={12}>
            <Grid item>
              <Typography>{modalCopy()}</Typography>
            </Grid>
            <Grid item style={{ marginTop: '2em' }}>
              <Typography>
                {'Removing online access will not stop electric service.'}
              </Typography>
            </Grid>
          </Grid>
        </Modal>
      )}
    </>
  );
};

export default ManageAccounts;
