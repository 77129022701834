import React, { FunctionComponent } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Dropdown from '../dropdown';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { useTranslation } from '../../hooks/useTranslation';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '100%',
    },
    gridDivider: {
      margin: theme.spacing(2, 0),
    },
  }),
);

interface PlanSelectorProps {
  editMode: boolean;
  accounts: any[];
  availablePlans?: any[];
}

const PlanSelector: FunctionComponent<PlanSelectorProps> = props => {
  const { accounts, editMode } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              {t('PLAN_SELECTOR_ACCOUNT_NUMBER')}
            </TableCell>
            <TableCell align="center">{t('PLAN_SELECTOR_SPID')}</TableCell>
            <TableCell align="center">
              {t('PLAN_SELECTOR_SERVICE_ADDRESS')}
            </TableCell>
            <TableCell align="center">
              {t('PLAN_SELECTOR_CURRENT_PLAN')}
            </TableCell>
            <TableCell align="center">{t('PLAN_SELECTOR_CHANGE_TO')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map(account => (
            <TableRow key={account.accountNumber}>
              <TableCell align="center" component="th" scope="row">
                {account.accountNumber}
              </TableCell>
              <TableCell>{account.spid}</TableCell>
              <TableCell>{account.serviceAddress}</TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                {account.plan}
              </TableCell>
              <TableCell style={{ whiteSpace: 'nowrap' }}>
                {editMode ? (
                  <Dropdown
                    selectionList={(props.availablePlans || []).map(plan => {
                      return {
                        value: plan.id,
                        label: (
                          <Typography variant="body2">{plan.name}</Typography>
                        ),
                      };
                    })}
                  />
                ) : (
                  account.changedTo
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PlanSelector;
