import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import React from 'react';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer } from 'recharts';
import colors from '../../../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '150px !important',
      background: colors.white,
    },
    chart: {
      width: 900,
      height: 900,
    },
  }),
);

export type TimeOfDayEventProps = {
  chartInfo: {
    name: string;
    value: number;
  }[];
};

const COLORS = [colors.noirBlurLight, colors.waikawaGray, colors.poloBlue];
export const TimeOfDayEvents = (props: TimeOfDayEventProps) => {
  const classes = useStyles();
  const { chartInfo: data } = props;
  return (
    <ResponsiveContainer className={classes.container}>
      <PieChart className={classes.chart}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          outerRadius={37}
          fill={colors.chetwodeBlue}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Legend
          iconSize={25}
          layout="vertical"
          align="right"
          verticalAlign="middle"
        />
      </PieChart>
    </ResponsiveContainer>
  );
};
