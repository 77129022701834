import useFormState from '../../../hooks/useFormState';
import validationRules from './renewableIndustrialForm.rules';
import { ApolloClient } from 'apollo-client';
import { useApolloClient } from '@apollo/react-hooks';
import {
  RenewablesEnrollmentToDoRequest,
} from '../../../__generated__/pge-types';
import {
  createRenewablesEnrollmentTodoMutation,
} from '../renewable-power.query';

export type Model = {
  contactName: string;
  emailAddress: string;
  phoneNumber: string;
};
const createRenewablesEnrollmentTodo = (
  formData: Model,
  encryptedAccountNumber: string,
  encryptedServiceAgreementId: string,
  apolloClient: ApolloClient<object>,
) => {
  const payload: RenewablesEnrollmentToDoRequest = {
    contactName: formData.contactName,
    primaryPhoneNumber: formData.phoneNumber,
    username: formData.emailAddress,
    encryptedAccountNumber: encryptedAccountNumber,
    encryptedServiceAgreementId: encryptedServiceAgreementId,
  };
  const result = apolloClient.mutate<{
    createRenewablesEnrollmentTodo: boolean;
  }>({
    mutation: createRenewablesEnrollmentTodoMutation,
    variables: { payload },
    errorPolicy: 'all',
  });
  return result;
};

const useRenewableIndustrialForm = (
  emailAddress = '',
  pnpPhone: string | undefined,
) => {
  const apolloClient = useApolloClient();
  const form = useFormState(
    {
      contactName: '',
      emailAddress: emailAddress,
      phoneNumber: pnpPhone ? pnpPhone.replace(/\D/g, '') : '',
    },
    {
      validate: validationRules,
    },
  );

  const onSubmit = (
    encryptedAccountNumber: string,
    encryptedServiceAgreementId: string,
  ) => {
    const formValues: Model = {
      contactName: form.values.contactName,
      phoneNumber: form.values.phoneNumber,
      emailAddress: form.values.emailAddress,
    };
    return createRenewablesEnrollmentTodo(
      formValues,
      encryptedAccountNumber,
      encryptedServiceAgreementId,
      apolloClient,
    );
  };
  return { form, onSubmit };
};

export default useRenewableIndustrialForm;
