import React, { FunctionComponent, useEffect } from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import Dropdown from '../dropdown';
import { useTranslation } from '../../hooks/useTranslation';
import { Divider, Grid, Typography, useMediaQuery } from '@material-ui/core';
import { EnrollmentAccount, PricingPlan } from '../../protected-pages/ess/electionWindow.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '100%',
    },
    gridDivider: {
      margin: theme.spacing(2, 0),
    },
    header: {
      fontSize: '14px',
    },
    dateCell: {
      fontSize: '13px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '12px',
      },
    },
  }),
);

interface PricingPlanSelectorProps {
  editMode: boolean;
  accounts: EnrollmentAccount[];
  availablePlans?: PricingPlan[];
  onPlanChange?: (value: string, index: number) => void;
}

const PricingPlanSelector: FunctionComponent<PricingPlanSelectorProps> = props => {
  const { accounts, editMode, availablePlans, onPlanChange } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={2}>
              <Typography variant="body2" className={classes.header}>
                {t('PLAN_SELECTOR_ACCOUNT_NUMBER')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="body2" className={classes.header}>
                {t('PLAN_SELECTOR_SPID')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="body2" className={classes.header}>
                {t('PLAN_SELECTOR_SERVICE_ADDRESS')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography variant="body2" className={classes.header}>
                {t('PLAN_SELECTOR_CURRENT_PLAN')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                variant="body2"
                align="center"
                className={classes.header}
              >
                {t('PLAN_SELECTOR_CHANGE_TO')}
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.gridDivider} />
          {accounts.map((account, index) => (
            <React.Fragment key={index}>
              <Grid container>
                {(account.acctId && isMobile) || !isMobile ? (
                  <Grid item xs={12} md={2} data-testid={'acctId-field'}>
                    {account.acctId}
                  </Grid>
                ) : null}
                {(account.spId && isMobile) || !isMobile ? (
                  <Grid item xs={12} md={2} data-testid={'spId-field'}>
                    {account.spId}
                  </Grid>
                ) : null}
                {(account.address && isMobile) || !isMobile ? (
                  <Grid item xs={12} md={2} data-testid={'address-field'}>
                    {account.address}
                  </Grid>
                ) : null}
                {(account.currentPlan && isMobile) || !isMobile ? (
                  <Grid item xs={12} md={2} data-testid={'currentPlan-field'}>
                    {account.currentPlan}
                  </Grid>
                ) : null}
                {editMode && availablePlans && onPlanChange ? (
                  <Grid item xs={12} md={4} data-testid={'changeTo-field'}>
                    <Dropdown
                      selectionList={availablePlans.map(plan => {
                        return {
                          value: plan.id,
                          label: (
                            <Typography variant="body2">{plan.name}</Typography>
                          ),
                        };
                      })}
                      value={account.planChangedTo}
                      onChange={e =>
                        onPlanChange(e.target.value as string, account.accountIndex)
                      }
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12} md={4} data-testid={'changeTo-field'}>
                    {
                      availablePlans?.find(p => p.id === account.planChangedTo)
                        ?.name
                    }
                  </Grid>
                )}
              </Grid>
              {index + 1 < (accounts || []).length && (
                <Divider className={classes.gridDivider} />
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default PricingPlanSelector;
