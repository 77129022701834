import React from 'react';
import { Paper, Typography } from '@material-ui/core';
import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';

export const PersonalInfoCard = () => {
  const { t, richT } = useTranslation();

  return (
    <Paper style={{ padding: 30, color: colors.noirBlur, marginBottom: 30 }}>
      <Typography variant="h2">{t('PERSONAL_INFORMATION')}</Typography>
      <Typography variant="body2" style={{ marginTop: 8, marginBottom: 16 }}>
        {richT('UPDATE_YOUR_OWN_PHONE_EMAIL')}
      </Typography>
    </Paper>
  );
};
