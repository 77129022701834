import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import {
  EditLink,
  DataLabel,
  getIdTypeLabel,
} from '../../start-service/StartServiceReviewHelpers';
import { useTranslation } from '../../../hooks/useTranslation';
import { BaseFormModel } from '../../../hooks/useFormState.types';
import colors from '../../../themes/main-colors';
import { displayPhoneNumberFormat } from '../../../util/format';
import ROUTES from '../../../routes';
import { PersonalIdentificationType } from '../../../__generated__/pge-types';

type CustomerInfoReviewProps = {
  serviceDetailsForm: BaseFormModel;
  customerInfoForm: BaseFormModel;
  useAltMailingAddress: boolean;
  goToStep: (step: number) => Promise<void>;
  primaryIdType: PersonalIdentificationType | undefined;
  accountDetails: any;
};

const MoveServiceCustomerReview = ({
  serviceDetailsForm,
  customerInfoForm,
  useAltMailingAddress,
  goToStep,
  primaryIdType,
  accountDetails,
}: CustomerInfoReviewProps) => {
  const { t } = useTranslation();

  let lastName = '';
  let firstMiddleName = '';

  if (accountDetails?.mainCustomerName) {
    [lastName, firstMiddleName] = accountDetails?.mainCustomerName?.split(', ');
  }

  return (
    <Grid
      container
      spacing={3}
      style={{ borderTop: `1px solid ${colors.lightGray1}`, marginTop: 30 }}
    >
      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2">{t('CUSTOMER_INFORMATION')}</Typography>
          <EditLink
            step={2}
            to={ROUTES.MOVE_SERVICE_CUSTOMER_INFO}
            goToStep={goToStep}
          />
        </Box>
      </Grid>
      <DataLabel
        text={t('CUSTOMER_NAME')}
        value={
          <Typography variant="body2">{`${firstMiddleName} ${lastName}`}</Typography>
        }
      />
      {(!primaryIdType ||
        primaryIdType === PersonalIdentificationType.None) && (
        <DataLabel
          text={getIdTypeLabel(
            customerInfoForm.values.idSubType || customerInfoForm.values.idType,
          )}
          value={`*****${customerInfoForm.values.idValue.substr(
            customerInfoForm.values.idValue.length - 4,
            customerInfoForm.values.idValue.length,
          )}`}
        />
      )}
      <DataLabel
        text={t('PREFERRED_PHONE')}
        value={displayPhoneNumberFormat(customerInfoForm.values.preferredPhone)}
      />
      <DataLabel
        text={t('ALTERNATE_PHONE')}
        value={
          <Typography variant="body2">
            {customerInfoForm.values.alternatePhone
              ? displayPhoneNumberFormat(customerInfoForm.values.alternatePhone)
              : t('NONE')}
            {customerInfoForm.values.alternatePhone
              ? ' - ' + customerInfoForm.values.altPhoneType.toLowerCase()
              : ''}
            {customerInfoForm.values?.altPhoneExt &&
            customerInfoForm.values.altPhoneType === t('WORK') ? (
              <>
                <br />
                {`${t('EXT')} ${customerInfoForm.values?.altPhoneExt}`}
              </>
            ) : null}
          </Typography>
        }
      />
      <DataLabel
        text={t('MAILING_ADDRESS')}
        value={
          !useAltMailingAddress ? (
            <Typography variant="body2">
              {`${serviceDetailsForm.values.address}, ${serviceDetailsForm.values.city}, OR ${serviceDetailsForm.values.zip}`}
            </Typography>
          ) : (
            <Typography variant="body2">
              {`${customerInfoForm.values.mailAddress}, ${customerInfoForm.values.mailCity}, ${customerInfoForm.values.mailState} ${customerInfoForm.values.mailZip}`}
            </Typography>
          )
        }
      />
    </Grid>
  );
};

export default MoveServiceCustomerReview;
