import { DocumentNode } from 'graphql';
import useAuthQuery from './useAuthQuery';
import {
  AccountDetail,
  AutoPayInfo,
  BillInfo,
  ViewBillDetails,
  ViewPaymentHistoryBillingAndPaymentType,
} from '../__generated__/pge-types';
import { getAccountDetailsForPaymentHistory } from '../components/account-summary/single-account/queries';
import useSelectedAccountParams from '../hooks/useSelectedAccountParams';
import { compact } from 'lodash';

export type AccountParamsType = {
  accountNumber: string | null | undefined;
};

export default function useViewBill(gql: DocumentNode) {
  const { encryptedAccountNumber, accountParams } = useSelectedAccountParams();

  const { loading, data } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetailsForPaymentHistory, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
      paymentHistoryParams: {
        filterParams: {
          types: [
            ViewPaymentHistoryBillingAndPaymentType.Payment,
            ViewPaymentHistoryBillingAndPaymentType.PendingPayment,
          ],
        },
        pagingParams: {
          limit: 3,
        },
        sortDirection: 'DESC',
      },
    },
    errorPolicy: 'all',
    skip: !encryptedAccountNumber,
  });

  const selectedAccount = data?.getAccountDetails?.[0];
  const latestPaymentHistory = compact(
    data?.getAccountDetails?.[0].paymentHistory?.paymentHistoryDetails,
  );

  const billDetails: ViewBillDetails | null | undefined =
    data?.getAccountDetails?.[0].billInfo?.billDetails;
  const isNewAccount: boolean | undefined | null =
    data?.getAccountDetails?.[0].billInfo?.isNewAccount;
  const averageTemperature =
    data?.getAccountDetails?.[0].viewBillAverageTemperature;
  const monthUsage = data?.getAccountDetails?.[0].viewBillMonthUsage;
  const billInfo: BillInfo | null | undefined =
    data?.getAccountDetails?.[0].billInfo;
  const autopay: AutoPayInfo | null | undefined =
    data?.getAccountDetails?.[0].autoPay;
  const isPendingDisconnect: boolean | undefined =
    data?.getAccountDetails?.[0].pendingDisconnect?.isPendingDisconnect;
  const paymentPlanType =
    data?.getAccountDetails?.[0]?.equalpay?.paymentPlanType;
  const pgeEnergyTrackerData = data?.getAccountDetails?.[0].pgeEnergyTracker;
  const isSummaryBillAccount =
    data?.getAccountDetails?.[0].isSummaryBillAccount;

  return {
    loading: loading || !selectedAccount,
    billDetails,
    averageTemperature,
    monthUsage,
    selectedAccount,
    accountParams,
    latestPaymentHistory,
    billInfo,
    autopay,
    isPendingDisconnect,
    paymentPlanType,
    pgeEnergyTrackerData,
    isSummaryBillAccount,
    isNewAccount,
  };
}
