import { Router } from '@reach/router';
import React from 'react';
import ROUTES from '../../routes';
import PrivateRoute from '../../components/routing/PrivateRoute';
import NotFoundPage from '../../pages/404';
import DirectAccessHistoricalUsage from './DirectAccessHistoricalUsage';
import ElectionWindow from './ElectionWindow';

export const EssRouter = () => {
  return (
    <Router basepath="/">
      <ElectionWindow path={`${ROUTES.ESS_ELECTION_WINDOW}/*`} />
      <DirectAccessHistoricalUsage
        path={`${ROUTES.ESS_DIRECT_ACCESS_HISTORICAL_USAGE_RELEASE}/*`}
      />
      <PrivateRoute path="/*" component={NotFoundPage} />
    </Router>
  );
};
