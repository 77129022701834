/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import { useState, FC, Fragment } from 'react';
import { Grid, Link, useTheme } from '@material-ui/core';
import { PageTextContentType } from '../../hooks/useContentMgmt';
import SaveAndComeBackModal from './SaveAndComeBackModal';

interface SelectChargerProps {
  content: PageTextContentType;
  onSaveAndExit: () => void;
}

export const SaveAndComeBackLink: FC<SelectChargerProps> = ({
  content,
  onSaveAndExit,
}: SelectChargerProps) => {
  const theme = useTheme();
  const saveText = content.get('SAVE_AND_COME_BACK_LATER');

  const [saveAndComeBackModalOpen, setSaveAndComeBackModalOpen] = useState(
    false,
  );

  const handleSaveAndComeBack = () => {
    setSaveAndComeBackModalOpen(true);
  };

  return (
    <Fragment>
      <Grid item css={{ textAlign: 'center', marginTop: 14 }}>
        <Link
          variant="body1"
          onClick={handleSaveAndComeBack}
          css={{
            fontSize: '20px',
            fontWeight: 700,
            borderBottom: '1px solid',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'none',
            },
            [theme.breakpoints.down('xs')]: {
              fontSize: 16,
            },
          }}
        >
          {saveText}
        </Link>
      </Grid>

      <SaveAndComeBackModal
        open={saveAndComeBackModalOpen}
        onClose={() => setSaveAndComeBackModalOpen(false)}
        onSaveAndExit={() => {
          setSaveAndComeBackModalOpen(false);
          onSaveAndExit();
        }}
      />
    </Fragment>
  );
};
