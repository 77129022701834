import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import withWidth from '@material-ui/core/withWidth';
import { Helmet } from 'react-helmet';

import { Grid, Typography } from '@material-ui/core';
import ProfileLayout from '../../../components/profile-layout';
import {
  UpdateInfoForm,
  UpdateInfoConfirmation,
} from '../../../components/update-info';
import { UpdateInfoProps } from '../../../hooks/useUpdateInfoForm';
import { OnlineAccountType } from '../../../__generated__/pge-types';

type Props = {
  path?: string;
};

const UpdateInfoPage = (_: Props) => {
  const { t } = useTranslation();
  const updateInfoProps = UpdateInfoProps();

  return (
    <ProfileLayout dense={false}>
      <Helmet>
        <title>{t('UPDATE_INFORMATION')}</title>
      </Helmet>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant={'h1'}>{t('UPDATE_INFORMATION')}</Typography>
        </Grid>
        {updateInfoProps.isComplete ? (
          <UpdateInfoConfirmation />
        ) : (
          <UpdateInfoForm {...updateInfoProps} />
        )}
      </Grid>
    </ProfileLayout>
  );
};

export default withWidth()(UpdateInfoPage);
