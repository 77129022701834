import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import { AddCircle } from '@material-ui/icons';
import { Paper } from '@material-ui/core';
import { range } from 'lodash';
import { NextBackOrCancel } from '../next-back-or-cancel';
import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import { ServiceOptionsFormModel } from './MoveServiceForm.rules';
import { PeakTimeRebates } from '../start-service/peak-time-rebates';
import { BaseFormModel } from '../../hooks/useFormState.types';
import ROUTES from '../../routes';

type MoveServiceOptionsFormProps = {
  form: BaseFormModel;
  isMobile: boolean;
  handleServiceOptionsSubmit: (
    values: ServiceOptionsFormModel,
  ) => Promise<void>;
  path: string;
  goToStep: (step: number) => Promise<void>;
  isPTREligible: boolean | undefined;
  isRenewablesEligible: boolean | undefined;
  customerEmail: string;
  customerPhone: string | undefined;
  hasCoCustomer: boolean;
  hasMainCustomer: boolean;
};

export const MoveServiceOptions = ({
  form,
  isMobile,
  handleServiceOptionsSubmit,
  goToStep,
  isPTREligible,
  isRenewablesEligible,
  customerEmail = '',
  customerPhone = '',
  hasCoCustomer,
  hasMainCustomer,
}: MoveServiceOptionsFormProps) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography
        variant="body1"
        style={{ marginBottom: 20, textAlign: 'left' }}
      >
        {t('CONSIDER_THESE_SERVICE_OPTIONS')}
      </Typography>

      <Paper style={{ padding: 30, color: colors.noirBlur }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ marginBottom: 3 }}
        >
          <Grid item>
            <AddCircle color="primary" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ color: colors.sparkBlue }}>
              {t('SETUP_YOUR_BILLING_OPTIONS')}
            </Typography>
          </Grid>
        </Grid>

        <div style={{ marginLeft: !isMobile ? 58 : 0 }}>
          <Typography variant="body1">
            {t('SIGN_UP_FOR_PAPERLESS_AND_SELECT_DUE_DATE')}
          </Typography>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid
              item
              xs={12}
              md={6}
              style={
                !isMobile
                  ? {
                      paddingRight: 30,
                      borderRight: `1px solid ${colors.lightGray1}`,
                    }
                  : {}
              }
            >
              <FormControlLabel
                id="paperless-bill"
                style={{ height: 50, marginTop: 10 }}
                control={
                  <Checkbox
                    name={'paperless'}
                    checked={Boolean(form.values.paperless)}
                    color={'primary'}
                    onChange={form.onChange}
                    value={Boolean(form.values.paperless)}
                  />
                }
                label={t('PAPERLESS_BILL')}
              />
              <Typography variant="body2" style={{ marginTop: 20 }}>
                {t('PAPERLESS_BILL_DESCRIPTION')}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={
                !isMobile
                  ? {
                      paddingLeft: 30,
                    }
                  : {}
              }
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <Typography variant="body1">
                    {t('MAKE_MY_BILL_DUE_ON')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl
                    variant="outlined"
                    error={Boolean(form.errors.billDueOn)}
                    style={{ width: 100, marginLeft: -20 }}
                  >
                    <InputLabel id="bill-due-on-label"></InputLabel>
                    <Select
                      labelId="bill-due-on-label"
                      name="billDueOn"
                      value={form.values.billDueOn}
                      onChange={form.onChange}
                      variant="outlined"
                      error={Boolean(form.errors.billDueOn)}
                      onBlur={form.onBlur}
                    >
                      <MenuItem value="0">{t('DAY')}</MenuItem>
                      {range(1, 31).map(v => (
                        <MenuItem key={v} value={v}>
                          {v}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{form.errors.billDueOn}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Typography variant="body2" style={{ marginTop: 20 }}>
                {t('BILL_DUE_ON_DESCRIPTION')}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Paper>

      {(isRenewablesEligible || isPTREligible) && (
        <Paper style={{ padding: 30, color: colors.noirBlur, marginTop: 25 }}>
          <Grid
            container
            spacing={2}
            alignItems="center"
            style={{ marginBottom: 3 }}
          >
            <Grid item>
              <AddCircle color="primary" fontSize="large" />
            </Grid>
            <Grid item>
              <Typography variant="body1" style={{ color: colors.sparkBlue }}>
                {t('ENROLL_IN_ENERGY_PROGRAMS')}
              </Typography>
            </Grid>
          </Grid>

          <div style={{ marginLeft: !isMobile ? 58 : 0 }}>
            <Typography variant="body1">
              {t('SAVE_MONEY_OR_GO_GREEN')}
            </Typography>
            {isPTREligible && (
              <PeakTimeRebates
                form={form}
                customerEmail={customerEmail}
                customerPhone={customerPhone}
              />
            )}
          </div>
        </Paper>
      )}

      <Paper style={{ padding: 30, color: colors.noirBlur, marginTop: 25 }}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          style={{ marginBottom: 3 }}
        >
          <Grid item>
            <AddCircle color="primary" fontSize="large" />
          </Grid>
          <Grid item>
            <Typography variant="body1" style={{ color: colors.sparkBlue }}>
              {t('CREATE_YOUR_ENERGY_PROFILE')}
            </Typography>
          </Grid>
        </Grid>

        <div style={{ marginLeft: !isMobile ? 58 : 0 }}>
          <Typography variant="body1">
            {t('GET_CUSTOM_RECOMMEDNATIONS_HOW_TO_SAVE')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                style={{ height: 50, marginTop: 10 }}
                control={
                  <Checkbox
                    name={'hasElectricVehicle'}
                    checked={Boolean(form.values.hasElectricVehicle)}
                    color={'primary'}
                    onChange={form.onChange}
                    value={Boolean(form.values.hasElectricVehicle)}
                  />
                }
                label={t('I_HAVE_AN_ELECTRIC_VEHICLE')}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <FormControlLabel
                style={{ height: 50, marginTop: 10 }}
                control={
                  <Checkbox
                    name={'hasSmartThermostat'}
                    checked={Boolean(form.values.hasSmartThermostat)}
                    color={'primary'}
                    onChange={form.onChange}
                    value={Boolean(form.values.hasSmartThermostat)}
                  />
                }
                label={t('I_HAVE_A_SMART_THERMOSTAT')}
              />
            </Grid>
          </Grid>
        </div>
      </Paper>

      <div style={{ margin: 25 }}>
        <NextBackOrCancel
          backHandler={async () => {
            if (hasCoCustomer) {
              await goToStep(2.4);
            } else if (hasMainCustomer) {
              await goToStep(2.2);
            } else {
              await goToStep(2);
            }
          }}
          cancelRoute={ROUTES.ACCOUNT}
          nextHandler={form.submit(handleServiceOptionsSubmit)}
        />
      </div>
    </>
  );
};
