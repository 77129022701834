import { SaDetail, SaStatus } from '../../../__generated__/pge-types';
import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';

export const useGetSAStatusInfo = (
  saDetails: SaDetail,
  isAccountActive: Boolean = true,
) => {
  const { t } = useTranslation();
  const statusInfo = {
    encryptedSaId: saDetails?.encryptedSAId,
    status: saDetails?.saStatus,
    statusLabel: '',
    statusHeaderText: '',
    statusDate: saDetails?.startDate,
    statusNickname: saDetails?.nickName || '',
    statusColor: '',
    showViewBill: false,
    showViewBillHistory: false,
    isClosedAccount: false,
  };

  switch (saDetails?.saStatus) {
    case SaStatus.Pendingstart:
      statusInfo.statusLabel = t('STATUS_PENDING_START');
      statusInfo.statusHeaderText = t('REQUESTED_FOR');
      statusInfo.statusColor = colors.shoreGreen;
      break;
    case SaStatus.Pendingstop:
      statusInfo.statusLabel = t('STATUS_PENDING_STOP');
      statusInfo.statusHeaderText = t('REQUESTED_FOR');
      statusInfo.statusColor = colors.orange;
      statusInfo.statusDate = saDetails?.endDate;
      statusInfo.showViewBill = true;
      statusInfo.showViewBillHistory = true;
      break;
    case SaStatus.Stopped:
      statusInfo.statusLabel = t('STATUS_SERVICE_STOPPED');
      statusInfo.statusHeaderText = t('EFFECTIVE');
      statusInfo.statusColor = colors.orange;
      statusInfo.statusDate = saDetails?.endDate;
      statusInfo.showViewBill = true;
      statusInfo.showViewBillHistory = true;
      break;
    case SaStatus.Closed:
    case SaStatus.Cancelled:
      statusInfo.statusLabel = t('STATUS_CLOSED');
      statusInfo.statusHeaderText = t('EFFECTIVE');
      statusInfo.statusColor = colors.darkGray2;
      statusInfo.statusDate = saDetails?.endDate;
      statusInfo.showViewBill = true;
      statusInfo.showViewBillHistory = true;
      statusInfo.isClosedAccount = true;
      break;
  }

  if (!isAccountActive) {
    statusInfo.statusLabel = t('STATUS_CLOSED');
    statusInfo.statusHeaderText = t('EFFECTIVE');
    statusInfo.statusColor = colors.darkGray2;
    statusInfo.statusDate = saDetails?.endDate;
    statusInfo.showViewBill = true;
    statusInfo.showViewBillHistory = true;
    statusInfo.isClosedAccount = true;
  }

  return statusInfo;
};
