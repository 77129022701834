import React from 'react';

//svg Icons
import FiveFeetScaleIcon from '../../pge-plus-common/static/fiveFeetOrLessScaleIcon.svg';
import SixToThirtyFeetScaleIcon from '../../pge-plus-common/static/sixToThirtyfeetScaleIcon.svg';
import ThirtyPlusScaleIcon from '../../pge-plus-common/static/thirtyPlusScaleIcon.svg';

import { PageTextContentType } from '../../../hooks/useContentMgmt';
import { PanelDistance } from '../../../__generated__/pgeplus-types';

export interface HomeTypeProps {
  textLabel: React.ReactNode;
  formValue: string;
  img: React.ReactNode;
}

type Props = {
  content: PageTextContentType;
};

export const distanceFromPanelTypes = ({ content }: Props): HomeTypeProps[] => [
  {
    textLabel: content.get('5_FEET_OR_LESS'),
    formValue: PanelDistance.Lt_5,
    img: <FiveFeetScaleIcon />,
  },
  {
    textLabel: content.get('6_TO_30_FEET'),
    formValue: PanelDistance.Btw_6_30,
    img: <SixToThirtyFeetScaleIcon />,
  },
  {
    textLabel: content.get('MORE_THAN_30_FEET'),
    formValue: PanelDistance.Gt_30,
    img: <ThirtyPlusScaleIcon />,
  },
];
