import React, { FunctionComponent } from 'react';
import { navigate } from '@reach/router';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ROUTES from '../../../routes';
import { useTranslation } from '../../../hooks/useTranslation';
import useStyles from '../styles';
import PGEButton from '../../buttons';
import Alerts from '../../alerts';
interface GiveBackIneligibleProps {
  path?: string;
}

const GiveBackIneligible: FunctionComponent<GiveBackIneligibleProps> = props => {
  const { t, richT } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={3} style={{ marginTop: '1em' }}>
      <Grid item>
        <Paper component={'main'} style={{ justifyContent: 'flex-start' }}>
          <Card>
            <CardContent>
              <Alerts
                disableColorOverride={true}
                isOpen
                severity={'error'}
                variant={'outlined'}
                title={t('PTR_GIVE_BACK_INELIGIBLE_HEADER')}
                message={
                  <>
                    <Typography variant={'h6'} component={'div'}>
                      {richT('PTR_GIVE_BACK_INELIGIBLE_MESSAGE', {
                        SMART_GRID_EMAIL: t('SMART_GRID_EMAIL'),
                      })}
                    </Typography>
                  </>
                }
              />
            </CardContent>
          </Card>
        </Paper>
      </Grid>
      <Grid item xs={12} className={classes.actionArea}>
        <PGEButton
          onClick={() => navigate(ROUTES.ACCOUNT)}
          className={classes.submitButton}
          variant="contained"
          color="primary"
        >
          {t('OK')}
        </PGEButton>
      </Grid>
    </Grid>
  );
};

export default GiveBackIneligible;
