import {
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  useReducer,
  useMemo,
  useEffect,
} from 'react';

import ROUTES from '../routes';
import { postAccountGroup } from '../queries/account.query';
import { Model as ManageGroupsAccountModel } from '../components/manage-accounts/manage-groups-account/ManageGroupsAccount.rules';
import useValidationForm from './useValidationForm';
import manageAccountGroupsValidationRules from '../components/manage-account-groups/ManageAccountGroups.rules';
import {
  useMutation,
  useLazyQuery,
  useApolloClient,
} from '@apollo/react-hooks';
import {
  Mutation,
  AccountGroupResponse,
  AccountGroupVerificationType,
  GroupStatus,
  ErrorReason,
} from '../__generated__/pge-types';
import { NotificationsContext } from '../providers/NotificationsProvider';
import { useTranslation } from './useTranslation';
import {
  updateAccountGroupsMutation,
  addRemoveAccountsToGroupMutation,
} from '../queries/manageAccountGroups.query';
import { getAccountsList } from '../components/account-summary/multi-accounts/queries';
import useAccountDetailList from './useAccountDetailList';
import useMulticheckState, { MulticheckState } from './useMulticheckState';
import AccountListReducer, {
  makeParams,
} from '../components/account-summary/multi-accounts/accountListReducer';
import useSelectedAccountParams, {
  AccountIdentifier,
} from '../hooks/useSelectedAccountParams';
import useAccountCustomer, {
  getAccountInfoWithParams,
} from './useAccountCustomer';
import { navigate } from '@reach/router';
import useWrapWithLoader from './useWrapWithLoading';
import useAccountMulticheckState from './useAccountMulticheckState';
import { FormState } from './useFormState.types';
import useAccountListHeader from '../components/account-summary/multi-accounts/useAccountListHeader';
import { VirtualAccountType } from '../constants/customEnum';

export interface ManageAccountGroupsHook {
  loading: boolean;
  state: any;
  isRemoveButtonDisabled: boolean;
  handleRemove: () => void;
  showRemoveAccountModal: boolean;
  toggleRemoveAccountModal: Dispatch<SetStateAction<boolean>>;
  showGroupNameModal: boolean;
  toggleGroupNameModal: Dispatch<SetStateAction<boolean>>;
  groupNameDialogFormProps: GroupNameDialogFormProps;
  sortFilterProps: any;
  multicheckState: MulticheckState;
  setSelectedAccountParams: (
    groupId: string,
    account: AccountIdentifier,
  ) => void;
}

export type DefaultFormValue = {
  value: string | number | boolean;
  errorMessage: string | null;
};

export interface GroupNameDialogFormProps {
  handleSubmit: () => void;
  handleCancel: () => void;
  handleChange: React.ChangeEvent;
  onBlur: React.MouseEvent;
  groupName: DefaultFormValue;
}

export default (selectedGroup: any = {}): ManageAccountGroupsHook => {
  const notificationContext = useContext(NotificationsContext);
  const { setSelectedAccountParams } = useSelectedAccountParams();

  const selectedGroupId = selectedGroup?.groupId;
  const apolloClient = useApolloClient();
  const { wrapWithLoader } = useWrapWithLoader();

  const {
    accountListParams,
    onAccountSearch,
    onAccountSort,
    onAccountPageChange,
  } = useAccountListHeader();

  const { loading, accounts, totalCount = 0 } = useAccountDetailList(
    getAccountsList,
    selectedGroupId,
    {
      accountListParams,
    },
  );

  const multicheckState = useAccountMulticheckState({
    query: accountListParams.query,
    groupId: selectedGroupId,
    allowInactive: true,
  });

  const [showGroupNameModal, toggleGroupNameModal] = useState(false);
  const [showRemoveAccountModal, toggleRemoveAccountModal] = useState(false);

  const { t } = useTranslation();

  const groupProps = useValidationForm(
    {
      groupName: { value: selectedGroup.groupName, errorMessage: '' },
      groupCode: { value: selectedGroup.groupCode, errorMessage: '' },
    },
    manageAccountGroupsValidationRules,
  );

  useEffect(() => {
    groupProps.setFormState({
      groupName: { value: selectedGroup.groupName, errorMessage: '' },
    });
  }, [selectedGroup?.groupId]);

  const [updateGroupName] = useMutation(updateAccountGroupsMutation, {
    variables: {
      payload: {
        groupId: selectedGroup.groupId,
        groupName: groupProps.groupName.value,
        isDefault: selectedGroup.isDefault,
      },
    },
    update(cache, { data: { updateGroup } }) {
      const updateGroupSuccess = updateGroup?.success || false;
      if (updateGroupSuccess) {
        const { getAccountInfo }: any = cache.readQuery({
          query: getAccountInfoWithParams,
          variables: {
            params: {
              removeInactiveAccounts: false,
            },
          },
        });
        getAccountInfo.groups &&
          getAccountInfo.groups.forEach((grp: any) => {
            if (selectedGroup.groupId === grp.groupId) {
              grp.groupName = groupProps.groupName.value;
            }
          });
        cache.writeQuery({
          query: getAccountInfoWithParams,
          variables: {
            params: {
              removeInactiveAccounts: false,
            },
          },
          data: {
            getAccountInfo: {
              ...getAccountInfo,
              groups: getAccountInfo.groups,
            },
          },
        });
      }
    },
  });

  const [getAccountListLazy] = useLazyQuery(getAccountsList, {
    variables: {
      params: {
        groupId: selectedGroup.groupId,
      },
    },
  });

  const [removeGroupAccount] = useMutation<Mutation>(
    addRemoveAccountsToGroupMutation,
    {
      onCompleted: () => {
        getAccountListLazy();
      },
      onError: () => {
        getAccountListLazy();
      },
    },
  );

  const isRemoveButtonDisabled = multicheckState.getSelected().length === 0;

  const onRemoveSuccess = (message: string): void => {
    notificationContext.setState({
      isOpen: true,
      severity: 'success',
      variant: 'filled',
      message: message,
    });
  };

  const handleRemove = wrapWithLoader(async () => {
    toggleRemoveAccountModal(false);
    const accountsToRemove = accounts.filter(
      (acct: any) =>
        multicheckState.getSelected().indexOf(acct.accountNumber) > -1,
    );

    const payloadAccounts = accountsToRemove.map(
      (acct: any) => acct.accountNumber,
    );

    try {
      const result = await removeGroupAccount({
        variables: {
          payload: {
            groupId: selectedGroup.groupId,
            accounts: payloadAccounts,
            action: 'REMOVE',
            groupName: groupProps.groupName.value,
          },
        },
      });

      const response = result?.data?.addRemoveAccountsToGroup;
      const success = response?.success || false;
      if (success) {
        const groupStatus = response?.groupStatus;

        const message = (): string => {
          if (accountsToRemove.length > 1) {
            return t('MULTIPLE_ACCOUNT_REMOVED_MESSAGE_SUCCESS', {
              NUMBER: String(accountsToRemove.length),
            });
          }
          return t('ACCOUNT_REMOVED_MESSAGE_SUCCESS');
        };

        // scroll to top of the page so you can see the notifications feedback
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0);
        }
        const groupDeleted = groupStatus === GroupStatus.Deleted;

        // If the group was deleted, we should go back to the
        // landing screen
        if (groupDeleted) {
          await navigate(ROUTES.MANAGE_ACCOUNTS_LANDING_SCREEN);
        }

        await apolloClient.resetStore();

        if (groupDeleted) {
          onRemoveSuccess(
            t('DELETE_GROUP', {
              NAME: selectedGroup.groupName,
              NUMBER: String(selectedGroup.numberOfAccounts),
            }),
          );
        } else {
          onRemoveSuccess(message());
        }
      } else {
        if (
          result?.data?.addRemoveAccountsToGroup?.errorReason ===
          ErrorReason.CannotRemoveDefaultAccount
        ) {
          handleErrors('DELETE_DEFAULT_ACCOUNT_ERROR');
        } else {
          handleErrors('REMOVE_GROUP_ACCOUNT_ERROR');
        }
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0);
        }
      }
    } catch (e) {
      toggleRemoveAccountModal(false);
      handleErrors('REMOVE_GROUP_ACCOUNT_ERROR');
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0);
      }
    }
  });

  const handleErrors = (message: string) => {
    notificationContext.setState({
      severity: 'error',
      isOpen: true,
      variant: 'filled',
      title: t(message, { PGE_PHONE: t('PGE_PHONE') }),
    });
  };

  const groupNameDialogFormProps = {
    ...groupProps,
    handleCancel: () => {
      groupProps.groupName.value = selectedGroup.groupName;
      groupProps.groupName.errorMessage = '';
    },
    handleSubmit: async () => {
      try {
        const result = await updateGroupName();
        const success = result?.data?.updateGroup?.success || false;
        if (success) {
          notificationContext.setState({
            severity: 'success',
            isOpen: true,
            variant: 'filled',
            title: t('GROUP_NAME_UPDATED'),
          });
        } else {
          return handleErrors('GROUP_NAME_UPDATE_ERROR');
        }
      } catch (e) {
        return handleErrors('GROUP_NAME_UPDATE_ERROR');
      }
    },
  };

  return {
    loading,
    state: {
      ...selectedGroup,
      accounts,
    },
    sortFilterProps: {
      totalCount,
      accountListParams,
      AccountListReducer,
      onAccountSearch,
      onAccountSort,
      onAccountPageChange,
    },
    multicheckState,
    groupNameDialogFormProps,
    isRemoveButtonDisabled,
    handleRemove,
    showGroupNameModal,
    toggleGroupNameModal,
    showRemoveAccountModal,
    toggleRemoveAccountModal,
    setSelectedAccountParams,
  };
};

export function useManageGroupFormProps() {
  const { wrapWithLoader } = useWrapWithLoader();
  const { groups, writeCustomerCache } = useAccountCustomer();
  const apolloClient = useApolloClient();
  const notificationContext = useContext(NotificationsContext);
  const { t } = useTranslation();

  return {
    handleSubmit: wrapWithLoader(
      async (
        formData: ManageGroupsAccountModel,
        form: FormState<ManageGroupsAccountModel>,
      ) => {
        const { data } = await apolloClient.mutate<{
          createAccountGroup: AccountGroupResponse;
        }>({
          mutation: postAccountGroup,
          variables: {
            payload: {
              accountNumber: formData.accountNumber,
              businessName: formData.businessName,
              verificationValue:
                formData.verificationType === 'ein'
                  ? formData.ein
                  : formData.phoneNumber,
              verificationType:
                formData.verificationType === 'ein'
                  ? AccountGroupVerificationType.EmployeeIdentificationNumber
                  : AccountGroupVerificationType.PrimaryNotificationPhone,
            },
          },
          refetchQueries: [
            {
              query: getAccountsList,
              variables: {
                params: makeParams(
                  VirtualAccountType.ALL_ACCTS,
                  AccountListReducer.initialState,
                ),
              },
            },
          ],
          awaitRefetchQueries: true,
        });

        const success = data?.createAccountGroup?.success;
        if (!success) {
          if (data?.createAccountGroup?.message?.endsWith('already exists')) {
            return notificationContext.setState({
              isOpen: true,
              severity: 'error',
              variant: 'filled',
              message: t('GROUP_ALREADY_EXISTS'),
            });
          } else if (data?.createAccountGroup?.message === 'AccountNotFound') {
            return form.setError(
              'accountNumber',
              t('ERROR_ADD_GROUP_ACCOUNT_NOT_FOUND'),
            );
          } else if (
            data?.createAccountGroup?.message === 'BusinessPersonNotFound'
          ) {
            return form.setError(
              form.values.verificationType,
              form.values.verificationType === 'phoneNumber'
                ? t('COMMERCIAL_PHONE_INVALID_BUSINESS_INFORMATION')
                : t('COMMERCIAL_EIN_INVALID_BUSINESS_INFORMATION'),
            );
          } else if (
            data?.createAccountGroup?.message ===
            'ParentPersonNotOfTypeBusiness'
          ) {
            await navigate(ROUTES.MANAGE_ACCOUNTS_INELIGIBLE);
            return true;
          } else {
            return Promise.reject('FAILED');
          }
        }

        const group = data?.createAccountGroup?.group!;
        if (group) {
          Object.keys(groups).map((_, index) => {
            if (groups[index].groupId === VirtualAccountType.ALL_ACCTS) {
              groups[index].numberOfAccounts =
                groups[index].numberOfAccounts! + group.numberOfAccounts!;
            }
          });
        }

        writeCustomerCache({
          groups: [group, ...groups],
        });

        await navigate(ROUTES.MANAGE_ACCOUNTS_LANDING_SCREEN, {
          state: {
            firstGroup: group.groupId,
          },
        });

        notificationContext.setState({
          isOpen: true,
          severity: 'success',
          variant: 'filled',
          message: t('GROUP_CREATED', {
            GROUP_NAME: group?.groupName,
            NUMBER_OF_ACCOUNTS: String(group?.numberOfAccounts),
          }),
        });
      },
    ),
  };
}
