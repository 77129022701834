import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';

import { Query } from '../../../__generated__/gatsby-types';
import MessagingBlock from '../../messaging-block';
import { LanguageContext } from '../../../providers/LanguageProvider';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      '& .MuiCardContent-root': {
        padding: 0,
      },
      '& section': {
        padding: theme.spacing(3),
        '& > div': {
          padding: 0,
          '& div.media.align-Left': {
            paddingRight: theme.spacing(2.5),
            [theme.breakpoints.down('sm')]: {
              padding: 0,
            },
          },
          '& div.media.align-Right': {
            paddingRight: 0,
            paddingLeft: theme.spacing(2.5),
            [theme.breakpoints.down('sm')]: {
              padding: 0,
            },
          },
        },
      },
    },
  });
});

const SecureSitePromoQuery = graphql`
  {
    allContentfulElementMessagingBlock(
      filter: {
        contentful_id: { eq: "4uclkYO9SzVtwKbkKFrXjE" }
      }
    ) {
      nodes {
        node_locale
        ...ModuleMessagingBlock
      }
    }
  }
`;

const SecureSitePromo = () => {
  const { language } = useContext(LanguageContext);
  const classes = useStyles();
  const { allContentfulElementMessagingBlock } = useStaticQuery<Query>(
    SecureSitePromoQuery,
  );

  const [msgBlock] = allContentfulElementMessagingBlock.nodes.filter(
    (node: any) => node.node_locale === language,
  );;
  return (
    <Paper className={classes.root}>
      <Card>
        <CardContent>
          <MessagingBlock {...(msgBlock as any)} />
        </CardContent>
      </Card>
    </Paper>
  );
};

export default SecureSitePromo;
