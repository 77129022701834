import {
  PersonalIdentificationType,
  StateIdType,
  FederalIdentificationType,
} from '../__generated__/pge-types';

export const getPersonalIdentificationType = (idType: any, idSubType: any) => {
  if (
    [PersonalIdentificationType.Ssn, PersonalIdentificationType.Itin].includes(
      idType,
    )
  ) {
    return idType;
  }

  if (idType === 'stateId') {
    if (idSubType === StateIdType.DriverLicense) {
      return PersonalIdentificationType.Dl;
    }

    if (idSubType === StateIdType.StateIdCard) {
      return PersonalIdentificationType.Dl; // this is intentional as we don't have a personalIdentificationType for StateIdCard
    }
  }

  if (idType === 'federalId') {
    if (idSubType === FederalIdentificationType.OregonTribalId) {
      return PersonalIdentificationType.Ortrib;
    }

    if (idSubType === FederalIdentificationType.UsMilitaryId) {
      return PersonalIdentificationType.Military;
    }

    if (idSubType === FederalIdentificationType.UsImmigration) {
      return PersonalIdentificationType.Resalien;
    }

    if (
      [
        FederalIdentificationType.UsStudentVisa,
        FederalIdentificationType.UsTemporaryVisa,
      ].includes(idSubType)
    ) {
      return PersonalIdentificationType.Visa;
    }

    if (idSubType === FederalIdentificationType.UsPassport) {
      return PersonalIdentificationType.Pssprt;
    }

    return null;
  }
};
