import React, { FC, useState } from 'react';
import {
  Typography,
  makeStyles,
  createStyles,
  useTheme,
  useMediaQuery,
  Button,
  Grid,
  Fab,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { useTranslation } from '../../../../hooks/useTranslation';
import colors from '../../../../themes/main-colors';
import InfoIcon from '../../../../static/InfoIcon.svg';
import Tooltip from '../../../tooltip/Tooltip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'row',
        justifyContent: 'start',
        border: `1px solid ${colors.raven}`,
        backgroundColor: colors.whiteSmoke,

        position: 'relative',
        padding: theme.spacing(0.62, 1, 0.62, 3),
        margin: theme.spacing(6, 0, 0, 0),
        borderRadius: 5,
        '&:first-child': {
          margin: theme.spacing(0, 0, 0, 0),
        },
      },
    },
    miWrapper: {
      [theme.breakpoints.down('sm')]: {
        backgroundColor: colors.aliceBlue,
        borderColor: colors.sparkBlue,
        borderBottom: 0,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
      },
    },
    tpaImageWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'start',
      },
    },
    tpaImage: {
      width: '100%',
      height: 97,
      [theme.breakpoints.down('sm')]: {
        width: 49,
        height: 52,
      },
    },
    tpaTabControl: {
      width: '100%',
      height: 48,
      backgroundColor: colors.lightGray2,
      border: `1px solid ${colors.charcoal}`,
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '5px',
      '& .tpaTooltip': {
        position: 'absolute',
        top: 5,
        right: 5,
        [theme.breakpoints.down('md')]: {
          height: 16,
          width: 16,
          right: 8,
        },
      },
      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'transparent',
        border: 0,
        position: 'static',
      },
    },

    tpaTabControlForMI: {
      backgroundColor: colors.aliceBlue,
      border: `1px solid ${colors.sparkBlue}`,
      color: colors.navyBlue,

      '& h2': {
        fontWeight: 700,
      },
      [theme.breakpoints.down('sm')]: {
        border: 0,
      },
    },
    tpaImageTitle: {
      width: '100%',
    },
    tpaCardText: {
      textAlign: 'center',
      padding: theme.spacing(3, 0, 2, 0),
      [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(0, 0, -1.5, 0),
        padding: theme.spacing(0, 0, 0, 0),
      },
    },

    monthlyInstallmentContainer: {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: 22,
        background: colors.aliceBlue,
        borderRight: `1px solid ${colors.sparkBlue}`,
        borderLeft: `1px solid ${colors.sparkBlue}`,
        bottom: -17,
        left: 0,
        [theme.breakpoints.down('md')]: {
          bottom: -17,
        },
      },
    },

    fontStyle: {
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.75rem',
        fontWeight: 800,
      },
    },
    miFontStyle: {
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.75rem',
        fontWeight: 800,
      },
    },
    miTitle: {
      marginBottom: '5px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.87rem',
        fontWeight: 800,
      },
    },
    accTitle: {
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: '1.25rem',
      },
    },
  }),
);

const useStylesForToolTip = makeStyles((theme: Theme) => ({
  tooltip: {
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    border: '3px solid #F3AD3C',
    borderRadius: '5px',
    opacity: 1,
    [theme.breakpoints.down('sm')]: {
      left: '-27px',
    },
  },
  arrow: {
    color: '#F3AD3C',
    [theme.breakpoints.down('sm')]: {
      left: '190px !important',
    },
  },
}));

interface IconProps {
  definedClass: string;
}

interface Props {
  SvgIcon: React.FC<IconProps>;
  title: string;
  amount: string;
  tooltipContent: string;
  isMonthlyInstallment?: Boolean;
}

const TPAIconInfoBox: FC<Props> = (props: Props) => {
  const {
    SvgIcon,
    title,
    amount,
    tooltipContent,
    isMonthlyInstallment,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();
  const classesForToolTip = useStylesForToolTip();
  const theme = useTheme();

  const [isOpen, setTooltipIsOpen] = useState(false);
  const onCloseTooltip = () => {
    setTooltipIsOpen(false);
  };
  const onOpenTooltip = () => {
    setTooltipIsOpen(true);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const buttonClass =
    !isMobile && isMonthlyInstallment
      ? classes.monthlyInstallmentContainer
      : '';
  return (
    <>
      <Grid
        container
        justify="center"
        alignItems="center"
        xs={12}
        md={4}
        className={`${classes.wrapper} ${
          isMonthlyInstallment ? classes.miWrapper : ''
        }`}
        item
      >
        <Grid item xs={4} md={12} className={classes.tpaImageWrapper}>
          <SvgIcon definedClass={classes.tpaImage} />
        </Grid>
        <Grid item xs={4} md={12} className={classes.tpaImageTitle}>
          <Grid style={{ flexDirection: 'column' }}>
            <Grid className={classes.tpaCardText}>
              <Typography
                className={`${
                  isMonthlyInstallment ? classes.miTitle : classes.accTitle
                }`}
                variant={'h5'}
                data-testid={'tpa-card-title'}
              >
                {title}
              </Typography>
            </Grid>

            <Grid className={buttonClass}>
              <Grid
                className={`${classes.tpaTabControl} ${
                  isMonthlyInstallment ? classes.tpaTabControlForMI : ''
                }`}
              >
                <Typography
                  className={`${
                    isMonthlyInstallment
                      ? classes.miFontStyle
                      : classes.fontStyle
                  }`}
                  variant={'h2'}
                  data-testid={'tpa-card-amount'}
                >
                  {amount}
                </Typography>
                {isMobile ? (
                  <ClickAwayListener onClickAway={onCloseTooltip}>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      arrow
                      onOpen={() => setTooltipIsOpen(true)}
                      onClose={onCloseTooltip}
                      open={isOpen}
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      classes={classesForToolTip}
                      placement={'bottom'}
                      title={
                        <div
                          style={{
                            display: 'flex',
                            textAlign: 'left',
                            fontSize: theme.typography.pxToRem(14),
                            letterSpacing: '0px',
                            color: colors.noirBlurLight,
                            opacity: 1,
                          }}
                        >
                          <div>{tooltipContent}</div>
                          <div>
                            <CloseIcon onClick={onCloseTooltip} />
                          </div>
                        </div>
                      }
                    >
                      <div className={'tpaTooltip'} onClick={onOpenTooltip}>
                        <InfoIcon />
                      </div>
                    </Tooltip>
                  </ClickAwayListener>
                ) : (
                  <Tooltip
                    arrow
                    title={
                      <div
                        style={{
                          display: 'flex',
                          textAlign: 'left',
                          fontSize: theme.typography.pxToRem(16),
                          letterSpacing: '0px',
                          color: colors.noirBlurLight,
                          opacity: 1,
                          padding: '8px 5px',
                        }}
                      >
                        <div>{tooltipContent}</div>
                      </div>
                    }
                    classes={classesForToolTip}
                    placement={'bottom'}
                  >
                    <div className={'tpaTooltip'}>
                      <InfoIcon />
                    </div>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default TPAIconInfoBox;
