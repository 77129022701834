import React, { FC } from 'react';
import { Typography, Box } from '@material-ui/core';

import AccountDropdown from '../account-dropdown';
import Backdrop from '../backdrop';
import { useTranslation } from '../../hooks/useTranslation';
import usePeakTimeRebates from './usePeakTimeRebates';
import Ineligible from './ineligibile';
import PeakTimeRebatesEnroll from './enroll';
import PeakTimeRebatesManageEnrollment from './manage';
import usePeakTimeRebatesGivingBack from '../peak-time-rebates-giving-back/usePeakTimeRebatesGivingBack';

interface PeakTimeRebatesProps {
  path?: string;
}

const PeakTimeRebates: FC<PeakTimeRebatesProps> = props => {
  const { t } = useTranslation();
  const {
    loading,
    isEnrolled,
    isEligible,
    showConfirm,
    setShowConfirm,
    transactionType,
    setTransactionType,
    form,
    showNotificationPreferenceRequiredError,
    handleOnChangeEnrollOrCancel,
    handleOnClickManageNext,
    handleOnFormSubmit,
    isSubmitSuccessful,
  } = usePeakTimeRebates();

  const {
    isEligible: isPeakTimeGivingBackEligible,
    loading: peakTimeRebateGivingBackLoading,
  } = usePeakTimeRebatesGivingBack();

  return (
    <>
      <Typography variant={'h1'} noWrap>
        {t('PTR_TITLE_CASE')}
      </Typography>
      <Box mt={2} mb={1}>
        <AccountDropdown />
      </Box>
      {loading && <Backdrop forceOpen />}
      {!loading && !isEligible && !isEnrolled && <Ineligible />}
      {!loading && !isEnrolled && isEligible && (
        <PeakTimeRebatesEnroll
          setTransactionType={setTransactionType}
          form={form}
          isSubmitSuccessful={isSubmitSuccessful}
          showNotificationPreferenceRequiredError={
            showNotificationPreferenceRequiredError
          }
          handleOnFormSubmit={handleOnFormSubmit}
        />
      )}
      {!loading && !peakTimeRebateGivingBackLoading && isEnrolled && (
        <PeakTimeRebatesManageEnrollment
          setTransactionType={setTransactionType}
          transactionType={transactionType}
          handleOnChangeEnrollOrCancel={handleOnChangeEnrollOrCancel}
          form={form}
          showNotificationPreferenceRequiredError={
            showNotificationPreferenceRequiredError
          }
          handleOnClickManageNext={handleOnClickManageNext}
          showConfirm={showConfirm}
          setShowConfirm={setShowConfirm}
          handleOnFormSubmit={handleOnFormSubmit}
          isSubmitSuccessful={isSubmitSuccessful}
          isPeakTimeGivingBackEligible={isPeakTimeGivingBackEligible}
        />
      )}
    </>
  );
};

export default PeakTimeRebates;
