/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box } from '@material-ui/core';
import { Router } from '@reach/router';
import { navigate } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { useRebateEligibility } from '../../../hooks/pge-plus/useRebatesEligibility';
import useContentMgmt from '../../../hooks/useContentMgmt';
import useFormState from '../../../hooks/useFormState';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';
import routes from '../../../routes';
import { GridContainer } from '../../pge-plus-common/Grid';
import { RebateEligibility as RebateEligibilityTypes } from '../evcharger.types';
import { createRebateEligibilityValidateFunction } from '../../pge-plus-common/PGEPlusForm.rules';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { AllRebatesClaimed } from './AllRebatesClaimed';
import { RebatesIneligible } from './RebatesIneligible';
import { EVPulseOrExistingSmartCharger } from './EVPulseOrExistingSmartCharger';
import { IQDBOrNewSmartCharger } from './IQDBOrNewSmartCharger';
import { RebateAmountConfirmation } from './RebateAmountConfirmation';
import { ImageUpload } from './ImageUpload';
import { NoRebates } from './NoRebates';

interface RebateEligibilityProps {
  children?: React.ReactNode;
  path: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
}

/***
   The context of the current screen is needed for
   writing form validations logic based on the current screen
***/
export enum CurrentRebatesView {
  NEW_CHARGER,
  EXISTING_CHARGER,
  IMAGE_UPLOAD,
}

export enum ExistingChargerType {
  EV_PULSE,
  SMART_CHARGER,
}

export const RebateEligibility: FC<RebateEligibilityProps> = props => {
  const { setPageTitle } = props;
  const { wrapWithLoader } = useWrapWithLoader();
  const { content, isContentLoading } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ELIGIBILITY,
  );
  const [currentView, setCurrentView] = useState<CurrentRebatesView>();

  const {
    formState,
    formState: { rebateEligibility },
    updateFormState,
  } = useEVChargerFormState();

  const updateFormValues = (values: RebateEligibilityTypes) =>
    updateFormState('rebateEligibility', values);

  const {
    checkRebateEligibility,
    updateSectionEVRebatesEligibility,
  } = useRebateEligibility();

  const form = useFormState(rebateEligibility, {
    validate: createRebateEligibilityValidateFunction(),
    validationContext: {
      content,
      currentView,
      checkRebateEligibility,
    },
  });

  const handleSaveAndExit = wrapWithLoader(async () => {
    await updateSectionEVRebatesEligibility(form.values, true);
    void navigate(routes.ACCOUNT);
  });

  useEffect(() => {
    if (
      checkRebateEligibility.isEligibleForIQDB ||
      checkRebateEligibility.eligibleForSmartChargingRebate
    ) {
      void navigate(routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_NEW_CHARGER);
    }
    if (checkRebateEligibility.vin || checkRebateEligibility.serialNumber) {
      void navigate(
        routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_CHARGER_REBATE,
      );
    }
    if (checkRebateEligibility.hasBothRebatesClaimed) {
      void navigate(routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_ALL_CLAIMED);
    }
  }, []);

  if (isContentLoading) {
    return null;
  }

  return (
    <Box mt={4} css={{ maxWidth: '790px', margin: 'auto', marginTop: -20 }}>
      <GridContainer>
        <Router basepath="/">
          <IQDBOrNewSmartCharger
            content={content}
            form={form}
            path={routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_NEW_CHARGER}
            setPageTitle={setPageTitle}
            handleSaveAndExit={handleSaveAndExit}
            setCurrentView={setCurrentView}
            iqdb={checkRebateEligibility.isEligibleForIQDB}
            evPulse={Boolean(checkRebateEligibility.vin)}
            smartCharger={Boolean(checkRebateEligibility.serialNumber)}
            updateSectionEVRebatesEligibility={
              updateSectionEVRebatesEligibility
            }
            updateFormValues={updateFormValues}
          />
          <EVPulseOrExistingSmartCharger
            content={content}
            form={form}
            path={routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_CHARGER_REBATE}
            setPageTitle={setPageTitle}
            handleSaveAndExit={handleSaveAndExit}
            setCurrentView={setCurrentView}
            type={
              (checkRebateEligibility.vin && ExistingChargerType.EV_PULSE) ||
              (checkRebateEligibility.serialNumber &&
                ExistingChargerType.SMART_CHARGER) ||
              ExistingChargerType.EV_PULSE
            }
            identifier={
              checkRebateEligibility.vin ||
              checkRebateEligibility.serialNumber ||
              ''
            }
            updateFormValues={updateFormValues}
          />
          <ImageUpload
            content={content}
            form={form}
            path={
              routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_CHARGER_REBATE_VIN
            }
            setPageTitle={setPageTitle}
            handleSaveAndExit={handleSaveAndExit}
            setCurrentView={setCurrentView}
            updateSectionEVRebatesEligibility={
              updateSectionEVRebatesEligibility
            }
            updateFormValues={updateFormValues}
          />
          <AllRebatesClaimed
            content={content}
            form={form}
            path={routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_ALL_CLAIMED}
            setPageTitle={setPageTitle}
            updateSectionEVRebatesEligibility={
              updateSectionEVRebatesEligibility
            }
            updateFormValues={updateFormValues}
          />
          <RebatesIneligible
            content={content}
            form={form}
            path={routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_INELIGIBLE}
            setPageTitle={setPageTitle}
            handleSaveAndExit={handleSaveAndExit}
            updateSectionEVRebatesEligibility={
              updateSectionEVRebatesEligibility
            }
            updateFormValues={updateFormValues}
          />
          <NoRebates
            content={content}
            form={form}
            path={routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_NOREBATES}
            setPageTitle={setPageTitle}
            handleSaveAndExit={handleSaveAndExit}
            updateSectionEVRebatesEligibility={
              updateSectionEVRebatesEligibility
            }
            updateFormValues={updateFormValues}
          />
          <RebateAmountConfirmation
            content={content}
            form={form}
            path={routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_CONFIRMATION}
            setPageTitle={setPageTitle}
            handleSaveAndExit={handleSaveAndExit}
            updateSectionEVRebatesEligibility={
              updateSectionEVRebatesEligibility
            }
            updateFormValues={updateFormValues}
          />
        </Router>
      </GridContainer>
    </Box>
  );
};
