/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { Fragment, useEffect } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import {
  Box,
  Divider,
  FormHelperText,
  Typography,
  Grid,
  useTheme,
} from '@material-ui/core';

import { GridItem } from '../../../../pge-plus-common/Grid';
import { DropdownMenu } from '../../../../pge-plus-common/DropdownMenu';
import CurrencyTextField from '../../../../currency-text-field/CurrencyTextField';
import { Question } from '../../../../pge-plus-common/Typography';
import RadioButtonGroup from '../../../../../components/pge-plus-common/RadioButtonGroup';
import { ProceedOrCancelButton } from '../../../../pge-plus-common/StepperForm';
import ROUTES from '../../../../../routes';
import { CheckBoxWithPaper } from '../../../../pge-plus-common/CheckBoxWithPaper';
import PopoverToolTip from '../../../../pge-plus-common/PopoverToolTip';
import { useRebateOnlyFormState } from '../../../../../providers/RebateOnlyFormStateProvider';
import useFormState from '../../../../../hooks/useFormState';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import { higherRabatesValidateFunction } from '../rebateOnlyForm.rules';
import { RebateConfirmIQBD } from '../rebateOnlyForm.types';
import {
  SectionErRebateConfirm,
  ApplicationErSectionType,
  RebateSmartCharging,
  EvRebateEnrollments,
  SectionErRebateConfirmData,
} from '../../../../../__generated__/pgeplus-types';
import useUpdateERSections from '../../../../../hooks/pge-plus/useUpdateERSections';
import { rebateConfirmERFormStateAdapter } from '../rebateOnlyFormState.adapter';
import useUtil from '../../../../../hooks/pge-plus/useUtil';
import useWrapWithLoader from '../../../../../hooks/useWrapWithLoading';
import { SaveAndComeBackLink } from '../../../../pge-plus-common/SaveAndComeBackLink';
import { Link } from 'gatsby';
import { sectionFragmentER } from '../../../../pge-plus-common/queries/fragments';
import gql from 'not-graphql-tag';
import { useGetApplicationSectionERQuery } from '../../../../../hooks/pge-plus/useGetApplicationSectionER';
import Backdrop from '../../../../backdrop/Backdrop';

export const getApplicationSectionQuery = gql`
  query($input: GetApplicationSectionInput!) {
    getApplicationSection(input: $input) {
      ...sectionFragment
    }
  }
  ${sectionFragmentER}
`;

const CheckHigherRebatesEligibility: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { content, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_REBATE_CONFIRM,
  );

  const {
    formState: { rebateConfirm, application },
    getSectionData,
    updateFormState,
  } = useRebateOnlyFormState();

  const { updateSectionRebateConfirm } = useUpdateERSections();
  const { setErrorNotification } = useUtil();
  const theme = useTheme();
  const { wrapWithLoader } = useWrapWithLoader();

  const sectionDataRebateConfirm: SectionErRebateConfirm = getSectionData(
    ApplicationErSectionType.ErRebateConfirm,
  );

  const { sectionData, loading } = useGetApplicationSectionERQuery(
    application?.id!,
    sectionDataRebateConfirm?.id,
  );

  const form = useFormState(rebateConfirm.iqbd || {}, {
    validate: higherRabatesValidateFunction(),
    validationContext: {
      content,
    },
  });

  const householdSizeOptions = Array.from([...Array(10).keys()]).map(num => ({
    label: `${num + 1}`,
    value: `${num + 1}`,
  }));

  useEffect(() => {
    if (sectionData && !loading) {
      const sectionDataRebateConfirmData = sectionData?.getApplicationSection
        ?.data as SectionErRebateConfirmData;

      const updatedValues = rebateConfirmERFormStateAdapter().toFormState(
        sectionDataRebateConfirmData,
      );
      updateFormState('rebateConfirm', {
        ...updatedValues,
      });

      //run the system check if isAbleToRequestIQBD is false and redirect to appropriate page
      if (
        !sectionDataRebateConfirmData?.rebateInfo?.availability
          ?.isAbleToRequestIQBD
      ) {
        handleRebateEligiblityCheckAndRedirect(
          sectionDataRebateConfirmData?.preRebateEligibility1
            ?.evRebate as RebateSmartCharging,
          sectionDataRebateConfirmData?.rebateInfo?.existingEnrollments,
          false,
        );
      }
    }
  }, [sectionData, loading]);

  const handleSubmit = wrapWithLoader(async (data: RebateConfirmIQBD) => {
    try {
      if (!data.checkEligibility) {
        // Removing previously saved value when the user clicked Yes for checkEligibility
        delete data.grossIncome;
        delete data.grossIncomeConfirmed;
        delete data.houseHoldSize;
      }

      const response = await updateSectionRebateConfirm(
        rebateConfirmERFormStateAdapter().fromFormState(
          { ...rebateConfirm, iqbd: data },
          application?.id!,
          sectionDataRebateConfirm?.id,
        ),
      );

      if (!response.data?.updateSectionERRebateConfirm.section.errors?.length) {
        //Store the data in global store
        if (response.data?.updateSectionERRebateConfirm?.section.data) {
          const updatedValues = rebateConfirmERFormStateAdapter().toFormState(
            response.data?.updateSectionERRebateConfirm?.section.data,
          );
          updateFormState('rebateConfirm', {
            ...updatedValues,
          });
        }

        void handleRebateEligiblityCheckAndRedirect(
          response.data?.updateSectionERRebateConfirm.section.data
            .preRebateEligibility1?.evRebate as RebateSmartCharging,
          response.data?.updateSectionERRebateConfirm.section.data.rebateInfo
            .existingEnrollments!,
        );
      } else {
        throw new Error();
      }
    } catch (error) {
      console.log('error', error);
      setErrorNotification(true);
    }
  });

  const handleRebateEligiblityCheckAndRedirect = (
    evRebate: RebateSmartCharging,
    existingEnrollments: EvRebateEnrollments,
    isAbleToRequestIQBD = true,
  ) => {
    try {
      const rebateSmartCharging = evRebate;

      const state: any = {
        chargerAmount: rebateSmartCharging?.charger,
        panelUpgradeAmount: rebateSmartCharging?.panelUpgrade,
        isAbleToRequestIQBD,
      };

      //Navigation logic
      if (existingEnrollments?.iqbd && rebateSmartCharging?.isHigherDiscount) {
        state['rebateType'] = 'iqbd';
      } else if (
        !existingEnrollments?.iqbd &&
        !rebateSmartCharging?.isHigherDiscount
      ) {
        state['rebateType'] = 'standard';
      } else if (
        !existingEnrollments?.iqbd &&
        rebateSmartCharging?.isHigherDiscount
      ) {
        state['rebateType'] = 'higher';
      }
      if (state.rebateType) {
        void navigate(
          state.rebateType === 'standard'
            ? ROUTES.PGE_PLUS_REBATE_ONLY_STANDARD_REBATES_ELIGIBILE
            : ROUTES.PGE_PLUS_REBATE_ONLY_HIGHER_REBATES_ELIGIBILE,
          {
            state,
          },
        );
      } else {
        throw new Error();
      }
    } catch (error) {
      throw new Error();
    }
  };

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      const response = await updateSectionRebateConfirm({
        ...rebateConfirmERFormStateAdapter().fromFormState(
          {
            ...rebateConfirm,
            iqbd: form.values,
          },
          application?.id!,
          sectionDataRebateConfirm?.id,
        ),
        saveAndComeBack: true,
      });
      if (
        response?.errors ||
        response.data?.updateSectionERRebateConfirm?.validation?.errors ||
        response.data?.updateSectionERRebateConfirm?.section?.errors.length !==
          0
      ) {
        throw new Error();
      } else {
        void navigate(ROUTES.ACCOUNT);
      }
    } catch {
      setErrorNotification(true);
    }
  });

  return (
    <form onSubmit={form.submit(values => handleSubmit(values), console.log)}>
      <Box>
        {loading && <Backdrop forceOpen />}
        <Box
          css={{
            '& a': { textDecoration: 'none', fontWeight: 700 },
            marginBottom: theme.spacing(1),
          }}
        >
          <Question
            title={content.get('WOULD_YOU_LIKE_TO_CHECK')}
            detail={richText(content.get('REBATE_ELIGIBILITY_DETAILS'))}
          />
        </Box>
        <Box mt={3} display={'flex'} justifyContent={'center'}>
          <Grid xs={12} md={6}>
            <RadioButtonGroup
              options={[
                {
                  label: content.get('REUSABLE_YES') || '',
                  value: content.get('REUSABLE_YES') || '',
                },
                {
                  label: content.get('REUSABLE_NO') || '',
                  value: content.get('REUSABLE_NO') || '',
                },
              ]}
              state={
                form.values.checkEligibility === true
                  ? content.get('REUSABLE_YES') || ''
                  : form.values.checkEligibility === false
                  ? content.get('REUSABLE_NO') || ''
                  : ''
              }
              setState={val => {
                void form.setValue(
                  'checkEligibility',
                  val === content.get('REUSABLE_YES'),
                );
              }}
            />
          </Grid>
        </Box>
        {form.errors.checkEligibility && (
          <FormHelperText error>{form.errors.checkEligibility}</FormHelperText>
        )}
        {form.values.checkEligibility && (
          <React.Fragment>
            <Grid
              container
              spacing={2}
              css={{ justifyContent: 'space-between', margin: '16px 0' }}
            >
              <Grid item xs={12} md={6}>
                <DropdownMenu
                  name={content.get('HOUSEHOLD_SIZE') || ''}
                  options={householdSizeOptions}
                  state={form.values.houseHoldSize?.toString() || ''}
                  setState={val => {
                    void form.setValue('houseHoldSize', Number(val));
                  }}
                  errorMsg={form.errors.houseHoldSize?.toString() || ''}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CurrencyTextField
                  css={{
                    margin: theme.spacing(0, 0, 0, 0),
                    width: '100%',
                    '& label': {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '90%',
                      '&.MuiInputLabel-shrink': {
                        overflow: 'unset',
                      },
                    },
                  }}
                  label={content.get('ANNUAL_HOUSEHOLD_INCOME') || ''}
                  name={content.get('ANNUAL_HOUSEHOLD_INCOME') || ''}
                  onChange={(e: any) => {
                    void form.setValue('grossIncome', e.target.value);
                  }}
                  value={form.values.grossIncome || ''}
                  error={false}
                />
                {form.errors.grossIncome && (
                  <FormHelperText error>
                    {form.errors.grossIncome}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <CheckBoxWithPaper
              option={{
                label: content.get('I_CONFIRM_THIS_IS_MY_INCOME') || '',
                value: content.get('I_CONFIRM_THIS_IS_MY_INCOME') || '',
              }}
              state={
                form.values.grossIncomeConfirmed
                  ? content.get('I_CONFIRM_THIS_IS_MY_INCOME') || ''
                  : ''
              }
              setState={val =>
                form.setValue(
                  'grossIncomeConfirmed',
                  val === content.get('I_CONFIRM_THIS_IS_MY_INCOME')
                    ? true
                    : undefined,
                )
              }
              errorMsg={form.errors.grossIncomeConfirmed?.toString()}
            />
            <Box marginTop={2} marginBottom={6}>
              <PopoverToolTip
                baseContent={content.get(
                  'LEARN_MORE_ABOUT_GROSS_ANNUAL_INCOME',
                )}
                popoverContent={content.get('GROSS_ANNUAL_INCOME_TOOLTIP')}
              />
            </Box>
          </React.Fragment>
        )}
        <ProceedOrCancelButton
          back={ROUTES.PGE_PLUS_REBATE_ONLY_CUSTOMER_INFORMATION}
          backLabel={content.get('REUSABLE_BUTTON_BACK')}
        />
      </Box>
      <Box mt={6}>
        <SaveAndComeBackLink
          content={content}
          onSaveAndExit={handleSaveAndExit}
        />
      </Box>
    </form>
  );
};

export default CheckHigherRebatesEligibility;
