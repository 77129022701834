import React from 'react';
import Grid from '@material-ui/core/Grid';
import ViewAndDownloadBills from '../../../components/view-and-download-bills/ViewAndDownloadBills';

const DownloadMultipleBillsPage = () => {
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <ViewAndDownloadBills />
      </Grid>
    </Grid>
  );
};

export default DownloadMultipleBillsPage;
