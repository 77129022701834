import gql from 'not-graphql-tag';

//FIXME: Remove serviceAddresses if it is no longer required on the frontend
const accountFragment = gql`
  fragment AccountInfo on AccountDetail {
    accountNumber
    accountType
    encryptedAccountNumber
    encryptedPersonId
    description
    isDefault
    isActive
    mainCustomerName
    pendingDisconnect {
      isPendingDisconnect
    }
    premiseInfo {
      encryptedPremiseId
      addressLine1
      saDetails {
        encryptedSAId
        saStatus
        startDate
        endDate
        nickName
      }
    }
    currentCharges {
      amountDue
      dueDate
    }
    paymentEligibility {
      isCashOnly
      isNonBillableNoBalance
    }
    serviceConnectivity {
      isDisconnected
      isEligibleForReconnect
      disconnectionDate
    }
  }
`;

export const getAccountsList = gql`
  query getAccountDetailList($params: AccountDetailListParams!) {
    getAccountDetailList(params: $params) {
      totalCount
      timestamp
      accounts {
        ...AccountInfo
      }
    }
  }
  ${accountFragment}
`;

// Here we cheat and use another query at the same time to pull the timestamp
export const getAccountDetails = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      ...AccountInfo
    }
    getAccountDetailList(params: {}) {
      timestamp
    }
  }
  ${accountFragment}
`;

export const submitOnecheckPayment = gql`
  mutation submitOnecheckPayment($payload: OnecheckPaymentSubmitRequest!) {
    submitOnecheckPayment(payload: $payload) {
      success
      remittanceId
    }
  }
`;

export const getOnecheckPdf = gql`
  query getDownloadOnecheckRemittanceFormPdf(
    $payload: OnecheckPaymentSubmitRequest!
  ) {
    getDownloadOnecheckRemittanceFormPdf(payload: $payload) {
      pdf
      success
    }
  }
`;
