import {
  Card,
  CardContent,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import routes from '../../../routes';
import colors from '../../../themes/main-colors';
import GreenCheckIcon from '../../../static/greenCheckIcon.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      marginTop: theme.spacing(2),
    },
    content: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(2),
    },
    heading: {
      fontSize: theme.spacing(3.5),
      lineHeight: theme.typography.pxToRem(28),
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
      color: colors.lightCyan,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        marginBottom: '10px',
      },
    },
    subText: {
      fontSize: theme.spacing(1.75),
      marginTop: theme.spacing(1),
    },
    additionalPrograms: {
      display: 'block',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    ytdIcon: {
      width: '25px',
    },
    ytdSubtextContainer: {
      display: 'flex',
      height: '30px',
    },
    ytdSubtextBody: {
      marginLeft: '0.5rem',
      fontSize: 'larger',
      paddingTop: '0.6rem',
    },
  }),
);

export function Programs({
  category,
  children,
  ytdFlexLoadEarnings,
  isDataLoading,
  isEnergyShiftCardsNotAvailable,
}: {
  category: 'energy-shift' | 'renewables';
  children: React.ReactNode;
  ytdFlexLoadEarnings?: string;
  isDataLoading?: boolean;
  isEnergyShiftCardsNotAvailable?: boolean;
}) {
  const textData: {
    title: string;
    subText: React.ReactNode;
    additionalProgramsCTATitle: string;
  } = {
    title: '',
    subText: '',
    additionalProgramsCTATitle: '',
  };
  const classes = useStyles();
  const { t, inlineRichT } = useTranslation();

  if (category === 'energy-shift') {
    textData.title = t('MY_ENERGY_SHIFTING_PROGRAMS');
    textData.subText = !isDataLoading && Boolean(ytdFlexLoadEarnings) && (
      <div className={classes.ytdSubtextContainer}>
        <span>
          <GreenCheckIcon className={classes.ytdIcon} />
        </span>
        <span className={classes.ytdSubtextBody}>
          {inlineRichT('YTD_EARNINGS', {
            YTD_EARNINGS: ytdFlexLoadEarnings,
          })}
        </span>
      </div>
    );
  }

  if (category === 'renewables') {
    textData.title = t('MY_RENEWABLES');
  }

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Typography className={classes.heading}>{textData.title}</Typography>
        <Typography className={classes.subText}>{textData.subText}</Typography>
        <Link to={routes.HOME} className={classes.additionalPrograms}>
          {textData.additionalProgramsCTATitle}
        </Link>
        {children}
      </CardContent>
    </Card>
  );
}
