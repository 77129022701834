import Button, { ButtonProps } from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core';
import React from 'react';

import colors from '../../themes/main-colors';

const StyledButton = withStyles({})((props: ButtonProps) => (
  <Button
    fullWidth
    aria-controls="customized-menu"
    aria-haspopup="true"
    variant="outlined"
    endIcon={<KeyboardArrowDownIcon />}
    style={{
      background: '#fff',
    }}
    {...props}
  />
));

interface StyledMenuProps extends MenuProps {
  width: number;
}

const useStyledMenuStyles = makeStyles({
  paper: {
    width: (props: StyledMenuProps) => props.width,
  },
});

const StyledMenu = (props: StyledMenuProps) => {
  const classes = useStyledMenuStyles(props);
  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      classes={classes}
      {...props}
    />
  );
};

const StyledMenuItem = withStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    color: colors.noirBlur,
    '& .PrimaryText': {
      flex: '1 0 auto',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    '& .primary': {
      color: colors.noirBlur,
      '& span': {
        whiteSpace: 'normal',
      },
    },
    '& .secondary': {
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
}))(MenuItem);

interface AccountGroupSelectProps {
  elements: { labels: { primary?: string; secondary?: string }[] }[];
  onSelect: (selectedGroup: any) => void;
}

export default function AccountGroupSelect(props: AccountGroupSelectProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [width, setWidth] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setWidth(event.currentTarget.clientWidth);
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    props.onSelect(props.elements[index]);
  };

  return (
    <React.Fragment>
      <StyledButton onClick={handleClick}>
        <StyledMenuItem>
          {props.elements[selectedIndex].labels.map((label, index) => (
            <ListItemText
              key={index}
              primary={label.primary}
              secondary={label.secondary}
              className={label.primary ? 'primary' : 'secondary'}
              style={{ flex: label.secondary ? '0 0 auto' : '1 0 auto' }}
              primaryTypographyProps={{
                style: {
                  fontWeight: index < 1 ? 'bold' : 'inherit',
                  color: colors.noirBlur,
                },
              }}
              secondaryTypographyProps={{
                style: {
                  color: colors.noirBlur,
                },
              }}
            />
          ))}
        </StyledMenuItem>
      </StyledButton>
      <StyledMenu
        width={width}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {props.elements.map((element, index) => (
          <StyledMenuItem
            key={index}
            selected={index === selectedIndex}
            onClick={() => handleSelect(index)}
          >
            {element.labels.map((label, idx) => (
              <ListItemText
                key={idx}
                primary={label.primary}
                secondary={label.secondary}
                className={label.primary ? 'primary' : 'secondary'}
                style={{ flex: label.secondary ? '0 0 auto' : '1 0 auto' }}
              />
            ))}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </React.Fragment>
  );
}
