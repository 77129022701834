import {
  Box,
  Card,
  CardContent,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { navigate } from 'gatsby';
import React from 'react';
import colors from '../../themes/main-colors';
import theme from '../../themes/theme';
import { useIsMobile } from '../../util/style-utils';
import PGEButton from '../buttons';

const useStyles = (bgColor?: string) =>
  makeStyles(() => {
    const heading = {
      fontSize: theme.spacing(3),
      lineHeight: theme.typography.pxToRem(28),
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
      color: colors.noirBlur,
    };
    return createStyles({
      container: {
        padding: theme.spacing(1),
        backgroundColor: bgColor ? bgColor : colors.aliceBlue,
      },

      cardHeader: {
        ...heading,
        marginBottom: theme.spacing(2),
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(2),
        },
      },
      cardBody: {
        marginBottom: theme.spacing(2),
      },
      disclaimer: {
        fontSize: '12px',
        marginTop: theme.spacing(2),
      },
      cardPanel: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column-reverse',
        },
      },
      leftPanelContainer: {
        marginRight: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
          marginLeft: 'unset',
          height: 'inherit',
        },
      },
      image: {
        height: '145px',
        width: '145px',
      },

      ctaContainer: {
        textAlign: 'left',
        marginTop: 'auto',
      },
      btnRight: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          marginLeft: 'unset',
          marginTop: theme.spacing(2),
        },
      },
    });
  });

type SingleCardProps = {
  cardHeader?: string;
  cardBody?: React.ReactNode;
  buttons?: {
    ctaText: string;
    ctaLink: string;
  }[];
  disclaimer?: string;
  bgColor?: string;
  image?: string;
  mobileImage?: string;
};

export function SingleCard(props: SingleCardProps) {
  const {
    cardHeader,
    cardBody,
    buttons,
    disclaimer,
    bgColor,
    image,
    mobileImage,
  } = props;
  const classes = useStyles(bgColor)();
  const isMobile = useIsMobile();

  return (
    <Card className={classes.container}>
      <CardContent className={classes.cardPanel}>
        <Box className={classes.leftPanelContainer}>
          {cardHeader && (
            <Typography className={classes.cardHeader}>{cardHeader}</Typography>
          )}
          {cardBody && <Box className={classes.cardBody}>{cardBody}</Box>}
          <Box className={classes.ctaContainer}>
            {buttons &&
              buttons.map((btn, i) => (
                <PGEButton
                  className={i === 1 ? classes.btnRight : undefined}
                  onClick={() => void navigate(btn.ctaLink)}
                  fullWidth={isMobile}
                  variant={i === 1 ? 'outlined' : undefined}
                  key={i}
                >
                  {btn.ctaText}
                </PGEButton>
              ))}
          </Box>
          {disclaimer && (
            <Typography className={classes.disclaimer}>{disclaimer}</Typography>
          )}
        </Box>

        <Box>
          <img
            src={isMobile && mobileImage ? mobileImage : image}
            className={classes.image}
          />
        </Box>
      </CardContent>
    </Card>
  );
}
