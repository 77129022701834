import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from '../../hooks/useTranslation';
import { Card, CardContent } from '@material-ui/core';
import ROUTES from '../../routes';
import { Model } from './types';
import gql from 'not-graphql-tag';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import useAuthQuery from '../../hooks/useAuthQuery';
import { AccountDetail, ServiceAddress } from '../../__generated__/pge-types';
import {
  toDateStringFullMonthName,
  displayPhoneNumberFormat,
} from '../../util/format';
import { parse } from 'date-fns';
import { useIsMobile } from '../../util/style-utils';
import PGEButton from '../buttons';
import { navigate } from '@reach/router';

type Props = {
  path?: string;
  data: Model;
  onSubmit: (data: Model, serviceAddress: ServiceAddress) => void;
};

export const getAccountDetails = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      encryptedAccountNumber
      encryptedPersonId
      serviceAddresses {
        addressLine1
        addressLine2
        city
        state
        postal
      }
    }
  }
`;

export default function StopServiceReview({ data, onSubmit }: Props) {
  const { t } = useTranslation();
  const { accountParams } = useSelectedAccountParams();

  const { data: accountData } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetails, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams,
  });
  const isMobile = useIsMobile();

  const account = accountData?.getAccountDetails?.[0];
  const serviceAddress = account?.serviceAddresses?.[0];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h2">
                    {t('PLEASE_MAKE_SURE_EVERYTHING_IS_CORRECT')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1" component="div">
                  <Grid container spacing={3}>
                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        {t('STOP_SERVICE_DATE')}
                      </Grid>
                      <Grid item xs={12}>
                        <strong>
                          {toDateStringFullMonthName(
                            parse(data.stopDate, 'MM/dd/yyyy', new Date(0)),
                          )}
                        </strong>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        {t('SEND_FINAL_BILL_TO')}
                      </Grid>
                      <Grid item xs={12}>
                        <strong>{data.address}</strong>
                      </Grid>
                      <Grid item xs={12}>
                        <strong>{`${data.city}, ${data.state} ${data.zip}`}</strong>
                      </Grid>
                    </Grid>

                    <Grid container item xs={12}>
                      <Grid item xs={12}>
                        {t('SEND_IN_CARE_OF')}
                      </Grid>
                      <Grid item xs={12}>
                        <strong>{data.inCareOf}</strong>
                      </Grid>
                      <Grid item xs={12}>
                        <strong>
                          {data.phoneExt
                            ? `${displayPhoneNumberFormat(data.phone)} ${
                                data.phoneExt
                              }`
                            : displayPhoneNumberFormat(data.phone)}
                        </strong>
                      </Grid>
                    </Grid>
                  </Grid>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Grid
          item
          container
          direction="row"
          justify={isMobile ? 'center' : 'flex-end'}
          spacing={1}
          wrap="wrap-reverse"
        >
          <Grid item xs={10} md="auto">
            <PGEButton
              fullWidth
              onClick={() => navigate(ROUTES.STOP_SERVICE)}
              color="secondary"
              variant="outlined"
            >
              {t('BACK')}
            </PGEButton>
          </Grid>
          <Grid item xs={10} md="auto">
            <PGEButton
              fullWidth
              disabled={!serviceAddress}
              onClick={() => onSubmit(data, serviceAddress!)}
            >
              {t('STOP_SERVICE_BUTTON')}
            </PGEButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
