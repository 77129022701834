import useFormState from '../../hooks/useFormState';
import { useTranslation } from '../../hooks/useTranslation';
import validationRules from './ChangeEmailForm.rules';
import {
  updateEmailMutation,
} from '../../queries/changeEmail.query';
import { ApolloClient } from 'apollo-client';
import React from 'react';
import { Link } from 'gatsby';
import ROUTES from '../../routes';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import useAuth from '../../hooks/useAuth';
import { useApolloClient } from '@apollo/react-hooks';
import { NavigateOptions } from '@reach/router';

export type Model = {
  newEmail: string;
};

const defaultState: Model = {
  newEmail: '',
};

enum ChangeEmailReason {
  Success = 'Success',
  PersonNotRegistered = 'PersonNotRegistered',
  WebUserNameExists = 'WebUserNameExists'
}

// exported function to allow for testing it in unit tests.
export async function updateEmail(
  newEmail: string,
  apolloClient: ApolloClient<any>,
  encryptedPersonId: string | undefined,
): Promise<{
  isSuccess: boolean,
  reason: ChangeEmailReason
}> {
  const queryResult = await apolloClient.mutate({
    mutation: updateEmailMutation,
    variables: {
      payload: {
        newEmail: newEmail,
        encryptedPersonId: encryptedPersonId,
      },
    },
  });
  return queryResult?.data?.updateEmail;
}

// exported function to allow for testing it in unit tests.
/**
 * signs user out if updateEmail is successful
 */
export async function handleUpdateEmailResponse(
  updateEmailSuccessFlag: boolean,
  newEmail: string,
  signOut: (route: any, options: NavigateOptions<any>) => Promise<void>,
): Promise<void> {
  if (!updateEmailSuccessFlag) {
    throw new Error('update email mutation was not successful');
  }
  const navigationState = { newEmail };
  // signing out the current user to make them re-sign in with their new email address.
  try {
    await signOut(
      `${ROUTES.CHANGE_EMAIL_CONFIRMATION}?emailAddress=${newEmail}`,
      {
        state: navigationState,
        replace: true,
      },
    );
  } catch (err) {
    // Swallowing Errors because core function worked
    console.log('Error while signing out.' + err);
  }
}

const useChangeEmailForm = () => {
  const apolloClient = useApolloClient();
  const { signOut } = useAuth();
  const { customer } = useAccountCustomer();
  const { t } = useTranslation();
  const form = useFormState(defaultState, {
    validate: validationRules,
    validationContext: { t },
  });

  const onSubmit = async (newEmail: string): Promise<void> => {
    const updateEmailResponse = await updateEmail(
      newEmail,
      apolloClient,
      customer?.encryptedPersonId,
    );
    if (updateEmailResponse.isSuccess) {
      await handleUpdateEmailResponse(
        updateEmailResponse.isSuccess,
        newEmail,
        signOut,
      );
    } else {
      form.setError(
        'newEmail',
        <span>
          {t('CHANGE_EMAIL__EMAIL_ALREADY_REGISTERED_1')}
          {' ' + newEmail + ' '}
          {t('CHANGE_EMAIL__EMAIL_ALREADY_REGISTERED_2')}{' '}
          <Link to={ROUTES.SIGN_IN}>{t('SIGN_IN')}</Link>
          {'. '}
          {t('CHANGE_EMAIL__EMAIL_ALREADY_REGISTERED_3')}
        </span>,
      );
    }
  };

  return {
    customer,
    form,
    onSubmit,
  };
};

export default useChangeEmailForm;
