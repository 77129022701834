import React from 'react';
import { DateAndAdress } from '../../../hooks/useSSMStopService';
import { useTranslation } from '../../../hooks/useTranslation';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import colors from '../../../themes/main-colors';
import { Link } from 'gatsby';
import routes from '../../../routes';
import moment from 'moment';
import { displayPhoneNumberFormat } from '../../../util/format';
import { PremiseInfo } from '../../../__generated__/pge-types';

const useStyles = makeStyles(() => ({
  costSummmaryBox: {
    padding: '15px',
    background: colors.riverBlue,
    marginTop: '26px',
  },
  costSummmaryBoxText: {
    fontSize: '18px',
  },
  belowInfo: {
    color: colors.charcoal,
    fontSize: '18px',
    margin: '6px 0 10px',
  },
  summaryCard: {
    padding: '39px 20px',
    background: colors.lightGray2,
    boxShadow: `0px 3px 6px ${colors.boxShadowColor}`,
  },
  cardText: {
    fontSize: '16px',
    color: colors.lightGray4,
    fontWeight: 'bold',
  },
  cardFirstline: {
    display: 'flex',
    flexDirection: 'row',
  },
  linkContainer: {
    marginLeft: 'auto',
  },
  linkItem: {
    textDecoration: 'none',
    fontSize: '16px',
    color: colors.brightBlue,
  },
  cardInnerBox: {
    marginTop: '10px',
    marginLeft: '18px',
  },
  cardInnerText: {
    fontSize: '14px',
    lineHeight: '17px',
    marginBottom: '10px',
  },
  topTest: {
    display: 'none',
  },
}));

export const SSMStopServiceReview = ({
  componentProps,
}: WizardFormProps<DateAndAdress>) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { ssmStopServiceState } = componentProps;

  const premiseInfo = ssmStopServiceState?.account
    ?.premiseInfo as PremiseInfo[];

  const address = `${premiseInfo?.[0]?.addressLine1}, ${premiseInfo?.[0]?.city}, ${premiseInfo?.[0]?.postal}`;

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <Box>
          <Typography
            variant={'h2'}
            data-testid={'confirmation-text'}
            className={classes.belowInfo}
          >
            {t('CONFIRM_INFORMATION_BEFORE_SUBMIT')}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.summaryCard}>
          <Box className={classes.cardFirstline}>
            <Box>
              <Typography
                variant={'h2'}
                data-testid={'stop-summary'}
                className={classes.cardText}
              >
                {t('SSM_STOP_SUMMARY')}
              </Typography>
            </Box>
            <Box className={classes.linkContainer}>
              <Link className={classes.linkItem} to={routes.SSM_STOP_ADDRESS}>
                {t('SSM_EDIT')}
              </Link>
            </Box>
          </Box>
          <Box className={classes.cardInnerBox}>
            <Typography
              className={classes.cardInnerText}
              data-testid={'stop-summary-address'}
            >
              {t('STOP_SERVICE_ADDRESS_PLACEHOLDER')}
            </Typography>
            <Typography
              className={classes.cardInnerText}
              data-testid={'address-value'}
            >
              <strong>{address}</strong>
            </Typography>
            <Typography
              className={classes.cardInnerText}
              data-testid={'stop-summary-date'}
            >
              {t('STOP_SERVICE_DATE_PLACEHOLDER')}
            </Typography>
            <Typography
              className={classes.cardInnerText}
              data-testid={'date-value'}
            >
              <strong>
                {' '}
                {ssmStopServiceState?.addressAndDate?.date &&
                  moment(ssmStopServiceState?.addressAndDate?.date).format(
                    'MM/DD/YYYY',
                  )}
              </strong>
            </Typography>
            <Typography
              className={classes.cardInnerText}
              data-testid={'stop-summary-billing'}
            >
              {t('FINAL_BILLING_ADDRESS')}
            </Typography>
            <Typography
              className={classes.cardInnerText}
              data-testid={'final-address-value'}
            >
              <strong>{`${ssmStopServiceState?.addressAndDate?.addressLine1}, ${ssmStopServiceState?.addressAndDate?.city}, ${ssmStopServiceState?.addressAndDate?.state}, ${ssmStopServiceState?.addressAndDate?.zip}`}</strong>
            </Typography>
            <Typography
              className={classes.cardInnerText}
              data-testid={'stop-summary-contact'}
            >
              {t('CONTACT_INFORMATION')}
            </Typography>
            <Typography
              className={classes.cardInnerText}
              data-testid={'contact-value'}
            >
              <strong>
                {displayPhoneNumberFormat(
                  ssmStopServiceState?.addressAndDate?.phone?.replace(
                    /[^0-9]/g,
                    '',
                  ),
                )}
              </strong>
            </Typography>
            <Typography
              className={classes.cardInnerText}
              data-testid={'stop-summary-remaining-behind'}
            >
              {t('SOMEONE_REMAINING_BEHIND')}
            </Typography>
            <Typography className={classes.cardInnerText}>
              <strong data-testid={'someone-behind-value'}>
                {String(ssmStopServiceState?.addressAndDate?.stayingBehind)}
              </strong>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
