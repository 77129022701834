import { createStyles, makeStyles, Theme } from '@material-ui/core';
import colors from '../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justtifyContent: 'flex-end',
      '& .MuiGrid-grid-xs-12': {
        margin: '15px 0px',
      },
      '& .MuiGrid-item': {
        '& .recharts-wrapper': {
          marginLeft: '-30px',
        },
      },
      '& .MuiGrid-direction-xs-column-reverse': {
        '& > div:first-child': {
          padding: 'unset',
          '& div': {
            '& a': {
              paddingRight: 'unset',
            },
          },
        },
        '& > div:last-child': {
          height: '80px',
        },
      },
    },
    inputField: {
      [theme.breakpoints.up('md')]: { width: '40% !important' },
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    notEnoughDataContent: {
      color: colors.noirBlur,
      padding: '5px 0px',
    },
    todHeader: {
      fontSize: '24px',
      letterSpacing: '0px',
      opacity: '1',
    },
    todSubHeader: {
      fontSize: '20px',
      letterSpacing: '0px',
      opacity: '1',
      fontWeight: 'bold',
    },
    notEnoughDataGrid: {
      background: '#F1F1F1 0% 0% no-repeat padding-box',
      borderRadius: '5px',
      textAlign: 'left',
      margin: '10px 0px',
      padding: '15px',
    },
    notEnoughDataHeader: {
      fontSize: '24px',
      letterSpacing: '0px',
      opacity: '1',
      fontWeight: 'bold',
      textAlign: 'left',
    },
    notEnoughDataEnrolled: {
      fontSize: '24px',
      letterSpacing: '0px',
      opacity: '1',
      textAlign: 'left',
    },
    notEnoughDataSubHeader: {
      fontSize: '18px',
      letterSpacing: '0px',
      opacity: '1',
      fontWeight: 'bold',
    },
    sideBySideBox: {
      boxShadow: 'inset 0px 2px 0px #27788C, 0px 3px 6px #00000029',
      borderRadius: '4px',
      textAlign: 'center',
      opacity: 1,
      padding: '20px',
      margin: '30px 0px',
    },
    savingsBox: {
      boxShadow: 'inset 0px 2px 0px #3D5265, 0px 3px 6px #00000029',
      borderRadius: '4px',
      textAlign: 'center',
      opacity: 1,
      padding: '20px',
      marginTop: '0px',
      marginBottom: '30px',
      width: '100%',
    },
    sideBySideSubHeader: {
      fontSize: '18px',
      letterSpacing: '0px',
      opacity: '1',
      fontWeight: 'bold',
      textAlign: 'left',
    },
    sideBySideHeader: {
      fontSize: '18px',
      letterSpacing: '0px',
      opacity: '1',
      fontWeight: 'bold',
      marginBottom: '15px',
    },
    sideBySideContent: {
      textAlign: 'left',
      fontSize: '15px',
    },
    verticalDivider: {
      height: '100%',
      float: 'left',
      padding: '0px 15px',
    },
    sideBySideDivider: {
      border: '1px solid #27788C',
      margin: '10px 0px',
    },
    todTermsTitle: {
      color: colors.noirBlur,
      fontWeight: 800,
    },
    sideBySideAmount: {
      fontWeight: 'bold',
      fontSize: '28px',
      margin: '4px',
    },
    todTermsLink: {
      fontWeight: 800,
    },
    actionArea: {
      textAlign: 'center',
      '& Button': {
        width: '100%',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
          width: '60%',
          justifyContent: 'center',
          margin: theme.spacing(3),
        },
      },
    },
    cancelLink: {
      marginTop: '1em',
      textAlign: 'center',
      marginLeft: '1em',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
      '& a': {
        paddingRight: '30px',
        '&:active': {
          borderBottom: 'none',
        },
      },
    },
    enrollButton: {
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
      '& Button': {
        backgroundColor: '#EB7100',
      },
    },
    greenIcons: {
      background: theme.palette.success.main,
    },
    blueIcon: {
      background: '#00AED0',
    },
    redIcon: {
      background: '#E86A5E',
    },
    callModal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: theme.spacing(4, 4, 3, 3),
      boxShadow: theme.shadows[5],
      backgroundColor: theme.palette.background.paper,
      borderRadius: '5px',
      [theme.breakpoints.down('xs')]: {
        maxHeight: '95%',
        minWidth: '80%',
        padding: theme.spacing(4, 3, 3, 3),
        overflow: 'auto',
      },
      overflow: 'auto',
      maxHeight: '95%',
    },
    callModalTitle: {
      fontSize: '20px',
    },
    modal: {
      position: 'absolute',
      width: '100%',
      maxWidth: '450px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50% , -50%)',
      outline: 0,
      [theme.breakpoints.down('xs')]: {
        maxWidth: '95%',
      },
    },
    successBorder: {
      border: `1px solid ${theme.palette.success.main}`,
    },
    successColor: {
      color: theme.palette.success.main,
    },
    unenrollBtn: {
      margin: theme.spacing(0, 2),
      display: 'flex',
      flexGrow: 1,
      borderWidth: 2,
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: '#E6F5FF',
        borderWidth: '2px',
        borderStyle: 'solid',
        boxShadow: '2px 5px 5px #d6d6d6',
      },
    },
    basicCost: {
      fontSize: '14px',
      textAlign: 'center',
    },
    basicCostInfo: {
      color: '#3D5265',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    tooltip: {
      backgroundColor: 'rgb(255, 255, 255)',
      border: '1px solid rgb(204, 204, 204)',
      '& div': {
        padding: '3px 10px',
      },
    },
  }),
);

export default useStyles;
