import { Router } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import AccountLayout from '../../components/account-layout';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import RooftopSolar from '../../components/rooftop-solar';

export const RooftopSolarMain = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Net Metering Application Status</title>
      </Helmet>
      <AccountLayout>
        <Router basepath="/">
          <RooftopSolar path={`${ROUTES.ROOFTOP_SOLAR}/*`} />
        </Router>
      </AccountLayout>
    </>
  );
};
export default RooftopSolarMain;
