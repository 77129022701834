/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { Fragment, useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import colors from '../../../../../themes/main-colors';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import Modal from '../../../../pge-plus-common/Modal';
import PopoverToolTip from '../../../../pge-plus-common/PopoverToolTip';
import { Button, Divider, FormHelperText, TextField } from '@material-ui/core';
import { ProceedOrCancelButton } from '../../../../pge-plus-common/StepperForm';
import routes from '../../../../../routes';
import { SaveAndComeBackLink } from '../../../../pge-plus-common/SaveAndComeBackLink';
import useWrapWithLoader from '../../../../../hooks/useWrapWithLoading';
import { useRebateOnlyFormState } from '../../../../../providers/RebateOnlyFormStateProvider';
import useFormState from '../../../../../hooks/useFormState';
import { uploadSerialChargerValidateFunction } from '../rebateOnlyForm.rules';
import useUpdateERSections from '../../../../../hooks/pge-plus/useUpdateERSections';
import { rebateInfoERFormStateAdapter } from '../rebateOnlyFormState.adapter';
import {
  ApplicationErSectionType,
  ApplicationSectionStatus,
  OcrFieldNames,
  SectionErRebateInfo,
} from '../../../../../__generated__/pgeplus-types';
import useUtil from '../../../../need-more-time-to-pay/useUtil';
import {
  QualifiedProductInfo,
  UpdateSectionErrorCode,
} from '../rebateOnlyForm.types';
import FileUploaderWithOCR from '../../../../pge-plus-common/FileUploaderWithOCR';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageTitle: {
      fontSize: '16px !important',
      padding: '0px !important',
      color: `${colors.sparkBlue} !important`,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: 'unset !important',
      },
    },
    errorMessage: {
      fontSize: '1rem !important',
      letterSpacing: '1px',
    },
  }),
);

const UploadJuiceboxIdNumber: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const theme = useTheme();
  const classes = useStyles(props);
  const { wrapWithLoader } = useWrapWithLoader();

  const { content, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_CONFIRM_YOUR_CHARGER,
  );
  const { content: fileUploaderText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_UPLOAD_PHOTOS,
  );
  const [openExamplePhotoModal, setOpenExamplePhotoModal] = useState<boolean>(
    false,
  );
  const [ocrExtractionStatus, setOcrExtractionStatus] = useState<
    string | undefined
  >();
  const {
    formState: { rebateInfo, application },
    getSectionData,
    updateFormState,
  } = useRebateOnlyFormState();
  const form = useFormState(rebateInfo.qualifiedProductInfo!, {
    validate: uploadSerialChargerValidateFunction(),
    validationContext: {
      content,
    },
  });

  const { updateSectionRebateInfo } = useUpdateERSections();
  const sectionDataRebateInfo: SectionErRebateInfo = getSectionData(
    ApplicationErSectionType.ErRebateInfo,
  );
  const { setErrorNotification } = useUtil();
  const onSubmit = wrapWithLoader(async (data: QualifiedProductInfo) => {
    try {
      const response = await updateSectionRebateInfo(
        rebateInfoERFormStateAdapter().fromFormState(
          {
            ...rebateInfo,
            qualifiedProductInfo: data,
            evPulse: undefined,
          },
          application?.id!,
          sectionDataRebateInfo?.id,
          ApplicationSectionStatus.Completed,
        ),
      );

      // Save the response in a local var
      const result = response?.data?.updateSectionERRebateInfo;

      // Check for errors to show the red banner and block navigation
      if (response?.errors || result?.validation?.errors) {
        throw new Error();
      }

      //Update the app state with the user selected values
      if (result?.section.data) {
        const updatedValues = rebateInfoERFormStateAdapter().toFormState(
          result?.section.data,
        );
        updateFormState('rebateInfo', {
          ...updatedValues,
        });
      }

      if (result?.section?.errors.length === 0) {
        void navigate(routes.PGE_PLUS_REBATE_ONLY_CUSTOMER_INFORMATION);
      } else {
        const serialNoAlreadyEnrolled = result?.section.errors.filter(
          reason =>
            reason.code === UpdateSectionErrorCode.SerialNoAlreadyEnrolled,
        );
        if (serialNoAlreadyEnrolled) {
          await navigate(routes.PGE_PLUS_REBATE_ONLY_INELIGIBLE, {
            state: {
              message: 'REBATE_ONLY_SMART_CHARGING_INELIGIBLE',
            },
          });
        } else {
          throw new Error();
        }
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });
  const subText1 = {
    fontSize: 20,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(3),
    },
  };

  const ErrorText = {
    fontSize: 20,
    marginBottom: theme.spacing(2),
    color: colors.roseRed,
  };

  const boldText = {
    fontWeight: 700,
  };

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      const response = await updateSectionRebateInfo({
        ...rebateInfoERFormStateAdapter().fromFormState(
          {
            ...rebateInfo,
            qualifiedProductInfo: form.values,
            evPulse: undefined,
          },
          application?.id!,
          sectionDataRebateInfo?.id,
        ),
        saveAndComeBack: true,
      });
      if (
        response?.errors ||
        response.data?.updateSectionERRebateInfo?.validation?.errors ||
        response.data?.updateSectionERRebateInfo?.section?.errors.length !== 0
      ) {
        throw new Error();
      } else {
        void navigate(routes.ACCOUNT);
      }
    } catch {
      setErrorNotification(true);
    }
  });

  return (
    <Box>
      <form
        onSubmit={form.submit(values => onSubmit(values), console.log)}
        noValidate
      >
        <Box>
          <Typography css={{ ...subText1, ...boldText }}>
            {content.get('UPLOAD_PHOTO_CHARGER_JUICEBOX_ID')}
          </Typography>
        </Box>
        <Box>
          <PopoverToolTip
            baseContent={content.get('HOW_TO_FIND_JUICEBOX_ID')}
            popoverContent={richText(
              content.get('HOW_TO_FIND_JUICEBOX_ID_TOOLTIP'),
            )}
          />
        </Box>
        <Box>
          <Fragment>
            {!form.values?.serialNumberImage?.id && (
              <Box mt={3}>
                <Button
                  className={classes.imageTitle}
                  onClick={() => setOpenExamplePhotoModal(true)}
                >
                  {content.get('REUSABLE_VIEW_EXAMPLE_PHOTO')}
                </Button>
                <Modal
                  open={openExamplePhotoModal}
                  onClose={() => setOpenExamplePhotoModal(false)}
                  title={content.get('MODEL_SAMPLE_PHOTO_TITLE')}
                  showCloseIcon={true}
                  modalStyles={{ width: 600 }}
                >
                  {' '}
                  <Box mt={3}>
                    <img
                      css={{
                        width: '100%',
                        maxHeight: '350px',
                        objectFit: 'cover',
                      }}
                      src={content.get('JUICEBOX_ID_SAMPLE_PHOTO_URL')}
                    />
                  </Box>
                </Modal>
              </Box>
            )}

            {/* drag and drop box*/}
            <Box mt={2}>
              <FileUploaderWithOCR
                placeholder={fileUploaderText.get('UPLOAD_A_PHOTO')}
                reuploadLabel={fileUploaderText.get('REUPLOAD_PHOTO')!}
                ocrType={OcrFieldNames.JuiceboxId}
                onComplete={(key, extractedText, message, status) => {
                  void form.setValue('serialNumberImage', { id: key });
                  void form.setValue('serialNumber', extractedText);
                  setOcrExtractionStatus(status);
                }}
                image={form.values?.serialNumberImage}
              />
              {form.errors?.serialNumberImage && (
                <FormHelperText className={classes.errorMessage} error>
                  {form.errors.serialNumberImage}
                </FormHelperText>
              )}
            </Box>

            {form.values?.serialNumberImage && (
              <Box>
                {ocrExtractionStatus && ocrExtractionStatus === 'fail' && (
                  <FormHelperText
                    className={classes.errorMessage}
                    error
                    component="div"
                  >
                    {fileUploaderText.get('OCR_FAIL_MESSAGE')}
                  </FormHelperText>
                )}
                <Typography
                  css={{
                    ...subText1,
                    ...boldText,
                    marginTop: theme.spacing(3),
                    marginBottom: theme.spacing(0),
                  }}
                >
                  {ocrExtractionStatus && ocrExtractionStatus === 'success'
                    ? content.get('JUICEBOX_ID_NUMBER_VERIFICATION')
                    : content.get('JUICEBOX_ID_NUMBER')}
                </Typography>
                <TextField
                  css={{
                    background: 'transparent',
                    '.MuiInputBase-formControl': {
                      background: colors.white,
                    },
                    width: '100%',
                    margin: '8px 0 8px 0',
                  }}
                  name={'name'}
                  label={'ID number (Juicebox)'}
                  variant={'outlined'}
                  {...form.props('serialNumber')}
                />
                {form.errors?.serialNumber && (
                  <FormHelperText error>
                    {form.errors.serialNumber}
                  </FormHelperText>
                )}
                {ocrExtractionStatus && ocrExtractionStatus === 'success' && (
                  <Typography css={{ marginTop: 8 }}>
                    {content.get('JUICEBOX_ID_NUMBER_OCR_SUCCESS_MESSAGE')}
                  </Typography>
                )}
              </Box>
            )}
            <Divider css={{ color: colors.gray93, margin: '20px 0 0px 0' }} />
          </Fragment>
        </Box>
        <ProceedOrCancelButton
          back={routes.PGE_PLUS_REBATE_ONLY_UPLOAD_MODEL_NUMBER}
          backLabel={content.get('BACK')}
          disable={
            !form.values?.serialNumber || !form.values?.serialNumberImage
          }
        />

        <Box mt={3}>
          <SaveAndComeBackLink
            content={content}
            onSaveAndExit={handleSaveAndExit}
          />
        </Box>
      </form>
    </Box>
  );
};

export default UploadJuiceboxIdNumber;
