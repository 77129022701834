import gql from 'not-graphql-tag';

export const sameAsOldPasswordQuery = gql`
  query sameAsOldPassword($payload: SameAsOldPasswordRequest!) {
    sameAsOldPassword(payload: $payload) {
      sameAsOldPassword
    }
  }
`;

export const updatePasswordMutation = gql`
  mutation updatePassword($payload: ChangePasswordUpdateRequest!) {
    updatePassword(payload: $payload) {
      success
      signInToken
    }
  }
`;
