import {
  validatePhoneRequired,
  validateZipCode,
  validateCanadianZipCode,
} from '../../util/form-validation';
import { convertValidationRules } from '../../hooks/useFormState';
import { Model } from './types';
import { BaseValidationContext } from '../../hooks/useFormState.types';
import { statesAndProvincesGrouping } from '../utility/state-dropdown';
import { parse, isWithinInterval, addDays } from 'date-fns';

type AdditionalContext = {
  t: (translation: string) => string;
};

const datePattern = /\d{2}\/\d{2}\/\d{4}/;

function validateStopDate(
  date: string,
  context: BaseValidationContext<Model> & AdditionalContext,
) {
  if (date.length < 6 || !datePattern.test(date)) {
    return context.t('STOP_DATE_BLANK');
  }

  const userSelectedDate = parse(date, 'MM/dd/yyyy', new Date(0));
  const today = new Date().setHours(0, 0, 0, 0);
  const maxDate = addDays(today, 89);

  const isInRange = isWithinInterval(userSelectedDate, {
    start: today,
    end: maxDate,
  });

  if (!isInRange) {
    return context.t('STOP_DATE_OUT_OF_RANGE');
  }

  return null;
}

function validateInCareOf(inCareOf: string, context: AdditionalContext) {
  const invalidCharacters = new RegExp(/^.*?(?=[(^<>_={}[\];|\)]).*$/gi);
  if (inCareOf.match(invalidCharacters)) {
    return context.t('IN_CARE_OF_INVALID_CHARACTERS');
  }
  return null;
}

function validateAddress(address: string, context: AdditionalContext) {
  const invalidCharacters = new RegExp(/^.*?(?=[(^<>_={}[\];|\)]).*$/gi);
  if (!address) {
    return context.t('ENTER_FINAL_BILL_ADDRESS');
  } else if (address.match(invalidCharacters)) {
    return context.t('ADDRESS_INVALID_CHARACTERS');
  }
  return null;
}

function validateCity(city: string, context: AdditionalContext) {
  const invalidCharacters = new RegExp(/^.*?(?=[(^<>_={}[\];|\)]).*$/gi);
  if (!city) {
    return context.t('ENTER_CITY');
  } else if (city.match(invalidCharacters)) {
    return context.t('CITY_INVALID_CHARACTERS');
  }
  return null;
}

function validateState(state: string, context: AdditionalContext) {
  if (!state) {
    return context.t('SELECT_STATE');
  }
  return null;
}

export default convertValidationRules<Model, AdditionalContext>(
  (context: BaseValidationContext<Model>) => {
    const { values } = context;
    return {
      stopDate: validateStopDate,
      address: validateAddress,
      city: validateCity,
      state: validateState,
      zip: statesAndProvincesGrouping.canada.includes(values.state)
        ? validateCanadianZipCode
        : validateZipCode,
      phone: validatePhoneRequired,
      inCareOf: validateInCareOf,
    };
  },
);
