import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';
import { Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: colors.shoreGreen,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    paddingLeft: '20px',
  },
  successIcon: {
    '& path': {
      transform: 'scale(1.25) translateX(-2.5px) translateY(-2.5px)',
    },
    fill: colors.darkGreen,
    fontSize: 'inherit',
    background: colors.white,
    borderRadius: '50%',
    transform: 'scale(1.6)',
  },
  successText: {
    paddingLeft: '20px',
    fontWeight: 'bold',
  },
  successTextContainer: {
    padding: theme.spacing(2, 2, 2, 0),
    color: colors.white,
    flexGrow: 8,
    '& p': { margin: 0 },
    '& a': {
      color: colors.white,
    },
  },
}));

const ReconnectServiceSuccessNotice = ({}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <CheckCircleIcon className={classes.successIcon} />
      <div className={classes.successTextContainer}>
        <Typography variant={'h6'} className={classes.successText}>
          {t('RECONNECT_SUCCESS_BANER')}
        </Typography>
      </div>
    </Box>
  );
};

export default ReconnectServiceSuccessNotice;
