import gql from 'not-graphql-tag';
import useAuthQuery from './useAuthQuery';
import { PreferredDueDateResponse } from '../__generated__/pge-types';

const GET_PREFERRED_DUE_DATE_INFO = gql`
  query getPreferredDueDateInfo($payload: PreferredDueDateRequest) {
    getPreferredDueDateInfo(payload: $payload) {
      dueDateInfo {
        preferredDueDate
        status
        effectiveDate
      }
      eligibility {
        isEligible
      }
    }
  }
`;

export default function useGetPreferredDueDateInfo(
  encryptedAccountNumber = '',
) {
  const { data } = useAuthQuery<{
    getPreferredDueDateInfo: PreferredDueDateResponse;
  }>(GET_PREFERRED_DUE_DATE_INFO, {
    variables: {
      payload: {
        encryptedAccountNumber,
      },
    },
    fetchPolicy: 'network-only',
    skip: !encryptedAccountNumber,
  });

  const preferredDueDate =
    data?.getPreferredDueDateInfo?.dueDateInfo?.preferredDueDate;

  return {
    preferredDueDate,
  };
}
