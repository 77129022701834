import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { GreenFutureCardType } from '../../../__generated__/pge-types';
import { useTranslation } from '../../../hooks/useTranslation';
import TwentyFiveYearsPGEGreenFuture from '../../../images/25years.png';
import routes from '../../../routes';
import { GreenFuture as TGreenFuture } from '../types';
import { Card } from './Card';
import { stripOffCents } from '../../account-summary/energy-usage/current-use-card/useCurrentUseCard';

const useStyles = makeStyles(() =>
  createStyles({
    img: {
      width: 'inherit',
    },
  }),
);

export function GreenFuture(props: TGreenFuture) {
  const { cardType, kwh: kwhWithDecimals, consumptionPercentage } = props;
  const { t, inlineRichT } = useTranslation();
  const classes = useStyles();
  const kwh = String(stripOffCents(Number(kwhWithDecimals ?? 0)));

  if (cardType === GreenFutureCardType.FirstBillGfc) {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('WELCOME_TO_GREEN_FUTURE')}
        sectionA={inlineRichT('THANK_YOU_FOR_CHOOSING_GREEN_GF1', {
          consumptionPercentage,
        })}
        sectionB={
          <img src={TwentyFiveYearsPGEGreenFuture} className={classes.img} />
        }
        ctaText={t('GET_MORE_DETAILS')}
        ctaLink={routes.RENEWABLE_POWER}
      />
    );
  }

  if (cardType === GreenFutureCardType.FirstBillGfb) {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('WELCOME_TO_GREEN_FUTURE')}
        sectionA={inlineRichT('THANK_YOU_FOR_CHOOSING_GREEN_GF2', {
          kwh,
        })}
        sectionB={
          <img src={TwentyFiveYearsPGEGreenFuture} className={classes.img} />
        }
        ctaText={t('GET_MORE_DETAILS')}
        ctaLink={routes.RENEWABLE_POWER}
      />
    );
  }

  if (cardType === GreenFutureCardType.FirstBillGfcGfb) {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('WELCOME_TO_GREEN_FUTURE')}
        sectionA={inlineRichT('THANK_YOU_FOR_CHOOSING_GREEN_GF3', {
          kwh,
          consumptionPercentage,
        })}
        sectionB={
          <img src={TwentyFiveYearsPGEGreenFuture} className={classes.img} />
        }
        ctaText={t('GET_MORE_DETAILS')}
        ctaLink={routes.RENEWABLE_POWER}
      />
    );
  }

  if (
    cardType === GreenFutureCardType.MonthlyBillGfc ||
    cardType === GreenFutureCardType.MonthlyBillGfb ||
    cardType === GreenFutureCardType.MonthlyBillGfcGfb
  ) {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('GREEN_FUTURE_IN_ACTION')}
        sectionA={inlineRichT('THANK_YOU_FOR_SUPPORTING_GREEN', {
          kwh,
          consumptionPercentage,
        })}
        sectionB={
          <img src={TwentyFiveYearsPGEGreenFuture} className={classes.img} />
        }
        ctaText={t('GET_MORE_DETAILS')}
        ctaLink={routes.RENEWABLE_POWER}
      />
    );
  }

  return <></>;
}
