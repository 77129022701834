import React from 'react';
import SmartCardSection from '../smart-card-section/SmartCardSection';
import useNextBestOffer from './useNextBestOffer';

export function NextBestOffer() {
  const { contentfulSectionCard, cardGrouping } = useNextBestOffer();

  return (
    <SmartCardSection
      cardGrouping={cardGrouping}
      standaloneCards={[]}
      contentfulSectionCard={contentfulSectionCard}
    />
  );
}
