import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import {
  MonthlyBill,
  NetMeteringApplicationStatus,
} from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import colors from '../../../../themes/main-colors';
import { stripOffCents } from '../../../account-summary/energy-usage/current-use-card/useCurrentUseCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: 'inherit',
    },
    item: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      background: colors.navBarBackground,
      '& > p': {
        fontSize: theme.spacing(1.75),
        letterSpacing: '0.720px',
        fontFamily: 'Forma-DJR-Display',
      },
    },
    containerBgNoirBlue: {
      background: colors.noirBlur,
      color: colors.navBarBackground,
    },
    textBold: {
      fontWeight: 'bold',
    },
  }),
);

type TMeteringList = {
  type: 'application' | 'monthlyBalance';
  status?: NetMeteringApplicationStatus;
  date?: string;
  monthlyBill?: MonthlyBill;
};

export function MeteringList(props: TMeteringList) {
  const { t } = useTranslation();
  const { type, status, date, monthlyBill } = props;
  const classes = useStyles();

  if (type === 'application') {
    const DEFAULT_APPLICATION_STEP_LABELS = {
      [NetMeteringApplicationStatus.ApplicationReceived]:
        'APPLICATION_RECEIVED',
      [NetMeteringApplicationStatus.ReviewInProgress]: 'REVIEW_IN_PROGRESS',
      [NetMeteringApplicationStatus.Approval]: 'ROOFTOP_ APPROVAL_TITLE',
      [NetMeteringApplicationStatus.MeterUpdatesAndInspection]:
        'METER_UPDATES_AND_INSPECTION',
      [NetMeteringApplicationStatus.SolarOn]: 'ROOFTOP_ SOLAR_ON_TITLE',
    };

    if (status === NetMeteringApplicationStatus.Approval) {
      DEFAULT_APPLICATION_STEP_LABELS[
        NetMeteringApplicationStatus.ReviewInProgress
      ] = 'REVIEW_COMPLETED';
    }

    if (status === NetMeteringApplicationStatus.MeterUpdatesAndInspection) {
      DEFAULT_APPLICATION_STEP_LABELS[
        NetMeteringApplicationStatus.ReviewInProgress
      ] = 'REVIEW_COMPLETED';
      DEFAULT_APPLICATION_STEP_LABELS[NetMeteringApplicationStatus.Approval] =
        'YOU_ARE_APPROVED';
    }

    if (status === NetMeteringApplicationStatus.SolarOn) {
      DEFAULT_APPLICATION_STEP_LABELS[
        NetMeteringApplicationStatus.ReviewInProgress
      ] = 'REVIEW_COMPLETED';
      DEFAULT_APPLICATION_STEP_LABELS[NetMeteringApplicationStatus.Approval] =
        'YOU_ARE_APPROVED';
      DEFAULT_APPLICATION_STEP_LABELS[
        NetMeteringApplicationStatus.MeterUpdatesAndInspection
      ] = 'METER_UPDATES_AND_INSPECTION_COMPLETE';
    }

    return (
      <Box className={classes.container}>
        <Box
          className={`${classes.item} ${
            status === NetMeteringApplicationStatus.ApplicationReceived
              ? classes.containerBgNoirBlue
              : ''
          }`}
        >
          <Typography>
            {t(
              DEFAULT_APPLICATION_STEP_LABELS[
                NetMeteringApplicationStatus.ApplicationReceived
              ],
            )}
          </Typography>
          <Typography>{date}</Typography>
        </Box>
        <Box
          className={`${classes.item} ${
            status === NetMeteringApplicationStatus.ReviewInProgress
              ? classes.containerBgNoirBlue
              : ''
          }`}
        >
          <Typography>
            {t(
              DEFAULT_APPLICATION_STEP_LABELS[
                NetMeteringApplicationStatus.ReviewInProgress
              ],
            )}
          </Typography>
        </Box>
        <Box
          className={`${classes.item} ${
            status === NetMeteringApplicationStatus.Approval
              ? classes.containerBgNoirBlue
              : ''
          }`}
        >
          <Typography>
            {t(
              DEFAULT_APPLICATION_STEP_LABELS[
                NetMeteringApplicationStatus.Approval
              ],
            )}
          </Typography>
        </Box>
        <Box
          className={`${classes.item} ${
            status === NetMeteringApplicationStatus.MeterUpdatesAndInspection
              ? classes.containerBgNoirBlue
              : ''
          }`}
        >
          <Typography>
            {t(
              DEFAULT_APPLICATION_STEP_LABELS[
                NetMeteringApplicationStatus.MeterUpdatesAndInspection
              ],
            )}
          </Typography>
        </Box>
        <Box
          className={`${classes.item} ${
            status === NetMeteringApplicationStatus.SolarOn
              ? classes.containerBgNoirBlue
              : ''
          }`}
        >
          <Typography>
            {t(
              DEFAULT_APPLICATION_STEP_LABELS[
                NetMeteringApplicationStatus.SolarOn
              ],
            )}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.item}>
        <Typography>{t('YOUR_NET_CONSUMPTION')}</Typography>
        <Typography className={classes.textBold}>{`${stripOffCents(
          Number(monthlyBill?.consumption ?? 0),
        )} ${t('KWH')}`}</Typography>
      </Box>
      <Box className={classes.item}>
        <Typography>{t('YOUR_EXCESS_GENERATION')}</Typography>
        <Typography className={classes.textBold}>{`${stripOffCents(
          Number(monthlyBill?.excessGeneration ?? 0),
        )} ${t('KWH')}`}</Typography>
      </Box>
      <Box className={classes.item}>
        {Number(monthlyBill?.energySavingBalance ?? 0) < 0 ? (
          <Typography>{t('ENERGY_SAVINGS_DEPOSIT')}</Typography>
        ) : (
          <Typography>{t('ENERYG_SAVINGS_WITHDRAWL')}</Typography>
        )}
        <Typography className={classes.textBold}>{`${stripOffCents(
          Math.abs(Number(monthlyBill?.energySavingBalance ?? 0)),
        )} ${t('KWH')}`}</Typography>
      </Box>
      <Box className={`${classes.item} ${classes.containerBgNoirBlue}`}>
        <Typography>{t('BILLED_ENERGY_THIS_MONTH')}</Typography>
        <Typography className={classes.textBold}>{`${stripOffCents(
          Number(monthlyBill?.billedEnergyThisMonth ?? 0),
        )} ${t('KWH')}`}</Typography>
      </Box>
    </Box>
  );
}
