/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';
import {
  Box,
  Typography,
  Modal,
  IconButton,
  useTheme,
  useMediaQuery,
  Slide,
  Grid,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import colors from '../../themes/main-colors';

type Props = {
  title?: string | React.ReactNode;
  description?: string;
  open: boolean;
  onClose: () => void;
  showCloseIcon?: boolean;
  children: React.ReactNode;
  modalStyles?: React.CSSProperties;
};

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 740,
  backgroundColor: colors.white,
  borderRadius: '5px',
  padding: 24,
};

const modalStyleMobile = {
  position: 'absolute' as 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
  backgroundColor: colors.white,
  borderRadius: '5px',
  padding: 24,
};

export default function BasicModal({
  title,
  description,
  open,
  onClose,
  showCloseIcon = true,
  children,
  modalStyles = {},
}: Props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {isMobile ? (
        <Slide direction="up" in={open}>
          <Box css={modalStyleMobile}>
            {showCloseIcon && (
              <Grid
                container
                css={{
                  justifyContent: 'end',
                }}
              >
                <Grid item>
                  <IconButton
                    css={{ padding: 0 }}
                    onClick={onClose}
                    data-testid="close-btn"
                  >
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
            )}
            {title && (
              <Typography
                id="modal-modal-title"
                component="h2"
                variant="h2"
                css={{ fontWeight: 'bold' }}
              >
                {title}
              </Typography>
            )}
            {description && (
              <Typography id="modal-modal-description" css={{ mt: 2 }}>
                {description}
              </Typography>
            )}
            {children}
          </Box>
        </Slide>
      ) : (
        <Box css={{ ...modalStyle, ...modalStyles }}>
          {showCloseIcon && (
            <Grid
              container
              css={{
                justifyContent: 'end',
              }}
            >
              <Grid item>
                <IconButton
                  css={{ padding: 0 }}
                  onClick={onClose}
                  data-testid="close-btn"
                >
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          )}
          {title && (
            <Typography
              id="modal-modal-title"
              component="h2"
              variant="h2"
              css={{ fontWeight: 'bold' }}
            >
              {title}
            </Typography>
          )}
          {description && (
            <Typography id="modal-modal-description" css={{ mt: 2 }}>
              {description}
            </Typography>
          )}
          {children}
        </Box>
      )}
    </Modal>
  );
}
