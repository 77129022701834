/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FC, ReactNode, useState, useEffect } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import useContentMgmt from '../../../hooks/useContentMgmt';
import ReactMarkdown from 'react-markdown';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { navigate } from 'gatsby';
import routes from '../../../routes';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface DeclineQuoteProps {
  children?: ReactNode;
  path: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: '32px',
      fontWeight: 700,
      textAlign: 'center',
      fontFamily: 'Forma-DJR-Display',
      [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
        fontSize: '24px',
        textAlign: 'left',
      },
    },
    details: {
      fontSize: '20px',
      [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
        fontSize: '16px',
      },
    },
  }),
);

export const DeclineQuote: FC<DeclineQuoteProps> = props => {
  const [acceptTerms, setAcceptTerms] = useState<string[]>([]);
  const { register, handleSubmit, watch } = useForm();

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.up('sm'));

  const onSubmit = (data: any) => {
    //TODO: store the data in global store
    //Navigate to the next screen
    void navigate(routes.ACCOUNT);
  };

  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_FINAL_QUOTE_ACCEPT_DECLINE,
  );

  return (
    <Box mt={4}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          <Typography css={{ marginTop: 16 }} component="div">
            <ReactMarkdown className={classes.title}>
              {content.get('CUSTOMER_QUOTE_DECLINE_QUOTE_CONFIRMATION_TITLE')}
            </ReactMarkdown>
            <ReactMarkdown className={classes.details}>
              {content.get('CUSTOMER_QUOTE_DECLINE_QUOTE_CONFIRMATION_DETAILS')}
            </ReactMarkdown>
          </Typography>
        </Box>
        {fullScreen && (
          <ProceedOrCancelButton
            back={false}
            isCancel={true}
            nextLabel={content.get('REUSABLE_BUTTON_CLOSE')}
          />
        )}
      </form>
    </Box>
  );
};
