import React, { useState } from 'react';
import { DocumentNode } from 'graphql';
import { useApolloClient } from '@apollo/react-hooks';
import { navigate } from 'gatsby';

import ROUTES from '../../routes';
import useAuthQuery from '../../hooks/useAuthQuery';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import useFormState from '../../hooks/useFormState';
import validationRules from './rules';
import { PeakTimeRebatesTransactionType } from './types';
import {
  getPeakTimeRebatesProgramInfoQuery,
  peakTimeRebatesEnrollMutation,
  peakTimeRebatesUpdateMutation,
  peakTimeRebatesCancelMutation,
  getAccountCacheQuery,
} from './queries';
import { PeakTimeRebateProgramInfoResponse } from '../../__generated__/pge-types';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import { parsePhoneNumber } from '../../util/format';

export default () => {
  const apolloClient = useApolloClient();
  const { wrapWithLoader } = useWrapWithLoader();

  const [isEnrolled, setIsEnrolled] = useState(false);
  const [isEligible, setIsEligible] = useState(false);
  const [transactionType, setTransactionType] = useState(
    PeakTimeRebatesTransactionType.Update,
  );
  const [showConfirm, setShowConfirm] = useState(false);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  const {
    encryptedAccountNumber,
    loading: accountParamsLoading,
    accountParams,
    encryptedPersonId,
  } = useSelectedAccountParams();

  function updateAccountCache() {
    return apolloClient.query({
      query: getAccountCacheQuery,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        params: {
          accountNumberList: [accountParams],
        },
      },
    });
  }

  const form = useFormState(
    {
      notificationEmail: '',
      notificationTextNumber: '',
    },
    {
      validate: validationRules,
      validationContext: {},
    },
  );

  const isMissingContactValue = () => {
    return (
      !Boolean(form.values.notificationEmail) &&
      !Boolean(form.values.notificationTextNumber)
    );
  };

  const { loading: queryLoading, refetch } = useAuthQuery<{
    getPeakTimeRebateProgramInfo: PeakTimeRebateProgramInfoResponse;
  }>(getPeakTimeRebatesProgramInfoQuery, {
    variables: {
      payload: {
        encryptedAccountNumber: encryptedAccountNumber,
        encryptedPersonId: encryptedPersonId,
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    skip: !encryptedAccountNumber,
    onCompleted: result => {
      const info = result?.getPeakTimeRebateProgramInfo;
      if (
        info?.isEnrolled === null ||
        info?.isEnrolled === undefined ||
        info?.isEligible === null ||
        info?.isEligible === undefined ||
        info?.peakTimeRebateContact === null ||
        info?.peakTimeRebateContact === undefined
      ) {
        return;
      }
      setIsEnrolled(info.isEnrolled);
      setIsEligible(info.isEligible);
      form.reset({
        ...form.values,
        notificationEmail: info?.peakTimeRebateContact?.email
          ? info.peakTimeRebateContact.email
          : '',
        notificationTextNumber: info?.peakTimeRebateContact?.mobilePhoneNumber
          ? parsePhoneNumber(info.peakTimeRebateContact.mobilePhoneNumber)
          : '',
      });
    },
  });

  const submitPeakTimeRebates = async (): Promise<boolean | undefined> => {
    let mutation: DocumentNode;
    switch (transactionType) {
      case PeakTimeRebatesTransactionType.Enroll:
        mutation = peakTimeRebatesEnrollMutation;
        break;
      case PeakTimeRebatesTransactionType.Update:
        mutation = peakTimeRebatesUpdateMutation;
        break;
      case PeakTimeRebatesTransactionType.Cancel:
        mutation = peakTimeRebatesCancelMutation;
        break;
      default:
        throw new Error('Invalid transaction type.');
    }

    const payload: any = {
      encryptedAccountNumber: encryptedAccountNumber,
      encryptedPersonId: encryptedPersonId,
    };

    if (transactionType !== PeakTimeRebatesTransactionType.Cancel) {
      payload.emailAddress = form.values.notificationEmail;
      payload.mobilePhoneNumber = form.values.notificationTextNumber;
    }

    const { data, errors } = await apolloClient.mutate({
      mutation: mutation,
      variables: { payload },
    });
    await updateAccountCache();

    let isSuccess: boolean | null | undefined = undefined;

    if (errors && errors.length > 0) {
      throw new Error('Mutation error.');
    } else {
      switch (transactionType) {
        case PeakTimeRebatesTransactionType.Enroll:
          isSuccess = data?.enrollPeakTimeRebate;
          break;
        case PeakTimeRebatesTransactionType.Update:
          isSuccess = data?.updatePeakTimeRebate;
          break;
        case PeakTimeRebatesTransactionType.Cancel:
          isSuccess = data?.cancelPeakTimeRebate;
          break;
        default:
          throw new Error('Invalid transaction type.');
      }
    }
    return Boolean(isSuccess);
  };

  const handleOnChangeEnrollOrCancel = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (e.target.value === PeakTimeRebatesTransactionType.Cancel) {
      setTransactionType(PeakTimeRebatesTransactionType.Cancel);
      return;
    }
    setTransactionType(PeakTimeRebatesTransactionType.Update);
  };

  const handleOnClickManageNext = async () => {
    if (isMissingContactValue()) {
      return;
    }
    await form.submit(
      async () => {
        setShowConfirm(true);
      },
      errors => {
        console.log(errors);
      },
    )();
  };

  const handleOnFormSubmit = wrapWithLoader(async () => {
    if (isMissingContactValue()) {
      return;
    }

    setIsSubmitSuccessful(false);
    await form.submit(
      async () => {
        try {
          const isSuccessful = await submitPeakTimeRebates();
          if (isSuccessful) {
            setIsSubmitSuccessful(true);
          } else {
            await navigate(ROUTES.ERROR);
          }
        } catch {
          await navigate(ROUTES.ERROR);
        }
      },
      errors => {
        console.log(errors);
      },
    )();
  });

  return {
    loading: accountParamsLoading || queryLoading,
    isEnrolled,
    isEligible,
    showConfirm,
    setShowConfirm,
    transactionType,
    setTransactionType,
    form,
    showNotificationPreferenceRequiredError: isMissingContactValue(),
    handleOnChangeEnrollOrCancel,
    handleOnClickManageNext,
    handleOnFormSubmit,
    isSubmitSuccessful,
    refetch,
  };
};
