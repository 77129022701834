import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import useUpdateDefaultAccount from '../../../hooks/useUpdateDefaultAccount';
import { useNicknameChangeContext } from '../../nickname-change-modal';
import { AccountDetail, PremiseInfo } from '../../../__generated__/pge-types';
import ROUTES from '../../../routes';
import { navigate } from '@reach/router';
import { useTranslation } from '../../../hooks/useTranslation';
import { toSentenceCase } from '../../../util/format';
import compact from 'lodash/compact';
import { MenuItem } from '../../menu/Menu';
import useSelectedGroupId from '../../../hooks/useSelectedGroupId';
import { useContext } from 'react';
import { featureFlagsContext } from '../../../providers/FeatureFlagsProvider';
import { NickNameAddressPayload } from '../../nickname-change-modal/types';

export type BillMenuType = 'VIEW' | 'PAY' | 'BOTH';

type Options = {
  billMenuType?: BillMenuType;
  onViewDetails?: (account: AccountDetail) => void;
  groupId?: string;
  showViewBill?: boolean;
  showViewBillHistory?: boolean;
  isClosedAccount?: boolean;
  isDisconnected?: boolean;
  isEligibleForReconnect?: boolean;
  accountsListCacheUpdate?: (payload: NickNameAddressPayload) => void;
};

export default function useAccountListItemMenuItems({
  billMenuType = 'PAY',
  onViewDetails: passedOnViewDetails,
  groupId,
  showViewBill,
  showViewBillHistory,
  isClosedAccount,
  isDisconnected,
  isEligibleForReconnect,
  accountsListCacheUpdate,
}: Options = {}) {
  const { setSelectedAccountParams } = useSelectedAccountParams({
    ignoreNoAccounts: true,
  });
  const { selectedGroupId } = useSelectedGroupId();
  const grpId = groupId || selectedGroupId!;
  const { isClosedAccountsEnabled } = useContext(featureFlagsContext);

  const { t } = useTranslation();

  const { updateDefaultAccount } = useUpdateDefaultAccount();

  const { setNicknamingPayload } = useNicknameChangeContext();

  const showPayMenu =
    (billMenuType === 'PAY' || billMenuType === 'BOTH') &&
    !isEligibleForReconnect;
  const showPayOptionMenu = isDisconnected && isEligibleForReconnect;
  const showViewMenu =
    billMenuType === 'VIEW' ||
    billMenuType === 'BOTH' ||
    showViewBill ||
    isDisconnected;

  const defaultOnViewDetails = async (acct: AccountDetail) => {
    await navigate(ROUTES.ACCOUNT);
    setSelectedAccountParams(grpId, acct);
    window.scrollTo(0, 0);
  };
  const onViewDetails = passedOnViewDetails || defaultOnViewDetails;

  const onNicknameAccount = (account: AccountDetail) =>
    setNicknamingPayload({
      type: 'ACCOUNT',
      value: account,
    });
  const onNicknameAddress = (
    premiseInfo: PremiseInfo,
    encryptedSaId: string,
    nickName: string,
    accountSaStatus: string,
  ) =>
    setNicknamingPayload({
      type: 'ADDRESS',
      value: {
        ...premiseInfo,
        encryptedSaId,
        nickName,
        accountSaStatus,
      },
      accountsListCacheUpdate,
    } as any);
  const onPayBill = showPayMenu
    ? (account: AccountDetail) => {
        setSelectedAccountParams(grpId, account);
        return navigate(ROUTES.PAYMENT);
      }
    : undefined;
  const onPayOption = showPayOptionMenu
    ? (account: AccountDetail) => {
        setSelectedAccountParams(grpId, account);
        return navigate(ROUTES.RECONNECT_SERVICE_START);
      }
    : undefined;
  const onViewBillingHistory =
    showViewBillHistory || isDisconnected
      ? (account: AccountDetail) => {
          if (isClosedAccountsEnabled) {
            !isClosedAccount && setSelectedAccountParams(grpId, account);
            return !isClosedAccount
              ? navigate(ROUTES.PAYMENT_HISTORY)
              : navigate(ROUTES.CLOSED_ACCOUNTS_HISTORY, {
                  state: { currentAccount: account },
                });
          } else {
            setSelectedAccountParams(grpId, account);
            return navigate(ROUTES.PAYMENT_HISTORY);
          }
        }
      : undefined;
  const onViewBill = showViewMenu
    ? (account: AccountDetail) => {
        setSelectedAccountParams(grpId, account);
        return navigate(ROUTES.VIEW_BILL);
      }
    : undefined;
  const onSetDefault = (account: AccountDetail) =>
    updateDefaultAccount(account);

  function makeMenuItems(account: AccountDetail) {
    const addresses = account.premiseInfo || [];
    const menuItems: Array<MenuItem<AccountDetail>> = compact([
      !isClosedAccount &&
        !isDisconnected && {
          text: toSentenceCase(t('VIEW_DETAILS')),
          onClick: onViewDetails,
        },
      !isClosedAccount &&
        !account.isDefault &&
        !isDisconnected &&
        onSetDefault && {
          text: toSentenceCase(t('MAKE_PRIMARY')),
          onClick: onSetDefault,
        },
      !isClosedAccount &&
        onPayBill && {
          text: toSentenceCase(t('PAY_BILL')),
          onClick: onPayBill,
        },
      !isClosedAccount &&
        onPayOption && {
          text: toSentenceCase(t('SEE_PAYMENT_OPTIONS')),
          onClick: onPayOption,
        },
      !isClosedAccount &&
        onViewBill && {
          text: toSentenceCase(t('VIEW_BILL')),
          onClick: onViewBill,
        },
      onViewBillingHistory && {
        text: toSentenceCase(t('BILLING_PAYMENT_HISTORY')),
        onClick: onViewBillingHistory,
      },
      !isClosedAccount &&
        !isDisconnected &&
        onNicknameAccount && {
          text: toSentenceCase(t('NICKNAME_ACCOUNT')),
          onClick: onNicknameAccount,
        },
      !isClosedAccount &&
        !isDisconnected &&
        onNicknameAddress &&
        addresses.length === 1 && {
          text: toSentenceCase(t('NICKNAME_ADDRESS')),
          onClick: (acct: AccountDetail) =>
            onNicknameAddress(
              acct.premiseInfo![0]!,
              acct.premiseInfo![0]!.saDetails![0]!.encryptedSAId,
              acct.premiseInfo![0]!.saDetails![0]!.nickName!,
              acct.premiseInfo![0]!.saDetails![0]!.saStatus,
            ),
        },
    ]);
    return menuItems;
  }

  return {
    makeMenuItems,
    onNicknameAddress,
    onViewDetails,
    onViewBillingHistory,
  };
}
