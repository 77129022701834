import gql from 'not-graphql-tag';

export const getTimeOfDayInfo = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      timeOfDayInfo {
        enrollmentStatus
        eligibility {
          isEligible
          ineligibilityType
        }
        timeOfDaySummaryInfo {
          accountId
          basicBillAmount
          todBillAmount
          deltaBasicToTodAmount
          numberOfCalculatedBills
          totalNumberOfBills
          timeofDaySummaryDetails {
            billDate
            basicBillAmount
            todBillAmount
          }
        }
      }
    }
  }
`;

export const getTimeOfDayEnrollmnentStatus = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      timeOfDayInfo {
        enrollmentStatus
      }
    }
  }
`;

export const submitTimeOfDayEnrollment = gql`
  mutation submitTimeOfDayEnrollment($payload: TimeOfDayEnrollmentInput!) {
    submitTimeOfDayEnrollment(payload: $payload) {
      referenceId
      isSuccess
      exceptions {
        type
        value
      }
    }
  }
`;

export const submitTimeOfDayUnenrollment = gql`
  mutation submitTimeOfDayUnenrollment($payload: TimeOfDayUnenrollmentInput!) {
    submitTimeOfDayUnenrollment(payload: $payload) {
      referenceId
      isSuccess
    }
  }
`;
