import React, { FunctionComponent } from 'react';
import { navigate } from '@reach/router';
import Page2ColumnLayout from '../utility/page2-column-layout/Page2ColumnLayout';
import { Box } from '@material-ui/core';
import LeftNavBlock from '../utility/left-nav-block';
import ROUTES from '../../routes';

const ServiceLayout: FunctionComponent = ({ children }) => {
  const leftMenuData = {
    label: 'Manage Service',
    menuItems: [
      {
        label: 'Start Service',
        url: ROUTES.START_SERVICE,
        onClick: async () =>
          navigate(ROUTES.START_SERVICE, { state: { reset: true } }),
      },
      {
        label: 'Move Service',
        url: ROUTES.MOVE_SERVICE,
        onClick: async () =>
          navigate(ROUTES.MOVE_SERVICE, { state: { reset: true } }),
      },
      {
        label: 'Stop Service',
        url: ROUTES.STOP_SERVICE,
        onClick: async () =>
          navigate(ROUTES.STOP_SERVICE, { state: { reset: true } }),
      },
    ],
  };

  const handleNavClick = async (url?: string) => {
    if (!!url) {
      await navigate(url);
    }
  };
  return (
    <Page2ColumnLayout
      leftColumn={
        <Box>
          <LeftNavBlock
            {...leftMenuData}
            onSelect={(_, url) => handleNavClick(url || undefined)}
          />
        </Box>
      }
    >
      {children}
    </Page2ColumnLayout>
  );
};

export default ServiceLayout;
