import React, { FunctionComponent } from 'react';

interface CancelServiceConfirmationProps {
  path?: string;
}

const CancelServiceConfirmation: FunctionComponent<CancelServiceConfirmationProps> = props => {
  return <></>;
};

export default CancelServiceConfirmation;
