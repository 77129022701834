import { createStyles, makeStyles, Theme } from '@material-ui/core';
import colors from '../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justtifyContent: 'flex-end',
    },
    copyBlock: {
      '& > h4': {
        marginTop: '0.875rem',
      },
      //Filter out MUI typography which already has the styling - catch raw paragraphs from richT
      '& > p:not(.MuiTypography-root)': {
        color: colors.noirBlur,
        fontSize: '1.125rem',
        fontWeight: 400,
      },
      '& p:first-child': {
        marginTop: '0.625rem',
      },
    },
    submitButton: {
      paddingLeft: 25,
      paddingRight: 25,
      marginLeft: 10,
      fontSize: 15,
      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
        width: '100%',
      },
    },
    textBold: {
      fontWeight: 'bold',
    },
    boxStyles: {
      display: 'flex',
      flexDirection: 'row-reverse',
      marginTop: '.5em',
    },
    select: {
      background: '#fff',
      width: '100%',
    },
    '.MuiSelect-select': {
      '&:focus': {
        background: '#fff',
      },
    },
    addPerson: {
      marginRight: '10px',
    },
  }),
);

export default useStyles;
