import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react';
import { navigate } from 'gatsby';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '../../hooks/useTranslation';
import { BaseFormModel } from '../../hooks/useFormState.types';
import { Paper } from '@material-ui/core';
import colors from '../../themes/main-colors';
import { NextBackOrCancel } from '../next-back-or-cancel';
import { MoveServiceCustomerFormModel } from './MoveServiceForm.rules';
import {
  PersonalIdentificationType,
  QuickAddressSearchParams,
  SuggestedAddress,
} from '../../__generated__/pge-types';
import ROUTES from '../../routes';
import { CustomerInfo } from '../customer-info';
import { CustomerMailingAddress } from '../customer-mailing-address';
import { AlternateAddress } from '../alternate-address';
import { CustomerIdentity } from '../customer-identity';
import { VerifyCustomerIdentity } from '../verify-customer-identity';
import useQas, { QasResult } from '../../hooks/useQas';
import { QasModal } from '../qas';
import { MoveServiceCoCustomer } from './co-customer';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import useIsPersonalIdValid from '../../hooks/useIsPersonalIdValid';
import { getPostalCountry } from '../../util/format';
import { isAtLeastEighteenYearsOfAge } from '../../util/form-validation';

type MoveServiceCustomerInfoProps = {
  serviceDetails: BaseFormModel;
  form: BaseFormModel;
  path: string;
  useAltMailingAddress: boolean;
  setUseAltMailingAddress: Dispatch<SetStateAction<boolean>>;
  primaryIdType?: PersonalIdentificationType;
  goToStep: (step: number) => Promise<void>;
  handleCustomerInfoSubmit: (
    values: MoveServiceCustomerFormModel,
  ) => Promise<void>;
  useAltPhone: boolean;
  setUseAltPhone: Dispatch<SetStateAction<boolean>>;
  coCustomerData: any;
  encryptedPersonId: string | undefined;
  accountNumber: string;
};

export const MoveServiceCustomerInfo = ({
  form,
  useAltMailingAddress,
  setUseAltMailingAddress,
  primaryIdType,
  goToStep,
  handleCustomerInfoSubmit,
  useAltPhone,
  setUseAltPhone,
  serviceDetails,
  coCustomerData,
  encryptedPersonId,
  accountNumber,
}: MoveServiceCustomerInfoProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const scrollToMailingAddressRef = useRef(false);
  const idErrorCount = useRef(0);
  const { search } = useQas();

  const { validateId } = useIsPersonalIdValid();

  const { wrapWithLoader } = useWrapWithLoader();

  const [qasData, setQasData] = useState<null | {
    params: QuickAddressSearchParams;
    results: QasResult;
  }>(null);

  const mailingAddressSectionId = 'service-move-mailing-address-section';

  const coCustomerCount = useMemo(
    () => Object.keys(coCustomerData || {}).length,
    [coCustomerData],
  );

  useEffect(() => {
    return () => {
      idErrorCount.current = 0;
    };
  }, []);

  useEffect(() => {
    if (scrollToMailingAddressRef.current) {
      scrollToMailingAddressRef.current = false;
      document.getElementById(mailingAddressSectionId)?.scrollIntoView();
    }
  }, [scrollToMailingAddressRef.current]);

  return (
    <>
      <Paper style={{ padding: theme.spacing(2), color: colors.noirBlur }}>
        <Typography variant="h2">
          {t('PLEASE_CONFIRM_YOUR_INFORMATION')}
        </Typography>
        {primaryIdType &&
        [
          PersonalIdentificationType.Dl,
          PersonalIdentificationType.Ssn,
          PersonalIdentificationType.Ortrib,
          PersonalIdentificationType.Visa,
          PersonalIdentificationType.Military,
          PersonalIdentificationType.Resalien,
          PersonalIdentificationType.Pssprt,
          PersonalIdentificationType.Itin,
        ].includes(primaryIdType) &&
        primaryIdType !== PersonalIdentificationType.None ? (
          <VerifyCustomerIdentity
            form={form}
            primaryIdType={primaryIdType}
            showInstructionText={false}
          />
        ) : (
          <CustomerIdentity form={form} />
        )}

        <div
          style={{
            borderTop: `1px solid ${colors.lightGray1}`,
            marginTop: theme.spacing(2),
            paddingTop: theme.spacing(2),
          }}
        >
          <CustomerInfo
            form={form}
            headerText={t('CONTACT_DETAILS')}
            nameError={''}
            useAltPhone={useAltPhone}
            setUseAltPhone={setUseAltPhone}
            showNameFields={false}
          />

          <Typography variant="h2" style={{ marginTop: theme.spacing(3) }}>
            {t('MAILING_ADDRESS')}
          </Typography>

          <CustomerMailingAddress
            id={mailingAddressSectionId}
            useAltMailingAddress={useAltMailingAddress}
            setUseAltMailingAddress={setUseAltMailingAddress}
            sameAddressText={
              <p style={{ display: 'block' }}>
                {t('SAME_AS_SERVICE_ADDRESS')}:{' '}
                <strong>
                  {[
                    serviceDetails?.values?.address || '',
                    ', ',
                    serviceDetails?.values?.city || '',
                    ' ',
                    serviceDetails?.values?.zip || '',
                  ].join('')}
                </strong>
              </p>
            }
          />

          {useAltMailingAddress && <AlternateAddress form={form} />}
        </div>
      </Paper>

      {coCustomerCount > 0 && (
        <MoveServiceCoCustomer
          coCustomerCount={coCustomerCount}
          coCustomerData={coCustomerData}
          form={form}
        />
      )}

      <div style={{ margin: 25 }}>
        <NextBackOrCancel
          backHandler={() => goToStep(1)}
          cancelRoute={ROUTES.ACCOUNT}
          nextDisabled={false}
          nextHandler={wrapWithLoader(
            form.submit(async (data: MoveServiceCustomerFormModel) => {
              if (
                data.birthdate &&
                !isAtLeastEighteenYearsOfAge(data.birthdate)
              ) {
                return navigate(ROUTES.START_SERVICE_ASSISTANCE);
              }
              if (useAltMailingAddress) {
                const params: QuickAddressSearchParams = {
                  city: data.mailCity,
                  state: data.mailState,
                  addressLine1: data.mailAddress,
                  postal: data.mailZip,
                  isMailingAddress: true,
                  country: getPostalCountry(data.mailZip),
                };

                const results = await search(params);
                return setQasData({ results, params });
              }

              if (
                primaryIdType &&
                primaryIdType !== PersonalIdentificationType.None &&
                encryptedPersonId
              ) {
                const isValid = await validateId({
                  encryptedPersonId,
                  idNumber: data.idValue,
                });

                if (!isValid) {
                  idErrorCount.current += 1;
                  if (idErrorCount.current < 2) {
                    return form.setError('idValue', t('ID_INVALID'));
                  }

                  return navigate(ROUTES.MOVE_SERVICE_ASSISTANCE);
                }
              }

              if (
                coCustomerCount > 0 &&
                data.selectedCoCustomers.length !== coCustomerCount
              ) {
                return navigate(ROUTES.MOVE_SERVICE_ASSISTANCE);
              }

              return handleCustomerInfoSubmit(data);
            }),
          )}
        />
      </div>
      {qasData && (
        <QasModal
          results={qasData.results}
          onChange={async (address: SuggestedAddress) => {
            setQasData(null);
            await Promise.all([
              form.setValue('mailCity', address.city),
              form.setValue('mailState', address.state),
              form.setValue('mailAddress', address.addressLine1),
              form.setValue('mailZip', address.postal),
              form.setValue('mailQasVerified', true),
            ]);
            return wrapWithLoader(form.submit(handleCustomerInfoSubmit))();
          }}
          address={qasData.params}
          onSearch={(params, results) => setQasData({ params, results })}
          onEdit={() => {
            scrollToMailingAddressRef.current = true;
            setQasData(null);
          }}
          onUseEntered={async () => {
            await form.setValue('mailQasVerified', false);

            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            wrapWithLoader(form.submit(handleCustomerInfoSubmit))();
          }}
        />
      )}
    </>
  );
};
