import {
  Box,
  Card,
  CardContent,
  CardMedia,
  MobileStepper,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import colors from '../../themes/main-colors';
import theme from '../../themes/theme';
import { useIsMobile } from '../../util/style-utils';
import PGEButton from '../buttons';

const useStyles = (isMobile?: boolean, activePaneBackgroundColor?: string) =>
  makeStyles(() => {
    const heading = {
      fontSize: theme.spacing(3),
      lineHeight: theme.typography.pxToRem(28),
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
      color: colors.noirBlur,
    };

    return createStyles({
      container: {
        backgroundColor: colors.white,
        padding: theme.spacing(1),
      },
      hideArrow: {
        visibility: 'hidden',
      },
      header: {
        ...heading,
        marginBottom: theme.spacing(1),
        fontWeight: 'bold',
      },

      cardContent: {
        padding: 0,
        '&:last-child': {
          padding: 0,
        },
      },
      carouselContainer: {
        display: 'flex',
        height: isMobile ? '350px' : theme.spacing(39),
        [theme.breakpoints.up('sm')]: {
          width: '100%',
        },
      },
      panesContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: isMobile ? '350px' : '312px',
      },
      pane: {
        backgroundColor: activePaneBackgroundColor ?? colors.riverBlue,
        height: '100%',
        padding: '16px 8px 8px 8px',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
        },
      },
      paneImgContainer: {
        width: '30%',
        flexShrink: 0,
      },
      paneImg: {
        width: '100%',
        borderRadius: theme.spacing(0.5),
        height: '100%',
      },
      paneDetailsContainer: {
        marginLeft: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
          marginLeft: 'unset',
          height: 'inherit',
        },
      },
      paneHeader: {
        ...heading,
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
      },
      paneSubHeader: {
        ...heading,
        fontWeight: 'bold',
        fontSize: theme.spacing(2),
        marginBottom: theme.spacing(1),
      },
      paneBodyContainer: {
        marginBottom: theme.spacing(1),
      },
      ctaContainer: {
        textAlign: 'right',
        marginTop: 'auto',
      },
      dotsContainer: {
        padding: 0,
        paddingBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: isMobile ? 'space-between' : 'center',
        backgroundColor: activePaneBackgroundColor ?? colors.riverBlue,
        alignItems: 'center',
        background: 'transparent',
        '& .MuiMobileStepper-dot': {
          width: theme.spacing(3),
          background: colors.darkGray3,
          borderRadius: 'unset',
        },
        '& .MuiMobileStepper-dots': { width: '100%', justifyContent: 'center' },
        '& .MuiMobileStepper-dotActive': {
          background: colors.vibrantOrange,
        },
        [theme.breakpoints.down('sm')]: {
          paddingBottom: theme.spacing(0),
          paddingTop: theme.spacing(1),
        },
      },
      arrowStyleMobile: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height: isMobile ? '' : '328px',
        backgroundColor: colors.blackSqueeze,
        [theme.breakpoints.down('sm')]: {
          marginRight: 'auto',
        },
      },
      arrowStyles: {
        display: 'flex',
        alignItems: 'center',
      },
      leftArrow: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height: isMobile ? '' : '328px',
        backgroundColor: colors.blackSqueeze,
        [theme.breakpoints.down('sm')]: {
          marginLeft: 'auto',
        },
      },
      rightArrow: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height: isMobile ? '' : '328px',
        backgroundColor: colors.blackSqueeze,
        [theme.breakpoints.down('sm')]: {
          marginRight: 'auto',
        },
      },
    });
  });

type TCarousel = {
  panes: {
    header: string;
    subHeader: string;
    image: string;
    mobileImage?: string;
    body: React.ReactNode;
    ctaText?: string;
    ctaLink?: string;
    backgroundColor?: string;
  }[];
};

export function Carousel(props: TCarousel) {
  const { panes } = props;
  const [activeStep, setActiveStep] = useState(0);
  const isMobile = useIsMobile();
  const classes = useStyles(isMobile, panes[activeStep].backgroundColor)();

  const handlePrevious = () => {
    if (activeStep === 0) {
      return;
    }
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (activeStep === panes.length - 1) {
      return;
    }
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const getButtonMobile = (direction: 'previous' | 'next') => {
    return (
      <Box className={classes.arrowStyles}>
        <KeyboardArrowLeftIcon
          className={
            !(direction === 'previous' && activeStep > 0)
              ? classes.hideArrow
              : classes.arrowStyleMobile
          }
          onClick={direction === 'previous' ? handlePrevious : handleNext}
        />
        <KeyboardArrowRightIcon
          className={
            !(direction === 'next' && activeStep !== (panes?.length || 1) - 1)
              ? classes.hideArrow
              : classes.arrowStyleMobile
          }
          onClick={direction === 'previous' ? handlePrevious : handleNext}
        />
      </Box>
    );
  };

  const getButton = (direction: 'previous' | 'next') => {
    if (direction === 'previous' && activeStep > 0) {
      return (
        <Box className={classes.leftArrow} onClick={handlePrevious}>
          <KeyboardArrowLeftIcon />
        </Box>
      );
    }

    if (direction === 'next' && activeStep !== (panes?.length || 1) - 1) {
      return (
        <Box className={classes.rightArrow} onClick={handleNext}>
          <KeyboardArrowRightIcon />
        </Box>
      );
    }
  };

  return (
    <Card>
      {isMobile && (
        <CardMedia
          component="img"
          image={panes[activeStep].mobileImage ?? panes[activeStep].image}
          height={240}
        />
      )}
      <CardContent className={classes.cardContent}>
        {isMobile && (panes?.length ?? 0) > 1 && (
          <MobileStepper
            className={classes.dotsContainer}
            variant="dots"
            steps={panes?.length || 1}
            position="static"
            activeStep={activeStep}
            backButton={getButtonMobile('previous')}
            nextButton={getButtonMobile('next')}
          />
        )}
        <Box className={classes.carouselContainer}>
          {!isMobile && getButton('previous')}
          <Box className={classes.panesContainer}>
            <Box className={classes.pane}>
              {!isMobile && (
                <Box className={classes.paneImgContainer}>
                  <img
                    src={panes[activeStep].image}
                    className={classes.paneImg}
                  />
                </Box>
              )}
              <Box className={classes.paneDetailsContainer}>
                <Typography className={classes.paneHeader}>
                  {panes[activeStep].header}
                </Typography>
                <Typography className={classes.paneSubHeader}>
                  {panes[activeStep].subHeader}
                </Typography>
                <Box className={classes.paneBodyContainer}>
                  {panes[activeStep].body}
                </Box>
                {panes[activeStep].ctaText && panes[activeStep].ctaLink && (
                  <Box className={classes.ctaContainer}>
                    <PGEButton
                      onClick={() =>
                        void navigate(panes[activeStep].ctaLink ?? '')
                      }
                      fullWidth={isMobile}
                    >
                      {panes[activeStep].ctaText}
                    </PGEButton>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          {!isMobile && getButton('next')}
        </Box>
        {!isMobile && (panes?.length ?? 0) > 1 && (
          <MobileStepper
            className={classes.dotsContainer}
            variant="dots"
            steps={panes?.length || 1}
            position="static"
            activeStep={activeStep}
            backButton={<></>}
            nextButton={<></>}
          />
        )}
      </CardContent>
    </Card>
  );
}
