import { Dialog, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useStyles } from './styles';
import { useTranslation } from '../../../hooks/useTranslation';
import PGEButton from '../../buttons/PGE-Button';
import { useIsMobile } from '../../../util/style-utils';

type Props = {
  cancel: () => void;
  confirm: () => void;
};

export default function AlertsWarningDialog({ cancel, confirm }: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <div
      className={isMobile ? classes.alertsWarningMob : classes.alertsWarning}
    >
      <div>
        <Typography variant="h2">{t('UNSAVED_CHANGES')}</Typography>
        <Typography className={classes.unsavedContent}>
          {t('UNSAVED_CHANGES_CONTENT')}
        </Typography>
      </div>
      <Grid item xs={12} className={classes.alertButtonContainer}>
        <PGEButton
          onClick={cancel}
          variant={'outlined'}
          className={classes.close}
        >
          {t('CLOSE')}
        </PGEButton>
        <PGEButton onClick={confirm} className={classes.save}>
          {t('SAVE')}
        </PGEButton>
      </Grid>
    </div>
  );
}
