import { makeStyles, Theme } from '@material-ui/core';
import colors from '../../themes/main-colors';

export default makeStyles((theme: Theme) => ({
  paymentItemContainer: {
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minHeight: '3.5em',
    color: colors.noirBlur,
    '& .PrimaryText': {
      flex: '1 0 auto',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '1em',
      alignItems: 'flex-start',
      gap: '1em',
    },
    '& .primary': {
      color: colors.noirBlur,
      '& span': {
        whiteSpace: 'normal',
      },
    },
    '& .secondary': {
      color: colors.noirBlur,
      marginRight: theme.spacing(8),
    },
  },
  groupContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  groupSelector: {
    flexGrow: 1,
  },
}));
