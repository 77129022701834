import {
  validateFullSSN,
  validateFullITIN,
  validateRequiredAndValidCharacters,
  validateSSN,
  validateSSNCoCustomer,
  validateITIN,
  validateLastFourDigits,
  validateLastFourDigitsCoCustomer,
  validateStateIdOrDiversLicense,
} from './form-validation';
import {
  PersonalIdentificationType,
  StateIdType,
  FederalIdentificationType,
} from '../__generated__/pge-types';
import { useTranslation } from '../hooks/useTranslation';

const { t } = useTranslation();

interface ValidationMap<TValue> {
  [id: string]: TValue | null;
}

export const idTypeValidatorsUnauthenticated: ValidationMap<(
  value?: any,
) => any> = {
  [PersonalIdentificationType.Ssn]: () => validateFullSSN,
  [PersonalIdentificationType.Itin]: () => validateFullITIN,
};

export const idTypeValidatorsAuthenticated: ValidationMap<(
  value?: any,
) => any> = {
  [PersonalIdentificationType.Ssn]: (isCoCustomer: boolean = false) =>
    isCoCustomer ? validateSSNCoCustomer : validateSSN,
  [PersonalIdentificationType.Itin]: () => validateITIN,
};

export const idSubTypeValidatorsUnauthenticated: ValidationMap<(
  value?: any,
) => any> = {
  [StateIdType.DriverLicense]: () =>
    validateStateIdOrDiversLicense.bind(null, t('DRIVERS_LICENSE')),
  [StateIdType.StateIdCard]: () =>
    validateStateIdOrDiversLicense.bind(null, t('STATE_ID_CARD')),
  [PersonalIdentificationType.Dl]: () =>
    validateStateIdOrDiversLicense.bind(null, t('DRIVERS_LICENSE')),
  [PersonalIdentificationType.Ortrib]: () =>
    validateRequiredAndValidCharacters.bind(null, t('OREGON_TRIBAL_ID')),
  [FederalIdentificationType.OregonTribalId]: () =>
    validateRequiredAndValidCharacters.bind(null, t('OREGON_TRIBAL_ID')),
  [PersonalIdentificationType.Visa]: () =>
    validateRequiredAndValidCharacters.bind(null, t('VISA_ID')),
  [FederalIdentificationType.UsStudentVisa]: () =>
    validateRequiredAndValidCharacters.bind(null, t('US_STUDENT_VISA_ID')),
  [FederalIdentificationType.UsTemporaryVisa]: () =>
    validateRequiredAndValidCharacters.bind(null, t('US_TEMPORARY_VISA_ID')),
  [PersonalIdentificationType.Military]: () =>
    validateRequiredAndValidCharacters.bind(null, t('MILITARY_ID')),
  [FederalIdentificationType.UsMilitaryId]: () =>
    validateRequiredAndValidCharacters.bind(null, t('MILITARY_ID')),
  [PersonalIdentificationType.Pssprt]: () =>
    validateRequiredAndValidCharacters.bind(null, t('PASSPORT')),
  [FederalIdentificationType.UsPassport]: () =>
    validateRequiredAndValidCharacters.bind(null, t('PASSPORT')),
  [PersonalIdentificationType.Resalien]: () =>
    validateRequiredAndValidCharacters.bind(null, t('RESIDENT_ALIEN_ID')),
  [FederalIdentificationType.UsImmigration]: () =>
    validateRequiredAndValidCharacters.bind(null, t('RESIDENT_ALIEN_ID')),
};

export const idSubTypeValidatorsAuthenticated: ValidationMap<(
  value?: any,
) => any> = {
  [StateIdType.DriverLicense]: (isCoCustomer: boolean) =>
    isCoCustomer
      ? validateLastFourDigitsCoCustomer.bind(null, t('DL_OR_STATE_ID'))
      : validateLastFourDigits.bind(null, t('DL_OR_STATE_ID')),
  [StateIdType.StateIdCard]: () =>
    validateRequiredAndValidCharacters.bind(null, t('STATE_ID_CARD')),
  [PersonalIdentificationType.Dl]: (isCoCustomer: boolean) =>
    isCoCustomer
      ? validateLastFourDigitsCoCustomer.bind(null, t('DL_OR_STATE_ID'))
      : validateLastFourDigits.bind(null, t('DL_OR_STATE_ID')),
  [PersonalIdentificationType.Ortrib]: (isCoCustomer: boolean) =>
    isCoCustomer
      ? validateLastFourDigitsCoCustomer.bind(null, t('OREGON_TRIBAL_ID'))
      : validateLastFourDigits.bind(null, t('OREGON_TRIBAL_ID')),
  [FederalIdentificationType.OregonTribalId]: (isCoCustomer: boolean) =>
    isCoCustomer
      ? validateLastFourDigitsCoCustomer.bind(null, t('OREGON_TRIBAL_ID'))
      : validateLastFourDigits.bind(null, t('OREGON_TRIBAL_ID')),
  [PersonalIdentificationType.Visa]: (isCoCustomer: boolean) =>
    isCoCustomer
      ? validateLastFourDigitsCoCustomer.bind(null, t('VISA_ID_TEMP_STUDENT'))
      : validateLastFourDigits.bind(null, t('VISA_ID_TEMP_STUDENT')),
  [FederalIdentificationType.UsStudentVisa]: (isCoCustomer: boolean) =>
    isCoCustomer
      ? validateLastFourDigitsCoCustomer.bind(null, t('VISA_ID_TEMP_STUDENT'))
      : validateLastFourDigits.bind(null, t('VISA_ID_TEMP_STUDENT')),
  [FederalIdentificationType.UsTemporaryVisa]: (isCoCustomer: boolean) =>
    isCoCustomer
      ? validateLastFourDigitsCoCustomer.bind(null, t('VISA_ID_TEMP_STUDENT'))
      : validateLastFourDigits.bind(null, t('VISA_ID_TEMP_STUDENT')),
  [PersonalIdentificationType.Military]: (isCoCustomer: boolean) =>
    isCoCustomer
      ? validateLastFourDigitsCoCustomer.bind(null, t('MILITARY_ID'))
      : validateLastFourDigits.bind(null, t('MILITARY_ID')),
  [FederalIdentificationType.UsMilitaryId]: (isCoCustomer: boolean) =>
    isCoCustomer
      ? validateLastFourDigitsCoCustomer.bind(null, t('MILITARY_ID'))
      : validateLastFourDigits.bind(null, t('MILITARY_ID')),
  [PersonalIdentificationType.Pssprt]: (isCoCustomer: boolean) =>
    isCoCustomer
      ? validateLastFourDigitsCoCustomer.bind(null, t('PASSPORT'))
      : validateLastFourDigits.bind(null, t('PASSPORT')),
  [FederalIdentificationType.UsPassport]: (isCoCustomer: boolean) =>
    isCoCustomer
      ? validateLastFourDigitsCoCustomer.bind(null, t('PASSPORT'))
      : validateLastFourDigits.bind(null, t('PASSPORT')),
  [PersonalIdentificationType.Resalien]: (isCoCustomer: boolean) =>
    isCoCustomer
      ? validateLastFourDigitsCoCustomer.bind(null, t('RESIDENT_ALIEN_ID'))
      : validateLastFourDigits.bind(null, t('RESIDENT_ALIEN_ID')),
  [FederalIdentificationType.UsImmigration]: (isCoCustomer: boolean) =>
    isCoCustomer
      ? validateLastFourDigitsCoCustomer.bind(null, t('RESIDENT_ALIEN_ID'))
      : validateLastFourDigits.bind(null, t('RESIDENT_ALIEN_ID')),
};

export const validateIdValue = (
  idType: string,
  idSubType: string,
  isAuthenticated: boolean,
  primaryIdType: any,
  isCoCustomer: boolean = false,
) => {
  const hasCorrectPrimaryIdType =
    Boolean(primaryIdType) &&
    [
      PersonalIdentificationType.Dl,
      PersonalIdentificationType.Ssn,
      PersonalIdentificationType.Ortrib,
      PersonalIdentificationType.Visa,
      PersonalIdentificationType.Military,
      PersonalIdentificationType.Resalien,
      PersonalIdentificationType.Itin,
      PersonalIdentificationType.Pssprt,
    ].includes(primaryIdType);
  if (
    !isAuthenticated ||
    !hasCorrectPrimaryIdType ||
    (isAuthenticated && !hasCorrectPrimaryIdType)
  ) {
    if (idType !== 'stateId' && idType !== 'federalId') {
      return idTypeValidatorsUnauthenticated[idType]?.(isCoCustomer);
    }

    if (idType === 'stateId' || idType === 'federalId') {
      return idSubTypeValidatorsUnauthenticated[idSubType]?.(isCoCustomer);
    }
  } else if (isAuthenticated && hasCorrectPrimaryIdType) {
    if (idType !== 'stateId' && idType !== 'federalId') {
      return idTypeValidatorsAuthenticated[idType]?.(isCoCustomer);
    }

    if (idType === 'stateId' || idType === 'federalId') {
      return idSubTypeValidatorsAuthenticated[idSubType]?.(isCoCustomer);
    }
  }

  return null;
};
