import { useContext, useState } from 'react';
import { AccountType } from '../../__generated__/pge-types';
import useGetAccountType from '../../hooks/useGetAccountType';
import { LanguageContext } from '../../providers/LanguageProvider';
import DashboardInterceptQuery from './dashboardIntercept.query';
import storage from './storage-utils';
import { resetDashboardIntercept } from './storage-utils/resetDashboardIntercept';

enum CUSTOMER_CLASS {
  ALL = 'All',
  COM = 'Commercial Only',
  RES = 'Residential Only',
}

export const useAccountDashboardIntercept = (
  isSecureSiteModalEnabled: boolean,
) => {
  if (isSecureSiteModalEnabled === false) {
    return { pages: undefined, open: false, setOpen: undefined };
  }
  resetDashboardIntercept();
  const { language } = useContext(LanguageContext);
  const { accountTypeData } = useGetAccountType({});
  const { allContentfulSecureSiteModal } = DashboardInterceptQuery();

  const contentfulSecureSiteModal = allContentfulSecureSiteModal.nodes.filter(
    node => node.node_locale === language,
  )[0];

  const accountType =
    accountTypeData &&
    accountTypeData.getAccountDetails &&
    accountTypeData?.getAccountDetails?.[0]?.accountType;
  const isResModal =
    accountType === AccountType?.Res &&
    contentfulSecureSiteModal?.customerClass === CUSTOMER_CLASS.RES;
  const isComModal =
    accountType === AccountType?.Com &&
    contentfulSecureSiteModal?.customerClass === CUSTOMER_CLASS.COM;
  const isModalForAll =
    contentfulSecureSiteModal?.customerClass === CUSTOMER_CLASS.ALL;

  const modalIntialState =
    !storage.localStorageKeysExists() &&
    (isResModal || isComModal || isModalForAll);

  const [open, setOpen] = useState(modalIntialState);

  return {
    pages: contentfulSecureSiteModal?.pages,
    open,
    setOpen,
  };
};
