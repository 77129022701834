import React, { FC } from 'react';
import { Typography, Box } from '@material-ui/core';
import Button from '../buttons';
import useStyles from './styles';
import AccountDropdown from '../account-dropdown';
import { useTranslation } from '../../hooks/useTranslation';
import PaperBox from '../utility/paper-box';

interface Props {
  path?: string;
  handleOnClickManageNext: () => Promise<void>;
}

const EvRebates: FC<Props> = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleOnClickManageNext } = props;
  return (
    <>
      <Box mt={2} mb={1}>
        <AccountDropdown />
      </Box>
      <PaperBox>
        <Typography
          className={classes.colorTextPrimary}
          variant={'h4'}
          component="div"
        >
          {t('CLICK_TO_BEGIN_OR_COMPLETE_ENROLLMENT')}
        </Typography>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            marginTop: '.5em',
          }}
        >
          <Button
            variant={'contained'}
            color={'primary'}
            className={classes.submitButton}
            onClick={handleOnClickManageNext}
          >
            {t('NEXT')}
          </Button>
        </Box>
      </PaperBox>
    </>
  );
};

export default EvRebates;
