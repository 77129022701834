import React, { useContext, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Delete from '@material-ui/icons/DeleteOutline';
import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import Box from '@material-ui/core/Box';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Modal from '../modal';
import PGEButton from '../buttons';
import { auth } from '../../firebase';
import { getEnrolledFactors, unEnrollUser } from './firebase.helper';
import { FirebaseError } from 'firebase/app';
import { AuthErrorCodes } from 'firebase/auth';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import Backdrop from '../backdrop';

type Props = {
  enrolledPhoneNumber: string;
  refreshUser: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(2),
    },
    cardContent: {
      paddingBottom: '16px !important',
    },
    phoneInfo: {
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        marginTop: theme.spacing(2),
      },
    },
    deleteMFA: {
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        marginTop: theme.spacing(2),
      },
    },
    body: {
      maxWidth: 600,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      '& > *': {
        marginTop: theme.spacing(1),
      },
    },
    cancelButton: {
      border: 'none !important',
      marginRight: theme.spacing(1),
    },
    otpContainer: {
      marginTop: theme.spacing(2),
    },
    pointer: {
      cursor: 'pointer',
    },
  }),
);

const EnrolledFactors = ({ enrolledPhoneNumber, refreshUser }: Props) => {
  const { t } = useTranslation();
  const [deleteConfirmation, setDeleteConfirmation] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const notificationContext = useContext(NotificationsContext);

  const classes = useStyles();

  const unenroll = async () => {
    setDeleteConfirmation(false);

    if (auth && auth.currentUser) {
      const mfa = getEnrolledFactors(auth.currentUser);
      if (mfa[0].factorId === 'phone') {
        setIsLoading(true);

        try {
          await unEnrollUser(auth.currentUser, mfa[0].uid);
          refreshUser();
          showNotification(t('TWO_STEP_AUTH_DELETE_CONFIRMATION'));
          setIsLoading(false);
        } catch (e) {
          setIsLoading(false);
          if (
            (e as FirebaseError)?.code ===
            AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN
          ) {
            showNotification(
              t('TWO_STEP_AUTH_CREDENTIAL_TOO_OLD_LOGIN_AGAIN_ERROR'),
              'error',
            );
          } else {
            showNotification(
              t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
              'error',
            );
          }
        }
      }
    }
  };

  const showNotification = (
    message: string,
    type: 'error' | 'success' = 'success',
  ) => {
    notificationContext.setState({
      isOpen: true,
      message: message,
      severity: type,
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      {isLoading ? <Backdrop forceOpen message={t('LOADING')} /> : null}
      <Card className={classes.container}>
        <CardContent className={classes.cardContent}>
          <Grid container sx={{ px: 2, py: 1 }}>
            <Grid item xs={12} md={3}>
              <Typography
                variant={'h6'}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <CheckCircle
                  htmlColor={colors.shoreGreen}
                  fontSize="small"
                  style={{ marginRight: '8px' }}
                />{' '}
                {t('ENABLED')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} className={classes.phoneInfo}>
              <Typography variant={'h6'}>
                {t('TWO_STEP_AUTH_ENROLLED_FACTOR_TEXT_MESSAGE')} - +1XXXXXX
                {enrolledPhoneNumber.slice(-4)}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3} className={classes.deleteMFA}>
              <Typography variant={'h3'}>
                <Delete
                  htmlColor={colors.midGrey}
                  onClick={() => setDeleteConfirmation(true)}
                  className={classes.pointer}
                  aria-label="delete-icon-btn"
                  role="button"
                />
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Modal
        open={deleteConfirmation}
        showCloseIcon={false}
        title={
          <Typography variant="h2">
            {t('TWO_STEP_AUTH_DELETE_MODAL_TITLE')}
          </Typography>
        }
      >
        <Box className={classes.body}>
          <Typography>{t('TWO_STEP_AUTH_DELETE_MODAL_DESCRIPTION')}</Typography>
        </Box>
        <Box justifyContent="flex-end" display="flex">
          <PGEButton
            className={classes.cancelButton}
            color={'secondary'}
            variant={'outlined'}
            onClick={() => setDeleteConfirmation(false)}
          >
            {t('CANCEL')}
          </PGEButton>
          <PGEButton
            color={'primary'}
            variant={'contained'}
            onClick={unenroll}
            aria-label="delete-btn"
          >
            {t('DELETE')}
          </PGEButton>
        </Box>
      </Modal>
    </>
  );
};

export default EnrolledFactors;
