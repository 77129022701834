import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';
import colors from '../../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(3),
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    containerBgChromeWhite: {
      background: colors.chromeWhite,
    },
    containerBgRiverBlue: {
      background: colors.riverBlue,
    },
    compactContainer: {
      [theme.breakpoints.down('sm')]: {
        alignItems: 'center',
      },
    },
    fullContainer: {
      [theme.breakpoints.up('sm')]: {
        columnGap: `${theme.spacing(1)}px`,
      },
      [theme.breakpoints.down('sm')]: {
        rowGap: `${theme.spacing(3)}px`,
      },
    },
    title: {
      fontSize: theme.spacing(1.75),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
      marginBottom: theme.spacing(2),
    },
    cta: {
      display: 'block',
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    sectionA: {
      fontSize: theme.spacing(1.75),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      rowGap: `${theme.spacing(1)}px`,
    },
    sectionB: {
      borderRadius: theme.spacing(0.5),
      width: theme.spacing(35),
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        margin: '0 auto',
      },
    },

    marginBottomUnset: {
      marginBottom: 'unset',
    },
  }),
);

type TCardData = {
  theme: 'blue' | 'green';
  type: 'full' | 'compact';
  title: React.ReactNode;
  sectionA?: React.ReactNode;
  sectionB?: React.ReactNode;
  ctaText?: string;
  ctaLink?: string;
  isCtaExternal?: boolean;
};

export function Card(props: TCardData) {
  const {
    theme,
    type,
    title,
    sectionA,
    sectionB,
    ctaText,
    ctaLink,
    isCtaExternal,
  } = props;
  const classes = useStyles();

  const containerBg = {
    class: classes.containerBgRiverBlue,
  };

  if (theme === 'green') {
    containerBg.class = classes.containerBgChromeWhite;
  }

  if (type === 'compact') {
    return (
      <Box
        className={`${classes.compactContainer} ${classes.container} ${containerBg.class}`}
      >
        <Typography className={`${classes.title} ${classes.marginBottomUnset}`}>
          {title}
        </Typography>
        {isCtaExternal && (
          <a href={ctaLink} target="_blank">
            {ctaText}
          </a>
        )}
        {!isCtaExternal && ctaLink && (
          <Link className={classes.cta} to={ctaLink}>
            {ctaText}
          </Link>
        )}
      </Box>
    );
  }

  return (
    <Box
      className={`${classes.fullContainer} ${classes.container} ${containerBg.class}`}
    >
      {sectionA && (
        <Box className={classes.sectionA}>
          <Box>
            <Typography className={classes.title}>{title}</Typography>
            <Box>{sectionA}</Box>
          </Box>
          {isCtaExternal && ctaLink && (
            <a href={ctaLink} target="_blank">
              {ctaText}
            </a>
          )}
          {!isCtaExternal && ctaLink && (
            <Link className={classes.cta} to={ctaLink}>
              {ctaText}
            </Link>
          )}
        </Box>
      )}
      {sectionB && <Box className={classes.sectionB}>{sectionB}</Box>}
    </Box>
  );
}
