/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import { GridContainer } from '../../../../pge-plus-common/Grid';
import ChargerInstructions from './ChargerInstructions';
import routes from '../../../../../routes';
import CheckHigherRebatesEligibility from './CheckHigherRebatesEligibility';
import EligibleForRebates from './EligibleForRebates';
import EligibleForEvPulse from './EligibleForEvPulse';
import ChargerInformation from './ChargerInformation';
import ChargerAndInstallationCosts from './ChargerAndInstallationCosts';
import ConnectChargerToWifi from './ConnectChargerToWifi';
import ConnectTeslaToEvPulseApp from './ConnectTeslaToEvPulseApp';
import EligibilityConfirmation from './EligibilityConfirmation';
import RebateResults from './RebateResults';
import ChargerRebateResults from './ChargerRebateResults';
import EVPulseRebateResults from './EVPulseRebateResults';

const RebateEligibility: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  return (
    <GridContainer>
      <Router basepath="/">
        <CheckHigherRebatesEligibility
          path={routes.PGE_PLUS_REBATE_ONLY_HIGHER_REBATES_CHECK}
        />
        <EligibleForRebates
          path={routes.PGE_PLUS_REBATE_ONLY_HIGHER_REBATES_ELIGIBILE}
        />
        <EligibleForRebates
          path={routes.PGE_PLUS_REBATE_ONLY_STANDARD_REBATES_ELIGIBILE}
        />
        <EligibleForEvPulse
          path={routes.PGE_PLUS_REBATE_ONLY_EVPULSE_ELIGIBLE}
        />
        <ChargerInformation
          path={routes.PGE_PLUS_REBATE_ONLY_CHARGER_INFORMATION}
        />
        <ChargerAndInstallationCosts
          path={routes.PGE_PLUS_REBATE_ONLY_CHARGER_INSTALLATION_COSTS}
        />
        <ConnectChargerToWifi
          path={routes.PGE_PLUS_REBATE_ONLY_CONNECT_CHARGER}
        />
        <ConnectTeslaToEvPulseApp
          path={routes.PGE_PLUS_REBATE_ONLY_CONNECT_TESLA}
        />
        <EligibilityConfirmation
          path={routes.PGE_PLUS_REBATE_ONLY_ELIGIBILITY_CONFIRMATION}
        />
        <ChargerRebateResults
          path={routes.PGE_PLUS_REBATE_ONLY_REBATE_RESULTS}
        />
        <EVPulseRebateResults
          path={routes.PGE_PLUS_REBATE_ONLY_EV_PULSE_REBATE_RESULTS}
        />
        <ChargerInstructions
          path={routes.PGE_PLUS_REBATE_ONLY_CHARGER_INSTRUCTIONS}
        />
      </Router>
    </GridContainer>
  );
};

export default RebateEligibility;
