import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from '../../hooks/useTranslation';
import { useLocation, navigate } from '@reach/router';
import { AccountAmounts } from './types';
import ROUTES from '../../routes';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useIsMobile } from '../../util/style-utils';
import colors from '../../themes/main-colors';
import Button from '../buttons';
import clsx from 'clsx';
import DownloadIcon from '@material-ui/icons/GetApp';
import Hidden from '@material-ui/core/Hidden';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textColor: {
      color: colors.noirBlur,
    },
    blockArea: {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      background: 'white',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      fontSize: '1.5em',
      marginTop: theme.spacing(1),
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    blockButton: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      fontSize: '1.2em',
      borderWidth: '2px !important',
      // eslint-disable-next-line @typescript-eslint/quotes
      borderStyle: 'solid !important',
      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
        width: '100%',
      },
    },
    whiteButton: {
      background: 'white',
    },
    doneButton: {
      borderColor: theme.palette.primary.main,
    },
  }),
);

type Props = {
  path?: string;
  onSubmit: () => void;
  onDownloadPdf: (
    remittanceId: string,
    accountAmounts: Array<AccountAmounts>,
  ) => void;
  onPrintPdf: (
    remittanceId: string,
    accountAmounts: Array<AccountAmounts>,
  ) => void;
};

type LocationState =
  | {
      accountAmounts: Array<AccountAmounts>;
      paymentId: string;
    }
  | undefined;

export default function CompleteStep({
  onSubmit,
  onDownloadPdf,
  onPrintPdf,
}: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const classes = useStyles();

  const location = useLocation();
  const locationState: LocationState = location?.state as LocationState;
  const accountAmounts: Array<AccountAmounts> =
    locationState?.accountAmounts || [];
  const paymentId = locationState?.paymentId || '';

  useEffect(() => {
    if (accountAmounts.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.MULTI_PAY);
    }
  }, []);

  return (
    <Box marginTop={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2">{t('PAYMENT_SUMMARY_INFO')}</Typography>
        </Grid>
        <Grid
          container
          justify={isMobile ? 'center' : 'flex-end'}
          item
          xs={12}
          spacing={isMobile ? 0 : 2}
          direction="row"
        >
          <Hidden smDown>
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                className={clsx(classes.blockButton)}
                onClick={() => onPrintPdf(paymentId, accountAmounts)}
              >
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  justify="center"
                >
                  <Grid item>{t('PRINT')}</Grid>
                  <Grid item>
                    <Box display="flex">
                      <PrintIcon />
                    </Box>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Hidden>
          <Grid item xs={10} md="auto">
            <Button
              color="secondary"
              variant="outlined"
              className={clsx(classes.blockButton, classes.whiteButton)}
              onClick={() => onDownloadPdf(paymentId, accountAmounts)}
            >
              <Grid container spacing={1} alignItems="center" justify="center">
                <Grid item>{t('DOWNLOAD_TEXT')}</Grid>
                <Grid item>
                  <Box display="flex">
                    <DownloadIcon />
                  </Box>
                </Grid>
              </Grid>
            </Button>
          </Grid>
          <Grid item xs={10} md="auto">
            <Button
              className={clsx(classes.blockButton, classes.doneButton)}
              onClick={onSubmit}
            >
              {t('DONE')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
