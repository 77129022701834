import { useTranslation } from '../../hooks/useTranslation';
import { isValidPin } from '../../util/form-validation';
import { Model } from './UseChangePinForm';
import { BaseValidationContext } from '../../hooks/useFormState.types';

const { t } = useTranslation();

const validatePinCode = (pin = ''): string | null => {
  return isValidPin(pin) ? null : t('ERROR_PIN_CODE_REQUIRED');
};

const validationRules = (
  values: Model,
  context: BaseValidationContext<Model>,
) => {
  return {
    newPin: validatePinCode(values.newPin),
  };
};

export default validationRules;
