import { IqbdEnrollInput } from '../../../__generated__/pge-types';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import { C2MPreferredLanguages } from '../../../util/languageUtil';
import { IQBDModel } from '../IQBDForm.rules';

const getC2MEthnicityCode = (
  ethnicity: string | undefined,
  content: PageTextContentType,
) => {
  switch (ethnicity) {
    case content.get('ETHNICITY_1'):
      return 'IQBD-1';
    case content.get('ETHNICITY_2'):
      return 'IQBD-2';
    case content.get('ETHNICITY_3'):
      return 'IQBD-3';
    case content.get('ETHNICITY_4'):
      return 'IQBD-4';
    case content.get('ETHNICITY_5'):
      return 'IQBD-5';
    case content.get('ETHNICITY_6'):
      return 'IQBD-6';
    case content.get('ETHNICITY_7'):
      return 'IQBD-7';
    case content.get('ETHNICITY_8'):
      return 'IQBD-8';
    case content.get('ETHNICITY_9'):
      return 'IQBD-9';
    case content.get('OTHER_ETHNICITY'):
      return 'IQBD-10';
    case content.get('PREFER_NOT_TO_RESPOND'):
      return 'IQBD-11';
    default:
      return 'IQBD-11';
  }
};

export default {
  fromFormState: (
    content: PageTextContentType,
    formData: IQBDModel,
    accountNumber: string,
    encryptedPersonId: string,
    encryptedPremiseId: string,
  ): IqbdEnrollInput => {
    return {
      accountNumber: accountNumber,
      encryptedPersonId: encryptedPersonId,
      encryptedPremiseId: encryptedPremiseId,
      annualIncome: Math.round(Number(formData.annualIncome)).toString(),
      ethinicity: getC2MEthnicityCode(formData.ethinicity, content),
      otherEthnicity:
        formData.ethinicity === content.get('OTHER_ETHNICITY')
          ? formData.otherEthnicity
          : undefined,
      fixedIncome: formData.fixedIncome?.toUpperCase(),
      houseHoldSize: formData.houseHoldSize,
      paperless: Boolean(formData.paperless),
      prefLanguage:
        formData.prefLanguage &&
        C2MPreferredLanguages[
          formData.prefLanguage as keyof typeof C2MPreferredLanguages
        ],
      signature: formData.signature,
      confirmAnnualIncome: Boolean(formData.confirmAnnualIncome),
    };
  },
};
