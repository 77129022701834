import React, { useEffect } from 'react';
import { WizardFormProps } from '../../../../utility/wizard/wizard.types';
import { SSMCoCustomerVerificationFormModel } from '../../../ssm.types';
import SSMCustomerVerification from '../../../common/SSMCustomerVerification';
import { PersonPrimaryIdType } from '../../../../../__generated__/pge-types';

export const VerifyInformation = ({
  formState,
  componentProps,
}: WizardFormProps<SSMCoCustomerVerificationFormModel>) => {
  const form = formState;
  const { values } = form;
  const { active, primaryIdType, idValueError, onSSNChange } = componentProps;

  useEffect(() => {
    form.setError('idValue', idValueError);
  }, [idValueError]);

  useEffect(() => {
    if(values.idType === PersonPrimaryIdType.Ssn){
      onSSNChange(values.idValue);
    }
  }, [values.idValue, values.idType])

  if (!active) {
    return null;
  }

  return (
    <SSMCustomerVerification
      form={form}
      primaryIdType={primaryIdType}
      isCoCustomer={true}
    />
  );
};

export default VerifyInformation;
