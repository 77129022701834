import React from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { useTranslation } from '../../hooks/useTranslation';
import TextField, { TextFieldProps } from '../text-field/TextField';

const numberMask = createNumberMask({
  prefix: '',
  integerLimit: 5,
  includeThousandsSeparator: false,
  allowLeadingZeroes: false,
});

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const TextMaskCustom = (textMaskProps: TextMaskCustomProps | any) => {
  const { inputRef, ...other } = textMaskProps;
  const { t } = useTranslation();

  return (
    <MaskedInput
      {...other}
      type={'text'}
      name={textMaskProps.name || t('PHONE_EXT')}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={numberMask}
      guide={false}
      showMask
    />
  );
};

export default React.forwardRef(
  (props: TextFieldProps | any, ref?: React.Ref<HTMLInputElement>) => {
    const { t } = useTranslation();
    const label = props.label || t('PHONE_EXT');

    return (
      <TextField
        label={label}
        onBlur={props.onBlur}
        ref={ref}
        disabled={!!props.disabled}
        style={props.style || { width: '100%' }}
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
        name={props.name || t('PHONE_EXT')}
        margin={'normal'}
        error={!!props.error}
        helperText={props.helperText || props.error}
        onChange={props.onChange}
        defaultValue={props.defaultValue || null}
        {...props}
      />
    );
  },
);
