import { UseTranslationHook } from '../../../hooks/useTranslation';
import { ApolloClient } from 'apollo-client';
import { convertValidationRules } from '../../../hooks/useFormState';
import {
  validateRoutingNumber,
  validateBankAccount,
  validateConfirmBankAccount,
} from '../../../util/form-validation';

export type Model = {
  routingNumber: string;
  bankAccountNumber: string;
  confirmBankAccountNumber: string;
  paymentFeature: string;
  rememberMe: boolean;
  disableRememberMe: boolean;
};

type AdditionalContext = {
  t: UseTranslationHook['t'];
  apolloClient: ApolloClient<unknown>;
};

export default convertValidationRules<Model, AdditionalContext>(context => {
  return {
    routingNumber: value =>
      validateRoutingNumber(
        value,
        context.values.bankAccountNumber,
        context.apolloClient,
      ),
    bankAccountNumber: value => validateBankAccount(value),
    confirmBankAccountNumber: value =>
      validateConfirmBankAccount(context.values.bankAccountNumber, value),
  };
});
