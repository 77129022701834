import { Box, createStyles, makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import routes from '../../../routes';
import colors from '../../../themes/main-colors';
import theme from '../../../themes/theme';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      backgroundColor: colors.cloudGrey,
      padding: theme.spacing(1),
      textAlign: 'center',
    },
  }),
);

export function MorePrograms({ totalEnrolled }: { totalEnrolled: number }) {
  const classes = useStyles();
  const { inlineRichT } = useTranslation();

  return (
    <Box className={classes.container}>
      <Link to={routes.MY_PROGRAMS}>
        {inlineRichT('YOU_ARE_CURRENTLY_ENROLLED_IN_TOTAL', {
          totalEnrolled: String(totalEnrolled),
        })}
      </Link>
    </Box>
  );
}
