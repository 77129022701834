import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ROUTES from '../../../routes';
import { useTranslation } from '../../../hooks/useTranslation';
import Button from '../../buttons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    header: {
      fontSize: '34px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
        textAlign: 'center',
      },
    },
    kicker: {
      fontSize: '34px',
      fontWeight: 'bold',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
    },
    info: {
      fontSize: '18px',
      textAlign: 'center',
      marginTop: '20px',
    },
    done: {
      marginTop: '30px',
      width: '200px',
      height: '40px',
    },
    back: {
      marginTop: '30px',
      width: '200px',
      height: '40px',
      [theme.breakpoints.up('sm')]: {
        marginRight: '15px',
      },
    },
    action: {
      textAlign: 'center',
    },
  }),
);

type Props = {
  path?: string;
};
export default function ManageAccountIneligible(props: Props) {
  const { t, inlineRichT } = useTranslation();
  const classes = useStyles();

  return (
    <Grid item className={classes.container}>
      <Grid item>
        <Typography className={classes.header} data-testid="header">
          {t('ERROR_AUTH_UNEXPECTED')}
        </Typography>
      </Grid>

      <Grid item>
        <Typography className={classes.kicker} data-testid="kicker">
          {t('WE_ARE_UNABLE_TO_PROCESS_YOUR_REQUEST')}
        </Typography>
      </Grid>

      <Grid item>
        <Typography className={classes.info} data-testid="info">
          {inlineRichT('MANAGE_ACCOUNT_GROUP_INELIGIBLE_TEXT', {
            RESIDENTIAL_CUSTOMER_SERVICE_NUMBER: t(
              'RESIDENTIAL_CUSTOMER_SERVICE_NUMBER',
            ),
          })}
        </Typography>
      </Grid>

      <Grid item className={classes.action}>
        <Button
          color={'secondary'}
          variant={'outlined'}
          className={classes.back}
          onClick={() => navigate(ROUTES.MANAGE_GROUPS_ACCOUNT)}
          data-testid="back"
        >
          {t('BACK')}
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          className={classes.done}
          onClick={() => navigate(ROUTES.ACCOUNT)}
          data-testid="done"
        >
          {t('OK')}
        </Button>
      </Grid>
    </Grid>
  );
}
