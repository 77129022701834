import { Box, Grid, makeStyles, Theme, createStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useTranslation } from '../../../../../hooks/useTranslation';
import Button from '../../../../buttons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    message: {
      '& h2': {
        fontWeight: '300',
        lineHeight: '1.2',
        fontSize: '1.5rem',
      },
      '& p': {
        width: '100%',
      },
    },
    header: {
      fontSize: '22px',
      textAlign: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
    },
    kicker: {
      fontSize: '22px',
      fontWeight: 'bold',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
      padding: '0 16px !important'
    },
    info: {
      fontSize: '18px',
      textAlign: 'center',
    },
  }),
);

interface Props {
  onBack: () => void;
  onDone: () => void;
  ineligibilityType: CoCustomerIneligibleMessageTypes;
}

export enum CoCustomerIneligibleMessageTypes {
  NOT_FOUND = 'notFound',
  EXPERIAN_CREDIT_CHECK = 'experianCheck',
  C2M_CHECK = 'c2m',
}

const Ineligible = ({
  onBack,
  onDone,
  ineligibilityType = CoCustomerIneligibleMessageTypes.C2M_CHECK,
}: Props) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const [messageType, setMessageType] = useState<
    CoCustomerIneligibleMessageTypes
  >(CoCustomerIneligibleMessageTypes.C2M_CHECK);

  const messages = {
    [CoCustomerIneligibleMessageTypes.NOT_FOUND]: {
        header: richT('SSM_CO_CUSTOMER_PERSON_NOT_FOUND_HEADER'), 
        kickerText: t('SSM_CO_CUSTOMER_PERSON_NOT_FOUND'),
        text: richT('SSM_CO_CUSTOMER_PERSON_NOT_FOUND_TEXT', {
          PGE_PHONE: t(
            'PGE_PHONE',
          )
        }),
    },
    [CoCustomerIneligibleMessageTypes.EXPERIAN_CREDIT_CHECK]: {
      header: richT('SSM_CO_CUSTOMER_INELIGIBLE_HEADER'), 
      kickerText: t('SSM_CO_CUSTOMER_CREDIT_CHECK_FAILED'),
      text: richT('SSM_CO_CUSTOMER_INELIGIBLE_TEXT', {
        PGE_PHONE: t(
          'PGE_PHONE',
        )
      }),
    },
    [CoCustomerIneligibleMessageTypes.C2M_CHECK]:{
      header: richT('SSM_CO_CUSTOMER_INELIGIBLE_HEADER'), 
      kickerText: t('SSM_CO_CUSTOMER_INELIGIBLE_FRAUD_KICKER'),
      text: richT('SSM_CO_CUSTOMER_INELIGIBLE_TEXT', {
        PGE_PHONE: t(
          'PGE_PHONE',
        )
      }),
    }
  };

  useEffect(() => {
    setMessageType(ineligibilityType);
  }, [ineligibilityType]);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container className={classes.header}>
        {messages[messageType].header}
      </Grid>

      <Grid item container className={classes.kicker}>
        {messages[messageType].kickerText}
      </Grid>

      <Grid item container className={classes.info}>
        {messages[messageType].text}
      </Grid>

      <Grid container justify="center">
        <Box mb={4}>
          <Button
            style={{ marginRight: 10 }}
            color={'secondary'}
            variant={'outlined'}
            onClick={onBack}
          >
            {t('BACK')}
          </Button>
          <Button
            color={'secondary'}
            variant={'contained'}
            onClick={onDone}
            data-testid="submit-button"
          >
            {t('DONE')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Ineligible;
