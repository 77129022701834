import Grid from '@material-ui/core/Grid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import { Router } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';

import Backdrop from '../../../components/backdrop';
import CancelService from '../../../components/cancel-service';
import CancelServiceConfirmation from '../../../components/cancel-service/cancel-service-confirmation';
import CancelServiceVerification from '../../../components/cancel-service/cancel-service-verification';
import ProfileLayout from '../../../components/profile-layout';
import useCancelService from '../../../hooks/useCancelService';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
    },
    leftColumn: {
      marginRight: theme.spacing(3),
    },
    mainArea: {
      minWidth: '20em',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        padding: '0 5em',
      },
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    paperBox: {
      margin: theme.spacing(4, 0),
      padding: theme.spacing(1),
      [theme.breakpoints.up('xs')]: {
        padding: theme.spacing(2),
      },
    },
    submitButton: {
      paddingLeft: 25,
      paddingRight: 25,
      fontSize: 15,
      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
        width: '100%',
      },
    },
    backLinkMobile: {
      display: 'none',
      margin: 20,
      textAlign: 'center',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    backLinkDesktop: {
      display: 'inline-block',
      marginRight: 20,
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  }),
);

const CancelServicePage = (props: { width: string; path?: string }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const cancelServiceProps = useCancelService();
  const isMobile = ['xs', 'sm'].includes(props.width);
  const childProps = { isMobile, ...cancelServiceProps, classes };
  const { state } = cancelServiceProps;
  const { loading } = state;

  return (
    <>
      <Helmet>
        <title>{t('CANCEL_ONLINE_SERVICE')}</title>
      </Helmet>
      <ProfileLayout dense={false}>
        <>
          {loading && <Backdrop forceOpen />}
          {!loading && (
            <>
              <Grid item style={{ marginBottom: '1em' }}>
                <Typography className={classes.colorTextPrimary} variant={'h1'}>
                  {t('CANCEL_ONLINE_SERVICE')}
                </Typography>
              </Grid>

              <Grid item style={{ marginTop: '2em' }}>
                <Router basepath="/">
                  <CancelService
                    path={ROUTES.CANCEL_ONLINE_SERVICE}
                    {...childProps}
                  />
                  <CancelServiceVerification
                    path={ROUTES.CANCEL_ONLINE_SERVICE_VERIFICATION}
                    {...childProps}
                  />
                  <CancelServiceConfirmation
                    path={ROUTES.CANCEL_ONLINE_SERVICE_CONFIRMATION}
                    {...childProps}
                  />
                </Router>
              </Grid>
            </>
          )}
        </>
      </ProfileLayout>
    </>
  );
};

export default withWidth()(CancelServicePage);
