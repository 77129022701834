import Button from '@material-ui/core/Button';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { navigate } from 'gatsby';
import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import { useLocation } from '@reach/router';

import colors from '../../../themes/main-colors';
import { AirplanemodeActiveSharp } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: colors.noirBlur,
      fontSize: '16px',
      fontWeight: 600,
      margin: theme.spacing(1, 3),
    },
    subLabel: {
      color: theme.palette.primary.main,
      fontSize: '16px',
      fontWeight: 400,
      margin: theme.spacing(0, 4),
    },
    button: {
      // margin: theme.spacing(0.5, 1, 0.5, 1),
      display: 'block',
      color: theme.palette.primary.main,
      textAlign: 'left',
      width: '100%',
      '&:hover': {
        backgroundColor: theme.palette.primary.main + '33',
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main + '33',
      },

      active: {
        backgroundColor: theme.palette.primary.main + '33',
      },
    },
    leftNavBlockRoot: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
    active: {
      backgroundColor: theme.palette.primary.main + '33',
    },
  }),
);

interface MenuItem {
  label: string;
  url: string;
  onClick?: any;
}

interface LeftNavBlockProps extends Omit<PaperProps, 'onSelect'> {
  label: string;
  menuItems?: MenuItem[];
  onSelect?: (
    evt: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value?: string | null,
  ) => void;
  buttonClass?: any;
}

const LeftNavBlock: FunctionComponent<LeftNavBlockProps> = ({
  label,
  menuItems,
  onSelect,
  buttonClass,
  ...props
}) => {
  const classes = useStyles();
  const location = useLocation();

  //TODO This should be refactored to use material's Menu component
  return (
    <Paper className={classes.leftNavBlockRoot} {...props}>
      <Typography variant={'button'} noWrap className={classes.label}>
        {label}
      </Typography>

      {!menuItems
        ? null
        : menuItems.map((item, index) => {
            return (
              <Button
                key={`megaButton-${index}`}
                className={clsx(
                  classes.button,
                  location.pathname.search(item.url) !== -1 && classes.active,
                  buttonClass,
                )}
                onMouseDown={
                  item.onClick ? item.onClick : () => navigate(item.url)
                }
              >
                <Typography
                  variant={'button'}
                  noWrap
                  className={classes.subLabel}
                >
                  {item.label}
                </Typography>
              </Button>
            );
          })}
    </Paper>
  );
};

export default LeftNavBlock;
