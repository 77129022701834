import { styled } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

export default styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

export const GridMainArea = styled(Grid)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});
