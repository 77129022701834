import React, { useEffect, useRef, useState } from 'react';
import {
  CustContactCharacteristicType,
  CustContactCharacteristicValue,
  CustContactClass,
  CustContactType,
  DateAndAdress,
} from '../../../hooks/useSSMStopService';
import DatePicker from '../../date-picker';
import Select from '@material-ui/core/Select';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import TextField from '../../text-field';
import PhoneTextField from '../../phone-text-field/PhoneTextField';
import { useTranslation } from '../../../hooks/useTranslation';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import {
  FormHelperText,
  Grid,
  Typography,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import {
  makeStyles,
  Theme as TypeTheme,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';
import {
  validateCanadianZipCode,
  validatePhoneNumber,
  validateRequiredAndValidCharacters,
  validateZipCode,
} from '../../../util/form-validation';
import { convertValidationRules } from '../../../hooks/useFormState';
import CloseIcon from '@material-ui/icons/Close';
import TextLink from '../../text-link';
import themes from '../../../themes/theme';
import { validateAddress } from '../common/SSMStartService.rules';
import { addDays, parse } from 'date-fns';
import { statesAndProvincesGrouping } from '../../utility/state-dropdown';
import {
  stayingBehindValidation,
  validateInCareOf,
  validateState,
  validateStoptDate,
} from './SSMStopService.rules';
import ZipInputField from '../../zip-input-field';
import { QasModal } from '../../qas';
import { SuggestedAddress } from '../../../__generated__/pge-types';
import SSMStayBehind from '../common/SSMStayBehind';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import { useCreateCustomerContact } from '../../../hooks/useCreateCustomerContact';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { navigate } from '@reach/router';
import ROUTES from '../../../routes';
import useUtil from '../../need-more-time-to-pay/useUtil';

const DATE_FORMAT = 'MM/dd/yyyy';

const useStyles = makeStyles((theme: TypeTheme) => ({
  headingGrid: {
    width: '100%',
    marginTop: '0px',
    marginBottom: '0px',
  },
  subHeading: {
    fontSize: '20px',
    lineHeight: '30px',
    marginTop: '15px',
    marginBottom: '15px',
  },
  infoText: {
    fontSize: '20px',
    lineHeight: '30px',
    marginTop: '-7',
    marginBottom: '0px',
  },
  datePickerWidth: {
    width: '100%',
  },
  formWrapper: {
    width: '100%',
    paddingTop: '0px',
    fontSize: '20px',
  },

  hintText: {
    paddingTop: '5px',
    fontSize: '16px',
  },
  streetAddress: {
    marginTop: '35px',
  },
  city: {
    marginTop: '30px',
  },
  stateProvince: {
    marginTop: '20px',
  },
  zipCode: {
    marginTop: '20px',
  },
  toolTip: {
    paddingTop: '30px',
    fontSize: '16px',
  },
  crossBtn: {
    fontSize: '16px',
    position: 'absolute',
    top: '0px !important',
    right: '0px',
    padding: '0!important',
    opacity: '0.5',
    minWidth: '25px',
    '&:hover': {
      opacity: '1',
    },
  },

  link: {
    fontSize: '16px',
    textAlign: 'left',
    marginTop: '18px',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  cityInfo: {
    marginTop: '20px',
  },
  phoneField: {
    marginTop: '15px',
  },
  tooltip: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(20),
    cursor: 'pointer',
    textDecoration: 'none',
    borderBottom: 'dashed',
    borderWidth: '1px !important',
    '&:hover': {
      borderBottom: `dashed ${theme.palette.secondary.main}`,
    },
    '&:active': {
      color: theme.palette.primary.dark,
      borderBottom: `dashed ${theme.palette.primary.dark}`,
    },
  },
  tooltipHeading: {
    color: colors.noirBlur,
    marginBottom: '5px',
    position: 'relative',
  },
  tooltipContent: {
    color: colors.noirBlur,
    marginTop: '0px',
  },
  tooltipBottomContent: {
    color: colors.noirBlur,
    marginTop: '10px',
  },
  header: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  undrLine: {
    textDecoration: 'underline',
  },
}));

export const SSMStopServiceAddress = ({
  formState,
  componentProps,
}: WizardFormProps<DateAndAdress>) => {
  const { onChange } = formState.props('date');
  const { setErrorNotification } = useUtil();

  const { submit } = useCreateCustomerContact();
  const { wrapWithLoader } = useWrapWithLoader();
  const { customer } = useAccountCustomer();

  const [isOpen, setisOpen] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    qasData,
    setQasData,
    setShowAddressError,
    ssmStopServiceState,
    isPaperlessBillEnrolled,
  } = componentProps;

  const mailingAddressSectionId = 'service-move-mailing-address-section';
  const scrollToMailingAddressRef = useRef(false);

  useEffect(() => {
    if (scrollToMailingAddressRef.current) {
      scrollToMailingAddressRef.current = false;
      document.getElementById(mailingAddressSectionId)?.scrollIntoView();
    }
  }, [scrollToMailingAddressRef.current]);

  useEffect(() => {
    return () => {
      setQasData(null);
    };
  }, []);

  const useTooltipStyles = makeStyles((theme: Theme) =>
    createStyles({
      tooltip: {
        backgroundColor: colors.white,
        color: colors.sparkBlue,
        maxWidth: 560,
        height: 'auto',
        fontSize: theme.typography.pxToRem(12),
        boxShadow: `${colors.boxShadowColor} 0px 8px 24px`,
        padding: '1rem',
      },
      popper: {
        pointerEvents: 'unset',
      },
    }),
  );
  const tooltipClasses = useTooltipStyles();

  return (
    <Grid container direction="column" className={classes.formWrapper}>
      <Grid item container>
        <Grid item xs={12} className={classes.formWrapper}>
          <Typography variant="h2" className={classes.subHeading}>
            <b>{t('SSM_SERVICE_STOP_DATE')}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            data-testid="ssm-stop-date"
            className={classes.datePickerWidth}
            minDate={new Date()}
            format={DATE_FORMAT}
            maxDate={addDays(new Date(), 90)}
            label={t('STOP_SERVICE_DATE')}
            variant={'outlined'}
            name="date"
            {...formState.props('date')}
            onChange={async ({
              target: { value },
            }: {
              target: { value: string };
            }) => {
              // TODO - Need to parse the value because
              // DatePicker onChange is called with string
              // It is not clear yet what the new backend start service
              // submit will accept but current placeholder is of type Date
              // if backend expects MM/DD/YYYY string type, then we can store
              // the type as string in state and get rid of this conversion
              await onChange(parse(value, DATE_FORMAT, new Date()));
            }}
            data-cy="start-field"
            mask={'__/__/____'}
            labelShrink={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={12} className={classes.formWrapper}>
          <SSMStayBehind formState={formState.props('stayingBehind')} />
        </Grid>
        <Grid item xs={12} className={classes.formWrapper}>
          <Typography variant="h2" className={classes.subHeading}>
            <b>{t('SSM_STOP_ADDRESS_AND_DATE_FINAL_BILL')}</b>
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.hintText}>
          <Typography variant="h2" className={classes.infoText}>
            {t('SSM_STOP_FINAL_BILL')}
          </Typography>
        </Grid>
        <Grid id={mailingAddressSectionId} item xs={12}>
          <Grid item xs={12} md={12} className={classes.streetAddress}>
            <TextField
              data-testid="ssm-stop-street"
              id="cityUpdateInfo"
              name="addressLine1"
              label={t('STREET_ADDRESS')}
              style={{ width: '100%', background: 'transparent' }}
              inputProps={{ maxLength: 60 }}
              {...formState.props('addressLine1')}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.city}>
            <TextField
              data-testid="ssm-stop-city"
              name={'city'}
              id="cityUpdateInfo"
              label={t('CITY')}
              style={{ width: '100%', background: 'transparent' }}
              inputProps={{ maxLength: 20 }}
              {...formState.props('city')}
            />
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={7} className={classes.stateProvince}>
              <FormControl
                fullWidth
                variant="outlined"
                error={Boolean(formState.errors.state)}
              >
                <InputLabel id="select-state-label">
                  {t('STATE_PROVINCE_LABEL')}
                </InputLabel>
                <Select
                  fullWidth
                  data-testid="ssm-stop-state"
                  labelId="select-state-label"
                  id="stateUpdateInfo"
                  label={t('STATE_PROVINCE_LABEL')}
                  placeholder={t('STATE_PROVINCE_LABEL')}
                  aria-labelledby="account-select-label"
                  name="state"
                  onChange={async (e: any) => {
                    e.persist();
                    await formState.setValue('state', e.target.value);
                    if (formState.values.zip) {
                      if (
                        !statesAndProvincesGrouping.unitedStates.includes(
                          e.target.value,
                        ) &&
                        formState.values.zip.length === 5
                      ) {
                        await formState.setValue('zip', '');
                      }
                      if (
                        !statesAndProvincesGrouping.canada.includes(
                          e.target.value,
                        ) &&
                        formState.values.zip.length === 7
                      ) {
                        await formState.setValue('zip', '');
                      }
                    }
                  }}
                  onBlur={formState.onBlur}
                  value={formState.values.state}
                  native
                >
                  <option aria-label="None" value="" />
                  <optgroup
                    style={{ backgroundColor: '#fff' }}
                    label={t('UNITED_STATES')}
                  >
                    {statesAndProvincesGrouping.unitedStates.map(stateValue => (
                      <option key={stateValue} value={stateValue}>
                        {stateValue}
                      </option>
                    ))}
                  </optgroup>
                  <optgroup
                    style={{ backgroundColor: '#fff' }}
                    label={t('CANADA')}
                  >
                    {statesAndProvincesGrouping.canada.map(stateValue => (
                      <option key={stateValue} value={stateValue}>
                        {stateValue}
                      </option>
                    ))}
                  </optgroup>
                </Select>
                <FormHelperText error={Boolean(formState.errors.state)}>
                  {formState.errors.state}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={5} className={classes.zipCode}>
              <ZipInputField
                data-testid="ssm-stop-zip"
                name={'zip'}
                label={t('ZIP_CODE')}
                style={{ width: '100%', background: 'transparent' }}
                {...formState.props('zip')}
                inputProps={{ minLength: 1, maxLength: 5 }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ClickAwayListener onClickAway={() => setisOpen(false)}>
              <Tooltip
                open={isOpen}
                classes={tooltipClasses}
                onClose={() => setisOpen(false)}
                title={
                  <>
                    <Typography
                      component={'div'}
                      className={classes.tooltipHeading}
                    >
                      <strong>
                        {t('SSM_STOP_CUSTOMER_ASSISTANCE_REQUIRED')}
                      </strong>
                      <Button
                        onClick={() => {
                          setisOpen(false);
                        }}
                        className={classes.crossBtn}
                      >
                        <CloseIcon style={{ float: 'right' }} />
                      </Button>
                    </Typography>
                    <Typography
                      component={'div'}
                      className={classes.tooltipContent}
                    >
                      {t('SSM_STOP_ADDRESS_AND_DATE_FOREIGN_MILITARY_TEXT')}
                      <br />
                      {t('ASSISTANCE')}
                      <TextLink
                        external
                        plain
                        textColor={themes.palette.primary.main}
                        to={`tel:${t('CALL_FOR_ASSISTANCE_NUMBER')}`}
                      >
                        {' '}
                        <span className={classes.undrLine}>
                          {t('CALL_FOR_ASSISTANCE_NUMBER')}{' '}
                        </span>
                      </TextLink>{' '}
                    </Typography>
                    <Typography
                      component={'div'}
                      className={classes.tooltipBottomContent}
                    >
                      {t('SSM_STOP_BUSINESS_CUSTOMERS')}{' '}
                      <TextLink
                        external
                        plain
                        textColor={themes.palette.primary.main}
                        to={`tel:${t('CALL_FOR_ASSISTANCE_NUMBER')}`}
                      >
                        <span className={classes.undrLine}>
                          {t('BUSINESS_CUSTOMER_SERVICE_NUMBER')}
                        </span>
                      </TextLink>{' '}
                    </Typography>
                  </>
                }
                placement={'bottom-start'}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => {
                    setisOpen(true);
                  }}
                  className={classes.link}
                >
                  {t('SSM_STOP_ADDRESS_AND_DATE_MAILING_OUT_US_TEXT')}
                </Link>
              </Tooltip>
            </ClickAwayListener>
          </Grid>
          <Grid item xs={12} md={12} className={classes.cityInfo}>
            <TextField
              name={'inCareOf'}
              data-testid="ssm-stop-incare"
              id="cityUpdateInfo"
              label={t('IN_CARE_OF')}
              style={{ width: '100%', background: 'transparent' }}
              {...formState.props('inCareOf')}
            />
          </Grid>
          <Grid item xs={12} md={12} className={classes.phoneField}>
            <Box>
              <PhoneTextField
                name="phone"
                data-testid="ssm-stop-phone"
                label={t('SSM_STOP_PHONE')}
                style={{ width: '100%', background: 'transparent' }}
                {...formState.props('phone')}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {qasData && (
        <>
          <QasModal
            results={qasData.results}
            onChange={async (address: SuggestedAddress) => {
              setQasData(null);
              await Promise.all([
                formState.setValue('city', address.city),
                formState.setValue('state', address.state),
                formState.setValue('addressLine1', address.addressLine1),
                formState.setValue('zip', address.postal),
                await formState.setValue('qasVerified', true),
              ]);
            }}
            address={qasData.params}
            onSearch={(params, results) => setQasData({ params, results })}
            onEdit={() => {
              scrollToMailingAddressRef.current = true;
              setQasData(null);
            }}
            onUseEntered={wrapWithLoader(async () => {
              if (isPaperlessBillEnrolled || formState.values.qasVerified) {
                void navigate(ROUTES?.SSM_STOP_REVIEW);
              } else {
                const payload = {
                  accountNumber: ssmStopServiceState?.account?.accountNumber!,
                  longDescription: `Stop Service - QAS not verified and paperless not enrolled, Invalid address:${ssmStopServiceState?.addressAndDate?.addressLine1},${ssmStopServiceState?.addressAndDate?.city},${ssmStopServiceState?.addressAndDate?.state},${ssmStopServiceState?.addressAndDate?.zip}`,
                  encryptedPersonId: customer?.encryptedPersonId,
                  contactClass: CustContactClass.WSS,
                  contactType: CustContactType.WEBCK,
                  characteristics: [
                    {
                      type: CustContactCharacteristicType.CMCHNNL,
                      value: CustContactCharacteristicValue.CSWEB,
                      action: '',
                    },
                  ],
                  shouldPrintLetter: false,
                };
                const _result = await submit(payload);
                setShowAddressError(true);
                if (!_result?.data?.createWebCustomerContact) {
                  setErrorNotification(true);
                }
              }
              setQasData(null);
            })}
          />
        </>
      )}
    </Grid>
  );
};

export const createAddressAndDateFormAdapter = () => {
  return {
    toFormState: (initial: DateAndAdress): DateAndAdress => ({
      date: initial.date,
      addressLine1: initial.addressLine1,
      phone: initial.phone,
      inCareOf: initial.inCareOf,
      city: initial.city,
      state: initial.state,
      zip: initial.zip,
      qasVerified: initial.qasVerified,
      stayingBehind: initial.stayingBehind,
      country: initial.country,
    }),
    fromFormState: (state: DateAndAdress): DateAndAdress => {
      return {
        date: state.date,
        addressLine1: state.addressLine1,
        phone: state.phone,
        inCareOf: state.inCareOf,
        city: state.city,
        state: state.state,
        zip: state.zip,
        qasVerified: state.qasVerified,
        stayingBehind: state.stayingBehind,
        country: state.country,
      };
    },
  };
};

export const createAddressAndDateValidateFunction = () =>
  convertValidationRules<{
    date?: Date;
    addressLine1?: string;
    city?: string;
    state: string;
    zip: string;
    phone?: string;
    inCareOf: string;
    stayingBehind: string;
  }>(() => {
    const { t } = useTranslation();
    return {
      date: validateStoptDate,
      phone: validatePhoneNumber,
      addressLine1: validateAddress,
      inCareOf: validateInCareOf,
      city: validateRequiredAndValidCharacters.bind(
        null,
        t('CITY').toLowerCase(),
      ),
      state: validateState,
      zip: statesAndProvincesGrouping.canada.includes('CA')
        ? validateCanadianZipCode
        : validateZipCode,
      stayingBehind: stayingBehindValidation,
    };
  });
