import React from 'react';
import { Typography, Button, Paper } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { navigate } from 'gatsby';

import ROUTES from '../../../routes';
import { useTranslation } from '../../../hooks/useTranslation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subLabel: {
      color: theme.palette.primary.main,
      fontSize: '16px',
      fontWeight: 600,
      margin: theme.spacing(0, 4),
    },

    button: {
      display: 'block',
      color: theme.palette.primary.main,
      textAlign: 'left',
      width: '100%',
      '&:hover': {
        backgroundColor: theme.palette.primary.main + '33',
      },
      '&:focus': {
        backgroundColor: theme.palette.primary.main + '33',
      },
      '&:active': {
        backgroundColor: theme.palette.primary.main + '33',
      },
    },
    navSection: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(1),
    },
  }),
);

const ReconnectServiceLeftNav = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Paper className={classes.navSection}>
        <Button
          className={clsx(classes.button)}
          onClick={async e => {
            e.preventDefault;
            await navigate(ROUTES.ACCOUNT);
          }}
        >
          <Typography variant="button" noWrap className={classes.subLabel}>
            {t('MY_ACCOUNT')}
          </Typography>
        </Button>
      </Paper>
    </>
  );
};

export default ReconnectServiceLeftNav;
