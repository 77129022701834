import useFormState from '../../hooks/useFormState';
import { useTranslation } from '../../hooks/useTranslation';
import validationRules from './ChangePinForm.rules';
import { updatePinMutation } from './ChangePin.query';
import { ApolloClient } from 'apollo-client';
import { useApolloClient } from '@apollo/react-hooks';
import { useContext } from 'react';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import { navigate } from '@reach/router';
import ROUTES from '../../routes';

export type Model = {
  newPin: string;
};

const defaultState: Model = {
  newPin: '',
};

const updatePin = async (
  newPin: string,
  encryptedPersonId: string,
  apolloClient: ApolloClient<any>,
): Promise<boolean> => {
  const queryResult = await apolloClient.mutate({
    mutation: updatePinMutation,
    variables: {
      payload: {
        newPinCode: newPin,
        encryptedPersonId,
      },
    },
  });
  return !!queryResult?.data?.updatePin?.success;
};

const useChangePinForm = () => {
  const apolloClient = useApolloClient();
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationsContext);
  const form = useFormState(defaultState, {
    validate: validationRules,
    validationContext: { t },
  });

  const onSubmit = async (
    newPin: string,
    encryptedPersonId: string,
  ): Promise<void> => {
    const wasSuccess = await updatePin(newPin, encryptedPersonId, apolloClient);
    if (!wasSuccess) {
      const errMsg =
        'Update pin response was not successful. updatePin mutation returned queryResult.data.updatePin.success = ' +
        wasSuccess;
      console.log(errMsg);
      throw new Error(errMsg);
    } else {
      notificationContext.setState({
        isOpen: true,
        severity: 'success',
        variant: 'filled',
        message: t('PIN_CHANGED_SUCCESSFULLY'),
      });
      return navigate(ROUTES.ACCOUNT);
    }
  };

  return {
    form,
    onSubmit,
  };
};

export default useChangePinForm;
