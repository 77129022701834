import { Card, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import Routes from '../../../routes';
import { isMobile } from '../../../util/style-utils';
import {
  AccountDetail,
  AccountType,
  PaymentCategory,
} from '../../../__generated__/pge-types';
import DaySelector from '../../day-selector';
import PaymentMethodSelector from '../../payment-wallet/payment-method-selector';
import PaymentLimitsFees from '../../paymentus/payment-limits-fees';
import { ADD_PROFILE, NO_PROFILE } from '../../paymentus/types';
import ProceedOrCancel from '../../proceed-or-cancel';
import { useBankSectionForm } from '../auto-pay.hooks';
import { AutoPayConfigData } from '../auto-pay.types';
import {
  getDefaultAutoPayConfigData,
  isPDDEligible,
  mergeAutoPayConfigChanges,
} from '../auto-pay.utils';

interface AutoPayConfigProps {
  path?: string;
  data: AutoPayConfigData | null;
  onSubmit: (data: AutoPayConfigData) => void;
  onUpdateAutoPayConfig: (data: AutoPayConfigData) => void;
  account: AccountDetail;
  reset: () => void | any;
  disableCancelLink?: boolean;
  disablePDDUpdate?: boolean;
}

const AutoPayConfig: FunctionComponent<AutoPayConfigProps> = ({
  data,
  onSubmit,
  onUpdateAutoPayConfig,
  account,
  reset,
  disableCancelLink = false,
  disablePDDUpdate = false,
}) => {
  const { t } = useTranslation();
  const [hasOpenedBillMatrix, setHasOpenedBillMatrix] = useState(false);
  const enrollText = t('AUTO_PAY_CONFIG_ENROLL_TEXT');
  const enrollSelectDayTitle = t('AUTO_PAY_CONFIG_ENROLL_SELECT_DAY_TITLE');
  const updateDayOptionText = t('AUTO_PAY_CONFIG_SELECT_DAY_OPTION_TEXT');
  const updateDayOptionNote = t('AUTO_PAY_CONFIG_UPDATE_DAY_OPTION_NOTE');

  const [autoNext, setAutoNext] = useState(false);

  const enrollSelectBankTitle = t('AUTO_PAY_CONFIG_ENROLL_BANK_TITLE');

  const [autoPayConfig, setAutoPayConfig] = useState<AutoPayConfigData>(
    data || getDefaultAutoPayConfigData(account), // TODO CVM NEED FROM SCHEMA
  );

  const bankSectionForm = useBankSectionForm(autoPayConfig.paymentSelector);
  const { wrapWithLoader } = useWrapWithLoader();

  const handleNext = wrapWithLoader(async () => {
    if (hasOpenedBillMatrix) {
      return navigate(Routes.ACCOUNT);
    }
    try {
      return onSubmit(autoPayConfig);
    } catch (_) {
      // Do nothing, this means, there was a form error
    }
  });

  useEffect(() => {
    if (autoNext) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      handleNext();
    }
  }, [autoNext]);

  return (
    <Grid container direction={'column'} spacing={4}>
      <Grid item>
        <Typography variant={'body2'}>{enrollText}</Typography>
      </Grid>
      {!disablePDDUpdate && isPDDEligible(account.preferredDueDate) && (
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <Typography variant={'h4'}>{enrollSelectDayTitle}</Typography>
          </Grid>
          <Grid item>
            <Card>
              <CardContent>
                <Grid container direction="column" spacing={2}>
                  <Grid item container direction={'column'} spacing={4}>
                    <Grid
                      item
                      container
                      direction={!isMobile() ? 'row' : 'column'}
                      spacing={4}
                      alignItems={'center'}
                      alignContent={'center'}
                    >
                      <Grid item>
                        <Typography variant={'body1'}>
                          {updateDayOptionText}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <DaySelector
                          value={autoPayConfig.selectedDay}
                          onChange={day =>
                            setAutoPayConfig(cfg => ({
                              ...cfg,
                              selectedDay: day,
                            }))
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant={'body1'}>
                        {updateDayOptionNote}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Paper
          style={{
            justifyContent: 'flex-start',
            padding: '0px 15px 20px 15px',
          }}
        >
          <Grid style={{ float: 'right', margin: '10px' }}>
            <PaymentLimitsFees tabIndex={-1} />
          </Grid>
          <PaymentMethodSelector
            selectLabel={t('SELECT_YOUR_PAYMENT_METHOD')}
            isAutopay={true}
            allowedCategories={account?.autoPay?.availablePaymentMethods!}
            excludeCategories={
              account?.accountType !== AccountType.Res
                ? [
                    PaymentCategory.Cc,
                    PaymentCategory.Dc,
                    PaymentCategory.AmazonPay,
                    PaymentCategory.PaypalAccount,
                  ]
                : account?.accountType === AccountType.Res &&
                  account?.paymentEligibility?.isCashOnly
                ? [PaymentCategory.Dd]
                : undefined
            }
            currentProfileInfo={
              autoPayConfig.paymentSelector.selectedProfileInfo
            }
            onChange={selectedProfileInfo => {
              setAutoPayConfig(config =>
                mergeAutoPayConfigChanges(config, {
                  newProfileList: config.paymentSelector.newProfileList,
                  selectedProfileInfo,
                }),
              );
              onUpdateAutoPayConfig && onUpdateAutoPayConfig(autoPayConfig);
            }}
            onNew={newProfileInfo => {
              setAutoPayConfig(config =>
                mergeAutoPayConfigChanges(config, {
                  newProfileList: [
                    ...config.paymentSelector.newProfileList,
                    newProfileInfo.profile,
                  ],
                  selectedProfileInfo: newProfileInfo,
                }),
              );
              setAutoNext(true);
            }}
            onDelete={deleteProfile => {
              setAutoPayConfig(config =>
                mergeAutoPayConfigChanges(config, {
                  newProfileList: config.paymentSelector.newProfileList.filter(
                    p => p?.token !== deleteProfile?.profile?.token,
                  ),
                  selectedProfileInfo: undefined,
                }),
              );
              onUpdateAutoPayConfig && onUpdateAutoPayConfig(autoPayConfig);
            }}
          />
        </Paper>
      </Grid>
      <Grid item>
        <ProceedOrCancel
          cancelRoute={
            hasOpenedBillMatrix || disableCancelLink ? false : Routes.AUTO_PAY
          }
          proceedDisabled={
            !autoPayConfig.paymentSelector?.selectedProfileInfo ||
            autoPayConfig.paymentSelector?.selectedProfileInfo?.profile ===
              ADD_PROFILE ||
            autoPayConfig.paymentSelector?.selectedProfileInfo?.profile ===
              NO_PROFILE
          }
          cancelHandler={reset}
          proceedHandler={handleNext}
          proceedLabel={hasOpenedBillMatrix ? t('DONE') : t('NEXT')}
        />
      </Grid>
    </Grid>
  );
};

export default AutoPayConfig;
