import React, { FC } from 'react';
import Helmet from 'react-helmet';
import { Router } from '@reach/router';
import ProfileLayout from '../../../components/profile-layout/ProfileLayout';
import { Grid, Typography, withWidth } from '@material-ui/core';
import ROUTES from '../../../routes';
import { useTranslation } from '../../../hooks/useTranslation';
import UpdatePersonalInfoForm from '../../../components/update-info/update-personal-info/UpdatePersonalInfoForm';
import { UpdateInfoProps } from '../../../hooks/useUpdateInfoForm';
import UpdateInfoConfirmation from '../../../components/update-info/UpdateInfoConfirmation';

type Props = {
  path?: string;
};

const UpdatePersonalInfoPage = (_: Props) => {
  const { t } = useTranslation();
  const updateInfoProps = UpdateInfoProps();
  return (
    <>
      <ProfileLayout dense={false}>
        <Helmet>
          <title>{t('UPDATE_PERSONAL_CONTACT_INFORMATION')}</title>
        </Helmet>

        <Grid container direction="column" spacing={2}>
          {updateInfoProps.isComplete ? (
            <UpdateInfoConfirmation />
          ) : (
            <Router basepath="/">
              <UpdatePersonalInfoForm
                {...updateInfoProps}
                path={ROUTES.UPDATE_PERSONAL_INFORMATION}
              />
            </Router>
          )}
        </Grid>
      </ProfileLayout>
    </>
  );
};

export default withWidth()(UpdatePersonalInfoPage);
