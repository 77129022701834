import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import routes from '../../../routes';
import GreenCheckIcon from '../../../static/greenCheckIcon.svg';
import colors from '../../../themes/main-colors';
import theme from '../../../themes/theme';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: 'inherit',
      display: 'flex',
      alignItems: 'center',
      columnGap: theme.spacing(1),
      backgroundColor: colors.cloudGrey,
      borderRadius: theme.spacing(0.5),
      padding: theme.spacing(1),
      textDecoration: 'none',
    },
    icon: {
      display: 'block',
    },
    savingsContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      columnGap: theme.spacing(1),
      '& > p:nth-child(odd)': {
        width: `calc(32% - ${theme.spacing(1)}px)`,
      },
    },
    savingsOnBillAmount: {
      fontSize: theme.spacing(1.875),
      fontWeight: 'bold',
      textAlign: 'end',
      color: colors.shoreGreen,
    },
    savingsOnBillLabel: {
      fontSize: theme.spacing(1.875),
      fontWeight: 'bold',
      color: colors.noirBlur,
    },
    ytdSavingsAmount: {
      fontSize: theme.spacing(1.75),
      textAlign: 'end',
      color: colors.noirBlur,
    },
    ytdSavingsLabel: {
      fontSize: theme.spacing(1.75),
      color: colors.noirBlur,
    },
  }),
);

export function SavingsPanelSection({
  savingsOnBill,
  ytdSavings,
}: {
  savingsOnBill: string;
  ytdSavings: string;
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Link className={classes.container} to={routes.MY_PROGRAMS}>
      <GreenCheckIcon className={classes.icon} />
      <Box className={classes.savingsContainer}>
        <Typography className={classes.savingsOnBillAmount}>
          ${savingsOnBill}
        </Typography>
        <Typography className={classes.savingsOnBillLabel}>
          {t('SAVINGS_ON_BILL')}
        </Typography>
        <Typography className={classes.ytdSavingsAmount}>
          ${ytdSavings}
        </Typography>
        <Typography className={classes.ytdSavingsLabel}>
          {t('YEAR_TO_DATE_SAVINGS')}
        </Typography>
      </Box>
      <KeyboardArrowRight />
    </Link>
  );
}
