/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  Theme,
  Slide,
  useTheme,
} from '@material-ui/core';
import Buttons from '../buttons';
import { Close } from '@material-ui/icons';
import { styled } from '@material-ui/styles';
import { getFromStorage, setInStorage } from '../../util/storage-utils';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import useContentMgmt from '../../hooks/useContentMgmt';
import { TransitionProps } from '@material-ui/core/transitions';
import colors from '../../themes/main-colors';
import microcopyGroupIds from '../pge-plus-common/microcopyGroupIds';
import { useIsMobile } from '../../util/style-utils';

const PREFIX = 'welcomeToPGEPlus';
const classes = {
  dialogPaper: `${PREFIX}-dialog-paper`,
  dialogContent: `${PREFIX}-dialog-content`,
  dialogTitle: `${PREFIX}-dialog-title`,
  dialogActions: `${PREFIX}-dialog-actions`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const StyledDialog = styled(Dialog)(({ theme }: { theme: Theme }) => ({
  [`& .${classes.dialogPaper}`]: {
    maxWidth: '530px',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '600px !important',
      width: '100%',
      position: 'fixed',
      bottom: 0,
      left: 0,
    },
  },
  [`& .${classes.dialogContent}`]: {
    padding: '0 30px',
  },
  [`& .${classes.dialogTitle}`]: {
    padding: '30px 30px 0',
  },
  [`& .${classes.dialogActions}`]: {
    padding: '30px 30px 50px',
  },
}));

export const WELCOME_TO_PGE_PLUS_STORAGE_IDENTIFIER = 'pgepluswelcomemessage';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { customer: user } = useAccountCustomer();
  const { content } = useContentMgmt(microcopyGroupIds.PGE_PLUS_WELCOME_MODAL);
  const theme = useTheme();
  const isMobile = useIsMobile();

  const handleClose = async () => {
    const usersList = getUsersListFromLocalstorage();
    //Mark that user has visited the modal
    setInStorage(
      WELCOME_TO_PGE_PLUS_STORAGE_IDENTIFIER,
      JSON.stringify([...usersList, user?.personId]),
    );
    setIsVisible(false);
  };

  useEffect(() => {
    if (user?.personId) {
      const localstorageValue = getFromStorage(
        WELCOME_TO_PGE_PLUS_STORAGE_IDENTIFIER,
      );
      if (localstorageValue) {
        const usersList = getUsersListFromLocalstorage();
        //Check if the logged in user has already viewed the modal
        if (!usersList.includes(user?.personId)) {
          setIsVisible(true);
        }
      } else {
        setIsVisible(true);
      }
    }
  }, [user]);

  const getUsersListFromLocalstorage = () => {
    const localstorageValue = getFromStorage(
      WELCOME_TO_PGE_PLUS_STORAGE_IDENTIFIER,
    );
    return localstorageValue ? JSON.parse(localstorageValue) : [];
  };

  return (
    <StyledDialog
      open={isVisible}
      TransitionComponent={isMobile ? Transition : undefined}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="body"
      fullWidth={true}
      disableRestoreFocus={true}
      PaperProps={{
        className: classes.dialogPaper,
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Grid
          className={'outageTitle'}
          container
          css={{
            justifyContent: 'end',
          }}
        >
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            css={{
              padding: 0,
            }}
          >
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container>
          <Grid item>
            <Typography
              variant={'h2'}
              css={{ fontSize: '36px', marginBottom: '20px', lineHeight: '43px'  }}
            >
              {content.get('WELCOME_TO_PGE_PLUS_MODAL_TITLE')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body1'} style={{ fontSize: '20px' }}>
              {content.get('WELCOME_TO_PGE_PLUS_MODAL_DESC')}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Grid container>
          <Grid item xs={12} md={12}>
            <Buttons
              variant="contained"
              onClick={handleClose}
              fullWidth={isMobile}
              css={{
                fontSize: '14px',
                borderRadius: '30px',
                fontWeight: 500,
                backgroundColor: colors.orange,
                '&:hover': {
                  backgroundColor: colors.orangeBrown,
                },
              }}
            >
              {content.get('LETS_GET_STARTED')}
            </Buttons>
          </Grid>
        </Grid>
      </DialogActions>
    </StyledDialog>
  );
};
