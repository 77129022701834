import {
  Card,
  Grid,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import ExclamationIcon from '../../static/exclamationIcon.svg';
import colors from '../../themes/main-colors';
import { useIsMobile } from '../../util/style-utils';
import ROUTES from '../../routes';
import { useTranslation } from '../../hooks/useTranslation';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '12px',
      background: colors.noirBlur,
      display: 'flex',
    },
    cardMob: {
      padding: '12px',
      background: colors.noirBlur,
      display: 'flex',
      borderLeft: '10px solid #EB7100',
    },
    header: {
      fontSize: '18px',
    },
    contentEndline: {
      fontSize: '18px',
      display: 'inline-block',
      marginLeft: '5px',
    },
    subtext: {
      fontSize: '14px',
    },
    icon: {
      display: 'flex',
      width: '30%',
      justifyContent: 'center',
    },
    contentMain: {
      color: 'white',
      marginLeft: '1rem',
    },
    pspsPageLink: {
      display: 'inline-block',
    },
    pspsLinkText: {
      color: 'white',
    },
  }),
);

type PspsWarningCardInfoData = {
  pspsHeader: React.ReactNode;
  isPspsContentEnd?: boolean;
  pspsEndContent?: React.ReactNode;
};

export function ScheduledOutageCard(props: PspsWarningCardInfoData) {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { pspsHeader, isPspsContentEnd, pspsEndContent } = props;
  const { inlineRichT, t } = useTranslation();

  return (
    <Grid item>
      <Card className={isMobile ? classes.cardMob : classes.card}>
        {!isMobile && (
          <div className={classes.icon}>
            <ExclamationIcon />
          </div>
        )}
        <div className={isMobile ? classes.pspsLinkText : classes.contentMain}>
          <Typography className={classes.header}>
            {pspsHeader}
            <Typography className={classes.pspsPageLink}>
              <a href={ROUTES.PUBLIC_SAFETY_POWER_SHUTOFF} target="_blank">
                <Typography className={classes.pspsLinkText}>
                  {inlineRichT('PSPS_PAGE_LINK')}
                </Typography>
              </a>
            </Typography>
            {isPspsContentEnd && (
              <Typography className={classes.contentEndline}>
                {t('PSPS_CONTENT_ENDLINE')}
              </Typography>
            )}
          </Typography>
        </div>
      </Card>
    </Grid>
  );
}
