import React, { useContext, useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Button from '../buttons';
import { auth } from '../../firebase';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import { useTranslation } from '../../hooks/useTranslation';
import RefreshOutlined from '@material-ui/icons/RefreshOutlined';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'not-graphql-tag';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  progress: {
    marginTop: theme.spacing(1),
    border: 'none',
  },
}));

type Props = {
  onNext: () => void;
};

interface Response {
  verifyUserEmail: {
    isSuccess: boolean;
  };
}

const VERIFY_EMAIL_QUERY = gql`
  query VerifyUserEmail {
    verifyUserEmail {
      isSuccess
    }
  }
`;

const EmailVerification = ({ onNext }: Props) => {
  const classes = useStyles();
  const notificationContext = useContext(NotificationsContext);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [enableRefresh, setEnableRefresh] = useState<boolean>(false);
  const [checking, setChecking] = useState<boolean>(false);

  const [
    sendEmailVerificationQuery,
    { loading, data: sendEmailVerificationData, error },
  ] = useLazyQuery<Response>(VERIFY_EMAIL_QUERY);

  const verificationStatusCheckCount = useRef<number>(0);

  let checkInterval: NodeJS.Timer;

  const { t } = useTranslation();

  useEffect(() => {
    if (sendEmailVerificationData?.verifyUserEmail?.isSuccess) {
      showNotification(t('TWO_STEP_AUTH_EMAIL_VERIFICATION_SENT'));
      setChecking(true);
      checkEmailVerificationStatus();
    } else if (
      sendEmailVerificationData?.verifyUserEmail?.isSuccess === false
    ) {
      showNotification(t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'), 'error');
      setChecking(false);
    }
  }, [sendEmailVerificationData]);

  useEffect(() => {
    if (error) {
      showNotification(t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'), 'error');
      setChecking(false);
      setDisabled(false);
    }
  }, [error]);

  const sendEmailVerification = async () => {
    setDisabled(true);

    if (auth.currentUser) {
      sendEmailVerificationQuery();
    } else {
      setDisabled(false);
      setChecking(false);
      showNotification(t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'), 'error');
    }
  };

  const checkEmailVerificationStatus = () => {
    checkInterval = setInterval(async () => {
      if (verificationStatusCheckCount.current < 3) {
        await refresh();
      } else {
        setEnableRefresh(true);
        clearInterval(checkInterval);
        setChecking(false);
      }

      verificationStatusCheckCount.current += 1;
    }, 30000);
  };

  const showNotification = (
    message: string,
    type: 'error' | 'success' = 'success',
  ) => {
    notificationContext.setState({
      isOpen: true,
      message: message,
      severity: type,
    });
    window.scrollTo(0, 0);
  };

  const refresh = async () => {
    await auth.currentUser?.reload();
    if (auth.currentUser?.emailVerified) {
      onNext();
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <Typography variant={'body1'}>
          {t('TWO_STEP_AUTH_VERIFY_YOUR_EMAIL_INFO')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Button
          className={classes.button}
          variant={'outlined'}
          color={'secondary'}
          onClick={sendEmailVerification}
          disabled={disabled}
          aria-label="send-email-verification"
        >
          {t('TWO_STEP_AUTH_SEND_EMAIL_VERIFICATION')}
        </Button>
        {enableRefresh && (
          <Button
            className={classes.button}
            variant={'outlined'}
            color={'secondary'}
            onClick={refresh}
          >
            <RefreshOutlined />
          </Button>
        )}
        {checking && !enableRefresh && (
          <Button
            className={classes.progress}
            variant={'outlined'}
            color={'secondary'}
          >
            <CircularProgress size={24} />
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export default EmailVerification;
