/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { Fragment, useState } from 'react';
import {
  Box,
  Divider,
  FormHelperText,
  Typography,
  useTheme,
} from '@material-ui/core';
import { RouteComponentProps, navigate } from '@reach/router';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import routes from '../../../../../routes';
import colors from '../../../../../themes/main-colors';
import Modal from '../../../../../components/pge-plus-common/Modal';
import PopoverToolTip from '../../../../pge-plus-common/PopoverToolTip';
import { ProceedOrCancelButton } from '../../../../pge-plus-common/StepperForm';
import { useRebateOnlyFormState } from '../../../../../providers/RebateOnlyFormStateProvider';
import {
  ApplicationErSectionType,
  ApplicationSectionStatus,
  SectionErRebateInfo,
} from '../../../../../__generated__/pgeplus-types';
import RadioButtonGroup from '../../../../pge-plus-common/RadioButtonGroup';
import useFormState from '../../../../../hooks/useFormState';
import { chargerInstalledOnResidentialMeterValidateFunction } from '../rebateOnlyForm.rules';
import {
  ChargerInstalledOnResidentialMeter,
  UpdateSectionErrorCode,
} from '../rebateOnlyForm.types';
import useUpdateERSections from '../../../../../hooks/pge-plus/useUpdateERSections';
import useWrapWithLoader from '../../../../../hooks/useWrapWithLoading';
import { rebateInfoERFormStateAdapter } from '../rebateOnlyFormState.adapter';
import useUtil from '../../../../../hooks/pge-plus/useUtil';
import { SaveAndComeBackLink } from '../../../../pge-plus-common/SaveAndComeBackLink';

const ConfirmYourInformation: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const theme = useTheme();
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_EV_PULSE_CONFIRM_YOUR_INFORMATION,
  );
  const { wrapWithLoader } = useWrapWithLoader();
  const { setErrorNotification } = useUtil();
  const [showExamplePhoto, setShowExamplePhoto] = useState<boolean>(false);

  const {
    formState: { application, rebateInfo },
    getSectionData,
    updateFormState,
  } = useRebateOnlyFormState();

  const form = useFormState(
    {
      chargerInstalledOnResidentialMeter: rebateInfo.chargerInstalledOnResidentialMeter!,
    },
    {
      validate: chargerInstalledOnResidentialMeterValidateFunction(),
      validationContext: {
        content,
      },
    },
  );

  const sectionDataRebateInfo: SectionErRebateInfo = getSectionData(
    ApplicationErSectionType.ErRebateInfo,
  );
  const { updateSectionRebateInfo } = useUpdateERSections();

  const handleSubmit = wrapWithLoader(
    async (data: ChargerInstalledOnResidentialMeter) => {
      try {
        const response = await updateSectionRebateInfo(
          rebateInfoERFormStateAdapter().fromFormState(
            {
              ...rebateInfo,
              chargerInstalledOnResidentialMeter:
                data.chargerInstalledOnResidentialMeter,
            },
            application?.id!,
            sectionDataRebateInfo?.id,
          ),
        );

        // Save the response in a local var
        const result = response?.data?.updateSectionERRebateInfo;

        // Check for errors to show the red banner and block navigation
        if (response?.errors || result?.validation?.errors) {
          throw new Error();
        }

        // Check for section errors to navigate to a specific fiddlesticks page based on the error code
        if (result?.section.errors.length) {
          const nonResidentialMeterFound =
            result?.section.errors.filter(
              error =>
                error.code === UpdateSectionErrorCode.NonResidentialMeter,
            ).length > 0;
          if (nonResidentialMeterFound) {
            void navigate(routes.PGE_PLUS_REBATE_ONLY_INELIGIBLE, {
              state: {
                message: 'REBATE_ONLY_METER_INELIGIBILITY_DETAILS',
              },
            });
          }
        }

        // If there are no GQL, Validation errors or Section errors then proceed with the onSubmit logic
        if (!result?.section.errors.length && result?.section.data) {
          const responseData = result?.section?.data;
          const updatedValues = rebateInfoERFormStateAdapter().toFormState(
            responseData,
          );
          updateFormState('rebateInfo', updatedValues);
          if (
            responseData?.rebateInfo?.existingEnrollments?.evPulse?.[0]?.vin
          ) {
            void navigate(routes.PGE_PLUS_REBATE_ONLY_VERIFY_EVPULSE_REBATE, {
              state: {
                identifier:
                  responseData.rebateInfo?.existingEnrollments.evPulse[0]?.vin,
              },
            });
            return;
          }
          if (
            responseData?.rebateInfo?.existingEnrollments?.smartCharging?.[0]
              ?.serialNo
          ) {
            void navigate(routes.PGE_PLUS_REBATE_ONLY_VERIFY_EVPULSE_REBATE, {
              state: {
                identifier:
                  responseData.rebateInfo?.existingEnrollments.smartCharging[0]
                    ?.serialNo,
              },
            });
            return;
          }
          void navigate(routes.PGE_PLUS_REBATE_ONLY_UPLOAD_MODEL_NUMBER);
        }
      } catch (e) {
        setErrorNotification(true);
      }
    },
  );

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      const response = await updateSectionRebateInfo({
        ...rebateInfoERFormStateAdapter().fromFormState(
          {
            ...rebateInfo,
            ...form.values,
          },
          application?.id!,
          sectionDataRebateInfo?.id,
        ),
        saveAndComeBack: true,
      });
      if (
        response?.errors ||
        response.data?.updateSectionERRebateInfo?.validation?.errors ||
        response.data?.updateSectionERRebateInfo?.section?.errors.length !== 0
      ) {
        throw new Error();
      } else {
        void navigate(routes.ACCOUNT);
      }
    } catch {
      setErrorNotification(true);
    }
  });

  return (
    <Fragment>
      <form onSubmit={form.submit(values => handleSubmit(values), console.log)}>
        <Box css={{ marginBottom: 3 }}>
          <Box>
            <Typography
              component="h1"
              variant="h1"
              css={{
                fontFamily: 'Forma-DJR-Display',
                color: colors.noirBlur,
                fontSize: '36px',
                marginBottom: '30px',
                letterSpacing: '0.72px',
                lineHeight: '43px',
                whiteSpace: 'nowrap',
                marginLeft: '-8px',
                textAlign: 'center',
              }}
            >
              {content.get('REUSABLE_CONFIRM_YOUR_INFORMATION_HEADER')}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant={'body1'}
              css={{
                fontSize: '20px !important',
                lineHeight: '26px !important',
                marginBottom: theme.spacing(4),
              }}
            >
              {content.get('REBATE_ONLY_CONFIRM_YOUR_INFORMATION_DETAILS')}
            </Typography>
            <Typography
              variant={'body1'}
              css={{
                fontSize: '20px !important',
                lineHeight: '26px !important',
                marginBottom: theme.spacing(3),
              }}
            >
              {content.get('TWO_METER_SCENARIOS')}
            </Typography>
            <Typography
              variant="body1"
              css={{
                marginTop: '24px',
                marginBottom: '8px',
                fontSize: '20px',
                color: colors.noirBlur,
                fontWeight: 600,
              }}
            >
              {content.get('REBATE_ONLY_CHARGER_ON_RESIDENTIAL_METER_HEADER')}
            </Typography>
            <Typography
              component="span"
              css={{
                cursor: 'pointer',
                color: colors.sparkBlue,
                marginLeft: '4px',
                borderBottom: `1px dotted ${colors.sparkBlue}`,
                fontWeight: 'bold',
                fontSize: '20px',
              }}
              onClick={() => setShowExamplePhoto(true)}
            >
              {content.get('REUSABLE_VIEW_EXAMPLE_PHOTO')}
            </Typography>
            <Modal
              open={showExamplePhoto}
              onClose={() => setShowExamplePhoto(false)}
              title={content.get('RESIDENTIAL_METER_EXAMPLE_PHOTO_MODAL_TITLE')}
              showCloseIcon={true}
            >
              <Box mt={3}>
                <img
                  css={{
                    width: '100%',
                    maxHeight: '250px',
                  }}
                  src={content.get('RESIDENTIAL_METER_EXAMPLE_PHOTO_URL')}
                />
              </Box>
            </Modal>
            <Box
              css={{
                margin: '30px auto',
                width: 'max-content',
              }}
            >
              <RadioButtonGroup
                options={[
                  {
                    label: content.get('REUSABLE_BUTTON_YES') || '',
                    value: true,
                  },
                  {
                    label: content.get('REUSABLE_BUTTON_NO') || '',
                    value: false,
                  },
                ]}
                state={form.values.chargerInstalledOnResidentialMeter}
                setState={e =>
                  form.setValue('chargerInstalledOnResidentialMeter', e)
                }
              />
              {form.errors.chargerInstalledOnResidentialMeter && (
                <FormHelperText error>
                  {form.errors.chargerInstalledOnResidentialMeter}
                </FormHelperText>
              )}
            </Box>
          </Box>
          <PopoverToolTip
            baseContent={content.get('REBATE_ONLY_HOW_TO_FIND_METER')}
            popoverContent={content.get('REBATE_ONLY_FIND_METER_DETAILS')}
          />
          <Divider css={{ margin: '31px 0 50px 0' }} />
        </Box>
        <ProceedOrCancelButton
          back={routes.PGE_PLUS_REBATE_ONLY_HOME}
          disable={
            form.values.chargerInstalledOnResidentialMeter === null ||
            form.values.chargerInstalledOnResidentialMeter === undefined
          }
          isCancel={true}
        />
      </form>
      <Box mt={6}>
        <SaveAndComeBackLink
          content={content}
          onSaveAndExit={handleSaveAndExit}
        />
      </Box>
    </Fragment>
  );
};

export default ConfirmYourInformation;
