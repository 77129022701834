import { createStyles, makeStyles, Theme } from '@material-ui/core';

import colors from '../../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    margin2: {
      marginBottom: '2em',
      marginTop: '2em',
    },
    paymentMethodTitle: {
      color: colors.noirBlur,
      fontWeight: 'bold',
      marginBottom: '1em',
    },
    item1margin3: {
      marginBottom: '2em',
      boxShadow: 'none',
    },
    margin3: {
      marginBottom: '2em',
      marginTop: '2em',
    },
    margin1: {
      marginBottom: '1em',
      marginTop: '1em',
    },
    splitPayText: {
      color: colors.noirBlur,
      fontWeight: 'bold',
      fontSize: '14px',
      cursor: 'pointer',
    },
    dividerStyle: {
      marginBottom: '1em',
      marginTop: '1em',
    },
  }),
);

export default useStyles;
