/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FC, Fragment, ReactNode } from 'react';
import { Typography, Box, Link, useTheme } from '@material-ui/core';

import { GridItem } from './Grid';
import PopoverToolTip from './PopoverToolTip';

interface QuestionTitleProps {
  children: ReactNode;
}
const QuestionTitle: FC<QuestionTitleProps> = ({ children }) => {
  const theme = useTheme();

  return (
    <GridItem>
      {/* mui overwrites font size using important here */}
      <Typography
        variant="body1"
        css={{
          fontSize: '20px',
          fontWeight: 700,
          lineHeight: '26px',
          marginTop: '20px',
          [theme.breakpoints.down('xs')]: {
            fontSize: 16,
          },
        }}
      >
        {children}
      </Typography>
    </GridItem>
  );
};

interface QuestionDetailProps {
  children: ReactNode;
  detailPopoverContent: ReactNode;
}
const QuestionDetail: FC<QuestionDetailProps> = ({
  children,
  detailPopoverContent,
}) => {
  const theme = useTheme();

  return (
    <GridItem>
      <Typography
        variant="body1"
        component="div"
        css={{
          fontSize: 20,
          marginTop: '20px',
          [theme.breakpoints.down('xs')]: {
            fontSize: 16,
          },
        }}
      >
        {children}
        {detailPopoverContent && (
          <Fragment>
            <PopoverToolTip popoverContent={detailPopoverContent} />
          </Fragment>
        )}
      </Typography>
    </GridItem>
  );
};

interface QuestionProps {
  title?: ReactNode;
  detail?: ReactNode;
  popoverLinkContent?: ReactNode;
  popoverContent?: ReactNode;
  detailPopoverContent?: ReactNode;
}
export const Question: FC<QuestionProps> = ({
  title,
  detail,
  popoverLinkContent,
  popoverContent,
  detailPopoverContent,
}) => {
  const theme = useTheme();

  return (
    <Fragment>
      {title && (
        <Box display={'flex'}>
          <QuestionTitle>
            {title}
            {popoverContent && popoverLinkContent && (
              <Fragment>
                <PopoverToolTip
                  popoverContent={popoverContent}
                  baseContent={popoverLinkContent}
                />
              </Fragment>
            )}
          </QuestionTitle>
        </Box>
      )}
      {detail && (
        <QuestionDetail detailPopoverContent={detailPopoverContent}>
          {detail}
        </QuestionDetail>
      )}
    </Fragment>
  );
};
