import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import {
  PeakTimeEventSeason,
  PeakTimeSeasonDetails,
  SeasonCategory,
} from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import { LanguageContext } from '../../../../providers/LanguageProvider';
import SnowIcon from '../../../../static/snowIcon.svg';
import SunIcon from '../../../../static/sunIcon.svg';
import colors from '../../../../themes/main-colors';
import theme from '../../../../themes/theme';
import { formatFullMonthWithDay } from '../../../../util/date-utils';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: 'inherit',
      backgroundColor: colors.white,
      padding: theme.spacing(1),
      display: 'flex',
      columnGap: theme.spacing(2),
      minHeight: '60%',
      borderRadius: theme.spacing(0.5),
    },
    messagingTitle: {
      fontSize: theme.spacing(1.75),
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
    },
    messagingBody: {
      fontSize: theme.spacing(1.75),
    },
  }),
);

type TSeasonalDates = {
  peakTimeSeason?: PeakTimeSeasonDetails;
};

export function SeasonalDates(props: TSeasonalDates) {
  const { peakTimeSeason } = props;
  const { t, inlineRichT } = useTranslation();
  const classes = useStyles();
  const { language } = useContext(LanguageContext);
  const seasonalInfo: {
    icon: React.ReactNode;
    messagingTitle: React.ReactNode;
    messagingBody: React.ReactNode;
  } = {
    icon: '',
    messagingTitle: '',
    messagingBody: '',
  };

  if (peakTimeSeason?.seasonCategory === SeasonCategory.ActiveSummerSeason) {
    seasonalInfo.icon = <SunIcon />;
    seasonalInfo.messagingTitle = t('SUMMER_SEASON_IS_UNDERWAY');
    seasonalInfo.messagingBody = t('STAY_TUNED_UPCOMING_EVENTS');
  }

  if (peakTimeSeason?.seasonCategory === SeasonCategory.ActiveWinterSeason) {
    seasonalInfo.icon = <SnowIcon />;
    seasonalInfo.messagingTitle = t('WINTER_SEASON_IS_UNDERWAY');
    seasonalInfo.messagingBody = t('STAY_TUNED_UPCOMING_EVENTS');
  }

  if (peakTimeSeason?.seasonCategory === SeasonCategory.AprilOrMay) {
    seasonalInfo.icon = <SunIcon />;
    seasonalInfo.messagingBody = t('PTR_PRIOR_TO_SUMMER_START');
  }

  if (peakTimeSeason?.seasonCategory === SeasonCategory.OffSeason) {
    seasonalInfo.icon =
      peakTimeSeason.season === PeakTimeEventSeason.Summer ? (
        <SunIcon />
      ) : (
        <SnowIcon />
      );
    seasonalInfo.messagingTitle = inlineRichT(
      'PTR_NEXT_SEASON_DATES_WHITE_CARD_HEADER',
      {
        START_DATE: formatFullMonthWithDay(
          peakTimeSeason.startDate
            ? new Date(peakTimeSeason.startDate)
            : new Date(),
          language,
          t,
        ),
      },
    );
    seasonalInfo.messagingBody = inlineRichT('PTR_NEXT_SEASON_START_DATE', {
      START_DATE: formatFullMonthWithDay(
        peakTimeSeason.startDate
          ? new Date(peakTimeSeason.startDate)
          : new Date(),
        language,
        t,
      ),
    });
  }

  return (
    <Box className={classes.container}>
      {Boolean(seasonalInfo.icon) && <Box>{seasonalInfo.icon}</Box>}
      <Box>
        {Boolean(seasonalInfo.messagingTitle) && (
          <Typography className={classes.messagingTitle}>
            {seasonalInfo.messagingTitle}
          </Typography>
        )}
        {Boolean(seasonalInfo.messagingBody) && (
          <Typography className={classes.messagingBody}>
            {seasonalInfo.messagingBody}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
