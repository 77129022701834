/** @jsx jsx */
import { jsx } from '@emotion/core';

import { FC, ReactNode, useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Typography } from '@material-ui/core';
import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import routes from '../../../routes';
import { navigate } from 'gatsby';
import colors from '../../../themes/main-colors';
import { CheckBoxes } from '../../pge-plus-common/CheckBoxes';
import useContentMgmt from '../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import { useGetApplicationSectionQuery } from '../../../hooks/pge-plus/useGetApplicationSection';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';
import {
  ApplicationEvSectionType,
  ApplicationSectionStatus,
  SectionEvEstimate,
} from '../../../__generated__/pgeplus-types';
import { useSubmitApplicationMutation } from '../../../hooks/pge-plus/useSubmitApplication';
import { toDateString } from '../../../util/format';
import { CostEstimateDetails } from '../../pge-plus-common/InstallationCostDetails';
import useUpdateEVSections from '../../../hooks/pge-plus/useUpdateEVSections';
import useUtil from '../../../hooks/pge-plus/useUtil';
import { SaveAndComeBackLink } from '../../pge-plus-common/SaveAndComeBackLink';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';

interface CostEstimationProps {
  children?: ReactNode;
  path: string;
}

export const CostEstimation: FC<CostEstimationProps> = props => {
  const { handleSubmit } = useForm();
  const [acceptTerms, setAcceptTerms] = useState<string[]>([]);

  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_COST_ESTIMATION,
  );
  const sectionText = {
    fontSize: '20px',
    marginTop: 8,
  };
  const {
    formState: { application },
    getSectionData,
  } = useEVChargerFormState();
  const sectionDataEstimateData: SectionEvEstimate = getSectionData(
    ApplicationEvSectionType.EvEstimate,
  );

  const { getApplicationSectionData } = useGetApplicationSectionQuery(
    application?.id!,
    sectionDataEstimateData?.id,
  );

  const costEstimateDetails =
    getApplicationSectionData &&
    getApplicationSectionData.getApplicationSection.data;

  const { updateSectionEvEstimate } = useUpdateEVSections();
  const { setErrorNotification } = useUtil();
  const { wrapWithLoader } = useWrapWithLoader();

  //Submit application
  const {
    submitApplicationEV,
    loading: loadingSubmitApp,
  } = useSubmitApplicationMutation();

  const onSubmit = wrapWithLoader(async () => {
    try {
      const response = await updateSectionEvEstimate({
        id: sectionDataEstimateData.id,
        applicationId: application?.id!,
        status: ApplicationSectionStatus.Completed,
        acknowledgedInstallerInfo: true,
        acknowledgedEstimate: true,
      });

      if (response.data?.updateSectionEVEstimate.validation?.success) {
        const { data } = await submitApplicationEV({
          variables: {
            input: {
              applicationId: application?.id,
            },
          },
        });

        //Route according to Ineligibility case
        const IneligibilityCode =
          data?.submitApplicationEV?.ineligibilityReasons[0]?.code;

        if (!loadingSubmitApp) {
          if (IneligibilityCode) {
            void navigate(routes.PGE_PLUS_EV_CHARGERS_INELIGIBLE, {
              state: { IneligibilityCode },
            });
          } else {
            void navigate(routes.PGE_PLUS_EV_CHARGERS_SUCCESS);
          }
          return;
        }
        //Navigate to the next screen
        void navigate(routes.PGE_PLUS_EV_CHARGERS_SUMMARY);
      } else {
        //TODO: Handle the validation error messages
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      await updateSectionEvEstimate({
        id: sectionDataEstimateData.id,
        applicationId: application?.id!,
        status: ApplicationSectionStatus.Pending,
        acknowledgedInstallerInfo: false,
        acknowledgedEstimate: false,
        saveAndComeBack: true,
      });

      void navigate(routes.ACCOUNT);
    } catch (e) {
      setErrorNotification(true);
    }
  });

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box mt={4}>
          <Box
            mt={3}
            p={2}
            css={{
              color: colors.noirBlur,
              backgroundColor: colors.riverBlue,
              borderRadius: '5px 5px 0px 0px',
            }}
          >
            <Typography component="p" variant="body2" css={{ fontSize: 20 }}>
              {content.get('REUSABLE_DATE')}{' '}
              <strong>{toDateString(new Date())}</strong>
            </Typography>
            <Typography component="p" variant="body2" css={{ ...sectionText }}>
              {content.get('REUSABLE_REFERENCE_NUMBER_COLON')}{' '}
              <strong>{application?.applicationNo}</strong>
            </Typography>
            {!costEstimateDetails?.ineligibleEstimateReason ? (
              <Fragment>
                <Typography
                  component="p"
                  variant="body2"
                  css={{ ...sectionText }}
                >
                  {content.get('STANDARD_INSTALLATION_ESTIMATE_P1')}
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  css={{ ...sectionText }}
                >
                  {content.get('STANDARD_INSTALLATION_ESTIMATE_P2')}
                </Typography>
              </Fragment>
            ) : (
              <Fragment>
                <Typography
                  component="p"
                  variant="body2"
                  css={{ ...sectionText }}
                >
                  {content.get('CUSTOM_INSTALLATION_ESTIMATE_P1')}
                </Typography>
                <Typography
                  component="p"
                  variant="body2"
                  css={{ ...sectionText }}
                >
                  {content.get('CUSTOM_INSTALLATION_ESTIMATE_P2')}
                </Typography>
              </Fragment>
            )}
          </Box>
          <CostEstimateDetails
            costEstimateDetails={costEstimateDetails}
            estimateInstallation={true}
          />
          <Box mt={3}>
            <CheckBoxes
              options={[
                {
                  label: content.get('COST_ESTIMATION_ACKNOWLEDGMENT')!,
                  value: content.get('COST_ESTIMATION_ACKNOWLEDGMENT')!,
                },
              ]}
              state={acceptTerms}
              setState={setAcceptTerms}
            />
          </Box>
          <Box mt={3}>
            <CheckBoxes
              options={[
                {
                  label: content.get('COST_ESTIMATION_DISCLAIMER')!,
                  value: content.get('COST_ESTIMATION_DISCLAIMER')!,
                },
              ]}
              state={acceptTerms}
              setState={setAcceptTerms}
            />
          </Box>

          <Box
            css={{
              '& button[type=submit]:not(:disabled)': {
                backgroundColor: colors.orange,
              },
              '& button[type=submit]:not(:disabled):hover': {
                boxShadow: 'none',
                backgroundColor: colors.orangeBrown,
              },
            }}
          >
            <ProceedOrCancelButton
              back={routes.PGE_PLUS_EV_CHARGERS_SUMMARY}
              nextLabel={content.get('SUBMIT')}
              disable={acceptTerms.length !== 2}
            />
          </Box>

          <Box mt={6}>
            <SaveAndComeBackLink
              content={content}
              onSaveAndExit={handleSaveAndExit}
            />
          </Box>
        </Box>
      </form>
    </Fragment>
  );
};
