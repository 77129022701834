import {
  Container,
  createStyles,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import React, { FC, useContext, useEffect, useState } from 'react';
import Page2ColumnLayout from '../../../components/utility/page2-column-layout';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import { Wizard } from '../../../components/utility/wizard/Wizard';
import { WizardStep } from '../../../components/utility/wizard/WizardStep';
import useSSMPostEnrollment from '../../../hooks/useSSMPostEnrollment';
import { SSMPaymentPreferences } from '../../../components/ssm/post-enrollment/SSMPaymentPreferences';
import {
  createNotificationFormAdapter,
  createNotificationValidation,
  SSMNotificationSettings,
} from '../../../components/ssm/post-enrollment/SSMNotificationSettings';
import { SSMEnrollInPrograms } from '../../../components/ssm/post-enrollment/SSMEnrollInPrograms';
import ProgressTracker from '../../../components/ProgressTracker';
import { SSMAddCoCustomer } from '../../../components/ssm/post-enrollment/SSMAddCoCustomer';
import {
  PostEnrollmentNotificationSettings,
  PostEnrollmentPaymentPreferences,
} from '../../../components/ssm/ssm.types';
import Backdrop from '../../../components/backdrop';
import CustomerAssistance from '../../../components/ssm/post-enrollment/CoCustomer/CustomerAssistance';
import { useIsMobile } from '../../../util/style-utils';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import useUtil from '../../../components/need-more-time-to-pay/useUtil';
import {
  UpdateTextPhoneInput,
  UpdateTextPhoneResponse,
} from '../../../__generated__/pge-types';
import { parsePhoneNumber } from '../../../util/format';
import apolloClient from '../../../lib/apolloClient';
import { updateTextPhoneMutation } from '../../../components/account-summary/manage-alerts/queries';
import { useAutoPayService } from '../../../components/auto-pay/auto-pay.hooks';
import {
  getDayFromDueDate,
  isDateInFuture,
} from '../../../components/auto-pay/auto-pay.utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 3, 16, 3),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 2, 10, 2),
      },
    },
    wizardTitle: {
      fontSize: '2rem',
    },
    indexContainer: {
      width: '100%',
    },
    wizardSubTitle: {
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: theme.typography.fontWeightBold,
      margin: theme.spacing(1, 0, 0),
    },
  }),
);

const SSMPostEnrollmentService: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const {
    ssmPostEnrollmentState,
    account,
    accountLoading,
    setNotificationSettings,
    setNotificationMessage,
    refetchAccount,
  } = useSSMPostEnrollment();

  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { setErrorNotification } = useUtil();
  const {
    customer: customerInfoData,
    loading: customerInfoLoading,
    error: customerInfoError,
  } = useAccountCustomer();

  const [mobNumber, setMobNumber] = useState<string>('');

  const [enrollInPrograms, setEnrollInprograms] = useState(false);
  const [isNotificationCompleted, setIsNotificationCompleted] = useState(false);
  const [isCoCustomerCompleted, setIsCocustomerCompleted] = useState(false);

  const pathname = props.location?.pathname;
  const autoPayService = useAutoPayService();

  const handlePaymentPreferences = async (
    data: PostEnrollmentPaymentPreferences,
  ) => {
    const newPDD = data.preferredDueDate;
    const existingDate =
      account?.preferredDueDate?.dueDate?.preferredDueDate ||
      (isDateInFuture(account?.currentCharges?.dueDate)
        ? getDayFromDueDate(account?.currentCharges?.dueDate)
        : null);

    //Update PDD only when user has changed it
    if (newPDD && existingDate !== newPDD) {
      setLoading(true);
      const dueDateResult = await autoPayService.updatePreferredDueDate(
        account!,
        newPDD,
      );

      const addPreferredDueDate = dueDateResult?.data?.addPreferredDueDate;
      if (
        addPreferredDueDate?.preferredDueDate !== newPDD &&
        addPreferredDueDate?.status !== 'NotChanged'
      ) {
        setNotificationMessage(
          t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
          'error',
        );
        setLoading(false);
        return false;
      } else {
        await refetchAccount();
        setLoading(false);
        setNotificationMessage(t('SSM_POST_PDD_SUCCESS'));
        return true;
      }
    } else {
      return true;
    }
  };

  const handleNoficationSettingsBack = () => {
    setIsNotificationCompleted(false);
  };

  const handleNotificationSettings = async (
    notificationSettings: PostEnrollmentNotificationSettings,
  ) => {
    let encryptedContactId = '';
    setLoading(true);

    if (
      notificationSettings.phone &&
      mobNumber !== notificationSettings.phone
    ) {
      const updatePhonePayload: UpdateTextPhoneInput = {
        encryptedPersonId: customerInfoData?.encryptedPersonId!,
        phoneNumber:
          '+1' + parsePhoneNumber(notificationSettings.phone as string),
        isActive: 'true',
      };

      try {
        const { data: updatePhoneData, errors } = await apolloClient.mutate<{
          updateTextPhone: UpdateTextPhoneResponse;
        }>({
          mutation: updateTextPhoneMutation,
          variables: {
            payload: updatePhonePayload,
          },
          refetchQueries: ['getAccountInfo'],
        });

        if (!updatePhoneData?.updateTextPhone.updatePhoneError) {
          encryptedContactId = updatePhoneData?.updateTextPhone
            .encryptedContactId!;
        } else {
          setNotificationMessage(
            t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
            'error',
          );
          return false;
        }
      } catch (e) {
        setErrorNotification(true);
        setLoading(false);
        return false;
      }
    }

    if (
      notificationSettings?.notificationTypes &&
      notificationSettings.email !== ''
    ) {
      try {
        const result = await setNotificationSettings(
          notificationSettings,
          encryptedContactId,
          mobNumber,
        );
        if (!result?.success) {
          setErrorNotification(true);
          return false;
        }
      } catch (e) {
        setErrorNotification(true);
        setLoading(false);
        return false;
      }
    } else {
      setErrorNotification(true);
    }
    setMobNumber(
      notificationSettings.phone ? String(notificationSettings.phone) : '',
    );
    setLoading(false);
    setIsNotificationCompleted(true);
    return true;
  };

  const handleProgramEnrollment = async () => {
    //alert("Enroll In Program");
    setEnrollInprograms(true);
    return true;
  };

  const handleProgramEnrollmentBack = async () => {
    //alert("Enroll In Program");
    setEnrollInprograms(false);
  };

  const handleConfirm = async () => {
    //await onConfirm();
    //alert("SUBMITTING")
    return true;
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(ROUTES.SSM_POST_ENROLLMENT_PAYMENT_PREFERENCES);
  }, []);

  useEffect(() => {
    if (customerInfoError) {
      setErrorNotification(true);
    } else {
      setErrorNotification(false);
    }
  }, [customerInfoError]);

  useEffect(() => {
    if (customerInfoData) {
      const { contactDetails } = customerInfoData;
      const _mobNumber = contactDetails?.filter(
        contact => contact?.contactType === 'MOB',
      );
      if (_mobNumber && _mobNumber?.length > 0) {
        const num = _mobNumber[0]?.contactValue.replace(/\D+/g, '');
        setMobNumber(num || '');
      }
    }
  }, [customerInfoData]);

  const steps = [
    {
      path: ROUTES.SSM_POST_ENROLLMENT_PAYMENT_PREFERENCES as string,
      title: t('SSM_POST_PAYMENT_PREFERENCES_TITLE'),
      wizardStep: (
        <WizardStep
          initial={ssmPostEnrollmentState.paymentPreferences}
          onNext={handlePaymentPreferences}
          backRoute={false}
          component={SSMPaymentPreferences}
        ></WizardStep>
      ),
      progressStep: {
        label: t('SSM_PAYMENT_PREFERENCES'),
      },
    },
    {
      path: ROUTES.SSM_POST_ENROLLMENT_NOTIFICATIONS,
      title: t('SSM_SETUP_NOTIFICATIONS'),
      subTitle: t('SSM_NOTIFICATION_SETTINGS_TITLE'),
      wizardStep: (
        <WizardStep
          initial={ssmPostEnrollmentState.notificationSettings}
          onNext={handleNotificationSettings}
          component={SSMNotificationSettings}
          onBack={handleNoficationSettingsBack}
          formAdapter={createNotificationFormAdapter(mobNumber)}
          validate={createNotificationValidation()}
          componentProps={{
            mobNumber,
            setLoading,
          }}
        ></WizardStep>
      ),
      progressStep: {
        label: t('SSM_NOTIFICATION_SETTINGS'),
        isComplete: isNotificationCompleted,
      },
    },
    {
      path: ROUTES.SSM_POST_ENROLLMENT_ENROLL_IN_PROGRAMS,
      title: t('SSM_ENROLL_IN_PROGRAMS'),
      wizardStep: (
        <WizardStep
          initial={ssmPostEnrollmentState.enrollInPrograms}
          onBack={handleProgramEnrollmentBack}
          onNext={handleProgramEnrollment}
          component={SSMEnrollInPrograms}
        ></WizardStep>
      ),
      progressStep: {
        label: t('SSM_ENROLL_IN_PROGRAMS'),
        isComplete: enrollInPrograms,
      },
    },
    {
      path: ROUTES.SSM_POST_ENROLLMENT_ADD_CO_CUSTOMER,
      title: t('SSM_POST_ADD_CO_CUSTOMER_TITLE'),
      wizardStep: (
        <WizardStep
          initial={ssmPostEnrollmentState.coCustomers}
          onNext={() => true}
          nextRoute={ROUTES.ACCOUNT}
          component={SSMAddCoCustomer}
          nextText={t('DONE')}
        ></WizardStep>
      ),
      progressStep: {
        label: t('SSM_ADD_CO_CUSTOMER'),
        isComplete: isCoCustomerCompleted,
      },
    },
  ];

  if (pathname === ROUTES.SSM_POST_ENROLLMENT_ADD_CO_CUSTOMER_ASSISTANCE) {
    return <CustomerAssistance />;
  }

  return customerInfoLoading ? (
    <Backdrop forceOpen message={t('LOADING')} />
  ) : (
    <>
      {loading ? <Backdrop forceOpen={true} message={t('LOADING')} /> : null}
      <Page2ColumnLayout>
        <Grid
          style={{ margin: `${isMobile ? '0px' : 'auto'}` }}
          className={classes.indexContainer}
          container
          spacing={2}
          item
          xs={12}
          md={7}
        >
          <Hidden smDown>
            <Grid item>
              <Typography variant={'h1'} className={classes.wizardTitle}>
                {steps.find(s => s.path === pathname)?.title ?? ''}
              </Typography>
              <Typography variant={'h4'} className={classes.wizardSubTitle}>
                {steps.find(s => s.path === pathname)?.subTitle ?? ''}
              </Typography>
            </Grid>
          </Hidden>
          <Grid container spacing={0} item style={{ paddingBottom: '0' }}>
            <ProgressTracker
              steps={steps.map(({ progressStep, path }, index) => ({
                ...progressStep,
                isActive: path === pathname,
                isComplete:
                  progressStep.isComplete ||
                  steps.map(step => step.path).indexOf(pathname || '') > index,
              }))}
            />
          </Grid>

          <Grid container spacing={0} item style={{ paddingTop: '0' }}>
            {account === undefined || accountLoading ? (
              <Backdrop forceOpen />
            ) : (
              <Wizard basepath="/">
                {steps.map(({ wizardStep, path, title }, index) => {
                  return (
                    <wizardStep.type
                      {...{
                        ...wizardStep.props,
                        path: path,
                        key: path,
                        title,
                        backRoute:
                          wizardStep.props.backRoute ??
                          (index > 0 ? steps[index - 1].path : false),
                        nextRoute:
                          wizardStep.props.nextRoute ??
                          (index < steps.length - 1
                            ? steps[index + 1].path
                            : undefined),
                      }}
                    />
                  );
                })}
              </Wizard>
            )}
          </Grid>
        </Grid>
      </Page2ColumnLayout>
    </>
  );
};

export default SSMPostEnrollmentService;
