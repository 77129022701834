import {
  Box,
  Divider,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import {
  AmountDetails,
  AmountType,
  ChargesAndCredits,
} from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import colors from '../../../../themes/main-colors';
import theme from '../../../../themes/theme';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: 'inherit',
      backgroundColor: colors.white,
      borderRadius: theme.spacing(0.5),
      display: 'flex',
      columnGap: theme.spacing(1.5),
      padding: theme.spacing(1.5),
    },
    balanceContainer: {
      backgroundColor: colors.blueWhale,
      height: theme.spacing(9),
      width: theme.spacing(9),
      padding: theme.spacing(1),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    amount: {
      color: colors.white,
      fontSize: theme.spacing(2),
    },
    currentMonths: {
      color: colors.white,
      fontSize: theme.spacing(1.125),
    },
    amountType: {
      color: colors.white,
      fontSize: theme.spacing(1.125),
    },
    chargesAndCreditsContainer: {
      flexGrow: 2,
    },
    chargesAndCredits: {
      fontSize: theme.spacing(1.75),
      fontWeight: 'bold',
      marginBottom: theme.spacing(0.5),
    },
    chargesAndCreditsTotal: {
      fontSize: theme.spacing(2),
      fontWeight: 'bold',
    },
    divider: {
      width: '100%',
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(0.5),
    },
    chargesAndCreditsRow: {
      display: 'flex',
      justifyContent: 'space-between',
      '& > p': {
        fontSize: theme.spacing(1.5),
        marginBottom: theme.spacing(0.5),
      },
    },
    solarCreditsMessage: {
      fontSize: theme.spacing(1.5),
    },
  }),
);

type TCreditAndChargeDetails = {
  showAmountBreakdown?: boolean;
  showCommunitySolarMessage?: boolean;
  amountDetails?: AmountDetails;
  chargesAndCredits?: ChargesAndCredits;
};

export function CreditAndChargeDetails(props: TCreditAndChargeDetails) {
  const {
    showAmountBreakdown,
    showCommunitySolarMessage,
    amountDetails,
    chargesAndCredits,
  } = props;
  const classes = useStyles();
  const { t, inlineRichT } = useTranslation();

  const currentMonths = amountDetails?.currentMonths?.reduce(
    (acc: string, month) => {
      const monthName = t(month?.replace(/[0-9\s]/g, '') ?? '');

      if (acc === '') {
        return acc + monthName;
      } else {
        return acc + `-${monthName}`;
      }
    },
    '',
  );

  function getAmountType() {
    if (amountDetails?.amountType === AmountType.Cost) {
      return t('COST');
    }

    if (amountDetails?.amountType === AmountType.Savings) {
      return t('SAVINGS_LABEL');
    }
  }

  function getCreditOrChargeRow(rowName: string, rowAmount: string) {
    return (
      <Box className={classes.chargesAndCreditsRow}>
        <Typography>{rowName}</Typography>
        <Typography>${rowAmount}</Typography>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.balanceContainer}>
        <Typography className={classes.amount}>
          ${amountDetails?.amount}
        </Typography>
        {currentMonths && (
          <Typography className={classes.currentMonths}>
            {currentMonths}
          </Typography>
        )}
        <Typography className={classes.amountType}>
          {getAmountType()}
        </Typography>
      </Box>
      <Box className={classes.chargesAndCreditsContainer}>
        <Typography className={classes.chargesAndCredits}>
          {t('CHARGED_AND_CREDITS')}
        </Typography>
        <Typography className={classes.chargesAndCreditsTotal}>
          ${Math.abs(Number(chargesAndCredits?.total ?? 0)).toFixed(2)}
          {Number(chargesAndCredits?.total ?? 0) < 0
            ? ` (${t('CREDIT_BALANCE_SORT_LABEL')})`
            : null}
        </Typography>
        <Divider className={classes.divider} />
        {showAmountBreakdown && (
          <>
            {getCreditOrChargeRow(
              t('GENERATION_CREDIT'),
              Math.abs(Number(chargesAndCredits?.generationCredit ?? ''))
                .toFixed(2)
                .toString(),
            )}
            {getCreditOrChargeRow(
              t('SUB_FEE'),
              chargesAndCredits?.subscriptionFee ?? '',
            )}
            {getCreditOrChargeRow(
              t('ADMIN_FEE'),
              chargesAndCredits?.adminFee ?? '',
            )}
          </>
        )}
        {showCommunitySolarMessage && (
          <Typography className={classes.solarCreditsMessage}>
            {inlineRichT('THIS_YEAR_YOU_RECEIVED_COMMUNITY_SOLAR_CREDITS')}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
