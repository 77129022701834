/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { useRef, Fragment } from 'react';
import {
  Dialog,
  DialogContent,
  Grid,
  Theme,
  Typography,
  Box,
} from '@material-ui/core';
import { styled } from '@material-ui/styles';
import colors from '../../themes/main-colors';
import Fireworks from 'react-canvas-confetti/dist/presets/fireworks';
import { TCanvasConfettiInstance } from 'react-canvas-confetti/dist/types';

const PREFIX = 'ModalWithConfetti';
const classes = {
  dialogPaper: `${PREFIX}-dialog-paper`,
  dialogContent: `${PREFIX}-dialog-content`,
  title: `${PREFIX}-title`,
};

const StyledDialog = styled(Dialog)(({ theme }: { theme: Theme }) => ({
  [`& .${classes.dialogPaper}`]: {
    maxWidth: '960px',
    margin: 0,
    background: 'transparent',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '415px !important',
      width: '100%',
    },
  },
  [`& .${classes.dialogContent}`]: {
    padding: '94px 68px 32px 72px',
    [theme.breakpoints.down('sm')]: {
      padding: '94px 22px 32px 22px',
    },
    margin: 32,
    background: '#d2eef4',
    color: colors.noirBlur,
  },
  [`& .${classes.title}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
    backgroundColor: colors.noirBlurLight,
    color: colors.white,
    position: 'absolute',
    left: 0,
    top: 67,
    padding: '24px 72px',
    [theme.breakpoints.down('sm')]: {
      padding: '24px 42px',
    },
    width: '100%',
    clipPath: 'polygon(100% 0%, 98% 50%, 100% 100%, 0 100%, 0% 50%, 0 1%)',
  },
}));

export type Props = {
  title: React.ReactNode;
  open: boolean | false;
  handleClose: () => void;
  children: React.ReactNode;
};

export const ConfettiModal = ({
  title,
  open,
  handleClose,
  children,
}: Props) => {
  const instance = useRef<TCanvasConfettiInstance>();

  const onInitHandler = ({
    confetti,
  }: {
    confetti: TCanvasConfettiInstance;
  }) => {
    instance.current = confetti;
    shootFromTop(confetti);
  };

  const shootFromTop = (confetti: TCanvasConfettiInstance) => {
    // Counter to keep track of the number of times the function has been called
    let counter = 0;

    // Set interval to call the function every second (1000 milliseconds)
    const intervalId = setInterval(() => {
      if (counter < 4) {
        void confetti?.({
          scalar: 1.5,
          spread: 300,
          particleCount: 200,
          origin: { y: -0.5 },
          startVelocity: -60,
          ticks: 350,
        });
        counter++;
      } else {
        clearInterval(intervalId); // Clear the interval after 3 calls
      }
    }, 300);
  };

  return (
    <Fragment>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="body"
        fullWidth={true}
        disableRestoreFocus={true}
        PaperProps={{
          className: classes.dialogPaper,
          elevation: 0,
        }}
        BackdropProps={{
          style: {
            display: 'none',
          },
        }}
      >
        <DialogContent className={classes.dialogContent}>
          <Grid container css={{ marginBottom: 16 }}>
            <Grid item className={classes.title}>
              <Typography variant={'h2'}>{title}</Typography>
            </Grid>
          </Grid>

          {children}
        </DialogContent>
      </StyledDialog>
      <Fireworks
        onInit={onInitHandler}
        css={{
          zIndex: 1,
          position: 'fixed',
          pointerEvents: 'none',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
        }}
      />
      <Box
        className="confetti-modal-backdrop"
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          position: 'fixed',
          backgroundColor: 'rgba(0, 0, 0, 0.21)',
        }}
      ></Box>
    </Fragment>
  );
};
