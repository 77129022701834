import React from 'react';
import { Box, Typography, Select, MenuItem } from '@material-ui/core';

import colors from '../../../themes/main-colors';

interface SelectDropdownProps {
  title: string;
  selectedValue?: string;
  placeholder?: string;
  options: Array<any>;
  onChange: (value: string) => void;
}

export const SelectDropdown: React.FC<SelectDropdownProps> = props => {
  const { title, options, selectedValue, placeholder } = props;

  return (
    <Box>
      <Typography
        css={{
          fontFamily: 'Untitled-Sans',
          fontSize: '16px',
          lineHeight: '20px',
          color: colors.lightCyan,
          display: 'block',
          margin: '0 0 10px 0',
        }}
      >
        {title}
      </Typography>
      <Select
        variant="outlined"
        displayEmpty={true}
        renderValue={() => (selectedValue ? selectedValue : placeholder)}
        style={{
          width: '313px',
          height: '48px',
          overflow: 'hidden',
          fontSize: '16px',
        }}
        inputProps={{ maxLength: 90, fontSize: 16 }}
        onChange={event => props.onChange(event.target.value as string)}
      >
        {options.map((option, idx) => (
          <MenuItem key={idx} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
