import gql from 'not-graphql-tag';

export const getLinkedPersons = gql`
  query GetLinkedPersons($params: LinkedPersonRequest) {
    getLinkedPersons(params: $params) {
      contactPhone
      email
      name
      encryptedPersonId
      encryptedContactId
      personId
      isFinanciallyResponsible
      isCssUser
      accountRelTypeCd
      verificationStatus
      inputData
    }
  }
`;

export const GetMedicalCertStatus = gql`
  query GetMedicalCertStatus($params: MedicalCertStatusParams!) {
    getMedicalCertStatus(params: $params) {
      isEligible
      enrollmentStatus
      isEnrolled
      expirationDate
    }
  }
`;

export const enrollMedicalCertificateMutation = gql`
  mutation Mutation($payload: EnrollMedicalCertProgramInput!) {
    enrollMedicalCertificateProgram(payload: $payload) {
      caseId
      expirationDate
    }
  }
`;
