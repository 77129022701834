import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ROUTES from '../../../routes';
import { useTranslation } from '../../../hooks/useTranslation';
import Button from '../../buttons';
import { StopEligibility } from '../../../hooks/useSSMStopService';
import clsx from 'clsx';

type Props = {
  path?: string;
  personAndAccountInEligibleData: StopEligibility;
  accountCount: Number;
  serviceAddress: string;
  setPersonAndAccountInEligibleData?: (
    personAndAccountInEligibleData: StopEligibility,
  ) => void;
  hasNoActiveElectricSAs: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '646px',
    },
    header: {
      fontSize: '34px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
        textAlign: 'center',
      },
    },
    kicker: {
      fontSize: '34px',
      fontWeight: 'bold',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
    },
    info: {
      fontSize: '18px',
      textAlign: 'center',
      marginTop: '20px',
    },
    done: {
      marginTop: '30px',
      width: '200px',
      height: '40px',
      marginRight: '10px',
    },
    mainContent: {
      '& h2': {
        fontFamily: theme.typography.h2.fontFamily,
        fontSize: theme.typography.h2.fontSize,
        fontWeight: theme.typography.h2.fontWeight,
      },
    },
    button: {
      paddingLeft: 25,
      paddingRight: 25,
      fontSize: 15,
      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
        width: '100%',
      },
    },
    backButton: {
      background: 'white',
    },
    center: {
      textAlign: 'center',
    },
  }),
);

export const SSMStopServiceIneligible = (props: Props) => {
  const {
    personAndAccountInEligibleData,
    accountCount,
    serviceAddress,
    setPersonAndAccountInEligibleData,
    hasNoActiveElectricSAs,
  } = props;
  const {
    isPendingDisconnect,
    isDisconnected,
    hasMultiplePremises,
    hasNoActiveElectricSA,
    hasProtectedAddress,
    hasPendingStop,
    isEqualPay,
  } = personAndAccountInEligibleData;

  const { richT, t, inlineRichT } = useTranslation();
  const classes = useStyles();

  let headerText = t('SSM_STOP_UNABLE_TO_PROCEED_ONLINE');
  let kickerText = t('SSM_STOP_NO_ACTIVE_SERVICE_KICKER_TEXT');
  let infoText = richT('SSM_STOP_ASSISTANCE');

  if (isPendingDisconnect) {
    headerText = t('PENDING_DISCONNECT');
    kickerText = t('SSM_STOP_KICKER_TEXT');
    infoText = inlineRichT('SSM_MOVE_PENDING_DISCONNECT_INFO_2', {
      serviceAddress,
    });
  }

  if (isDisconnected) {
    headerText = t('PENDING_DISCONNECT_REQUEST');
    kickerText = t('SSM_STOP_KICKER_TEXT');
    infoText = richT('SSM_STOP_CALL_FOR_ASSISTANCE');
  }

  if (hasMultiplePremises) {
    headerText = t('PENDING_DISCONNECT_MULTIPLE_PREMISE');
    kickerText = t('PENDING_DISCONNECT_MULTIPLE_PREMISE_KICKERTEXT');
    infoText = richT('SSM_STOP_ASSISTANCE');
  }

  if (hasNoActiveElectricSA || hasNoActiveElectricSAs) {
    headerText = t('NO_ACTIVE_SERVICE');
    kickerText = t('SSM_STOP_ELECTRIC_SA_KICKER_TEXT');
    infoText = richT('SSM_STOP_ELECTRIC_SA_TEXT');
  }

  if (hasProtectedAddress) {
    headerText = t('SSM_STOP_UNABLE_TO_PROCEED_ONLINE');
    kickerText = t('SSM_STOP_NO_ACTIVE_SERVICE_KICKER_TEXT');
    infoText = richT('SSM_STOP_ASSISTANCE');
  }

  if (hasPendingStop) {
    headerText = t('PENDING_DISCONNECT_REQUEST');
    kickerText = t('SSM_STOP_KICKER_TEXT');
    infoText = richT('SSM_STOP_CALL_FOR_ASSISTANCE');
  }

  if (isEqualPay) {
    headerText = t('SSM_EQUAL_PAY_ELIGIBILITY');
    kickerText = t('SSM_STOP_KICKER_TEXT');
    infoText = richT('SSM_EQUAL_PAY_ELIGIBILITY_ASSISTANCE');
  }

  useEffect(() => {
    return () => {
      if (setPersonAndAccountInEligibleData) {
        setPersonAndAccountInEligibleData({
          isPendingDisconnect: false,
          isDisconnected: false,
          hasMultiplePremises: false,
          hasNoActiveElectricSA: false,
          hasProtectedAddress: false,
          hasPendingStop: false,
          isEqualPay: false,
        });
      }
    };
  }, []);

  return (
    <Grid item className={classes.container}>
      <>
        <Grid item>
          <Typography className={classes.header} data-testid="header">
            {headerText}
          </Typography>
        </Grid>

        <Grid item>
          <Typography className={classes.kicker} data-testid="kicker">
            {kickerText}
          </Typography>
        </Grid>

        <Grid item>
          <Typography className={classes.info} data-testid="info">
            {infoText}
          </Typography>
        </Grid>
        <Grid item className={classes.center}>
          {accountCount > 1 && (
            <Button
              color="secondary"
              variant="outlined"
              className={clsx(classes.done, classes.backButton)}
              onClick={() => {
                if (setPersonAndAccountInEligibleData) {
                  setPersonAndAccountInEligibleData({
                    isPendingDisconnect: false,
                    isDisconnected: false,
                    hasMultiplePremises: false,
                    hasNoActiveElectricSA: false,
                    hasProtectedAddress: false,
                    hasPendingStop: false,
                    isEqualPay: false,
                  });
                }
                void navigate(ROUTES.SSM_STOP_ACCOUNT);
              }}
            >
              {t('BACK')}
            </Button>
          )}
          <Button
            color={'primary'}
            variant={'contained'}
            className={classes.done}
            onClick={() => void navigate(ROUTES.HOME)}
            data-testid="done"
          >
            {t('DONE')}
          </Button>
        </Grid>
      </>
    </Grid>
  );
};
