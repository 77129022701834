import { Router } from '@reach/router';
import React from 'react';
import Helmet from 'react-helmet';
import AccountLayout from '../../components/account-layout';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import AdditionalPrograms from '../../components/additional-programs/AdditionalPrograms';

export const AdditionalProgramsAndOfferingMain = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('ADDITIONAL_PROGRAMS_AND_OFFERING')}</title>
      </Helmet>
      <AccountLayout>
        <Router basepath="/">
          <AdditionalPrograms
            path={`${ROUTES.ADDITIONAL_PROGRAMS_AND_OFFERING}/*`}
          />
        </Router>
      </AccountLayout>
    </>
  );
};
export default AdditionalProgramsAndOfferingMain;
