import React, { useEffect, useState } from 'react';
import { useTranslation } from '../../../../../hooks/useTranslation';
import usePeakTimeRebates from '../../../../peak-time-rebates/usePeakTimeRebates';
import Backdrop from '../../../../backdrop';
import { PeakTimeRebatesTransactionType } from '../../../../peak-time-rebates/types';
import PTRIcon from '../../../../../static/icon_get_rebate.svg';

import useSSMPostEnrollment from '../../../../../hooks/useSSMPostEnrollment';
import ProgramCard from '../ProgramCard';
import ProgramDetails from './ProgramDetails';
import ProgramDetailsModal from '../ProgramDetailsModal';

const PeakTimeRebates = () => {
  const { t, richT } = useTranslation();
  const [viewDetails, setViewDetails] = useState<boolean>(false);

  const {
    loading,
    isEnrolled,
    isEligible,
    transactionType,
    setTransactionType,
    form,
    showNotificationPreferenceRequiredError,
    handleOnFormSubmit,
    isSubmitSuccessful,
    refetch,
  } = usePeakTimeRebates();

  const { setNotificationMessage } = useSSMPostEnrollment();

  const messages = {
    [PeakTimeRebatesTransactionType.Enroll]: t('ENROLLMENT_SUCCESSFUL'),
    [PeakTimeRebatesTransactionType.Cancel]: t('UNENROLLMENT_SUCCESSFUL'),
    [PeakTimeRebatesTransactionType.Update]: t('UPDATE_SUCCESSFUL'),
    [PeakTimeRebatesTransactionType.Unknown]: '',
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      const refetchEnrollmentData = async () => {
        await refetch();
      };

      void refetchEnrollmentData();
      setNotificationMessage(messages[transactionType]);

      window.scrollTo(0, 0);
      setViewDetails(false);
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    const submitCancelEnrollment = async () => {
      await handleOnFormSubmit();
      await refetch();
    };

    if (transactionType === PeakTimeRebatesTransactionType.Cancel) {
      void submitCancelEnrollment();
    }
  }, [transactionType]);

  const handleUnEnrollment = async () => {
    setTransactionType(PeakTimeRebatesTransactionType.Cancel);
  };

  const ProgramDetailsProps = {
    isEnrolled,
    isEligible,
    loading,
    form,
    transactionType,
    setTransactionType,
    showNotificationPreferenceRequiredError,
  };

  return (
    <>
      {loading && <Backdrop forceOpen />}

      <ProgramCard
        isEnrolled={isEnrolled}
        title={t('PTR_TITLE_CASE')}
        description={richT('SSM_PTR_OPTIONS_SUBHEADER')}
        icon={<PTRIcon style={{ width: '50px' }} />}
        viewProgramDetails={() => setViewDetails(true)}
        onCancel={handleUnEnrollment}
      />

      {viewDetails && (
        <ProgramDetailsModal
          onClose={() => setViewDetails(false)}
          open={viewDetails}
        >
          <ProgramDetails
            onClose={() => setViewDetails(false)}
            onSubmit={handleOnFormSubmit}
            {...ProgramDetailsProps}
          />
        </ProgramDetailsModal>
      )}
    </>
  );
};

export default PeakTimeRebates;
