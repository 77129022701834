/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Box, Button, FormHelperText, Typography } from '@material-ui/core';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useEffect, useState } from 'react';
import { CurrentRebatesView } from '.';
import useContentMgmt, {
  PageTextContentType,
} from '../../../hooks/useContentMgmt';
import { FormState } from '../../../hooks/useFormState.types';
import routes from '../../../routes';
import colors from '../../../themes/main-colors';
import { GridItem } from '../../pge-plus-common/Grid';
import Modal from '../../pge-plus-common/Modal';
import { SaveAndComeBackLink } from '../../pge-plus-common/SaveAndComeBackLink';
import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import { Question } from '../../pge-plus-common/Typography';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import TextField from '../../text-field';
import { RebateEligibility } from '../evcharger.types';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { UpdateSectionEVRebatesMutationTypes } from '../../../hooks/pge-plus/useRebatesEligibility';
import {
  ApplicationSectionStatus,
  OcrFieldNames,
} from '../../../__generated__/pgeplus-types';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';
import { ContinueFromOtherDeviceButton } from '../../pge-plus-common/ContinueFromOtherDeviceButton';
import FileUploaderWithOCR from '../../pge-plus-common/FileUploaderWithOCR';

type ImageUploadProps = {
  content: PageTextContentType;
  form: FormState<RebateEligibility>;
  path: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  handleSaveAndExit: () => void;
  setCurrentView: React.Dispatch<
    React.SetStateAction<CurrentRebatesView | undefined>
  >;
  updateSectionEVRebatesEligibility: UpdateSectionEVRebatesMutationTypes;
  updateFormValues: (values: RebateEligibility) => boolean;
};

export const ImageUpload = (props: ImageUploadProps) => {
  const {
    content,
    form,
    setPageTitle,
    handleSaveAndExit,
    setCurrentView,
    updateSectionEVRebatesEligibility,
    updateFormValues,
  } = props;
  const [ocrExtractionStatus, setOcrExtractionStatus] = useState<
    string | undefined
  >();
  const [failCount, setFailCount] = useState<number>(0);
  const { wrapWithLoader } = useWrapWithLoader();
  const { content: fileUploaderText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_UPLOAD_PHOTOS,
  );
  const [openExamplePhotoModal, setOpenExamplePhotoModal] = useState<boolean>(
    false,
  );

  const { state } = useLocation();
  // @ts-ignore
  const backRoute = state?.pathname;

  const {
    formState: {
      selectCharger: { suppliedByCustomer },
    },
  } = useEVChargerFormState();

  useEffect(() => {
    setCurrentView(CurrentRebatesView.IMAGE_UPLOAD);
    setPageTitle(content.get('EVPULSE_ELIGIBLE_PAGE_TITLE') || '');
  }, []);

  return (
    <form
      onSubmit={wrapWithLoader(
        form.submit(async () => {
          updateFormValues(form.values);
          const {
            validationError,
            isEligible,
            amount,
          } = await updateSectionEVRebatesEligibility(
            form.values,
            false,
            ApplicationSectionStatus.Completed,
          );
          if (!validationError) {
            updateFormValues({ ...form.values, amount: amount?.toString() });
            if (isEligible) {
              void navigate(
                suppliedByCustomer
                  ? routes.PGE_PLUS_EV_CHARGERS_INSTALLATION
                  : routes.PGE_PLUS_EV_CHARGERS_SELECT_CHARGER,
              );
            } else {
              void navigate(
                routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_INELIGIBLE,
              );
            }
          }
        }),
      )}
      noValidate
    >
      <Question detail={content.get('FIFTY_DOLLAR_REBATE')} />
      <Question
        title={content.get('PLEASE_UPLOAD_VIN')}
        detail={content.get('YOU_CAN_FIND_VIN')}
      />
      <Box css={{ width: '100%' }}>
        <Box mt={4}>
          <Button
            css={{
              fontSize: '16px !important',
              padding: '0px !important',
              color: `${colors.sparkBlue} !important`,
              fontWeight: 600,
            }}
            onClick={() => setOpenExamplePhotoModal(true)}
          >
            {content.get('VIEW_EXAMPLE_PHOTO_OF_A_VIN')}
          </Button>
          <Modal
            open={openExamplePhotoModal}
            onClose={() => setOpenExamplePhotoModal(false)}
            title={content.get('SAMPLE_PHOTO_TITLE')}
            showCloseIcon={true}
          >
            <Box mt={3}>
              <img
                css={{
                  width: '100%',
                  maxHeight: '250px',
                }}
                src={content.get('SAMPLE_IMAGE_URL')}
              />
            </Box>
          </Modal>
        </Box>
        <Box mt={2}>
          <FileUploaderWithOCR
            image={{ id: form.values.vinImage as string }}
            placeholder={fileUploaderText.get('UPLOAD_A_PHOTO')}
            reuploadLabel={fileUploaderText.get('REUPLOAD_PHOTO')!}
            ocrType={OcrFieldNames.Vin}
            onComplete={(key, extractedText, message, status) => {
              void form.setValue('vinImage', key);
              void form.setValue('vin', extractedText);
              setOcrExtractionStatus(status);

              if (status === 'fail') {
                setFailCount(count => count + 1);
              } else {
                setFailCount(0);
              }
            }}
          />
          {form.errors.vinImage && (
            <FormHelperText error>{form.errors.vinImage}</FormHelperText>
          )}
        </Box>
      </Box>
      {form.values.vinImage && (
        <Question
          title={content.get('VIN')}
          detail={
            <TextField
              css={{
                background: 'transparent',
                '.MuiInputBase-formControl': { background: colors.white },
              }}
              {...form.props('vin')}
            />
          }
        />
      )}

      {ocrExtractionStatus && ocrExtractionStatus === 'fail' && (
        <FormHelperText error>
          {failCount > 1
            ? content.get('OCR_PROCESS_REUPLOAD_MESSAGE')
            : fileUploaderText.get('OCR_PROCESS_FAIL_MESSAGE')}
        </FormHelperText>
      )}

      {ocrExtractionStatus && ocrExtractionStatus === 'success' && (
        <Typography css={{ marginTop: 8 }}>
          {content.get('OCR_PROCESS_COMPLETION_MESSAGE')}
        </Typography>
      )}
      <GridItem>
        <ProceedOrCancelButton
          back={backRoute || routes.PGE_PLUS_EV_CHARGERS_CONTACT_INFO}
        />
      </GridItem>
      <GridItem>
        <SaveAndComeBackLink
          content={content}
          onSaveAndExit={handleSaveAndExit}
        />
      </GridItem>
    </form>
  );
};
