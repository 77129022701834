import React, { FC } from 'react';

import EqualPay from '../../components/equal-pay';
import { useTranslation } from '../../hooks/useTranslation';
import Grid from '@material-ui/core/Grid';
import {
  Typography,
  Container,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import Helmet from 'react-helmet';

import ViewBillLeftPane from '../../components/view-bill/view-bill-left-pane';
import Page2ColumnLayout from '../../components/utility/page2-column-layout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centerColumn: {
      width: '100%',
    },
    headingPart: {
      paddingBottom: theme.spacing(2),
    },
  }),
);

type Props = {
  path?: string;
};

const EqualPayPage: FC<Props> = () => {
  const { t } = useTranslation();
  const title = t('EQUAL_PAY_TITLE');
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Grid container justify="center" alignItems="center">
        <Page2ColumnLayout leftColumn={<ViewBillLeftPane />}>
          <Grid className={classes.centerColumn}>
            <Grid className={classes.headingPart}>
              <Typography variant={'h1'}>{title}</Typography>
            </Grid>
            <Grid item>
              <EqualPay />
            </Grid>
          </Grid>
        </Page2ColumnLayout>
      </Grid>
    </>
  );
};

export default EqualPayPage;
