import { Box } from '@material-ui/core';
import React from 'react';

import ROUTES from '../../routes';
import Page2ColumnLayout from '../utility/page2-column-layout/Page2ColumnLayout';
import { useTranslation } from '../../hooks/useTranslation';
import LeftNavMenu from '../left-menu-navigation';

type Props = {
  children: any;
  dense?: boolean | undefined;
};

const ProfileLayout = ({ children, dense = true }: Props) => {
  const { t } = useTranslation();

  const leftMenuData = [
    {
      labels: t('PROFILE_AND_PREFERENCES_SIDE_MENU'),
      menuOpen: true,
      items: [
        {
          labels: t('MANAGE_USERS_AND_ALERTS_TITLE'),
          url: ROUTES.MANAGE_ALERTS,
          menuOpen: false,
        },
        {
          labels: t('MANAGE_ACCOUNTS_GROUPS'),
          url: ROUTES.MANAGE_ACCOUNTS_LANDING_SCREEN,
          menuOpen: false,
        },
        {
          labels: t('UPDATE_INFORMATION'),
          url: ROUTES.UPDATE_INFORMATION,
          menuOpen: false,
        },
        {
          labels: t('TWO_STEP_AUTHENTICATION'),
          url: ROUTES.TWO_STEP_AUTHENTICATION,
          menuOpen: false,
        },        
        {
          labels: t('CHANGE_PASSWORD_BUTTON'),
          url: ROUTES.CHANGE_PASSWORD,
          menuOpen: false,
        },
        {
          labels: t('CHANGE_PIN'),
          url: ROUTES.CHANGE_PIN,
          menuOpen: false,
        },
        {
          labels: t('CHANGE_EMAIL'),
          url: ROUTES.CHANGE_EMAIL,
          menuOpen: false,
        },
        {
          labels: t('AUTO_PAY'),
          url: ROUTES.AUTO_PAY,
          menuOpen: false,
        },
        {
          labels: t('PAPERLESS_BILLING'),
          url: ROUTES.PAPERLESS_BILLING,
          menuOpen: false,
        },
        {
          labels: t('PDD_PAGE_TITLE'),
          url: ROUTES.PREFERRED_DUE_DAY,
          menuOpen: false,
        },
        {
          labels: t('CANCEL_ONLINE_SERVICE'),
          url: ROUTES.CANCEL_ONLINE_SERVICE,
          menuOpen: false,
        },
      ],
    },
  ];
  const selectedLinkRoute = location.pathname;
  return (
    <Page2ColumnLayout
      dense={dense}
      leftColumn={
        <Box>
          <LeftNavMenu
            leftNavMenuItems={leftMenuData}
            selectedItem={selectedLinkRoute}
          />
        </Box>
      }
    >
      {children}
    </Page2ColumnLayout>
  );
};

export default ProfileLayout;
