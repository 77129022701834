import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Menu from '../menu';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { MenuItem } from '../menu/Menu';
import Typography from '@material-ui/core/Typography';
import colors from '../../themes/main-colors';
import { toDateString } from '../../util/format';
import { SaStatus } from '../../__generated__/pge-types';
import themePalette from '../../themes/theme';
import { useTranslation } from '../../hooks/useTranslation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      borderRadius: theme.shape.borderRadius,
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      backgroundColor: theme.palette.primary.main,
    },
    listItemHeader: {
      background: colors.lightGray2,
    },
    listItemHeaderText: {
      margin: 'auto',
      marginLeft: '4px',
      padding: theme.spacing(1),
      fontWeight: 'bold',
    },
    listItemHeaderSubtext: {
      color: colors.noirBlur,
      marginLeft: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
        marginLeft: 'unset',
        display: 'block',
      },
    },
    card: {
      marginLeft: 4,
      paddingBottom: theme.spacing(2),
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    cardContent: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      '& > .MuiCheckbox-root': {
        marginTop: -5,
      },
    },
    menu: {
      marginTop: -12,
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      flex: 1,
      padding: theme.spacing(1),
      paddingTop: '0.3em',
    },
    root: {
      marginTop: '1em',
      position: 'relative',
    },
  }),
);

type Props<T> = {
  item: T;
  cardContentTestId?: string;
  checked?: boolean;
  checkDisabled?: boolean;
  onCheckChange?: () => void;
  menuItems?: Array<MenuItem<T>>;
  footer?: React.ReactNode;
  children?: React.ReactNode;
  moreVertIconColor?: string;
  status?: SaStatus;
  statusLabel?: string;
  statusHeaderText?: string;
  statusDate?: Date;
  statusColor?: string;
  isPastDue?: boolean;
  premiseInfoNotFound?: boolean;
  isDisconnected?: boolean;
  isEligibleForReconnect?: boolean;
  disconnectionDate?: Date;
};

export default function ListItem<T>({
  checkDisabled,
  checked,
  onCheckChange,
  children,
  cardContentTestId,
  menuItems,
  item,
  footer,
  moreVertIconColor,
  status,
  statusLabel,
  statusHeaderText,
  statusDate,
  statusColor,
  isPastDue,
  premiseInfoNotFound,
  isDisconnected,
  isEligibleForReconnect,
  disconnectionDate,
}: Props<T>) {
  const classes = useStyles();
  const hideCheckbox =
    status === SaStatus.Pendingstart ||
    status === SaStatus.Cancelled ||
    status === SaStatus.Closed ||
    (isDisconnected && isEligibleForReconnect);
  const { t } = useTranslation();

  return (
    <Grid item className={classes.root}>
      <Paper elevation={3}>
        <div
          className={classes.backdrop}
          style={{
            backgroundColor: `${
              isPastDue || isDisconnected
                ? themePalette.palette.error.main
                : statusColor
            }`,
          }}
        />
        <Card
          className={classes.card}
          elevation={0}
          style={
            Boolean(statusHeaderText) || Boolean(isDisconnected)
              ? undefined
              : { paddingTop: '16px' }
          }
        >
          {Boolean(statusHeaderText) && (
            <Box
              className={classes.listItemHeader}
              data-testid="status-gray-bar"
            >
              <Typography
                component="p"
                variant="body1"
                className={classes.listItemHeaderText}
                style={{ color: `${statusColor}` }}
                data-testid="account-status-label"
              >
                {statusLabel}
                {!premiseInfoNotFound && (
                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.listItemHeaderSubtext}
                  >
                    {`${statusHeaderText} ${toDateString(statusDate)}`}
                  </Typography>
                )}
              </Typography>
            </Box>
          )}
          {Boolean(isDisconnected) && (
            <Box
              className={classes.listItemHeader}
              data-testid="status-gray-bar"
            >
              <Typography
                component="p"
                variant="body1"
                className={classes.listItemHeaderText}
                style={{ color: 'red' }}
                data-testid="account-status-label"
              >
                {t('DISCONNECTED')}
                {!premiseInfoNotFound && (
                  <Typography
                    component="span"
                    variant="body1"
                    className={classes.listItemHeaderSubtext}
                  >
                    {`${t('EFFECTIVE')} ${toDateString(disconnectionDate)}`}
                  </Typography>
                )}
              </Typography>
            </Box>
          )}

          <Box className={classes.cardContent} data-testid={cardContentTestId}>
            {!hideCheckbox && checked !== undefined && (
              <Checkbox
                color="primary"
                checked={checked}
                disabled={Boolean(checkDisabled)}
                onChange={onCheckChange}
                data-testid="account-checkbox"
              />
            )}
            <Box className={classes.content}>{children}</Box>
            {menuItems !== undefined ? (
              <Menu
                items={menuItems}
                value={item}
                className={classes.menu}
                moreVertIconColor={moreVertIconColor}
              />
            ) : null}
          </Box>
          {footer}
        </Card>
      </Paper>
    </Grid>
  );
}
