import useFormState from '../../hooks/useFormState';
import { iqbdFormValidateFunction } from './IQBDForm.rules';
import useContentMgmt, {
  PageTextContentType,
} from '../../hooks/useContentMgmt';
import microcopyGroupIds from '../pge-plus-common/microcopyGroupIds';
import {
  IqbdEnrollInput,
  IqbdEnrollResponse,
  IqbdUnenrollInput,
  IqbdUnenrollResponse,
  MutationIqbdEnrollArgs,
  MutationIqbdUnenrollArgs,
} from '../../__generated__/pge-types';
import apolloClient from '../../lib/apolloClient';
import gql from 'not-graphql-tag';

const IQBDUnEnrollMutation = gql`
  mutation IQBDUnenroll($payload: IQBDUnenrollInput!) {
    IQBDUnenroll(payload: $payload) {
      isUnenrolled
      reason
    }
  }
`;

const IQBDEnrollMutation = gql`
  mutation IQBDEnroll($payload: IQBDEnrollInput!) {
    IQBDEnroll(payload: $payload) {
      isEnrolled
      isEligible
      reason
      discountPercentage
    }
  }
`;

const unEnrollIqbd = (payload: IqbdUnenrollInput) => {
  return apolloClient.mutate<
    { IQBDUnenroll: IqbdUnenrollResponse },
    MutationIqbdUnenrollArgs
  >({
    mutation: IQBDUnEnrollMutation,
    variables: { payload },
    errorPolicy: 'all',
  });
};

const submitForm = (payload: IqbdEnrollInput) => {
  return apolloClient.mutate<
    { IQBDEnroll: IqbdEnrollResponse },
    MutationIqbdEnrollArgs
  >({
    mutation: IQBDEnrollMutation,
    variables: { payload },
    errorPolicy: 'all',
  });
};

export default (content: PageTextContentType) => {
  const form = useFormState(
    { paperless: true, confirmAnnualIncome: false },
    { validate: iqbdFormValidateFunction(), validationContext: { content } },
  );

  return { form, submitForm, unEnrollIqbd };
};
