import gql from 'not-graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import {
  CustomerServiceDisconnectedParams,
  CustomerServiceDisconnectedResponse,
} from '../__generated__/pge-types';

export const IS_CUSTOMER_SERVICE_DISCONNECTED = gql`
  query isCustomerServiceDisconnected(
    $payload: CustomerServiceDisconnectedParams
  ) {
    isCustomerServiceDisconnected(payload: $payload) {
      isServiceDisconnected
    }
  }
`;

interface Request {
  payload: CustomerServiceDisconnectedParams;
}

interface Response {
  isCustomerServiceDisconnected: CustomerServiceDisconnectedResponse;
}

export default function useIsCustomerServiceDisconnected(
  addressLine1: string,
  city: string,
  state: string,
  zip: string,
) {
  const { data } = useQuery<Response, Request>(
    IS_CUSTOMER_SERVICE_DISCONNECTED,
    {
      variables: {
        payload: {
          addressLine1: addressLine1 || '',
          city: city || '',
          state: state || '',
          postal: zip || '',
        },
      },
      fetchPolicy: 'network-only',
      skip: !(addressLine1 && city && state && zip),
    },
  );

  const isServiceDisconnected =
    data?.isCustomerServiceDisconnected?.isServiceDisconnected;

  return {
    isServiceDisconnected,
  };
}
