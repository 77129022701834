/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Box, Grid, Typography } from '@material-ui/core';
import { RouteComponentProps, navigate, useLocation } from '@reach/router';
import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import Backdrop from '../../../../../components/backdrop/Backdrop';
import PGEButton from '../../../../../components/buttons';
import microcopyGroupId from '../../../../../components/pge-plus-common/microcopyGroupIds';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import routes from '../../../../../routes';
import colors from '../../../../../themes/main-colors';
import theme from '../../../../../themes/theme';
import PopoverToolTip from '../../../../../components/pge-plus-common/PopoverToolTip';

const DEFAULT_VALUES = {
  title: 'FIDDLESTICKS_ALTERNATIVE',
  message: 'REBATES_INELIGIBLE',
  backButtonText: 'REUSABLE_BUTTON_BACK',
  nextButtonText: 'RETURN_TO_MY_ACCOUNT',
  nextButtonRoute: routes.ACCOUNT,
};

type TRebateOnlyIneligibleState = {
  /***
   * Pass the slug as string
   * Entry has to be added in the PGE+ Ineligible microcopy group.
   ***/
  title: string;
  /***
   * Pass the slug as string
   * Entry has to be added and formatted in the PGE+ Ineligible microcopy group.
   ***/
  message: string;
  message2?: string;
  learnMoreContent?: string;
  backButtonText: string;
  nextButtonText: string;
  showBackButton: boolean;
  showNextButton: boolean;
  nextButtonRoute: string;
};

function createIneligibleScreen(
  title: React.ReactNode,
  message: React.ReactNode,
  message2: React.ReactNode,
  showBackButton: boolean,
  backButtonText: React.ReactNode,
  showNextButton: boolean,
  nextButtonText: React.ReactNode,
  nextButtonRoute: string,
) {
  return (
    <Box
      css={{
        marginTop: theme.spacing(3),
      }}
    >
      <Typography
        css={{
          textAlign: 'center',
          lineHeight: `${theme.spacing(4.5)}px !important`,
        }}
        variant={'h1'}
      >
        {title}
      </Typography>
      <Box
        css={css`
          margin-top: 32px;
          & a {
            text-decoration: none;
          }
        `}
      >
        <Typography
          variant="body1"
          css={{
            fontSize: 20,
            marginTop: theme.spacing(2.5),
            [theme.breakpoints.down('xs')]: {
              fontSize: 16,
            },
          }}
        >
          {message}
        </Typography>
        {message2 && (
          <Typography
            variant="body1"
            css={{
              fontSize: 20,
              marginTop: theme.spacing(2.5),
              [theme.breakpoints.down('xs')]: {
                fontSize: 16,
              },
            }}
          >
            {message2}
          </Typography>
        )}
      </Box>

      <Grid
        container
        spacing={2}
        css={{
          marginTop: 24,
          marginBottom: 24,
          justifyContent: 'center',
          ...(!showNextButton
            ? {
                display: 'flex',
                justifyContent: 'center',
              }
            : {}),
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
          },
        }}
      >
        {showBackButton && (
          <Grid item xs={12} md={3}>
            <PGEButton
              type="button"
              fullWidth
              variant="outlined"
              color="secondary"
              css={{
                fontSize: '18px',
                borderRadius: '30px',
                '.MuiButton-label': {
                  fontWeight: 500,
                },
                '&:hover': {
                  borderWidth: '2px',
                },
              }}
              onClick={() => window.history.back()}
            >
              {backButtonText}
            </PGEButton>
          </Grid>
        )}
        {showNextButton && (
          <Grid item xs={showBackButton ? 12 : 12} md={showBackButton ? 3 : 6}>
            <PGEButton
              type={'button'}
              fullWidth
              variant="contained"
              onClick={() => navigate(nextButtonRoute)}
              css={{
                backgroundColor: colors.orange,
                borderColor: colors.orange,
                fontSize: '18px',
                borderRadius: '30px',
                '.MuiButton-label': {
                  fontWeight: 500,
                },
                '&:hover': {
                  backgroundColor: colors.orangeBrown,
                  borderColor: colors.orange,
                  boxShadow: 'none',
                },
              }}
            >
              {nextButtonText}
            </PGEButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

const RebateOnlyIneligible: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const {
    content,
    richText,
    inlineRichText,
    isContentLoading,
  } = useContentMgmt(microcopyGroupId.PGE_PLUS_EV_PULSE_ENROLLMENT_INELIGIBLE);
  const location = useLocation();
  const {
    title,
    message,
    message2,
    learnMoreContent,
    showBackButton,
    backButtonText,
    showNextButton,
    nextButtonText,
    nextButtonRoute,
  } = (location.state as TRebateOnlyIneligibleState) || {};

  if (isContentLoading) {
    return <Backdrop forceOpen />;
  }

  return createIneligibleScreen(
    content.get(title ?? DEFAULT_VALUES.title),
    message === 'REBATE_ONLY_TIME_OF_THE_DAY' ? (
      <Fragment>
        <Typography
          css={{
            fontSize: '20px',
            '&>a': { textDecoration: 'none', fontWeight: 700 },
          }}
        >
          {inlineRichText(
            content.get('REBATE_ONLY_ONLY_EV_CHARGERS_ON_LIST_QUALIFY_DETAILS'),
          )}
          <PopoverToolTip
            popoverContent={content.get('EXISTING_CHARGER_UNAVILABLE_TOOLTIP')}
          />
        </Typography>
        <Typography
          css={{
            fontSize: '20px',
            display: 'inline',
          }}
        >
          {richText(content.get('REBATE_ONLY_TIME_OF_THE_DAY_BOTTOM'))}
        </Typography>
      </Fragment>
    ) : message === 'REBATE_ONLY_NOT_ACTIVE' ? (
      <Box>
        {richText(
          `${content.get('EV_CHARGER_INELIGIBLE_NOT_ACTIVE')} ${content.get(
            'EV_CHARGER_INELIGIBLE_NOT_ACTIVE_LINK_TO_START_SERVICE',
          )}`,
        )}
      </Box>
    ) : (
      richText(content.get(message ?? DEFAULT_VALUES.message))
    ),
    message === 'REBATE_ONLY_NOT_ACTIVE'
      ? richText(`${content.get('REUSABLE_NEED_HELP')}`)
      : message2 && richText(content.get(message2 || '')),
    showBackButton ?? true,
    content.get(backButtonText ?? DEFAULT_VALUES.backButtonText),
    showNextButton ?? true,
    content.get(nextButtonText ?? DEFAULT_VALUES.nextButtonText),
    nextButtonRoute ?? DEFAULT_VALUES.nextButtonRoute,
  );
};

export default RebateOnlyIneligible;
