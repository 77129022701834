import { Box, Typography } from '@material-ui/core';
import { navigate, Router } from '@reach/router';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import ROUTES from '../../routes';
import {
  TimeOfDayEligibilityType,
  TimeOfDayEnrollmentConfirmation,
  TimeOfDayEnrollmentExceptionType,
  TimeOfDayEnrollmentStatus,
} from '../../__generated__/pge-types';
import AccountDropdown from '../account-dropdown';
import Backdrop from '../backdrop';
import TimeOfDayEnroll from './enroll';
import TimeOfDayIneligible from './ineligible';
import TimeOfDayManage from './manage/TimeOfDayManage';
import TimeOfDayMeterException from './meter-exception';
import TimeOfDayPending from './pending/TimeOfDayPending';
import TimeOfDaySpecialHandling from './special-handling';
import TimeOfDaySuccess from './success';
import TimeOfDayUnenrollSuccess from './unenroll-success';
import TimeOfDayUnenroll from './unenroll/TimeOfDayUnenroll';
import useTimeOfDay from './useTimeOfDay';
import { isTimeOfDayEnrolled } from './util';

interface TimeOfDayProps {
  path?: string;
}
export const TimeOfDay: FC<TimeOfDayProps> = props => {
  const { t } = useTranslation();
  const { wrapWithLoader } = useWrapWithLoader();

  const [unEnrollmentReasonCode, setUnEnrollmentReasonCode] = useState('');

  const notificationContext = useContext(NotificationsContext);
  const {
    loading,
    timeOfDayInfo,
    submitEnrollment,
    submitUnenrollment,
  } = useTimeOfDay();
  const inEligibleHardTypes = [
    TimeOfDayEligibilityType.CustomerOrCoCustomer,
    TimeOfDayEligibilityType.Residential_07,
    TimeOfDayEligibilityType.SaStatusNone,
    TimeOfDayEligibilityType.UnenrolledLast_12Months,
  ];
  const statusNavigationMap: { [key: string]: string } = {
    [TimeOfDayEnrollmentStatus.Unenrolled]: ROUTES.TIMEOFDAY_ENROLL,

    [TimeOfDayEnrollmentStatus.Enrolled]: ROUTES.TIMEOFDAY_MANAGE,
    [TimeOfDayEnrollmentStatus.PendingUnenrolled]: ROUTES.TIMEOFDAY_MANAGE,
    [TimeOfDayEnrollmentStatus.PendingEnrolled]: ROUTES.TIMEOFDAY_PENDING,
  };
  useEffect(() => {
    if (loading) {
      return;
    }

    if (!timeOfDayInfo?.eligibility && !timeOfDayInfo?.enrollmentStatus) {
      return;
    }

    if (
      !timeOfDayInfo?.eligibility?.isEligible &&
      !isTimeOfDayEnrolled(timeOfDayInfo.enrollmentStatus)
    ) {
      if (
        timeOfDayInfo?.eligibility?.ineligibilityType?.some(inEligibleType =>
          inEligibleHardTypes.includes(inEligibleType),
        )
      ) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate(ROUTES.TIMEOFDAY_INELIGIBLE, { replace: true });
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.TIMEOFDAY_SPECIAL_HANDLING, { replace: true });
      return;
    }

    statusNavigationMap[timeOfDayInfo.enrollmentStatus] &&
      location.pathname !==
        statusNavigationMap[timeOfDayInfo.enrollmentStatus] &&
      navigate(statusNavigationMap[timeOfDayInfo.enrollmentStatus], {
        replace: true,
      });
  }, [timeOfDayInfo, loading]);

  const isMeterException = (result: TimeOfDayEnrollmentConfirmation) =>
    result?.exceptions?.some(
      e => e?.type === TimeOfDayEnrollmentExceptionType.Meter && e?.value,
    );

  const handleEnrollmentSubmit = wrapWithLoader(async () => {
    try {
      const result = await submitEnrollment();
      if (result?.isSuccess) {
        await navigate(
          isMeterException(result)
            ? ROUTES.TIMEOFDAY_METER_EXCEPTION
            : ROUTES.TIMEOFDAY_SUCCESS,
          { replace: true },
        );
        if (!isMeterException(result)) {
          notificationContext.setState({
            isOpen: true,
            severity: 'success',
            variant: 'filled',
            message: t('TOD_ENROLLMENT_SUCCESS_ALERT_MESSAGE'),
          });
        }
      } else {
        await navigate(ROUTES.ERROR);
      }
    } catch (e) {
      await navigate(ROUTES.ERROR);
    }
  });

  const handleUnenrollmentSubmit = wrapWithLoader(async () => {
    try {
      const result = await submitUnenrollment(unEnrollmentReasonCode);
      if (result?.isSuccess) {
        await navigate(ROUTES.TIMEOFDAY_UNENROLL_SUCCESS, { replace: true });
        notificationContext.setState({
          isOpen: true,
          severity: 'success',
          variant: 'filled',
          message: t('TOD_UNENROLLMENT_SUCCESS_ALERT_MESSAGE'),
        });
      } else {
        await navigate(ROUTES.ERROR);
      }
    } catch (e) {
      await navigate(ROUTES.ERROR);
    }
  });

  if (loading) {
    return <Backdrop forceOpen />;
  }

  return (
    <>
      <Typography variant={'h1'} noWrap>
        {t('TOD_TITLE_CASE')}
      </Typography>
      <Box mt={2} mb={1}>
        <AccountDropdown />
      </Box>
      <Router basepath="/">
        <TimeOfDayEnroll
          path={ROUTES.TIMEOFDAY_ENROLL}
          handleEnrollmentSubmit={handleEnrollmentSubmit}
          timeOfDayInfo={timeOfDayInfo}
        />
        <TimeOfDaySuccess path={ROUTES.TIMEOFDAY_SUCCESS} />
        <TimeOfDayMeterException path={ROUTES.TIMEOFDAY_METER_EXCEPTION} />
        <TimeOfDayPending path={ROUTES.TIMEOFDAY_PENDING} />
        <TimeOfDayManage
          path={ROUTES.TIMEOFDAY_MANAGE}
          timeOfDayInfo={timeOfDayInfo}
        />
        <TimeOfDayIneligible path={ROUTES.TIMEOFDAY_INELIGIBLE} />
        <TimeOfDaySpecialHandling path={ROUTES.TIMEOFDAY_SPECIAL_HANDLING} />
        <TimeOfDayUnenroll
          path={ROUTES.TIMEOFDAY_UNENROLL}
          unEnrollmentReasonCode={unEnrollmentReasonCode}
          setUnEnrollmentReasonCode={setUnEnrollmentReasonCode}
          handleUnenrollmentSubmit={handleUnenrollmentSubmit}
        />
        <TimeOfDayUnenrollSuccess path={ROUTES.TIMEOFDAY_UNENROLL_SUCCESS} />
      </Router>
    </>
  );
};

export default TimeOfDay;
