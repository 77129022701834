/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FC, ReactNode } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core';
import { useIsMobile } from '../../util/style-utils';

interface CheckBoxesProps {
  options: { label: string; value: string; required?: string }[];
  state: string[];
  setState: (options: string[]) => void;
}

export const CheckBoxes: FC<CheckBoxesProps> = ({
  options,
  state,
  setState,
}) => {
  const handleChange = (value: string) => () => {
    isChecked(value)
      ? setState(state.filter(opt => opt !== value))
      : setState([...state, value]);
  };

  const isChecked = (value: string) => state.includes(value);
  const isMobile = useIsMobile();

  return (
    <FormGroup style={{ flexDirection: isMobile ? 'column' : 'row' }}>
      {options.map(option => (
        <FormControlLabel
          control={
            <Checkbox
              color={isChecked(option.value) ? 'primary' : 'default'}
              size={'medium'}
              css={{
                padding: '0 10px 16px 0',
              }}
            />
          }
          checked={isChecked(option.value)}
          onChange={handleChange(option.value)}
          label={
            <Typography css={{ fontSize: isMobile ? 16 : 20 }}>
              {option.label}
              {option?.required && (
                <span css={{ color: 'red' }}>{option?.required}</span>
              )}
            </Typography>
          }
          css={{ display: 'flex', alignItems: 'flex-start', marginLeft: 0 }}
        />
      ))}
    </FormGroup>
  );
};
