import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { useAccountCustomerDashboard } from '../../hooks/useAccountCustomerDashboard';
import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import AccountDropdown from '../account-dropdown';
import { GoodEnergyQuiz } from './components/GoodEnergyQuiz';
import { GreenFuture } from './components/GreenFuture';
import { HabitatSupport } from './components/HabitatSupport';
import { Loader } from './components/Loader';
import { Programs } from './components/Programs';
import { CommunitySolar } from './components/community-solar';
import { NetMetering } from './components/net-metering/';
import { PeakTimeRebates } from './components/peak-time-rebates';
import { SmartBattery } from './components/smart-battery';
import { SmartCharging } from './components/smart-charging/SmartCharging';
import { SmartThermostat } from './components/smart-thermostat/SmartThermostat';
import { TimeOfDay } from './components/time-of-day/TimeOfDay';
import { useMyPrograms } from './useMyPrograms';
import { SupportGreenFutureCard } from './components/SupportGreenFutureCard';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.spacing(4.5),
      lineHeight: theme.typography.pxToRem(28),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
      color: colors.lightCyan,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        marginBottom: '16px',
      },
    },
    accountBox: {
      margin: '16px 0px 32px',
      [theme.breakpoints.down('sm')]: {
        margin: '16px 0px 20px',
      },
    },
  }),
);

type Props = {
  path?: string;
};

export default function MyPrograms(_: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    energyShiftCardsData,
    energyShiftCardsLoading,
    energyShiftCardsError,
    renewablesCardsData,
    renewablesCardsLoading,
    renewablesCardsError,
    ytdFlexLoadEarnings,
    isEnrollementStatusLoading,
  } = useMyPrograms();
  const {
    showLegacyUI,
    loading: showLegacyUILoading,
  } = useAccountCustomerDashboard();

  if (showLegacyUILoading) {
    return <Loader />;
  }

  const isEnergyShiftCardsNotAvailable: boolean =
    energyShiftCardsData.peakTimeRebates.cardType === 'unknown' &&
    energyShiftCardsData.timeOfDay.cardType === 'unknown' &&
    energyShiftCardsData.smartThermostat.cardType === 'unknown' &&
    energyShiftCardsData.smartCharging.cardType === 'unknown' &&
    energyShiftCardsData.smartBattery.cardType === 'unknown';

  return (
    <>
      <Typography className={classes.heading}>{t('MY_PROGRAMS')}</Typography>
      <Box className={classes.accountBox}>
        <AccountDropdown />
      </Box>
      {showLegacyUI && (
        <Typography variant="h3">
          {t('MY_PROGRAMS_MESSAGE_FOR_NON_RES')}
        </Typography>
      )}
      {!showLegacyUI && (
        <>
          <Programs
            category="energy-shift"
            isEnergyShiftCardsNotAvailable={isEnergyShiftCardsNotAvailable}
            isDataLoading={isEnrollementStatusLoading}
            ytdFlexLoadEarnings={ytdFlexLoadEarnings || ''}
          >
            {energyShiftCardsLoading && <Loader />}
            {!energyShiftCardsLoading && energyShiftCardsError && (
              <Typography>{t('ERROR_AUTH_UNEXPECTED')}</Typography>
            )}
            {!energyShiftCardsLoading && !energyShiftCardsError && (
              <>
                {energyShiftCardsData.peakTimeRebates.cardType !==
                  'unknown' && (
                  <PeakTimeRebates
                    cardType={energyShiftCardsData.peakTimeRebates.cardType}
                    enrollmentStatus={
                      energyShiftCardsData.peakTimeRebates.enrollmentStatus
                    }
                    activePTRSeason={
                      energyShiftCardsData.peakTimeRebates.activePTRSeason
                    }
                    lastPTRSeason={
                      energyShiftCardsData.peakTimeRebates.lastPTRSeason
                    }
                    nextPTRSeason={
                      energyShiftCardsData.peakTimeRebates.nextPTRSeason
                    }
                    peakTimeEvents={
                      energyShiftCardsData.peakTimeRebates.peakTimeEvents
                    }
                    seasonalDates={
                      energyShiftCardsData.peakTimeRebates.seasonalDates
                    }
                    totalEarnedCredit={
                      energyShiftCardsData.peakTimeRebates.totalEarnedCredit
                    }
                  />
                )}
                {energyShiftCardsData.timeOfDay.cardType !== 'unknown' && (
                  <TimeOfDay
                    cardType={energyShiftCardsData.timeOfDay.cardType}
                    planSavings={energyShiftCardsData.timeOfDay.planSavings}
                    isEnrolled={energyShiftCardsData.timeOfDay.isEnrolled}
                    offPeakCharges={
                      energyShiftCardsData.timeOfDay.offPeakCharges
                    }
                    midPeakCharges={
                      energyShiftCardsData.timeOfDay.midPeakCharges
                    }
                    onPeakCharges={energyShiftCardsData.timeOfDay.onPeakCharges}
                    annualLookBackEarnedCredit={
                      energyShiftCardsData.timeOfDay.annualLookBackEarnedCredit
                    }
                  />
                )}
                {energyShiftCardsData.smartBattery.cardType !== 'unknown' && (
                  <SmartBattery
                    cardType={energyShiftCardsData.smartBattery.cardType}
                    currentBillCreditAmount={
                      energyShiftCardsData.smartBattery.currentBillCreditAmount
                    }
                    currentBillKwh={
                      energyShiftCardsData.smartBattery.currentBillKwh
                    }
                    peakTimeSeason={
                      energyShiftCardsData.smartBattery.peakTimeSeason
                    }
                    ytdCreditAmount={
                      energyShiftCardsData.smartBattery.ytdCreditAmount
                    }
                    ytdKwh={energyShiftCardsData.smartBattery.ytdKwh}
                  />
                )}
                {energyShiftCardsData.smartThermostat.cardType !==
                  'unknown' && (
                  <SmartThermostat
                    cardType={energyShiftCardsData.smartThermostat.cardType}
                    seasonalDates={
                      energyShiftCardsData.smartThermostat.seasonalDates
                    }
                    lastSeason={energyShiftCardsData.smartThermostat.lastSeason}
                    lastSeasonEarnedCredit={
                      energyShiftCardsData.smartThermostat
                        .lastSeasonEarnedCredit
                    }
                  />
                )}
                {energyShiftCardsData.smartCharging.cardType !== 'unknown' && (
                  <SmartCharging
                    cardType={energyShiftCardsData.smartCharging.cardType}
                    lastSeasonEarnedCredit={
                      energyShiftCardsData.smartCharging.lastSeasonEarnedCredit
                    }
                    activeSeason={
                      energyShiftCardsData.smartCharging.activeSeason
                    }
                  />
                )}
                {isEnergyShiftCardsNotAvailable && <GoodEnergyQuiz />}
              </>
            )}
          </Programs>
          <Programs category="renewables">
            {renewablesCardsLoading && <Loader />}
            {!renewablesCardsLoading && renewablesCardsError && (
              <Typography>{t('ERROR_AUTH_UNEXPECTED')}</Typography>
            )}
            {!renewablesCardsLoading && !renewablesCardsError && (
              <>
                {renewablesCardsData.netMetering.cardType !== 'unknown' && (
                  <NetMetering
                    cardType={renewablesCardsData.netMetering.cardType}
                    date={renewablesCardsData.netMetering.date}
                    monthlyBill={renewablesCardsData.netMetering.monthlyBill}
                  />
                )}
                {renewablesCardsData.greenFuture.cardType !== 'unknown' && (
                  <>
                    <GreenFuture
                      cardType={renewablesCardsData.greenFuture.cardType}
                      kwh={renewablesCardsData.greenFuture.kwh}
                      consumptionPercentage={
                        renewablesCardsData.greenFuture.consumptionPercentage
                      }
                    />
                    {renewablesCardsData.habitatSupport.isEnrolled !==
                      'unknown' && (
                      <HabitatSupport
                        isEnrolled={
                          renewablesCardsData.habitatSupport.isEnrolled ?? false
                        }
                      />
                    )}
                  </>
                )}
                {renewablesCardsData.communitySolar.cardType !== 'unknown' && (
                  <CommunitySolar
                    cardType={renewablesCardsData.communitySolar.cardType}
                    amountDetails={
                      renewablesCardsData.communitySolar.amountDetails
                    }
                    chargesAndCredits={
                      renewablesCardsData.communitySolar.chargesAndCredits
                    }
                  />
                )}
                {renewablesCardsData.netMetering.cardType === 'unknown' &&
                  renewablesCardsData.greenFuture.cardType === 'unknown' &&
                  renewablesCardsData.communitySolar.cardType === 'unknown' && (
                    <SupportGreenFutureCard />
                  )}
              </>
            )}
          </Programs>
        </>
      )}
    </>
  );
}
