import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';

import React from 'react';
import { Close } from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeBtn: {
      position: 'absolute',
      top: 24,
      right: 24,
      padding: 0,
    },
    popupContent: {
      padding: '0px',
    },
    popupContentArea: {
      padding: theme.spacing(3, 12),
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing(2, 5)} !important`,
      },
    },
    addPersonContent: {
      padding: '0px 24px 24px 24px',
      minWidth: '447px',
      [theme.breakpoints.down('sm')]: {
        padding: '16px 24px',
        minWidth: 'auto',
      },
    },
  }),
);

interface Props {
  open: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  mainTitle?: string;
  isDynamicClass?: boolean;
}

export default ({
  open,
  onClose,
  title,
  children,
  isDynamicClass,
  mainTitle,
}: Props) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' && isDynamicClass) {
            return;
          } else {
            onClose;
          }
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        scroll="body"
        disableScrollLock
      >
        <DialogTitle
          style={
            isDynamicClass
              ? { padding: '16px 24px 0px 24px' }
              : { padding: '16px 24px' }
          }
        >
          <Grid className={'outageTitle'} container justify="space-between">
            {mainTitle && (
              <Typography
                variant={'h2'}
                noWrap={isDynamicClass ? false : true}
                gutterBottom
              >
                {mainTitle}
              </Typography>
            )}
            <Typography
              variant={isDynamicClass ? 'subtitle1' : 'h2'}
              noWrap={isDynamicClass ? false : true}
              gutterBottom
            >
              {title}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.popupContent}>
          <Paper
            className={
              isDynamicClass
                ? classes.addPersonContent
                : classes.popupContentArea
            }
          >
            {children}
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};
