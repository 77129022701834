/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { Fragment } from 'react';
import { Box, Divider, FormHelperText, Grid } from '@material-ui/core';
import { RouteComponentProps, navigate, useLocation } from '@reach/router';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import useFormState from '../../../../../hooks/useFormState';
import { ConnectTeslaToEVPulse } from '../rebateOnlyForm.types';
import { connectTeslaToEVPulseValidateFunction } from '../rebateOnlyForm.rules';
import { Question } from '../../../../pge-plus-common/Typography';
import RadioButtonGroup from '../../../../pge-plus-common/RadioButtonGroup';
import { GridItem } from '../../../../pge-plus-common/Grid';
import colors from '../../../../../themes/main-colors';
import { ProceedOrCancelButton } from '../../../../pge-plus-common/StepperForm';
import routes from '../../../../../routes';
import { SaveAndComeBackLink } from '../../../../pge-plus-common/SaveAndComeBackLink';
import { useRebateOnlyFormState } from '../../../../../providers/RebateOnlyFormStateProvider';
import useUtil from '../../../../need-more-time-to-pay/useUtil';
import useWrapWithLoader from '../../../../../hooks/useWrapWithLoading';
import {
  ApplicationErSectionType,
  SectionErRebateConfirm,
} from '../../../../../__generated__/pgeplus-types';
import useUpdateERSections from '../../../../../hooks/pge-plus/useUpdateERSections';
import { rebateConfirmERFormStateAdapter } from '../rebateOnlyFormState.adapter';

type TBackRouteState = {
  backRoute: string;
};

const ConnectTeslaToEvPulseApp: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_EV_PULSE_REBATE_OFFER,
  );
  const location = useLocation();
  const { backRoute } = (location.state as TBackRouteState) || {};
  const {
    formState: { rebateConfirm, rebateInfo, application },
    getSectionData,
    updateFormState,
  } = useRebateOnlyFormState();
  const form = useFormState(
    rebateConfirm.connectTeslaToEVPulse!, //TODO: This should be taken from the global app state
    {
      validate: connectTeslaToEVPulseValidateFunction(),
      validationContext: {
        content,
      },
    },
  );
  const { wrapWithLoader } = useWrapWithLoader();
  const sectionDataRebateConfirm: SectionErRebateConfirm = getSectionData(
    ApplicationErSectionType.ErRebateConfirm,
  );
  const { updateSectionRebateConfirm } = useUpdateERSections();
  const { setErrorNotification } = useUtil();
  const onSubmit = wrapWithLoader(async (data: ConnectTeslaToEVPulse) => {
    console.log('Submitting data', data);
    try {
      const response = await updateSectionRebateConfirm(
        rebateConfirmERFormStateAdapter().fromFormState(
          {
            ...rebateConfirm,
            connectTeslaToEVPulse: data,
            rebateQualification:
              !rebateInfo.chargerValidation?.isQualifiedProduct &&
              rebateInfo.evPulse?.vin
                ? undefined
                : {
                    ...rebateConfirm.rebateQualification,
                    haveChargerActivated: undefined,
                  },
            iqbd:
              !rebateInfo.chargerValidation?.isQualifiedProduct &&
              rebateInfo.evPulse?.vin
                ? undefined
                : rebateConfirm.iqbd,
            chargerInstallInfo:
              !rebateInfo.chargerValidation?.isQualifiedProduct &&
              rebateInfo.evPulse?.vin
                ? undefined
                : rebateConfirm.chargerInstallInfo,
          },
          application?.id!,
          sectionDataRebateConfirm?.id,
        ),
      );
      if (!response.data?.updateSectionERRebateConfirm.section.errors.length) {
        if (response.data?.updateSectionERRebateConfirm?.section.data) {
          const updatedValues = rebateConfirmERFormStateAdapter().toFormState(
            response.data?.updateSectionERRebateConfirm?.section.data,
          );
          updateFormState('rebateConfirm', {
            ...updatedValues,
          });
        }
        if (data.isTeslaConnectedToEVPulse) {
          void navigate(routes.PGE_PLUS_REBATE_ONLY_EV_PULSE_REBATE_RESULTS);
        } else {
          void navigate(routes.PGE_PLUS_REBATE_ONLY_CHARGER_INSTRUCTIONS, {
            state: { chargerModel: 'EvPulse' },
          });
        }
      }
      if (response.errors) {
        throw new Error();
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      const response = await updateSectionRebateConfirm({
        ...rebateConfirmERFormStateAdapter().fromFormState(
          {
            ...rebateConfirm,
            connectTeslaToEVPulse: form.values,
            rebateQualification: {
              ...rebateConfirm.rebateQualification,
              haveChargerActivated: undefined,
            },
            iqbd: undefined,
          },
          application?.id!,
          sectionDataRebateConfirm?.id,
        ),
        saveAndComeBack: true,
      });
      if (
        response?.errors ||
        response.data?.updateSectionERRebateConfirm?.validation?.errors ||
        response.data?.updateSectionERRebateConfirm?.section?.errors.length !==
          0
      ) {
        throw new Error();
      } else {
        void navigate(routes.ACCOUNT);
      }
    } catch {
      setErrorNotification(true);
    }
  });

  return (
    <Fragment>
      <form
        onSubmit={form.submit(values => onSubmit(values), console.log)}
        noValidate
      >
        <Grid
          container
          css={{
            rowGap: 24,
          }}
        >
          <GridItem>
            <Question
              title={content.get('HAVE_YOU_CONNECTED_YOUR_TESLA_TITLE')}
              detail={content.get('CONNECT_YOUR_TESLA_DETAILS')}
            />
          </GridItem>
          <GridItem>
            <Box
              css={{
                width: 'max-content',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <RadioButtonGroup
                options={[
                  {
                    label: content.get('REUSABLE_YES') || '',
                    value: true,
                  },
                  {
                    label: content.get('REUSABLE_NO') || '',
                    value: false,
                  },
                ]}
                state={form.values?.isTeslaConnectedToEVPulse}
                setState={e => form.setValue('isTeslaConnectedToEVPulse', e)}
              />
              {form.errors?.isTeslaConnectedToEVPulse && (
                <FormHelperText error>
                  {form.errors?.isTeslaConnectedToEVPulse}
                </FormHelperText>
              )}
            </Box>
          </GridItem>
          <GridItem>
            <Divider css={{ color: colors.gray93, marginTop: '6px' }} />
          </GridItem>
          <GridItem>
            <ProceedOrCancelButton
              back={
                rebateInfo.chargerValidation?.isQualifiedProduct
                  ? routes.PGE_PLUS_REBATE_ONLY_EVPULSE_ELIGIBLE
                  : routes.PGE_PLUS_REBATE_ONLY_ELIGIBILITY_CONFIRMATION
              }
              backRouteState={
                !rebateInfo.chargerValidation?.isQualifiedProduct
                  ? {
                      message: 'EV_PULSE_REBATE_OFFER_DETAILS',
                      backButtonRoute:
                        routes.PGE_PLUS_REBATE_ONLY_CUSTOMER_INFORMATION,
                      nextButtonRoute:
                        routes.PGE_PLUS_REBATE_ONLY_CONNECT_TESLA,
                    }
                  : {}
              }
              disable={
                form.values?.isTeslaConnectedToEVPulse === undefined ||
                form.values?.isTeslaConnectedToEVPulse === null
              }
            />
          </GridItem>
        </Grid>
      </form>
      <SaveAndComeBackLink
        content={content}
        onSaveAndExit={handleSaveAndExit}
      />
    </Fragment>
  );
};

export default ConnectTeslaToEvPulseApp;
