import React, { useEffect } from 'react';
import {
  Paper,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import { useIsMobile } from '../../../util/style-utils';
import PGEButton from '../../buttons';
import ROUTES from '../../../routes';
import { navigate } from '@reach/router';

interface Props {
  path: string;
  setShowProgressBar: (arg0: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    '& h2': {
      fontFamily: theme.typography.h2.fontFamily,
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
    },
  },
}));

export const MoveServiceAddressIneligibleAlert = ({
  setShowProgressBar,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();

  useEffect(() => {
    setShowProgressBar(false);

    return function cleanup() {
      setShowProgressBar(true);
    };
  }, []);

  return (
    <Paper>
      <Card>
        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="h2"
                component="div"
                className={classes.mainContent}
              >
                {`${t('WE_COULD_NOT_LOCATE_YOUR_ADDRESS')}.`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="div">
                {t('ADDRESS_MAY_BE_OUT_OF_SERVICE_AREA')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="div"
                style={{ display: 'inline' }}
              >
                {t('PLEASE')}{' '}
                <a
                  href={ROUTES.REVIEW_SERVICE_MAP}
                  target="_blank"
                  rel="noopener"
                >
                  {t('ADDRESS_INELIGIBLE_ERROR_2')}
                </a>{' '}
                {t('OR_CALL')}{' '}
                <a href={`tel:${t('PGE_PHONE')}`}>{t('PGE_PHONE')}</a>{' '}
                {`${t('FOR_ASSISTANCE')}.`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                component="div"
                style={{ display: 'inline' }}
              >
                {t('ADDRESS_INELIGIBLE_ERROR_3')}{' '}
                <a
                  href={ROUTES.STOP_SERVICE}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {`${t('STOP_YOUR_CURRENT_SERVICE')}.`}
                </a>
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="row"
              justify={isMobile ? 'center' : 'flex-end'}
              spacing={1}
              wrap="wrap-reverse"
            >
              <Grid item xs={10} md="auto">
                <PGEButton
                  fullWidth
                  onClick={() => navigate(ROUTES.MOVE_SERVICE)}
                  color="secondary"
                  variant="outlined"
                >
                  {t('BACK')}
                </PGEButton>
              </Grid>
              <Grid item xs={10} md="auto">
                <PGEButton
                  fullWidth
                  onClick={async () => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    navigate(ROUTES.ACCOUNT);
                  }}
                >
                  {t('OK')}
                </PGEButton>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};
