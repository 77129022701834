import { useState } from 'react';
import { EnrollmentAccount, PricingPlan } from '../protected-pages/ess/electionWindow.types';
import { useTranslation } from './useTranslation';

export default () => {
  const { t } = useTranslation();

  const annualPricingPlans = [
    { id: 'ALT', name: t('PLANS_ALTERNATE_PRICING') },
    { id: 'A', name: t('PLANS_ANNUAL_COST') },
    { id: 'CAN', name: t('CANCEL_PREVIOUS_REQUEST') },
  ];

  const quaterlyPricingPlans = [
    { id: 'ALT', name: t('PLANS_ALTERNATE_PRICING') },
    { id: 'CAN', name: t('CANCEL_PREVIOUS_REQUEST') },
  ];

  const [enrollmentAccounts, setEnrollmentAccounts] = useState([] as EnrollmentAccount[]);

  const [changedAccounts, setChangedAccounts] = useState([] as EnrollmentAccount[]);

  const [availablePlans, setAvailablePlans] = useState([] as PricingPlan[]);

  return {
    enrollmentAccounts,
    annualPricingPlans,
    quaterlyPricingPlans,
    availablePlans,
    setAvailablePlans,
    changedAccounts,
    setChangedAccounts,
    setEnrollmentAccounts,
  };
};
