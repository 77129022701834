import {
  useContext,
  useRef,
  useEffect,
  useReducer,
  useState,
  useMemo,
} from 'react';
import GroupListReducer, {
  GroupSort,
} from '../../account-summary/multi-accounts/groupListReducer';
import { useTranslation } from '../../../hooks/useTranslation';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import useAccountCustomer, {
  getAccountInfoWithParams,
} from '../../../hooks/useAccountCustomer';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { useLocation, navigate } from '@reach/router';
import {
  Group,
  GroupType,
  Mutation,
  ErrorReason,
  GroupStatus,
} from '../../../__generated__/pge-types';
import useMulticheckState from '../../../hooks/useMulticheckState';
import { useMutation } from '@apollo/react-hooks';
import {
  deleteGroupMutation,
  getAccountsList,
  updateAccountGroupsMutation,
} from '../../../queries/manageAccountGroups.query';
import AccountList, {
  makeParams,
} from '../../../components/account-summary/multi-accounts/accountListReducer';
import { featureFlagsContext } from '../../../providers/FeatureFlagsProvider';
import { VirtualAccountType } from '../../../constants/customEnum';

import { useSetSelectedGroupId } from '../../../hooks/useSelectedGroupId';

type LocationState = {
  firstGroup?: string;
};

export function useAccountGroupsLanding() {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationsContext);
  const { isClosedAccountsEnabled } = useContext(featureFlagsContext);
  const {
    loading,
    groups,
    refetch: refetchAccountCustomer,
    writeCustomerCache,
  } = useAccountCustomer();
  const { wrapWithLoader } = useWrapWithLoader();
  const { setSelectedGroupId } = useSetSelectedGroupId();
  const location = useLocation();
  const locationState = useRef<LocationState>((location.state || {}) as any);

  useEffect(() => {
    // Unset location.state so that a refresh will bring everything back to normal
    if (location.state) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate('', { replace: true, state: undefined });
    }
  }, []);

  const [groupListParams, dispatch] = useReducer(
    GroupListReducer.reducer,
    GroupListReducer.initialState,
  );

  const [showRemoveGroupModal, toggleRemoveGroupModal] = useState(false);
  const [groupToRemove, setGroupIdToRemove] = useState('');

  let filteredGroups: Group[] = groups.filter(
    group => group.type !== 'Virtual',
  );

  const showMakePrimary = () => {
    const noOfAccounts =
      groups.find(grp => grp.groupId === VirtualAccountType.ALL_ACCTS)
        ?.numberOfAccounts || 0;
    return !Boolean(noOfAccounts >= 2 && noOfAccounts <= 5);
  };

  if (groupListParams.query) {
    filteredGroups = filteredGroups?.filter((grp: Group) =>
      grp?.groupName
        ?.toLowerCase()
        .startsWith(groupListParams.query.toLowerCase()),
    );
  }

  if (groupListParams.sortBy) {
    filteredGroups = filteredGroups.sort((a: Group, b: Group) => {
      if (locationState.current.firstGroup === a.groupId) {
        return -1;
      } else if (locationState.current.firstGroup === b.groupId) {
        return 1;
      }
      switch (groupListParams.sortBy) {
        case GroupSort.AToZ:
          if (a.groupName && b.groupName) {
            if (a.groupName?.toUpperCase() < b.groupName?.toUpperCase()) {
              return -1;
            }
            if (a.groupName?.toUpperCase() > b.groupName?.toUpperCase()) {
              return 1;
            }
          }
          return 0;
        case GroupSort.ZToA:
          if (a.groupName && b.groupName) {
            if (a.groupName?.toUpperCase() > b.groupName?.toUpperCase()) {
              return -1;
            }
            if (a.groupName?.toUpperCase() < b.groupName?.toUpperCase()) {
              return 1;
            }
          }
          return 0;
        case GroupSort.Grouptype:
          if (a.type && b.type) {
            if (a.type < b.type) {
              return -1;
            }
            if (a.type > b.type) {
              return 1;
            }
          }
          return 0;
        case GroupSort.Numberofaccounts:
          return (a.numberOfAccounts || 0) - (b.numberOfAccounts || 0);
      }
    });
  }

  let paginatedGroups = filteredGroups;
  if (groupListParams.pageSize !== 'All') {
    paginatedGroups = filteredGroups?.slice(
      (groupListParams.page - 1) * groupListParams.pageSize,
      groupListParams.page * groupListParams.pageSize,
    );
  }
  const checkOptions = useMemo(
    () =>
      filteredGroups
        ?.filter(
          grp =>
            grp.type !== GroupType.Automatic || !grp.isLoggedOnUserAutoGroup,
        )
        .map(grp => grp.groupId) || [],
    [filteredGroups],
  );

  const multicheckState = useMulticheckState(checkOptions);

  const [deleteGrp] = useMutation<Mutation>(deleteGroupMutation);

  const [updateGroupDefaultStatus] = useMutation<Mutation>(
    updateAccountGroupsMutation,
  );

  const isRemoveButtonDisabled = multicheckState.getSelected().length === 0;

  const setGroupAsDefault = wrapWithLoader(async (group: Group) => {
    try {
      const result = await updateGroupDefaultStatus({
        variables: {
          payload: {
            groupId: group.groupId,
            groupName: group.groupName,
            isDefault: true,
          },
        },
        update(cache, { data: { updateGroup } }: any) {
          const updateGroupSuccess = updateGroup?.success || false;
          if (updateGroupSuccess) {
            const { getAccountInfo }: any = cache.readQuery({
              query: getAccountInfoWithParams,
              variables: {
                params: {
                  removeInactiveAccounts: isClosedAccountsEnabled,
                },
              },
            });
            const filterdGroups = getAccountInfo.groups.map((grp: any) => {
              if (grp?.groupId === group.groupId) {
                grp.isDefault = true;
              } else {
                grp.isDefault = false;
              }
            });
            setSelectedGroupId(group.groupId);
            cache.writeQuery({
              query: getAccountInfoWithParams,
              variables: {
                params: {
                  removeInactiveAccounts: isClosedAccountsEnabled,
                },
              },
              data: {
                getAccountInfo: {
                  ...getAccountInfo,
                  groups: filteredGroups,
                },
              },
            });
          }
        },
      });
      const success = result?.data?.updateGroup?.success || false;
      if (success) {
        return onRemoveSuccess(t('ACCOUNT_GROUP_PRIMARY_SUCCESS'));
      } else {
        return handleErrors(t('SET_ACCOUNT_GROUP_PRIMARY_ERROR'));
      }
    } catch (e) {
      return handleErrors(t('SET_ACCOUNT_GROUP_PRIMARY_ERROR'));
    }
  });

  const onRemoveSuccess = (message: string): void => {
    notificationContext.setState({
      isOpen: true,
      severity: 'success',
      variant: 'filled',
      message: message,
    });
  };

  const handleMultiGroupsDelete = wrapWithLoader(async (groupIds: string[]) => {
    try {
      toggleRemoveGroupModal(false);
      setGroupIdToRemove('');
      const result = await deleteGrp({
        variables: {
          payload: {
            groups: groupIds.map(groupId => ({ groupId })),
          },
        },
        refetchQueries: [
          {
            query: getAccountsList,
            variables: {
              params: makeParams(
                VirtualAccountType.ALL_ACCTS,
                AccountList.initialState,
              ),
            },
          },
          {
            query: getAccountInfoWithParams,
            variables: {
              params: {
                removeInactiveAccounts: isClosedAccountsEnabled,
              },
            },
          }
        ],
        awaitRefetchQueries: true,
        update(cache, { data: { deleteGroup } }: any) {
          const deleteGroupSuccess = deleteGroup?.success || false;
          if (deleteGroupSuccess) {
            const { getAccountInfo }: any = cache.readQuery({
              query: getAccountInfoWithParams,
              variables: {
                params: {
                  removeInactiveAccounts: isClosedAccountsEnabled,
                },
              },
            });
            const filterdGroups = getAccountInfo.groups.filter(
              (grp: any) => groupIds.indexOf(grp?.groupId) === -1,
            );
            const deletedGroups = getAccountInfo.groups.filter((grp: any) =>
              groupIds.includes(grp?.groupId),
            );
            const noOfAccounts = deletedGroups.reduce(
              (total: number, currentGroup: any) =>
                total + currentGroup.numberOfAccounts,
              0,
            );

            const allAcctGroups = filterdGroups.find(
              (group: Group) => group.groupId === VirtualAccountType.ALL_ACCTS,
            );
            // with reference update , the number of accounts value in all account group is updated.
            allAcctGroups.numberOfAccounts =
              allAcctGroups.numberOfAccounts - noOfAccounts;
            cache.writeQuery({
              query: getAccountInfoWithParams,
              variables: {
                params: {
                  removeInactiveAccounts: isClosedAccountsEnabled,
                },
              },
              data: {
                getAccountInfo: {
                  ...getAccountInfo,
                  groups: filterdGroups,
                },
              },
            });
            writeCustomerCache({
              groups: filterdGroups,
            });
          }
        },
      });
      const success = result?.data?.deleteGroup?.success || false;
      const reason = result?.data?.deleteGroup?.errorReason;
      const groupStatus = result?.data?.deleteGroup?.groupStatus;
      if (success) {
        if (groupIds.length > 1) {
          onRemoveSuccess(
            t('DELETE_MULTIPLE_GROUPS', {
              NUMBER: String(groupIds.length),
            }),
          );
        } else {
          const [groupId] = groupIds;
          const group = groups.find(grp => grp.groupId === groupId)!;
          onRemoveSuccess(
            t('DELETE_GROUP', {
              NAME: group.groupName,
              NUMBER: String(group.numberOfAccounts),
            }),
          );
        }
      } else {
        if (groupStatus === GroupStatus.Partial) {
          await refetchAccountCustomer();
        }
        if (reason === ErrorReason.DefaultGroup) {
          return handleErrors(
            t('DELETE_DEFAULT_GROUP_ERROR', {
              PGE_PHONE: t('PGE_PHONE'),
            }),
          );
        } else if (reason === ErrorReason.CustomGroupIsDefaultGroup) {
          return handleErrors(
            t('DELETE_DEFAULT_CUSTOM_GROUP_ERROR', {
              PGE_PHONE: t('PGE_PHONE'),
            }),
          );
        } else if (reason === ErrorReason.CannotRemoveDefaultAccount) {
          return handleErrors(t('DELETE_GROUP_WITH_PRIMARY_ACCOUNT_ERROR'));
        } else if (reason === ErrorReason.DelCustomGroupTechnicalError) {
          return handleErrors(
            t('DELETE_CUSTOM_GROUP_TECHNICAL_ERROR', {
              PGE_PHONE: t('PGE_PHONE'),
            }),
          );
        } else if (reason === ErrorReason.DelAutoAssociationTechnicalError) {
          return handleErrors(
            t('DELETE_GROUP_TECHNICAL_ERROR ', {
              PGE_PHONE: t('PGE_PHONE'),
            }),
          );
        }
        return handleErrors(t('DELETE_GROUP_ERROR'));
      }
    } catch (error) {
      toggleRemoveGroupModal(false);
      setGroupIdToRemove('');
      return handleErrors(t('DELETE_GROUP_ERROR'));
    }
  });

  const handleErrors = (message: string) => {
    notificationContext.setState({
      severity: 'error',
      isOpen: true,
      variant: 'filled',
      title: message,
    });
  };

  return {
    loading,
    state: {
      groups: paginatedGroups,
    },
    sortFilterProps: {
      dispatch,
      totalCount: filteredGroups.length,
      groupListParams,
      GroupListReducer,
    },
    multicheckState,
    isRemoveButtonDisabled,
    showRemoveGroupModal,
    toggleRemoveGroupModal,
    handleMultiGroupsDelete,
    groupToRemove,
    setGroupIdToRemove,
    setGroupAsDefault,
    showMakePrimary,
  };
}
