import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  actionArea: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& Button': {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        margin: theme.spacing(3),
      },
    },
  },
  daySelectionArea: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  cardContent: {
    display: 'flex',
  },
  errorText: {
    color: `${theme.palette.error.main} !important`,
  },
}));
