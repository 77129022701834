import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { navigate } from 'gatsby';
import React from 'react';

import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import Button from '../../buttons';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(3),
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  card: {
    minWidth: 275,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '1em 2em',
    alignItems: 'center',
    width: '100%',
  },
  textPrimary: {
    color: colors.noirBlur,
  },
  buttonContainer: (props: { width: string | undefined }) => ({
    display: 'flex',
    flexDirection: props.width === 'xs' ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minWidth: '9em',
    padding: '1em',
  }),
  button: (props: { width: string | undefined }) => ({
    margin: '1em',
    minWith: '9em',
    width: props.width === 'xs' ? '9em' : 'inherit',
  }),
}));

const AddAccountHeader = (props: { width?: string | undefined } | any) => {
  const classes = useStyles(props);
  const { t } = useTranslation();

  return (
    <Paper className={classes.paper}>
      <Card className={classes.card}>
        <CardContent className={classes.buttonContainer}>
          <Button
            size={'large'}
            data-testid={'create-residential-button'}
            className={classes.button}
            variant={'outlined'}
            color={'secondary'}
            onClick={() => navigate(ROUTES.MANAGE_RESIDENTIAL_ACCOUNT)}
          >
            {t('Add residential account')}
          </Button>
          <Button
            size={'large'}
            data-testid={'create-business-button'}
            className={classes.button}
            variant={'outlined'}
            color={'secondary'}
            onClick={() => navigate(ROUTES.MANAGE_BUSINESS_ACCOUNT)}
          >
            {t('Add business account')}
          </Button>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default AddAccountHeader;
