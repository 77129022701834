import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from '../../hooks/useTranslation';
import { Grid, CardContent, Card, Typography } from '@material-ui/core';
import { navigate } from 'gatsby';
import ROUTES from '../../routes';
import PGEButton from '../buttons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionArea: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    submitButton: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
);

const ActionNotAvailable: FunctionComponent = () => {
  const classes = useStyles();

  const { richT, t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography variant={'body1'}>
              {richT('ACCOUNT_ACTION_NOT_AVAILABLE_CALL_CUSTOMER_SERVICE')}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} className={classes.actionArea}>
        <PGEButton
          className={classes.submitButton}
          onClick={() => navigate(ROUTES.ACCOUNT)}
          type={'submit'}
          variant="contained"
          color="primary"
          data-testid="change-password-button"
        >
          {t('DONE')}
        </PGEButton>
      </Grid>
    </Grid>
  );
};

export default ActionNotAvailable;
