import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import {
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ROUTES from '../../../routes';
import { useTranslation } from '../../../hooks/useTranslation';
import Button from '../../buttons';
import { MoveSvcEligibilityResponse } from '../../../__generated__/pge-types';
import { SSMServiceSummary } from '../common/SSMServiceSummary';
import { ServiceSummary } from '../ssm.types';
import Page2ColumnLayout from '../../../components/utility/page2-column-layout';

type Props = {
  path?: string;
  personAndAccountInEligibleData: MoveSvcEligibilityResponse;
  notMainCustomer: boolean;
  multiplePremisesIneligible: boolean;
  setPersonAndAccountInEligibleData?: (
    personAndAccountInEligibleData: MoveSvcEligibilityResponse,
  ) => void;
  serviceAddress: string;
  serviceSummary: ServiceSummary;
  pendingDisconnectIneligible: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    header: {
      fontSize: '34px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
        textAlign: 'center',
      },
    },
    kicker: {
      fontSize: '34px',
      fontWeight: 'bold',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '30px',
      },
    },
    info: {
      fontSize: '18px',
      textAlign: 'center',
      marginTop: '20px',
    },
    done: {
      marginTop: '30px',
      width: '200px',
      height: '40px',
    },
    back: {
      marginTop: '30px',
      width: '200px',
      height: '40px',
      [theme.breakpoints.up('sm')]: {
        marginRight: '15px',
      },
    },
    action: {
      textAlign: 'center',
    },
  }),
);

export const SSMMoveServiceIneligible = (props: Props) => {
  const {
    personAndAccountInEligibleData,
    notMainCustomer,
    multiplePremisesIneligible,
    serviceAddress,
    setPersonAndAccountInEligibleData,
    serviceSummary,
    pendingDisconnectIneligible,
  } = props;
  const {
    hasActiveDepositAndTPAAgreement,
    hasBadDebt,
    hasActiveServiceAgreement,
    hasDisconnect,
    hasEnrolledInEqualpay,
    hasFraud,
    hasPendingDisconnect,
  } = personAndAccountInEligibleData;
  const { richT, t, inlineRichT } = useTranslation();
  const classes = useStyles();

  let headerText;
  let kickerText;
  let infoText;
  let doneLink: string = ROUTES.SSM_HOME;

  // message set based on following priorities

  if (hasEnrolledInEqualpay) {
    headerText = t('ACCOUNT_ENROLLED_IN_EQUAL_PAY');
    kickerText = t('SSM_START_INELIGIBLE_KICKER_TEXT');
    infoText = richT('SSM_MOVE_CALL_FOR_ASSISTANCE_EQUAL_PAY');
  }
  if (hasActiveDepositAndTPAAgreement) {
    headerText = '';
    kickerText = t('UNFORTUNATE_NO_REQUEST_SERVICE');
    infoText = richT('PLEASE_CALL_CUST_MON_FRIDAY');
  }
  if (!hasActiveServiceAgreement) {
    headerText = t('ACCOUNT_IS_NOT_ACTIVE_TITLE');
    kickerText = t('SSM_START_INELIGIBLE_KICKER_TEXT');
    infoText = t('PLEASE_USE_THE_START_SERVICE_FEATURE_TITLE');
  }
  if (hasDisconnect) {
    headerText = t('ACCNT_IS_DISCONNECTED');
    kickerText = t('SSM_START_INELIGIBLE_KICKER_TEXT');
    infoText = '';
  }

  if (hasPendingDisconnect) {
    headerText = t('PENDING_DISCONNECT_TITLE');
    kickerText = t('SSM_START_INELIGIBLE_KICKER_TEXT');
    infoText = infoText = inlineRichT('SSM_MOVE_PENDING_DISCONNECT_INFO_2', {
      serviceAddress,
    });
  }
  if (hasBadDebt || hasFraud) {
    headerText = t('INELIGIBLE_TO_START_ON_LINE');
    kickerText = t('SSM_START_INELIGIBLE_KICKER_TEXT');
    infoText = inlineRichT('SSM_START_INELIGIBLE_INFO_TEXT', {
      RESIDENTIAL_CUSTOMER_SERVICE_NUMBER: t(
        'RESIDENTIAL_CUSTOMER_SERVICE_NUMBER',
      ),
    });
  }
  if (multiplePremisesIneligible) {
    headerText = t('MULTIPLE_PREMISES_FOUND_TITLE');
    kickerText = t('SSM_START_INELIGIBLE_KICKER_TEXT');
    infoText = inlineRichT('MULTIPLE_PREMISES_CALL_CS', {
      CUSTOMER_CARE_NUMBER_OTHER: t('CUSTOMER_CARE_NUMBER_OTHER'),
    });
  }
  if (notMainCustomer) {
    headerText = t('MOVE_APPLICANT_DIFFERENT_THAN_PRIMARY');
    kickerText = '';
    infoText = richT('UNABLE_TO_PROCESS_MOVE_REQUEST');
  }
  if (pendingDisconnectIneligible) {
    headerText = t('ALREADY_SET_A_START_DATE');
    kickerText = t('CHANGE_IF_NEED_TO');
    infoText = inlineRichT('PENDING_INELIGIBLE_DESC', {
      RESIDENTIAL_CUSTOMER_SERVICE_NUMBER: t(
        'RESIDENTIAL_CUSTOMER_SERVICE_NUMBER',
      ),
    });
    doneLink = ROUTES.ACCOUNT;
  }

  useEffect(() => {
    return () => {
      if (setPersonAndAccountInEligibleData) {
        setPersonAndAccountInEligibleData({
          hasActiveDepositAndTPAAgreement: false,
          hasBadDebt: false,
          hasDisconnect: false,
          hasActiveServiceAgreement: false,
          hasEnrolledInEqualpay: false,
          hasFraud: false,
          hasPendingDisconnect: false,
        })!;
      }
    };
  }, []);

  return (
    <Page2ColumnLayout
      leftColumn={<SSMServiceSummary summary={serviceSummary} />}
    >
      <Grid item className={classes.container}>
        <Grid item>
          <Typography className={classes.header} data-testid="header">
            {headerText}
          </Typography>
        </Grid>

        <Grid item>
          <Typography className={classes.kicker} data-testid="kicker">
            {kickerText}
          </Typography>
        </Grid>

        <Grid item>
          <Typography className={classes.info} data-testid="info">
            {infoText}
          </Typography>
        </Grid>

        <Grid item className={classes.action}>
          {// @ts-ignore
          multiplePremisesIneligible ? (
            <Button
              color={'secondary'}
              variant={'outlined'}
              className={classes.back}
              onClick={() => navigate(ROUTES.SSM_MOVE_ADDRESS)}
              data-testid="back"
            >
              {t('BACK')}
            </Button>
          ) : null}
          <Button
            color={'primary'}
            variant={'contained'}
            className={classes.done}
            onClick={() => navigate(doneLink)}
            data-testid="done"
          >
            {t('DONE')}
          </Button>
        </Grid>
      </Grid>
    </Page2ColumnLayout>
  );
};
