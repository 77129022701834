import useFormState from '../../../../hooks/useFormState';
import validationRules, {
  Model,
} from '../../report-outage/serviceLocation.rules';
import useUtils from '../utils';
import { useReportOutageApi } from '../outage-map-panel/useOutageReportApi';
import useAuth from '../../../../hooks/useAuth';
import {
  AlertType,
  DeliveryType,
  DeliveryTypeInput,
  NotificationType,
  OutageRestoredAlert,
} from '../../../../__generated__/pge-types';
import { NotificationRequestAccountParams } from '../../OutageCenterPage';
import useWrapWithLoader from '../../../../hooks/useWrapWithLoading';

export const useUpdateOutageAlert = (
  loggedInUserNotificationRequestAccountParams: NotificationRequestAccountParams,
  notificationFormValues: Model,
  setNotificationFormValues: React.Dispatch<React.SetStateAction<Model>>,
) => {
  const { setErrorNotification } = useUtils();
  const { submitCommPreferenceForLoggedInUser } = useReportOutageApi();
  const { isAuthenticated } = useAuth();
  const { wrapWithLoader } = useWrapWithLoader();

  const form = useFormState(notificationFormValues, {
    validate: validationRules,
  });

  const setError = () => {
    window.scrollTo(0, 0);
    setErrorNotification(true);
  };

  const updateForm = () =>
    setNotificationFormValues({
      receiveEmail: form.values.receiveEmail,
      email: form.values.email,
      receiveText: form.values.receiveText,
      textNumber: form.values.textNumber,
      callbackPhoneNumber: form.values.receiveCallBack
        ? form.values.callbackPhoneNumber
        : '',
      receiveCallBack: form.values.receiveCallBack,
      encryptedEmailContactId: form.values.encryptedEmailContactId,
      encryptedTextNumberContactId: form.values.encryptedTextNumberContactId,
      encryptedEmailPreferenceId: form.values.encryptedEmailPreferenceId,
      encryptedTextNumberPreferenceId:
        form.values.encryptedTextNumberPreferenceId,
    });

  let handleOnSubmitStatus: any;
  let handleOnCommSubmitStatus: any;

  const outageRestoredAlertList: OutageRestoredAlert[] = [];
  if (
    Boolean(form.values.encryptedEmailPreferenceId) ||
    Boolean(form.values.receiveEmail)
  ) {
    const emailAlert = {
      type: AlertType.Email,
      encryptedContactId: form.values.encryptedEmailContactId!,
      encryptedNotificationId: form.values.encryptedEmailPreferenceId,
      contactValue: form.values.email,
      isSelected: form.values.receiveEmail,
    };
    outageRestoredAlertList.push(emailAlert);
  }
  if (
    Boolean(form.values.encryptedTextNumberPreferenceId) ||
    Boolean(form.values.receiveText)
  ) {
    const smsAlert = {
      type: AlertType.Sms,
      encryptedContactId: form.values.encryptedTextNumberContactId!,
      encryptedNotificationId: form.values.encryptedTextNumberPreferenceId,
      contactValue: form.values.textNumber,
      isSelected: form.values.receiveText,
    };
    outageRestoredAlertList.push(smsAlert);
  }

  const deliveryTypes: DeliveryTypeInput[] = [];
  if (
    Boolean(form.values.encryptedEmailPreferenceId) ||
    Boolean(form.values.receiveEmail)
  ) {
    const emailAlert = {
      deliveryType: DeliveryType.Email,
      contacts: [
        {
          encryptedContactId: form.values.encryptedEmailContactId!,
          encryptedPreferenceId: form.values.encryptedEmailPreferenceId,
          value: form.values.email,
          isSelected: form.values.receiveEmail,
        },
      ],
    };
    deliveryTypes.push(emailAlert);
  }
  if (
    Boolean(form.values.encryptedTextNumberPreferenceId) ||
    Boolean(form.values.receiveText)
  ) {
    const smsAlert = {
      deliveryType: DeliveryType.Sms,
      contacts: [
        {
          encryptedContactId: form.values.encryptedTextNumberContactId!,
          encryptedPreferenceId: form.values.encryptedTextNumberPreferenceId,
          value: form.values.textNumber,
          isSelected: form.values.receiveText,
        },
      ],
    };
    deliveryTypes.push(smsAlert);
  }

  // Have email or text been modified?
  const isEmailOrTextModifed =
    notificationFormValues.receiveEmail !== form.values.receiveEmail ||
    notificationFormValues.receiveText !== form.values.receiveText;

  const handleOnSubmit = wrapWithLoader(async () => {
    try {
      if (
        isEmailOrTextModifed &&
        isAuthenticated &&
        loggedInUserNotificationRequestAccountParams.encryptedPersonId &&
        loggedInUserNotificationRequestAccountParams.accountNumber
      ) {
        handleOnCommSubmitStatus = await submitCommPreferenceForLoggedInUser({
          accountNumber:
            loggedInUserNotificationRequestAccountParams.accountNumber,
          encryptedPersonId:
            loggedInUserNotificationRequestAccountParams.encryptedPersonId,
          notificationTypes: [
            {
              notificationType: NotificationType.Rest,
              deliveryTypes: deliveryTypes,
            },
          ],
        });
      }

      if (
        handleOnSubmitStatus?.reportOutageBySpList?.success &&
        !Boolean(handleOnSubmitStatus?.reportOutageBySpList?.success)
      ) {
        setError();
      } else if (
        handleOnSubmitStatus?.reportOutageBySpList?.success &&
        Boolean(handleOnSubmitStatus?.reportOutageBySpList?.success)
      ) {
        updateForm();
      }

      if (
        handleOnCommSubmitStatus?.submitCommPreferences?.success &&
        !Boolean(handleOnCommSubmitStatus?.submitCommPreferences?.success)
      ) {
        setError();
      } else if (
        handleOnCommSubmitStatus?.submitCommPreferences?.success &&
        Boolean(handleOnCommSubmitStatus?.submitCommPreferences?.success)
      ) {
        updateForm();
      }

      return (
        Boolean(handleOnSubmitStatus?.reportOutageBySpList?.success) ||
        Boolean(handleOnCommSubmitStatus?.submitCommPreferences?.success)
      );
    } catch {
      setError();
    }
  });

  return {
    form,
    handleOnSubmit,
  };
};
export default useUpdateOutageAlert;
