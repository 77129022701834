import gql from 'not-graphql-tag';

export const getAccountDetails = gql`
  query getAccountDetails(
    $params: AccountDetailParams!
    $commPreferencesParams: CommPreferencesParams!
  ) {
    getAccountDetails(params: $params) {
      accountNumber
      encryptedAccountNumber
      encryptedPersonId
      description
      isDefault
      isActive
      accountType
      relationType
      mainCustomerName
      coCustomerNames
      isLoggedInUserOnAccount
      pgeEnergyTracker {
        energyTrackerInfo {
          showEnergyTracker
        }
        billableAccountDetails {
          isAccountBillable
          isServiceDesignationElectric
        }
      }
      commPreferences(commPreferencesParams: $commPreferencesParams) {
        notificationType
        notificationOptions {
          sequence
          value
        }
        deliveryTypeDetails {
          deliveryType
          contactDetails {
            contactId
            encryptedContactId
            encryptedPreferenceId
            value
            isSelected
            isPrimary
          }
        }
      }
    }
  }
`;

export const getLinkedPersons = gql`
query GetLinkedPersons($params: LinkedPersonRequest) {
  getLinkedPersons(params: $params) {
    contactPhone
    email
    name
    encryptedPersonId
    encryptedContactId
    personId
    isFinanciallyResponsible
    isCssUser
    accountRelTypeCd
    verificationStatus
    inputData
  }
}
`;

export const sendActivationCodeMutation = gql`
  mutation sendActivationCode($phoneNumber: String!) {
    sendActivationCode(payload: { phoneNumber: $phoneNumber }) {
      encryptedActivationCode
    }
  }
`;

export const verifyActivationCodeMutation = gql`
  mutation verifyEqualsEncryptedValue(
    $clearTextValue: String!
    $encryptedValue: String!
  ) {
    verifyEqualsEncryptedValue(
      payload: {
        clearTextValue: $clearTextValue
        encryptedValue: $encryptedValue
      }
    ) {
      result
    }
  }
`;

export const updateTextPhoneMutation = gql`
  mutation UpdateTextPhone($payload: updateTextPhoneInput!) {
    updateTextPhone(payload: $payload) {
      success
      encryptedContactId
      updatePhoneError
    }
  }
`;

export const updatePersonContactDetailsMutation = gql`
  mutation updatePersonContactDetails($payload: UpdatePersonContactDetailsRequest!) {
    updatePersonContactDetails(payload: $payload) {
      isSuccess
      phoneContactId
      emailContactId
      reason
      encryptedEmailId
      encryptedPhoneId
    }
  }
`;

export const submitCommPreferenceMutation = gql`
  mutation submitCommPreference($payload: SubmitCommPreferencesRequest!) {
    submitCommPreferences(payload: $payload) {
      success
    }
  }
`;

export const deletePersonDetailsMutation = gql`
mutation Mutation($payload: DeletePersonDetailsRequest) {
  deletePersonDetails(payload: $payload) {
    success
  }
}
`;

export const submitPendingPersonCommPrefMutation = gql`
mutation Mutation($payload: pendingUserCommPreference!) {
  updatePendingUserCommPreference(payload: $payload) {
    success
  }
}
`;

export const linkedPersonMutation = gql`
  mutation Mutation($payload: linkedPersonApplicant!) {
    linkedAccountApplicant(payload: $payload) {
      perId
      linkedApplicanError
      success
    }
  }
`;

export const sendVerificationForAddPersonMutation = gql`
  mutation Mutation($payload: linkedPersonApplicant!) {
    sendVerificationForAddPerson(payload: $payload) {
      perId
      linkedApplicanError
      success
    }
  }
`;

