import gql from 'not-graphql-tag';

export const getAccountsListForIQBDForm = gql`
  query getAccountDropdownInfo($params: AccountDetailListParams!) {
    getAccountDetailList(params: $params) {
      totalCount
      accounts {
        isDefault
        accountNumber
        accountType
        encryptedAccountNumber
        encryptedPersonId
        description
        mainCustomerName
        isActive
        isPaperlessBillEnrolled {
          result
        }
        premiseInfo {
          addressLine1
          addressLine2
          city
          county
          state
          postal
          country
          encryptedPremiseId
          saDetails {
            encryptedSAId
          }
        }
      }
    }
  }
`;

export const getIQBDEligibility = gql`
  query IQBDStatus($iqbdParams: IqbdParams!, $params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      IQBDStatus(IqbdParams: $iqbdParams) {
        isEnrolled
        isExpired
        isSameAccountEnrolled
        discountTier
        expirationDate
        effectiveDate
        isPendingReview
      }
      premiseInfo {
        addressLine1
        addressLine2
        city
        county
        state
        postal
        country
        IQBDEligibility {
          eligibility {
            isEligible
            reason
          }
        }
      }
    }
  }
`;

export const getAccountInfoForIQBD = gql`
  query getAccountInfo($params: GetAccountInfoParams) {
    getAccountInfo(params: $params) {
      prefLanguage
    }
  }
`;
