import React, { useEffect } from 'react';
import {
  Paper,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { navigate } from 'gatsby';
import { useMoveServiceEligibility } from './useMoveServiceEligibility';
import PGEButton from '../../buttons';
import Backdrop from '../../backdrop';
import AccountDropdown from '../../account-dropdown';
import { useTranslation } from '../../../hooks/useTranslation';
import { useIsMobile } from '../../../util/style-utils';
import ROUTES from '../../../routes';

interface Props {
  path: string;
  setShowProgressBar: (arg0: boolean) => void;
  isAuthenticated: boolean;
  encryptedAccountNumber: string | undefined;
  accountDetails: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  mainContent: {
    '& h2': {
      fontFamily: theme.typography.h2.fontFamily,
      fontSize: theme.typography.h2.fontSize,
      fontWeight: theme.typography.h2.fontWeight,
    },
  },
}));

export const MoveServiceIneligibleAlert = ({
  setShowProgressBar,
  isAuthenticated,
  encryptedAccountNumber,
  accountDetails,
}: Props) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const { loading, data, setServiceAddress } = useMoveServiceEligibility();

  useEffect(() => {
    if (
      accountDetails &&
      encryptedAccountNumber &&
      accountDetails?.premiseInfo?.[0]
    ) {
      const {
        addressLine1,
        city,
        state,
        postal,
        qasVerified,
      } = accountDetails.premiseInfo?.[0];
      setServiceAddress({
        serviceAddress: {
          addressLine1,
          city,
          state,
          postal,
          qasVerified,
        },
        encryptedAccountNumber,
      });
    }
  }, [accountDetails, encryptedAccountNumber]);

  useEffect(() => {
    setShowProgressBar(false);

    return function cleanup() {
      setShowProgressBar(true);
    };
  }, []);

  useEffect(() => {
    if (data && data?.moveServiceEligibility.eligibleForMoveService) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.MOVE_SERVICE);
    }
  }, [data]);

  if (!data) {
    return <Backdrop forceOpen={loading} />;
  }

  return (
    <>
      <div style={{ marginBottom: 24 }}>
        <AccountDropdown />
      </div>
      <Paper>
        <Card>
          <CardContent>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <span className={classes.mainContent}>
                  {richT('MOVE_SERVICE_ADDRESS_INELIGIBLE_ERROR')}
                </span>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify={isMobile ? 'center' : 'flex-end'}
                spacing={1}
              >
                <Grid item xs={10} md="auto">
                  <PGEButton
                    fullWidth
                    onClick={async () => {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      navigate(isAuthenticated ? ROUTES.ACCOUNT : ROUTES.HOME);
                    }}
                  >
                    {t('OK')}
                  </PGEButton>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </>
  );
};
