import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { AccountDetail } from '../../../__generated__/pge-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import colors from '../../../themes/main-colors';
import TextLink from '../../text-link';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import Icon from '@material-ui/core/Icon';
import { useTranslation } from '../../../hooks/useTranslation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    container: {},
    toggle: {
      fontSize: '1.09rem',
      '& > a': {
        '&:active': {
          borderBottom: 'none',
        },
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(1),
        '& > .MuiIcon-root': {
          marginLeft: theme.spacing(1),
          color: colors.darkGray3,
        },
      },
    },
  }),
);

type Props = {
  account: AccountDetail;
  showing: boolean;
  onToggleShow: () => void;
  accountHasMultiplePremisesOrMultipleSA?: boolean;
};

export default function ServiceAddressList({
  account,
  onToggleShow,
  showing,
  accountHasMultiplePremisesOrMultipleSA,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const premiseInfoList = account.premiseInfo || [];
  const multiSACount = premiseInfoList.reduce(
    (total, SA) => Number(total + Number(SA?.saDetails?.length)),
    0,
  );

  if (premiseInfoList.length === 0) {
    return (
      <div>
        <Typography variant={'body1'} className={classes.colorTextPrimary}>
          {account.mainCustomerName}
        </Typography>
      </div>
    );
  } else if (
    premiseInfoList.length === 1 &&
    !accountHasMultiplePremisesOrMultipleSA
  ) {
    const address = premiseInfoList[0]!;
    return (
      <>
        <div>
          <Typography variant={'body1'} className={classes.colorTextPrimary}>
            {address.addressLine1}
          </Typography>
        </div>
        <div>
          <Typography
            variant={'body1'}
            className={classes.colorTextPrimary}
            data-testid="customer-name-on-card"
          >
            {account.mainCustomerName}
          </Typography>
        </div>
      </>
    );
  } else {
    return (
      <Box className={classes.container}>
        <Box className={classes.toggle}>
          <TextLink
            external
            onClick={e => {
              e.preventDefault();
              onToggleShow();
            }}
          >
            {t('SERVICE_ADDRESSES')} ({multiSACount})
            <Icon>{showing ? <ExpandLess /> : <ExpandMore />}</Icon>
          </TextLink>
        </Box>
      </Box>
    );
  }
}
