import { FieldErrors } from '../../hooks/useFormState.types';
import { validatePaymentDate } from '../payment-form/payment-info-form/PaymentInfoForm.rules';
import { PaymentDateState } from './types';

type AdditionalContext = {
  t: (translation: string) => string;
  maxPayDate: Date;
};

export default function validate(
  paymentDateState: PaymentDateState,
  context: AdditionalContext,
): FieldErrors<PaymentDateState> {
  return {
    paymentDate: validatePaymentDate(paymentDateState.paymentDate),
  };
}
