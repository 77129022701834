import React, { FunctionComponent, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import colors from '../../themes/main-colors';

import { auth } from '../../firebase';
import { verifyIfUserIsEnrolled, getEnrolledFactors } from './firebase.helper';

import EmailVerification from './EmailVerification';
import PhoneDetails from './PhoneDetails';
import Enrollment from './Enrollment';
import EnrolledFactors from './EnrolledFactors';

import { onAuthStateChanged, MultiFactorInfo } from 'firebase/auth';

import { getContactDetails } from '../../queries/account.query';
import useAuthQuery from '../../hooks/useAuthQuery';
import { AccountCustomer } from '../../__generated__/pge-types';
import { parsePhoneNumber } from '../../util/format';
import { useTranslation } from '../../hooks/useTranslation';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiStepConnector-root': {
      marginLeft: '20px',
    },
    '& .MuiStepContent-root': {
      marginLeft: '20px',
      paddingLeft: '28px',
    },
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  completed: {
    color: colors.shoreGreen + ' !important',
  },
  stepper: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0),
    },
  },
  stepperLabel: {
    '& .MuiTypography-root': {
      fontSize: '1.2rem',
    },
  },
}));

type CustomMultiFactorInfo = MultiFactorInfo & {
  phoneNumber: string;
};

const TwoStepAuthentication: FunctionComponent = props => {
  const [isMFAEnrolled, setIsMFAEnrolled] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);

  const [verificationCodeId, setVerificationCodeId] = useState<string>('');
  const [MFAPhoneNumber, setMFAPhoneNumber] = useState<string>('');
  const [enrolledPhoneNumber, setEnrolledPhoneNumber] = useState<string>('');

  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        await refreshUser();

        setIsMFAEnrolled(verifyIfUserIsEnrolled(user));

        const enrolledFactors = getEnrolledFactors(user);

        if (enrolledFactors.length > 0) {
          setEnrolledPhoneNumber(
            (enrolledFactors[0] as CustomMultiFactorInfo).phoneNumber,
          );
        }

        if (user.emailVerified) {
          setActiveStep(1);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const refreshUser = async () => {
    await auth.currentUser?.reload();
    if (auth.currentUser) {
      const isEnrolled = verifyIfUserIsEnrolled(auth.currentUser);

      if (auth.currentUser?.emailVerified) {
        setIsMFAEnrolled(isEnrolled);
      }

      if (isEnrolled) {
        const enrolledFactors = getEnrolledFactors(auth.currentUser);
        setEnrolledPhoneNumber(
          (enrolledFactors?.[0] as CustomMultiFactorInfo)?.phoneNumber,
        );
      }
    }
  };

  const stepperLabelProps = {
    StepIconProps: {
      classes: {
        completed: classes.completed,
      },
    },
    className: classes.stepperLabel,
  };

  const { data, loading } = useAuthQuery<{
    getAccountInfo: AccountCustomer;
  }>(getContactDetails, {
    variables: {
      params: {},
    },
  });

  const mobNumberData =
    data?.getAccountInfo?.contactDetails &&
    data?.getAccountInfo?.contactDetails?.find(
      mob => mob?.contactType === 'MOB',
    );
  const mobNumber = mobNumberData?.contactValue
    ? parsePhoneNumber(mobNumberData?.contactValue)
    : '';

  return (
    <Grid container spacing={2} className={classes.root}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant={'h2'} className={classes.title}>
                  {t('TWO_STEP_AUTH_ENROLLMENT_TITLE')}
                </Typography>
                <Typography variant={'body1'}>
                  {t('WHAT_IS_TWO_STEP_AUTHENTICATION')}
                </Typography>
              </Grid>

              {auth.currentUser && !isMFAEnrolled && (
                <Grid item>
                  <Stepper
                    className={classes.stepper}
                    activeStep={activeStep}
                    orientation="vertical"
                  >
                    <Step>
                      <StepLabel {...stepperLabelProps}>
                        <Typography variant={'h6'}>
                          {t('TWO_STEP_AUTH_VERIFY_YOUR_EMAIL')}
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <EmailVerification onNext={() => setActiveStep(1)} />
                      </StepContent>
                    </Step>
                    <Step>
                      <StepLabel {...stepperLabelProps}>
                        <Typography variant={'h6'}>
                          {t('TWO_STEP_AUTH_ENTER_PHONE_NUMBER')}
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <PhoneDetails
                          mobilePhone={mobNumber || ''}
                          onNext={(id: string, phone: string) => {
                            setVerificationCodeId(id);
                            setMFAPhoneNumber(phone);
                            setActiveStep(2);
                          }}
                        />
                      </StepContent>
                    </Step>
                    <Step>
                      <StepLabel {...stepperLabelProps}>
                        <Typography variant={'h6'}>
                          {t('TWO_STEP_AUTH_ENROLL')}
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <Enrollment
                          phoneNumber={MFAPhoneNumber}
                          verificationCodeId={verificationCodeId}
                          onVerify={async () => {
                            await refreshUser();
                            setActiveStep(1);
                          }}
                        />
                      </StepContent>
                    </Step>
                  </Stepper>
                </Grid>
              )}
            </Grid>
            <Box id="two-step-enrollment"></Box>
          </CardContent>
        </Card>

        {auth.currentUser && isMFAEnrolled && (
          <EnrolledFactors
            enrolledPhoneNumber={enrolledPhoneNumber}
            refreshUser={refreshUser}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default TwoStepAuthentication;
