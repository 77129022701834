import React, { FunctionComponent, useEffect } from 'react';
import { Router } from '@reach/router';
import ROUTES from '../../routes';
import HistoricalUsageForm from '../../components/historical-usage/historical-usage-form';
import HistoricalUsageDone from '../../components/historical-usage/historical-usage-done';
import HistoricalUsageConfirm from '../../components/historical-usage/historical-usage-confirm';
import AccountLayout from '../../components/account-layout';
import { HistoricalUsageContextProvider } from '../../providers/HistoricalUsageProvider';

type Props = {
  path?: string;
};

const HistoricalUsagePage: FunctionComponent<Props> = () => {
  useEffect(() => {
    window.location.replace(ROUTES.HISTORICAL_USAGE_LEGACY_URL);
  });

  return (
    <HistoricalUsageContextProvider>
      <AccountLayout>
        <Router basepath="/">
          <HistoricalUsageForm path={ROUTES.HISTORICAL_USAGE_CONFIG} />
          <HistoricalUsageConfirm path={ROUTES.HISTORICAL_USAGE_CONFIRM} />
          <HistoricalUsageDone path={ROUTES.HISTORICAL_USAGE_DONE} />
        </Router>
      </AccountLayout>
    </HistoricalUsageContextProvider>
  );
};

export default HistoricalUsagePage;
