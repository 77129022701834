import React, { FC, useContext, useState } from 'react';
import {
  useTheme,
  useMediaQuery,
  CircularProgress,
  Grid,
  Link,
} from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import usePaymentForm from '../../../hooks/usePaymentForm';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import useOneTimePayment from '../../../hooks/useOneTimePayment';
import {
  OneTimePayConfigData,
  SubmitOneTimePay,
} from '../../payment-form/oneTimePayment.types';
import PaymentInfoForm from '../../payment-form/payment-info-form';
import { toCurrencyString } from '../../../util/format';
import ROUTES from '../../../routes';
import PaymentVerificationForm from '../../payment-form/payment-verification-form';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import { PaymentObject } from '../../../components/need-more-time-to-pay/tpa/useTPA';
import { useApolloClient } from '@apollo/react-hooks';
import { navigate } from '@reach/router';

type Props = {
  path: string;
  fullAmount?: string;
  minimumAmount?: string;
  handleOnPaymentConfirmation: (data: PaymentObject) => Promise<void>;
};

const ReconnectServicePayment: FC<Props> = (props: Props) => {
  const {
    path,
    handleOnPaymentConfirmation,
    fullAmount,
    minimumAmount,
  } = props;
  const notificationContext = useContext(NotificationsContext);

  const { wrapWithLoader } = useWrapWithLoader();

  const paymentMethodCapLimit: number = 2;
  const { t, richT } = useTranslation();
  const payBillProps = usePaymentForm();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const childProps = { isMobile, ...payBillProps };
  const [showPayment, setShowPayment] = useState<Boolean>(true);
  const [
    oneTimePayConfig,
    setOneTimePayConfig,
  ] = useState<OneTimePayConfigData | null>(null);

  const handleConfigSubmit = (data: OneTimePayConfigData) => {
    setOneTimePayConfig(data);
    setShowPayment(false);
  };

  const paymentVerificationCancelHandler = () => {
    setShowPayment(true);
  };

  const apolloClient = useApolloClient();

  //TODO: refactor PaymentInfoForm so that the uneeded function param is optional. Alos impacts TPA?
  const handleEligibleValue = (value: boolean) => {
    return false;
  };

  const {
    accountDetails,
    customer,
    savedProfileList,
    profileListLoading,
    accountLoading,
    encryptedPersonId,
    submitOneTimePay,
    refetchPayBillAccountDetails,
    refetchSingleAccountDetails,
  } = useOneTimePayment();

  const getFormattedPaymentAmount = () => {
    return oneTimePayConfig?.paymentAmount
      ? `$${toCurrencyString(
          parseFloat(oneTimePayConfig?.paymentAmount),
          false,
        )}`
      : '';
  };
  const handleConfirmSubmit = wrapWithLoader(async function() {
    try {
      notificationContext.setState({ isOpen: false });

      const additionalPayInfo: SubmitOneTimePay = {
        emailAddress: customer?.email!,
        encryptedPersonId: encryptedPersonId!,
      };

      const oneTimeSubmitPayResult = await submitOneTimePay(
        accountDetails!,
        oneTimePayConfig!,
        additionalPayInfo,
      );
      const oneTimeSubmitData = oneTimeSubmitPayResult.data;

      if (oneTimeSubmitData?.makePayment.paymentConfirmation.length) {
        const oneTimePayConfirmationData = oneTimeSubmitData.makePayment;
        try {
          await refetchPayBillAccountDetails();
          await refetchSingleAccountDetails();
        } catch (e) {
          await handleOnPaymentConfirmation({
            oneTimePayConfirmationData,
            oneTimePayConfig,
            paymentMethodCapLimit,
          });

          // We could not refetch the account, so clear the account from the cache to
          // force a data reload
          // TODO: When we migrate to Apollo 3, use cache.evict with cache.gc
          const cache = apolloClient.cache as any;
          return cache.data?.delete(
            `AccountDetail:${accountDetails?.accountNumber}`,
          );
        }
        await handleOnPaymentConfirmation({
          oneTimePayConfirmationData,
          oneTimePayConfig,
          paymentMethodCapLimit,
        });
        return;
      } else {
        window.scrollTo(0, 0);
        return notificationContext.setState({
          isOpen: true,
          message: richT('PAYMENT_ERROR_MESSAGE'),
          severity: 'error',
        });
      }
    } catch (_) {
      window.scrollTo(0, 0);
      return notificationContext.setState({
        isOpen: true,
        message: richT('PAYMENT_ERROR_MESSAGE'),
        severity: 'error',
      });
    }
  });

  if (
    accountDetails === undefined ||
    accountLoading ||
    customer === undefined ||
    savedProfileList === undefined ||
    profileListLoading
  ) {
    return <CircularProgress data-testid="loading-data" size={35} />;
  }

  return (
    <>
      {customer && savedProfileList && accountDetails && (
        <>
          {showPayment && (
            <PaymentInfoForm
              path={path}
              {...childProps}
              savedProfileList={savedProfileList}
              onSubmit={handleConfigSubmit}
              handleEligible={handleEligibleValue}
              oneTimePayConfigData={oneTimePayConfig}
              paymentMethodCapLimit={paymentMethodCapLimit}
              isForReconnect={true}
              fullAmount={fullAmount}
              minimumAmount={minimumAmount}
              paymentInfo={{
                amountDue: accountDetails.currentCharges?.amountDue!,
                dueDate: accountDetails.currentCharges?.dueDate,
                paymentEligibility: accountDetails?.paymentEligibility!,
              }}
              backButtonRoute={ROUTES.RECONNECT_SERVICE_START}
              enableDonation={true}
              cardFooterContent={
                <Grid
                  css={{
                    padding: theme.spacing(0, 4, 2, 4),
                    '& .MuiGrid-container': {
                      marginBottom: theme.spacing(2),
                    },
                  }}
                >
                  <Link
                    css={{
                      textDecoration: 'underline',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(ROUTES.AUTO_PAY)}
                  >
                    {accountDetails?.autoPay?.isEnrolled
                      ? t('AUTOPAY_SETTINGS')
                      : t('ENROLL_IN_AUTOPAY')}
                  </Link>
                </Grid>
              }
            />
          )}
          {!showPayment && (
            <PaymentVerificationForm
              path={ROUTES.PAYMENT_VERIFICATION}
              {...childProps}
              oneTimePayConfigData={oneTimePayConfig!}
              formattedPaymentAmount={getFormattedPaymentAmount()}
              onSubmit={handleConfirmSubmit}
              paymentMethodCapLimit={paymentMethodCapLimit}
              cancelHandler={paymentVerificationCancelHandler}
              backButtonRoute={ROUTES.RECONNECT_SERVICE_PAYMENT}
            />
          )}
        </>
      )}
    </>
  );
};
export default ReconnectServicePayment;
