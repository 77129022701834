import gql from 'not-graphql-tag';
import {
  CoCustomerInfo,
  CoCustomerForAccountRequest,
} from '../__generated__/pge-types';
import useAuthQuery from './useAuthQuery';

const getCoCustomersForAccount = gql`
  query getCoCustomersForAccount($params: CoCustomerForAccountRequest!) {
    getCoCustomersForAccount(params: $params) {
      firstName
      middleName
      lastName
      personalIdentificationType
      encryptedPersonId
    }
  }
`;

export default (
  isAuthenticated: boolean,
  encryptedAccountNumber: string | null | undefined,
  encryptedPersonId: string | null | undefined,
) => {
  const { data, loading: coCustomerDataLoading } = useAuthQuery<
    {
      getCoCustomersForAccount: CoCustomerInfo[];
    },
    {
      params: CoCustomerForAccountRequest;
    }
  >(getCoCustomersForAccount, {
    variables: {
      params: {
        encryptedAccountNumber: encryptedAccountNumber || '',
        encryptedPersonId: encryptedPersonId || '',
      },
    },
    skip: !isAuthenticated || (!encryptedAccountNumber && !encryptedPersonId),
  });

  const coCustomerData = data?.getCoCustomersForAccount;

  return {
    coCustomerDataLoading,
    coCustomerData,
  };
};
