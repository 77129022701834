import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { useTranslation } from '../../hooks/useTranslation';
import { useLocation, navigate, Link } from '@reach/router';
import { AccountAmounts, PaymentProfileState } from './types';
import ROUTES from '../../routes';
import { toCurrencyString } from '../../util/format';
import sumBy from 'lodash/sumBy';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useIsMobile } from '../../util/style-utils';
import ProceedOrCancel from '../proceed-or-cancel';
import ReviewTable from './ReviewTable';
import colors from '../../themes/main-colors';
import Button from '../buttons';
import clsx from 'clsx';
import PrintIcon from '@material-ui/icons/Print';
import DownloadIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textColor: {
      color: colors.noirBlur,
    },
    blockArea: {
      width: '50%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      background: 'white',
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      fontSize: '1.5em',
      marginTop: theme.spacing(1),
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    blockButton: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      fontSize: '1.2em',
      borderWidth: '2px !important',
      [theme.breakpoints.down('sm')]: {
        marginTop: 20,
        width: '100%',
      },
      background: 'white',
    },
  }),
);

type Props = {
  path?: string;
  onSubmit: (
    accountAmounts: Array<AccountAmounts>,
    timestamp: string,
    paymentId: string,
  ) => void;
  onDownloadPdf: (
    remittanceId: string,
    accountAmounts: Array<AccountAmounts>,
  ) => void;
  onPrintPdf: (
    remittanceId: string,
    accountAmounts: Array<AccountAmounts>,
  ) => void;
};

type LocationState =
  | {
      accountAmounts: Array<AccountAmounts>;
      timestamp: string;
      paymentId: string;
      profileState?: PaymentProfileState;
    }
  | undefined;

export default function ReviewStep({
  onSubmit,
  onDownloadPdf,
  onPrintPdf,
}: Props) {
  const { richT, t } = useTranslation();
  const isMobile = useIsMobile();
  const classes = useStyles();

  const location = useLocation();
  const locationState: LocationState = location?.state as LocationState;
  const accountAmounts: Array<AccountAmounts> =
    locationState?.accountAmounts || [];
  const timestamp = locationState?.timestamp || '';
  const paymentId = locationState?.paymentId || '';
  const profileState = locationState?.profileState;

  useEffect(() => {
    if (accountAmounts.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.MULTI_PAY);
    }
  }, []);

  const total = sumBy(accountAmounts, 'amount');

  return (
    <Box marginTop={2}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Typography variant="h2">{t('MAIL_YOUR_PAYMENT')}</Typography>
        </Grid>

        <Grid item xs={12} className={classes.textColor}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">
                <strong>
                  {isMobile
                    ? t('PRINT_REMITTANCE_MOBILE')
                    : t('PRINT_REMITTANCE')}
                </strong>
              </Typography>
              <Grid
                container
                spacing={2}
                justify={isMobile ? 'center' : 'flex-start'}
              >
                <Hidden smDown>
                  <Grid item>
                    <Button
                      color="secondary"
                      variant="outlined"
                      className={clsx(classes.blockButton)}
                      onClick={() => onPrintPdf(paymentId, accountAmounts)}
                    >
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        justify="center"
                      >
                        <Grid item>{t('PRINT')}</Grid>
                        <Grid item>
                          <Box display="flex">
                            <PrintIcon />
                          </Box>
                        </Grid>
                      </Grid>
                    </Button>
                  </Grid>
                </Hidden>
                <Grid item md="auto" xs={10}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    className={clsx(classes.blockButton)}
                    onClick={() => onDownloadPdf(paymentId, accountAmounts)}
                  >
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justify="center"
                    >
                      <Grid item>{t('DOWNLOAD_TEXT')}</Grid>
                      <Grid item>
                        <Box display="flex">
                          <DownloadIcon />
                        </Box>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">{t('MAKE_CHECK_PAYABLE')}</Typography>
              <Box className={classes.blockArea}>
                ${toCurrencyString(total, true)}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">{t('WRITE_PAYMENT_ID')}</Typography>
              <Box className={classes.blockArea}>{paymentId}</Box>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body1">{t('MAIL_REMITTANCE')}</Typography>

              {richT('PGE_ADDRESS')}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <ReviewTable
            accountAmounts={accountAmounts}
            timestamp={timestamp}
            profileState={profileState}
            skipEligibilityCheck={true}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid container justify="flex-end" direction={'row'} spacing={1}>
            <Grid item xs={12} md="auto">
              <Box paddingRight={1}>
                <ProceedOrCancel
                  proceedLabel={t('FINISH')}
                  proceedHandler={() =>
                    onSubmit(accountAmounts, timestamp, paymentId)
                  }
                  cancelRoute={false}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
