import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { getPaymentMethodLabel } from '../../../hooks/usePaymentus';
import { useTranslation } from '../../../hooks/useTranslation';
import { substrNFromEnd } from '../../../util/format';
import { PaymentProfile } from '../../../__generated__/pge-types';
import PGEButton from '../../buttons/PGE-Button';
import Modal from '../../modal';
import colors from '../../../themes/main-colors';
import ROUTES from '../../../routes';

import {
  addEditExcludeList,
  getAccountDisplayString,
} from '../../paymentus/utils';

type PaymentMethodDeleteConfirmModalProps = {
  open: boolean;
  profile: PaymentProfile;
  onDeleteProfileConfirm: () => void;
  onClose: () => void;
};

const PaymentMethodDeleteConfirmModal = ({
  open,
  profile,
  onDeleteProfileConfirm,
  onClose,
}: PaymentMethodDeleteConfirmModalProps) => {
  const { t, richT } = useTranslation();

  const typeLabel = profile.type ? getPaymentMethodLabel(profile.type) : '';

  const numberLabel =
    profile.type && addEditExcludeList.includes(profile.type)
      ? getAccountDisplayString(profile)
      : substrNFromEnd(4, getAccountDisplayString(profile));

  return (
    <Modal
      data-testid="payment-method-delete-confirm-modal"
      open={open}
      onClose={() => onClose()}
      children={
        <Grid
          container
          direction={'column'}
          style={{ width: 'auto', maxWidth: '600px' }}
          spacing={4}
        >
          <Grid
            container
            item
            direction={'column'}
            justify={'flex-start'}
            spacing={2}
          >
            <Grid item>
              <Typography variant={'h3'}>
                {t('PAYMENT_PROFILE_REMOVAL_CONFIRMATION_TITLE_NEW')}
              </Typography>
            </Grid>
            {profile.usedForAutopay || profile.hasDeferredPayments ? (
              <>
                <Grid item>
                  <Typography variant={'body1'} component={'div'}>
                    {richT(
                      profile.usedForAutopay
                        ? 'PAYMENT_PROFILE_REMOVAL_AUTOPAY_WARNING'
                        : 'PAYMENT_PROFILE_REMOVAL_DEFERRED_PAY_WARNING',
                      {
                        PAYMENT_METHOD_LABEL: `${typeLabel} ${numberLabel}`,
                      },
                    )}
                  </Typography>
                  {profile.usedForAutopay && (
                    <Typography variant={'body1'} component={'div'}>
                      {richT('PAYMENT_PROFILE_REMOVAL_AUTOPAY_MESSAGE', {
                        ASSISTANCE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
                        AUTOPAY_ROUTE: ROUTES.AUTO_PAY,
                      })}
                    </Typography>
                  )}
                </Grid>
              </>
            ) : (
              <Grid item>
                <Typography variant={'body1'} component={'div'}>
                  {richT('PAYMENT_PROFILE_REMOVAL_CONFIRMATION_MESSAGE_NEW', {
                    ASSISTANCE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
                    PAYMENT_METHOD_LABEL: `${typeLabel} ${numberLabel}`,
                  })}
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            item
            direction={'row'}
            justify={'space-between'}
            spacing={2}
          >
            <Grid item>
              <PGEButton
                data-testid="pmdcm-close-button"
                onClick={() => onClose()}
                color={'secondary'}
                variant={'outlined'}
              >
                {t('DO_NOT_REMOVE_PAYMENT_PROFILE')}
              </PGEButton>
            </Grid>
            <Grid item>
              <PGEButton
                disabled={!!profile.usedForAutopay}
                data-testid="pmdcm-confirm-button"
                onClick={() => onDeleteProfileConfirm()}
              >
                {profile.hasDeferredPayments && !profile.usedForAutopay
                  ? t('REMOVE_ANYWAY')
                  : t('REMOVE')}
              </PGEButton>
            </Grid>
          </Grid>
        </Grid>
      }
    ></Modal>
  );
};
export default PaymentMethodDeleteConfirmModal;
