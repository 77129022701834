import React from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import {
  Paper,
  Card,
  CardContent,
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';
import Pagination from '../../pagination';
import { PaginationProps } from '../../pagination/Pagination';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    cardContent: {
      borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
    menuButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    col3: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '0em',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: '-2.25em',
        marginLeft: '5em',
      },
    },
    values: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    checkboxContainer: {
      [theme.breakpoints.down('sm')]: {
        marginTop: '-4.2em',
      },
      [theme.breakpoints.up('md')]: {
        marginRight: '2em',
      },
    },
    accountInfo: {},
    inactive: {
      [theme.breakpoints.up('md')]: {
        marginTop: '1em',
      },
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <Paper elevation={3}>
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h2">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    </Paper>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3),
  },
}))(MuiDialogActions);

export default withStyles(styles)((props: any) => {
  const { t } = useTranslation();
  const { onClose, open, classes } = props;

  const handleClose = () => {
    onClose();
  };

  const groupName = 'Brown and Bayer LLC';
  const rowData: any[] = [
    {
      accountNumber: 9876543210,
      nickname: 'Barn',
      serviceAddresses: ['23 south beeker street'],
    },
    {
      accountNumber: 7603334561,
      nickname: 'Office',
      serviceAddresses: [
        '1234 Somestreet, Portland OR',
        '4309 Westridge Av, Salem OR',
        '4567 Service address, Portland OR',
      ],
    },
  ];

  const paginationProps: PaginationProps = {
    count: rowData.length,
    pageSize: 5,
    pageSizes: [5, 15, 25],
    page: 1,
    visibleOnSinglePage: true,
    onPageChange: () => {
      return;
    },
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        disableScrollLock
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <Typography variant="h2">Accounts by Business Name</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Card>
            <CardContent>
              <Grid container direction="column" spacing={3}>
                <Grid item>
                  <Typography variant="h2">{groupName}</Typography>
                </Grid>
                <Grid>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Typography variant="body2">
                        Accounts: {rowData.length}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Pagination {...paginationProps} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="column" spacing={3}>
                    {rowData.map((account: any, idx: number) => (
                      <Grid item key={`row-${idx.toString()}`}>
                        <Paper>
                          <Card>
                            <CardContent className={classes.cardContent}>
                              <Grid container direction="column">
                                <Grid item>
                                  <Typography>
                                    <strong>
                                      {account.accountNumber}
                                      {account.nickname &&
                                        ` (${account.nickname})`}
                                    </strong>
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  {account.serviceAddresses.length === 1 ? (
                                    <Typography
                                      variant={'body2'}
                                      className={classes.colorTextPrimary}
                                    >
                                      {account.serviceAddresses[0]}
                                    </Typography>
                                  ) : (
                                    <ExpansionPanel>
                                      <ExpansionPanelSummary
                                        expandIcon={<ExpandMoreIcon />}
                                      >
                                        <Typography variant="body2">
                                          {`Service addresses (${account.serviceAddresses.length})`}
                                        </Typography>
                                      </ExpansionPanelSummary>
                                      <ExpansionPanelDetails>
                                        <Grid
                                          container
                                          direction="column"
                                          spacing={2}
                                        >
                                          {account.serviceAddresses.map(
                                            (address: string) => (
                                              <Grid>
                                                <Typography
                                                  variant={'body2'}
                                                  className={
                                                    classes.colorTextPrimary
                                                  }
                                                >
                                                  {address}
                                                </Typography>
                                              </Grid>
                                            ),
                                          )}
                                        </Grid>
                                      </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                  )}
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            onClick={handleClose}
            color="primary"
          >
            {t('CLOSE')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
