import {
  DoubleBreakerAvailable,
  HomeType,
  MainBreakerAmperage,
  ChargerInstallLocation,
  TimeFrame,
  DayOfWeek,
  PanelLocation,
  PanelDistance,
  PanelSideLocation,
} from '../../__generated__/pgeplus-types';
export type Application = {
  applicationNumber?: string;
};

export type ContactInfo = {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  phoneNumber?: string;
  email?: string;
  isMainPerson?: boolean;
  isOwnHome?: boolean;
  userRelationship?: string;
  homeType?: HomeType;
};

export type RebateEligibility = {
  isNeedRebateInfo?: string;
  houseHoldSize?: string;
  annualHouseholdIncome?: string;
  isAnnnualIncomeChecked?: string;
  isHaveWifi?: string;
  isOwnTesla?: string;
  isCommitToExtendWifi?: string;
  chargerForExistingVehicle?: string;
  continueWithoutRebate?: string;
  vin?: string;
  vinImage?: string;
  amount?: string;
};

export type SelectCharger = {
  chargerId?: string;
  suppliedByCustomer?: boolean;
};

export type Installation = {
  urgency?: string;
  dayOfWeek?: DayOfWeek[];
  preferredServiceTime?: string[];
  timeFrame?: TimeFrame[];

  panelLocation?: PanelLocation;
  comment?: string;
  installLocation?: ChargerInstallLocation;

  installerRecommendPanelDistance?: boolean;
  distanceToPanel?: PanelDistance;
  panelSideLocation?: PanelSideLocation;
};

export type UploadPhotos = {
  panelImage?: string;
  amperageImage?: string;
  panelLocationImage?: string;

  extendedInstallDistance?: boolean;
  extendedInstallDistanceImage?: string;

  mainBreakerAmperage?: MainBreakerAmperage;
  doubleBreakerAvailable?: DoubleBreakerAvailable;

  chargerCost?: number;
  receiptImage?: string;
};

export type UploadChargerReceipt = {
  chargerCost?: number;
  receiptImage?: string;
};

export enum UpdateEVSectionErrorCode {
  ProductInactive = 'INACTIVE_PRODUCT',
  ProductOutOfStock = 'OUT_OF_STOCK_PRODUCT',
  ProductNotAvailable = 'PRDOUCT_NOT_AVAILABLE',
}
