import React, { FunctionComponent, useState, useEffect } from 'react';
import PaperlessBillFormProps, {
  BillMethodOptions,
} from './PaperlessBillForm.types';
import {
  Grid,
  Typography,
  Radio,
  FormControlLabel,
  FormControl,
  RadioGroup,
  makeStyles,
  Theme,
  Card,
  CardContent,
} from '@material-ui/core';
import { useTranslation } from '../../hooks/useTranslation';
import PGEButton from '../buttons';
import ActionNotAvailable from './ActionNotAvailable';
import Ineligible from './Ineligible';

const useStyles = makeStyles((theme: Theme) => ({
  strongText: {
    fontWeight: 600,
  },

  paddingBox: {
    paddingTop: '1em',
  },

  formButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

const PaperlessBillForm: FunctionComponent<PaperlessBillFormProps> = props => {
  const { t, richT } = useTranslation();
  const classes = useStyles();

  const paperlessMethod = {
    key: 'PAPERLESS',
    option: t('CHANGE_BILL_PAPERLESS_OPTION'),
    switchOption: t('CHANGE_BILL_PAPERLESS_SWITCH_OPTION'),
  };

  const mailMethod = {
    key: 'MAIL',
    option: t('CHANGE_BILL_MAIL_OPTION'),
    switchOption: t('CHANGE_BILL_MAIL_SWITCH_OPTION'),
  };

  if (!props?.encryptedPersonId) {
    return <ActionNotAvailable></ActionNotAvailable>;
  }

  if (!props?.isLoggedInUserOnAccount) {
    return (
      <Ineligible title={t('PAPER_LESS_INELIGIBLE')}>
        {richT('PAPER_LESS_INELIGIBLE_NO_PERSON', {
          ASSISTANCE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
          BIZ_ASSISTANCE_NUMBER: t('CALL_FOR_ASSISTANCE_BIZ_NUMBER'),
        })}
      </Ineligible>
    );
  }

  const [selectedOption, setSelectedOption] = useState<BillMethodOptions>(
    props.customerBillMethod,
  );

  useEffect(() => {
    setSelectedOption(props.customerBillMethod || '');
  }, [props.accountNumber]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(
      (event.target as HTMLInputElement).value as BillMethodOptions,
    );
  };

  return (
    <Card>
      <CardContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography component="span">
              <Typography component="span">
                {props?.customerBillMethod === 'PAPERLESS'
                  ? richT('CHANGE_BILL_PAPERLESS_INFO', {
                      CUSTOMER_EMAIL: props.customerEmail,
                    })
                  : richT('CHANGE_BILL_MAIL_INFO', {
                      CUSTOMER_ADDRESS: props.customerAddress || '',
                    })}
              </Typography>
            </Typography>
          </Grid>
          <Grid item className={classes.paddingBox}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="method"
                name={'method'}
                value={selectedOption}
                onChange={handleChange}
                row
              >
                <FormControlLabel
                  value={paperlessMethod.key}
                  control={<Radio color="primary" />}
                  label={
                    props?.customerBillMethod === paperlessMethod.key
                      ? paperlessMethod.option
                      : paperlessMethod.switchOption
                  }
                  labelPlacement="end"
                />
                <FormControlLabel
                  value={mailMethod.key}
                  control={<Radio color="primary" />}
                  label={
                    props?.customerBillMethod === mailMethod.key
                      ? mailMethod.option
                      : mailMethod.switchOption
                  }
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid container justify="flex-end">
            <PGEButton
              className={classes.formButton}
              variant="contained"
              color="primary"
              disabled={selectedOption === props?.customerBillMethod}
              type={'submit'}
              onClick={() => props.onSubmit(selectedOption)}
            >
              {t('CHANGE')}
            </PGEButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PaperlessBillForm;
