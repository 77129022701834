import React, { forwardRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '../../text-link/TextLink';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from '../../../hooks/useTranslation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      margin: theme.spacing(2, 0),
    },
  }),
);

const EqualPayUnavailable = forwardRef<HTMLElement>((props, ref) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box>
      <Grid
        xs={12}
        style={{ display: 'flex', flexDirection: 'row' }}
        className={classes.header}
      >
        <Typography variant={'body2'}>
          {t('EQUAL_PAY_UNAVAILABLE')}
          {<a href={'tel:800-542-8818'}> 800-542-8818</a>}
        </Typography>
      </Grid>
    </Box>
  );
});

export default EqualPayUnavailable;
