import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { useTranslation } from '../../../../hooks/useTranslation';
import colors from '../../../../themes/main-colors';
import { useIsMobile } from '../../../../util/style-utils';
import {
  toDateStringFullMonthName,
  toCurrencyString,
} from '../../../../util/format';

type ViewAccountBalanceProps = {
  amount: number;
  dueDate: Date;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountCard: {
      width: '100%',
      margin: theme.spacing(0, 0, 1.5, 0),
    },
    accountCardContainer: {
      padding: theme.spacing(2.61, 0, 2.61, 0),
    },
    headingLabel: {
      color: colors.noirBlur,
    },
    dollar: {
      color: colors.noirBlur,
    },
    bottomLabel: {},
    vdivider: {
      margin: theme.spacing(0, 0, -1, 0),
    },
  }),
);

export default function ViewAccountBalance(props: ViewAccountBalanceProps) {
  const { amount, dueDate } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <Paper className={classes.accountCard}>
      <Card>
        <CardContent className={classes.accountCardContainer}>
          <Grid container>
            <Box
              style={{ width: '49%' }}
              justifyContent={'center'}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
            >
              <Typography variant={'h6'} className={classes.headingLabel}>
                {t('ACCOUNT_BALANCE')}
              </Typography>

              <Box display="flex">
                <Typography
                  variant={'h3'}
                  style={{ fontWeight: 800 }}
                  className={classes.dollar}
                >
                  {'$'}
                </Typography>
                <Typography
                  variant={'h3'}
                  style={{ fontWeight: 800 }}
                  className={classes.bottomLabel}
                  data-testid={'account-amount-element'}
                >
                  {amount}
                </Typography>
              </Box>
            </Box>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.vdivider}
            />
            <Box
              style={{ width: '49%' }}
              justifyContent={'center'}
              display={'flex'}
              flexDirection={isMobile ? 'column' : 'column'}
              alignItems={isMobile ? 'center' : 'center'}
            >
              <Typography variant={'h6'} className={classes.headingLabel}>
                {t('DUE_DATE')}
              </Typography>
              <Typography
                variant={'h3'}
                style={{ fontWeight: 800 }}
                className={classes.bottomLabel}
                data-testid={'account-date-element'}
              >
                {toDateStringFullMonthName(dueDate)}
              </Typography>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
}
