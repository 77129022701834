import {
  Maybe,
  PremiseInfo,
  SaDetail,
  SaStatus,
} from '../../__generated__/pge-types';

export function getActiveEncryptedSaId(premiseInfo?: PremiseInfo[]) {
  if (!premiseInfo) {
    return null;
  }

  const encryptedSaId = premiseInfo.reduce(
    (saId, premise) =>
      saId +
      (premise.saDetails?.filter(
        (sa: Maybe<SaDetail>) => sa?.saStatus === SaStatus.Active,
      )[0]?.encryptedSAId ?? ''),
    '',
  );

  if (!Boolean(encryptedSaId)) {
    return null;
  }

  return encryptedSaId;
}

export function getActiveEncryptedPremiseId(premiseInfo?: PremiseInfo[]) {
  if (!premiseInfo) {
    return null;
  }

  return (
    premiseInfo.find(premise =>
      premise.saDetails?.some(sa => sa?.saStatus === SaStatus.Active),
    )?.encryptedPremiseId ?? ''
  );
}
