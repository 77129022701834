import React from 'react';
// svg Icons
import DetachedHomeIcon from '../../pge-plus-common/static/detachedHomeIcon.svg';
import TownHouseIcon from '../../pge-plus-common/static/townhouseIcon.svg';
import DublexIcon from '../../pge-plus-common/static/dublexIcon.svg';
import TriplexIcon from '../../pge-plus-common/static/triplexIcon.svg';
import ApartmentIcon from '../../pge-plus-common/static/apartmentIcon.svg';
import CondoIcon from '../../pge-plus-common/static/condoIcon.svg';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import { HomeType } from '../../../__generated__/pgeplus-types';

export interface HomeTypeProps {
  textLabel: string;
  formValue: string;
  img: React.ReactNode;
}

export const homeTypes = (content: PageTextContentType): HomeTypeProps[] => [
  {
    textLabel: content.get('DETACHED')!,
    formValue: HomeType.Detached,
    img: <DetachedHomeIcon />,
  },
  {
    textLabel: content.get('TOWNHOUSE')!,
    formValue: HomeType.Townhouse,
    img: <TownHouseIcon />,
  },
  {
    textLabel: content.get('DUPLEX')!,
    formValue: HomeType.Duplex,
    img: <DublexIcon />,
  },
  {
    textLabel: content.get('TRIPLEX')!,
    formValue: HomeType.Triplex,
    img: <TriplexIcon />,
  },
  {
    textLabel: content.get('APARTMENT')!,
    formValue: HomeType.Apartment,
    img: <ApartmentIcon />,
  },
  {
    textLabel: content.get('CONDO')!,
    formValue: HomeType.Condo,
    img: <CondoIcon />,
  },
];
