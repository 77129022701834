import { navigate } from 'gatsby';

import validationRules from '../components/payment-form/PaymentForm.rules';
import { UsePaymentFormResponse } from '../components/payment-form/PaymentForm.types';
import ROUTES from '../routes';
import useGlobalState from '../state/state';
import useValidationForm, { DefaultFormValuesProps } from './useValidationForm';

const defaultFieldValue = { value: '', errorMessage: '' };

export const defaultFormValues: DefaultFormValuesProps = {
  paymentAmount: defaultFieldValue,
  paymentDate: {
    ...defaultFieldValue,
    value: new Date().toDateString().substring(4),
  },
  phoneNumber: defaultFieldValue,
  routingNumber: defaultFieldValue,
  bankAccountNumber: defaultFieldValue,
  confirmBankAccountNumber: defaultFieldValue,
  rememberAccountInformation: { ...defaultFieldValue, value: false },
  bankAccountOption: { ...defaultFieldValue, value: 'currentBankAccount' },
};

export default (): UsePaymentFormResponse => {
  const props = useValidationForm(defaultFormValues, validationRules);
  const [state, setAccountState] = useGlobalState('account');

  const leftMenuData = {
    label: 'Billing & Payments',
    menuItems: [
      { label: 'Pay Bill', url: ROUTES.PAYMENT },
      { label: 'View Bill', url: ROUTES.VIEW_BILL },
      { label: 'Billing & Payment History', url: ROUTES.PAYMENT_HISTORY },
      { label: 'Need more time to pay?', url: '' },
      { label: 'Multi Pay / One-check', url: '' },
      { label: 'View & Download Bills', url: ROUTES.DOWNLOAD_MULTIPLE_BILLS },
    ],
  };

  const onLeftMenuSelect = async (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value?: string | null,
  ) => {
    if (value) {
      return navigate(value);
    }
    return;
  };

  const validateRoutingNumber = async (name: string, routingNumber: string) => {
    props.setFormState((prevState: { routingNumber: any }) => ({
      ...prevState,
      [name]: {
        ...prevState.routingNumber,
        value: routingNumber,
      },
    }));

    const { code, customer_name } = await fetch(
      `https://www.routingnumbers.info/api/data.json?rn=${routingNumber}`,
    ).then(response => response.json());

    if (code !== 200) {
      props.setErrorMessage(name, 'The routing number is invalid.');
    } else {
      setAccountState({
        ...state,
        bankName: customer_name,
        routingNumber,
      });
    }
  };

  const onBlur = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();
    props.onBlur(event);

    if (event?.target?.name === 'routingNumber') {
      await validateRoutingNumber(event?.target?.name, event?.target?.value);
    }
  };

  // add bill matrix logic

  // useMutation to submit payment - returns success boolean or throw error

  const bankAccountNumber =
    state.checkingAccount || props.bankAccountNumber.value;
  const maskedBankAccountNumber = `******${bankAccountNumber.substring(
    bankAccountNumber.length,
    bankAccountNumber.length - 4,
  )}`;

  const maskedAccountNumber = `*****${state?.accountNumber?.substring(
    state?.accountNumber?.length,
    state?.accountNumber?.length - 4,
  )} (Home)`;

  const handleSubmit = async () => {
    if (props?.routingNumber?.value?.length) {
      await validateRoutingNumber('routingNumber', props.routingNumber.value);
    }
    return navigate(ROUTES.PAYMENT_CONFIRMATION);
  };

  const paymentAmount = props?.paymentAmount?.value || state.amountDue;

  const formHeaders = [
    { title: `${state.accountNumber} (Home)` },
    { title: state.serviceAddress },
    { title: state.fullName },
  ];

  return {
    ...props,
    state,
    leftMenuData,
    onLeftMenuSelect,
    formHeaders,
    onBlur,
    handleSubmit,
    maskedBankAccountNumber,
    maskedAccountNumber,
    paymentAmount,
  };
};
