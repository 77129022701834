import { convertValidationRules } from '../../../../../hooks/useFormState';
import { BaseValidationContext } from '../../../../../hooks/useFormState.types';
import { useTranslation } from '../../../../../hooks/useTranslation';
import {
  validateAlphabetCharacters,
  validateBirthdate,
  validateEmail,
  validateExistsAndAlphabetCharacters,
  validatePhoneNumber,
} from '../../../../../util/form-validation';
import { AuthUserApplicant } from '../../../../../__generated__/pge-types';
import { SSMAuthUserInfoFormModel } from '../../../ssm.types';
import { parse, format } from 'date-fns';

const { t } = useTranslation();

export const createAuthUserInfoValidateFunction = () =>
  convertValidationRules<SSMAuthUserInfoFormModel>(
    (context: BaseValidationContext<SSMAuthUserInfoFormModel>) => {
      const {
        values: { email, birthDate },
      } = context;

      return {
        firstName: validateExistsAndAlphabetCharacters.bind(
          null,
          t('FIRST_NAME'),
        ),

        middleName: validateAlphabetCharacters.bind(
          null,
          t('MIDDLE_NAME_OR_INITIAL'),
        ),

        lastName: validateExistsAndAlphabetCharacters.bind(
          null,
          t('LAST_NAME'),
        ),
        birthDate: validateBirthdate,
        email: email ? validateEmail : null,
        primaryPhone: validatePhoneNumber,
      };
    },
  );
export const createAuthUserPayload = (
  customerInfo: SSMAuthUserInfoFormModel,
  accountNumber: string,
) => {
  const payload: AuthUserApplicant = {
    lastName: customerInfo.lastName,
    firstName: customerInfo.firstName,
    middleName: customerInfo.middleName,
    acctId: accountNumber,
    additionalInfo: {
      primaryPhone: customerInfo.primaryPhone,
      emailAddress: customerInfo.email,
      dateOfBirth: customerInfo.birthDate
        ? format(
            parse(customerInfo.birthDate, 'dd/mm/yyyy', new Date()),
            'yyyy-dd-mm',
          )
        : null,
    },
  };
  return payload;
};
