import React, { useEffect, useState, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from '../../../../hooks/useTranslation';
import gtmUtils from '../../gtm/useGTMUtilNmttp';

import { Typography, Slider, Grid } from '@material-ui/core';
import colors from '../../../../themes/main-colors';
import { calculateTpaInstallmentAmount } from '../tpaAmountCalculationUtil';
import debounce from 'lodash/debounce';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: theme.spacing(3.75, 0, 5.75, 0),
      position: 'relative',
      display: 'inline-block',
      '& .MuiSlider-root': {
        color: colors.sparkBlue,
      },
      '& .MuiSlider-track': {
        height: 20,
        borderRadius: 100,
        boxShadow: `0px 1px 1px ${colors.boxShadow}`,
      },
      '& .MuiSlider-rail': {
        width: '100%',
        height: 20,
        borderRadius: 100,
      },

      '& .MuiSlider-mark': {
        height: 10,
        width: 1,
        margin: '5px 0',
      },
      '& .MuiSlider-thumb': {
        width: 40,
        height: 40,
        boxShadow: `0px 5px 5px ${colors.boxShadowLight}`,
        border: `1px solid ${colors.oliveDrab}`,
        color: colors.white,
        margin: '-9px 0 0 -18px',
      },

      '& span.MuiSlider-root.MuiSlider-colorPrimary span:nth-child(4)': {
        display: 'none',
      },
      '& span.MuiSlider-root.MuiSlider-colorPrimary span:nth-last-child(2)': {
        display: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        width: '98%',
        '& .MuiSlider-thumb': {
          width: 35,
          height: 35,
        },
      },
      [theme.breakpoints.down('xs')]: {
        width: '93%',
      },
    },
    tpaSliderContent: {
      textAlign: 'center',
      padding: theme.spacing(0, 9, 0, 9),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(0, 0, 0, 0),
      },
      [theme.breakpoints.down('sm')]: {
        lineHeight: '1.25rem',
        fontSize: '1rem',
      },
    },
    amount: {
      position: 'absolute',
      top: 5,
      fontSize: '14px',
      [theme.breakpoints.down('sm')]: {
        top: 15,
      },
    },
    month: {
      position: 'absolute',
      bottom: 5,
      fontSize: '14px',
      minWidth: 45,
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        bottom: 15,
      },
    },
  }),
);

interface Props {
  recommendedPaymentMonths: number;
  accountBalance: number;
  updateMonthlyInstallmentAmount: (installment: number, months: number) => void;
}
const TPASlider: React.FC<Props> = props => {
  const {
    accountBalance,
    recommendedPaymentMonths,
    updateMonthlyInstallmentAmount,
  } = props;

  useEffect(() => {
    getXCoordinatesForLabelDisplay(recommendedPaymentMonths);
    updateMonthlyInstallmentAmount(
      calculateTpaInstallmentAmount(accountBalance, recommendedPaymentMonths),
      recommendedPaymentMonths,
    );
  }, []);

  const [sliderValue, setSliderValue] = React.useState(
    recommendedPaymentMonths,
  );
  const amountEleRef = useRef<HTMLHeadingElement>(null);
  const monthEleRef = useRef<HTMLHeadingElement>(null);
  const sliderEleRef = useRef<HTMLElement>(null);
  const classes = useStyles();
  const { t } = useTranslation();

  const getXCoordinatesForLabelDisplay = (months: number) => {
    const sliderEle =
      sliderEleRef?.current &&
      sliderEleRef?.current?.querySelector<HTMLElement>(
        '[aria-labelledby="TPASlider"]',
      );
    const amountEle = amountEleRef?.current;
    const monthEle = monthEleRef?.current;
    if (sliderEle && amountEle && monthEle) {
      const left = parseFloat(sliderEle.style.left);
      amountEle.style.left = `${left - 4}%`; // subtracting -4 with left for alignment
      monthEle.style.left = `${left - 5}%`;
      monthEle.textContent = `${months} mo`;
      amountEle.textContent = `$${calculateTpaInstallmentAmount(
        accountBalance,
        months,
      )}`;
    }
    return '';
  };

  const { gtm_TPASlider_HandleSlideChange } = gtmUtils();

  const updateInstallment = (changeMonth: number) => {
    gtm_TPASlider_HandleSlideChange();
    updateMonthlyInstallmentAmount(
      calculateTpaInstallmentAmount(accountBalance, changeMonth),
      changeMonth,
    );
  };
  // This is used to handle multiple callback initiate by slider by callback on every drag:
  // ref: https://stackoverflow.com/questions/62522994/how-do-i-lower-the-rate-of-http-requests-triggered-due-to-onchange-for-material?answertab=votes#tab-top
  const [updateInstallmentDebounce] = useState(() =>
    debounce(updateInstallment, 300, {
      leading: false,
      trailing: true,
    }),
  );

  const handleSlideChange = (e: object, selectedValue: any) => {
    const changeMonth = selectedValue > 1 ? selectedValue : 2;
    setSliderValue(changeMonth);
    updateInstallmentDebounce(changeMonth);
  };

  return (
    <>
      <Typography variant={'h6'} className={classes.tpaSliderContent}>
        {t('PAYMENT_PLAN_SLIDER_CARD_TEXT')}
      </Typography>
      <Grid className={classes.root}>
        <Typography
          data-testid={'tpa-amount-element'}
          ref={amountEleRef}
          className={classes.amount}
          variant={'h5'}
        />

        <Slider
          key={`slider-${recommendedPaymentMonths}`}
          data-testid={'tpa-slider-element'}
          ref={sliderEleRef}
          getAriaValueText={getXCoordinatesForLabelDisplay}
          defaultValue={recommendedPaymentMonths}
          value={sliderValue}
          color="primary"
          aria-labelledby="TPASlider"
          step={1}
          marks
          min={1}
          max={12}
          onChange={handleSlideChange}
        />
        <Typography
          data-testid={'tpa-month-element'}
          ref={monthEleRef}
          className={classes.month}
          variant={'h5'}
        />
      </Grid>
    </>
  );
};

export default TPASlider;
