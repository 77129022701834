import React, { FC, useEffect, useState, useContext } from 'react';
import Helmet from 'react-helmet';
import { Grid, Typography, withWidth } from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import PaperlessBillForm from '../../../components/paperless-bill-form';
import ProfileLayout from '../../../components/profile-layout';
import AccountDropdown from '../../../components/account-dropdown';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';

import { getAccountDetailsQuery } from '../../../queries/paperlessBilling.query';
import { AccountDetail } from '../../../__generated__/pge-types';
import Backdrop from '../../../components/backdrop';
import { formatServiceAddress } from '../../../util/format';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import useAuthQuery from '../../../hooks/useAuthQuery';
import { Router, navigate } from '@reach/router';
import ROUTES from '../../../routes';
import PaperlessDone from '../../../components/paperless-bill-form/paperless-bill-form-done';
import { BillMethodOptions } from '../../../components/paperless-bill-form/PaperlessBillForm.types';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import { usePaperlessBillForm } from '../../../components/paperless-bill-form/usePaperlessBillForm';

type Props = {
  path?: string;
};

const PaperlessPage: FC<Props> = () => {
  const { t } = useTranslation();
  const { customer } = useAccountCustomer();
  const { wrapWithLoader } = useWrapWithLoader();
  const { accountParams } = useSelectedAccountParams();
  const notificationContext = useContext(NotificationsContext);

  const [isPaperlessSubmitted, setIsPaperlessSubmitted] = useState<boolean>(
    false,
  );

  const submitPaperlessService = usePaperlessBillForm();

  const [
    updatedBillingMethod,
    setUpdatedBillingMethod,
  ] = useState<BillMethodOptions | null>(null);

  const { loading: hookLoading, data, refetch } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetailsQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams,
  });

  const account = data?.getAccountDetails?.[0]!;

  useEffect(() => {
    setIsPaperlessSubmitted(false);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(ROUTES.PAPERLESS_BILLING);
  }, [account?.accountNumber]);

  const loading = hookLoading || !data;
  if (loading) {
    return <Backdrop forceOpen />;
  }

  const accountBillingMethod = account?.isPaperlessBillEnrolled?.result
    ? 'PAPERLESS'
    : 'MAIL';

  const postalAddress = account?.mailingAddress?.addressLine1
    ? account?.mailingAddress
    : // eslint-disable-next-line deprecation/deprecation
      account?.premiseInfo?.[0];

  const encryptedPersonId = accountParams?.encryptedPersonId;

  const paperlessBillFormProps = {
    customerEmail: account?.paperLessBillInfo?.paperLessEmail || '',
    customerAddress: formatServiceAddress(postalAddress || null),
    accountNumber: accountParams?.accountNumber || '',
    encryptedPersonId: encryptedPersonId || '',
    isLoggedInUserOnAccount: account?.isLoggedInUserOnAccount,
  };

  const handleConfirmSubmit = wrapWithLoader(async function(
    billingMethod: BillMethodOptions,
  ) {
    const result = await submitPaperlessService.submitPaperless(
      encryptedPersonId || '',
      account.encryptedAccountNumber,
      billingMethod === 'MAIL' ? false : true,
      customer?.email || '',
    );

    if (result?.success) {
      setUpdatedBillingMethod(billingMethod);
      setIsPaperlessSubmitted(true);

      await refetch();
      return navigate(ROUTES.PAPERLESS_BILLING_DONE);
    }
  });

  function handleDoneSubmit() {
    setIsPaperlessSubmitted(false);

    return navigate(ROUTES.ACCOUNT);
  }

  const isOnPaperless = location.pathname === ROUTES.PAPERLESS_BILLING;

  return (
    <ProfileLayout dense={false}>
      <Helmet>
        <title>{t('PAPERLESS_BILL')}</title>
      </Helmet>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant={'h1'}>{t('PAPERLESS_BILL')}</Typography>
        </Grid>
        {isOnPaperless && (
          <Grid item>
            <AccountDropdown />
          </Grid>
        )}
        <Grid item>
          <Router basepath="/">
            <PaperlessBillForm
              path={ROUTES.PAPERLESS_BILLING}
              onSubmit={handleConfirmSubmit}
              {...paperlessBillFormProps}
              customerBillMethod={accountBillingMethod}
            />
            <PaperlessDone
              path={ROUTES.PAPERLESS_BILLING_DONE}
              onSubmit={handleDoneSubmit}
              customerAddress={paperlessBillFormProps.customerAddress}
              customerEmail={paperlessBillFormProps.customerEmail}
              customerBillMethod={updatedBillingMethod}
            />
          </Router>
        </Grid>
      </Grid>
    </ProfileLayout>
  );
};
export default withWidth()(PaperlessPage);
