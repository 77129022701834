import { AlertState, AlertStates, Model } from './types';
import { NotificationType } from '../../../__generated__/pge-types';

export const allAlertTypes = [
  NotificationType.Out,
  NotificationType.Webpyrem,
  NotificationType.Webpyrcv,
  NotificationType.Webpda,
  NotificationType.Webdisc,
  NotificationType.Webuse,
  NotificationType.Webexc,
] as const;

export const allManageAlertTypes = [
  NotificationType.Out,
  NotificationType.Webpyrem,
  NotificationType.Webpyrcv,
  NotificationType.Webpda,
  NotificationType.Webdisc,
  NotificationType.Webuse,
  NotificationType.Webexc,
  NotificationType.Busiconn,
  NotificationType.Cleanenergy,
  NotificationType.Energysol,
  NotificationType.Homeconn,
  NotificationType.Homeevcharge,
  NotificationType.Moderngrid,
  NotificationType.Netmtr,
  NotificationType.Outagepl,
  NotificationType.Outagese,
  NotificationType.Outageupl,
  NotificationType.Paperless,
  NotificationType.Ptrevent,
  NotificationType.Ptrmkt,
  NotificationType.Rest,
  NotificationType.Savebill,
  NotificationType.Smtther,
  NotificationType.Timeofday,
] as const;

export const defaultAlertState: AlertState = {
  email: false,
  text: false,
};

export const defaultAlertStates: AlertStates = {
  [NotificationType.Out]: defaultAlertState,
  [NotificationType.Webpyrem]: defaultAlertState,
  [NotificationType.Webpyrcv]: defaultAlertState,
  [NotificationType.Webpda]: defaultAlertState,
  [NotificationType.Webdisc]: defaultAlertState,
  [NotificationType.Webuse]: defaultAlertState,
  [NotificationType.Webexc]: defaultAlertState,
  [NotificationType.Busiconn]: defaultAlertState,
  [NotificationType.Cleanenergy]: defaultAlertState,
  [NotificationType.Energysol]: defaultAlertState,
  [NotificationType.Homeconn]: defaultAlertState,
  [NotificationType.Homeevcharge]: defaultAlertState,
  [NotificationType.Moderngrid]: defaultAlertState,
  [NotificationType.Netmtr]: defaultAlertState,
  [NotificationType.Outagepl]: defaultAlertState,
  [NotificationType.Outagese]: defaultAlertState,
  [NotificationType.Outageupl]: defaultAlertState,
  [NotificationType.Paperless]: defaultAlertState,
  [NotificationType.Ptrevent]: defaultAlertState,
  [NotificationType.Ptrmkt]: defaultAlertState,
  [NotificationType.Rest]: defaultAlertState,
  [NotificationType.Savebill]: defaultAlertState,
  [NotificationType.Smtther]: defaultAlertState,
  [NotificationType.Timeofday]: defaultAlertState,
};

export const noTextChecked = (data: Model) => {
  return allAlertTypes.every(alrt => !data[alrt].text);
};
