import { useApolloClient } from '@apollo/react-hooks';
import { useState } from 'react';
import useAuthQuery from '../../hooks/useAuthQuery';
import useFormState from '../../hooks/useFormState';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import { useTranslation } from '../../hooks/useTranslation';
import { AccountDetail } from '../../__generated__/pge-types';
import {
  getAccounDetailsForPeakTimeRebateGiveBackQuery,
  peakTimeRebateGiveBackMutation,
} from './giveBack.queries';
import validationRules from './giveBack.rules';
import { GiveBackInfo } from './giveBack.types';

const defaultGiveBakFormValues: GiveBackInfo = {
  organization: '',
  enrollment: 'Winter',
};

export default () => {
  const { t } = useTranslation();
  const apolloClient = useApolloClient();
  const [isEligible, setIsEligible] = useState(false);

  const {
    encryptedAccountNumber,
    loading: accountParamsLoading,
    accountParams,
    encryptedPersonId,
  } = useSelectedAccountParams();

  const giveBackForm = useFormState(defaultGiveBakFormValues, {
    validate: validationRules,
    validationContext: { t },
  });

  const { loading: queryLoading, data } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccounDetailsForPeakTimeRebateGiveBackQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
    skip: !accountParams,
    onCompleted: result => {
      setIsEligible(
        result?.getAccountDetails?.[0]?.peakTimeRebateGiveBack?.isEligible ||
          false,
      );
      giveBackForm.reset({
        organization:
          result?.getAccountDetails?.[0]?.peakTimeRebateGiveBack
            ?.organizationName || 'None',
        enrollment:
          result?.getAccountDetails?.[0]?.peakTimeRebateGiveBack
            ?.peakTimeSeason || 'Winter',
      });
    },
  });

  const loading = queryLoading || accountParamsLoading || !data;

  const submitPeakTimeRebatesGiveBack = async (): Promise<
    boolean | undefined
  > => {
    const payload: any = {
      encryptedAccountNumber: encryptedAccountNumber,
      organizationName: giveBackForm.values.organization,
      peakTimeSeason: giveBackForm.values.enrollment,
    };

    const { data: giveBackSubmitData, errors } = await apolloClient.mutate({
      mutation: peakTimeRebateGiveBackMutation,
      variables: { payload },
    });

    if (errors && errors.length > 0) {
      throw new Error('Mutation error.');
    }

    return Boolean(giveBackSubmitData?.success);
  };

  return {
    isEligible,
    loading,
    giveBackForm,
    submitPeakTimeRebatesGiveBack,
  };
};
