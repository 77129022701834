import React, { useEffect } from 'react';

import { Box, MenuItem, Select, Typography, useTheme } from '@material-ui/core';
import { useIsMobile } from '../../../util/style-utils';
import {
  AccountDetail,
  AccountStatusType,
  AccountType,
  PremiseInfo,
} from '../../../__generated__/pge-types';
import useAccountListHeader from '../../account-summary/multi-accounts/useAccountListHeader';
import useAccountDetailList from '../../../hooks/useAccountDetailList';
import AccountDropdown from '../../account-dropdown/AccountDropdown';
import colors from '../../../themes/main-colors';
import { VirtualAccountType } from '../../../constants/customEnum';
import { getFromStorage } from '../../../util/storage-utils';
import { SELECTED_ACCOUNT_STORAGE_KEY } from '../../../protected-pages/account/summary';
import { PageTextContentType } from '../../../hooks/useContentMgmt';
import { getAccountsListForIQBDForm } from '../queries';

interface FormTopNavbarProps {
  children?: React.ReactNode;
  content: PageTextContentType;
  selectedAccount?: AccountDetail;
  selectedPremise?: PremiseInfo;
  onSelectAccount: (
    account: AccountDetail,
    shouldHideAccountSelection?: boolean,
  ) => void;
  onSelectPremise: (selectedPremise: PremiseInfo) => void;
}

export const FormTopNavbar: React.FC<FormTopNavbarProps> = ({
  content,
  selectedAccount,
  selectedPremise,
  onSelectAccount,
  onSelectPremise,
}) => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  const {
    accountListParams,
    onAccountSearch,
    onAccountSort,
    onAccountPageChange,
  } = useAccountListHeader();

  const {
    loading: loadingAccounts,
    accounts,
    totalCount,
  } = useAccountDetailList(
    getAccountsListForIQBDForm,
    VirtualAccountType.ALL_ACCTS,
    {
      accountListParams: {
        ...accountListParams,
        accountStatus: AccountStatusType.Active,
      },
    },
    'no-cache',
  );

  useEffect(() => {
    const selectedAccountNumber = getFromStorage(SELECTED_ACCOUNT_STORAGE_KEY);
    if (accounts?.length > 0 && !selectedAccount && !accountListParams.query) {
      const accountToSelect = Boolean(selectedAccountNumber)
        ? accounts.find(
            account => account.accountNumber === selectedAccountNumber,
          )
        : accounts.find(account => account.accountType === AccountType.Res) ||
          accounts[0];
      onSelectAccount(
        accountToSelect!,
        accounts.length === 1 && accounts[0].premiseInfo?.length === 1,
      );
    }
  }, [accounts]);

  return (
    <Box css={{ margin: '16px 0' }}>
      {accounts?.length > 1 ||
      accountListParams.query.length ||
      loadingAccounts ? (
        <AccountDropdown
          onAccountSearch={onAccountSearch}
          onAccountSort={onAccountSort}
          onAccountPageChange={onAccountPageChange}
          accountListParams={accountListParams}
          totalCount={totalCount || 0}
          loading={loadingAccounts}
          accounts={accounts}
          selectedAccount={selectedAccount}
          onSelect={account => {
            onSelectAccount(account);
          }}
          showSelectedAccountOnly={false}
          isMobile={isMobile}
          disableNonResAccounts={true}
        />
      ) : (
        <Box
          css={{
            display: 'flex',
            alignItems: 'center',
            [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
              display: 'block',
            },
          }}
        >
          <Typography
            css={{
              fontFamily: 'Untitled-Sans',
              lineHeight: '1.5',
              fontSize: '1.125rem',
              marginRight: '8px',
              [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                fontSize: '1.08rem',
                lineHeight: 1.5,
              },
            }}
          >
            {content.get('SELECTED_ACCOUNT')}{' '}
          </Typography>
          <Box
            css={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px',
              border: '1px solid rgba(0, 0, 0, 0.23)',
              backgroundColor: colors.white,
              flexGrow: 1,
              borderRadius: '4px',
            }}
          >
            <Typography
              variant="h3"
              css={{
                fontFamily: 'Forma-DJR-Display',
                fontWeight: 'bold',
                letterSpacing: '0.360px',
                color: colors.noirBlur,
              }}
            >
              {selectedAccount?.accountNumber}
            </Typography>
            <Typography
              css={{
                fontFamily: 'Untitled-Sans',
                fontWeight: 400,
                lineHeight: '1.43',
                fontSize: '0.875rem',
                color: colors.noirBlur,
              }}
            >
              {selectedAccount?.premiseInfo?.[0]?.addressLine1}
            </Typography>
          </Box>
        </Box>
      )}

      {selectedAccount?.premiseInfo && selectedAccount?.premiseInfo.length > 1 && (
        <Box
          css={{
            display: 'flex',
            marginTop: '16px',
            alignItems: 'center',
            [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
              display: 'block',
            },
          }}
        >
          <Typography
            css={{
              fontFamily: 'Untitled-Sans',
              whiteSpace: 'nowrap',
              marginRight: '8px',
              [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
                marginBlock: '4px',
              },
            }}
          >
            {content.get('SELECTED_ADDRESS')}{' '}
          </Typography>
          <Select
            css={{ backgroundColor: colors.white, minHeight: '55px' }}
            variant="outlined"
            displayEmpty={true}
            renderValue={() => selectedPremise?.addressLine1}
            style={{
              width: '100%',
              height: '48px',
              overflow: 'hidden',
              fontSize: '16px',
            }}
            inputProps={{ maxLength: 90, fontSize: 16 }}
            onChange={event =>
              onSelectPremise(
                JSON.parse(event.target.value as string) as PremiseInfo,
              )
            }
          >
            {selectedAccount.premiseInfo.map((premise, idx) => (
              <MenuItem key={idx} value={JSON.stringify(premise)}>
                {premise?.addressLine1}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
    </Box>
  );
};
