import React, { FC, useState } from 'react';
import {
  Typography,
  makeStyles,
  createStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useTranslation } from '../../../../hooks/useTranslation';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Theme } from '@material-ui/core/styles';

import colors from '../../../../themes/main-colors';
import ROUTES from '../../../../routes';
import ViewAccountBalance from './ViewAccountBalance';
import {
  CurrentChargeIconWrapper,
  MonthlyInstallIconWrapper,
  TotalBillIconWrapper,
} from './TpaIcon';
import TPAIconInfoBox from './TPAIconInfoBox';
import TPASlider from './TPASlider';
import ProceedOrCancel from '../../../proceed-or-cancel/ProceedOrCancel';
import { convertToHigherValue } from '../tpaAmountCalculationUtil';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardWrapper: {
      padding: theme.spacing(2, 0, 0, 0),
      margin: theme.spacing(0, 0, 1, 0),
      [theme.breakpoints.down('md')]: {
        margin: theme.spacing(0, 0, 1, 0),
        padding: theme.spacing(0, 0, 0, 0),
      },
    },

    tpaTitle: {
      padding: theme.spacing(0, 0, 3, 0),
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.13rem',
        fontWeight: 800,
        padding: theme.spacing(0, 0, 2.5, 0),
      },
    },
    tpaContent: {
      padding: theme.spacing(0, 0, 2, 0),
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
        lineHeight: '1.25rem',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    amountTab: {
      border: '1px solid #707070',
      [theme.breakpoints.down('sm')]: {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      },
      [theme.breakpoints.up('sm')]: {
        direction: 'row',
      },
      position: 'relative',
      backgroundColor: '#F7F7F7',
      borderRadius: 5,
    },
    tpaCardText: {
      textAlign: 'center',
      padding: theme.spacing(3, 0, 2, 0),
    },
    infoTooltip: {
      display: 'block',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
      position: 'absolute',
      top: 0,
      right: 0,
      padding: 4,
    },
    infoTooltipMob: {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
      position: 'relative',
    },
    infoTooltipMoSection: {
      position: 'absolute',
      right: 0,
      padding: 5,
    },

    tpaContainer: {
      padding: theme.spacing(3, 3.5, 3, 3.5),
      [theme.breakpoints.down('sm')]: {
        padding: '24px 18px 60px !important',
        // Note: with !important other higher specificity rules don't win. https://css-tricks.com/when-using-important-is-the-right-choice/
      },
    },

    tpaSliderContainer: {
      backgroundColor: colors.aliceBlue,
      borderRadius: 5,
      padding: theme.spacing(4, 3.75, 4, 3.75),
      [theme.breakpoints.down('md')]: {
        padding: theme.spacing(2, 2, 2, 2),
      },
      [theme.breakpoints.down('sm')]: {
        borderTop: 0,
        padding: theme.spacing(1, 2, 1, 2),
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
      },
      border: `1px solid ${colors.sparkBlue}`,
    },
    reviewPlan: {
      marginTop: theme.spacing(3),
    },
  }),
);

interface Props {
  path: string;
  numberOfMonths: number;
  accountBalance?: number;
  currentCharges: number;
  totalBill?: number;
  dueDate: Date;
  setInstallmentAmount: (installment: number, months: number) => void;
  handleOnReviewPlanClick: (
    installmentAmount: number,
    numberOfMonths: number,
  ) => void;
  handleOnBackClick: (
    installmentAmount: number,
    numberOfMonths: number,
  ) => void;
}

const TPACreatePlan: FC<Props> = (props: Props) => {
  const {
    path,
    numberOfMonths,
    currentCharges,
    accountBalance,
    dueDate,
    setInstallmentAmount,
    handleOnReviewPlanClick,
    handleOnBackClick,
  } = props;
  const [monthlyInstallmentAmount, setMonthlyInstallmentAmount] = useState<
    number
  >(0);
  const [numOfMonths, setNumOfMonths] = useState<number>(numberOfMonths);
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const updateMonthlyInstallmentAmount = (
    installment: number,
    months: number,
  ) => {
    setMonthlyInstallmentAmount(installment);
    setNumOfMonths(months);
    setInstallmentAmount(installment, months);
  };

  const backButtonClick = () => {
    handleOnBackClick(monthlyInstallmentAmount, numOfMonths);
  };
  const reviewButtonClick = () => {
    handleOnReviewPlanClick(monthlyInstallmentAmount, numOfMonths);
  };

  return (
    <>
      <ViewAccountBalance amount={accountBalance!} dueDate={dueDate} />
      <Paper>
        <Card>
          <CardContent className={classes.tpaContainer}>
            <Grid>
              <Typography variant={'h2'} className={classes.tpaTitle}>
                {t('PAYMENT_PLAN_NMTTP')}
              </Typography>
              <Typography variant={'h6'} className={classes.tpaContent}>
                {t('PAYMENT_PLAN_PARAGRAPH_TEXT')}
              </Typography>
              <Typography variant={'h5'} className={classes.tpaContent}>
                {t('PAYMENT_PLAN_PARAGRAPH_TEXT_BOLD')}
              </Typography>
            </Grid>

            <Grid
              container
              justify="center"
              alignItems="center"
              xs={12}
              md={12}
              spacing={2}
              className={classes.cardWrapper}
            >
              <TPAIconInfoBox
                SvgIcon={CurrentChargeIconWrapper}
                title={t('CURRENT_CHARGES')}
                amount={`$${currentCharges}`}
                tooltipContent={t('CURRENT_CHARGES_INFO_TOOL_TIP')}
              />

              <TPAIconInfoBox
                SvgIcon={MonthlyInstallIconWrapper}
                title={t('MONTHLY_INSTALLMENTS')}
                amount={`$${monthlyInstallmentAmount}/${numOfMonths}mo`}
                tooltipContent={t('MONTHLY_INSTALLMENTS_INFO_TOOL_TIP')}
                isMonthlyInstallment={true}
              />
              {isMobile && (
                <Grid xs={12} className={classes.tpaSliderContainer}>
                  <TPASlider
                    accountBalance={accountBalance!}
                    recommendedPaymentMonths={numberOfMonths}
                    updateMonthlyInstallmentAmount={
                      updateMonthlyInstallmentAmount
                    }
                  />
                </Grid>
              )}

              <TPAIconInfoBox
                SvgIcon={TotalBillIconWrapper}
                title={t('TOTAL_BILL')}
                amount={`$${convertToHigherValue(
                  currentCharges + monthlyInstallmentAmount,
                  2,
                )}`}
                tooltipContent={t('TOTAL_BILL_BOX_INFO_TOOL_TIP')}
              />
            </Grid>
            {!isMobile && (
              <Grid className={classes.tpaSliderContainer}>
                <TPASlider
                  accountBalance={accountBalance!}
                  recommendedPaymentMonths={numberOfMonths}
                  updateMonthlyInstallmentAmount={
                    updateMonthlyInstallmentAmount
                  }
                />
              </Grid>
            )}
          </CardContent>
        </Card>
      </Paper>
      <Grid className={classes.reviewPlan}>
        <ProceedOrCancel
          proceedDisabled={false}
          proceedLabel={t('TPA_STEPPER_LABEL_2')}
          proceedHandler={reviewButtonClick}
          cancelLabel={t('BACK')}
          cancelHandler={backButtonClick}
          cancelRoute={ROUTES.NEED_MORE_TIME_TO_PAY_PLAN_OPTIONS}
        />
      </Grid>
    </>
  );
};
export default TPACreatePlan;
