import React, { createContext, useReducer } from 'react';
import { ContactInfo } from '../components/contact-info-form/ContactInfoForm.types';

const fakeData = {
  businesses: [
    { name: 'Strauss, Robert E', accounts: [], isSelected: false },
    { name: 'Clackamas County', accounts: [], isSelected: false },
  ],
};

export enum HistoricalUsageAuthType {
  RELEASE = 'RELEASE',
  REVOKE = 'REVOKE',
}

export interface HistoricalUsageContextState {
  businesses: { name: string; accounts: any[]; isSelected: boolean }[];
  authType: HistoricalUsageAuthType;
  isSubmitted: boolean;
  isSamePersonAuthorizing: boolean;
  submittingContact?: ContactInfo;
  authorizingContact?: ContactInfo;
}

export enum HistoricalUsageContextActionType {
  BUSINESS_SELECTION = 'SELECT_BUSINESS',
  SET_CONTACT_INFO = 'SET_CONTACT_INFO',
  SET_AUTH_TYPE = 'SET_AUTH_TYPE',
  SUBMIT = 'SUBMIT',
}

export type HistoricalUsageContextAction =
  | {
      type: HistoricalUsageContextActionType.BUSINESS_SELECTION;
      name: string;
      isSelected: boolean;
    }
  | {
      type: HistoricalUsageContextActionType.SET_CONTACT_INFO;
      submittingContact: ContactInfo;
      isSamePersonAuthorizing: boolean;
      authorizingContact?: ContactInfo;
    }
  | {
      type: HistoricalUsageContextActionType.SET_AUTH_TYPE;
      authType: HistoricalUsageAuthType;
    }
  | {
      type: HistoricalUsageContextActionType.SUBMIT;
    };

interface HistoricalUsageContext {
  state: HistoricalUsageContextState;
  dispatch: (action: HistoricalUsageContextAction) => void;
}

const initialState: HistoricalUsageContext = {
  state: {
    businesses: fakeData.businesses,
    authType: HistoricalUsageAuthType.RELEASE,
    isSubmitted: false,
    isSamePersonAuthorizing: true,
    submittingContact: undefined,
    authorizingContact: undefined,
  },
  dispatch: (action: HistoricalUsageContextAction) => {
    return;
  },
};

// eslint-disable-next-line no-redeclare
export const HistoricalUsageContext = createContext<HistoricalUsageContext>(
  initialState,
);

const { Provider } = HistoricalUsageContext;

const reducer = (
  state: HistoricalUsageContextState,
  action: HistoricalUsageContextAction,
): HistoricalUsageContextState => {
  switch (action.type) {
    case HistoricalUsageContextActionType.SET_CONTACT_INFO:
      return {
        ...state,
        isSamePersonAuthorizing: action.isSamePersonAuthorizing,
        submittingContact: action.submittingContact,
        authorizingContact: action.authorizingContact,
      };
    case HistoricalUsageContextActionType.BUSINESS_SELECTION:
      const business = state.businesses.find(b => b.name === action.name);
      if (business) {
        business.isSelected = action.isSelected;
      }
      return { ...state };
    case HistoricalUsageContextActionType.SET_AUTH_TYPE:
      state.authType = action.authType;
      return { ...state };
    case HistoricalUsageContextActionType.SUBMIT:
      state.isSubmitted = true;
      return { ...state };
    default:
      return state;
  }
};

export const HistoricalUsageContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState.state);
  return <Provider value={{ state, dispatch }}>{props.children}</Provider>;
};
