import React, { FunctionComponent } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  Theme,
  useTheme,
  Card,
  CardContent,
} from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import useOpenEnrollment from '../../../hooks/useOpenEnrollment';
import Link from '../../text-link';
import PGEButton from '../../buttons/PGE-Button';
import { isMobile } from '../../../util/style-utils';
import { navigate } from '@reach/router';
import ROUTES from '../../../routes';
import Pagination from '../../pagination';
import PlanSelector from '../../plan-selector';

const useStyles = makeStyles((theme: Theme) => ({
  actionArea: {
    [theme.breakpoints.down('sm')]: {
      padingLeft: theme.spacing(4),
      padingRight: theme.spacing(4),
    },
  },
  formAction: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface OpenEnrollmentConfirmProps {
  path?: string;
}

const OpenEnrollmentConfirm: FunctionComponent<OpenEnrollmentConfirmProps> = props => {
  const { t } = useTranslation();
  const {
    content,
    summaryRecords,
    summaryPaginationProps,
  } = useOpenEnrollment();
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Grid container direction={'column'} spacing={4}>
      <Grid item>
        <Typography variant={'h2'}>{content.confirmPage.title}</Typography>
      </Grid>
      <Grid item>
        <Card>
          <CardContent>
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <Typography variant={'h2'}>
                  {content.confirmPage.summaryTitle}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant={'body2'}>
                  {content.confirmPage.summaryText}
                </Typography>
              </Grid>
              {summaryRecords?.length ? (
                <Grid item>
                  <Pagination {...summaryPaginationProps} />
                  <PlanSelector accounts={summaryRecords} editMode={false} />
                </Grid>
              ) : (
                <>
                  <Grid item>
                    <Typography variant={'body2'}>
                      <strong>{content.confirmPage.summaryNoChangeText}</strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'body2'}>
                      {content.confirmPage.summaryNoChangeNote}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        container
        style={
          isMobile()
            ? { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }
            : {}
        }
        direction={isMobile() ? 'column-reverse' : 'row'}
        justify={'space-between'}
        alignItems={isMobile() ? 'stretch' : 'center'}
        spacing={1}
      >
        <Grid item>
          {summaryRecords?.length > 0 && (
            <Grid container justify={'center'}>
              <Link plain to={ROUTES.ACCOUNT}>
                <Typography className={classes.formAction}>
                  {t('CANCEL')}
                </Typography>
              </Link>
            </Grid>
          )}
        </Grid>

        <Grid item>
          <Grid
            container
            direction={isMobile() ? 'column-reverse' : 'row'}
            spacing={2}
          >
            <Grid item>
              <PGEButton
                variant={'outlined'}
                color={'secondary'}
                onClick={() => navigate(ROUTES.OPEN_ENROLLMENT_PLAN)}
                className={classes.formAction}
              >
                {content.back}
              </PGEButton>
            </Grid>
            {summaryRecords?.length ? (
              <Grid item>
                <PGEButton
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => navigate(ROUTES.OPEN_ENROLLMENT_DONE)}
                  className={classes.formAction}
                >
                  {content.confirmPage.confirm}
                </PGEButton>
              </Grid>
            ) : (
              <Grid item>
                <PGEButton
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => navigate(ROUTES.ACCOUNT)}
                  className={classes.formAction}
                >
                  {content.done}
                </PGEButton>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default OpenEnrollmentConfirm;
