import React, { useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ServiceAddressListItem from './ServiceAddressListItem';
import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';
import { useTheme } from '@material-ui/core/styles';
import {
  AccountDetail,
  ServiceAgreementNickname,
  SaStatus,
} from '../../../__generated__/pge-types';
import { Chip, useMediaQuery } from '@material-ui/core';
import ServiceAddressDisplay from './ServiceAddressDisplay';
import useStyles from './AccountListItem.styles';
import TextLink from '../../text-link';
import {
  displayAccountIdentifier,
  toDateStringFullMonthName,
  toCurrencyDisplayFormat,
} from '../../../util/format';
import ROUTES from '../../../routes';
import ListItem from '../../list-item';
import useAccountListItemMenuItems, {
  BillMenuType,
} from './useAccountListItemMenuItems';
import { useGetSAStatusInfo } from './useGetSAStatusInfo';
import { featureFlagsContext } from '../../../providers/FeatureFlagsProvider';
import { NickNameAddressPayload } from '../../nickname-change-modal/types';

type Props = {
  account: AccountDetail;
  groupId?: string;
  manageAccounts?: boolean;
  hideMenu?: boolean;
  onCheckboxChange: () => void;
  checked: boolean;
  checkDisabled?: boolean;
  inactiveText?: string;
  onChangePaymentAmount?: () => void;
  children?: React.ReactNode;
  nicknames?: [ServiceAgreementNickname];
  onViewDetails?: (account: AccountDetail) => void;
  billMenuType?: BillMenuType;
  accountsListCacheUpdate?: (payload: NickNameAddressPayload) => void;
};

export default function AccountListItem({
  account,
  groupId,
  manageAccounts = false,
  hideMenu,
  checked,
  onCheckboxChange,
  inactiveText,
  children,
  nicknames,
  onViewDetails,
  billMenuType = 'PAY',
  checkDisabled = false,
  accountsListCacheUpdate,
}: Props) {
  const { t } = useTranslation();
  const [showAddressList, setShowingAddressList] = useState(false);
  const { isClosedAccountsEnabled } = useContext(featureFlagsContext);
  const classes = useStyles();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
  const pastDue = Boolean(account.pendingDisconnect?.isPendingDisconnect);
  const isDisconnected = Boolean(account.serviceConnectivity?.isDisconnected);
  const isEligibleForReconnect = Boolean(
    account.serviceConnectivity?.isEligibleForReconnect,
  );
  const disconnectionDate = account.serviceConnectivity?.disconnectionDate;
  const isEnrolledInAutoPay = Boolean(account?.autoPay?.isEnrolled);
  // Three cases being handled below

  // Case 1: An account having no premises and hence no saDetails
  const premiseInfoNotFound = !account?.premiseInfo?.length;

  // Case 2: An account having just one premise and that premise having just one item in saDetails
  const accountHasASinglePremiseOrASingleSA =
    account?.premiseInfo?.length === 1 &&
    account?.premiseInfo[0]?.saDetails?.length === 1;

  // Case 3: An account having more than one premise or more than one item in saDetails
  const accountHasMultiplePremisesOrMultipleSA =
    !premiseInfoNotFound && !accountHasASinglePremiseOrASingleSA;
  const accordionToggle = isMobile && accountHasMultiplePremisesOrMultipleSA;

  const saDetails = accountHasASinglePremiseOrASingleSA
    ? account.premiseInfo![0]!.saDetails![0]!
    : {
        encryptedSAId: '',
        saStatus: premiseInfoNotFound ? SaStatus.Closed : SaStatus.Active,
        // Q: Why is SaStatus.Active being assigned if premiseInfoNotFound is false?
        // A: If an account falls under Case 3 then the card design has to display the default card UI.
        // Cards with Active status display the default card UI.
        startDate: new Date(),
      };
  const isAccountActive = account.isActive;

  const {
    status,
    statusLabel,
    statusHeaderText,
    statusDate,
    statusColor,
    showViewBill,
    showViewBillHistory,
    isClosedAccount,
  } = useGetSAStatusInfo(saDetails, isAccountActive);

  const { makeMenuItems, ...handlers } = useAccountListItemMenuItems({
    onViewDetails,
    billMenuType,
    groupId,
    showViewBill,
    showViewBillHistory,
    isClosedAccount,
    isDisconnected,
    isEligibleForReconnect,
    accountsListCacheUpdate,
  });

  const menuItems = makeMenuItems(account);

  const amountDueValue = Number(account?.currentCharges?.amountDue);
  const isAccountClosedOrCancelled =
    status === SaStatus.Closed || status === SaStatus.Cancelled;
  const isClosedOrCancelledAndAmountDueIsNull =
    isNaN(amountDueValue) && isAccountClosedOrCancelled;

  const hideAmountDueSection =
    amountDueValue <= 0 &&
    (isAccountClosedOrCancelled ||
      status === SaStatus.Pendingstop ||
      status === SaStatus.Stopped);

  const isPastDue = !isAccountClosedOrCancelled && pastDue;
  const moreVertIconColor = colors.sparkBlue;

  return (
    <ListItem
      item={account}
      cardContentTestId="account-list-item-card-content"
      checked={checked}
      checkDisabled={checkDisabled}
      onCheckChange={onCheckboxChange}
      menuItems={hideMenu ? undefined : menuItems}
      footer={
        showAddressList && (
          <Box className={classes.list}>
            {account?.premiseInfo?.map(premise => {
              const saNickname = (nicknames || []).find(
                (n: any) => n.streetAddress === premise?.addressLine1,
              );

              return (
                <ServiceAddressListItem
                  key={premise?.addressLine1}
                  address={
                    {
                      ...premise,
                      encryptedAccountNumber: account.encryptedAccountNumber,
                      encryptedPersonId: account.encryptedPersonId,
                      accountSaStatus: status,
                      ...(saNickname ||
                        ({
                          encryptedId: '',
                          streetAddress: '',
                          cityStateZip: '',
                          nickname: '',
                        } as ServiceAgreementNickname)),
                    } as any
                  }
                  onNicknameAddress={handlers.onNicknameAddress}
                  moreVertIconColor={moreVertIconColor}
                  premiseInfo={premise}
                  accountHasMultiplePremisesOrMultipleSA={
                    accountHasMultiplePremisesOrMultipleSA
                  }
                  accountSaStatus={premise?.saDetails![0]?.saStatus}
                />
              );
            })}
          </Box>
        )
      }
      moreVertIconColor={moreVertIconColor}
      status={status}
      statusLabel={statusLabel}
      statusHeaderText={statusHeaderText}
      statusDate={statusDate}
      statusColor={statusColor}
      isPastDue={isPastDue}
      premiseInfoNotFound={premiseInfoNotFound}
      isDisconnected={isDisconnected}
      isEligibleForReconnect={isEligibleForReconnect}
      disconnectionDate={disconnectionDate}
    >
      <>
        <Box className={classes.accountSection}>
          <Box
            className={
              account.isDefault && account.accountNumber && isMobile
                ? [
                    classes.accountDisplay,
                    classes.mobilePrimaryAccountIndicator,
                  ].join(' ')
                : classes.accountDisplay
            }
          >
            {account.isDefault && account.accountNumber && (
              <Chip
                size="small"
                label={t('PRIMARY')}
                className={classes.primaryAccountIndicator}
              />
            )}
            <TextLink
              external
              accountTextLink
              className={classes.displayAccountIdentifier}
              onClick={e => {
                e.preventDefault();
                if (isClosedAccountsEnabled) {
                  !isClosedAccount && handlers.onViewDetails(account);
                  isClosedAccount &&
                    handlers.onViewBillingHistory &&
                    handlers.onViewBillingHistory(account);
                } else {
                  handlers.onViewDetails(account);
                }
              }}
              to={
                isClosedAccount && isClosedAccountsEnabled
                  ? ROUTES.CLOSED_ACCOUNTS_HISTORY
                  : ROUTES.ACCOUNT
              }
              plain
              state={{ closedAccountNumber: account.accountNumber }}
            >
              {displayAccountIdentifier(account)}
            </TextLink>
          </Box>
          {!accordionToggle && (
            <ServiceAddressDisplay
              account={account}
              showing={showAddressList}
              onToggleShow={() => setShowingAddressList(!showAddressList)}
              accountHasMultiplePremisesOrMultipleSA={
                accountHasMultiplePremisesOrMultipleSA
              }
            />
          )}
        </Box>
        {!hideAmountDueSection && (
          <Box
            className={classes.billingSection}
            data-testid="amount-due-section"
          >
            {!manageAccounts && account.isActive && (
              <Box className={classes.accountInfoGrid}>
                <Box className={`${classes.labels} ${classes.unsetTextAlign}`}>
                  <Typography
                    variant={'body1'}
                    className={
                      isPastDue
                        ? classes.colorTextPastDue
                        : classes.colorTextPrimary
                    }
                  >
                    {isPastDue
                      ? t('PAST_DUE')
                      : isEnrolledInAutoPay
                      ? t('AUTO_PAY_AMOUNT')
                      : t('AMOUNT_DUE')}
                  </Typography>
                </Box>
                <Box className={classes.values}>
                  <Typography
                    variant={'body1'}
                    className={
                      isPastDue
                        ? classes.colorTextPastDue
                        : classes.colorTextPrimary
                    }
                    data-testid="amount-due-section-value"
                  >
                    {isClosedOrCancelledAndAmountDueIsNull
                      ? 'NA'
                      : '$' +
                        toCurrencyDisplayFormat(
                          account?.currentCharges?.amountDue || 0,
                          true,
                          'CR',
                        )}
                  </Typography>
                </Box>
                <Box className={`${classes.labels} ${classes.unsetTextAlign}`}>
                  <Typography
                    variant={'body1'}
                    className={classes.colorTextPrimary}
                  >
                    {isEnrolledInAutoPay
                      ? t('WILL_BE_DEDUCTED')
                      : t('DUE_DATE')}
                  </Typography>
                </Box>
                <Box className={classes.values}>
                  <Typography
                    variant={'body1'}
                    className={`${classes.colorTextPrimary} ${classes.dueDateWidth}`}
                    data-testid="amount-due-section-due-date"
                  >
                    {isClosedOrCancelledAndAmountDueIsNull
                      ? 'NA'
                      : Boolean(account.currentCharges?.dueDate) &&
                        toDateStringFullMonthName(
                          String(account.currentCharges?.dueDate),
                        )}
                  </Typography>
                </Box>
              </Box>
            )}
            {children}
          </Box>
        )}
        {accordionToggle && (
          <ServiceAddressDisplay
            account={account}
            showing={showAddressList}
            onToggleShow={() => setShowingAddressList(!showAddressList)}
            accountHasMultiplePremisesOrMultipleSA={
              accountHasMultiplePremisesOrMultipleSA
            }
          />
        )}
      </>
    </ListItem>
  );
}
