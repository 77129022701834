import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import routes from '../../../routes';
import WindMill from '../../../static/WindMill.svg';
import theme from '../../../themes/theme';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
    },
    header: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: theme.spacing(3),
      '& > p': {
        margin: 0,
      },
    },
    subText: {
      fontSize: theme.spacing(2),
      fontWeight: 'bold',
    },
    body: {
      fontSize: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    cta: {
      fontSize: theme.spacing(2),
      fontWeight: 'bold',
      display: 'block',
      marginBottom: theme.spacing(2),
    },
    footer: {
      fontSize: theme.spacing(1.25),
    },
    icon: {
      display: 'block',
      flexShrink: 0,
      alignSelf: 'center',
    },
  }),
);

export function NoEnrollments({
  programCategory,
}: {
  programCategory: 'energy-shifting' | 'renewables';
}) {
  const classes = useStyles();
  const { t, inlineRichT, richT } = useTranslation();

  if (programCategory === 'energy-shifting') {
    return (
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Box className={classes.title}>
            {richT('TAKE_THE_GOOD_ENERGY_QUIZ_2')}
          </Box>
          <img src={require('../../../images/GoodEnergyQuizIcon.png')} />
        </Box>
        <Typography className={classes.body}>
          {t('GOOD_ENERGY_QUIZ_DEFAULT_CARD')}
        </Typography>
        <Link to={routes.GOOD_ENERGY_QUIZ_LINK} className={classes.cta}>
          {t('GOOD_ENERGY_QUIZ_LINK_TEXT')}
        </Link>
      </Box>
    );
  }

  if (programCategory === 'renewables') {
    return (
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Box>
            <Typography className={classes.title}>
              {inlineRichT('SUPPORT_A_GREEN_FUTURE')}
            </Typography>
            <Typography className={classes.subText}>
              {t('MATCH_ALL_OR_PORTION_OF_RENEWABLE_ENERGY')}
            </Typography>
          </Box>
          <WindMill className={classes.icon} />
        </Box>
        <Typography className={classes.body}>
          {t('GO_100_RENEWABLE_FOR_7_A_MONTH')}
        </Typography>
        <Link to={routes.RENEWABLE_POWER} className={classes.cta}>
          {t('ENROLL_IN_A_RENEWABLE_POWER_PROGRAM')}
        </Link>
        <Typography className={classes.footer}>
          {t('BASED_ON_2023_AVERAGE_RES_CUSTOMER')}
        </Typography>
      </Box>
    );
  }

  return <></>;
}
