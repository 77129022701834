import React, { FC } from 'react';
import {
  PaymentExtensionCurrentPlan,
  PaymentExtensionOption,
} from '../../../__generated__/pge-types';
import CurrentPlan from './current-plan';
import TPAStatus from '../tpa/TPAStatus';
import { TpaAccountDetails } from '../../need-more-time-to-pay/tpa/useTPA';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import colors from '../../../themes/main-colors';
import PGEButton from '../../buttons';
import { useTranslation } from '../../../hooks/useTranslation';
import PaperBox from '../../utility/paper-box';

interface Props {
  path?: string;
  currentPlan: PaymentExtensionCurrentPlan | null | undefined;
  hasAvailablePEOptions: boolean;
  onShowOptionClick: (isEnrolled: Boolean) => Promise<void>;
  isTPAEnrolled: boolean;
  availablePEOptions: PaymentExtensionOption[];
  tpaDetails: TpaAccountDetails | undefined;
}
const useStyles = makeStyles((theme: Theme) => ({
  spacing: {
    padding: theme.spacing(2.5, 2.5, 2.5, 2.5),
  },
  headingLabel: {
    color: colors.noirBlur,
  },
}));
const Landing: FC<Props> = (props: Props) => {
  const {
    currentPlan,
    hasAvailablePEOptions,
    isTPAEnrolled,
    tpaDetails,
  } = props;

  const { t, richT } = useTranslation();
  const classes = useStyles();

  const hasCurrentPlan = Boolean(currentPlan);
  const handleNextClick = () => {
    return props.onShowOptionClick(isTPAEnrolled);
  };

  return (
    <>
      <PaperBox>
        <Box>
          <Typography variant={'h2'} className={classes.headingLabel}>
            {t('PAYMENT_PLAN_OPTIONS')}
          </Typography>
          <Typography>{richT('PAYMENT_PLAN_OPTIONS_DESCRIPTION')}</Typography>
        </Box>
      </PaperBox>

      {isTPAEnrolled && (
        <>
          <TPAStatus tpaDetails={tpaDetails} />
        </>
      )}

      {hasCurrentPlan && (
        <CurrentPlan
          currentPlan={props.currentPlan!}
          hasAvailableOptions={hasAvailablePEOptions}
          onRequestMoretimeToPayClick={handleNextClick}
        />
      )}

      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <PGEButton size={'large'} onClick={handleNextClick}>
          <Typography variant={'button'} noWrap>
            {t('CHECK_PLAN_OPTIONS')}
          </Typography>
        </PGEButton>
      </Box>
    </>
  );
};

export default Landing;
