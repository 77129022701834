import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import { navigate, RouteComponentProps } from '@reach/router';
import React, { FC, useEffect, useState } from 'react';
import Page2ColumnLayout from '../../components/utility/page2-column-layout';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import Helmet from 'react-helmet';
import EssLeftPane from '../../components/ess/EssLeftPane';
import useElectionWindow, { ContactInfoModel } from '../../hooks/useElectionWindow';
import { Wizard } from '../../components/utility/wizard/Wizard';
import { WizardStep } from '../../components/utility/wizard/WizardStep';
import { ElectionWindowAccountSelection } from '../../components/election-window/ElectionWindowAccountSelection';
import {
  createElectionWindowContactInfoFormAdapter,
  createElectionWindowFormValidateFunc,
  ElectionWindowContactInfo,
} from '../../components/election-window/ElectionWindowContactInfo';
import { ElectionWindowPricingPlan } from '../../components/election-window/ElectionWindowPricingPlan';
import { ElectionWindowSummary } from '../../components/election-window/ElectionWindowSummary';
import { ElectionWindowConfirmation } from '../../components/election-window/ElectionWindowConfirmation';
import useElectionWindowStatus from '../../hooks/useElectionWindowStatus';
import { createStyles, Hidden, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wizardTitle: {
      fontSize: '2rem',
    },
  }),
);
import useElectionWindowPricingPlans from '../../hooks/useElectionWindowPricingPlans';
import useSelectedGroupId from '../../hooks/useSelectedGroupId';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import useOpenEnrollmentValidate from '../../hooks/useOpenEnrollmentValidate';
import useUtil from '../../components/need-more-time-to-pay/useUtil';
import Backdrop from '../../components/backdrop/Backdrop';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import { OpenEnrollmentAccountInput } from '../../__generated__/pge-types';
import { EnrollmentAccount } from './electionWindow.types';

const ElectionWindow: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { customer, loading: accountGroupsLoading } = useAccountCustomer();
  const { encryptedPersonId } = useSelectedAccountParams();
  const { selectedGroupId } = useSelectedGroupId();

  const { electionWindowState, setContactInfo, onSubmit } = useElectionWindow();
  const { refetch: getOpenEnrollmentAccounts } = useOpenEnrollmentValidate();

  const {
    enrollmentAccounts,
    setEnrollmentAccounts,
    annualPricingPlans,
    quaterlyPricingPlans,
    availablePlans,
    setAvailablePlans,
    changedAccounts,
    setChangedAccounts,
  } = useElectionWindowPricingPlans();
  
  const { data: electionWindowStatus } = useElectionWindowStatus();
  const { setErrorNotification } = useUtil();
  const { wrapWithLoader } = useWrapWithLoader();
  
  const [loading, setLoading] = useState<boolean>(false);
  const [eligibleForEnrollment, setEligibleForEnrollment] = useState<boolean>(true);

  const pathname = props.location?.pathname;
  const selectedGroup = selectedGroupId
    ? customer?.groups?.find((group: any) => group.groupId === selectedGroupId)
    : null;

  useEffect(() => {
    setAvailablePlans(
      electionWindowStatus?.getElectionWindowStatus?.electionWindow === 'FEB'
        ? quaterlyPricingPlans
        : annualPricingPlans,
    );
  }, [electionWindowStatus]);

  useEffect(() => {
    setEligibleForEnrollment(true);
  }, [selectedGroupId]);

  const handleSelectedAccountGroup = async () => {
    setLoading(true);
    if (encryptedPersonId && selectedGroup) {
      try {
        const result = await getOpenEnrollmentAccounts({
          payload: {
            encryptedPersonId: encryptedPersonId,
            selectedGroup: selectedGroupId,
          },
        });
        if (
          !result.loading &&
          !result.errors &&
          Object.keys(result.data).length > 0
        ) {
          if(result.data.getOpenEnrollmentAccounts.eligibleForEnrollment) {
            setEligibleForEnrollment(true);
            setEnrollmentAccounts(
              result.data.getOpenEnrollmentAccounts.openEnrollmentAccounts?.map(
                (acc, i) => ({
                  ...acc,
                  accountIndex: i,
                  planChangedTo: '',
                }),
              ) as EnrollmentAccount[],
            );
          } else {
            setEligibleForEnrollment(false);
            setLoading(false);
            return false;
          }
          setLoading(false);
        }
      } catch {
        setLoading(false);
        setErrorNotification(true);
        return true;
      }
    }
    return true;
  };

  const handleContactInfo = async (
    state: ContactInfoModel,
  ) => {
    setContactInfo(state);
    return true;
  };

  const handlePricingPlanSummary = async () => {
    const accounts = enrollmentAccounts.filter((r: EnrollmentAccount) => {
      if (r.planChangedTo) {
        return (
          r.currentPlan !== availablePlans.find((p: { id: any; }) => p.id === r.planChangedTo)?.name
        );
      } else {
        return false;
      }
    });
    setChangedAccounts([...accounts]);
    return true;
  };

  const handleSubmit = wrapWithLoader(async () => {
    if(changedAccounts.length > 0 && electionWindowStatus) {
      const enrolledAccounts = changedAccounts.map((acc: EnrollmentAccount) => ({
        acctId: acc.acctId,
        address: acc.address,
        planChangedTo: acc.planChangedTo,
        effectiveRateSched: acc.effectiveRateSched,
        spId: acc.spId,
        perId: acc.perId,
      })) as OpenEnrollmentAccountInput[];
      const result = await onSubmit(enrolledAccounts, electionWindowStatus.getElectionWindowStatus);
      if (result?.errors) {
        setErrorNotification(true);
        return false;
      }
    }
    return true;
  });
  const handleDone = async () => {
    await navigate(ROUTES.ESS_ELECTION_WINDOW);
    return true;
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(ROUTES.ESS_ELECTION_WINDOW);
  }, []);

  const steps = [
    {
      path: ROUTES.ESS_ELECTION_WINDOW,
      title: t('ELECTION_WINDOW'),
      wizardStep: (
        <WizardStep
          initial={electionWindowState}
          onNext={handleSelectedAccountGroup}
          component={ElectionWindowAccountSelection}
          backRoute={false}
          nextButton={
            electionWindowStatus?.getElectionWindowStatus?.electionWindow?.toUpperCase() ===
            'CLOSED'
              ? false
              : true
          }
          componentProps={{
            electionWindowStatus: electionWindowStatus?.getElectionWindowStatus,
            eligibleForEnrollment: eligibleForEnrollment,
          }}
        ></WizardStep>
      ),
    },
    {
      path: ROUTES.ESS_ELECTION_WINDOW_CONTACT_INFO,
      title: t('ELECTION_WINDOW'),
      wizardStep: (
        <WizardStep
          initial={electionWindowState.contactInfo}
          onNext={handleContactInfo}
          formAdapter={createElectionWindowContactInfoFormAdapter()}
          validate={createElectionWindowFormValidateFunc()}
          componentProps={{
            selectedGroup,
          }}
          backRoute={ROUTES.ESS_ELECTION_WINDOW}
          component={ElectionWindowContactInfo}
        ></WizardStep>
      ),
    },
    {
      path: ROUTES.ESS_ELECTION_WINDOW_SELECT_PRICING_PLAN,
      title: t('ELECTION_WINDOW'),
      wizardStep: (
        <WizardStep
          initial={electionWindowState}
          path={ROUTES.ESS_ELECTION_WINDOW_SELECT_PRICING_PLAN}
          onNext={handlePricingPlanSummary}
          component={ElectionWindowPricingPlan}
          componentProps={{
            enrollmentAccounts,
            setEnrollmentAccounts,
            availablePlans,
            selectedGroup,
            electionWindowStatus: electionWindowStatus?.getElectionWindowStatus,
          }}
          backRoute={ROUTES.ESS_ELECTION_WINDOW_CONTACT_INFO}
          nextRoute={ROUTES.ESS_ELECTION_WINDOW_SUMMARY}
        ></WizardStep>
      ),
    },
    {
      path: ROUTES.ESS_ELECTION_WINDOW_SUMMARY,
      title: t('ELECTION_WINDOW'),
      wizardStep: (
        <WizardStep
          initial={electionWindowState}
          path={ROUTES.ESS_ELECTION_WINDOW_SUMMARY}
          onNext={changedAccounts?.length > 0 ? handleSubmit : handleDone}
          nextText={changedAccounts?.length > 0 ? t('SUBMIT') : t('DONE')}
          componentProps={{
            changedAccounts,
            availablePlans,
          }}
          backRoute={ROUTES.ESS_ELECTION_WINDOW_SELECT_PRICING_PLAN}
          nextRoute={
            changedAccounts?.length > 0
              ? ROUTES.ESS_ELECTION_WINDOW_CONFIRMATION
              : ROUTES.ESS_ELECTION_WINDOW
          }
          component={ElectionWindowSummary}
        ></WizardStep>
      ),
    },
    {
      path: ROUTES.ESS_ELECTION_WINDOW_CONFIRMATION,
      title: t('ELECTION_WINDOW'),
      wizardStep: (
        <WizardStep
          initial={electionWindowState}
          component={ElectionWindowConfirmation}
          backRoute={false}
          onNext={handleDone}
          nextText={t('DONE')}
          componentProps={{
            changedAccounts,
            availablePlans,
            electionWindowStatus: electionWindowStatus?.getElectionWindowStatus,
          }}
        ></WizardStep>
      ),
    },
  ];
  return (
    <>
      {accountGroupsLoading || loading ? (
        <Backdrop forceOpen message={t('LOADING')} />
      ) : null}
      <Helmet>
        <title>{t('ELECTION_WINDOW')}</title>
      </Helmet>
      <Page2ColumnLayout leftColumn={<EssLeftPane />}>
        <Grid container direction="column" spacing={2}>
          <Hidden smDown>
            <Grid item>
              <Typography variant={'h1'} className={classes.wizardTitle}>
                {steps.find(s => s.path === pathname)?.title ?? ''}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item>
            <Wizard basepath="/">
              {steps.map(({ wizardStep, path, title }, index) => {
                return (
                  <wizardStep.type
                    {...{
                      ...wizardStep.props,
                      path: path,
                      key: path,
                      title,
                      backRoute:
                        wizardStep.props.backRoute ??
                        (index > 0 ? steps[index - 1].path : false),
                      nextRoute:
                        wizardStep.props.nextRoute ??
                        (index < steps.length - 1
                          ? steps[index + 1].path
                          : undefined),
                    }}
                  />
                );
              })}
            </Wizard>
          </Grid>
        </Grid>
      </Page2ColumnLayout>
    </>
  );
};

export default withWidth()(ElectionWindow);
