import React, { useContext, useEffect } from 'react';
import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Close from '@material-ui/icons/Close';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../../themes/main-colors';
import { useTranslation } from '../../../../hooks/useTranslation';
import NotificationOptions from '../outage-report-popup/NotificationOptions';
import { useIsMobile } from '../../../../util/style-utils';
import ChangeNotificationSettingsMobileView from './ChangeNotificationSettingsMobileView';
import { NotificationRequestAccountParams } from '../../OutageCenterPage';
import { Model } from '../../report-outage/serviceLocation.rules';
import useWrapWithLoader from '../../../../hooks/useWrapWithLoading';
import useGTMUtil from '../../../../lib/useGTMUtil';
import useUpdateOutageAlert from './useUpdateOutageAlert';
import { NotificationsContext } from '../../../../providers/NotificationsProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalWrapper: {
      display: 'flex',
      width: '922px',
      maxHeight: '77.21vh',
      flexDirection: 'column',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: theme.spacing(3.5, 3, 6.25, 3),
      backgroundColor: colors.modalBackgroundColor,
      overflow: 'auto',
      borderRadius: '5px',
      [theme.breakpoints.down('sm')]: {
        top: '50%',
        width: '90%',
        maxHeight: '90%',
        padding: theme.spacing(3),
      },
    },
    closeButton: {
      position: 'absolute',
      top: 6,
      right: 24,
    },
    title: {
      lineHeight: '2.25rem',
      marginBottom: '30px',
    },
    alternateLookupOptionBox: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
      },
    },
    kickerText: {
      paddingBottom: theme.spacing(1.875),
      paddingTop: theme.spacing(1.25),
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
  }),
);

type Props = {
  open: boolean;
  loggedInUserNotificationRequestAccountParams: NotificationRequestAccountParams;
  notificationFormValues: Model;
  setNotificationFormValues: React.Dispatch<React.SetStateAction<Model>>;
  setNotifFormSuccessfullySubmitted: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsChangeNotificationSettingsPopup: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

const UpdateOutageAlerts: React.FC<Props> = ({
  open,
  loggedInUserNotificationRequestAccountParams,
  notificationFormValues,
  setNotificationFormValues,
  setNotifFormSuccessfullySubmitted,
  setIsChangeNotificationSettingsPopup,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const { postGTMModalVisibilityData } = useGTMUtil();
  const { wrapWithLoader } = useWrapWithLoader();
  const { form, handleOnSubmit } = useUpdateOutageAlert(
    loggedInUserNotificationRequestAccountParams,
    notificationFormValues,
    setNotificationFormValues,
  );
  const notificationContext = useContext(NotificationsContext);
  //Push modal visibility status to the GTM
  useEffect(() => {
    const GTMPageData = {
      page: 'Request Notifications',
      siteSection: 'Request Notifications Modal',
    };

    postGTMModalVisibilityData({ ...GTMPageData, isVisible: true });

    return () =>
      postGTMModalVisibilityData({ ...GTMPageData, isVisible: false });
  }, []);

  const handleUpdateSettings = wrapWithLoader(async () => {
    const successfullySubmitted = await handleOnSubmit();
    setNotifFormSuccessfullySubmitted(Boolean(successfullySubmitted));
    if (successfullySubmitted) {
      setIsChangeNotificationSettingsPopup(false);
      notificationContext.setState({
        isOpen: true,
        severity: 'success',
        variant: 'filled',
        message: t('UPDATE_SUCCESSFUL'),
      });
    }
  });
  const onClose = () => {
    setIsChangeNotificationSettingsPopup(false);
  };

  return isMobile ? (
    <ChangeNotificationSettingsMobileView
      setNotifFormSuccessfullySubmitted={setNotifFormSuccessfullySubmitted}
      loggedInUserNotificationRequestAccountParams={
        loggedInUserNotificationRequestAccountParams
      }
      setNotificationFormValues={setNotificationFormValues}
      notificationFormValues={notificationFormValues}
      open={open}
      onClose={onClose}
      form={form}
      handleOnSubmit={handleUpdateSettings}
      isSignedInFlow={true}
    />
  ) : (
    <Modal
      aria-labelledby="report-outage-modal"
      aria-describedby="report-outage-modal"
      open={open}
      onClose={onClose}
      disableAutoFocus
      disableEnforceFocus
      disableRestoreFocus
    >
      <div className={classes.modalWrapper}>
        <div className={classes.closeButton}>
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={onClose}
            data-testid="close-icon"
          >
            <Close />
          </IconButton>
        </div>
        <Grid>
          <Typography className={classes.title} variant={'h1'}>
            {t('NOTIFICATION_OPTIONS')}
          </Typography>{' '}
          <NotificationOptions
            loggedInUserNotificationRequestAccountParams={
              loggedInUserNotificationRequestAccountParams
            }
            setNotifFormSuccessfullySubmitted={
              setNotifFormSuccessfullySubmitted
            }
            setNotificationFormValues={setNotificationFormValues}
            notificationFormValues={notificationFormValues}
            isChangeNotificationSettings={open}
            onClose={onClose}
            form={form}
            handleOnSubmit={handleUpdateSettings}
            isSignedInFlow={true}
          />
        </Grid>
      </div>
    </Modal>
  );
};

export default UpdateOutageAlerts;
