import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Router, useLocation } from '@reach/router';
import React from 'react';
import { Helmet } from 'react-helmet';

import ProfileLayout from '../../../components/profile-layout';
import ManageAccountGroups from '../../../components/manage-account-groups';
import AddGroupAccounts from '../../../components/manage-account-groups/add-group-accounts';
import AccountGroupsLandingSceen from '../../../components/manage-account-groups/account-groups-landing-screen';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import ManageGroupsAccount from '../../../components/manage-accounts/manage-groups-account';
import ManageAccountIneligible from '../../../components/manage-accounts/manage-account-ineligible';

type Props = {
  path?: string;
};

export default function ManageAccountGroupsPage(_: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <ProfileLayout dense={false}>
      <Helmet>
        <title>{t('MANAGE_ACCOUNTS_GROUPS')}</title>
      </Helmet>
      <div>
        <Grid
          container
          style={{ display: 'flex', flexDirection: 'row', width: '100%' }}
        >
          {pathname !== ROUTES.MANAGE_ACCOUNTS_INELIGIBLE && (
            <Grid item style={{ marginBottom: '1em' }} xs={12} md={8} lg={12}>
              <Typography
                style={{ color: colors.noirBlur }}
                component={'span'}
                variant={'h1'}
              >
                {t('MANAGE_ACCOUNTS_GROUPS')}
              </Typography>
            </Grid>
          )}
          <Router basepath="/">
            <AccountGroupsLandingSceen
              path={ROUTES.MANAGE_ACCOUNTS_LANDING_SCREEN}
            />
            <ManageAccountGroups path={ROUTES.MANAGE_ACCOUNT_GROUPS} />
            <ManageGroupsAccount path={ROUTES.MANAGE_GROUPS_ACCOUNT} />
            <AddGroupAccounts path={ROUTES.ADD_GROUP_ACCOUNTS} />
            <ManageAccountIneligible path={ROUTES.MANAGE_ACCOUNTS_INELIGIBLE} />
          </Router>
        </Grid>
      </div>
    </ProfileLayout>
  );
}
