import Grid from '@material-ui/core/Grid';
import React, { useEffect } from 'react';
import { getAccountDetails } from './queries';
import Backdrop from '../backdrop';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ProceedOrCancel from '../proceed-or-cancel';
import useAuthQuery from '../../hooks/useAuthQuery';
import {
  AccountDetail,
  AccountDetailParams,
  AccountParams,
  AccountDetailList,
} from '../../__generated__/pge-types';
import { useLocation, navigate, Link, WindowLocation } from '@reach/router';
import ROUTES from '../../routes';
import useMulticheckState from '../../hooks/useMulticheckState';
import AccountListItem from '../account-summary/multi-accounts/AccountListItem';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import values from 'lodash/values';
import sum from 'lodash/sum';
import validate from './PaymentAmountsStep.rules';
import { useTranslation } from '../../hooks/useTranslation';
import useFormState from '../../hooks/useFormState';
import { AccountAmounts, PaymentAmountsState } from './types';
import { toCurrencyString, toDateStringFullMonthName } from '../../util/format';
import pickBy from 'lodash/pickBy';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { parseISO } from 'date-fns';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import { useIsMobile } from '../../util/style-utils';
import CurrencyTextField from '../currency-text-field';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    totalArea: {
      background: 'white',
      padding: `${theme.spacing(1)}px ${theme.spacing(2.5)}px`,
      display: 'flex',
      '& > *:first-child': {
        marginRight: theme.spacing(1),
      },
    },
    sum: {
      fontSize: '1.8em',
    },
    total: {
      fontSize: '1.3em',
    },
  }),
);

type LocationState =
  | {
      accountParams: Array<AccountParams>;
    }
  | undefined;

function makeDefaults<T>(
  accountParams: Array<AccountParams>,
  defaultValue: T,
): Record<string, T> {
  return mapValues(keyBy(accountParams, 'accountNumber'), _ => defaultValue);
}

function getDefaultAccountValues(
  accounts: Array<AccountDetail>,
): Record<string, string> {
  return mapValues(keyBy(accounts, 'accountNumber'), account => {
    const amountDue = account.currentCharges?.amountDue;

    return !isNil(amountDue) && amountDue > 0
      ? toCurrencyString(amountDue, false)
      : '0.00';
  });
}

function getAccountAmounts(
  accounts: Array<AccountDetail>,
  amounts: Record<string, string>,
  isChecked: (acctNumber: string) => boolean,
) {
  return accounts
    .filter(acct => isChecked(acct.accountNumber))
    .map(account => ({
      account,
      amount: Number(amounts[account.accountNumber].replace(/,/g, '')),
    }));
}

type Props = {
  path?: string;
  onSubmit: (
    accountAmounts: Array<AccountAmounts>,
    timestamp: string,
    state: PaymentAmountsState,
  ) => void;
  initialState?: PaymentAmountsState;
};

function getTotal(
  formValues: Record<string, string>,
  isChecked: (acctNumber: string) => boolean,
): number {
  return sum(
    values(pickBy(formValues, (_, key) => isChecked(key))).map(val =>
      Number(val.replace(/,/g, '')),
    ),
  );
}

function toAccountParams(
  acctParams: Array<AccountParams>,
): Array<AccountParams> {
  // Fix issues with extra params being sent
  return acctParams.map(params =>
    pick(params, 'accountNumber', 'encryptedPersonId'),
  );
}

function getInitialState(location: WindowLocation): PaymentAmountsState {
  const state: LocationState = location.state as any;
  const accountParams = toAccountParams(state?.accountParams || []);

  const checkedState = makeDefaults(accountParams, true);
  const formValues = makeDefaults(accountParams, '');

  return { accountParams, checkedState, formValues };
}

export default function PaymentAmountsStep({
  onSubmit,
  initialState: initialStateProp,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const location = useLocation();
  const { accountParams, ...initialState } =
    initialStateProp || getInitialState(location);

  const { loading, data } = useAuthQuery<
    {
      getAccountDetails: Array<AccountDetail>;
      getAccountDetailList: AccountDetailList;
    },
    {
      params: AccountDetailParams;
    }
  >(getAccountDetails, {
    variables: {
      params: { accountNumberList: accountParams },
    },
    skip: accountParams.length === 0,
  });

  const accounts = data?.getAccountDetails || [];
  const detailList = data?.getAccountDetailList;

  const multicheckState = useMulticheckState(
    accountParams.map(acc => acc.accountNumber),
    { initialChecked: initialState.checkedState },
  );

  const form = useFormState(initialState.formValues, {
    validate,
    validationContext: {
      t,
      isChecked: multicheckState.isChecked,
    },
  });

  useEffect(() => {
    if (accounts.length > 0 && !initialStateProp) {
      // When accounts load, set the form values to default to
      // their ammount dues
      form.reset(getDefaultAccountValues(accounts));
    }
  }, [accounts]);

  const isMobile = useIsMobile();

  useEffect(() => {
    if (accountParams.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.MULTI_PAY);
    }
  }, []);

  const timestamp = detailList?.timestamp
    ? toDateStringFullMonthName(parseISO(detailList?.timestamp))
    : '';
  const total = getTotal(form.values, multicheckState.isChecked);

  return (
    <>
      {loading && <Backdrop forceOpen />}

      {!loading && (
        <Box marginTop={2}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              {accounts.map((account: AccountDetail) => {
                const checked = multicheckState.isChecked(
                  account.accountNumber,
                );
                return (
                  <AccountListItem
                    key={account.accountNumber}
                    hideMenu
                    account={account}
                    onCheckboxChange={multicheckState.handleCheckToggle(
                      account.accountNumber,
                    )}
                    checkDisabled={
                      !multicheckState.isAnOption(account.accountNumber)
                    }
                    checked={checked}
                    billMenuType={'VIEW'}
                  >
                    <Box alignSelf="flex-start">
                      <CurrencyTextField
                        label={t('PAYMENT_AMOUNT')}
                        {...form.props(account.accountNumber)}
                        error={
                          checked
                            ? Boolean(form.errors[account.accountNumber])
                            : undefined
                        }
                        helperText={
                          checked
                            ? form.errors[account.accountNumber]
                            : undefined
                        }
                      />
                    </Box>
                  </AccountListItem>
                );
              })}
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                item
                justify="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md="auto">
                  <Typography variant="body2" align="center">
                    {t('AMOUNT_DUE_INCLUDES')} {timestamp}.
                  </Typography>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Box className={classes.totalArea}>
                    <Box>
                      <Typography variant="body2" className={classes.total}>
                        {t('TOTAL')}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h3" className={classes.sum}>
                        $
                        {toCurrencyString(
                          getTotal(form.values, multicheckState.isChecked),
                          true,
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                justify="space-between"
                direction={isMobile ? 'column-reverse' : 'row'}
                spacing={1}
              >
                <Grid item xs={12} md="auto">
                  <Box textAlign="center">
                    <Link to={ROUTES.ACCOUNT}>{t('CANCEL')}</Link>
                  </Box>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Box paddingRight={1}>
                    <ProceedOrCancel
                      proceedDisabled={
                        multicheckState.getSelected().length === 0 ||
                        total === 0
                      }
                      proceedHandler={form.submit(
                        amounts =>
                          onSubmit(
                            getAccountAmounts(
                              accounts,
                              amounts,
                              multicheckState.isChecked,
                            ),
                            timestamp,
                            {
                              formValues: form.values,
                              checkedState: multicheckState.checked,
                              accountParams: accountParams,
                            },
                          ),
                        () => window.scrollTo({ top: 100, behavior: 'smooth' }),
                      )}
                      cancelRoute={ROUTES.MULTI_PAY}
                      cancelLabel={t('BACK')}
                      cancelVariant="Button"
                      proceedLabel={t('NEXT')}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
