import React from 'react';
import { Router } from '@reach/router';
import ROUTES from '../../routes';

import RebateOnly from './ev-charger/rebate-only/';
import PrivateRoute from '../../components/routing/PrivateRoute';
import NotFoundPage from '../../pages/404';
import GetStarted from './get-started/GetStarted';

export const PGEPlusRouter = () => {
  return (
    <Router basepath="/">
      <GetStarted path={ROUTES.PGE_PLUS_GET_STARTED} />
      <RebateOnly path={`${ROUTES.PGE_PLUS_REBATE_ONLY_HOME}/*`} />
      <PrivateRoute path="/*" component={NotFoundPage} />
    </Router>
  );
};
