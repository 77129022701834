import { Grid, Paper, makeStyles, createStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import React, { FunctionComponent as FC, useEffect } from 'react';
import Helmet from 'react-helmet';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';
import { addOrdinalSuffixToNumber } from '../../util/format';
import Alerts from '../alerts';
import ProceedOrCancel from '../proceed-or-cancel';

const useStyles = makeStyles(() =>
  createStyles({
    pddSuccessPage: {
      color: colors.noirBlur,
      '& p': {
        margin: 0,
      },
    },
    alertTitle: {
      fontWeight: 300,
      margin: '-10px 0 0',
      fontFamily: 'Forma-DJR-Display',
    },
    doneBtnHolder: {
      padding: '15px',
    },
  }),
);

export type PDDLocationState = {
  selectedPDDay: number;
};

type Props = {
  path?: string;
};

const PddSuccess: FC<Props> = () => {
  const { t, richT } = useTranslation();
  const title = t('PDD_PAGE_TITLE');
  const classes = useStyles();
  const location = useLocation();
  const locationState: PDDLocationState = location?.state as PDDLocationState;
  const selectedPDDay = locationState?.selectedPDDay;

  useEffect(() => {
    // if someone types the URL without a previously successful PDD change, we redirect back.
    if (!selectedPDDay) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.PREFERRED_DUE_DAY);
    }
  }, []);

  const successMsg = richT('PDD_SUCCESS', {
    selectedPDDay: addOrdinalSuffixToNumber(selectedPDDay),
  });

  // Get Customer's email address:
  const { customer } = useAccountCustomer();
  const emailAddress = customer?.email;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Paper className={classes.pddSuccessPage}>
            <Alerts
              disableColorOverride={true}
              severity={'success'}
              variant={'outlined'}
              title={
                <h2 className={classes.alertTitle}>
                  {t('TRANSACTION_SUCCESSFUL')}
                </h2>
              }
              message={
                <>
                  <Typography variant="body1">{successMsg}</Typography>
                  <br />
                  <Typography variant="body1">
                    {richT('NOTE_ASYNC_PROCESS_PROMISE_EMAIL', {
                      CUSTOMER_EMAIL: emailAddress,
                    })}
                  </Typography>
                  <br />
                  <Typography variant="body1">
                    {t('PDD_NOTE_TAKE_EFFECT')}
                  </Typography>
                  <br />
                  <span>
                    <Link to={ROUTES.NEED_MORE_TIME_TO_PAY}>
                      {t('PDD_NMTTP_LINK')}
                    </Link>{' '}
                    {t('OUR_ONLINE_NMTTP')}
                  </span>
                  <div className={classes.doneBtnHolder}>
                    <ProceedOrCancel
                      proceedLabel={t('DONE')}
                      proceedHandler={async () => {
                        await navigate(ROUTES.ACCOUNT);
                      }}
                      cancelRoute={false}
                    />
                  </div>
                </>
              }
              isOpen
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default PddSuccess;
