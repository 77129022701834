import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
import CloseIcon from '@material-ui/icons/Close';

import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: colors.roseRed,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
  },
  errorIcon: {
    width: 48,
    paddingRight: theme.spacing(2),
    '& path:first-of-type': {
      fill: '#520b02',
    },
    '& path:last-of-type': {
      fill: colors.white,
    },
  },
  warningText: {
    color: colors.white,
    flexGrow: 8,
  },
  closeIcon: {
    width: 48,
    paddingLeft: theme.spacing(4),
    fill: colors.white,
    height: 48,
    cursor: 'pointer',
  },
}));

type DisconnectNoticeProps = {
  dismissDisconnectNotice?: () => void;
};

const DisconnectNotice = ({
  dismissDisconnectNotice,
}: DisconnectNoticeProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <ErrorTwoToneIcon className={classes.errorIcon} />
      <p className={classes.warningText}>{t('PENDING_DISCONNECT_NOTICE')}</p>
      {Boolean(dismissDisconnectNotice) && (
        <CloseIcon
          data-testid="disconnect-notice-dismiss-button"
          className={classes.closeIcon}
          onClick={dismissDisconnectNotice}
        />
      )}
    </Box>
  );
};

export default DisconnectNotice;
