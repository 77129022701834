import gql from 'not-graphql-tag';

export const getAccountDetailsQuery = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      accountType
      encryptedAccountNumber
      encryptedPersonId
      preferredDueDate {
        dueDate {
          preferredDueDate
          status
          effectiveDate
        }
        eligibility {
          reasonCode
          isEligible
        }
      }
      currentCharges {
        amountDue
        dueDate
      }
      autoPay {
        isEnrolled
        isSameDayEnrolled
        startDate
        enrollDetails {
          tokenId
          type
          referenceNumber
        }
        availablePaymentMethods
      }
      paymentEligibility {
        isCashOnly
        isNonBillableNoBalance
      }
      pendingDisconnect {
        isPendingDisconnect
      }
    }
  }
`;

export const updateAutoPayment = gql`
  mutation updateAutoPayment($payload: AutoPaymentRequest) {
    updateAutoPayment(payload: $payload) {
      confirmationNumber
    }
  }
`;

export const enrollAutoPay = gql`
  mutation enrollAutoPay($payload: AutoPayEnrollInput!) {
    enrollAutoPay(payload: $payload) {
      isSuccess
      confirmationId
      errorReason
      autoPay {
        isEnrolled
      }
    }
  }
`;

export const cancelAutoPay = gql`
  mutation cancelAutoPay($payload: AutoPayCancelInput!) {
    cancelAutoPay(payload: $payload) {
      isSuccess
    }
  }
`;
