import { useApolloClient } from '@apollo/react-hooks';
import useFormState from '../../hooks/useFormState';
import { useTranslation } from '../../hooks/useTranslation';
import * as queries from '../../queries/autoPay.query';
import * as pddQueries from '../../queries/preferredDueDay.query';
import {
  AccountDetail,
  AutoPayCancelInput,
  CancelAutoPay,
  EnrollAutoPay,
  PreferredDueDateAddRequest,
  PreferredDueDateAddResponse,
} from '../../__generated__/pge-types';
import { AutoPayConfigData, PaymentMethodSelectorData } from './auto-pay.types';
import { defaultBankingData } from './auto-pay.utils';
import bankSectionRules from './payment-method-selector/BankingInfoSection.rules';

export function useAutoPayService() {
  const apolloClient = useApolloClient();

  function enrollAutoPay(account: AccountDetail, config: AutoPayConfigData) {
    const payload = {
      accountNumber: account.accountNumber,
      encryptedPersonId: account.encryptedPersonId,
      tokenId: config?.paymentSelector?.selectedProfileInfo?.profile?.token,
    };

    return apolloClient.mutate<{
      enrollAutoPay: EnrollAutoPay;
    }>({
      mutation: queries.enrollAutoPay,
      variables: { payload },
      errorPolicy: 'all',
    });
  }

  function updateAutoPay(account: AccountDetail, config: AutoPayConfigData) {
    return { data: undefined };
  }

  function updatePreferredDueDate(account: AccountDetail, day: number) {
    return apolloClient.mutate<
      {
        addPreferredDueDate: PreferredDueDateAddResponse;
      },
      { payload: PreferredDueDateAddRequest }
    >({
      mutation: pddQueries.updatePreferredDueDate,
      variables: {
        payload: {
          encryptedAccountNumber: account.encryptedAccountNumber,
          preferredDueDate: day,
        },
      },
      errorPolicy: 'all',
    });
  }

  function cancelAutoPay(accountNumber: string, encryptedPersonId: string) {
    return apolloClient.mutate<
      {
        cancelAutoPay: CancelAutoPay;
      },
      { payload: AutoPayCancelInput }
    >({
      mutation: queries.cancelAutoPay,
      variables: {
        payload: {
          accountNumber,
          encryptedPersonId,
        },
      },
      errorPolicy: 'all',
    });
  }

  return {
    enrollAutoPay,
    updateAutoPay,
    updatePreferredDueDate,
    cancelAutoPay,
  };
}

export function useBankSectionForm(data: PaymentMethodSelectorData) {
  const { t } = useTranslation();
  return useFormState(defaultBankingData(data), {
    validate: bankSectionRules,
    validationContext: { t, apolloClient: useApolloClient() },
    connectedFields: {
      bankAccountNumber: ['confirmBankAccountNumber', 'routingNumber'],
    },
  });
}
