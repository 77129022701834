import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import PasswordTextField from '../password-text-field';
import PasswordIndicatorList from '../password-indicator-list';
import { ChangePasswordProps } from './ChangePassword.types';
import PGEButton from '../buttons';
import { useTranslation } from '../../hooks/useTranslation';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import { isValidPassword } from '../../util/form-validation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionArea: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    submitButton: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  }),
);

const ChangePassword: FunctionComponent<ChangePasswordProps> = props => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { wrapWithLoader } = useWrapWithLoader();

  const onSubmit = wrapWithLoader(
    props.form.submit(async data => {
      await props.doSubmit(data, props.encryptedPersonId);
      await props.onSuccess();
    }),
  );

  return (
    <form noValidate onSubmit={onSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant={'h2'}>
                    {t('ENTER_NEW_PASSWORD')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <PasswordTextField
                    name="password"
                    style={{ width: '100%' }}
                    inputProps={{ minLength: 8, maxLength: 16 }}
                    {...props.form.props('password')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <PasswordIndicatorList
                    password={props.form.values.password}
                  />
                </Grid>
                <Grid item xs={12} className={classes.actionArea}>
                  <PGEButton
                    className={classes.submitButton}
                    type={'submit'}
                    variant="contained"
                    color="primary"
                    disabled={!isValidPassword(props.form.values.password)}
                    data-testid="change-password-button"
                  >
                    {t('CHANGE_PASSWORD_BUTTON')}
                  </PGEButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
};

export default ChangePassword;
