import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Grid, Typography } from '@material-ui/core';

import { useTranslation } from '../../../hooks/useTranslation';
import ChangePassword from '../../../components/change-password';
import useChangePasswordForm from '../../../hooks/useChangePasswordForm';
import ProfileLayout from '../../../components/profile-layout';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import Helmet from 'react-helmet';

const ChangePasswordPage: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { t } = useTranslation();

  const { encryptedAccountNumber } = useSelectedAccountParams({
    ignoreNoAccounts: true,
  });
  const { customer } = useAccountCustomer();

  const { form, doSubmit, onSuccess } = useChangePasswordForm();

  const childProps = {
    form,
    doSubmit,
    onSuccess,

    encryptedPersonId: customer?.encryptedPersonId || null,
  };

  const title = t('CHANGE_PASSWORD_BUTTON');

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ProfileLayout dense={false}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant={'h1'}>{title}</Typography>
          </Grid>
          <Grid item>
            <ChangePassword {...childProps}></ChangePassword>
          </Grid>
        </Grid>
      </ProfileLayout>
    </>
  );
};

export default ChangePasswordPage;
