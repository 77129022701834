import { convertValidationRules } from '../../hooks/useFormState';

type AdditionalContext = {
  content: Map<string, string>;
  params?: any;
};

export type IQBDModel = {
  annualIncome?: string;
  confirmAnnualIncome?: Boolean;
  ethinicity?: string;
  otherEthnicity?: string;
  fixedIncome?: string;
  houseHoldSize?: number;
  paperless?: Boolean;
  prefLanguage?: string;
  signature?: string;
};

export const iqbdFormValidateFunction = () =>
  convertValidationRules<IQBDModel, AdditionalContext>(context => {
    const { values, content } = context;

    return {
      annualIncome: () =>
        !values.annualIncome?.trim() ? content.get('FIELD_IS_REQUIRED') : null,
      otherEthnicity: () =>
        values.ethinicity === content.get('OTHER')
          ? values.otherEthnicity?.trim().length
            ? null
            : content.get('FIELD_IS_REQUIRED')
          : null,
      fixedIncome: () =>
        !values.fixedIncome?.trim()
          ? content.get('UNSELECTED_FIXED_INCOME_ERROR')
          : null,
      houseHoldSize: () =>
        !values.houseHoldSize
          ? content.get('UNSELECTED_HOUSEHOLD_SIZE_ERROR')
          : null,
      paperless: () =>
        values.paperless === null || values.paperless === undefined
          ? content.get('FIELD_IS_REQUIRED')
          : null,
      prefLanguage: () =>
        !values.prefLanguage?.trim() ? content.get('FIELD_IS_REQUIRED') : null,
      confirmAnnualIncome: () =>
        !values.confirmAnnualIncome ? content.get('FIELD_IS_REQUIRED') : null,
      signature: () =>
        !values.signature?.trim() ? content.get('BLANK_NAME_ERROR') : null,
    };
  });
