import { Dispatch, SetStateAction, useState } from 'react';

import { selectAllAccounts, toggleCheckBox } from '../actions/account';
import { PaginationProps } from '../components/pagination/Pagination';
import {
  AccountType,
  UsePaymentFormResponse,
} from '../components/payment-form/PaymentForm.types';
import { AccountState } from '../state/account';
import useGlobalState, { Entity } from '../state/state';
import usePaymentForm from './usePaymentForm';
import { useTranslation } from './useTranslation';
import routes from '../routes';
import { PageSize } from '../components/pagination/types';

export interface PaymentOption {
  title: string;
  link: string;
  linkText: string;
  active: boolean;
}

export interface AccountSummaryHook extends UsePaymentFormResponse {
  usageComparedToPreviousMonth: string;
  usageComparedToPreviousMonthMeter: number;
  multipleAccountHeaders: {
    labels: { primary?: string; secondary?: string }[];
  }[];
  multipleManageAccountHeaders: {
    labels: { primary?: string; secondary?: string }[];
  }[];
  showAccountDetail: boolean;
  toggleShowAccountDetail: Dispatch<SetStateAction<any>>;
  menuItems: any[];
  sortByItems: any[];
  onSearchChange: (event: {
    persist: () => void;
    target: { value: string };
  }) => void;
  onSortByChange: (event: { target: { value: string } }) => void;
  paginationProps: PaginationProps;
  isSelectAll: boolean;
  toggleSelectAll: Dispatch<SetStateAction<any>>;
  onCheckboxChange: (
    event: { target: { name: string } },
    value: boolean,
  ) => any;
  isPayButtonDisabled: boolean;
  handleSelectAll: () => void;
  setState: Dispatch<SetStateAction<any>>;
  paymentOptions: PaymentOption[];
}

export default (): AccountSummaryHook => {
  const paymentProps = usePaymentForm();
  const { t } = useTranslation();
  const [showAccountDetail, toggleShowAccountDetail] = useState(false);

  const [state, setAccountState] = useGlobalState('account');
  const account = state;

  const paymentOptions = [
    // TODO: remove now that this is in global state
    {
      title: 'Auto Pay',
      link: routes.AUTO_PAY,
      linkText: 'Enroll',
      active: false,
    },
    {
      title: 'Paperless Bill',
      link: routes.PAPERLESS_BILLING,
      linkText: 'Change',
      active: true,
    },
    {
      title: `Due date: ${state.dueDate}`,
      link: routes.PREFERRED_DUE_DAY,
      linkText: 'Change',
      active: true,
    },
    {
      title: 'Equal Pay',
      link: routes.EQUAL_PAY,
      linkText: 'Change',
      active: true,
    },
  ];

  const usageComparedPercentage: string = (
    (Number(state?.currentBillingPeriod?.totalKwh) /
      Number(state?.previousBillingPeriod?.totalKwh)) *
    100
  ).toString();
  const usageComparedToPreviousMonth: string = `${parseInt(
    usageComparedPercentage,
  ).toString()}%`;
  const usageComparedToPreviousMonthMeter: number = Number(
    usageComparedPercentage,
  );

  const multipleAccountHeaders = state?.accounts?.map((acct: AccountType) => ({
    labels: [
      {
        primary: acct.nickname
          ? `${account.accountNumber} (${acct.nickname})`
          : acct.accountNumber,
      },
      { primary: acct.serviceAddress },
      { secondary: acct.fullName },
    ],
  }));

  const multipleManageAccountHeaders = state?.accounts?.map(
    (acct: AccountType) => ({
      labels: [
        {
          primary: acct.nickname
            ? `${acct.accountNumber} (${acct.nickname})`
            : acct.accountNumber,
        },
        { secondary: `${state.accounts.length.toString()} ${t('Accounts')}` },
      ],
    }),
  );

  const menuItems = [
    { text: 'View Details' },
    { text: 'Pay Bill' },
    { text: 'Download Data' },
    { text: 'Nickname' },
  ];

  const sortByItems = [
    { value: 'serviceAddress', label: 'Address' },
    { value: 'nickname', label: 'NickName' },
  ];

  const onPageChange = (page: number, pageSize: PageSize) => {
    setAccountState((prev: AccountState) => ({
      ...prev,
      paginationSettings: {
        ...prev.paginationSettings,
        page,
        pageSize,
      },
    }));
  };

  const paginationProps = {
    ...state?.paginationSettings,
    onPageChange,
  };

  const [isSelectAll, toggleSelectAll] = useState(false);
  const data =
    state?.paginationSettings.pageSize === 'All'
      ? state?.accounts
      : state?.accounts?.slice(
          (state.paginationSettings.page - 1) *
            state?.paginationSettings.pageSize,
          state.paginationSettings.page * state?.paginationSettings.pageSize,
        );

  const handleSelectAll = () => {
    toggleSelectAll(!isSelectAll);
    selectAllAccounts(setAccountState, !isSelectAll);
  };

  const onSearchChange = (event: {
    persist: () => void;
    target: { value: string };
  }): void => {
    event.persist();
    const searchString = event?.target?.value?.toString();

    if (!searchString) {
      return setAccountState({
        ...state,
        accounts: data,
      });
    }

    setTimeout(() => {
      const rows = data.filter(
        (row: { [s: string]: any } | ArrayLike<unknown>) => {
          return Object.values(row).find(r => {
            return r?.toLowerCase?.()?.includes(searchString.toLowerCase());
          });
        },
      );
      return setAccountState({
        ...state,
        accounts: rows,
      });
    }, 200);
  };

  const onSortByChange = (event: { target: { value: string } }): void => {
    const sortKey = event.target.value;
    const rows = data.sort((a: any, b: any) =>
      a[sortKey] > b[sortKey] ? 1 : -1,
    );
    return setAccountState({
      ...state,
      accounts: rows,
    });
  };

  const onCheckboxChange = (
    event: { target: { name: string } },
    value: boolean,
  ) => {
    const idx = Number(event.target.name.split('account-checkbox-').join(''));
    toggleCheckBox(setAccountState, { idx, value });

    if (!value) {
      toggleSelectAll(false);
    }
  };

  const isPayButtonDisabled = !state.accounts.some(row => !!row.checked);

  return {
    ...paymentProps,
    state,
    usageComparedToPreviousMonth,
    usageComparedToPreviousMonthMeter,
    multipleAccountHeaders,
    multipleManageAccountHeaders,
    showAccountDetail,
    toggleShowAccountDetail,
    menuItems,
    sortByItems,
    onSearchChange,
    onSortByChange,
    paginationProps,
    onCheckboxChange,
    isPayButtonDisabled,
    toggleSelectAll,
    isSelectAll,
    handleSelectAll,
    setState: setAccountState, // TODO: remove and get from useGlobalState if used downstream
    paymentOptions,
  };
};
