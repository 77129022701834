import { Typography } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { PaymentProfile } from '../../../__generated__/pge-types';
import { ProfileChangeHandler } from '../../paymentus/types';
import PaymentMethodDeleteConfirmModal from '../payment-delete-confirm-modal';
import PaymentMethodEditDelete from '../payment-edit-delete';
import PaymentMethodItem from '../payment-method-item';
import useStyles from '../styles';

const StyledButton = ({ ...rest }: ButtonProps) => (
  <Button
    fullWidth
    aria-controls="customized-menu"
    aria-haspopup="true"
    variant="outlined"
    style={{
      background: '#fff',
    }}
    {...rest}
  />
);

type Props = {
  profileList: PaymentProfile[];
  onEdit?: ProfileChangeHandler;
  onDelete?: ProfileChangeHandler;
};

export const PaymentMethodList = (props: Props) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();

  const [deleteProfile, setDeleteProfile] = useState<PaymentProfile | null>(
    null,
  );
  const handleDeleteProfile = () => {
    if (onDelete && deleteProfile) {
      onDelete(deleteProfile);
    }
    setDeleteProfile(null);
  };

  const { profileList, onEdit, onDelete } = props;

  return (
    <Grid
      data-testid="payment-method-list"
      container
      item
      spacing={1}
      direction={'column'}
      alignItems={'flex-start'}
      justify={'flex-start'}
      // style={{ width: '100%' }}
    >
      <Grid item container>
        <Typography variant="body1">
          {t('PAYMENT_METHOD_LIST_HEADER')}{' '}
        </Typography>
      </Grid>
      {profileList.map(profile => (
        <Grid item container key={profile.token}>
          <StyledButton>
            <div className={classes.paymentItemContainer}>
              <PaymentMethodItem profile={profile} />
              <PaymentMethodEditDelete
                profile={profile}
                onEdit={onEdit}
                onDelete={p => setDeleteProfile(p)}
              ></PaymentMethodEditDelete>
            </div>
          </StyledButton>
        </Grid>
      ))}
      {deleteProfile && (
        <PaymentMethodDeleteConfirmModal
          open={!!deleteProfile}
          profile={deleteProfile}
          onClose={() => setDeleteProfile(null)}
          onDeleteProfileConfirm={handleDeleteProfile}
        ></PaymentMethodDeleteConfirmModal>
      )}
    </Grid>
  );
};

export default PaymentMethodList;
