import { getPaymentMethodSelectorData } from '../auto-pay/auto-pay.utils';
import {
  PaymentProfile,
  PaymentEligibility,
  PaymentAlerts,
  AccountDetail,
  Scalars,
  Maybe,
} from '../../__generated__/pge-types';
import {
  OneTimePayConfigData,
  OnetimePayWarning,
  BankInfo,
} from './oneTimePayment.types';
import {
  NewBankInfo,
  PaymentMethodSelectorData,
} from '../auto-pay/auto-pay.types';

import { Model as BankSectionModel } from '../auto-pay/payment-method-selector/BankingInfoSection.rules';
import { FormState } from '../../hooks/useFormState.types';
import {
  toCurrencyString,
  getFormattedToday,
  maskAllButLast,
  formatDate,
} from '../../util/format';
import { useTranslation } from '../../hooks/useTranslation';
import { isWithinInterval, parse, addDays } from 'date-fns';
import {
  ADD_PROFILE,
  PaymentProfileMultiSelectWrapper,
} from '../paymentus/types';
import moment from 'moment-timezone';

const { richT, t, inlineRichT } = useTranslation();
const PstTime = moment()
  .tz('Canada/Pacific')
  .format('MM/DD/yyyy');

export type PaymentInfo = {
  amountDue: Scalars['Float'];
  dueDate?: Maybe<Scalars['DateTimeCustom']>;
  paymentEligibility?: PaymentEligibility;
  pastDueAmount?: Scalars['Float'];
};

export function getDefaultOneTimePaymentInfoFormData(
  details: PaymentInfo,
  savedProfileList?: PaymentProfile[],
  maxPaymentDate?: Date,
  minimumAmount?: string,
  defaultAmount?: number,
): OneTimePayConfigData {
  const today = formatDate(new Date(PstTime), 'MM/dd/yyyy');
  const amount = defaultAmount
    ? defaultAmount < 0
      ? 0
      : defaultAmount
    : details?.amountDue
    ? details?.amountDue <= 0
      ? 0
      : details?.amountDue
    : 0;

  const eligibility: PaymentEligibility = {
    isCashOnly: details?.paymentEligibility?.isCashOnly || false,
    isNonBillableNoBalance:
      details?.paymentEligibility?.isNonBillableNoBalance || false,
  };

  const profileUpdatedProps = {
    isEditButton: true,
    isDeleteButton: true,
    paymentAmount: '0',
    isDisabled: false,
    isChecked: false,
    isError: false,
    errorText: '',
  };

  const multiPaymentProfileList:
    | PaymentProfileMultiSelectWrapper[]
    | undefined = [];
  Array.isArray(savedProfileList) && savedProfileList.length !== 0
    ? savedProfileList.map(profile => {
        multiPaymentProfileList.push({
          profile: profile,
          ...profileUpdatedProps,
        });
      })
    : multiPaymentProfileList;

  return {
    // TODO: Do NOT cast back and forth between Numbers and string,
    // if the Number needs to be displayed in certain format do that in the display
    // component, refactor to preserve number type instead of casting to string
    // <07-08-20, cpitt> //
    paymentAmount: String(amount || 0),
    paymentDate: today,
    // NEED TO CHECK THIS AGAIN WHEN WE DO ONE-TIME-PAY BACKEND ELIGIBILITY WIRING
    paymentSelector: getPaymentMethodSelectorData(
      'oneTime',
      eligibility,
      null,
      savedProfileList,
      undefined,
      multiPaymentProfileList,
    ),
    saveBankInfo: false,
    initialLoading: true,
    splitPayStatus: false,
    splitAmounts: [],
    selectedAccordian:
      Array.isArray(savedProfileList) && savedProfileList.length !== 0
        ? undefined
        : 0,
    maxPaymentDate,
    minimumAmount,
  };
}

export function combineBankSection(
  oneTimePayConfig: OneTimePayConfigData,
  bankSectionForm: FormState<BankSectionModel>,
  paymentAmount: string,
  paymentDate: string,
): Promise<OneTimePayConfigData> {
  return new Promise((res, rej) => {
    const isBank =
      oneTimePayConfig?.paymentSelector?.paymentType === 'bankAccount';
    const newInfo =
      oneTimePayConfig?.paymentSelector?.bankAccountOption === 'addNewAccount';

    if (isBank && newInfo) {
      return bankSectionForm.submit(bankSection => {
        res(
          setNewBankInfo(
            oneTimePayConfig,
            {
              bankAccountNumber: bankSection.bankAccountNumber,
              bankRoutingNumber: bankSection.routingNumber,
            },
            paymentAmount,
            paymentDate,
            bankSection.rememberMe,
          ),
        );
      }, rej)();
    } else {
      res(
        setNewBankInfo(
          oneTimePayConfig,
          {
            bankAccountNumber: null,
            bankRoutingNumber: null,
          },
          paymentAmount,
          paymentDate,
          false,
        ),
      );
    }
  });
}

export function setNewBankInfo(
  data: OneTimePayConfigData,
  newBankInfo: NewBankInfo,
  paymentAmount: string,
  paymentDate: string,
  rememberMe: boolean,
): OneTimePayConfigData {
  return {
    ...data,
    paymentAmount: paymentAmount,
    paymentDate: paymentDate,
    paymentSelector: {
      ...data.paymentSelector,
      newBankInfo,
    },
    saveBankInfo: rememberMe,
  };
}

export function getSelectedBankDetails(
  paymentSelectorData: PaymentMethodSelectorData | undefined,
): BankInfo {
  const savedInfo = paymentSelectorData?.savedBankInfo;
  const newInfo = paymentSelectorData?.newBankInfo;

  const isSaved =
    paymentSelectorData?.bankAccountOption === 'currentBankAccount';

  const bankAccountNumber = isSaved
    ? savedInfo?.maskedBankAccountNumber
    : maskAllButLast(4, newInfo?.bankAccountNumber);
  const routingNumber = isSaved
    ? savedInfo?.bankRoutingNumber
    : newInfo?.bankRoutingNumber;

  const bankInfo: BankInfo = {
    bankAccountNumber: bankAccountNumber || '',
    routingNumber: routingNumber || '',
  };

  return bankInfo;
}

export function isWithInThreeDays(paymentDate: string): boolean {
  const userSelectedDate = parse(paymentDate, 'MM/dd/yyyy', new Date(0));
  const today = new Date(PstTime).setHours(0, 0, 0, 0);
  const maxDate = addDays(today, 3);

  return isWithinInterval(userSelectedDate, {
    start: today,
    end: maxDate,
  });
}

export function makeBillMatrixProps(oneTimePayConfig: OneTimePayConfigData) {
  const paymentAmount = oneTimePayConfig?.paymentAmount;
  const paymentDate = oneTimePayConfig?.paymentDate;

  return {
    paymentAmount,
    paymentDate,
  };
}

export function getOneTimePayWarningMessages(
  details: AccountDetail | undefined,
): OnetimePayWarning[] {
  const warnings: OnetimePayWarning[] = [];
  if (details === undefined) {
    return warnings;
  }

  hasCurrentDayPayment(details, warnings);
  hasMultipleCurrentDayPayments(details, warnings);
  hasFutureDatedPayment(details, warnings);
  hasAutoPay(details, warnings);

  return warnings;
}

function hasMultipleCurrentDayPayments(
  details: AccountDetail,
  warnings: OnetimePayWarning[],
) {
  if (details?.paymentAlerts?.isMultipleSameDayPayment) {
    warnings.push({
      type: 'multipleCurrentDay',
      message: t('ONETIMEPAY_WARNING_MULTIPLE_PAYMENTS'),
    });
  }
}

function hasCurrentDayPayment(
  details: AccountDetail,
  warnings: OnetimePayWarning[],
) {
  if (details?.paymentAlerts?.isSingleSameDayPayment) {
    warnings.push({
      type: 'currentDay',
      message: inlineRichT('ONETIMEPAY_WARNING_CURRENT_DAY_PAYMENT', {
        AMOUNT: toCurrencyString(
          details?.paymentAlerts?.lastPaidAmount || 0,
          false,
        ),
        CURRENT_DATE: getFormattedToday(),
      }),
    });
  }
}

function hasFutureDatedPayment(
  details: AccountDetail,
  warnings: OnetimePayWarning[],
) {
  if (details?.paymentAlerts?.isFutureDated) {
    warnings.push({
      type: 'futureDated',
      message: inlineRichT('ONETIMEPAY_WARNING_FUTURE_DATED', {
        AMOUNT: toCurrencyString(
          details?.paymentAlerts?.totalFutureDatedPaymentAmount || 0,
          false,
        ),
      }),
    });
  }
}

function hasAutoPay(details: AccountDetail, warnings: OnetimePayWarning[]) {
  if (details.autoPay?.isEnrolled) {
    warnings.push({
      type: 'autoPay',
      message: t('ONETIMEPAY_WARNING_AUTOPAY_BANK'),
    });
  }
}
export const roundUpToNextDollar = (number: number) => {
  return Math.ceil(number) === number ? number + 1 : Math.ceil(number);
};

export const roundUpToNearestTen = (number: number) => {
  return Math.ceil(number / 10) * 10 === number
    ? number + 10
    : Math.ceil(number / 10) * 10;
};

export const roundUpToNearestFive = (number: number) => {
  return Math.ceil(number / 5) * 5 === number
    ? number + 5
    : Math.ceil(number / 5) * 5;
};
