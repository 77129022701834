import React from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import RichText from '../rich-text';
import { ContentfulSmartCardSection } from '../../__generated__/gatsby-types';
import { Carousel } from '../carousel';

import colors from '../../themes/main-colors';
import { SingleCard } from '../single-card';
import TabSet from '../tabset/TabSet2';
import { getButtonVariable } from '../../util/contentful-render-utils';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2, 0),
    },
    sectionHeader: {
      fontSize: theme.spacing(3),
      lineHeight: theme.typography.pxToRem(28),
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
      color: colors.noirBlur,
      marginBottom: theme.spacing(1),
      fontWeight: 'bold',
    },
    sectionBody: {
      marginBottom: theme.spacing(2),
    },
  }),
);

export enum CardGroupingDisplayType {
  CarouselComponent = 'CAROUSEL',
  TabSetComponent = 'TAB-SET',
}

export const SmartCardColors: { [key: string]: string } = Object.freeze({
  GREEN: colors.chromeWhite,
  BLUE: colors.riverBlue,
  LINEN: colors.linenWhite,
  WHITE: colors.white,
});

interface TSmartCardSection {
  cardGrouping: any[];
  standaloneCards: any[];
  contentfulSectionCard: ContentfulSmartCardSection;
}

function SmartCardSection(props: TSmartCardSection): ElementNode {
  const classes = useStyles();

  const resolveButtonLink = (buttonLink: any) => {
    return getButtonVariable(null, buttonLink)?.onClick;
  };

  const doesCardGroupingHaveCards = props.cardGrouping?.length > 0;
  const doesStandAloneCardsHaveCards = props.standaloneCards?.length > 0;

  if (!doesCardGroupingHaveCards && !doesStandAloneCardsHaveCards) {
    return null;
  }

  const carousel = {
    panes:
      doesCardGroupingHaveCards &&
      props.contentfulSectionCard.cardGroupingDisplayType ===
        CardGroupingDisplayType.CarouselComponent
        ? props.cardGrouping.map((card: any) => {
            return {
              header: card.header,
              subHeader: card.subHeader,
              image: card.image && card.image.file ? card.image.file.url : null,
              mobileImage:
                card.mobileImage && card.mobileImage.file
                  ? card.mobileImage.file.url
                  : null,
              body: card.copy ? <RichText>{card.copy}</RichText> : null,
              ctaText: card.button && card.button?.button.buttonText,
              ctaLink:
                card.button && resolveButtonLink(card.button?.buttonLink),
              backgroundColor: card.backgroundColor,
            };
          })
        : [],
  };

  const tabInfo =
    doesCardGroupingHaveCards &&
    props.contentfulSectionCard.cardGroupingDisplayType ===
      CardGroupingDisplayType.TabSetComponent
      ? props.cardGrouping.map((card: any) => {
          return {
            mobileHeading: card.header,
            image: card.image && card.image.file ? card.image.file.url : null,
            mobileImage:
              card.mobileImage && card.mobileImage.file
                ? card.mobileImage.file.url
                : null,
            description: card.copy ? <RichText>{card.copy}</RichText> : null,
            title: card.subHeader,
            ctaText: card.button && card.button?.button.buttonText,
            ctaLink: card.button && resolveButtonLink(card.button?.buttonLink),
          };
        })
      : [];

  return (
    <Box className={classes.container}>
      {props.contentfulSectionCard.sectionHeader && (
        <Typography className={classes.sectionHeader}>
          {props.contentfulSectionCard.sectionHeader}
        </Typography>
      )}

      {props.contentfulSectionCard.sectionBody && (
        <Box className={classes.sectionBody}>
          <RichText>{props.contentfulSectionCard.sectionBody}</RichText>
        </Box>
      )}

      {doesCardGroupingHaveCards &&
        props.contentfulSectionCard.cardGroupingDisplayType ===
          CardGroupingDisplayType.CarouselComponent && (
          <Box className={classes.sectionBody}>
            <Carousel panes={carousel.panes}></Carousel>
          </Box>
        )}
      {doesCardGroupingHaveCards &&
        props.contentfulSectionCard.cardGroupingDisplayType ===
          CardGroupingDisplayType.TabSetComponent && (
          <Box className={classes.sectionBody}>
            <TabSet tabsInfo={tabInfo}></TabSet>
          </Box>
        )}
      {doesStandAloneCardsHaveCards &&
        props.standaloneCards.map((card: any) => {
          return (
            <Box className={classes.sectionBody} key={card.contentful_id}>
              <SingleCard
                cardHeader={card?.header}
                cardBody={card?.copy ? <RichText>{card?.copy}</RichText> : null}
                buttons={
                  card?.button
                    ? [
                        {
                          ctaText: card?.button?.button?.buttonText,
                          ctaLink: resolveButtonLink(card.button?.buttonLink),
                        },
                      ]
                    : undefined
                }
                disclaimer={card?.finePrint}
                bgColor={card?.backgroundColor}
                image={card?.image?.file?.url}
                mobileImage={card?.mobileImage?.file?.url}
              />
            </Box>
          );
        })}
    </Box>
  );
}

export default SmartCardSection;
