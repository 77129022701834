import React from 'react';
import AccountLayout from '../../components/account-layout';
import PeakTimeRebates from '../../components/peak-time-rebates';
import { useTranslation } from '../../hooks/useTranslation';
import Helmet from 'react-helmet';
import { Router } from '@reach/router';
import ROUTES from '../../routes';
import PeakTimeRebatesHistory from '../../components/peak-time-rebates/history';

export const PeakTimeRebatesPageItWorks = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>{t('PTR_TITLE_CASE')}</title>
      </Helmet>
      <AccountLayout>
        <Router basepath="/">
          <PeakTimeRebates path={ROUTES.PEAKTIME_REBATES} />
          <PeakTimeRebatesHistory path={ROUTES.PEAKTIME_REBATES_HISTORY} />
        </Router>
      </AccountLayout>
    </>
  );
};

export default PeakTimeRebatesPageItWorks;
