/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';

// svg Icons
import BasementIcon from '../../pge-plus-common/static/basementIcon.svg';
import GarageIcon from '../../pge-plus-common/static/garageIcon.svg';
import DetachedHomeIcon from '../../pge-plus-common/static/detachedHomeIcon.svg';
import OthersIcon from '../../pge-plus-common/static/othersIcon.svg';
import QuestionMarkIcon from '../../pge-plus-common/static/questionMarkIcon.svg';
import OutsideHomeIcon from '../../pge-plus-common/static/outsideHomeIcon.svg';

import { PageTextContentType } from '../../../hooks/useContentMgmt';
import {
  ChargerInstallLocation,
  PanelLocation,
} from '../../../__generated__/pgeplus-types';

export interface PanelLocationTypeProps {
  textLabel: React.ReactNode;
  formValue: string;
  img: React.ReactNode;
}

type Props = {
  content: PageTextContentType;
};

export const locationTypes = ({ content }: Props): PanelLocationTypeProps[] => {
  return [
    {
      textLabel: content.get('ATTACHED_GARAGE'),
      formValue: PanelLocation.GarageAttached,
      img: <GarageIcon />,
    },
    {
      textLabel: content.get('BASEMENT'),
      formValue: PanelLocation.Basement,
      img: <BasementIcon />,
    },
    {
      textLabel: content.get('INSIDE_THE_HOME'),
      formValue: PanelLocation.Inside,
      img: <DetachedHomeIcon />,
    },
    {
      textLabel: content.get('OUTSIDE_THE_HOME'),
      formValue: PanelLocation.Outside,
      img: <OutsideHomeIcon />,
    },
    {
      textLabel: content.get('NOT_SURE'),
      formValue: PanelLocation.NotSure,
      img: <QuestionMarkIcon />,
    },
    {
      textLabel: content.get('OTHER'),
      formValue: PanelLocation.Other,
      img: <OthersIcon />,
    },
  ];
};

export const chargerInstallationlocationTypes = ({
  content,
}: Props): PanelLocationTypeProps[] => {
  return [
    {
      textLabel: content.get('GARAGE'),
      formValue: ChargerInstallLocation.Garage,
      img: <GarageIcon />,
    },
    {
      textLabel: content.get('OUTSIDE_THE_HOME_PLAIN'),
      formValue: ChargerInstallLocation.Outside,
      img: <OutsideHomeIcon />,
    },
  ];
};
