import React from 'react';
import {
  Box,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cta: {
      display: 'block',
      fontWeight: 'bold',
    },

    title: {
      fontSize: theme.spacing(1.75),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        rowGap: `${theme.spacing(0.5)}px`,
      },
    },
    content: {
      paddingTop: '1.5rem',
      fontSize: theme.spacing(1.75),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        rowGap: `${theme.spacing(0.5)}px`,
      },
    },
  }),
);

type TimeOfDayInfoData = {
  initialText: React.ReactNode;
  initialTextCTATitle?: React.ReactNode;
  middleText?: React.ReactNode;
  finalText?: React.ReactNode;
  initialTextCTALink?: string;
  initialTextCTAIsExternal?: boolean;
};

export function TimeOfDayInfo(props: TimeOfDayInfoData) {
  const classes = useStyles();
  const {
    initialText,
    initialTextCTATitle,
    middleText,
    finalText,
    initialTextCTALink,
    initialTextCTAIsExternal,
  } = props;

  return (
    <>
      <Box>
        <Typography className={classes.title}>
          {initialText}
          {initialTextCTAIsExternal &&
            initialTextCTATitle &&
            initialTextCTALink && (
              <a
                className={classes.cta}
                href={initialTextCTALink}
                target="_blank"
              >
                {initialTextCTATitle}
              </a>
            )}
          {!initialTextCTAIsExternal &&
            initialTextCTATitle &&
            initialTextCTALink && (
              <Link className={classes.cta} to={initialTextCTALink}>
                {initialTextCTATitle}
              </Link>
            )}
        </Typography>
      </Box>
      {middleText && (
        <Typography className={classes.content}>{middleText}</Typography>
      )}
      {finalText && (
        <Typography className={classes.content}>{finalText}</Typography>
      )}
    </>
  );
}
