import { Grid, Typography } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import React from 'react';
import { useIsMobile } from '../../../util/style-utils';
import { PaymentProfile } from '../../../__generated__/pge-types';
import { ProfileChangeHandler } from '../../paymentus/types';
import { addEditExcludeList } from '../../paymentus/utils';

type EditDeleteProps = {
  profile: PaymentProfile;
  onEdit?: ProfileChangeHandler;
  onDelete?: ProfileChangeHandler;
};

const PaymentMethodEditDelete = ({
  profile,
  onEdit,
  onDelete,
}: EditDeleteProps) => {
  const isMobile = useIsMobile();
  const isHideEdit = profile.type && addEditExcludeList.includes(profile.type);

  return (
    <Grid
      container
      item
      direction={'row'}
      justify={'flex-start'}
      style={{ width: 'auto' }}
    >
      {isMobile && isHideEdit ? null : (
        <>
          <Grid container item xs={4} justify={'center'}>
            <Edit
              data-testid={`${profile.token}-edit-button`}
              fontSize="small"
              color="primary"
              onClick={event => onEdit && onEdit(profile)}
              style={isHideEdit ? { visibility: 'hidden' } : {}}
            ></Edit>
          </Grid>
          <Grid container item xs={4} justify={'center'}>
            <Typography
              component={'span'}
              style={{
                ...(isHideEdit ? { visibility: 'hidden' } : {}),
                margin: 'auto 1em ',
              }}
            >
              |
            </Typography>
          </Grid>
        </>
      )}
      <Grid item container xs={4} justify={'center'}>
        <Delete
          data-testid={`${profile.token}-delete-button`}
          fontSize="small"
          color="primary"
          onClick={event => onDelete && onDelete(profile)}
        ></Delete>
      </Grid>
    </Grid>
  );
};

export default PaymentMethodEditDelete;
