import {
  Box,
  createStyles,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Link,
  makeStyles,
  Modal,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
import { navigate } from '@reach/router';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import PGEButton from '../../buttons';
import PaperBox from '../../utility/paper-box';

import ROUTES from '../../../routes';
import PaymentMethodTypeIconList from '../../payment-method-type/PaymentMethodTypeIconList';
import { Close } from '@material-ui/icons';

import colors from '../../../themes/main-colors';
import { useIsMobile } from '../../../util/style-utils';
import { toCurrencyDisplayFormat, toDateString } from '../../../util/format';
import {
  ReconnectQuoteDetails,
  ReconnectQuoteStatus,
} from '../../../__generated__/pge-types';

import IconPhoneContact from '../../../static/phoneContact.svg';
import IconPayInPerson from '../../../static/payInPerson.svg';
import ReactMarkdown from 'react-markdown';
import Alerts from '../../alerts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperCard: {
      margin: theme.spacing(2, 0, 0, 2),
      width: '100%',
      boxShadow: 'inset 0px 2px 0px #27788C, 0px 3px 6px #00000029',
      borderRadius: '4px',
    },
    paperbox: {
      margin: theme.spacing(1, 1, 1, 1),
    },
    paperBoxOuterContainer: {
      padding: theme.spacing(2),
    },
    paperBoxContainer: {
      padding: theme.spacing(2, 2, 2, 2),
      width: '100%',
      backgroundColor: colors.gray93,
      marginTop: theme.spacing(2),
    },
    centerContainer: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    callModal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: theme.spacing(4, 4, 3, 3),
      boxShadow: theme.shadows[5],
      backgroundColor: theme.palette.background.paper,
      borderRadius: '5px',
      [theme.breakpoints.down('xs')]: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(4, 3, 3, 3),
        overflow: 'auto',
      },
      overflow: 'auto',
      maxHeight: '95%',
    },
    callModalTitle: {
      fontSize: '20px',
    },
    modal: {
      position: 'absolute',
      width: '100%',
      maxWidth: '450px',
      top: '50%',
      left: '50%',
      transform: 'translate(-50% , -50%)',
      outline: 0,
      [theme.breakpoints.down('xs')]: {
        maxWidth: '95%',
      },
    },
    button: {
      background: colors.orange,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      '&:hover': {
        backgroundColor: colors.orange,
      },
    },
    textMoney: {
      fontWeight: 'bold',
    },
    link: {
      textDecoration: 'none',
      cursor: 'pointer',
    },
    boxSize: {
      width: '49%',
    },
    mobileBoxSize: {
      width: '99%',
    },
    diaplyTopMargin: {
      marginTop: theme.spacing(1),
    },
    diaplyTopMargin20: {
      marginTop: theme.spacing(2),
    },
    vdivider: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    customFontSize: {
      fontSize: '16px',
    },
    warningText: {
      color: colors.white,
      flexGrow: 8,
      marginLeft: '1em',
      marginRight: '1em',
    },
    wrapper: {
      backgroundColor: colors.brightBlue,
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
    },
    belowTextPanel: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    leftPanelText: {
      '& a': {
        textDecoration: 'none',
      },
    },
    rightPanelText: {
      '& a': {
        textDecoration: 'none',
      },
    },
    modalText: {
      marginRight: '.75rem',
      '& p:nth-child(5), & p:nth-child(7)': {
        marginLeft: '24px',
        marginBottom: '0px',
      },
      '& ul': {
        marginTop: '0px',
      },
    },
    paidMessageBox: {
      marginTop: '1em',
      '& > .MuiCollapse-container > .MuiCollapse-wrapper': {
        '& .MuiCollapse-wrapperInner > .MuiAlert-standardSuccess': {
          backgroundColor: '#fff !important',
        },
      },
    },
  }),
);

interface Props {
  reconnectPaymentOption: ReconnectQuoteDetails;
  path?: string;
}
const ReconnectServiceStart: FunctionComponent<Props> = ({
  reconnectPaymentOption,
}: Props) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();

  const isMobile = useIsMobile();
  const [showModal, setShowModal] = useState(false);
  const [
    showSampleAccountNumbeModal,
    setShowSampleAccountNumbeModal,
  ] = useState(false);
  const [
    showSampleRoutingNumbeModal,
    setShowSampleRoutingNumbeModal,
  ] = useState(false);

  const onShowTnC = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(true);
  };
  const showSampleAccountNumber = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowSampleAccountNumbeModal(true);
  };
  const showSampleRoutingNumber = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setShowSampleRoutingNumbeModal(true);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {reconnectPaymentOption?.quoteStatus === ReconnectQuoteStatus.Accepted ? (
        <>
          <PaperBox>
            <Grid container>
              <Grid item xs={12} style={{ marginLeft: '1em' }}>
                <Typography variant={'h2'}>
                  {t('RECONNECT_SERVICE_START_SUB_TEXT')}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ marginLeft: '1em', marginTop: '2em' }}
              >
                <Box className={classes.wrapper}>
                  <p className={classes.warningText}>
                    <Typography variant="body2">
                      {' '}
                      {t('RECONNECT_START_BANNER')}
                    </Typography>
                  </p>
                </Box>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '1em' }}></Grid>

              <Paper className={classes.paperCard}>
                <Grid container direction="row" alignItems="center">
                  <Box
                    className={
                      isMobile ? classes.mobileBoxSize : classes.boxSize
                    }
                    justifyContent={'center'}
                    display={'flex'}
                    flexDirection={isMobile ? 'column' : 'row'}
                    alignItems={isMobile ? 'center' : 'flex-start'}
                  >
                    <Grid
                      container
                      direction="column"
                      className={classes.paperBoxOuterContainer}
                    >
                      <Typography variant={'h2'}>{t('MIN_DUE')}</Typography>
                      <Box className={classes.paperBoxContainer}>
                        <Grid container direction="row" spacing={1}>
                          <Grid item xs={isMobile ? 12 : 8}>
                            {t('MIN_BALANCE')}
                          </Grid>
                          <Grid
                            item
                            xs={isMobile ? 12 : 4}
                            className={classes.textMoney}
                          >
                            $
                            {toCurrencyDisplayFormat(
                              reconnectPaymentOption?.minimumDueOption
                                ?.balance || 0,
                              true,
                              'CR',
                            )}
                          </Grid>
                          <Grid item xs={isMobile ? 12 : 8}>
                            {t('DEPOSIT')}
                          </Grid>
                          <Grid
                            item
                            xs={isMobile ? 12 : 4}
                            className={classes.textMoney}
                          >
                            $
                            {toCurrencyDisplayFormat(
                              reconnectPaymentOption?.minimumDueOption
                                ?.deposit || 0,
                              true,
                              'CR',
                            )}
                          </Grid>
                          <Grid item xs={isMobile ? 12 : 8}>
                            {t('RECONNECT_FEE')}
                          </Grid>
                          <Grid
                            item
                            xs={isMobile ? 12 : 4}
                            className={classes.textMoney}
                          >
                            $
                            {toCurrencyDisplayFormat(
                              reconnectPaymentOption?.minimumDueOption
                                ?.reconnectFee || 0,
                              true,
                              'CR',
                            )}
                          </Grid>
                        </Grid>
                        <Divider className={classes.diaplyTopMargin} />
                        <Grid
                          container
                          direction="row"
                          className={classes.diaplyTopMargin}
                        >
                          <Grid item xs={8}>
                            <Typography
                              variant={'body2'}
                              className={classes.customFontSize}
                            >
                              {t('TOTAL_DUE')}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              variant={'body1'}
                              className={classes.textMoney}
                            >
                              $
                              {toCurrencyDisplayFormat(
                                Number(
                                  reconnectPaymentOption?.minimumDueOption
                                    ?.totalDue,
                                ) || 0,
                                true,
                                'CR',
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Box>
                  <Hidden xsDown>
                    <Divider
                      orientation="vertical"
                      flexItem
                      className={classes.vdivider}
                    />
                  </Hidden>
                  <Box
                    className={
                      isMobile ? classes.mobileBoxSize : classes.boxSize
                    }
                    justifyContent={'center'}
                    display={'flex'}
                    flexDirection={isMobile ? 'column' : 'row'}
                    alignItems={isMobile ? 'center' : 'flex-start'}
                  >
                    <Grid
                      container
                      direction="column"
                      className={classes.paperBoxOuterContainer}
                    >
                      <Typography variant={'h2'}>{t('FULL_AMOUNT')}</Typography>
                      <Box className={classes.paperBoxContainer}>
                        <Grid container direction="row" spacing={1}>
                          <Grid item xs={isMobile ? 12 : 8}>
                            {t('FULL_BALANCE')}
                          </Grid>
                          <Grid
                            item
                            xs={isMobile ? 12 : 4}
                            className={classes.textMoney}
                          >
                            $
                            {toCurrencyDisplayFormat(
                              reconnectPaymentOption?.fullAmountOption
                                ?.balance || 0,
                              true,
                              'CR',
                            )}
                          </Grid>
                          <Grid item xs={isMobile ? 12 : 8}>
                            {t('DEPOSIT')}
                          </Grid>
                          <Grid
                            item
                            xs={isMobile ? 12 : 4}
                            className={classes.textMoney}
                          >
                            $
                            {toCurrencyDisplayFormat(
                              reconnectPaymentOption?.fullAmountOption
                                ?.deposit || 0,
                              true,
                              'CR',
                            )}
                          </Grid>
                          <Grid item xs={isMobile ? 12 : 8}>
                            {t('RECONNECT_FEE')}
                          </Grid>
                          <Grid
                            item
                            xs={isMobile ? 12 : 4}
                            className={classes.textMoney}
                          >
                            $
                            {toCurrencyDisplayFormat(
                              reconnectPaymentOption?.fullAmountOption
                                ?.reconnectFee || 0,
                              true,
                              'CR',
                            )}
                          </Grid>
                        </Grid>
                        <Divider className={classes.diaplyTopMargin} />
                        <Grid
                          container
                          direction="row"
                          className={classes.diaplyTopMargin}
                        >
                          <Grid item xs={8}>
                            <Typography
                              variant={'body2'}
                              className={classes.customFontSize}
                            >
                              {t('TOTAL_DUE')}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography
                              variant={'body1'}
                              className={classes.textMoney}
                            >
                              $
                              {toCurrencyDisplayFormat(
                                Number(
                                  reconnectPaymentOption?.fullAmountOption
                                    ?.totalDue,
                                ) || 0,
                                true,
                                'CR',
                              )}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Paper>
              <Grid item xs={12} className={classes.centerContainer}>
                <Box justifyContent={'center'} display={'flex'}>
                  <Typography variant={'body2'} className={classes.textMoney}>
                    {richT('RECONNECT_START_DATE_OPTION', {
                      DATE_DATA: toDateString(
                        reconnectPaymentOption?.optionsValidThrough.split(
                          '.',
                        )[0],
                        'MM/dd/yyyy',
                      ),
                    })}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} className={classes.centerContainer}>
                <Box justifyContent={'center'} display={'flex'}>
                  <PGEButton
                    data-testid={'pay-online-button'}
                    onClick={() => navigate(ROUTES.RECONNECT_SERVICE_PAYMENT)}
                    size={'large'}
                    className={classes.button}
                  >
                    {t('PAY_ONLINE')}
                  </PGEButton>
                </Box>
              </Grid>

              <Grid container direction={isMobile ? 'column' : 'row'}>
                <Grid item xs={4}></Grid>
                <Grid
                  item
                  xs={isMobile ? 12 : 4}
                  className={classes.centerContainer}
                  style={{ padding: '0 12px' }}
                >
                  <PaymentMethodTypeIconList />
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
              <Grid
                container
                direction="row"
                className={classes.diaplyTopMargin20}
              >
                <Typography
                  variant={'body2'}
                  component={'div'}
                  className={classes.textMoney}
                >
                  {t('RECONNECT_START_BELOW_TEXT')}{' '}
                  {
                    <Link onClick={onShowTnC} className={classes.link}>
                      {t('HOW_TO_FIND_BREAKER')}
                    </Link>
                  }
                </Typography>
              </Grid>
            </Grid>
          </PaperBox>
          <PaperBox>
            <Grid container>
              <Grid item xs={12} style={{ padding: '1em' }}>
                <Typography variant={'h4'}>{t('OTHER_WAY_TO_PAY')}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              style={{ padding: '0em 1em 1em 1em' }}
            >
              <Box
                className={isMobile ? classes.mobileBoxSize : classes.boxSize}
                justifyContent={'center'}
                display={'flex'}
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'center' : 'flex-start'}
                style={!isMobile ? { paddingRight: '1em' } : {}}
              >
                <Grid container direction="row">
                  <Grid item xs={12}>
                    <IconPayInPerson />
                  </Grid>
                  <Grid item xs={12} className={classes.leftPanelText}>
                    <Typography variant={'h4'}>
                      {t('WESTERN_UNION_OR')}
                    </Typography>
                    <Typography variant={'h4'}>
                      {t('CHECK_FREE_PAY')}
                    </Typography>
                    <Typography
                      variant={'subtitle1'}
                      style={{ display: 'inline' }}
                    >
                      {richT('OTHERS_WAY_TO_PAY_LEFT_PANEL', {
                        CUSTOMER_SERVICE_NUMBER: t(
                          'CALL_FOR_ASSISTANCE_NUMBER',
                        ),
                      })}
                    </Typography>
                    <Typography variant={'subtitle1'}>
                      <ReactMarkdown
                        source={t('OTHERS_WAY_TO_PAY_LEFT_PANEL_LIST_ITEMS')}
                        escapeHtml={false}
                        linkTarget="_blank"
                      />
                      {richT('')}
                    </Typography>
                    <Typography
                      variant={'subtitle1'}
                      style={{ display: 'inline' }}
                    >
                      {t('OTHERS_WAY_TO_PAY_LEFT_PANEL_TEXT_PART1')}
                      {
                        <Link
                          onClick={showSampleAccountNumber}
                          className={classes.link}
                        >
                          {t('PGE_ACCOUNT_NUMBER')}
                        </Link>
                      }
                      {t('OTHERS_WAY_TO_PAY_LEFT_PANEL_TEXT_PART2')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Hidden xsDown>
                <Divider orientation="vertical" flexItem />
              </Hidden>

              <Box
                className={isMobile ? classes.mobileBoxSize : classes.boxSize}
                justifyContent={'center'}
                display={'flex'}
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems={isMobile ? 'center' : 'flex-start'}
                style={!isMobile ? { paddingLeft: '1em' } : {}}
              >
                <Grid container direction="row">
                  {isMobile && (
                    <Grid item xs={12}>
                      <Divider className={classes.vdivider} />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <IconPhoneContact />
                  </Grid>
                  <Grid item xs={12} className={classes.rightPanelText}>
                    <Typography variant={'h4'}>{t('PAY_BY_PHONE')}</Typography>
                    <Typography variant={'body2'} className={classes.link}>
                      {richT('PAY_BY_PHONE_RIGHT_PANEL', {
                        CUSTOMER_SERVICE_NUMBER: t(
                          'CALL_FOR_ASSISTANCE_NUMBER',
                        ),
                        CUSTOMER_SERVICE_NUMBER1: t(
                          'PGE_CUSTOMER_SERVICE_NUMBER',
                        ),
                      })}
                    </Typography>
                    <Typography
                      variant={'subtitle1'}
                      style={{ display: 'inline' }}
                    >
                      {t('PAY_BY_PHONE_RIGHT_PANEL_TEXT_PART1')}
                      {
                        <Link
                          onClick={showSampleAccountNumber}
                          className={classes.link}
                        >
                          {t('PGE_ACCOUNT_NUMBER')}
                        </Link>
                      }
                      {t('PAY_BY_PHONE_RIGHT_PANEL_TEXT_PART2')}
                      {
                        <Link
                          onClick={showSampleRoutingNumber}
                          className={classes.link}
                        >
                          {t('ROUTING_NUMBER')}
                        </Link>
                      }
                      {t('PAY_BY_PHONE_RIGHT_PANEL_TEXT_PART3')}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </PaperBox>
        </>
      ) : reconnectPaymentOption?.quoteStatus === ReconnectQuoteStatus.Paid ? (
        <PaperBox>
          <Grid container>
            <Grid item xs={12} style={{ marginLeft: '1em' }}>
              <Typography variant={'h2'}>
                {t('YOUR_RECONNECTION_IS_IN_PROGRESS')}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.paidMessageBox}>
              <Alerts
                disableColorOverride={true}
                isOpen
                severity={'success'}
                variant={'standard'}
                message={
                  <Typography
                    style={{ background: 'none', marginTop: '-10px' }}
                  >
                    {t('RECONNECT_SUCCESS_BANER')}
                  </Typography>
                }
              />
            </Grid>
          </Grid>
        </PaperBox>
      ) : null}

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <div className={classes.callModal}>
          <div className={'closeButton'}>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setShowModal(false)}
            >
              <Close />
            </IconButton>
          </div>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant={'h4'} style={{ marginRight: '.75rem' }}>
                {richT('HOW_TO_FIND_BREAKER')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant={'body2'} className={classes.modalText}>
                {richT('HOW_TO_FIND_BREAKER_MODAL_TEXT')}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={showSampleAccountNumbeModal}
        onClose={() => setShowSampleAccountNumbeModal(false)}
      >
        <div className={classes.callModal}>
          <div className={'closeButton'}>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setShowSampleAccountNumbeModal(false)}
            >
              <Close />
            </IconButton>
          </div>
          <Grid container>
            <Grid item xs={12}>
              <picture>
                <img
                  style={{ width: '100%' }}
                  src={t('SAMPLE_ACCOUNT_NUMBER_URL')}
                  alt={'SAMPLE_ACCOUNT_NUMBER' || ''}
                />
              </picture>
            </Grid>
          </Grid>
        </div>
      </Modal>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={showSampleRoutingNumbeModal}
        onClose={() => setShowSampleRoutingNumbeModal(false)}
      >
        <div className={classes.callModal}>
          <div className={'closeButton'}>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setShowSampleRoutingNumbeModal(false)}
            >
              <Close />
            </IconButton>
          </div>
          <Grid container>
            <Grid item xs={12}>
              <picture>
                <img
                  style={{ width: '100%' }}
                  src={t('ROUTING_NUMBER_IMAGE_URL')}
                  alt={'ROUTING_NUMBER' || ''}
                />
              </picture>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};
export default ReconnectServiceStart;
