import { Box, createStyles, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';
import {
  Maybe,
  ProgramName,
  ProgramsEnrollmentStatus,
} from '../../../__generated__/pge-types';
import { useTranslation } from '../../../hooks/useTranslation';
import routes from '../../../routes';
import Dial from '../../../static/Dial.svg';
import HandAndMoney from '../../../static/HandAndMoney.svg';
import LeafAndPlug from '../../../static/LeafAndPlug.svg';
import Wires from '../../../static/Wires.svg';
import colors from '../../../themes/main-colors';
import theme from '../../../themes/theme';

const useStyles = (sectionBgColor: string) =>
  makeStyles(() => {
    return createStyles({
      container: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(1.5),
        backgroundColor: sectionBgColor,
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        borderRadius: theme.spacing(0.5),
        textDecoration: 'none',
      },
      icon: {
        display: 'block',
        flexShrink: 0,
      },
      header: {
        fontSize: theme.spacing(1.875),
        fontWeight: 'bold',
        color: colors.noirBlur,
        marginBottom: theme.spacing(0.5),
      },
      subText: {
        fontSize: theme.spacing(1.75),
        color: colors.noirBlur,
      },
    });
  });

export type ProgramWithExtra =
  | Maybe<ProgramsEnrollmentStatus>
  | { isEnrolled: true; programName: 'BothGFBAndGFC' };

export function PanelSection({
  program,
  sectionBgColor,
}: {
  program: ProgramWithExtra;
  sectionBgColor: string;
}) {
  const classes = useStyles(sectionBgColor)();
  const { t } = useTranslation();
  const section = {
    icon: <Dial className={classes.icon} />,
    header: '',
    subText: '',
  };

  if (program?.programName === ProgramName.CommunitySolar) {
    section.icon = <LeafAndPlug className={classes.icon} />;
    section.header = t('COMMUNITY_SOLAR');
    section.subText = t('YOU_ARE_SUPPORTING_RENEWABLE_ENERGY');
  }

  if (program?.programName === ProgramName.EvSmartCharging) {
    section.icon = <Dial className={classes.icon} />;
    section.header = t('SMART_CHARGING_HEADER');
    section.subText = t('JUST_PLUG_IN_TO_EARN');
  }

  if (program?.programName === ProgramName.GreenFutureBlock) {
    section.icon = <Wires className={classes.icon} />;
    section.header = t('RENEWABLE_CONFIG_ENROLLMENT_CLEAN_WIND_OPTION');
    section.subText = t('YOU_ARE_SUPPORTING_RENEWABLE_ENERGY');
  }

  if (program?.programName === ProgramName.GreenFutureChoice) {
    section.icon = <Wires className={classes.icon} />;
    section.header = t('GREEN_FUTURE_CHOICE');
    section.subText = t('YOU_ARE_SUPPORTING_RENEWABLE_ENERGY');
  }

  if (program?.programName === 'BothGFBAndGFC') {
    section.icon = <Wires className={classes.icon} />;
    section.header = t('GREEN_FUTURE');
    section.subText = t('YOU_ARE_SUPPORTING_RENEWABLE_ENERGY');
  }

  if (program?.programName === ProgramName.NetMeteringProjectInProcess) {
    section.icon = <Wires className={classes.icon} />;
    section.header = t('ROOFTOP_NAV_TITLE');
    section.subText = t('YOUR_APP_IS_IN_PROCESS');
  }

  if (program?.programName === ProgramName.NetMeteringSystemGenerating) {
    section.icon = <Wires className={classes.icon} />;
    section.header = t('ROOFTOP_NAV_TITLE');
    section.subText = t('YOU_ARE_EARNING_SOLAR_CREDIT');
  }

  if (program?.programName === ProgramName.PeakTimeRebates) {
    section.icon = <Dial className={classes.icon} />;
    section.header = t('PEAK_TIME_REBATES');
    section.subText = t('EARN_REWARDS_FOR_MAKING_A_DIFFERENCE');
  }

  if (program?.programName === ProgramName.SmartBatteryPilot) {
    section.icon = <Dial className={classes.icon} />;
    section.header = t('SMART_BATTERY_PILOT');
    section.subText = t('YOU_ARE_EARNING_SOLAR_CREDIT');
  }

  if (program?.programName === ProgramName.SmartThermostat) {
    section.icon = <Dial className={classes.icon} />;
    section.header = t('SMART_THERMOSTAT');
    section.subText = t('SMART_WAY_TO_EARN_REWARDS');
  }

  if (program?.programName === ProgramName.TimeOfDay) {
    section.icon = <HandAndMoney className={classes.icon} />;
    section.header = t('TIME_OF_DAY_HEADER');
    section.subText = t('USE_LESS_ENERGY_FROM_WEEKDAYS');
  }

  return (
    <Link className={classes.container} to={routes.MY_PROGRAMS}>
      {section.icon}
      <Box>
        <Typography className={classes.header}>{section.header}</Typography>
        <Typography className={classes.subText}>{section.subText}</Typography>
      </Box>
    </Link>
  );
}
