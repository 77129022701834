import React, { useEffect, useRef } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Button from '../../../components/buttons';
import { useIsMobile } from '../../../util/style-utils';
import { useStyles } from './styles';
import { displayPhoneNumberFormat } from '../../../util/format';
import { FieldErrors } from '../../../hooks/useFormState.types';
import { navigate } from '@reach/router';
import ROUTES from '../../../routes';
import NumericTextField from '../../numeric-text-field';
import useFormState from '../../../hooks/useFormState';

type Props = {
  path?: string;
  onSubmit: (code: string, onInvalid: () => void) => void;
  phoneNumber: string;
  hasActivationCode: boolean;
  onTooManyFailures: () => void;
  onPrevious: () => void;
};

type Model = {
  code: string;
};

type Context = {
  t: (s: string) => string;
};

function validate(data: Model, context: Context) {
  const errors: FieldErrors<Model> = {};
  if (data.code.length !== 4) {
    errors.code = context.t('INVALID_ACTIVATION_CODE');
  }
  return errors;
}

export default function ManageAlertsConfirmPhone({
  onSubmit,
  onTooManyFailures,
  phoneNumber,
  hasActivationCode,
  onPrevious,
}: Props) {
  const { t, inlineRichT } = useTranslation();
  const isMobile = useIsMobile();
  const classes = useStyles();
  const form = useFormState(
    { code: '' },
    {
      validate,
      validationContext: {
        t,
      },
    },
  );
  const failureCount = useRef(0);

  useEffect(() => {
    if (!hasActivationCode) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.MANAGE_ALERTS);
    }
  }, []);

  function handleInvalid() {
    failureCount.current += 1;
    if (failureCount.current === 3) {
      onTooManyFailures();
    } else {
      form.setError('code', t('INVALID_ACTIVATION_CODE'));
    }
  }

  return (
    <div className={classes.updatePhoneNumberDialog}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant={'h2'} className={classes.colorTextPrimary}>
            {t('CONFIRM_PHONE_HEADER')}
          </Typography>
          <Typography variant={'body1'} className={classes.colorTextPrimary}>
            {inlineRichT('TEXT_CODE', {
              PHONE_NUMBER: displayPhoneNumberFormat(phoneNumber),
            })}
          </Typography>
          <div className={classes.textConfirmBox}>
            <Typography
              variant={'body1'}
              className={classes.textConfirmContent}
            >
              {t('TEXT_CONFIRM')}
            </Typography>
            <NumericTextField
              maxLength={4}
              label={t('ACTIVATION_CODE')}
              style={{ width: isMobile ? '100%' : '50%' }}
              {...form.props('code')}
            />
          </div>

          <Typography variant={'body1'} className={classes.colorTextPrimary}>
            {t('TEXT_NOTE')}
          </Typography>
        </Grid>
        <Grid
          className={classes.buttonRow}
          item
          xs={12}
          container
          justify={isMobile ? 'center' : 'flex-end'}
          spacing={2}
          wrap="wrap-reverse"
        >
          <Grid item xs={10} md="auto">
            <Button
              color={'secondary'}
              variant={'outlined'}
              fullWidth
              onClick={onPrevious}
            >
              {t('CLOSE')}
            </Button>
          </Grid>
          <Grid item xs={10} md="auto">
            <Button
              className={classes.activateButton}
              onClick={form.submit(({ code }) => onSubmit(code, handleInvalid))}
              fullWidth
            >
              {t('ACTIVATE')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
