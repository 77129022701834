import { Dispatch, SetStateAction } from 'react';

import { PaginationProps } from '../components/pagination/Pagination';
import { AccountType } from '../components/payment-form/PaymentForm.types';
import { PaymentOption } from '../hooks/useAccountSummary';
import { getFromSessionStorage } from '../util/storage-utils';

export interface AccountState {
  [x: string]: any;
  accountHasNotSynced?: boolean;
  accountNumber: string;
  accounts: AccountType[];
  amountDue: string;
  balanceDueDate: string;
  bankName: string;
  checkingAccount: string;
  confirmationNumber: string;
  dueDate: string;
  email: string;
  encryptedAccountNumber: string;
  errors: [];
  fullName: string;
  hasNoAccount?: boolean;
  hasPeakTimeRebateEnrollment?: boolean;
  isPendingDisconnect: boolean;
  lastPaymentAmount: string;
  lastPaymentDate: string;
  paymentPlanType: 'Unknown' | 'RegularPay' | 'EqualPay';
  PDF: string;
  pendingStartService?: boolean;
  pendingStartServiceDate?: string;
  pendingStartServiceDueDate: string;
  preferredDueDate?: string;
  routingNumber: string;
  serviceAddress: string;
  serviceStartDate: string;
  totalBalance: string;
  totalBalanceDate: string;
  usageComparedToPreviousMonth: string;
  usageComparedToPreviousMonthMeter: number;
  multipleAccountHeaders: {
    labels: { primary?: string; secondary?: string }[];
  }[];
  multipleManageAccountHeaders: {
    labels: { primary?: string; secondary?: string }[];
  }[];
  showAccountDetail: boolean;
  toggleShowAccountDetail: Dispatch<SetStateAction<any>>;
  menuItems: any[];
  sortByItems: any[];
  onSearchChange: (event: {
    persist: () => void;
    target: { value: string };
  }) => void;
  onSortByChange: (event: { target: { value: string } }) => void;
  paginationSettings: PaginationProps;
  rowData: AccountType[];
  isSelectAll: boolean;
  toggleSelectAll: Dispatch<SetStateAction<any>>;
  setRowData: Dispatch<SetStateAction<any>>;
  onCheckboxChange: (
    event: { target: { name: string } },
    value: boolean,
  ) => any;
  isPayButtonDisabled: boolean;
  handleSelectAll: () => void;
  setState: Dispatch<SetStateAction<any>>;
  paymentOptions: PaymentOption[];
  primaryAccountNumber: string;
  selectedAccount?: number | null;
}

const initialState: AccountState = (typeof window !== 'undefined' &&
  getFromSessionStorage('account') &&
  JSON.parse(sessionStorage.account)) || {
  accountHasNotSynced: false,
  accountNumber: '7603390000',
  accounts: [
    {
      accountNumber: '7603390000',
      active: true,
      amountDue: '$109.00',
      checked: false,
      dueDate: new Date().toDateString().substring(4),
      fullName: 'Jonathan Smith',
      nickname: 'Home',
      pastDue: new Date(new Date().toDateString().substring(4)) < new Date(),
      serviceAddress: '12902 SW Hillside, Terrace Unit 209',
    },
    {
      accountNumber: '1212122020',
      active: false,
      amountDue: '50.00',
      checked: false,
      dueDate: 'May 5 2020',
      fullName: 'John Doe',
      nickname: 'Business',
      pastDue: false,
      serviceAddress: '100 Main St',
    },
  ],
  alerts: [
    {
      description: 'Payment received',
      enabled: true,
    },
    {
      description: 'Payment reminder',
      enabled: true,
    },
    {
      description: 'Weekly use',
      enabled: false,
    },
    {
      description: 'Past due alert',
      enabled: true,
    },
    {
      description: 'Disconnect notice',
      enabled: true,
    },
    {
      description: 'Outages',
      enabled: true,
    },
    {
      description: 'Bill may exceeed',
      enabled: false,
    },
  ],
  amountDue: '$109.00',
  balanceDueDate: new Date().toDateString().substring(4),
  bankName: 'Bank of America',
  checkingAccount: '1234567890',
  confirmationNumber: '109283747',
  currentBillingPeriod: {
    averageTemperature: 45,
    date: '2020-03-24T02:29:02.598Z',
    totalCost: 0,
    totalKwh: 1360,
  },
  dueDate: new Date().toDateString().substring(4),
  email: 'pge-demo@test.com',
  encryptedAccountNumber: 'eHemD1ugOMEgJt3ZOtKjpzR3NybQyuvITorG6IHfDGs=',
  energyTrackerLink: '',
  energyUsage: {},
  enrollments: [
    {
      descriptions: [
        {
          description: 'Peak Time Rebates',
          link: '',
        },
        {
          description: 'View Rebate History',
          link: '',
        },
      ],
      enrolled: true,
    },
    {
      descriptions: [
        {
          description: 'Time of Use',
          link: '',
        },
      ],
      enrolled: false,
    },
    {
      descriptions: [
        {
          description: 'Renewable Power',
          link: '',
        },
      ],
      enrolled: true,
    },
  ],
  errors: [],
  fullName: 'Jonathan Smith',
  hasNoAccount: false,
  hasPeakTimeRebateEnrollment: true,
  isPendingDisconnect: false,
  lastPaymentAmount: '$90.00',
  lastPaymentDate: 'Dec 10th 2019',
  monthlyUsage: [
    {
      monthName: 'Jan',
      totalKwh: 30,
      year: 2019,
    },
    {
      monthName: 'Feb',
      totalKwh: 36,
      year: 2019,
    },
    {
      monthName: 'Mar',
      totalKwh: 32,
      year: 2019,
    },
    {
      monthName: 'Apr',
      totalKwh: 40,
      year: 2019,
    },
    {
      monthName: 'May',
      totalKwh: 45,
      year: 2019,
    },
    {
      monthName: 'Jun',
      totalKwh: 33,
      year: 2019,
    },
    {
      monthName: 'Jul',
      totalKwh: 43,
      year: 2019,
    },
    {
      monthName: 'Aug',
      totalKwh: 44,
      year: 2019,
    },
    {
      monthName: 'Oct',
      totalKwh: 34,
      year: 2019,
    },
    {
      monthName: 'Sept',
      totalKwh: 33,
      year: 2019,
    },
    {
      monthName: 'Oct',
      totalKwh: 36,
      year: 2019,
    },
    {
      monthName: 'Nov',
      totalKwh: 36,
      year: 2019,
    },
    {
      monthName: 'Dec',
      totalKwh: 40,
      year: 2019,
    },
    {
      monthName: 'Jan',
      totalKwh: 30,
      year: 2020,
    },
    {
      monthName: 'Feb',
      totalKwh: 31,
      year: 2020,
    },
    {
      monthName: 'Mar',
      totalKwh: 42,
      year: 2020,
    },
  ],
  paginationSettings: {
    count: 1,
    page: 1,
    pageSize: 5,
    pageSizes: [5, 10, 25],
    visibleOnSinglePage: true,
  },
  paymentOptions: [
    {
      active: false,
      link: '',
      linkText: 'Enroll',
      title: 'Auto Pay',
    },
    {
      active: true,
      link: '',
      linkText: 'Change',
      title: 'Paperless Bill',
    },
    {
      active: true,
      link: '',
      linkText: 'Change',
      title: 'Due date:',
    },
    {
      active: true,
      link: '',
      linkText: 'Change',
      title: 'Equal Pay',
    },
  ],
  paymentPlanType: 'EqualPay',
  PDF: '1123112323233232323232323232323',
  peakTimeRebateWidgetLink: '',
  pendingStartService: false,
  pendingStartServiceDate: 'Apr. 24, 2020',
  pendingStartServiceDueDate: '20th of each month',
  preferredDueDate: new Date().toDateString().substring(4),
  previousBillingPeriod: {
    averageTemperature: 42,
    date: '2020-03-24T02:29:02.598Z',
    totalCost: 0,
    totalKwh: 1646,
  },
  primaryAccountNumber: '7603390000',
  routingNumber: '022300173',
  selectedAccount: null,
  serviceAddress: '12902 SW Hillside, Terrace Unit 209',
  serviceStartDate: 'Dec 1 2019',
  showEnergyTracker: true,
  totalBalance: '$110.00',
  totalBalanceDate: new Date().toDateString().substring(4),
};

export default initialState;
