import { useState } from 'react';
import { useDirectAccessUsageSubmit } from './useDirectAccessUsageSubmit';

export type ContactInfoModel = {
  companyName?: string;
  fullName?: string;
  title?: string;
  emailAddress?: string;
  phoneNumber?: string;
};

export enum HistoricalUsageAuthType {
  RELEASE = 'RELEASE',
  REVOKE = 'REVOKE',
}

export type DirectAccessHistoricalUsageFormState = {
  businesses: { name: string; isSelected: boolean; groupId: string }[];
  authType: HistoricalUsageAuthType;
  isSamePersonAuthorizing: boolean;
  companyName?: string;
  contactFullName?: string;
  contactTitle?: string;
  contactEmail?: string;
  contactPhoneNumber?: string;
  authFullName?: string;
  authTitle?: string;
  authEmail?: string;
  authPhoneNumber?: string;
};

export type DirectAccessHistoricalUsageModel = {
  businesses: { name: string; isSelected: boolean; groupId: string }[];
  authType: HistoricalUsageAuthType;
  isSamePersonAuthorizing: boolean;
  contactInfo?: ContactInfoModel;
  authInfo?: ContactInfoModel;
};

export default () => {
  const [directAccessHistoricalUsageState, setDirectAccessHistoricalUsageState] = useState<
    DirectAccessHistoricalUsageModel
  >({
    businesses: [],
    authType: HistoricalUsageAuthType.RELEASE,
    isSamePersonAuthorizing: true,
    contactInfo: {},
    authInfo: {},
  });

  const { submit } = useDirectAccessUsageSubmit();

  const onConfirm = async (personId: string | undefined) => {
    const selectedBusinessNames = directAccessHistoricalUsageState?.businesses.filter((business) => business.isSelected);
    const directAccessRequest = {
      authorizationContactInfo: directAccessHistoricalUsageState?.authInfo!,
      businessNames: selectedBusinessNames.map(business => {
        return ({
          businessName: business.name,
          perId: personId
        })
      })!,
      contactInformation: directAccessHistoricalUsageState?.contactInfo!,
      isReleaseAuthorization: directAccessHistoricalUsageState?.authType === HistoricalUsageAuthType.RELEASE,
    };
    const result = await submit(directAccessRequest);
    return result;
  };

  return {
    directAccessHistoricalUsageState,
    setDirectAccessHistoricalUsageState,
    onConfirm,
  };
};
