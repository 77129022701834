import gql from 'not-graphql-tag';

export const GET_PROGRAMS_ENROLLMENT_DETAILS = gql`
  query GetProgramsEnrollmentStatusDetails(
    $params: ProgramsEnrollmentStatusDetailsParams!
  ) {
    getProgramsEnrollmentStatusDetails(params: $params) {
      energyShifting {
        programName
        isEnrolled
      }
      renewables {
        programName
        isEnrolled
      }
      onBillFlexLoadEarnings
      ytdFlexLoadEarnings
    }
  }
`;
