import { Grid } from '@material-ui/core';

import React from 'react';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import { PostEnrollmentEnrollInPrograms } from '../ssm.types';
import PeakTimeRebate from './EnrollInPrograms/PeakTimeRebates';

export const SSMEnrollInPrograms = ({
  formState,
}: WizardFormProps<PostEnrollmentEnrollInPrograms>) => {
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container spacing={2}>
        <Grid item container>
          <PeakTimeRebate />
        </Grid>
      </Grid>
    </Grid>
  );
};
