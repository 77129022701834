import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import Divider from '@material-ui/core/Divider';

import { CreateCustomGroupHook } from '../../hooks/useCreateCustomGroup';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';
import Button from '../buttons';
import TextLink from '../text-link';
import TextField from '../text-field';

import AccountGroupDropdown from '../account-group-dropdown';
import SortFilterPageHeader from '../account-summary/sort-filter-page-header/SortFilterPageHeader';
import useSelectedGroupId from '../../hooks/useSelectedGroupId';
import useAccountDetailList from '../../hooks/useAccountDetailList';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import { getAccountsList } from '../../queries/manageAccountGroups.query';
import AccountListReducer from '../account-summary/multi-accounts/accountListReducer';
import Backdrop from '../backdrop';
import useAccountMulticheckState from '../../hooks/useAccountMulticheckState';
import {
  AccountDetail,
  AccountStatusType,
} from '../../__generated__/pge-types';
import useAccountListItemMenuItems from '../account-summary/multi-accounts/useAccountListItemMenuItems';
import SimpleAccountListItem from '../account-summary/multi-accounts/SimpleAccountListItem';
import useAccountListHeader from '../account-summary/multi-accounts/useAccountListHeader';
import { NickNameAddressPayload } from '../nickname-change-modal/types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    buttonRow: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
      },
    },
    buttonContainer: {
      display: 'flex',
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
        justifyContent: 'center',
      },
    },
    button: {
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },
    },
  }),
);

interface CreateCustomGroupProps extends CreateCustomGroupHook {
  path: string;
}

const ManageAccounts: FunctionComponent<CreateCustomGroupProps> = props => {
  const { t } = useTranslation();
  const { selectedGroupId } = useSelectedGroupId();
  const {
    onAccountSearch,
    onAccountSort,
    onAccountPageChange,
    onResetParams,
    accountListParams,
  } = useAccountListHeader();
  const {
    loading,
    accounts,
    totalCount = 0,
    writeAccountInfoCache,
  } = useAccountDetailList(getAccountsList, selectedGroupId, {
    accountListParams: {
      ...accountListParams,
      accountStatus: AccountStatusType.All,
    },
  });

  const accountsListCacheUpdate = (payload: NickNameAddressPayload) => {
    const idx = accounts.findIndex(a =>
      a.premiseInfo?.find(
        p => p?.encryptedPremiseId === payload.encryptedPremiseId,
      ),
    );
    accounts[idx]?.premiseInfo?.some(premise => {
      if (premise?.encryptedPremiseId === payload.encryptedPremiseId) {
        return premise?.saDetails?.some(serviceAddress => {
          if (serviceAddress?.encryptedSAId === payload.encryptedSaId) {
            serviceAddress.nickName = payload.nickName;
            return true;
          }
          return false;
        });
      }
      return false;
    });
    writeAccountInfoCache({ accounts: accounts });
  };

  const multicheckState = useAccountMulticheckState({
    query: accountListParams.query,
    allowMultiGroup: true,
    allowInactive: true,
  });

  const classes = useStyles();
  const childProps = props;
  const {
    groupPropertiesProps,
    isCustomGroupAddButtonDisable,
    addCustomGroup,
  } = childProps;

  const { onViewDetails, makeMenuItems } = useAccountListItemMenuItems({
    accountsListCacheUpdate,
  });

  return (
    <>
      <Grid item xs={12} md={11} style={{ marginTop: '1em' }}>
        <Paper>
          <Card>
            <CardContent style={{ margin: '1em' }}>
              <Grid item xs={12}>
                <Typography variant={'body1'}>
                  {t('CREATE_CUSTOM_GROUP_MSG')}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <TextField
                  name={'groupName'}
                  label={t('CREATE_CUSTOM_GROUPNAME')}
                  onChange={groupPropertiesProps.handleChange}
                  onBlur={groupPropertiesProps.onBlur}
                  style={{ width: '100%', margin: '2em 0 2em 0' }}
                  error={!!groupPropertiesProps?.groupName?.errorMessage}
                  helperText={groupPropertiesProps?.groupName?.errorMessage}
                  defaultValue={groupPropertiesProps?.groupName?.value}
                  inputProps={{ minLength: 1, maxLength: 50 }}
                />
              </Grid>

              <Divider style={{ margin: '1.5em 0' }} />

              <Grid item md={12}>
                <Typography>{t('MANAGE_ACCOUNT_GROUPS_LABEL')}</Typography>
              </Grid>

              <Grid item md={12}>
                <Box marginTop={2}>
                  <AccountGroupDropdown onSelect={onResetParams} />
                </Box>
                {loading && <Backdrop forceOpen />}
                {!loading && (
                  <SortFilterPageHeader
                    onAccountSearch={onAccountSearch}
                    onAccountSort={onAccountSort}
                    onAccountPageChange={onAccountPageChange}
                    accountListParams={accountListParams}
                    totalCount={totalCount}
                  />
                )}
              </Grid>

              {!loading && (
                <Grid item xs={12} style={{ marginTop: '1em' }}>
                  <Box marginTop={1}>
                    {(accounts || []).map((account: AccountDetail) => (
                      <SimpleAccountListItem
                        account={account}
                        multicheckState={multicheckState}
                        menuItems={makeMenuItems(account)}
                        onViewDetails={onViewDetails}
                      />
                    ))}
                  </Box>
                </Grid>
              )}

              <Grid item xs={12} className={classes.buttonRow}>
                <Grid item>
                  <TextLink plain to={ROUTES.MANAGE_ACCOUNTS_LANDING_SCREEN}>
                    {t('CANCEL')}
                  </TextLink>
                </Grid>
                <Grid item className={classes.buttonContainer}>
                  <Button
                    onClick={() =>
                      addCustomGroup(multicheckState.getSelectedAccounts())
                    }
                    disabled={
                      isCustomGroupAddButtonDisable ||
                      !multicheckState.getSelected().length
                    }
                    size={'large'}
                    className={classes.button}
                  >
                    {t('ADD')}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
    </>
  );
};

export default ManageAccounts;
