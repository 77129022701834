import React, { useEffect } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import {
  Box,
  Button,
  Divider,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';
import PathIcon from '../../../static/Path.svg';
import Payment from '../../../static/noun-payment.svg';
import Group from '../../../static/Group.svg';
import { SSMMoveServiceState } from '../../../hooks/useSSMMoveService';
import { useIsMobile } from '../../../util/style-utils';
import moment from 'moment';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import ROUTES from '../../../routes';
import { navigate } from 'gatsby';
import {
  OtherPeopleRelType,
  OtherPeopleResponse,
} from '../../../__generated__/pge-types';
import { displayPhoneNumberFormat } from '../../../util/format';
import useSelectedAccountParams, {
  AccountIdentifier,
} from '../../../hooks/useSelectedAccountParams';

type Props = {
  path?: string;
  ssmMoveServiceState: SSMMoveServiceState;
  pnpNumber: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  headingGrid: {
    maxWidth: '820px',
    margin: '25px auto',
    textAlign: 'center',
  },
  subHeading: {
    fontSize: '20px',
    lineHeight: '30px',
    textAlign: 'center',
    marginBottom: '40px',
  },
  costSummmaryBox: {
    padding: '15px',

    background: colors.riverBlue,
  },

  costSummmaryBoxText: {
    fontSize: '18px',
  },

  belowInfo: {
    color: colors.charcoal,
    fontSize: '18px',
    margin: '6px 0 10px',
  },

  summaryCard: {
    padding: '20px',
    background: colors.white,
    border: `1px solid ${colors.brightBlue}`,
    borderRadius: '13px',
    boxShadow: `0px 3px 6px ${colors.boxShadowColor}`,
    width: '100%',
    maxWidth: '820px',
    margin: '0 auto',
  },

  cardText: {
    fontSize: '16px',
    textAlign: 'center',
  },

  cardFirstline: {
    borderBottom: `1px solid ${colors.lightGray3}`,
    paddingBottom: '20px',
    marginBottom: '25px',
  },
  cardListingBox: {
    textAlign: 'left',
    position: 'relative',
  },
  linkContainer: {
    marginLeft: 'auto',
  },

  listingItem: {
    textAlign: 'left',
    fontSize: '14px',
    lineHeight: '17px',
    marginBottom: '17px',
    display: 'flex',
    flexDirection: 'row',
    '& span': {
      marginRihgt: '25px',
      maxWidth: '100px',
      width: '100%',
      display: 'inline-block',
      textAlign: 'left',
      fontWeight: '500',
    },
    '& strong': {
      wordBreak: 'break-all',
    },
  },

  cardInnerBox: {
    marginTop: '10px',
    marginLeft: '18px',
  },
  addresSection: {
    '& span': {
      maxWidth: '110px',
    },
  },
  cardInnerText: {
    fontSize: '14px',
    lineHeight: '17px',
    marginBottom: '10px',
  },

  topTest: {
    display: 'none',
  },
  completed: {
    color: `${colors.shoreGreen} !important`,
  },
  stepperIcon: {
    padding: '10px 0 20px',
  },
  cardListingBoxCenter: {
    borderBottom: `1px solid ${colors.lightGray3}`,
    marginBottom: '25px',
    position: 'relative',
  },
  divider: {
    height: '80px',
  },
  doneButton: {
    textAlign: 'center',
    marginTop: '50px',
    '& button': {
      minWidth: '181px',
      fontSize: '18px',
    },
  },
  account_Preferences: {
    background: colors.lightBlue1,
    boxShadow: `0 1px 3px ${colors.boxShadowColor}`,
    borderRadius: '13px',
    padding: '30px',
    width: '100%',
    maxWidth: '820px',
    margin: '40px auto 0',
  },
  accountlistingItem: {
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 'bold',
    padding: '9px 0 5px',
  },
  accountInfo: {
    color: colors.black,
    fontSize: '14px',
    lineHeight: '20px',
  },
  accountHeading: {
    fontSize: '28px',
    lineHeight: '34px',
    margin: '0 0 15px',
    textAlign: 'center',
  },
  accountSubHeading: {
    margin: '0 0 30px',
    textAlign: 'center',
    fontSize: '14px',
  },
  cardListingtext: {
    borderRight: `2px solid ${colors.white}`,
    paddingRight: '5px',
    height: 'calc(100% - 50px)',
  },
  mobileCardListing: {
    height: 'calc(100% - 50px)',
    borderRight: '0',
    paddingRight: '0',
    borderBottom: `2px solid ${colors.white}`,
    paddingBottom: '25px',
  },
  subHeadingGrid: {
    fontSize: '20px',
    lineHeight: '25px',
    marginTop: '20px',
  },
  email: {
    wordBreak: 'break-all',
  },
}));
export const SSMMoveServiceSuccess = (props: Props) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { ssmMoveServiceState, pnpNumber } = props;
  const { customer } = useAccountCustomer();
  const coCustomers = ssmMoveServiceState.ssmMoveTransferPeopleToService.filter(
    (user: OtherPeopleResponse) =>
      user.acctRelType === OtherPeopleRelType.Coapp,
  );

  const {
    selectedGroupId,
    encryptedAccountNumber,
    setSelectedAccountParams,
    selectedAccountParams,
  } = useSelectedAccountParams();

  const navigateBack = async (): Promise<void> => {
    if (!ssmMoveServiceState?.accountInfo?.accountNumber) {
      await navigate(ROUTES.SSM_MOVE_CHOOSE_ACCOUNT);
    }
  };

  const renderTransferServiceUsers = (userList: OtherPeopleResponse[]) => {
    return userList.map(user => {
      const { firstName, middleName, lastName } = user;
      return <div>{`${firstName} ${middleName} ${lastName}`}</div>;
    });
  };

  useEffect(() => {
    void navigateBack();
  }, []);

  const setSelectedAccount = () =>
    new Promise(resolve => {
      const accountInfoForPostEnrollment: AccountIdentifier = {
        ...selectedAccountParams?.selectedAccountParams,
        accountNumber: ssmMoveServiceState.accountInfo.accountNumber!,
        encryptedAccountNumber: encryptedAccountNumber as string,
        encryptedPersonId: customer?.encryptedPersonId,
      };

      setSelectedAccountParams(selectedGroupId!, accountInfoForPostEnrollment);
      resolve(true);
    });

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Grid item xs={12} className={classes.headingGrid}>
          <Typography variant={'h1'}>{t('WELCOME_TEXT')}</Typography>
          <Typography variant={'h2'} className={classes.subHeadingGrid}>
            {t('SEND_EMAIL_TEXT')} {customer?.email} {t('SOON')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.summaryCard}>
          <Box className={classes.cardFirstline}>
            <Box>
              <Stepper
                data-testid="desktop-progress-tracker"
                alternativeLabel
                style={{ background: 'transparent' }}
                className={classes.stepperIcon}
              >
                <Step completed={true}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        completed: classes.completed,
                      },
                    }}
                  />
                </Step>
              </Stepper>
              <Typography variant={'h2'} className={classes.cardText}>
                <strong>{t('SERVICE_SUMMARY')}</strong>
              </Typography>
            </Box>
          </Box>
          {!isMobile && (
            <Grid className={classes.cardListingBoxCenter}>
              <Box className={classes.cardListingBox}>
                <Grid container spacing={2}>
                  <Grid item sm={5} xs={12} direction="column">
                    <Typography
                      variant={'h2'}
                      className={classes.listingItem}
                      data-testid="moving-from"
                    >
                      <span>{t('MOVING_FROM')}:</span>
                      <strong data-testid="moving-from-value">
                        {ssmMoveServiceState.accountInfo?.premiseInfo &&
                        ssmMoveServiceState.accountInfo.premiseInfo.length > 0
                          ? ssmMoveServiceState.accountInfo.premiseInfo[0]
                              .addressLine1
                          : t('NA_LABEL')}
                      </strong>
                    </Typography>
                    <Typography
                      variant={'h2'}
                      className={classes.listingItem}
                      data-testid="stop-service-date"
                    >
                      <span>{t('STOPPING_SERVICE_ON')}:</span>{' '}
                      <strong
                        style={{ marginTop: 'auto' }}
                        data-testid="stop-service-date-value"
                      >
                        {moment(
                          ssmMoveServiceState?.ssmStopCurrentServiceDate?.date,
                        ).format('MM/DD/YYYY')}
                      </strong>
                    </Typography>
                  </Grid>
                  <Grid item sm={1} direction="column">
                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                      variant="middle"
                      flexItem
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} direction="column">
                    <Typography
                      variant={'h2'}
                      className={classes.listingItem}
                      data-testid="moving-to"
                    >
                      <span
                        style={{ maxWidth: `${isMobile ? '122px' : '110px'}` }}
                      >
                        {t('MOVING_TO')}:
                      </span>{' '}
                      <strong data-testid="moving-to-value">
                        {
                          ssmMoveServiceState?.addressAndDate?.address
                            ?.addressLine1
                        }
                      </strong>
                    </Typography>
                    <Typography
                      variant={'h2'}
                      className={classes.listingItem}
                      data-testid={'start-service-date'}
                    >
                      <span
                        style={{ maxWidth: `${isMobile ? '122px' : '110px'}` }}
                      >
                        {t('STARTING_SERVICE_ON')}:
                      </span>
                      <strong data-testid={'start-service-date-value'}>
                        {moment(
                          ssmMoveServiceState?.addressAndDate?.date,
                        ).format('MM/DD/YYYY')}
                      </strong>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
          <Box className={classes.cardListingBox}>
            <Grid container spacing={2}>
              <Grid item sm={6} xs={12} direction="column">
                <Typography
                  variant={'h2'}
                  className={classes.listingItem}
                  data-testid={'type'}
                >
                  <span style={{ maxWidth: `${isMobile ? '122px' : '100px'}` }}>
                    {t('TYPE')}:
                  </span>{' '}
                  <strong data-testid={'type-value'}>{t('MOVE')}</strong>
                </Typography>
                <Typography
                  variant={'h2'}
                  className={classes.listingItem}
                  data-testid={'for'}
                >
                  <span style={{ maxWidth: `${isMobile ? '122px' : '100px'}` }}>
                    {t('FOR')}:
                  </span>{' '}
                  <strong data-testid={'for-value'}>
                    {ssmMoveServiceState?.accountInfo?.mainCustomerName}{' '}
                  </strong>
                </Typography>
                <Typography
                  variant={'h2'}
                  className={classes.listingItem}
                  data-testid={'at'}
                >
                  <span style={{ maxWidth: `${isMobile ? '122px' : '100px'}` }}>
                    {' '}
                    {t('AT')}:
                  </span>{' '}
                  <strong data-testid={'at-value'}>
                    {ssmMoveServiceState?.addressAndDate?.address?.addressLine1}
                  </strong>
                </Typography>
                <Typography
                  variant={'h2'}
                  style={{ marginBottom: `${isMobile ? '1px' : '17px'}` }}
                  className={classes.listingItem}
                  data-testid={'on'}
                >
                  <span style={{ maxWidth: `${isMobile ? '122px' : '100px'}` }}>
                    {t('ON')}:
                  </span>{' '}
                  <strong data-testid={'on-value'}>
                    {' '}
                    {moment(ssmMoveServiceState?.addressAndDate?.date).format(
                      'MM/DD/YYYY',
                    )}
                  </strong>
                </Typography>
              </Grid>
              <Grid
                item
                sm={6}
                xs={12}
                direction="column"
                className={classes.addresSection}
                data-testid={'move-account-number'}
              >
                <Typography variant={'h2'} className={classes.listingItem}>
                  <span style={{ maxWidth: `${isMobile ? '122px' : ''}` }}>
                    {t('ACCOUNT_NUMBER')}:
                  </span>{' '}
                  <strong data-testid={'move-account-number-value'}>
                    {ssmMoveServiceState?.accountInfo?.accountNumber}
                  </strong>
                </Typography>
                <Typography
                  variant={'h2'}
                  className={classes.listingItem}
                  data-testid={'email'}
                >
                  <span
                    style={{ maxWidth: `${isMobile ? '122px' : ''}` }}
                    className={classes.email}
                  >
                    {t('EMAIL')}:
                  </span>
                  <strong data-testid={'email-value'}>{customer?.email}</strong>
                </Typography>
                <Typography
                  variant={'h2'}
                  className={classes.listingItem}
                  data-testid={'phone-number'}
                >
                  <span style={{ maxWidth: `${isMobile ? '122px' : ''}` }}>
                    {t('PHONE_NUMBER')}:
                  </span>{' '}
                  <strong data-testid={'phone-number-value'}>
                    {pnpNumber}
                  </strong>
                </Typography>
                <Typography
                  variant={'h2'}
                  className={classes.listingItem}
                  data-testid={'co-customer'}
                >
                  <span style={{ maxWidth: `${isMobile ? '122px' : ''}` }}>
                    {t('CO_CUSTOMER')}:
                  </span>
                  <strong data-testid={'co-customer-value'}>
                    {coCustomers?.length > 0 ? (
                      <>{renderTransferServiceUsers(coCustomers)}</>
                    ) : (
                      t('NA_LABEL')
                    )}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className={classes.account_Preferences}>
          <Typography variant={'h2'} className={classes.accountHeading}>
            {t('NEXT_REVIEW_PREFERENCES')}
          </Typography>

          <Typography className={classes.accountSubHeading}>
            {richT('NEXT_REVIEW_PREFERENCES_SUB_HEADING', {
              CALL_FOR_ASSISTANCE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
            })}
          </Typography>

          <Box className={classes.cardListingBox}>
            <Grid container spacing={2}>
              <Grid
                item
                sm={4}
                xs={12}
                direction="column"
                style={
                  isMobile ? { textAlign: 'center' } : { textAlign: 'left' }
                }
              >
                <Group />
                <Grid
                  className={
                    isMobile
                      ? classes.mobileCardListing
                      : classes.cardListingtext
                  }
                >
                  <Typography
                    variant={'h2'}
                    className={classes.accountlistingItem}
                  >
                    {t('YOUR_DUE_DATE')}
                  </Typography>
                  <Typography className={classes.accountInfo}>
                    {t('DUE_DATE_INFO_LINE')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                sm={4}
                xs={12}
                direction="column"
                style={
                  isMobile ? { textAlign: 'center' } : { textAlign: 'left' }
                }
              >
                <Payment />
                <Grid
                  className={
                    isMobile
                      ? classes.mobileCardListing
                      : classes.cardListingtext
                  }
                >
                  <Typography
                    variant={'h2'}
                    className={classes.accountlistingItem}
                  >
                    {t('SETUP_AUTO_PAY')}
                  </Typography>
                  <Typography className={classes.accountInfo}>
                    {t('AUTO_PAY_INFO_LINE')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                sm={4}
                xs={12}
                style={
                  isMobile ? { textAlign: 'center' } : { textAlign: 'left' }
                }
                direction="column"
              >
                <PathIcon />

                <Typography
                  variant={'h2'}
                  className={classes.accountlistingItem}
                >
                  {t('ACCOUNT_PREFERENCES')}
                </Typography>
                <Typography className={classes.accountInfo}>
                  {t('CHOOSE_BILLING_NOTIFICATION_PAYMENT_PREFERENCES')}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Grid className={classes.doneButton}>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={async () => {
                await setSelectedAccount();
                void navigate(ROUTES.SSM_POST_ENROLLMENT_PAYMENT_PREFERENCES);
              }}
            >
              {t('SETUP_PREFERENCES')}
            </Button>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
