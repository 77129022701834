import React, { useMemo } from 'react';

import { useTranslation } from '../../../hooks/useTranslation';
import LeftNavMenu from '../../left-menu-navigation';
import useAuthQuery from '../../../hooks/useAuthQuery';
import { getRooftopSolarQuery } from '../../rooftop-solar/rooftopSolar.query';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import { useProductsAndProgramsMenu } from '../../utility/account-left-nav-block/AccountLeftNavBlock';

const IQBDFormLeftMenu = () => {
  const { t } = useTranslation();
  const { accountParams } = useSelectedAccountParams();

  const { data: solarData } = useAuthQuery(getRooftopSolarQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function,no-empty-function
    onError: () => {},
    skip: !accountParams,
  });

  const isRooftopSolar = Boolean(
    solarData?.getAccountDetails?.[0]?.premiseInfo?.[0]?.rooftopSolar
      ?.applicationDetails?.length,
  );

  const data = useMemo(() => [useProductsAndProgramsMenu(isRooftopSolar, t)], [
    isRooftopSolar,
  ]);

  return (
    <LeftNavMenu leftNavMenuItems={data} selectedItem={location.pathname} />
  );
};

export default IQBDFormLeftMenu;
