import gql from 'not-graphql-tag';

const accountInfo = gql`
  fragment AccountInfo on AccountDetail {
    isDefault
    accountNumber
    mainCustomerName
    accountType
    premiseInfo {
      encryptedPremiseId
      addressLine1
      addressLine2
      city
      state
      postal
      saDetails {
        saStatus
      }
    }
  }
`;

export const getAccounts = gql`
  query getAccountInfo($params: AccountDetailListParams!) {
    getAccountDetailList(params: $params) {
      totalCount
      accounts {
        ...AccountInfo
      }
    }
  }
  ${accountInfo}
`;
