import {
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { ElectionWindowModel } from '../../hooks/useElectionWindow';
import { useTranslation } from '../../hooks/useTranslation';
import Pagination, { PaginationProps } from '../pagination/Pagination';
import { PageSize } from '../pagination/types';
import { WizardFormProps } from '../utility/wizard/wizard.types';
import PricingPlanSelector from './PricingPlanSelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '100%',
    },
    gridDivider: {
      margin: theme.spacing(2, 0),
    },
  }),
);

export const ElectionWindowSummary = ({
  formState,
  componentProps
}: WizardFormProps<ElectionWindowModel>) => {
  const { t } = useTranslation();
  const {
    changedAccounts,
    availablePlans
  } = componentProps;
  const pageSizes = [5, 10, 25];
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<PageSize>(10);
  const start = (page - 1) * Number(pageSize);
  const end = start + Number(pageSize);
  const classes = useStyles();

  const [summaryRecords, setSummaryRecords] = useState(
    changedAccounts.slice(start, end),
  );

  const onSummaryPageChange = (newPage: number, newPageSize: PageSize) => {
    if (newPage !== page) {
      setPage(newPage);
    }
    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
    }
    const newStart = (newPage - 1) * Number(newPageSize);
    const newEnd = newStart + Number(newPageSize);
    setSummaryRecords(changedAccounts.slice(newStart, newEnd));
  };

  const summaryPaginationProps: PaginationProps = {
    count: changedAccounts.length,
    pageSize,
    page,
    pageSizes,
    onPageChange: onSummaryPageChange,
  };

  return (
    <Grid container direction={'column'} spacing={4}>
      <Grid item>
        <Card>
          <CardContent>
            <Grid container direction={'column'} spacing={2}>
              <Grid item>
                <Typography variant={'h2'}>
                  {t('SUMMARY')}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant={'body1'} data-testid={'confirm-summary-txt'}>
                  {t('GENERIC_CONFIRM_SUMMARY')}
                </Typography>
              </Grid>
              {summaryRecords?.length ? (
                <Grid item>
                  {changedAccounts?.length > 5 && ( <Pagination {...summaryPaginationProps} /> )}
                  <PricingPlanSelector 
                    accounts={summaryRecords} 
                    editMode={false}
                    availablePlans={availablePlans}
                  />
                </Grid>
              ) : (
                <>
                  <Grid item>
                    <Typography variant={'body1'} data-testid={'no-changes-txt'}>
                      <strong>{t('OPEN_ENROLLMENT_CONFIRM_SUMMARY_NO_CHANGE_TEXT')}</strong>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant={'body1'}>
                      {t('OPEN_ENROLLMENT_CONFIRM_SUMMARY_NO_CHANGE_NOTE')}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
