import React, { FunctionComponent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PGEButton from '../buttons';

import { useTranslation } from '../../hooks/useTranslation';
import EqualPayUnavailable from './equal-pay-unavailable';
import AccountDropdown from '../account-dropdown';
import { Grid, Card, CardContent } from '@material-ui/core';
import routes from '../../routes';
import { navigate } from '@reach/router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      margin: theme.spacing(2, 0),
    },
    formBox: {
      padding: theme.spacing(1, 2),
      flex: 1,
    },
    formBoxContent: {
      display: 'flex',
      flexDirection: 'column',
      padding: 0,
    },
  }),
);

const EqualPay: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  // equalPayAvailable currently not available. Change to dynamic resolution when it becomes available.
  const equalPayAvailable: boolean = false;

  const onDone = () => {
    return navigate(routes.ACCOUNT);
  };

  return (
    <Grid container direction="column">
      <Card className={classes.formBox}>
        <CardContent className={classes.formBoxContent}>
          <Typography variant={'h6'} className={classes.header}>
            {t('EQUAL_PAY_SUBTITLE')}
          </Typography>
          <AccountDropdown showSelectedAccountOnly={true} />
          <Typography variant={'h4'} className={classes.header}>
            {t('EQUAL_PAY_PAYMENT_PLAN_OPTIONS')}
          </Typography>
          {equalPayAvailable ? null : (
            // insert whatever happens when equal pay is available here
            <EqualPayUnavailable />
          )}
        </CardContent>{' '}
        <Grid container direction="row" justify="flex-end" alignItems="center">
          <Box>
            <PGEButton onClick={onDone}>{t('OK')}</PGEButton>
          </Box>
        </Grid>
      </Card>
    </Grid>
  );
};

export default EqualPay;
