import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { navigate, Router } from '@reach/router';
import React, { useContext, useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import AccountDropdown from '../../../components/account-dropdown';
import AutoPayCancelDone from '../../../components/auto-pay/auto-pay-cancel-done';
import AutoPayConfig from '../../../components/auto-pay/auto-pay-config';
import AutoPayConfirm from '../../../components/auto-pay/auto-pay-confirm';
import AutoPayDone from '../../../components/auto-pay/auto-pay-done';
import AutoPay from '../../../components/auto-pay/auto-pay-start';
import AutoPayUpdatePaymentProfileDone from '../../../components/auto-pay/auto-pay-update-payment-profile-done';
import AutoPayUpdateDayDone from '../../../components/auto-pay/auto-pay-update-day-done';
import { useAutoPayService } from '../../../components/auto-pay/auto-pay.hooks';
import { AutoPayConfigData } from '../../../components/auto-pay/auto-pay.types';
import {
  billDueInfo,
  isPDDEligible,
} from '../../../components/auto-pay/auto-pay.utils';
import Backdrop from '../../../components/backdrop';
import Page2ColumnLayout from '../../../components/utility/page2-column-layout';
import ViewBillLeftPane from '../../../components/view-bill/view-bill-left-pane';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import useAuthQuery from '../../../hooks/useAuthQuery';
import { useSavedProfileList } from '../../../hooks/usePaymentus';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import { useTranslation } from '../../../hooks/useTranslation';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import { getAccountDetailsQuery } from '../../../queries/autoPay.query';
import ROUTES from '../../../routes';
import {
  AccountDetail,
  AutoPayErrorReason,
} from '../../../__generated__/pge-types';

export default (_: { path?: string }) => {
  const { t } = useTranslation();
  const title = t('AUTO_PAY');
  const notificationContext = useContext(NotificationsContext);

  const { encryptedAccountNumber, accountParams } = useSelectedAccountParams();
  const { customer } = useAccountCustomer();
  const [autoPayConfig, setAutoPayConfig] = useState<AutoPayConfigData | null>(
    null,
  );
  const [confirmationNumber, setConfirmationNumber] = useState<string | null>(
    null,
  );

  const [hasPreferredDueDateError, setHasPreferredDueDateErorr] = useState<
    boolean
  >(false);

  const {
    loading: accountLoading,
    data: accountData,
    refetch: refetchAccount,
  } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetailsQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
  });

  const accountDetails = accountData?.getAccountDetails?.[0];

  function reset() {
    setAutoPayConfig(null);
  }

  // If the account is changed, we need to reset to the first page,
  // and initial data
  useEffect(() => {
    reset();

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(ROUTES.AUTO_PAY, { replace: true });
  }, [accountDetails?.accountNumber]);

  const encryptedPersonId = accountParams?.encryptedPersonId;

  const {
    loading: profileListLoading,
    savedProfileList,
    refetch: refetchProfileList,
  } = useSavedProfileList(customer);

  const refetch = () => Promise.all([refetchProfileList(), refetchAccount()]);

  const { wrapWithLoader } = useWrapWithLoader();
  const autoPayService = useAutoPayService();

  if (accountDetails === undefined || accountLoading) {
    return <Backdrop forceOpen />;
  }

  function handleConfigSubmit(data: AutoPayConfigData) {
    setAutoPayConfig(data);
    return navigate(ROUTES.AUTO_PAY_CONFIRM);
  }

  const handleConfirmSubmit = wrapWithLoader(async function() {
    notificationContext.setState({ isOpen: false });
    setHasPreferredDueDateErorr(false);
    const selectedDay = autoPayConfig!.selectedDay || null;

    const [autoPayResult, dueDateResult] = await Promise.all([
      autoPayService.enrollAutoPay(accountDetails, autoPayConfig!),
      isPDDEligible(accountDetails.preferredDueDate) && selectedDay !== null
        ? autoPayService.updatePreferredDueDate(accountDetails, selectedDay)
        : undefined,
    ]);

    const autoPayData = autoPayResult.data;

    if (autoPayData?.enrollAutoPay?.confirmationId) {
      setConfirmationNumber(autoPayData?.enrollAutoPay?.confirmationId);
      await refetch();
    } else {
      return notificationContext.setState({
        isOpen: true,
        message: t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
        severity: 'error',
      });
    }

    if (
      isPDDEligible(accountDetails.preferredDueDate) &&
      selectedDay !== null
    ) {
      const addPreferredDueDate = dueDateResult?.data?.addPreferredDueDate;
      if (
        addPreferredDueDate?.preferredDueDate !== selectedDay &&
        addPreferredDueDate?.status !== 'NotChanged'
      ) {
        setHasPreferredDueDateErorr(true);
      }
    }

    return navigate(ROUTES.AUTO_PAY_DONE);
  });

  const handleConfirmUpdatePaymentProfile = wrapWithLoader(async () => {
    const { data } = await autoPayService.enrollAutoPay(
      accountDetails,
      autoPayConfig!,
    );
    if (data?.enrollAutoPay?.confirmationId) {
      setConfirmationNumber(data?.enrollAutoPay?.confirmationId);
      await refetch();
    } else {
      return notificationContext.setState({
        isOpen: true,
        message: t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
        severity: 'error',
      });
    }
    return navigate(ROUTES.AUTO_PAY_UPDATE_PAYMENT_PROFILE_DONE);
  });

  async function handleDoneSubmit() {
    await navigate(ROUTES.ACCOUNT);
    notificationContext.setState({ isOpen: false });
    reset();
  }

  const handleUpdatePaymentProfile = wrapWithLoader(
    async (config: AutoPayConfigData) => {
      setAutoPayConfig(config);
      return navigate(ROUTES.AUTO_PAY_UPDATE_PAYMENT_PROFILE_CONFIRM);
    },
  );

  const handleUpdateDay = wrapWithLoader(async (config: AutoPayConfigData) => {
    setAutoPayConfig(config);
    const result = await autoPayService.updatePreferredDueDate(
      accountDetails,
      config.selectedDay!,
    );
    if (result.errors) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw result.errors;
    }

    const addPreferredDueDate = result?.data?.addPreferredDueDate;
    if (
      addPreferredDueDate?.preferredDueDate !== config?.selectedDay &&
      addPreferredDueDate?.status !== 'NotChanged'
    ) {
      return notificationContext.setState({
        isOpen: true,
        message: t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
        severity: 'error',
      });
    }
    await refetch();
    return navigate(ROUTES.AUTO_PAY_UPDATE_DAY_DONE);
  });

  const handleCancel = wrapWithLoader(async () => {
    const result = await autoPayService.cancelAutoPay(
      accountDetails.accountNumber,
      accountDetails.encryptedPersonId,
    );
    if (result.errors) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw result.errors;
    }
    if (!result.data?.cancelAutoPay?.isSuccess) {
      throw new Error(
        result?.data?.cancelAutoPay?.errorReason || AutoPayErrorReason.Unknown,
      );
    }
    await refetch();
    return navigate(ROUTES.AUTO_PAY_CANCEL_DONE);
  });
  const PRINT_CONTAINER_ID = 'auto-pay-enroll-print-receipt';
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Page2ColumnLayout leftColumn={<ViewBillLeftPane />}>
        <Grid container direction="column" spacing={2} id={PRINT_CONTAINER_ID}>          
          <Grid item>
            <Typography variant={'h1'}>{title}</Typography>
          </Grid>          
          {!confirmationNumber && <Grid item>
            <AccountDropdown />
          </Grid>}
          {confirmationNumber && <Grid item>
            <AccountDropdown showSelectedAccountOnly/>
          </Grid>}
          <Grid item>
            <Router basepath="/">
              <AutoPay
                path={ROUTES.AUTO_PAY}
                savedProfileList={savedProfileList}
                account={accountDetails}
                onUpdatePaymentProfile={handleUpdatePaymentProfile}
                onUpdatePayDay={handleUpdateDay}
                onUpdateAutoPayConfig={async config =>
                  await setAutoPayConfig(config)
                }
                onCancel={handleCancel}
              />
              <AutoPayConfig
                path={ROUTES.AUTO_PAY_CONFIG}
                data={autoPayConfig}
                onSubmit={handleConfigSubmit}
                onUpdateAutoPayConfig={setAutoPayConfig}
                account={accountDetails}
                reset={reset}
              />
              <AutoPayConfirm
                path={ROUTES.AUTO_PAY_UPDATE_PAYMENT_PROFILE_CONFIRM}
                config={autoPayConfig!}
                onSubmit={handleConfirmUpdatePaymentProfile}
                fromBankUpdate
              />
              <AutoPayConfirm
                path={ROUTES.AUTO_PAY_CONFIRM}
                config={autoPayConfig!}
                onSubmit={handleConfirmSubmit}
              />
              <AutoPayDone
                confirmationNumber={confirmationNumber!}
                config={autoPayConfig!}
                path={ROUTES.AUTO_PAY_DONE}
                email={customer?.email!}
                isPendingDisconnect={Boolean(
                  accountDetails?.pendingDisconnect?.isPendingDisconnect,
                )}
                onSubmit={handleDoneSubmit}
                hasPreferredDueDateError={hasPreferredDueDateError}
                preferredDueDate={
                  accountDetails.preferredDueDate?.dueDate?.preferredDueDate ||
                  null
                }
                billDueInfo={billDueInfo(accountDetails?.currentCharges)}
                printContainerId={PRINT_CONTAINER_ID}
              />
              <AutoPayCancelDone
                path={ROUTES.AUTO_PAY_CANCEL_DONE}
                onSubmit={handleDoneSubmit}
                printContainerId={PRINT_CONTAINER_ID}
              />
              <AutoPayUpdatePaymentProfileDone
                path={ROUTES.AUTO_PAY_UPDATE_PAYMENT_PROFILE_DONE}
                confirmationNumber={confirmationNumber!}
                printContainerId={PRINT_CONTAINER_ID}
              />
              <AutoPayUpdateDayDone
                path={ROUTES.AUTO_PAY_UPDATE_DAY_DONE}
                preferredDueDate={
                  accountDetails?.preferredDueDate?.dueDate?.preferredDueDate ||
                  1
                }
                printContainerId={PRINT_CONTAINER_ID}
              />
            </Router>
          </Grid>
        </Grid>
      </Page2ColumnLayout>
    </>
  );
};
