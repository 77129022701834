/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Typography, useTheme } from '@material-ui/core';
import useContentMgmt, {
  PageTextContentType,
} from '../../../hooks/useContentMgmt';
import { GridItem } from '../../pge-plus-common/Grid';
import { FormState } from '../../../hooks/useFormState.types';
import { RebateEligibility } from '../evcharger.types';
import { useLocation } from '@reach/router';
import { useEffect } from 'react';
import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';
import { SaveAndComeBackLink } from '../../pge-plus-common/SaveAndComeBackLink';
import routes from '../../../routes';
import { navigate } from 'gatsby';
import { UpdateSectionEVRebatesMutationTypes } from '../../../hooks/pge-plus/useRebatesEligibility';
import { ApplicationSectionStatus } from '../../../__generated__/pgeplus-types';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { toCurrencyString } from '../../../util/format';
import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';

type RebateAmountConfirmationProps = {
  content: PageTextContentType;
  form: FormState<RebateEligibility>;
  path: string;
  setPageTitle: React.Dispatch<React.SetStateAction<string>>;
  handleSaveAndExit: () => void;
  updateSectionEVRebatesEligibility: UpdateSectionEVRebatesMutationTypes;
  updateFormValues: (values: RebateEligibility) => boolean;
};

export const RebateAmountConfirmation = (
  props: RebateAmountConfirmationProps,
) => {
  const {
    content,
    handleSaveAndExit,
    setPageTitle,
    form,
    updateFormValues,
    updateSectionEVRebatesEligibility,
  } = props;
  const { richText } = useContentMgmt();
  const { wrapWithLoader } = useWrapWithLoader();
  const theme = useTheme();
  const { state } = useLocation();
  // @ts-ignore
  const backRoute = state?.pathname;
  // @ts-ignore
  const higherRebate = state?.higherRebate;
  // @ts-ignore
  const chargerAmount = state?.chargerAmount;
  // @ts-ignore
  const panelAmount = state?.panelAmount;

  const {
    formState: {
      selectCharger: { suppliedByCustomer },
    },
  } = useEVChargerFormState();

  useEffect(() => {
    if (higherRebate) {
      setPageTitle(content.get('HIGHER_REBATE_TITLE') || '');
    } else {
      setPageTitle(content.get('STANDARD_REBATE_TITLE') || '');
    }
  }, []);

  return (
    <form
      onSubmit={wrapWithLoader(
        form.submit(async () => {
          updateFormValues(form.values);
          const {
            validationError,
            isEligible,
            amount,
          } = await updateSectionEVRebatesEligibility(
            form.values,
            false,
            ApplicationSectionStatus.Completed,
          );
          if (!validationError) {
            updateFormValues({ ...form.values, amount: amount?.toString() });
            if (isEligible) {
              void navigate(
                suppliedByCustomer
                  ? routes.PGE_PLUS_EV_CHARGERS_INSTALLATION
                  : routes.PGE_PLUS_EV_CHARGERS_SELECT_CHARGER,
              );
            } else {
              void navigate(
                routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY_INELIGIBLE,
              );
            }
          }
        }),
      )}
      noValidate
    >
      <Typography
        variant="body1"
        css={{
          fontSize: 20,
          [theme.breakpoints.down('xs')]: {
            fontSize: 16,
          },
        }}
      >
        {higherRebate
          ? richText(content.get('HIGHER_REBATES_MESSAGE'), {
              chargerAmount: `${toCurrencyString(chargerAmount, true, true)}`,
              panelAmount: `${toCurrencyString(panelAmount, true, true)}`,
            })
          : richText(content.get('STANDARD_REBATE'), {
              chargerAmount: `${toCurrencyString(chargerAmount, true, true)}`,
              panelAmount: `${toCurrencyString(panelAmount, true, true)}`,
            })}
      </Typography>
      <GridItem>
        <ProceedOrCancelButton
          back={backRoute || routes.PGE_PLUS_EV_CHARGERS_CONTACT_INFO}
        />
      </GridItem>
      <GridItem>
        <SaveAndComeBackLink
          content={content}
          onSaveAndExit={handleSaveAndExit}
        />
      </GridItem>
    </form>
  );
};
