/** @jsx jsx */
import { jsx } from '@emotion/core';

import React, { Fragment, useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button, Divider, FormHelperText, useTheme } from '@material-ui/core';
import PopoverToolTip from '../../../../pge-plus-common/PopoverToolTip';
import Modal from '../../../../pge-plus-common/Modal';
import colors from '../../../../../themes/main-colors';
import routes from '../../../../../routes';
import { ProceedOrCancelButton } from '../../../../pge-plus-common/StepperForm';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import useWrapWithLoader from '../../../../../hooks/useWrapWithLoading';
import { SaveAndComeBackLink } from '../../../../pge-plus-common/SaveAndComeBackLink';
import { ChargerType, SmartCharging } from '../rebateOnlyForm.types';
import { uploadModelNumberValidateFunction } from '../rebateOnlyForm.rules';
import useFormState from '../../../../../hooks/useFormState';
import FileUploaderWithOCR from '../../../../pge-plus-common/FileUploaderWithOCR';
import { Question } from '../../../../pge-plus-common/Typography';
import { useRebateOnlyFormState } from '../../../../../providers/RebateOnlyFormStateProvider';
import TextField from '../../../../text-field';
import useUpdateERSections from '../../../../../hooks/pge-plus/useUpdateERSections';
import useUtil from '../../../../need-more-time-to-pay/useUtil';
import { rebateInfoERFormStateAdapter } from '../rebateOnlyFormState.adapter';
import {
  ApplicationErSectionType,
  OcrFieldNames,
  SectionErRebateInfo,
} from '../../../../../__generated__/pgeplus-types';
import WelcomeToPGEPlusModal from '../../../../pge-plus-common/WelcomeToPGEPlusModal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageTitle: {
      fontSize: '16px !important',
      padding: '0px !important',
      color: `${colors.sparkBlue} !important`,
      fontWeight: 600,
      '&:hover': {
        backgroundColor: 'unset !important',
      },
    },
    errorMessage: {
      fontFamily: 'Untitled-Sans',
      fontSize: '1rem !important',
      letterSpacing: '1px',
    },
  }),
);

const UploadModelNumber: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { content, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_CONFIRM_YOUR_CHARGER,
  );

  const theme = useTheme();
  const classes = useStyles(props);
  const { wrapWithLoader } = useWrapWithLoader();
  const { content: fileUploaderText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_UPLOAD_PHOTOS,
  );

  const [openExamplePhotoModal, setOpenExamplePhotoModal] = useState<boolean>(
    false,
  );
  const [ocrExtractionStatus, setOcrExtractionStatus] = useState<
    string | undefined
  >();
  const {
    formState: { rebateInfo, application },
    getSectionData,
    updateFormState,
  } = useRebateOnlyFormState();
  const form = useFormState(
    rebateInfo.smartCharging!, //TODO: This should be taken from the global app state
    {
      validate: uploadModelNumberValidateFunction(),
      validationContext: {
        content,
      },
    },
  );
  const subText1 = {
    fontSize: 20,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  };

  const boldText = {
    fontWeight: 700,
  };

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      const response = await updateSectionRebateInfo({
        ...rebateInfoERFormStateAdapter().fromFormState(
          {
            ...rebateInfo,
            smartCharging: form.values,
          },
          application?.id!,
          sectionDataRebateInfo?.id,
        ),
        saveAndComeBack: true,
      });
      if (
        response?.errors ||
        response.data?.updateSectionERRebateInfo?.validation?.errors ||
        response.data?.updateSectionERRebateInfo?.section?.errors.length !== 0
      ) {
        throw new Error();
      } else {
        void navigate(routes.ACCOUNT);
      }
    } catch {
      setErrorNotification(true);
    }
  });

  const sectionDataRebateInfo: SectionErRebateInfo = getSectionData(
    ApplicationErSectionType.ErRebateInfo,
  );

  const backRoute = () => {
    if (
      sectionDataRebateInfo?.data?.rebateInfo?.existingEnrollments &&
      sectionDataRebateInfo?.data?.rebateInfo?.existingEnrollments?.evPulse &&
      sectionDataRebateInfo?.data?.rebateInfo?.existingEnrollments?.evPulse
        ?.length > 0
    ) {
      return routes.PGE_PLUS_REBATE_ONLY_VERIFY_EVPULSE_REBATE;
    } else if (
      sectionDataRebateInfo?.data?.rebateInfo?.existingEnrollments
        ?.smartCharging &&
      sectionDataRebateInfo?.data?.rebateInfo?.existingEnrollments
        ?.smartCharging?.length > 0
    ) {
      return routes.PGE_PLUS_REBATE_ONLY_VERIFY_CHARGER_REBATE;
    } else if (sectionDataRebateInfo?.data?.mustVerifyResidentialMeter) {
      return routes.PGE_PLUS_REBATE_ONLY_CONFIRM_YOUR_INFORMATION;
    } else {
      return routes.PGE_PLUS_REBATE_ONLY_HOME;
    }
  };

  const { updateSectionRebateInfo } = useUpdateERSections();

  const { setErrorNotification } = useUtil();
  const onSubmit = wrapWithLoader(async (data: SmartCharging) => {
    try {
      const response = await updateSectionRebateInfo(
        rebateInfoERFormStateAdapter().fromFormState(
          {
            ...rebateInfo,
            smartCharging: data,
            smartChargingEnrollmentConfirmation:
              sectionDataRebateInfo?.data.rebateInfo?.existingEnrollments
                ?.smartCharging &&
              sectionDataRebateInfo?.data.rebateInfo?.existingEnrollments
                ?.smartCharging.length > 0
                ? rebateInfo.smartChargingEnrollmentConfirmation
                : undefined,
            evPulseEnrollmentConfirmation:
              sectionDataRebateInfo?.data.rebateInfo?.existingEnrollments
                ?.evPulse &&
              sectionDataRebateInfo?.data.rebateInfo?.existingEnrollments
                ?.evPulse.length > 0
                ? rebateInfo.evPulseEnrollmentConfirmation
                : undefined,
          },
          application?.id!,
          sectionDataRebateInfo?.id,
        ),
      );
      // Save the response in a local var
      const result = response?.data?.updateSectionERRebateInfo;

      // Check for errors to show the red banner and block navigation
      if (response?.errors || result?.validation?.errors) {
        throw new Error();
      }

      //Update the app state with the user selected values
      if (result?.section.data) {
        const updatedValues = rebateInfoERFormStateAdapter().toFormState(
          result?.section.data,
        );
        updateFormState('rebateInfo', {
          ...updatedValues,
        });
      }
      if (result?.section?.errors.length === 0) {
        if (result?.section?.data?.chargerValidation?.isQualifiedProduct) {
          if (
            result?.section?.data?.chargerValidation?.product?.brand ===
            ChargerType.JUICE_BOX
          ) {
            void navigate(routes.PGE_PLUS_REBATE_ONLY_UPLOAD_JUICEBOXID_NUMBER);
          } else {
            void navigate(routes.PGE_PLUS_REBATE_ONLY_UPLOAD_SERIAL_NUMBER);
          }
        } else {
          void navigate(
            routes.PGE_PLUS_REBATE_ONLY_EVPULSE_SMART_CHARGING_ENROLLMENT,
          );
        }
      }
    } catch (e) {
      console.log('error', e);
      setErrorNotification(true);
    }
  });

  return (
    <form
      onSubmit={form.submit(values => onSubmit(values), console.log)}
      noValidate
    >
      <Box>
        <Typography css={{ ...subText1, ...boldText }}>
          {content.get('UPLOAD_PHOTO_CHARGER_MODEL_NUMBER')}
        </Typography>
      </Box>
      <Box>
        <PopoverToolTip
          baseContent={content.get('HOW_TO_FIND_YOUR_CHARGER_MODEL_NUMBER')}
          popoverContent={richText(
            content.get('HOW_TO_FIND_MODEL_NUMBER_TOOLTIP'),
          )}
        />
      </Box>
      <Box mt={2}>
        <Fragment>
          {!form.values?.modelNumberImage && (
            <Box mt={3}>
              <Button
                className={classes.imageTitle}
                onClick={() => setOpenExamplePhotoModal(true)}
              >
                {content.get('REUSABLE_VIEW_EXAMPLE_PHOTO')}
              </Button>
              <Modal
                open={openExamplePhotoModal}
                onClose={() => setOpenExamplePhotoModal(false)}
                title={content.get('MODEL_SAMPLE_PHOTO_TITLE')}
                showCloseIcon={true}
                modalStyles={{ width: 600 }}
              >
                {' '}
                <Box mt={3}>
                  <img
                    css={{
                      width: '100%',
                      maxHeight: '350px',
                      objectFit: 'cover',
                    }}
                    src={content.get('MODEL_SAMPLE_PHOTO_URL')}
                  />
                </Box>
              </Modal>
            </Box>
          )}
          <FileUploaderWithOCR
            placeholder={fileUploaderText.get('UPLOAD_A_PHOTO')}
            reuploadLabel={fileUploaderText.get('REUPLOAD_PHOTO')!}
            ocrType={OcrFieldNames.ModelNumber}
            onComplete={(key, extractedText, message, status) => {
              void form.setValue('modelNumberImage', { id: key });
              void form.setValue('modelNumber', extractedText);
              setOcrExtractionStatus(status);
            }}
            image={form.values?.modelNumberImage}
          />
          {form.errors?.modelNumberImage && (
            <FormHelperText className={classes.errorMessage} error>
              {form.errors?.modelNumberImage}
            </FormHelperText>
          )}

          {form.values?.modelNumberImage && (
            <Box
              css={{
                marginBottom: theme.spacing(1),
                '& .MuiFormControl-root': {
                  background: 'transparent',
                },
                '& .MuiInputBase-input': {
                  background: '#FFF',
                },
              }}
            >
              {ocrExtractionStatus && ocrExtractionStatus === 'fail' && (
                <FormHelperText
                  className={classes.errorMessage}
                  error
                  component="div"
                >
                  {fileUploaderText.get('OCR_FAIL_MESSAGE')}
                </FormHelperText>
              )}
              <Typography
                css={{
                  ...subText1,
                  ...boldText,
                  marginTop: theme.spacing(3),
                  marginBottom: theme.spacing(1),
                }}
              >
                {ocrExtractionStatus && ocrExtractionStatus === 'success'
                  ? content.get('REBATE_ONLY_EDIT_MODEL_HEADER')
                  : content.get('MODEL_NUMBER')}
              </Typography>
              <TextField
                css={{
                  background: 'transparent',
                  '.MuiInputBase-formControl': {
                    background: colors.white,
                  },
                  width: '100%',
                }}
                name={'modelNumber'}
                {...form.props('modelNumber')}
              />

              {ocrExtractionStatus && ocrExtractionStatus === 'success' && (
                <Typography css={{ marginTop: 8 }}>
                  {content.get('MODEL_OCR_SUCCESS_MESSAGE')}
                </Typography>
              )}
            </Box>
          )}

          <Divider css={{ color: colors.gray93, margin: '20px 0 0px 0' }} />
        </Fragment>
      </Box>

      <ProceedOrCancelButton
        back={backRoute()}
        backRouteState={
          sectionDataRebateInfo?.data?.rebateInfo?.existingEnrollments
            ?.evPulse &&
          sectionDataRebateInfo?.data?.rebateInfo?.existingEnrollments?.evPulse
            ?.length > 0
            ? {
                state:
                  sectionDataRebateInfo?.data?.rebateInfo?.existingEnrollments
                    .evPulse[0].vin,
              }
            : sectionDataRebateInfo?.data?.rebateInfo?.existingEnrollments
                ?.smartCharging &&
              sectionDataRebateInfo?.data?.rebateInfo?.existingEnrollments
                ?.smartCharging?.length > 0
            ? {
                state:
                  sectionDataRebateInfo?.data?.rebateInfo?.existingEnrollments
                    ?.smartCharging[0].serialNo,
              }
            : undefined
        }
        backLabel={content.get('BACK')}
        disable={
          !form.values?.modelNumber ||
          form.values?.modelNumberImage === undefined
        }
      />

      <Box mt={3}>
        <SaveAndComeBackLink
          content={content}
          onSaveAndExit={handleSaveAndExit}
        />
      </Box>
      <Box>
        {!rebateInfo?.smartCharging?.modelNumberImage && (
          <WelcomeToPGEPlusModal />
        )}
      </Box>
    </form>
  );
};

export default UploadModelNumber;
