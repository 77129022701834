import { Card, CardContent, Container, Grid, Paper } from '@material-ui/core';
import { RouteComponentProps, navigate } from '@reach/router';
import React from 'react';
import { useTranslation } from '../../../../../hooks/useTranslation';
import routes from '../../../../../routes';
import PGEButton from '../../../../buttons';
import Page2ColumnLayout from '../../../../utility/page2-column-layout';

type Props = {} & RouteComponentProps;

const CustomerAssistance = (props: Props) => {
  const { t, richT } = useTranslation();
  return (
    <Container maxWidth="lg">
      <Page2ColumnLayout>
        <Grid container direction="column" spacing={1}>
          <Grid container item justify="center">
            <span>{richT('SSM_ADD_CO_CUSTOMER_INELIGIBLE_ERROR')}</span>
          </Grid>
          <Grid item container direction="row" spacing={1} justify="center">
            <Grid item xs={10} md="auto">
              <PGEButton
                fullWidth
                onClick={async () => {
                  // eslint-disable-next-line @typescript-eslint/no-floating-promises
                  navigate(routes.ACCOUNT);
                }}
              >
                {t('OK')}
              </PGEButton>
            </Grid>
          </Grid>
        </Grid>
      </Page2ColumnLayout>
    </Container>
  );
};

export default CustomerAssistance;
