import React from 'react';
import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  useTheme,
} from '@material-ui/core';

import PopoverToolTip from '../pge-plus-common/PopoverToolTip';
import colors from '../../themes/main-colors';

interface RadioGroupWithQuestionProps {
  options: { label: string; value: string }[];
  selectedValue: string;
  onValueChange: (option: string) => void;
  question?: string;
  popoverContent?: string;
}

export default function RadioGroupWithQuestion({
  question,
  popoverContent,
  options,
  selectedValue,
  onValueChange,
}: RadioGroupWithQuestionProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onValueChange((event.target as HTMLInputElement).value);
  };
  const theme = useTheme();

  return (
    <>
      {question && (
        <Box display={'flex'}>
          <Typography
            variant="body1"
            css={{
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: '26px',
              marginTop: '20px',
              [theme.breakpoints.down('xs')]: {
                fontSize: 16,
              },
            }}
          >
            {question}
          </Typography>
          {popoverContent && <PopoverToolTip popoverContent={popoverContent} />}
        </Box>
      )}
      <FormControl css={{ marginTop: 24 }}>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedValue}
          onChange={handleChange}
          css={{
            '.MuiRadio-colorSecondary.Mui-checked': {
              color: colors.sparkBlue,
            },
          }}
        >
          {options.map(opt => (
            <FormControlLabel
              value={opt.value}
              control={
                <Radio
                  css={{
                    '& .MuiIconButton-label': {
                      position: 'relative',
                      zIndex: 0,
                    },
                    '&:not(.Mui-checked) .MuiIconButton-label:after': {
                      content: '""',
                      left: 4,
                      top: 4,
                      height: 16,
                      width: 16,
                      position: 'absolute',
                      backgroundColor: colors.white,
                      zIndex: -1,
                      borderRadius: 8,
                    },
                  }}
                />
              }
              label={opt.label}
              css={{
                color: `${colors.noirBlur} !important`,
              }}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
}
