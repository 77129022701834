import gql from 'not-graphql-tag';
import { ElectionWindowStatus } from '../__generated__/pge-types';
import useAuthQuery from './useAuthQuery';

const ELECTION_WINDOW_STATUS = gql`
  query getElectionWindowStatus {
    getElectionWindowStatus {
      electionWindow
      periodEffectiveDate
      periodEndDate
    }
  }
`;

export default function useElectionWindowStatus() {
  const { loading, error, data } = useAuthQuery<{
    getElectionWindowStatus: ElectionWindowStatus;
  }, Request>(
    ELECTION_WINDOW_STATUS,
    {
      skip: false,
    },
  );
  return {
    loading,
    error,
    data,
  };
}
