import { convertValidationRules } from '../../hooks/useFormState';
import { BaseValidationContext } from '../../hooks/useFormState.types';
import { GiveBackInfo } from './giveBack.types';

const validateOrganizationName = (
  organization: string,
  enrollment: string,
  errorMessage: string,
): string | null => {
  if (organization === 'None' && enrollment !== 'NotToDonate') {
    return errorMessage;
  }
  return null;
};

type AdditionalContext = {
  t: (key: string) => string;
};

export default convertValidationRules<GiveBackInfo, AdditionalContext>(
  context => {
    const { enrollment } = context.values;
    return {
      organization: (value, { t }) =>
        validateOrganizationName(
          value,
          enrollment,
          t('PTR_GIVE_BACK_CHOOSE_AN_ORGANIZATION_ERROR'),
        ),
      enrollment: null,
    };
  },
);
