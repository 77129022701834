import { createStyles, makeStyles, Theme } from '@material-ui/core';

import colors from '../../../themes/main-colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      justifyContent: 'center',
      padding: '2rem',
      backgroundColor: colors.riverBlue,
    },
    whiteBox: {
      backgroundColor: 'white',
      textAlign: 'center',
      padding: theme.spacing(2),
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    cancelVerificationLink: {
      paddingLeft: '1em',
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        paddingLeft: '0em',
      },
      '& a': {
        '&:active': {
          borderBottom: 'none',
        },
      },
    },
    marginTop1: {
      marginTop: '1em',
    },
    marginBotSpace: {
      marginBottom: '1.5em',
    },
    listGrid: {
      flexDirection: 'row',
      display: 'flex',
      margin: '1em 0',
    },
    paymentMethodContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: '1em',
      marginBottom: '1em',
      [theme.breakpoints.down('sm')]: {},
    },
    paymentMethodIcon: {
      width: '1.5em',
      height: 'auto',
      verticalAlign: 'inherit',
      marginRight: '0.2em',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
    paymentMethodMob: {
      display: 'flex',
      marginTop: '.5em',
      marginBottom: '2em',
    },
    marginLeftSpace: {
      marginLeft: '.2em',
      color: colors.noirBlur,
    },
    paddingSpace: {
      padding: '.5em',
    },
    gridDisplay: {
      display: 'flex',
    },
    cancel: {
      textDecoration: 'none',
    },
    buttonContainer: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
        flexDirection: 'column-reverse',
        width: '80%',
      },
    },
    fontFamilyCls: {
      fontFamily: 'Untitled-Sans',
    },
    noWrap: {
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'normal',
      },
    },
  }),
);
export default useStyles;
