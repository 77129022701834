import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';

export default makeStyles((theme: Theme) =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    groupCheckbox: {
      marginTop: -theme.spacing(1.5),
    },
    groupMenu: {
      padding: theme.spacing(0.5),
      marginTop: -theme.spacing(0.5),
    },
    groupName: {
      wordBreak: 'break-word',
    },
    accountSection: {
      flex: 1,
      marginLeft: '4px',
    },
    accountDisplay: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      '& > svg:last-child': {
        marginLeft: theme.spacing(1),
      },
    },
    primaryCheck: {
      marginTop: theme.spacing(0.5),
      display: 'flex',
      alignItems: 'center',
      '& > *:first-child': {
        marginRight: theme.spacing(0.5),
      },
    },
    flexStart: {
      alignItems: 'flex-start !important',
    },
    billingSection: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
      marginRight: theme.spacing(5),
      [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
        marginTop: '1em',
        marginRight: '0',
        marginLeft: '4px',
      },
    },
    menuButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginLeft: '5em',
      },
    },
    accountInfo: {
      display: 'flex',
      flexDirection: 'row',
    },
    accountInfoGrid: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
    },
    values: {
      textAlign: 'left',
      '& .MuiTypography-body1': {
        fontWeight: 'bold',
      },
    },
    labels: {
      textAlign: 'right',
      marginRight: theme.spacing(2),
    },
    checkboxContainer: {
      [theme.breakpoints.down('sm')]: {},
      [theme.breakpoints.up('md')]: {
        marginRight: '2em',
      },
    },
    inactive: {},
    colorTextPastDue: {
      color: `${theme.palette.error.main} !important`,
    },
    pastDueIndicator: {
      display: 'inline-block',
      marginLeft: theme.spacing(1),
    },
    list: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      '& > *': {
        marginBottom: theme.spacing(1),
        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    primaryAccountIndicator: {
      color: colors.white,
      background: colors.sparkBlue,
      marginRight: theme.spacing(1),
      borderRadius: '2px',
      [theme.breakpoints.down('sm')]: {
        marginBottom: '10px',
      },
    },
    mobilePrimaryAccountIndicator: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: '10px',
      },
    },
    displayAccountIdentifier: {
      fontWeight: 'bold',
      color: colors.sparkBlue,
      cursor: 'pointer',
      textDecorationLine: 'underline',
      textDecorationThickness: '2px',
    },
    unsetTextAlign: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'unset',
      },
    },
    dueDateWidth: {
      [theme.breakpoints.up('sm')]: {
        minWidth: '115px',
      },
    },
  }),
);
