import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import colors from '../../themes/main-colors';
import Grid from '@material-ui/core/Grid';
import RichText, { richTextStyles } from '../rich-text';

interface IProps {
  accordionItems: any;
  onIndexChange: (index: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(24),
      flexShrink: 0,
      lineHeight: theme.typography.pxToRem(28),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.pxToRem(20),
        lineHeight: theme.typography.pxToRem(24),
      },
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(16),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(20),
      marginTop: theme.spacing(1.25),
    },
    paymentMethodTitle: {
      color: colors.noirBlur,
      fontWeight: 'bold',
      marginBottom: '1em',
    },
    gridXs1: {
      flexGrow: 0,
      maxWidth: '5.333333%',
      flexBasis: '5.333333%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '12.333333%',
        flexBasis: '12.333333%',
      },
    },
    gridXs11: {
      flexGrow: 0,
      maxWidth: '94.666667%',
      flexBasis: '94.666667%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '87.666667%',
        flexBasis: '87.666667%',
      },
    },
    header: {
      fontSize: theme.spacing(4.5),
      lineHeight: theme.typography.pxToRem(40),
      fontWeight: theme.spacing(12.5),
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
      },
    },
    accordionWrapper: {
      '&.MuiAccordion-root': {
        marginTop: theme.spacing(2),
        position: 'static',
        borderRadius: '4px',
      },
    },
    accordionDetails: {
      '& img': {
        width: 'inherit',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      '& .RichText': {
        width: '100%',
      },
    },
  }),
);

const PaymentAccordion = (props: IProps): React.ReactElement => {
  const classes = useStyles(props);
  const richText = richTextStyles();
  const { accordionItems } = props;
  const initialItems: any = [...accordionItems];
  const [expands, setExpands] = React.useState<Array<boolean>>([
    ...initialItems?.fill(false),
  ]);
  // Handle Expand and Collapse of Accordion
  const handleChange = (index: number) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ): void => {
    // accordionItems[props.selectedIndex].selectedTab = false;
    const data: Array<boolean> = [...expands];
    data.map((i, indexval) => {
      if (indexval !== index) {
        data[indexval] = !isExpanded;
      }
    });
    data[index] = isExpanded;
    setExpands(data);
    props.onIndexChange(index);
  };

  const accordion = accordionItems?.map(
    (panel: any, index: number): React.ReactElement => {
      return (
        <Accordion
          data-testid={index}
          expanded={panel.selectedTab ? panel.selectedTab : expands[index]}
          onChange={panel.selectedTab ? undefined : handleChange(index)}
          key={index}
          className={classes.accordionWrapper}
          style={{
            boxShadow:
              '0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
          }}
        >
          <AccordionSummary
            data-testid={`panel${index}bh-header`}
            expandIcon={panel.selectedTab ? undefined : <ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            className={panel.tabTitleIcon ? 'icon' : ''}
          >
            <Grid container>
              {panel.tabTitleIcon && (
                <Grid item xs={1} className={classes.gridXs1}>
                  {panel?.tabTitleIcon?.file && (
                    <img
                      src={panel?.tabTitleIcon?.file?.url}
                      alt={panel?.tabTitle}
                    />
                  )}
                  {panel?.tabTitleIcon?.$$typeof && <>{panel?.tabTitleIcon}</>}
                </Grid>
              )}
              {panel.tabTitle && (
                <Grid item xs={panel?.tabTitleIcon ? 11 : 12}>
                  <Typography
                    className={classes.paymentMethodTitle}
                    component={'span'}
                    variant={'h6'}
                  >
                    {panel.tabTitle}
                  </Typography>
                  <Typography variant={'body2'}>{panel.tabSubTitle}</Typography>
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {panel.children}
          </AccordionDetails>
        </Accordion>
      );
    },
  );
  return <div>{accordion}</div>;
};

export default PaymentAccordion;
