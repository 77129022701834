import React, { FunctionComponent } from 'react';
import { navigate } from '@reach/router';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import ROUTES from '../../../routes';
import { useTranslation } from '../../../hooks/useTranslation';
import useStyles from '../styles';
import PGEButton from '../../buttons';
interface PeakTimeRebatesOptionNotAvailableProps {
  path?: string;
}

const PeakTimeRebatesIneligible: FunctionComponent<PeakTimeRebatesOptionNotAvailableProps> = props => {
  const { t, richT } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container spacing={3} style={{ marginTop: '1em' }}>
      <Grid item>
        <Paper component={'main'} style={{ justifyContent: 'flex-start' }}>
          <Card>
            <CardContent>
              <Grid item xs={12} style={{ marginBottom: '1em' }}>
                <Typography variant={'h2'}>
                  {t('OPTION_NOT_AVAILABLE')}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '1em' }}>
                <Typography variant={'h6'} component={'div'}>
                  {richT('INELIGIBLE_PTR')}
                </Typography>
              </Grid>

              <Grid
                container
                justify="flex-end"
                alignContent="center"
                spacing={2}
                alignItems="center"
                style={{ padding: '.5em .5em 0 0' }}
              >
                <PGEButton
                  onClick={async () => {
                    await navigate(ROUTES.ACCOUNT);
                  }}
                >
                  {t('RETURN_TO_ACCOUNT_SUMMARY')}
                </PGEButton>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PeakTimeRebatesIneligible;
