import React, { useEffect, useState, useContext } from 'react';
import useContentMgmt from '../../hooks/useContentMgmt';
import { useTranslation } from '../../hooks/useTranslation';
import { ScheduledOutageCard } from './ScheduledOutageCard';
import { ActiveOutageCard } from './ActiveOutageCard';
import useAuthQuery from '../../hooks/useAuthQuery';
import { getPspsZoneDetails } from '../account-dropdown/queries';
import {
  AccountDetail,
  AccountParams,
  OutageInfo,
} from '../../__generated__/pge-types';
import dayjs from 'dayjs';
import { getAccountDetailsForOutages } from '../account-summary/single-account/queries';
import { toDateAtTimeString } from '../../util/format';
import ROUTES from '../../routes';

type PublicSafetyPowerShutoffDetailsProps = {
  accountParams: AccountParams[];
  isAccountSelected: boolean;
};
type OutageAccountDetail = {
  premiseInfo: OutagePremiseInfo[];
};
type OutagePremiseInfo = {
  servicePointDetails: OutageServicePointDetails[];
};
type OutageServicePointDetails = {
  outageInfo: OutageInfo[];
};
type ContentfulPspsEntry = {
  fields: {
    zone: string;
    startDateAndTime: string;
  };
};

type MultipleZonesData = {
  isPlannedOutageIn4Hours: boolean;
  isPlannedOutageAfter4Hours: boolean;
  isDiffStartTimes: boolean;
};

type ContentfulFuturePspsEvent = {
  zoneMatchText: string;
  timeDiff: number;
};

export const PublicSafetyPowerShutoffDetails = ({
  accountParams,
  isAccountSelected,
}: PublicSafetyPowerShutoffDetailsProps) => {
  const { contentfulClient } = useContentMgmt();
  const [contentfulPspsEntries, setContentfulPspsEntries] = useState<any>();
  const { t, inlineRichT } = useTranslation();
  const { data: accountPspsZoneData } = useAuthQuery(getPspsZoneDetails, {
    variables: {
      params: {
        accountNumberList: [...(accountParams || [])],
      },
    },
    fetchPolicy: 'no-cache',
    skip: !accountParams,
  });

  const { data: outageData, loading: outageDataLoading } = useAuthQuery(
    getAccountDetailsForOutages,
    {
      variables: {
        params: {
          accountNumberList: [...(accountParams || [])],
        },
      },
      fetchPolicy: 'no-cache',
      skip: !accountParams,
    },
  );

  const getActiveOutageInfo = () => {
    if (!outageDataLoading && outageData && outageData.getAccountDetails) {
      const activeOutageData = outageData.getAccountDetails.map(
        (data: OutageAccountDetail) => {
          const premiseInfo = data.premiseInfo;
          const filteredPremiseInfo: OutagePremiseInfo[] =
            premiseInfo?.filter(
              (premise: OutagePremiseInfo) =>
                premise.servicePointDetails !== null,
            ) || [];
          const filteredData = filteredPremiseInfo.filter(
            (premiseData: OutagePremiseInfo) =>
              premiseData.servicePointDetails[0].outageInfo !== null,
          );

          const outageInfo =
            filteredData.length === 1 &&
            filteredData[0].servicePointDetails[0].outageInfo;
          return outageInfo;
        },
      );
      const activeOutageInfo =
        activeOutageData.filter(
          (outage: OutageInfo) =>
            outage.cause === 'Public Safety Power Shutoff',
        )[0] || {};
      return activeOutageInfo;
    } else {
      return {};
    }
  };
  const { cause, estimatedTimeOn } = getActiveOutageInfo();
  const getAccountAssociatedZones = () => {
    return accountPspsZoneData?.getAccountDetails
      ?.map((accountDetails: AccountDetail) => {
        if (
          accountDetails?.pspsOutage === null ||
          !accountDetails?.pspsOutage?.outageZone
        ) {
          return null;
        } else {
          return accountDetails?.pspsOutage?.outageZone[0]?.zone;
        }
      })
      .filter((zone: string | null) => zone !== null);
  };

  const getContentfulPspsEntries = async () => {
    try {
      const entries = await contentfulClient.getEntries({
        content_type: 'publicSafetyPowerShutdownEvent',
        'sys.id[ne]': '6E3jQiDqRPi3ztH8uRvkY2', // Exclude placeholder entry
      });
      setContentfulPspsEntries(entries);
    } catch (e) {
      console.error('error', e);
    }
  };

  useEffect(() => {
    void getContentfulPspsEntries();
  }, []);

  const getContentfulFuturePspsEvents = (): ContentfulFuturePspsEvent[] => {
    const currentDate = dayjs(new Date()).format('YYYY-MM-DD HH:mm');
    return contentfulPspsEntries?.items?.reduce(
      (entries: ContentfulFuturePspsEvent[], entry: ContentfulPspsEntry) => {
        const contentfulPspsStartDateTime = dayjs(
          entry.fields.startDateAndTime,
        );
        const timeDiff = contentfulPspsStartDateTime.diff(
          currentDate,
          'second',
        );
        if (timeDiff > 0) {
          entries.push({
            zoneMatchText: entry.fields?.zone,
            timeDiff: timeDiff,
          });
        }
        return entries;
      },
      [],
    );
  };

  const renderPspsOutageCard = (
    timeDiff: number,
    multipleZonesData?: MultipleZonesData,
  ) => {
    if (multipleZonesData) {
      return (
        !outageDataLoading && (
          <ScheduledOutageCard
            isPspsContentEnd={true}
            pspsHeader={
              multipleZonesData.isDiffStartTimes
                ? inlineRichT('PSPS_SCHEDULED_OUTAGE_HEADER_GENERIC')
                : multipleZonesData.isPlannedOutageIn4Hours
                ? inlineRichT(
                    'PSPS_SCHEDULED_OUTAGE_HEADER_LESS_THAN_4HOURS_MULTIPLE',
                  )
                : inlineRichT(
                    'PSPS_SCHEDULED_OUTAGE_HEADER_MORE_THAN_4HOURS_MULTIPLE',
                  )
            }
          />
        )
      );
    } else {
      if (timeDiff <= 14400) {
        if (!outageDataLoading && !isAccountSelected) {
          return (
            <ScheduledOutageCard
              isPspsContentEnd={true}
              pspsHeader={inlineRichT(
                'PSPS_SCHEDULED_OUTAGE_HEADER_LESS_THAN_4HOURS_MULTIPLE',
                {
                  PAGE_LINK: ROUTES.PUBLIC_SAFETY_POWER_SHUTOFF,
                },
              )}
            />
          );
        } else {
          return (
            !outageDataLoading && (
              <ScheduledOutageCard
                pspsHeader={inlineRichT(
                  'PSPS_SCHEDULED_OUTAGE_HEADER_LESS_THAN_4HOURS',
                )}
              />
            )
          );
        }
      } else {
        if (!outageDataLoading && !isAccountSelected) {
          return (
            <ScheduledOutageCard
              isPspsContentEnd={true}
              pspsHeader={inlineRichT(
                'PSPS_SCHEDULED_OUTAGE_HEADER_MORE_THAN_4HOURS_MULTIPLE',
                {
                  PAGE_LINK: ROUTES.PUBLIC_SAFETY_POWER_SHUTOFF,
                },
              )}
            />
          );
        } else {
          return (
            !outageDataLoading && (
              <ScheduledOutageCard
                pspsHeader={inlineRichT(
                  'PSPS_SCHEDULED_OUTAGE_HEADER_MORE_THAN_4HOURS',
                )}
              />
            )
          );
        }
      }
    }
  };

  const processMultipleZonesData = (
    matchedEvents: ContentfulFuturePspsEvent[],
  ) => {
    const isPlannedOutageIn4Hours =
      matchedEvents.filter(
        event => event.timeDiff >= 0 && event.timeDiff < 14400,
      ).length === matchedEvents.length;
    const isPlannedOutageAfter4Hours =
      matchedEvents.filter(event => event.timeDiff >= 14400).length ===
      matchedEvents.length;

    return {
      isPlannedOutageIn4Hours: isPlannedOutageIn4Hours,
      isPlannedOutageAfter4Hours: isPlannedOutageAfter4Hours,
      isDiffStartTimes: !isPlannedOutageIn4Hours && !isPlannedOutageAfter4Hours,
    };
  };

  const processPspsOutageCard = () => {
    const contentfulFuturePspsEvents = getContentfulFuturePspsEvents();
    const accountZones = [...new Set(getAccountAssociatedZones())] as string[];
    const matchedEvents: ContentfulFuturePspsEvent[] = [];
    if (
      contentfulFuturePspsEvents &&
      contentfulFuturePspsEvents?.length > 0 &&
      accountZones
    ) {
      for (const contentfulFuturePspsEvent of contentfulFuturePspsEvents) {
        for (const acountZone of accountZones) {
          if (
            acountZone.toLowerCase() ===
            contentfulFuturePspsEvent.zoneMatchText?.toLowerCase()
          ) {
            matchedEvents.push(contentfulFuturePspsEvent);
          }
        }
      }
    }
    const numberOfMatchedEvents = matchedEvents?.length;
    const lastUpdatedAt = toDateAtTimeString(new Date());
    if (isAccountSelected && cause === 'Public Safety Power Shutoff') {
      return (
        !outageDataLoading && (
          <ActiveOutageCard
            lastUpdatedAt={lastUpdatedAt}
            restorationTimeStamp={toDateAtTimeString(estimatedTimeOn)}
            pspsActiveCardWarningMessage={inlineRichT(
              'PSPS_ACTIVE_CARD_WARNING_MESSAGE_SINGLE_ACCOUNT',
            )}
          />
        )
      );
    } else {
      if (numberOfMatchedEvents > 0) {
        const isMultipleZones = numberOfMatchedEvents > 1;
        const timeDiff = matchedEvents[0].timeDiff;
        const multipleZoneData = processMultipleZonesData(matchedEvents);
        if (cause === 'Public Safety Power Shutoff' && !isAccountSelected) {
          return null;
        }
        return renderPspsOutageCard(
          timeDiff,
          isMultipleZones ? multipleZoneData : undefined,
        );
      }
      return null;
    }
  };

  return <>{processPspsOutageCard()}</>;
};
