import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { navigate, useLocation } from '@reach/router';
import React, { useEffect } from 'react';
import AccountDropdown from '../../components/account-dropdown';
import {
  OneTimePayConfigData,
  OneTimePayConfirmationResponse,
  SubmitOneTimePay,
} from '../../components/payment-form/oneTimePayment.types';
import PaymentConfirmationForm from '../../components/payment-form/payment-confirmation-form';
import Page2ColumnLayout from '../../components/utility/page2-column-layout';
import ViewBillLeftPane from '../../components/view-bill/view-bill-left-pane';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import Helmet from 'react-helmet';
import { AccountDetail } from '../../__generated__/pge-types';
import { DownloadAndPrint } from '../../components/download-print/DownloadAndPrint';

type LocationState = {
  oneTimePayConfirmationData?: OneTimePayConfirmationResponse;
  needsCacheReset?: boolean;
  oneTimePayConfig: OneTimePayConfigData;
  paymentMethodCapLimit: number;
};

const PRINT_CONTAINER_ID = 'one-time-print-receipt';

export default function PaymentConfirmationPage(_: { path?: string }) {
  const { t } = useTranslation();

  const location = useLocation();
  const state = (location.state || {}) as LocationState;

  useEffect(() => {
    if (!state.oneTimePayConfirmationData) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.PAYMENT);
    }
  }, [state.oneTimePayConfirmationData]);

  if (!state.oneTimePayConfirmationData) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>{t('PAY_BILL')}</title>
      </Helmet>
      <Page2ColumnLayout leftColumn={<ViewBillLeftPane />}>
        <Grid container direction="column" spacing={2} id={PRINT_CONTAINER_ID}>
            <Grid item>
              <Typography variant={'h1'}>{t('PAY_BILL')}</Typography>
            </Grid>
          <Grid item>
            <AccountDropdown showSelectedAccountOnly />
          </Grid>
          <Grid item>
            <PaymentConfirmationForm
              oneTimePayConfirmationData={state.oneTimePayConfirmationData}
              oneTimePayConfig={state.oneTimePayConfig}
              paymentMethodCapLimit={state.paymentMethodCapLimit}
              printContainerId={PRINT_CONTAINER_ID}
            />
          </Grid>
        </Grid>
      </Page2ColumnLayout>
    </>
  );
}
