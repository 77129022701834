import React, { FC } from 'react';
import {
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
  Slide,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Paper,
} from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import colors from '../../themes/main-colors';
import { PageTextContentType } from '../../hooks/useContentMgmt';
import { TransitionProps } from '@material-ui/core/transitions';
import Buttons from '../buttons';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  content: PageTextContentType;
  open: boolean;
  onClose: () => void;
  onDiscardApplication: () => void;
};

const RestartApplicationPopup: FC<Props> = ({
  open,
  content,
  onClose,
  onDiscardApplication,
}: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Dialog
      open={open}
      TransitionComponent={isMobile ? Transition : undefined}
      onClose={onClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="body"
      fullWidth={true}
      disableRestoreFocus={true}
      PaperProps={{
        style: {
          maxWidth: '530px',
          margin: 0,
        },
      }}
    >
      <Paper
        css={{
          [theme.breakpoints.down('xs')]: {
            maxWidth: '600px !important',
            width: '100%',
            position: 'fixed',
            bottom: 0,
            left: 0,
          },
        }}
      >
        <DialogTitle css={{ padding: '24px 24px 0' }}>
          <Grid
            container
            css={{
              justifyContent: 'end',
            }}
          >
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={onClose}
              css={{
                padding: 0,
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </DialogTitle>
        <DialogContent
          css={{
            [theme.breakpoints.down('xs')]: {
              padding: '0 24px 40px',
            },
          }}
        >
          <Grid container>
            <Grid item>
              <Typography variant={'h1'} css={{ marginBottom: '20px' }}>
                {content.get(
                  'INSTALLATION_CUSTOMER_WELCOME_BACK_RESTART_APP_TEXT',
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body1'}>
                {content.get(
                  'INSTALLATION_CUSTOMER_WELCOME_BACK_RESTART_APP_BODY_TEXT',
                )}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          css={{
            padding: '16px 24px 50px',
            [theme.breakpoints.down('xs')]: {
              backgroundColor: colors.cloudGrey,
              padding: '30px 24px',
              boxShadow: `0px -3px 6px ${colors.boxShadowColor}`,
              opacity: 1,
            },
          }}
        >
          <Grid
            container
            css={{
              justifyContent: 'center',
              columnGap: '20px',
            }}
          >
            <Grid item xs={5} md={5}>
              <Buttons
                type="button"
                fullWidth
                variant="outlined"
                onClick={onClose}
                color="secondary"
                css={{
                  fontSize: '18px',
                  borderRadius: '30px',
                }}
              >
                {content.get(
                  'INSTALLATION_CUSTOMER_WELCOME_BACK_RESTART_POPUP_NO',
                )}
              </Buttons>
            </Grid>
            <Grid item xs={5} md={5}>
              <Buttons
                type="submit"
                color={'primary'}
                fullWidth
                variant="contained"
                onClick={onDiscardApplication}
                css={{
                  fontSize: '18px',
                  borderRadius: '30px',
                }}
              >
                {content.get('YES_DISCARD_BUTTON')}
              </Buttons>
            </Grid>
          </Grid>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default RestartApplicationPopup;
