import React, { FC } from 'react';
import authService from '../../lib/authService';

interface Props {
  path?: string;
  sfEncryptedAccountNumber?: string;
  hostUrl: string;
}

const EvRebateForm: FC<Props> = props => {
  const { hostUrl, sfEncryptedAccountNumber } = props;

  const tokens = authService.tokensStorage.get();
  const token = tokens.idToken;

  const src = `${hostUrl}?accid=${encodeURIComponent(
    sfEncryptedAccountNumber!,
  )}&jwt=${encodeURIComponent(token?.token!)}`;

  return (
    <iframe
      src={src}
      frameBorder="0"
      style={{ width: '100%', height: '1200px', border: 'none' }}
      scrolling="yes"
    />
  );
};

export default EvRebateForm;
