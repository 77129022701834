import { Card, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import PGEButton from '../../buttons';
import useStyles from '../styles';
import ROUTES from '../../../routes';
import { navigate } from 'gatsby';
import { useIsMobile } from '../../../util/style-utils';
interface TimeOfDayOptionNotAvailableProps {
  path?: string;
}
const TimeOfDayIneligible: FunctionComponent<TimeOfDayOptionNotAvailableProps> = props => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();
  return (
    <Grid container spacing={3} style={{ marginTop: '1em' }}>
      <Grid item>
        <Paper component={'main'} style={{ justifyContent: 'flex-start' }}>
          <Card>
            <CardContent>
              <Grid item xs={12} style={{ marginBottom: '1em' }}>
                <Typography variant={'h2'}>
                  {t('OPTION_NOT_AVAILABLE')}
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ marginBottom: '1em' }}>
                <Typography variant={'body1'} component={'div'}>
                  {richT('TOD_INELIGIBLE')}
                </Typography>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justify={isMobile ? 'center' : 'flex-end'}
                spacing={1}
                wrap="wrap-reverse"
              >
                <Grid item xs={10} md="auto">
                  <PGEButton
                    fullWidth
                    onClick={async () => {
                      await navigate(ROUTES.ACCOUNT);
                    }}
                  >
                    <Typography variant={'h4'} noWrap>
                      {t('DONE')}
                    </Typography>
                  </PGEButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TimeOfDayIneligible;
