import { graphql } from 'gatsby';
import gql from 'not-graphql-tag';

export const getProgramsEnrollmentStatusDetailsQuery = gql`
  query GetProgramsEnrollmentStatusDetails(
    $params: ProgramsEnrollmentStatusDetailsParams!
  ) {
    getProgramsEnrollmentStatusDetails(params: $params) {
      energyShifting {
        isEligible
        isEnrolled
        programName
      }
      renewables {
        isEligible
        isEnrolled
        programName
      }
    }
  }
`;
