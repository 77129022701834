import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { navigate } from 'gatsby';
import useManageAccountGroups from '../../hooks/useManageAccountGroups';

import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';
import SortFilterPageHeader from '../account-summary/sort-filter-page-header/SortFilterPageHeader';
import Button from '../buttons';
import TextField from '../text-field';
import TextLink from '../text-link';
import Modal from '../modal';
import Box from '@material-ui/core/Box';
import Backdrop from '../backdrop';
import useSelectedGroupId from '../../hooks/useSelectedGroupId';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import SimpleAccountListItem from '../account-summary/multi-accounts/SimpleAccountListItem';
import useAccountListItemMenuItems from '../account-summary/multi-accounts/useAccountListItemMenuItems';
import { AccountDetail, PremiseInfo } from '../../__generated__/pge-types';
import { useIsMobile } from '../../util/style-utils';
import useAccountListHeader from '../account-summary/multi-accounts/useAccountListHeader';
import { NickNameAddressPayload } from '../nickname-change-modal/types';
import useAccountDetailList from '../../hooks/useAccountDetailList';
import { getAccountsList } from '../account-summary/multi-accounts/queries';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    buttonRow: {
      marginTop: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
      },
    },
    buttonContainer: {
      display: 'flex',
      marginLeft: theme.spacing(1),
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
        justifyContent: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
        justifyContent: 'center',
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: '0',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        width: '100%',
        justifyContent: 'left',
      },
    },
    button: {
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },
    },
    paper: {
      [theme.breakpoints.down('xs')]: {
        position: 'relative',
        width: 300,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #FFF',
        boxShadow: 'none',
        padding: theme.spacing(2),
        outline: 0,
      },
      [theme.breakpoints.up('md')]: {
        position: 'relative',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '0px solid #FFF',
        boxShadow: 'none',
        padding: theme.spacing(2, 4, 3),
        outline: 0,
      },
    },
  }),
);

type Props = {
  path?: string;
};

export default function ManageAccountGroups(_: Props) {
  const { richT, t } = useTranslation();
  const classes = useStyles();
  const { selectedGroupId } = useSelectedGroupId();

  const { loading: customerLoading, groups } = useAccountCustomer();
  const selectedGroup: any = groups.find(
    grp => grp.groupId === selectedGroupId,
  );
  const {
    multicheckState,
    state,
    toggleGroupNameModal,
    sortFilterProps,
    groupNameDialogFormProps,
    showRemoveAccountModal,
    isRemoveButtonDisabled,
    loading: hookLoading,
    handleRemove,
    toggleRemoveAccountModal,
    showGroupNameModal,
  } = useManageAccountGroups(selectedGroup);
  const loading = customerLoading || hookLoading;
  const isMobile = useIsMobile();
  const {
    accountListParams   
  } = useAccountListHeader();
  const {    
    writeAccountInfoCache,
  } = useAccountDetailList(getAccountsList, selectedGroupId, {
    accountListParams,
  });

  const accountsListCacheUpdate = (payload: NickNameAddressPayload) => {
    const idx = state.accounts.findIndex((a: AccountDetail) =>
      a.premiseInfo?.find(
        p => p?.encryptedPremiseId === payload.encryptedPremiseId,
      ),
    );
    state.accounts[idx]?.premiseInfo?.some((premise: PremiseInfo) => {
      if (premise?.encryptedPremiseId === payload.encryptedPremiseId) {
        return premise.saDetails?.some(serviceAddress => {
          if (serviceAddress?.encryptedSAId === payload.encryptedSaId) {
            serviceAddress.nickName = payload.nickName;
            return true;
          }
          return false;
        });
      }
      return false;
    });
    writeAccountInfoCache({ accounts: state.accounts });
  };
  const { onViewDetails, makeMenuItems } = useAccountListItemMenuItems({accountsListCacheUpdate});

  

  const modalCopy = () => {
    if (multicheckState.allSelected) {
      return richT('REMOVE_ALL_ACCOUNTS_MESSAGE', {
        GROUP_NAME: selectedGroup.groupName,
      });
    }
    if (multicheckState.getSelected().length > 1) {
      return richT('REMOVE_MULTIPLE_ACCOUNTS_MESSAGE', {
        NUMBER: String(multicheckState.getSelected().length),
      });
    }

    return richT('REMOVE_SINGLE_ACCOUNT_MESSAGE');
  };

  const modalHeader = () => {
    if (multicheckState.getSelected().length === 1) {
      return t('REMOVE_ACCOUNT');
    }
    return t('REMOVE_ACCOUNTS');
  };

  return (
    <>
      <Grid item xs={12} style={{ marginTop: '1em' }}>
        <Paper>
          <Card>
            <CardContent>
              <Grid item xs={12}>
                <Grid item style={{ marginBottom: '1em' }}>
                  <Typography
                    variant={'body2'}
                    style={{ display: 'inline-block', marginRight: '1em' }}
                  >
                    {t('CUSTOM_GROUP_NAME_LABEL')}
                  </Typography>
                  <Typography
                    variant={'body2'}
                    style={{ display: 'inline-block', marginRight: '1em' }}
                  >
                    {t(state.groupName)}
                  </Typography>
                  <TextLink
                    plain
                    to={ROUTES.MANAGE_ACCOUNT_GROUPS}
                    onClick={() => toggleGroupNameModal(true)}
                  >
                    {t('CHANGE_CUSTOM_NAME')}
                  </TextLink>
                </Grid>

                <Grid item style={{ marginBottom: '1em' }}>
                  <Typography
                    variant={'body2'}
                    style={{ display: 'inline-block', marginRight: '1em' }}
                  >
                    {t('NUMBER_OF_ACCOUNTS_LABEL')}
                  </Typography>
                  <Typography
                    variant={'body2'}
                    style={{ display: 'inline-block', marginRight: '1em' }}
                  >
                    {t(state.numberOfAccounts)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  className={classes.buttonContainer}
                  xs={12}
                  lg={3}
                  style={{ display: 'flex' }}
                >
                  <Button
                    onClick={() => navigate(ROUTES.ADD_GROUP_ACCOUNTS)}
                    size={'large'}
                    className={classes.button}
                    variant={'outlined'}
                    color={'secondary'}
                  >
                    {t('ADD_ACCOUNT')}
                  </Button>
                </Grid>
              </Grid>

              <Divider style={{ margin: '1.5em 0' }} />

              <Grid item style={{ marginBottom: '1em' }}>
                <Typography variant={'h2'}>
                  {t('ACCOUNTS_FOR_CUSTOM_GROUP') + state.groupName}
                </Typography>
              </Grid>

              {!loading && (
                <Grid item md={12}>
                  <SortFilterPageHeader
                    onAccountSearch={sortFilterProps.onAccountSearch}
                    onAccountSort={sortFilterProps.onAccountSort}
                    onAccountPageChange={sortFilterProps.onAccountPageChange}
                    accountListParams={sortFilterProps.accountListParams}
                    totalCount={sortFilterProps.totalCount}
                  />
                </Grid>
              )}

              {!loading && (
                <Grid item xs={12}>
                  {(state.accounts || []).map((account: AccountDetail) => (
                    <SimpleAccountListItem
                      account={account}
                      multicheckState={multicheckState}
                      menuItems={makeMenuItems(account)}
                      onViewDetails={onViewDetails}
                    />
                  ))}
                </Grid>
              )}
              {loading && <Backdrop forceOpen />}

              <Grid
                item
                xs={12}
                style={{
                  margin: '1em',
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                }}
              >
                <Grid item xs={'auto'}>
                  <Typography variant={'body1'}>
                    {t('REMOVE_ACCOUNTS_NOTE')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  margin: '1em',
                }}
              >
                <Grid
                  container
                  spacing={2}
                  justify={isMobile ? 'center' : 'flex-end'}
                  alignItems="center"
                  wrap="wrap-reverse"
                >
                  <Grid item xs={10} md="auto">
                    <Grid container justify="center">
                      <Grid item>
                        <TextLink
                          plain
                          to={ROUTES.MANAGE_ACCOUNTS_LANDING_SCREEN}
                        >
                          {t('CANCEL')}
                        </TextLink>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={10} md="auto">
                    <Button
                      onClick={() => toggleRemoveAccountModal(true)}
                      disabled={isRemoveButtonDisabled}
                      size={'large'}
                      fullWidth
                    >
                      <Hidden mdUp>{t('REMOVE')}</Hidden>
                      <Hidden smDown>{t('REMOVE_ACCOUNTS')}</Hidden>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
      {showGroupNameModal && (
        <Modal
          open={showGroupNameModal}
          showCloseIcon={true}
          onClose={() => toggleGroupNameModal(false)}
          title={
            <Typography variant="h2">
              {t('CHANGE_CUSTOM_GROUP_NAME')}
            </Typography>
          }
        >
          <Paper className={classes.paper}>
            <Typography variant={'body2'}>
              <Grid item xs={12}>
                <TextField
                  name={'groupName'}
                  label={t('CUSTOM_GROUP_NAME')}
                  onChange={groupNameDialogFormProps.handleChange}
                  onBlur={groupNameDialogFormProps.onBlur}
                  style={{ width: '100%', margin: '2em 0' }}
                  error={!!groupNameDialogFormProps?.groupName?.errorMessage}
                  helperText={groupNameDialogFormProps?.groupName?.errorMessage}
                  defaultValue={groupNameDialogFormProps?.groupName?.value}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
            </Typography>
            <Grid
              container
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingTop: 10,
              }}
            >
              <Box justifyContent="flex-end" display="flex">
                <Button
                  style={{ marginRight: 10 }}
                  className={classes.button}
                  color={'secondary'}
                  variant={'outlined'}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    groupNameDialogFormProps.handleCancel();
                    toggleGroupNameModal(false);
                  }}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={() => {
                    groupNameDialogFormProps.handleSubmit();
                    toggleGroupNameModal(false);
                  }}
                  disabled={
                    !groupNameDialogFormProps?.groupName?.value ||
                    groupNameDialogFormProps?.groupName?.value ===
                      state.groupName
                  }
                >
                  {t('UPDATE')}
                </Button>
              </Box>
            </Grid>
          </Paper>
        </Modal>
      )}

      {showRemoveAccountModal && (
        <Modal
          open={showRemoveAccountModal}
          showCloseIcon={true}
          onClose={() => toggleRemoveAccountModal(false)}
          title={<Typography variant="h2">{modalHeader()}</Typography>}
        >
          <Paper className={classes.paper}>
            <Typography variant={'body2'}>
              <Grid item xs={12}>
                <Typography>{modalCopy()}</Typography>
              </Grid>
            </Typography>
            <Grid
              container
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                paddingTop: 10,
              }}
            >
              <Box justifyContent="flex-end" display="flex">
                <Button
                  style={{ marginRight: 10 }}
                  className={classes.button}
                  color={'secondary'}
                  variant={'outlined'}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleRemoveAccountModal(false);
                  }}
                >
                  {t('CANCEL')}
                </Button>
                <Button
                  color={'primary'}
                  variant={'contained'}
                  onClick={handleRemove}
                >
                  {t('REMOVE')}
                </Button>
              </Box>
            </Grid>
          </Paper>
        </Modal>
      )}
      <Typography
        style={{ marginTop: '1em' }}
        color={'textPrimary'}
        variant={'body1'}
      >
        {t('CALL_FOR_ASSISTANCE') + ' '}{' '}
        <TextLink external plain to={`tel:${t('CALL_FOR_ASSISTANCE_NUMBER')}`}>
          {t('CALL_FOR_ASSISTANCE_NUMBER')}
        </TextLink>
      </Typography>
    </>
  );
}
