// import Box from '@material-ui/core/Box';
// import { navigate } from '@reach/router';
import React from 'react';

// import LeftNavBlock from '../utility/left-nav-block';
import Page2ColumnLayout from '../utility/page2-column-layout/Page2ColumnLayout';
import ViewBillLeftPane from '../../components/view-bill/view-bill-left-pane';
// import {
//   getTranslationsByLocale,
//   getLocale,
//   UnpackContentfulNodes,
// } from '../../hooks/useTranslation';
// import { graphql, useStaticQuery } from 'gatsby';
// import { AllContentfulNavigationBlockQuery } from '../../__generated__/gatsby-types';

type Props = {
  children?: React.ReactNode;
};

// export const useNavigationTranslations = (): UnpackContentfulNodes<AllContentfulNavigationBlockQuery['allContentfulNavigationBlock']['nodes']> => {
//   const data = useStaticQuery<AllContentfulNavigationBlockQuery>(graphql`
//     query AllContentfulNavigationBlock {
//       allContentfulNavigationBlock(
//         filter: { contentful_id: { eq: "28PrXZPkz4IF8owDjHVVcK" } }
//       ) {
//         nodes {
//           label
//           node_locale
//           links {
//             ... on ContentfulNavigationBlock {
//               label
//               link
//             }
//             ... on ContentfulApplicationPage {
//               url
//               title
//             }
//             ... on ContentfulPage {
//               url: slug
//               title
//             }
//             ... on ContentfulNavigationBlockSubItem {
//               label
//               slug: url
//             }
//           }
//         }
//       }
//     }
//   `);
//   const translatedData = getTranslationsByLocale(
//     data.allContentfulNavigationBlock.nodes,
//   );
//
//   const locale = getLocale(); // TODO Language will eventually be some sort of hook?
//   return translatedData[locale];
// };

export default function PaymentLayout({ children }: Props) {
  // const navigationTranslations = useNavigationTranslations();

  // const leftMenuData = {
  //   label: navigationTranslations.label || '',
  //   menuItems:
  //     navigationTranslations.links?.map((link: any) => ({
  //       label: link?.title || link?.label || '',
  //       url: link.url || '',
  //     })) || [],
  // };

  // const handleNavClick = async (url?: string) => {
  //   if (!!url) {
  //     await navigate(url);
  //   }
  // };
  return (
    <Page2ColumnLayout dense leftColumn={<ViewBillLeftPane />}>
      {children}
    </Page2ColumnLayout>
  );
}
