import React, { FC } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';

import ChangePinForm from '../../../components/change-pin-form';
import { Grid, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import ProfileLayout from '../../../components/profile-layout';
import Helmet from 'react-helmet';

const ChangePinPage: FC<RouteComponentProps> = (props: RouteComponentProps) => {
  const { t } = useTranslation();
  const title = t('CHANGE_PIN');
  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ProfileLayout dense={false}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant={'h1'}>{title}</Typography>
          </Grid>
          <Grid item>
            <ChangePinForm />
          </Grid>
        </Grid>
      </ProfileLayout>
    </>
  );
};

export default ChangePinPage;
