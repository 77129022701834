import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import clsx from 'clsx';

import { BillInfo } from '../../../__generated__/pge-types';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import {
  toSentenceCase,
  toDateString,
  toCurrencyDisplayFormat,
} from '../../../util/format';
import Button from '../../buttons';
import { navigate } from 'gatsby';
import TextLink from '../../text-link';
import { useIsMobile } from '../../../util/style-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      paddingBottom: 16,
      display: 'block',
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    boldText: {
      color: colors.errorRed,
      fontWeight: 'bold',
      fontFamily: 'Untitled-Sans'
    },
    paymentSummaryLinks: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    billRow: {
      maxHeight: 40,
      margin: '10px 0 7px',
    },
    boxContainer: {
      backgroundColor: colors.gray93,
      padding: theme.spacing(0, 1, 1, 1),
      margin: '0 8px 0',
      '& a': {
        '&:active': {
          borderBottom: 'none',
        },
      },
    },
    inEligibleTextGrayBox: {
      '& a': {
        textDecoration: 'none',
      },
    },
  }),
);

interface Props {
  isEligibleForReconnect: boolean;
  billInfo: BillInfo;
}

function buildPayButton(isEligibleForReconnect: boolean) {
  const { t } = useTranslation();
  if (isEligibleForReconnect) {
    return {
      text: t('PAYMENT_OPTIONS'),
      route: ROUTES.RECONNECT_SERVICE_START,
    };
  } else {
    return {
      text: t('PAY_BILL'),
      route: ROUTES.PAYMENT,
    };
  }
}
const ReconnectCard: FunctionComponent<Props> = ({
  isEligibleForReconnect,
  billInfo,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const { t, richT } = useTranslation();
  const payButton = buildPayButton(isEligibleForReconnect);
  return (
    <Grid container direction="column" spacing={2} alignItems="stretch">
      <Grid item>
        <Typography
          className={clsx(classes.boldText)}
          component="span"
          variant="h2"
        >
          {toSentenceCase(t('RECONNECT_CARD_TITLE'))}
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={clsx(classes.colorTextPrimary)} variant='body1'>
          {toSentenceCase(t('RECONNECT_CARD_SUBTEXT'))}
        </Typography>
      </Grid>
      <Grid item className={classes.boxContainer}>
        {isEligibleForReconnect ? (
          <Typography variant="body1">
            <TextLink to={ROUTES.RECONNECT_SERVICE_START}>
              {t('SEE_YOUR_PAYMENT_OPTIONS')}
            </TextLink>
            . {t('RECONNECT_CARD_ELIGIBLE_TEXT')}
          </Typography>
        ) : (
          <Typography variant="body1" className={classes.inEligibleTextGrayBox}>
            {richT('RECONNECT_CARD_NOT_ELIGIBLE_TEXT', {
              PGE_CUSTOMER_SERVICE_NUMBER: t('PGE_CUSTOMER_SERVICE_NUMBER'),
              CUSTOMER_SERVICE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
            })}
          </Typography>
        )}
      </Grid>
      <Grid item className={classes.billRow}>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={6} sm={4} md={6} lg={4}>
            {isEligibleForReconnect && (
              <Typography className={classes.colorTextPrimary}>
                {t('AMOUNT_DUE')}
              </Typography>
            )}
            {!isEligibleForReconnect && (
              <Typography className={classes.boldText}>
                {t('PAST_DUE')}
              </Typography>
            )}
          </Grid>
          <Grid item xs={6} sm={4} md={6} lg={6}>
            <Typography
              className={
                !isEligibleForReconnect
                  ? classes.boldText
                  : classes.colorTextPrimary
              }
              variant="h2"
              style={{ textAlign: isMobile ? 'right' : 'left', fontFamily: 'Untitled-Sans' }}
            >
              {`${
                billInfo?.amountDue !== null &&
                billInfo?.amountDue !== undefined
                  ? '$' +
                    toCurrencyDisplayFormat(billInfo?.amountDue, true, 'CR')
                  : '--'
              }`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item className={classes.billRow}>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={6} sm={4} md={4} lg={4}>
            <Typography className={classes.colorTextPrimary}>
              {t('DUE_DATE')}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            md={6}
            lg={6}
            style={{ textAlign: isMobile ? 'right' : 'left' }}
          >
            <Typography className={classes.colorTextPrimary} variant="h2" style={{fontFamily: 'Untitled-Sans'}}>
              {Number(billInfo?.amountDue) > 0 && billInfo?.dueDate
                ? toDateString(billInfo?.dueDate, 'MMM, dd yyyy')
                : '---'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Divider />
      </Grid>

      <Grid item>
        <Grid
          container
          direction="row"
          spacing={1}
          alignItems={'flex-end'}
          justify="space-between"
        >
          <Grid item xs={12} sm={7}></Grid>
          <Grid item xs={12} sm={5}>
            <Button
              fullWidth
              onClick={() => navigate(payButton.route)}
              style={{
                whiteSpace: 'nowrap',
                background: colors.orange,
              }}
            >
              {payButton.text}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReconnectCard;
