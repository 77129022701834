import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '../../hooks/useTranslation';
import { BaseFormModel } from '../../hooks/useFormState.types';
import TextField from '../text-field';
import {
  PersonalIdentificationType,
  StateIdType,
  FederalIdentificationType,
} from '../../__generated__/pge-types';

type VerifyCustomerIdentityProps = {
  form: BaseFormModel;
  primaryIdType: PersonalIdentificationType;
  showInstructionText?: boolean;
  isCoCustomer?: boolean;
};

interface IdTypeMap<TValue> {
  [id: string]: TValue;
}

const idTypeMap: IdTypeMap<string> = {
  [PersonalIdentificationType.Ssn]: 'SOCIAL_SECURITY_NUMBER',
  [PersonalIdentificationType.Dl]: 'DL_OR_STATE_ID',
  [StateIdType.DriverLicense]: 'DL_OR_STATE_ID',
  [StateIdType.StateIdCard]: 'DL_OR_STATE_ID',
  [PersonalIdentificationType.Ortrib]: 'OREGON_TRIBAL_ID',
  [FederalIdentificationType.OregonTribalId]: 'OREGON_TRIBAL_ID',
  [PersonalIdentificationType.Visa]: 'VISA_ID_TEMP_STUDENT',
  [FederalIdentificationType.UsStudentVisa]: 'VISA_ID_TEMP_STUDENT',
  [FederalIdentificationType.UsTemporaryVisa]: 'VISA_ID_TEMP_STUDENT',
  [PersonalIdentificationType.Military]: 'MILITARY_ID',
  [FederalIdentificationType.UsMilitaryId]: 'MILITARY_ID',
  [PersonalIdentificationType.Pssprt]: 'PASSPORT',
  [FederalIdentificationType.UsPassport]: 'PASSPORT',
  [PersonalIdentificationType.Resalien]: 'RESIDENT_ALIEN_ID',
  [FederalIdentificationType.UsImmigration]: 'RESIDENT_ALIEN_ID',
  [PersonalIdentificationType.Itin]: 'ITIN',
};

const VerifyCustomerIdentity = ({
  form,
  primaryIdType,
  showInstructionText = true,
  isCoCustomer = false,
}: VerifyCustomerIdentityProps) => {
  const { t } = useTranslation();

  const getIdTypeLabel = (): string => {
    return t(idTypeMap[primaryIdType] || '');
  };

  return (
    <Grid container spacing={2}>
      {showInstructionText && (
        <Grid item xs={12}>
          <Typography variant="body1">
            {t('CONFIRM_THE_PERSONAL_INFORMATION')}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12}>
        <Typography variant="body1" component="div">
          <p>
            {isCoCustomer
              ? t('ENTER_THE_LAST_FOUR_DIGITS_OF_THEIR')
              : t('ENTER_THE_LAST_FOUR_DIGITS_OF_YOUR')}{' '}
            <strong>{getIdTypeLabel()}</strong>
          </p>
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          inputProps={{ maxLength: 4, minLength: 1 }}
          name="idValue"
          {...form.props('idValue')}
          delayedTextMask={true}
        />
      </Grid>
    </Grid>
  );
};

export default VerifyCustomerIdentity;
