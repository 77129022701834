/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FC, ReactNode, useEffect, useState, Fragment } from 'react';
import {
  Box,
  Divider,
  FormHelperText,
  Typography,
  Link,
} from '@material-ui/core';
// import Grid from '@material-ui/core/Unstable_Grid2'; // Grid version 2
import Grid from '@material-ui/core/Grid'; // Grid version 2
import { navigate } from 'gatsby';
import routes from '../../../routes';
import ProductCardExpandable from '../../pge-plus-common/ProductCardExpandable';
import useContentMgmt, {
  ProductData,
  ProductStatus,
} from '../../../hooks/useContentMgmt';

import { ProceedOrCancelButton } from '../../pge-plus-common/StepperForm';

import { useEVChargerFormState } from '../../../providers/EVChargerFormStateProvider';
import useFormState from '../../../hooks/useFormState';
import { createSelectChargerValidateFunction } from '../../pge-plus-common/PGEPlusForm.rules';
import {
  ApplicationEvSectionType,
  SectionEvChargerSelection,
} from '../../../__generated__/pgeplus-types';
import useUpdateEVSections from '../../../hooks/pge-plus/useUpdateEVSections';
import { selectChargerFormStateAdapter } from '../../pge-plus-common/utils/pgeplusFormstateAdapters';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { SaveAndComeBackLink } from '../../pge-plus-common/SaveAndComeBackLink';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import theme from '../../../themes/theme';
import useUtil from '../../../hooks/pge-plus/useUtil';
import { Question } from '../../pge-plus-common/Typography';
import colors from '../../../themes/main-colors';
import { PgeEvModel } from '../../pge-plus-common/PgeEvModel';
import { UpdateEVSectionErrorCode } from '../evcharger.types';
export interface ProductProps {
  imgUrl?: string;
  sellerName: string;
  price: string;
  name: string;
  description: string;
  contents: string[];
}

interface SelectChargerProps {
  children?: ReactNode;
  path: string;
}

export const SelectCharger: FC<SelectChargerProps> = () => {
  const { wrapWithLoader } = useWrapWithLoader();
  const {
    formState: { selectCharger, application },
    updateFormState,
    getSectionData,
  } = useEVChargerFormState();
  const sectionDataSelectCharger: SectionEvChargerSelection = getSectionData(
    ApplicationEvSectionType.EvChargerSelection,
  );
  const { updateSectionChargerSelection } = useUpdateEVSections();

  const { content, getProductList, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_SELECT_CHARGER,
  );

  const form = useFormState(selectCharger, {
    validate: createSelectChargerValidateFunction(),
    validationContext: {
      content,
    },
  });
  const { setErrorNotification } = useUtil();
  const [evChargerProductList, setEvChargerProductList] = useState<
    ProductData[]
  >([]);
  const [
    isSmartChargingProgramModalVisible,
    setIsSmartChargingProgramModalVisible,
  ] = useState<boolean>(false);

  // NOTE: Here we pass product id empty string,  not null
  const submitSection = async (
    productId: string,
    saveAndComeBack: boolean = false,
  ) => {
    return updateSectionChargerSelection(
      selectChargerFormStateAdapter().fromFormState(
        { chargerId: productId, suppliedByCustomer: false },
        application?.id!,
        sectionDataSelectCharger?.id,
        saveAndComeBack,
      ),
    );
  };

  const handleSelect = wrapWithLoader(async (product: ProductData) => {
    try {
      const response = await submitSection(product.id, false);

      if (response.data?.updateSectionEVChargerSelection.validation?.success) {
        //Store the data in global store
        updateFormState('selectCharger', {
          chargerId: product.id,
          suppliedByCustomer: false,
        });

        //Navigate to the next screen
        void navigate(routes.PGE_PLUS_EV_CHARGERS_INSTALLATION);
      } else if (response.errors) {
        const ineligibleErrors = response.errors?.filter(
          reason =>
            reason?.extensions?.code ===
              UpdateEVSectionErrorCode.ProductInactive ||
            reason?.extensions?.code ===
              UpdateEVSectionErrorCode.ProductOutOfStock ||
            reason?.extensions?.code ===
              UpdateEVSectionErrorCode.ProductNotAvailable,
        );
        if (ineligibleErrors.length > 0) {
          setErrorNotification(
            true,
            content.get('PRODUCT_NOT_AVAILABLE_ERROR'),
          );
          return;
        } else {
          throw new Error();
        }
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const handleSaveAndExit = wrapWithLoader(async () => {
    try {
      await submitSection(form.values.chargerId || '', true);
      void navigate(routes.ACCOUNT);
    } catch (e) {
      setErrorNotification(true);
    }
  });

  useEffect(() => {
    const fetchProductList = async () => {
      const productList = await getProductList({
        productListEntryId:
          microcopyGroupIds.PGE_PLUS_SELECT_CHARGER_PRODUCT_LIST,
        status: ProductStatus.Active,
      });

      setEvChargerProductList(productList);
    };
    fetchProductList().catch(err => {
      setErrorNotification(true);
    });
  }, []);

  return (
    <Box mt={4} css={{ maxWidth: '790px', margin: 'auto', marginTop: 6 }}>
      <Grid container spacing={2} css={{ rowGap: 24 }}>
        <Box>
          <Question
            title={content.get('PRODUCT_RECOMMENDATIONS')}
            detail={
              <Fragment>
                {content.get('THESE_CHARGERS_QUALIFY')}
                <Link
                  css={{ fontWeight: 'bold', cursor: 'pointer' }}
                  onClick={() => setIsSmartChargingProgramModalVisible(true)}
                >
                  {content.get('SMARTCHARGING_PROGRAM')}
                </Link>
              </Fragment>
            }
          />
        </Box>

        <Grid container xs={12} css={{ rowGap: 30 }}>
          {evChargerProductList?.map(product => (
            <Grid xs={12}>
              <ProductCardExpandable
                product={product}
                handleSelect={() => handleSelect(product)}
                pageContentMap={content}
                isActive={product.id === selectCharger.chargerId}
              />
            </Grid>
          ))}
        </Grid>

        {form.errors.chargerId && (
          <FormHelperText error>{form.errors.chargerId}</FormHelperText>
        )}

        <Divider css={{ color: colors.gray93, margin: '20px 0 0px 0' }} />

        <Grid xs={12}>
          <ProceedOrCancelButton
            back={routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY}
            next={false}
          />
        </Grid>

        <Grid xs={12}>
          <SaveAndComeBackLink
            content={content}
            onSaveAndExit={handleSaveAndExit}
          />
        </Grid>

        <PgeEvModel
          isVisible={isSmartChargingProgramModalVisible}
          setIsVisible={setIsSmartChargingProgramModalVisible}
        />
      </Grid>
    </Box>
  );
};
