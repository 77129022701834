import { useState } from 'react';
import {
  ServiceAddress,
  Scalars,
  Maybe,
  OtherPeopleRelType,
  OtherPeopleResponse,
  QuickAddressSearchParams,
  PremiseSearchResponse,
  PropertyUsage,
  MoveSvcRequest,
  MoveTopremiseDetails,
} from '../__generated__/pge-types';
import {
  AccountInfo,
  SSMStopCurrentServiceDate,
  AddressAndDate,
  HomeDetails,
  ContactInfo,
  Meter,
  SSMMoveStayBehind,
  PropertyUsageType,
} from '../components/ssm/ssm.types';
import { displayPhoneNumberFormat, getPostalCountry } from '../util/format';
import useQas from './useQas';
import { useSSMMoveSubmit } from './useSSMMoveSubmit';
import useAccountCustomer from './useAccountCustomer';
import { usePremiseSearchQuery } from '../hooks/usePremiseSearchQuery';
import moment from 'moment';

export type SSMTransferPeopleToService = {
  acctRelType?: Maybe<OtherPeopleRelType>;
  perId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  isSelected?: boolean;
};

interface Response {
  premiseSearch: PremiseSearchResponse[];
}

export type SSMMoveServiceState = {
  accountInfo: AccountInfo;
  addressAndDate: AddressAndDate;
  homeDetails: HomeDetails;
  ssmStopCurrentServiceDate: SSMStopCurrentServiceDate;
  ssmMoveTransferPeopleToService: OtherPeopleResponse[];
  contactInfo: ContactInfo;
  stayingBehindInfo: SSMMoveStayBehind;
  moveToEncryptedPremiseIds: string[];
  moveFromPremiseIds: string[];
};

export default () => {
  const { search } = useQas();
  const { submit } = useSSMMoveSubmit();
  const {
    customer: customerInfoData,
    loading: customerInfoLoading,
    error: customerInfoError,
  } = useAccountCustomer();

  const {
    setPremiseIds,
    loading: premiseSearchLoading,
    error,
    data: premiseInfoData,
  } = usePremiseSearchQuery();
  const [ssmMoveServiceState, setSSMMoveServiceState] = useState<
    SSMMoveServiceState
  >({
    accountInfo: {},
    addressAndDate: {},
    homeDetails: {},
    ssmStopCurrentServiceDate: {},
    ssmMoveTransferPeopleToService: [],
    contactInfo: {},
    stayingBehindInfo: {},
    moveToEncryptedPremiseIds: [],
    moveFromPremiseIds: [],
  });

  const setMoveToEncryptedPremiseIds = (
    moveToEncryptedPremiseIds: string[],
  ) => {
    setSSMMoveServiceState(state => ({
      ...state,
      moveToEncryptedPremiseIds,
    }));
    if (moveToEncryptedPremiseIds) {
      setPremiseIds(moveToEncryptedPremiseIds);
    }
  };

  const setMoveFromPremiseIds = (moveFromPremiseIds: string[]) => {
    setSSMMoveServiceState(state => ({
      ...state,
      moveFromPremiseIds,
    }));
  };

  const doVerifyQASAddress = async (homeDetails: HomeDetails) => {
    const { mailAddress, mailCity, mailState, mailZip } = homeDetails;
    const params: QuickAddressSearchParams = {
      city: mailCity,
      state: mailState,
      addressLine1: mailAddress,
      postal: mailZip,
      isMailingAddress: true,
      country: mailZip ? getPostalCountry(mailZip) : '',
    };
    const results = await search(params);
    return { results, params };
  };

  const setAccountInfo = (accountInfo: AccountInfo) => {
    setSSMMoveServiceState(state => ({
      ...state,
      accountInfo,
    }));
  };

  const setAddressAndDate = (addressAndDate: AddressAndDate) => {
    setSSMMoveServiceState(state => ({
      ...state,
      addressAndDate,
    }));
  };

  const doVerifyAddress = (
    isAddressVerified: boolean,
    autoCompleteAddress: string,
    setIsAddressSuggestionModalDisplayed: React.Dispatch<
      React.SetStateAction<boolean>
    >,
    setIsMultipleMetersModalDisplayed: React.Dispatch<
      React.SetStateAction<boolean>
    >,
    metersInfo: Meter[],
    isMeterSelectionVerified: boolean,
    _address?: ServiceAddress,
  ) => {
    if (metersInfo.length > 0) {
      const isEveryMeterDisabled = metersInfo.every(
        meter => meter.disabled === true,
      );
      if (isEveryMeterDisabled) {
        return true;
      }
    }
    if (isAddressVerified && _address?.addressLine1 !== autoCompleteAddress) {
      setIsAddressSuggestionModalDisplayed(true);
      return false;
    }
    if (!isAddressVerified) {
      setIsAddressSuggestionModalDisplayed(true);
      return false;
    }
    if (metersInfo.length > 10) {
      setIsMultipleMetersModalDisplayed(true);
      return false;
    }
    if (
      metersInfo.length > 1 &&
      metersInfo.length < 11 &&
      !isMeterSelectionVerified
    ) {
      setIsMultipleMetersModalDisplayed(true);
      return false;
    }
    if (!isMeterSelectionVerified) {
      return false;
    }
    return true;
  };

  const concatenateAddressFields = () => {
    setSSMMoveServiceState(state => ({
      ...state,
      addressAndDate: {
        address: {
          addressLine1: `${state?.addressAndDate?.address?.addressLine1 ?? ''}${
            state?.addressAndDate?.address?.city ? ',' : ''
          } ${state?.addressAndDate?.address?.city ?? ''}${
            state?.addressAndDate?.address?.postal ? ',' : ''
          } ${state?.addressAndDate?.address?.postal ?? ''}`.trim(),
          city: '',
          postal: '',
        },
        date: state.addressAndDate.date,
      },
    }));
  };

  const setHomeDetails = (homeDetails: HomeDetails) => {
    setSSMMoveServiceState(state => ({
      ...state,
      homeDetails,
    }));
    return true;
  };

  const setSsmMoveTransferPeopleToService = (
    ssmMoveTransferPeopleToService: OtherPeopleResponse[],
  ) => {
    setSSMMoveServiceState(state => ({
      ...state,
      ssmMoveTransferPeopleToService,
    }));
  };

  const setSSMMoveStayBehind = (stayingBehind: SSMMoveStayBehind) => {
    setSSMMoveServiceState(state => ({
      ...state,
      stayingBehindInfo: stayingBehind,
    }));
  };

  const setSsmStopCurrentServiceDate = (
    ssmStopCurrentServiceDate: SSMStopCurrentServiceDate,
  ) => {
    setSSMMoveServiceState(state => ({
      ...state,
      ssmStopCurrentServiceDate,
    }));
  };

  const setContactInfo = (contactInfo: ContactInfo) => {
    setSSMMoveServiceState(state => ({
      ...state,
      contactInfo,
    }));
  };

  const getPropertyUsage = (usageType: PropertyUsageType | undefined) => {
    if (usageType === PropertyUsageType.Own) {
      return PropertyUsage.Owner;
    } else if (usageType === PropertyUsageType.TNT) {
      return PropertyUsage.Tenant;
    } else if (usageType === PropertyUsageType.LLD) {
      return PropertyUsage.Landlord;
    } else if (usageType === PropertyUsageType.FNF) {
      return PropertyUsage.FriendFamily;
    } else {
      return PropertyUsage.Owner;
    }
  };

  const getPremises = (metersInfo: Meter[]) => {
    const premises: MoveTopremiseDetails = {
      premId: premiseInfoData?.premiseSearch[0].premId || '',
      servicePointList: [],
    };

    metersInfo.forEach((meter: Meter) => {
      if (meter.checked) {
        premises?.servicePointList?.push({ servicePointId: meter.spId });
      }
    });

    return premises;
  };

  const onConfirm = async (metersInfo: Meter[]) => {
    const coCustomerPerId = ssmMoveServiceState.ssmMoveTransferPeopleToService.find(
      user => {
        return user.acctRelType === OtherPeopleRelType.Coapp;
      },
    );
    const authPartyPerIds = ssmMoveServiceState.ssmMoveTransferPeopleToService
      .filter(user => {
        return user.acctRelType === OtherPeopleRelType.Authusr;
      })
      .map(item => {
        return String(item.perId);
      });

    const payload: MoveSvcRequest = {
      premId: ssmMoveServiceState.moveFromPremiseIds[0],
      enrollInPaperless: ssmMoveServiceState.homeDetails.goPaperLess ?? false,
      moveTopremises: getPremises(metersInfo),
      startDate: moment(ssmMoveServiceState?.addressAndDate?.date).format(
        'YYYY-MM-DD',
      ),
      stopDate: moment(
        ssmMoveServiceState?.ssmStopCurrentServiceDate?.date,
      ).format('YYYY-MM-DD'),
      acctId: ssmMoveServiceState?.accountInfo?.accountNumber!,
      receiveNotifications:
        ssmMoveServiceState.homeDetails.shouldReceiveNotification ?? false,
      propertyUsage: getPropertyUsage(
        ssmMoveServiceState?.homeDetails?.propertyUsage,
      ),
      coCustomerPerId: coCustomerPerId?.perId,
      authPartyPerIds: authPartyPerIds,
      mailingAndServiceAddressesSame: !!ssmMoveServiceState.homeDetails
        .sameAsServiceAddress,
      customerInfo: {
        perId: customerInfoData?.personId!,
        additionalInfo: {
          mailingAddress: {
            addressLine1: ssmMoveServiceState.homeDetails.mailAddress || '',
            city: ssmMoveServiceState.homeDetails.mailCity || '',
            state: ssmMoveServiceState.homeDetails.mailState || '',
            postal: ssmMoveServiceState.homeDetails.mailZip || '',
            country: ssmMoveServiceState.homeDetails.mailCountry || '',
            qasVerified:
              ssmMoveServiceState.homeDetails.mailQasVerified || false,
            inCareOf: ssmMoveServiceState.homeDetails.inCareOf || '',
          },
        },
        emailAddress: customerInfoData?.email!,
      },
      greenSource: ssmMoveServiceState.homeDetails.enrollInGreenFuture ?? false,
      moveToAddress: {
        addressLine1: String(
          ssmMoveServiceState?.addressAndDate?.address?.addressLine1,
        )?.split(', ')[0],
        city: String(
          ssmMoveServiceState?.addressAndDate?.address?.addressLine1,
        )?.split(', ')[1],
        postal: String(
          ssmMoveServiceState?.addressAndDate?.address?.addressLine1,
        )?.split(', ')[2],
        qasVerified: ssmMoveServiceState.homeDetails.mailQasVerified,
        state: 'OR',
        country: 'USA',
      },
      moveFromAddress: {
        addressLine1: ssmMoveServiceState?.accountInfo?.premiseInfo![0]
          .addressLine1,
        city: ssmMoveServiceState?.accountInfo?.premiseInfo![0].city,
        postal: ssmMoveServiceState?.accountInfo?.premiseInfo![0].postal,
        qasVerified: ssmMoveServiceState.homeDetails.mailQasVerified,
        state: 'OR',
        country: 'USA',
      },
      stayingBehind:
        ssmMoveServiceState.stayingBehindInfo.stayingBehind === 'Yes'
          ? true
          : false,
    };
    if (ssmMoveServiceState.homeDetails.shouldReceiveNotification) {
      payload.notificationPhone = displayPhoneNumberFormat(
        ssmMoveServiceState.homeDetails.registeredMobileNumber?.replace(
          /[^0-9]/g,
          '',
        ),
      );
    }
    //move payload
    const result = await submit(payload);

    return result;
  };

  return {
    ssmMoveServiceState,
    setAccountInfo,
    setAddressAndDate,
    doVerifyAddress,
    setHomeDetails,
    setSsmMoveTransferPeopleToService,
    setSsmStopCurrentServiceDate,
    setContactInfo,
    onConfirm,
    concatenateAddressFields,
    setSSMMoveStayBehind,
    doVerifyQASAddress,
    setMoveToEncryptedPremiseIds,
    setMoveFromPremiseIds,
  };
};
