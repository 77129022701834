import React, { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Buttons from '../buttons/PGE-Button';
import useContentMgmt from '../../hooks/useContentMgmt';
import { styled } from '@material-ui/styles';
import colors from '../../themes/main-colors';
import microcopyGroupIds from './microcopyGroupIds';
import SmartChargeIcon from '../pge-plus-common/static/smartCharging.svg';
import { CheckBoxes } from '../pge-plus-common/CheckBoxes';

const PREFIX = 'costEstimation';
const classes = {
  dialogPaper: `${PREFIX}-dialog-paper`,
  dialogContent: `${PREFIX}-dialog-content`,
  dialogTitle: `${PREFIX}-dialog-title`,
  dialogActions: `${PREFIX}-dialog-actions`,
  root: `${PREFIX}-root`,
  table: `${PREFIX}-table`,
  row: `${PREFIX}-row`,
  closeButton: `${PREFIX}-closeButton`,
  typoGraphy: `${PREFIX}-typoGraphy`,
  programTitle: `${PREFIX}-programTitle`,
  smartIcon: `${PREFIX}-smartIcon`,
};

const StyledDialog = styled(Dialog)(({ theme }: { theme: Theme }) => ({
  [`& .${classes.dialogPaper}`]: {
    maxWidth: '878px',
    maxHeight: '723px',
    margin: 0,
    [theme.breakpoints.down('sm')]: {
      maxWidth: '415px !important',
      width: '100%',
    },
  },
  [`& .${classes.dialogContent}`]: {
    padding: '0 24px',
    marginBottom: 32,
  },
  [`& .${classes.dialogTitle}`]: {
    padding: '24px 24px 0',
  },
  [`& .${classes.dialogActions}`]: {
    padding: '30px 16px 50px',
  },
  [`& .${classes.root}`]: {
    width: '100%',
    marginTop: 32,
    overflowX: 'auto',
  },
  [`& .${classes.table}`]: {
    maxWidth: '878px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '415px !important',
    },
  },
  [`& .${classes.row}`]: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F8F8F8',
    },
  },
  [`& .${classes.closeButton}`]: {
    fontSize: '18px',
    borderRadius: '30px',
    width: '130px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  [`& .${classes.typoGraphy}`]: {
    fontSize: '20px',
    marginTop: '20px',
  },
  [`& .${classes.programTitle}`]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '16px',
  },
  [`& .${classes.smartIcon}`]: {
    height: '50px',
    width: '50px',
    [theme.breakpoints.down('sm')]: {
      height: '100px',
      width: '100px',
    },
  },
}));

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: colors.noirBlur,
    color: colors.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

export type PgeEvModelProps = {
  isVisible: boolean | false;
  setIsVisible: any;
  finalQuote?: boolean | false;
  finalQuoteData?: any;
  handleEnrollModal?: any;
};

export const PgeEvModel = ({
  isVisible,
  setIsVisible,
  finalQuote,
  finalQuoteData,
  handleEnrollModal,
}: PgeEvModelProps) => {
  const { content, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PROGRAM_EV_CHARGE,
  );
  const [enrollProgram, setEnrollProgram] = useState<string[]>([]);

  useEffect(() => {
    if (handleEnrollModal && typeof handleEnrollModal === 'function') {
      handleEnrollModal(enrollProgram);
    }
  }, [enrollProgram]);

  const handleClose = async () => {
    setIsVisible(false);
  };

  return (
    <StyledDialog
      open={isVisible}
      onClose={handleClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="body"
      fullWidth={true}
      disableRestoreFocus={true}
      PaperProps={{
        className: classes.dialogPaper,
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        <Grid
          className={'outageTitle'}
          container
          css={{
            justifyContent: 'end',
          }}
        >
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={handleClose}
            css={{
              padding: 0,
            }}
          >
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Grid container css={{ marginBottom: 16 }}>
          <Grid item className={classes.programTitle}>
            <SmartChargeIcon className={classes.smartIcon} />
            <Typography variant={'h1'}>
              {content.get('PROGRAM_DETAILS_TITLE')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body1'} className={classes.typoGraphy}>
              {richText(content.get('PGE_EV_SMART_CHARGING_PROGRAM_DETAILS'))}
            </Typography>
          </Grid>
        </Grid>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>{}</CustomTableCell>
                <CustomTableCell>
                  {content.get('PROGRAM_DETAILS_TABLE_GROUP_A_HEADER')}
                </CustomTableCell>
                <CustomTableCell>
                  {content.get('PROGRAM_DETAILS_TABLE_GROUP_B_HEADER')}
                </CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.row}>
                <CustomTableCell component="th" scope="row">
                  {content.get('PROGRAM_DETAILS_TABLE_COLUMN1_EVENT_DAYS')}
                </CustomTableCell>
                <CustomTableCell>
                  {content.get('PROGRAM_DETAILS_TABLE_GROUP_A_DAYS')}
                </CustomTableCell>
                <CustomTableCell>
                  {content.get('PROGRAM_DETAILS_TABLE_GROUP_B_DAYS')}
                </CustomTableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <CustomTableCell component="th" scope="row">
                  {content.get('PROGRAM_DETAILS_TABLE_COLUMN1_EVENT_TIMES')}
                </CustomTableCell>
                <CustomTableCell>
                  {content.get('PROGRAM_DETAILS_TABLE_GROUP_A_TIMES')}
                </CustomTableCell>
                <CustomTableCell>
                  {content.get('PROGRAM_DETAILS_TABLE_GROUP_B_TIMES')}
                </CustomTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Paper className={classes.root}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <CustomTableCell>
                  {content.get('PROGRAM_DETAILS_TABLE_REWARDS_HEADER')}
                </CustomTableCell>
                <CustomTableCell>{}</CustomTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow className={classes.row}>
                <CustomTableCell>
                  {content.get('PROGRAM_DETAILS_TABLE_REWARDS_COLUMN1_ROW1')}
                </CustomTableCell>
                <CustomTableCell component="th" scope="row">
                  {content.get('PROGRAM_DETAILS_TABLE_REWARDS_COLUMN2_ROW1')}
                </CustomTableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <CustomTableCell>
                  {content.get('PROGRAM_DETAILS_TABLE_REWARDS_COLUMN1_ROW2')}
                </CustomTableCell>
                <CustomTableCell component="th" scope="row">
                  {content.get('PROGRAM_DETAILS_TABLE_REWARDS_COLUMN2_ROW2')}
                </CustomTableCell>
              </TableRow>
              <TableRow className={classes.row}>
                <CustomTableCell>
                  {content.get('PROGRAM_DETAILS_TABLE_REWARDS_COLUMN1_ROW3')}
                </CustomTableCell>
                <CustomTableCell component="th" scope="row">
                  {content.get('PROGRAM_DETAILS_TABLE_REWARDS_COLUMN2_ROW3')}
                </CustomTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
        <Grid container>
          <Grid item>
            <Typography
              variant={'body1'}
              style={{ fontSize: '18px', marginTop: '32px' }}
            >
              {content.get('PROGRAM_DETAILS_DISCLAIMER')}
            </Typography>
          </Grid>
        </Grid>
        {finalQuote && (
          <Grid
            css={{
              backgroundColor: colors.lightGray2,
              padding: '10px',
            }}
          >
            <Box>
              <CheckBoxes
                options={[
                  {
                    label: finalQuoteData.get(
                      'CUSTOMER_QUOTE_SMART_CHARGING_UNENROLL',
                    )!,
                    value: finalQuoteData.get(
                      'CUSTOMER_QUOTE_SMART_CHARGING_UNENROLL',
                    )!,
                  },
                ]}
                state={enrollProgram}
                setState={setEnrollProgram}
              />
            </Box>
            <Box>
              <Typography
                css={{
                  color: enrollProgram?.length > 0 ? 'red' : 'none',
                }}
              >
                <span>*</span>
                {
                  finalQuoteData.get(
                    'CUSTOMER_QUOTE_SMART_CHARGING_NO_BENEFITS',
                  )!
                }
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          css={{ justifyContent: 'end', display: 'flex', marginTop: '16px' }}
        >
          <Buttons
            type="submit"
            fullWidth
            variant="contained"
            className={classes.closeButton}
            onClick={() => setIsVisible(false)}
          >
            {content.get('REUSABLE_BUTTON_CLOSE')}
          </Buttons>
        </Grid>
      </DialogContent>
    </StyledDialog>
  );
};
