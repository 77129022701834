import React from 'react';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { Typography, Divider, Link } from '@material-ui/core';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import {
  contentfulBroadcastMessageMessageBodyRichTextNode,
  Maybe,
} from '../../__generated__/gatsby-types';

/**
 * a wrapper around documentToReactComponents that has material-ui component defaults
 **/
function documentToMUIComponents(
  richTextDocument: Maybe<contentfulBroadcastMessageMessageBodyRichTextNode>,
  options?: Options,
) {
  const defaultOptions: Options = {
    renderNode: {
      [BLOCKS.HEADING_1]: (_node, children) => (
        <Typography variant="h1">{children}</Typography>
      ),
      [BLOCKS.HEADING_2]: (_node, children) => (
        <Typography variant="h2">{children}</Typography>
      ),
      [BLOCKS.HEADING_3]: (_node, children) => (
        <Typography variant="h3">{children}</Typography>
      ),
      [BLOCKS.HEADING_4]: (_node, children) => (
        <Typography variant="h4">{children}</Typography>
      ),
      [BLOCKS.HEADING_5]: (_node, children) => (
        <Typography variant="h5">{children}</Typography>
      ),
      [BLOCKS.HEADING_6]: (_node, children) => (
        <Typography variant="h6">{children}</Typography>
      ),
      [BLOCKS.HR]: (_node, children) => <Divider />,
      [BLOCKS.QUOTE]: (node, children) => (
        <Typography variant="body2" align="center" paragraph>
          {children}
        </Typography>
      ),
      [BLOCKS.PARAGRAPH]: (_node, children) => (
        <Typography variant="body1" paragraph>
          {children}
        </Typography>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        <Link href={node.data.uri}>{children}</Link>
      ),
    },
  };

  return renderRichText(richTextDocument, {
    ...defaultOptions,
    ...options,
  });
}

export default documentToMUIComponents;
