import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import { isMobile } from '../../../util/style-utils';
import ProceedOrCancel from '../../proceed-or-cancel';
import Link from '../../text-link';
import { AutoPayConfigData } from '../auto-pay.types';
import colors from '../../../themes/main-colors';
import PaymentProfileIcon from '../../paymentus/payment-profile-icon';
import { getAccountDisplayString } from '../../paymentus/utils';
import { getPaymentMethodLabel } from '../../../hooks/usePaymentus';

const useStyles = makeStyles((theme: Theme) => ({
  agreeCheck: {
    '& .MuiFormControlLabel-label': {
      color: theme.palette.error.main,
    },
  },
}));

interface AutoPayConfirmProps {
  path?: string;
  config: AutoPayConfigData;
  onSubmit: () => void;
  fromBankUpdate?: boolean;
  disableCancelLink?: boolean;
}

const AutoPayConfirm: FunctionComponent<AutoPayConfirmProps> = ({
  config,
  onSubmit,
  fromBankUpdate = false,
  disableCancelLink = false,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isCustomerAgree, setIsCustomerAgree] = useState<boolean>(false);

  const confirmTitle = t('AUTO_PAY_CONFIRM_TITLE');
  const confirmText = t('AUTO_PAY_CONFIRM_TEXT');
  const confirmOption = t('AUTO_PAY_CONFIRM_AGREEMENT');
  const confirmNote = t('AUTO_PAY_CONFIRM_NOTE', {
    ACTION: fromBankUpdate ? t('SUBMIT') : t('AUTO_PAY_CONFIRM_ENROLL'),
  });
  const privacyPolicy = ROUTES.PRIVACY_POLICY;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCustomerAgree(event.target.checked);
  };

  const paymentSelectorData = config.paymentSelector;

  const paymentProfileNumber = getAccountDisplayString(
    paymentSelectorData.selectedProfileInfo?.profile,
  );

  const paymentProfileType =
    paymentSelectorData.selectedProfileInfo?.profile?.type;

  const agreementArea = (
    <>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color={'primary'}
              checked={isCustomerAgree}
              onChange={handleChange}
              name="agreeCheck"
            />
          }
          label={confirmOption}
          className={classes.agreeCheck}
        />
      </Grid>
      <Grid item>
        <Typography variant={'body1'}>{confirmNote}</Typography>
      </Grid>
      <Grid item>
        <Link plain to={privacyPolicy}>
          <Typography variant={'h5'}>
            {t('AUTO_PAY_CONFIRM_PRIVACY_POLICY')}
          </Typography>
        </Link>
      </Grid>
    </>
  );

  return (
    <Grid container direction={'column'} spacing={2}>
      <Grid item>
        <Card>
          <CardContent>
            <Grid container direction={'column'} spacing={2}>
              <Grid item container direction={'column'}>
                <Grid item>
                  <Typography variant={'h2'}>{confirmTitle}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant={'body1'}>{confirmText}</Typography>
                </Grid>
              </Grid>
              <Grid item container direction={'column'} spacing={1}>
                <Grid item>
                  <Typography variant={'h4'}>
                    {getPaymentMethodLabel(paymentProfileType)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  spacing={2}
                  direction={'row'}
                  alignItems={'center'}
                >
                  <Grid item>
                    <PaymentProfileIcon
                      type={paymentProfileType}
                    ></PaymentProfileIcon>
                  </Grid>
                  <Grid item>
                    <Typography variant={'h1'}>
                      {paymentProfileNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {!fromBankUpdate && agreementArea}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {fromBankUpdate && (
        <Grid item>
          <Grid container direction={'column'} spacing={2}>
            {agreementArea}
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Box paddingRight={1}>
          <ProceedOrCancel
            cancelRoute={
              disableCancelLink
                ? false
                : fromBankUpdate
                ? ROUTES.AUTO_PAY
                : ROUTES.AUTO_PAY_CONFIG
            }
            cancelLabel={t('BACK')}
            proceedDisabled={!isCustomerAgree}
            proceedHandler={onSubmit}
            proceedLabel={
              fromBankUpdate ? t('UPDATE') : t('AUTO_PAY_CONFIRM_ENROLL')
            }
            proceedStyle={isCustomerAgree ? colors.orange : ''}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default AutoPayConfirm;
