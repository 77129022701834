import React, { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { Grid, useMediaQuery, Typography, Box } from '@material-ui/core';
import useMoveServiceForm from '../../../components/move-service/useMoveServiceForm';
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles';
import { RouteComponentProps, Router, useLocation } from '@reach/router';
import ServiceLayout from '../../../components/service-layout';
import { MoveServiceCustomerInfo } from '../../../components/move-service/MoveServiceCustomerInfo';
import { MoveServiceCoCustomerInfo } from '../../../components/move-service/MoveServiceCoCustomerInfo';
import { MoveServiceOptions } from '../../../components/move-service/MoveServiceOptions';
import { MoveServiceReview } from '../../../components/move-service/MoveServiceReview';
import { MoveServiceConfirmation } from '../../../components/move-service/MoveServiceConfirmation';
import ROUTES from '../../../routes';
import Backdrop from '../../../components/backdrop';
import { featureFlagsContext } from '../../../providers/FeatureFlagsProvider';
import { MoveServiceFormPreverificationCheck } from '../../../components/move-service/move-service-eligibility/MoveServiceFormPreverificationCheck';
import { MoveServiceIneligibleAlert } from '../../../components/move-service/move-service-eligibility/MoveServiceIneligibleAlert';
import { MoveServiceAddressIneligibleAlert } from '../../../components/move-service/move-service-eligibility/MoveServiceAddressIneligibleAlert';
import { MoveServiceIneligibleCommercialAlert } from '../../../components/move-service/move-service-eligibility/MoveServiceIneligibleCommercialAlert';
import { MoveServiceCustomerAssistance } from '../../../components/move-service/MoveServiceCustomerAssistance';
import ProgressTracker, {
  ProgressTrackerStep,
} from '../../../components/ProgressTracker';

const useStyles = makeStyles(() =>
  createStyles({
    completed: {
      color: '#43936F !important',
    },
  }),
);

const MoveService: FC<RouteComponentProps> = (): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const title = t('MOVE_SERVICE');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation();
  const [showProgressBar, setShowProgressBar] = useState<boolean>(true);
  const { moveEnabled, loading: featureFlagsLoading } = useContext(
    featureFlagsContext,
  );

  const {
    accountDetails,
    accountNumber,
    checkStepToUrl,
    coCustomerData,
    coCustomerInfoForm,
    currentStep,
    customerInfoForm,
    customerUseAltPhone,
    encryptedAccountNumber,
    encryptedPersonId,
    getMainCustomerPersonDetailsLoading,
    goToStep,
    handleCoCustomerInfoSubmit,
    handleCustomerInfoSubmit,
    handleMainCustomerInfoSubmit,
    handleReviewSubmit,
    handleServiceDetailSubmit,
    handleServiceOptionsSubmit,
    isAuthenticated,
    isPTREligible,
    isRenewablesEligible,
    isSubmitting,
    mainCustomerInfoForm,
    primaryIdType,
    resetState,
    serviceDetailsForm,
    serviceOptionsForm,
    serviceReviewForm,
    setCustomerUseAltPhone,
    setIsRenewablesEligible,
    setUseAltMailingAddress,
    setIsCurrentlyPaperlessBilling,
    useAltMailingAddress,
  } = useMoveServiceForm();

  useEffect(() => {
    if ((location?.state as any)?.reset) {
      delete (location?.state as any)?.reset;
      resetState();
    }
  }, [(location?.state as any)?.reset]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    checkStepToUrl(location);
  }, [(location?.state as any)?.state]);

  useEffect(() => {
    if (
      !getMainCustomerPersonDetailsLoading &&
      !moveEnabled &&
      !featureFlagsLoading
    ) {
      let url = ROUTES.MOVE_SERVICE_LEGACY_URL;
      if (encryptedAccountNumber) {
        url += `?encryptedAccountNumber=${encryptedAccountNumber}`;
      }
      window.location.replace(url);
    }
  }, [
    getMainCustomerPersonDetailsLoading,
    moveEnabled,
    featureFlagsLoading,
    encryptedAccountNumber,
  ]);

  const progressTrackerSteps: ProgressTrackerStep[] = [
    {
      isActive: location.pathname === ROUTES.MOVE_SERVICE,
      isComplete: currentStep > 1,
      label: t('SERVICE_DETAILS'),
      stepIconClasses: {
        completed: classes.completed,
      },
    },
    {
      isActive:
        location.pathname === ROUTES.MOVE_SERVICE_CUSTOMER_INFO ||
        location.pathname === ROUTES.MOVE_SERVICE_CO_CUSTOMER_INFO ||
        location.pathname === ROUTES.MOVE_SERVICE_MAIN_CUSTOMER_INFO,
      isComplete: currentStep > 2.4,
      label: t('CUSTOMER_INFORMATION'),
      stepIconClasses: {
        completed: classes.completed,
      },
    },
    {
      isActive: location.pathname === ROUTES.MOVE_SERVICE_OPTIONS,
      isComplete: currentStep > 3,
      label: t('SERVICE_OPTIONS'),
      stepIconClasses: {
        completed: classes.completed,
      },
    },
    {
      isActive: location.pathname === ROUTES.MOVE_SERVICE_REVIEW,
      isComplete: currentStep > 4,
      label: t('REVIEW_REQUEST'),
      stepIconClasses: {
        completed: classes.completed,
      },
    },
    {
      isActive: location.pathname === ROUTES.MOVE_SERVICE_CONFIRM,
      isComplete: currentStep > 4,
      label: t('COMPLETE'),
      stepIconClasses: {
        completed: classes.completed,
      },
    },
  ];

  return (
    <>
      <Backdrop
        forceOpen={!moveEnabled}
        message={t('PLEASE_WAIT_WHILE_WE_LOAD_YOUR_SERVICE_REQUEST')}
      />
      <ServiceLayout>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box
              display="flex"
              justifyContent={currentStep > 1 ? 'space-between' : 'flex-start'}
            >
              <Typography variant={'h1'}>{title}</Typography>
              {currentStep > 1 && (
                <Typography>
                  {serviceDetailsForm?.values?.address || ''}
                  {', '}
                  {`${serviceDetailsForm?.values?.city ||
                    ''} ${serviceDetailsForm?.values?.zip || ''}`}
                </Typography>
              )}
            </Box>
          </Grid>
          {showProgressBar ? (
            <Grid item>
              <ProgressTracker steps={progressTrackerSteps} />
            </Grid>
          ) : null}
          <Grid item>
            <Router basepath="/">
              <MoveServiceFormPreverificationCheck
                accountDetails={accountDetails}
                form={serviceDetailsForm}
                handleServiceDetailSubmit={handleServiceDetailSubmit}
                isMobile={isMobile}
                isAuthenticated={isAuthenticated}
                path={ROUTES.MOVE_SERVICE}
                encryptedAccountNumber={encryptedAccountNumber}
                encryptedPersonId={encryptedPersonId}
                accountNumber={accountNumber}
                setIsRenewablesEligible={setIsRenewablesEligible}
                setIsCurrentlyPaperlessBilling={setIsCurrentlyPaperlessBilling}
              />
              <MoveServiceCustomerInfo
                serviceDetails={serviceDetailsForm}
                path={ROUTES.MOVE_SERVICE_CUSTOMER_INFO}
                primaryIdType={primaryIdType}
                goToStep={goToStep}
                form={customerInfoForm}
                handleCustomerInfoSubmit={handleCustomerInfoSubmit}
                useAltMailingAddress={useAltMailingAddress}
                setUseAltMailingAddress={setUseAltMailingAddress}
                useAltPhone={customerUseAltPhone}
                setUseAltPhone={setCustomerUseAltPhone}
                coCustomerData={coCustomerData}
                encryptedPersonId={encryptedPersonId}
                accountNumber={accountNumber || ''}
              />

              {/* TODO: Refactor? Co-customer 1. Not related to CC&B 'main customer/person' */}
              <MoveServiceCoCustomerInfo
                form={mainCustomerInfoForm}
                path={ROUTES.MOVE_SERVICE_MAIN_CUSTOMER_INFO}
                handleSubmit={handleMainCustomerInfoSubmit}
                goToStep={goToStep}
                currentStep={currentStep}
                coCustomerData={coCustomerData}
                accountNumber={accountNumber || ''}
              />

              <MoveServiceCoCustomerInfo
                form={coCustomerInfoForm}
                path={ROUTES.MOVE_SERVICE_CO_CUSTOMER_INFO}
                handleSubmit={handleCoCustomerInfoSubmit}
                goToStep={goToStep}
                currentStep={currentStep}
                coCustomerData={coCustomerData}
                accountNumber={accountNumber || ''}
              />

              <MoveServiceOptions
                isPTREligible={isPTREligible}
                isRenewablesEligible={isRenewablesEligible}
                path={ROUTES.MOVE_SERVICE_OPTIONS}
                isMobile={isMobile}
                goToStep={goToStep}
                form={serviceOptionsForm}
                handleServiceOptionsSubmit={handleServiceOptionsSubmit}
                customerEmail={customerInfoForm.values.email || ''}
                customerPhone={
                  customerInfoForm.values.altPhoneType === t('MOBILE')
                    ? customerInfoForm.values.alternatePhone
                    : ''
                }
                hasCoCustomer={Boolean(
                  coCustomerInfoForm.values.encryptedPersonId,
                )}
                hasMainCustomer={Boolean(
                  mainCustomerInfoForm.values.encryptedPersonId,
                )}
              />
              <MoveServiceReview
                accountDetails={accountDetails}
                coCustomerData={coCustomerData}
                coCustomerInfoForm={coCustomerInfoForm}
                customerInfoForm={customerInfoForm}
                form={serviceReviewForm}
                goToStep={goToStep}
                handleReviewSubmit={handleReviewSubmit}
                isRenewablesEligible={isRenewablesEligible}
                isSubmitting={isSubmitting}
                mainCustomerInfoForm={mainCustomerInfoForm}
                path={ROUTES.MOVE_SERVICE_REVIEW}
                primaryIdType={primaryIdType}
                serviceDetailsForm={serviceDetailsForm}
                serviceOptionsForm={serviceOptionsForm}
                useAltMailingAddress={useAltMailingAddress}
              />
              <MoveServiceConfirmation path={ROUTES.MOVE_SERVICE_CONFIRM} />
              <MoveServiceIneligibleAlert
                path={ROUTES.MOVE_SERVICE_INELIGIBLE}
                setShowProgressBar={setShowProgressBar}
                encryptedAccountNumber={encryptedAccountNumber}
                accountDetails={accountDetails}
                isAuthenticated={isAuthenticated}
              />
              <MoveServiceAddressIneligibleAlert
                path={ROUTES.MOVE_SERVICE_ADDRESS_INELIGIBLE}
                setShowProgressBar={setShowProgressBar}
              />
              <MoveServiceIneligibleCommercialAlert
                path={ROUTES.MOVE_SERVICE_ADDRESS_COMMERICAL_INELIGIBLE}
                setShowProgressBar={setShowProgressBar}
                serviceDetailsForm={serviceDetailsForm}
              />
              <MoveServiceCustomerAssistance
                path={ROUTES.MOVE_SERVICE_ASSISTANCE}
                setShowProgressBar={setShowProgressBar}
                isMobile={isMobile}
                isAuthenticated
                encryptedPersonId={encryptedPersonId}
                accountNumber={accountNumber}
              />
            </Router>
          </Grid>
        </Grid>
      </ServiceLayout>
    </>
  );
};

export default MoveService;
