import React, { ReactHTML } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CardContent from '@material-ui/core/CardContent';
import Hidden from '@material-ui/core/Hidden';
import {
  toCurrencyString,
  displayAccountIdentifier,
  toCurrencyDisplayFormat,
} from '../../util/format';
import { useTranslation } from '../../hooks/useTranslation';
import { AccountAmounts } from './types';
import { useIsMobile } from '../../util/style-utils';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import sumBy from 'lodash/sumBy';
import {
  AccountDetail,
  PaymentConfirmation,
  PaymentErrorMessage,
} from '../../__generated__/pge-types';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    totalArea: {
      background: colors.lightGray2,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px`,
    },
    sum: {
      fontSize: '1.8em',
    },
    total: {
      fontSize: '1.3em',
    },
    error: {
      color: theme.palette.error.main,
    },
  }),
);

type Result = {
  account: AccountDetail;
  amount: number;
  result?: PaymentConfirmation;
};

type Props = {
  results: Array<Result>;
  timestamp?: string;
  showConfirmationMsg?: boolean;
  isSuccess?: boolean;
};

type RowProps = {
  rowKey: string;
  accountIdentifier: string;
  amountDue: string;
  amount: string;
  confirmation?: string;
  error?: string;
  isSuccess?: boolean;
  isMobile?: boolean;
};

const Row = ({
  rowKey,
  accountIdentifier,
  amountDue,
  amount,
  confirmation,
  error,
  isSuccess,
  isMobile,
}: RowProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid item xs={12} key={rowKey} className={isSuccess ? '' : classes.error}>
      <Grid container>
        <Grid item xs={6} md={6}>
          <Typography variant="body1">
            <strong>{accountIdentifier}</strong>
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs={6} md={2}>
            <Typography variant="body1" align="center">
              ${amountDue}
            </Typography>
          </Grid>
        </Hidden>
        <Grid item xs={6} md={2}>
          <Typography variant="body1" align="right">
            ${amount}
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs={6} md={2}>
            <Typography variant="body1" align="right">
              {isSuccess ? confirmation : '-'}
            </Typography>
          </Grid>
        </Hidden>
      </Grid>
      <Hidden mdUp>
        {isSuccess && (
          <Grid item xs={12}>
            <Typography variant="body1" align="left">
              {t('CONFIRMATION_NUMBER')}
            </Typography>
            <Typography variant="body1" align="left">
              {confirmation}
            </Typography>
          </Grid>
        )}
      </Hidden>
    </Grid>
  );
};

export default function CompleteTable({
  results,
  showConfirmationMsg,
  timestamp,
  isSuccess,
}: Props) {
  const { t, richT } = useTranslation();
  const isMobile = useIsMobile();
  const classes = useStyles();

  const { customer } = useAccountCustomer();

  const successfulPayments = results.filter(({ result }) => result?.isSuccess);
  const errorPayments = results.filter(({ result }) => !result?.isSuccess);

  const groupedErrorPayments = errorPayments.reduce(
    (groupMap, { result, account, amount }) =>
      groupMap.set(result?.errorMessage || PaymentErrorMessage.Unknown, [
        ...(groupMap.get(result?.errorMessage || PaymentErrorMessage.Unknown) ||
          []),
        { result, account, amount },
      ]),
    new Map<string, Array<Result>>(),
  );

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box borderBottom="1px solid #ccc" paddingBottom={2} marginTop={1}>
              <Grid container>
                <Grid item xs={6} md={6}>
                  <Typography variant="body1">{t('ACCOUNT')}</Typography>
                </Grid>
                <Hidden smDown>
                  <Grid item xs={6} md={2}>
                    <Typography variant="body1" align="center">
                      {t('AMOUNT_DUE')}*
                    </Typography>
                  </Grid>
                </Hidden>
                <Grid item xs={6} md={2}>
                  <Typography variant="body1" align="right">
                    {isMobile ? t('PAYMENT') : t('PAYMENT_AMOUNT')}
                  </Typography>
                </Grid>
                <Hidden smDown>
                  <Grid item xs={6} md={2}>
                    <Typography variant="body1" align="right">
                      {isSuccess ? t('CONFIRMATION_NUMBER') : t('ERROR')}
                    </Typography>
                  </Grid>
                </Hidden>
              </Grid>
            </Box>
          </Grid>
          {Array.from(groupedErrorPayments.entries()).map(([key, values]) => {
            return (
              <Grid container item key={`error-table-${key}`}>
                {values.map(({ account, amount, result }) => (
                  <Row
                    key={account.accountNumber}
                    rowKey={account.accountNumber}
                    accountIdentifier={displayAccountIdentifier(account)}
                    amountDue={toCurrencyDisplayFormat(
                      Number(account?.currentCharges?.amountDue),
                      true,
                      'CR',
                    )}
                    amount={toCurrencyString(amount, true)}
                    confirmation={result?.confirmationId || ''}
                    error={result?.errorReason || ''}
                    isSuccess={result?.isSuccess}
                    isMobile={isMobile}
                  ></Row>
                ))}
                {
                  <Grid item xs={12} key={`error-${key}`}>
                    <Grid container>
                      <Typography
                        variant={'body1'}
                        component={'div'}
                        className={classes.error}
                      >
                        {richT(key, {
                          ASSISTANCE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
                        })}
                      </Typography>
                    </Grid>
                  </Grid>
                }
                {
                  <Grid item xs={12} key={`divider-error-${key}`}>
                    <Divider></Divider>
                  </Grid>
                }
              </Grid>
            );
          })}
          {successfulPayments.map(({ account, amount, result }) => (
            <Row
              key={account.accountNumber}
              rowKey={account.accountNumber}
              accountIdentifier={displayAccountIdentifier(account)}
              amountDue={toCurrencyDisplayFormat(
                Number(account?.currentCharges?.amountDue),
                true,
                'CR',
              )}
              amount={toCurrencyString(amount, true)}
              confirmation={result?.confirmationId || ''}
              error={result?.errorMessage || result?.errorReason || ''}
              isSuccess={result?.isSuccess}
              isMobile={isMobile}
            ></Row>
          ))}
        </Grid>
      </Grid>
      {timestamp && (
        <>
          {successfulPayments?.length > 0 && (
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
          )}

          <Grid item xs={12} md="auto">
            <Typography variant="body1" align="center">
              {t('AMOUNT_DUE_INCLUDES')} {timestamp}.
            </Typography>
          </Grid>
        </>
      )}
      {showConfirmationMsg && successfulPayments?.length > 0 && (
        <Grid item xs={12}>
          <Typography variant="body1" component={'div'}>
            {richT('MULTIPAY_CONFIRMATION_MESSAGE', {
              EMAIL: customer?.email,
            })}
          </Typography>
        </Grid>
      )}
      {showConfirmationMsg &&
        successfulPayments?.length > 0 &&
        !!successfulPayments.find(({ account }) =>
          Boolean(account?.pendingDisconnect?.isPendingDisconnect),
        ) && (
          <Grid item xs={12}>
            <Typography
              variant="body1"
              component={'div'}
              className={classes.error}
            >
              {richT('MULTIPAY_PENDING_DISCONNECT_WARNING', {
                ASSISTANCE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
              })}
            </Typography>
          </Grid>
        )}
    </Grid>
  );
}
