/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { useState, Fragment, useEffect } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import useContentMgmt from '../../../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../../../pge-plus-common/microcopyGroupIds';
import routes from '../../../../../routes';
import RebateResults from './RebateResults';
import { useRebateOnlyFormState } from '../../../../../providers/RebateOnlyFormStateProvider';
import {
  ApplicationErSectionType,
  ApplicationSectionStatus,
  RebateByoc,
  RebateSmartCharging,
  SectionErRebateConfirm,
} from '../../../../../__generated__/pgeplus-types';
import useWrapWithLoader from '../../../../../hooks/useWrapWithLoading';
import useUpdateERSections from '../../../../../hooks/pge-plus/useUpdateERSections';
import useERApplicationApi from '../../../../../hooks/pge-plus/useERApplicationApi';
import useUtil from '../../../../need-more-time-to-pay/useUtil';
import { rebateConfirmERFormStateAdapter } from '../rebateOnlyFormState.adapter';

export enum rebateResultType {
  STANDARD_REBATE_PANEL_UPGRADE = 'StandardRebatePanelUpgrade',
  STANDARD_REBATE_NO_PANEL_UPGRADE = 'StandardRebateNoPanelUpgrade',
  HIGHER_REBATE_PANEL_UPGRADE = 'HigherRebatePanelUpgrade',
  HIGHER_REBATE_NO_PANEL_UPGRADE = 'HigherRebateNoPanelUpgrade',
  BYOC_REBATE = 'BYOCRebate',
}

const ChargerRebateResults: React.FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_REBATE_ONLY_REBATE_RESULTS,
  );

  const {
    formState: { rebateConfirm, rebateInfo, application },
    getSectionData,
    updateFormState,
  } = useRebateOnlyFormState();

  const [checkType, setCheckType] = useState('');
  const [productName, setProductName] = useState<string>('');
  const [panelUpgradeRebate, setPanelUpgradeRebate] = useState(0);
  const [chargerRebate, setChargerRebate] = useState(0);
  const [totalRebate, setTotalRebate] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [installCost, setInstallCost] = useState(0);
  const [chargeCost, setChargeCost] = useState(0);

  useEffect(() => {
    const PName = rebateInfo?.chargerValidation?.product?.name;
    setProductName(PName || '');
  }, []);

  useEffect(() => {
    let rebateSmartChargingData = {} as RebateSmartCharging;
    let rebateBYOCData = {} as RebateByoc;

    if (rebateConfirm?.appliedRebate?.__typename === 'RebateSmartCharging') {
      rebateSmartChargingData = rebateConfirm?.appliedRebate;
    }

    if (rebateConfirm?.appliedRebate?.__typename === 'RebateBYOC') {
      rebateBYOCData = rebateConfirm?.appliedRebate;
    }

    const installationCost =
      rebateConfirm?.chargerInstallInfo?.installationCost || 0;
    const chargerCost = rebateConfirm?.chargerInstallInfo?.chargerCost || 0;

    const BYOCAmount = rebateBYOCData?.amount;
    const isHigherRebate = rebateSmartChargingData?.isHigherDiscount;
    const isPanelUpgrade = rebateSmartChargingData?.panelUpgrade;
    const isCharge = rebateSmartChargingData?.charger;
    const TCost = installationCost + chargerCost;
    const rebateAmount = Math.min(rebateConfirm?.evPulseRebateAmount!, TCost);

    setInstallCost(installationCost || 0);
    setChargeCost(chargerCost || 0);
    setPanelUpgradeRebate(isPanelUpgrade || 0);
    setChargerRebate(isCharge || 0);
    setTotalRebate(rebateAmount || 0);
    setTotalCost(TCost || 0);

    if (BYOCAmount !== undefined && BYOCAmount > 0) {
      setCheckType(rebateResultType.BYOC_REBATE);
      return;
    }

    if (isHigherRebate && isPanelUpgrade !== undefined && isPanelUpgrade > 0) {
      setCheckType(rebateResultType.HIGHER_REBATE_PANEL_UPGRADE);
      return;
    }

    if (isHigherRebate && isPanelUpgrade !== undefined && isPanelUpgrade <= 0) {
      setCheckType(rebateResultType.HIGHER_REBATE_NO_PANEL_UPGRADE);
      return;
    }

    if (!isHigherRebate && isPanelUpgrade !== undefined && isPanelUpgrade > 0) {
      setCheckType(rebateResultType.STANDARD_REBATE_PANEL_UPGRADE);
      return;
    }

    if (
      !isHigherRebate &&
      isPanelUpgrade !== undefined &&
      isPanelUpgrade <= 0
    ) {
      setCheckType(rebateResultType.STANDARD_REBATE_NO_PANEL_UPGRADE);
      return;
    }
  }, []);

  const sectionDataRebateConfirm: SectionErRebateConfirm = getSectionData(
    ApplicationErSectionType.ErRebateConfirm,
  );
  const { wrapWithLoader } = useWrapWithLoader();
  const { updateSectionRebateConfirm } = useUpdateERSections();
  const { submitApplication } = useERApplicationApi();
  const { setErrorNotification } = useUtil();
  const onEnroll = wrapWithLoader(async () => {
    try {
      const response = await updateSectionRebateConfirm(
        rebateConfirmERFormStateAdapter().fromFormState(
          {
            ...rebateConfirm,
            confirmChargerConnectedToResidentialMeter: true,
            confirmDriveElectricVehicle: true,
          },
          application?.id!,
          sectionDataRebateConfirm?.id,
          ApplicationSectionStatus.Completed,
        ),
      );
      if (!response.data?.updateSectionERRebateConfirm.section.errors.length) {
        if (response.data?.updateSectionERRebateConfirm?.section.data) {
          const updatedValues = rebateConfirmERFormStateAdapter().toFormState(
            response.data?.updateSectionERRebateConfirm?.section.data,
          );
          updateFormState('rebateConfirm', {
            ...updatedValues,
          });
        }
        const { data } = await submitApplication({
          applicationId: application?.id!,
        });

        //Route according to Ineligibility case
        const IneligibilityCode =
          data?.submitApplicationER?.ineligibilityReasons[0]?.code;

        if (IneligibilityCode) {
          void navigate(routes.PGE_PLUS_REBATE_ONLY_INELIGIBLE, {
            //state: { IneligibilityCode },
          });
        } else {
          void navigate(routes.PGE_PLUS_EV_CHARGERS_SUCCESS);
        }
        return;
      } else {
        throw new Error();
      }
    } catch (e) {
      setErrorNotification(true);
    }
  });

  const generateEligibilityText = (type: string) => {
    switch (type) {
      case rebateResultType.STANDARD_REBATE_PANEL_UPGRADE:
        return (
          `${content.get('BASED_ON_THE_INFORMATION_YOU_PROVIDED')}` +
            `${content
              .get('STANDARD_REBATE_PANEL_UPGRADE')
              ?.replace('**', ' ')
              .replace('**', ' ')}` +
            `${content.get('BASED_ON_THE_INFORMATION_YOU_PROVIDED_END')}` || ''
        );

      case rebateResultType.STANDARD_REBATE_NO_PANEL_UPGRADE:
        return (
          `${content.get('BASED_ON_THE_INFORMATION_YOU_PROVIDED')}` +
            `${content
              .get('STANDARD_REBATE_NO_PANEL_UPGRADE')
              ?.replace('**', ' ')
              .replace('**', ' ')}` +
            `${content.get('BASED_ON_THE_INFORMATION_YOU_PROVIDED_END')}` || ''
        );

      case rebateResultType.HIGHER_REBATE_PANEL_UPGRADE:
        return (
          `${content.get('BASED_ON_THE_INFORMATION_YOU_PROVIDED')}` +
            `${content
              .get('HIGHER_REBATE_PANEL_UPGRADE')
              ?.replace('**', ' ')
              .replace('**', ' ')}` +
            `${content.get('BASED_ON_THE_INFORMATION_YOU_PROVIDED_END')}` || ''
        );

      case rebateResultType.HIGHER_REBATE_NO_PANEL_UPGRADE:
        return (
          `${content.get('BASED_ON_THE_INFORMATION_YOU_PROVIDED')}` +
            `${content
              .get('HIGHER_REBATE_NO_PANEL_UPGRADE')
              ?.replace('**', ' ')
              .replace('**', ' ')}` +
            `${content.get('BASED_ON_THE_INFORMATION_YOU_PROVIDED_END')}` || ''
        );

      case rebateResultType.BYOC_REBATE:
        return (
          `${content.get('BASED_ON_THE_INFORMATION_YOU_PROVIDED')}` +
            `${content
              .get('BYOC_REBATE_R')
              ?.replace('**', ' ')
              .replace('**', ' ')}` +
            `${content.get('BASED_ON_THE_INFORMATION_YOU_PROVIDED_END')}` || ''
        );

      default:
        return content.get('REBATE_ONLY_REBATE_RESULTS') || '';
    }
  };

  const generateRebateTypeLabel = (type: string) => {
    switch (type) {
      case rebateResultType.STANDARD_REBATE_PANEL_UPGRADE:
      case rebateResultType.STANDARD_REBATE_NO_PANEL_UPGRADE:
        return {
          charger: content.get('REBATE_SMARTCHARGING'),
          panelUpgrade: content.get('CUSTOMER_QUOTE_STANDARD_PANEL_REBATE'),
        };

      case rebateResultType.HIGHER_REBATE_PANEL_UPGRADE:
      case rebateResultType.HIGHER_REBATE_NO_PANEL_UPGRADE:
        return {
          charger: content.get('HIGHER_REBATE_LABEL'),
          panelUpgrade: content.get('HIGHER_REBATE_PANEL_UPGRADE_LABEL'),
        };

      default:
        return undefined;
    }
  };

  return (
    <Fragment>
      {checkType === rebateResultType.BYOC_REBATE ? (
        <RebateResults
          title={content.get('CHARGER_INSTALLTION_TITLE')}
          eligibilityText={generateEligibilityText(checkType)}
          rebateData={{
            totalRebates: rebateConfirm?.appliedRebate?.amount,
          }}
          backRoute={routes.PGE_PLUS_REBATE_ONLY_CONNECT_CHARGER}
          onEnroll={onEnroll}
        />
      ) : (
        <RebateResults
          title={content.get('CHARGER_INSTALLTION_TITLE')}
          isStandardOrChargerRebate={true}
          rebateLabels={generateRebateTypeLabel(checkType)}
          eligibilityText={generateEligibilityText(checkType)}
          rebateData={{
            totalRebates: totalRebate,
            chargerModel: productName,
            chargerCost: chargeCost,
            installationCost: installCost,
            totalCost: totalCost,
            standardChargerRebate: chargerRebate,
            standardPanelUpgradRebate: panelUpgradeRebate,
          }}
          backRoute={routes.PGE_PLUS_REBATE_ONLY_CONNECT_CHARGER}
          onEnroll={onEnroll}
        />
      )}
    </Fragment>
  );
};

export default ChargerRebateResults;
