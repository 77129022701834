import React from 'react';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';
import { Group, GroupType } from '../../../__generated__/pge-types';
import useStyles from './AccountListItem.styles';
import { MenuItem } from '../../menu/Menu';
import compact from 'lodash/compact';
import TextLink from '../../text-link';
import ListItem from '../../list-item';

type Props = {
  group: Group;
  onCheckboxChange: () => void;
  checked: boolean;
  onRemoveGroup: (group: Group) => void;
  onViewDetails: (group: Group) => void;
  onSetGroupDefault: (group: Group) => void;
  onEditGroup: (group: Group) => void;
  showMakePrimary?: boolean;
};

export default function CustomGroupListItem({
  group,
  checked,
  onCheckboxChange,
  onRemoveGroup,
  onViewDetails,
  onEditGroup,
  onSetGroupDefault,
  showMakePrimary,
}: Props) {
  const { t } = useTranslation();
  const classes = useStyles();

  const menuItems: Array<MenuItem<any>> =
    group?.type === 'Automatic'
      ? compact([
          { text: t('VIEW_ACCOUNT'), onClick: onViewDetails },
          {
            text: t('Remove'),
            onClick: onRemoveGroup,
            disabled: group.isLoggedOnUserAutoGroup,
          },
          showMakePrimary
            ? {
                text: t('Make Primary'),
                onClick: onSetGroupDefault,
                disabled: group?.isDefault,
              }
            : null,
        ])
      : compact([
          { text: t('VIEW_ACCOUNT'), onClick: onViewDetails },
          { text: t('Edit'), onClick: onEditGroup },
          { text: t('Remove'), onClick: onRemoveGroup },
          showMakePrimary
            ? {
                text: t('Make Primary'),
                onClick: onSetGroupDefault,
                disabled: group?.isDefault,
              }
            : null,
        ]);

  let groupTypeLabel = '';
  switch (group.type) {
    case GroupType.Automatic:
      groupTypeLabel = t('ACCOUNT_GROUP_LIST_ITEM_GROUP_TYPE_LABEL_AUTOMATIC');
      break;
    case GroupType.Virtual:
      groupTypeLabel = t('ACCOUNT_GROUP_LIST_ITEM_GROUP_TYPE_LABEL_VIRTUAL');
      break;
    case GroupType.Custom:
      groupTypeLabel = t('ACCOUNT_GROUP_LIST_ITEM_GROUP_TYPE_LABEL_CUSTOM');
      break;
    default:
      // this shouldn't kick in but if it does, we'll know why.
      groupTypeLabel = group.type;
      break;
  }

  return (
    <ListItem
      item={group}
      cardContentTestId="group-list-item-card-content"
      checked={checked}
      checkDisabled={
        group.type === GroupType.Automatic && group.isLoggedOnUserAutoGroup
      }
      onCheckChange={onCheckboxChange}
      menuItems={menuItems}
    >
      <Grid container spacing={1}>
        <Grid item xs>
          <Grid container spacing={1}>
            <Grid item xs={12} md={5}>
              <Box>
                {group.type === GroupType.Automatic ? (
                  <span>
                    <strong className={classes.groupName}>
                      {group?.groupName}
                    </strong>
                  </span>
                ) : (
                  <TextLink
                    external
                    onClick={e => {
                      e.preventDefault();
                      return onEditGroup(group);
                    }}
                    plain
                    style={{
                      fontWeight: 'bold',
                      color: colors.sparkBlue,
                      cursor: 'pointer',
                      wordBreak: 'break-word',
                    }}
                  >
                    {group?.groupName}
                  </TextLink>
                )}
              </Box>
            </Grid>

            <Grid item xs={12} md={3} zeroMinWidth>
              <Box className={classes.accountInfo}>
                <Typography
                  variant={'body2'}
                  className={classes.colorTextPrimary}
                  noWrap
                >
                  {t('ACCOUNT_GROUP_LIST_ITEM_ACCOUNTS_LABEL')}{' '}
                  {group?.numberOfAccounts}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              {group?.type && (
                <Box className={classes.inactive}>
                  <Typography
                    variant={'subtitle2'}
                    className={classes.colorTextPrimary}
                  >
                    {groupTypeLabel}
                  </Typography>
                </Box>
              )}
              {group?.isDefault && group?.groupId && (
                <Box className={classes.primaryCheck}>
                  <CheckCircleIcon color={'primary'} />
                  <Typography variant={'body2'}>Primary</Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ListItem>
  );
}
