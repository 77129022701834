import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { navigate, Router } from '@reach/router';
import React, { useEffect } from 'react';
import AccountDropdown from '../../../components/account-dropdown';
import Helmet from 'react-helmet';
import AccountLayout from '../../../components/account-layout';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import PeakTimeRebatesGiveBackEnrollment from '../../../components/peak-time-rebates-giving-back/give-back';
import usePeakTimeRebatesGivingBack from '../../../components/peak-time-rebates-giving-back/usePeakTimeRebatesGivingBack';
import Backdrop from '../../../components/backdrop';
import GiveBackIneligible from '../../../components/peak-time-rebates-giving-back/give-back/Ineligible';
import GiveBackNotToDonateConfirm from '../../../components/peak-time-rebates-giving-back/give-back/GiveBackNotToDonateConfirm';
import GiveBackConfirmation from '../../../components/peak-time-rebates-giving-back/give-back/GiveBackConfirmation';

export const PeakTimeRebatesGivingBackPage = (props: { path?: string }) => {
  const { t } = useTranslation();

  const {
    isEligible,
    loading,
    giveBackForm,
    submitPeakTimeRebatesGiveBack,
  } = usePeakTimeRebatesGivingBack();

  useEffect(() => {
    if (!isEligible && !loading) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.PEAKTIME_REBATES_GIVING_BACK_INELIGIBLE);
    } else if (
      isEligible &&
      !loading &&
      location.pathname !== ROUTES.PEAKTIME_REBATES_GIVING_BACK
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.PEAKTIME_REBATES_GIVING_BACK);
    }
  }, [isEligible, loading]);

  if (loading) {
    return <Backdrop forceOpen />;
  }

  return (
    <>
      <Helmet>
        <title>{t('PTR_GIVE_BACK_TITLE')}</title>
      </Helmet>
      {!loading && (
        <AccountLayout>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant={'h1'}>{t('PTR_GIVE_BACK_TITLE')}</Typography>
            </Grid>
            <Grid item>
              <AccountDropdown />
            </Grid>
            <Grid item>
              <Router basepath="/">
                <PeakTimeRebatesGiveBackEnrollment
                  path={ROUTES.PEAKTIME_REBATES_GIVING_BACK}
                  form={giveBackForm}
                  handleOnFormSubmit={submitPeakTimeRebatesGiveBack}
                />
                <GiveBackIneligible
                  path={ROUTES.PEAKTIME_REBATES_GIVING_BACK_INELIGIBLE}
                />
                <GiveBackConfirmation
                  path={ROUTES.PEAKTIME_REBATES_GIVING_BACK_CONFIRMATION}
                />
                <GiveBackNotToDonateConfirm
                  path={
                    ROUTES.PEAKTIME_REBATES_GIVING_BACK_NOT_TO_DONATE_CONFIRM
                  }
                />
              </Router>
            </Grid>
          </Grid>
        </AccountLayout>
      )}
    </>
  );
};

export default PeakTimeRebatesGivingBackPage;
