import React from 'react';
import { NetMeteringApplicationStatus } from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import routes from '../../../../routes';
import { NetMetering as TNetMetering } from '../../types';
import { Card } from '../Card';
import { MeteringList } from './MeteringList';
import { stripOffCents } from '../../../account-summary/energy-usage/current-use-card/useCurrentUseCard';

export function NetMetering(props: TNetMetering) {
  const { cardType, date, monthlyBill } = props;
  const { t, richT } = useTranslation();

  if (cardType === NetMeteringApplicationStatus.ApplicationReceived) {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('ROOFTOP_NAV_TITLE')}
        sectionA={richT('NM_APPLICATION_RECEIVED', {
          date,
        })}
        sectionB={
          <MeteringList
            type="application"
            status={NetMeteringApplicationStatus.ApplicationReceived}
            date={date}
          />
        }
        ctaText={t('GET_PROGRAM_UPDATES')}
        ctaLink={routes.ROOFTOP_SOLAR}
      />
    );
  }
  if (cardType === NetMeteringApplicationStatus.ReviewInProgress) {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('ROOFTOP_NAV_TITLE')}
        sectionA={richT('NM_REVIEW_IN_PROGRESS')}
        sectionB={
          <MeteringList
            type="application"
            status={NetMeteringApplicationStatus.ReviewInProgress}
            date={date}
          />
        }
        ctaText={t('GET_PROGRAM_UPDATES')}
        ctaLink={routes.ROOFTOP_SOLAR}
      />
    );
  }
  if (cardType === NetMeteringApplicationStatus.Approval) {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('ROOFTOP_NAV_TITLE')}
        sectionA={richT('NM_APPROVAL')}
        sectionB={
          <MeteringList
            type="application"
            status={NetMeteringApplicationStatus.Approval}
            date={date}
          />
        }
        ctaText={t('GET_PROGRAM_UPDATES')}
        ctaLink={routes.ROOFTOP_SOLAR}
      />
    );
  }
  if (cardType === NetMeteringApplicationStatus.MeterUpdatesAndInspection) {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('ROOFTOP_NAV_TITLE')}
        sectionA={richT('NM_METER_UPDATES_AND_INSPECTION')}
        sectionB={
          <MeteringList
            type="application"
            status={NetMeteringApplicationStatus.MeterUpdatesAndInspection}
            date={date}
          />
        }
        ctaText={t('GET_PROGRAM_UPDATES')}
        ctaLink={routes.ROOFTOP_SOLAR}
      />
    );
  }
  if (cardType === NetMeteringApplicationStatus.SolarOn) {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('ROOFTOP_NAV_TITLE')}
        sectionA={richT('NM_SOLAR_ON')}
        sectionB={
          <MeteringList
            type="application"
            status={NetMeteringApplicationStatus.SolarOn}
            date={date}
          />
        }
        ctaText={t('GET_PROGRAM_UPDATES')}
        ctaLink={routes.ROOFTOP_SOLAR}
      />
    );
  }

  if (cardType === 'NET_METERING_BALANCE') {
    return (
      <Card
        type={'full'}
        theme={'green'}
        title={t('ROOFTOP_NAV_TITLE')}
        sectionA={richT('ENERGY_SAVINGS_ACCOUNT_BALANCE', {
          kwh: String(
            stripOffCents(Number(monthlyBill?.energySavingAccountBalance ?? 0)),
          ),
        })}
        sectionB={
          <MeteringList type="monthlyBalance" monthlyBill={monthlyBill} />
        }
        ctaText={t('LEARN_MORE_ABOUT_NET_METERING')}
        ctaLink={routes.LEARN_MORE_ABOUT_NET_METERING}
      />
    );
  }

  return <></>;
}
