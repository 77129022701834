import {
  Box,
  CircularProgress,
  Grid,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from '../../../../hooks/useTranslation';
import { AccountDetail } from '../../../../__generated__/pge-types';
import { AutoPayConfigData } from '../../../auto-pay/auto-pay.types';
import AutoPayConfig from '../../../auto-pay/auto-pay-config';
import AutoPayConfirm from '../../../auto-pay/auto-pay-confirm';
import { Close } from '@material-ui/icons';

type Props = {
  account: AccountDetail;
  autoPayConfigData: AutoPayConfigData | null;
  onClose: () => void;
  open?: boolean;
  onComplete: (configData: AutoPayConfigData | null) => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      maxWidth: 600,
      marginBottom: theme.spacing(3),
      '& > *': {
        marginTop: theme.spacing(1),
      },
    },
    modal: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '922px',
      },
    },
    closeBtn: {
      position: 'absolute',
      top: 24,
      right: 24,
      padding: 0,
    },
    contentDialog: {
      padding: '0px',
    },
    contentArea: {
      padding: theme.spacing(0, 7, 3, 7),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0, 2, 3, 2),
      },
      '& .MuiPaper-elevation1': {
        boxShadow: 'none',
        padding: `${theme.spacing(0, 0, 2, 0)} !important`,
      },
    },
  }),
);

const AutoPayModal = ({
  account,
  autoPayConfigData,
  onClose,
  open = true,
  onComplete,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [autoPayConfig, setAutoPayConfig] = useState<AutoPayConfigData | null>(
    autoPayConfigData,
  );
  const [step, setStep] = useState<number>(1);

  const handleConfigSubmit = (data: AutoPayConfigData) => {
    setAutoPayConfig(data);
    setStep(2);
  };

  const handleConfirmSubmit = async function() {
    onComplete && onComplete(autoPayConfig);
  };

  const reset = () => {
    setAutoPayConfig(null);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      data-testid="ssm-auto-pay-modal"
      className={classes.modal}
      scroll="body"
      disableScrollLock
    >
      <DialogTitle>
        <Grid container justify="space-between">
          <Typography variant={'h1'} noWrap gutterBottom>
            {t('SSM_POST_SETUP_AUTO_PAY')}
          </Typography>
          <IconButton
            aria-label="close"
            color="inherit"
            onClick={onClose}
            className={classes.closeBtn}
          >
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.contentDialog}>
        <Paper className={classes.contentArea}>
          {typeof account === 'undefined' ? (
            <Box className={classes.body}>
              <Typography component="div" style={{ textAlign: 'center' }}>
                <CircularProgress />
              </Typography>
            </Box>
          ) : (
            <Box className={classes.body}>
              {step === 1 && (
                <AutoPayConfig
                  data={autoPayConfig}
                  onSubmit={handleConfigSubmit}
                  onUpdateAutoPayConfig={setAutoPayConfig}
                  account={account}
                  reset={reset}
                  disableCancelLink={true}
                  disablePDDUpdate={true}
                />
              )}

              {step === 2 && (
                <AutoPayConfirm
                  config={autoPayConfig!}
                  onSubmit={handleConfirmSubmit}
                  disableCancelLink={true}
                />
              )}
            </Box>
          )}
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default AutoPayModal;
