import gql from 'not-graphql-tag';
import { Query, AccountDetail } from '../__generated__/pge-types';
import { useQuery } from '@apollo/react-hooks';
import useAuth from './useAuth';
import useAuthQuery from './useAuthQuery';
import { getAccountDetails } from '../components/account-summary/single-account/queries';
import useSelectedAccountParams from './useSelectedAccountParams';
import useGetCoCustomersForAccount from './useGetCoCustomersForAccount';
import useGetCoCustomerPersonDetails from './useGetCoCustomerPersonDetails';

const getMainCustomerPersonDetails = gql`
  query getMainCustomerPersonDetails($encryptedPersonId: String) {
    getMainCustomerPersonDetails(encryptedPersonId: $encryptedPersonId) {
      dateOfBirth
      primaryPhone
      emailAddress
      alternatePhoneInfo {
        alternatePhoneNumber
        alternatePhoneType
        alternatePhoneText
      }
      mailingAddress {
        addressLine1
        city
        state
        postal
      }
      previousAddress {
        addressLine1
        city
        state
        postal
      }
      hasExistingPrimaryIdentification
      hasSocialSecurityInfo
      hasStateInfo
      hasFederalInfo
      federalInformation {
        federalIDType
        federalIDNumber
      }
      stateInformation {
        stateIdentificationType
        stateIDState {
          name
          code
        }
        stateIDNumber
      }
    }
  }
`;

const getPersonsForAccount = gql`
  query getPersonsForAccount($encryptedAccountNumber: String) {
    getPersonsForAccount(encryptedAccountNumber: $encryptedAccountNumber) {
      fullName
    }
  }
`;

// I think this is actually 'get logged-in' person details. Not related to CC&B 'main customer/person'
export default () => {
  const { isAuthenticated } = useAuth();

  const {
    accountParams,
    encryptedPersonId,
    encryptedAccountNumber,
    accountNumber,
  } = useSelectedAccountParams({ ignoreNoAccounts: true });

  const { data: getAccountDetailsData } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetails, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
  });

  const accountDetails = getAccountDetailsData?.getAccountDetails?.[0];

  const {
    data: existingCustomerInfoData,
    loading: existingCustomerInfoLoading,
  } = useQuery<Query>(getMainCustomerPersonDetails, {
    variables: {
      encryptedPersonId,
    },
    skip: !encryptedPersonId,
  });

  const {
    data: existingPersonsForAccountData,
    loading: personsForAccountLoading,
  } = useQuery<Query>(getPersonsForAccount, {
    variables: {
      encryptedAccountNumber,
    },
    skip: !encryptedAccountNumber,
  });

  const { coCustomerData, coCustomerDataLoading } = useGetCoCustomersForAccount(
    isAuthenticated,
    encryptedAccountNumber,
    encryptedPersonId,
  );

  const { coCustomerDetails: coCustDetails1 } = useGetCoCustomerPersonDetails(
    (coCustomerData?.length ?? 0) > 0
      ? (coCustomerData as any)[0]?.encryptedPersonId || ''
      : '',
  );

  const { coCustomerDetails: coCustDetails2 } = useGetCoCustomerPersonDetails(
    (coCustomerData?.length ?? 0) > 1
      ? (coCustomerData as any)[1]?.encryptedPersonId || ''
      : '',
  );

  const coCustomerDetails = [coCustDetails1, coCustDetails2];

  const loading =
    personsForAccountLoading ||
    existingCustomerInfoLoading ||
    coCustomerDataLoading;

  const customerInfoData =
    existingCustomerInfoData?.getMainCustomerPersonDetails;

  const personsForAccountData =
    existingPersonsForAccountData?.getPersonsForAccount;

  const coCustomerDetailsData = coCustomerData?.map((coCustomerDatum, idx) => ({
    ...coCustomerDatum,
    ...(coCustomerDetails[idx] || {}),
  }));

  return {
    coCustomerData: { ...coCustomerDetailsData },
    customerInfoData,
    personsForAccountData,
    loading,
    isAuthenticated,
    encryptedAccountNumber,
    encryptedPersonId,
    accountDetails,
    accountNumber,
  };
};
