import { Grid } from '@material-ui/core';
import React from 'react';
import {
  OtherPeopleRelType,
  OtherPeopleResponse,
} from '../../../__generated__/pge-types';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import { PostEnrollmentCoCustomer } from '../ssm.types';
import AddAuthorizedParty from './CoCustomer/AddAuthorizedParty';
import AddCoCustomer from './CoCustomer/AddCoCustomer';
import useSSMPostEnrollment from '../../../hooks/useSSMPostEnrollment';
import useGetCoCustomersAndAuthUsersListQuery from '../../../hooks/useGetCoCustomersAndAuthUsersListQuery';
import Backdrop from '../../backdrop';

export const SSMAddCoCustomer = ({
  formState,
}: WizardFormProps<PostEnrollmentCoCustomer>) => {
  const { account } = useSSMPostEnrollment();

  const {
    loading,
    data,
    refetch: refreshList,
  } = useGetCoCustomersAndAuthUsersListQuery(account?.accountNumber);

  return (
    <Grid container direction="column" spacing={2}>
      {loading && <Backdrop forceOpen={true} />}

      <Grid item container spacing={2}>
        <Grid item container>
          <AddCoCustomer
            usersList={data?.otherPeople.filter(
              (user: OtherPeopleResponse) =>
                user.acctRelType === OtherPeopleRelType.Coapp,
            )}
            refetchCustomerList={refreshList}
          />
        </Grid>

        <Grid item container>
          <AddAuthorizedParty
            usersList={data?.otherPeople}
            refetchCustomerList={refreshList}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
