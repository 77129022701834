import React from 'react';
import {
  MutationUpdateSectionEvAccountInfoArgs,
  MutationUpdateSectionEvChargerSelectionArgs,
  MutationUpdateSectionEvEstimateArgs,
  MutationUpdateSectionEvInstallArgs,
  MutationUpdateSectionEvPanelInfoArgs,
  MutationUpdateSectionEvSummaryArgs,
  UpdateSectionEvAccountInfoInput,
  UpdateSectionEvAccountInfoOutput,
  UpdateSectionEvChargerSelectionInput,
  UpdateSectionEvChargerSelectionOutput,
  UpdateSectionEvEstimateInput,
  UpdateSectionEvEstimateOutput,
  UpdateSectionEvInstallInput,
  UpdateSectionEvInstallOutput,
  UpdateSectionEvPanelInfoInput,
  UpdateSectionEvPanelInfoOutput,
  UpdateSectionEvSummaryInput,
  UpdateSectionEvSummaryOutput,
} from '../../__generated__/pgeplus-types';
import { useApolloClient } from '@apollo/react-hooks';
import gql from 'not-graphql-tag';
import {
  sectionFragment,
  validationFragment,
} from '../../components/pge-plus-common/queries/fragments';

export const updateSectionCotactInfoMutation = gql`
  mutation updateSectionEVAccountInfo(
    $input: UpdateSectionEVAccountInfoInput!
  ) {
    updateSectionEVAccountInfo(input: $input) {
      section {
        ...sectionFragment
      }
      validation {
        ...validationFragment
      }
    }
  }
  ${sectionFragment}
  ${validationFragment}
`;

export const updateSectionChargerSelectionMutation = gql`
  mutation($input: UpdateSectionEVChargerSelectionInput!) {
    updateSectionEVChargerSelection(input: $input) {
      section {
        ...sectionFragment
      }
      validation {
        ...validationFragment
      }
    }
  }
  ${sectionFragment}
  ${validationFragment}
`;

export const updateSectionPanelInfoMutation = gql`
  mutation updateSectionEVPanelInfo($input: UpdateSectionEVPanelInfoInput!) {
    updateSectionEVPanelInfo(input: $input) {
      section {
        ...sectionFragment
      }
      validation {
        ...validationFragment
      }
    }
  }
  ${sectionFragment}
  ${validationFragment}
`;

export const updateSectionEvEstimateMutation = gql`
  mutation updateSectionEVEstimate($input: UpdateSectionEVEstimateInput!) {
    updateSectionEVEstimate(input: $input) {
      section {
        ...sectionFragment
      }
      validation {
        ...validationFragment
      }
    }
  }
  ${sectionFragment}
  ${validationFragment}
`;

export const updateSectionEVInstallMutation = gql`
  mutation($input: UpdateSectionEVInstallInput!) {
    updateSectionEVInstall(input: $input) {
      section {
        ...sectionFragment
      }
      validation {
        ...validationFragment
      }
    }
  }
  ${sectionFragment}
  ${validationFragment}
`;

export const updateSectionEVSummaryMutation = gql`
  mutation($input: UpdateSectionEVSummaryInput!) {
    updateSectionEVSummary(input: $input) {
      section {
        ...sectionFragment
      }
      validation {
        ...validationFragment
      }
    }
  }
  ${sectionFragment}
  ${validationFragment}
`;

const useUpdateEVSections = () => {
  const apolloClient = useApolloClient();

  const updateSectionContactInfo = (input: UpdateSectionEvAccountInfoInput) => {
    return apolloClient.mutate<
      { updateSectionEVAccountInfo: UpdateSectionEvAccountInfoOutput },
      MutationUpdateSectionEvAccountInfoArgs
    >({
      mutation: updateSectionCotactInfoMutation,
      variables: { input },
      context: { pgePlus: true },
      errorPolicy: 'all',
    });
  };

  const updateSectionChargerSelection = (
    input: UpdateSectionEvChargerSelectionInput,
  ) => {
    return apolloClient.mutate<
      {
        updateSectionEVChargerSelection: UpdateSectionEvChargerSelectionOutput;
      },
      MutationUpdateSectionEvChargerSelectionArgs
    >({
      mutation: updateSectionChargerSelectionMutation,
      variables: { input },
      context: { pgePlus: true },
      errorPolicy: 'all',
    });
  };

  const updateSectionEVInstall = async (input: UpdateSectionEvInstallInput) => {
    return apolloClient.mutate<
      { updateSectionEVInstall: UpdateSectionEvInstallOutput },
      MutationUpdateSectionEvInstallArgs
    >({
      mutation: updateSectionEVInstallMutation,
      variables: { input },
      context: { pgePlus: true },
      errorPolicy: 'all',
    });
  };

  const updateSectionEVPanelInfo = async (
    input: UpdateSectionEvPanelInfoInput,
  ) => {
    return apolloClient.mutate<
      { updateSectionEVPanelInfo: UpdateSectionEvPanelInfoOutput },
      MutationUpdateSectionEvPanelInfoArgs
    >({
      mutation: updateSectionPanelInfoMutation,
      variables: { input },
      context: { pgePlus: true },
      errorPolicy: 'all',
    });
  };

  const updateSectionEvEstimate = async (
    input: UpdateSectionEvEstimateInput,
  ) => {
    return apolloClient.mutate<
      { updateSectionEVEstimate: UpdateSectionEvEstimateOutput },
      MutationUpdateSectionEvEstimateArgs
    >({
      mutation: updateSectionEvEstimateMutation,
      variables: { input },
      context: { pgePlus: true },
      errorPolicy: 'all',
    });
  };
  const updateSectionEVSummary = async (input: UpdateSectionEvSummaryInput) => {
    return apolloClient.mutate<
      { updateSectionEVSummary: UpdateSectionEvSummaryOutput },
      MutationUpdateSectionEvSummaryArgs
    >({
      mutation: updateSectionEVSummaryMutation,
      variables: { input },
      context: { pgePlus: true },
      errorPolicy: 'all',
    });
  };

  return {
    updateSectionContactInfo,
    updateSectionChargerSelection,
    updateSectionEVInstall,
    updateSectionEVPanelInfo,
    updateSectionEvEstimate,
    updateSectionEVSummary,
  };
};

export default useUpdateEVSections;
