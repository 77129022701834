import React, { FC } from 'react';
import { Typography, Box } from '@material-ui/core';
import Button from '../buttons';
import useStyles from './styles';
import AccountDropdown from '../account-dropdown';
import { useTranslation } from '../../hooks/useTranslation';
import PaperBox from '../utility/paper-box';
import { navigate } from 'gatsby';
import ROUTES from '../../routes';
import { useLocation } from '@reach/router';
import { toDateString } from '../../util/format';

interface Props {
  path?: string;
}

const MedicalCertificateEnrolled: FC<Props> = _ => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const locationState = location.state as {
    isEnrolled: boolean;
    medCertExpiryDate: string;
  };

  if (!locationState?.isEnrolled) {
    void navigate(ROUTES.MEDICAL_CERTIFICATE, { replace: true });
  }

  const expiryDate = locationState?.medCertExpiryDate ? toDateString(new Date(locationState?.medCertExpiryDate)) : '';
  
  return (
    <>
      <Box mt={2} mb={1}>
        <AccountDropdown />
      </Box>
      <PaperBox className={classes.copyBlock}>
        <Typography className={`${classes.textBold}`} variant={'h4'}>
          {t('MEDICAL_CERTIFICATE_ALREADY_ENROLLED')}
        </Typography>
        <Typography variant={'body1'}>
          {t('EXPIRES_ON')} {expiryDate}
        </Typography>
      </PaperBox>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row-reverse',
          marginTop: '.5em',
        }}
      >
        <Button
          variant={'contained'}
          color={'primary'}
          className={classes.submitButton}
          onClick={() => void navigate(ROUTES.ACCOUNT)}
        >
          {t('DONE')}
        </Button>
      </Box>
    </>
  );
};

export default MedicalCertificateEnrolled;
