import { useTheme } from '@material-ui/core/styles';
import html2canvas from 'html2canvas';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

export type PrintOpts = {
  containerId: string;
  excludeSelectors?: string[];
  minWidth?: number;
};

export type DownloadOpts = PrintOpts & {
  filename?: string;
  timestampColor?: string;
  timestampFont?: StandardFonts;
  timestampFontsize?: number;
};

export const useOpenPDFNewTab = ({
  containerId,
  excludeSelectors = [],
  minWidth,
  timestampFont = StandardFonts.Helvetica,
  timestampFontsize = 12,
}: DownloadOpts) => {
  const theme = useTheme();
  const windowWidth = minWidth ?? theme.breakpoints.values['md'];

  const openPDFInNewTab = async (e: React.MouseEvent): Promise<void> => {
    e.preventDefault();
    e.stopPropagation();

    const blob = await captureImage();
    if (blob) {
      const doc = await PDFDocument.create();
      const font = await doc.embedFont(timestampFont);
      const spacing = 10;

      const page = doc.addPage();
      const bounds = page.getSize();

      const timestampHeight = font.heightAtSize(timestampFontsize);
      page.moveTo(spacing, bounds.height - timestampHeight - spacing);

      bounds.height = bounds.height - timestampHeight - spacing * 2;

      const pngImage = await doc.embedPng(await blob.arrayBuffer());

      const { width, height } = pngImage.scaleToFit(
        bounds.width,
        bounds.height,
      );

      page.moveTo(bounds.width / 2 - width / 2, bounds.height - height);

      page.drawImage(pngImage, {
        width,
        height,
      });
      
      const data = await doc.saveAsBase64({ dataUri: true });
      const iframe = `<iframe width='100%' height='100%' src=${data}></iframe>`
      const x = window.open();
      if(x) {
        x.document.open();
        x.document.write(iframe);
        x.document.close();
      }
    }
  };

  const captureImage = async (): Promise<Blob | null> => {
    const container = document.getElementById(containerId);
    if (container) {
      const exclude = [
        ...excludeSelectors,
        '[data-print-exclude]',
      ].flatMap(sel => Array.from(document.querySelectorAll(sel).values()));

      exclude.forEach(child =>
        child.setAttribute('data-html2canvas-ignore', 'true'),
      );

      const canvas = await html2canvas(container, {
        scale: 2,
        onclone: (_: Document, element: HTMLElement) => {
          const div = _.getElementById(containerId);
          if(div){
            div.style.display = 'block';
          }
          element
            .querySelectorAll(':scope *')
            .forEach(
              (e: Element) => ((e as HTMLElement).style.boxShadow = 'unset'),
            );
        },
        windowWidth,
      });

      exclude.forEach(child =>
        child.removeAttribute('data-html2canvas-ignore'),
      );

      return new Promise(resolve => canvas.toBlob(resolve));
    }
    return null;
  };

  return {
    openPDFInNewTab,
  };
};
