import React, { useState, useContext, useEffect } from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Theme,
  makeStyles,
} from '@material-ui/core';
import { useIsMobile } from '../../util/style-utils';
import { useTranslation } from '../../hooks/useTranslation';
import PGEButton from '../buttons';
import DaySelector from '../day-selector';
import dayjs from 'dayjs';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import Alerts from '../alerts';
import { navigate } from 'gatsby';
import routes from '../../routes';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import usePDDServices, { UpdatePDDResponse } from './pdd.hooks';
import Backdrop from '../backdrop';
import useAuthQuery from '../../hooks/useAuthQuery';
import * as queries from '../../queries/preferredDueDay.query';
import { isNil } from 'lodash';
import {
  AccountDetail,
  PreferredDueDateResponse,
} from '../../__generated__/pge-types';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import { PDDLocationState } from './PDDSuccess';
import AccountDropdown from '../account-dropdown';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(2),
  },
  actionArea: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

type Props = {
  path?: string;
};

export default (_: Props) => {
  const { t } = useTranslation();
  const notificationsContext = useContext(NotificationsContext);
  const classes = useStyles();
  const { encryptedAccountNumber, accountParams } = useSelectedAccountParams();

  const pddServices = usePDDServices();
  const isMobile = useIsMobile();
  const { wrapWithLoader } = useWrapWithLoader();

  const ineligibleMsg = t('PDD_INELIGIBLE_MSG');

  const [selectedPDDay, setSelectedPDDay] = useState<number>(1);
  const [storedPDDay, setStoredPDDay] = useState<number>(0);
  const [isEligible, setIsEligible] = useState<boolean | null | undefined>(
    true,
  );
  const [lastPDDUpdateDate, setLastPDDUpdateDate] = useState<string | null>(); // synonymous w. dueDateInfo.effectiveDate

  const { loading: pddInfoLoading, data: pddData } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(queries.getAccountDetailsForPreferredDueDate, {
    variables: {
      params: {
        accountNumberList: [
          {
            accountNumber: accountParams?.accountNumber,
          },
        ],
        encryptedPersonId: accountParams?.encryptedPersonId,
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
  });

  // If the account selection changed, we need to reset the page
  useEffect(() => {
    reset();
  }, [accountParams?.accountNumber]);

  useEffect(() => {
    pddData ? refreshState() : null;
  }, [pddData]);

  if (pddInfoLoading || isNil(pddData)) {
    return <Backdrop forceOpen />;
  }

  function reset() {
    setStoredPDDay(0); // 0 means no stored day
    setSelectedPDDay(1);
    setIsEligible(true);
    setLastPDDUpdateDate(null);

    refreshState();
  }

  function refreshState() {
    if (
      pddData?.getAccountDetails?.[0].preferredDueDate?.dueDate
        ?.preferredDueDate
    ) {
      const currentlyStoredPDDay =
        pddData?.getAccountDetails?.[0].preferredDueDate?.dueDate
          ?.preferredDueDate;
      setStoredPDDay(currentlyStoredPDDay);

      currentlyStoredPDDay === 0
        ? setSelectedPDDay(1)
        : setSelectedPDDay(currentlyStoredPDDay);
    }
    if (
      pddData?.getAccountDetails?.[0].preferredDueDate?.dueDate?.effectiveDate
    ) {
      setLastPDDUpdateDate(
        pddData.getAccountDetails?.[0].preferredDueDate?.dueDate?.effectiveDate,
      );
    }
    if (pddData?.getAccountDetails?.[0].preferredDueDate?.eligibility) {
      setIsEligible(
        pddData.getAccountDetails?.[0].preferredDueDate?.eligibility.isEligible,
      );
    }
  }

  const onSubmit = wrapWithLoader(async () => {
    if (selectedPDDay === storedPDDay) {
      notificationsContext.setState({
        isOpen: true,
        severity: 'info',
        variant: 'filled',
        message: t('PDD_SAME_DAY_SELECTED'),
      });
      return;
    }

    // If the last update (aka. effective date) was made today,
    // set selected day to stored day and show error.
    if (lastPDDUpdateDate && dayjs().diff(lastPDDUpdateDate, 'hour') < 24) {
      setSelectedPDDay(storedPDDay);
      notificationsContext.setState({
        isOpen: true,
        severity: 'error',
        variant: 'filled',
        message: t('PDD_ALREADY_CHANGED_TODAY'),
      });
      return;
    }

    // At this point, the update of PDD can happen
    const result: UpdatePDDResponse = await pddServices.updatePDD(
      encryptedAccountNumber!,
      selectedPDDay,
    );
    switch (result) {
      case UpdatePDDResponse.Success:
        // create state to pass the selected day to the success page for display
        const navigationState: PDDLocationState = {
          selectedPDDay: selectedPDDay,
        };
        return navigate(routes.PREFERRED_DUE_DAY_SUCCESS, {
          state: navigationState,
          replace: false,
        });
      case UpdatePDDResponse.ChangedToday:
        notificationsContext.setState({
          isOpen: true,
          severity: 'error',
          variant: 'filled',
          message: t('PDD_ALREADY_CHANGED_TODAY'),
        });
        return;
      case UpdatePDDResponse.NotEligible:
        setIsEligible(false);
        return;
      case UpdatePDDResponse.Error:
        throw new Error('Unknown error in updatePDD');
      default:
        // No other enum value so won't get here so let's throw an error.
        console.log(
          'The updatePDD service returned an unknown response value of:',
          result,
        );
        throw new Error(
          'The updatePDD service returned an unknown response value',
        );
    }
  });

  const onDone = () => {
    return navigate(routes.ACCOUNT);
  };

  return (
    <Grid container spacing={2} direction={'column'}>
      <Grid item>
        <AccountDropdown />
      </Grid>
      <Grid item>
        <Card className={classes.card}>
          <CardContent>
            <Grid container direction="column" spacing={2}>
              <Grid item container direction={'column'} spacing={4}>
                {isEligible ? (
                  <>
                    <Grid
                      item
                      container
                      direction={!isMobile ? 'row' : 'column'}
                      spacing={4}
                      alignItems={'center'}
                      alignContent={'center'}
                    >
                      <Grid item>
                        <Typography variant={'body2'}>
                          {t('PDD_TEXT')}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <DaySelector
                          data-testid={'daySelector'}
                          value={selectedPDDay}
                          onChange={day => {
                            setSelectedPDDay(day);
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant={'body2'}>
                        {t('PDD_NOTE_TAKE_EFFECT')}
                      </Typography>
                      <br />
                      <Typography variant={'body2'}>
                        {t('PDD_NOTE_CHANGE_LIMIT')}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <IneligibleAlert message={ineligibleMsg} />
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} className={classes.actionArea}>
        {isEligible ? (
          <PGEButton
            onClick={onSubmit}
            className={classes.submitButton}
            variant="contained"
            color="primary"
            disabled={storedPDDay === selectedPDDay}
          >
            {t('CHANGE')}
          </PGEButton>
        ) : (
          <PGEButton
            onClick={onDone}
            className={classes.submitButton}
            variant="contained"
            color="primary"
          >
            {t('OK')}
          </PGEButton>
        )}
      </Grid>
    </Grid>
  );
};

interface IneligibleAlertProps {
  message: string;
}

const IneligibleAlert = (props: IneligibleAlertProps) => {
  const { t } = useTranslation();
  const { message } = props;
  const assistanceMsg1 = t('PDD_ASSISTANCE_PART_1');
  const assistanceMsg2 = t('PDD_ASSISTANCE_PART_2');
  const assistanceHours = t('ASSISTANCE_HOURS');
  const assistancePhone = t('CALL_FOR_ASSISTANCE_NUMBER');

  return (
    <Alerts
      disableColorOverride={true}
      isOpen
      severity={'error'}
      variant={'outlined'}
      message={
        <>
          <Typography data-testid={'ineligibleMsg'} variant="body2">
            {message}
          </Typography>
          <Typography variant="body2">
            {assistanceMsg1}{' '}
            {<a href={`tel:${assistancePhone}`}>{assistancePhone}</a>}{' '}
            {assistanceHours} {assistanceMsg2}
          </Typography>
        </>
      }
    />
  );
};
