import React from 'react';
import Box from '@material-ui/core/Box';
import {
  ServiceAddress,
  ServiceAgreementNickname,
  Maybe,
  PremiseInfo,
} from '../../../__generated__/pge-types';
import Typography from '@material-ui/core/Typography';
import compact from 'lodash/compact';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';
import Menu from '../../menu';
import {
  formatServiceAddress,
  toDateString,
  toSentenceCase,
} from '../../../util/format';
import { MenuItem } from '../../menu/Menu';
import { useTranslation } from '../../../hooks/useTranslation';
import { useGetSAStatusInfo } from './useGetSAStatusInfo';
import { useIsMobile } from '../../../util/style-utils';

type Props = {
  address: ServiceAddress & {
    encryptedAccountNumber: string;
    encryptedPersonId?: string;
    nickname: ServiceAgreementNickname;
  };
  onNicknameAddress?: (
    premiseInfo: PremiseInfo,
    encryptedSaId: string,
    nickName: string,
    accountSaStatus: string,
  ) => void;
  moreVertIconColor?: string;
  premiseInfo?: Maybe<PremiseInfo>;
  accountHasMultiplePremisesOrMultipleSA?: boolean;
  accountSaStatus?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    well: {
      backgroundColor: colors.lightGray2,
      borderRadius: theme.shape.borderRadius,
      display: 'flex',
      marginBottom: theme.spacing(2),
    },
    content: {
      padding: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    addressAndNickname: {
      marginRight: '20px',
      [theme.breakpoints.down('sm')]: {
        order: 1,
        marginRight: 'unset',
      },
    },
    statusInfo: {
      display: 'flex',
      justifyContent: 'space-between',
      marginLeft: 'auto',
      marginRight: '-20px',
      minWidth: '47%',
      [theme.breakpoints.down('sm')]: {
        marginLeft: 'unset',
      },
    },
    button: {
      marginTop: '-18px',
      height: '60px',
      width: '60px',
      [theme.breakpoints.down('sm')]: {
        marginTop: 'unset',
      },
    },
    addressText: {
      fontWeight: 'bold',
    },
    statusLabel: {
      fontWeight: 'bold',
    },
    statusDate: {
      color: colors.noirBlur,
    },
    hideForClosedAccount: {
      visibility: 'hidden',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    displayFlex: {
      display: 'flex',
    },
    mobileBottomMargin: {
      [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(2),
      },
    },
  }),
);

type TSADetailsList = {
  statusLabel: string;
  statusHeaderText: string;
  statusDate: Date;
  statusNickname: string;
  statusColor: string;
};

export default function ServiceAddressListItem({
  address,
  onNicknameAddress,
  moreVertIconColor,
  premiseInfo,
  accountHasMultiplePremisesOrMultipleSA,
  accountSaStatus,
}: Props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const saDetailsList: TSADetailsList[] = [];

  accountHasMultiplePremisesOrMultipleSA &&
    premiseInfo?.saDetails?.map(saDetails => {
      saDetails && saDetailsList.push(useGetSAStatusInfo(saDetails));
    });

  const isMobile = useIsMobile();

  const menuItems: Array<MenuItem<TSADetailsList>> = compact([
    onNicknameAddress && {
      text: toSentenceCase(t('NICKNAME_ADDRESS')),
      onClick: (value: any) => {
        return onNicknameAddress(
          premiseInfo!,
          value.encryptedSaId,
          value.statusNickname,
          accountSaStatus || '',
        );
      },
    },
  ]);

  return (
    <>
      {saDetailsList.map(saDetailsItem => (
        <Box
          className={classes.well}
          data-testid="account-status-inner-address"
        >
          <Box
            className={
              Boolean(saDetailsItem.statusHeaderText) || !isMobile
                ? `${classes.content} ${classes.displayFlex}`
                : classes.content
            }
          >
            <Box className={classes.addressAndNickname}>
              <Typography variant="body1" className={classes.addressText}>
                {formatServiceAddress(address)}
              </Typography>
              {Boolean(saDetailsItem.statusNickname) && (
                <Typography
                  variant="body1"
                  data-testid="account-status-inner-nick"
                >
                  {saDetailsItem.statusNickname}
                </Typography>
              )}
            </Box>
            <Box
              className={
                Boolean(saDetailsItem.statusHeaderText)
                  ? `${classes.statusInfo} ${classes.mobileBottomMargin}`
                  : classes.statusInfo
              }
            >
              <Box
                className={
                  Boolean(saDetailsItem.statusHeaderText)
                    ? undefined
                    : classes.hideForClosedAccount
                }
              >
                <Typography
                  component="p"
                  variant="body1"
                  className={classes.statusLabel}
                  style={{ color: `${saDetailsItem.statusColor}` }}
                  data-testid="account-status-inner-label"
                >
                  {saDetailsItem.statusLabel}
                </Typography>
                <Typography
                  component="span"
                  variant="body1"
                  className={classes.statusDate}
                >
                  {`${saDetailsItem.statusHeaderText} ${toDateString(
                    saDetailsItem.statusDate,
                  )}`}
                </Typography>
              </Box>
              {!isMobile && menuItems.length && (
                <Menu
                  items={menuItems}
                  value={saDetailsItem}
                  moreVertIconColor={moreVertIconColor}
                  className={classes.button}
                />
              )}
            </Box>
          </Box>
          {isMobile && menuItems.length && (
            <Menu
              items={menuItems}
              value={saDetailsItem}
              moreVertIconColor={moreVertIconColor}
              className={classes.button}
            />
          )}
        </Box>
      ))}
    </>
  );
}
