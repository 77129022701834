import React, { FunctionComponent } from 'react';
import {
  Typography,
  makeStyles,
  createStyles,
  useTheme,
  CardContent,
  Grid,
  Box,
  useMediaQuery,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { AccountsProps } from '../payment-form/PaymentForm.types';
import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import useClosedAccounts from './useClosedAccounts';
import AccountList from '../account-summary/multi-accounts/AccountList';
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountSearch from '../account-search';
import Pagination from '../pagination';
import Dropdown from '../dropdown';
import { AccountSort } from '../../__generated__/pge-types';
import { PageSize } from '../pagination/types';

type StyleProps = {
  showMini: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    contentWrapper: {
      // borderBottom: `1px solid ${colors.lightGray1}`,
      padding: theme.spacing(3, 0, 3, 0),
      '&:first-child': {
        padding: theme.spacing(0, 0, 3, 0),
        '& p': {
          margin: 0,
        },
      },
      '&:last-child': {
        borderBottom: 0,
        padding: theme.spacing(3, 0, 0, 0),
      },
    },
    actionArea: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: theme.spacing(2),
    },
    doneButton: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    root: {
      borderLeft: `4px solid ${theme.palette.grey.A700}`,
    },
    menuButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    col3: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '0em',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: '-2.25em',
        marginLeft: '5em',
      },
    },
    values: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    accountInfo: {},
    inactive: {
      [theme.breakpoints.up('md')]: {
        marginTop: '1em',
      },
    },
    closedAccContactDetails: {
      marginTop: '1.5em',
      '& a': {
        textDecoration: 'none',
        display: 'inline-block',
      },
    },
    spacing: {
      marginTop: '16px',
    },
    loader: {
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(3),
    },
    cardContentContainerWrapper: {
      padding: 'unset',
    },
    formArea: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      marginTop: theme.spacing(2),
      display: 'flex',
      flex: ({ showMini }: StyleProps) => (showMini ? 1 : 0),
      flexDirection: ({ showMini }: StyleProps) =>
        showMini ? 'column' : 'row',
      marginBottom: ({ showMini }: StyleProps) =>
        showMini ? theme.spacing(0) : undefined,
    },
    formControl: {
      flex: 1,
      '& .MuiOutlinedInput-input': {
        padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      },
      '& .MuiOutlinedInput-inputAdornEnd': {
        paddingRight: 0,
      },
      width: ({ showMini }: StyleProps) => (showMini ? '100%' : undefined),
      marginBottom: ({ showMini }: StyleProps) =>
        showMini ? theme.spacing(3) : undefined,
      '&:first-of-type': {
        marginRight: theme.spacing(1),
      },
    },
  }),
);

const ClosedAccounts: FunctionComponent<AccountsProps | any> = props => {
  const { t, richT } = useTranslation();
  const theme = useTheme();
  const showMini = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({ showMini });
  const pageSizes: PageSize[] = [5, 10, 15 as const];
  const {
    onAccountSearch,
    onAccountSort,
    onAccountPageChange,
    closedAccounts,
    totalCount,
    accountListParams,
    loading,
  } = useClosedAccounts();

  return (
    <CardContent className={classes.cardContentContainerWrapper}>
      <Grid direction="row" container className={classes.contentWrapper}>
        <Typography variant={'h1'}>{t('CLOSED_ACCOUNTS')}</Typography>
        <Typography variant={'h6'} className={classes.spacing}>
          {t('CLOSED_ACCOUNTS_DESCRIPTION')}
        </Typography>
        <Typography variant={'h6'} className={classes.closedAccContactDetails}>
          {richT('CLOSED_ACCOUNT_CONTACT_DETAILS', {
            RESIDENTIAL_CUSTOMER_SERVICE_NUMBER: t(
              'RESIDENTIAL_CUSTOMER_SERVICE_NUMBER',
            ),
            BUSINESS_CUSTOMER_SERVICE_NUMBER: t(
              'BUSINESS_CUSTOMER_SERVICE_NUMBER',
            ),
          })}
        </Typography>
      </Grid>
      <Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className={classes.formArea}>
              <div className={classes.formControl}>
                <AccountSearch
                  onSearch={(search: string) => {
                    onAccountSearch(search);
                  }}
                  value={accountListParams.query}
                />
              </div>
              <div className={classes.formControl}>
                <Dropdown
                  label={t('SORT_BY')}
                  value={accountListParams.sortBy}
                  selectionList={[
                    { value: 'DEFAULT', label: t('DEFAULT') },
                    { value: 'ACCOUNTNUMBER', label: t('ACCOUNT_NUMBER') },
                  ]}
                  onChange={e => onAccountSort(e.target.value as AccountSort)}
                />
              </div>
            </div>
          </Grid>

          {totalCount > pageSizes[0] && (
            <Grid item xs={12}>
              <Box className={classes.formControl} flexGrow={1}>
                <Pagination
                  page={accountListParams.page}
                  pageSize={accountListParams.pageSize}
                  count={totalCount}
                  pageSizes={pageSizes}
                  onPageChange={(newPage: number, newPageSize: PageSize) => {
                    onAccountPageChange(newPage, newPageSize);
                  }}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {!loading ? (
          <AccountList accounts={closedAccounts || []} />
        ) : (
          <Box className={classes.loader}>
            <CircularProgress />
          </Box>
        )}
      </Grid>
    </CardContent>
  );
};

export default ClosedAccounts;
