import React from 'react';
import { TimeOfDayCardType } from '../../../../__generated__/pge-types';
import { useTranslation } from '../../../../hooks/useTranslation';
import { TimeOfDay as TTimeOfDay } from '../../types';
import { Card } from '../Card';
import routes from '../../../../routes';
import { TimeOfDayEvents } from './Events';
import { TimeOfDayInfo } from './PieChartInfo';
import TimeOfDayClockIcon from '../../../../images/Time-Of-Day-Clock.png';
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    img: {
      width: 'inherit',
    },
  }),
);

export function TimeOfDay(props: TTimeOfDay) {
  const {
    cardType,
    planSavings,
    offPeakCharges,
    midPeakCharges,
    onPeakCharges,
    annualLookBackEarnedCredit,
  } = props;
  const { t, richT, inlineRichT } = useTranslation();
  const classes = useStyles();
  const generateChartData = () => {
    return [
      {
        name: `$${offPeakCharges} ${t('TOD_OFF_PEAK')}`,
        value: offPeakCharges || 0.0,
      },
      {
        name: `$${midPeakCharges} ${t('TOD_MID_PEAK')}`,
        value: midPeakCharges || 0.0,
      },
      {
        name: `$${onPeakCharges} ${t('TOD_ON_PEAK')}`,
        value: onPeakCharges || 0.0,
      },
    ];
  };

  if (cardType === TimeOfDayCardType.FirstMonthBill) {
    return (
      <Card
        type="full"
        theme="blue"
        title={t('TIME_OF_DAY_HEADER')}
        sectionA={richT('TOD_FIRST_MONTH_SUBTEXT')}
        ctaLink={routes.TIME_OF_DAY_PDF}
        isCtaExternal={true}
        ctaText={t('TOD_FIRST_MONTH_LINK')}
        sectionB={<img src={TimeOfDayClockIcon} className={classes.img} />}
      />
    );
  }

  if (cardType === TimeOfDayCardType.MonthlyBillSavers) {
    return (
      <Card
        type="full"
        theme="blue"
        title={t('TIME_OF_DAY_HEADER')}
        sectionA={inlineRichT('TOD_MONTHLY_SAVERS_SUBTEXT', {
          PLAN_SAVINGS: planSavings?.toString(),
        })}
        ctaLink={routes.TIMEOFDAY}
        ctaText={t('TOD_MONTHLY_SAVERS_LINK')}
        sectionB={<TimeOfDayEvents chartInfo={generateChartData()} />}
      />
    );
  }

  if (cardType === TimeOfDayCardType.CuspSavers) {
    return (
      <Card
        type="full"
        theme="blue"
        title={t('TIME_OF_DAY_HEADER')}
        sectionA={
          <TimeOfDayInfo
            initialText={richT('TOD_CUSP_SAVERS_SUBTEXT', {
              PLAN_SAVINGS: planSavings?.toString(),
            })}
            initialTextCTATitle={t('TOD_FIRST_MONTH_LINK')}
            initialTextCTALink={routes.TIME_OF_DAY_PDF}
            initialTextCTAIsExternal={true}
          />
        }
        ctaLink={routes.TIMEOFDAY}
        ctaText={t('TOD_MONTHLY_SAVERS_LINK')}
        sectionB={<TimeOfDayEvents chartInfo={generateChartData()} />}
      />
    );
  }

  if (cardType === TimeOfDayCardType.MonthlyBillNonSavers) {
    return (
      <Card
        type="full"
        theme="blue"
        title={t('TIME_OF_DAY_HEADER')}
        sectionA={
          <TimeOfDayInfo
            initialText={t('TOD_NON_SAVERS_SUBTEXT1')}
            initialTextCTATitle={t('TOD_FIRST_MONTH_LINK')}
            initialTextCTALink={routes.TIME_OF_DAY_PDF}
            middleText={t('TOD_NON_SAVERS_SUBTEXT2')}
            initialTextCTAIsExternal={true}
          />
        }
        ctaLink={routes.TIMEOFDAY}
        ctaText={t('TOD_MONTHLY_SAVERS_LINK')}
        sectionB={<TimeOfDayEvents chartInfo={generateChartData()} />}
      />
    );
  }

  if (cardType === TimeOfDayCardType.FirstYearGuaranteedBillCredit) {
    return (
      <Card
        type="full"
        theme="blue"
        title={t('TIME_OF_DAY_HEADER')}
        sectionA={richT('TOD_FIRST_YEAR_GUARANTEED_SUBTEXT1', {
          EARNED_CREDIT: annualLookBackEarnedCredit?.toString(),
        })}
        ctaLink={routes.MY_PRICING_PLAN}
        ctaText={t('TOD_FIRST_YEAR_GUARANTEED_LINK')}
        sectionB={<TimeOfDayEvents chartInfo={generateChartData()} />}
      />
    );
  }

  if (cardType === TimeOfDayCardType.Unenrolled) {
    return (
      <Card
        type="full"
        theme="blue"
        title={t('TIME_OF_DAY_HEADER')}
        sectionA={
          <TimeOfDayInfo
            initialText={t('TOD_UNENROLLED_SUBTEXT')}
            initialTextCTATitle={richT('TOD_UNENROLLED_LINK')}
            initialTextCTALink={routes.ACCOUNT}
          />
        }
        ctaLink={routes.TIMEOFDAY}
        ctaText={t('TOD_UNENROLLED_LINK2')}
        sectionB={<TimeOfDayEvents chartInfo={generateChartData()} />}
      />
    );
  }

  if (cardType === TimeOfDayCardType.None) {
    return <></>;
  }

  return <></>;
}
