import { useTranslation } from '../../../hooks/useTranslation';
import { isValidEmail } from '../../../util/form-validation';
import { parsePhoneNumber } from '../../../util/format';

const { t } = useTranslation();

const validateName = (name = ''): string | null => {
  if (!name) {
    return t('CONTACT_INFO_FULL_NAME_ERROR');
  }
  return null;
};

const validateTitle = (title = ''): string | null => {
  if (!title) {
    return t('CONTACT_INFO_TITLE_ERROR');
  }
  return null;
};

const validateEmail = (email = ''): string | null => {
  if (!email) {
    return t('CONTACT_INFO_EMAIL_ADDRESS_EMPTY_ERROR');
  }
  if (!isValidEmail(email)) {
    return t('CONTACT_INFO_EMAIL_ADDRESS_INVALID_ERROR');
  }
  return null;
};

const validatePhone = (phone = ''): string | null => {
  const parsedPhoneNumber = parsePhoneNumber(phone);
  if (parsedPhoneNumber.length < 10) {
    return t('CONTACT_INFO_PHONE_NUMBER_ERROR');
  }
  return null;
};

export default (eventType = 'change') => ({
  name: validateName,
  title: validateTitle,
  email: validateEmail,
  phone: validatePhone,
});
