import gql from 'not-graphql-tag';

export const getAccounDetailsForPeakTimeRebateGiveBackQuery = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      peakTimeRebateGiveBack {
        isEligible
        peakTimeSeason
        organizationName
      }
    }
  }
`;

export const peakTimeRebateGiveBackMutation = gql`
  mutation submitPeakTimeRebateGiveBack(
    $payload: PeakTimeRebateGiveBackDetailsInput!
  ) {
    submitPeakTimeRebateGiveBack(payload: $payload) {
      success
    }
  }
`;
