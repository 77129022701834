import { validateEmail } from '../../util/form-validation';
import { Model } from './useChangeEmailForm';
import { BaseValidationContext } from '../../hooks/useFormState.types';

const validationRules = (
  values: Model,
  context: BaseValidationContext<Model>,
) => {
  return {
    newEmail: validateEmail(values.newEmail),
  };
};

export default validationRules;
