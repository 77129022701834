import React, { FunctionComponent } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Box,
} from '@material-ui/core';
import {
  useTheme,
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';
import EmailTextField from '../email-text-field';
import { useTranslation } from '../../hooks/useTranslation';
import TextLink from '../text-link';
import PGEButton from '../buttons';
import useChangeEmailForm from './useChangeEmailForm';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import { isValidEmail } from '../../util/form-validation';
import ROUTES from '../../routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    currentEmailAddress: {
      fontWeight: 'bold',
      marginLeft: theme.spacing(0),
      color: theme.palette.primary.main,
    },
    cancelLink: {
      fontSize: '1.1rem',
      marginLeft: -8,
    },
  }),
);

const ChangeEmailForm: FunctionComponent = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();

  // Get Customer's email address:
  const { form, onSubmit, customer } = useChangeEmailForm();
  const currentEmail = customer?.email;

  const { wrapWithLoader } = useWrapWithLoader();
  const handleSubmit = wrapWithLoader(
    form.submit(async () => {
      await onSubmit(form.values.newEmail);
    }),
  );
  const isValid =
    Boolean(form.values.newEmail) && isValidEmail(form.values.newEmail);

  return (
    <Card>
      <CardContent>
        <form noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Typography variant={'h2'}>{t('CURRENT_EMAIL')}</Typography>
              <Typography variant={'subtitle1'}>
                {t('CHANGE_EMAIL_COPY')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.currentEmailAddress}>
                <span data-testid={'currentEmailText'}>{currentEmail}</span>
              </Typography>
            </Grid>
            <Grid item>
              <Divider light />
            </Grid>
            <Grid item>
              <Typography variant={'h2'}>
                {t('CHANGE_EMAIL_NEW_TITLE')}
              </Typography>
            </Grid>
            <Grid item md={6}>
              <EmailTextField
                margin="none"
                name={'newEmail'}
                label={t('EMAIL_ADDRESS')}
                data-testid={'newEmailTextField'}
                {...form.props('newEmail')}
              />
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                padding={`0 ${theme.spacing(1)}px`}
              >
                <TextLink to={ROUTES.ACCOUNT} plain>
                  <span className={classes.cancelLink}>{t('CANCEL')}</span>
                </TextLink>
                <PGEButton
                  data-testid="submitChangeEmailButton"
                  onClick={handleSubmit}
                  disabled={!isValid}
                  size="large"
                >
                  {t('CHANGE')}
                </PGEButton>
              </Box>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
};

export default ChangeEmailForm;
