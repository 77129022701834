import {
  CardContent,
  Paper,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { navigate } from 'gatsby';
import { FullDivider } from '../pge-plus-common/FullDivider';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      cursor: 'pointer',
    },
    cardDetails: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    cardContent: {
      paddingBottom: '5px !important',
    },
    title: {
      fontFamily: 'Forma-DJR-Display',
      marginTop: '-5px',
      marginBottom: '5px',
      fontWeight: 100,
      fontSize: '24px',
    },
    subText: {
      margin: 0,
      marginBottom: '15px',
      fontSize: '16px',
    },
    imgContainer: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    cardFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '95%',
      cursor: 'pointer',
    },
    cardFooterText: {
      margin: 0,
    },
    arrow: {
      marginLeft: '30px',
      marginTop: '-2px',
      alignContent: 'center',
    },
  }),
);

const handleClick = (path: string) => {
  void navigate(path);
};

export const DashboardStaticCard = ({
  title,
  subText,
  footerText,
  image,
  path,
}: {
  title: string;
  subText: string;
  footerText: string;
  image: React.ReactNode;
  path: string;
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.paper}>
      <CardContent className={classes.cardContent}>
        <div className={classes.cardDetails} onClick={() => handleClick(path)}>
          <h2 className={classes.title} data-testid="title">
            {title}
          </h2>
          <p className={classes.subText} data-testid="subtext">
            {subText}
          </p>
          <div className={classes.imgContainer}>{image}</div>
          <FullDivider />
          <div className={classes.cardFooter} onClick={() => handleClick(path)}>
            <p className={classes.cardFooterText} data-testid="footer">
              {footerText}
            </p>
            <div className={classes.arrow}>
              <ChevronRightIcon />
            </div>
          </div>
        </div>
      </CardContent>
    </Paper>
  );
};
