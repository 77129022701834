/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import colors from '../../themes/main-colors';

type Props = {
  isOpen: boolean;
  closeTooltip: () => void;
  anchorEl: HTMLElement | null;
  content: React.ReactNode;
};

const PopoverCard = ({ isOpen, anchorEl, closeTooltip, content }: Props) => {
  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={closeTooltip}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      PaperProps={{
        style: {
          maxWidth: 341,
          backgroundColor: colors.linenWhite,
        },
      }}
      disableRestoreFocus
    >
      <Grid container spacing={0} direction="row-reverse">
        <Grid item>
          <IconButton
            size="medium"
            onClick={closeTooltip}
            css={{
              position: 'absolute',
              top: 8,
              right: 8,
              padding: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item>
          <Box padding="24px" fontSize="16px">
            {content}
          </Box>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default PopoverCard;
