import gql from 'not-graphql-tag';
import { AccountDetail, AccountDetailParams } from '../__generated__/pge-types';
import { useApolloClient } from '@apollo/react-hooks';

export const getAccountDetails = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      equalpay {
        paymentPlanType
      }
    }
  }
`;

export interface PaymentPlanType {
  paymentPlanType: string;
}

export interface Equalpay {
  equalpay: PaymentPlanType;
}

export const useGetAccountEligibilityQuery = () => {
  const appolloClient = useApolloClient();
  async function isEqualPay(accountId: string, encryptedPersonId: string) {
    const params = {
      accountNumberList: [
        { accountNumber: accountId, encryptedPersonId: encryptedPersonId },
      ],
    };
    const data = await appolloClient.query<
      { getAccountDetails: Array<AccountDetail> },
      { params: AccountDetailParams }
    >({
      query: getAccountDetails,
      variables: { params },
      fetchPolicy: 'network-only',
    });
    return data?.data?.getAccountDetails[0];
  }
  return {
    isEqualPay,
  };
};

export default useGetAccountEligibilityQuery;
