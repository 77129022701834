import React, { useEffect, useRef, useMemo } from 'react';
import { navigate } from 'gatsby';
import { useTheme, Paper, Grid, Typography } from '@material-ui/core';
import { IMoveServiceCustomerFormModel } from './MoveServiceForm.rules';
import { PersonalIdentificationType } from '../../__generated__/pge-types';
import Backdrop from '../backdrop';
import { useTranslation } from '../../hooks/useTranslation';
import { BaseFormModel } from '../../hooks/useFormState.types';
import colors from '../../themes/main-colors';
import { NextBackOrCancel } from '../next-back-or-cancel';
import EmailTextField from '../email-text-field';
import { CustomerIdentity } from '../customer-identity';
import { CustomerInfo } from '../customer-info';
import { VerifyCustomerIdentity } from '../verify-customer-identity';
import useGetCoCustomerPersonDetails from '../../hooks/useGetCoCustomerPersonDetails';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import useIsPersonalIdValid from '../../hooks/useIsPersonalIdValid';
import ROUTES from '../../routes';
import { isAtLeastEighteenYearsOfAge } from '../../util/form-validation';

type Props = {
  path: string;
  handleSubmit: (values: IMoveServiceCustomerFormModel) => Promise<void>;
  goToStep: (step: number) => Promise<void>;
  form: BaseFormModel;
  coCustomerData?: any;
  currentStep: number;
  accountNumber: string;
};

export const MoveServiceCoCustomerInfo = ({
  handleSubmit,
  goToStep,
  form,
  coCustomerData,
  currentStep,
  accountNumber,
}: Props) => {
  const { t } = useTranslation();

  const theme = useTheme();

  const idErrorCount = useRef(0);

  const { validateId } = useIsPersonalIdValid();

  const { wrapWithLoader } = useWrapWithLoader();

  const selectedCoCustomer: any = useMemo(() => {
    if (Object.keys(coCustomerData).length && form.values.encryptedPersonId) {
      return Object.values(coCustomerData).find(
        (c: any) => c.encryptedPersonId === form.values.encryptedPersonId,
      );
    }
    return null;
  }, [coCustomerData, form]);

  const { coCustomerDetails, loading } = useGetCoCustomerPersonDetails(
    selectedCoCustomer?.encryptedPersonId || '',
  );

  useEffect(() => {
    return () => {
      idErrorCount.current = 0;
    };
  }, []);

  useEffect(() => {
    const formValues = {
      ...form.values,
    };

    if (selectedCoCustomer.personalIdentificationType) {
      formValues.primaryIdType = selectedCoCustomer.personalIdentificationType;
    }

    form.reset(formValues);
  }, [coCustomerDetails, selectedCoCustomer.personalIdentificationType]);

  useEffect(() => {
    if (!loading && idErrorCount?.current === 0) {
      idErrorCount.current = 0;
    }
  }, [loading]);

  if (loading) {
    return <Backdrop forceOpen />;
  }

  if (!selectedCoCustomer) {
    return null;
  }

  return (
    <>
      <Paper style={{ padding: theme.spacing(2), color: colors.noirBlur }}>
        <Typography variant="h2">
          {t('PLEASE_CONFIRM_CO_CUSTOMER_INFORMATION')}
        </Typography>
        <Typography
          variant="body1"
          style={{
            textAlign: 'center',
            background: colors.lightGray2,
            padding: 15,
            marginBottom: 25,
            marginTop: 25,
          }}
        >
          {[
            selectedCoCustomer.firstName,
            selectedCoCustomer.middleName || '',
            selectedCoCustomer.lastName,
          ].join(' ')}
        </Typography>
        {form.values.primaryIdType &&
        [
          PersonalIdentificationType.Dl,
          PersonalIdentificationType.Ssn,
          PersonalIdentificationType.Ortrib,
          PersonalIdentificationType.Visa,
          PersonalIdentificationType.Military,
          PersonalIdentificationType.Resalien,
          PersonalIdentificationType.Pssprt,
          PersonalIdentificationType.Itin,
        ].includes(form.values.primaryIdType) &&
        form.values.primaryIdType !== PersonalIdentificationType.None ? (
          <VerifyCustomerIdentity
            form={form}
            primaryIdType={form.values.primaryIdType}
            showInstructionText={false}
            isCoCustomer={true}
          />
        ) : (
          <CustomerIdentity form={form} />
        )}
        <div
          style={{
            marginTop: theme.spacing(2),
            borderTop: `1px solid ${colors.lightGray1}`,
            paddingTop: theme.spacing(2),
          }}
        >
          <CustomerInfo
            nameError={''}
            showNameFields={false}
            form={form}
            headerText={t('CONTACT_DETAILS')}
            useAltPhone={form.values.useAltPhone}
            setUseAltPhone={async () => {
              await form.setValue('useAltPhone', !form.values.useAltPhone);
            }}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <EmailTextField
                label={t('EMAIL_ADDRESS')}
                style={{ width: '100%' }}
                name="email"
                value={form.values.email}
                error={Boolean(form.errors.email)}
                onChange={form.onChange}
                onBlur={form.onBlur}
                helperText={form.errors.email}
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
      <div style={{ margin: 25 }}>
        <NextBackOrCancel
          backHandler={async () => {
            if (currentStep === 2.4) {
              await goToStep(2.2);
            } else {
              await goToStep(2);
            }
          }}
          cancelRoute={ROUTES.ACCOUNT}
          nextDisabled={false}
          nextHandler={wrapWithLoader(
            form.submit(async (data: IMoveServiceCustomerFormModel) => {
              if (
                data.birthdate &&
                !isAtLeastEighteenYearsOfAge(data.birthdate)
              ) {
                return navigate(ROUTES.START_SERVICE_ASSISTANCE);
              }
              if (
                selectedCoCustomer.personalIdentificationType &&
                selectedCoCustomer.personalIdentificationType !==
                  PersonalIdentificationType.None &&
                selectedCoCustomer.encryptedPersonId
              ) {
                const isValid = await validateId({
                  encryptedPersonId: selectedCoCustomer.encryptedPersonId,
                  idNumber: form.values.idValue,
                });

                if (!isValid) {
                  idErrorCount.current += 1;
                  if (idErrorCount.current < 2) {
                    return form.setError('idValue', t('ID_INVALID'));
                  }

                  return navigate(ROUTES.MOVE_SERVICE_ASSISTANCE);
                }
              }

              return handleSubmit(data);
            }),
          )}
        />
      </div>
    </>
  );
};
