import { useApolloClient, useMutation } from '@apollo/react-hooks';
import {
  AccountDetail,
  Mutation,
  DefaultAccountInfo,
  GroupType,
  AccountCustomer,
} from '../__generated__/pge-types';
import { updateAccountDetails } from '../queries/account.query';
import useSelectedGroupId from './useSelectedGroupId';
import useAccountCustomer, {
  getAccountInfoWithParams,
} from './useAccountCustomer';
import useWrapWithLoader from './useWrapWithLoading';

export default function useUpdateDefaultAccount() {
  const { selectedGroupId } = useSelectedGroupId();
  const client = useApolloClient();
  const { groups, writeCustomerCache } = useAccountCustomer();
  const { wrapWithLoader } = useWrapWithLoader();

  const [update, { loading: savingDefaultAccount }] = useMutation<Mutation>(
    updateAccountDetails,
  );

  const updateDefaultAccount = wrapWithLoader(
    async (account: AccountDetail, groupId?: string) => {
      await update({
        variables: {
          payload: {
            accountNumber: account.accountNumber,
            isDefault: true,
            groupId: groupId || selectedGroupId,
          },
        },
      });

      // First get all accounts in cache
      const accounts = Object.values(client.cache.extract()).filter(
        item => item.__typename === 'AccountDetail',
      ) as Array<AccountDetail>;

      // Set isDefault on the right account, and set the rest to false
      accounts.forEach(currentAccount => {
        return client.writeData({
          id: `AccountDetail:${currentAccount.accountNumber}`,
          data: {
            isDefault: currentAccount.accountNumber === account.accountNumber,
          },
        });
      });

      const group = groups.find(
        grp => grp.groupId === (groupId || selectedGroupId),
      )!;

      // If the group type is virtual, like the view all group,
      // we need to reload the customer to figure out which group the backend
      // set the default account on. Querying the customer will update the
      // cache with the correct value for us. Otherwise we can handle updating
      // the cache ourselves.
      if (group.type === GroupType.Virtual) {
        return client.query<{ getAccountInfo: AccountCustomer }>({
          query: getAccountInfoWithParams,
          variables: {
            params: {
              removeInactiveAccounts: false,
            },
          },
          fetchPolicy: 'network-only',
        });
      } else {
        // set the default account on the group, and remove the rest
        const newGroups = groups.map(grp => {
          if (grp.groupId === (groupId || selectedGroupId)) {
            return {
              ...grp,
              isDefaultAccountExists: true,
              defaultAccount: {
                __typename: 'DefaultAccountInfo',
                accountNumber: account.accountNumber,
                encryptedPersonId: account.encryptedPersonId,
                encryptedAccountNumber: account.encryptedAccountNumber,
              } as DefaultAccountInfo,
            };
          } else {
            return {
              ...grp,
              isDefaultAccountExists: false,
              defaultAccount: undefined,
            };
          }
        });

        // Write the new groups to the customer
        writeCustomerCache({ groups: newGroups });
      }
    },
  );

  return {
    updateDefaultAccount,
    savingDefaultAccount,
  };
}
