import React, {
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from 'react';
import { navigate } from 'gatsby';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  useTheme,
  makeStyles,
  Theme,
} from '@material-ui/core';

import useRenewablePower from '../../../hooks/useRenewablePower';
import { isMobile } from '../../../util/style-utils';
import Link from '../../text-link';
import PGEButton from '../../buttons';
import ROUTES from '../../../routes';
import {
  RenewablePowerContext,
  RenewablePowerContextState,
  RenewablePowerContextActionType,
} from '../../../providers/RenewablePowerProvider';
import Alerts from '../../alerts';
import { cloneDeep } from 'lodash';

interface Record {
  price: number;
  quantity?: number;
  text: string;
}

interface Section {
  label: string;
  records: Record[];
}

const getText = (record: Record) => {
  return record.text
    .replace('<price>', record.price.toFixed(2))
    .replace('<quantity>', `${record.quantity}`)
    .replace('<total>', (record.price * (record.quantity || 1)).toFixed(2));
};

const prepareData = (state: RenewablePowerContextState, content: any) => {
  const added: Record[] = [];
  const cancelled: Record[] = [];
  const kept: Record[] = [];
  let dest: Record[] | undefined = undefined;
  if (state.greenSource.isEnrolled && state.greenSource.isSelected) {
    dest = kept;
  }
  if (state.greenSource.isEnrolled && !state.greenSource.isSelected) {
    dest = cancelled;
  }
  if (!state.greenSource.isEnrolled && state.greenSource.isSelected) {
    dest = added;
  }
  dest &&
    dest.push({
      price: state.greenSource.price,
      text: content.confirmPage.greenSource,
    });

  dest = undefined;
  let qty: number | undefined = undefined;
  const diffQty = state.cleanWind.enrolledBlocks !== state.cleanWind.blocks;

  if (state.cleanWind.isEnrolled && state.cleanWind.isSelected && diffQty) {
    dest = added;
    qty = state.cleanWind.blocks;
  }
  if (state.cleanWind.isEnrolled && state.cleanWind.isSelected && !diffQty) {
    dest = kept;
    qty = state.cleanWind.blocks;
  }
  if (state.cleanWind.isEnrolled && !state.cleanWind.isSelected) {
    dest = cancelled;
    qty = state.cleanWind.enrolledBlocks;
  }
  if (!state.cleanWind.isEnrolled && state.cleanWind.isSelected) {
    dest = added;
    qty = state.cleanWind.blocks;
  }
  dest &&
    dest.push({
      price: state.cleanWind.price,
      quantity: qty,
      text: content.confirmPage.cleanWind,
    });

  dest = undefined;
  if (state.solarSource.isEnrolled && state.solarSource.isSelected) {
    dest = kept;
  }
  if (state.solarSource.isEnrolled && !state.solarSource.isSelected) {
    dest = cancelled;
  }
  if (!state.solarSource.isEnrolled && state.solarSource.isSelected) {
    dest = added;
  }
  dest &&
    dest.push({
      price: state.solarSource.price,
      text: content.confirmPage.greenSolar,
    });

  dest = undefined;
  if (state.habitatSupport.isEnrolled && state.habitatSupport.isSelected) {
    dest = kept;
  }
  if (state.habitatSupport.isEnrolled && !state.habitatSupport.isSelected) {
    dest = cancelled;
  }
  if (!state.habitatSupport.isEnrolled && state.habitatSupport.isSelected) {
    dest = added;
  }
  dest &&
    dest.push({
      price: state.habitatSupport.price,
      text: content.confirmPage.habitat,
    });

  const sections: Section[] = [
    {
      label: content.confirmPage.addedOptionsLabel,
      records: added,
    },
    {
      label: content.confirmPage.cancelledOptionsLabel,
      records: cancelled,
    },
    {
      label: content.confirmPage.keptOptionsLabel,
      records: kept,
    },
  ];

  return sections;
};

const useStyles = makeStyles((theme: Theme) => ({
  actionArea: {
    [theme.breakpoints.down('sm')]: {
      padingLeft: theme.spacing(4),
      padingRight: theme.spacing(4),
    },
  },
  formAction: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cardContent: {
    '& > .MuiCardContent-root > .MuiCollapse-container > .MuiCollapse-wrapper': {
      '& .MuiCollapse-wrapperInner > .MuiAlert-standardSuccess': {
        backgroundColor: '#fff !important',
      },
    },
  },
}));

interface RenewablePowerConfirmProps {
  path?: string;
  content: any;
  onSubmit: (data: RenewablePowerContextState) => void;
}

const RenewablePowerConfirm: FunctionComponent<RenewablePowerConfirmProps> = ({
  content,
  onSubmit,
}) => {
  const { state, dispatch } = useContext(RenewablePowerContext);
  const theme = useTheme();
  const classes = useStyles();
  const sections = prepareData(state, content);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleConfirm = () => {
    const finalState = cloneDeep(state);
    setIsSubmitting(true);
    dispatch({
      type: RenewablePowerContextActionType.SUBMIT,
      data: state,
    });
    onSubmit(finalState);
  };

  useEffect(() => {
    !isSubmitting && state.isSubmitted && navigate(ROUTES.ACCOUNT);
  }, [state]);

  return (
    <Grid container direction={'column'} spacing={4}>
      <Grid item>
        <Card className={classes.cardContent}>
          <CardContent style={{ padding: theme.spacing(2) }}>
            <>
              <Grid
                container
                style={{ padding: theme.spacing(1) }}
                direction="column"
              >
                <Grid item>
                  <Typography
                    variant="h3"
                    style={{ marginBottom: theme.spacing(1) }}
                  >
                    {content.confirmPage.subtitle}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {content.confirmPage.note}
                  </Typography>
                </Grid>
                {sections.map(section =>
                  !section.records?.length ? null : (
                    <>
                      <Grid item>
                        <Typography variant="body2">
                          <strong>{section.label}</strong>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container direction="column">
                          {section.records.map(record => (
                            <Grid item>
                              <Typography variant="body2">
                                {getText(record)}
                              </Typography>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </>
                  ),
                )}
              </Grid>
            </>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        container
        style={
          isMobile()
            ? { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }
            : {}
        }
        direction={isMobile() ? 'column-reverse' : 'row'}
        justify={'space-between'}
        alignItems={isMobile() ? 'stretch' : 'center'}
        spacing={1}
      >
        <Grid item>
          <Grid container justify={'center'}>
            <Link plain to={ROUTES.ACCOUNT}>
              <Typography className={classes.formAction}>
                {content.cancel}
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            direction={isMobile() ? 'column-reverse' : 'row'}
            spacing={2}
          >
            <Grid item>
              <PGEButton
                variant={'outlined'}
                color={'secondary'}
                onClick={() => navigate(ROUTES.RENEWABLE_POWER)}
                className={classes.formAction}
              >
                {content.back}
              </PGEButton>
            </Grid>
            <Grid item>
              <PGEButton
                variant={'contained'}
                color={'primary'}
                onClick={handleConfirm}
                className={classes.formAction}
              >
                {content.confirm}
              </PGEButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RenewablePowerConfirm;
