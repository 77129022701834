import { navigate } from 'gatsby';
import { useContext } from 'react';

import ROUTES from '../routes';
import validationRules, {
  Model,
} from '../components/change-password/ChangePassword.rules';
import { updatePasswordMutation } from '../queries/changePassword.query';
import { useApolloClient } from '@apollo/react-hooks';
import useFormState from './useFormState';
import { NotificationsContext } from '../providers/NotificationsProvider';

export const defaultFormValues: Model = {
  password: '',
};
import { useTranslation } from '../hooks/useTranslation';
import useAuth from './useAuth';
import { useLocation } from '@reach/router';

type RouterState = {
  redirectUrl?: string;
};

export default () => {
  const apolloClient = useApolloClient();
  const notificationContext = useContext(NotificationsContext);
  const { t } = useTranslation();
  const { signInWithCustomToken } = useAuth();
  let signInToken: string = '';
  const location = useLocation();
  const redirectUrl =
    ((location.state || {}) as RouterState)?.redirectUrl || undefined;

  const form = useFormState(defaultFormValues, {
    validate: validationRules,
    validationContext: {
      apolloClient,
    },
  });

  const doSubmit = async (
    data: any,
    encryptedPersonId: string | null,
  ): Promise<void> => {
    await apolloClient
      .mutate({
        mutation: updatePasswordMutation,
        variables: {
          payload: {
            password: data.password,
            encryptedPersonId: encryptedPersonId,
          },
        },
      })
      .then(result => {
        if (!result?.data.updatePassword.success) {
          onFailure();
        } else {
          signInToken = result?.data?.updatePassword?.signInToken || '';
        }
      });
    return Promise.resolve();
  };

  const onFailure = (): void => {
    notificationContext.setState({
      isOpen: true,
      message: t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
      severity: 'error',
    });
  };

  const onSuccess = async (): Promise<void> => {
    notificationContext.setState({
      isOpen: true,
      severity: 'success',
      variant: 'filled',
      message: t('CHANGE_PASSWORD_SUCCESS'),
    });
    await signInWithCustomToken(signInToken);

    if (redirectUrl) {
      return navigate(redirectUrl);
    }

    return navigate(ROUTES.ACCOUNT);
  };

  return {
    form,
    doSubmit,
    onSuccess,
  };
};
