/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import { useState, useContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import PhoneTextField from '../../phone-text-field';
import Close from '@material-ui/icons/Close';
import Buttons from '../../buttons';

import useFormState, {
  convertValidationRules,
} from '../../../hooks/useFormState';
import { validatePhone } from '../../../util/form-validation';
import {
  PhoneType,
  UpdateProfilePhoneInputDtl,
} from '../../../__generated__/pge-types';
import {
  displayPhoneNumberFormat,
  parsePhoneNumber,
} from '../../../util/format';
import apolloClient from '../../../lib/apolloClient';
import Backdrop from '../../backdrop';
import { useTranslation } from '../../../hooks/useTranslation';
import { NotificationsContext } from '../../../providers/NotificationsProvider';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import gql from 'not-graphql-tag';
import colors from '../../../themes/main-colors';
import { CheckBoxes } from '../../pge-plus-common/CheckBoxes';
import theme from '../../../themes/theme';
import { PageTextContentType } from '../../../hooks/useContentMgmt';

const updateTextPhoneMutation = gql`
  mutation UpdateUserProfilePhone($payload: updateProfilePhoneInputDtl!) {
    updateUserProfilePhone(payload: $payload)
  }
`;
interface Props {
  phoneNumber: string;
  open: boolean;
  content: PageTextContentType;
  onClose: () => void;
  onUpdate: (phoneNumber: string) => void;
}
interface UpdatePhoneFormType {
  phoneNumber: string;
  consent: string;
}

export default ({ phoneNumber, open, content, onClose, onUpdate }: Props) => {
  const form = useFormState(
    { phoneNumber, consent: '' },
    {
      validate: createValidateFunction(),
    },
  );
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const notificationContext = useContext(NotificationsContext);
  const { customer } = useAccountCustomer();

  const handleSave = async (values: UpdatePhoneFormType) => {
    setLoading(true);
    const updatePhonePayload: UpdateProfilePhoneInputDtl = {
      encryptedPersonId: customer?.encryptedPersonId || '', // customerInfoData?.encryptedPersonId!,
      phoneNumber: '+1' + parsePhoneNumber(values.phoneNumber),
      phoneType: PhoneType.PrimaryNotificationPhone,
      isPrimary: true,
    };
    try {
      const { data: updatePhoneData, errors } = await apolloClient.mutate<{
        updateTextPhone: boolean;
      }>({
        mutation: updateTextPhoneMutation,
        variables: {
          payload: updatePhonePayload,
        },
        refetchQueries: ['getAccountInfo'],
      });
      if (updatePhoneData) {
        setNotificationMessage(content.get('UPDATE_PHONE_NUMBER_SUCCESS'));
        onUpdate(displayPhoneNumberFormat(values.phoneNumber));
      } else {
        setNotificationMessage(
          t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
          'error',
        );
        return false;
      }
      setLoading(false);
      onClose();
    } catch (e) {
      console.log(e);
      setNotificationMessage(
        t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
        'error',
      );
      setLoading(false);
      onClose();
      return false;
    }
  };
  const setNotificationMessage = (
    message: string | React.ReactNode = '',
    severity: 'success' | 'error' = 'success',
  ) => {
    notificationContext.setState({
      isOpen: true,
      message,
      severity,
    });
    window.scrollTo(0, 0);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="body"
      maxWidth="xs"
      disableRestoreFocus={true}
    >
      <DialogTitle>
        <Grid container>
          <Grid item>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={onClose}
              css={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent css={{ paddingBottom: theme.spacing(3) }}>
        {loading ? <Backdrop forceOpen={true} message={t('LOADING')} /> : null}
        <Grid container>
          <Grid item>
            <Typography variant={'h2'} css={{ marginBottom: 2 }}>
              {content.get('CUSTOMER_INFORMATION')}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body1'} css={{ marginTop: theme.spacing(2) }}>
              {content.get('UPDATE_PHONE_NUMBER_INFO')}
            </Typography>
            <PhoneTextField
              label={'Phone Number'}
              name="phoneNumber"
              {...form.props('phoneNumber')}
              error={Boolean(form.errors.phoneNumber)}
              helperText={form.errors.phoneNumber}
              css={{ marginTop: theme.spacing(2) }}
            />
            <Typography
              variant="body2"
              css={{
                marginTop: theme.spacing(2),
                '& .MuiTypography-body1': {
                  fontSize: '15px',
                  fontWeight: 'normal',
                },
                '& .MuiButtonBase-root': {
                  padding: '0 9px',
                },
              }}
            >
              <CheckBoxes
                options={[
                  {
                    value: 'agree',
                    label: content.get('REUSABLE_PHONE_DISCLAIMER')!,
                  },
                ]}
                state={form.values.consent ? [form.values.consent] : []}
                setState={e => {
                  void form.setValue('consent', e.length > 0 ? e[0] : '');
                }}
              />
            </Typography>
            {form.errors.consent && (
              <FormHelperText error>{form.errors.consent}</FormHelperText>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        css={{
          padding: '16px 32px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Buttons
          onClick={onClose}
          type={'button'}
          variant={'outlined'}
          color={'secondary'}
          css={{
            border: '2px solid ' + colors.sparkBlue,
            borderRadius: '30px',
            minWidth: '130px',
            '&:hover': {
              border: '2px solid ' + colors.sparkBlue,
            },
          }}
        >
          {content.get('CANCEL')}
        </Buttons>

        <Buttons
          variant={'contained'}
          color={'primary'}
          onClick={form.submit(async values => await handleSave(values))}
          disabled={
            !form.values.phoneNumber ||
            !form.values.consent ||
            !!form.errors.phoneNumber
          }
          css={{
            backgroundColor: colors.orange,
            borderColor: colors.orange,
            borderRadius: '30px',
            minWidth: '130px',
            '&:hover': {
              backgroundColor: colors.orange,
              borderColor: colors.orange,
            },
          }}
        >
          {content.get('REUSABLE_SAVE')}
        </Buttons>
      </DialogActions>
    </Dialog>
  );
};
const createValidateFunction = () =>
  convertValidationRules<UpdatePhoneFormType>(context => {
    const { values } = context;
    return {
      phoneNumber: validatePhone,
      consent: () => (!values.consent ? 'Consent is required' : null),
    };
  });
