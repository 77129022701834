import React, { useEffect } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import {
  Box,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';
import { SSMStopServiceState } from '../../../hooks/useSSMStopService';
import { format } from 'date-fns';
import ROUTES from '../../../routes';
import { navigate } from 'gatsby';
import { useIsMobile } from '../../../util/style-utils';
import { displayPhoneNumberFormat } from '../../../util/format';
import { PremiseInfo } from '../../../__generated__/pge-types';

type Props = {
  path?: string;
  ssmStopServiceState: SSMStopServiceState;
};

const useStyles = makeStyles((theme: Theme) => ({
  headingGrid: {
    marginTop: '25px',
    marginBottom: '15px',
    textAlign: 'center',
    color: colors.brightBlue,
    fontSize: '40px',
  },
  subHeading: {
    fontSize: '20px',
    lineHeight: '30px',
    textAlign: 'center',
    marginBottom: '40px',
  },
  costSummmaryBox: {
    padding: '15px',
    background: colors.riverBlue,
  },

  costSummmaryBoxText: {
    fontSize: '18px',
  },

  belowInfo: {
    color: colors.charcoal,

    fontSize: '18px',

    margin: '6px 0 10px',
  },

  summaryCard: {
    padding: '20px',
    background: colors.white,
    border: `1px solid ${colors.brightBlue}`,
    borderRadius: '13px',
    boxShadow: `0px 3px 6px ${colors.boxShadowColor}`,
    width: '100%',
  },

  cardText: {
    fontSize: '16px',
    textAlign: 'center',
  },

  cardFirstline: {
    borderBottom: `1px solid ${colors.lightGray3}`,
    paddingBottom: '20px',
    marginBottom: '25px',
  },
  cardListingBox: {
    textAlign: 'left',
  },
  linkContainer: {
    marginLeft: 'auto',
  },

  listingItem: {
    textAlign: 'left',
    fontSize: '14px',
    lineHeight: '17px',
    marginBottom: '17px',
    display: 'flex',
    flexDirection: 'row',
    '& span': {
      marginRihgt: '25px',
      display: 'inline-block',
      textAlign: 'left',
      fontWeight: '500',
    },
  },

  cardInnerBox: {
    marginTop: '10px',
    marginLeft: '18px',
  },
  addresSection: {
    '& span': {
      minWidth: '122px',
    },
  },
  cardInnerText: {
    fontSize: '14px',
    lineHeight: '17px',
    marginBottom: '10px',
  },

  topTest: {
    display: 'none',
  },
  completed: {
    color: `${colors.shoreGreen} !important`,
  },
  stepperIcon: {
    padding: '10px 0 20px',
  },
  doneButton: {
    textAlign: 'center',
    marginTop: '50px',
    '& button': {
      minWidth: '181px',
      fontSize: '18px',
    },
  },
}));

export const SSMStopServiceSuccess = (props: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isMobile = useIsMobile();

  const { ssmStopServiceState } = props;

  const premiseInfo = ssmStopServiceState?.account
    ?.premiseInfo as PremiseInfo[];

  const address = `${premiseInfo?.[0]?.addressLine1}, ${premiseInfo?.[0]?.city}, ${premiseInfo?.[0]?.state}, ${premiseInfo?.[0]?.postal}`;

  const navigateBack = async (): Promise<void> => {
    // if (
    //   Object.keys(ssmStopServiceState.account).length === 0 &&
    //   Object.keys(ssmStopServiceState.addressAndDate).length === 0
    // ) {
    //   await navigate(ROUTES.SSM_STOP);
    // }
  };

  useEffect(() => {
    void navigateBack();
  }, []);

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      style={isMobile ? { padding: '0 16px' } : { padding: '0' }}
    >
      <Grid item>
        <Grid item xs={12} className={classes.headingGrid}>
          <Typography variant={'h1'}>
            {t('RECEIVED_SSM_STOP_REQUEST')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h2" className={classes.subHeading}>
            {t('BRANDED_MESSAGE')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.summaryCard}>
          <Box className={classes.cardFirstline}>
            <Box>
              <Stepper
                data-testid="desktop-progress-tracker"
                alternativeLabel
                style={{ background: 'transparent' }}
                className={classes.stepperIcon}
              >
                <Step completed={true}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        completed: classes.completed,
                      },
                    }}
                  />
                </Step>
              </Stepper>
              <Typography variant={'h2'} className={classes.cardText}>
                <strong>{t('SERVICE_SUMMARY')}</strong>
              </Typography>
            </Box>
          </Box>

          <Box className={classes.cardListingBox}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} direction="column">
                <Typography variant={'h2'} className={classes.listingItem}>
                  <span
                    style={
                      isMobile ? { minWidth: '122px' } : { minWidth: '41px' }
                    }
                  >
                    {t('TYPE')}:
                  </span>{' '}
                  <strong>{t('STOP')}</strong>
                </Typography>
                <Typography variant={'h2'} className={classes.listingItem}>
                  <span
                    style={
                      isMobile ? { minWidth: '122px' } : { minWidth: '41px' }
                    }
                  >
                    {t('FOR')}:
                  </span>{' '}
                  <strong>
                    {ssmStopServiceState?.account?.mainCustomerName}
                  </strong>
                </Typography>
                <Typography variant={'h2'} className={classes.listingItem}>
                  <span
                    style={
                      isMobile ? { minWidth: '122px' } : { minWidth: '41px' }
                    }
                  >
                    {t('AT')}:
                  </span>{' '}
                  <strong>{address}</strong>
                </Typography>
                <Typography
                  variant={'h2'}
                  style={
                    isMobile
                      ? { marginBottom: '0px' }
                      : { marginBottom: '17px' }
                  }
                  className={classes.listingItem}
                >
                  <span
                    style={
                      isMobile ? { minWidth: '122px' } : { minWidth: '41px' }
                    }
                  >
                    {t('DATE')}:
                  </span>{' '}
                  <strong>
                    {ssmStopServiceState?.addressAndDate?.date &&
                      format(
                        ssmStopServiceState?.addressAndDate?.date,
                        'MM/dd/yyyy',
                      )}
                  </strong>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                direction="column"
                className={classes.addresSection}
              >
                <Typography variant={'h2'} className={classes.listingItem}>
                  <span>{t('MAILING_ADDRESS')}:</span>{' '}
                  <strong>{`${ssmStopServiceState?.addressAndDate?.addressLine1}, ${ssmStopServiceState?.addressAndDate?.city}, ${ssmStopServiceState?.addressAndDate?.state}, ${ssmStopServiceState?.addressAndDate?.zip}`}</strong>{' '}
                </Typography>
                <Typography variant={'h2'} className={classes.listingItem}>
                  <span>{t('PHONE')}:</span>
                  <strong>
                    {displayPhoneNumberFormat(
                      ssmStopServiceState?.addressAndDate?.phone?.replace(
                        /[^0-9]/g,
                        '',
                      ),
                    )}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Grid className={classes.doneButton}>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={async () => await navigate(ROUTES.ACCOUNT)}
          >
            {t('DONE')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
