import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { Typography, Grid, Paper, useTheme } from '@material-ui/core';
import colors from '../../themes/main-colors';
import SecureSitePromo from '../secure-site-promo';
import ButtonLink from '../button-link';
import { useIsMobile } from '../../util/style-utils';
import ROUTES from '../../routes';

type Props = {
  path: string;
};

export const MoveServiceConfirmation = (_: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const theme = useTheme();

  return (
    <>
      <Paper style={{ padding: 30, color: colors.noirBlur }}>
        <Typography variant="h2">
          {t('YOURE_ALL_SET_THANK_YOU_FOR_MOVING')}
        </Typography>

        <div style={{ height: 30 }}>
          <Grid
            container
            direction="row"
            justify={isMobile ? 'center' : 'flex-end'}
            spacing={1}
            wrap="wrap-reverse"
            style={{ marginTop: theme.spacing(3) }}
          >
            <Grid item xs={10} md="auto">
              <ButtonLink to={ROUTES.ACCOUNT} text={t('DONE')} />
            </Grid>
          </Grid>
        </div>
      </Paper>
      <div style={{ marginTop: '2em' }}>
        <SecureSitePromo />
      </div>
    </>
  );
};
