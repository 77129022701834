import gql from 'not-graphql-tag';
import useAccountDetailList from './useAccountDetailList';
import useFeatureFlagProtectedValues from '../components/account-summary/multi-accounts/useFeatureFlagProtectedValues';
import { useEffect, useMemo, useRef, useState } from 'react';
import useMulticheckState from './useMulticheckState';
import keyBy from 'lodash/keyBy';
import useSelectedGroupId from './useSelectedGroupId';
import {
  AccountDetail,
  AccountDetailList,
  AccountDetailListParams,
} from '../__generated__/pge-types';
import values from 'lodash/values';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';
import { useApolloClient } from '@apollo/react-hooks';
export const getAccountsList = gql`
  query getAccountDetailList($params: AccountDetailListParams!) {
    getAccountDetailList(params: $params) {
      accounts {
        accountNumber
        encryptedAccountNumber
        encryptedPersonId
        isActive
        serviceConnectivity {
          isDisconnected
          isEligibleForReconnect
          disconnectionDate
        }
      }
    }
  }
`;

export const getAccountsCurrentChargesList = gql`
  query getAccountDetailList($params: AccountDetailListParams!) {
    getAccountDetailList(params: $params) {
      accounts {
        accountNumber
        currentCharges {
          amountDue
        }
      }
    }
  }
`;

type Options = {
  skip?: boolean;
  initialChecked?: Record<string, boolean>;
  query?: string;
  groupId?: string;
  allowMultiGroup?: boolean;
  allowInactive?: boolean;
  selectAllOverride?: () => any;
};

type GroupedAccounts = Record<string, Array<AccountDetail>>;

export default function useAccountMulticheckState({
  skip = false,
  initialChecked,
  query,
  groupId,
  allowMultiGroup = false,
  allowInactive = false,
}: Options = {}) {
  const apolloClient = useApolloClient();

  const { selectedGroupId } = useSelectedGroupId();
  const queryGroupId = groupId || selectedGroupId;
  const groupedAccounts = useRef<GroupedAccounts>({});

  const [areCurrentChargesLoading, setAreCurrentChargesLoading] = useState(
    false,
  );
  const [isCurrentChargesError, setIsCurrentChargesError] = useState(false);
  const [areCurrentChargesLoaded, setAreCurrentChargesLoaded] = useState(false);
  const [areAllWithBalanceSelected, setAreAllWithBalanceSelected] = useState(
    false,
  );
  const {
    getAccountListStateByClosedAcctFlag,
  } = useFeatureFlagProtectedValues();
  const { loading, accounts: queryAccounts } = useAccountDetailList(
    getAccountsList,
    queryGroupId,
    {
      accountListParams: {
        ...getAccountListStateByClosedAcctFlag(),
        query: query || '',
        pageSize: 'All',
        page: 1,
      },
      skip: skip || !queryGroupId,
    },
  );

  useEffect(() => {
    setAreCurrentChargesLoading(false);
    setIsCurrentChargesError(false);
    setAreCurrentChargesLoaded(false);
    setAreAllWithBalanceSelected(false);
    state.setChecked({});
  }, [queryGroupId]);

  if (typeof queryGroupId === 'string') {
    groupedAccounts.current[queryGroupId] = queryAccounts;
  }

  const allAccounts = allowMultiGroup
    ? uniqBy(flatten(values(groupedAccounts.current)), 'accountNumber')
    : queryAccounts;

  const checkOptions = useMemo(
    () =>
      (!loading &&
        allAccounts
          ?.filter(acc => allowInactive || acc.isActive)
          ?.map(acc => acc.accountNumber)) ||
      [],
    [allAccounts],
  );

  const accountsByNumber = useMemo(() => keyBy(allAccounts, 'accountNumber'), [
    allAccounts,
  ]);

  const state = useMulticheckState(checkOptions, { initialChecked });

  const loadCurrentCharges = async () => {
    try {
      setIsCurrentChargesError(false);
      setAreCurrentChargesLoading(true);

      const { data } = await apolloClient.query<
        { getAccountDetailList: AccountDetailList },
        { params: AccountDetailListParams }
      >({
        query: getAccountsCurrentChargesList,
        variables: {
          params: {
            groupId: queryGroupId,
          },
        },
      });

      const accountCharges = data?.getAccountDetailList?.accounts;
      if (accountCharges === null || accountCharges === undefined) {
        throw new Error();
      }

      accountCharges.map(acct => {
        const accountByAccountNumber = accountsByNumber[acct?.accountNumber!];
        accountByAccountNumber &&
          Object.assign(accountByAccountNumber, {
            currentCharges: acct?.currentCharges,
          });
      });

      setAreCurrentChargesLoaded(true);
    } catch (error) {
      setIsCurrentChargesError(true);
    } finally {
      setAreCurrentChargesLoading(false);
    }
  };

  const getAllAccountsWithBalance = async (fullObject: boolean = false) => {
    if (!areCurrentChargesLoaded) {
      await loadCurrentCharges();
    }
    return state.options.reduce((list, accNum) => {
      const account = accountsByNumber[accNum];

      if (
        (account?.currentCharges?.amountDue || 0) > 0 &&
        !account?.serviceConnectivity?.isEligibleForReconnect
      ) {
        list.push(fullObject ? account : accNum);
      }

      return list;
    }, [] as any);
  };

  const toggleSelectAllWithBalance = async (selected: boolean) => {
    setAreAllWithBalanceSelected(selected);
    if (selected) {
      const accts = await getAllAccountsWithBalance();
      state.setChecked(
        accts.reduce((acc: Array<string>, option: string) => {
          return { ...acc, [option]: true };
        }, {}),
      );
    } else {
      state.setChecked({});
    }
  };

  return {
    ...state,
    areAllWithBalanceSelected,
    areCurrentChargesLoading,
    isCurrentChargesError,
    toggleSelectAllWithBalance,
    getSelectedAccounts() {
      return state
        .getSelected()
        .map(accountNumber => accountsByNumber[accountNumber]);
    },
    getAllAccounts() {
      return state.options.map(
        accountNumber => accountsByNumber[accountNumber],
      );
    },
    getAllAccountsWithBalance,
  };
}
