import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { SSMStopCurrentServiceDate } from '../ssm.types';
import { useTranslation } from '../../../hooks/useTranslation';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import DatePicker from '../../date-picker';
import { addDays, parse } from 'date-fns';
import { convertValidationRules } from '../../../hooks/useFormState';
import { makeStyles, Theme as TypeTheme } from '@material-ui/core/styles';
import { validateSSMMoveStopDate } from '../common/SSMMoveService.rules';

const DATE_FORMAT = 'MM/dd/yyyy';

const useStyles = makeStyles((theme: TypeTheme) => ({
  formWrapper: {
    width: '100%',
    paddingTop: '0px',
    fontSize: '20px',
  },
  subHeading: {
    fontSize: '20px',
    lineHeight: '30px',
    marginTop: '15px',
    marginBottom: '15px',
  },
  datePickerWidth: {
    width: '100%',
  },
}));

// PLACEHOLDER FOR REAL COMPONENT
export const SSMStopCurrentService = ({
  formState,
  componentProps: { accountInfo },
}: WizardFormProps<SSMStopCurrentServiceDate>) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const { onChange } = formState.props('date');

  return (
    <Grid container direction="column" className={classes.formWrapper}>
      <Grid item container>
        <Grid item xs={12} className={classes.formWrapper}>
          <Typography variant="h2" className={classes.subHeading}>
            <b>
              {richT('WHEN_TO_STOP_SERVCE_AT_CURRENT_ADDRESS', {
                CURRENT_ADDRESS: accountInfo?.premiseInfo?.[0]?.addressLine1,
              })}
            </b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            className={classes.datePickerWidth}
            minDate={new Date()}
            format={DATE_FORMAT}
            maxDate={addDays(new Date(), 90)}
            label={t('STOP_SERVICE_DATE')}
            variant={'outlined'}
            name="date"
            {...formState.props('date')}
            onChange={async ({
              target: { value },
            }: {
              target: { value: string };
            }) => {
              // TODO - Need to parse the value because
              // DatePicker onChange is called with string
              // It is not clear yet what the new backend start service
              // submit will accept but current placeholder is of type Date
              // if backend expects MM/DD/YYYY string type, then we can store
              // the type as string in state and get rid of this conversion
              await onChange(parse(value, DATE_FORMAT, new Date()));
            }}
            data-cy="start-field"
            mask={'__/__/____'}
            labelShrink={{ shrink: true }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const createSSMMoveStopDateForm = () => {
  return {
    toFormState: (
      initial: SSMStopCurrentServiceDate,
    ): SSMStopCurrentServiceDate => ({
      date: initial.date,
    }),
    fromFormState: (
      state: SSMStopCurrentServiceDate,
    ): SSMStopCurrentServiceDate => {
      return {
        date: state.date,
      };
    },
  };
};

export const createSSMMoveStopValidateFunction = () =>
  convertValidationRules<{
    date?: Date;
  }>(() => {
    return {
      date: validateSSMMoveStopDate,
    };
  });
