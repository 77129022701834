import { useState, useEffect } from 'react';
import gql from 'not-graphql-tag';
import { useLazyQuery } from '@apollo/react-hooks';
import {
  MoveServiceEligibilityRequest,
  MoveServiceEligibilityResponse,
} from '../../../__generated__/pge-types';

const MOVE_SERVICE_ADDRESS_QUERY = gql`
  query moveServiceEligibility($payload: MoveServiceEligibilityRequest) {
    moveServiceEligibility(payload: $payload) {
      eligibleForMoveService
      eligibleForGreenResource
      isCurrentlyPaperlessBilling
    }
  }
`;

interface Response {
  moveServiceEligibility: MoveServiceEligibilityResponse;
}

interface Request {
  payload: MoveServiceEligibilityRequest;
}

export const useMoveServiceEligibility = () => {
  const [
    serviceAddress,
    setServiceAddress,
  ] = useState<MoveServiceEligibilityRequest | null>(null);

  const [
    getMoveServiceAddressEligibility,
    { loading, error, data },
  ] = useLazyQuery<Response, Request>(MOVE_SERVICE_ADDRESS_QUERY);

  useEffect(() => {
    if (serviceAddress) {
      getMoveServiceAddressEligibility({
        variables: { payload: serviceAddress },
      });
    }
  }, [serviceAddress]);

  return {
    serviceAddress,
    setServiceAddress,
    loading,
    error,
    data,
  };
};
