import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '../../../components/buttons';
import { useIsMobile } from '../../../util/style-utils';
import { useStyles } from './styles';
import { navigate } from '@reach/router';
import ROUTES from '../../../routes';
import Alerts from '../../alerts';
import { Dialog } from '@material-ui/core';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function ManageAlertsConfirmPhone(props: Props) {
  const { richT, t } = useTranslation();
  const isMobile = useIsMobile();
  const classes = useStyles();

  return (
    <>
      <Dialog open={props.isOpen}>
        <Card className={classes.manageAlertsSuccess}>
          <Alerts
            disableColorOverride={true}
            isOpen
            severity={'success'}
            variant={'outlined'}
            title={<h2 className={classes.alertTitle}>{t('YOURE_ALL_SET')}</h2>}
            message={
              <Grid container spacing={2}>
                <Grid item>
                  <Typography
                    className={classes.colorTextPrimary}
                    component="span"
                  >
                    {richT('NOTIFICATION_PREFERENCES_UPDATED_SUCCESS')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  container
                  justify={isMobile ? 'center' : 'flex-end'}
                >
                  <Grid item xs={10} md="auto">
                    <Button
                      className={classes.updateButton}
                      color={'secondary'}
                      variant={'contained'}
                      onClick={() => {
                        props.onClose();
                        return navigate(ROUTES.ACCOUNT);
                      }}
                      fullWidth
                    >
                      {t('CLOSE')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            }
          />
        </Card>
      </Dialog>
    </>
  );
}
