import { useState, useContext } from 'react';
import { DocumentNode } from 'graphql';

import { toDateString } from '../util/format';
import useAuthQuery from './useAuthQuery';
import useSelectedGroupId from './useSelectedGroupId';
import useAccountDetailList from './useAccountDetailList';
import useAccountCustomer from './useAccountCustomer';
import {
  AccountSort,
  Direction,
  BillingAndPaymentHistoryDetailsInput,
  BillingAndPaymentHistoryDetailsResponse,
  BillingAndPaymentDelimiterType,
  BillingAndPaymentReportType,
} from '../__generated__/pge-types';
import { getAccountsList } from '../components/account-dropdown/queries';
import fetch from 'isomorphic-fetch';
import authService from '../lib/authService';
import { useTranslation } from './useTranslation';
import { NotificationsContext } from '../providers/NotificationsProvider';
import config from '../config';

type UseViewAndDownloadBillsParams = {
  sortBy: string;
  reportType: BillingAndPaymentReportType;
  delimiterType: BillingAndPaymentDelimiterType;
  encryptedPersonId: string;
};

const BILLING_AND_PAYMENT_HISTORY_DOWNLOAD_URL = `${config.apigee.baseUrl}/pge-bill-api/bill/billHistory`;

const reportTypeMap = {
  Unknown: 0,
  LongForm: 1,
  ShortForm: 2,
  Accounting: 3,
};
const delimiterTypeMap = {
  Unknown: 0,
  Csv: 1,
  Pipe: 2,
};

export default function useViewAndDownloadBills(
  gql: DocumentNode,
  params: UseViewAndDownloadBillsParams,
) {
  const { t } = useTranslation();
  const { sortBy, reportType, delimiterType, encryptedPersonId } = params;
  const { customer } = useAccountCustomer();
  const { selectedGroupId } = useSelectedGroupId();
  const [dateRange, setDateRange] = useState<{
    startDate: string | number | Date | null | undefined;
    endDate: string | number | Date | null | undefined;
  } | null>();
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const notificationContext = useContext(NotificationsContext);

  const selectedGroup = selectedGroupId
    ? customer?.groups?.find((group: any) => group.groupId === selectedGroupId)
    : null;

  const { accounts, loading: accountsLoading } = useAccountDetailList(
    getAccountsList,
    selectedGroupId,
    {
      accountListParams: {
        page: 1,
        pageSize: 'All',
        query: '',
        sortBy: AccountSort.Default,
        sortOrder: Direction.Asc,
      },
    },
  );

  const isDueDateSelected = sortBy === 'DueDate';

  const { refetch: refetchBillAndPaymentHisotry } = useAuthQuery<
    {
      getBillingAndPaymentHistoryDetails: BillingAndPaymentHistoryDetailsResponse;
    },
    { payload: BillingAndPaymentHistoryDetailsInput }
  >(gql, {
    variables: {
      payload: {
        encryptedPersonId,
        encryptedAccountNumbers: [], // Initialize with empty accounts, handled later
        startDate: toDateString(dateRange?.startDate, 'MM/dd/yyyy'),
        endDate: toDateString(dateRange?.endDate, 'MM/dd/yyyy'),
        sortedByDueDate: isDueDateSelected,
        filterByDueDate: isDueDateSelected,
        reportType,
        delimeterType: delimiterType,
      },
    },
    fetchPolicy: 'network-only',
    skip: true, // Skip initial fetch, will handle with refetch
  });

  async function downloadBillingAndPaymentHistory() {
    setDownloadLoading(true);
    notificationContext.setState({ isOpen: false });

    try {
      const startDate = dateRange?.startDate;
      const endDate = dateRange?.endDate;
      const accountNumbers = accounts?.map((acc: any) => acc.accountNumber);
      const tokens = authService.tokensStorage.get();
      const token = tokens.accessToken?.token;
      const result = await fetch(BILLING_AND_PAYMENT_HISTORY_DOWNLOAD_URL, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          accountNumbers: accountNumbers,
          startDate: toDateString(startDate, 'yyyy-MM-dd'),
          endDate: toDateString(endDate, 'yyyy-MM-dd'),
          sortedByDueDate: isDueDateSelected,
          filterByDueDate: isDueDateSelected,
          reportType: reportTypeMap[reportType],
          delimiterType: delimiterTypeMap[delimiterType],
          encryptedPersonId: encryptedPersonId,
        }),
        method: 'POST',
      });

      const text = await result.text();

      const ext =
        delimiterType === BillingAndPaymentDelimiterType.Csv ? 'csv' : 'plain';
      const universalBOM = '\uFEFF';
      // forcing csv file to be encoded in utf-8 by adding byte order mark as first character as csv file uses ANSI by default.
      const linkSource = `data:text/${ext}; charset=utf-8,${encodeURIComponent(
        universalBOM + text,
      )}`;
      const downloadLink = document.createElement('a');

      downloadLink.href = linkSource;
      downloadLink.download = `${(
        selectedGroup?.groupCode?.substr(0, 8) || t('BILLING_PAYMENT_HISTORY')
      ).replace(' ', '_')}_${toDateString(
        startDate,
        'MMddyyyy',
      )}_${toDateString(endDate, 'MMddyyyy')}.${ext === 'csv' ? 'csv' : 'txt'}`;
      downloadLink.click();
    } catch (e) {
      console.error(e);
      notificationContext.setState({
        isOpen: true,
        message: t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
        severity: 'error',
      });
      typeof window !== 'undefined' && window.scrollTo(0, 0);
    } finally {
      setDownloadLoading(false);
    }
  }

  return {
    setDateRange,
    refetchBillAndPaymentHisotry,
    accounts,
    accountsLoading,
    downloadLoading,
    selectedGroup,
    downloadBillingAndPaymentHistory,
  };
}
