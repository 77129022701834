import { Box, IconButton, Modal } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { navigate } from 'gatsby';
import React, { FunctionComponent, useState, useContext } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import PGEButton from '../../buttons';
import PaperBox from '../../utility/paper-box';
import useStyles from '../styles';
import ROUTES from '../../../routes';
import { Close } from '@material-ui/icons';
import TextLink from '../../text-link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { unEnrollmentReasonCodes } from '../TODConstants';

interface Props {
  path?: string;
  unEnrollmentReasonCode?: string;
  setUnEnrollmentReasonCode: (arg: string | any) => void;
  handleUnenrollmentSubmit: () => void;
}
const TimeOfDayUnenroll: FunctionComponent<Props> = ({
  handleUnenrollmentSubmit,
  setUnEnrollmentReasonCode,
  unEnrollmentReasonCode,
}) => {
  const { t, richT } = useTranslation();
  const [showUnenrollConfirm, setshowUnenrollConfirm] = useState(false);

  const classes = useStyles();
  const onshowUnenrollConfirm = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setshowUnenrollConfirm(true);
  };
  return (
    <>
      <PaperBox>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant={'h2'}>
              {t('TOD_UNENROLLMENT_TITLE')}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '1em' }}>
            <Typography variant={'h6'} component={'div'}>
              {richT('TOD_UNENROLLMENT_BODY')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant={'h6'} style={{ fontWeight: 'bold' }}>
              {t('PLEASE_LET_US_KNOW_WHY_YOU_ARE_CHOOSING')}
            </Typography>
            <Select
              id="unEnrollmentReasonCode"
              name="unEnrollmentReasonCode"
              variant="outlined"
              style={{ width: '75%' }}
              displayEmpty
              onChange={e => setUnEnrollmentReasonCode(e?.target?.value || '')}
              value={unEnrollmentReasonCode}
            >
              <MenuItem style={{ whiteSpace: 'break-spaces' }} value="">
                {t('SELECT_REASON')}
              </MenuItem>
              {unEnrollmentReasonCodes.map(reasonCode => (
                <MenuItem
                  style={{ whiteSpace: 'break-spaces' }}
                  key={reasonCode.key}
                  value={reasonCode.key}
                >
                  {t(reasonCode.label)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </PaperBox>
      <Grid
        item
        xs={12}
        style={{
          marginTop: '1em',
          marginRight: '.75em',
        }}
      >
        <Grid container justify="space-between">
          <Grid item xs={12} md="auto">
            <Box>
              <TextLink
                style={{ textDecoration: 'none' }}
                onClick={(e: any) => {
                  e.preventDefault();
                  return navigate(ROUTES.TIMEOFDAY_MANAGE);
                }}
              >
                {t('BACK')}
              </TextLink>
            </Box>
          </Grid>
          <Grid item xs={12} md="auto">
            <Box>
              <PGEButton
                onClick={onshowUnenrollConfirm}
                variant="contained"
                color="primary"
                data-testid="change-password-button"
                size="large"
              >
                {t('TOD_UNENROLLMENT_TITLE')}
              </PGEButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={showUnenrollConfirm}
        onClose={() => setshowUnenrollConfirm(false)}
      >
        <div className={classes.callModal}>
          <div className={'closeButton'}>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => setshowUnenrollConfirm(false)}
            >
              <Close />
            </IconButton>
          </div>
          <Typography variant={'h4'} gutterBottom>
            {t('TOD_UNENROLL_CONFIRM_MODAL_TITLE')}
          </Typography>
          <Typography variant={'h6'} style={{ marginTop: '1em' }}>
            {t('TOD_UNENROLL_CONFIRM_MODAL_BODY')}
          </Typography>

          <Grid
            item
            xs={12}
            style={{
              marginTop: '1em',
              marginRight: '.75em',
            }}
          >
            <Grid container justify="space-between">
              <Grid item xs={12} md="auto">
                <Box>
                  <PGEButton
                    variant={'outlined'}
                    color={'primary'}
                    className={classes.unenrollBtn}
                    size="large"
                    data-testid="unenroll-cancel_button"
                    onClick={async () => setshowUnenrollConfirm(false)}
                  >
                    {t('CANCEL')}
                  </PGEButton>
                </Box>
              </Grid>
              <Grid item xs={12} md="auto">
                <Box>
                  <PGEButton
                    variant="contained"
                    color="primary"
                    data-testid="modal-button-2"
                    size="large"
                    onClick={e => {
                      setshowUnenrollConfirm(false);
                      handleUnenrollmentSubmit();
                    }}
                  >
                    {t('TOD_UNENROLL_CONFIRM_MODAL_BTN2')}
                  </PGEButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </>
  );
};
export default TimeOfDayUnenroll;
