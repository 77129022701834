import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from '../../../hooks/useTranslation';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import useGetCoCustomersAndAuthUsersListQuery from '../../../hooks/useGetCoCustomersAndAuthUsersListQuery';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import AddUsersCard from '../post-enrollment/CoCustomer/AddUsersCard';
import Backdrop from '../../backdrop/Backdrop';
import {
  OtherPeopleRelType,
  OtherPeopleResponse,
} from '../../../__generated__/pge-types';
import SSMStayBehind from '../common/SSMStayBehind';
import { SSMMoveStayBehind } from '../ssm.types';
import { stayingBehindValidation } from '../stop/SSMStopService.rules';
import { convertValidationRules } from '../../../hooks/useFormState';

// PLACEHOLDER FOR REAL COMPONENT
export const SSMTransferPeopleToServiceComponent = ({
  formState,
  componentProps,
}: WizardFormProps<SSMMoveStayBehind>) => {
  const { t } = useTranslation();
  const {
    transferServiceUserList,
    setTransferServiceUserList,
  } = componentProps;
  const { accountParams } = useSelectedAccountParams();
  const { loading, data, error } = useGetCoCustomersAndAuthUsersListQuery(
    accountParams?.accountNumber,
  );

  const handleUserChecked = (perId: string) => {
    const existingRecord = transferServiceUserList.find(
      (item: OtherPeopleResponse) => item.perId === perId,
    );
    if (existingRecord) {
      const filteredUserList = transferServiceUserList.filter(
        (item: { perId: string }) => item.perId !== perId,
      );
      setTransferServiceUserList(filteredUserList);
    } else {
      const newRecord = data?.otherPeople?.find(item => item.perId === perId);
      if (perId && newRecord) {
        setTransferServiceUserList([...transferServiceUserList, newRecord]);
      }
    }
  };

  return (
    <Grid container direction="column" spacing={2}>
      {loading ? <Backdrop forceOpen={loading} /> : null}
      {data ? (
        <>
          <Typography variant="h6">
            {data?.otherPeople?.length > 0
              ? t('SSM_MOVE_TRANSFER_PEOPLE_KICKER_TEXT')
              : t('SSM_MOVE_TRANSFER_NO_PEOPLE_KICKER_TEXT')}
          </Typography>
          <AddUsersCard
            title={t('SSM_MOVE_CO_CUSTOMER_TITLE')}
            description={t('SSM_MOVE_CO_CUSTOMER_MESSAGE')}
            usersList={data?.otherPeople.filter(
              (user: OtherPeopleResponse) =>
                user.acctRelType === OtherPeopleRelType.Coapp,
            )}
            isSSMMoveFlow={true}
            transferServiceUserList={transferServiceUserList}
            handleUserChecked={handleUserChecked}
          />
          <AddUsersCard
            title={t('SSM_MOVE_AUTHORIZED_PARTY_TITLE')}
            description={t('SSM_POST_ADD_AUTH_USERS_DESC')}
            usersList={data?.otherPeople?.filter(
              (user: OtherPeopleResponse) =>
                user.acctRelType === OtherPeopleRelType.Authusr,
            )}
            isSSMMoveFlow={true}
            transferServiceUserList={transferServiceUserList}
            handleUserChecked={handleUserChecked}
          />
          <SSMStayBehind formState={formState.props('stayingBehind')} />
        </>
      ) : null}
    </Grid>
  );
};

export const createStayBehindFormAdapter = () => {
  return {
    toFormState: (initial: SSMMoveStayBehind) => ({
      stayingBehind: initial.stayingBehind,
    }),
    fromFormState: (state: SSMMoveStayBehind) => {
      return {
        stayingBehind: state.stayingBehind,
      };
    },
  };
};

export const createSSMStayBehindValidation = () =>
  convertValidationRules<{
    stayingBehind: string;
  }>(() => {
    return {
      stayingBehind: stayingBehindValidation,
    };
  });
