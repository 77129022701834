import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { navigate } from 'gatsby';
import React from 'react';
import SunIcon from '../../../static/sunIcon.svg';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import Button from '../../buttons';
import { useIsMobile } from '../../../util/style-utils';
import AlertBellIcon from '../../../images/AlertsBell.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
    },
    alertsContent: {
      display: 'flex',
      alignItems: 'center',
    },
    alertsContentMob: {
      display: 'flex',
      flexDirection: 'column',
    },
    icon: {
      flex: '1',
    },
    iconMob: {
      flex: '1',
      textAlign: 'center',
      marginBottom: '1rem',
    },
    alertsContentText: {
      flex: '7',
    },
    alertsContentTextMob: {
      flex: '7',
      textAlign: 'center',
      marginBottom: '1rem',
    },
    heading: {
      marginBottom: '0.5rem',
    },
    subHeading: {
      fontSize: '16px',
    },
    alertsButton: {
      color: colors.sparkBlue,
      background: 'transparent',
      borderColor: colors.sparkBlue,
      borderRadius: theme.spacing(3.75),
      minWidth: '100px',
      borderWidth: '2px',
      padding: '5px',
      width: '100%',
      '& span': {
        fontSize: '18px',
      },
      '&:hover': {
        color: colors.sparkBlue,
        background: 'transparent',
        borderColor: colors.sparkBlue,
        borderWidth: '2px',
      },
    },
  }),
);

export const ManageAlerts = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <>
      <Paper>
        <Card>
          <CardContent
            className={
              isMobile ? classes.alertsContentMob : classes.alertsContent
            }
          >
            <div className={isMobile ? classes.iconMob : classes.icon}>
              <img alt={''} src={AlertBellIcon} />
            </div>
            <div
              className={
                isMobile
                  ? classes.alertsContentTextMob
                  : classes.alertsContentText
              }
            >
              <Typography
                variant="h3"
                className={isMobile ? classes.heading : ''}
              >
                {t('ALERTS_AND_REMINDERS_LABEL')}
              </Typography>
              <Typography className={classes.subHeading}>
                {t('ALERTS_AND_REMINDERS_SUBHEADING')}
              </Typography>
            </div>
            <div style={{ flex: '2.7' }}>
              <Button
                variant={'outlined'}
                className={classes.alertsButton}
                onClick={() => navigate(ROUTES.MANAGE_ALERTS)}
              >
                {t('MANAGE_ALERTS')}
              </Button>
            </div>
          </CardContent>
        </Card>
      </Paper>
    </>
  );
};

export default ManageAlerts;
