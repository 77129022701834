/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React from 'react';
import { Grid, Button, useTheme } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import colors from '../../themes/main-colors';

interface RadioButtonGroupProps {
  options: {
    label: string;
    value: string | boolean | number;
  }[];
  state: string | boolean | undefined;
  setState: (option: string | boolean | number) => void;
}

export default function RadioButtonGroup({
  options,
  state,
  setState,
}: RadioButtonGroupProps) {
  const theme = useTheme();

  const selectedBox = {
    backgroundColor: colors.navBarHoverBackground,
    color: colors.sparkBlue,
    boxShadow: `0px 5px 5px ${colors.boxShadowBlack}`,
    borderColor: colors.sparkBlue,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: colors.navBarHoverBackground,
      color: colors.sparkBlue,
    },
  };

  return (
    <Grid container css={{ gap: '26px' }}>
      {options.map((option, idx) => {
        const checked = state === option.value;
        return (
          <Button
            key={idx}
            css={{
              minWidth: '160px',
              maxWidth: '50%',
              border: `1px solid ${colors.lightGray1}`,
              color: colors.noirBlur,
              fontSize: 20,
              textAlign: 'center',
              borderRadius: 0,
              padding: theme.spacing(2, 1),
              backgroundColor: colors.white,
              '&:hover': {
                backgroundColor: colors.white,
              },
              '& svg': {
                position: 'absolute',
                width: 18,
                height: 18,
                top: 5,
                right: 5,
              },
              [theme.breakpoints.down('md')]: {
                minWidth: '160px',
              },
              ...(checked ? selectedBox : {}),
            }}
            onClick={() => setState(option.value)}
          >
            {option.label}
            {checked && <CheckCircle htmlColor={colors.shoreGreen} />}
          </Button>
        );
      })}
    </Grid>
  );
}
