import React, { useState, useEffect, useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { RouteComponentProps, Router, navigate } from '@reach/router';
import ServiceLayout from '../../../components/service-layout';
import ROUTES from '../../../routes';
import { useTranslation } from '../../../hooks/useTranslation';
import useAuthQuery from '../../../hooks/useAuthQuery';
import useSelectedAccountParams from '../../../hooks/useSelectedAccountParams';
import Backdrop from '../../../components/backdrop';
import { featureFlagsContext } from '../../../providers/FeatureFlagsProvider';
import AccountDropdown from '../../../components/account-dropdown';
import StopServiceIneligible from '../../../components/stop-service/StopServiceIneligible';
import StopServiceEligibilityCheck from '../../../components/stop-service/StopServiceEligibilityCheck';
import qs from 'query-string';
import { Model } from '../../../components/stop-service/types';
import StopServiceReview from '../../../components/stop-service/StopServiceReview';
import {
  ServiceAddress,
  StopServiceSubmitRequest,
  AccountDetail,
} from '../../../__generated__/pge-types';
import StopServiceConfirmation from '../../../components/stop-service/StopServiceConfirmation';
import { getAccountDetails } from '../../../components/account-summary/single-account/queries';
import { useStopService } from '../../../components/stop-service/hooks';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';

export default function StopService(_: RouteComponentProps) {
  const { t } = useTranslation();
  const { loading: loadingFeatureFlags, startStopEnabled } = useContext(
    featureFlagsContext,
  );
  const { submit } = useStopService();
  const { wrapWithLoader } = useWrapWithLoader();

  const {
    accountNumber,
    encryptedAccountNumber,
    encryptedPersonId,
    accountParams,
  } = useSelectedAccountParams();

  const { data: getAccountDetailsData } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountDetails, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    errorPolicy: 'all',
    skip: !accountParams,
  });

  const accountDetails = getAccountDetailsData?.getAccountDetails?.[0];

  useEffect(() => {
    if (!loadingFeatureFlags && !startStopEnabled && encryptedAccountNumber) {
      const url = `${ROUTES.STOP_SERVICE_LEGACY_URL}?${qs.stringify({
        encryptedAccountNumber: encryptedAccountNumber,
      })}`;
      console.log(url);
      window.location.replace(url);
    }
  }, [loadingFeatureFlags, startStopEnabled, encryptedAccountNumber]);

  const [data, setData] = useState<Model | null>(null);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(ROUTES.STOP_SERVICE);
    setData(null);
  }, [accountNumber]);

  function handleFormSubmit(formData: Model) {
    setData(formData);
    return navigate(ROUTES.STOP_SERVICE_REVIEW);
  }

  const handleStopService = wrapWithLoader(
    async (formData: Model, serviceAddress: ServiceAddress) => {
      const payload: StopServiceSubmitRequest = {
        encryptedAccountNumber: encryptedAccountNumber!,
        encryptedPersonId: encryptedPersonId!,
        phoneNumber: formData.phone,
        phoneExt: formData.phoneExt,
        stopDate: formData.stopDate,
        anyoneRemainingAtProperty: formData.livingRemainAtProperty,
        finalBillAddress: {
          addressLine1: formData.address,
          city: formData.city,
          state: formData.state,
          postal: formData.zip,
          country: formData.country,
          qasVerified: formData.qasVerified,
          inCareOf: formData.inCareOf || null,
        },
        serviceAddress: {
          addressLine1: serviceAddress.addressLine2
            ? `${serviceAddress.addressLine1}, ${serviceAddress.addressLine2}`
            : serviceAddress.addressLine1,
          city: serviceAddress.city,
          state: serviceAddress.state,
          postal: serviceAddress.postal,
          country: serviceAddress.country,
          qasVerified: false,
        },
      };
      await submit(payload);
      return navigate(ROUTES.STOP_SERVICE_CONFIRM);
    },
    () => t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
  );

  if (!startStopEnabled) {
    return (
      <Backdrop
        forceOpen
        message="Please wait while we load your service request."
      />
    );
  }

  return (
    <ServiceLayout>
      <Grid container direction="column" spacing={2}>
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h1'}>{t('STOP_SERVICE')}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1">
              {t('STOP_SERVICE_SUBTITLE')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <AccountDropdown />
        </Grid>
        <Grid item>
          <Router basepath="/">
            <StopServiceEligibilityCheck
              key={accountNumber}
              path={ROUTES.STOP_SERVICE}
              onSubmit={handleFormSubmit}
              data={data}
              encryptedPersonId={encryptedPersonId || ''}
              encryptedAccountNumber={encryptedAccountNumber || ''}
            />
            <StopServiceIneligible path={ROUTES.STOP_SERVICE_INELIGIBLE} />
            {data && (
              <>
                <StopServiceReview
                  data={data}
                  path={ROUTES.STOP_SERVICE_REVIEW}
                  onSubmit={handleStopService}
                />
                <StopServiceConfirmation
                  data={data}
                  path={ROUTES.STOP_SERVICE_CONFIRM}
                  accountDetails={accountDetails}
                />
              </>
            )}
          </Router>
        </Grid>
      </Grid>
    </ServiceLayout>
  );
}
