import React, { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import { Grid, Typography } from '@material-ui/core';

import { useTranslation } from '../../../hooks/useTranslation';
import ProfileLayout from '../../../components/profile-layout';
import Helmet from 'react-helmet';
import TwoStepAuthentication from '../../../components/two-step-authentication';

const TwoStepAuthenticationPage: FC<RouteComponentProps> = (
  props: RouteComponentProps,
) => {
  const { t } = useTranslation();

  const title = t('TWO_STEP_AUTHENTICATION');

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ProfileLayout dense={false}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant={'h1'}>{title}</Typography>
          </Grid>
          <Grid item>
            <TwoStepAuthentication />
          </Grid>
        </Grid>
      </ProfileLayout>
    </>
  );
};

export default TwoStepAuthenticationPage;
