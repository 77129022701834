import {
  Box,
  Card,
  CardContent,
  Grid,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { Fragment } from 'react';
import IconMoney from '../../../src/static/waytoSave.svg';
import { AccountDetail, AccountType } from '../../__generated__/pge-types';
import useAuthQuery from '../../hooks/useAuthQuery';
import useGetAccountType from '../../hooks/useGetAccountType';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import { MyPricingPlanWidget } from '../OpowerWidgets';
import AccountDropdown from '../account-dropdown';
import { getPgeEnergyTrackerData } from '../account-summary/single-account/queries';
import Backdrop from '../backdrop';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.spacing(4.5),
      lineHeight: theme.typography.pxToRem(28),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      fontFamily: 'Forma-DJR-Display',
      color: colors.lightCyan,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        marginBottom: '16px',
      },
    },
    subText: {
      marginBottom: '25px',
    },
    colorTextPrimary: {
      backgroundColor: colors.navBarBackground,
    },
    widget: {
      backgroundColor: colors.lightGray2,
      margin: '20px',
      [theme.breakpoints.down('sm')]: {
        margin: '0px',
        padding: '0px',
      },
    },
    accountBox: {
      margin: '20px 0 20px',
    },
    nonRes: {
      display: 'flex',
    },
    nonResText: {
      padding: '20px',
      fontSize: '20px',
      lineHeight: '25px',
      color: colors.lightCyan,
    },
  }),
);

type Props = {
  path?: string;
};

export default function MyPricingPlan(_: Props) {
  const { accountParams } = useSelectedAccountParams();
  const { t } = useTranslation();
  const classes = useStyles();
  const { accountTypeData } = useGetAccountType({});

  const { loading: eTLoading, data: etData } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getPgeEnergyTrackerData, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    skip: !accountParams,
  });

  const pgeEnergyTrackerData = etData?.getAccountDetails[0]?.pgeEnergyTracker;
  const isResAccount =
    accountTypeData?.getAccountDetails[0]?.accountType === AccountType?.Res;
  const isLoading = etData === undefined || eTLoading;

  if (isLoading || accountTypeData === undefined) {
    return (
      <Backdrop forceOpen message={t('STILL_LOADING')} data-testid="loading" />
    );
  }
  return (
    <Fragment>
      <Typography className={classes.heading} data-testid="heading">
        {t('MY_PRICING_PLAN_OPTIONS')}
      </Typography>
      <Box className={classes.accountBox} data-testid="account-dropdown">
        <AccountDropdown />
      </Box>
      <Box>
        <Typography className={classes.subText} data-testid="subtext">
          {t('MY_PRICING_PLAN_OPTIONS_SUBTEXT')}
        </Typography>
        <Card className={classes.colorTextPrimary}>
          {isResAccount ? (
            <CardContent className={classes.widget} data-testid="widget-res">
              <MyPricingPlanWidget
                entityId={
                  pgeEnergyTrackerData?.energyTrackerInfo?.identifiers?.[0]
                }
              />
            </CardContent>
          ) : (
            !isResAccount && (
              <CardContent
                className={classes.widget}
                data-testid="widget-non-res"
              >
                <Grid item className={classes.nonRes}>
                  <IconMoney />
                  <Typography className={classes.nonResText}>
                    {t(
                      'MY_PRICING_PLAN_OPTIONS_WIDGET_NON_RESIDENTIAL_MESSAGE',
                    )}
                  </Typography>
                </Grid>
              </CardContent>
            )
          )}
        </Card>
      </Box>
    </Fragment>
  );
}
