import { FormState } from '../../hooks/useFormState.types';
import {
  AccountDetail,
  AccountType,
  PaymentProfile,
} from '../../__generated__/pge-types';
import { PAY_BY_CHECK } from '../paymentus/types';
import { isBankAccountType, isCreditCardType } from '../paymentus/utils';

type InputProps = ReturnType<FormState<any>['props']>;

function addDecimalZeroes(value: string) {
  return Number(value).toFixed(2);
}

export function addDecimalOnBlur(inputProps: InputProps) {
  const originalBlur = inputProps.onBlur;

  return {
    ...inputProps,
    onBlur: async () => {
      const value = inputProps.value;
      if (typeof value === 'string') {
        await inputProps.onChange({ value: addDecimalZeroes(value) });
      }
      return originalBlur();
    },
  };
}

const isNonBillable = (account: AccountDetail) =>
  account.paymentEligibility?.isNonBillableNoBalance;

const isCashOnly = (account: AccountDetail, paymentProfile?: PaymentProfile) =>
  account.paymentEligibility?.isCashOnly &&
  isBankAccountType(paymentProfile?.type);

export const getMultipayEligibility = (
  account: AccountDetail,
  paymentProfile?: PaymentProfile,
) => {
  if (paymentProfile && paymentProfile === PAY_BY_CHECK) {
    return { isEligible: true };
  }

  if (isNonBillable(account)) {
    return {
      isEligible: false,
      type: 'MULTIPAY_ACCOUNT_INELIGIBLE_IS_NON_BILLABLE',
    };
  }
  if (isCashOnly(account, paymentProfile)) {
    return {
      isEligible: false,
      type: 'MULTIPAY_ACCOUNT_INELIGIBLE_IS_CASH_ONLY',
    };
  }

  return { isEligible: true };
};
