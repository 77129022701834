import React, { FC } from 'react';
import {
  Grid,
  makeStyles,
  createStyles,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

import CurrentChargeIcon from '../../../../static/currentchargeIcon.svg';
import MonthlyInstallIcon from '../../../../static/monthlyinstallIcon.svg';
import TotalBillIcon from '../../../../static/totalbillIcon.svg';
import AddIcon from '@material-ui/icons/Add';
import DragHandleIcon from '@material-ui/icons/DragHandle';

interface IconProps {
  definedClass: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    plusIcon: {
      position: 'absolute',
      top: '38%',
      right: '-5%',
      [theme.breakpoints.down('md')]: {
        right: '-15%',
      },
      [theme.breakpoints.down('sm')]: {
        bottom: '-48px',
        top: 'auto',
        height: '35px',
        width: '35px',
        right: '-62%',
      },
    },
    equalIcon: {
      position: 'absolute',
      top: '38%',
      left: '-12%',
      [theme.breakpoints.down('sm')]: {
        top: '-50px',
        height: '35px',
        width: '35px',
        right: '-62%',
        left: 'auto',
      },
    },
  }),
);

const CurrentChargeIconWrapper: FC<IconProps> = props => {
  const { definedClass } = props;
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid>
      <CurrentChargeIcon className={definedClass} />
      <AddIcon fontSize="large" className={classes.plusIcon} />
    </Grid>
  );
};

const MonthlyInstallIconWrapper: FC<IconProps> = props => {
  const { definedClass } = props;
  const theme = useTheme();

  return (
    <Grid>
      <MonthlyInstallIcon className={definedClass} />
    </Grid>
  );
};

const TotalBillIconWrapper: FC<IconProps> = props => {
  const { definedClass } = props;
  const classes = useStyles();

  return (
    <Grid>
      <DragHandleIcon fontSize="large" className={classes.equalIcon} />

      <TotalBillIcon className={definedClass} />
    </Grid>
  );
};

export {
  CurrentChargeIconWrapper,
  MonthlyInstallIconWrapper,
  TotalBillIconWrapper,
};
