import useAccountDetailList from '../../hooks/useAccountDetailList';
import { AccountStatusType } from '../../__generated__/pge-types';
import { getClosedAccountsList } from './queries';
import useAccountListHeader from '../account-summary/multi-accounts/useAccountListHeader';
import { VirtualAccountType } from '../../constants/customEnum';
export default () => {
  const {
    onAccountSearch,
    onAccountSort,
    onAccountPageChange,
    accountListParams,
  } = useAccountListHeader();

  const {
    loading,
    error,
    accounts: closedAccounts,
    totalCount = 0,
  } = useAccountDetailList(
    getClosedAccountsList,
    VirtualAccountType.ALL_ACCTS,
    {
      accountListParams: {
        ...accountListParams,
        accountStatus: AccountStatusType.Inactive,
      },
    },
  );
  return {
    onAccountSearch,
    closedAccounts,
    onAccountSort,
    onAccountPageChange,
    loading,
    error,
    totalCount,
    accountListParams,
  };
};
