/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import useWrapWithLoader from '../../../hooks/useWrapWithLoading';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import { PostEnrollmentPaymentPreferences } from '../ssm.types';
import DaySelector from '../../day-selector';
import useSSMPostEnrollment from '../../../hooks/useSSMPostEnrollment';
import {
  ArrowForwardIos,
  CheckCircle,
  EditOutlined,
  DeleteOutline,
} from '@material-ui/icons';
import colors from '../../../themes/main-colors';
import AutoPayModal from './auto-pay-modal';
import { AutoPayConfigData } from '../../auto-pay/auto-pay.types';
import {
  getPaymentMethodLabel,
  useSavedProfileList,
} from '../../../hooks/usePaymentus';
import { dateSuffixMap } from '../../../util/format';
import {
  getDayFromDueDate,
  getPaymentMethodSelectorData,
  isDateInFuture,
  isEligibleToCancel,
  isEligibleToChangePaymentMethod,
  isPDDEligible,
} from '../../auto-pay/auto-pay.utils';
import useAccountCustomer from '../../../hooks/useAccountCustomer';
import { getAccountDisplayString } from '../../paymentus/utils';
import { useAutoPayService } from '../../auto-pay/auto-pay.hooks';
import Backdrop from '../../backdrop';
import clsx from 'clsx';
import { AutoPayErrorReason } from '../../../__generated__/pge-types';
import dayjs from 'dayjs';
import { Alert } from '@material-ui/lab';
import theme from '../../../themes/theme';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '18px',
      paddingLeft: '0',
    },
    pddInfo: {
      background: colors.lightGray3,
      padding: theme.spacing(0, 2),
      margin: theme.spacing(1, 0),
    },
    pddControl: {
      width: '100%',
    },
    pddControlDisabled: {
      background: colors.lightGray1,
      cursor: 'not-allowed',
    },
    autoPay: {
      padding: theme.spacing(2, 3.5),
      margin: theme.spacing(1, 0),
    },
    autoPayEnrolled: {
      border: '1px solid ' + colors.sparkBlue,
      background: colors.aliceBlue,
      boxShadow: '0px 3px 6px ' + colors.boxShadowColor,
    },
    autoPayNotEnrolled: {
      border: '1px solid ' + colors.lightGray1,
    },
    autoPayCardDetails: {
      width: '100%',
      borderTop: '1px solid ' + colors.sparkBlue,
      marginTop: theme.spacing(2),
    },
    sparkBlue: {
      color: colors.sparkBlue,
    },
    alignCenter: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: '5px',
      },
    },
    cursorPointer: {
      cursor: 'pointer',
    },
  }),
);
export const SSMPaymentPreferences = ({
  formState,
}: WizardFormProps<PostEnrollmentPaymentPreferences>) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  const {
    onChange: setPreferredDueDate,
    value: preferredDueDate,
  } = formState.props('preferredDueDate');

  const {
    account,
    refetchAccount,
    setNotificationMessage,
  } = useSSMPostEnrollment();
  const { customer } = useAccountCustomer();
  const {
    loading: profileListLoading,
    savedProfileList,
    refetch: refetchProfileList,
  } = useSavedProfileList(customer);
  const autoPayService = useAutoPayService();
  const { wrapWithLoader } = useWrapWithLoader();

  const { onChange: onAutoPayConfigChange } = formState.props('autoPayConfig');

  const [autoPayModalVisibility, setAutoPayModalVisibility] = useState<boolean>(
    false,
  );
  const [autoPayConfig, setAutoPayConfig] = useState<AutoPayConfigData | null>(
    null,
  );

  const [loading, setLoading] = useState<boolean>(true);
  const [isDueDateUpdated, setIsDueDateUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (!profileListLoading) {
      setLoading(false);
    }

    if (!isDueDateUpdated) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setPreferredDueDate(getPreferredDueDateEstimate());
    }

    const currentPaymentProfile = savedProfileList?.find(
      p => p.token === account?.autoPay?.enrollDetails?.tokenId,
    );

    setAutoPayConfig({
      selectedDay: null,
      paymentSelector: getPaymentMethodSelectorData(
        'autoPay',
        account?.paymentEligibility,
        account?.paymentAlerts?.isFutureDated,
        savedProfileList,
        currentPaymentProfile ? { profile: currentPaymentProfile } : undefined,
      ),
    });
  }, [account, savedProfileList]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    onAutoPayConfigChange(autoPayConfig);
  }, [autoPayConfig]);

  const refetch = () => Promise.all([refetchProfileList(), refetchAccount()]);

  const handleAutoPaySetup = wrapWithLoader(
    async (newAutoPayData: AutoPayConfigData | null) => {
      const autoPayResult = await autoPayService.enrollAutoPay(
        account!,
        newAutoPayData!,
      );

      const autoPayData = autoPayResult.data;
      if (autoPayData?.enrollAutoPay?.confirmationId) {
        setNotificationMessage(t('SSM_POST_AUTO_PAY_SETUP_SUCCESS'));
        setAutoPayConfig(newAutoPayData);
      } else {
        return setNotificationMessage(
          t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
          'error',
        );
      }
      await refetch();
    },
  );

  const handleAutoPayCancel = wrapWithLoader(async () => {
    const result = await autoPayService.cancelAutoPay(
      account?.accountNumber!,
      account?.encryptedPersonId!,
    );

    if (result.errors) {
      return setNotificationMessage(
        t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
        'error',
      );
    }
    if (!result.data?.cancelAutoPay?.isSuccess) {
      return setNotificationMessage(
        result?.data?.cancelAutoPay?.errorReason || AutoPayErrorReason.Unknown,
        'error',
      );
    }

    setNotificationMessage(t('AUTO_PAY_CANCEL_DONE_NOTIFICATION'));
    await refetch();
  });

  const getPreferredDueDateEstimate = () => {
    return (
      account?.preferredDueDate?.dueDate?.preferredDueDate ||
      (isDateInFuture(account?.currentCharges?.dueDate)
        ? getDayFromDueDate(account?.currentCharges?.dueDate)
        : null)
    );
  };

  if (loading) {
    return <Backdrop forceOpen />;
  }

  const isEnrolled =
    account?.autoPay?.isEnrolled ||
    autoPayConfig?.paymentSelector.selectedProfileInfo?.profile.token;

  const isEligibleToUpdateAutoPay =
    isEligibleToChangePaymentMethod(account?.autoPay) &&
    isEligibleToCancel(account?.autoPay);

  const lastPDDUpdateDate = account?.preferredDueDate?.dueDate?.effectiveDate;
  let isLastUpdateLessThan24Hours = false;

  if (lastPDDUpdateDate) {
    isLastUpdateLessThan24Hours = dayjs().diff(lastPDDUpdateDate, 'hour') < 24;
  }

  const pddEstimate = getPreferredDueDateEstimate();

  const isPDDEnabled = isPDDEligible(account?.preferredDueDate);

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item container spacing={2}>
        <Grid item container>
          <Box mb="8px">
            <Typography className={classes.header}>
              {t('SSM_POST_SELECT_PDD')}
            </Typography>
          </Box>
        </Grid>

        {pddEstimate && (
          <Grid item container>
            <Grid container item className={classes.pddInfo}>
              <Typography variant="body2" data-testid="pdd-estimate">
                {richT('SSM_POST_PDD_ESTIMATE', {
                  ESTIMATED_DAY: `${pddEstimate}${dateSuffixMap(
                    t,
                    pddEstimate,
                  )}`,
                })}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid item container>
        <Box css={{ width: '100%' }}>
          <DaySelector
            value={formState.props('preferredDueDate').value}
            onChange={day => {
              setIsDueDateUpdated(true);
              void formState.setValue('preferredDueDate', day);
            }}
            label={t('SSM_POST_PDD_FIELD_LABEL')}
            className={clsx({
              [classes.pddControl]: true,
              [classes.pddControlDisabled]:
                !isPDDEnabled || isLastUpdateLessThan24Hours,
            })}
            disabled={!isPDDEnabled || isLastUpdateLessThan24Hours}
          />
        </Box>
        {isLastUpdateLessThan24Hours && (
          <Box css={{ marginTop: theme.spacing(2), width: '100%' }}>
            {<Alert severity="error">{t('PDD_ALREADY_CHANGED_TODAY')}</Alert>}
          </Box>
        )}
      </Grid>

      <Grid item container>
        <Grid>
          <Box mb="8px">
            <Typography className={classes.header}>
              {t('SSM_POST_SETUP_AUTO_PAY')}
            </Typography>
          </Box>
        </Grid>
        <Grid
          container
          item
          className={clsx({
            [classes.autoPay]: true,
            [classes.autoPayEnrolled]: isEnrolled,
            [classes.autoPayNotEnrolled]: !isEnrolled,
          })}
          data-testid="setup-auto-pay"
          onClick={() => {
            !isEnrolled && setAutoPayModalVisibility(true);
          }}
        >
          <Grid
            item
            container
            spacing={0}
            className={clsx({
              [classes.cursorPointer]: !isEnrolled,
            })}
          >
            <Grid item container xs={8} md={8}>
              <Box pb={2}>
                <Typography variant="h3" className={classes.sparkBlue}>
                  {t('SSM_POST_SETUP_AUTO_PAY')}
                </Typography>
              </Box>

              <Typography variant="body2">
                {isEnrolled && !isEligibleToUpdateAutoPay && (
                  <Fragment>
                    {t('SSM_POST_AUTO_PAY_UPDATE_INELIGIBLE')}
                  </Fragment>
                )}
                {(!isEnrolled || isEligibleToUpdateAutoPay) &&
                  t('SSM_POST_AUTO_PAY_DESC')}
              </Typography>
            </Grid>
            <Grid container xs={4} md={4} justify="flex-end">
              <Box pt={2}>
                <ArrowForwardIos className={classes.sparkBlue} />
              </Box>
            </Grid>
          </Grid>
          {isEnrolled && (
            <Grid
              item
              container
              justify="space-between"
              className={classes.autoPayCardDetails}
              data-testid="enrollment-details"
            >
              <Box pt={1}>
                <Typography
                  variant="h4"
                  className={clsx(classes.sparkBlue, classes.alignCenter)}
                >
                  <CheckCircle htmlColor="#4DA189" />
                  {autoPayConfig?.paymentSelector?.selectedProfileInfo?.profile
                    ?.type ? (
                    <Fragment>
                      <strong>
                        {getPaymentMethodLabel(
                          autoPayConfig?.paymentSelector?.selectedProfileInfo
                            ?.profile?.type,
                        )}{' '}
                        -{' '}
                      </strong>
                      {getAccountDisplayString(
                        autoPayConfig?.paymentSelector?.selectedProfileInfo
                          ?.profile,
                      )}
                    </Fragment>
                  ) : (
                    <strong>{t('ENROLLED')}</strong>
                  )}
                </Typography>
              </Box>
              <Box pt={1}>
                {isEligibleToChangePaymentMethod(account?.autoPay) && (
                  <EditOutlined
                    htmlColor={colors.sparkBlue}
                    className={classes.cursorPointer}
                    onClick={() => setAutoPayModalVisibility(true)}
                  />
                )}
                {isEligibleToCancel(account?.autoPay) && (
                  <DeleteOutline
                    htmlColor={colors.sparkBlue}
                    className={classes.cursorPointer}
                    onClick={handleAutoPayCancel}
                  />
                )}
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
      {autoPayModalVisibility && (
        <AutoPayModal
          account={account!}
          autoPayConfigData={autoPayConfig}
          onClose={() => setAutoPayModalVisibility(false)}
          onComplete={async (e: AutoPayConfigData | null) => {
            setAutoPayModalVisibility(false);
            await handleAutoPaySetup(e);
          }}
        />
      )}
    </Grid>
  );
};
