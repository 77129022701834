import React, { FunctionComponent, useState } from 'react';
import useRenewablePower from '../../../hooks/useRenewablePower';
import {
  Grid,
  makeStyles,
  Theme,
  useTheme,
  Card,
  CardContent,
} from '@material-ui/core';
import { isMobile } from '../../../util/style-utils';
import PGEButton from '../../buttons';
import { navigate } from 'gatsby';
import ROUTES from '../../../routes';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  actionArea: {
    [theme.breakpoints.down('sm')]: {
      padingLeft: theme.spacing(4),
      padingRight: theme.spacing(4),
    },
  },
  formAction: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  textLG: {
    margin: theme.spacing(0, 0, 4, 0),
  },
  textSM: {
    margin: theme.spacing(0, 0, 2, 0),
  },
}));

interface RenewablePowerIneligibilityProps {
  path?: string;
  eligibility: string;
  content: any;
}

const RenewablePowerIneligibility: FunctionComponent<RenewablePowerIneligibilityProps> = props => {
  const classes = useStyles();
  const theme = useTheme();
  const { eligibility, content } = props;

  return (
    <Grid container spacing={2} direction={'column'}>
      <Grid item>
        <Card>
          <CardContent>
            <Grid container direction={'column'}>
              {eligibility === 'IneligibleHasDisconnect' ? (
                <Grid item>
                  <Typography variant={'h2'} className={classes.textLG}>
                    {content.ineligibilityPage.unavailable}
                  </Typography>
                  <Typography variant={'h4'} className={classes.textSM}>
                    {content.ineligibilityPage.not_eligible}
                  </Typography>
                  <Typography variant={'h4'}>
                    {content.ineligibilityPage.assistance}{' '}
                    {
                      <a href={'tel:' + content.ineligibilityPage.phone_number}>
                        {content.ineligibilityPage.phone_number}
                      </a>
                    }
                  </Typography>
                </Grid>
              ) : null}
              {eligibility === 'IneligibleHasMultipleServiceAgreement' ||
              eligibility === 'Unknown' ||
              eligibility === '' ? (
                <Grid item>
                  <Typography variant={'h2'} className={classes.textLG}>
                    {content.ineligibilityPage.unavailable}
                  </Typography>
                  <Typography variant={'h4'} className={classes.textSM}>
                    {content.ineligibilityPage.manage_renewables}{' '}
                    {
                      <a href={'tel:' + content.ineligibilityPage.phone_number}>
                        {content.ineligibilityPage.phone_number}
                      </a>
                    }{' '}
                    {content.ineligibilityPage.between_hours}
                  </Typography>
                </Grid>
              ) : null}
              {eligibility === 'IneligibleNoActiveServiceAgreement' ? (
                <Grid item>
                  <Typography variant={'h2'} className={classes.textLG}>
                    {content.ineligibilityPage.unavailable}
                  </Typography>
                  <Typography variant={'h4'} className={classes.textSM}>
                    {content.ineligibilityPage.not_available}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid
        item
        container
        style={
          isMobile()
            ? { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) }
            : {}
        }
        direction={'column'}
        alignItems={isMobile() ? 'stretch' : 'flex-end'}
      >
        <Grid item>
          <PGEButton
            variant={'contained'}
            color={'primary'}
            onClick={() => navigate(ROUTES.ACCOUNT)}
            className={classes.formAction}
          >
            {content.ok}
          </PGEButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RenewablePowerIneligibility;
