import React, { FC } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';

import { Grid, Typography } from '@material-ui/core';
import ChangeEmailForm from '../../../components/change-email-form';
import ProfileLayout from '../../../components/profile-layout';
import Helmet from 'react-helmet';

type Props = {
  path?: string;
};

const ChangeEmailPage: FC<Props> = () => {
  const { t } = useTranslation();
  const title = t('CHANGE_EMAIL');

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <ProfileLayout dense={false}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant={'h1'}>{title}</Typography>
          </Grid>
          <Grid item>
            <ChangeEmailForm />
          </Grid>
        </Grid>
      </ProfileLayout>
    </>
  );
};

export default ChangeEmailPage;
