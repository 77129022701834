import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withWidth from '@material-ui/core/withWidth';
import Link from '@material-ui/core/Link';
import { navigate, Router } from '@reach/router';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import AccountDropdown from '../../components/account-dropdown';
import Alerts from '../../components/alerts';
import Backdrop from '../../components/backdrop';
import { getOneTimePayWarningMessages } from '../../components/payment-form/oneTimePay.utils';
import {
  OneTimePayConfigData,
  OnetimePayWarning,
  SubmitOneTimePay,
} from '../../components/payment-form/oneTimePayment.types';
import PaymentInformationForm from '../../components/payment-form/payment-info-form';
import PaymentVerificationForm from '../../components/payment-form/payment-verification-form';
import Page2ColumnLayout from '../../components/utility/page2-column-layout';
import ViewBillLeftPane from '../../components/view-bill/view-bill-left-pane';
import useOneTimePayment from '../../hooks/useOneTimePayment';
import usePaymentForm from '../../hooks/usePaymentForm';
import { useTranslation } from '../../hooks/useTranslation';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import ROUTES from '../../routes';
import { toCurrencyString } from '../../util/format';
import Helmet from 'react-helmet';
import { useApolloClient } from '@apollo/react-hooks';
import theme from '../../themes/theme';

const PaymentPage = (props: { width: string; path?: string }) => {
  const { t, richT } = useTranslation();
  const payBillProps = usePaymentForm();
  const isMobile = ['xs', 'sm'].includes(props.width);
  const childProps = { isMobile, ...payBillProps };
  const { wrapWithLoader } = useWrapWithLoader();
  const notificationContext = useContext(NotificationsContext);
  const apolloClient = useApolloClient();
  const paymentMethodCapLimit: number = 2;
  const [
    oneTimePayConfig,
    setOneTimePayConfig,
  ] = useState<OneTimePayConfigData | null>(null);

  const [paymentWarningDismissed, setPaymentWarningDismissed] = useState<
    boolean
  >(false);
  const [eligibleValue, setEligibleValue] = useState<boolean>(false);

  const {
    accountLoading,
    accountDetails,
    encryptedPersonId,
    submitOneTimePay,
    refetchPayBillAccountDetails,
    customer,
    savedProfileList,
    profileListLoading,
    refetchProfileListDetails,
    refetchSingleAccountDetails,
  } = useOneTimePayment();

  function reset() {
    setOneTimePayConfig(null);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(ROUTES.PAYMENT, { replace: true });
  }

  const warningMessages = useMemo(() => {
    const alerts: OnetimePayWarning[] = getOneTimePayWarningMessages(
      accountDetails,
    );
    return alerts.map(item => <span key={item.type}>{item.message}</span>);
  }, [accountDetails?.autoPay, accountDetails?.paymentAlerts]);

  useEffect(() => {
    reset();
  }, [accountDetails?.accountNumber]);

  function handleConfigSubmit(data: OneTimePayConfigData) {
    setPaymentWarningDismissed(true);
    setEligibleValue(false);
    setOneTimePayConfig(data);
  }

  function handleEligibleValue(value: boolean) {
    setPaymentWarningDismissed(true);
    setEligibleValue(value);
  }
  if (
    accountDetails === undefined ||
    accountLoading ||
    customer === undefined ||
    savedProfileList === undefined ||
    profileListLoading
  ) {
    return <Backdrop forceOpen />;
  }

  const getFormattedPaymentAmount = () => {
    return oneTimePayConfig?.paymentAmount
      ? `$${toCurrencyString(
          parseFloat(oneTimePayConfig?.paymentAmount),
          false,
        )}`
      : '';
  };

  const getFormattedDonationAmount = () => {
    return oneTimePayConfig?.charitySelection &&
      oneTimePayConfig?.charitySelection !== 'none'
      ? `$${toCurrencyString(
          parseFloat(oneTimePayConfig?.charityAmount!),
          false,
        )}`
      : '';
  };

  const closeWarning = () => {
    setPaymentWarningDismissed(true);
  };

  const closeEligibleWarning = () => {
    setEligibleValue(false);
  };

  const handleConfirmSubmit = wrapWithLoader(async function() {
    try {
      notificationContext.setState({ isOpen: false });

      const additionalPayInfo: SubmitOneTimePay = {
        emailAddress: customer?.email,
        encryptedPersonId: encryptedPersonId!,
      };

      const oneTimeSubmitPayResult = await submitOneTimePay(
        accountDetails,
        oneTimePayConfig!,
        additionalPayInfo,
      );
      const oneTimeSubmitData = oneTimeSubmitPayResult.data;

      if (oneTimeSubmitData?.makePayment.paymentConfirmation.length) {
        const oneTimePayConfirmationData = oneTimeSubmitData.makePayment;
        try {
          typeof window !== 'undefined' &&
            window.history.pushState(
              null,
              '',
              oneTimePayConfirmationData.paymentConfirmation.some(
                p => !p.isSuccess,
              )
                ? ROUTES.ACCOUNT
                : ROUTES.PAYMENT,
            );
          await refetchPayBillAccountDetails();
          await refetchSingleAccountDetails();
        } catch (e) {
          await navigate(ROUTES.PAYMENT_CONFIRMATION, {
            state: {
              oneTimePayConfirmationData,
              oneTimePayConfig,
              paymentMethodCapLimit,
            },
          });
          // We could not refetch the account, so clear the account from the cache to
          // force a data reload
          // TODO: When we migrate to Apollo 3, use cache.evict with cache.gc
          const cache = apolloClient.cache as any;
          return cache.data?.delete(
            `AccountDetail:${accountDetails?.accountNumber}`,
          );
        }

        return navigate(ROUTES.PAYMENT_CONFIRMATION, {
          state: {
            oneTimePayConfirmationData,
            oneTimePayConfig,
            paymentMethodCapLimit,
          },
        });
      } else {
        window.scrollTo(0, 0);
        return notificationContext.setState({
          isOpen: true,
          message: richT('PAYMENT_ERROR_MESSAGE'),
          severity: 'error',
        });
      }
    } catch (_) {
      window.scrollTo(0, 0);
      return notificationContext.setState({
        isOpen: true,
        message: richT('PAYMENT_ERROR_MESSAGE'),
        severity: 'error',
      });
    }
  });

  return (
    <>
      <Helmet>
        <title>{t('PAY_BILL')}</title>
      </Helmet>
      <Page2ColumnLayout leftColumn={<ViewBillLeftPane />}>
        <Grid container direction="column" spacing={2}>
          {warningMessages.length > 0 && !paymentWarningDismissed && (
            <Grid item>
              <Alerts
                isOpen={!paymentWarningDismissed}
                severity={'warning'}
                variant={'filled'}
                title={t('PAYMENT_ALERT')}
                message={<>{warningMessages}</>}
                onClose={closeWarning}
              />
            </Grid>
          )}
          {eligibleValue && (
            <Grid item>
              <Alerts
                isOpen={eligibleValue}
                severity={'warning'}
                variant={'filled'}
                title={t('INELIGIBLE')}
                message={t('ONETIMEPAY_INELIGIBLE_MESSAGE')}
                onClose={closeEligibleWarning}
              />
            </Grid>
          )}
          <Grid item>
            <Typography variant={'h1'}>{t('PAY_BILL')}</Typography>
          </Grid>
          <Grid item>
            <AccountDropdown />
          </Grid>
          <Grid item>
            <Router basepath="/">
              <PaymentInformationForm
                path={ROUTES.PAYMENT}
                {...childProps}
                savedProfileList={savedProfileList}
                onSubmit={handleConfigSubmit}
                handleEligible={handleEligibleValue}
                oneTimePayConfigData={oneTimePayConfig}
                paymentMethodCapLimit={paymentMethodCapLimit}
                paymentInfo={{
                  amountDue: accountDetails.currentCharges?.amountDue!,
                  dueDate: accountDetails.currentCharges?.dueDate,
                  paymentEligibility: accountDetails?.paymentEligibility!,
                  pastDueAmount: accountDetails?.pastDueAmount!,
                }}
                backButtonText={t('CANCEL')}
                backButtonRoute={ROUTES.ACCOUNT}
                nextButtonRoute={ROUTES.PAYMENT_VERIFICATION}
                enableDonation={true}
                cardFooterContent={
                  <Grid
                    container
                    justify="space-between"
                    css={{
                      padding: !isMobile
                        ? theme.spacing(0, 4, 2, 4)
                        : theme.spacing(1, 0, 0, 0),
                      '& .MuiGrid-container': {
                        marginBottom: theme.spacing(2),
                      },
                    }}
                  >
                    {/* {accountDetails?.pastDueAmount! > 0 && <Grid item>{t('PAST_DUE')}{' $'}{accountDetails?.pastDueAmount!}</Grid>} */}
                    <Grid item>
                      <Link
                        css={{
                          textDecoration: 'underline',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                        onClick={() => navigate(ROUTES.AUTO_PAY)}
                      >
                        {accountDetails?.autoPay?.isEnrolled
                          ? t('AUTOPAY_SETTINGS')
                          : t('ENROLL_IN_AUTOPAY')}
                      </Link>
                    </Grid>
                  </Grid>
                }
              />
              <PaymentVerificationForm
                path={ROUTES.PAYMENT_VERIFICATION}
                {...childProps}
                oneTimePayConfigData={oneTimePayConfig!}
                formattedPaymentAmount={getFormattedPaymentAmount()}
                onSubmit={handleConfirmSubmit}
                paymentMethodCapLimit={paymentMethodCapLimit}
                charityAmount={getFormattedDonationAmount()}
              />
            </Router>
          </Grid>
        </Grid>
      </Page2ColumnLayout>
    </>
  );
};

export default withWidth()(PaymentPage);
