import {
  Button,
  CardContent,
  Paper,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { navigate } from 'gatsby';
import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import routes from '../../routes';
import colors from '../../themes/main-colors';
import theme from '../../themes/theme';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    desc: {
      [theme.breakpoints.up('sm')]: {
        width: 'fit-content',
      },
    },
    button: {
      color: colors.sparkBlue,
      border: `1px solid ${colors.sparkBlue}`,
      '&:hover': {
        border: `1px solid ${colors.sparkBlue}`,
        color: colors.sparkBlue,
        backgroundColor: colors.white,
      },
      '&:active': {
        border: `1px solid ${colors.sparkBlue}`,
        color: colors.sparkBlue,
        backgroundColor: colors.white,
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginTop: '20px',
      },
    },
    alignCenter: {
      alignContent: 'center',
    },
  }),
);

export const SSMDashboardCard = () => {
  const { t, inlineRichT } = useTranslation();
  const classes = useStyles();
  return (
    <Paper>
      <CardContent className={classes.content}>
        <div className={classes.desc}>
          <Typography>{inlineRichT('DO_YOU_NEED_TO_SSM_SERVICE')}</Typography>
        </div>
        <div className={classes.alignCenter}>
          <Button
            variant="outlined"
            className={classes.button}
            onClick={() => void navigate(routes.START_STOP_MOVE)}
          >
            {t('MANAGE_YOUR_SERVICE')}
          </Button>
        </div>
      </CardContent>
    </Paper>
  );
};
