import React from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import { Card, CardContent, createStyles, Grid, makeStyles, Paper, Theme, Typography } from '@material-ui/core';
import { WizardFormProps } from '../../utility/wizard/wizard.types';
import { DirectAccessHistoricalUsageModel } from '../../../hooks/useDirectAccessHistoricalUsage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop1: {
      marginTop: '1em',
    },
    marginBottom1: {
      marginBottom: '1em',
    },
    cardContent: {
      padding: 16, 
      marginBottom: 30
    },
    businessName: {
      marginBottom: '1em',
      fontWeight: 'bold'
    }
  }),
);

export const DirectAccessHistoricalUsageSummary = ({
  formState
}: WizardFormProps<DirectAccessHistoricalUsageModel>) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const selectedBusiness = formState.values.businesses.filter((item: { name: string, isSelected: boolean, groupId: string }) => item.isSelected);

  return (
    <>
      <Grid item xs={12} className={classes.marginTop1}>
        <Paper>
          <Card>
            <CardContent className={classes.cardContent}>
              <Grid container direction="column">
                <Grid item container>
                  <Grid item xs={12}>
                    <Typography variant="h2"
                      className={classes.marginBottom1}>
                      {t('SUMMARY')}
                    </Typography>
                    <Typography variant={'body1'}
                      className={classes.marginBottom1}
                      data-testid={'confirm-txt'}>
                      {t('HISTORICAL_USAGE_CONFIRM_TEXT')}
                    </Typography>
                    <Typography variant={'body1'}
                      className={classes.marginBottom1}>
                      {t('HISTORICAL_USAGE_CONFIRM_RELEASE_GROUPS_TEXT')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {selectedBusiness.map((business: { name: string, isSelected: boolean, groupId: string }, index: number) => (
                      <Typography variant={'body1'} key={index}
                        className={classes.businessName}
                        data-testid={'business-name'}>
                        {business.name}
                      </Typography>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant={'body1'}
                      className={classes.marginBottom1}
                      data-testid={'confirm-no-release-txt'}>
                      {t('HISTORICAL_USAGE_CONFIRM_NO_RELEASE_NOTE')}
                    </Typography>
                    <Typography variant={'body1'}
                      className={classes.marginBottom1}
                      data-testid={'confirm-select-service-txt'}>
                      {t('HISTORICAL_USAGE_CONFIRM_SELECT_SERVICE_NOTE')}
                    </Typography>
                    <Typography variant={'body1'}
                      className={classes.marginBottom1}
                      data-testid={'confirm-auth-release-txt'}>
                      {t('HISTORICAL_USAGE_CONFIRM_RELEASE_NOTE')}
                    </Typography>
                    <Typography variant={'body1'}
                      className={classes.marginBottom1}
                      data-testid={'confirm-release-prohibition-txt'}>
                      {t('HISTORICAL_USAGE_CONFIRM_RELEASE_PROHIBITION_NOTE')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Paper>
      </Grid>
    </>
  );
};
