import React from 'react';
import MaskedInput from 'react-text-mask';

import { useTranslation } from '../../hooks/useTranslation';
import TextField, { TextFieldProps } from '../text-field/TextField';

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}
const numeric = /[0-9]/;
const alfhabetic = /[a-zA-Z]/;

const TextMaskCustom = (textMaskProps: TextMaskCustomProps | any) => {
  const { inputRef, inputProps, ...other } = textMaskProps;
  return (
    <MaskedInput
      {...other}
      name={textMaskProps.name || 'provinceCode'}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[alfhabetic, numeric, alfhabetic, numeric, alfhabetic, numeric]}
      guide={false}
      showMask
    />
  );
};

const ProvinceInputField = React.forwardRef(
  (props: TextFieldProps, ref?: React.Ref<HTMLInputElement>) => {
    const { t } = useTranslation();

    return (
      <TextField
        label={props.label || t('PROVINCE_CODE')}
        {...props}
        ref={ref}
        name={props.name || 'provinceCode'}
        helperText={props.helperText || props.error}
        defaultValue={props.defaultValue || null}
        style={props.style || { width: '100%' }}
        InputProps={{
          ...props.InputProps,
          inputComponent: TextMaskCustom,
        }}
        inputProps={{
          ...props.inputProps,
          type: 'tel', // Use tel instead of number to avoid browser behaviour
        }}
      />
    );
  },
);
export default ProvinceInputField;
