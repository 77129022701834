import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect, useReducer } from 'react';
import { parseISO } from 'date-fns';
import AccountList from '../account-summary/multi-accounts/AccountList';
import SortFilterPageHeader from '../account-summary/sort-filter-page-header/SortFilterPageHeader';
import useAccountDetailList from '../../hooks/useAccountDetailList';
import { getAccountsList } from './queries';
import { useTranslation } from '../../hooks/useTranslation';
import useSelectedGroupId from '../../hooks/useSelectedGroupId';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import AccountListReducer from '../account-summary/multi-accounts/accountListReducer';
import Button from '../buttons';
import Backdrop from '../backdrop';
import colors from '../../themes/main-colors';
import useAccountMulticheckState from '../../hooks/useAccountMulticheckState';
import Box from '@material-ui/core/Box';
import { AccountDetail } from '../../__generated__/pge-types';
import { navigate } from '@reach/router';
import ROUTES from '../../routes';
import Alerts from '../alerts';
import { SelectAccountsState } from './types';
import { toDateStringFullMonthName } from '../../util/format';
import useAccountListHeader from '../account-summary/multi-accounts/useAccountListHeader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonRow: {
      marginTop: '1em',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    buttonContainer: {
      display: 'flex',
      marginLeft: '4px',
      [theme.breakpoints.down('md')]: {
        marginLeft: '0',
        marginTop: '1em',
        width: '100%',
        justifyContent: 'center',
      },
    },
    button: {
      background: colors.orange,
      ':hover': {
        background: colors.sparkBlue,
      },
      [theme.breakpoints.down('md')]: {
        width: '80%',
      },
    },
  }),
);

const defaultState: SelectAccountsState = {
  accountListState: AccountListReducer.initialState,
  checkedState: {},
};

type Props = {
  path?: string;
  initialState?: SelectAccountsState;
  onSubmit: (
    accounts: Array<AccountDetail>,
    state: SelectAccountsState,
  ) => void;
};

export default function SelectAccountsStep({
  onSubmit,
  initialState = defaultState,
}: Props) {
  const { t } = useTranslation();
  const { selectedGroupId } = useSelectedGroupId();
  const { setSelectedAccountParams } = useSelectedAccountParams();
  const {
    accountListParams,
    onAccountPageChange,
    onAccountSearch,
    onAccountSort,
  } = useAccountListHeader();

  const {
    loading: hookLoading,
    detailList,
    accounts,
    totalCount = 0,
  } = useAccountDetailList(getAccountsList, selectedGroupId, {
    accountListParams,
  });
  const loading = !detailList || hookLoading;

  const multicheckState = useAccountMulticheckState({
    initialChecked: initialState.checkedState,
    query: accountListParams.query,
  });

  useEffect(() => {
    if (multicheckState.isCurrentChargesError) {
      typeof window !== 'undefined' && window.scrollTo(0, 0);
    }
  }, [multicheckState.isCurrentChargesError]);

  const onPayAllAccountsWithBalanceSubmit = async () => {
    const allAccountsWithBalance = await multicheckState.getAllAccountsWithBalance(
      true,
    );
    onSubmit(allAccountsWithBalance, {
      accountListState: accountListParams,
      checkedState: multicheckState.checked,
    });
  };

  const onRenderSelectAllCheckbox = () => (
    <FormControlLabel
      control={
        <Checkbox
          name={'selectAll'}
          checked={multicheckState.areAllWithBalanceSelected}
          onChange={async e => {
            await multicheckState.toggleSelectAllWithBalance(e.target.checked);
          }}
          color={'primary'}
        />
      }
      disabled={multicheckState.selectAllDisabled}
      label={`${t('SELECT_ALL_WITH_AMOUNT_DUE')}${
        multicheckState.checkedOptions().length
          ? ` (${multicheckState.checkedOptions().length})`
          : ''
      }`}
    />
  );
  const classes = useStyles();

  const hasError = !loading && multicheckState.options.length === 0;

  return (
    <>
      {loading && <Backdrop forceOpen />}

      {!loading && (
        <SortFilterPageHeader
          onAccountSearch={onAccountSearch}
          onAccountSort={onAccountSort}
          onAccountPageChange={onAccountPageChange}
          accountListParams={accountListParams}
          totalCount={totalCount}
        />
      )}

      {hasError && (
        <Box marginTop={2}>
          <Alerts
            disableColorOverride={true}
            isOpen
            severity="error"
            variant="outlined"
            message={t('MULTI_PAY_NO_ACCOUNTS')}
          />
        </Box>
      )}

      {!loading && !hasError && (
        <Box marginTop={2}>
          <Grid item xs={12}>
            <AccountList
              inactiveText={t('CLOSED')}
              accounts={accounts || []}
              multicheckState={multicheckState}
              onViewDetails={account => {
                setSelectedAccountParams(selectedGroupId!, account);
                return navigate(ROUTES.ACCOUNT);
              }}
              onRenderSelectAll={onRenderSelectAllCheckbox}
              onPayAll={onPayAllAccountsWithBalanceSubmit}
              billMenuType="VIEW"
            />
          </Grid>

          <Grid item xs={12} className={classes.buttonRow}>
            <Grid item>
              <Typography variant={'body2'}>
                {detailList?.timestamp &&
                  `${t('AMOUNT_DUE_INCLUDES')} ${toDateStringFullMonthName(
                    parseISO(detailList?.timestamp),
                  )}.`}
              </Typography>
            </Grid>
            <Grid item className={classes.buttonContainer}>
              <Button
                disabled={multicheckState.getSelected().length === 0}
                size={'large'}
                className={classes.button}
                onClick={() =>
                  onSubmit(multicheckState.getSelectedAccounts(), {
                    accountListState: accountListParams,
                    checkedState: multicheckState.checked,
                  })
                }
              >
                {t('PAY_SELECTED')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
