import React from 'react';
import {
  Box,
  Checkbox,
  createStyles,
  FormControlLabel,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { ArrowForwardIos, CheckCircle } from '@material-ui/icons';
import clsx from 'clsx';
import { useTranslation } from '../../../../../hooks/useTranslation';
import colors from '../../../../../themes/main-colors';
import { OtherPeopleResponse } from '../../../../../__generated__/pge-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: theme.typography.fontWeightBold,
    },
    cardDetails: {
      paddingLeft: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    card: (props: { isUsersExists: boolean }) => ({
      padding: props.isUsersExists
        ? theme.spacing(3.5, 3.5, 1.5, 3.5)
        : theme.spacing(3.5),
      margin: theme.spacing(1, 0),
      cursor: props.isUsersExists ? 'default' : 'pointer',
    }),
    enrolled: {
      border: '1px solid ' + colors.sparkBlue,
      background: colors.aliceBlue,
      boxShadow: '0px 3px 6px ' + colors.boxShadowColor,
    },
    notEnrolled: {
      border: '1px solid ' + colors.lightGray1,
    },
    sparkBlue: {
      color: colors.sparkBlue,
    },
    footer: {
      width: '100%',
      borderTop: '1px solid ' + colors.sparkBlue,
      paddingTop: theme.spacing(1),
      margin: theme.spacing(2, 2, 0, 2),
    },
    alignCenter: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: '5px',
      },
    },
    cursorPointer: {
      cursor: 'pointer !important',
    },
    fullWidth: {
      width: '100%',
    },
    flexJustify: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    noUserCase: {
      width: '100%',
      alignItems: 'center',
      paddingLeft: theme.spacing(2),
    },
  }),
);

export interface AddUsersCardProps {
  title: string;
  description: React.ReactNode | string;
  showAddUsersModal?: () => void;
  usersList?: OtherPeopleResponse[] | undefined;
  authUsers?: boolean;
  isSSMMoveFlow?: boolean;
  transferServiceUserList?: Array<OtherPeopleResponse>;
  handleUserChecked?: (personId: string) => void;
}

export default ({
  title,
  description,
  usersList,
  showAddUsersModal,
  authUsers = false,
  isSSMMoveFlow = false,
  transferServiceUserList,
  handleUserChecked,
}: AddUsersCardProps) => {
  const isUsersExists = usersList && usersList.length > 0;
  const classes = useStyles({ isUsersExists: !!isUsersExists });
  const { t } = useTranslation();

  const addUsers = () => {
    if (!isSSMMoveFlow && showAddUsersModal && (!isUsersExists || authUsers)) {
      showAddUsersModal();
    }
  };

  return (
    <>
      <Grid
        container
        item
        className={clsx({
          [classes.card]: true,
          [classes.enrolled]: isUsersExists,
          [classes.notEnrolled]: !isUsersExists,
          [classes.cursorPointer]: authUsers,
        })}
        onClick={addUsers}
        data-testid="program-card"
      >
        <Grid item container spacing={0}>
          <Grid item container className={classes.cardDetails}>
            <Grid
              item
              container
              justify="space-between"
              className={classes.title}
            >
              <Box pt={1}>
                <Typography
                  variant="h2"
                  className={clsx(classes.sparkBlue, classes.bold)}
                >
                  {title}
                </Typography>
              </Box>
              {(!isUsersExists || authUsers) && !isSSMMoveFlow && (
                <Box pt={1}>
                  <ArrowForwardIos className={classes.sparkBlue} />
                </Box>
              )}
            </Grid>

            <Typography variant="body2">{description}</Typography>
          </Grid>
        </Grid>
        {isUsersExists && (
          <Grid
            item
            container
            justify="space-between"
            className={classes.footer}
            data-testid="program-enrolled"
          >
            {usersList &&
              usersList.map(user => (
                <Box
                  pt={1}
                  className={clsx({
                    [classes.fullWidth]: true,
                    [classes.flexJustify]: true,
                  })}
                >
                  <Typography
                    variant="h4"
                    className={clsx(classes.sparkBlue, classes.alignCenter)}
                  >
                    {!isSSMMoveFlow && (
                      <CheckCircle htmlColor={colors.shoreGreen} />
                    )}
                    <strong>{`${user.firstName} ${user.middleName} ${user.lastName}`}</strong>
                  </Typography>
                  {isSSMMoveFlow && transferServiceUserList && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={'checkBox'}
                          checked={
                            !!transferServiceUserList.find(
                              item => item.perId === user.perId,
                            )
                          }
                          onChange={() => {
                            if (user && user.perId && handleUserChecked) {
                              handleUserChecked(user.perId);
                            }
                          }}
                          color={'primary'}
                          disabled={!isUsersExists}
                        />
                      }
                      label={t('Transfer')}
                    />
                  )}
                </Box>
              ))}
            {!isUsersExists && isSSMMoveFlow && (
              <>
                <strong>{t('NONE_LISTED')}</strong>
                <Checkbox
                  name={'checkBox'}
                  checked={false}
                  onChange={e => () => console.log('checked')}
                  disabled={!isUsersExists}
                  color={'primary'}
                />
              </>
            )}
          </Grid>
        )}
        {!isUsersExists && isSSMMoveFlow && (
          <Box
            className={clsx({
              [classes.flexJustify]: true,
              [classes.noUserCase]: true,
            })}
          >
            <Typography variant="h4">{t('NONE_LISTED')}</Typography>
            <Checkbox
              name={'checkBox'}
              checked={false}
              onChange={e => () => console.log('checked')}
              disabled={!isUsersExists}
              color={'primary'}
            />
          </Box>
        )}
      </Grid>
    </>
  );
};
