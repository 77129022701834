import React, { FunctionComponent, useState, useContext } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  makeStyles,
  Theme,
  FormHelperText,
  Dialog,
  DialogTitle,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { isMobile } from '../../../util/style-utils';
import Link from '../../text-link';
import { useTranslation } from '../../../hooks/useTranslation';
import PGEButton from '../../buttons';
import { navigate } from '@reach/router';
import ROUTES from '../../../routes';
import useDirectAccess from '../../../hooks/useHistoricalUsage';
import mainColors from '../../../themes/main-colors';
import ContactInfoForm from '../../contact-info-form';
import useContactInfoForm, {
  mapFormDataToContactInfo,
} from '../../../hooks/useContactInfoForm';
import clsx from 'clsx';
import {
  HistoricalUsageContext,
  HistoricalUsageContextActionType,
  HistoricalUsageAuthType,
} from '../../../providers/HistoricalUsageProvider';
import SubmittingContactInfoRules from './SubmittingContactInfo.rules';
import AutorizingContactInfoRules from './AuthorizingContactInfo.rules';
import CloseIcon from '@material-ui/icons/Close';
import BusinessAccountsDialog from './BusinessAccountsDialog';

const useStyles = makeStyles((theme: Theme) => ({
  optionLink: {
    cursor: 'pointer',
    color: mainColors.sparkBlue,
  },
  optionText: {
    paddingLeft: theme.spacing(4),
  },
  formAction: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

interface HistoricalUsageFormProps {
  path?: string;
}

const HistoricalUsageForm: FunctionComponent<HistoricalUsageFormProps> = props => {
  const { t } = useTranslation();
  const { content } = useDirectAccess();
  const classes = useStyles();
  const { state, dispatch } = useContext(HistoricalUsageContext);
  const [isSamePersonAuthorizing, setIsSamePersonAuthorizing] = useState(
    state.isSamePersonAuthorizing,
  );
  const contactInfoFormProps = useContactInfoForm(
    SubmittingContactInfoRules,
    state.submittingContact,
  );
  const authContactInfoFormProps = {
    isSamePersonAuthorizing,
    setIsSamePersonAuthorizing,
    ...useContactInfoForm(AutorizingContactInfoRules, state.authorizingContact),
  };
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  const handleNext = () => {
    let isInvalid = false;
    const submittingContactResult = contactInfoFormProps.handleSubmit();
    const authorizingContactResult = !isSamePersonAuthorizing
      ? authContactInfoFormProps.handleSubmit()
      : undefined;

    isInvalid =
      !state.businesses.some(b => b.isSelected) ||
      submittingContactResult?.errors?.length ||
      authorizingContactResult?.errors?.length;

    if (isInvalid) {
      setShowValidationErrors(true);
      return;
    }

    dispatch({
      type: HistoricalUsageContextActionType.SET_CONTACT_INFO,
      isSamePersonAuthorizing,
      submittingContact: mapFormDataToContactInfo(submittingContactResult.data),
      authorizingContact: !isSamePersonAuthorizing
        ? mapFormDataToContactInfo(authorizingContactResult.data)
        : undefined,
    });

    return navigate(ROUTES.HISTORICAL_USAGE_CONFIRM);
  };

  return (
    <Grid container direction={'column'} spacing={4}>
      <Grid item>
        <Typography variant={'h1'}>{content.title}</Typography>
        <Typography variant={'body2'}>{content.formPage.summary}</Typography>
      </Grid>
      <Grid item>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <HistoricalUsageFormBusinesses
              showValidationErrors={showValidationErrors}
            />
          </Grid>
          <Grid item>
            <HistoricalUsageFormAuthorization />
          </Grid>
          <Grid item>
            <ContactInformation {...contactInfoFormProps} />
          </Grid>
          <Grid item>
            <AuthContactInformation {...authContactInfoFormProps} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          direction={isMobile() ? 'column-reverse' : 'row'}
          justify={'space-between'}
          alignItems={isMobile() ? 'stretch' : 'center'}
          spacing={1}
        >
          <Grid item>
            <Grid container justify={'center'}>
              <Link plain to={ROUTES.ACCOUNT}>
                <Typography className={classes.formAction}>
                  {t('CANCEL')}
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Grid item>
            <PGEButton
              variant={'contained'}
              color={'primary'}
              className={classes.formAction}
              onClick={handleNext}
            >
              {t('NEXT')}
            </PGEButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const HistoricalUsageFormBusinesses: FunctionComponent<{
  showValidationErrors: boolean;
}> = props => {
  const { content } = useDirectAccess();
  const { state, dispatch } = useContext(HistoricalUsageContext);
  const classes = useStyles();

  const openBusinessDialog = (business: any) => {
    setOpen(true);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value: string) => {
    setOpen(false);
  };

  const handleBussinesSelection = (name: string, isSelected: boolean) => {
    dispatch({
      type: HistoricalUsageContextActionType.BUSINESS_SELECTION,
      name,
      isSelected,
    });
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container direction={'column'} spacing={2}>
            <Grid item>
              <Typography variant={'h2'}>
                {content.formPage.businesses.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body2'}>
                {content.formPage.businesses.text}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body2'}>
                {content.formPage.businesses.note}
              </Typography>
            </Grid>
            <Grid item>
              <Divider />
            </Grid>
            <Grid item>
              <Typography variant={'h2'}>
                {content.formPage.businesses.listTitle}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body2'}>
                {content.formPage.businesses.listText}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container direction={'column'} spacing={1}>
                {props.showValidationErrors &&
                  !state.businesses.some(b => b.isSelected) && (
                    <FormHelperText
                      className={clsx(
                        'MuiFormHelperText-root MuiFormHelperText-contained Mui-error',
                      )}
                    >
                      {content.formPage.businesses.noSelectionError}
                    </FormHelperText>
                  )}
                {state.businesses.map((business, index) => (
                  <Grid
                    key={index}
                    container
                    direction={'row'}
                    alignItems={'center'}
                  >
                    <Grid item>
                      <Checkbox
                        name={`business_${index}`}
                        checked={business.isSelected}
                        onChange={e =>
                          handleBussinesSelection(
                            business.name,
                            e.target.checked,
                          )
                        }
                        color="primary"
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        variant={'h4'}
                        onClick={() => openBusinessDialog(business)}
                        className={classes.optionLink}
                      >
                        {business.name}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant={'body2'}>
                {content.formPage.businesses.listNote}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <BusinessAccountsDialog open={open} onClose={handleClose} />
    </>
  );
};

const HistoricalUsageFormAuthorization: FunctionComponent = () => {
  const { content } = useDirectAccess();
  const { state, dispatch } = useContext(HistoricalUsageContext);
  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: HistoricalUsageContextActionType.SET_AUTH_TYPE,
      authType: event.target.value as HistoricalUsageAuthType,
    });
  };
  return (
    <Card>
      <CardContent>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <Typography variant={'h2'}>
              {content.formPage.authorization.title}
            </Typography>
          </Grid>
          <Grid item>
            <RadioGroup
              aria-label={'direct-access-authorization'}
              name={'directAccessAuthorization'}
              value={state.authType}
              onChange={handleChange}
            >
              <FormControlLabel
                value={HistoricalUsageAuthType.RELEASE}
                control={<Radio color={'primary'} />}
                label={content.formPage.authorization.releaseOption}
              />
              <Typography variant={'body2'} className={classes.optionText}>
                {content.formPage.authorization.releaseOptionText}
              </Typography>

              <FormControlLabel
                value={HistoricalUsageAuthType.REVOKE}
                control={<Radio color={'primary'} />}
                label={content.formPage.authorization.revokeOption}
              />
              <Typography variant={'body2'} className={classes.optionText}>
                {content.formPage.authorization.revokeOptionText}
              </Typography>
            </RadioGroup>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const ContactInformation: FunctionComponent = (props: any) => {
  const { content } = useDirectAccess();

  return (
    <Card>
      <CardContent>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <Typography variant={'h2'}>
              {content.formPage.contactInformation.title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body2'}>
              {content.formPage.contactInformation.text}
            </Typography>
          </Grid>
          <Grid item>
            <ContactInfoForm {...props} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

const AuthContactInformation: FunctionComponent = (props: any) => {
  const { content } = useDirectAccess();
  const { isSamePersonAuthorizing, setIsSamePersonAuthorizing } = props;

  return (
    <Card>
      <CardContent>
        <Grid container direction={'column'} spacing={2}>
          <Grid item>
            <Typography variant={'h2'}>
              {content.formPage.authContacInformation.title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body2'}>
              {content.formPage.authContacInformation.text}
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isSamePersonAuthorizing}
                  onChange={e => setIsSamePersonAuthorizing(e.target.checked)}
                  name="samePersonAuthorizing"
                  color="primary"
                />
              }
              label={
                content.formPage.authContacInformation.samePersonAuthorizing
              }
            />
          </Grid>
          {!isSamePersonAuthorizing && (
            <Grid item>
              <ContactInfoForm {...props} />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default HistoricalUsageForm;
