/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, { FC, ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import useContentMgmt from '../../../hooks/useContentMgmt';
import microcopyGroupIds from '../../pge-plus-common/microcopyGroupIds';
import Buttons from '../../buttons/PGE-Button';
import routes from '../../../routes';
import Grid from '@material-ui/core/Grid';
import { Link } from 'gatsby';

interface SuccessPageProps {
  children?: ReactNode;
  path: string;
}

export const Success: FC<SuccessPageProps> = props => {
  const { content, richText } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_EV_SUCCESS,
  );

  return (
    <Box mt={4}>
      <Box>
        <Typography css={{ marginTop: 16, fontSize: '20px' }} component="div">
          {richText(content.get('COST_ESTIMATION_SUBMIT_SUCCESS_BODY'))}
        </Typography>
      </Box>

      <Grid
        container
        spacing={2}
        css={{
          marginTop: 50,
          marginBottom: 24,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Grid item xs={6}>
          <Link to={routes.ACCOUNT}>
            <Buttons
              type="submit"
              fullWidth
              variant="contained"
              css={{
                fontSize: '18px',
                borderRadius: '30px',
              }}
            >
              {content.get('REUSABLE_BUTTON_CLOSE')}
            </Buttons>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};
