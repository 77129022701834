import gql from 'not-graphql-tag';

export const getViewBillInfo = gql`
  query getViewBillInfo($payload: ViewBillDetailsRequest!) {
    getViewBillInfo(payload: $payload) {
      viewBillDetails {
        encryptedBillId
        downloadBillUrl
        billStatus
        billDate
        dueDate
        amountDue
        previousBalance
        totalAdjustments
        totalCurrentCharges
        totalBalanceAfterBill
        hasBills
        kwh
        billingPeriodStartDate
        billingPeriodEndDate
      }
      viewBillAverageTemperature {
        temperatureSource
        currentBillingPeriod {
          date
          averageTemperature
          totalCost
          totalKwh
        }
        previousBillingPeriod {
          date
          averageTemperature
          totalCost
          totalKwh
        }
      }
      viewBillMonthUsage {
        usages {
          monthName
          year
          totalKwh
        }
      }
    }
  }
`;

export const viewBillDownloadPdf = gql`
  query viewBillDownloadPdf($payload: ViewBillDownloadPdfRequest) {
    viewBillDownloadPdf(payload: $payload) {
      pdf
      success
    }
  }
`;

export const getViewPaymentHistoryChargeSummary = gql`
  query getViewPaymentHistoryChargeSummary($payload: ViewPaymentHistoryInput) {
    getViewPaymentHistoryChargeSummary(payload: $payload) {
      billingAndPaymentDetails {
        date
        billingPeriodStartDate
        billingPeriodEndDate
        amountDue
        kwh
        amountPaid
        encryptedBillId
        type
      }
    }
  }
`;
