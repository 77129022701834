export const unEnrollmentReasonCodes = [
  { key: 'TOODIFFICULT', label: 'IT_IS_TOO_DIFFICULT_TO_SHIFT' },
  { key: 'NOTENOUGHINFO', label: 'I_DONT_HAVE_ENOUGH_INFORMATION' },
  { key: 'UNAWAREOFENROL', label: 'I_WAS_NOT_AWARE' },
  { key: 'NOTWORTHIT', label: 'THE_SAVINGS_ON_TIME_OF_DAY' },
];

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
