import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { FunctionComponent } from 'react';
import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import AccountTextField from '../../account-text-field';
import Button from '../../buttons';
import PhoneTextField from '../../phone-text-field';
import SampleBill from '../../sample-bill';
import TextField from '../../text-field';
import TextLink from '../../text-link';
import useFormState from '../../../hooks/useFormState';
import validate, { defaultValues } from './ManageGroupsAccount.rules';
import EitherField from '../../either-field';
import { useIsMobile } from '../../../util/style-utils';
import { useManageGroupFormProps } from '../../../hooks/useManageAccountGroups';

const useStyles = makeStyles((theme: Theme) => ({
  colorTextPrimary: {
    color: colors.noirBlur,
  },
  radioStyle: {
    [theme.breakpoints.up('md')]: {
      marginRight: -theme.spacing(4),
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginLeft: 0,
    },
  },
  buttonRow: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'center',
    },
  },
  buttonContainer: {
    display: 'flex',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      width: '100%',
      justifyContent: 'center',
    },
  },
  button: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(6),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(6),
    },
  },
  textContainer: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(3),
    },
  },
}));

type Props = {
  path?: string;
};

export default function ManageGroupsAccount(props: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { handleSubmit } = useManageGroupFormProps();
  const form = useFormState(defaultValues, {
    validate,
    validationContext: { t },
  });
  const isMobile = useIsMobile();

  return (
    <>
      <Paper>
        <Card>
          <CardContent>
            <Grid container style={{ display: 'flex', flexDirection: 'row' }}>
              <Grid item xs={12}>
                <Typography variant={'h2'}>{t('ADD_ACCOUNT_GROUP')}</Typography>
              </Grid>
              <Typography
                className={classes.colorTextPrimary}
                component={'span'}
                color={'textPrimary'}
                variant={'body1'}
                style={{ padding: 5, marginTop: 15, marginBottom: 15 }}
              >
                {t('ADD_BUSINESS_ACCOUNT_MSG')}
              </Typography>
              <Grid item xs={12} md={6}>
                <AccountTextField
                  data-testid={'create-rbusiness-acccount-number'}
                  name={'accountNumber'}
                  label={t('ACCOUNT_NUMBER')}
                  autoComplete={t('ACCOUNT_NUMBER')}
                  type={'number'}
                  inputProps={{ minLength: 10, maxLength: 10, type: 'number' }}
                  {...form.props('accountNumber')}
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <p className={classes.textContainer}>
                  <SampleBill />
                </p>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  name={'businessName'}
                  label={t('BUSINESS_NAME')}
                  style={{ width: '100%', marginTop: 20 }}
                  inputProps={{ minLength: 1, maxLength: 256 }}
                  {...form.props('businessName')}
                />
              </Grid>
              <Typography
                className={classes.colorTextPrimary}
                component={'span'}
                color={'textPrimary'}
                variant={'body1'}
                style={{ padding: 5, marginTop: 15, marginBottom: 15 }}
              >
                {t('IDENTIFY_YOUR_BUSINESS_WITH_PHONE_OR_EIN')}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <EitherField
                    noDivider
                    form={form}
                    selectorField="verificationType"
                    inputFields={['ein', 'phoneNumber']}
                    renderLeft={
                      <TextField
                        data-testid={'validate-ein-number'}
                        name={'ein'}
                        label={t('EIN_NUMBER')}
                        inputProps={{ minLength: 10, maxLength: 10 }}
                      />
                    }
                    renderRight={
                      <PhoneTextField
                        data-testid={'validate-phone-number'}
                        name={'phoneNumber'}
                        label={t('PHONE_NUMBER')}
                        autoComplete={'telephone'}
                        inputProps={{ type: 'telephone' }}
                      />
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={isMobile ? 1 : 4}
                    justify={isMobile ? 'center' : 'flex-end'}
                    alignItems="center"
                    wrap="wrap-reverse"
                  >
                    <Grid item xs={10} md="auto">
                      <Grid container justify="center">
                        <Grid item>
                          <TextLink
                            plain
                            to={ROUTES.MANAGE_ACCOUNTS_LANDING_SCREEN}
                          >
                            {t('CANCEL')}
                          </TextLink>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={10} md="auto">
                      <Button
                        onClick={form.submit(data => handleSubmit(data, form))}
                        size={'large'}
                        className={classes.button}
                        fullWidth
                      >
                        {t('ADD')}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Paper>
    </>
  );
}
