import { useApolloClient } from '@apollo/react-hooks';
import {
  PreferredDueDateAddResponse,
  PreferredDueDateAddRequest,
  PreferredDueDateStatus,
} from '../../__generated__/pge-types';
import * as queries from '../../queries/preferredDueDay.query';
import { getAccounDetailsForCacheUpdate } from './queries';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';

export enum UpdatePDDResponse {
  Error = 'Error',
  ChangedToday = 'ChangedToday',
  NotEligible = 'NotEligible',
  Success = 'Success',
}

const usePDDServices = () => {
  const apolloClient = useApolloClient();
  const { accountParams } = useSelectedAccountParams();

  function updateAccountCache() {
    return apolloClient.query({
      query: getAccounDetailsForCacheUpdate,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables: {
        params: {
          accountNumberList: [accountParams],
        },
      },
    });
  }

  async function updatePDD(
    encryptedAcctNum: string,
    day: number,
  ): Promise<UpdatePDDResponse> {
    const result = await apolloClient.mutate<
      {
        addPreferredDueDate: PreferredDueDateAddResponse;
      },
      { payload: PreferredDueDateAddRequest }
    >({
      mutation: queries.updatePreferredDueDate,
      variables: {
        payload: {
          encryptedAccountNumber: encryptedAcctNum,
          preferredDueDate: day,
        },
      },
      errorPolicy: 'all',
    });
    await updateAccountCache();

    if (result.data) {
      const updateStatus = result.data.addPreferredDueDate.status!;
      // I'm abstracting/simplifying the response for the form.
      switch (updateStatus) {
        case PreferredDueDateStatus.Added:
          return UpdatePDDResponse.Success;
          break;
        case PreferredDueDateStatus.NotChanged:
          // NotChanged is returned when the value submitted was the same.
          return UpdatePDDResponse.Success;
          break;
        case PreferredDueDateStatus.ChangedToday:
          return UpdatePDDResponse.ChangedToday;
          break;
        case PreferredDueDateStatus.NotEligible:
          return UpdatePDDResponse.NotEligible;
          break;
        case PreferredDueDateStatus.NotAdded:
          // NotAdded when e.g. invalid value like 31 (not available option. ). Considering it as error
          console.log(
            'Error when calling updatePreferredDueDate. Not added due to unknown reason.',
          );
          return UpdatePDDResponse.Error;
          break;
        default:
          // All status result in error; currently: Found/NotFound/Error
          // Found and NotFound is in the enum but shouldn't be used here.
          return UpdatePDDResponse.Error;
          break;
      }
    }
    if (result.errors) {
      console.log('Error when calling updatePreferredDueDate: ', result.errors);
      return UpdatePDDResponse.Error;
    }
    // should only be result.data or result.error so this case covers unforseen deviations.
    return UpdatePDDResponse.Error;
  }

  return {
    updatePDD,
  };
};

export default usePDDServices;
