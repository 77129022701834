import React from 'react';
import { Grid, Typography, createStyles, makeStyles } from '@material-ui/core';
import { useTranslation } from '../../../../../hooks/useTranslation';
import Modal from '../../../../modal';
import Button from '../../../../buttons';
import colors from '../../../../../themes/main-colors';
import theme from '../../../../../themes/theme';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '32px',
      color: colors.noirBlur,
      marginBottom: '6px',
    },
    container: {
      maxWidth: '680px',
    },
    body: {
      backgroundColor: colors.modalBackgroundColor,
      marginTop: '20px',
      padding: '10px 25px 10px 25px',
      borderRadius: '4px',
      boxShadow: `0px 3px 6px ${colors.boxShadowColor}`,
    },
    bodyText: {
      fontSize: '18px',
      color: colors.noirBlur,
      marginTop: '10px',
    },
    marginBottom: {
      marginBottom: '6px',
    },
    actions: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
    },
    okay: {
      fontSize: '18px',
      color: colors.white,
      padding: '5px 25px 5px 25px',
      marginLeft: '20px',
      [theme.breakpoints.down('sm')]: {
        width: '45%',
        marginLeft: '10px',
      },
    },
  }),
);

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

export const AuthUserIsCoCustomerError = (props: Props) => {
  const { isOpen, handleClose } = props;
  const { t, richT } = useTranslation();
  const classes = useStyles();

  return (
    <Modal
      open={isOpen}
      showCloseIcon={true}
      onClose={handleClose}
      title={
        <Typography
          variant="body1"
          className={classes.title}
          data-testid="modal-title"
        >
          {t('CO_CUSTOMER_FOUND')}
        </Typography>
      }
    >
      <Grid container className={classes.container}>
        <Grid container className={classes.body}>
          <Typography
            className={classes.bodyText}
            data-testid="modal-body-text"
          >
            {richT('SSM_AUTH_USER_IS_CO_CUSTOMER_ERROR')}
          </Typography>
        </Grid>
        <Grid container className={classes.actions}>
          <Button
            color={'primary'}
            variant={'contained'}
            className={classes.okay}
            onClick={handleClose}
            data-testid="modal-okay-button"
          >
            {t('CLOSE')}
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};
