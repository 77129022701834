import { useState, useContext } from 'react';

import ROUTES from '../routes';
import { useMutation } from '@apollo/react-hooks';
import { unregisterMutation } from '../queries/registration.query';
import { Mutation } from '../__generated__/pge-types';
import { NotificationsContext } from '../providers/NotificationsProvider';
import { useTranslation } from './useTranslation';
import useAuth from './useAuth';

export default () => {
  const { t } = useTranslation();
  const [unregister] = useMutation<Mutation>(unregisterMutation);
  const { signOut } = useAuth();
  const notificationsContext = useContext(NotificationsContext);
  const [state, setState] = useState({
    loading: false,
    error: false,
  });

  const handleErrors = () => {
    setState(previous => ({ ...previous, loading: false }));
    notificationsContext.setState({
      severity: 'error',
      isOpen: true,
      variant: 'standard',
      title: t('CANCEL_ONLINE_ERROR_TITLE'),
      message: t('CANCEL_ONLINE_ERROR_MESSAGE'),
    });
  };

  const handleSubmit = async () => {
    setState(prevState => ({ ...prevState, loading: true }));

    try {
      const result = await unregister();
      const success = result?.data?.unregister?.success || false;
      if (success) {
        await signOut(ROUTES.HOME);
        setState(previous => ({ ...previous, loading: false }));
        notificationsContext.setState({
          severity: 'success',
          isOpen: true,
          variant: 'filled',
          title: t('CANCEL_ONLINE_SUCCESS'),
        });
      } else {
        return handleErrors();
      }
    } catch (e) {
      return handleErrors();
    }
  };

  return {
    state,
    setState,
    handleSubmit,
  };
};
